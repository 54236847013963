import React, { useMemo } from "react";
import PropTypes from "prop-types";

import "./UserDisplay.scss";

const UserDisplay = (props) => {
  const { user, inline } = props;
  const css = useMemo(() => {
    const c = ["asci-user"];
    if (inline) {
      c.push("asci-user-inline");
    }
    return c.join(" ");
  }, [inline]);
  return (
    <div className={css}>
      <div className="asci-user-name">{user.name}</div>
      <div className="asci-user-email" onClick={(e) => e.stopPropagation()}>
        <a href={`mailto:${user.email}`}>{user.email}</a>
      </div>
    </div>
  );
};

UserDisplay.defaultProps = {};

UserDisplay.propTypes = {
  user: PropTypes.object.isRequired,
  inline: PropTypes.bool,
};

export default UserDisplay;
