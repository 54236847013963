import React, { Component, forwardRef } from "react";
import { PickerProps, PickerTimeProps } from "antd/lib/date-picker/generatePicker";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";

import { DatePicker } from "./DatePicker";

export type TimePickerProps = Omit<PickerTimeProps<Date>, "picker">;

type Ref = Component<
  PickerProps<Date> & {
    status?: "" | "warning" | "error" | undefined;
    popupClassName?: string | undefined;
  }
> &
  CommonPickerMethods;

export const TimePicker = forwardRef<Ref, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} /> // eslint-disable-line react/jsx-props-no-spreading
));

TimePicker.displayName = "TimePicker";
