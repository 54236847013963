import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const fetchSystemFieldsBegin = createAction("programManagement/FETCH_SYSTEM_FIELDS_BEGIN");
export const fetchSystemFieldsSuccess = createAction(
  "programManagement/FETCH_SYSTEM_FIELDS_SUCCESS"
);
export const fetchSystemFieldsError = createAction("programManagement/FETCH_SYSTEM_FIELDS_ERROR");

export const fetchSystemFields = () => async (dispatch, getState) => {
  const state = getState();
  const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

  try {
    dispatch(fetchSystemFieldsBegin());
    const query = CustomerManagement.gqlBuilder(`{
            system_fields {
                name,
                displayName {${displayNameLanguages}},
                canBeMinimumIdentifier,
                canBeRequiredIdentifier
            }

        }`);

    const response = await CustomerManagement.POST("/gql", { query });
    const systemFields = response.payload.data.system_fields;

    dispatch(fetchSystemFieldsSuccess({ systemFields }));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchSystemFieldsError({ errors: message }));
  }
};
