import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const fetchServiceSuitesBegin = createAction("programManagement/FETCH_SERVICE_SUITES_BEGIN");
export const fetchServiceSuitesSuccess = createAction(
  "programManagement/FETCH_SERVICE_SUITES_SUCCESS"
);
export const fetchServiceSuitesError = createAction("programManagement/FETCH_SERVICE_SUITES_ERROR");

export const fetchServiceSuites = () => async (dispatch, getState) => {
  try {
    dispatch(fetchServiceSuitesBegin());

    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    const query = CustomerManagement.gqlBuilder(`{
            service_suites {
                pgid,
                createdOn,
                description,
                displayName{${displayNameLanguages}}
                identifier,
                labelCopies,
                labelType,
                minimumIdentifiers,
                printLabel,
                printOnResults,
                requiredFields,
                servicesTemplateIdentifier,
                services {
                    arguments {
                        name,
                        type,
                        value,
                    },
                    className,
                    displayName {${displayNameLanguages}},
                    identifier,
                    isEnabled,
                    isOptional,
                }
            }
        }`);

    const response = await CustomerManagement.POST("/gql", { query });
    const serviceSuites = response.payload.data.service_suites;

    dispatch(fetchServiceSuitesSuccess({ serviceSuites }));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchServiceSuitesError({ errors: message }));
  }
};
