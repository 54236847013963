import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/device-history/actions";

const initialState = {
  errors: null,
  isLoading: false,
  deviceImage: "",
  items: [],
};

export default createReducer(initialState, {
  [moduleActions.deviceInfosWDS.fetchDeviceImageBegin]: (state, action) => {
    state.deviceImage = "";
    state.isLoading = true;
  },
  [moduleActions.deviceInfosWDS.fetchDeviceImageSuccess]: (state, action) => {
    state.deviceImage = action.payload.image;
    state.isLoading = false;
  },
  [moduleActions.deviceInfosWDS.fetchDeviceImageError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },

  [moduleActions.deviceInfosWDS.fetchWDSDeviceInfosBegin]: (state, action) => {
    state.items = [];
    state.isLoading = true;
  },
  [moduleActions.deviceInfosWDS.fetchWDSDeviceInfosSuccess]: (state, action) => {
    state.items = action.payload.models;
    state.isLoading = false;
  },
  [moduleActions.deviceInfosWDS.fetchWDSDeviceInfosError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.deviceInfosWDS.resetWdsInfos]: (state, action) => {
    state.items = [];
    state.deviceImage = "";
    state.errors = null;
  },
});
