import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { DataTable, TableBody, TableColumn, TableHeader, TableRow, Button } from "react-md";

import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";
import { Spinner } from "~/global";

const LabelList = (props) => {
  const {
    canEdit,
    canDelete,
    labels,
    fetchLabels,
    loading,
    deleteLabel,
    loadLabel,
    setLoadedLabelId,
    setLabelName,
  } = props;
  const history = useHistory();

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  useEffect(() => {
    fetchLabels();
  }, [props.csid]);

  const onEditLabel = useCallback(
    (labelId) =>
      loadLabel(labelId).then(() => history.push(`/label-printer/designer?labelId=${labelId}`)),
    [loadLabel]
  );

  const onCopyLabel = useCallback(
    (labelId) =>
      loadLabel(labelId).then((label) => {
        setLoadedLabelId(null);
        setLabelName(`${label.name} (copy)`);
        history.push(`/label-printer/designer`);
      }),
    [loadLabel]
  );

  const onDeleteLabel = useCallback(
    (labelId) => deleteLabel(labelId).then(() => fetchLabels()),
    [deleteLabel, fetchLabels]
  );

  return (
    <DataTable className="cc-table full-width asci-table" baseId="labels" plain>
      <TableHeader>
        <TableRow className="asci-table-row">
          <TableColumn>Name</TableColumn>
          <TableColumn>Printer</TableColumn>
          <TableColumn>Format</TableColumn>
          <TableColumn />
        </TableRow>
      </TableHeader>
      <TableBody>
        {loading ? (
          <TableRow align="center center" style={{ width: "100%" }}>
            <Spinner />
          </TableRow>
        ) : (
          <>
            {labels.map((label) => (
              <TableRow key={label.id}>
                <TableColumn>{label.name}</TableColumn>
                <TableColumn>{label.printer}</TableColumn>
                <TableColumn>{label.format.name}</TableColumn>
                <TableColumn style={{ display: "flex" }}>
                  {canEdit && (
                    <>
                      <Button
                        icon
                        className="icon-btn"
                        iconClassName="icon-pencil"
                        onClick={() => onEditLabel(label.id)}
                      />
                      <Button
                        icon
                        className="icon-btn"
                        iconClassName="icon-copy"
                        onClick={() => onCopyLabel(label.id)}
                      />
                    </>
                  )}
                  {canDelete && (
                    <Button
                      icon
                      secondary
                      className="icon-btn text-color--red"
                      iconClassName="icon-trash"
                      onClick={() => onDeleteLabel(label.id)}
                    />
                  )}
                </TableColumn>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </DataTable>
  );
};

LabelList.defaultProps = {
  canEdit: true,
  canDelete: true,
};

LabelList.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,

  labels: PropTypes.array,
  error: PropTypes.any,

  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,

  fetchLabels: PropTypes.func,

  loadLabel: PropTypes.func,
  deleteLabel: PropTypes.func,
  setLoaded: PropTypes.func,

  setLoadedLabelId: PropTypes.func,
  setLabelName: PropTypes.func,
};

const stateToProps = (state) => ({
  loading:
    labelSelectors.labelLister.isLoading(state) ||
    labelSelectors.labelDeleter.isDeleting(state) ||
    labelSelectors.labelSaver.isLoading(state),
  loaded: labelSelectors.labelLister.isLoaded(state),
  labels: labelSelectors.labelLister.getLabels(state),
  error: labelSelectors.labelLister.getError(state),
  csid: state.customer.activeCustomerId,
});

const dispatchToProps = {
  fetchLabels: labelActions.labelLister.fetchLabels,

  loadLabel: labelActions.labelSaver.loadLabel,
  deleteLabel: labelActions.labelDeleter.deleteLabel,
  setLoaded: labelActions.labelLister.setLoaded,
  setLoadedLabelId: labelActions.labelSaver.setLoadedLabelId,
  setLabelName: labelActions.labelDesigner.setLabelName,
};

export default connect(stateToProps, dispatchToProps)(LabelList);
