import { CustomerManagement } from "~/services";

export function downloadVersion(version) {
  const versionToInstall = version.replace(/\./g, "-");
  return CustomerManagement.downloadFile(
    `/versions/${versionToInstall}`,
    "application/x-java-jnlp-file",
    `dse-${versionToInstall}.jnlp`
  )
    .then((response) => {
      console.log(response);

      return response;
    })
    .catch((error) => {
      console.error(error);
      if (error.response != undefined) {
        return error;
      }
    });
}
