import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  sub,
} from "date-fns";
import { enUS, es, fr } from "date-fns/locale";
import moment from "moment";
import PropTypes from "prop-types";
import { DateRange, DateRangePicker } from "react-date-range";

import { AsciButton } from "~/global";
import { useTranslation } from "~/i18n";

import "./DatePicker.component.scss";

const locales = {
  en: enUS,
  es,
  fr,
};

// Note that it is important to have the range as a function to be able to evaluate it at a later time.
// For example, if the ui stays open overnight and the user selects `today`, it has to be relevant to the current day, not the day the ui was opened.
export const relativeDateRanges = {
  today: {
    value: "today",
    range: () => ({ startDate: startOfToday(), endDate: endOfToday() }),
  },
  yesterday: {
    value: "yesterday",
    range: () => ({ startDate: startOfYesterday(), endDate: endOfYesterday() }),
  },
  "this-week": {
    value: "this-week",
    range: () => ({ startDate: startOfWeek(new Date()), endDate: endOfWeek(new Date()) }),
  },
  "last-week": {
    value: "last-week",
    range: () => ({
      startDate: startOfWeek(addDays(new Date(), -7)),
      endDate: endOfWeek(addDays(new Date(), -7)),
    }),
  },
  "this-month": {
    value: "this-month",
    range: () => ({ startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) }),
  },
  "last-month": {
    value: "last-month",
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  "one-year": {
    value: "one-year",
    range: () => ({
      startDate: startOfDay(sub(new Date(), { years: 1 })),
      endDate: endOfToday(),
    }),
  },
  // The backend will return an error for ranges of 18 months or longer
  "18-months": {
    value: "18-months",
    range: () => {
      const now = new Date();

      return {
        startDate: startOfDay(sub(now, { months: 18, days: -1 })),
        endDate: endOfDay(now),
      };
    },
  },
  "24-months": {
    value: "24-months",
    range: () => {
      const now = new Date();

      return {
        startDate: startOfDay(sub(now, { months: 24, days: -1 })),
        endDate: endOfDay(now),
      };
    },
  },
};

const DatePicker = ({ setDates, startDate, endDate, maxRange, includeRelativeDate }) => {
  const { t, language } = useTranslation("DatePicker");

  const staticRanges = useMemo(() => {
    const rangeValues = Object.values(relativeDateRanges);

    const ranges = rangeValues.map(({ value, range }) => ({
      value,
      range,
      label: {
        today: t("today"),
        yesterday: t("yesterday"),
        "this-week": t("this-week"),
        "last-week": t("last-week"),
        "this-month": t("this-month"),
        "last-month": t("last-month"),
        "one-year": t("one-year"),
        "18-months": t("18-months"),
        "24-months": t("24-months"),
      }[value],
      isSelected: ({ startDate, endDate }) => {
        const staticRange = range();

        return (
          isSameDay(startDate, staticRange.startDate) && isSameDay(endDate, staticRange.endDate)
        );
      },
    }));

    return ranges;
  }, [t]);

  const [dateRangePicker, setDateRangePicker] = useState({
    selection: {
      selectedRange: staticRanges.find((range) =>
        range.isSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        })
      ),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: "selection",
    },
  });

  const [differenceDatesValid, setDifferenceDatesValid] = useState(true);
  const [isRelative, setIsRelative] = useState(true);

  const currentDate = new Date();
  const maxDate = endOfDay(currentDate.setDate(currentDate.getDate() + 1));
  const width = window.innerWidth;
  const monthsVisible = width < 1248 ? 1 : 2;

  const handleRangeChange = (payload) => {
    const selectedRange = staticRanges.find((range) =>
      range.isSelected({
        startDate: payload.selection.startDate,
        endDate: payload.selection.endDate,
      })
    );

    const dateRange = {
      selection: {
        ...payload.selection,
        startDate: startOfDay(new Date(payload.selection.startDate)),
        endDate:
          payload.selection.endDate != null ? endOfDay(new Date(payload.selection.endDate)) : null,
        selectedRange,
      },
    };

    const endDate = moment(dateRange.selection.endDate);
    const startDate = moment(dateRange.selection.startDate);
    const differenceDatesValid =
      maxRange != null ? endDate.diff(startDate, maxRange.units) < maxRange.num : true;

    setDateRangePicker(dateRange);
    setDifferenceDatesValid(differenceDatesValid);
  };

  useEffect(() => {
    if (dateRangePicker.selection.selectedRange == null) {
      setIsRelative(false);
    } else {
      setIsRelative(true);
    }
  }, [dateRangePicker.selection.selectedRange]);

  return (
    <div className="container-picker column spaced-centered">
      {width > 768 ? (
        <DateRangePicker
          onChange={(payload) => handleRangeChange(payload)}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          className="preview-area"
          months={monthsVisible}
          ranges={[dateRangePicker.selection]}
          inputRanges={[]}
          direction="horizontal"
          maxDate={maxDate}
          locale={locales[language]}
          staticRanges={staticRanges}
        />
      ) : (
        <DateRange
          onChange={(payload) => handleRangeChange(payload)}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          className="preview-area"
          months={monthsVisible}
          ranges={[dateRangePicker.selection]}
          direction="horizontal"
          maxDate={maxDate}
          locale={locales[language]}
        />
      )}

      {maxRange != null ? (
        <div className={differenceDatesValid ? "asci-hide" : "error-message"}>
          {maxRange.errorText}
        </div>
      ) : null}

      <div className="modal-actions row to-end flow-centered full-width">
        {includeRelativeDate && (
          <Checkbox
            checked={isRelative}
            disabled={dateRangePicker.selection.selectedRange == null}
            onChange={(e) => {
              setIsRelative(e.target.checked);
            }}
          >
            {t("relative-date")}
          </Checkbox>
        )}

        <AsciButton
          color="white"
          onClick={() => {
            setDates();
          }}
        >
          {t("cancel")}
        </AsciButton>

        <AsciButton
          color="blue"
          onClick={() => {
            setDates(
              dateRangePicker.selection.startDate,
              dateRangePicker.selection.endDate,
              includeRelativeDate && isRelative
                ? dateRangePicker.selection.selectedRange
                : undefined
            );
          }}
          disabled={!differenceDatesValid}
        >
          {t("ok")}
        </AsciButton>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setDates: PropTypes.func,
  maxRange: PropTypes.object,
  includeRelativeDate: PropTypes.bool,
};

export default DatePicker;
