import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { InputNumber } from "antd";

import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import labelActions from "~/label-printer/actions";

const GridInspector = ({ numRows, numColumns, changeNumRows, changeNumColumns }) => (
  <>
    <InspectorInput label="Rows">
      <InputNumber onChange={changeNumRows} min={1} value={numRows} />
    </InspectorInput>
    <InspectorInput label="Columns">
      <InputNumber onChange={changeNumColumns} value={numColumns} min={1} />
    </InspectorInput>
  </>
);

GridInspector.defaultProps = {};

GridInspector.propTypes = {
  numRows: PropTypes.number,
  numColumns: PropTypes.number,

  displayGrid: PropTypes.bool,

  setLayoutData: PropTypes.func,
  changeNumRows: PropTypes.func,
  changeNumColumns: PropTypes.func,
};

const dispatchToProps = {
  changeNumRows: labelActions.labelDesigner.changeNumRows,
  changeNumColumns: labelActions.labelDesigner.changeNumColumns,
  setLayoutData: labelActions.labelDesigner.setLayoutData,
};

export default connect(null, dispatchToProps)(GridInspector);
