import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const TestSuiteManagementIcon = ({ size, customId }) => (
  <SVGIcon title="Test Suites Management" viewBox="-0.62 0 34 13.75" size={size}>
    <defs>
      <linearGradient id={customId} x2="31.5" y1="6.88" y2="6.88" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill={`url(#${customId})`}
        d="M24.62 13.75H6.88A6.88 6.88 0 1 1 6.88 0h17.74a6.88 6.88 0 1 1 0 13.75zM6.88 1a5.88 5.88 0 1 0 0 11.75h17.74a5.88 5.88 0 1 0 0-11.75z"
      />
      <path
        fill="#93d500"
        d="M7.22 10.81a4.17 4.17 0 1 1 4.17-4.16 4.17 4.17 0 0 1-4.17 4.16zm0-7.33a3.17 3.17 0 1 0 3.17 3.17 3.17 3.17 0 0 0-3.17-3.17z"
      />
    </g>
  </SVGIcon>
);

TestSuiteManagementIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default TestSuiteManagementIcon;
