import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import history from "~/global/history";

import UploadCSVPage from "../UploadCSVPage/UploadCSVPage.component";
import ManualUploadPage from "../ManualUploadPage/ManualUploadPage.component";

import { AsciSpinner, Tab, Tabs } from "~/global";

import "./DataImportPage.component.scss";

const DataImportPage = () => {
  const params = useParams();
  const tabs = ["upload", "manual"];
  const tabIndex = useMemo(
    () => (params.tabSelected ? tabs.indexOf(params.tabSelected) : 0),
    [params.tabSelected]
  );

  const handleSelectedIndex = (index) => {
    updateHistory(tabs[index]);
  };

  const updateHistory = (tabSelected) => {
    const newUrl = `${tabSelected}`;
    history.push(`/data-import/${newUrl}`);
  };

  return (
    <div className="data-import-page panel-container layout-column layout-column--start">
      <Tabs
        fullWidth={false}
        onTabChanged={(index) => handleSelectedIndex(index)}
        selectedIndex={tabIndex}
      >
        <Tab key="upload" title="Upload Device List">
          <UploadCSVPage tabSelected={params.tabSelected} />
        </Tab>
        <Tab key="manual" title="Manual Device Upload">
          <ManualUploadPage tabSelected={params.tabSelected} />
        </Tab>
      </Tabs>
    </div>
  );
};

const stateToProps = (state) => ({});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(DataImportPage);
