import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

import omit from "lodash/omit";
import find from "lodash/find";

import { AsciButton, SectionHeader } from "~/global";
import NewLabelModalContent from "~/label-printer/components/listing/NewLabelModalContent";
import LabelList from "~/label-printer/components/listing/LabelList";
import LabelStatics from "~/label-printer/components/LabelStatics";

import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";

import "./LabelPage.scss";

const LabelPage = (props) => {
  const { createNewLabel, printerFormats, printers } = props;
  const [newLabelModalOpened, setNewLabelModalOpened] = useState(false);
  const [newLabelData, setNewLabelData] = useState({
    layoutName: "grid",
    printer: null,
    format: null,
  });
  const history = useHistory();

  useEffect(() => {
    if (printers.length && !newLabelData.printer) {
      setNewLabelData({ ...newLabelData, printer: printers[0] });
    }
    if (printerFormats.length && !newLabelData.format) {
      setNewLabelData({ ...newLabelData, format: printerFormats[0].name });
    }
  }, [printers, printerFormats, newLabelData.printer, newLabelData.format]);

  const onNewClick = useCallback(() => setNewLabelModalOpened(true), []);
  const onNewLabel = useCallback(() => {
    setNewLabelModalOpened(false);
    const { width, height, format } = newLabelData;

    let formatData;
    if (format === "custom") {
      formatData = { name: format, width, height };
    } else {
      formatData = find(printerFormats, { name: format });
    }

    createNewLabel({ ...omit(newLabelData, ["width", "height", "format"]), format: formatData });
    history.push("/label-printer/designer");
  }, [newLabelData]);
  const onNewCancel = useCallback(() => {
    setNewLabelModalOpened(false);
  }, []);

  const newLabelDataSetter = (key) => (value) => setNewLabelData({ ...newLabelData, [key]: value });

  return (
    <LabelStatics>
      <div className="panel-container label-printer-page">
        <div className="label-list-table">
          <SectionHeader title="Labels">
            <AsciButton color="green" onClick={onNewClick}>
              New
            </AsciButton>
          </SectionHeader>
          <Modal
            title="New label"
            open={newLabelModalOpened}
            onOk={onNewLabel}
            onCancel={onNewCancel}
          >
            <NewLabelModalContent setter={newLabelDataSetter} {...newLabelData} />
          </Modal>
          <LabelList />
        </div>
      </div>
    </LabelStatics>
  );
};

LabelPage.defaultProps = {};
LabelPage.propTypes = {
  createNewLabel: PropTypes.func,
  printers: PropTypes.array,
  printerFormats: PropTypes.array,
  setFormat: PropTypes.func,
  loadedStatics: PropTypes.bool,
  loadingStatics: PropTypes.bool,

  fetchLabelStatics: PropTypes.func,
};

const stateToProps = (state) => ({
  printerFormats: labelSelectors.labelFormats.getPrinterFormats()(state),
  printers: labelSelectors.labelFormats.getPrinters(state).map((e) => e.name),
});

const dispatchToProps = {
  createNewLabel: labelActions.labelDesigner.createNewLabel,
  setFormat: labelActions.labelDesigner.setFormat,
};

export default connect(stateToProps, dispatchToProps)(LabelPage);
