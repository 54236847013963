import { createReducer } from "@reduxjs/toolkit";

import labelActions from "~/label-printer/actions";

const initialState = {
  deleting: false,
  deleted: false,
  error: null,
};

// noinspection DuplicatedCode
export default createReducer(initialState, {
  [labelActions.labelDeleter.deleteLabelBegin]: (state) => {
    state.deleting = true;
    state.deleted = false;
    state.error = null;
  },
  [labelActions.labelDeleter.deleteLabelSuccess]: (state) => {
    state.deleting = false;
    state.deleted = true;
  },
  [labelActions.labelDeleter.deleteLabelError]: (state, action) => {
    state.deleting = false;
    state.error = action.payload;
  },
});
