import React from "react";
import { connect } from "react-redux";
import {
  DataTable,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import moduleActions from "../../actions";

import { Row, Column } from "~/global";

import "./BatteryInformation.component.scss";

const BatteryInformation = (props) => {
  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  return (
    <div
      className={
        props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
      }
    >
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={
            <div className="panel-text title bold-screen printable-title">Battery Information</div>
          }
          secondaryLabel={
            <div className="panel-text no-printable">
              {props.includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          footer={null}
          onExpandToggle={() => includeForPrint()}
          expanded={props.includeToPrint}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          <Row className="battery-details full-width">
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="battery-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="4" className="header-label">
                      <div className="section-banner row centered">Battery Details</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Battery Level:</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.charge || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Temperature:</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.temperature || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Charging State:</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.chargingState || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Cycle Count:</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.cycleCount || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Health</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.health || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Current Max Capacity:</TableColumn>
                    <TableColumn className="table-data">
                      {props.batteryInfo.currentMaxCapacity || " N/A "}
                      {props.batteryInfo.currentMaxCapacity != null ? " mAh" : ""}(
                      {props.batteryInfo.capacity || " N/A "}
                      {props.batteryInfo.capacity != null ? "%" : ""})
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeBatteryToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeBatteryToPrint,
};

export default connect(stateToProps, dispatchToProps)(BatteryInformation);
