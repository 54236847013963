import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const DeveloppersZoneIcon = ({ size, customId }) => (
  <SVGIcon title="Developers Zone" viewBox="0 0 39.06 38.38" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        gradientUnits="userSpaceOnUse"
        x1=".3"
        x2="18.67"
        y1="27.81"
        y2="27.81"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset=".2" stopColor="#96c934" />
        <stop offset=".36" stopColor="#8cbf37" />
        <stop offset=".51" stopColor="#7aad3d" />
        <stop offset=".65" stopColor="#629545" />
        <stop offset=".78" stopColor="#437650" />
        <stop offset=".91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x1="20.39"
        x2="38.76"
        y1="27.81"
        y2="27.81"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-c`}
        x1="10.34"
        x2="28.72"
        y1="10.57"
        y2="10.57"
        xlinkHref={`#${customId}-a`}
      />
    </defs>
    <g>
      <path
        d="M.51 23.75v8.12a2 2 0 0 0 1 1.68l7 4.06a1.91 1.91 0 0 0 1.93 0l7-4.06a2 2 0 0 0 1-1.68v-8.12a2 2 0 0 0-1-1.68l-7-4.06a2 2 0 0 0-1.93 0l-7 4.06a2 2 0 0 0-1 1.68z"
        fill="none"
        stroke={`url(#${customId}-a)`}
        strokeLinecap="round"
        strokeWidth="1.03"
      />
      <path
        d="M20.6 23.75v8.12a2 2 0 0 0 1 1.68l7 4.06a1.92 1.92 0 0 0 1.94 0l7-4.06a2 2 0 0 0 1-1.68v-8.12a2 2 0 0 0-1-1.68l-7-4.06a2 2 0 0 0-1.94 0l-7 4.06a2 2 0 0 0-1 1.68z"
        fill="none"
        stroke={`url(#${customId}-b)`}
        strokeLinecap="round"
        strokeWidth="1.03"
      />
      <path
        className="cls-3"
        d="M24.1 25.5c1.74.24 2.31.93 3.35 1.48l.78.43a3.41 3.41 0 0 1 .38.21 2.72 2.72 0 0 0 .35.16 1.91 1.91 0 0 0 1.46-.11c.28-.18.58-.34.87-.51S34.91 24.88 36 25c-.73.43-4.12 2.53-4.4 2.72s-.55.38-.84.56a2.81 2.81 0 0 1-1 .28 2.55 2.55 0 0 1-1.46-.35l-.38-.24-.75-.47c-1-.67-1.97-1.28-3.07-2zM4.21 25.5c1.74.24 2.3.93 3.35 1.48l.78.43a3.41 3.41 0 0 1 .38.21 2.21 2.21 0 0 0 .35.16 1.91 1.91 0 0 0 1.46-.11c.29-.18.59-.34.88-.51S15 24.88 16.09 25c-.73.43-4.12 2.53-4.4 2.72l-.84.56a2.81 2.81 0 0 1-1 .28 2.55 2.55 0 0 1-1.46-.35L8 27.94l-.75-.47z"
      />
      <path
        d="M10.56 15.28A.84.84 0 0 0 11 16l7.59 4.38a1.92 1.92 0 0 0 1.94 0l7-4.06a1.92 1.92 0 0 0 1-1.68V6.51a2 2 0 0 0-1-1.68L20.5.77a2 2 0 0 0-1.94 0L11 5.16a.81.81 0 0 0-.41.71"
        fill="none"
        stroke={`url(#${customId}-c)`}
        strokeLinecap="round"
        strokeWidth="1.03"
      />
      <text
        fill="#96c93e"
        fontFamily="Titillium-Bold,Titillium"
        fontSize="8.52"
        transform="translate(11.68 13.96)"
      >
        SDK
      </text>
    </g>
  </SVGIcon>
);

DeveloppersZoneIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default DeveloppersZoneIcon;
