import moment from "moment";

import { inRange } from "lodash/number";
import Data from "~/constants/DataDictionnary.constant";

export function filterByKey(array, key, filter = "", caseSensitive = false) {
  if (typeof array !== "object" || !array.filter) {
    throw Error("first param must be an array");
  }

  // TODO: make it work with string items too
  if (array.length && typeof array[0] !== "object") {
    throw Error("array items must be objects");
  }

  if (filter.length === 0) {
    return array;
  }

  const path = key.split(".");

  return array.filter((item) => {
    let value;
    if (path.length > 1) {
      // Deep key
      value = path.reduce((res, prop) => res[prop], item);
    } else {
      // Top level key
      value = item[path[0]];
    }

    if (typeof value !== "string") {
      throw Error("value at given key must be string");
    }

    if (value != null) {
      if (!caseSensitive) {
        value = value.toLowerCase();
        filter = (filter || "").toLowerCase();
      }

      return value.indexOf(filter) > -1;
    }

    return false;
  });
}

export function stringBoolToBool(value) {
  return (value || "").toLowerCase() == "true";
}

// Transforms input from camelcased string to separate capital letters and numbers by spaces
export function camelToFriendly(string = "") {
  let out = string;

  // add space beteew lowercase and uppercase
  out = out.replace(/([a-z])([A-Z])/g, "$1 $2").trim();

  // add space between uppercase followed by uppercase AND lowercase
  out = out.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2").trim();

  // add space better lowercase followed by a number
  out = out.replace(/([a-z])([0-9])/g, "$1 $2").trim();

  // add space between number followed by lowercase
  out = out.replace(/([0-9])([a-z])/g, "$1 $2").trim();

  // capitalize each words
  out = out
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return out;
}

export function toCamel(string = "") {
  return string
    .trim()
    .replace(/[ ]+/g, " ")
    .split(" ")
    .map((word, index) => {
      // leave first world as-is, don't force capital letter
      if (index == 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}

export function isValidRegex(string) {
  let isValid = true;

  try {
    new RegExp(string);
  } catch (e) {
    isValid = false;
  }

  return isValid;
}

export function copyToClipboard(value) {
  const element = document.createElement("textarea");
  element.innerText = value;
  document.body.appendChild(element);
  element.select();
  document.execCommand("copy");
  element.remove();
}

export function getURLParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function capitalizeFirstLetter(str = "") {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function indexValuesByKey(items = [], key, multipleValues = false) {
  if (typeof items !== "object" || items.length == null) {
    throw Error("First parameter must be an array");
  }

  if (key == null) {
    throw Error("The key to index the array by is required");
  }

  return items.reduce((acc, def) => {
    const index = def[key];

    if (multipleValues) {
      // { key: [value1, value2] }
      if (!acc[index]) {
        acc[index] = [];
      }
      acc[index].push(def);
    } else {
      // { key: value }
      if (acc[index]) {
        console.warn(
          `indexValuesByKey: duplicate value at key "${index}", ignoring all after first occurence`
        );
        return acc;
      }
      acc[index] = def;
    }
    return acc;
  }, {});
}

export function arrayHasDuplicate(array = [], compareKey) {
  if (!compareKey) {
    throw Error("second argument compare key required");
  }

  const seen = {};
  const hasDuplicates = array.some(
    (currentObject) =>
      seen.hasOwnProperty(currentObject[compareKey]) || (seen[currentObject[compareKey]] = false)
  );
  return hasDuplicates;
}

export function formatTime(seconds) {
  // following the UI and convention of abbreviations
  if (+seconds.isNaN) {
    return "";
  }

  let time = moment().startOf("day").seconds(seconds);

  if (+seconds < 10) {
    time = time.format("s[ s]");
  } else if (+seconds >= 10 && +seconds < 60) {
    time = time.format("ss[ s]");
  } else if (+seconds >= 60 && +seconds < 3600) {
    time = time.format("m[ min ]ss[ s]");
  } else if (+seconds >= 3600 && +seconds < 36000) {
    time = time.format("H[ h ]mm[ min ]ss[ s]");
  } else {
    time = time.format("HH[ h ]mm[ min ]ss[ s]");
  }

  return time;
}
export function formatDuration(sec) {
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours} h ${minutes} m ${seconds} s`;
}

export function secondsFormat(units = "ns", time = 0) {
  let seconds = "N/A";

  if (units == "s") {
    seconds = time;
  } else if (units == "ms") {
    seconds = time / 1000;
  } else if (units == "ns") {
    seconds = time / 1000000000;
  }

  return seconds.isNaN ? seconds : Math.round(seconds);
}

export function getDurationTime(units = "ns", time = 0) {
  const isNanoSeconds = units == null || units === "ns";
  const isMilliSeconds = units === "ms";

  if (isNanoSeconds && inRange(time, 0, 999999)) {
    return `${Math.round(time)} ns`;
  }
  if (isNanoSeconds && inRange(time, 999999, 1000000000)) {
    const toS = time / 1000000;
    return `${Math.round(toS)} ms`;
  }
  if (isMilliSeconds && inRange(time, 0, 1000)) {
    return `${Math.round(time)} ms`;
  }
  const duration = secondsFormat(units, time);
  return duration.isNaN ? "N/A" : formatTime(duration);
}

export function formatDate(date) {
  const d = date != null ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;

  return d;
}

export function getRegexValidation(key = "", data = "") {
  const keyExist = Data[key] != null;

  if (keyExist) {
    const format = Data[key].Syntax;
    const formatIsValid = format.test(data);

    return formatIsValid;
  }

  const errorMessage =
    key.length > 0
      ? `The key ${key} was not found inside the DataDictionnary`
      : `A key is required to find the required regex inside the dictionnary`;
  console.warn(errorMessage);

  const defaultFormat = /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/;
  const defaultFormatIsValid = defaultFormat.test(data);

  return defaultFormatIsValid;
}

export function validate(key, data) {
  const keyExist = Data[key] != null;

  if (keyExist) {
    const isValid = Data[key].Syntax.test(data);
    const message = Data[key].ValidationMessage;

    return { isValid, message };
  }

  if (key.length > 0) {
    throw new TypeError(`The key ${key} was not found inside the DataDictionnary`);
  } else {
    throw new TypeError("A key is required to find the required regex inside the dictionnary");
  }
}

export function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgb(${r},${g},${b})`;
}

export const getPercentage = (fraction, total) =>
  total > 0 ? `${Math.floor((fraction / total) * 100)}%` : "0%";

export const median = (values) => {
  if (values.length === 0) return 0;

  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
};

const dive = (currentKey, into, target) => {
  for (const i in into) {
    if (into.hasOwnProperty(i)) {
      let newKey = i;
      const newVal = into[i];

      if (currentKey.length > 0) {
        newKey = `${currentKey}.${i}`;
      }

      if (typeof newVal === "object") {
        dive(newKey, newVal, target);
      } else {
        target[newKey] = newVal;
      }
    }
  }
};

export const getFlatten = (arr) => {
  const newObj = {};
  dive("", arr, newObj);
  return newObj;
};
