import { createSelector } from "@reduxjs/toolkit";

export const getLanguages = (state) => state.languages.items;

export const getLanguageCodes = createSelector(getLanguages, (languages) =>
  languages.map((lang) => lang.value)
);

export const getLanguagesByCode = createSelector(getLanguages, (languages) =>
  languages.reduce((acc, language) => {
    acc[language.value] = language;
    return acc;
  }, {})
);

export const getLanguagesCodesToString = createSelector(getLanguageCodes, (languageCodes) =>
  languageCodes.join(",")
);

export const getLanguagesEmptyValue = createSelector(getLanguages, (languages) =>
  languages.reduce((acc, language) => {
    acc[language.value] = "";
    return acc;
  }, {})
);
