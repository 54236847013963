import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Space, Table } from "antd";
import { isEqual } from "lodash";

import {
  archiveSkuGroup,
  fetchSkuGroups,
  SkuGroup,
  SkuGroupBody,
  skuGroupsSelectors,
  updateSkuGroup,
} from "~/entities/sku-groups/model/skuGroupsSlice";
import { AsciSpinner } from "~/global";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import {
  fetchSkuStatics,
  selectAdditionalAttributesByValue,
  selectSkuAttributesByValue,
} from "../model/skuStaticsSlice";
import { CreateSkuGroupForm } from "./CreateSkuGroupForm";
import { UpdateSkuGroupForm } from "./UpdateSkuGroupForm";

import "./SkuGroupsPage.scss";

export const SkuGroupsPage = () => {
  const { t } = useTranslation("sku");
  const dispatch = useAppDispatch();

  const isStaticDataLoading = useAppSelector((state) => state.skuStatics.status === "loading");
  const skuAttributesByValue = useAppSelector(selectSkuAttributesByValue);
  const additionalAttributesByValue = useAppSelector(selectAdditionalAttributesByValue);

  const { formStatus, listStatus, archiveStatus } = useAppSelector((state) => state.skuGroups);
  const allSkuGroups = useAppSelector(skuGroupsSelectors.selectAll);

  const [skuGroupToEdit, setSkuGroupToEdit] = useState<SkuGroup>();

  useEffect(() => {
    void dispatch(fetchSkuStatics());
  }, [dispatch]);

  useEffect(() => {
    if (formStatus === "idle" && archiveStatus === "idle") {
      void dispatch(fetchSkuGroups());
    }
  }, [dispatch, formStatus, archiveStatus]);

  if (isStaticDataLoading) {
    return <AsciSpinner visible />;
  }

  return (
    <div className="panel-container sku-groups-page">
      {skuGroupToEdit ? (
        <UpdateSkuGroupForm
          key={skuGroupToEdit.id}
          skuGroup={skuGroupToEdit}
          onFinish={(values: SkuGroupBody) => {
            const newValues = { ...skuGroupToEdit, ...values };

            if (!isEqual(newValues, skuGroupToEdit)) {
              dispatch(updateSkuGroup({ id: skuGroupToEdit.id, ...values }))
                .unwrap()
                .then(() => {
                  void message.success(t("update-success"));
                })
                .catch((rejectedValue: string) => {
                  void message.error(rejectedValue);
                });
            }

            setSkuGroupToEdit(undefined);
          }}
          onCancel={() => {
            setSkuGroupToEdit(undefined);
          }}
        />
      ) : (
        <CreateSkuGroupForm />
      )}
      <Card className="sku-groups" title={t("sku-groups")} bordered={false}>
        <Table
          columns={[
            {
              title: t("name"),
              dataIndex: "name",
            },
            {
              title: t("device-type"),
              dataIndex: "deviceType",
            },
            {
              title: t("required-attributes"),
              dataIndex: "skuAttributes",
              render: (attributeValues?: string[]) =>
                attributeValues?.map((value) => skuAttributesByValue[value].label).join(", "),
            },
            {
              title: t("additional-attributes"),
              dataIndex: "additionalAttributes",
              render: (attributeValues?: string[]) =>
                attributeValues
                  ?.map((value) => additionalAttributesByValue[value].label)
                  .join(", "),
            },
            {
              title: t("actions"),
              key: "actions",
              align: "right",
              width: 80,
              render: (_, skuGroup) => (
                <Space>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setSkuGroupToEdit(skuGroup);
                    }}
                  />

                  <Popconfirm
                    title={t("delete-confirmation")}
                    okText={t("yes")}
                    okType="danger"
                    cancelText={t("no")}
                    onConfirm={() => {
                      dispatch(archiveSkuGroup(skuGroup.id))
                        .unwrap()
                        .then(() => {
                          void message.success(t("delete-success"));
                        })
                        .catch((rejectedValue: string) => {
                          void message.error(rejectedValue);
                        });
                    }}
                  >
                    <Button danger shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={allSkuGroups}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
          }}
          loading={
            listStatus === "loading"
              ? {
                  indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                  size: "large",
                }
              : false
          }
        />
      </Card>
    </div>
  );
};
