/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash/string";
import { join } from "lodash/array";

import "./ServiceSuitesServices.component.scss";

const workflowEvents = (events) =>
  Object.entries(events).map(([label, _]) => (
    <div key={label}>
      <div className="row service-id">{label}</div>
    </div>
  ));

const ServiceSuitesServices = ({ serviceSuite }) => (
  <div className="overflow-ellipsis">
    {serviceSuite.services.map((service) => {
      if (service.isEnabled === false) {
        return null;
      }

      return (
        <div className="column service-wrapper" key={service.identifier}>
          <div className="row service-name">{service.displayName.en}</div>
          <div className="row service-id">ID: {service.identifier}</div>
          {service.arguments.map((argument) => {
            const nameId = argument.name + argument.value;
            const id = camelCase(nameId);

            return (
              <div key={id} className="row argument">
                <div className="argument-name">{argument.name}:</div>
                <div className="argument-value">
                  {argument.value === true
                    ? "true"
                    : argument.value === false
                    ? "false"
                    : argument.value}
                </div>
              </div>
            );
          })}
        </div>
      );
    })}
    <div className="column service-wrapper">
      <div className="row service-name">Priority Group</div>
      <div className="row service-id">ID: {serviceSuite.pgid}</div>
      <div className="row service-name">Minimum identifiers</div>
      <div className="row service-id">{join(serviceSuite.minimumIdentifiers, ", ")}</div>
      <div className="row service-name">Required Fields</div>
      <div className="row service-id">{join(serviceSuite.requiredFields, ", ") || "N/A"}</div>
      {serviceSuite.workflow && (
        <>
          <div className="row service-name">Workflow events</div>
          {workflowEvents(serviceSuite.workflow.events).map((event) => (
            <div className="row service-id" key={event}>
              {event}
            </div>
          ))}
        </>
      )}
    </div>
  </div>
);

ServiceSuitesServices.propTypes = {
  services: PropTypes.array,
};

ServiceSuitesServices.defaultProps = {
  services: [],
};

export default ServiceSuitesServices;
