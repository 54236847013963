import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import classnames from "classnames";

import {
  Row,
  Column,
  SelectList,
  AsciButton,
  AsciSpinner,
  DateTimeSelector,
  BorderedGroup,
  InfoBanner,
} from "~/global";

import actions from "~/actions";
import selectors from "~/selectors";

import moduleActions from "~/canned-reports/actions/";

import "./CannedReportFilters.component.scss";

const CannedReportFilters = ({
  cleanFacilities,
  configurationsByProgramId,
  currentCannedReport,
  currentFilters,
  deploymentsByFacilityId,
  facilities,
  fetchAllFilters,
  fetchAuditResultsForCustomer,
  fetchAuditResultsForFacility,
  fetchAuditResultsForTeam,
  fetchAuditResultsPerTest,
  fetchProcessesByOperator,
  fetchProcessesByOS,
  fetchProcessesByServiceSuite,
  fetchProcessesByTeam,
  filtersToDisplay,
  isLoading,
  programIdsByDeploymentId,
  programsById,
  setCurrentFilters,
  shiftsByDeploymentId,
  teamsByFacilityId,
  fetchTestProcessingTimes,
}) => {
  const [selectedFacilityId, setSelectedFacilityId] = useState("all");
  const [selectedDeploymentId, setSelectedDeploymentId] = useState("all");
  const [selectedShiftId, setSelectedShiftId] = useState("all");
  const [selectedTeamId, setSelectedTeamId] = useState("all");
  const [selectedProgramId, setSelectedProgramId] = useState("all");
  const [selectedConfigurationId, setSelectedConfigurationId] = useState("all");
  const [selectedPlatformId, setSelectedPlatformId] = useState("all");

  const [dateFrom, setDateFrom] = useState(moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [dateTo, setDateTo] = useState(moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));

  useEffect(() => {
    fetchAllFilters();
  }, []);

  useEffect(() => {
    if (facilities.length > 0) {
      applyFilters(
        cleanFacilities[0].faid,
        selectedDeploymentId,
        selectedShiftId,
        selectedTeamId,
        selectedProgramId,
        selectedConfigurationId
      );
    }
  }, [facilities]);

  useEffect(() => {
    if (Object.keys(currentFilters).length > 0) {
      setSelectedFacilityId(currentFilters.facilityId);
      setSelectedDeploymentId(currentFilters.deploymentId);
      setSelectedShiftId(currentFilters.shiftId);
      setSelectedTeamId(currentFilters.teamId);
      setSelectedProgramId(currentFilters.programId);
      setSelectedConfigurationId(currentFilters.configurationId);
    }
  }, [currentFilters]);

  const validFilters = [
    "facility",
    "deployment",
    "shift",
    "team",
    "program",
    "serviceSuite",
    "timeframe",
    "platform",
  ];

  const validFiltersToDisplay = filtersToDisplay
    ? validFilters.filter((validFilter) => (filtersToDisplay || []).indexOf(validFilter) > -1)
    : validFilters;

  const setTimeframe = (data = { dateFrom: null, dateTo: null }) => {
    setDateFrom(data.dateFrom);
    setDateTo(data.dateTo);
  };

  const updateFilters = (option, selectedFilter) => {
    switch (selectedFilter) {
      case "facility":
        setSelectedFacilityId(option.faid);
        setSelectedDeploymentId("all");
        setSelectedTeamId("all");
        setSelectedShiftId("all");
        setSelectedProgramId("all");
        setSelectedConfigurationId("all");
        break;

      case "deployment":
        setSelectedDeploymentId(option.dpid);
        setSelectedShiftId("all");
        setSelectedProgramId("all");
        setSelectedConfigurationId("all");
        break;

      case "shift":
        setSelectedShiftId(option.id);
        break;

      case "team":
        setSelectedTeamId(option.teamid);
        break;

      case "program":
        setSelectedProgramId(option.id);
        setSelectedConfigurationId("all");
        break;

      case "configuration":
        setSelectedConfigurationId(option.id);
        break;

      case "platform":
        setSelectedPlatformId(option.id);
    }
  };

  const applyFilters = (facilityId, deploymentId, shiftId, teamId, programId, configurationId) => {
    setCurrentFilters({
      facilityId,
      deploymentId,
      shiftId,
      teamId,
      programId,
      configurationId,
      platformId: selectedPlatformId, // TODO: do this for the other filters (ie, use state directly)
      dateFrom,
      dateTo,
    });

    switch (currentCannedReport) {
      case "auditResults":
        if (teamId !== "all") {
          fetchAuditResultsForTeam(facilityId, teamId, programId, dateFrom, dateTo);
        } else if (facilityId !== "all") {
          fetchAuditResultsForFacility(facilityId, programId, dateFrom, dateTo);
        } else {
          fetchAuditResultsForCustomer(programId, dateFrom, dateTo);
        }
        break;

      case "devicesProcessedByGroup":
        if (teamId !== "all") {
          fetchProcessesByOperator(facilityId, teamId, programId, dateFrom, dateTo);
        } else {
          fetchProcessesByTeam(facilityId, programId, dateFrom, dateTo);
        }
        break;

      case "devicesProcessedByServiceSuite":
        fetchProcessesByServiceSuite(
          facilityId,
          teamId,
          programId,
          configurationId,
          dateFrom,
          dateTo
        );
        break;

      case "devicesProcessedByOS":
        fetchProcessesByOS(facilityId, teamId, programId, configurationId, dateFrom, dateTo);
        break;

      case "devicesProcessed":
        fetchProcessesByServiceSuite(
          facilityId,
          teamId,
          programId,
          configurationId,
          selectedPlatformId,
          dateFrom,
          dateTo
        );

        fetchProcessesByOS(
          facilityId,
          teamId,
          programId,
          configurationId,
          selectedPlatformId,
          dateFrom,
          dateTo
        );
        break;

      case "testProcessingTimes":
        fetchTestProcessingTimes(facilityId, programId, dateFrom, dateTo);
        break;

      case "auditResultsPerTest":
        fetchAuditResultsPerTest(facilityId, programId, dateFrom, dateTo);
        // TODO: add deploymentId
        break;
    }
  };

  if (isLoading || selectedFacilityId.length === 0) {
    return <AsciSpinner visible />;
  }

  const selectedFacilityItem = cleanFacilities.filter((f) => f.faid === selectedFacilityId)[0];

  const deploymentItems = deploymentsByFacilityId[selectedFacilityId] || [];
  const selectedDeploymentItem = deploymentItems.filter((d) => d.dpid === selectedDeploymentId)[0];

  const shiftItems = (shiftsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const selectedShiftItem = shiftItems.filter((s) => s.id === selectedShiftId)[0];

  const teamItems = teamsByFacilityId[selectedFacilityId] || [];
  const selectedTeamItem = teamItems.filter((t) => t.teamid === selectedTeamId)[0];

  const programIds =
    (programIdsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const programs = programIds.map((programId) => programsById[programId.id] || []);

  const programItems = programs.filter((program, index, self) => self.indexOf(program) === index);
  const selectedProgramItem = programItems.filter((p) => p.id === selectedProgramId)[0];

  const configItems = configurationsByProgramId[selectedProgramId] || [];
  const selectedConfigItem = configItems.filter((c) => c.id === selectedConfigurationId)[0];

  const platformItems = [
    { id: "all", name: "All platforms" },
    { id: "ios", name: "iOS" },
    { id: "android", name: "Android" },
  ];
  const selectedPlatformItem = platformItems.filter((p) => p.id === selectedPlatformId)[0];

  return (
    <>
      <InfoBanner text="Only devices processed using Pro-Diagnostics Desktop are displayed in the canned reports." />

      <Row className="canned-report-filters full-width" align="space-between center">
        <Column>
          {validFiltersToDisplay.includes("timeframe") ? (
            <BorderedGroup>
              <DateTimeSelector
                dateFrom={dateFrom}
                dateTo={dateTo}
                datesCallBack={(dates) => setTimeframe(dates)}
                label={false}
              />
            </BorderedGroup>
          ) : null}

          <Row className="filters">
            {validFiltersToDisplay.includes("facility") ? (
              <Column className="filter-container">
                <Row className="filter-label">Facility</Row>
                <SelectList
                  items={cleanFacilities}
                  onChange={(option) => updateFilters(option, "facility")}
                  selectedItem={selectedFacilityItem}
                  nameKey="name"
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("deployment") ? (
              <Column className="filter-container">
                <Row className="filter-label">Deployment</Row>
                <SelectList
                  items={deploymentItems}
                  onChange={(option) => updateFilters(option, "deployment")}
                  selectedItem={selectedDeploymentItem}
                  nameKey="name"
                  disabled={deploymentItems.length === 0}
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("shift") ? (
              <Column className="filter-container">
                <Row className="filter-label">Shift</Row>
                <SelectList
                  items={shiftItems}
                  onChange={(option) => updateFilters(option, "shift")}
                  selectedItem={selectedShiftItem}
                  nameKey="name"
                  disabled={shiftItems.length === 0 || selectedDeploymentId === "all"}
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("team") ? (
              <Column className="filter-container">
                <Row className="filter-label">Team</Row>
                <SelectList
                  items={teamItems}
                  onChange={(option) => updateFilters(option, "team")}
                  selectedItem={selectedTeamItem}
                  nameKey="name"
                  disabled={teamItems.length === 0}
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("program") ? (
              <Column className="filter-container">
                <Row className="filter-label">Program</Row>
                <SelectList
                  items={programItems}
                  onChange={(option) => updateFilters(option, "program")}
                  selectedItem={selectedProgramItem}
                  nameKey="name"
                  disabled={programItems.length === 0}
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("serviceSuite") ? (
              <Column className="filter-container">
                <Row className="filter-label">Service Suite</Row>
                <SelectList
                  items={configItems}
                  onChange={(option) => updateFilters(option, "configuration")}
                  selectedItem={selectedConfigItem}
                  nameKey="name"
                  disabled={configItems.length === 0 || selectedProgramId === "all"}
                />
              </Column>
            ) : null}

            {validFiltersToDisplay.includes("platform") ? (
              <Column className="filter-container">
                <Row className="filter-label">Platform</Row>
                <SelectList
                  items={platformItems}
                  onChange={(option) => updateFilters(option, "platform")}
                  selectedItem={selectedPlatformItem}
                  nameKey="name"
                />
              </Column>
            ) : null}
          </Row>
        </Column>

        <Column className="actions" align="center end">
          <Column className="btn-group" align="center center">
            <AsciButton
              color="green"
              disabled={!dateFrom || !dateTo}
              onClick={() =>
                applyFilters(
                  selectedFacilityId,
                  selectedDeploymentId,
                  selectedShiftId,
                  selectedTeamId,
                  selectedProgramId,
                  selectedConfigurationId
                )
              }
            >
              Apply filters
            </AsciButton>

            <Row
              className={classnames("secondary-btn", {
                "is-disabled": !dateFrom || !dateTo,
              })}
              onClick={
                dateFrom && dateTo
                  ? () => applyFilters(cleanFacilities[0].faid, "all", "all", "all", "all", "all")
                  : null
              }
            >
              <span>Clear all filters</span>
            </Row>
          </Column>
        </Column>
      </Row>
    </>
  );
};

CannedReportFilters.propTypes = {
  currentFilters: PropTypes.exact({
    facilityId: PropTypes.string,
    deploymentId: PropTypes.string,
    shiftId: PropTypes.string,
    teamId: PropTypes.string,
    programId: PropTypes.string,
    configurationId: PropTypes.string,
    platformId: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
  }).isRequired,
  setCurrentFilters: PropTypes.func.isRequired,
  currentCannedReport: PropTypes.string.isRequired,
  filtersToDisplay: PropTypes.string,

  // From the store
  fetchAllFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  cleanFacilities: PropTypes.array.isRequired,
  deploymentsByFacilityId: PropTypes.object.isRequired,
  shiftsByDeploymentId: PropTypes.object.isRequired,
  programIdsByDeploymentId: PropTypes.object.isRequired,
  programsById: PropTypes.object.isRequired,
  configurationsByProgramId: PropTypes.object.isRequired,
  teamsByFacilityId: PropTypes.object.isRequired,
  fetchAuditResultsForCustomer: PropTypes.func.isRequired,
  fetchAuditResultsForFacility: PropTypes.func.isRequired,
  fetchAuditResultsForTeam: PropTypes.func.isRequired,
  fetchAuditResultsPerTest: PropTypes.func.isRequired,
  fetchProcessesByTeam: PropTypes.func.isRequired,
  fetchProcessesByOperator: PropTypes.func.isRequired,
  fetchProcessesByServiceSuite: PropTypes.func.isRequired,
  fetchProcessesByOS: PropTypes.func.isRequired,
  fetchTestProcessingTimes: PropTypes.func.isRequired,
};

CannedReportFilters.defaultProps = {
  filtersToDisplay: null,
};

const stateToProps = (state) => ({
  isLoading: state.filters.isLoading,
  facilities: state.filters.facilities,
  cleanFacilities: selectors.filters.getCleanFacilities(state),
  deploymentsByFacilityId: selectors.filters.getDeploymentsByFacilityId(state),
  shiftsByDeploymentId: selectors.filters.getShiftsByDeploymentId(state),
  programIdsByDeploymentId: selectors.filters.getProgramIdsByDeploymentId(state),
  programsById: selectors.filters.getProgramsById(state),
  configurationsByProgramId: selectors.filters.getConfigurationsByProgramId(state),
  teamsByFacilityId: selectors.filters.getTeamsByFacilityId(state),
});

const dispatchToProps = {
  fetchAllFilters: actions.filters.fetchAllFilters,
  fetchAuditResultsForCustomer: moduleActions.auditResults.fetchAuditResultsForCustomer,
  fetchAuditResultsForFacility: moduleActions.auditResults.fetchAuditResultsForFacility,
  fetchAuditResultsForTeam: moduleActions.auditResults.fetchAuditResultsForTeam,
  fetchProcessesByTeam: moduleActions.processes.fetchProcessesByTeam,
  fetchProcessesByOperator: moduleActions.processes.fetchProcessesByOperator,
  fetchProcessesByServiceSuite: moduleActions.processesByServiceSuite.fetchProcessesByServiceSuite,
  fetchAuditResultsPerTest: moduleActions.auditResultsPerTest.fetchAuditResultsPerTest,
  fetchProcessesByOS: moduleActions.processesByOS.fetchProcessesByOS,
  fetchTestProcessingTimes: moduleActions.testProcessingTimes.fetchTestProcessingTimes,
};

export default connect(stateToProps, dispatchToProps)(CannedReportFilters);
