import { createAction } from "@reduxjs/toolkit";
import { ModelManagement } from "~/services";

export const fetchDeviceImageBegin = createAction("deviceHistory/FETCH_DEVICE_IMAGE_BEGIN");
export const fetchDeviceImageSuccess = createAction("deviceHistory/FETCH_DEVICE_IMAGE_SUCCESS");
export const fetchDeviceImageError = createAction("deviceHistory/FETCH_DEVICE_IMAGE_ERROR");

export const fetchWDSDeviceInfosBegin = createAction("deviceHistory/FETCH_WDS_DEVICE_INFOS_BEGIN");
export const fetchWDSDeviceInfosSuccess = createAction(
  "deviceHistory/FETCH_WDS_DEVICE_INFOS_SUCCESS"
);
export const fetchWDSDeviceInfosError = createAction("deviceHistory/FETCH_WDS_DEVICE_INFOS_ERROR");

export const resetWdsInfos = createAction("deviceHistory/RESET_WDS_INFOS");

export const clearWdsInfos = () => resetWdsInfos();

export const fetchDeviceImage = (imei) => async (dispatch) => {
  try {
    dispatch(fetchDeviceImageBegin());
    const imageType = "LargeImage";

    const query = ModelManagement.gqlBuilder(`{
            models(imei: "${imei}") {
                Images {
                    ${imageType}
                }
            }
        }`);
    const response = await ModelManagement.QUERY(query);
    const imageUrl = ((response.payload.data.models[0] || {}).Images || {})[imageType] || "";
    dispatch(fetchDeviceImageSuccess({ image: imageUrl }));
    return;
  } catch (e) {
    console.error(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request device image didn't complete successfully";
    dispatch(fetchDeviceImageError({ errors: message }));
    return message;
  }
};

export const fetchWDSDeviceInfos = (imei) => async (dispatch) => {
  try {
    dispatch(fetchWDSDeviceInfosBegin());
    const query = ModelManagement.gqlBuilder(`{models(imei: "${imei}") {
            ApplicationFrameworks {
                FlashLiteEnabled,
                FlashLiteVersion,
                FlashRunStandalone
            },
            Battery {
                Battery2GStandbyTime,
                Battery2GTalkTime,
                Battery3GStandbyTime,
                Battery3GTalkTime,
                Battery4GStandbyTime,
                Battery4GTalkTime,
                BatteryCapacity,
                BatteryChargingTime,
                BatteryForm,
                BatteryMusicPlaybackTime,
                BatteryStandbyTime,
                BatteryTalkTime,
                BatteryType,
                BatteryVideoPlaybackTime,
                BrowsingTime,
                FastChargeSupport,
                FastChargeType,
                FastChargeVersion,
                FixedBattery,
                HasBattery,
                UsageTime
            },
            BrowserUA {
                BookmarksSupported,
                BrowserName,
                BrowserScreenSize,
                BrowserVersion,
                DownloadableBrowserApps,
                FramesCapable,
                HtmlVersion,
                JavaAppletEnabled,
                JavaScriptEnabled,
                JavaScriptVersion,
                MultipleObjectsSameLine,
                PanandZoomSupported,
                PipelineCapable,
                PreferenceForFrames,
                TablesCapable,
                WAPPushSupported,
                XhtmlModules,
                XhtmlVersion
            },
            Camera {
                AttachedCamera,
                Camera4KVideoSupport,
                CameraGeoTagging,
                CameraTouchFocus,
                FrontCamera,
                FrontCameraCapturesStills,
                FrontCameraDigitalZoom,
                FrontCameraDigitalZoomFactor,
                FrontCameraFaceDetection,
                FrontCameraFlash,
                FrontCameraISO,
                FrontCameraMaxVideoResolution,
                FrontCameraMaximumResolution,
                FrontCameraNumber,
                FrontCameraOpticalZoom,
                FrontCameraRecordsVideo,
                FrontLens1Aperture,
                FrontLens1Megapixels,
                FrontLens2Aperture,
                FrontLens2Megapixels,
                FrontLens3Aperture,
                FrontLens3Megapixels,
                IntegratedCamera,
                MaximumCameraResolution,
                RearCameraAmbientLightSensor,
                RearCameraAutoFocus,
                RearCameraDigitalZoom,
                RearCameraDigitalZoomFactor,
                RearCameraFaceDetection,
                RearCameraFlash,
                RearCameraHDR,
                RearCameraISO,
                RearCameraLaserFocus,
                RearCameraMaxVideoFrameRate,
                RearCameraMaxVideoResolution,
                RearCameraMinFocusDistance,
                RearCameraModes,
                RearCameraNumber,
                RearCameraOpticalZoom,
                RearCameraOpticalZoomFactor,
                RearCameraRecordsVideo,
                RearCameraVideoMaximumResolution,
                RearLens1Aperture,
                RearLens1Megapixels,
                RearLens2Aperture,
                RearLens2Megapixels,
                RearLens3Aperture,
                RearLens3Megapixels,
                RearLens4Aperture,
                RearLens4Megapixels,
                RearLens5Aperture,
                RearLens5Megapixels
            },
            Connectivity {
                Bluetooth,
                Cable,
                CableConnector,
                CableTypes,
                Infrared,
                PCConnectionMode,
                USBOnTheGo,
                WIFI
            },
            ContentTypes {
                AudioFileTypesSupported,
                AudioMaxAudioEncodingRate,
                AudioMaxFileSize,
                AudioSupported,
                ImageAcceptExtensions,
                ImageColorDepth,
                ImageFileTypesSupported,
                ImageMaxDimension,
                ImageMaxFileSize,
                ImageSupported,
                RingtonesAcceptExtensions,
                VideoFileTypesSupported,
                WallpaperAcceptExtensions,
                WallpaperMaxDimension
            },
            DefaultApplications {
                ApplicationTypes,
                MusicPlayer,
                MusicPlayerType,
                PCSync,
                PCSyncType
            },
            Email {
                EmailAuthSMTP,
                EmailInboundSecurityTypes,
                EmailMaxConcurrentAccounts,
                EmailOutboundSecurityTypes,
                EmailTypes,
                SupportsEmail
            },
            HardwarePlatform {
                BitsPerPixel,
                BluetoothProfile,
                CPU,
                ColorCapable,
                ImageCapable,
                InputCharSet,
                Keyboard,
                Model,
                NumberOfSoftKeys,
                OutputCharSet,
                PixelAspectRatio,
                PointingResolution,
                ScreenSize,
                ScreenSizeChar,
                SoC,
                SoundOutputCapable,
                StandardFontProportional,
                TextInputCapable,
                Vendor,
                VoiceInputCapable
            },
            InstantMessaging {
                IMCapable,
                IMTypes
            },
            Media {
                AnimatedGraphics,
                AudioBitRate,
                FlashVersion,
                RingtoneTypes,
                RingtoneVoices,
                Ringtones,
                Screensaver,
                StreamingAudioSupported,
                StreamingVideoSupported,
                SupportsFlash,
                SupportsFlashScreensaver,
                SupportsFlashWallpaper,
                Themes,
                Video,
                VideoConf,
                VideoEditing,
                VideoFrameRate,
                VideoSend,
                Wallpaper,
                vCalender,
                vCard
            },
            MediaPlayerUA {
                MediaPlayerAccept,
                MediaPlayerGraphicEqualizer,
                MediaPlayerName,
                MediaPlayerVendor,
                MediaPlayerVersion
            },
            Memory {
                AvailableMemory,
                InternalMemory,
                InternalRAM,
                MemoryCardType,
                MemoryType,
                RemovableMemory,
                RemovableMemoryCardSlotPosition,
                RemovableMemoryFormattedFolderStructure,
                RemovableMemoryHotSwapSupport,
                RemovableMemoryMaximumSize,
                SDHCSupport
            },
            MmsCharacteristics {
                MmsApplicAddressingCapable,
                MmsCMFBaseSet,
                MmsCcppAccept,
                MmsCcppAcceptCharSet,
                MmsCcppAcceptEncoding,
                MmsCcppAcceptLanguage,
                MmsCcppStreamingCapable,
                MmsContentClass,
                MmsDrmClass,
                MmsMaxImageResolution,
                MmsMaxMessageSize,
                MmsSmilBaseSet,
                MmsSuppressContentAdaptation,
                MmsVersion,
                MmsXHTMLBaseSet
            },
            NetworkCharacteristics {
                CurrentBearerService,
                IPV6Supported,
                SecuritySupport,
                SupportedBearers,
                SupportedBluetoothVersion,
                SupportedWiFiVersion,
                SupportedWiMaxVersion
            },
            PssCommon {
                AudioChannels,
                MaxPolyphony,
                NumOfGM1Voices,
                NumOfMobileDLSVoicesWithOptionalBlocks,
                NumOfMobileDLSVoicesWithoutOptionalBlocks,
                PssVersion,
                RenderingScreenSize
            },
            PssSmil {
                SmilAccept,
                SmilAcceptSubset,
                SmilBaseSet,
                SmilModules
            },
            PushCharacteristics {
                PushAccept,
                PushAcceptAppID,
                PushAcceptCharset,
                PushAcceptEncoding,
                PushAcceptLanguage,
                PushMaxPushReq,
                PushMsgSize,
                PushSupportedBearers,
                PushVersion
            },
            Referencing {
                BluetoothAddress,
                DeviceLaunchDate,
                EthernetMacAddress,
                InfoLink,
                MarketingName,
                RegionOrCountryOfRelease,
                TAC,
                UAHeader,
                UUID,
                UserAgentString,
                VendorUAProfileURL,
                WDSID,
                WifiMacAddress
            },
            Services {
                SupportedServices
            },
            SoftwarePlatform {
                AcceptDownloadableSoftware,
                AudioInputEncoder,
                CLIPlatform,
                CPVersion,
                CcppAccept,
                CcppAcceptCharset,
                CcppAcceptEncoding,
                CcppAcceptLanguage,
                DownloadableSoftwareSupport,
                EmailURISchemes,
                ImodeProfile,
                ImodeStep,
                JVMVersion,
                JavaEnabled,
                JavaJsrListSupported,
                JavaMaxMemorySize,
                JavaPackage,
                JavaPlatform,
                JavaProtocol,
                MexeClassmarks,
                MexeSecureDomains,
                OSName,
                OSSkin,
                OSSkinVersion,
                OSVendor,
                OSVersion,
                RecipientAppAgent,
                SoftwareNumber,
                SpeechRecognitionCapable,
                SymbianUserInterface,
                SymbianUserInterfaceVersion,
                VideoInputEncoder,
                VoiceXMLCapable
            },
            Source,
            Streaming {
                AdaptationSupport,
                ExtendedRtcpReports,
                MediaAlternatives,
                PSSIntegrity,
                QoESupport,
                RtpProfiles,
                RtpRetransmission,
                StreamingAccept,
                StreamingAcceptSubset,
                StreamingOmaDrm,
                ThreeGPPLinkChar,
                VideoDecodingByteRate,
                VideoInitialPostDecoderBufferingPeriod,
                VideoPreDecoderBufferSize
            },
            ThreeGPFileFormat {
                Brands,
                ThreeGPAccept,
                ThreeGPAcceptSubset,
                ThreeGPOmaDrm
            },
            VoiceApplications {
                ConferenceCalling,
                PushToTalk,
                UmaSupport,
                VoIP,
                VoIPSIP,
                VoIPSkype,
                VoLTE,
                VoWiFi
            },
            WapCharacteristics {
                DrmClass,
                DrmConstraints,
                OmaDownload,
                SupportedPictogramSet,
                WapDeviceClass,
                WapVersion,
                WmlDeckSize,
                WmlScriptLibraries,
                WmlScriptVersion,
                WmlVersion,
                WtaVersion,
                WtaiLibraries
            },
            WdsBrowserUA {
                Browser,
                BrowserAcceptCharset,
                BrowserCallURIScheme,
                BrowserEmbeddedObjectSupport,
                BrowserFullScreenCapable,
                BrowserImageSupport,
                BrowserLandscapeModeAvailable,
                BrowserLanguages,
                BrowserProtocols,
                BrowserRTSPLinkBehaviour,
                BrowserSaveAs,
                CookieHeaderSupported,
                MaxBookmarkCount,
                MaxBookmarkUrlLength,
                SupportedHttpMethods,
                SupportsCookies,
                SupportsMetaRefresh
            },
            WdsHardwarePlatform {
                AGPS,
                AGPSMethod,
                Accelerometer,
                AlphanumericKeyboard,
                AntennaConnector,
                AntennaType,
                BLE,
                BarometerSensor,
                BeiDouGPS,
                BuiltInHandsFree,
                CPUCoreNumber,
                CPUSpeed,
                CarKitCompatible,
                Category,
                Colour,
                DLNASupported,
                DVBH,
                Depth,
                DeviceCategory,
                DolbyMobileSupported,
                DualSIM,
                DualStandby,
                EAPSIM,
                FMRadio,
                FMTransmitter,
                FingerprintScanner,
                FlashLight,
                GLONASS,
                GPS,
                GalileoGNSS,
                GloveMode,
                GraphicsProcessorManufacturer,
                GraphicsProcessorModel,
                HDMIPort,
                HardwareManufacturer,
                HeadphoneJackSize,
                Height,
                HighSpeedSIM,
                IPRatings,
                IrisFaceDetection,
                Magnetometer,
                MediaFLO,
                MobileDeviceFormFactor,
                NFCInternal,
                NFCSIM,
                NumericKeyboard,
                PrimaryScreenDiagonalSize,
                PrimaryScreenHeight,
                PrimaryScreenMaterialType,
                PrimaryScreenPPI,
                PrimaryScreenRotate,
                PrimaryScreenType,
                PrimaryScreenWidth,
                RuggedisedEnclosure,
                SGPS,
                SarLevel,
                ScreenOrientation,
                SecondaryBitsPerPixel,
                SecondaryColorCapable,
                SecondaryPixelAspectRatio,
                SecondaryScreenHeight,
                SecondaryScreenPPI,
                SecondaryScreenSize,
                SecondaryScreenSizeChar,
                SecondaryScreenType,
                SecondaryScreenWidth,
                SimCount,
                SimFormFactor,
                StepCounter,
                TVOutput,
                TVOutputInterfaceType,
                TetheringSupported,
                ThreeDimensionalAccelerationSupport,
                ThreeDimensionalSupported,
                TouchScreenNavigation,
                UINavigationType,
                USBIDMassStorageDeviceClass,
                USBIDMediaTransferProtocol,
                VibratingAlert,
                Weight,
                WiFiBands,
                WiFiDirect,
                WiFiMIMO,
                WiFiSecurityTypesSupported,
                Width,
                WirelessCharging
            },
            WdsNetworkCharacteristics {
                ApplicationProcessorVendor,
                BasebandVendor,
                CDMAFrequencyTypes,
                CarrierAggregation,
                CellBroadcast,
                CellBroadcastDataDownload,
                ConcatenatedSMS,
                EDGEDownloadSpeeds,
                EDGEMultislotClass,
                FrequencyTypes,
                GPRSDownloadSpeeds,
                GPRSMultislotClass,
                GSMFrequencyTypes,
                HSDPASpeeds,
                HSUPASpeeds,
                LTEDownloadSpeeds,
                LTEFrequencyBands,
                LTEFrequencyTypes,
                LTEUECategory,
                LTEUploadSpeeds,
                MobileTelecommunicationsSystem,
                NR5GFrequencyBands,
                NR5GFrequencyTypes,
                PopUpSMS,
                SMSCharSet,
                SimLock,
                SupportedWirelessTechnologies,
                TDSCDMAFrequencyTypes,
                Telephony,
                TelephonyTypes,
                TestedNetwork,
                UMTSFrequencyBands,
                UMTSFrequencyTypes,
                UsableURLInSMS
            },
            WdsProfile {
                NetworkBranded,
                ProfileVersion,
                PublicationDate
            },
            WdsWapCharacteristics {
                WspEncodingVersion
            }
        }}`);
    const response = await ModelManagement.POST("/gql", { query });
    const { models } = response.payload.data;

    const currentDevice = models[0];

    dispatch(fetchWDSDeviceInfosSuccess({ currentDevice }));

    if (models.length === 0) {
      const message = "IMEI was not found on TAC database";
      dispatch(fetchWDSDeviceInfosError({ errors: message }));
      return message;
    }

    return;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchWDSDeviceInfosError({ errors: message }));
    return message;
  }
};
