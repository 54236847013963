import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "react-md";
import { Row, Column, AsciButton } from "~/global";

import "./DuplicateTemplateDialog.component.scss";

const DuplicateTemplateDialog = ({ template, addNewTemplate, close }) => {
  const [identifier, setIdentifier] = useState(`${template.identifier} (copy)`);

  // call to the DB action to duplicate the focused template
  const duplicateTemplate = () => {
    const { services } = template;
    const newTemplate = { identifier, services };
    addNewTemplate(newTemplate);
    close();
  };

  return (
    <div className="duplicate-template-dialog">
      <Column className="full-width">
        <Row className="identifier-input full-width">
          <div className="identifier-label">Identifier:</div>
          <TextField
            id="identifier-text-field"
            lineDirection="left"
            placeholder="Enter new Identifier"
            value={identifier}
            className="identifier-field"
            onChange={(value) => setIdentifier(value)}
            type="text"
          />
        </Row>
        <Row className="btn-group full-width">
          <AsciButton className="cancel-btn" color="white" onClick={() => close()}>
            Cancel
          </AsciButton>
          <AsciButton
            className="confirm-btn"
            color="green"
            onClick={() => duplicateTemplate()}
            disabled={identifier == ""}
          >
            Duplicate
          </AsciButton>
        </Row>
      </Column>
    </div>
  );
};

DuplicateTemplateDialog.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(DuplicateTemplateDialog);
