import React, { useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, Select } from "antd";
import { Cell, Grid, TextField, Switch } from "react-md";
import { useDispatch, useSelector } from "react-redux";
import { AsciButton } from "~/global";
import { useAppSelector } from "~/hooks";

import actions from "~/actions";
import selectors from "~/selectors";

const languages = ["en", "fr", "es"];

const FailCodeEditor = ({ canEditFailFast, onCancel, onConfirm, onCreateAnother }) => {
  const existingNames = useSelector((state) => selectors.failCodes.getExistingFailCodeNames(state));
  const failCode = useSelector((state) => selectors.failCodes.getUpdatedFailCode(state));
  const originalFailCode = useSelector((state) => state.failCode.original);
  const errors = useSelector((state) => state.failCode.errors);
  const processRoutes = useAppSelector((state) => state.customer?.processRoutes);

  const [canCreateAnother, setCanCreateAnother] = useState(false);

  const dispatch = useDispatch();

  const onNameChanged = (name) => {
    dispatch(actions.failCode.validateName(name, existingNames));
    dispatch(actions.failCode.setName(name));
  };

  const onDescriptionChanged = (description, language) => {
    dispatch(actions.failCode.validateDescription(description, language));
    const newDescription = {
      ...failCode.description,
      [language]: description,
    };
    dispatch(actions.failCode.setDescription(newDescription));
  };

  const toggleFailFast = () => {
    dispatch(actions.failCode.setFailFast(!failCode.failFast));
  };

  const toggleShowOnDesktop = () => {
    dispatch(actions.failCode.setShowOnDesktop(!failCode.showOnDesktop));
  };
  const onRouteChanged = (route) => {
    dispatch(actions.failCode.setRoute(route));
  };
  const save = () => {
    if (!isDisabled) {
      if (canCreateAnother) {
        onCreateAnother(failCode);
      } else {
        onConfirm(failCode);
      }
    }
  };

  const errorsFound =
    errors.name.length > 0 ||
    errors.description.en.length > 0 ||
    failCode.name.length === 0 ||
    failCode.description.en.length === 0;
  const isEqual =
    failCode.name === originalFailCode.name &&
    failCode.description === originalFailCode.description &&
    failCode.failFast === originalFailCode.failFast &&
    failCode.showOnDesktop === originalFailCode.showOnDesktop &&
    failCode.route === originalFailCode.route;
  const isDisabled = errorsFound || isEqual;

  return (
    <div className="fail-code-editor">
      <Grid className="full-width">
        <Cell size={5} tabletSize={12} className="row centered to-end">
          Name:&nbsp;<sup>*</sup>
        </Cell>
        <Cell size={7} tabletSize={12}>
          <TextField
            required
            id="fail-codes-editor-name"
            error={errors.name.length > 0}
            errorText={errors.name}
            value={failCode.name}
            onChange={onNameChanged}
            type="text"
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                save();
              }
            }}
          />
        </Cell>
        <Cell size={12}>
          <div className="layout-row">
            <div className="flex--30">Language:</div>
            <div className="flex--70">Description:</div>
          </div>
          {languages.map((language) => (
            <div
              className="layout-row layout-row--center-center"
              key={`description-language-${language}`}
            >
              <div className="flex--30">
                {language.toUpperCase()}: <sup>{language == "en" ? "*" : ""}</sup>
              </div>
              <div className="flex--70">
                <TextField
                  required={language == "en"}
                  id={`fail-codes-description-${language}`}
                  error={(errors.description[language] || "").length > 0}
                  errorText={errors.description[language]}
                  value={failCode.description[language]}
                  onChange={(description) => onDescriptionChanged(description, language)}
                  type="text"
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      save();
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </Cell>
        <Cell size={5} className="row centered to-end">
          Fail Fast:
        </Cell>
        <Cell size={7}>
          <Switch
            id={`fail-fast-switch-${failCode.id}`}
            type="switch"
            name={`fail-fast ${failCode}`}
            checked={failCode.failFast}
            onClick={(e) => e.stopPropagation()}
            onChange={toggleFailFast}
            className="flex--30 editor-switch"
            label=""
            disabled={!canEditFailFast}
          />
        </Cell>
        <Cell size={5} className="row centered to-end">
          Show on Desktop:
        </Cell>
        <Cell size={7}>
          <Switch
            id={`show-on--desktop-switch-${failCode.id}`}
            type="switch"
            name={`fail-show-on-desktop ${failCode}`}
            checked={failCode.showOnDesktop}
            onClick={(e) => e.stopPropagation()}
            onChange={toggleShowOnDesktop}
            className="flex--30 editor-switch"
            label=""
          />
        </Cell>
        <Cell size={5} className="row centered to-end">
          Route:
        </Cell>
        <Cell size={7}>
          <Select defaultValue={failCode.route} style={{ width: 120 }} onChange={onRouteChanged}>
            {processRoutes.map((route) => (
              <Select.Option value={route} key={route}>
                {route}
              </Select.Option>
            ))}
          </Select>
        </Cell>
      </Grid>
      <div className="dialog-actions layout-row layout-row--end-center">
        {onCreateAnother !== null && typeof onCreateAnother === "function" ? (
          <Checkbox
            onChange={(e) => setCanCreateAnother(e.target.checked)}
            checked={canCreateAnother}
          >
            Create another
          </Checkbox>
        ) : null}
        <AsciButton color="green" onClick={save} disabled={isDisabled}>
          Save
        </AsciButton>
        <AsciButton color="white" onClick={onCancel}>
          Cancel
        </AsciButton>
      </div>
    </div>
  );
};

FailCodeEditor.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCreateAnother: PropTypes.func.isRequired,
};

export default FailCodeEditor;
