import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

// TODO replace labels by icons/images.
const ALL = ["all", "All"];
const LEFT = ["left", "Left"];
const TOP = ["top", "Top"];
const RIGHT = ["right", "Right"];
const BOTTOM = ["bottom", "Bottom"];

const SIDES_OPTIONS = [ALL, LEFT, TOP, RIGHT, BOTTOM];

const SideSelect = (props) => {
  const { value, onChange, includeAll } = props;

  return (
    <Select value={value} onChange={onChange} allowClear>
      {SIDES_OPTIONS.filter((side) => includeAll || side[0] !== "all").map(
        ([sideValue, sideLabel]) => (
          <Select.Option key={`side-${sideValue}`} value={sideValue}>
            {sideLabel}
          </Select.Option>
        )
      )}
    </Select>
  );
};

SideSelect.defaultProps = {
  includeAll: true,
};

SideSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  includeAll: PropTypes.bool,
};

SideSelect.SIDES = SIDES_OPTIONS.map((side) => side[0]);

export default SideSelect;
