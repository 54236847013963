import { createReducer } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import moduleActions from "~/programs-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,
  errors: {},
};

export default createReducer(initialState, {
  [moduleActions.serviceSuiteEditor.setServiceSuiteEdition]: (state, action) => {
    state.original = action.payload.serviceSuite;
  },
  [moduleActions.serviceSuiteEditor.onChangeServiceSuite]: (state, action) => {
    state.modifications = { ...state.modifications, ...action.payload.serviceSuite };
  },
  [moduleActions.serviceSuiteEditor.setServiceToggled]: (state, action) => {
    const identifier = action.payload.serviceIdentifier;
    let services;

    if (state.modifications.services != null) {
      services = state.modifications.services;
    } else {
      services = cloneDeep(state.original.services);
    }

    for (let index = 0; index < services.length; index++) {
      const service = services[index];
      if (service.identifier == identifier) {
        service.isEnabled = !service.isEnabled;
        break;
      }
    }

    state.modifications.services = services;
  },
  [moduleActions.serviceSuiteEditor.setServiceArguments]: (state, action) => {
    const identifier = action.payload.serviceIdentifier;
    const { serviceArguments } = action.payload;
    let services;

    if (state.modifications.services != null) {
      services = state.modifications.services;
    } else {
      services = cloneDeep(state.original.services);
    }

    for (let index = 0; index < services.length; index++) {
      const service = services[index];
      if (service.identifier == identifier) {
        service.arguments = [].concat(serviceArguments);
        break;
      }
    }

    state.modifications.services = services;
  },
  [moduleActions.serviceSuiteEditor.setServiceSuitesServices]: (state, action) => {
    state.modifications.services = action.payload.services;
  },
  [moduleActions.serviceSuiteEditor.resetServiceSuiteEdition]: (state, action) => {
    state.original = {};
    state.modifications = {};
    state.errors = {};
    state.isLoading = false;
  },
  [moduleActions.serviceSuiteEditor.setServicesOrder]: (state, action) => {
    state.modifications.services = action.payload.services;
  },
});
