import React from "react";
import PropTypes from "prop-types";

import FieldInspector from "./FieldInspector";
import InspectorSection from "./commons/InspectorSection";
import ShapeStyleInspector from "./ShapeStyleInspector";
import ShapeLayoutInspector from "~/label-printer/components/designer/inspector/ShapeLayoutInspector";

const ShapeInspector = ({ shape }) => {
  const { identity, fieldType, fieldData, shapeData } = shape;
  const { style } = shapeData;

  return (
    <InspectorSection title="Shape">
      <FieldInspector identity={identity} fieldType={fieldType} fieldData={fieldData} />
      <ShapeLayoutInspector identity={identity} />
      <ShapeStyleInspector style={style} identity={identity} />
    </InspectorSection>
  );
};

ShapeInspector.defaultProps = {};

ShapeInspector.propTypes = {
  shape: PropTypes.object,
};

export default ShapeInspector;
