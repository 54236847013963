import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchAuditResultsForCustomerBegin = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_CUSTOMER_BEGIN"
);
export const fetchAuditResultsForCustomerSuccess = createAction("canned-reports/FETCH_AUDIT_RESULTS_FOR_CUSTOMER_SUCCESS"); // prettier-ignore
export const fetchAuditResultsForCustomerError = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_CUSTOMER_ERROR"
);

export const fetchAuditResultsForFacilityBegin = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_FACILITY_BEGIN"
);
export const fetchAuditResultsForFacilitySuccess = createAction("canned-reports/FETCH_AUDIT_RESULTS_FOR_FACILITY_SUCCESS"); // prettier-ignore
export const fetchAuditResultsForFacilityError = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_FACILITY_ERROR"
);

export const fetchAuditResultsForTeamBegin = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_TEAM_BEGIN"
);
export const fetchAuditResultsForTeamSuccess = createAction("canned-reports/FETCH_AUDIT_RESULTS_FOR_TEAM_SUCCESS"); // prettier-ignore
export const fetchAuditResultsForTeamError = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_FOR_TEAM_ERROR"
);

export const fetchAuditResultsForCustomer = (programId, from, to) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        auditResultsForCustomer(
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Facility {
                name,
                faid
            },
            ChartData {
                Audits,
                UphPercentMedian,
                FailedAuditsPercent,
                ProcessesUnique
            }
        }
    }`);

  dispatch(fetchAuditResultsForCustomerBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const auditResultsForCustomer =
      (((response || {}).payload || {}).data || {}).auditResultsForCustomer || [];

    dispatch(fetchAuditResultsForCustomerSuccess({ auditResultsForCustomer }));
  } catch (e) {
    if (e instanceof Error) throw e;

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(fetchAuditResultsForCustomerError({ errors: message }));
  }
};

export const fetchAuditResultsForFacility =
  (facilityId, programId, from, to) => async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`{
        auditResultsForFacility(
            facility: "${facilityId === "all" ? "" : facilityId}",
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Team {
                name,
                teamid
            },
            ChartData {
                Audits,
                UphPercentMedian,
                FailedAuditsPercent,
                ProcessesUnique
            }
        }
    }`);

    dispatch(fetchAuditResultsForFacilityBegin());

    try {
      const response = await CustomerManagement.QUERY(query);
      const auditResultsForFacility =
        (((response || {}).payload || {}).data || {}).auditResultsForFacility || [];

      dispatch(fetchAuditResultsForFacilitySuccess({ auditResultsForFacility }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      dispatch(fetchAuditResultsForFacilityError({ errors: message }));
    }
  };

export const fetchAuditResultsForTeam =
  (facilityId, teamId, programId, from, to) => async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`{
        auditResultsForTeam(
            facility: "${facilityId === "all" ? "" : facilityId}",
            team: "${teamId === "all" ? "" : teamId}",
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Operator {
                updateData {
                    user_metadata {
                        name
                    }
                }
            },
            ChartData {
                Audits,
                UphPercentMedian,
                FailedAuditsPercent,
                ProcessesUnique
            }
        }
    }`);

    dispatch(fetchAuditResultsForTeamBegin());

    try {
      const response = await CustomerManagement.QUERY(query);
      const auditResultsForTeam =
        (((response || {}).payload || {}).data || {}).auditResultsForTeam || [];

      dispatch(fetchAuditResultsForTeamSuccess({ auditResultsForTeam }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      dispatch(fetchAuditResultsForTeamError({ errors: message }));
    }
  };
