import { createSelector } from "@reduxjs/toolkit";

import { isEmpty } from "lodash/lang";
import isEqual from "lodash/isEqual";

const getCustomerIdentityOriginal = (state) =>
  state.modules.customerManagement.customerIdentity.original;
const getCustomerIdentityModified = (state) =>
  state.modules.customerManagement.customerIdentity.modifications;

const getCustomerIdentityNameError = (state) =>
  state.modules.customerManagement.customerIdentity.nameError;

export const getUpdatedCustomerIdentity = createSelector(
  [getCustomerIdentityOriginal, getCustomerIdentityModified],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getCustomerName = createSelector([getUpdatedCustomerIdentity], (customerIdentity) =>
  customerIdentity.name != null ? customerIdentity.name : ""
);

export const getCsid = createSelector([getUpdatedCustomerIdentity], (customerIdentity) =>
  customerIdentity.csid != null ? customerIdentity.csid : ""
);

export const getReseller = createSelector([getUpdatedCustomerIdentity], (customerIdentity) =>
  customerIdentity.reseller != null ? customerIdentity.reseller : ""
);

export const getAutoAssignToAllTeams = createSelector(
  [getUpdatedCustomerIdentity],
  (customerIdentity) =>
    customerIdentity.autoAssignToAllTeams != null ? customerIdentity.autoAssignToAllTeams : ""
);

export const getCustomerLogo = createSelector([getUpdatedCustomerIdentity], (customerIdentity) =>
  customerIdentity.logo != null ? customerIdentity.logo : ""
);

// VALIDATION: to be included for each tab
export const getCustomerIdentityIsValid = createSelector(
  [getCustomerIdentityNameError],
  (customerIdentityNameError) => !customerIdentityNameError
);

export const getCustomerIdentityIsEqual = createSelector(
  [getCustomerIdentityModified, getCustomerIdentityOriginal],
  (modified, original) => {
    const modifiedTrimed = {
      ...modified,
      name: modified.name != null ? modified.name.trim() : "",
    };
    const originalTrimed = {
      ...original,
      name: original.name != null ? original.name.trim() : "",
    };
    return isEqual(modifiedTrimed, originalTrimed) || isEmpty(modified);
  }
);

export const getRequiredComplete = createSelector(
  [getCustomerName],
  (customerName) => customerName.length > 0
);

export const getCanSaveCustomerIdentity = createSelector(
  [getCustomerIdentityIsValid, getCustomerIdentityIsEqual, getRequiredComplete],
  (customerIdentityIsValid, customerIdentityIsEqual, requiredCompleted) =>
    customerIdentityIsValid && !customerIdentityIsEqual && requiredCompleted
);
