import selectors from "~/selectors";

export const SET_SELECTED_PLATFORM = "SET_SELECTED_PLATFORM";
export const SET_INITIALLY_SELECTED_PLATFORM_VALUE = "SET_INITIALLY_SELECTED_PLATFORM_VALUE";

// Set selected platform
export const setSelectedPlatform = (platform) => ({
  type: SET_SELECTED_PLATFORM,
  payload: { platform },
});

export const setSelectedPlatformWithValue = (platformValue) => (dispatch, getState) => {
  const state = getState();
  const platformsByValue = selectors.platforms.getPlatformsByValue(state);

  dispatch(setSelectedPlatform(platformsByValue[platformValue]));
};

export const setInitiallySelectedPlatformValue = (platformValue) => ({
  type: SET_INITIALLY_SELECTED_PLATFORM_VALUE,
  payload: { platformValue },
});
