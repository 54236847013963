import React from "react";
import PropTypes from "prop-types";
import { TextField } from "~/global";

import InspectorInput from "../commons/InspectorInput";

const TextFieldInspector = ({ identity, value, setShapeFieldData }) => {
  const fieldSetter = (key) => (data) => setShapeFieldData({ identity, data, key });
  return (
    <>
      <InspectorInput label="Text">
        <TextField onChange={fieldSetter("value")} value={value} />
      </InspectorInput>
    </>
  );
};

TextFieldInspector.propTypes = {
  identity: PropTypes.number,
  value: PropTypes.string,

  // Setters
  setShapeFieldData: PropTypes.func.isRequired,
};

export default TextFieldInspector;
