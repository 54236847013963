/* eslint-disable no-restricted-imports */
import React from "react";
import { DataTable, TableHeader, TableBody, TableRow, TableColumn } from "react-md";
import { useTranslation } from "~/i18n";

import { SectionHeader, Row, Column } from "~/global";
import { TopFailedAudits, TopFailedTests } from "../../models/teams.slice";

import "./TopFailedCard.component.scss";

type Props =
  | {
      dataType: "Tests";
      data?: TopFailedTests[];
    }
  | {
      dataType: "Audits";
      data?: TopFailedAudits[];
    };

const TopFailedCard = ({ data, dataType }: Props) => {
  const { t } = useTranslation("dashboard");

  if ((data || []).length === 0) {
    return (
      // TODO: change the className
      <Column className="cc-performance-card">
        <Row className="no-data" align="center center">
          <i className="icon-ban" />
          {t("there-is-no")} {dataType} {t("data-to-be-displayed")}
        </Row>
      </Column>
    );
  }

  const normalizeData =
    dataType === "Audits"
      ? data?.map((d) => ({ ...d, fails: d.auditsFailed, total: d.audits }))
      : data?.map((d) => ({ ...d, fails: d.testsFailed, total: d.tests }));

  return (
    // TODO: change the className
    <Column className="cc-performance-card">
      <SectionHeader title={`${t("top-failed")} ${dataType}`} />

      <Row align="space-around">
        <DataTable className="tests-table" plain>
          <TableHeader>
            <TableRow>
              <TableColumn>{t("tests")}</TableColumn>
              <TableColumn className="text-centered">{t("fail-percent")}</TableColumn>
              <TableColumn className="text-centered">{t("score")}</TableColumn>
            </TableRow>
          </TableHeader>

          <TableBody>
            {normalizeData &&
              normalizeData.map((d, index) => (
                <TableRow key={index}>
                  <TableColumn>{d.name}</TableColumn>
                  <TableColumn className="text-centered">{d.percentage}</TableColumn>
                  <TableColumn className="text-centered">{`${d.fails}/${d.total}`}</TableColumn>
                </TableRow>
              ))}
          </TableBody>
        </DataTable>
      </Row>
    </Column>
  );
};

export default TopFailedCard;
