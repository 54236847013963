import { createSelector } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";
import { selectors as workflowSelectors } from "~/workflow/redux";

const getServiceSuiteOriginal = (state) =>
  state.modules.programManagement.serviceSuiteEditor.original;
const getServiceSuiteModifications = (state) =>
  state.modules.programManagement.serviceSuiteEditor.modifications;
const getLanguages = (state) => state.languages.items;
const getPrograms = (state) => state.programs.items;

export const getAllServiceSuitesByProgram = createSelector([getPrograms], (programs = []) =>
  programs.reduce((acc, program) => {
    const serviceSuites = [...program.serviceSuites].map((service) => ({
      ...service,
      "program.identifier": program.identifier,
      "program.displayName": program.displayName,
    }));
    const allServiceByProgram = {
      ...acc,
      [program.identifier]: serviceSuites,
    };
    return allServiceByProgram;
  }, {})
);

export const getServiceSuitesAllPrograms = createSelector(
  [getAllServiceSuitesByProgram],
  (allServiceSuitesByProgram = []) =>
    Object.keys(allServiceSuitesByProgram).reduce(
      (acc, val) => acc.concat(allServiceSuitesByProgram[val]),
      []
    )
);

export const getUpdatedServiceSuite = createSelector(
  [getServiceSuiteOriginal, getServiceSuiteModifications],
  (original, modifications) => {
    const updatedServices = { ...original, ...modifications };
    return updatedServices;
  }
);

export const getServiceSuiteServices = createSelector([getUpdatedServiceSuite], (serviceSuite) => {
  const services = serviceSuite.services || [];
  return services;
});

export const getSelectedIdentifiers = createSelector(
  [getUpdatedServiceSuite],
  (serviceSuite) => serviceSuite.minimumIdentifiers || []
);

export const getDisplayName = createSelector(
  [getUpdatedServiceSuite, getLanguages],
  (serviceSuite, languages) => {
    if (serviceSuite.displayName != null) {
      return languages.reduce((acc, language) => {
        const value = serviceSuite.displayName[language.value] || "";
        acc[language.value] = {
          value,
          name: language.name,
        };
        return acc;
      }, {});
    }
    return languages.reduce((acc, language) => {
      acc[language.value] = { value: "", name: language.name };
      return acc;
    }, {});
  }
);

export const getEnglishNameValue = createSelector([getUpdatedServiceSuite], (serviceSuite) =>
  serviceSuite.displayName != null ? serviceSuite.displayName.en || "" : ""
);

export const getIdentifier = createSelector(
  [getUpdatedServiceSuite],
  (serviceSuite) => serviceSuite.identifier || ""
);

export const getServiceSuiteDidChange = createSelector(
  [getServiceSuiteOriginal, getUpdatedServiceSuite],
  (original, modifiedServiceSuite) => {
    const modified = isEqual(original, modifiedServiceSuite);
    return !modified;
  }
);

export const getRequiredIsFilled = createSelector(
  [getEnglishNameValue, getIdentifier, getSelectedIdentifiers],
  (englishName, identifier, selectedIdentifiers) => {
    const requiredFilled =
      englishName.length > 0 && identifier.length > 0 && selectedIdentifiers.length > 0;
    return requiredFilled;
  }
);

export const saveDisabled = createSelector(
  [getServiceSuiteDidChange, getRequiredIsFilled, workflowSelectors.didChange],
  (didChange, requiredFilled, workflowChanged) => !requiredFilled || !(didChange || workflowChanged)
);

export const getServiceSuiteServicesByIdentifier = createSelector(
  [getServiceSuiteServices],
  (serviceSuiteServices) =>
    (serviceSuiteServices || []).reduce((acc, service) => {
      acc[service.identifier] = service;
      return acc;
    }, {})
);

export const getDiagnosticDeviceArguments = createSelector(
  [getServiceSuiteServicesByIdentifier],
  (serviceSuiteServicesByIdentifier) =>
    serviceSuiteServicesByIdentifier["DiagnosticsSuite"] != null
      ? serviceSuiteServicesByIdentifier["DiagnosticsSuite"].arguments
      : []
);

export const getDiagnosticDeviceArgumentsByName = createSelector(
  [getDiagnosticDeviceArguments],
  (diagnosticDeviceArguments) =>
    (diagnosticDeviceArguments || []).reduce((acc, arg) => {
      acc[arg.name] = arg;
      return acc;
    }, {})
);
