import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import { Row, Column, AsciButton } from "~/global";
import CannedReportFilters from "~/canned-reports/components/CannedReportFilters/CannedReportFilters.component";
import BubbleChart from "~/canned-reports/components/BubbleChart/BubbleChart.component";
import ReportSummary from "~/canned-reports/components/ReportSummary/ReportSummary.component";

import moduleActions from "~/canned-reports/actions/";
import moduleSelectors from "~/canned-reports/selectors/";

import "./DevicesProcessedByGroupPage.component.scss";

const DevicesProcessedByGroupPage = ({
  fetchProcessesByOperator,
  formattedProcesses,
  processesColumns,
  processesTitles,
  formattedChartData,
}) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const ref = useRef();
  const itemIds = Object.keys(formattedProcesses.processesByItemId);

  const drillDown = (itemId, facilityId, type) => {
    let filters;

    if (type === "team") {
      filters = { ...currentFilters, teamId: itemId, facilityId };
      fetchProcessesByOperator(
        filters.facilityId,
        filters.teamId,
        filters.programId,
        filters.dateFrom,
        filters.dateTo
      );
    }

    setCurrentFilters(filters);
  };

  return (
    <Column className="devices-processed-by-group-page full-width">
      <CannedReportFilters
        currentFilters={currentFilters}
        setCurrentFilters={(filters) => setCurrentFilters(filters)}
        currentCannedReport="devicesProcessedByGroup"
        filtersToDisplay="timeframe facility team program"
      />

      <div className="report-results-wrapper">
        <Row className="report-results">
          <div style={{ display: "flex" }}>
            <Row className="result-item">
              Results:&nbsp;<b>{itemIds.length}</b>&nbsp;Teams
            </Row>

            <Row className="result-item">
              <b>{formattedProcesses.totalProcesses}</b>&nbsp;Total Devices Processed
            </Row>
          </div>

          <ReactToPrint
            content={() => ref.current}
            pageStyle={`
              @page { size: auto; margin: 1cm; }
              @media print {
                .page-break {
                  break-inside: avoid;
                  break-after: avoid;
                }
                body { -webkit-print-color-adjust: exact; }}
              .recharts-wrapper > .recharts-surface { width: 1120px; height: 600px; }
              .recharts-legend-wrapper > .recharts-default-legend { width: 1120px; height: 600px; }
            `}
            trigger={() => (
              <AsciButton color="white">
                <div className="row centered">
                  <i className="icon-printer" />
                  <span className="margin-left--5">Export</span>
                </div>
              </AsciButton>
            )}
          />
        </Row>
      </div>
      <div ref={ref}>
        <BubbleChart
          title="Devices Processed by Group"
          data={formattedProcesses.processesByItemId}
          xAxis={{ dataKey: "productivity", name: "Productivity" }}
          yAxis={{ dataKey: "processes", name: "Total Device Count" }}
          zAxis={{ dataKey: "failedAuditsPercent", name: "Failed Audits", unit: "%" }}
          drillDown={(itemId, facilityId, type) => drillDown(itemId, facilityId, type)}
        />
        <div className="page-break">
          <ReportSummary
            data={formattedChartData}
            columns={processesColumns}
            titles={processesTitles}
          />
        </div>
      </div>
    </Column>
  );
};

const stateToProps = (state) => ({
  formattedProcesses: moduleSelectors.processes.getFormattedProcesses(state),
  processesColumns: moduleSelectors.processes.getProcessesColumns(state),
  processesTitles: moduleSelectors.processes.getProcessesTitles(state),
  formattedChartData: moduleSelectors.processes.getFormattedChartData(state),
});

const dispatchToProps = {
  fetchProcessesByOperator: moduleActions.processes.fetchProcessesByOperator,
};

export default connect(stateToProps, dispatchToProps)(DevicesProcessedByGroupPage);
