import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontIcon } from "react-md";
import { FixedSizeList as List } from "react-window";
import Timestamp from "~/workstation/components/common/Timestamp";

import "./WorkstationRecording.scss";

const WorkstationRecordingField = ({ label, children, ...rest }) => (
  <div className="workstation-recording-field" {...rest}>
    {label && <div className="workstation-recording-field-label">{label}</div>}
    <div className="workstation-recording-field-body">{children}</div>
  </div>
);

const RecordingItem = ({ recording, showManual, style }) => {
  const { process, deviceInfo, processResult, detailsLink, isManual } = recording;
  return (
    <Link
      to={detailsLink || `/reporting/process-run/${process.sessionIDHumanReadable}`}
      className="workstation-recording"
      style={style}
    >
      <div className="workstation-recording-model-info">
        <div className="brand-icon">
          <FontIcon
            iconClassName={`icon-${deviceInfo.manufacturer === "Apple" ? "apple" : "android"}`}
          />
        </div>
        <div className="double-rows">
          <WorkstationRecordingField>
            {deviceInfo.modelName} - ({deviceInfo.modelNumber})
          </WorkstationRecordingField>
          <WorkstationRecordingField label="IMEI">{deviceInfo.IMEI}</WorkstationRecordingField>
        </div>
      </div>
      <Timestamp value={process.endDateTime} />
      <div className="workstation-recording-id">{process.sessionIDHumanReadable}1</div>
      {processResult && (
        <div className={`workstation-recording-result ${processResult.toLowerCase()}`}>
          {processResult}
        </div>
      )}
      {showManual && (
        <div title={isManual ? "Manually reported" : "System reported"}>
          <FontIcon iconClassName={isManual ? "icon-standard-op" : "icon-asci-gear"} />
        </div>
      )}
    </Link>
  );
};

export const RecordingList = ({ recordings, recordingType, pageType, showManual }) => {
  const Row = ({ index, style }) => (
    <RecordingItem recording={recordings[index]} style={style} showManual={showManual} />
  );
  return (
    <>
      {recordings.length > 0 ? (
        <List itemCount={recordings.length} height={360} itemSize={60}>
          {Row}
        </List>
      ) : (
        `No ${recordingType} for this ${pageType}.`
      )}
    </>
  );
};

RecordingList.propTypes = {
  recordings: PropTypes.array,
  recordingType: PropTypes.string,
  pageType: PropTypes.string,
};
