import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";
import { type RootState } from "~/store";

export type DeviceProcess = {
  /** e.g. SE-TES-2828 */
  sessionId: string;
  /** ISO 8601 date */
  endDateTime: string;
  name: string;
  /** INCOMPLETE, FAIL, PASS */
  result: ProcessResult;
};

type ProcessResult = "PASS" | "FAIL" | "INCOMPLETE";

type ServiceError = { json: { message?: string } };

export const fetchDeviceProcesses = createAsyncThunk<
  DeviceProcess[],
  string,
  { rejectValue: string }
>("deviceProcesses/fetch", async (sessionId, { rejectWithValue }) => {
  try {
    const { payload } = (await CustomerManagement.GET(`/device/${sessionId}/rollup`)) as {
      payload: { processes: DeviceProcess[] };
    };

    return payload.processes;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

const deviceProcessesAdapter = createEntityAdapter<DeviceProcess>({
  selectId: (deviceProcess) => deviceProcess.sessionId,
});

interface DeviceProcessesState extends EntityState<DeviceProcess> {
  status: "idle" | "loading";
}

const initialState: DeviceProcessesState = {
  ...deviceProcessesAdapter.getInitialState(),
  status: "idle",
};

const deviceProcessesSlice = createSlice({
  name: "deviceProcesses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchDeviceProcesses.pending, (draftState) => {
        draftState.status = "loading";
      })
      .addCase(fetchDeviceProcesses.fulfilled, (draftState, action) => {
        draftState.status = "idle";
        deviceProcessesAdapter.setAll(draftState, action.payload);
      })
      .addCase(fetchDeviceProcesses.rejected, (draftState) => {
        draftState.status = "idle";
      });
  },
});

export default deviceProcessesSlice.reducer;

export const deviceProcessesSelectors = deviceProcessesAdapter.getSelectors<RootState>(
  (state) => state.reportingModule.deviceProcesses
);
