import { createSelector } from "@reduxjs/toolkit";
import sortBy from "lodash/sortBy";

export const getSortedTestSuites = createSelector(
  [
    (state) => state.testSuites.items,
    (state) => state.testSuites.sortKey,
    (state) => state.testSuites.sortReverse,
  ],
  (testSuites = [], key, reverse = false) => {
    let sortKeys;
    if (key) {
      sortKeys = [key];
    }
    const sorted = sortBy(testSuites, sortKeys);

    if (reverse) {
      return sorted.reverse();
    }
    return sorted;
  }
);

export const getAndroidTestSuitesByTsid = createSelector(
  [getSortedTestSuites],
  (testSuites = []) => {
    const testSuitesWithEmptyValue = [].concat(
      { name: "", tsid: "", platform: "android" },
      testSuites
    );
    return testSuitesWithEmptyValue
      .filter((item) => item.platform == "android")
      .map((test) => ({ name: test.name, tsid: test.tsid }))
      .reduce(
        (acc, test) => ({
          ...acc,
          [test.tsid]: { ...test },
        }),
        {}
      );
  }
);

export const getIosTestSuitesByTsid = createSelector([getSortedTestSuites], (testSuites = []) => {
  const testSuitesWithEmptyValue = [].concat({ name: "", tsid: "", platform: "ios" }, testSuites);
  return testSuitesWithEmptyValue
    .filter((item) => item.platform == "ios")
    .map((test) => ({ name: test.name, tsid: test.tsid }))
    .reduce(
      (acc, test) => ({
        ...acc,
        [test.tsid]: { ...test },
      }),
      {}
    );
});

export const getTestSuitesNames = createSelector([getSortedTestSuites], (testSuites = []) =>
  testSuites.map((testSuite) => testSuite.name)
);
