import React, { ReactElement, useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, message, notification, Tabs } from "antd";
import { format, parseISO } from "date-fns";
import { createSearchParams, useNavigate } from "react-router-dom-v5-compat";
import { pick } from "lodash";

import { Page } from "~/global";
import { ExportedReport } from "~/actions/exportedReports.actions";
import {
  fetchRecordingFacets,
  selectOptions,
} from "~/features/recording-facets/reportingFilters.slice";
import { errorCleared as chartErrorCleared } from "~/features/reporting-chart/reportingChart.slice";
import { useTranslation } from "~/i18n";
import ExportedReports from "~/reporting/ExportedReports/ExportedReports.component";
import { ApiError } from "~/services";
import { errorCleared as processRunsErrorCleared } from "~/entities/process-runs/model/processRunsSlice";
import { REPORTING_SEARCH_PARAMS_VERSION } from "./model/constants";
import {
  createScheduledReport,
  fetchScheduledReports,
  ScheduledReport,
  updateScheduledReport,
} from "./model/scheduledReportsSlice";
import { ReportingFiltersAndChart } from "./ReportingFiltersAndChart";
import { ReportSchedulerModal } from "./ui/ReportSchedulerModal";
import { ScheduledReports } from "./ui/ScheduledReports";

import { useAppDispatch, useAppSelector } from "~/hooks";
import actions from "~/actions";
import selectors from "~/selectors";

import "./ReportingPage.scss";
import { ReportType } from "~/actions/reportTypes.actions";

export const ReportingPage = (): ReactElement => {
  const { t } = useTranslation("reporting");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const facilities = useAppSelector(selectors.facilities.getFacilitiesAllOption);
  const facilitiesById = useAppSelector(selectors.facilities.getFacilitiesByIdAllOption);
  const areFacilitiesLoaded = useAppSelector((state) => state.facilities.items == null);
  const reportTypes = useAppSelector((state) => state.reportingModule.reportTypes.items);
  const reportTypesByName = useAppSelector(selectors.reports.selectReportTypesByName);
  // TODO: rename selectOptions to something more explicit
  const facetOptions = useAppSelector(selectOptions);
  const processRunsError = useAppSelector((state) => state.reportingModule.processRuns.error);
  const chartError = useAppSelector((state) => state.reportingModule.chart.error);

  const [modalType, setModalType] = useState<"create" | "update">();
  const [exportedReportId, setExportedReportId] = useState<string>();
  const [scheduledReport, setScheduledReport] = useState<ScheduledReport>();
  const [reportTypesState, setReportTypesState] = useState<ReportType[] | undefined>(undefined);
  useEffect(() => {
    void dispatch(actions.facilities.fetchFacilities());
    void dispatch(actions.reportTypes.fetchReportTypes());
    void dispatch(actions.exportedReports.fetchExportedReports());
    void dispatch(fetchScheduledReports());
    void dispatch(fetchRecordingFacets());
  }, [dispatch]);

  useEffect(
    () => {
      type Error = {
        error: ApiError;
        key?: string;
        onClose?: () => void;
      };

      // TODO: translate message and errors (backend - GLOB-3301)
      const showErrorNotification = ({ error, key, onClose }: Error) => {
        // This is necessary because the error message returned by the back-end is not properly formatted (ie, sentence case)
        const errMsg = `The server ${error.message ?? "returned an error."}`;

        notification.error({
          key,
          onClose,
          message: t("chart.error-message"),
          duration: 0,
          description: (
            <>
              {/* Overwrite react-md font-size */}
              <p style={{ fontSize: 14 }}>{errMsg}</p>

              {error.errors && (
                <ul style={{ listStyleType: "initial", paddingLeft: 16 }}>
                  {Object.entries(error.errors).map(([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  ))}
                </ul>
              )}
            </>
          ),
        });
      };

      if (processRunsError) {
        showErrorNotification({
          error: processRunsError,
          key: "historyError",
          onClose: () => {
            void dispatch(processRunsErrorCleared());
          },
        });
      }

      if (chartError) {
        showErrorNotification({
          error: chartError,
          key: "chartError",
          onClose: () => {
            void dispatch(chartErrorCleared());
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Including the t function creates a new notification when changing the language
    [processRunsError, chartError]
  );

  const applyFilters = (
    { dateFrom, dateTo, dateRange, type, uniqueness, faid, filters, columns }: ExportedReport,
    triggerSearch = false
  ) => {
    const facility = facilitiesById[faid ?? "all"] ?? facilitiesById["all"]; // eslint-disable-line @typescript-eslint/dot-notation
    const facetFilters = Object.fromEntries(
      filters?.map((facet) => [`facet.${facet.field}`, facet.value]) ?? []
    );

    const formattedDateFrom = format(parseISO(dateFrom), "yyyy-MM-dd'T'HH:mm:ss");
    const formattedDateTo = format(parseISO(dateTo), "yyyy-MM-dd'T'HH:mm:ss");

    // Check if dateFrom and dateTo are not today
    const today = format(new Date(), "yyyy-MM-dd");
    if (
      dateRange === "today" &&
      (formattedDateFrom.slice(0, 10) !== today || formattedDateTo.slice(0, 10) !== today)
    ) {
      dateRange = "custom";
    }

    const searchParams = new URLSearchParams({
      ...facetFilters,
      notClear: "true",
      facilityId: facility.faid,
      timeZone: facility.timezone,
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      dateRange: dateRange ?? "",
      uniqueness,
      reportTypeName: type,
      v: REPORTING_SEARCH_PARAMS_VERSION,
      columns: columns?.join(",") ?? "",
    });

    reportTypes?.forEach((reportType) => {
      if (reportType.name === type) {
        reportType.exportColumns = reportType.exportColumns.map((exportColumn) => ({
          ...exportColumn,
          isDefault: columns?.includes(exportColumn.value) ?? false,
        }));
      }
    });

    setReportTypesState(reportTypes);

    navigate(
      {
        pathname: "/reporting",
        search: searchParams.toString(),
      },
      { state: { triggerSearch } }
    );
  };
  // TODO: show a spinner while fetching
  return (
    <Page className="reporting-page">
      {!areFacilitiesLoaded && reportTypes && facetOptions && (
        <ReportingFiltersAndChart
          facilities={facilities}
          reportTypes={reportTypesState ?? reportTypes}
          reportTypesByName={reportTypesByName}
          facetOptions={facetOptions}
        />
      )}

      <Tabs
        tabBarExtraContent={
          <Button
            title={t("refresh-reports")}
            type="text"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={() => {
              void dispatch(actions.exportedReports.fetchExportedReports());
              void dispatch(fetchScheduledReports());
            }}
            size="large"
          />
        }
        items={[
          {
            label: t("exported-reports"),
            key: "exported-reports",
            children: (
              <ExportedReports
                reportTypesByName={reportTypesByName}
                applyFilters={applyFilters}
                onScheduleReport={(id) => {
                  setExportedReportId(id);
                  setModalType("create");
                }}
              />
            ),
          },
          {
            label: t("scheduled-reports"),
            key: "scheduled-reports",
            children: (
              <ScheduledReports
                reportTypesByName={reportTypesByName}
                applyFilters={applyFilters}
                onEdit={(scheduledReport) => {
                  setScheduledReport(scheduledReport);
                  setModalType("update");
                }}
              />
            ),
          },
        ]}
      />

      {exportedReportId != null && (
        <ReportSchedulerModal
          open={modalType === "create"}
          onClose={() => setModalType(undefined)}
          onFinish={(report) => {
            dispatch(createScheduledReport({ report, exportedReportId }))
              .unwrap()
              .then(() => {
                void message.success(t("create-success"));
                setModalType(undefined);
              })
              .catch((rejectedValue: string) => {
                void message.error(rejectedValue);
              });
          }}
          initialValues={{
            repeat: {
              every: "week",
              on: ["sunday"],
            },
            runAt: { hour: 23, minute: 59 },
          }}
        />
      )}

      {scheduledReport && (
        <ReportSchedulerModal
          open={modalType === "update"}
          onClose={() => {
            setModalType(undefined);
          }}
          onFinish={(report) => {
            dispatch(updateScheduledReport({ report, id: scheduledReport.id }))
              .unwrap()
              .then(() => {
                void message.success(t("update-success"));
                setModalType(undefined);
              })
              .catch((rejectedValue: string) => {
                void message.error(rejectedValue);
              });
          }}
          key={scheduledReport.id}
          initialValues={pick(scheduledReport, ["repeat", "runAt", "displayName"])}
        />
      )}
    </Page>
  );
};
