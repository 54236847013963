import { createReducer } from "@reduxjs/toolkit";

import labelActions from "~/label-printer/actions";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

export default createReducer(initialState, {
  [labelActions.labelStatics.fetchLabelStaticsBegin]: (state, action) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [labelActions.labelStatics.fetchLabelStaticsSuccess]: (state, action) => {
    state.loaded = true;
    state.loading = false;
  },
});
