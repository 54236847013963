import keys from "lodash/keys";

import { ConsumerManagement, PDFManagement } from "~/services";
import { RemoteDiagnosticsSession } from "~/models";

export const fetchSessions =
  (license = "", filters) =>
  async (dispatch) => {
    if (license === "") {
      throw new Error("license required");
    }

    const queryFilters = [`license: "${license}"`];
    keys(filters).forEach((key) => {
      const value = filters[key];
      if (value != null && value !== "") {
        queryFilters.push(`${key}: "${value}"`);
      }
    });

    const query = ConsumerManagement.gqlBuilder(`{
        sessions(${queryFilters.join(", ")}) {
            sessionId,
            createdOn,
            platform,
            firstName,
            lastName,
            cellphone,
            email,
            model,
            status,
            imei
        }
    }`);

    const response = await ConsumerManagement.POST("/gql", { query });

    if (!response.payload || response.payload.data == null) {
      throw Error((response.payload.errors || []).map((err) => `${JSON.stringify(err)}\n`));
    }

    return (response.payload.data.sessions || []).map((session) =>
      RemoteDiagnosticsSession.parse(session)
    );
  };

export async function fetchSession(license = "", sessionId) {
  if (sessionId == "") {
    throw Error("Can't get session details without a session id.");
  }

  try {
    const query = ConsumerManagement.gqlBuilder(`{
            session(license: "${license}", sessionId: "${sessionId}") {
                sessionId,
                firstName,
                lastName,
                cellphone,
                email,
                model,
                status,
                platform,
                imei,
                results {
                    testResults {
                        className,
                        identifier,
                        name,
                        result,
                        image
                    }
                }
            }
        }`);

    const response = await ConsumerManagement.POST("/gql", { query });
    return RemoteDiagnosticsSession.parse(response.payload.data.session);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function closeSession(sessionId = "") {
  if (sessionId == "") {
    console.warn("invalid session id:", sessionId);
    return;
  }

  return ConsumerManagement.DELETE(`/session/${sessionId}`);
}

export async function generatePDF(sessionId = "") {
  if (sessionId == "") {
    console.warn("Can't get generate PDF without a session id.");
    return;
  }

  const response = await PDFManagement.GET(`/pdf/${sessionId}`);
  return response.payload.pdfUrl;
}

export async function createSession(license, data) {
  if (data.tests.length === 0) {
    throw new Error("At least one test is required when creating a session");
  }

  const body = {
    pdfTemplate: "testeditor",
    ...data,
  };

  const response = await ConsumerManagement.POST(`/sessions/${license}`, body);
  return response.payload.sessionId;
}

//
// Templates
//
export async function createTemplate(license, platform, name, identifiers) {
  try {
    return await ConsumerManagement.POST(`/templates/${license}`, {
      platform,
      name,
      identifiers,
    });
  } catch (e) {
    if (e instanceof Error) {
      throw e;
    }

    let errors = {};

    if (e.response.status == 500) {
      errors.server = `Server error: ${e.json.message}`;
    } else if (e.response.status == 400) {
      errors = e.json.payload;
    }

    throw errors;
  }
}

export async function fetchTemplates(license) {
  const response = await ConsumerManagement.GET(`/templates/${license}`);
  return response.payload;
}

export async function deleteTemplate(license, name) {
  await ConsumerManagement.DELETE(`/templates/${license}/${name}`);
}

export const downloadCSV = (license) => async (dispatch) => {
  const url = `/sessions/${license}`;
  const fileType = "text/csv";
  const fileName = "remote-diagnostics-sessions.csv";

  await ConsumerManagement.downloadFile(url, fileType, fileName, false);
};
