import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextField, Switch, FontIcon } from "react-md";
import isEqual from "lodash/isEqual";

import { DeviceTestDefinition } from "~/models";
import actions from "~/actions";
import { DialogMessage, ContentPanel, AsciButton, Checkbox, Row, Column } from "~/global";
import { capitalizeFirstLetter } from "~/global/utils";
import AddInclusionModelDialog from "./AddInclusionModelDialog.component";

import "./TestDefinitionInclusionEditor.component.scss";

class TestDefinitionInclusionEditor extends Component {
  static propTypes = {
    inclusionType: PropTypes.oneOf([
      DeviceTestDefinition.INCLUSION_TYPE_INCLUDE,
      DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE,
    ]),
    targetModels: PropTypes.arrayOf(PropTypes.string),
    identifier: PropTypes.string, // used to detect change and reset include all
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
  };

  static defaultProps = {
    targetModels: [],
    inclusionType: DeviceTestDefinition.INCLUSION_TYPE_INCLUDE,
    errors: {},
  };

  descriptions = {
    "toggle-all": {
      title: "Include all Models",
      content: "This test will only target or exclude phone models listed bellow.",
    },
    [`toggle-type-${DeviceTestDefinition.INCLUSION_TYPE_INCLUDE}`]: {
      title: "Include",
      content:
        "This test will ONLY include the phones in the 'Include' list. All other phones are excluded.",
    },
    [`toggle-type-${DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE}`]: {
      title: "Exclude",
      content:
        "This test will ONLY exclude the phones in the 'Exclude' list. All other phones are included.",
    },
  };

  state = {
    includeAll: undefined,
    selectedModels: [],
  };

  componentDidMount() {
    this.setState({
      includeAll: this.props.targetModels.length === 0,
    });
  }

  componentDidUpdate(prevProps) {
    const modelsChanged = !isEqual(prevProps.targetModels, this.props.targetModels);
    const identifierChanged = prevProps.identifier !== this.props.identifier;
    const isFirstRender = this.state.includeAll == null;

    // remove all selections when the test config changes
    if (isFirstRender || identifierChanged || modelsChanged) {
      this.setState({
        selectedModels: [],
        includeAll: this.props.targetModels.length === 0,
      });
    }
  }

  onToggleIncludeAll = async () => {
    const type = this.state.includeAll
      ? DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE
      : DeviceTestDefinition.INCLUSION_TYPE_INCLUDE;

    // for this component, just toggle the switch
    this.setState({ includeAll: type === DeviceTestDefinition.INCLUSION_TYPE_INCLUDE }, () => {
      // for the actual value of the type, set to include and empty models by default
      this.props.onChange(DeviceTestDefinition.INCLUSION_TYPE_INCLUDE, []);
    });
  };

  toggle = (type) =>
    type === DeviceTestDefinition.INCLUSION_TYPE_INCLUDE
      ? DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE
      : DeviceTestDefinition.INCLUSION_TYPE_INCLUDE;

  onToggleInclusionType = () => {
    const type = this.toggle(this.props.inclusionType);

    this.props.onChange(type, this.props.targetModels);
  };

  showDescription = (key) => {
    const desc = this.descriptions[key];
    if (desc == null) {
      throw Error(`undefined description key "${key}"`);
    }

    this.props.showDialog({
      title: desc.title,
      content: (close) => <DialogMessage close={close}>{desc.content}</DialogMessage>,
    });
  };

  areAllModelsSelected = () => {
    if (this.props.targetModels.length === 0) {
      return false;
    }

    return this.props.targetModels.reduce((acc, model) => this.isModelChecked(model) && acc, true);
  };

  onToggleAll = () => {
    let models;
    if (this.props.targetModels.length === this.state.selectedModels.length) {
      models = [];
    } else {
      models = [...this.props.targetModels];
    }
    this.setState({ selectedModels: models });
  };

  isModelChecked = (model) => this.state.selectedModels.includes(model);

  onToggleModel = (model) => {
    const index = this.state.selectedModels.indexOf(model);

    const models = [...this.state.selectedModels];
    if (index > -1) {
      models.splice(index, 1);
    } else {
      models.push(model);
    }

    this.setState({ selectedModels: models });
  };

  onAddModel = () => {
    this.props.showDialog({
      title: "Add model",
      width: "30%",
      content: (close) => (
        <AddInclusionModelDialog
          isModelValid={(model) => !this.props.targetModels.includes(model) && model.length > 0}
          onCancel={close}
          onConfirm={(name) => {
            const models = [...this.props.targetModels, name];
            this.props.onChange(this.props.inclusionType, models);
            close();
          }}
        />
      ),
    });
  };

  onDeleteSelectedModels = () => {
    const models = [...this.props.targetModels];

    this.state.selectedModels.forEach((m) => {
      const index = models.indexOf(m);
      models.splice(index, 1);
    });

    if (
      models.length === 0 &&
      this.props.inclusionType === DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE
    ) {
      // inclusionType should be "include" when there are no targetModels or the back-end will break
      this.props.onChange(DeviceTestDefinition.INCLUSION_TYPE_INCLUDE, models);
    } else {
      this.props.onChange(this.props.inclusionType, models);
    }
  };

  render() {
    return (
      <div className="test-definition-inclusion-editor">
        <div className="include-all layout-row layout-row--space-between-center">
          <Switch
            id="include-all-switch"
            name="include-all-switch"
            type="switch"
            checked={this.state.includeAll}
            onChange={this.onToggleIncludeAll}
            label="Include all Models:"
            labelBefore
          />

          <FontIcon
            iconClassName="icon-info-circle"
            onClick={() => this.showDescription("toggle-all")}
          />
        </div>

        {!this.state.includeAll ? (
          <div className="target margin-top--10">
            <div className="type layout-row layout-row--space-between-center">
              <Row className="include-exclude" align="start center">
                <span>Exclude</span>

                <Switch
                  id="include-type-switch"
                  name="include-type-switch"
                  type="switch"
                  checked={this.props.inclusionType === DeviceTestDefinition.INCLUSION_TYPE_INCLUDE}
                  onChange={this.onToggleInclusionType}
                />

                <span>Include</span>
              </Row>

              <FontIcon
                iconClassName="icon-info-circle"
                onClick={() => this.showDescription(`toggle-type-${this.props.inclusionType}`)}
              />
            </div>

            <ContentPanel className="margin-top--5">
              <header className="layout-row layout-row--start-center">
                <div className="col-checkbox layout-column layout-column--center">
                  {this.props.targetModels.length > 0 ? (
                    <Checkbox
                      id="checkbox-select-all"
                      noVerticalPadding
                      isChecked={this.areAllModelsSelected()}
                      onChange={this.onToggleAll}
                      disabled={this.props.targetModels.length === 0}
                    />
                  ) : null}
                </div>

                <div className="model-name">Model</div>
              </header>

              <main className="padding-v--5 padding-h--10">
                {this.props.targetModels.length === 0 ? (
                  <div className="layout-column layout-column--center-center padding--10">
                    No models
                  </div>
                ) : null}

                {this.props.targetModels.map((model, index) => (
                  <div key={index} className="model layout-row layout-row--start-center">
                    <div className="col-checkbox layout-column layout-column--center">
                      <Checkbox
                        id={`checkbox-select-model-${index}`}
                        isChecked={this.isModelChecked(model)}
                        onChange={() => this.onToggleModel(model)}
                      />
                    </div>

                    <div className="col-model">{model}</div>
                  </div>
                ))}
              </main>

              <footer className="layout-row layout-row--end-center">
                {this.props.errors.models ? (
                  <div className="error-message layout-column layout-column--center-center">
                    {this.props.errors.models}
                  </div>
                ) : null}

                <AsciButton
                  color="white"
                  disabled={this.state.selectedModels.length === 0}
                  onClick={this.onDeleteSelectedModels}
                >
                  Delete
                </AsciButton>

                <AsciButton color="blue" onClick={this.onAddModel}>
                  Add
                </AsciButton>
              </footer>
            </ContentPanel>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  showDialog: actions.dialog.show,
};

export default connect(undefined, mapDispatchToProps)(TestDefinitionInclusionEditor);
