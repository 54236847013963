import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [actions.customFields.fetchCustomFieldsBegin]: (state) => {
    state.isLoading = true;
  },
  [actions.customFields.fetchCustomFieldsSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.customFields;
    state.errors = null;
  },
  [actions.customFields.fetchCustomFieldsError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [actions.customFields.fetchCustomFieldsNameBegin]: (state) => {
    state.isLoading = true;
  },
  [actions.customFields.fetchCustomFieldsNameSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.customFields;
    state.errors = null;
  },
  [actions.customFields.fetchCustomFieldsNameError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [actions.customFields.deleteCustomFieldBegin]: (state) => {
    state.isLoading = true;
  },
  [actions.customFields.deleteCustomFieldSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.customFields;
    state.errors = null;
  },
  [actions.customFields.deleteCustomFieldError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [actions.customFields.onCustomFieldAdded]: (state, action) => {
    const newItems = state.items.concat(action.payload.customField);
    state.items = newItems;
  },
});
