import last from "lodash/last";

import * as labelDesigner from "./labelDesigner.selectors";
import * as labelSaver from "./labelSaver.selectors";
import * as labelDeleter from "./labelDeleter.selectors";
import * as labelLister from "./labelLister.selectors";

import * as labelPicker from "./labelPicker.selectors";
import * as labelFonts from "./labelFonts.selectors";
import * as labelPrint from "./labelPrint.selectors";
import * as labelFormats from "./labelFormats.selectors";
import * as labelFields from "./labelFields.selectors";
import * as labelStatics from "./labelStatics.selectors";

const toIdRegex = /ObjectI[dD]\("([\d\w-]*)"\)/;
const objectIdToString = (objectId) => last(toIdRegex.exec(objectId));

export default {
  labelDesigner,
  labelSaver,
  labelLister,
  labelDeleter,
  labelPicker,
  labelFonts,
  labelPrint,
  labelFormats,
  labelFields,
  labelStatics,
  objectIdToString,
};
