import concat from "lodash/concat";
import findIndex from "lodash/findIndex";
import setByPath from "lodash/set";
import merge from "lodash/merge";

import { createReducer } from "@reduxjs/toolkit";

import labelActions from "../actions";
import * as layouts from "~/label-printer/layouts";
import * as fields from "~/label-printer/fields";

let nShape = 0;

const createShape = (fieldType, shapeData, layoutData, identity) => ({
  fieldType,
  fieldData: fields.getFieldDefault(fieldType),
  layoutData: {
    width: null,
    height: null,
    x: null,
    y: null,
    row: 0,
    column: 0,
    columnWidth: null,
    columnSpan: null,
    ...layoutData,
  },
  shapeData: {
    style: {
      padding: 0,
      margin: 0,
      background: null,
      border: {
        side: null,
        style: "solid",
        width: 0,
        color: "#000",
        radius: 0,
      },
      font: {
        textAlign: "",
        fontSize: null,
        fontWeight: "",
        fontFamily: "",
      },
      alignment: {
        horizontal: null,
        vertical: null,
        orientation: null,
        direction: null,
      },
    },
    ...shapeData,
  },
  // Get the shape from anywhere with ``find(shapes, {identity: 202})``
  identity,
});

const initialState = (layoutName) => ({
  name: "Label",
  printer: "custom",

  format: {
    size: {
      width: 89,
      height: 28,
    },
    name: "custom",
    printSettings: {
      landscape: true,
      printBackground: true,
      color: true,
      margins: {
        marginType: "none",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      dpi: {
        horizontal: 300,
        vertical: 300,
      },
    },
  },

  style: {
    background: "#fff",
    padding: 0,
    margin: 0,
    border: {
      side: "all",
      style: "solid",
      width: 0,
      color: "#000",
      radius: 5, // Label most likely have round corners.
    },
    font: {
      textAlign: "",
      fontSize: 8,
      fontWeight: "",
      fontFamily: "",
      color: "#000",
    },
  },

  layoutName,
  layoutData: layouts.getDefaultLayoutData(layoutName),

  shapes: [],
});

const getShapeIndex = (shapes, identity) =>
  findIndex(shapes, (shape) => shape.identity === identity);

export default createReducer(initialState(layouts.GRID), {
  [labelActions.labelDesigner.setLabelName]: (state, action) => {
    state.name = action.payload;
  },
  [labelActions.labelDesigner.setLabelDescription]: (state, action) => {
    state.description = action.payload;
  },
  [labelActions.labelDesigner.setFormatData]: (state, action) => {
    const { key, data } = action.payload;
    setByPath(state.format, key, data);
  },
  [labelActions.labelDesigner.setLayoutName]: (state, action) => {
    const { layoutName } = action.payload;
    state.layoutName = layoutName;
    state.layoutData = layouts.getDefaultLayoutData(layoutName);
  },
  [labelActions.labelDesigner.setLayoutData]: (state, action) => {
    const { key, data } = action.payload;
    setByPath(state.layoutData, key, data);
  },
  [labelActions.labelDesigner.addShape]: (state, action) => {
    const { fieldType, shapeData, layoutData } = action.payload;
    const shape = createShape(fieldType || fields.TEXT, shapeData, layoutData, nShape);
    nShape++;
    state.shapes = concat(state.shapes, [shape]);
  },
  [labelActions.labelDesigner.removeShape]: (state, action) => {
    const { identity } = action.payload;
    state.shapes = state.shapes.filter((shape) => shape.identity !== identity);
  },
  [labelActions.labelDesigner.setShapeData]: (state, action) => {
    const { identity, key, data } = action.payload;
    const shapeIndex = getShapeIndex(state.shapes, identity);
    setByPath(state.shapes[shapeIndex].shapeData, key, data);
  },
  [labelActions.labelDesigner.setShapeFieldType]: (state, action) => {
    const { identity, fieldType } = action.payload;
    const shapeIndex = getShapeIndex(state.shapes, identity);
    state.shapes[shapeIndex].fieldType = fieldType;
    state.shapes[shapeIndex].fieldData = fields.getFieldDefault(fieldType);
  },
  [labelActions.labelDesigner.setShapeFieldData]: (state, action) => {
    const { identity, key, data } = action.payload;
    const shapeIndex = getShapeIndex(state.shapes, identity);
    setByPath(state.shapes[shapeIndex].fieldData, key, data);
  },
  [labelActions.labelDesigner.setLabelStyle]: (state, action) => {
    const { key, data } = action.payload;
    setByPath(state.style, key, data);
  },
  [labelActions.labelDesigner.createNewLabel]: (state, action) => {
    const { layoutName } = action.payload;
    return merge(initialState(layoutName), action.payload);
  },
  [labelActions.labelSaver.labelLoadSuccess]: (state, action) => {
    const maxIdentity = action.payload.shapes
      .map((s) => s.identity)
      .reduce((p, n) => (p > n ? p : n));
    nShape = maxIdentity + 1;
    return { ...action.payload, nShape };
  },
  [labelActions.labelDesigner.setShapeLayoutData]: (state, action) => {
    const { identity, key, data } = action.payload;
    const shapeIndex = getShapeIndex(state.shapes, identity);
    setByPath(state.shapes[shapeIndex].layoutData, key, data);
  },
  [labelActions.labelDesigner.setRowHeight]: (state, action) => {
    const { rowIndex, height } = action.payload;
    state.layoutData.rowHeights[rowIndex] = height;
  },
  [labelActions.labelDesigner.setPrinter]: (state, action) => {
    state.printer = action.payload;
  },
});
