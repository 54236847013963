import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const WorkstationManagementIcon = ({ size, customId }) => (
  <SVGIcon title="Workstation Management" viewBox="0 0 35 25.73" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x2="16.03"
        y1="7.51"
        y2="7.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.2" stopColor="#96c934" />
        <stop offset="0.36" stopColor="#8cbf37" />
        <stop offset="0.51" stopColor="#7aad3d" />
        <stop offset="0.65" stopColor="#629545" />
        <stop offset="0.78" stopColor="#437650" />
        <stop offset="0.91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x1="17.81"
        x2="33.84"
        y1="7.51"
        y2="7.51"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-c`}
        x1="8.91"
        x2="24.93"
        y1="24.38"
        y2="24.38"
        xlinkHref={`#${customId}-a`}
      />
    </defs>
    <g>
      <path
        fill={`url(#${customId}-a)`}
        d="M11.74 14.54a1 1 0 00-.1-1c-.05-.07-.14-.17-.69-.75-.19-.2-.41-.42-.61-.64h4.33a1.38 1.38 0 00.34 0 1.27 1.27 0 001-1.32v-9.5A1.28 1.28 0 0014.68 0H1.34A1.28 1.28 0 000 1.34v9.39a1.27 1.27 0 001.37 1.37h4.37c-.09.09-.18.17-.26.26-.69.68-1 1-1 1.06a1.11 1.11 0 00-.17 1.12.91.91 0 00.84.49h5.78a.94.94 0 00.81-.49zM1 10.73V1.34c0-.24.1-.34.34-.34h13.34c.25 0 .35.09.35.33v9.42c0 .3-.15.33-.23.35H1.37c-.29 0-.37-.1-.37-.37zm5.18 2.34l1-1H9c.29.33.81.87 1.22 1.31a2.85 2.85 0 00.67.6H5.23c.08-.05.56-.52.95-.91z"
      />
      <path
        fill={`url(#${customId}-b)`}
        d="M32.5 0H19.16a1.28 1.28 0 00-1.35 1.34v9.39a1.27 1.27 0 001.37 1.37h4.37l-.26.26c-.69.68-1 1-1 1.06a1.09 1.09 0 00-.16 1.12.89.89 0 00.83.49h5.74a.93.93 0 00.86-.49 1 1 0 00-.11-1 6.62 6.62 0 00-.69-.75l-.6-.64h4.32a1.42 1.42 0 00.34 0 1.27 1.27 0 001-1.32v-9.5A1.27 1.27 0 0032.5 0zm-5.68 12.12c.29.33.8.87 1.22 1.31a3.1 3.1 0 00.66.6h-5.65c.07-.1.56-.57.94-1s.73-.72 1-1h1.81s.01.09.02.09zm6-1.37c0 .3-.15.33-.23.35H19.18c-.29 0-.37-.07-.37-.37V1.34c0-.24.1-.34.35-.34H32.5c.24 0 .34.09.34.33z"
      />
      <path
        fill={`url(#${customId}-c)`}
        d="M23.59 16.87H10.25a1.28 1.28 0 00-1.34 1.34v9.39a1.27 1.27 0 001.37 1.4h4.36l-.26.26c-.68.68-1 .95-1 1.06a1.07 1.07 0 00-.17 1.11.9.9 0 00.84.5h5.75a.94.94 0 00.85-.5 1 1 0 00-.1-1c-.05-.07-.14-.17-.69-.75-.19-.2-.41-.42-.61-.64h4.33a1.39 1.39 0 00.33 0 1.27 1.27 0 001-1.32v-9.53a1.27 1.27 0 00-1.32-1.32zM17.91 29c.29.33.81.87 1.22 1.31a2.62 2.62 0 00.67.6h-5.66c.08-.1.56-.58.95-1s.73-.72 1-1h1.81s0 .09.01.09zm6-1.37c0 .3-.15.33-.23.34h-13.4c-.3 0-.37-.08-.37-.37v-9.39c0-.24.1-.34.34-.34h13.34c.25 0 .34.09.34.32z"
      />
    </g>
  </SVGIcon>
);

WorkstationManagementIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default WorkstationManagementIcon;
