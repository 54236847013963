import React, { useState, useEffect, useRef } from "react";
import { TextField } from "react-md";
import PropTypes from "prop-types";

const CustomFieldNumericTypeEditor = (props) => (
  // TODO: remove this once I'm sure it's useless
  /* useEffect(() => {
        if (props.isMinNumberValid) {
            props.onChange({ min: +props.customField.min });
        }
    }, [props.customField.min]);

    useEffect(() => {
        if (props.isMaxNumberValid) {
            props.onChange({ max: +props.customField.max });
        }
    }, [props.customField.max]);

    useEffect(() => {
        if (props.isDefaultNumberValid) {
            props.onChange({ defaultNumberValue: +props.customField.defaultNumberValue });
        }
    }, [props.customField.defaultNumberValue]); */

  <div className="margin--5">
    <div className="layout-row layout-row--start-center">
      <div className="flex--30 flex--nogrow bold">Default Value</div>

      <div className="flex--30 flex--nogrow">
        <TextField
          id="custom-field-numeric-default"
          placeholder="Default Value"
          value={props.customField.defaultNumberValue}
          min={0}
          onChange={(value) => props.onChange({ defaultNumberValue: +value })}
          type="number"
          error={!props.isDefaultNumberValid}
          errorText="Default Value must be a number between Min and Max Values"
        />
      </div>
    </div>
    <div className="layout-row layout-row--start-center">
      <div className="flex--30 flex--nogrow bold">Minimum Value</div>

      <div className="flex--30 flex--nogrow">
        <TextField
          id="custom-field-numeric-min"
          placeholder="Min"
          value={props.customField.min}
          min={0}
          onChange={(value) => props.onChange({ min: +value })}
          type="number"
          error={!props.isMinNumberValid}
          errorText="Min Value must be a number below or equal to the Max Value"
        />
      </div>
    </div>
    <div className="layout-row layout-row--start-center">
      <div className="flex--30 flex--nogrow bold">Max Value</div>

      <div className="flex--30 flex--nogrow">
        <TextField
          id="custom-field-numeric-max"
          placeholder="Max"
          value={props.customField.max}
          min={0}
          onChange={(value) => props.onChange({ max: +value })}
          type="number"
          error={!props.isMaxNumberValid}
          errorText="Max Value must be a number above or equal to the Min Value"
        />
      </div>
    </div>
  </div>
);
CustomFieldNumericTypeEditor.propTypes = {
  isMinNumberValid: PropTypes.any,
  isMaxNumberValid: PropTypes.any,
  isDefaultNumberValid: PropTypes.any,
  customField: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

CustomFieldNumericTypeEditor.defaultProps = {
  customField: {},
};

export default CustomFieldNumericTypeEditor;
