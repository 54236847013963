import React from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { omit } from "lodash";

import { Descriptor } from "~/entities/model-descriptors/model/descriptorTypes";
import { createModelDescriptor } from "~/entities/model-descriptors/model/modelDescriptorsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";

type Props = {
  open: boolean;
  modelDescriptor: Descriptor;
  onClose: () => void;
  onSuccess: (descriptor: Descriptor) => void;
};

type FormValues = {
  commercialName: string;
  manufacturer: string;
  modelNumber: string;
};

export const DuplicateDescriptorModal = ({ open, modelDescriptor, onClose, onSuccess }: Props) => {
  const { t } = useTranslation("modelDescriptors");
  const dispatch = useAppDispatch();

  const isDescriptorUpdating = useAppSelector(
    (state) => state.modelDescriptors.formStatus === "loading"
  );

  return (
    <Modal
      className="model-descriptors-modal"
      title={t("duplicate-descriptor")}
      open={open}
      footer={false}
      onCancel={() => {
        onClose();
      }}
      width={960}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          commercialName: modelDescriptor.modelInfo.commercialName,
          manufacturer: modelDescriptor.modelInfo.manufacturer,
          modelNumber: modelDescriptor.modelInfo.modelNumbers[0]?.modelNumber,
        }}
        onFinish={({ commercialName, manufacturer, modelNumber }: FormValues) => {
          const { modelInfo } = modelDescriptor;
          modelInfo.commercialName = commercialName;
          modelInfo.manufacturer = manufacturer;
          modelInfo.modelNumbers = [{ carrier: "", modelNumber, modelSupport: "" }];

          void dispatch(
            createModelDescriptor({
              ...omit(modelDescriptor, "id"),
              ...modelInfo,
            })
          )
            .unwrap()
            .then((response) => {
              void message.success(t("create-success"));
              onSuccess(response);
            })
            .catch((rejectedValue: string) => {
              void message.error(rejectedValue);
            });
        }}
      >
        <Form.Item
          label={t("commercial-name")}
          name="commercialName"
          rules={[{ required: true, message: t("required-field") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("manufacturer")}
          name="manufacturer"
          rules={[{ required: true, message: t("required-field") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("model-number")}
          name="modelNumber"
          rules={[{ required: true, message: t("required-field") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Button type="primary" htmlType="submit" loading={isDescriptorUpdating}>
            {t("create")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
