import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { PlatformIcon } from "~/global";

import "./PlatformSelector.component.scss";

const PlatformSelector = ({
  className,
  platforms,
  selected,
  disabled,
  onChange,
  tabIndex,
  layout,
}) => (
  <div
    className={classnames("cc-platform-selector", className, {
      "layout-row": layout === "row",
      "layout-column": layout === "column",
      disabled,
    })}
  >
    {platforms.map((platform) => (
      <PlatformIcon
        key={`platform-${platform.value}`}
        platform={platform.value}
        size="30"
        className={classnames({
          selectable: !disabled,
          selected: platform.value === selected,
        })}
        onClick={() => (disabled ? undefined : onChange(platform.value))}
        tabIndex={tabIndex}
      />
    ))}
  </div>
);

PlatformSelector.propTypes = {
  className: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  layout: PropTypes.oneOf(["row", "column"]),
};

PlatformSelector.defaultProps = {
  className: "",
  disabled: false,
  layout: "row",
};

export default PlatformSelector;
