import React from "react";
import PropTypes from "prop-types";
import { Button, DataTable, TableBody } from "react-md";
import produce from "immer";
import { connect } from "react-redux";

import actions from "~/actions";
import { AsciButton } from "~/global";

import DeploymentShiftRow from "./DeploymentShiftRow.component";

const DeploymentSchedules = ({ shifts, onChange, showDialog }) => {
  const onShiftAdded = () => {
    const newShift = {
      name: "",
      start: { hours: 0, minutes: 0 },
      end: { hours: 0, minutes: 0 },
      isEnabled: true,
    };
    const newShifts = [].concat(shifts, newShift);
    onChange(newShifts);
  };

  const onShiftChanged = (shift, index) => {
    const newShifts = shifts.map((s, i) => {
      const newShift = { ...(i == index ? shift : s) };
      return newShift;
    });
    onChange(newShifts);
  };

  const onDeleteShift = (deletedShift, close) => {
    onChange(shifts.filter((s) => s != deletedShift).map((s) => ({ ...s })));
    close();
  };

  const onShiftDeleted = (deletedShift) => {
    showDialog({
      title: "Delete Shift?",
      content: (close) => (
        <div className="column centered">
          <p className="align-center">Are you sure you want to remove this Shift? </p>
          <p className="align-center">
            If you press Delete, the Shift will be removed from Pro-Diagnostics Desktop.
          </p>
          <p className="align-center">
            <b>Cancel</b> or <b>Delete</b>.
          </p>

          <div className="row centered" style={{ marginTop: "20px" }}>
            <AsciButton color="white" onClick={close}>
              Cancel
            </AsciButton>

            <AsciButton color="red" onClick={() => onDeleteShift(deletedShift, close)}>
              Delete
            </AsciButton>
          </div>
        </div>
      ),
    });
  };

  return (
    <div className="cc-deployment-shifts">
      <div className="white-background row spaced">
        <div className="text-info">
          Create and edit the Shift names you want available for your operators, and enter a start
          and end time: a default Shift is included
        </div>
        <div>
          <Button raised className="asci-btn blue-button" onClick={onShiftAdded}>
            <span>Add Shift</span>
          </Button>
        </div>
      </div>

      <DataTable plain>
        <TableBody className="asci-table">
          {(shifts || []).map((shift, index) => (
            <DeploymentShiftRow
              key={index}
              shift={shift}
              numberOfShifts={shifts.length}
              onChange={(newShift) => onShiftChanged(newShift, index)}
              onDelete={() => onShiftDeleted(shift, index)}
            />
          ))}
        </TableBody>
      </DataTable>
    </div>
  );
};

DeploymentSchedules.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

const dispatchToProps = {
  showDialog: actions.dialog.show,
};

export default connect(null, dispatchToProps)(DeploymentSchedules);
