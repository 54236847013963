import React, { Component } from "react";
import { TablePagination, FontIcon } from "react-md";

import "./AsciTablePagination.component.scss";

export default class AsciPagination extends Component {
  handlePagination = (offset, rowsPerPage, page) => {
    this.props.paginationCallback(offset, page, rowsPerPage);
  };

  render() {
    return (
      <TablePagination
        rows={this.props.dataList.length}
        page={this.props.page}
        rowsPerPage={this.props.rowsPerPage}
        rowsPerPageLabel=""
        onPagination={(offset, rowsPerPage, page) =>
          this.handlePagination(offset, rowsPerPage, page)
        }
        decrementIcon={<FontIcon iconClassName="icon-chevron-left" />}
        incrementIcon={<FontIcon iconClassName="icon-chevron-right" />}
        selectFieldInputClassName="pagination-select"
        rowsPerPageItems={[10, 50, 100]}
        selectFieldClassName="table-select"
        className="table-pagination"
      />
    );
  }
}
