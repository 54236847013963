import { CustomerManagement } from "~/services";
import { VersionManager } from "~/models";

export async function fetchVersions() {
  const query = `{my_customer{softwareVersions{name,url,releaseNotes,selected}}}`;

  try {
    const response = await CustomerManagement.QUERY(query);
    const versions = response.payload?.data?.my_customer?.softwareVersions ?? [];
    return versions.map((version) => new VersionManager(version));
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

export async function updateVersions(selectedVersion) {
  try {
    const response = await CustomerManagement.PUT(`/versions/selected`, selectedVersion);
    if (response.payload != null) {
      return response;
    }
    return response.message;
  } catch (e) {
    console.error(e);
    if (e.response != undefined) {
      return e;
    }
  }
}
