import React from "react";
import PropTypes from "prop-types";

import { AsciButton } from "~/global";

const DialogConfirmation = ({
  children,
  onCancel,
  textButtonCancel,
  onConfirm,
  confirmDisabled,
  textButtonConfirm,
}) => (
  <div>
    <div>{children}</div>

    <div className="dialog-actions layout-row layout-row--center-center">
      <AsciButton color="white" onClick={onCancel}>
        {textButtonCancel}
      </AsciButton>
      <AsciButton color="green" onClick={onConfirm} disabled={confirmDisabled}>
        {textButtonConfirm}
      </AsciButton>
    </div>
  </div>
);

DialogConfirmation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  textButtonCancel: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  confirmDisabled: PropTypes.bool,
};

DialogConfirmation.defaultProps = {
  textButtonCancel: "Cancel",
  textButtonConfirm: "Confirm",
  confirmDisabled: false,
};

export default DialogConfirmation;
