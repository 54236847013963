import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";
import { getApplications } from "~/selectors/applications.selectors";

const getActiveCustomerId = (state) => state.customer.activeCustomerId;
const getCustomersList = (state) => state.customers.items;
const getCurrentAppUrlName = (state) => state.session.currentAppUrlName;
const getPermissions = (state) => state.session.permissions;

export const getActiveCustomer = createSelector(
  [getActiveCustomerId, getCustomersList],
  (activeCustomerId, customers = []) =>
    customers.filter((customer) => customer.csid === activeCustomerId)[0] || {}
);

export const hasPermission = (state, method, path) =>
  createSelector(
    (state) => state.session.permissions,
    (permissions) => {
      if (permissions && permissions[method]) {
        return !!permissions[method][path];
      }
      return false;
    }
  )(state);

export const getCurrentApp = createSelector(
  [getApplications, getCurrentAppUrlName],
  (applications = [], currentAppUrlName = "") => {
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].urlName === currentAppUrlName) {
        return applications[i];
      }
    }
    return {};
  }
);

export const getApplicationsSorted = createSelector([getApplications], (applications = []) => {
  const sortedApplications = sortBy(applications, (application) => {
    const value = application.title;
    if (typeof value === "string") {
      return value.toLowerCase();
    }
    return value;
  });
  return sortedApplications;
});

export const getAvailableApps = createSelector(
  [getApplicationsSorted, getPermissions],
  (applications = [], permissions = {}) =>
    applications.filter((app) => {
      if (!app.permission) {
        console.warn(`permission not defined on app "${app.appName}"`);
        return false;
      }
      if (permissions[app.permission.method] && app.permission.service) {
        return permissions[app.permission.method][app.permission.path] === app.permission.service;
      }
      if (permissions[app.permission.method] && !app.permission.service) {
        return !!permissions[app.permission.method][app.permission.path];
      }
      return false;
    })
);

export const isAppAvailable = (state, appName) =>
  createSelector(
    getAvailableApps,
    (availableApps) => availableApps.filter((app) => app.appName === appName).length > 0
  )(state);
