import React from "react";
import PropTypes from "prop-types";

import "./Thumbnail.component.scss";

const Thumbnail = ({ iconClass, text, image }) => (
  <div className="thumbnail column centered">
    <div className="description-icon ">
      <i className={iconClass} />
    </div>

    <div className="report-name row-wrap centered">
      {typeof text === "object"
        ? text.map((line, index) => (
            <div key={index} className="full-width">
              {line}
            </div>
          ))
        : text}

      {image && <img src={image} alt={image} />}
    </div>
  </div>
);

Thumbnail.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  image: PropTypes.string,
};

Thumbnail.defaultProps = {
  iconClass: "",
  text: "",
};

export default Thumbnail;
