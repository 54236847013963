import React, { useCallback, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Card, Input, message, Popconfirm, Space, Table } from "antd";

import { Descriptor, ModelNumber } from "~/entities/model-descriptors/model/descriptorTypes";
import {
  archiveModelDescriptor,
  modelDescriptorsSelectors,
  searchModelDescriptors,
} from "~/entities/model-descriptors/model/modelDescriptorsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { usePagination } from "~/shared/lib/usePagination";
import { DuplicateDescriptorModal } from "./DuplicateDescriptorModal";
import { UpdateDescriptorModal } from "./UpdateDescriptorModal";
import { ViewDescriptorModal } from "./ViewDescriptorModal";

import "./ModelDescriptorsPage.scss";

export const ModelDescriptorsPage = () => {
  const { t } = useTranslation("modelDescriptors");
  const dispatch = useAppDispatch();

  const { formStatus, listStatus, numOfResults } = useAppSelector(
    (state) => state.modelDescriptors
  );
  const allDescriptors = useAppSelector(modelDescriptorsSelectors.selectAll);

  const [modalType, setModalType] = useState<"view" | "update" | "duplicate">();
  const [modelDescriptor, setModelDescriptor] = useState<Descriptor>();
  const [searchInput, setSearchInput] = useState<string>();
  const [keyword, setKeyword] = useState<string>();

  const { page, pageSize, changePagination } = usePagination(
    useCallback(
      ({ page, pageSize }) => {
        if (formStatus === "idle") {
          void dispatch(searchModelDescriptors({ page, perPage: pageSize, keyword }));
        }
      },
      [dispatch, formStatus, keyword]
    )
  );

  return (
    <div className="panel-container model-descriptors-page">
      <Input
        className="model-descriptors-search"
        placeholder={t("search-placeholder")}
        value={searchInput}
        onChange={(e) => {
          setSearchInput(e.target.value);
        }}
        onPressEnter={() => {
          setKeyword(searchInput);
        }}
        suffix={<SearchOutlined />}
        size="large"
      />

      <Card className="model-descriptors-list" title={t("model-descriptor-list")} bordered={false}>
        <Table
          columns={[
            {
              title: t("commercial-name"),
              dataIndex: ["modelInfo", "commercialName"],
            },
            {
              title: t("manufacturer"),
              dataIndex: ["modelInfo", "manufacturer"],
            },
            {
              title: t("model-number"),
              dataIndex: ["modelInfo", "modelNumbers"],
              render: (modelNumbers: ModelNumber[]) =>
                modelNumbers.map((modelNumber) => modelNumber.modelNumber).join(", "),
            },
            {
              title: t("actions"),
              key: "actions",
              align: "right",
              width: 80,
              render: (_, descriptor) => (
                <Space>
                  <Button
                    title={t("view-descriptor")}
                    shape="circle"
                    icon={<FileSearchOutlined />}
                    onClick={() => {
                      setModelDescriptor(descriptor);
                      setModalType("view");
                    }}
                  />

                  <Button
                    title={t("edit-descriptor")}
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setModelDescriptor(descriptor);
                      setModalType("update");
                    }}
                  />

                  <Button
                    title={t("duplicate-descriptor")}
                    shape="circle"
                    icon={<CopyOutlined />}
                    onClick={() => {
                      setModelDescriptor(descriptor);
                      setModalType("duplicate");
                    }}
                  />
                  <Popconfirm
                    title={t("delete-confirmation")}
                    okText={t("yes")}
                    okType="danger"
                    cancelText={t("no")}
                    onConfirm={() => {
                      dispatch(archiveModelDescriptor(descriptor.id))
                        .unwrap()
                        .then(() => {
                          void message.success(t("delete-success"));
                        })
                        .catch((rejectedValue: string) => {
                          void message.error(rejectedValue);
                        });
                    }}
                  >
                    <Button
                      title={t("delete-button")}
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={allDescriptors}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "25", "50"],
            current: page,
            pageSize,
            onChange: changePagination,
            total: numOfResults,
          }}
          loading={
            listStatus === "loading"
              ? {
                  indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                  size: "large",
                }
              : false
          }
        />
      </Card>

      {modelDescriptor && (
        <>
          <ViewDescriptorModal
            key={`view-${modelDescriptor.id}`}
            open={modalType === "view"}
            modelDescriptor={modelDescriptor}
            onClose={() => {
              setModalType(undefined);
            }}
          />

          <UpdateDescriptorModal
            key={`update-${modelDescriptor.id}`}
            open={modalType === "update"}
            modelDescriptor={modelDescriptor}
            onClose={() => {
              setModalType(undefined);
            }}
          />

          <DuplicateDescriptorModal
            key={`duplicate-${modelDescriptor.id}`}
            open={modalType === "duplicate"}
            modelDescriptor={modelDescriptor}
            onClose={() => {
              setModalType(undefined);
            }}
            onSuccess={(descriptor) => {
              setModelDescriptor(descriptor);
              setModalType("update");
            }}
          />
        </>
      )}
    </div>
  );
};
