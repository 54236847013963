import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "~/global";

const DeploymentPrograms = ({ onChange, programs, selectedPrograms }) => {
  const onProgramToggled = (identifier) => {
    const isIncluded = selectedPrograms.includes(identifier);
    let newPrograms = [].concat(selectedPrograms);
    if (isIncluded) {
      newPrograms = newPrograms.filter((programIdentifier) => programIdentifier != identifier);
    } else {
      newPrograms.push(identifier);
    }
    onChange(newPrograms);
  };

  return (
    <div className="deployment-programs padding--10">
      <div className="margin-top--15">Programs to use:</div>
      <div>
        {programs.map((program) => {
          const includedProgram = selectedPrograms.includes(program.identifier);
          return (
            <Checkbox
              key={program.identifier}
              id={`checkbox-program-${program.identifier}`}
              label={program.displayName.en || program.identifier}
              isChecked={includedProgram}
              onChange={() => onProgramToggled(program.identifier)}
            />
          );
        })}
      </div>
    </div>
  );
};

DeploymentPrograms.propTypes = {
  programs: PropTypes.array,
  selectedPrograms: PropTypes.array,
  onChange: PropTypes.func,
};

DeploymentPrograms.defaultProps = {
  programs: [],
  selectedPrograms: [],
};

export default DeploymentPrograms;
