import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AsciButton } from "~/global";

import moduleSelectors from "../../selectors";
import { useTranslation } from "~/i18n";

const CustomerUserEditorButtons = (props) => {
  const { t } = useTranslation("customerUserEditorButtons");

  return (
    <div className="layout-row layout-row--space-between-center full-width">
      <div className="column">
        <div className="info-message">{t("field-marked-with-an-asterisk")}</div>
        {props.editMode ? (
          <div className="info-message">{t("at-last-one-client-administrator")}</div>
        ) : null}
      </div>
      <div className="layout-row layout-row--end-center">
        <AsciButton color="white" onClick={() => props.onCancel()}>
          {t("button-cancel")}
        </AsciButton>
        <AsciButton color="blue" disabled={!props.canSaveUser} onClick={() => props.onSave()}>
          {t("button-save")}
        </AsciButton>
      </div>
    </div>
  );
};

CustomerUserEditorButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  editMode: PropTypes.bool,
};

CustomerUserEditorButtons.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  editMode: false,
};

const stateToProps = (state) => ({
  canSaveUser: moduleSelectors.customerUserEditor.getCanSaveCustomerUser(state),
});

export default connect(stateToProps, null)(CustomerUserEditorButtons);
