import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  actions as workstationActions,
  selectors as workstationSelectors,
} from "~/workstation/redux";
import DetailsField from "~/global/reduxer/components/details/DetailsField";
import DetailsPage from "~/global/reduxer/components/details/DetailsPage";

import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import Timestamp from "~/workstation/components/common/Timestamp";
import UserDisplay from "~/workstation/components/common/UserDisplay";
import SessionStatus from "~/workstation/components/common/SessionStatus";
import DetailsSection from "~/global/reduxer/components/details/DetailsSection";

import "./SessionDetails.scss";
import { RecordingList } from "~/workstation/components/common/WorkstationRecording";
import IssueList from "~/workstation/components/listings/IssueList";
import ResultDownloader from "~/global/reduxer/components/ResultDownloader";

const SessionStatistics = ({ session }) => {
  const durationMinutes = useMemo(
    () => moment(session.endAt).diff(session.beginAt, "minutes"),
    [session.endAt, session.beginAt]
  );
  const durationHour = useMemo(
    () => moment(session.endAt).diff(session.beginAt, "hours"),
    [session.endAt, session.beginAt]
  );

  return (
    <DetailsSection sectionTitle="Statistics">
      <DetailsField
        label="Workstation"
        value={<Link to={`/workstations/${session.workstationId}`}>{session.workstationId}</Link>}
      />
      <DetailsField label="User" value={<UserDisplay user={session.operator} inline />} />
      <DetailsField label="Status" value={<SessionStatus status={session.status} />} />
      <DetailsField label="Version" value={session.version} />

      <DetailsField label="Started at" value={<Timestamp value={session.beginAt} inline />} />
      {session.endAt && (
        <>
          <DetailsField label="Ends at" value={<Timestamp value={session.endAt} inline />} />
          <DetailsField
            label="Duration"
            value={durationHour >= 2 ? `${durationHour} hours` : `${durationMinutes} minutes`}
          />
        </>
      )}
      <DetailsField label="Recording Count" value={session.recordingCount} />
      {session.endAt && (
        <DetailsField
          label="Unit per hour"
          value={(session.recordingCount / durationHour).toFixed(2)}
        />
      )}
    </DetailsSection>
  );
};

const SessionData = ({ facility, team, program, deployment, serviceSuite, shift }) => (
  <DetailsSection sectionTitle="Information">
    <DetailsField label="Facility" value={facility.name} />
    <DetailsField label="Team" value={team.name} />
    <DetailsField
      label="Program"
      value={<Link to={`/programs/${program.id}`}>{program.name}</Link>}
    />
    <DetailsField
      label="Deployment"
      value={
        <Link to={`/deployment/editor/${facility.id}/${deployment.id}`}>{deployment.name}</Link>
      }
    />
    <DetailsField label="Service Suite" value={serviceSuite.name} />
    <DetailsField label="Shift" value={shift.name} />
  </DetailsSection>
);

const SessionDetails = () => {
  const { sessionId } = useParams();

  const session = useSelector(workstationSelectors.sessionDetails.getResults);

  return (
    <DetailsPage
      pageTitle={
        <div className="details-page-title-head">
          <span>Session {sessionId}</span>
          <ResultDownloader
            selectors={workstationSelectors.sessionDetails}
            download={`session-${sessionId}.json`}
          />
        </div>
      }
    >
      <ReduxerControlled
        selectors={workstationSelectors.sessionDetails}
        actions={workstationActions.sessionDetails}
        fetchArguments={[sessionId]}
      >
        {session && (
          <>
            <div className="double-section">
              <SessionStatistics session={session} />
              <SessionData {...session.sessionData} />
            </div>
            <DetailsSection sectionTitle="Issues" expandable>
              <IssueList sessionId={sessionId} />
            </DetailsSection>
            <DetailsSection sectionTitle="Recordings" expandable>
              <RecordingList
                recordings={session.recordings}
                recordingType="recordings"
                pageType="session"
              />
            </DetailsSection>
            <DetailsSection sectionTitle="Crashes">
              <RecordingList
                recordings={session.crashes.map((c) => ({
                  ...c,
                  detailsLink: `/workstations/crash/${c.id}`,
                }))}
                recordingType="crashes"
                pageType="session"
              />
            </DetailsSection>
          </>
        )}
      </ReduxerControlled>
    </DetailsPage>
  );
};

SessionDetails.defaultProps = {};

SessionDetails.propTypes = {
  sessionId: PropTypes.string,
};

export default SessionDetails;
