import { createAction } from "@reduxjs/toolkit";
import moduleSelectors from "../selectors";

import { CustomerManagement } from "~/services";

export const updateCustomerTeams = createAction("customerManagement/UPDATE_CUSTOMER_TEAMS");
export const fetchCustomerTeamsBegin = createAction(
  "customerManagement/FETCH_CUSTOMER_TEAMS_BEGIN"
);
export const fetchCustomerTeamsSucess = createAction(
  "customerManagement/FETCH_CUSTOMER_TEAMS_SUCESS"
);
export const fetchCustomerTeamsError = createAction(
  "customerManagement/FETCH_CUSTOMER_TEAMS_ERROR"
);
export const fetchUsersTeamsBegin = createAction("customerManagement/teams/FETCH_USERS_BEGIN");
export const fetchUsersTeamsSuccess = createAction("customerManagement/teams/FETCH_USERS_SUCCESS");
export const fetchUsersTeamsError = createAction("customerManagement/teams/FETCH_USERS_ERROR");

export const updateKey = createAction("customerManagement/UPDATE_KEY_TEAMS");
export const updateSortReverse = createAction("customerManagement/UPDATE_SORT_REVERSE_TEAMS");

export const updateTeamsBegin = createAction("customerManagement/UPDATE_TEAMS_BEGIN");
export const updateTeamsSuccess = createAction("customerManagement/UPDATE_TEAMS_SUCCESS");
export const updateTeamsError = createAction("customerManagement/UPDATE_TEAMS_ERROR");

export const fetchUsersTeams = (csid) => async (dispatch) => {
  dispatch(fetchUsersTeamsBegin());
  try {
    const response = await CustomerManagement.POST(`/customers/${csid}/users/search`, {
      page: 1,
      perPage: 10000,
    });
    const users = response.payload.Doc;
    dispatch(fetchUsersTeamsSuccess({ users }));
  } catch (e) {
    if (e instanceof Error) throw e;

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(fetchUsersTeamsError({ message }));
  }
};

export const fetchTeams = (csid) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        teams (csid: "${csid}") {
            csid,
            description,
            faid,
            name,
            teamid,
            usersid
        }
    }`);

  try {
    dispatch(fetchCustomerTeamsBegin());
    const response = await CustomerManagement.QUERY(query);
    const { teams } = response.payload.data;
    dispatch(fetchCustomerTeamsSucess({ teams }));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchCustomerTeamsError({ message }));
  }
};

export const setSortKey = (key) => updateKey({ key });

export const setSortReverse = () => (dispatch, getState) => {
  const state = getState();
  const sortReverse = !state.modules.customerManagement.customerTeams.sortReverse;
  dispatch(updateSortReverse({ sortReverse }));
};

export const onSaveTeam = () => async (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  const teamsById = moduleSelectors.customerTeams.getTeamsById(state);
  const teamId = moduleSelectors.customerTeamEditor.getTeamId(state);

  teamsById[teamId] = team;
  const originalTeams = moduleSelectors.customerTeams.getSortedTeams(state);
  const teams = Object.keys(teamsById || {}).map((teamId) => teamsById[teamId]);

  try {
    dispatch(updateTeamsBegin({ teams }));
    await CustomerManagement.PUT(`/team`, team);
    dispatch(updateTeamsSuccess());
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateTeamsError({ message }));
    dispatch(updateCustomerTeams({ teams: originalTeams }));
  }
};

export const onAddTeam = () => async (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  const teamId = moduleSelectors.customerTeamEditor.getTeamId(state);
  const teamsById = moduleSelectors.customerTeams.getTeamsById(state);
  const originalTeams = moduleSelectors.customerTeams.getSortedTeams(state);
  teamsById[teamId] = team;
  const teams = Object.keys(teamsById || {}).map((id) => teamsById[id]);

  try {
    dispatch(updateTeamsBegin({ teams }));

    if (!team.teamid) {
      delete team.teamid;
    }

    const response = await CustomerManagement.POST(`/team`, team);
    team.teamid = response.payload.UpsertedID;
    teamsById[teamId] = team;
    const updatedTeams = Object.keys(teamsById || {}).map((id) => teamsById[id]);

    dispatch(updateTeamsSuccess());
    dispatch(updateCustomerTeams({ teams: updatedTeams }));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(updateTeamsError({ message }));
    dispatch(updateCustomerTeams({ teams: originalTeams }));
  }
};

export const onDeleteTeam = (teamToDelete) => async (dispatch, getState) => {
  const state = getState();
  const currentTeams = moduleSelectors.customerTeams.getSortedTeams(state);
  const originalTeams = moduleSelectors.customerTeams.getSortedTeams(state);
  const teamId = teamToDelete.teamid;
  const teams = currentTeams.filter((team) => team.teamid != teamId);

  try {
    dispatch(updateTeamsBegin({ teams }));
    await CustomerManagement.DELETE(`/team/${teamId}`);
    dispatch(updateTeamsSuccess());
  } catch (e) {
    console.log(e);

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateTeamsError({ message }));
    dispatch(updateCustomerTeams({ teams: originalTeams }));
  }
};
