import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Button, DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { Trans } from "react-i18next";
import { Switch } from "antd";
import actions from "~/actions";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors";
import { useTranslation } from "~/i18n";
import { useAppDispatch } from "~/hooks";

import CustomerFacilityEditor from "./CustomerFacilityEditor.component";
import CustomerFacilityEditorButtons from "./CustomerFacilityEditorButtons.component";

import {
  AsciButton,
  SectionHeader,
  DialogMessage,
  InputSearch,
  DialogConfirmation,
} from "~/global";

const CustomerFacilities = (props) => {
  const facilitiesLength = props.facilities.length;
  const isEmptyList = facilitiesLength == 0;

  const [facilitiesList, setFacilitiesList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [renderCount, setRenderCount] = useState(0); // Ajoutez ceci

  const { t } = useTranslation("customerFacilities");
  const dispatch = useAppDispatch();
  const forceRender = () => setRenderCount(renderCount + 1);

  useEffect(() => {
    if (props.countries.length === 0) {
      props.fetchCountries();
    }
  }, []);

  useEffect(() => {
    if (props.facilities.length > 0) {
      filterFacilitiesList();
    }
  }, [props.facilities, isArchived, nameFilter]);

  const filterFacilitiesList = () => {
    if (nameFilter.length > 0) {
      const newFacilitiesList = props.facilities.filter((facilities) => {
        const facilitiesName = (facilities.name || "").toLowerCase();
        const faid = (facilities.faid || "").toLowerCase();
        const name = nameFilter.toLowerCase();
        return facilitiesName.includes(name) || faid.includes(name);
      });
      setFacilitiesList(newFacilitiesList);
    } else {
      setFacilitiesList(props.facilities);
    }
  };

  const onSort = (key) => {
    props.setSortReverse();
    props.setSortKey(key);
  };

  useEffect(() => {
    dispatch(moduleActions.customerFacilities.fetchCustomerFacilities(props.csid, isArchived));
    dispatch(actions.facilities.fetchFacilities(isArchived));
  }, [props.csid, isArchived, dispatch]);

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = props.sortKey == colKey;
    sorted ? classes.push("is-sorted") : null;
    props.sortReverse ? classes.push("sort-reverse") : null;
    return classes.join(" ");
  };

  const onAddFacility = () => {
    const nextFacilityNumber = (1 + facilitiesLength).toString();
    props.initFacility(nextFacilityNumber);
    props.showDialog({
      title: t("title-add-facility"),
      width: "650px",
      modal: true,
      content: () => (
        <div>
          <CustomerFacilityEditor onSave={onAddFacilities} editMode onCancel={onCancel} />
        </div>
      ),
      footer: (close) => (
        <CustomerFacilityEditorButtons onSave={onAddFacilities} onCancel={onCancel} />
      ),
    });
  };

  const onEdit = (faid) => {
    props.setFacility(faid);
    props.showDialog({
      title: t("title-facility-id") + faid,
      width: "650px",
      modal: true,
      content: () => (
        <div>
          <CustomerFacilityEditor onSave={onSaveFacilities} editMode onCancel={onCancel} />
        </div>
      ),
      footer: (close) => (
        <CustomerFacilityEditorButtons onSave={onSaveFacilities} onCancel={onCancel} />
      ),
    });
  };

  const onAddFacilities = () => {
    props.onAdd(props.csid, forceRender);
    props.onClear();
    props.hideDialog();
  };

  const onSaveFacilities = () => {
    props.onSave();
    props.onClear();
    props.hideDialog();
  };

  const onCancel = () => {
    props.onClear();
    props.hideDialog();
  };

  const onInfoClicked = () => {
    props.showDialog({
      title: t("title-usage-information"),
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={props.hideDialog} textButtonClose={t("close-button")}>
          <div>{t("on-info-clicked-when-adding-or-editing")}</div>
          <br />
          <div>{t("on-info-clicked-click-the-list")}</div>
        </DialogMessage>
      ),
    });
  };

  const confirmDeleteFacility = (faid) => {
    dispatch(
      actions.dialog.show({
        title: t("title-delete-facility"),
        width: "450px",
        modal: true,

        content: (close) => (
          <DialogConfirmation
            textButtonCancel={t("button-text-cancel")}
            onCancel={close}
            onConfirm={() => {
              close();
              dispatch(moduleActions.customerFacilities.onDeleteFacilities(faid));
            }}
            textButtonConfirm={t("button-text-confirm")}
          >
            <p className="align-center">{t("are-you-sure", { faid })}</p>
            <p className="align-center">
              <Trans t={t} i18nKey="will-be-impossible-to-connect" />
            </p>
          </DialogConfirmation>
        ),
      })
    );
  };

  return (
    <div>
      <SectionHeader
        title={t("section-header-customer") + props.customerName}
        description={props.csid}
      >
        {props.canAddFacility ? (
          <div className="push-right row centered">
            <InputSearch
              placeholder={t("section-header-placeholder-search-facility")}
              onChange={(name) => setNameFilter(name)}
              initialValue={nameFilter}
              trim
            />
            <AsciButton color="green" onClick={() => onAddFacility()}>
              {t("section-header-add-facility")}
            </AsciButton>
            <Button
              icon
              className="icon-btn text-color--white"
              iconClassName="icon-info-circle"
              forceIconSize={32}
              forceIconFontSize
              onClick={() => onInfoClicked()}
            />
          </div>
        ) : null}
      </SectionHeader>
      <DataTable className="cc-table full-width" baseId="session-list-table" plain>
        <TableHeader>
          <TableRow className="asci-table-row">
            <TableColumn className={getColHeaderClasses("faid")} onClick={() => onSort("faid")}>
              ID
            </TableColumn>
            <TableColumn className={getColHeaderClasses("name")} onClick={() => onSort("name")}>
              {t("data-table-name")}
            </TableColumn>
            <TableColumn
              className={getColHeaderClasses("address")}
              onClick={() => onSort("address")}
            >
              {t("data-table-address")}
            </TableColumn>
            <TableColumn className={getColHeaderClasses("city")} onClick={() => onSort("city")}>
              {t("data-table-city")}
            </TableColumn>
            <TableColumn className={getColHeaderClasses("state")} onClick={() => onSort("state")}>
              {t("data-table-state")}
            </TableColumn>
            <TableColumn
              className={getColHeaderClasses("postalcode")}
              onClick={() => onSort("postalcode")}
            >
              {t("data-table-zip")}
            </TableColumn>
            <TableColumn
              className={getColHeaderClasses("timezone")}
              onClick={() => onSort("timezone")}
            >
              {t("data-table-timezone")}
            </TableColumn>
            <TableColumn
              className={getColHeaderClasses("country")}
              onClick={() => onSort("country")}
            >
              {t("data-table-country")}
            </TableColumn>
            <TableColumn className="edit layout-row layout-row--end-center">
              <Switch
                checked={isArchived}
                align-right
                onChange={(checked) => {
                  setIsArchived(checked);
                }}
                checkedChildren={t("archived")}
                unCheckedChildren={t("not-archived")}
              />
            </TableColumn>
          </TableRow>
        </TableHeader>

        <TableBody>
          {isEmptyList ? (
            <TableRow className="message-empty">
              <TableColumn colSpan="4">{t("data-table-there-are-no-facilities")}</TableColumn>
            </TableRow>
          ) : (
            (facilitiesList || []).map((facility, index) => (
              <TableRow key={`facility-${index}`}>
                <TableColumn>{facility.faid || t("not-available")}</TableColumn>
                <TableColumn>{facility.name || t("not-available")}</TableColumn>
                <TableColumn>{facility.address || t("not-available")}</TableColumn>
                <TableColumn>{facility.city || t("not-available")}</TableColumn>
                <TableColumn>{facility.state || t("not-available")}</TableColumn>
                <TableColumn>{facility.postalcode || t("not-available")}</TableColumn>
                <TableColumn>{facility.timezone || t("not-available")}</TableColumn>
                <TableColumn>{facility.country || t("not-available")}</TableColumn>
                <TableColumn>
                  {!isArchived ? (
                    <div className="row centered to-end">
                      <Button
                        icon
                        className="icon-btn"
                        iconClassName="icon-pencil"
                        onClick={() => onEdit(facility.faid)}
                      />
                      <Button
                        icon
                        className="icon-btn text-color--red"
                        iconClassName="icon-trash"
                        onClick={() => {
                          confirmDeleteFacility(facility.faid);
                        }}
                      />
                    </div>
                  ) : null}
                </TableColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </DataTable>
    </div>
  );
};

CustomerFacilities.propTypes = {
  newCustomer: PropTypes.bool.isRequired,
};

CustomerFacilities.defaultProps = {
  newCustomer: true,
};

const stateToProps = (state) => ({
  customerName: moduleSelectors.customerIdentity.getCustomerName(state),
  csid: moduleSelectors.customerIdentity.getCsid(state),
  countries: selectors.countries.getCountryNames(state),

  facilities: moduleSelectors.customerFacilities.getSortedFacilities(state),
  sortKey: state.modules.customerManagement.customerFacilities.sortKey,
  sortReverse: state.modules.customerManagement.customerFacilities.sortReverse,
  initialized: state.modules.customerManagement.customerFacilities.initialized,

  canAddFacility: selectors.session.hasPermission(state, "POST", "/canAddFacility"),
});

const dispatchToProps = {
  fetchCountries: actions.countries.fetchCountries,
  setSortReverse: moduleActions.customerFacilities.setSortReverse,
  setSortKey: moduleActions.customerFacilities.setSortKey,
  setFacility: moduleActions.customerFacilityEditor.setFacility,
  initFacility: moduleActions.customerFacilityEditor.initFacility,
  onClear: moduleActions.customerFacilityEditor.onClear,
  onAdd: moduleActions.customerFacilities.onAddFacilities,
  onSave: moduleActions.customerFacilities.onSaveFacilities,
  onDelete: moduleActions.customerFacilities.onDeleteFacilities,

  hideDialog: actions.dialog.hide,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(CustomerFacilities);
