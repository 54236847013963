import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import actions from "~/actions";

import "./Footer.component.scss";

const Footer = () => {
  const dispatch = useDispatch();

  const currentYear = new Date().getFullYear().toString();
  const { t, i18n } = useTranslation("footer");
  const { Option } = Select;

  const languages = i18n.options.supportedLngs
    .filter((lng) => {
      // only show cimode when in dev mode
      if (lng === "cimode") {
        return WEBPACK_DEV_MODE;
      }
      return true;
    })
    .map((lng) => ({
      value: lng,
      // show the name of the language in its own language
      name: new Intl.DisplayNames(lng, { type: "language" }).of(lng),
    }));

  const handleLanguageChange = (lng) => {
    // never save cimode
    if (lng !== "cimode") {
      dispatch(actions.user.changeUserLanguage(lng));
    }
    i18n.changeLanguage(lng);
  };

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left">
          <span className="logo-icon icon-asci-logo" />
          <div className="footer-copyright">
            <div>&copy; {t("footer.copyright", { currentYear })}</div>
            <div>{t("footer.version", { version: CLIENT_PORTAL_VERSION })}</div>
          </div>
        </div>
        <div className="footer-right">
          <GlobalOutlined className="language-icon" />
          <Select onChange={handleLanguageChange} value={i18n.language} size="small">
            {languages.map((lng) => (
              <Option key={lng.value} value={lng.value}>
                <span style={{ textTransform: "capitalize" }}>{lng.name}</span>
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
