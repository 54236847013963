import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import replace from "lodash/replace";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import concat from "lodash/concat";
import uniqBy from "lodash/uniqBy";
import some from "lodash/some";

import { Button, Dropdown, Menu } from "antd";
import { actions as workflowActions } from "~/workflow/redux";
import { fetchTestSuite } from "~/test-suites-management/actions/testSuiteEditor.actions";

const PASS = "PASS";
const FAIL = "FAIL";
const NOT_AVAILABLE = "N/A";
const INCOMPLETE = "INCOMPLETE";
const NOT_SUPPORTED = "TEST_NOT_SUPPORTED";
const SKIPPED_DEPENDENCY_NOT_RUN = "SKIPPED_DEPENDENCY_NOT_RUN";
const SKIPPED_DEPENDENCY_PASS = "SKIPPED_DEPENDENCY_PASS";
const REPEAT = "REPEAT";
const SKIPPED = "SKIPPED";
const PREVIOUS = "PREVIOUS";
const AUTO_NEXT = "AUTO_NEXT";

const transformLabel = (value) => ({
  value,
  label: capitalize(replace(value, /_/g, " ").toLowerCase()),
});

const testResultOptions = [
  PASS,
  FAIL,
  NOT_AVAILABLE,
  INCOMPLETE,
  NOT_SUPPORTED,
  SKIPPED_DEPENDENCY_NOT_RUN,
  SKIPPED_DEPENDENCY_PASS,
  REPEAT,
  SKIPPED,
  PREVIOUS,
  AUTO_NEXT,
].map(transformLabel);

const TestResultCompare = (props) => {
  const { conditionData, conditionId, eventId, behaviorId } = props;
  const dispatch = useDispatch();

  const [iosTSID, androidTSID, error] = useSelector((state) => {
    const services = uniqBy(
      concat(
        state.modules.programManagement.serviceSuiteEditor.modifications.services || [],
        state.modules.programManagement.serviceSuiteEditor.original.services || []
      ),
      "className"
    );
    const tsService = find(services, { className: "InitializeDiagnosticsService" });
    if (tsService) {
      const android = find(tsService.arguments, { name: "testSuiteAndroid" });
      const ios = find(tsService.arguments, { name: "testSuiteIOS" });
      return [ios && ios.value, android && android.value];
    }
    return [null, null, true];
  });

  const tests = useSelector((state) => {
    let identifiers = [];
    if (iosTSID) {
      const suites = find(state.modules.testSuitesManagement.loaded, { tsid: iosTSID });
      identifiers = (suites || {}).testIdentifiers || [];
    }
    if (androidTSID) {
      const suites = find(state.modules.testSuitesManagement.loaded, { tsid: androidTSID });
      identifiers = concat(identifiers, (suites || {}).testIdentifiers || []);
    }
    return identifiers;
  });

  useEffect(() => {
    if (iosTSID) {
      dispatch(fetchTestSuite(iosTSID));
    }
    if (androidTSID) {
      dispatch(fetchTestSuite(androidTSID));
    }
  }, [dispatch, iosTSID, androidTSID]);

  const dataSetter = (key, value) => () =>
    dispatch(
      workflowActions.eventEditor[eventId].setConditionData({
        conditionId,
        behaviorId,
        key,
        value,
      })
    );

  if (error || !some([iosTSID, androidTSID])) {
    return <div>Please select test suite first.</div>;
  }

  return (
    <>
      <Dropdown
        overlay={
          <Menu selectable={false}>
            {tests.map((t) => (
              <Menu.Item
                key={`${behaviorId}-${conditionId}-test-opt-${t}`}
                onClick={dataSetter("testIdentifier", t)}
              >
                {t}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button>
          {(conditionData.testIdentifier && conditionData.testIdentifier) || "Select test"}
        </Button>
      </Dropdown>
      <Dropdown
        overlay={
          <Menu selectable={false}>
            {testResultOptions &&
              testResultOptions.map((t) => (
                <Menu.Item
                  key={`${behaviorId}-${conditionId}-status-opt-${t.value}`}
                  onClick={dataSetter("status", t.value)}
                >
                  {t.label}
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <Button>{conditionData.status || "Select status"}</Button>
      </Dropdown>
    </>
  );
};

TestResultCompare.propTypes = {
  conditionId: PropTypes.number,
  behaviorId: PropTypes.number,
  conditionData: PropTypes.shape({
    testIdentifier: PropTypes.string,
    status: PropTypes.string,
  }),
  eventId: PropTypes.string,
};

export default TestResultCompare;
