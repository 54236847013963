import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/device-history/actions";

const initialState = {
  errors: null,
  includeAdvancedToPrint: false,
  includeServicesToPrint: true,
  includeTestsToPrint: true,
  includeAuditTestsToPrint: true,
  includeFailDetailsToPrint: true,
  includeConfigInfoToPrint: true,
  includeBatteryToPrint: true,
  includeWdsToPrint: false,
  includeCustomFieldsToPrint: true,
  includeContentClearedToPrint: true,
};

export default createReducer(initialState, {
  [moduleActions.printableInformation.setIncludeAdvancedToPrint]: (state, action) => {
    state.includeAdvancedToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeServicesToPrint]: (state, action) => {
    state.includeServicesToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeTestsToPrint]: (state, action) => {
    state.includeTestsToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeAuditTestsToPrint]: (state, action) => {
    state.includeAuditTestsToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeConfigurationToPrint]: (state, action) => {
    state.includeConfigInfoToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeFailDetailsToPrint]: (state, action) => {
    state.includeFailDetailsToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeBatteryToPrint]: (state, action) => {
    state.includeBatteryToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeWdsToPrint]: (state, action) => {
    state.includeWdsToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeCustomFieldsToPrint]: (state, action) => {
    state.includeCustomFieldsToPrint = action.payload.value;
  },
  [moduleActions.printableInformation.setIncludeContentClearedToPrint]: (state, action) => {
    state.includeContentClearedToPrint = action.payload.value;
  },
});
