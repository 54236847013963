import React, { Component } from "react";
import { Checkbox as MDCheckbox, FontIcon } from "react-md";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Checkbox.component.scss";

export default class Checkbox extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    checkedIcon: PropTypes.element,
    uncheckedIcon: PropTypes.element,
    isChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    noPadding: PropTypes.bool,
    noVerticalPadding: PropTypes.bool,
  };

  static defaultProps = {
    checkedIcon: <FontIcon iconClassName="icon-check-square" />,
    uncheckedIcon: <FontIcon iconClassName="icon-square-o" />,
    disabled: false,
    noPadding: false,
    noVerticalPadding: false,
  };

  render() {
    return (
      <MDCheckbox
        id={this.props.id}
        name={this.props.name || this.props.id}
        aria-label={this.props.id}
        label={this.props.label}
        className={classnames("cc-checkbox", {
          "cc-checkbox--no-padding": this.props.noPadding,
          "cc-checkbox--no-vertical-padding": this.props.noVerticalPadding,
        })}
        checkedCheckboxIcon={this.props.checkedIcon}
        uncheckedCheckboxIcon={this.props.uncheckedIcon}
        inkDisabled
        onChange={this.props.onChange}
        checked={this.props.isChecked}
        disabled={this.props.disabled}
      />
    );
  }
}
