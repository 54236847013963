import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,
  errors: null,
  nameError: false,
  addressError: false,
  cityError: false,
  postalCodeError: false,
  timezones: [],
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [moduleActions.customerFacilityEditor.setFacilityToEdit]: (state, action) => {
    state.original = action.payload.facility;
  },
  [moduleActions.customerFacilityEditor.updateCustomerFacility]: (state, action) => {
    state.modifications = action.payload.facility;
  },
  [moduleActions.customerFacilityEditor.onClearFacilityEdition]: (state, action) => {
    state.original = {};
    state.modifications = {};
    state.isLoading = false;
    state.nameError = false;
    state.addressError = false;
    state.cityError = false;
    state.postalCodeError = false;
  },
  [moduleActions.customerEditor.resetCustomerEdition]: (state, action) => {
    state.original = {};
    state.modifications = {};
    state.isLoading = false;
    state.nameError = false;
    state.addressError = false;
    state.cityError = false;
    state.postalCodeError = false;
  },
  [moduleActions.customerFacilityEditor.onFacilityNameValidation]: (state, action) => {
    state.nameError = action.payload.errors;
  },
  [moduleActions.customerFacilityEditor.onFacilityAddressValidation]: (state, action) => {
    state.addressError = action.payload.errors;
  },
  [moduleActions.customerFacilityEditor.onFacilityCityValidation]: (state, action) => {
    state.cityError = action.payload.errors;
  },
  [moduleActions.customerFacilityEditor.onPostalCodeValidation]: (state, action) => {
    state.postalCodeError = action.payload.errors;
  },
  [moduleActions.customerFacilityEditor.updateTimezones]: (state, action) => {
    state.timezones = action.payload.timezones;
  },
});
