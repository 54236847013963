import React, { useState, Fragment } from "react";
import { Button } from "react-md";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./ListToList.component.scss";

/*
TO IMPROVE:
- Drag and drop suport
- Make the title optional
- Arrow key listeners to navigate selected item
- Customizable min height
*/

const ListToList = ({ lists, onChange }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemListIndex, setSelectedItemListIndex] = useState(undefined);

  const onSelect = (item, listIndex) => {
    if (isSelectedItem(item, listIndex)) {
      // move to next/prev list on double click if there are only 2 lists
      if (lists.length === 2) {
        moveSelectedItem(listIndex, listIndex == 0 ? 1 : 0);
      } else {
        item = null;
        listIndex = -1;
      }
    }

    setSelectedItem(item);
    setSelectedItemListIndex(listIndex);
  };

  // move value between 2 arrays
  const moveSelectedItem = (srcIndex, destIndex) => {
    // an item must be selected in one of the lists
    if (!selectedItem) {
      console.warn("no item selected");
      return;
    }

    const isMovingRight = srcIndex < destIndex;
    const isMovingLeft = srcIndex > destIndex;
    const isItemInLastList = selectedItemListIndex == lists.length - 1;
    const isItemInFirstList = selectedItemListIndex == 0;

    // bound validation
    if (isMovingRight && isItemInLastList) {
      console.warn("Can't move right when selected item is in the last list");
      return;
    }

    if (isMovingLeft && isItemInFirstList) {
      console.warn("Can't move left when selected item is in the first list");
      return;
    }

    const source = [...lists[srcIndex].items];
    const dest = [...lists[destIndex].items];

    const itemIndex = source.indexOf(selectedItem);
    source.splice(itemIndex, 1);
    dest.push(selectedItem);

    // create a new list from the props list in case there are more than 2 lists
    const newLists = [...lists];
    newLists[srcIndex].items = source;
    newLists[destIndex].items = dest;

    // supply a parameter for each list's items (don't include title)
    onChange(...newLists.map((l) => l.items));

    // keep the selected item and update the selected list index
    setSelectedItemListIndex(destIndex);
  };

  const isSelectedItem = (item, listIndex) =>
    selectedItem == item && selectedItemListIndex == listIndex;

  return (
    <div className="cc-list-to-list">
      {lists.map((list, listIndex) => (
        <Fragment key={`list-${listIndex}`}>
          <div className="list-container">
            <p>{list.title}</p>

            <div className="list-items">
              {list.items.map((item, index) => (
                <div
                  key={`item-${index}`}
                  className={classnames("list-item", {
                    selected: isSelectedItem(item, listIndex),
                  })}
                  onClick={() => onSelect(item, listIndex)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          {listIndex < lists.length - 1 ? (
            <div className="actions">
              <Button
                icon
                className="icon-btn"
                iconClassName="icon-chevron-right"
                disabled={!selectedItem || selectedItemListIndex == lists.length - 1}
                onClick={() => moveSelectedItem(listIndex, listIndex + 1)}
              />

              <Button
                icon
                className="icon-btn"
                iconClassName="icon-chevron-left"
                disabled={!selectedItem || selectedItemListIndex == 0}
                onClick={() => moveSelectedItem(listIndex + 1, listIndex)}
              />
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

ListToList.propTypes = {
  lists: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ListToList;
