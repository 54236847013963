import { DocumentationManagement } from "~/services";

export const FETCH_HOME_DOCUMENTS_BEGIN = "FETCH_HOME_DOCUMENTS_BEGIN";
export const FETCH_HOME_DOCUMENTS_SUCCESS = "FETCH_HOME_DOCUMENTS_SUCCESS";
export const FETCH_HOME_DOCUMENTS_ERROR = "FETCH_HOME_DOCUMENTS_ERROR";

export const fetchHomeDocuments = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_HOME_DOCUMENTS_BEGIN });

    const response = await DocumentationManagement.GET("/home");

    const documents = (response.payload || []).filter((doc) => doc.name.length > 0);

    dispatch({
      type: FETCH_HOME_DOCUMENTS_SUCCESS,
      payload: { documents },
    });
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch({
      type: FETCH_HOME_DOCUMENTS_ERROR,
      payload: { errors: e },
    });
  }
};

export function fetchDocumentation() {
  return DocumentationManagement.GET("/documentation")
    .then((response) =>
      ((response || {}).payload || []).reduce(
        (acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }

          acc[item.category].push(item);

          return acc;
        },
        {
          Training: [],
          "User Guide": [],
          Other: [],
        }
      )
    )
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export function downloadVersion(url, contentType, name) {
  const isExternalUrl = true;
  return DocumentationManagement.downloadFile(url, contentType, name, isExternalUrl)
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      if (error.response != undefined) {
        return error;
      }
    });
}
