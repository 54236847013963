import { createAction } from "@reduxjs/toolkit";
import moduleSelectors from "../selectors";

import { getRegexValidation } from "~/global/utils";

export const setUserToEdit = createAction("customerManagement/SET_USER_TO_EDIT");
export const onUserPasswordValidation = createAction(
  "customerManagement/ON_USER_PASSWORD_VALIDATION"
);

export const onUserEmailValidation = createAction("customerManagement/ON_USER_EMAIL_VALIDATION");
export const onUserFirstNameValidation = createAction(
  "customerManagement/ON_USER_FIRST_NAME_VALIDATION"
);

export const onUserLastNameValidation = createAction(
  "customerManagement/ON_USER_LAST_NAME_VALIDATION"
);

export const onUserFacilitiesValidation = createAction(
  "customerManagement/ON_USER_FACILITIES_VALIDATION"
);

export const updateCustomerUser = createAction("customerManagement/UPDATE_CUSTOMER_USER");
export const onClearUserEdition = createAction("customerManagement/ON_CLEAR_USER_EDITION");
export const setResetPassword = createAction("customerManagement/SET_RESET_PASSWORD");
export const onOperatorId = createAction("customerManagement/ON_OPERATOR_ID");

export const onClear = () => onClearUserEdition();

export const initUser = () => (dispatch, getState) => {
  const state = getState();
  const firstRoleName = moduleSelectors.customerUsers.getFirstRoleName(state);

  const user = {
    userid: "",
    email: "",
    facilities: [],
    hasAllFacilitiesAccess: true,
    firstname: "",
    lastname: "",
    roleName: firstRoleName,
    status: 0,
    operatorId: "",
  };
  const editMode = false;
  dispatch(setUserToEdit({ user, editMode }));
};

export const setUser = (userData) => (dispatch, getState) => {
  const state = getState();
  const users = moduleSelectors.customerUsers.getFlattenUsersById(state);
  const user = users[userData.userid];
  const editMode = true;

  dispatch(setUserToEdit({ user, editMode }));
};

export const validateUserPassword = (password) => async (dispatch) => {
  const nameForValidation = password.trim();
  const isValid = await getRegexValidation("User_Password", nameForValidation);
  const errors = !isValid;
  dispatch(onUserPasswordValidation({ errors }));
};

export const onResetPassword = (value) => setResetPassword({ value });

export const validateUserEmail = (email) => async (dispatch) => {
  const nameForValidation = email.trim();
  const isValid = await getRegexValidation("User_Email", nameForValidation);
  const errors = !isValid;
  dispatch(onUserEmailValidation({ errors }));
};

export const validateUserFirstName = (firstName) => async (dispatch) => {
  const nameForValidation = firstName.trim();
  const isValid = await getRegexValidation("User_FirstName", nameForValidation);
  const errors = !isValid;
  dispatch(onUserFirstNameValidation({ errors }));
};

export const validateUserLastName = (lastName) => async (dispatch) => {
  const nameForValidation = lastName.trim();
  const isValid = await getRegexValidation("User_LastName", nameForValidation);
  const errors = !isValid;
  dispatch(onUserLastNameValidation({ errors }));
};

export const onChangePassword = (password) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.password = password;
  dispatch(validateUserPassword(password));
  dispatch(updateCustomerUser({ user }));
};

export const onChangeEmail = (email) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.email = email;
  dispatch(validateUserEmail(email));

  dispatch(updateCustomerUser({ user }));
};

export const onChangeFirstName = (firstname) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.firstname = firstname;
  dispatch(validateUserFirstName(firstname));
  dispatch(updateCustomerUser({ user }));
};

export const onChangeLastName = (lastname) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.lastname = lastname;
  dispatch(validateUserLastName(lastname));
  dispatch(updateCustomerUser({ user }));
};

export const onChangeRole = (roleName) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.roleName = roleName;
  dispatch(updateCustomerUser({ user }));
};

export const onChangeFacilities = (facilityIds) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  const includesAll = facilityIds.includes("all");

  if (includesAll) {
    user.hasAllFacilitiesAccess = includesAll;
    user.facilities = [];
  } else {
    user.hasAllFacilitiesAccess = false;
    user.facilities = facilityIds.map((faid) => ({ faid }));
  }

  dispatch(updateCustomerUser({ user }));
};

export const validateFacilities = (facilityIds) => (dispatch, getState) => {
  const state = getState();
  const originalFacilityIds = state.modules.customerManagement.customerUserEditor.original
    .hasAllFacilitiesAccess
    ? ["all"]
    : state.modules.customerManagement.customerUserEditor.original.facilities.map(
        (facility) => facility.faid
      );

  const includesSameFacilities = originalFacilityIds.every((faid) => facilityIds.includes(faid));
  const includesAll = facilityIds.includes("all");
  const error = !includesAll && !includesSameFacilities;

  dispatch(onUserFacilitiesValidation({ error }));
};

export const onSelectStatus = (status) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.status = status;
  dispatch(updateCustomerUser({ user }));
};

export const onChangeOperatorId = (operatorId) => (dispatch, getState) => {
  const state = getState();
  const user = moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state);
  user.operatorId = operatorId;
  dispatch(updateCustomerUser({ user }));
};
