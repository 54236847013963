import React, { useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "./LabelEditor.scss";

import GridLayout from "./layouts/GridLayout";
import FreeformLayout from "./layouts/FreeformLayout";

import * as layouts from "~/label-printer/layouts";
import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";

const LabelEditor = ({
  layoutName,
  width,
  height,
  layoutData,
  selectShape,
  labelStyle,
  maxHeight,
  maxWidth,
  printPreview,
  setPrintPreview,
  onPrinted,
}) => {
  const labelRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
    onAfterPrint: () => {
      setPrintPreview(false);
      if (onPrinted) {
        onPrinted();
      }
    },
    copyStyles: true,
    bodyClass: "label-body-print",
  });

  useEffect(() => {
    if (printPreview) {
      handlePrint();
    }
  }, [printPreview]);

  const layout = useMemo(() => {
    const payload = { ...layoutData, width, height };

    switch (layoutName) {
      case layouts.FREEFORM:
        return <FreeformLayout {...payload} />;
      case layouts.GRID:
        return <GridLayout {...payload} />;
    }
  }, [layoutName, layoutData, width, height]);

  return (
    <div
      className="label-editor"
      style={{ maxHeight, maxWidth }}
      onClick={() => selectShape({ shape: null })}
    >
      <div className="label-editor-layout-container" style={labelStyle} ref={labelRef}>
        {layout}
      </div>
    </div>
  );
};

LabelEditor.propTypes = {
  layoutName: PropTypes.string,
  layoutData: PropTypes.object,

  width: PropTypes.number,
  height: PropTypes.number,
  selectShape: PropTypes.func,

  labelStyle: PropTypes.object,

  printPreview: PropTypes.bool,
  setPrintPreview: PropTypes.func,
};

const stateToProps = (state) => ({
  labelStyle: labelSelectors.labelDesigner.getLabelStyle(state),
  printPreview: labelSelectors.labelPrint.printPreview(state),
});

const dispatchToProps = {
  selectShape: labelActions.labelPicker.selectShape,
  setPrintPreview: labelActions.labelPrint.setPrintPreview,
};

export default connect(stateToProps, dispatchToProps)(LabelEditor);
