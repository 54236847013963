import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./ContentPanel.component.scss";

// children should be header, main and footer elements
export default class ContentPanel extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={classnames("cc-content-panel", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}
