import { createSelector } from "@reduxjs/toolkit";
import { isEmpty, isNumber } from "lodash/lang";
import { inRange } from "lodash/number";
import uuid from "uuid/v4";

import { validate } from "~/global/utils";

const getCustomFieldOriginal = (state) => state.modules.customFields.customFieldEditor.original;
const getCustomFieldModified = (state) =>
  state.modules.customFields.customFieldEditor.modifications;
const getCustomFields = (state) => state.customFields.items;

export const getUpdatedCustomField = createSelector(
  [getCustomFieldOriginal, getCustomFieldModified],
  (original, modifications) => {
    const updatedCustomField = { ...original, ...modifications };
    return updatedCustomField;
  }
);

export const getIsEqual = createSelector([getCustomFieldModified], (modifications) =>
  isEmpty(modifications)
);

export const getCustomFieldsName = createSelector(
  [getCustomFields, getCustomFieldOriginal],
  (customFields, originalCustomField) =>
    (customFields || [])
      .map((customField) => customField.name)
      .filter((customFieldName) => originalCustomField.name != customFieldName)
);

export const getCustomFieldName = createSelector(
  [getUpdatedCustomField],
  (customField) => customField.name || ""
);

export const getDisplayNameEn = createSelector([getUpdatedCustomField], (customField) =>
  isEmpty(customField.displayName) ? "" : customField.displayName.en || ""
);

export const getNameHasDuplicate = createSelector(
  [getCustomFieldName, getCustomFieldsName],
  (newName, customFieldsName) => customFieldsName.includes(newName)
);

export const getNameErrorText = createSelector(
  [getCustomFieldName, getNameHasDuplicate],
  (newName, duplicate) => {
    const { isValid, message } = validate("customFieldId", newName, true);
    let errorText = "";

    if (newName.length === 0) {
      errorText = "Required field";
    } else if (!isValid) {
      errorText = message;
    } else if (duplicate) {
      errorText = "Identifier is already in use";
    }

    return errorText;
  }
);

export const getType = createSelector([getUpdatedCustomField], (customField) => customField.type);

export const getMinNumber = createSelector([getUpdatedCustomField, getType], (customField, type) =>
  type === "numeric" ? customField.min : 0
);

export const getMaxNumber = createSelector([getUpdatedCustomField, getType], (customField, type) =>
  type === "numeric" ? customField.max : 0
);

export const getDefaultNumber = createSelector(
  [getUpdatedCustomField, getType],
  (customField, type) => (type === "numeric" ? customField.defaultNumberValue : 0)
);

export const getIsMinNumberValid = createSelector([getMinNumber, getMaxNumber], (min, max) => {
  const minToNumber = +min;
  const maxToNumber = +max;

  const isFilled = min.toString().length > 0;
  const isANumber = isNumber(minToNumber);
  const isInRange = minToNumber <= maxToNumber;
  return isANumber && isInRange && isFilled;
});

export const getIsMaxNumberValid = createSelector([getMinNumber, getMaxNumber], (min, max) => {
  const minToNumber = +min;
  const maxToNumber = +max;

  const isFilled = max.toString().length > 0;
  const isANumber = isNumber(maxToNumber);
  const isInRange = minToNumber <= maxToNumber;
  return isANumber && isInRange && isFilled;
});

export const getIsDefaultNumberValid = createSelector(
  [getMinNumber, getMaxNumber, getDefaultNumber],
  (min, max, defaultNumber) => {
    const minToNumber = +min;
    const maxToNumber = +max;

    const isFilled = max.toString().length > 0;
    const isANumber = isNumber(maxToNumber);
    const isInRange = inRange(defaultNumber, minToNumber, maxToNumber + 1);
    return isANumber && isInRange && isFilled;
  }
);

export const getNumbericValid = createSelector(
  [getIsMaxNumberValid, getIsMinNumberValid, getIsDefaultNumberValid],
  (minvalid, maxvalid, defaultvalid) => minvalid && maxvalid && defaultvalid
);

export const getMaxLength = createSelector([getUpdatedCustomField, getType], (customField, type) =>
  type === "string" ? customField.maxLength : 0
);

export const getIsMaxLengthValid = createSelector([getMaxLength], (maxLength) => {
  const minToNumber = 0;
  const maxToNumber = 10000;
  const maxLengthToNumber = +maxLength;

  const isFilled = maxLength.toString().length > 0;
  const isANumber = isNumber(maxLengthToNumber);
  const isInRange = inRange(maxLengthToNumber, minToNumber, maxToNumber);

  return isANumber && isInRange && isFilled;
});

export const getStringValid = createSelector(
  [getIsMaxLengthValid, getUpdatedCustomField],
  (maxLengthValid, customField) => {
    const stringValid = (customField.defaultStringValue || []).length <= customField.maxLength;

    return maxLengthValid && stringValid;
  }
);

export const getCustomFieldItems = createSelector(
  [getUpdatedCustomField],
  (customField) => customField.items || []
);

export const getListHastems = createSelector([getCustomFieldItems, getType], (items, type) =>
  type === "list" ? items.length > 0 : true
);

export const getListItemsNameValid = createSelector(
  [getCustomFieldItems],
  (items) => items.filter((item) => item.value.length > 0).length == items.length
);

export const getListItemsDisplayNameValid = createSelector(
  [getCustomFieldItems],
  (items) =>
    items.filter((item) => item.displayName.en != null && item.displayName.en.length > 0).length ==
    items.length
);

export const getListItemsValid = createSelector(
  [getListHastems, getListItemsNameValid, getListItemsDisplayNameValid],
  (hasItems, nameValid, displayNameValid) => hasItems && nameValid && displayNameValid
);

export const getCanSave = createSelector(
  [
    getCustomFieldName,
    getDisplayNameEn,
    getIsEqual,
    getNameErrorText,
    getNumbericValid,
    getStringValid,
    getListItemsValid,
    getType,
  ],
  (
    customFieldName,
    displayNameEn,
    isEqual,
    nameErrorText,
    numericValid,
    stringValid,
    listValid,
    type
  ) => {
    const fieldTypes = {
      numeric: numericValid,
      string: stringValid,
      list: listValid,
    };

    const nameIsFilled = customFieldName.length > 0;
    const hasEnglishDisplayName = displayNameEn.length > 0;

    const canSave =
      !isEqual &&
      nameIsFilled &&
      hasEnglishDisplayName &&
      nameErrorText.length === 0 &&
      fieldTypes[type];
    return canSave;
  }
);

export const getErrorMessages = createSelector(
  [
    getCustomFieldName,
    getDisplayNameEn,
    getNameErrorText,
    getNumbericValid,
    getStringValid,
    getListItemsValid,
    getListItemsNameValid,
    getListItemsDisplayNameValid,
    getType,
  ],
  (
    customFieldName,
    displayNameEn,
    nameErrorText,
    numericValid,
    stringValid,
    listValid,
    listNameValid,
    displayNameValid,
    type
  ) => {
    const fieldTypes = {
      numeric: numericValid,
      string: stringValid,
      list: listValid,
    };
    const nameIsFilled = customFieldName.length > 0;
    const hasEnglishDisplayName = displayNameEn.length > 0;

    const listRequiredMissing = type === "list" && (!listNameValid || !displayNameValid);

    const message = [];
    !fieldTypes[type] ? message.push("Please verify the Options") : null;
    nameErrorText.length > 0 ? message.push(nameErrorText) : null;
    !hasEnglishDisplayName || !nameIsFilled || listRequiredMissing
      ? message.push("All fields marked with an asterisk (*) are required")
      : null;

    return message.join(". ");
  }
);

export const getCustomFielditemsWithId = createSelector([getCustomFieldItems], (items) =>
  items.reduce((acc, item) => {
    const id = uuid();
    acc[id] = { ...item };
    return acc;
  }, {})
);
