import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { message, Modal } from "antd";
import { useNavigate } from "react-router-dom-v5-compat";
import actions from "~/actions";
import moduleActions from "../../actions";
import { AsciButton, SectionHeader, DialogMessage, InputSearch, Column } from "~/global";
import CustomerList from "./CustomerList.component";
import CustomerWizard from "../CustomerWizard/CustomerWizard.component";
import CustomerWizardButtons from "../CustomerWizard/CustomerWizardButtons.component";
import selectors from "~/selectors";

import "./CustomerManagementPage.component.scss";
import { useTranslation } from "~/i18n";

const CustomerManagementPage = ({
  canEditCustomers,
  canEditOnlyCurrent,
  currentCsid,
  customers,
  errors,
  hideDialog,
  onResetCustomerEdition,
  showDialog,
  onAddCustomer,
  onResetCustomersEdition,
  initUser,
  isLoading,
  deleteCustomer,
  fetchCustomers,
}) => {
  const [wizardInUse, setWizardInUse] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const userRoles = useSelector((state) => state.session.roleNames);
  const dispatch = useDispatch();
  const { t } = useTranslation("customerManagementPage");
  const navigate = useNavigate();
  const [isArchived, setIsArchived] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    onCanEditOnlyCurrent();
    onResetCustomerEdition();
    return () => {
      hideDialog();
      onResetCustomersEdition();
    };
  }, []);

  useEffect(() => {
    if (customers.length > 0) {
      filterCustomerList();
    }
  }, [nameFilter]);

  useEffect(() => {
    errors != null ? showErrorMessage(errors) : null;
  }, [errors]);

  useEffect(() => {
    if (wizardInUse) {
      message.destroy();
      message.success(t("message-success-customer-added-successfully"), 2.5);
      setWizardInUse(false);
      hideDialog();
    }
    if (customers.length > 0) {
      filterCustomerList();
    }
  }, [customers, isArchived]);

  const filterCustomerList = () => {
    const newCustomers = customers.filter((customer) => customer.isArchived === isArchived);
    if (nameFilter.length > 0) {
      const newCustomersList = newCustomers.filter((customer) => {
        const customerName = (customer.name || "").toLowerCase();
        const csid = (customer.csid || "").toLowerCase();
        const name = nameFilter.toLowerCase();
        return customerName.includes(name) || csid.includes(name);
      });
      setCustomersList(newCustomersList);
    } else {
      setCustomersList(newCustomers);
    }
  };

  const showErrorMessage = (errorMessage) => {
    if (wizardInUse) {
      message.destroy();
      message.error(t("error-message-request-dont-complete"), 2.5);
    } else {
      showDialog({
        title: t("title-error"),
        width: "450px",
        content: (close) => (
          <DialogMessage
            close={() => {
              close();
            }}
          >
            <p>{errorMessage}</p>
          </DialogMessage>
        ),
      });
    }
  };
  const errorCustomer = (errors, name) => ({
    title: `Error with this customer: ${name}`,
    content: errors,
  });

  const onDeleteCustomer = (csid) => {
    showDialog({
      title: "Delete Customer",
      content: (close) => (
        <div className="layout-column">
          <p className="align-center">{t("delete-message-info")}</p>

          <div className="dialog-actions layout-row layout-row--end">
            <AsciButton color="white" onClick={close}>
              {t("cancel")}
            </AsciButton>

            <AsciButton
              color="red"
              onClick={async () => {
                try {
                  await deleteCustomer(csid);
                  fetchCustomers();
                } catch (e) {
                  modal.error(errorCustomer(e.message, csid));
                }
                close();
              }}
            >
              {t("delete")}
            </AsciButton>
          </div>
        </div>
      ),
    });
  };

  const onCanEditOnlyCurrent = () => {
    if (canEditOnlyCurrent) {
      navigate(`/customer-management/${currentCsid}`, { replace: true });
    } else if (!canEditCustomers && !canEditOnlyCurrent) {
      showDialog({
        title: t("title-forbidden"),
        width: "450px",
        modal: true,
        content: (close) => (
          <DialogMessage
            close={() => {
              close();
              navigate("/home", { replace: true });
            }}
          >
            {t("you-do-not-have-the-permissions")}
          </DialogMessage>
        ),
      });
    }
  };

  const addCustomerWizard = () => {
    setWizardInUse(true);
    initUser();
    showDialog({
      title: t("title-add-customer"),
      width: "850px",
      modal: true,
      content: (close) => (
        <CustomerWizard
          close={() => {
            close();
          }}
        />
      ),
      footer: (close) => (
        <CustomerWizardButtons
          onCancel={close}
          onSave={() => {
            onAddCustomer();
            message.loading(t("message-loading-action-in-progress"), 0);
          }}
        />
      ),
    });
  };

  const handleCustomerSelect = async (csid) => {
    if (userRoles.includes("asci-administrator")) {
      await dispatch(actions.customer.changeActiveCustomer(csid));
    }

    navigate(`/customer-management/${csid}`);
  };

  return (
    <Column className="customer-management-page full-width" flex align="start center">
      <div className="customer-management-table">
        <SectionHeader title={t("customer-manager")}>
          <div className="push-right row centered">
            <InputSearch
              placeholder={t("search-customer")}
              onChange={(name) => setNameFilter(name)}
              initialValue={nameFilter}
              trim
            />
            <AsciButton color="green" onClick={addCustomerWizard} disabled={!canEditCustomers}>
              <div className="layout-row layout-row--center-center">{t("add-customer")}</div>
            </AsciButton>
          </div>
        </SectionHeader>
        <CustomerList
          onEdit={handleCustomerSelect}
          customers={customersList}
          canEdit={canEditCustomers}
          onDelete={onDeleteCustomer}
          isLoading={isLoading}
          isArchived={isArchived}
          setIsArchived={setIsArchived}
        />
        {contextHolder}
      </div>
    </Column>
  );
};

const stateToProps = (state) => ({
  canEditCustomers: selectors.session.hasPermission(state, "PUT", "/canEditCustomers"),
  canEditOnlyCurrent: selectors.session.hasPermission(state, "PUT", "/canEditOnlyCurrent"),
  currentCsid: state.customer.activeCustomerId,
  customers: state.customers.items,
  isLoading: state.customers.isLoading,
  errors: state.customers.errors,
});

const dispatchToProps = {
  onResetCustomerEdition: moduleActions.customerEditor.onResetCustomerEdition,
  hideDialog: actions.dialog.hide,
  showDialog: actions.dialog.show,
  onResetCustomersEdition: actions.customers.onResetCustomersEdition,
  onAddCustomer: moduleActions.customerWizard.addToCustomersList,
  initUser: moduleActions.customerUserEditor.initUser,
  deleteCustomer: actions.customers.deleteCustomer,
  fetchCustomers: actions.customers.fetchCustomers,
};

export default connect(stateToProps, dispatchToProps)(CustomerManagementPage);
