import React, { Component } from "react";
import PropTypes from "prop-types";

import { Spinner, TestClassRow } from "~/global";
import { RemoteDiagnosticsSession } from "~/models";

export default class SessionResults extends Component {
  static propTypes = {
    session: PropTypes.object,
    testsByCategory: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    testsByCategory: [],
  };

  getResultsByIdentifier = (results = []) =>
    results.reduce((acc, result) => {
      if (!acc[result.identifier]) {
        acc[result.identifier] = result.result;
      } else {
        console.warn(
          `duplicate test result for className: ${result.className}, identifier: ${result.identifier}, result: ${result.result}`
        );
      }

      return acc;
    }, {});

  getCategoryStatus = (category, resultsByIdentifier) => {
    let status = RemoteDiagnosticsSession.RESULT_PASS;

    for (let i = 0; i < category.definitions.length; i++) {
      const definition = category.definitions[i];

      if (resultsByIdentifier[definition.identifier] == RemoteDiagnosticsSession.RESULT_FAIL) {
        status = RemoteDiagnosticsSession.RESULT_FAIL;
        break;
      }
    }

    return status;
  };

  render() {
    const resultsByIdentifier = this.getResultsByIdentifier(
      ((this.props.session || {}).results || {}).testResults
    );
    const isSessionPending =
      this.props.session.status === RemoteDiagnosticsSession.SESSION_STATUS_PENDING;

    return (
      <div className="remote-diagnostics-session-results">
        {this.props.testsByCategory.length === 0 ? (
          <div className="padding--15 layout-column layout-column--start-center">
            {isSessionPending ? (
              <>
                <h3 className="margin-bottom--10">Test sent, results pending</h3>
                <Spinner />
              </>
            ) : (
              <h3 className="margin-bottom--10">Session expired, no results received</h3>
            )}
          </div>
        ) : null}

        {this.props.testsByCategory.map((category) => (
          <div className="category-tests-selector" key={category.name}>
            <header className="layout-row layout-row--start-center">
              <div className="result margin-right--10">
                <TestClassRow.ResultStatus
                  status={this.getCategoryStatus(category, resultsByIdentifier)}
                />
              </div>
              <div className="name">{category.displayName}</div>
            </header>

            <div className="columns-header layout-row layout-row--start-center">
              <div className="status">Status</div>
              <div className="test">Test</div>
            </div>

            <main>
              {category.definitions.map((definition, index) => (
                <TestClassRow key={index}>
                  <TestClassRow.ResultStatus status={resultsByIdentifier[definition.identifier]} />
                  <TestClassRow.Icon image={definition.image} />
                  <TestClassRow.Identifier
                    displayName={definition.displayName}
                    className={definition.className}
                  />
                </TestClassRow>
              ))}
            </main>
          </div>
        ))}
      </div>
    );
  }
}
