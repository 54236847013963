import React from "react";
import classnames from "classnames";

import "./PrimaryTitle.component.scss";

const PrimaryTitle = ({ children, className, ...rest }) => (
  <div className={classnames("cc-primary-title", className)} {...rest}>
    {children}
  </div>
);

export default PrimaryTitle;
