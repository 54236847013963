import React from "react";
import { Grid, Cell } from "react-md";
import PropTypes from "prop-types";

import { SectionHeader } from "~/global";

import "./DeploymentSummary.component.scss";

const DeploymentSummary = ({
  facility,
  shifts,
  programs,
  selectedPrograms,
  customFields,
  selectedCustomFields,
}) => {
  const shiftsToString = (shifts = []) =>
    shifts
      .map((s) => {
        const startMins = s.start.minutes == 0 ? "00" : s.start.minutes;
        const endMins = s.end.minutes == 0 ? "00" : s.end.minutes;

        return `${s.name} (${s.start.hours}:${startMins}-${s.end.hours}:${endMins})`;
      })
      .join(", ");

  const programsToString = (programs = [], selectedPrograms = []) => {
    const displayNames = programs
      .filter((program) => selectedPrograms.includes(program.identifier))
      .map((program) => program.displayName.en || programs.identifier);

    if (displayNames.length > 0) {
      return displayNames.join(", ");
    }

    return "No programs selected";
  };

  const customFieldsToString = (customFields = [], selectedCustomFields = []) => {
    const displayNames = customFields
      .filter((cf) => selectedCustomFields.includes(cf.name))
      .map((cf) => cf.displayName.en || cf.name);

    if (displayNames.length > 0) {
      return displayNames.join(", ");
    }
    return "No custom fields selected";
  };

  const getStringList = () =>
    [
      {
        key: "schedules",
        title: "Schedules",
        list: shiftsToString(shifts),
      },
      {
        key: "programs",
        title: "Selected Programs",
        list: programsToString(programs, selectedPrograms),
      },
      {
        key: "customFields",
        title: "Selected Custom Fields",
        list: customFieldsToString(customFields, selectedCustomFields),
      },
    ].map((listField, index) => (
      <Grid key={index} noSpacing>
        <Cell size={2} className="title-cell">
          {listField.title}
        </Cell>
        <Cell size={10} className="content-cell medium">
          <div className="row-wrap">{listField.list}</div>
        </Cell>
      </Grid>
    ));

  return (
    <div className="cc-deployment-summary layout-column">
      <SectionHeader title="Review of your deployment" />

      <Grid noSpacing>
        <Cell size={2} className="title-cell">
          Facility
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.name}
        </Cell>

        <Cell size={2} className="title-cell">
          Client Facility ID
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.faid}
        </Cell>

        <Cell size={2} className="title-cell">
          Time Zone
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.timezone}
        </Cell>
      </Grid>

      <Grid noSpacing>
        <Cell size={2} className="title-cell">
          Country
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.country}
        </Cell>

        <Cell size={2} className="title-cell">
          State
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.state}
        </Cell>

        <Cell size={2} className="title-cell">
          Postal Code
        </Cell>
        <Cell size={2} className="content-cell">
          {facility.postalcode}
        </Cell>
      </Grid>

      {getStringList()}
    </div>
  );
};

DeploymentSummary.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string,
    faid: PropTypes.string,
    timezone: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    postalcode: PropTypes.string,
  }),
  shifts: PropTypes.array,
  programs: PropTypes.array,
  selectedPrograms: PropTypes.arrayOf(PropTypes.string),
  customFields: PropTypes.array,
  selectedCustomFields: PropTypes.arrayOf(PropTypes.string),
};

DeploymentSummary.defaultProps = {
  facility: {},
  shifts: [],
  programs: [],
  customFields: [],
};

export default DeploymentSummary;
