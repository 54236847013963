import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";

import { type ExportedReport } from "~/actions/exportedReports.actions";
import { CustomerManagement } from "~/services";
import { type ElementOf } from "~/shared/lib/utilityTypes";
import { type RootState } from "~/store";

export type ScheduledReport = ScheduledReportBase & ScheduledReportParams;

export type ApiScheduledReport = ScheduledReportBase & ApiScheduledReportParams;

type ScheduledReportBase = {
  active: boolean;
  id: string;
  /** ISO 8601 date */
  lastRun?: string;
  /** ISO 8601 date */
  nextRun?: string;
  exportedReport: ExportedReport;
  exportedReportID: string;
};

export type ScheduledReportParams = ScheduledReportParamsBase & {
  repeat: ScheduleRepeat;
};

export type ApiScheduledReportParams = ScheduledReportParamsBase & ApiRepeat;

export type ScheduledReportParamsBase = {
  runAt: PlainTime;
  displayName?: string;
};

export type PlainTime = { hour: number; minute: number };

export type RepeatPeriod = ScheduleRepeat["every"];
export type ScheduleRepeat = RepeatWeek | RepeatMonth;

type RepeatWeek = {
  every: "week";
  on: Weekday[];
};

type RepeatMonth = {
  every: "month";
  on: MonthlyOption;
};

export type Weekday = ElementOf<typeof weekdays>;
export const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export type MonthlyOption = ElementOf<typeof monthlyOptions>;
export const monthlyOptions = ["1st-day", "15th-day"] as const;

/**
 * When `repeatEvery` passed is `week` then *[0...6]* are accepted.
 * When `month` is passed then *0 or 1* is accepted; where 0 is beginning of the month and 1 is the end of the month.
 */
export type ApiRepeat =
  | {
      repeatEvery: "week";
      repeatOn: ApiWeekday[];
    }
  | {
      repeatEvery: "month";
      repeatOn: ApiMonthlyOption;
    };
type ApiWeekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type ApiMonthlyOption = [0] | [1];

type ServiceError = { json: { message?: string } };

const apiToModel = ({ repeatEvery, repeatOn, ...rest }: ApiScheduledReport): ScheduledReport => {
  switch (repeatEvery) {
    case "week": {
      const weekdays = repeatOn.map(
        (n) =>
          ((
            {
              0: "sunday",
              1: "monday",
              2: "tuesday",
              3: "wednesday",
              4: "thursday",
              5: "friday",
              6: "saturday",
            } as const
          )[n])
      );

      return {
        ...rest,
        repeat: {
          every: "week",
          on: weekdays,
        },
      };
    }
    case "month": {
      return {
        ...rest,
        repeat: {
          every: "month",
          on: repeatOn[0] === 0 ? "1st-day" : "15th-day",
        },
      };
    }
    default: {
      const err: never = repeatEvery;
      throw new TypeError("Unhandled case: ", err);
    }
  }
};

const scheduleRepeatToApi = (repeat: ScheduleRepeat): ApiRepeat => {
  const { every } = repeat;

  switch (every) {
    case "week": {
      const apiWeekdays = repeat.on.map(
        (weekday) =>
          ((
            {
              sunday: 0,
              monday: 1,
              tuesday: 2,
              wednesday: 3,
              thursday: 4,
              friday: 5,
              saturday: 6,
            } as const
          )[weekday])
      );

      return { repeatEvery: repeat.every, repeatOn: apiWeekdays };
    }
    case "month": {
      const apiMonthDay: [0] | [1] = repeat.on === "1st-day" ? [0] : [1];
      return { repeatEvery: repeat.every, repeatOn: apiMonthDay };
    }
    default: {
      const err: never = every;
      throw new TypeError("Unhandled case: ", err);
    }
  }
};

export const fetchScheduledReports = createAsyncThunk<
  ScheduledReport[],
  void,
  { rejectValue: string }
>("scheduledReports/fetch", async (_, { rejectWithValue }) => {
  try {
    const { payload } = (await CustomerManagement.GET("/scheduled-reports")) as {
      payload: ApiScheduledReport[];
    };

    const scheduledReports = payload.map(apiToModel);

    return scheduledReports;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const createScheduledReport = createAsyncThunk<
  ScheduledReport,
  { report: ScheduledReportParams; exportedReportId: string },
  { rejectValue: string }
>("scheduledReports/create", async ({ report, exportedReportId }, { rejectWithValue }) => {
  const { repeat, ...rest } = report;
  try {
    const apiRepeat = scheduleRepeatToApi(repeat);
    const body: ApiScheduledReportParams & { exportedReportID: string; active: boolean } = {
      ...apiRepeat,
      ...rest,
      exportedReportID: exportedReportId,
      active: true,
    };

    const { payload } = (await CustomerManagement.POST("/scheduled-reports", body)) as {
      payload: ApiScheduledReport;
    };

    const scheduledReport = apiToModel(payload);

    return scheduledReport;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const updateScheduledReport = createAsyncThunk<
  ScheduledReport,
  { report: ScheduledReportParams; id: string },
  { rejectValue: string }
>("scheduledReports/update", async ({ report, id }, { rejectWithValue }) => {
  const { repeat, ...rest } = report;
  const apiRepeat = scheduleRepeatToApi(repeat);
  const body: ApiScheduledReportParams = {
    ...apiRepeat,
    ...rest,
  };

  try {
    const { payload } = (await CustomerManagement.PUT(`/scheduled-reports/${id}`, body)) as {
      payload: ApiScheduledReport;
    };

    const scheduledReport = apiToModel(payload);

    return scheduledReport;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const archiveScheduledReport = createAsyncThunk<string, string, { rejectValue: string }>(
  "scheduledReports/archive",
  async (id, { rejectWithValue }) => {
    try {
      await CustomerManagement.DELETE(`/scheduled-reports/${id}`, undefined);

      return id;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

const scheduledReportsAdapter = createEntityAdapter<ScheduledReport>();

interface ScheduledReportsState extends EntityState<ScheduledReport> {
  listStatus: "idle" | "loading";
  formStatus: "idle" | "loading";
}

const initialState: ScheduledReportsState = {
  ...scheduledReportsAdapter.getInitialState(),
  listStatus: "idle",
  formStatus: "idle",
};

const scheduledReportsSlice = createSlice({
  name: "scheduledReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchScheduledReports.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(fetchScheduledReports.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        scheduledReportsAdapter.setAll(draftState, action.payload);
      })
      .addCase(fetchScheduledReports.rejected, (draftState) => {
        draftState.listStatus = "idle";
      })

      // Create
      .addCase(createScheduledReport.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(createScheduledReport.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        scheduledReportsAdapter.addOne(draftState, action.payload);
      })
      .addCase(createScheduledReport.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })

      // Update
      .addCase(updateScheduledReport.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(updateScheduledReport.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        scheduledReportsAdapter.setOne(draftState, action.payload);
      })
      .addCase(updateScheduledReport.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })

      // Archive (functions as a delete in the UI)
      .addCase(archiveScheduledReport.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(archiveScheduledReport.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        scheduledReportsAdapter.removeOne(draftState, action.payload);
      })
      .addCase(archiveScheduledReport.rejected, (draftState) => {
        draftState.listStatus = "idle";
      });
  },
});

export default scheduledReportsSlice.reducer;

export const scheduledReportsSelectors = scheduledReportsAdapter.getSelectors<RootState>(
  (state) => state.reportingModule.scheduledReports
);
