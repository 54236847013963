import { createAction } from "@reduxjs/toolkit";
import { UserManagement } from "~/services";
import selectors from "../selectors";

export const fetchAuditsBegin = createAction("FETCH_AUDITS_BEGIN");
export const fetchAuditsError = createAction("FETCH_AUDITS_ERROR");
export const fetchAuditsSuccess = createAction("FETCH_AUDITS_SUCCESS");

export const saveAuditsBegin = createAction("SAVE_AUDITS_BEGIN");
export const saveAuditsSuccess = createAction("SAVE_AUDITS_SUCCESS");
export const saveAuditsError = createAction("SAVE_AUDITS_ERROR");
export const updateAudits = createAction("UPDATE_AUDITS");

export const resetModifications = createAction("RESET_MODIFICATIONS");

export const fetchAudits = (faid) => async (dispatch) => {
  dispatch(fetchAuditsBegin());
  try {
    const query = UserManagement.gqlBuilder(`query audits {
            audits(faid: "${faid}") {
                name,
                level,
                restart,
                client,
                facility,
                icon,
                auditrate
            }
        }`);
    const response = await UserManagement.QUERY(query);
    const data = response.payload.data.audits || [];
    dispatch(fetchAuditsSuccess(data));
    return response;
  } catch (error) {
    dispatch(fetchAuditsError(error));
    return error;
  }
};

export const onUpdateAudits = (number, auditName) => (dispatch) => {
  dispatch(updateAudits({ number, auditName }));
};

export const onResetModifications = () => (dispatch) => {
  dispatch(resetModifications());
};

export const onSaveAudits = () => async (dispatch, getState) => {
  const state = getState();
  const audits = selectors.audits.getUpdatedAudits(state);

  dispatch(saveAuditsBegin());
  try {
    await UserManagement.PUT(`/audits`, audits);
    dispatch(saveAuditsSuccess());
    return;
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(saveAuditsError({ message }));
    return e;
  }
};
