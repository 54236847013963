import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "../actions";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
  // { [`${faid}${dpid}`]: deployment }
  pendingArchival: {},
};
export default createReducer(initialState, {
  [moduleActions.deployments.fetchDeploymentsBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.deployments.fetchDeploymentsSuccess]: (state, action) => {
    state.items = action.payload.facilities;
    state.isLoading = false;
  },
  [moduleActions.deployments.fetchDeploymentsError]: (state, action) => {
    state.errors = action.payload.error;
    state.isLoading = false;
  },
  [moduleActions.deployments.archiveDeploymentBegin]: (state, { payload }) => {
    const { faid, dpid } = payload;
    const facility = state.items.find((facility) => facility.faid === faid);
    const index = facility.deployments.findIndex((deployment) => deployment.dpid === dpid);
    const deployment = facility.deployments[index];

    // set the deployment aside until the request resolves sucessfully
    state.pendingArchival[`${faid}${dpid}`] = deployment;

    // remove the deployment
    facility.deployments.splice(index, 1);
  },
  [moduleActions.deployments.archiveDeploymentSuccess]: (state, { payload }) => {
    const { faid, dpid } = payload;
    delete state.pendingArchival[`${faid}${dpid}`];
  },
  [moduleActions.deployments.archiveDeploymentError]: (state, { payload }) => {
    const { faid, dpid } = payload;
    const deployment = state.pendingArchival[`${faid}${dpid}`];
    delete state.pendingArchival[`${faid}${dpid}`];
    state.items.find((facility) => facility.faid === faid).deployments.push(deployment);
  },
});
