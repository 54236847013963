import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./ClientLogo.component.scss";

const ClientLogo = ({ clientLogo, clientName }) => (
  <div className="client-logo">
    <Link to="/home" className="block">
      {clientLogo.length > 0 ? (
        <img className="logo-icon" src={clientLogo} alt={clientName} />
      ) : (
        <span className="logo-icon icon-asci-logo" />
      )}
    </Link>
  </div>
);

ClientLogo.propTypes = {
  clientLogo: PropTypes.string,
  clientName: PropTypes.string,
};

ClientLogo.defaultProps = {
  clientLogo: "",
  clientName: "",
};

export default ClientLogo;
