import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import { Modal } from "antd";

import { Page, SectionHeader, AsciButton, AsciSpinner, InputSearch, Column } from "~/global";
import {
  CustomFieldsList,
  CustomFieldEditor,
  CustomFieldEditorButtons,
} from "~/custom-fields-editor";

import actions from "~/actions";
import selectors from "~/selectors";

import "./CustomFieldsPage.component.scss";

const CustomFieldsPage = ({
  customFields,
  deleteCustomField,
  fetchCustomFields,
  isLoading,
  showDialog,
}) => {
  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useState("");
  const [filteredCustomFields, setFilteredCustomFields] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    fetchCustomFields();
  }, []);

  useEffect(() => {
    if (customFields != null && nameFilter.length === 0) {
      setFilteredCustomFields(customFields);
    }
  }, [customFields]);

  const filterList = (c) => {
    const value = c.toLowerCase();
    if (value.length > 0) {
      const filteredCustomFields = (customFields || []).filter((customfield) => {
        const displayNameEn = customfield.displayNameEn.toLowerCase();
        const found = displayNameEn.indexOf(value) > -1;
        return found;
      });
      setFilteredCustomFields(filteredCustomFields);
    } else {
      setFilteredCustomFields(customFields);
    }
  };

  const onAddField = () => {
    showDialog({
      title: "Add Custom Field",
      width: "calc(100% - 20px)",
      modal: true,
      content: <CustomFieldEditor isNew />,
      footer: (close) => (
        <CustomFieldEditorButtons
          isNew
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  const errorCustomFields = (errors, name) => ({
    title: `Error with this custom field: ${name}`,
    content: errors,
  });

  const onDeleteField = (field) => {
    showDialog({
      title: "Delete Custom Field",
      content: (close) => (
        <div className="layout-column">
          <p className="align-center">Are you sure you want to delete this custom field?</p>

          <div className="dialog-actions layout-row layout-row--end">
            <AsciButton color="white" onClick={close}>
              Cancel
            </AsciButton>

            <AsciButton
              color="red"
              onClick={async () => {
                close();
                try {
                  await deleteCustomField(field.name);
                  fetchCustomFields();
                } catch (e) {
                  modal.error(errorCustomFields(e.errors, field.name));
                }
              }}
            >
              Delete
            </AsciButton>
          </div>
        </div>
      ),
    });
  };

  if (customFields == null) {
    return <AsciSpinner visible />;
  }

  return (
    <Column className="custom-fields-page full-width" flex align="start center">
      <Page>
        <div className="custom-fields-table">
          <SectionHeader title="Custom Fields List">
            <div className="push-right row centered">
              <InputSearch
                onChange={(value) => filterList(value)}
                initialValue={nameFilter}
                placeholder="Search Field"
                trim
              />
              <AsciButton color="green" onClick={onAddField}>
                Add Field
              </AsciButton>
            </div>
          </SectionHeader>

          <CustomFieldsList
            customFields={filteredCustomFields}
            onEdit={(field) => navigate(`/custom-fields/editor/${field.name}`)}
            onDelete={onDeleteField}
            isLoading={isLoading}
          />
          <AsciSpinner visible={isLoading} />
        </div>
      </Page>
      {contextHolder}
    </Column>
  );
};

const stateToProps = (state) => ({
  customFields: selectors.customFields.getFlatCustomFields(state),
  isLoading: state.customFields.isLoading,
});

const dispatchToProps = {
  showDialog: actions.dialog.show,
  fetchCustomFields: actions.customFields.fetchCustomFields,
  deleteCustomField: actions.customFields.deleteCustomField,
};

export default connect(stateToProps, dispatchToProps)(CustomFieldsPage);
