import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Select } from "antd";
import { FontIcon, TextField } from "react-md";

import {
  actions as workstationActions,
  selectors as workstationSelectors,
} from "~/workstation/redux";
import { AsciButton } from "~/global";

import "./TaskSender.scss";
import {
  EXECUTE,
  GATHER_LOGS,
  LOGOUT,
  SCREENSHOT,
  SEND_MESSAGE,
  UPDATE_WORKSTATION,
} from "~/workstation/taskTypes";
import TaskType from "~/workstation/components/common/TaskType";

const OPTIONS = [
  { value: GATHER_LOGS, label: <TaskType type={GATHER_LOGS} /> },
  { value: UPDATE_WORKSTATION, label: <TaskType type={UPDATE_WORKSTATION} /> },
  { value: LOGOUT, label: <TaskType type={LOGOUT} /> },
  { value: SEND_MESSAGE, label: <TaskType type={SEND_MESSAGE} /> },
  { value: SCREENSHOT, label: <TaskType type={SCREENSHOT} /> },
  { value: EXECUTE, label: <TaskType type={EXECUTE} /> },
];

const TaskPayloadModalField = (props) => {
  const { label, children, ...rest } = props;
  return (
    <div className="payload-field" {...rest}>
      <div className="payload-label">{label}</div>
      <div className="payload-body">{children}</div>
    </div>
  );
};

const EmptyTaskContent = () => <></>;

const SendMessageTaskContent = (props) => {
  const { setTaskData } = props;
  return (
    <div>
      <TaskPayloadModalField label="Message">
        <TextField onChange={(e) => setTaskData("message", e)} />
      </TaskPayloadModalField>
      <TaskPayloadModalField label="Title">
        <TextField onChange={(e) => setTaskData("title", e)} />
      </TaskPayloadModalField>
      <TaskPayloadModalField label="Choices" title="Separated with semicolons (;)">
        <TextField onChange={(e) => setTaskData("options", e)} />
      </TaskPayloadModalField>
    </div>
  );
};

const ExecuteTaskContent = (props) => {
  const { setTaskData } = props;

  return (
    <div>
      <TaskPayloadModalField label="Command">
        <TextField onChange={(e) => setTaskData("command", e)} />
      </TaskPayloadModalField>
    </div>
  );
};

const GatherLogTaskContent = (props) => {
  const { setTaskData } = props;
  return (
    <div>
      <TaskPayloadModalField label="Extra files" title="comma separated">
        <TextField onChange={(e) => setTaskData("extraFiles", e)} />
      </TaskPayloadModalField>
    </div>
  );
};

const TaskSender = (props) => {
  const { workstationIds, btnLabel } = props;

  const dispatch = useDispatch();

  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [taskPayload, setTaskPayload] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const taskSent = useSelector(workstationSelectors.taskSender.isFetched);

  useEffect(() => {
    if (taskSent) {
      dispatch(workstationActions.tasks.fetchReset());
      dispatch(workstationActions.taskSender.fetchReset());
      setTaskPayload({});
      setSelectedTaskType(null);
    }
  }, [dispatch, taskSent]);

  const setTaskData = (key, data) => {
    setTaskPayload({ ...taskPayload, [key]: data });
  };

  const onSubmitTask = () => {
    dispatch(workstationActions.taskSender.fetch(workstationIds, selectedTaskType, taskPayload));
    setSelectedTaskType(null);
    setIsModalVisible(false);
  };
  const onCancel = () => {
    setSelectedTaskType(null);
    setIsModalVisible(false);
  };

  const onOpenModal = () => setIsModalVisible(true);

  const modalContent = useMemo(() => {
    switch (selectedTaskType) {
      case SEND_MESSAGE:
        return <SendMessageTaskContent setTaskData={setTaskData} />;
      case EXECUTE:
        return <ExecuteTaskContent setTaskData={setTaskData} />;
      case GATHER_LOGS:
        return <GatherLogTaskContent setTaskData={setTaskData} />;
      default:
        return <EmptyTaskContent />;
    }
  }, [selectedTaskType, taskPayload]);

  const canSend = useMemo(() => selectedTaskType, [selectedTaskType]);

  return (
    <>
      <AsciButton onClick={onOpenModal} color="blue" disabled={workstationIds.length === 0}>
        <FontIcon iconClassName="icon-tasks" />
        &nbsp;{btnLabel}
      </AsciButton>
      <Modal
        onOk={onSubmitTask}
        onCancel={onCancel}
        open={isModalVisible}
        okText="Send"
        okButtonProps={{ disabled: !canSend }}
        className="task-modal"
      >
        <label>
          Task Type
          <Select
            placeholder="Task type"
            onChange={(e) => setSelectedTaskType(e)}
            style={{ width: "15rem", marginLeft: "1rem" }}
            value={selectedTaskType}
          >
            {OPTIONS.map((option) => (
              <Select.Option value={option.value} key={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </label>
        {modalContent}
      </Modal>
    </>
  );
};

TaskSender.defaultProps = {
  btnLabel: "New task",
};

TaskSender.propTypes = {
  workstationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  btnLabel: PropTypes.string,
};

export default TaskSender;
