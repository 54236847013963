import React from "react";
import { DataTable, TableHeader, TableBody, TableRow, TableColumn } from "react-md";
import { useTranslation } from "~/i18n";

import { Row, Column } from "~/global";
import { CustomFields } from "../../models/teams.slice";

import "./TeamCustomFields.component.scss";

type Items<T> = {
  items?: T[];
  status: "idle" | "pending" | "succeeded" | "failed";
  error?: string;
};

type Props = {
  customFieldsData?: Items<CustomFields>;
};
const TeamCustomFields = ({ customFieldsData }: Props) => {
  const { t } = useTranslation("dashboard");

  if (
    customFieldsData?.error ||
    !customFieldsData?.items ||
    customFieldsData?.items?.length === 0
  ) {
    return (
      <Column className="team-custom-fields">
        <Row className="no-data" align="center center">
          <i className="icon-ban" />
          {customFieldsData?.error || t("no-data-to-be-displayed")}
        </Row>
      </Column>
    );
  }

  return (
    <Column className="team-custom-fields">
      <Row className="title" align="space-around">
        {t("custom-fields")}
      </Row>

      <Row align="space-around">
        <DataTable className="custom-fields-table" plain>
          <TableHeader>
            <TableRow>
              <TableColumn className="text-centered">{t("key")}</TableColumn>
              <TableColumn className="text-centered">{t("value")}</TableColumn>
              <TableColumn className="text-centered">{t("count")}</TableColumn>
              <TableColumn className="text-centered">%</TableColumn>
            </TableRow>
          </TableHeader>

          <TableBody>
            {customFieldsData?.items?.map((customField, index) => (
              <TableRow key={index}>
                <TableColumn className="text-centered">{customField.name}</TableColumn>
                <TableColumn className="text-centered">{customField.value}</TableColumn>
                <TableColumn className="text-centered">{customField.count}</TableColumn>
                <TableColumn className="text-centered">{customField.percent}</TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </Row>
    </Column>
  );
};

export default TeamCustomFields;
