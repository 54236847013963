import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import produce from "immer";
import PropTypes from "prop-types";

import actions from "~/actions";
import { AsciButton, ContentPanel, Checkbox } from "~/global";

import TestDefinitionFailCodesSelectionDialog from "./TestDefinitionFailCodesSelectionDialog.component";

import "./TestDefinitionFailCodesEditor.component.scss";

const TestDefinitionFailCodesEditor = ({ failCodes, onChange, showDialog, config }) => {
  const MAX_FAIL_CODES_PER_TEST_CONFIGURATION = 18;
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [config]);

  const onToggleAll = () => {
    let newSelectedIds;

    if (selectedIds.length === failCodes.length) {
      newSelectedIds = [];
    } else {
      newSelectedIds = failCodes.map((f) => f.id);
    }

    setSelectedIds(newSelectedIds);
  };

  const onToggle = (failCode) => {
    const newSelectedIds = produce(selectedIds, (draft) => {
      const index = draft.indexOf(failCode.id);
      if (index > -1) {
        draft.splice(index, 1);
      } else {
        draft.push(failCode.id);
      }
    });

    setSelectedIds(newSelectedIds);
  };

  const removeSelectedFailCodes = () => {
    onChange(failCodes.filter((f) => !isFailCodeChecked(f)).map((f) => f.id));

    setSelectedIds([]);
  };

  const isFailCodeChecked = (failCode) => selectedIds.indexOf(failCode.id) > -1;

  const areAllChecked = () =>
    failCodes.reduce((acc, failCode) => isFailCodeChecked(failCode) && acc, true);

  const showSelectionDialog = () => {
    showDialog({
      width: "1200px",
      veryLarge: true,
      noPadding: true,
      noMargin: true,
      modal: true,
      content: (close) => (
        <TestDefinitionFailCodesSelectionDialog
          initialSelectedFailCodeIds={failCodes.map((fc) => fc.id)}
          onClose={close}
          onConfirm={(ids) => {
            onChange(ids);
            close();
          }}
        />
      ),
    });
  };

  return (
    <div className="test-definition-fail-codes-editor">
      <ContentPanel>
        <header className="layout-row layout-row--start-center">
          <div className="col-check">
            {failCodes.length > 0 ? (
              <Checkbox
                id="checkbox-all-fail-codes"
                isChecked={areAllChecked()}
                onChange={onToggleAll}
              />
            ) : null}
          </div>

          <div className="col-category">Category</div>

          <div className="col-name">Code</div>

          <div className="col-desc">Description</div>

          <div className="col-ff">Fail Fast</div>

          <div className="col-desktop">Show on Desktop</div>
        </header>

        <main className="layout-column padding-v--15 padding-h--10">
          {failCodes.length === 0 ? (
            <div className="layout-column layout-column--center-center">
              No fail codes associated with this test configuration.
            </div>
          ) : null}

          {failCodes.map((failCode, index) => (
            <div className="fail-code layout-row layout-row--start-center" key={index}>
              <div className="col-check">
                <Checkbox
                  id={`checkbox-failcode-${failCode.id}`}
                  isChecked={isFailCodeChecked(failCode)}
                  onChange={() => onToggle(failCode)}
                />
              </div>

              <div className="col-category">{(failCode.categoryName || {}).en}</div>

              <div className="col-name">{failCode.name}</div>

              <div className="col-desc">{(failCode.description || {}).en}</div>

              <div className="col-ff">{failCode.failFast ? "Yes" : "No"}</div>

              <div className="col-desktop">{failCode.showOnDesktop ? "Yes" : "No"}</div>
            </div>
          ))}
        </main>

        <footer className="layout-row layout-row--end-center">
          <AsciButton disabled={selectedIds.length === 0} onClick={removeSelectedFailCodes}>
            Remove
          </AsciButton>

          <AsciButton
            disabled={failCodes.length >= MAX_FAIL_CODES_PER_TEST_CONFIGURATION}
            onClick={showSelectionDialog}
          >
            Select New
          </AsciButton>
        </footer>
      </ContentPanel>
    </div>
  );
};

TestDefinitionFailCodesEditor.propTypes = {
  failCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      categoryName: PropTypes.object, // localizable
      name: PropTypes.string,
      description: PropTypes.object, // localizable
      failFast: PropTypes.bool,
      showOnDesktop: PropTypes.bool,
      route: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

const mapDispatchToProps = {
  showDialog: actions.dialog.show,
};

export default connect(null, mapDispatchToProps)(TestDefinitionFailCodesEditor);
