import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "./Timestamp.scss";

const Timestamp = (props) => {
  const { value, inline } = props;
  const formattedDate = useMemo(() => moment(value).format("LL"), [value]);
  const formattedTime = useMemo(() => moment(value).format("LTS"), [value]);

  const css = useMemo(() => {
    const c = ["asci-timestamp"];
    if (inline) {
      c.push("asci-timestamp-inline");
    }
    return c.join(" ");
  }, [inline]);

  return (
    <div className={css}>
      <div className="asci-date">{formattedDate}</div>
      <div className="asci-time">{formattedTime}</div>
    </div>
  );
};

Timestamp.defaultProps = {};

Timestamp.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool,
};

export default Timestamp;
