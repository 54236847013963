import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import "./MarqueeText.component.scss";

const MarqueeText = ({ text }) => {
  const [isOverflowActive, setIsOverflowActive] = useState(false);
  const textContainer = useRef(null);

  useEffect(() => {
    isEllipsisActive(textContainer.current);
  }, []);

  const isEllipsisActive = (element) => {
    if (element != null) {
      setIsOverflowActive(
        element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
      );
    }
  };

  return (
    <div
      className={isOverflowActive ? "marquee-active" : "marquee"}
      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      onMouseEnter={() => isEllipsisActive(textContainer.current)}
      ref={textContainer}
    >
      <div className="marquee-text" style={{ animationDuration: `${text.length / 10}s` }}>
        {text}
      </div>
    </div>
  );
};

MarqueeText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

MarqueeText.defaultProps = {
  text: "",
};

export default MarqueeText;
