import DeviceTestArgument from "./DeviceTestArgument.model";

export default class TestDefinition {
  static INCLUSION_TYPE_INCLUDE = "include";
  static INCLUSION_TYPE_EXCLUDE = "exclude";

  static parse = (data = {}) => {
    const definition = {
      className: data.className,
      displayName: data.displayName,
      identifier: data.identifier,
      identifierDisplayName: data.identifierDisplayName,
      platform: data.platform,
      description: data.description,
      image: data.image,
      arguments: (data.arguments || []).map((data) => DeviceTestArgument.parse(data)),
      failCodes: data.failCodes || [],
      inclusionType: data.inclusionType,
      targetModels: data.targetModels || [],
    };

    return definition;
  };

  static toServerFormat(definition = {}) {
    const serverFormat = {
      className: definition.className,
      identifier: definition.identifier,
      identifierDisplayName: definition.identifierDisplayName,
      platform: definition.platform,
      displayName: definition.displayName,
      description: definition.description,
      image: definition.image,
      arguments: (definition.arguments || []).map((arg) => DeviceTestArgument.toServerFormat(arg)),
      failCodes: definition.failCodes || [],
      inclusionType: definition.inclusionType || "",
      targetModels: definition.targetModels || [],
    };

    return serverFormat;
  }
}
