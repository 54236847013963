import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const DataImportIcon = ({ size, customId }) => (
  <SVGIcon viewBox="0 0 36.13 35.99" size={size}>
    <defs>
      <linearGradient id={customId} y1="17.5" x2="27.61" y2="17.5" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.2" stopColor="#96c934" />
        <stop offset="0.36" stopColor="#8cbf37" />
        <stop offset="0.51" stopColor="#7aad3d" />
        <stop offset="0.65" stopColor="#629545" />
        <stop offset="0.78" stopColor="#437650" />
        <stop offset="0.91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>

    <g>
      <g>
        <g>
          <path
            fill="#93d500"
            d="M21.38,19.31,18,22.64a.72.72,0,0,0,0,1L21.37,27a.7.7,0,0,0,1,0h0a.71.71,0,0,0,0-1l-2.15-2.21H30.29a.7.7,0,0,0,.71-.7.71.71,0,0,0-.71-.71h-10l2.14-2.12a.71.71,0,0,0,.21-.5h0a.71.71,0,0,0-1.21-.5Z"
          />
        </g>
      </g>

      <g>
        <path
          fill={`url(#${customId})`}
          d="M27.11,28.33a.5.5,0,0,0-.51.5c0,2.8-6,5.16-13.16,5.16C6,34,1,31.11,1,28.42V23.73c2.12,1.7,7.24,2.6,12.14,2.6.87,0,1.74,0,2.58-.08a.51.51,0,0,0,.48-.54.48.48,0,0,0-.54-.47c-.82.05-1.67.08-2.52.08C6.2,25.32,1,23.55,1,22V15.05c2.18,1.72,7.55,2.64,12.68,2.64,6.64,0,13.69-1.52,13.69-4.35v-9c0-2.82-7-4.35-13.69-4.35S0,1.53,0,4.35V28.42C0,32.05,6,35,13.44,35c8,0,14.17-2.71,14.17-6.17A.5.5,0,0,0,27.11,28.33ZM13.69,16.68C5.84,16.68,1,14.73,1,13.34V6.07C3.19,7.79,8.56,8.71,13.69,8.71S24.2,7.79,26.37,6.07v7.27C26.37,14.73,21.55,16.68,13.69,16.68ZM13.69,1c7.86,0,12.68,2,12.68,3.34S21.55,7.69,13.69,7.69,1,5.75,1,4.35,5.84,1,13.69,1Z"
        />
      </g>
    </g>
  </SVGIcon>
);

DataImportIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default DataImportIcon;
