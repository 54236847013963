import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchAllFiltersBegin = createAction("FETCH_ALL_FILTERS_BEGIN");
export const fetchAllFiltersSuccess = createAction("FETCH_ALL_FILTERS_SUCCESS");
export const fetchAllFiltersError = createAction("FETCH_ALL_FILTERS_ERROR");

export const fetchAllFilters = () => async (dispatch) => {
  const queryFacilities = CustomerManagement.gqlBuilder(`query FiltersFacilities {
        facilities {
            name,
            faid,
            timezone,
            deployments {
                name,
                dpid,
                programs,
                shifts {
                    name,
                    id
                }
            }
        }
    }`);

  const queryPrograms = CustomerManagement.gqlBuilder(`query FiltersPrograms {
        programs {
            displayName { en },
            identifier,
            serviceSuites {
                displayName { en },
                identifier
            }
        }
    }`);

  const queryTeams = CustomerManagement.gqlBuilder(`query FiltersTeams{
        teams {
            name,
            teamid,
            faid
        }
    }`);

  dispatch(fetchAllFiltersBegin());

  try {
    const response = await Promise.all([
      CustomerManagement.QUERY(queryFacilities),
      CustomerManagement.QUERY(queryPrograms),
      CustomerManagement.QUERY(queryTeams),
    ]);

    const facilities = (((response[0] || {}).payload || {}).data || {}).facilities || [];
    const programs = (((response[1] || {}).payload || {}).data || {}).programs || [];
    const teams = (((response[2] || {}).payload || {}).data || {}).teams || [];
    const filters = { facilities, programs, teams };

    dispatch(fetchAllFiltersSuccess({ filters }));
  } catch (e) {
    if (e instanceof Error) throw e;

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(fetchAllFiltersError({ errors: message }));
  }
};
