import { createSelector } from "@reduxjs/toolkit";

const getFailCodes = (state) => state.failCodes.items;
const getFailCategories = (state) => state.failCodeCategories.items;
const getCustomFields = (state) => state.customFields.items;

export const getCleanFailCategories = createSelector([getFailCategories], (failCategories) => {
  const cleanFailCategories = failCategories.reduce(
    (acc, failCategory) => acc.concat(failCategory.name.en),
    []
  );

  return ["Select...", ...cleanFailCategories];
});

export const getFlattenFailCodes = createSelector([getFailCodes], (failCodes) => {
  const flattenFailCodes = failCodes.reduce((acc, failCode) => {
    const flat = {
      category: failCode.categoryName.en,
      name: failCode.name,
      description: failCode.description.en,
    };
    return acc.concat(flat);
  }, []);

  return flattenFailCodes;
});

export const getCustomFieldsList = createSelector([getCustomFields], (customFields) => {
  const customFieldsList = [{ name: "Select...", value: "", type: "", items: [] }];

  customFields.forEach((cf) => {
    const customField = {
      name: cf.name,
      value: "",
      type: cf.type,
      items: cf.items,
    };
    customFieldsList.push(customField);
  });

  return customFieldsList;
});
