import { createAction } from "@reduxjs/toolkit";

import type { AppDispatch } from "~/store";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

export const logout = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("session");

  dispatch(logoutSuccess());
};

export const setCurrentApp = createAction<string>("SET_CURRENT_APP");
