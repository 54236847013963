import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  items: {},
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [actions.countries.fetchCountriesBegin]: (state, action) => {
    state.items = [];
  },
  [actions.countries.fetchCountriesSuccess]: (state, action) => {
    state.items = action.payload.countries;
  },
  [actions.countries.fetchCountriesError]: (state, action) => {
    state.items = [];
  },
});
