import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const deleteLabelBegin = createAction("labelLister/deleteLabelBegin");
export const deleteLabelSuccess = createAction("labelLister/deleteLabelSuccess");
export const deleteLabelError = createAction("labelLister/deleteLabelError");

export const deleteLabel = (labelId) => (dispatch) => {
  dispatch(deleteLabelBegin());

  return CustomerManagement.DELETE("/labels", { _id: labelId })
    .then(() => dispatch(deleteLabelSuccess()))
    .catch((error) => dispatch(deleteLabelError(error)));
};
