import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { FontIcon } from "react-md";
import InspectorSection from "./InspectorSection";
import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import ColorInput from "./ColorInput";
import FontSelect from "./FontSelect";

import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";
import GroupedButtons from "~/label-printer/components/designer/inspector/commons/GroupedButtons";

const FontInspector = (props) => {
  const {
    setterFactory,
    fontFamily,
    textAlign,
    fontSize,
    fontWeight,
    fontStyle,
    textDecoration,
    level,
    color,
  } = props;

  const selectedDecoration = useMemo(() => {
    const selected = [];
    if (fontWeight) {
      selected.push("fontWeight");
    }
    if (fontStyle) {
      selected.push("fontStyle");
    }
    if (textDecoration) {
      selected.push("textDecoration");
    }
    return selected;
  }, [fontWeight, fontStyle, textDecoration]);

  const onSelectDecos = (deco) => {
    const isUnselected = selectedDecoration.includes(deco);
    if (isUnselected) {
      setterFactory(deco)(null);
      return;
    }
    let value;
    switch (deco) {
      case "fontWeight":
        value = "bold";
        break;
      case "fontStyle":
        value = "italic";
        break;
      case "textDecoration":
        value = "underline";
        break;
    }
    setterFactory(deco)(value);
  };

  return (
    <InspectorSection title="Font" level={level} initialFolded>
      <InspectorInput label="Font Family">
        <FontSelect value={fontFamily} setter={setterFactory("fontFamily")} />
      </InspectorInput>
      <InspectorInput label="Font Size">
        <NumberInput min={1} onChange={setterFactory("fontSize")} value={fontSize} unit="pt" />
      </InspectorInput>
      <InspectorInput label="Decorations">
        <div style={{ display: "flex" }}>
          <GroupedButtons
            options={[
              {
                label: (
                  <div style={{ fontWeight: "bold", width: "1.25rem", textAlign: "center" }}>B</div>
                ),
                value: "fontWeight",
              },
              {
                label: (
                  <div style={{ fontStyle: "italic", width: "1.25rem", textAlign: "center" }}>
                    I
                  </div>
                ),
                value: "fontStyle",
              },
              {
                label: (
                  <div
                    style={{ textDecoration: "underline", width: "1.25rem", textAlign: "center" }}
                  >
                    U
                  </div>
                ),
                value: "textDecoration",
              },
            ]}
            selected={selectedDecoration}
            onSelectOption={onSelectDecos}
          />
        </div>
      </InspectorInput>
      <InspectorInput label="Alignment">
        <div style={{ display: "flex" }}>
          <GroupedButtons
            options={[
              { label: <FontIcon iconClassName="icon-align-left" />, value: "left" },
              { label: <FontIcon iconClassName="icon-align-center" />, value: "center" },
              { label: <FontIcon iconClassName="icon-align-right" />, value: "right" },
            ]}
            selected={textAlign ? [textAlign] : []}
            onSelectOption={setterFactory("textAlign")}
          />
        </div>
      </InspectorInput>
      <InspectorInput label="Color">
        <ColorInput color={color} onChange={setterFactory("color")} />
      </InspectorInput>
    </InspectorSection>
  );
};

FontInspector.defaultProps = {};

FontInspector.propTypes = {
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
  fontStyle: PropTypes.string,
  textDecoration: PropTypes.string,
  color: PropTypes.string,

  level: PropTypes.number,
  setterFactory: PropTypes.func.isRequired,
};

export default FontInspector;
