export type DashboardFiltersParams = {
  facilityID?: string;
  deploymentID?: string;
  shiftID?: string;
  teamID: string;
  programID?: string;
  configID?: string;
  timezone?: string | undefined;
  from?: string | undefined;
  to?: string | undefined;
};

export const helperString = (items: DashboardFiltersParams) =>
  Object.entries(items)
    .filter(([_key, value]) => value != null)
    // To get all results, an empty string must be sent to the backend
    .map(([key, value]) => [key, value === "all" ? "" : value])
    .map(([key, value]) => `${key}: "${value}"`)
    .join(", ");
