import React, { useMemo } from "react";
import {
  CalendarOutlined,
  DownloadOutlined,
  FilterOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tag, Typography } from "antd";
import { parseISO } from "date-fns";
import prettyBytes from "pretty-bytes";

import type { ExportedReport } from "~/actions/exportedReports.actions";
import { useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import selectors from "~/selectors";
import {
  ReportTypesByName,
  selectExportedReports,
  selectLoadingReports,
} from "~/selectors/reports.selectors";
import ExportedReportFilters from "./ExportedReportFilters.component";

import "./ExportedReports.component.scss";

const { Text } = Typography;

type Props = {
  reportTypesByName: ReportTypesByName;
  applyFilters: (report: ExportedReport, triggerSearch?: boolean) => void;
  onScheduleReport: (id: string) => void;
};

const ExportedReports = ({ reportTypesByName, applyFilters, onScheduleReport }: Props) => {
  const { t, language } = useTranslation("reporting");

  const facilitiesById = useAppSelector(selectors.facilities.getFacilitiesByIdAllOption);
  const areFacilitiesLoaded = useAppSelector((state) => state.facilities.items == null);
  const exportedReports = useAppSelector(selectExportedReports);
  const isLoadingReports = useAppSelector(selectLoadingReports);

  const timeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
    [language]
  );

  const renderName = (report: ExportedReport) =>
    t("common:multi", { dict: reportTypesByName[report.type]?.displayName }) ||
    report.name ||
    report.type;

  const renderSize = (report: ExportedReport) =>
    report.reportSize && report.reportSize != -1
      ? prettyBytes(report.reportSize, { locale: language })
      : t("table.not-applicable");

  return (
    <Table<ExportedReport>
      className="exported-reports"
      rowKey="_id"
      loading={
        isLoadingReports || areFacilitiesLoaded
          ? {
              indicator: <LoadingOutlined style={{ color: "#005288" }} />,
              size: "large",
            }
          : false
      }
      dataSource={exportedReports}
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: true,
      }}
      columns={[
        {
          dataIndex: "name",
          title: t("table.name"),
          sorter: (a, b) => renderName(a).localeCompare(renderName(b)),
          render: (_, report) => renderName(report),
        },
        {
          dataIndex: "format",
          title: t("table.format"),
          filters: [
            { text: "JSON", value: "json" },
            { text: "CSV", value: "csv" },
          ],
          onFilter: (value, report) => report.format === value,
          render: (format: string) => format.toUpperCase(),
        },
        {
          dataIndex: "requestedAt",
          title: t("table.date"),
          defaultSortOrder: "descend",
          sorter: (a, b) => a.requestedAt.localeCompare(b.requestedAt),
          render: (requestedAt: string) => timeFormat.format(parseISO(requestedAt)),
        },
        {
          dataIndex: "status",
          title: t("table.status"),
          filters: [
            { text: t("table.ready"), value: "ready" },
            { text: t("table.in-progress"), value: "inProgress" },
          ],
          onFilter: (value, report) => report.status === value,
          render: (status: string) =>
            status === "ready" ? (
              <Tag color="#5cd9a7">{t("table.ready")}</Tag>
            ) : (
              <Tag color="#f8ac1c">{t("table.in-progress")}</Tag>
            ),
        },
        {
          title: t("table.records-count-title"),
          render: (_, { _id, recordsCount, durationInMilliseconds }) => (
            <Space.Compact direction="vertical">
              <span data-testid={_id}>
                {recordsCount ? t("table.records-count-message", { recordsCount }) : ""}
              </span>
              <Text type="secondary">
                {durationInMilliseconds
                  ? t("table.duration-message", { durationInMilliseconds })
                  : ""}
              </Text>
            </Space.Compact>
          ),
        },
        {
          dataIndex: "expiryDate",
          title: t("table.expiry-date"),
          sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
          render: (expiryDate: string) => timeFormat.format(parseISO(expiryDate)),
        },
        {
          dataIndex: "filters",
          title: t("table.filters"),
          render: (_, report) => <ExportedReportFilters report={report} />,
        },
        {
          dataIndex: "faid",
          title: t("table.faid"),
          render: (faid?: string) =>
            faid != null
              ? facilitiesById[faid]?.name ?? t("table.not-applicable")
              : t("table.not-applicable"),
        },
        {
          dataIndex: "reportSize",
          title: t("table.size"),
          className: "text-right",
          render: (_, report) => renderSize(report),
        },
        {
          title: t("table.actions"),
          key: "actions",
          align: "right",
          render: (_, report) => (
            <Space>
              {(reportTypesByName[report.type]?.searchSupport ?? false) && (
                <Button
                  title={t("quick-search")}
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={() => {
                    applyFilters(report, true);
                  }}
                />
              )}

              {reportTypesByName[report.type] != null && (
                <>
                  <Button
                    title={t("apply-filters")}
                    shape="circle"
                    icon={<FilterOutlined />}
                    onClick={() => {
                      applyFilters(report);
                    }}
                  />

                  {/* Prevent historical data (18 months +) from being exported daily because
                      it costs more and there's no real use case for it. */}
                  {report.type !== "processRunsArchive" && (
                    <Button
                      title={t("schedule-report")}
                      shape="circle"
                      icon={<CalendarOutlined />}
                      onClick={() => {
                        onScheduleReport(report._id);
                      }}
                    />
                  )}
                </>
              )}

              <Button
                title={t("download-report")}
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  if (report.format === "json") {
                    window.open(report.reportUrl);
                  } else {
                    window.location.href = report.reportUrl;
                  }
                }}
                disabled={report.status !== "ready"}
              />
            </Space>
          ),
        },
      ]}
    />
  );
};

export default ExportedReports;
