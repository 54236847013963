import { createAction } from "@reduxjs/toolkit";
import i18n from "~/i18n";
import { UserManagement } from "~/services";

export const fetchUserProfileBegin = createAction("FETCH_USER_PROFILE_BEGIN");
export const fetchUserProfileSuccess = createAction("FETCH_USER_PROFILE_SUCCESS");
export const fetchUserProfileError = createAction("FETCH_USER_PROFILE_ERROR");
export const changeUserLanguageBegin = createAction("CHANGE_USER_LANGUAGE_BEGIN");
export const changeUserLanguageSuccess = createAction("CHANGE_USER_LANGUAGE_SUCCESS");
export const changeUserLanguageError = createAction("CHANGE_USER_LANGUAGE_ERROR");

export function changePassword(password) {
  return UserManagement.PUT("/user/pwd", { password });
}
export const changeUserLanguage = (language) => async (dispatch, getState) => {
  const state = getState();
  const { userId } = state.session?.user;
  if (!userId) {
    return;
  }

  dispatch(changeUserLanguageBegin());
  try {
    await UserManagement.PUT(`/user/${userId}/lng`, {
      user_metadata: { language },
    });

    dispatch(changeUserLanguageSuccess());
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(changeUserLanguageError({ error: e }));
  }
};

export const fetchUserProfile = (userId) => async (dispatch) => {
  dispatch(fetchUserProfileBegin());
  const query = UserManagement.gqlBuilder(`query User{
    user(id: "${userId}") {
      user_id,
      nickname,
      updateData {
        user_metadata {
          language,
          client,
          facility,
          roles {
            name,
            permissions {
              method,
              resource,
              service
            }
          }
        }
      }
    }
  }`);

  try {
    const response = await UserManagement.QUERY(query);
    const profile = response.payload.data.user;
    const userLang = profile.updateData.user_metadata.language;

    // sync user language to i18next if present
    if (userLang && i18n.language !== userLang) {
      i18n.changeLanguage(userLang);
    }

    // fallback to browser detection & save if language not found in metadata
    // never save cimode
    if (!userLang && i18n.language !== "cimode") {
      dispatch(changeUserLanguage(i18n.language));
    }

    // Set profile
    // TODO: modify at selector
    const user = {
      userId: profile.user_id,
      nickname: profile.nickname,
      initials: profile.nickname.substring(0, 2),
      csid: profile.updateData.user_metadata.client,
      facilities: profile.updateData.user_metadata.facilities,
      hasAllFacilitiesAccess: profile.updateData.user_metadata.hasAllFacilitiesAccess,
      operatorId: profile.updateData.user_metadata.operatorId,
    };
    // TODO: modify at selector
    const { roles } = profile.updateData.user_metadata;

    dispatch(fetchUserProfileSuccess({ user, roles }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchUserProfileError({ errors: e }));
  }
};
