import React from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { CANNED_REPORTS_COLORS } from "~/constants";

import "./BubbleChart.component.scss";

const BubbleChart = ({ title, data, xAxis, yAxis, zAxis, drillDown }) => {
  const itemIds = Object.keys(data);

  return (
    <div className="bubble-chart">
      <div className="chart-header">
        <div className="chart-title">{title}</div>
      </div>

      <ResponsiveContainer height="80%">
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey={xAxis.dataKey}
            name={xAxis.name}
            unit={xAxis.unit ? xAxis.unit : null}
            label={{ value: xAxis.name, position: "bottom" }}
          />
          <YAxis
            type="number"
            dataKey={yAxis.dataKey}
            name={yAxis.name}
            unit={yAxis.unit ? yAxis.unit : null}
            label={{
              value: yAxis.name,
              position: "insideLeft",
              angle: -90,
              style: { textAnchor: "middle" },
            }}
          />
          <ZAxis
            type="number"
            dataKey={zAxis.dataKey}
            name={zAxis.name}
            unit={zAxis.unit ? zAxis.unit : null}
            range={[500, 10000]}
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Legend verticalAlign="top" height={48} />
          {itemIds.map((id, index) => (
            <Scatter
              key={id}
              onClick={
                data[id].type !== "operator"
                  ? () => drillDown(id, data[id].faid, data[id].type)
                  : null
              }
              name={data[id].name}
              data={data[id].chartData}
              fill={CANNED_REPORTS_COLORS[index % 5]}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BubbleChart;
