import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

import labelSelectors from "~/label-printer/selectors";

export const labelSaveBegin = createAction("labelSaver/labelSaveBegin");
export const labelSaveSuccess = createAction("labelSaver/labelSaveSuccess");
export const labelSaveError = createAction("labelSaver/labelSaveError");
export const setSaved = createAction("labelSaver/setSaved");
export const labelLoadBegin = createAction("labelSaver/labelLoadBegin");
export const labelLoadSuccess = createAction("labelSaver/labelLoadSuccess");
export const labelLoadError = createAction("labelSaver/labelLoadError");
export const setLoadedLabelId = createAction("labelSaver/setLoadedLabelId");

export const saveLabel = () => (dispatch, getState) => {
  const state = getState();
  const saving = labelSelectors.labelSaver.isSaving(state);
  if (saving) {
    console.log("Already saving label!");
    return;
  }

  dispatch(labelSaveBegin());
  const label = state.labelPrinter.labelDesigner;
  const labelId = labelSelectors.labelSaver.getLoadedLabelId(state);

  let request;

  if (labelId) {
    request = CustomerManagement.PUT("/labels", { ...label, _id: labelId });
  } else {
    request = CustomerManagement.POST("/labels", label);
  }

  return request
    .then((rep) => dispatch(labelSaveSuccess({ labelId: rep.payload })))
    .catch((error) => dispatch(labelSaveError(error.json)));
};

export const loadLabel = (labelId) => (dispatch) => {
  dispatch(labelLoadBegin({ labelId }));
  const query = CustomerManagement.gqlBuilder(`{
        label(id: "${labelId}") {
            id,
            name,
            printer,
            shapes {
                identity,
                fieldType,
                fieldData,
                shapeData,
                layoutData
            },
            layoutName,
            layoutData,
            format,
            style
        }
    }`);

  return CustomerManagement.QUERY(query)
    .then((response) => {
      dispatch(labelLoadSuccess(response.payload.data.label));
      return response.payload.data.label;
    })
    .catch((error) => {
      console.error(error);
      dispatch(labelLoadError(error));
    });
};
