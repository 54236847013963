import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  items: [
    { value: "en", name: "English" },
    { value: "fr", name: "Français" },
    { value: "es", name: "Español" },
  ],
};

export default createReducer(initialState, {
  // Fetch user profile
  [actions.languages.fetchLanguagesSuccess]: (state, action) => {
    state.items = action.payload.languages;
  },
});
