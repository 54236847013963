import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import { CustomerManagement } from "~/services";
import { RootState } from "~/store";

type SkuStatic = {
  label: string;
  value: string;
  isDefault: boolean;
  isSkuAttribute: boolean;
  isAdditionalAttribute: boolean;
};

type ServiceError = { json: { message?: string } };

export const fetchSkuStatics = createAsyncThunk<SkuStatic[], void, { rejectValue: string }>(
  "skuStatics/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = (await CustomerManagement.GET("/inventory/sku-statics")) as {
        payload: SkuStatic[];
      };

      return response.payload;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

interface SkuStaticsState {
  items?: SkuStatic[];
  status: "idle" | "loading";
  error?: string;
}

const initialState: SkuStaticsState = {
  status: "loading",
};

const skuStaticsSlice = createSlice({
  name: "skuStatics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkuStatics.pending, (draftState) => {
        draftState.status = "loading";
      })
      .addCase(fetchSkuStatics.fulfilled, (draftState, action) => {
        draftState.status = "idle";
        draftState.items = action.payload;
      })
      .addCase(fetchSkuStatics.rejected, (draftState, action) => {
        draftState.status = "idle";
        draftState.error = action.payload;
      });
  },
});

export default skuStaticsSlice.reducer;

const selectSkuStatics = (state: RootState) => state.skuStatics.items;

export const selectSkuAttributes = createSelector([selectSkuStatics], (skuStatics) =>
  skuStatics?.filter((s) => s.isSkuAttribute)
);

export const selectSkuAttributesByValue = createSelector([selectSkuAttributes], (skuAttributes) =>
  keyBy(skuAttributes, "value")
);

export const selectAdditionalAttributes = createSelector([selectSkuStatics], (skuStatics) =>
  skuStatics?.filter((s) => s.isAdditionalAttribute)
);

export const selectAdditionalAttributesByValue = createSelector(
  [selectAdditionalAttributes],
  (additionalAttributes) => keyBy(additionalAttributes, "value")
);
