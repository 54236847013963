import { createAction } from "@reduxjs/toolkit";

import type { AppThunk } from "~/store";
import { CustomerManagement } from "~/services";

export type ReportTypeName = string & { __brand: "ReportTypeName" };
export type ChartReportTypeName = ReportTypeName & { __brand2: "ChartReportTypeName" };
export type BasicReportTypeName = ReportTypeName & { __brand2: "BasicReportTypeName" };
export type ExportColumn = { label: string; value: string; isDefault: boolean };

interface ReportTypeBase {
  name: ReportTypeName;
  displayName: Record<"en" | "es" | "fr", string>;
  isDefault: boolean;
  csvSupport: boolean;
  searchSupport: boolean;
  uniquenessSupport: boolean;
  exportColumns: ExportColumn[];
  exportFormats: string[];
}

export interface BasicReportType extends ReportTypeBase {
  name: BasicReportTypeName;
  chartSupport: false;
}

export interface ChartReportType extends ReportTypeBase {
  name: ChartReportTypeName;
  chartSupport: true;
  groupByOptions: GroupByOption[];
  displayByOptions: DisplayByOption[];
}

type DisplayByOption = {
  value: DisplayByValue;
  isDefault: boolean;
};

export type DisplayByValue = "status" | "result" | "count";
export type GroupByValue = string & { __brand: "GroupByByValue" };

type GroupByOption = {
  label: string;
  value: GroupByValue;
  isDefault: boolean;
};

export type ReportType = BasicReportType | ChartReportType;

export const fetchReportTypesBegin = createAction("FETCH_REPORT_TYPES_BEGIN");
export const fetchReportTypesSuccess = createAction<ReportType[]>("FETCH_REPORT_TYPES_SUCCESS");
export const fetchReportTypesError = createAction<{ message: string }>("FETCH_REPORT_TYPES_ERROR");
export const fetchReportTypes = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(fetchReportTypesBegin());

  try {
    // TODO: add displayName{es} (backend - GLOB-3301)
    const query = CustomerManagement.gqlBuilder(`query ReportingStatics {
        reporting_statics{
          name,
          displayName{ en, fr },
          isDefault,
          csvSupport,
          chartSupport,
          searchSupport,
          uniquenessSupport,
          exportFormats,
          groupByOptions{
            label,
            value,
            isDefault
          },
          displayByOptions{
            value,
            isDefault
          },
          exportColumns{
            label,
            value,
            isDefault
          }
        }
      }`);

    type ReportingStatics = { reporting_statics: ReportType[] };

    const response = await CustomerManagement.QUERY<ReportingStatics>(query);
    const data = response.payload.data.reporting_statics;

    dispatch(fetchReportTypesSuccess(data));
  } catch (e: any) {
    if (e instanceof Error) throw e;

    const message: string = e.json?.message ?? "Request didn't complete successfully";
    dispatch(fetchReportTypesError({ message }));
  }
};
