import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import actions from "~/actions";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors";

import {
  CustomerFacilityEditor,
  CustomerIdentity,
  CustomerUserEditor,
} from "~/customer-management";

import { WizardNavigation } from "~/global";
import { useTranslation } from "~/i18n";

const CustomerWizard = (props) => {
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [facilitiesById, setFacilitiesById] = useState({});
  const { t } = useTranslation("customerWizard");

  const steps = [
    {
      title: t("identification"),
      valid: props.canSaveIdentity,
    },
    {
      title: t("facilities"),
      valid: props.canSaveFacility,
    },
    {
      title: t("user"),
      valid: props.customerUserIsValid,
    },
  ];

  const facilityFormat = new RegExp(/^fa-|^FA-/);

  useEffect(() => {
    props.onChangeRole("client-administrator");
    props.setCustomerIdentity();
    props.fetchUserRoles();
    if (props.countries.length === 0) {
      props.fetchCountries();
    }
    return () => {
      props.onResetEdition();
      props.onResetCustomersEdition();
    };
  }, []);

  useEffect(() => {
    if (props.updatedFacility.faid != null && facilityFormat.test(props.updatedFacility.faid)) {
      const facilities = [].concat("all", props.updatedFacility.faid);
      const newfacilitiesById = {
        ...props.facilitiesById,
        [props.updatedFacility.faid]: props.updatedFacility,
      };

      setFacilitiesList(facilities);
      setFacilitiesById(newfacilitiesById);
    }
  }, [props.updatedFacility]);

  useEffect(() => {
    props.initFacility();
  }, [props.csid]);

  const onStepChanged = (index) => {
    props.onStepChanged(index);
    const isIdentification = steps[index].title === t("identification");
    if (!isIdentification && props.customerName.length > 0) {
      props.fetchCsid(props.customerName);
    }
  };

  return (
    <div>
      <div className="error-message--large row centered margin-bottom--15">{props.errors}</div>
      <WizardNavigation
        currentIndex={props.currentStep}
        onClick={(index) => onStepChanged(index)}
        onCancel={() => props.close()}
        sections={steps}
        disabled={!steps[props.currentStep].valid}
      />
      {steps[props.currentStep].title === t("identification") ? (
        <CustomerIdentity newCustomer csid={props.csid} />
      ) : null}
      {steps[props.currentStep].title === t("facilities") ? (
        <CustomerFacilityEditor newCustomer />
      ) : null}
      {steps[props.currentStep].title === t("user") ? (
        <CustomerUserEditor
          facilitiesById={facilitiesById}
          facilitiesList={facilitiesList}
          editMode={false}
          newCustomer
        />
      ) : null}
    </div>
  );
};

CustomerWizard.propTypes = {
  close: PropTypes.func,
};

CustomerWizard.defaultProps = {
  close: () => console.log("close"),
};

const stateToProps = (state) => ({
  currentStep: state.modules.customerManagement.customerWizard.currentStep,
  customerInfos: moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state),
  canSaveIdentity: moduleSelectors.customerIdentity.getCanSaveCustomerIdentity(state),
  canSaveFacility: moduleSelectors.customerFacilityEditor.getCanSaveCustomerFacility(state),

  updatedFacility: moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state),
  facilitiesById: moduleSelectors.customerFacilities.getFacilitiesByIdAllOption(state),

  csid: moduleSelectors.customerIdentity.getCsid(state),
  customerName: moduleSelectors.customerIdentity.getCustomerName(state),
  countries: selectors.countries.getCountryNames(state),

  customerUserIsValid: moduleSelectors.customerUserEditor.getCustomerUserIsValid(state),
  errors: state.customers.errors,
});

const dispatchToProps = {
  onStepChanged: moduleActions.customerWizard.onStepChanged,
  onChangeRole: moduleActions.customerUserEditor.onChangeRole,
  setCustomerIdentity: moduleActions.customerIdentity.setCustomerIdentity,
  fetchUserRoles: moduleActions.customerUsers.fetchUserRoles,
  initFacility: moduleActions.customerFacilityEditor.initFacility,

  onResetEdition: moduleActions.customerEditor.onResetCustomerEdition,
  onResetCustomersEdition: actions.customers.onResetCustomersEdition,

  fetchCountries: actions.countries.fetchCountries,
  fetchCsid: moduleActions.customerIdentity.fetchCsid,
};

export default connect(stateToProps, dispatchToProps)(CustomerWizard);
