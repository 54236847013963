import React from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import DetailsPage from "~/global/reduxer/components/details/DetailsPage";
import ResultDownloader from "~/global/reduxer/components/ResultDownloader";
import {
  selectors as workstationSelectors,
  actions as workstationActions,
} from "~/workstation/redux";
import DetailsSection from "~/global/reduxer/components/details/DetailsSection";
import Timestamp from "~/workstation/components/common/Timestamp";
import DetailsField from "~/global/reduxer/components/details/DetailsField";

const CrashReportDetailsContent = (props) => {
  const { dumpUrl, sessionId, workstationId, appVersion, createdAt, guid, platform, processType } =
    props;
  return (
    <div className="double-section">
      <DetailsSection sectionTitle="Info">
        <DetailsField label="Created at" value={<Timestamp inline value={createdAt} />} />
        <DetailsField label="Version" value={appVersion} />
        <DetailsField label="Guid" value={guid} />
        <DetailsField label="Platform" value={platform} />
        <DetailsField label="Process Type" value={processType} />
      </DetailsSection>
      <DetailsSection sectionTitle="Links">
        <DetailsField
          label="Workstation"
          value={<Link to={`/workstations/${workstationId}`}>{workstationId}</Link>}
        />
        <DetailsField
          label="Session"
          value={<Link to={`/workstations/sessions/${sessionId}`}>{sessionId}</Link>}
        />
        <DetailsField label="Dump" value={<a href={dumpUrl}>DOWNLOAD</a>} />
      </DetailsSection>
    </div>
  );
};

const CrashReportDetails = () => {
  const { crashId } = useParams();
  const crash = useSelector(workstationSelectors.crashReportDetails.getResults);

  return (
    <DetailsPage
      pageTitle={
        <div className="details-page-title-head">
          <span>Crash Report {crashId}</span>
          <ResultDownloader
            selectors={workstationSelectors.crashReportDetails}
            download={`crash-report-${crashId}.json`}
          />
        </div>
      }
    >
      <ReduxerControlled
        selectors={workstationSelectors.crashReportDetails}
        actions={workstationActions.crashReportDetails}
        fetchArguments={[crashId]}
      >
        {crash && <CrashReportDetailsContent {...crash} />}
      </ReduxerControlled>
    </DetailsPage>
  );
};

export default CrashReportDetails;
