import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const FETCH_FAIL_CODE_CATEGORIES_BEGIN = "FETCH_FAIL_CODE_CATEGORIES_BEGIN";
export const FETCH_FAIL_CODE_CATEGORIES_SUCCESS = "FETCH_FAIL_CODE_CATEGORIES_SUCCESS";
export const FETCH_FAIL_CODE_CATEGORIES_ERROR = "FETCH_FAIL_CODE_CATEGORIES_ERROR";
export const CHECK_FAIL_CODE_CATEGORIES = "CHECK_FAIL_CODE_CATEGORIES";
export const DELETE_FAIL_CODE_CATEGORIES_BEGIN = "DELETE_FAIL_CODE_CATEGORIES_BEGIN";
export const DELETE_FAIL_CODE_CATEGORIES_SUCCESS = "DELETE_FAIL_CODE_CATEGORIES_SUCCESS";
export const DELETE_FAIL_CODE_CATEGORIES_ERROR = "DELETE_FAIL_CODE_CATEGORIES_ERROR";
export const CLEAR_FAIL_CODE_CATEGORIES_ERRORS = "CLEAR_FAIL_CODE_CATEGORIES_ERRORS";
export const SET_FAIL_CODES_CATEGORY_NAME_FILTER = "SET_FAIL_CODES_CATEGORY_NAME_FILTER";

export const fetchFailCodeCategories = () => async (dispatch, getState) => {
  dispatch(fetchFailCodeCategoriesBegin());
  try {
    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    const query = CustomerManagement.gqlBuilder(`{
            fail_codes_categories {
                id,
                name { ${displayNameLanguages} },
                failFast,
                failCodes {
                    id,
                    name,
                    description { ${displayNameLanguages} },
                    failFast,
                    type,
                    route
                }
            }
        }`);

    const response = await CustomerManagement.POST("/gql", { query });
    const data = response.payload.data.fail_codes_categories || [];

    dispatch(fetchFailCodeCategoriesSuccess(data));
  } catch (error) {
    dispatch(fetchFailCodeCategoriesError(error));
  }
};

export const fetchFailCodeCategoriesBegin = () => ({
  type: FETCH_FAIL_CODE_CATEGORIES_BEGIN,
});

export const fetchFailCodeCategoriesSuccess = (data) => {
  const failCodeCategories = [];
  const failCodes = [];

  data.forEach((category) => {
    failCodeCategories.push({
      id: category.id,
      failFast: !!category.failFast,
      name: category.name || {
        en: "",
        fr: "",
        es: "",
      },
    });

    (category.failCodes || []).forEach((failCode) => {
      failCodes.push({
        id: failCode.id,
        categoryId: category.id,
        categoryName: category.name,
        name: failCode.name || "",
        description: failCode.description || {},
        failFast: failCode.failFast, // this logic should be server side...
        showOnDesktop: failCode.type === "desktop", // this logic should be server side...
        route: failCode.route,
      });
    });
  });

  return {
    type: FETCH_FAIL_CODE_CATEGORIES_SUCCESS,
    payload: {
      failCodeCategories,
      failCodes,
    },
  };
};

export const fetchFailCodeCategoriesError = (response) => {
  const errors =
    response.json != null
      ? Object.keys(response.json.errors || []).map((error) => response.json.errors[error])
      : [];

  return {
    type: FETCH_FAIL_CODE_CATEGORIES_ERROR,
    payload: {
      message: errors.length > 0 ? errors : ["Failed to fetch Fail Categories"],
      title: "Error",
    },
  };
};

export const checkFailCodeCategories = (failCodeCategoryIds) => ({
  type: CHECK_FAIL_CODE_CATEGORIES,
  payload: { failCodeCategoryIds },
});

export const deleteFailCodeCategories = (failCodeCategoryIds) => async (dispatch, getState) => {
  dispatch(deleteFailCodeCategoriesBegin(failCodeCategoryIds));
  try {
    await CustomerManagement.DELETE("/fail-codes-categories", failCodeCategoryIds);

    dispatch(deleteFailCodeCategoriesSuccess(failCodeCategoryIds));
  } catch (e) {
    const state = getState();
    const errors = e.json != null ? e.json.errors : null;
    const isAssociatedTestsError = errors != null && errors.failCodeCategories != null;

    if (isAssociatedTestsError) {
      const failCodesById = selectors.failCodes.getFailCodesById(state);
      const failCodesCategoriesById = selectors.failCodes.getFailCodesCategoriesById(state);

      const message = Object.keys(errors.failCodeCategories || {}).map((failCodeCategoryId) => {
        const failCodeCategoryErrors = errors.failCodeCategories[failCodeCategoryId];
        const failCodeCategoryName = failCodesCategoriesById[failCodeCategoryId].name;

        return {
          failCategoryName: failCodeCategoryName,
          failCodes: Object.keys(failCodeCategoryErrors).map((failCodeId) => ({
            failCodeName: failCodesById[failCodeId].name,
            associatedTests: failCodeCategoryErrors[failCodeId],
          })),
        };
      });

      dispatch(deleteFailCodeCategoriesError(message, true));
    } else {
      const textOfErased = failCodeCategoryIds.length > 1 ? "Categories" : "Category";
      const message = [].concat(
        errors != null && typeof errors === "string"
          ? errors
          : `Failed to delete Fail Code ${textOfErased}`
      );

      dispatch(deleteFailCodeCategoriesError(message, false));
    }
  }
};

export const deleteFailCodeCategoriesBegin = (ids) => ({
  type: DELETE_FAIL_CODE_CATEGORIES_BEGIN,
  payload: { ids },
});

export const deleteFailCodeCategoriesSuccess = (ids) => ({
  type: DELETE_FAIL_CODE_CATEGORIES_SUCCESS,
  payload: { ids },
});

export const deleteFailCodeCategoriesError = (message, isAssociatedTestError) => ({
  type: DELETE_FAIL_CODE_CATEGORIES_ERROR,
  payload: {
    message,
    isAssociatedTestError,
  },
});

export const clearFailCodeCategoriesErrors = () => ({
  type: CLEAR_FAIL_CODE_CATEGORIES_ERRORS,
});

export const setNameFilter = (name) => ({
  type: SET_FAIL_CODES_CATEGORY_NAME_FILTER,
  payload: {
    name,
  },
});
