import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "react-md";
import { AsciButton } from "~/global";

import CustomFieldItemEditor from "./CustomFieldItemEditor.component";

const CustomFieldListTypeEditor = (props) => {
  const onItemValueChanged = (value, itemId) => {
    const changes = { value };
    props.onChange(changes, itemId);
  };

  return (
    <div>
      {Object.keys(props.items).length === 0 ? (
        <div className="error-message--large row centered margin-bottom--15">
          At least one item is required
        </div>
      ) : (
        Object.keys(props.items).map((itemId, index) => (
          <div key={index} className="margin--5">
            <div className="layout-row layout-row--start-center margin-v--15">
              <div className="bold">List Item {index + 1}</div>
              <div className="margin-left--15">
                <Button
                  flat
                  secondary
                  iconClassName="icon-trash"
                  className="icon-btn text-color--red icon-text-button"
                  onClick={() => props.onDelete(itemId)}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="layout-row layout-row--start-center">
              <div className="flex--30 flex--nogrow bold">
                Value<sup>*</sup>
              </div>
              <div className="flex--30 flex--nogrow">
                <TextField
                  id="custom-field-item-value-textfield"
                  placeholder="Value"
                  value={props.items[itemId].value}
                  onChange={(value) => onItemValueChanged(value, itemId)}
                  type="text"
                  error={props.items[itemId].value.length === 0}
                  errorText="Required field"
                />
              </div>
            </div>

            <div className="flex--30 flex--nogrow bold">Display Name</div>

            <CustomFieldItemEditor
              item={props.items[itemId]}
              languagesByCode={props.languagesByCode}
              onItemChanged={(changes) => props.onChange(changes, itemId)}
            />
            <div className="block margin--10 padding--10" />
          </div>
        ))
      )}
      <div className="row centered">
        <AsciButton color="blue" onClick={props.onAdd}>
          Add Item
        </AsciButton>
      </div>
    </div>
  );
};

CustomFieldListTypeEditor.propTypes = {
  items: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  languagesByCode: PropTypes.object,
};

CustomFieldListTypeEditor.defaultProps = {
  items: {},
  languagesByCode: { en: { name: "English", value: "en" } },
};

export default CustomFieldListTypeEditor;
