import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  errors: null,
  currentTab: 0,
};

export default createReducer(initialState, {
  [moduleActions.customerEditor.updateTab]: (state, action) => {
    state.currentTab = action.payload.index;
  },
  [moduleActions.customerEditor.resetCustomerEdition]: (state) => {
    state.errors = null;
    state.currentTab = 0;
  },
});
