import { createSelector } from "@reduxjs/toolkit";

export const getCustomFields = (state) => state.customFields.items;

export const getFlatCustomFields = createSelector([getCustomFields], (customFields) =>
  (customFields || []).map((customField) => {
    const displayNameEn = customField.displayName.en || "";
    return {
      ...customField,
      displayNameEn,
    };
  })
);

export const getCustomFieldsByName = createSelector(getCustomFields, (customFields) => {
  const customFieldsById = (customFields || []).reduce(
    (acc, customField) => ({
      ...acc,
      [customField.name]: customField,
    }),
    {}
  );
  return customFieldsById;
});
