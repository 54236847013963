import React, { useState, useEffect, useRef } from "react";
import { TextField } from "react-md";
import PropTypes from "prop-types";

const CustomFieldStringTypeEditor = (props) => (
  // TODO: remove this once I'm sure it's useless
  /* useEffect(() => {
        if (props.isMaxLengthValid) {
            props.onChange({ maxLength: +props.customField.maxLength });
        }
    }, [props.customField.maxLength]); */

  <div className="margin--5">
    <div className="layout-row layout-row--start-center">
      <div className="flex--30 flex--nogrow bold">Default Value</div>

      <div className="flex--30 flex--nogrow">
        <TextField
          id="custom-field-string-default-value"
          placeholder="Default Value"
          value={props.customField.defaultStringValue.toString()}
          onChange={(value) => props.onChange({ defaultStringValue: value })}
          type="text"
          error={!props.isStringValid}
          errorText="Value is longer than the maximum length"
        />
      </div>
    </div>

    <div className="layout-row layout-row--start-center">
      <div className="flex--30 flex--nogrow bold">Max Length</div>

      <div className="flex--30 flex--nogrow">
        <TextField
          id="custom-field-string-max-length"
          placeholder="Max Length"
          value={props.customField.maxLength}
          onChange={(value) => props.onChange({ maxLength: value })}
          type="number"
          min={0}
          max={9999}
          error={!props.isMaxLengthValid}
          errorText="Max Length must be a number between 0 and 9999"
        />
      </div>
    </div>
  </div>
);
CustomFieldStringTypeEditor.propTypes = {
  isMaxLengthValid: PropTypes.any,
  isStringValid: PropTypes.bool,
  customField: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

CustomFieldStringTypeEditor.defaultProps = {
  customField: {},
};

export default CustomFieldStringTypeEditor;
