import { createReducer } from "@reduxjs/toolkit";

import actions from "~/actions";

const initialState = {
  facilities: [],
  programs: [],
  teams: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [actions.filters.fetchAllFiltersBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },

  [actions.filters.fetchAllFiltersSuccess]: (state, action) => {
    state.facilities = action.payload.filters.facilities;
    state.programs = action.payload.filters.programs;
    state.teams = action.payload.filters.teams;
    state.isLoading = false;
  },

  [actions.filters.fetchAllFiltersError]: (state, action) => {
    state.errors = action.payload.errors;
    state.facilities = [];
    state.programs = [];
    state.teams = [];
    state.isLoading = false;
  },
});
