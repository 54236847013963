import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TableHeader, DataTable, TableRow, TableColumn, Button, TableBody } from "react-md";
import classnames from "classnames";
import { message } from "antd";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  AsciSpinner,
  AsciButton,
  SectionHeader,
  InputSearch,
  DialogMessage,
  DialogConfirmation,
} from "~/global";
import { formatDate } from "~/global/utils";
import actions from "~/actions";
import selectors from "~/selectors";
import moduleActions from "../../actions";
import * as moduleSelectors from "../../selectors";

import "./TestSuitesPage.component.scss";

const TestSuitesPage = ({
  testSuites,
  isLoading,
  sortKey,
  sortReverse,
  fetchTestSuites,
  fetchTestSuite,
  sortTestSuites,
  testSuite,
  testSuitesNames,
  createTestSuite,
  resetTestSuite,
  error,
  affectedPrograms,
  deleteTestSuite,
  showDialog,
  hideDialog,
}) => {
  const navigate = useNavigate();

  const [testSuitesList, setTestSuitesList] = useState([]);
  const [duplicate, setDuplicate] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [testSuiteToDelete, setTestSuiteToDelete] = useState("");

  useEffect(() => {
    fetchTestSuites();
  }, []);

  useEffect(() => {
    if (duplicate && testSuite.name != null && !isLoading) {
      setDuplicate(false);
      createCopy();
    }
  }, [testSuite]);

  const createCopy = async () => {
    let copyName = `Copy of ${testSuite.name}`;
    for (let index = 0; index < testSuitesNames.length; index++) {
      if (index === 0 && !testSuitesNames.includes(copyName)) {
        break;
      } else if (index > 0 && !testSuitesNames.includes(`${copyName} ${index}`)) {
        copyName = `${copyName} ${index}`;
        break;
      }
    }

    const newTestSuiteIdentity = { tsid: "", name: copyName };
    const newTestSuite = { ...testSuite, ...newTestSuiteIdentity };

    const response = await createTestSuite(newTestSuite);

    if (response) {
      messageSuccess(`${copyName} created successfully`);
      fetchTestSuites();
      resetTestSuite();
    }
  };

  useEffect(() => {
    if (testSuites.length > 0) {
      filterTestSuitesList();
    } else {
      setTestSuitesList([]);
    }
  }, [nameFilter]);

  useEffect(() => {
    if (testSuites.length > 0) {
      filterTestSuitesList();
    } else {
      setTestSuitesList(testSuites);
    }
  }, [testSuites]);

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);
    }
  }, [error]);

  const messageSuccess = (text) => {
    message.destroy();
    message.success(text, 2.5);
  };

  const messageLoading = async () => {
    await message.loading("Action in progress..", 10);
    message.warning("Request is taking longer than expected", 2.5);
  };

  const displayErrorMessage = (errorMessage) => {
    showDialog({
      title: "Error",
      width: "450px",
      content: (close) =>
        affectedPrograms ? (
          <DialogConfirmation
            onCancel={() => close()}
            textButtonCancel="Remove myself"
            onConfirm={() => {
              deleteTestSuite(testSuiteToDelete, true);
              close();
            }}
            textButtonConfirm="Remove automatically"
          >
            <div>{errorMessage}</div>

            <ul className="affected-programs">
              {affectedPrograms.map((program) => (
                <div key={program.Identifier}>
                  <li>{program.DisplayName.En}:</li>
                  <ul>
                    {program.ServiceSuites.map((serviceSuite) => (
                      <li key={serviceSuite.Identifier}>{serviceSuite.DisplayName.En}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          </DialogConfirmation>
        ) : (
          <DialogMessage close={() => close()}>
            <div>{errorMessage}</div>
          </DialogMessage>
        ),
    });
  };

  const filterTestSuitesList = () => {
    if (nameFilter.length > 0) {
      const newTestSuiteList = testSuites.filter((testSuites) => {
        const testSuitesName = (testSuites.name || "").toLowerCase();
        const testSuitesPlatform = (testSuites.platform || "").toLowerCase();
        const name = nameFilter.toLowerCase();
        return testSuitesName.includes(name) || testSuitesPlatform.includes(name);
      });
      setTestSuitesList(newTestSuiteList);
    } else {
      setTestSuitesList(testSuites);
    }
  };

  const onNewTestSuite = () => {
    navigate(`/test-suites/new`);
  };

  const onEdit = (testSuite) => {
    navigate(`/test-suites/${testSuite.tsid}`);
  };

  const onDuplicate = (testSuite) => {
    messageLoading();
    fetchTestSuite(testSuite.tsid);
    setDuplicate(true);
  };

  const confirmDeleteTestSuite = (testSuite) => {
    showDialog({
      title: `Delete test suite`,
      width: "450px",
      modal: true,
      content: (close) => (
        <DialogConfirmation
          onCancel={() => {
            close();
          }}
          onConfirm={async () => {
            await close();
            deleteTestSuite(testSuite);
          }}
          textButtonConfirm="Delete"
        >
          <p className="align-center">Are you sure you want to remove this test suite? </p>
          <p className="align-center">
            Confirm the removal or <b>Cancel</b>.
          </p>
        </DialogConfirmation>
      ),
    });
  };

  const isEmpty = testSuitesList.length === 0;

  return (
    <div className="panel-container test-suites-list">
      {isLoading ? (
        <AsciSpinner visible />
      ) : (
        <div className="test-suites-table">
          <SectionHeader title="Test Suites">
            <div className="push-right row centered">
              <InputSearch
                placeholder="Search Test Suite"
                onChange={(name) => setNameFilter(name)}
                initialValue={nameFilter}
                trim
              />
              <AsciButton color="green" onClick={onNewTestSuite}>
                New
              </AsciButton>
            </div>
          </SectionHeader>

          <DataTable className="cc-table" plain>
            {!isEmpty ? (
              <TableHeader>
                <TableRow>
                  <TableColumn
                    className={classnames("lg-cell sortable", {
                      "is-sorted": sortKey === "name",
                      "sort-reverse": sortReverse,
                    })}
                    onClick={() => sortTestSuites("name")}
                  >
                    Name
                  </TableColumn>

                  <TableColumn
                    className={classnames("md-cell text-centered sortable", {
                      "is-sorted": sortKey === "platform",
                      "sort-reverse": sortReverse,
                    })}
                    onClick={() => sortTestSuites("platform")}
                  >
                    Platform
                  </TableColumn>

                  <TableColumn
                    className={classnames("sm-cell text-centered sortable", {
                      "is-sorted": sortKey === "testsCount",
                      "sort-reverse": sortReverse,
                    })}
                    onClick={() => sortTestSuites("testsCount")}
                  >
                    Active Tests
                  </TableColumn>

                  <TableColumn
                    className={classnames("md-cell text-centered sortable", {
                      "is-sorted": sortKey === "lastEdited",
                      "sort-reverse": sortReverse,
                    })}
                    onClick={() => sortTestSuites("lastEdited")}
                  >
                    Last Edited
                  </TableColumn>

                  <TableColumn
                    className={classnames("md-cell text-centered sortable", {
                      "is-sorted": sortKey === "lastEditor",
                      "sort-reverse": sortReverse,
                    })}
                    onClick={() => sortTestSuites("lastEditor")}
                  >
                    Last Editor
                  </TableColumn>

                  <TableColumn className="sm-cell text-centered" />
                </TableRow>
              </TableHeader>
            ) : null}

            <TableBody>
              <>
                {isEmpty ? (
                  <TableRow>
                    <TableColumn colSpan="6" className="text-centered">
                      No data to display
                    </TableColumn>
                  </TableRow>
                ) : null}

                {testSuitesList.map((testSuite, index) => (
                  <TableRow key={index}>
                    <TableColumn className="lg-cell">
                      <div onClick={() => onEdit(testSuite)} className="clickable-text">
                        {testSuite.name}
                      </div>
                    </TableColumn>
                    <TableColumn className="md-cell text-centered">
                      {testSuite.platform}
                    </TableColumn>
                    <TableColumn className="text-centered">{testSuite.testsCount}</TableColumn>
                    <TableColumn className="text-centered">
                      {testSuite.lastEdited != null ? formatDate(testSuite.lastEdited) : "Never"}
                    </TableColumn>
                    <TableColumn className="text-centered">{testSuite.lastEditor}</TableColumn>
                    <TableColumn className="text-centered">
                      <div className="layout-row layout-row--end-center">
                        <Button
                          icon
                          className="icon-btn"
                          iconClassName="icon-copy"
                          disabled={isLoading}
                          onClick={() => onDuplicate(testSuite)}
                        />

                        <Button
                          icon
                          className="icon-btn"
                          iconClassName="icon-pencil"
                          onClick={() => onEdit(testSuite)}
                        />

                        <Button
                          icon
                          className="icon-btn text-color--red"
                          iconClassName="icon-trash"
                          onClick={() => {
                            confirmDeleteTestSuite(testSuite);
                            setTestSuiteToDelete(testSuite);
                          }}
                        />
                      </div>
                    </TableColumn>
                  </TableRow>
                ))}
              </>
            </TableBody>
          </DataTable>
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  testSuites: selectors.testSuites.getSortedTestSuites(state),
  testSuitesNames: selectors.testSuites.getTestSuitesNames(state),
  isLoading: state.testSuites.isLoading,
  error: state.testSuites.error,
  affectedPrograms: state.testSuites.affectedPrograms,
  sortKey: state.testSuites.sortKey,
  sortReverse: state.testSuites.sortReverse,
  testSuite: moduleSelectors.getCurrentTestSuite(state),
});

const dispatchToProps = {
  fetchTestSuites: actions.testSuites.fetchTestSuites,
  sortTestSuites: actions.testSuites.sortTestSuites,
  fetchTestSuite: moduleActions.fetchTestSuite,
  createTestSuite: moduleActions.createTestSuite,
  resetTestSuite: moduleActions.resetTestSuite,
  setTestSuite: moduleActions.setTestSuite,
  deleteTestSuite: moduleActions.deleteTestSuite,
  showDialog: actions.dialog.show,
  hideDialog: actions.dialog.hide,
};

export default connect(stateToProps, dispatchToProps)(TestSuitesPage);
