import React, { useState, useEffect } from "react";
import { Paper } from "react-md";
import { connect } from "react-redux";

import { Column, Row } from "~/global";

import moduleSelectors from "~/data-import/selectors/";

import "./FileValidation.component.scss";

const FileValidation = ({ fileName, fileSize, lineCount, recordingErrors, fileProcess }) => {
  const boxType = recordingErrors.length === 0;
  const [title, setTitle] = useState("");
  const [uiFileName, setUiFileName] = useState("");
  const [uiFileSize, setUiFileSize] = useState("");
  const [uiLineCount, setUiFileCount] = useState("");

  useEffect(() => {
    if (fileProcess) {
      setTitle("Pending...");
      setUiFileName("Pending...");
      setUiFileSize("Pending...");
      setUiFileCount("Pending...");
    } else if (fileSize === "0") {
      setTitle("No file selected");
      setUiFileName("N/A");
      setUiFileSize("N/A");
      setUiFileCount("N/A");
    } else {
      setTitle("Validated");
      setUiFileName(fileName);
      setUiFileSize(`${fileSize} KB`);
      setUiFileCount(lineCount - 1);
    }
  }, [fileSize, fileName, fileProcess]);

  return (
    <div>
      <Column className="file-validation full-width">
        <Row className="section-title">File Validation</Row>
        <Row>
          {boxType ? (
            <Paper zDepth={1}>
              <Row className="box-title">
                <i className="icon-check" />
                {title}
              </Row>
              <Row>
                <Column className="file-label">
                  <div>File Name:</div>
                  <div>File Size:</div>
                  <div>Line Count:</div>
                </Column>
                <Column className="data-label">
                  <div>{uiFileName}</div>
                  <div>{uiFileSize}</div>
                  <div>{uiLineCount}</div>
                </Column>
              </Row>
            </Paper>
          ) : (
            <Paper zDepth={1}>
              <Row className="box-title-error">
                <i className="icon-exclamation-circle" /> Errors found
              </Row>
              <Row className="descrition-label">
                The file you uploaded contains the following errors:{" "}
              </Row>
              <div className="error-list">
                {recordingErrors.map((cellError, index) => (
                  <Row key={index}>
                    <i className="icon-close" />
                    <div className="cell-error-message">{cellError}</div>
                  </Row>
                ))}
              </div>
            </Paper>
          )}
        </Row>
      </Column>
    </div>
  );
};

const stateToProps = (state) => ({
  fileName: state.modules.dataImport.importDataAutomatic.file.name,
  fileSize: state.modules.dataImport.importDataAutomatic.file.size,
  lineCount: moduleSelectors.importDataAutomatic.getLineCount(state),
  recordingErrors: moduleSelectors.importDataAutomatic.getRecordingErrors(state),
  fileProcess: state.modules.dataImport.importDataAutomatic.fileProcessing,
});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(FileValidation);
