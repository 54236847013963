import { createReducer } from "@reduxjs/toolkit";

import {
  generateReportBegin,
  generateReportSuccess,
  generateReportError,
} from "~/actions/report.actions";

interface ReportState {
  status: "idle" | "loading" | "error";
}

const initialState: ReportState = {
  status: "idle",
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(generateReportBegin, () => ({ status: "loading" }))
    .addCase(generateReportSuccess, () => ({ status: "idle" }))
    .addCase(generateReportError, () => ({ status: "error" }));
});
