import React, { useState } from "react";
import { connect } from "react-redux";

import moment from "moment";
import { Row, Column, Page } from "~/global";

import UserLogsFilters from "../UserLogsFilters/UserLogsFilters.component";
import UserLogsList from "../UserLogsList/UserLogsList.component";

import "./UserLogsPage.component.scss";

const UserLogsPage = ({ isLoading, userLogs }) => {
  const dateFrom = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ");
  const dateTo = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZZ");

  const initialDates = {
    dateTo,
    dateFrom,
  };

  const [timeFrame, setTimeFrame] = useState(initialDates);

  return (
    <Column className="full-width user-logs-page">
      <UserLogsFilters
        dateFrom={timeFrame.dateFrom}
        dateTo={timeFrame.dateTo}
        datesCallBack={(data) => setTimeFrame(data)}
      />
      <Column className="user-logs-list full-width" align="start center">
        <UserLogsList userLogs={userLogs} />
      </Column>
    </Column>
  );
};

const stateToProps = (state) => ({
  isLoading: state.modules.userLogs.userActivityData.isLoading,
  errors: state.modules.userLogs.userActivityData.errors,
  userLogs: state.modules.userLogs.userActivityData.items,
});
const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(UserLogsPage);
