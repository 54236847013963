import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import type { AppDispatch } from "~/store";

export type Facility = {
  faid: string;
  sbid: string;
  name: string;
  country: string;
  state: string;
  postalcode: string;
  timezone: string;
  deployments?: Deployment[];
};

export type Deployment = {
  dpid: string;
  name: string;
  shifts: Shift[];
  customFields: CustomField[];
  programs: Program[];
};

export type Shift = {
  id: string;
  name: string;
  end: {
    hours: number;
    minutes: number;
  };
  start: {
    hours: number;
    minutes: number;
  };
};

export type CustomField = {
  // TODO
};

export type Program = {
  // TODO
};

type FacilityResponse = { facility: Facility };

export const fetchFacilityBegin = createAction("FETCH_FACILITY_BEGIN");
export const fetchFacilitySuccess = createAction<{ facility: Facility }>("FETCH_FACILITY_SUCCESS");
export const fetchFacilityError = createAction<{ error: string }>("FETCH_FACILITY_ERROR");

export const fetchFacility =
  (faid: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const query = CustomerManagement.gqlBuilder(`{
      facility(faid: "${faid}") {
        faid,
        sbid,
        name,
        country,
        state,
        postalcode,
        timezone
      }
    }`);

    dispatch(fetchFacilityBegin());
    try {
      const response = await CustomerManagement.QUERY<FacilityResponse>(query);
      const facility = response.payload?.data?.facility ?? {};

      dispatch(fetchFacilitySuccess({ facility }));
    } catch (e: any) {
      dispatch(fetchFacilityError({ error: e.json }));
    }
  };

export const fetchFacilityWithDeployments =
  (faid: string, sbid?: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const sbidQuery = sbid ? `,sbid: "${sbid}"` : "";

    const query = CustomerManagement.gqlBuilder(`{
      facility (faid: "${faid}" ${sbidQuery}) {
        faid,
        sbid,
        name,
        country,
        state,
        postalcode,
        timezone,
        deployments {
          dpid,
          name,
          shifts {
            id,
            name,
            end {
              hours,
              minutes
            },
            start {
              hours,
              minutes
            }
          },
          customFields,
          programs
        },
      }
    }`);

    dispatch(fetchFacilityBegin());
    try {
      const response = await CustomerManagement.QUERY<FacilityResponse>(query);
      const facility = response.payload?.data?.facility ?? {};

      dispatch(fetchFacilitySuccess({ facility }));
    } catch (e: any) {
      dispatch(fetchFacilityError({ error: e.json }));
    }
  };
