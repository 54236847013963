import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontIcon } from "react-md";

import { TestDefinitionIcon } from "~/global";

import "./TestCategoriesList.component.scss";

const TestCategoriesList = ({ categories, selectedCategory, onSelect }) => (
  <div className="test-categories-list">
    {categories.map((category, index) => (
      <div
        key={index}
        className={classnames("layout-row", {
          selected: selectedCategory.name === category.name,
        })}
        onClick={() => onSelect && onSelect(category)}
      >
        <div className="image layout-column layout-column--center-center">
          <TestDefinitionIcon src={category.image} size="40" isCategory />
        </div>

        <div className="name flex layout-row layout-row--start-center">{category.displayName}</div>

        <div className="arrow layout-column layout-column--center-center">
          <FontIcon iconClassName="icon-chevron-right" />
        </div>
      </div>
    ))}
  </div>
);

TestCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  selectedCategory: PropTypes.shape({ name: PropTypes.string }),
  onSelect: PropTypes.func,
};

TestCategoriesList.defaultProps = {
  categories: [],
  selectedCategory: {},
};

export default TestCategoriesList;
