import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./ToggleButton.component.scss";

const ToggleButton = ({ className, isActive, onClick, children }) => (
  <button
    className={classnames("cc-toggle-btn row centered", isActive ? "is-active" : "", className)}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

ToggleButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ToggleButton.defaultProps = {
  className: "",
  isActive: false,
};

export default ToggleButton;
