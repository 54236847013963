import React from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";
import produce from "immer";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";

import { SectionHeader, Checkbox, Row, Column } from "~/global";
import { DeviceTestArgument } from "~/models";

import "./TestDefinitionLocaleEditor.component.scss";

const TestDefinitionLocaleEditor = ({ arguments: args, argumentsDetails, languages, onChange }) => {
  const onValueChanged = (arg, value, lang) => {
    onChange(
      produce(args, (draft) => {
        const index = findIndex(args, (a) => a.name === arg.name);
        if (index > -1) {
          draft[index].localization[lang] = value;
        }
      })
    );
  };

  const addArgument = (args, argName) =>
    produce(args, (draft) => {
      draft.push({
        name: argName,
        type: DeviceTestArgument.TYPE_LOCALIZABLE_STRING,
        localization: {
          en: "",
          fr: "",
          es: "",
        },
      });
    });

  const removeArgument = (args, argName) => {
    const index = findIndex(args, (a) => a.name === argName);

    return produce(args, (draft) => {
      draft.splice(index, 1);
    });
  };

  const getDisplayedArguments = (args, argsDetails) => {
    const currentArgumentsByName = args.reduce((acc, arg) => {
      acc[arg.name] = arg;
      return acc;
    }, {});

    const displayedArgs = [];

    for (const name in argsDetails) {
      const details = argsDetails[name];

      // ignore everything but localizableString
      if (DeviceTestArgument.isTypeLocalizableString(details)) {
        const item = {
          name: details.name,
          displayName: details.displayName || details.name,
          description: details.description,
          isRequired: details.required,
          isSelected: currentArgumentsByName[details.name] != null,
        };

        if (item.isSelected) {
          item.localization = currentArgumentsByName[item.name].localization || {};
        } else {
          item.localization = {
            en: "",
            fr: "",
            es: "",
          };
        }

        displayedArgs.push(item);
      }
    }

    return sortBy(displayedArgs, (i) => (i.displayName || "").toLowerCase());
  };

  const onToggle = (arg, isSelected) => {
    const index = findIndex(args, (a) => a.name === arg.name);
    let newArguments;

    if (!isSelected || index > -1) {
      newArguments = removeArgument(args, arg.name);
    }

    if (isSelected) {
      newArguments = addArgument(args, arg.name);
    }

    if (newArguments) {
      onChange(newArguments);
    }
  };

  const localizableArguments = getDisplayedArguments(args, argumentsDetails);

  return (
    <div className="test-definition-locale-editor">
      {localizableArguments.map((arg) => (
        <div key={`arg-${arg.name}`}>
          <SectionHeader>
            <Row align="start center">
              <Column className="cb-container">
                <Checkbox
                  id={`localizable-arg-row-checkbox-${arg.name}`}
                  isChecked={arg.isRequired || arg.isSelected}
                  disabled={arg.isRequired}
                  onChange={(isSelected) => onToggle(arg, isSelected)}
                />
              </Column>
              {arg.displayName}
            </Row>
          </SectionHeader>

          <div className="locale-table">
            <header>
              <div className="col-lang">Language</div>
              <div className="col-value">Content</div>
            </header>
            <main>
              {languages.map((lang) => (
                <div key={`lang-${lang.value}`}>
                  <div className="col-lang">{lang.name}</div>
                  <div className="col-value">
                    <TextField
                      id={`input-${arg.name}-${lang.value}`}
                      type="text"
                      value={arg.localization[lang.value]}
                      disabled={!arg.isSelected}
                      onChange={(val) => onValueChanged(arg, val, lang.value)}
                    />
                  </div>
                </div>
              ))}
            </main>
          </div>
        </div>
      ))}
    </div>
  );
};

TestDefinitionLocaleEditor.propTypes = {
  arguments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      localization: PropTypes.object,
    })
  ),
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // ex.: English
      value: PropTypes.string, // ex.: en
    })
  ),
  onChange: PropTypes.func.isRequired,
};

TestDefinitionLocaleEditor.defaultProps = {
  arguments: [],
  languages: [],
};

export default TestDefinitionLocaleEditor;
