import React from "react";
import type { ReactElement, ReactNode, CSSProperties } from "react";

import { SectionHeader } from "~/global";

import "./ReportingSection.scss";

type Props = {
  title?: string;
  description?: string;
  headerExtraContent?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
};

export const ReportingSection = ({
  title,
  description,
  headerExtraContent,
  children,
  style,
}: Props): ReactElement => (
  <div className="reporting-section" style={style}>
    <SectionHeader className="table-header" title={title} description={description}>
      {headerExtraContent}
    </SectionHeader>

    {children}
  </div>
);
