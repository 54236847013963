import { createReducer } from "@reduxjs/toolkit";

import labelActions from "~/label-printer/actions";

const initialState = {
  saving: false,
  success: false,
  error: null,
  loading: false,
  loaded: false,
  loadedLabelId: null,
};

// noinspection DuplicatedCode
export default createReducer(initialState, {
  [labelActions.labelSaver.labelLoadBegin]: (state, action) => {
    const { labelId } = action.payload;
    state.loadedLabelId = labelId;
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [labelActions.labelSaver.labelLoadSuccess]: (state, action) => {
    state.loaded = true;
    state.loading = false;
  },
  [labelActions.labelSaver.labelLoadError]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [labelActions.labelDesigner.createNewLabel]: (state, action) => {
    state.loadedLabelId = null;
    state.loaded = false;
  },
  [labelActions.labelSaver.setLoadedLabelId]: (state, action) => {
    state.loadedLabelId = action.payload;
  },
  [labelActions.labelSaver.labelSaveBegin]: (state) => {
    state.saving = true;
    state.success = false;
    state.error = null;
  },
  [labelActions.labelSaver.labelSaveSuccess]: (state, action) => {
    const { labelId } = action.payload;
    state.saving = false;
    state.success = true;
    if (labelId) {
      state.loadedLabelId = labelId;
    }
  },
  [labelActions.labelSaver.labelSaveError]: (state, action) => {
    state.saving = false;
    state.error = action.payload;
  },
  [labelActions.labelSaver.setSaved]: (state, action) => {
    state.success = action.payload;
  },
  [labelActions.labelSaver.labelLoadSuccess]: (state, action) => {
    state.saving = false;
    state.success = false;
    state.error = null;
    state.loading = false;
    state.loaded = true;
  },
});
