import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { InputNumber } from "antd";

import BorderInspector from "~/label-printer/components/designer/inspector/commons/BorderInspector";
import FontInspector from "~/label-printer/components/designer/inspector/commons/FontInspector";
import InspectorSection from "~/label-printer/components/designer/inspector/commons/InspectorSection";
import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";

import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";

const LabelStyleInspector = (props) => {
  const { style, setLabelStyle } = props;
  const { padding, border, font } = style;

  const setterFactory = (key) => (data) => setLabelStyle({ key, data });
  const borderSetterFactory = (key) => setterFactory(`border.${key}`);
  const fontSetterFactory = (key) => setterFactory(`font.${key}`);

  return (
    <InspectorSection title="Styles" initialFolded>
      {/* PADDING not working good */}
      <InspectorInput label="Padding">
        <InputNumber onChange={setterFactory("padding")} defaultValue={padding} />
      </InspectorInput>
      <FontInspector {...font} setterFactory={fontSetterFactory} level={1} />
      <BorderInspector setterFactory={borderSetterFactory} {...border} />
    </InspectorSection>
  );
};

LabelStyleInspector.defaultProps = {};

LabelStyleInspector.propTypes = {
  style: PropTypes.object,

  setLabelStyle: PropTypes.func,
};

const stateToProps = (state) => ({
  style: labelSelectors.labelDesigner.getLabelStyleData(state),
});

const dispatchToProps = {
  setLabelStyle: labelActions.labelDesigner.setLabelStyle,
};

export default connect(stateToProps, dispatchToProps)(LabelStyleInspector);
