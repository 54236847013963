import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Radio } from "antd";

import ReactToPrint from "react-to-print";
import { Row, Column, AsciButton } from "~/global";
import CannedReportFilters from "~/canned-reports/components/CannedReportFilters/CannedReportFilters.component";
import GroupBarChartByOS from "~/canned-reports/components/GroupBarChartByOS/GroupBarChartByOS.component";
import GroupBarChartByServiceSuite from "~/canned-reports/components/GroupBarChartByServiceSuite/GroupBarChartByServiceSuite.component";
import ReportSummaryProcesses from "~/canned-reports/components/ReportSummaryProcesses/ReportSummaryProcesses.component";

import moduleSelectors from "~/canned-reports/selectors/";

import "./DevicesProcessedPage.component.scss";

const DevicesProcessedPage = ({
  formattedProcesses,
  columns,
  processesTitle,
  processesForReport,
  formattedProcessesBySS,
  columnsBySS,
  processesTitleBySS,
  processesForReportBySS,
}) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const [displayBy, setDisplayBy] = useState("os");
  const ref = useRef();
  const nbOfFacilities = Object.keys(formattedProcesses.intervalsByFacility).length;
  return (
    <Column className="devices-processed-by-os-page full-width">
      <CannedReportFilters
        currentFilters={currentFilters}
        setCurrentFilters={(filters) => setCurrentFilters(filters)}
        currentCannedReport="devicesProcessed"
        filtersToDisplay="timeframe facility team program serviceSuite platform"
      />

      <div className="report-results-wrapper">
        <Row className="report-results">
          <div className="report-statistics">
            <div style={{ display: "flex" }}>
              <Row className="result-item">
                Results:&nbsp;<b>{nbOfFacilities}</b>&nbsp;Facilities
              </Row>

              <Row className="result-item">
                <b>{formattedProcesses.totalProcesses}</b>&nbsp;Total Devices Processed
              </Row>
            </div>
            <div className="report-radio-group">
              <Row className="result-item">
                <div className="radio-label">Display results by</div>
                <Radio.Group
                  onChange={(e) => setDisplayBy(e.target.value)}
                  value={displayBy}
                  buttonStyle="solid"
                >
                  <Radio.Button value="os">OS</Radio.Button>
                  <Radio.Button value="service-suite">Service Suite</Radio.Button>
                </Radio.Group>
              </Row>
            </div>
          </div>

          <ReactToPrint
            content={() => ref.current}
            pageStyle={`
              @page { size: auto; margin: 1cm; }
              @media print {
                  .page-break {
                    break-inside: avoid;
                    break-after: avoid;
                  }
                  body { -webkit-print-color-adjust: exact; }}
              .recharts-wrapper > .recharts-surface { width: 1120px; height: 600px; }
              .recharts-legend-wrapper > .recharts-default-legend { width: 1120px; height: 600px; }
            `}
            trigger={() => (
              <AsciButton color="white">
                <div className="row centered">
                  <i className="icon-printer" />
                  <span className="margin-left--5">Export</span>
                </div>
              </AsciButton>
            )}
          />
        </Row>
      </div>

      {displayBy === "os" ? (
        <div ref={ref}>
          <div>
            <GroupBarChartByOS
              title="Devices Processed by OS"
              data={formattedProcesses.chartData}
              intervalsByFacility={formattedProcesses.intervalsByFacility}
            />
          </div>
          <div className="page-break">
            <ReportSummaryProcesses
              data={processesForReport}
              columns={columns}
              titles={processesTitle}
            />
          </div>
        </div>
      ) : (
        <div ref={ref}>
          <div className="no-break">
            <GroupBarChartByServiceSuite
              title="Devices Processed by Service Suite"
              data={formattedProcessesBySS.chartData}
              intervalsByFacility={formattedProcessesBySS.intervalsByFacility}
            />
          </div>
          <div className="page-break">
            <ReportSummaryProcesses
              data={processesForReportBySS}
              columns={columnsBySS}
              titles={processesTitleBySS}
            />
          </div>
        </div>
      )}
    </Column>
  );
};

const stateToProps = (state) => ({
  formattedProcesses: moduleSelectors.processesByOS.getFormattedProcesses(state),
  columns: moduleSelectors.processesByOS.getProcessesKeys(state),
  processesTitle: moduleSelectors.processesByOS.getProcessesTitle(state),
  processesForReport: moduleSelectors.processesByOS.getProcessesForReport(state),
  // totalProcessesByOS: moduleSelectors.processesByOS.getTotalProcessesByOS(state)
  formattedProcessesBySS: moduleSelectors.processesByServiceSuite.getFormattedProcesses(state),
  columnsBySS: moduleSelectors.processesByServiceSuite.getProcessesKeys(state),
  processesTitleBySS: moduleSelectors.processesByServiceSuite.getProcessesTitle(state),
  processesForReportBySS: moduleSelectors.processesByServiceSuite.getProcessesForReport(state),
});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(DevicesProcessedPage);
