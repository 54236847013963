import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextField, DataTable, TableBody, TableHeader, TableColumn, TableRow } from "react-md";

import {
  SectionHeader,
  Panel,
  AsciButton,
  StaticField,
  TablePagination,
  SelectList,
} from "~/global";
import { RemoteDiagnosticsSession } from "~/models";
import selectors from "~/selectors";
import actions from "~/actions";

class RemoteSessionsHistory extends Component {
  static propTypes = {
    license: PropTypes.string.isRequired,
  };

  state = {
    sessions: [],
    sessionStatus: [
      "Any",
      RemoteDiagnosticsSession.SESSION_STATUS_EXPIRED,
      RemoteDiagnosticsSession.SESSION_STATUS_COMPLETED,
      RemoteDiagnosticsSession.SESSION_STATUS_PENDING,
    ],
    filters: {
      // firstName: "",
      // lastName: "",
      // phone: "",
      // email: "",
      model: "",
      imei: "",
      status: "",
    },
    currentPage: 1,
    rowsPerPage: 10,
  };

  componentDidMount() {
    this.onSearch();
  }

  onFilterChanged = (val, field) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: val,
      },
    });
  };

  clearFilters = () => {
    this.setState({
      filters: {
        // firstName: "",
        // lastName: "",
        // phone: "",
        // email: "",
        model: "",
        imei: "",
        status: "",
      },
    });
  };

  onSearch = async () => {
    const sessions = await this.props.fetchSessions(this.props.license, this.state.filters);

    this.setState({ sessions });
  };

  searchOnEnter = (e) => {
    if (e.key == "Enter") {
      this.onSearch();
    }
  };

  onPagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  downloadCSV = () => {
    this.props.downloadCSV(this.props.license);
  };

  render() {
    const firstIndex = (this.state.currentPage - 1) * this.state.rowsPerPage;
    const lastIndex = firstIndex + this.state.rowsPerPage;
    const rowsToShow = [...(this.state.sessions || [])].slice(firstIndex, lastIndex);

    const resultsSectionTitle =
      this.state.sessions.length === 1
        ? `Search Results (${this.state.sessions.length} session)`
        : `Search Results (${this.state.sessions.length} sessions)`;

    return (
      <div>
        <SectionHeader title="Remote Session">
          {this.props.canExportSessions ? (
            <AsciButton color="white" onClick={this.downloadCSV}>
              Export all sessions to CSV
            </AsciButton>
          ) : null}
        </SectionHeader>

        <div className="layout-column padding-bottom--10">
          <Panel title="Search By">
            <div className="layout-row">
              <div className="layout-column flex--50 padding-v--5 padding-h--10">
                {/* <StaticField label="First Name:">
                                    <TextField
                                        id="field-name-input"
                                        name="firstName"
                                        value={this.state.filters.firstName}
                                        onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                                        onKeyPress={this.searchOnEnter}
                                        tabIndex={1}
                                    />
                                </StaticField> */}

                {/* <StaticField label="Phone Number:">
                                    <TextField
                                        id="phone-input"
                                        name="phone"
                                        value={this.state.filters.phone}
                                        onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                                        onKeyPress={this.searchOnEnter}
                                        tabIndex={3}
                                    />
                                </StaticField> */}

                <StaticField label="Status:">
                  <SelectList
                    id="status-list"
                    items={this.state.sessionStatus}
                    selectedItem={this.state.filters.status}
                    onChange={(value) => {
                      if ((value || "").toLowerCase() === "any") {
                        value = "";
                      }
                      this.onFilterChanged(value, "status");
                    }}
                    nameBuilder={(item = "") => {
                      if (item.length) {
                        return item[0].toUpperCase() + item.slice(1);
                      }
                      return item;
                    }}
                    tabIndex={1}
                  />
                </StaticField>
              </div>

              <div className="layout-column flex--50 padding-v--5 padding-h--10">
                {/* <StaticField label="Last Name:">
                                    <TextField
                                        id="last-name-input"
                                        name="lastName"
                                        value={this.state.filters.lastName}
                                        onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                                        onKeyPress={this.searchOnEnter}
                                        tabIndex={2}
                                    />
                                </StaticField> */}

                {/* <StaticField label="Email:">
                                    <TextField
                                        id="email-input"
                                        name="email"
                                        value={this.state.filters.email}
                                        onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                                        onKeyPress={this.searchOnEnter}
                                        tabIndex={4}
                                    />
                                </StaticField> */}

                <StaticField label="IMEI:">
                  <TextField
                    id="imei-input"
                    name="imei"
                    value={this.state.filters.imei}
                    onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                    onKeyPress={this.searchOnEnter}
                    tabIndex={2}
                  />
                </StaticField>

                <StaticField label="Model:">
                  <TextField
                    id="model-input"
                    name="model"
                    value={this.state.filters.model}
                    onChange={(val, e) => this.onFilterChanged(val, e.target.name)}
                    onKeyPress={this.searchOnEnter}
                    tabIndex={3}
                  />
                </StaticField>
              </div>
            </div>
          </Panel>

          <div className="layout-row layout-row--end-center">
            <AsciButton color="white" onClick={this.clearFilters}>
              Clear
            </AsciButton>
            <AsciButton color="blue" onClick={this.onSearch}>
              Search
            </AsciButton>
          </div>
        </div>

        <SectionHeader title={resultsSectionTitle} />

        <div className="layout-column">
          <Panel title="Sessions">
            <DataTable className="cc-table" plain>
              <TableHeader>
                <TableRow>
                  {/* <TableColumn>
                                        First Name
                                    </TableColumn>

                                    <TableColumn>
                                        Last Name
                                    </TableColumn>

                                    <TableColumn>
                                        Phone Number
                                    </TableColumn>

                                    <TableColumn>
                                        Email
                                    </TableColumn> */}

                  <TableColumn>IMEI</TableColumn>

                  <TableColumn>Model</TableColumn>

                  <TableColumn>Status</TableColumn>
                </TableRow>
              </TableHeader>

              <TableBody>
                {(rowsToShow || []).map((session) => (
                  <TableRow
                    key={session.sessionId}
                    onClick={(e) => this.props.onSessionSelected(session)}
                  >
                    {/* <TableColumn>
                                                {session.firstName}
                                            </TableColumn>

                                            <TableColumn>
                                                {session.lastName}
                                            </TableColumn>

                                            <TableColumn>
                                                {session.phone}
                                            </TableColumn>

                                            <TableColumn>
                                                {session.email}
                                            </TableColumn> */}

                    <TableColumn>{session.imei}</TableColumn>

                    <TableColumn>{session.model}</TableColumn>

                    <TableColumn>{session.status}</TableColumn>
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>

            <TablePagination
              totalDoc={this.state.sessions.length}
              docPerPage={this.state.rowsPerPage}
              pageNo={this.state.currentPage}
              paginationCallback={this.onPagination}
            />
          </Panel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  canExportSessions: selectors.session.hasPermission(
    state,
    "GET",
    "EXPORT_REMOTE_DIAGNOSTICS_SESSIONS"
  ),
});

const mapDispatchToProps = {
  fetchSessions: actions.remoteDiagnostics.fetchSessions,
  downloadCSV: actions.remoteDiagnostics.downloadCSV,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSessionsHistory);
