import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import find from "lodash/find";

import "./RecordingPreview.scss";
import labelSelectors from "~/label-printer/selectors";

const RecordingPreview = (props) => {
  const { path, includeLabel, customLabel, recordingFields } = props;
  const fieldInfo = find(recordingFields, { path });

  const recordingValue = useSelector(labelSelectors.labelPrint.getRecordingValue(path));

  return (
    <div className="recording-preview">
      {includeLabel && (
        <div className="recording-preview-label">{customLabel || fieldInfo?.name}</div>
      )}
      <div className="recording-preview-value">{recordingValue || fieldInfo?.preview}</div>
    </div>
  );
};

RecordingPreview.defaultProps = {};

RecordingPreview.propTypes = {
  path: PropTypes.string,
  includeLabel: PropTypes.bool,
  customLabel: PropTypes.string,
  recordingFields: PropTypes.array,
};

const stateToProps = (state) => ({
  recordingFields: labelSelectors.labelFields.getAvailableFields(state),
});

export default connect(stateToProps, null)(RecordingPreview);
