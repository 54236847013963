import { createAction } from "@reduxjs/toolkit";
import uuid from "uuid/v4";
import { CustomerManagement } from "~/services";

// List all Templates
export const fetchServiceSuiteTemplatesBegin = createAction(
  "serviceSuiteTemplates/FETCH_TEMPLATES_BEGIN"
);
export const fetchServiceSuiteTemplatesSuccess = createAction(
  "serviceSuiteTemplates/FETCH_TEMPLATES_SUCCESS"
);
export const fetchServiceSuiteTemplatesError = createAction(
  "serviceSuiteTemplates/FETCH_TEMPLATES_ERROR"
);

// update Template
export const updateServiceTemplateBegin = createAction(
  "serviceSuiteTemplates/UPDATE_TEMPLATES_BEGIN"
);
export const updateServiceTemplateSuccess = createAction(
  "serviceSuiteTemplates/UPDATE_TEMPLATES_SUCCESS"
);
export const updateServiceTemplateError = createAction(
  "serviceSuiteTemplates/UPDATE_TEMPLATES_ERROR"
);

// duplicate & new Template
export const addServiceTemplateBegin = createAction("serviceSuiteTemplates/ADD_TEMPLATES_BEGIN");
export const addServiceTemplateSuccess = createAction(
  "serviceSuiteTemplates/ADD_TEMPLATES_SUCCESS"
);
export const addServiceTemplateError = createAction("serviceSuiteTemplates/ADD_TEMPLATES_ERROR");

// nonrelatedServices to a Template
export const fetchNonrelatedServicesBegin = createAction(
  "serviceSuiteTemplates/FETCH_NONRELATED_SERVICES_BEGIN"
);
export const fetchNonrelatedServicesSuccess = createAction(
  "serviceSuiteTemplates/FETCH_NONRELATED_SERVICES_SUCCESS"
);
export const fetchNonrelatedServicesError = createAction(
  "serviceSuiteTemplates/FETCH_NONRELATED_SERVICES_ERROR"
);

// affectedPrograms for a Template
export const fetchAffectedProgramsBegin = createAction(
  "serviceSuiteTemplates/FETCH_AFFECTED_PROGRAMS_BEGIN"
);
export const fetchAffectedProgramsSuccess = createAction(
  "serviceSuiteTemplates/FETCH_AFFECTED_PROGRAMS_SUCCESS"
);
export const fetchAffectedProgramsError = createAction(
  "serviceSuiteTemplates/FETCH_AFFECTED_PROGRAMS_ERROR"
);

// Redux state management functions
export const onAddPreparedTemplates = createAction("serviceSuiteTemplates/ADD_PREPARED_TEMPLATES");
export const saveEditedTemplate = createAction("serviceSuiteTemplates/SAVE_EDITED_TEMPLATE");
export const onUpdateNRServiceList = createAction("serviceSuiteTemplates/UPDATE_NR_SERVICES");
export const onClearNRServicesState = createAction("serviceSuiteTemplates/CLEAR_NR_SERVICES");
export const onAddEmptyTemplate = createAction("serviceSuiteTemplates/ADD_EMPTY_TEMPLATE");
export const onCancelChanges = createAction("serviceSuiteTemplates/CANCELED_CHANGES");

export const fetchTemplates = () => async (dispatch) => {
  try {
    dispatch(fetchServiceSuiteTemplatesBegin());
    const response = await CustomerManagement.GET("/serviceSuite-template");
    const templateList = response.payload;
    await dispatch(fetchServiceSuiteTemplatesSuccess({ templateList }));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchServiceSuiteTemplatesError({ message }));
  }
};

export const updateTemplate = (template) => async (dispatch) => {
  try {
    dispatch(updateServiceTemplateBegin());
    await CustomerManagement.PUT(`/serviceSuite-template/${template.identifier}`, template);
    dispatch(updateServiceTemplateSuccess());
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateServiceTemplateError({ message }));
  }
};

export const addTemplate = (template) => async (dispatch) => {
  try {
    dispatch(addServiceTemplateBegin());
    await CustomerManagement.POST("/serviceSuite-template", template);
    dispatch(addServiceTemplateSuccess());
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(addServiceTemplateError({ message }));
  }
};

export const addPreparedTemplates = (templates) => (dispatch) => {
  const preparedTemplates = templates.map((template) => {
    const templateUuid = uuid();
    const services = template.services.map((service) => {
      const serviceUuid = uuid();
      const args = service.arguments.map((argument) => {
        const argUuid = uuid();
        const preparedArg = { ...argument, id: argUuid };
        return preparedArg;
      });
      const preparedService = { ...service, arguments: args, id: serviceUuid };

      return preparedService;
    });
    const preparedTemplate = { ...template, services, id: templateUuid };
    return preparedTemplate;
  });

  dispatch(onAddPreparedTemplates(preparedTemplates));
};

export const fetchNonrelatedServices = (templateID) => async (dispatch) => {
  dispatch(fetchNonrelatedServicesBegin());

  try {
    const response = await CustomerManagement.QUERY(`{
      nonrelatedServices(templateID: "${templateID}") {
        identifier,
        className,
        displayName { en },
        isEnabled,
        isOptional,
        arguments {
          name,
          value,
          type
        }
      }   
    }`);
    const { nonrelatedServices } = response.payload.data;
    const services = nonrelatedServices.map((service) => ({
      ...service,
      isChecked: false,
      arguments: service.arguments.map((arg) => ({ ...arg, id: uuid() })),
      id: uuid(),
    }));

    dispatch(fetchNonrelatedServicesSuccess(services));
  } catch (e) {
    if (e instanceof Error) throw e;
    const message = e.json?.message ?? "Request didn't complete successfully";

    dispatch(fetchNonrelatedServicesError({ message }));
  }
};

export const fetchAffectedPrograms = (template) => async (dispatch) => {
  dispatch(fetchAffectedProgramsBegin());
  try {
    const response = await CustomerManagement.QUERY(`{
        affectedPrograms(templateID: "${template.identifier}") {
            _id,
            identifiers,
            nbPrograms,
        }   
    }`);

    dispatch(
      fetchAffectedProgramsSuccess({
        templateId: template.identifier,
        affectedPrograms: response.payload.data.affectedPrograms,
      })
    );
  } catch (e) {
    if (e instanceof Error) throw e;
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchAffectedProgramsError({ errors: message }));
  }
};
