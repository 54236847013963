import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { isEmpty } from "lodash";

import { Button, DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { Trans } from "react-i18next";
import actions from "~/actions";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";

import CustomerTeamDetails from "./CustomerTeamDetails.component";
import CustomerTeamEditor from "./CustomerTeamEditor.component";
import CustomerTeamEditorButtons from "./CustomerTeamEditorButtons.component";

import {
  SectionHeader,
  AsciButton,
  DialogConfirmation,
  DialogMessage,
  InputSearch,
} from "~/global";
import { DEFAULT_TEAM } from "~/constants";
import { useTranslation } from "~/i18n";
import { useAppDispatch, useAppSelector } from "~/hooks";

import "./CustomerTeams.component.scss";

const CustomerTeams = (props) => {
  const { t } = useTranslation("customerTeams");
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.modules.customerManagement.customerUsers.roles);
  const isEmptyList = props.teams.length === 0;

  useEffect(() => {
    props.fetchTeams(props.csid);
    dispatch(moduleActions.customerTeams.fetchUsersTeams(props.csid));
  }, [dispatch, props.csid]);

  useEffect(() => {
    if (isEmpty(roles)) {
      dispatch(moduleActions.customerUsers.fetchUserRoles());
    }
  }, [dispatch, roles]);

  const [teamsList, setTeamsList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  useEffect(() => {
    if (props.teams.length > 0) {
      filterTeamsList();
    }
  }, [nameFilter]);

  useEffect(() => {
    if (props.teams.length > 0) {
      filterTeamsList();
    }
  }, [props.teams]);

  const filterTeamsList = () => {
    if (nameFilter.length > 0) {
      const newFacilitiesList = props.teams.filter((team) => {
        const teamName = (team.name || "").toLowerCase();
        const facilityName = (team.facilityName || "").toLowerCase();
        const name = nameFilter.toLowerCase();
        return teamName.includes(name) || facilityName.includes(name);
      });
      setTeamsList(newFacilitiesList);
    } else {
      setTeamsList(props.teams);
    }
  };

  const onSort = (key) => {
    props.setSortReverse();
    props.setSortKey(key);
  };

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = props.sortKey == colKey;
    sorted ? classes.push("is-sorted") : null;
    props.sortReverse ? classes.push("sort-reverse") : null;
    return classes.join(" ");
  };

  const onSaveTeam = () => {
    props.onSave(props.csid);
    props.onClear();
    props.hideDialog();
  };

  const onAddTeam = () => {
    props.onAdd(props.csid);
    props.onClear();
    props.hideDialog();
  };

  const onCancel = () => {
    props.onClear();
    props.hideDialog();
  };

  const viewTeamDetails = (team) => {
    props.setTeam(team);

    props.showDialog(
      {
        title: t("title-default-team"),
        width: "720px",
        content: () => <CustomerTeamDetails />,
      },
      onCancel
    );
  };

  const editTeamCallback = (team) => {
    props.setTeam(team);
    props.showDialog(
      {
        title: t("title-edit-team"),
        width: "850px",
        modal: true,
        content: () => (
          <div>
            <CustomerTeamEditor
              onSave={onSaveTeam}
              editMode
              onCancel={onCancel}
              originalTeamName={team.name}
              csid={props.csid}
            />
          </div>
        ),
        footer: () => <CustomerTeamEditorButtons onSave={onSaveTeam} onCancel={onCancel} />,
      },
      onCancel
    );
  };

  const addTeamCallback = () => {
    props.initTeam(props.csid);
    props.showDialog({
      title: t("title-add-team"),
      width: "850px",
      modal: true,
      content: () => (
        <div>
          <CustomerTeamEditor
            csid={props.csid}
            onSave={onAddTeam}
            editMode={false}
            onCancel={onCancel}
          />
          <CustomerTeamEditorButtons onSave={onAddTeam} onCancel={onCancel} />
        </div>
      ),
    });
  };

  const confirmDeleteTeam = (team) => {
    props.showDialog({
      title: t("title-delete-team"),
      width: "450px",
      modal: true,

      content: (close) => (
        <DialogConfirmation
          onCancel={() => {
            close();
          }}
          onConfirm={() => {
            close();
            props.onDelete(team);
          }}
          textButtonConfirm={t("confirm-delete-team-delete")}
        >
          <p className="align-center">{t("confirm-delete-team-are-you-sure")} </p>
          <p className="align-center">
            <Trans t={t} i18nKey="confirm-delete-it-will-be-impossible" />
          </p>
        </DialogConfirmation>
      ),
    });
  };

  const infoForbidenDelte = () => {
    props.showDialog({
      title: t("title-forbidden"),
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={props.hideDialog} textButtonClose={t("close-button")}>
          <div>{t("info-at-least-one-team")}</div>
        </DialogMessage>
      ),
    });
  };

  const onInfoClicked = () => {
    props.showDialog({
      title: t("title-usage-information"),
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={props.hideDialog} textButtonClose={t("close-button")}>
          <div>{t("a-team-name-and-at-least")}</div>
          <br />
          <div>{t("click-the-list-header")}</div>
        </DialogMessage>
      ),
    });
  };

  return (
    <div className="customer-teams">
      <SectionHeader title={t("header-customer") + props.customerName} description={props.csid}>
        <div className="push-right row centered">
          <InputSearch
            placeholder={t("placeholder-search-team")}
            onChange={(name) => setNameFilter(name)}
            initialValue={nameFilter}
            trim
          />
          <AsciButton color="green" onClick={() => addTeamCallback()}>
            {t("add-team")}
          </AsciButton>
          <Button
            icon
            className="icon-btn text-color--white"
            iconClassName="icon-info-circle"
            forceIconSize={32}
            forceIconFontSize
            onClick={() => onInfoClicked()}
          />
        </div>
      </SectionHeader>
      <DataTable className="cc-table full-width" baseId="session-list-table" plain>
        {!isEmptyList ? (
          <TableHeader>
            <TableRow className="asci-table-row">
              <TableColumn className={getColHeaderClasses("name")} onClick={() => onSort("name")}>
                {t("team-name")}
              </TableColumn>
              <TableColumn
                className={getColHeaderClasses("facility")}
                onClick={() => onSort("facility")}
              >
                {t("facility")}
              </TableColumn>
              <TableColumn
                className={getColHeaderClasses("usersNumber")}
                onClick={() => onSort("usersNumber")}
              >
                {t("number-of-operators")}
              </TableColumn>
              <TableColumn>{t("description")}</TableColumn>
              <TableColumn className="buttons" />
            </TableRow>
          </TableHeader>
        ) : null}
        <TableBody>
          {isEmptyList ? (
            <TableRow className="message-empty">
              <TableColumn colSpan="4">{t("there-are-no-teams")}</TableColumn>
            </TableRow>
          ) : (
            (teamsList || []).map((team, index) => (
              <TeamRow
                key={`team-${index}`}
                team={team}
                teams={props.teams}
                viewTeamDetails={viewTeamDetails}
                editTeamCallback={editTeamCallback}
                infoForbidenDelte={infoForbidenDelte}
                confirmDeleteTeam={confirmDeleteTeam}
              />
            ))
          )}
        </TableBody>
      </DataTable>
    </div>
  );
};

const TeamRow = ({
  team,
  teams,
  viewTeamDetails,
  editTeamCallback,
  infoForbidenDelte,
  confirmDeleteTeam,
}) => {
  const isDefault = team.name === DEFAULT_TEAM;
  const { t } = useTranslation("customerTeams");

  return (
    <TableRow className={classnames("team-row", { "default-team": isDefault })}>
      <TableColumn className="team-name">
        {team.name === DEFAULT_TEAM ? t("title-default-team") : team.name || t("not-available")}
      </TableColumn>
      <TableColumn>{team.facilityName || t("not-available")}</TableColumn>
      <TableColumn>{team.usersNumber || t("not-available")}</TableColumn>
      <TableColumn>{team.description || t("not-available")}</TableColumn>

      <TableColumn>
        {isDefault ? (
          <div className="row centered to-end">
            <Button
              icon
              className="icon-btn"
              iconClassName="icon-file-text"
              onClick={() => viewTeamDetails(team)}
            />
          </div>
        ) : (
          <div className="row centered to-end">
            <Button
              icon
              className="icon-btn"
              iconClassName="icon-pencil"
              onClick={() => editTeamCallback(team)}
            />

            <Button
              icon
              className="icon-btn text-color--red"
              iconClassName="icon-trash"
              onClick={() => (teams.length === 1 ? infoForbidenDelte() : confirmDeleteTeam(team))}
            />
          </div>
        )}
      </TableColumn>
    </TableRow>
  );
};

CustomerTeams.propTypes = {
  facilitiesById: PropTypes.object.isRequired,
  newCustomer: PropTypes.bool.isRequired,
};

CustomerTeams.defaultProps = {
  newCustomer: true,
};

const stateToProps = (state) => ({
  customerName: moduleSelectors.customerIdentity.getCustomerName(state),
  csid: moduleSelectors.customerIdentity.getCsid(state),

  teams: moduleSelectors.customerTeams.getGroupedTeams(state),
  initialized: state.modules.customerManagement.customerTeams.initialized,

  facilitiesList: moduleSelectors.customerFacilities.getUpdatedFacilities(state),

  sortKey: state.modules.customerManagement.customerTeams.sortKey,
  sortReverse: state.modules.customerManagement.customerTeams.sortReverse,
});

const dispatchToProps = {
  onClear: moduleActions.customerTeamEditor.onClear,
  setTeam: moduleActions.customerTeamEditor.setTeam,
  initTeam: moduleActions.customerTeamEditor.initTeam,
  onDelete: moduleActions.customerTeams.onDeleteTeam,
  onSave: moduleActions.customerTeams.onSaveTeam,
  onAdd: moduleActions.customerTeams.onAddTeam,
  setSortKey: moduleActions.customerTeams.setSortKey,
  setSortReverse: moduleActions.customerTeams.setSortReverse,

  fetchUsers: moduleActions.customerUsers.fetchUsers,
  fetchTeams: moduleActions.customerTeams.fetchTeams,

  hideDialog: actions.dialog.hide,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(CustomerTeams);
