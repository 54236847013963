// TODO: rename this file to recordingFacets and rename initialState type accordingly
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { groupBy, keyBy, mapValues } from "lodash";

import { CustomerManagement } from "~/services";
import type { RootState } from "~/store";

export type RecordingFacet = {
  value: string;
  label: string;
  supportedReports: string[];
  categories: { name: string; count: number }[];
};

export type FacetOption = {
  label: string;
  value: string;
  supportedReports?: string[];
  children?: FacetOption[];
};

interface ReportingFiltersState {
  facets?: RecordingFacet[];
  status: "idle" | "loading";
  error?: string;
}

const initialState: ReportingFiltersState = {
  status: "idle",
};

// Thunks
export const fetchRecordingFacets = createAsyncThunk<
  RecordingFacet[],
  void,
  { rejectValue: string }
>("reportingFilters/fetchFacetsStatus", async (_, { rejectWithValue }) => {
  try {
    // TODO: translate facet labels (backend - GLOB-3301)
    type GetReportingFacetsPayload = { csid: string; facets: RecordingFacet[] };
    const response = await CustomerManagement.GET("/reporting/facets");
    const { facets } = response.payload as GetReportingFacetsPayload;
    return facets;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

// Slice
const reportingFiltersSlice = createSlice({
  name: "reportingFilters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordingFacets.pending, (draftState) => {
        draftState.status = "loading";
      })
      .addCase(fetchRecordingFacets.fulfilled, (draftState, action) => {
        draftState.facets = action.payload;
        draftState.status = "idle";
      })
      .addCase(fetchRecordingFacets.rejected, (draftState, action) => {
        draftState.error = action.payload;
        draftState.status = "idle";
      });
  },
});

export default reportingFiltersSlice.reducer;

// Selectors
const selectFacets = (state: RootState) => state.reportingModule.filters.facets;
const selectFacilities = (state: RootState) => state.facilities.items;

export const selectLoadingFacets = (state: RootState) =>
  state.reportingModule.filters.status === "loading";

export const selectOptions = createSelector(
  [selectFacets, selectFacilities],
  (facets, facilities) => {
    if (!facets || !facilities) {
      return undefined;
    }

    const options: FacetOption[] = facets.map((facet) => {
      const children = facet.categories.map((category) => {
        if (facet.value === "tenantIdentifier.portalFacilityID") {
          const facility = facilities.find((facility) => facility.faid === category.name);
          const facilityName = facility?.name ?? category.name;

          return {
            label: facilityName,
            value: category.name,
          };
        }

        return {
          label: category.name,
          value: category.name,
        };
      });

      return {
        value: facet.value,
        label: facet.label,
        supportedReports: facet.supportedReports,
        children,
      };
    });

    return options;
  }
);

export const selectOptionsByValue = createSelector([selectOptions], (options) => {
  if (!options) {
    return undefined;
  }

  return keyBy(options, (option) => option.value);
});

/**
 * This word soup of a selector is used to find a facet filter corresponding to
 * a column (groupBy value) of the reporting chart.
 * The chart values are returned by the /reporting/chart endpoint in uppercase.
 */
export const selectOptionChildrenGroupedByUppercaseValue = createSelector(
  [selectOptionsByValue],
  (options) => {
    if (!options) {
      return undefined;
    }

    return mapValues(options, (option) =>
      groupBy(option.children, (child) => child.value.toUpperCase())
    );
  }
);
