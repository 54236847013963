import { createAction } from "@reduxjs/toolkit";
import { omit } from "lodash";

import { Filter } from "~/features/recording-facets/RecordingFacetsFilter.component";
import { Uniqueness } from "~/features/recording-facets/UniquenessFilter.component";
import { AppThunk } from "~/store";
import { CustomerManagement } from "~/services";

export type ExportedReport = {
  _id: string;
  csid: string;
  /** auth0 userId */
  userIds: string[];
  /** ISO8601 utc timestamp */
  dateFrom: string;
  /** ISO8601 utc timestamp */
  dateTo: string;
  status: "ready" | "inProgress";
  email: string;
  format: "csv" | "json";
  type: string;
  dateRange?: string;
  // TODO: make the faid required when the legacy reports have expired in ~2 weeks
  faid?: string;
  /** ISO8601 utc timestamp */
  requestedAt: string;
  /** ISO8601 utc timestamp */
  expiryDate: string;
  reportUrl: string;
  reportSize: number;
  name?: string;
  /** always empty */
  createdBy: "";
  /** ISO8601 utc timestamp */
  createdOn: string;
  /** ISO8601 utc timestamp */
  modifiedOn?: string;
  filters?: Filter[];
  uniqueness: Uniqueness;
  /** bulk search file id */
  fileId?: string;
  recordsCount: number;
  durationInMilliseconds?: number;
  columns?: string[];
};

export const fetchExportedReportsBegin = createAction("FETCH_EXPORTED_REPORTS_BEGIN");
export const fetchExportedReportsSuccess = createAction<{
  exportedReports: ExportedReport[];
}>("FETCH_EXPORTED_REPORTS_SUCCESS");
export const fetchExportedReportsError = createAction<{ error: string }>(
  "FETCH_EXPORTED_REPORTS_ERROR"
);
export const fetchExportedReports = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(fetchExportedReportsBegin());

  try {
    const response = await CustomerManagement.GET(`/reporting-list`);
    const exportedReports = response.payload as ExportedReport[];

    const normalizedReports = exportedReports.map((report) => ({
      ...report,
      // TODO: remove once all reports in the db have a uniqueness value
      uniqueness: report.uniqueness || "none",
      // The API returns filters with extra property { searchType: "" }.
      // remove it, just in case
      filters: report.filters?.map((filter) => omit(filter, "searchType")),
    }));

    dispatch(fetchExportedReportsSuccess({ exportedReports: normalizedReports }));
  } catch (e: any) {
    if (e instanceof Error) throw e;

    const message: string = e.json?.message ?? "Request didn't complete successfully";

    dispatch(fetchExportedReportsError({ error: message }));
  }
};
