import { createReducer } from "@reduxjs/toolkit";

import labelActions from "../actions";

const initialState = {
  selectedShape: null,
  displayShapeBorder: true,
  selectedField: null, // For freeform.
};

export default createReducer(initialState, {
  [labelActions.labelPicker.selectShape]: (state, action) => {
    const { identity } = action.payload;
    state.selectedShape = identity;
  },
  [labelActions.labelPicker.setDisplayShapeBorder]: (state, action) => {
    state.displayShapeBorder = action.payload;
  },
});
