// Label designer contains the edited label data
import * as labelDesigner from "./labelDesigner.actions";

// Label Operations
import * as labelSaver from "./labelSaver.actions";
import * as labelLister from "./labelLister.actions";
import * as labelDeleter from "./labelDeleter.actions";

// Selecting shapes in the designer
import * as labelPicker from "./labelPicker.actions";

// Printing preview & label from device history.
import * as labelPrint from "./labelPrint.actions";

// Label statics
import * as labelStatics from "./labelStatics.actions";
import * as labelFonts from "./labelFonts.actions";
import * as labelFormats from "./labelFormats.actions";
import * as labelFields from "./labelFields.actions";

export default {
  labelDesigner,
  labelSaver,
  labelFields,
  labelDeleter,
  labelLister,
  labelPicker,
  labelFonts,
  labelPrint,
  labelFormats,
  labelStatics,
};
