/* eslint-disable react/jsx-props-no-spreading */
import "@babel/polyfill";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch } from "react-redux";
import { Redirect, Router, Switch } from "react-router-dom";
import { CompatRouter, CompatRoute } from "react-router-dom-v5-compat";
import classnames from "classnames";

import { Modal } from "antd";
import { AsciSpinner, RouteWithPermission } from "~/global";
import history from "~/global/history";
import { DialogWithStore, Footer, Navbar } from "~/app";
import { useAuth } from "~/auth/useAuth";
import { useAppDispatch, useAppSelector } from "~/hooks";

import AnalyticsPage from "~/analytics/components/AnalyticsPage/AnalyticsPage.component";
import AuditResultsPage from "~/canned-reports/components/AuditResultsPage/AuditResultsPage.component";
import AuditResultsPerTestPage from "~/canned-reports/components/AuditResultsPerTestPage/AuditResultsPerTestPage.component";
import DevicesProcessedByGroupPage from "~/canned-reports/components/DevicesProcessedByGroupPage/DevicesProcessedByGroupPage.component";
import DevicesProcessedPage from "~/canned-reports/components/DevicesProcessedPage/DevicesProcessedPage.component";
import TestProcessingTimesPage from "~/canned-reports/components/TestProcessingTimesPage/TestProcessingTimesPage.component";
import { CustomFieldEditor } from "~/custom-fields-editor";
import CustomFieldsPage from "~/custom-fields-editor/components/CustomFieldsPage/CustomFieldsPage.component";
import { CustomerEditor, CustomerManagementPage } from "~/customer-management";
import DashboardPage from "~/pages/dashboard/ui/DashboardPage/DashboardPage.component";
import DataImportPage from "~/data-import/components/DataImportPage/DataImportPage.component";
import DeploymentEditor from "~/deployment-management/components/DeploymentEditor/DeploymentEditor.component";
import DeploymentsPage from "~/deployment-management/components/DeploymentsPage/DeploymentsPage.component";
import DevelopersZone from "~/developers-zone/DevelopersZone.component";
import DocumentationTrainingPage from "~/documentation-training/DocumentationTrainingPage/DocumentationTrainingPage.component";
import FailCodesPage from "~/fail-codes/FailCodesPage/FailCodesPage.component";
import HomePage from "~/home/HomePage/HomePage.component";
import KitchenSink from "~/kitchen-sink/KitchenSink.component";
import LabelDesigner from "~/label-printer/components/designer/LabelDesigner";
import LabelPage from "~/label-printer/components/listing/LabelPage";
import { ProgramEditorPage, ProgramsPage } from "~/programs-management";
import RemoteDiagnosticsPage from "~/remote-diagnostics/components/RemoteDiagnosticsPage/RemoteDiagnosticsPage.component";
import { ReportingPage } from "~/pages/reporting";
import ServiceSuiteTemplatesPage from "~/service-suite-templates/components/ServiceSuiteTemplatesPage/ServiceSuiteTemplatesPage.component";
import TestDefinitionsPage from "~/test-definitions-editor/components/TestDefinitionsPage/TestDefinitionsPage.component";
import TestSuitesEditor from "~/test-suites-management/components/TestSuiteEditor/TestSuiteEditor.component";
import TestSuitesPage from "~/test-suites-management/components/TestSuitesPage/TestSuitesPage.component";
import UserLogsPage from "~/user-logs/components/UserLogsPage/UserLogsPage.component";
import VersionManager from "~/version-manager/VersionManager/VersionManager.component";
import CrashDetails from "~/workstation/components/details/CrashDetails";
import CrashReportDetails from "~/workstation/components/details/CrashReportDetails";
import SessionDetails from "~/workstation/components/details/SessionDetails";
import TaskDetails from "~/workstation/components/details/TaskDetails";
import WorkstationDetails from "~/workstation/components/details/WorkstationDetails";
import WorkstationList from "~/workstation/components/listings/WorkstationList";
import { SkuGroupsPage } from "~/pages/sku-groups/ui/SkuGroupsPage";
import { SkuManagerPage } from "~/pages/sku-manager/ui/SkuManagerPage";
import { ModelDescriptorsPage } from "~/pages/model-descriptors/ui/ModelDescriptorsPage";
import { DeviceHistoryPage } from "~/pages/device-history/ui/DeviceHistoryPage";

import actions from "~/actions";
import selectors from "~/selectors";
import { InventoryProjectsPage } from "~/pages/inventory/ui/inventory";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- React Router does not provide a type for the render prop
interface RouteProps extends Record<string, any> {}

const App = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const sessionUser = useAppSelector((state) => state.session.user);
  const isUserProfileLoading = useAppSelector((state) => state.session.isUserProfileLoading);
  const isUpdatingCurrentCustomer = useAppSelector(
    (state) => state.customer.isUpdatingCurrentCustomer
  );
  const activeCustomer = useAppSelector(selectors.session.getActiveCustomer);
  const currentApp = useAppSelector(selectors.session.getCurrentApp);
  // @ts-ignore
  const availableApps = useAppSelector(selectors.session.getAvailableApps);
  const csid = useAppSelector((state) => state.customer?.activeCustomerId);

  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  const isAuthenticated = useMemo(() => auth.status === "authenticated", [auth.status]);

  const errorChangeCustomer = (errors: any, csid: any) => ({
    title: `Error change customer: ${csid}`,
    content: errors,
  });

  const onActiveCustomerChanged = useCallback(
    (csid) => {
      void dispatch(actions.customer.changeActiveCustomer(csid))
        .then(() => {
          // @ts-expect-error
          void dispatch(actions.user.fetchUserProfile(sessionUser.userId));
          void dispatch(actions.customer.fetchMyCustomer());
        })
        .catch((e) => {
          modal.error(errorChangeCustomer(e.errors, csid));
        });

      // strip the querystring
      const path = history.location.pathname;
      history.replace(path);
    },
    [dispatch, sessionUser, csid]
  );

  useEffect(() => {
    setIsLoadingSession(isUserProfileLoading || isUpdatingCurrentCustomer);
  }, [isUserProfileLoading, isUpdatingCurrentCustomer]);

  return (
    <Router history={history}>
      <CompatRouter>
        {csid != null && (
          /* MOST of the UI depends on data pertaining to a customer. We need to make sure to
             remount the section below when the CSID changes. */
          <div key={csid} className={classnames("main-container", currentApp.urlName)}>
            <DialogWithStore />

            {/* @ts-expect-error */}
            <Navbar
              currentApp={currentApp}
              activeCustomer={activeCustomer}
              isAuthenticated={isAuthenticated}
              isLoading={isLoadingSession}
              onActiveCustomerChanged={onActiveCustomerChanged}
              onLogout={auth.logout}
            />

            <div className="content-container">
              {isAuthenticated ? (
                <Switch>
                  {/* Routes */}
                  {/* Home */}
                  <CompatRoute exact path="/" render={() => <HomePage />} />
                  <CompatRoute exact path="/home" render={() => <HomePage />} />

                  {/* Label design */}
                  <CompatRoute
                    exact
                    path="/label-printer/designer"
                    render={() => <LabelDesigner />}
                  />
                  <CompatRoute exact path="/label-printer" render={() => <LabelPage />} />

                  {/* Workstation management */}
                  <RouteWithPermission
                    exact
                    path="/workstations"
                    render={() => <WorkstationList />}
                    appName="workstations"
                  />
                  <RouteWithPermission
                    exact
                    path="/workstations/sessions/:sessionId"
                    render={() => <SessionDetails />}
                    appName="workstations"
                  />
                  <RouteWithPermission
                    exact
                    path="/workstations/tasks/:taskId"
                    render={() => <TaskDetails />}
                    appName="workstations"
                  />
                  <RouteWithPermission
                    exact
                    path="/workstations/crash-report/:crashId"
                    render={() => <CrashReportDetails />}
                    appName="workstations"
                  />
                  <RouteWithPermission
                    exact
                    path="/workstations/crash/:crashId"
                    render={() => <CrashDetails />}
                    appName="workstations"
                  />
                  <RouteWithPermission
                    exact
                    path="/workstations/:workstationId"
                    render={() => <WorkstationDetails />}
                    appName="workstations"
                  />
                  {/* TODO: Review the route below */}
                  {/* Kitchen sink -- We should probably delete this and all the related files */}
                  <CompatRoute exact path="/ks" render={() => <KitchenSink />} />

                  {/* Routes with permissions */}
                  {/* Client deployment */}
                  <RouteWithPermission
                    exact
                    path="/deployment"
                    appName="client-deployment"
                    render={() => <DeploymentsPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/deployment/editor/:faid/:dpid"
                    appName="client-deployment"
                    render={(props: RouteProps) => <DeploymentEditor {...props} />}
                  />
                  <RouteWithPermission
                    exact
                    path="/deployment/editor/:sbid/:faid/:dpid"
                    appName="client-deployment"
                    render={(props: RouteProps) => <DeploymentEditor {...props} />}
                  />
                  <RouteWithPermission
                    exact
                    path="/deployment/wizard/:faid"
                    appName="client-deployment"
                    render={(props: RouteProps) => <DeploymentEditor {...props} />}
                  />

                  {/* Custom fields editor */}
                  <RouteWithPermission
                    exact
                    path="/custom-fields"
                    appName="custom-fields-editor"
                    render={() => <CustomFieldsPage />}
                  />

                  <RouteWithPermission
                    exact
                    path="/custom-fields/editor/:name"
                    appName="custom-fields-editor"
                    render={(props: RouteProps) => <CustomFieldEditor {...props} />}
                  />

                  {/* Customer management */}
                  <RouteWithPermission
                    exact
                    path="/customer-management"
                    appName="customer-manager"
                    render={() => <CustomerManagementPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/customer-management/:csid"
                    appName="customer-manager"
                    render={() => <CustomerEditor />}
                  />

                  {/* Dashboard */}
                  <RouteWithPermission
                    exact
                    path="/dashboard"
                    appName="dashboard"
                    render={() => <DashboardPage />}
                  />
                  {/* inventory management */}
                  <RouteWithPermission
                    exact
                    path="/inventory"
                    render={() => <InventoryProjectsPage />}
                    appName="inventory"
                  />
                  {/* Data import tool */}
                  <RouteWithPermission
                    path="/data-import/:tabSelected"
                    appName="data-import"
                    render={() => <DataImportPage />}
                  />

                  {/* Developers zone */}
                  <RouteWithPermission
                    exact
                    path="/developers"
                    appName="developers-zone"
                    render={() => <DevelopersZone />}
                  />

                  {/* Device history */}
                  <RouteWithPermission
                    exact
                    path="/reporting/process-run/:sessionId"
                    appName="reporting"
                    render={() => <DeviceHistoryPage />}
                  />
                  {/*
                    Continue to support legacy URLs:
                    - /reporting/device/?sessionId=SE-TES-999
                    - /device-history/session/?sessionId=SE-TES-999
                  */}
                  <CompatRoute
                    exact
                    path="/reporting/device"
                    render={(props: RouteProps) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                      const params = new URLSearchParams(props.location.search);
                      const sessionId = params.get("sessionId");

                      return sessionId != null ? (
                        <Redirect to={`/reporting/process-run/${sessionId}`} />
                      ) : (
                        <Redirect to="/reporting" />
                      );
                    }}
                  />
                  <CompatRoute
                    exact
                    path="/device-history/session"
                    render={(props: RouteProps) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                      const params = new URLSearchParams(props.location.search);
                      const sessionId = params.get("sessionId");

                      return sessionId != null ? (
                        <Redirect to={`/reporting/process-run/${sessionId}`} />
                      ) : (
                        <Redirect to="/reporting" />
                      );
                    }}
                  />

                  {/* Documentation and training */}
                  <RouteWithPermission
                    exact
                    path="/documentation-training"
                    appName="documentation-training"
                    render={() => <DocumentationTrainingPage />}
                  />

                  {/* Fail codes */}
                  <RouteWithPermission
                    exact
                    path="/fail-codes"
                    appName="fail-codes"
                    render={() => <FailCodesPage />}
                  />

                  {/* Program management */}
                  <RouteWithPermission
                    exact
                    path="/programs"
                    appName="program-management"
                    render={() => <ProgramsPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/programs/new"
                    appName="program-management"
                    render={(props: RouteProps) => <ProgramEditorPage {...props} />}
                  />
                  <RouteWithPermission
                    exact
                    path="/programs/:identifier"
                    appName="program-management"
                    render={(props: RouteProps) => <ProgramEditorPage {...props} />}
                  />

                  {/* Reporting */}
                  <RouteWithPermission
                    exact
                    path="/reporting"
                    appName="reporting"
                    render={() => <ReportingPage />}
                  />
                  {/* Device history summary */}
                  <RouteWithPermission
                    exact
                    path="/reporting/process-run/:sessionId/summary"
                    appName="reporting"
                    render={() => <DeviceHistoryPage />}
                  />
                  {/* Service suite templates */}
                  <RouteWithPermission
                    exact
                    path="/service-suite-templates"
                    appName="service-suite-templates"
                    render={() => <ServiceSuiteTemplatesPage />}
                  />

                  {/* SKU groups */}
                  <RouteWithPermission
                    exact
                    path="/sku-groups"
                    appName="sku-groups"
                    render={() => <SkuGroupsPage />}
                  />

                  {/* SKU manager */}
                  <RouteWithPermission
                    exact
                    path="/sku-manager"
                    appName="sku-manager"
                    render={() => <SkuManagerPage />}
                  />

                  {/* Model Descriptors */}
                  <RouteWithPermission
                    exact
                    path="/model-descriptors"
                    appName="model-descriptors"
                    render={() => <ModelDescriptorsPage />}
                  />

                  {/* Test configurations */}
                  <RouteWithPermission
                    exact
                    path="/test-configurations"
                    appName="test-configurations"
                    render={() => <TestDefinitionsPage />}
                  />

                  {/* Test suite management */}
                  <RouteWithPermission
                    exact
                    path="/test-suites"
                    appName="test-suites-management"
                    render={() => <TestSuitesPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/test-suites/new"
                    appName="test-suites-management"
                    render={() => <TestSuitesEditor />}
                  />
                  <RouteWithPermission
                    exact
                    path="/test-suites/:tsid"
                    appName="test-suites-management"
                    render={() => <TestSuitesEditor />}
                  />

                  {/* User action monitor */}
                  <RouteWithPermission
                    exact
                    path="/user-logs"
                    appName="user-logs"
                    render={() => <UserLogsPage />}
                  />

                  {/* TODO: Review the routes below */}
                  {/* Analytics and canned reports -- We should probably delete these */}
                  <RouteWithPermission
                    exact
                    path="/analytics"
                    appName="analytics"
                    render={() => <AnalyticsPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/audit-results"
                    appName="audit-results"
                    render={() => <AuditResultsPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/devices-processed-by-group"
                    appName="devices-processed-by-group"
                    render={() => <DevicesProcessedByGroupPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/audit-results-per-test"
                    appName="audit-results-per-test"
                    render={() => <AuditResultsPerTestPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/devices-processed"
                    appName="devices-processed"
                    render={() => <DevicesProcessedPage />}
                  />
                  <RouteWithPermission
                    exact
                    path="/test-processing-times"
                    appName="test-processing-times"
                    render={() => <TestProcessingTimesPage />}
                  />

                  {/* Remote diagnostics -- We should probably delete these and all the related files */}
                  <RouteWithPermission
                    path="/remote-diagnostics/history"
                    appName="remote-diagnostics"
                    render={(props: RouteProps) => <RemoteDiagnosticsPage {...props} />}
                  />
                  <RouteWithPermission
                    path="/remote-diagnostics/:sessionId"
                    appName="remote-diagnostics"
                    render={(props: RouteProps) => <RemoteDiagnosticsPage {...props} />}
                  />
                  <RouteWithPermission
                    path="/remote-diagnostics"
                    appName="remote-diagnostics"
                    render={(props: RouteProps) => <RemoteDiagnosticsPage {...props} />}
                  />

                  {/* Version manager -- Seems broken. We should probably delete it. */}
                  <RouteWithPermission
                    exact
                    path="/version-manager"
                    appName="version-manager"
                    render={(props: RouteProps) => <VersionManager {...props} />}
                  />
                  {/* Redirect all 404 responses to the home page */}
                  <Redirect to="/" />
                </Switch>
              ) : (
                <AsciSpinner visible />
              )}
            </div>
            {contextHolder}
            <Footer />
          </div>
        )}
      </CompatRouter>
    </Router>
  );
};

export default App;
