import { createSelector } from "@reduxjs/toolkit";

const getFacilities = (state) => state.filters.facilities;
const getPrograms = (state) => state.filters.programs;
const getTeams = (state) => state.filters.teams;

/* FACILITIES */

export const getCleanFacilities = createSelector([getFacilities], (facilities) => {
  const cleanFacilities = facilities.reduce(
    (acc, facility) => {
      acc.push({
        faid: facility.faid,
        name: facility.name,
        timezone: facility.timezone,
      });

      return acc;
    },
    [{ name: "All facilities", faid: "all" }]
  );

  return cleanFacilities;
});

export const getTimezonesByFacilityId = createSelector([getFacilities], (facilities) => {
  const timezonesByFacilityId = facilities.reduce((acc, facility) => {
    acc[facility.faid] = facility.timezone;
    return acc;
  }, {});

  return timezonesByFacilityId;
});

export const getDeployments = createSelector([getFacilities], (facilities) => {
  const deployments = facilities
    .map((facility) =>
      facility.deployments.map((deployment) => ({
        name: deployment.name,
        dpid: deployment.dpid,
        faid: facility.faid,
      }))
    )
    .flat();

  return deployments;
});

export const getDeploymentsByFacilityId = createSelector([getDeployments], (deployments) => {
  const deploymentsByFacilityId = deployments.reduce((acc, deployment) => {
    if (!acc[deployment.faid]) {
      acc[deployment.faid] = [{ name: "All deployments", dpid: "all" }];
    }

    acc[deployment.faid].push(deployment);
    return acc;
  }, {});

  return deploymentsByFacilityId;
});

export const getShifts = createSelector([getFacilities], (facilities) => {
  const shifts = facilities
    .map((facility) =>
      facility.deployments.map((deployment) =>
        deployment.shifts.map((shift) => ({
          name: shift.name,
          id: shift.id,
          dpid: deployment.dpid,
          faid: facility.faid,
        }))
      )
    )
    .flat(2);

  return shifts;
});

export const getShiftsByDeploymentId = createSelector([getShifts], (shifts) => {
  const shiftsByDeploymentId = shifts.reduce((acc, shift) => {
    if (!acc[shift.faid]) {
      acc[shift.faid] = { all: [{ name: "All shifts", id: "all" }] };
    }

    if (!acc[shift.faid][shift.dpid]) {
      acc[shift.faid][shift.dpid] = [{ name: "All shifts", id: "all" }];
    }

    acc[shift.faid].all.push(shift);
    acc[shift.faid][shift.dpid].push(shift);
    return acc;
  }, {});

  return shiftsByDeploymentId;
});

export const getProgramIds = createSelector([getFacilities], (facilities) => {
  const programIds = facilities
    .map((facility) =>
      facility.deployments.map((deployment) =>
        deployment.programs.map((program) => ({
          id: program,
          dpid: deployment.dpid,
          faid: facility.faid,
        }))
      )
    )
    .flat(2);

  return programIds;
});

export const getProgramIdsByDeploymentId = createSelector([getProgramIds], (programIds) => {
  const programIdsByDeploymentId = programIds.reduce((acc, programId) => {
    if (!acc[programId.faid]) {
      acc[programId.faid] = { all: [{ id: "all" }] };
    }

    if (!acc[programId.faid][programId.dpid]) {
      acc[programId.faid][programId.dpid] = [{ id: "all" }];
    }

    // TODO: this can create duplicates in the "all" object
    acc[programId.faid].all.push(programId);
    acc[programId.faid][programId.dpid].push(programId);
    return acc;
  }, {});

  return programIdsByDeploymentId;
});

/* TEAMS */

export const getTeamsByFacilityId = createSelector([getTeams], (teams) => {
  const teamsByFacilityId = teams.reduce((acc, team) => {
    if (!acc[team.faid]) {
      acc[team.faid] = [{ name: "All teams", teamid: "all" }];
    }

    acc[team.faid].push(team);
    return acc;
  }, {});

  return teamsByFacilityId;
});

/* PROGRAMS */

export const getCleanPrograms = createSelector([getPrograms], (programs) => {
  const cleanPrograms = programs.map((program) => ({
    name: program.displayName.en,
    id: program.identifier,
  }));

  return cleanPrograms;
});

export const getProgramsById = createSelector([getCleanPrograms], (cleanPrograms) => {
  const programsById = cleanPrograms.reduce(
    (acc, program) => {
      acc[program.id] = program;
      return acc;
    },
    { all: { name: "All programs", id: "all" } }
  );

  return programsById;
});

export const getConfigurations = createSelector([getPrograms], (programs) => {
  const serviceSuites = programs
    .map((program) =>
      program.serviceSuites.map((serviceSuite) => ({
        name: serviceSuite.displayName.en,
        id: serviceSuite.identifier,
        programId: program.identifier,
      }))
    )
    .flat();

  return serviceSuites;
});

export const getConfigurationsByProgramId = createSelector([getConfigurations], (serviceSuites) => {
  const serviceSuitesByProgramId = serviceSuites.reduce(
    (acc, serviceSuite) => {
      if (!acc[serviceSuite.programId]) {
        acc[serviceSuite.programId] = [{ name: "All service suites", id: "all" }];
      }

      acc.all.push(serviceSuite);
      acc[serviceSuite.programId].push(serviceSuite);
      return acc;
    },
    { all: [{ name: "All service suites", id: "all" }] }
  );

  return serviceSuitesByProgramId;
});

export const getTeamsByIdByFacilityId = createSelector(
  [getTeamsByFacilityId],
  (teamsByFacilityId) => {
    const keys = Object.keys(teamsByFacilityId);

    const teamsByIdByFacilityId = keys.reduce((acc, key) => {
      const teamsById = teamsByFacilityId[key].reduce((acc, team) => {
        acc[team.teamid] = team.name;
        return acc;
      }, {});

      acc[key] = teamsById;
      return acc;
    }, {});

    return teamsByIdByFacilityId;
  }
);
