import React, { CSSProperties, ReactElement, useCallback, useEffect, useState } from "react";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Spin, Switch } from "antd";
import { capitalize } from "lodash";
import { useCurrentPng } from "recharts-to-png";

import { LoadingSpinner, Option, Row, SelectWithLabel } from "~/global";
import { ChartReportType } from "~/actions/reportTypes.actions";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { ChartFilters } from "~/pages/reporting/ReportingFiltersAndChart";
import { SelectedFacet } from "../recording-facets/RecordingFacetsFilter.component";
import { selectOptionChildrenGroupedByUppercaseValue } from "../recording-facets/reportingFilters.slice";
import BarChartGraph from "./BarChartGraph.component";
import { fetchChartData, selectChartData } from "./reportingChart.slice";

const paddingStyle: CSSProperties = { padding: 24 };
const marginRightStyle: CSSProperties = { marginRight: "1em" };

type Props = {
  chartFilters: ChartFilters;
  reportType: ChartReportType;
  onDrillDown: (facets: SelectedFacet[]) => void;
};

const ReportingChart = ({ chartFilters, reportType, onDrillDown }: Props): ReactElement => {
  const { t } = useTranslation("reporting");
  const dispatch = useAppDispatch();
  const [getPng, { ref, isLoading }] = useCurrentPng();

  const facetOptions = useAppSelector(selectOptionChildrenGroupedByUppercaseValue);
  const { status, error } = useAppSelector((state) => state.reportingModule.chart);
  const chartData = useAppSelector(selectChartData);

  const [chosenGroupByValue, setChosenGroupByValue] = useState(
    reportType.groupByOptions.find((option) => option.isDefault)?.value ??
      reportType.groupByOptions[0].value
  );
  const [chosenDisplayByValue, setChosenDisplayByValue] = useState(
    reportType.displayByOptions.find((option) => option.isDefault)?.value ??
      reportType.displayByOptions[0].value
  );

  const [isPercentageShown, setIsPercentageShown] = useState(false);

  useEffect(() => {
    void dispatch(fetchChartData({ chartFilters, groupByValue: chosenGroupByValue }));
  }, [chartFilters, chosenGroupByValue, dispatch]);

  const downloadChartPng = useCallback(async () => {
    const generateFileName = () => {
      const groupSplit = chosenGroupByValue.split(".");
      const group = capitalize(groupSplit[groupSplit.length - 1]);
      const fileName = `${chartFilters.reportTypeName}_${chosenDisplayByValue}By${group}.png`;

      return fileName;
    };

    const png = await getPng();

    if (png != null) {
      const downloadLink = document.createElement("a");
      downloadLink.href = png;
      downloadLink.download = generateFileName();
      downloadLink.click();
    }
  }, [getPng]); // eslint-disable-line react-hooks/exhaustive-deps

  const drillDown = (valueFromChart: string) => {
    // The groupBy values for the chart are returned by the /reporting/graph endpoint as uppercase,
    // and they don't always correspond to the values from the facets options.
    // As a workaround, it is necessary to verify that the value is a valid facet filter before
    // drilling down.
    const value = facetOptions?.[chosenGroupByValue]?.[valueFromChart]?.map((facet) => facet.value);

    if (!value) {
      const chosenGroupByLabel = reportType.groupByOptions.find(
        (option) => option.value === chosenGroupByValue
      )?.label;
      notification.error({
        message: t("chart.error-message"),
        description: t("chart.facet-not-found", {
          groupBy: chosenGroupByLabel,
          value: valueFromChart,
        }),
      });
      return;
    }

    const facets = value.map((item) => [chosenGroupByValue, item]);

    onDrillDown(facets);
  };

  if (chartData.length === 0 && status === "loading") {
    return <LoadingSpinner center size="large" style={paddingStyle} />;
  }

  return (
    <div style={paddingStyle}>
      <Row align="space-between">
        <div>
          <SelectWithLabel
            value={chosenDisplayByValue}
            onChange={(value) => {
              setChosenDisplayByValue(value);
            }}
            label={t("chart.display")}
            selectWidth={200}
            style={marginRightStyle}
          >
            {reportType.displayByOptions.map(({ value }) => (
              <Option key={value} value={value}>
                {
                  {
                    status: t("chart.status"),
                    result: t("chart.result"),
                    count: t("chart.count"),
                  }[value]
                }
              </Option>
            ))}
          </SelectWithLabel>

          <SelectWithLabel
            value={chosenGroupByValue}
            onChange={(value) => {
              setChosenGroupByValue(value);
            }}
            label={t("chart.group-by")}
            selectWidth={200}
            style={marginRightStyle}
          >
            {reportType.groupByOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {/* TODO: translate groupBy labels (backend - GLOB-3301) */}
                {option.label}
              </Option>
            ))}
          </SelectWithLabel>
        </div>
        <div>
          <Button
            icon={isLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            onClick={() => {
              void downloadChartPng();
            }}
            style={marginRightStyle}
          >
            {t("download-chart")}
          </Button>

          <Switch
            checked={isPercentageShown}
            onChange={(checked) => {
              setIsPercentageShown(checked);
            }}
            checkedChildren={t("chart.percentage-switch")}
            unCheckedChildren={t("chart.count-switch")}
          />
        </div>
      </Row>

      <Spin spinning={status === "loading"} size="large" indicator={<LoadingOutlined />}>
        {chartData.length === 0 || error != null ? (
          <Empty style={paddingStyle} />
        ) : (
          <BarChartGraph
            chartData={chartData}
            displayBy={chosenDisplayByValue}
            isPercentageShown={isPercentageShown}
            onDrillDown={drillDown}
            ref={ref}
          />
        )}
      </Spin>
    </div>
  );
};

export default ReportingChart;
