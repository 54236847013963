import React, { useState } from "react";
import { Checkbox, FontIcon } from "react-md";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Row } from "~/global";

import "./ExpandablePanel.component.scss";

const ExpandablePanel = ({
  children,
  id,
  title,
  secondaryLabel,
  onSelect,
  isToggleable,
  isSelected,
  isOpenable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(isSelected);

  // FIXME Now controlled component with isSelected, remove isActive reference
  const onToggleActivation = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (isToggleable) {
      setIsActive(!isActive);
      setIsOpen(isOpenable && isOpen && !isActive);
      onSelect();
    }
  };

  const onToggleOpen = (event) => {
    event.stopPropagation();

    if (isActive) {
      setIsOpen(isOpenable && !isOpen);
    }
  };

  return (
    <div
      className={classnames("cc-expandable-panel", {
        open: isOpen,
        active: isActive,
        openable: isOpenable,
        toggleable: isToggleable,
      })}
    >
      <header onClick={onToggleOpen}>
        <div className="full-width" onClick={onToggleActivation}>
          <Checkbox
            id={`selected-checkbox-${id}`}
            aria-label="select checkbox"
            checkedCheckboxIcon={<FontIcon iconClassName="icon-check-square" />}
            uncheckedCheckboxIcon={<FontIcon iconClassName="icon-square-o" />}
            disabled={!isToggleable}
            inkDisabled
            name={`selected-checkbox-${id}`}
            onChange={() => {}}
            checked={isSelected}
          />

          <Row className="full-width" align="space-between center">
            <h2>{title}</h2>
            <div className="secondary-label">{secondaryLabel}</div>
          </Row>
        </div>

        <div className="arrow-container">
          {isOpenable && <FontIcon iconClassName="icon-chevron-down" />}
        </div>
      </header>

      <main>{children}</main>
    </div>
  );
};

ExpandablePanel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onSelect: PropTypes.func,
  isToggleable: PropTypes.bool, // wether the checkbox can or cannot be checked/unchecked
  isSelected: PropTypes.bool, // wether the checkbox is checked or unchecked
  isOpenable: PropTypes.bool, // wether you can click on the header to expand the panel
};

ExpandablePanel.defaultProps = {
  onSelect: () => {},
  title: "",
  secondaryLabel: "",
  isToggleable: true,
  isSelected: false,
  isOpenable: true,
};

export default ExpandablePanel;
