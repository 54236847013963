import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/canned-reports/actions/";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  // Facility
  [moduleActions.processes.fetchProcessesByTeamBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },

  [moduleActions.processes.fetchProcessesByTeamSuccess]: (state, action) => {
    state.items = action.payload.processesByTeam;
    state.isLoading = false;
  },

  [moduleActions.processes.fetchProcessesByTeamError]: (state, action) => {
    state.errors = action.payload.errors;
    state.items = [];
    state.isLoading = false;
  },

  // Team
  [moduleActions.processes.fetchProcessesByOperatorBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },

  [moduleActions.processes.fetchProcessesByOperatorSuccess]: (state, action) => {
    state.items = action.payload.processesByOperator;
    state.isLoading = false;
  },

  [moduleActions.processes.fetchProcessesByOperatorError]: (state, action) => {
    state.errors = action.payload.errors;
    state.items = [];
    state.isLoading = false;
  },
});
