// Conversion functions
import find from "lodash/find";

export const convertInchToMM = (inch) => inch * 25.4;
export const convertMMToInch = (mm) => mm / 25.4;
export const inchToPixel = (inch, dpi) => inch * dpi;

export const getPrinters = (state) => state.labelPrinter.labelFormats.printers;
export const getPrinterFormats = (printer) => (state) => {
  // All formats available if no printer selected.
  if (!printer || printer === "custom" || !state.labelPrinter.labelStatics.loaded) {
    return state.labelPrinter.labelFormats.formats;
  }

  const printerFormats = find(state.labelPrinter.labelFormats.printers, { name: printer }).formats;
  return state.labelPrinter.labelFormats.formats.filter(
    (p) => p.generic || printerFormats.includes(p.name)
  );
};
