import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Panel.component.scss";

const Panel = ({ children, className, title }) => (
  <div className={classnames("cc-panel", className)}>
    <h2>{title}</h2>
    {children}
  </div>
);

Panel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Panel;
