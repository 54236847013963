import { createReducer } from "@reduxjs/toolkit";
import labelActions from "~/label-printer/actions";

const initialState = {
  fonts: [
    "Arial",
    "Arial Black",
    "Arial Narrow",
    "Bitter",
    "Cousine",
    "Courier Prime",
    "Fira Code",
    "Fira Sans",
    "Inconsolata",
    "Jura",
    "Karla",
    "Lato",
    "Lora",
    "Montserrat",
    "Noto Sans",
    "Oswald",
    "Oxygen",
    "Open Sans",
    "Quicksand",
    "Raleway",
    "Roboto",
    "Roboto Mono",
    "Roboto Slab",
    "Rubik",
    "Source Code Pro",
    "Teko",
    "Ubuntu",
    "Ubuntu Mono",
    "VT323",
    "Work Sans",
  ],
  loading: false,
  loaded: false,
};

export default createReducer(initialState, {
  [labelActions.labelFonts.setFontLoading]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [labelActions.labelFonts.setFontLoaded]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
});
