import React, { useCallback, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Select, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { parseISO } from "date-fns";
import { keyBy } from "lodash";
import { Button as MdButton } from "react-md";

import { ExportColumn } from "~/actions/reportTypes.actions";
import {
  processRunsSelectors,
  searchProcessRuns,
} from "~/entities/process-runs/model/processRunsSlice";
import { ProcessRun } from "~/entities/process-runs/model/processRunTypes";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { MultiLang, useTranslation } from "~/i18n";
import { usePagination } from "~/shared/lib/usePagination";
import { type SearchFilters } from "./ReportingFiltersAndChart";
import { ReportingSection } from "./ReportingSection";

type ExtraColumn = Pick<ExportColumn, "label" | "value">;

type Props = {
  filters: SearchFilters;
  extraColumns: ExtraColumn[];
  title?: MultiLang;
  onClose: () => void;
};

export const ProcessRuns = ({ filters, extraColumns, title, onClose }: Props) => {
  const { t, language } = useTranslation("reporting");
  const dispatch = useAppDispatch();
  const { page, pageSize, changePagination } = usePagination(
    useCallback(
      ({ page, pageSize }) => {
        void dispatch(
          searchProcessRuns({
            filters,
            pagination: { page, pageSize },
          })
        );
      },
      [filters, dispatch]
    )
  );

  const { status, total } = useAppSelector((state) => state.reportingModule.processRuns);
  const allProcessRuns = useAppSelector(processRunsSelectors.selectAll);

  const [chosenExtraColumns, setChosenExtraColumns] = useState<ExtraColumn[]>([]);

  const isLoadingResults = status === "loading";

  // TODO: integrate Intl with the useTranslation hook
  const timeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
    [language]
  );

  // TODO: translate the titles once we're sure what columns we want to display
  const baseColumns: ColumnType<ProcessRun>[] = useMemo(
    () => [
      {
        title: t("dh-table.sessionIDHumanReadable"),
        dataIndex: ["process", "sessionIDHumanReadable"],
        render: (sessionId: string) => (
          <Button
            type="link"
            onClick={() => {
              window.open(`/reporting/process-run/${sessionId}`);
            }}
          >
            {sessionId}
          </Button>
        ),
      },
      {
        title: "IMEI",
        dataIndex: ["deviceInfo", "IMEI"],
      },
      {
        title: "MEID",
        dataIndex: ["deviceInfo", "MEID"],
      },
      {
        title: "Process Name",
        dataIndex: ["process", "name"],
      },
      {
        title: "Test suite",
        dataIndex: ["process", "configurationInformation", "testSuite"],
      },
      {
        title: "Model number",
        dataIndex: ["deviceInfo", "modelNumber"],
      },
      {
        title: "Operator",
        dataIndex: ["process", "operator"],
      },
      {
        title: "End date",
        dataIndex: ["process", "endDateTime"],
        render: (date: string) => timeFormat.format(parseISO(date)),
      },
      {
        title: "Fail reason",
        dataIndex: ["failDetails", "failReason", "code"],
      },
      {
        title: "Device serial number",
        dataIndex: ["deviceInfo", "OEMSerialNumber"],
      },
      {
        title: "Batch ID",
        dataIndex: ["process", "customFields", "batchID"], // As defined for PCS
      },
      {
        title: "Process result",
        dataIndex: ["processResult"],
        render: (processResult: string) => {
          const result = processResult === "CRASH" ? "INCOMPLETE" : processResult;
          const color = result === "PASS" ? "#5cd9a7" : result === "FAIL" ? "#e55e67" : "#f8ac1c";

          return <b style={{ color }}>{result}</b>;
        },
      },
    ],
    [t, timeFormat]
  );

  const availableExtraColumns = useMemo(() => {
    const defaultFieldsSet = keyBy(baseColumns, (column) =>
      Array.isArray(column.dataIndex) ? column.dataIndex.join(".") : column.dataIndex ?? ""
    );
    // include the column if it's not in the default fields
    return extraColumns
      .filter((column) => !Object.hasOwn(defaultFieldsSet, column.value))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [baseColumns, extraColumns]);

  const columns: ColumnType<ProcessRun>[] = useMemo(
    () => [
      ...baseColumns,
      ...chosenExtraColumns.map(({ label, value }) => ({
        title: label,
        dataIndex: value.split("."),
      })),
    ],
    [baseColumns, chosenExtraColumns]
  );
  return (
    <ReportingSection
      title={t("search-results", { reportName: title })}
      headerExtraContent={
        <Space>
          {t("add-columns")}
          <Select
            style={{ minWidth: "200px", maxWidth: "1300px" }}
            options={availableExtraColumns}
            allowClear
            mode="multiple"
            maxTagCount={7}
            maxTagTextLength={16}
            optionFilterProp="label"
            value={chosenExtraColumns.map((column) => column.value)}
            onChange={(_, option) => {
              // Select with mode="multiple" should always give an array in onChange, but check just in case
              const newExtraColumns = Array.isArray(option) ? option : [option];
              setChosenExtraColumns(newExtraColumns);
            }}
          />
          <MdButton
            icon
            className="icon-btn"
            iconEl={
              <i
                className="icon-x"
                style={{ color: "#212121", fontSize: 30, fontWeight: "bold" }}
              />
            }
            onClick={onClose}
          />
        </Space>
      }
    >
      <Table
        dataSource={allProcessRuns}
        columns={columns}
        rowKey="ID"
        scroll={{ x: "100%" }}
        loading={
          isLoadingResults
            ? {
                indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                size: "large",
              }
            : false
        }
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50"],
          current: page,
          pageSize,
          onChange: changePagination,
          total,
        }}
      />
    </ReportingSection>
  );
};
