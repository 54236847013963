import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DataTable,
  ExpansionList,
  ExpansionPanel,
  TableColumn,
  TableHeader,
  TableRow,
  TableBody,
  FontIcon,
  TableFooter,
} from "react-md";

const DevicePart = ({ name, currentSerial, factorySerial, message, result }) => {
  const [msgStyle, icon] = useMemo(() => {
    const style = {};
    let icon = null;
    if (typeof result !== "undefined") {
      switch (result.toLowerCase()) {
        case "pass":
          style.color = "#9c3";
          icon = <FontIcon iconClassName="icon-check status-icon" style={style} />;
          break;
        case "fail":
          style.color = "#fd0000";
          icon = <FontIcon iconClassName="icon-close status-icon" style={style} />;
          break;
        case "check":
          style.color = "#005288";
          icon = <FontIcon iconClassName="icon-info-circle status-icon" style={style} />;
          break;
      }
    }
    return [style, icon];
  }, [result]);

  if (typeof result === "undefined") {
    result = "";
  }
  return (
    <TableRow>
      <TableColumn>{name}</TableColumn>
      <TableColumn style={{ textAlign: "center" }}>{icon}</TableColumn>
      <TableColumn>{currentSerial}</TableColumn>
      <TableColumn>{factorySerial}</TableColumn>
      <TableColumn style={msgStyle}>{message}</TableColumn>
    </TableRow>
  );
};

DevicePart.propTypes = {
  name: PropTypes.string,
  part: PropTypes.string,
  currentSerial: PropTypes.string,
  factorySerial: PropTypes.string,
  result: PropTypes.string,
  message: PropTypes.string,
};

const DeviceParts = ({ parts }) => (
  <ExpansionList>
    <ExpansionPanel
      className="cc-expansion-panel"
      label={<div className="panel-text title bold-screen printable-title">Device Parts</div>}
      headerClassName="cc-section-header table-header"
      contentClassName="cc-fullwidth-panel"
      expanderIcon={<i className="no-printable icon-chevron-down" />}
      footer={null}
    >
      <DataTable baseId="device parts" plain className="certificate-info-table-1">
        <TableHeader className="table-header" style={{ background: "#005288" }}>
          <TableRow>
            <TableColumn style={{ color: "white" }}>Part</TableColumn>
            <TableColumn style={{ color: "white", textAlign: "center" }}>Original</TableColumn>
            <TableColumn style={{ color: "white" }}>Current Serial</TableColumn>
            <TableColumn style={{ color: "white" }}>Factory Serial</TableColumn>
            <TableColumn />
          </TableRow>
        </TableHeader>
        <TableBody className="table-body">
          {parts.map((part) => (
            <DevicePart key={`part-info-${part.name}`} {...part} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableColumn
              colSpan={5}
              style={{
                borderTop: "1px solid #dedede",
                padding: "1rem 1.25rem",
                lineHeight: "1.2rem",
              }}
            >
              <FontIcon
                iconClassName="icon-exclamation-triangle"
                style={{ color: "#ff6b1d", marginRight: "1rem" }}
                forceSize={16}
              />
              This is only a recommendation. When the read value matches the ex-factory value, it
              could still be possible that the part is not genuine. Hackers and repairmen are able
              to overwrite ex-factory values. Use these results with caution.
            </TableColumn>
          </TableRow>
        </TableFooter>
      </DataTable>
    </ExpansionPanel>
  </ExpansionList>
);

DeviceParts.propTypes = {
  parts: PropTypes.array,
};

export default DeviceParts;
