import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  DataTable,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";

import { Row, Column } from "~/global";

import moduleActions from "../../actions";
import moduleSelectors from "~/device-history/selectors/";

import "./WDSInformations.component.scss";

const WDSInformation = ({ toggleIncludeToPrint, includeToPrint, wdsInfos }) => {
  const includeForPrint = () => {
    toggleIncludeToPrint(includeToPrint);
  };

  const isEmpty = wdsInfos == null;

  if (isEmpty) {
    return <div />;
  }

  return (
    <div className={includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"}>
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={
            <div className="panel-text title bold-screen printable-title">Wds Information</div>
          }
          secondaryLabel={
            <div className="panel-text no-printable">
              {includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          onExpandToggle={() => includeForPrint()}
          expanded={includeToPrint}
          footer={null}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          <Row className="wds-informations">
            <Column className="full-width">
              <DataTable baseId="certificate-info-table-1" plain className="wds-information-table">
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">WDS Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Model:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.HardwarePlatform.Model || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Vendor:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.HardwarePlatform.Vendor || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Internal Memory:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.Memory.InternalMemory || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Internal Ram:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.Memory.InternalRam || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Removable Memory:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.Memory.RemovableMemory || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Marketing Name:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.Referencing.MarketingName || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">TAC #:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.Referencing.TAC || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">OS Name:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.SoftwarePlatform.OSName || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">OS Version:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.SoftwarePlatform.OSVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>

            <Column>
              <DataTable baseId="certificate-info-table-1" plain className="wds-information-table">
                <TableBody>
                  <TableRow>
                    <TableColumn className="table-label">Source:</TableColumn>
                    <TableColumn className="table-data">{wdsInfos.Source || "N/A"}</TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Color:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsHardwarePlartform.Colour || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Device Category:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsHardwarePlartform.DeviceCategory || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Hardware Manufacturer:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsHardwarePlartform.HardwareManufacturer || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Frequency Type:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsNetworkCaracteristics.FrequencyTypes || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">GSM Frequency Type:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsNetworkCaracteristics.GSMFrequencyTypes || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Mobile Telecom:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsNetworkCaracteristics.MobileTelecommunicationsSystem || "N/A"}
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn className="table-label">Profile Version:</TableColumn>
                    <TableColumn className="table-data">
                      {wdsInfos.WdsProfile.ProfileVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeWdsInformation,
  wdsInfos: moduleSelectors.deviceInfosWDS.getDeviceInfosFlat(state),
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeWdsToPrint,
};

export default connect(stateToProps, dispatchToProps)(WDSInformation);
