import { createSelector } from "@reduxjs/toolkit";
import concat from "lodash/concat";

export const getRecordingFields = (state) => state.labelPrinter.labelFields.recordingFields;
export const getCustomFields = (state) => state.labelPrinter.labelFields.customFields;

const getSortedField = (fields) => {
  fields.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase()
      ? 1
      : b.name.toLowerCase() > a.name.toLowerCase()
      ? -1
      : 0
  );
  return fields;
};

export const getAvailableFields = createSelector(
  [getRecordingFields, getCustomFields],
  (recordingFields, customFields) => getSortedField(concat(recordingFields, customFields))
);
