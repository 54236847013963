import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./TestDefinitionIcon.component.scss";

const TestDefinitionIcon = ({ src, size, isCategory }) => {
  const imgStyle = {
    height: `${size}px`,
    width: `${size}px`,
  };

  return (
    <div className={classnames("test-definition-icon", { "is-category": isCategory })}>
      <img src={src || "http://via.placeholder.com/50/ffffff?text=missing"} style={imgStyle} />
    </div>
  );
};

TestDefinitionIcon.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCategory: PropTypes.bool,
};

TestDefinitionIcon.defaultProps = {
  size: 50,
  isCategory: false,
};

export default TestDefinitionIcon;
