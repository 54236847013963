import * as customerEditor from "./customerEditor.selectors";
import * as customerFacilities from "./customerFacilities.selectors";
import * as customerIdentity from "./customerIdentity.selectors";
import * as customerUsers from "./customerUsers.selectors";
import * as customerFacilityEditor from "./customerFacilityEditor.selectors";
import * as customerUserEditor from "./customerUserEditor.selectors";
import * as customerTeams from "./customerTeams.selectors";
import * as customerTeamEditor from "./customerTeamEditor.selectors";
import * as customerWizard from "./customerWizard.selectors";

export default {
  customerIdentity,
  customerEditor,
  customerFacilities,
  customerFacilityEditor,
  customerUsers,
  customerUserEditor,
  customerTeams,
  customerTeamEditor,
  customerWizard,
};
