import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { intersection, union, xor } from "lodash/array";

import { TextField, Grid, Cell } from "react-md";
import { Transfer, Spin } from "antd";

import { SelectList } from "~/global";

import moduleActions from "../../actions";
import actions from "~/actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";

const CustomerTeamEditor = ({ onSave = () => null, originalTeamName = "" }) => {
  const auditsAllFacilities = useAppSelector(selectors.audits.getAuditsByFacilityStatus);
  const canSaveTeam = useAppSelector(moduleSelectors.customerTeamEditor.getCanSaveCustomerTeam);
  const descriptionError = useAppSelector(
    (state) => state.modules.customerManagement.customerTeamEditor.descriptionError
  );
  const facilitiesById = useAppSelector(moduleSelectors.customerFacilities.getFacilitiesById);
  const nameExistError = useAppSelector(
    (state) => state.modules.customerManagement.customerTeamEditor.nameExistError
  );
  const teamDescription = useAppSelector(moduleSelectors.customerTeamEditor.getTeamDescription);
  const teamFaid = useAppSelector(moduleSelectors.customerTeamEditor.getTeamFaid);
  const teamName = useAppSelector(moduleSelectors.customerTeamEditor.getTeamName);
  const teamNameError = useAppSelector(
    (state) => state.modules.customerManagement.customerTeamEditor.nameError
  );
  const userIdMembers = useAppSelector(moduleSelectors.customerTeamEditor.getUserIdMembers);
  const users = useAppSelector(moduleSelectors.customerTeams.getFlatUsersTeams);
  const usersIsLoading = useAppSelector(
    (state) => state.modules.customerManagement.customerTeams.isLoadingUsers
  );

  const dispatch = useAppDispatch();

  const [checkedUserIds, setCheckedUserIds] = useState([]);
  const { t } = useTranslation("customerTeamEditor");

  useEffect(() => {
    if (users.length > 0) {
      dispatch(moduleActions.customerTeamEditor.initTeamMembers(users));
    }
  }, [dispatch, users]);

  const usersInFacility = useMemo(() => {
    if (teamFaid === "" || users.length === 0) {
      return [];
    }

    return users.filter(
      (user) => user.facilities.some(({ faid }) => faid === teamFaid) || user.hasAllFacilitiesAccess
    );
  }, [users, teamFaid]);

  useEffect(() => {
    if (teamFaid !== "") {
      dispatch(actions.audits.fetchAudits(teamFaid));
    }
  }, [dispatch, teamFaid]);

  const errorNameMessage = useMemo(
    () =>
      [
        {
          isShown: nameExistError,
          message: t("message-error-a-valid-team-name-is-required"),
        },
        { isShown: teamNameError, message: t("message-error-that-name-was-already") },
      ]
        .filter(({ isShown }) => isShown)
        .map(({ message }) => message),
    [nameExistError, teamNameError]
  );

  const changeName = (name) => {
    dispatch(moduleActions.customerTeamEditor.onChangeName(name));
    dispatch(moduleActions.customerTeamEditor.validateTeamName(name));
    dispatch(moduleActions.customerTeamEditor.validateNameExist(name, originalTeamName));
  };

  const changeDescription = (description) => {
    dispatch(moduleActions.customerTeamEditor.onChangeDescription(description));
    dispatch(moduleActions.customerTeamEditor.validateTeamDescription(description));
  };

  const getUserLabel = (user) => {
    const status = auditsAllFacilities[`${teamFaid}.${user.status}`];
    const roleStatus = status != null ? `(${user.roleName} ${status.name})` : `(${user.roleName})`;
    const userName =
      user.firstname == "" || user.lastname == ""
        ? `${user.name}`
        : `${user.firstname} ${user.lastname}`;
    return `${userName} ${roleStatus}`;
  };

  const filterOption = (inputValue, option) => {
    const searchValue = inputValue.toLowerCase();
    const firstNameValue = option.firstname.toLowerCase();
    const lastNameValue = option.lastname.toLowerCase();
    return firstNameValue.indexOf(searchValue) > -1 || lastNameValue.indexOf(searchValue) > -1;
  };

  const onSelectChange = async (userId, targetSelectedKeys) => {
    const isMember = intersection(userId, targetSelectedKeys);
    const newCheckedUsers = isMember
      ? xor(userId, targetSelectedKeys)
      : union(userId, targetSelectedKeys);
    setCheckedUserIds(newCheckedUsers);
  };

  return (
    <div>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("team-name")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="team-name-text-field"
            lineDirection="left"
            placeholder={t("placeholder-enter-team-name")}
            value={teamName}
            className="table-text-field"
            onChange={(name) => changeName(name)}
            error={teamNameError || nameExistError}
            errorText={errorNameMessage}
            onKeyPress={(e) => (canSaveTeam && e.key == "Enter" ? onSave() : null)}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("description")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="team-description-text-field"
            lineDirection="left"
            placeholder={t("placeholder-enter-description")}
            value={teamDescription}
            className="table-text-field"
            onChange={(description) => changeDescription(description)}
            error={descriptionError}
            errorText={t("message-error-description-must")}
            onKeyPress={(e) => (canSaveTeam && e.key == "Enter" ? onSave() : null)}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("facility")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <SelectList
            items={Object.keys(facilitiesById)}
            selectedItem={teamFaid}
            nameBuilder={(faid) => facilitiesById[faid].name}
            onChange={(faid) => dispatch(moduleActions.customerTeamEditor.onChangeFacility(faid))}
            underlined
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={12} className="row centered space-around">
          <div>
            {t("members")}
            <sup>*</sup>:
          </div>
        </Cell>
        <Cell size={12} className="row centered">
          {usersIsLoading ? (
            <div style={{ height: "300px" }} className="layout-column layout-column--center-center">
              <Spin size="large" tip={t("loading")} />
            </div>
          ) : (
            <div>
              <Transfer
                rowKey={(user) => user.userid}
                dataSource={usersInFacility}
                titles={[t("title-available"), t("selected")]}
                targetKeys={userIdMembers}
                selectedKeys={checkedUserIds}
                onChange={(usersid) =>
                  dispatch(moduleActions.customerTeamEditor.onChangeUserIdMembers(usersid))
                }
                onSelectChange={onSelectChange}
                render={getUserLabel}
                filterOption={filterOption}
                listStyle={{
                  width: 300,
                  height: 300,
                }}
                showSearch
              />
              <p className="error-message row to-end">
                {userIdMembers.length === 0 ? t("teams-members-are-required") : null}
              </p>
            </div>
          )}
        </Cell>
      </Grid>
    </div>
  );
};

CustomerTeamEditor.propTypes = {
  onSave: PropTypes.func,
  originalTeamName: PropTypes.string,
};

export default CustomerTeamEditor;
