import React, { Component } from "react";
import PropTypes from "prop-types";

import "./AsciSelect.component.scss";

export default class AsciSelect extends Component {
  static propTypes = {
    // Objects representing each choice in the list
    items: PropTypes.arrayOf(PropTypes.object).isRequired,

    // The object selected in the list
    selectedItem: PropTypes.object.isRequired,

    // The value of the selected item will be the value of this key in the selected item
    valueKey: PropTypes.string,

    // Key of the field that contains the text to display in the list for each item
    nameKey: PropTypes.string,

    // String that will be used as key for this component
    id: PropTypes.string.isRequired,

    // Callback function called when the selected item changes
    onChange: PropTypes.func.isRequired,

    // Is the list disabled or not
    disabled: PropTypes.bool,

    // Error of the field that will be used to show/hide the error message
    error: PropTypes.bool,

    // Error Text of the field that will be displayed if error is true
    errorText: PropTypes.string,

    // can be used to do something on enter
    callBack: PropTypes.func,

    // needed to create the groups, inside the props.items there must be a group field, this group must match a string inside the props.groups array
    groups: PropTypes.array,
  };

  static defaultProps = {
    items: [],
    nameKey: "name",
    id: "",
    disabled: false,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.selectedItem != this.props.selectedItem) {
      this.forceUpdate();
    }
  }

  onChange(event) {
    const selectedItem = (this.props.items || []).filter(
      (item) => item[this.props.valueKey] == event.target.value
    )[0];

    if (this.props.valueKey != null) {
      this.props.onChange(selectedItem, selectedItem[this.props.valueKey]);
    } else {
      this.props.onChange(selectedItem);
    }
  }

  render() {
    if (this.props.selectedItem == null) {
      return (
        <div className="asci-select">
          <div>
            <i className="icon-info-circle" /> Failed to fetch options
          </div>
        </div>
      );
    }

    const enabledClass = this.props.disabled ? "disabled" : "enabled";

    return (
      <div className="column">
        <div className={`error-${this.props.error} row base-aligned asci-select ${enabledClass}`}>
          <select
            className={`browser-default ${this.props.selectedItem[this.props.nameKey]}`}
            disabled={this.props.disabled}
            key={this.props.id}
            value={this.props.selectedItem[this.props.valueKey]}
            onChange={this.onChange.bind(this)}
            onKeyPress={(e) => {
              if (this.props.callBack != null && e.key == "Enter") {
                this.props.callBack(e.key);
              }
            }}
          >
            {(this.props.items || []).map((item, index) => {
              if (item.group == null) {
                return (
                  <option key={`asci-select-option-${index}`} value={item[this.props.valueKey]}>
                    {item[this.props.nameKey]}
                  </option>
                );
              }
            })}

            {(this.props.groups || []).map((group) => (
              <optgroup key={group} label={this.props.groups}>
                {(this.props.items || []).map((item, index) => {
                  if (item.group == group) {
                    return (
                      <option
                        key={`asci-select-option-${group}${index}`}
                        value={item[this.props.valueKey]}
                      >
                        {item[this.props.nameKey]}
                      </option>
                    );
                  }
                })}
              </optgroup>
            ))}
          </select>
        </div>

        {this.props.error ? <div className="error-message">{this.props.errorText}</div> : null}
      </div>
    );
  }
}
