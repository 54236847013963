import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

import { logoutSuccess } from "~/actions/session.actions";

const initialState = {
  activeCustomerId: undefined,
  isUpdatingCurrentCustomer: false,
  isChangingActiveCustomer: false,
  processRoutes: [],
};

export default createReducer(initialState, {
  [logoutSuccess]: (draftState) => {
    draftState.activeCustomerId = undefined;
  },

  // Update active customer
  [actions.customer.fetchCurrentCustomerCSIDBegin]: (draftState) => {
    draftState.isUpdatingCurrentCustomer = true;
  },
  [actions.customer.fetchCurrentCustomerCSIDSuccess]: (draftState, action) => {
    draftState.activeCustomerId = action.payload;
    draftState.isUpdatingCurrentCustomer = false;
  },
  [actions.customer.fetchCurrentCustomerProcessRouteSuccess]: (draftState, action) => {
    draftState.processRoutes = action.payload;
  },
  [actions.customer.fetchCurrentCustomerCSIDError]: (draftState) => {
    draftState.isUpdatingCurrentCustomer = false;
  },

  // Change active customer
  [actions.customer.changeActiveCustomerBegin]: (draftState) => {
    draftState.isChangingActiveCustomer = true;
  },
  [actions.customer.changeActiveCustomerSuccess]: (draftState, action) => {
    draftState.activeCustomerId = action.payload;
    draftState.isChangingActiveCustomer = false;
  },
  [actions.customer.changeActiveCustomerError]: (draftState) => {
    draftState.isChangingActiveCustomer = false;
  },
});
