import { createAction } from "@reduxjs/toolkit";
import { ConsumerManagement } from "~/services";

export const fetchTestDetailsBegin = createAction("FETCH_TEST_DEFINITIONS_DETAILS_BEGIN");
export const fetchTestDetailsSuccess = createAction("FETCH_TEST_DEFINITIONS_DETAILS_SUCCESS");
export const fetchTestDetailsError = createAction("FETCH_TEST_DEFINITIONS_DETAILS_ERROR");

export const setSelectedTestDetails = createAction("SET_SELECTED_TEST_CLASS");
export const setInitiallySelectedTestDetails = createAction("SET_INITIALLY_SELECTED_TEST");

export const fetchTests = () => async (dispatch) => {
  try {
    const query = ConsumerManagement.gqlBuilder(`{
            test_definitions_details {
                platform,
                className,
                description,
                displayName,
                image,
                canAudit,
                canFailFast,
                canAutoNext,
                auditModes {
                    displayName,
                    name,
                    description
                },
                arguments {
                    name,
                    displayName,
                    description,
                    required,
                    onlyWhenAuditable,
                    type,
                    defaultValue
                }
            }
        }`);

    dispatch(fetchTestDetailsBegin());

    const response = await ConsumerManagement.POST("/gql", { query });

    dispatch(
      fetchTestDetailsSuccess({
        details: response.payload.data.test_definitions_details || [],
      })
    );
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchTestDetailsError({ errors: e }));
  }
};
