import { createAction, createReducer } from "@reduxjs/toolkit";
import maxBy from "lodash/maxBy";
import concat from "lodash/concat";
import find from "lodash/find";
import setBy from "lodash/set";
import findIndex from "lodash/findIndex";
import last from "lodash/last";

import * as workflowBehaviors from "~/workflow/workflowBehaviors";
import * as workflowConditions from "~/workflow/workflowConditions";
import * as workflowEvents from "~/workflow/workflowEvents";

import { setServiceSuiteEdition } from "~/programs-management/actions/serviceSuiteEditor.actions";

const defaultCondition = {
  conditionId: 1,
  conditionName: "New condition",
  conditionType: null,
  conditionData: {},
  operator: "AND",
};

const defaultBehavior = {
  behaviorId: 1,
  behaviorName: "New behavior",
  behaviorType: null,
  behaviorData: {},
  conditions: [],
  eventId: "",
  serviceIdentifier: null,
};

export default function (moduleName, eventId) {
  const initialState = {
    behaviors: [],
    eventData: {},
  };

  const _createAction = (actionName) => createAction(`eventEditor/${eventId}/${actionName}`);

  const getOwnState = (state) => state[moduleName].eventEditor[eventId];
  const _getBehaviorById = (behaviors, behaviorId) => find(behaviors, { behaviorId });

  const nextId = (key) => (list) => {
    if (list.length < 1) {
      return 1;
    }
    return maxBy(list, key)[key] + 1;
  };
  const nextBehaviorId = nextId("behaviorId");
  const nextConditionId = nextId("conditionId");

  // Actions
  const addBehavior = _createAction(`addBehavior`);
  const setBehaviorName = _createAction(`setBehaviorName`);
  const addCondition = _createAction(`addCondition`);
  const setBehaviorData = _createAction(`setBehaviorData`);
  const setConditionData = _createAction(`setConditionData`);
  const removeCondition = _createAction(`removeCondition`);
  const removeBehavior = _createAction(`removeBehavior`);
  const changeConditionType = _createAction(`changeConditionType`);
  const setConditionOperator = _createAction(`setConditionOperator`);
  const moveBehaviorUp = _createAction("moveBehaviorUp");
  const moveBehaviorDown = _createAction("moveBehaviorDown");

  const setEventData = _createAction("setEventData");

  // Selectors

  const getBehaviors = (serviceIdentifier) => (state) =>
    serviceIdentifier
      ? getOwnState(state).behaviors.filter((b) => b.serviceIdentifier === serviceIdentifier)
      : getOwnState(state).behaviors;
  const getAllowedBehaviors = () => workflowEvents.metadata[eventId].allowedBehaviors;
  const getBehaviorById = (behaviorId) => (state) =>
    _getBehaviorById(getOwnState(state).behaviors, behaviorId);
  const isFirstBehavior = (behaviorId) => (state) => {
    const ownState = getOwnState(state);
    return ownState.behaviors[0] && ownState.behaviors[0].behaviorId === behaviorId;
  };
  const isLastBehavior = (behaviorId) => (state) => {
    const lastBehavior = last(getOwnState(state).behaviors);
    return lastBehavior.behaviorId === behaviorId;
  };

  const isFirstCondition = (behaviorId, conditionId) => (state) => {
    const b = _getBehaviorById(getOwnState(state).behaviors, behaviorId);
    return b.conditions[0] && b.conditions[0].conditionId === conditionId;
  };
  const getEventMetadata = () => workflowEvents.metadata[eventId];
  const getAllowedConditions = () => workflowEvents.metadata[eventId].allowedConditions;

  return {
    selectors: {
      getBehaviors,
      getAllowedBehaviors,
      getBehaviorById,
      isFirstBehavior,
      isFirstCondition,
      getEventMetadata,
      isLastBehavior,
      getAllowedConditions,
    },
    actions: {
      addBehavior,
      setBehaviorName,
      addCondition,
      setBehaviorData,
      setConditionData,
      removeBehavior,
      removeCondition,
      changeConditionType,
      setConditionOperator,
      moveBehaviorDown,
      moveBehaviorUp,
      setEventData,
    },
    reducer: createReducer(initialState, {
      [addBehavior]: (state, action) => {
        const { behaviorType, serviceIdentifier } = action.payload;
        state.behaviors = concat(state.behaviors, {
          ...defaultBehavior,
          behaviorId: nextBehaviorId(state.behaviors),
          behaviorType,
          behaviorData: workflowBehaviors.getDefaultBehaviorData(behaviorType),
          serviceIdentifier,
          eventId,
          conditions: [{ ...defaultCondition, conditionType: workflowConditions.Always }],
          behaviorName: `New ${behaviorType}`,
        });
      },
      [addCondition]: (state, action) => {
        const { behaviorId, conditionType } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        behavior.conditions = concat(behavior.conditions, {
          ...defaultCondition,
          conditionId: nextConditionId(behavior.conditions),
          conditionType,
          conditionData: workflowConditions.getDefaultConditionData(conditionType),
        });
      },
      [setBehaviorData]: (state, action) => {
        const { behaviorId, key, value } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        setBy(behavior.behaviorData, key, value);
      },
      [setConditionData]: (state, action) => {
        const { behaviorId, conditionId, key, value } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        const condition = find(behavior.conditions, { conditionId });
        setBy(condition.conditionData, key, value);
      },
      [removeBehavior]: (state, action) => {
        const { behaviorId } = action.payload;
        state.behaviors = state.behaviors.filter((b) => b.behaviorId !== behaviorId);
      },
      [removeCondition]: (state, action) => {
        const { behaviorId, conditionId } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        behavior.conditions = behavior.conditions.filter((c) => c.conditionId !== conditionId);
      },
      [changeConditionType]: (state, action) => {
        const { behaviorId, conditionId, conditionType } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        const condition = find(behavior.conditions, { conditionId });
        condition.conditionType = conditionType;
        condition.conditionData = workflowConditions.getDefaultConditionData(conditionType);
      },
      [setConditionOperator]: (state, action) => {
        const { behaviorId, conditionId, operator } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        const condition = find(behavior.conditions, { conditionId });
        condition.operator = operator;
      },
      [setBehaviorName]: (state, action) => {
        const { behaviorId, name } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        behavior.behaviorName = name;
      },
      [moveBehaviorUp]: (state, action) => {
        const { behaviorId } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);
        const index = findIndex(state.behaviors, { behaviorId });
        const previous = state.behaviors[index - 1];
        state.behaviors[index - 1] = behavior;
        state.behaviors[index] = previous;
      },
      [moveBehaviorDown]: (state, action) => {
        const { behaviorId } = action.payload;
        const behavior = _getBehaviorById(state.behaviors, behaviorId);

        const index = findIndex(state.behaviors, { behaviorId });
        const next = state.behaviors[index + 1];
        state.behaviors[index + 1] = behavior;
        state.behaviors[index] = next;
      },
      [setEventData]: (state, key, value) => {
        setBy(state.eventData, key, value);
      },
      [setServiceSuiteEdition]: (state, action) => {
        // Load service suite
        const { serviceSuite } = action.payload;
        const oldData = ((serviceSuite.workflow || {}).events || {})[eventId];
        if (oldData) return oldData;
        return initialState;
      },
    }),
  };
}
