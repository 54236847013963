import React from "react";
import PropTypes from "prop-types";

import "./AsciSpinner.component.scss";

const AsciSpinner = ({ visible = false, size = "small" }) => {
  const style = {
    visibility: visible ? "visible" : "hidden",
  };

  return (
    <div className="asci-spinner-container" style={style}>
      <div className={`icon-asci-gear asci-spinner-${size}`} />
    </div>
  );
};
AsciSpinner.propTypes = {
  visible: PropTypes.bool,
  size: PropTypes.string,
};

export default AsciSpinner;
