import { createReducer } from "@reduxjs/toolkit";

import {
  fetchExportedReportsBegin,
  fetchExportedReportsSuccess,
  fetchExportedReportsError,
  ExportedReport,
} from "~/actions/exportedReports.actions";

interface ExportedReportsState {
  items?: ExportedReport[];
  status: "idle" | "loading" | "error";
  error?: string;
}

const initialState: ExportedReportsState = {
  status: "idle",
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchExportedReportsBegin, (draftState) => {
      draftState.status = "loading";
      delete draftState.error;
    })
    .addCase(fetchExportedReportsSuccess, (draftState, action) => {
      draftState.status = "idle";
      draftState.items = action.payload.exportedReports;
    })
    .addCase(fetchExportedReportsError, (draftState, action) => {
      draftState.status = "error";
      draftState.error = action.payload.error;
    });
});
