import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  // customers & active customer
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  // Update customers list
  [actions.customers.fetchCustomersBegin]: () => {},

  [actions.customers.fetchCustomersSuccess]: (state, action) => {
    state.items = action.payload.customers || [];
  },
  [actions.customers.fetchCustomersError]: (state, action) => {
    state.errors = action.payload.errors;
  },

  [actions.customers.updateCustomersBegin]: (state) => {
    state.errors = null;
    state.isLoading = true;
  },
  [actions.customers.updateCustomersSuccess]: (state, action) => {
    state.items = action.payload.customers || [];
    state.isLoading = false;
  },

  [actions.customers.updateCustomersError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
  [actions.customers.deleteCustomerBegin]: (state) => {
    state.isLoading = true;
  },
  [actions.customers.deleteCustomerSuccess]: (state) => {
    state.isLoading = false;
    state.errors = null;
  },
  [actions.customers.deleteCustomerError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [actions.customers.resetCustomersEdition]: (state) => {
    state.errors = null;
  },
});
