import React from "react";

import { Row } from "~/global";
import { hexToRGB } from "~/global/utils";

import "./UphHeatMap.component.scss";

type Props = {
  data: { time: string; uph: number }[];
  color: string;
  targetUph: number;
};

const UphHeatMap = ({ data, color = "#5CD9C7", targetUph = 30 }: Props) => (
  <Row className="heat-map-row" flex>
    {data.map((cellData, index) => {
      let alpha = cellData.uph < targetUph ? (cellData.uph / targetUph).toFixed(2) : 1;
      if (alpha > 0 && alpha < 0.4) alpha = 0.4;
      const customStyle =
        cellData.uph != null ? { background: hexToRGB(color, alpha) } : { background: "#f2f2f2" };
      if (index == 0) {
        return null;
      }
      return (
        <Row
          className={`heat-map-cell ${cellData.uph >= targetUph ? "big-text" : ""}`}
          flex
          align="center center"
          style={customStyle}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {cellData.uph != null ? cellData.uph : "N/A"}
        </Row>
      );
    })}
  </Row>
);

export default UphHeatMap;
