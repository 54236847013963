import React, { ReactElement, useMemo, useState, useEffect } from "react";
import { Button, Checkbox, Divider, Form, Radio, Select, Space } from "antd";
import { pick } from "lodash";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { TextField } from "react-md";

import { getRegexValidation } from "~/global/utils";
import { ExportColumn } from "~/actions/reportTypes.actions";
import { useTranslation } from "~/i18n";

import "./DataExport.component.scss";

type EmailType = "user" | "custom";

type Props = {
  onSend: (fileFormat: string, columns?: string[], email?: string) => void;
  onDownload?: (fileFormat: string, columns?: string[]) => void;
  exportColumns?: ExportColumn[];
  exportFormats: string[];
  loading: boolean;
};
const DataExport = ({
  onSend,
  onDownload,
  exportColumns,
  loading,
  exportFormats,
}: Props): ReactElement => {
  const { t } = useTranslation("dataExport");

  const [exportFormat, setExportFormat] = useState<string>(
    exportFormats.length > 0 ? exportFormats[0] : "CSV"
  );
  const [exportOption, setExportOption] = useState<"download" | "email">("download");
  const [emailType, setEmailType] = useState<EmailType>("user");
  const [customEmail, setCustomEmail] = useState("");
  const [columns, setColumns] = useState(
    exportColumns?.filter((c) => c.isDefault).map((c) => c.value) ?? []
  );
  const [isAllChecked, setIsAllChecked] = useState(false);
  useEffect(() => {
    const defaultColumns = exportColumns?.filter((col) => col.isDefault).map((col) => col.value);
    setColumns(defaultColumns ?? []);
    setIsAllChecked(defaultColumns?.length === exportColumns?.length);
  }, [exportColumns]);

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    setIsAllChecked(e.target.checked);
    const defaultColumnValues =
      exportColumns?.filter((col) => col.isDefault).map((col) => col.value) ?? [];

    if (e.target.checked) {
      setColumns(defaultColumnValues);
    } else {
      setColumns([]);
    }
  };

  const handleChange = (selectedColumns: string[]) => {
    setColumns(selectedColumns);
    setIsAllChecked(selectedColumns.length === exportColumns?.length);
  };

  const isEmailValid = useMemo(() => {
    if (emailType === "user") {
      return true;
    }

    return getRegexValidation("User_Email", customEmail) as boolean;
  }, [emailType, customEmail]);

  const areColumnsValid = useMemo(() => {
    if (!exportColumns || exportColumns.length === 0) {
      return true;
    }

    return columns != null && columns.length > 0;
  }, [exportColumns, columns]);

  return (
    <Form
      className="cc-data-export"
      name="export-form"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
    >
      <Form.Item label={t("export-format")}>
        <Radio.Group
          options={exportFormats}
          onChange={(e) => {
            setExportFormat(e.target.value);
          }}
          value={exportFormat}
          buttonStyle="solid"
          optionType="button"
        />
      </Form.Item>

      {exportColumns && exportColumns.length > 0 && (
        <Form.Item label={t("export-columns")}>
          <Select
            options={exportColumns.map((col) => pick(col, ["label", "value"]))}
            value={columns}
            onChange={handleChange}
            placeholder={t("export-columns-placeholder")}
            mode="multiple"
            allowClear
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <Space align="center" style={{ padding: "5px 12px" }}>
                  <Checkbox
                    checked={isAllChecked}
                    indeterminate={columns.length < exportColumns.length && columns.length > 0}
                    onChange={handleSelectAllChange}
                  >
                    {t("select-all")}
                  </Checkbox>
                </Space>
              </>
            )}
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}

      {onDownload != null && (
        <>
          <hr className="separator" />

          <Form.Item noStyle={exportOption === "email"}>
            <Radio.Group
              onChange={(e) => {
                setExportOption(e.target.value);
              }}
              value={exportOption}
            >
              <Radio className="radio-btn-vertical" value="download">
                {t("download-report")}
              </Radio>
              <Radio className="radio-btn-vertical" value="email">
                {t("send-report-email")}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </>
      )}

      {exportOption === "email" && (
        <>
          <hr className="separator" />

          <Form.Item noStyle={emailType === "custom"}>
            <Radio.Group
              onChange={(e) => {
                setEmailType(e.target.value);
                setCustomEmail("");
              }}
              value={emailType}
            >
              <Radio className="radio-btn-vertical" value="user">
                {t("send-my-email")}
              </Radio>
              <Radio className="radio-btn-vertical" value="custom">
                {t("send-email-someone")}
              </Radio>
            </Radio.Group>
          </Form.Item>

          {emailType === "custom" && (
            <Form.Item>
              <TextField
                id="email-input"
                className="email-input"
                type="email"
                placeholder={t("email-placeholder")}
                error={!isEmailValid}
                value={customEmail}
                onChange={(value) => {
                  setCustomEmail(value as string);
                }}
                // @ts-ignore
                onKeyPress={(e: KeyboardEvent) => {
                  if (e.key === "Enter" && areColumnsValid && isEmailValid) {
                    onSend(exportFormat.toLowerCase(), columns, customEmail);
                  }
                }}
              />

              {!isEmailValid && <span className="email-error">{t("valid-email-required")}</span>}
            </Form.Item>
          )}

          <Form.Item noStyle>
            <Button
              type="primary"
              disabled={!areColumnsValid || !isEmailValid}
              onClick={() => onSend(exportFormat.toLowerCase(), columns, customEmail)}
              loading={loading}
            >
              {t("send-report")}
            </Button>
          </Form.Item>
        </>
      )}

      {onDownload != null && exportOption === "download" && (
        <Form.Item noStyle>
          <Button
            type="primary"
            disabled={!areColumnsValid}
            onClick={() => onDownload(exportFormat.toLowerCase(), columns)}
            loading={loading}
          >
            {t("generate-report")}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default DataExport;
