import React from "react";
import PropTypes from "prop-types";

import { SelectList } from "~/global";

const TimeSelector = ({ hours, minutes, onChange, label }) => {
  const hoursItems = [];
  for (let key = 0; key <= 23; key++) {
    hoursItems.push(key);
  }

  const minutesItems = [];
  for (let key = 0; key <= 45; key += 15) {
    minutesItems.push(key);
  }

  return (
    <div className="time-selector layout-row layout-row--start-center">
      <span className="margin--5">{label}</span>

      <SelectList
        items={hoursItems}
        selectedItem={hours}
        onChange={(value) => onChange({ hours: value })}
      />

      <span className="margin--5">:</span>

      <SelectList
        items={minutesItems}
        nameBuilder={(item) => (item < 10 ? `0${item}` : item)}
        selectedItem={minutes}
        onChange={(value) => onChange({ minutes: value })}
      />
    </div>
  );
};

TimeSelector.propTypes = {
  hours: PropTypes.number,
  minutes: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default TimeSelector;
