import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";
import { useTranslation } from "~/i18n";

interface Props extends Partial<CustomIconComponentProps> {
  customId: string;
  size: number;
}

export const InventoryIcon = ({ customId, size, style, ...iconProps }: Props) => {
  const { t } = useTranslation("home");

  const inventorySvg = useCallback(
    () => (
      <SVGIcon title="Inventory" viewBox="0 0 35 35" size={size}>
        <defs>
          <linearGradient
            id={customId}
            x1="0"
            y1="17.5"
            x2="35"
            y2="17.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#9c3" />
            <stop offset="0.62" stopColor="#386b53" />
            <stop offset="1" stopColor="#036" />
          </linearGradient>
        </defs>
        <g>
          {/* Shelves */}
          <path
            fill={`url(#${customId})`}
            d="M5 5h25v2H5z" // Top shelf
          />
          <path
            fill={`url(#${customId})`}
            d="M5 15h25v2H5z" // Middle shelf
          />
          <path
            fill={`url(#${customId})`}
            d="M5 25h25v2H5z" // Bottom shelf
          />
          {/* Vertical sides */}
          <path
            fill={`url(#${customId})`}
            d="M5 5h2v22H5z" // Left side
          />
          <path
            fill={`url(#${customId})`}
            d="M28 5h2v22h-2z" // Right side
          />
          {/* Boxes on shelves */}
          {/* Top shelf boxes */}
          <path fill="#99cc33" stroke="#003764" strokeWidth="0.5" d="M9 8h6v6H9z" />
          <path fill="#99cc33" stroke="#003764" strokeWidth="0.5" d="M20 8h6v6h-6z" />
          {/* Middle shelf boxes */}
          <path fill="#99cc33" stroke="#003764" strokeWidth="0.5" d="M9 18h6v6H9z" />
          <path fill="#99cc33" stroke="#003764" strokeWidth="0.5" d="M20 18h6v6h-6z" />
          {/* Bottom shelf box */}
          <path fill="#99cc33" stroke="#003764" strokeWidth="0.5" d="M14.5 28h6v6h-6z" />
        </g>
      </SVGIcon>
    ),
    [customId, size]
  );
  return (
    <Icon
      title={t("modules.inventory")}
      component={inventorySvg}
      style={{ ...style, fontSize: size }}
      {...iconProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};

InventoryIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default InventoryIcon;
