import { createSelector } from "@reduxjs/toolkit";

import { isEmpty, isString, isArray } from "lodash/lang";
import isEqual from "lodash/isEqual";
import moduleSelectors from "../../selectors";

const getCustomerTeamOriginal = (state) =>
  state.modules.customerManagement.customerTeamEditor.original;
const getCustomerTeamModified = (state) =>
  state.modules.customerManagement.customerTeamEditor.modifications;
const getCustomerTeamNameError = (state) =>
  state.modules.customerManagement.customerTeamEditor.nameError;
const getCustomerTeamNameExist = (state) =>
  state.modules.customerManagement.customerTeamEditor.nameExistError;
const getCustomerTeamDescriptionError = (state) =>
  state.modules.customerManagement.customerTeamEditor.descriptionError;

export const getUpdatedCustomerTeam = createSelector(
  [getCustomerTeamOriginal, getCustomerTeamModified],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getTeamName = createSelector(
  [getUpdatedCustomerTeam],
  (updatedCustomerTeam) => updatedCustomerTeam.name || ""
);

export const getTeamDescription = createSelector(
  [getUpdatedCustomerTeam],
  (updatedCustomerTeam) => updatedCustomerTeam.description || ""
);

export const getUserIdMembers = createSelector(
  [getUpdatedCustomerTeam],
  (updatedCustomerTeam) => updatedCustomerTeam.usersid || []
);

export const getTeamId = createSelector(
  [getUpdatedCustomerTeam],
  (updatedCustomerTeam) => updatedCustomerTeam.teamid || ""
);

export const getTeamFaid = createSelector([getUpdatedCustomerTeam], (team) => team.faid);

export const getRequiredComplete = createSelector(
  [getTeamName, getUserIdMembers],
  (teamName, userIdMembers) => teamName.length > 0 && userIdMembers.length > 0
);

export const getCustomerTeamIsEqual = createSelector(
  [getCustomerTeamModified, getCustomerTeamOriginal],
  (modified, original) => {
    const modifiedToCompare = Object.keys(modified || {}).map((key) => {
      if (!isEmpty(modified[key]) && isArray(modified[key])) {
        return modified[key].sort();
      }
      if (!isEmpty(modified[key]) && isString(modified[key])) {
        return modified[key].trim();
      }
    });

    const originalToCompare = Object.keys(original || {}).map((key) => {
      if (!isEmpty(original[key]) && isArray(original[key])) {
        return original[key].sort();
      }
      if (!isEmpty(original[key]) && isString(original[key])) {
        return original[key].trim();
      }
    });
    return isEqual(modifiedToCompare, originalToCompare);
  }
);

export const getCustomerUserIsValid = createSelector(
  [getCustomerTeamNameError, getCustomerTeamNameExist, getCustomerTeamDescriptionError],
  (customerTeamNameError, customerTeamNameExist, customerTeamDescriptionError) =>
    !customerTeamNameError && !customerTeamNameExist && !customerTeamDescriptionError
);

export const getCanSaveCustomerTeam = createSelector(
  [getRequiredComplete, getCustomerTeamIsEqual, getCustomerUserIsValid],
  (requiredComplete, customerTeamIsEqual, customerUserIsValid) =>
    requiredComplete && !customerTeamIsEqual && customerUserIsValid
);
