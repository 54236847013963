import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  items: [],
  isLoading: false,
  errors: null,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [actions.customers.fetchCustomersError]: (state, action) => {
    state.errors = action.payload.errors;
  },
});
