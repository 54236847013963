import { createAction } from "@reduxjs/toolkit";

import * as services from "~/services";

export const fetchServicesVersionBegin = createAction("FETCH_SERVICES_VERSION_BEGIN");
export const fetchServicesVersionSuccess = createAction("FETCH_SERVICES_VERSION_SUCCESS");
export const fetchServicesVersionError = createAction("FETCH_SERVICES_VERSION_ERROR");

export const fetchServicesVersion = () => async (dispatch) => {
  dispatch(fetchServicesVersionBegin());
  const servicesList = Object.keys(services);
  try {
    const promises = (servicesList || []).map(async (service) => {
      try {
        return { [service]: await services[service].getServiceVersion(service) };
      } catch (error) {
        return { [service]: "" };
      }
    });
    const values = await Promise.all(promises).then((values) => values);
    dispatch(fetchServicesVersionSuccess({ values }));

    return values;
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchServicesVersionError({ errors: e }));
  }
};
