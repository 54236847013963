import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select } from "antd";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

const FontSelect = (props) => {
  const { setter, value, fonts, loadFonts } = props;
  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  return (
    <Select onChange={setter} value={value} allowClear style={{ fontFamily: value }}>
      {fonts.map((font) => (
        <Select.Option key={font} style={{ fontFamily: font }}>
          {font}
        </Select.Option>
      ))}
    </Select>
  );
};

FontSelect.defaultProps = {};

FontSelect.propTypes = {
  value: PropTypes.string,
  setter: PropTypes.func,

  fonts: PropTypes.array,
  loadFonts: PropTypes.func,
};

const stateToProps = (state) => ({
  fonts: labelSelectors.labelFonts.getFonts(state),
});

const dispatchToProps = {
  loadFonts: labelActions.labelFonts.loadFonts,
};

export default connect(stateToProps, dispatchToProps)(FontSelect);
