import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { RootState } from "~/store";
import { CustomerManagement } from "~/services";
import { DashboardFiltersParams, helperString } from "./typeAndToolDashboard";

export type TeamPerformanceType = {
  teamId: string;
  teamName: string;
  currentUph: number;
  averageUph: number;
  devices: number;
  auditFail: number;
  audit: number;
  devicesIncomplete: number;
  devicesCrashed: number;
  devicesRetried: number;
  processPass: number;
  processFail: number;
};

type APIReponse = {
  performanceByTeam: TeamPerformanceType[];
};

export const fetchTeamPerformance = createAsyncThunk<
  TeamPerformanceType[],
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/fetchTeamPerformanceStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  try {
    const query = CustomerManagement.gqlBuilder(`query TeamPerformance {
      performanceByTeam(${stringItems}) {
        teamId,
        teamName,
        currentUph,
        averageUph,
        devices,
        auditFail,
        audit,
        devicesIncomplete,
        devicesCrashed,
        devicesRetried,
        processPass,
        processFail
      }
    }`);
    const response = await CustomerManagement.QUERY<APIReponse>(query);

    const { performanceByTeam } = response.payload.data;
    return performanceByTeam;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});
interface TeamPerformanceState {
  error?: string;
  teamsPerformance?: TeamPerformanceType[];
  status: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: TeamPerformanceState = {
  status: "idle",
};

const teamPerformanceSlice = createSlice({
  name: "teamPerformance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamPerformance.pending, (draftState) => {
        draftState.status = "pending";
        draftState.error = undefined;
      })
      .addCase(fetchTeamPerformance.fulfilled, (draftState, { payload }) => {
        draftState.status = "succeeded";
        draftState.teamsPerformance = payload;
      })
      .addCase(fetchTeamPerformance.rejected, (draftState, action) => {
        draftState.status = "idle";
        draftState.teamsPerformance = undefined;
        draftState.error = action.payload ?? "The request didn't complete successfully.";
      });
  },
});

export const getTeamPerformanceTotal = createSelector(
  (state: RootState) => state.modules.dashboard.teamPerformance.teamsPerformance,
  (teamPerformance) => {
    if (teamPerformance == null)
      return {
        currentUph: 0,
        averageUph: 0,
        devices: 0,
        auditFail: 0,
        audit: 0,
        devicesIncomplete: 0,
        devicesCrashed: 0,
        devicesRetried: 0,
        processPass: 0,
        processFail: 0,
      } as TeamPerformanceType;
    const teamPerformanceTotal = teamPerformance.reduce(
      (acc, item) => {
        (
          Object.entries(item) as [
            Exclude<keyof TeamPerformanceType, "teamId" | "teamName">,
            number
          ][]
        )
          .filter((val) => typeof val === "number")
          .forEach(([key, val]) => {
            // eslint-disable-next-line no-param-reassign
            acc[key] = +(acc[key] + val).toFixed(2);
          });
        return acc;
      },
      {
        currentUph: 0,
        averageUph: 0,
        devices: 0,
        auditFail: 0,
        audit: 0,
        devicesIncomplete: 0,
        devicesCrashed: 0,
        devicesRetried: 0,
        processPass: 0,
      } as Omit<TeamPerformanceType, "teamId" | "teamName">
    );

    return teamPerformanceTotal;
  }
);

export default teamPerformanceSlice.reducer;
