import React from "react";
import { TableRow, TableColumn, Button } from "react-md";
import PropTypes from "prop-types";

const DeploymentListRow = ({ deployment, canEdit, onEdit, canArchive, onArchive }) => (
  <TableRow>
    <TableColumn>{deployment.dpid}</TableColumn>

    <TableColumn>{deployment.name || "(no name)"}</TableColumn>

    {/* TODO: Remove release line from query upon termination of classic DSE */}
    <TableColumn>{deployment.releaseline || ""}</TableColumn>

    <TableColumn className="layout-row layout-row--center-center">
      {canEdit && (
        <Button
          icon
          className="icon-btn"
          iconClassName="icon-pencil"
          onClick={() => onEdit(deployment)}
        />
      )}
      {!deployment.releaseline && canArchive && (
        <Button
          icon
          className="icon-btn"
          iconClassName="icon-archive"
          onClick={() => onArchive(deployment)}
        />
      )}
    </TableColumn>
  </TableRow>
);

DeploymentListRow.propTypes = {
  deployment: PropTypes.shape({
    dpid: PropTypes.string,
    name: PropTypes.string,
    releaseline: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  canArchive: PropTypes.bool,
  onArchive: PropTypes.func,
};

export default DeploymentListRow;
