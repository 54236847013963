import uniq from "lodash/uniq";
import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "tasks",
  ({ workstationId, sessionId }) => {
    const queryFilter = workstationId
      ? `workstationId: "${workstationId}"`
      : `sessionId: "${sessionId}"`;

    return WorkstationManagement.QUERY(
      WorkstationManagement.gqlBuilder(`{
            tasks(${queryFilter}) {
                id,
                type,
                result {
                    status,
                    updatedAt
                }
                createdAt,
                createdBy,
                workstationId
            }
        }`)
    ).then((response) => response.payload.data.tasks);
  },
  [
    {
      identifier: "type",
      label: "Type",
      options: (results) => uniq(results.map((result) => result.type)),
      filterFunc: (task, value) => (value ? task.type === value : true),
    },
    {
      identifier: "status",
      label: "Status",
      options: (results) => uniq(results.map((result) => result.result.status)),
      filterFunc: (task, value) => (value ? task.result.status === value : true),
    },
  ]
);
