import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import { Row, Column, AsciButton } from "~/global";
import CannedReportFilters from "~/canned-reports/components/CannedReportFilters/CannedReportFilters.component";
import SameDataComposedChart from "~/canned-reports/components/SameDataComposedChart/SameDataComposedChart.component";

import moduleSelectors from "~/canned-reports/selectors/";

import "./TestProcessingTimesPage.component.scss";

const TestProcessingTimesPage = ({ formattedProcesses, formattedChartData }) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const ref = useRef();
  return (
    <Column className="test-processing-times-page full-width">
      <CannedReportFilters
        currentFilters={currentFilters}
        setCurrentFilters={(filters) => setCurrentFilters(filters)}
        currentCannedReport="testProcessingTimes"
        filtersToDisplay="timeframe facility program" // TODO: add deployment
      />

      <div className="report-results-wrapper">
        <Row className="report-results">
          <div className="report-statistics">
            <Row className="result-item">
              Results:&nbsp;<b>{formattedProcesses.chartData.length}</b>&nbsp;Tests
            </Row>

            <Row className="result-item">
              <b>{formattedProcesses.executions}</b>&nbsp;Executions
            </Row>
          </div>

          <ReactToPrint
            content={() => ref.current}
            pageStyle={`
              @page { size: auto; height: 100%; margin: 1cm; }
              @media print {
                .page-break {
                  page-break-after: avoid;
                }
                body { -webkit-print-color-adjust: exact; page-break-after: avoid; }}
              .recharts-wrapper > .recharts-surface { width: 1120px; height: 600px; }
              .recharts-legend-wrapper > .recharts-default-legend { width: 1120px; height: 600px; }
            `}
            trigger={() => (
              <AsciButton color="white">
                <div className="row centered">
                  <i className="icon-printer" />
                  <span className="margin-left--5">Export</span>
                </div>
              </AsciButton>
            )}
          />
        </Row>
      </div>
      <div className="page-break" ref={ref}>
        <SameDataComposedChart
          title="Test Processing Times"
          data={formattedChartData}
          medianMean={formattedProcesses.medianMean}
        />
      </div>
    </Column>
  );
};

const stateToProps = (state) => ({
  formattedProcesses: moduleSelectors.testProcessingTimes.getFormattedProcesses(state),
  formattedChartData: moduleSelectors.testProcessingTimes.getFormattedChartData(state),
});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(TestProcessingTimesPage);
