import produce from "immer";

import actions from "~/actions";

const initialState = {
  selectedPgid: null,
  // "idle" | "loading" | "succeeded" | "failed"
  status: "idle",
  // string | null
  error: null,
  nameValidationError: {
    // null | "badFormat" | "alreadyExists"
    en: null,
    fr: null,
    es: null,
  },
};

/*
 * NOTE: This reducer DOES NOT WORK when it is created using createReducer.
 * A lot of time was spent trying to figure out why, but no sensible reason could be found.
 * The reducer was created correctly, but the case reducers were never called.
 * Try again after @redux/toolkit is updated (current is v0.4.3 as of writing this comment)
 */
export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.failCodePriorityGroup.addFailCodePriorityGroupBegin.toString():
        draft.status = "loading";
        draft.error = null;
        break;
      case actions.failCodePriorityGroup.addFailCodePriorityGroupSuccess.toString():
        draft.status = "succeeded";
        break;
      case actions.failCodePriorityGroup.addFailCodePriorityGroupError.toString():
        draft.status = "failed";
        draft.error = action.payload;
        break;
      case actions.failCodePriorityGroup.selectFailCodePriorityGroup.toString():
        draft.selectedPgid = action.payload;
        break;
      case actions.failCodePriorityGroup.validateFailCodePriorityGroupNameError.toString():
        draft.nameValidationError[action.payload.language] = action.payload.errorType;
        break;
      case actions.failCodePriorityGroup.validateFailCodePriorityGroupNameSuccess.toString():
        draft.nameValidationError[action.payload.language] = null;
        break;
    }
  });
