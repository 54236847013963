import React, { Component } from "react";
import PropTypes from "prop-types";

import "./CertificateDescription.component.scss";

export default class CertficateDescription extends Component {
  static propTypes = {
    logo: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="row certificate-description white-background">
        <div className="certificate-stamp-logo column">
          <img src={this.props.logo} alt="Certified device" />
        </div>

        <div className="column certificate-description-text">{this.props.children}</div>
      </div>
    );
  }
}
