import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchTestSuitesBegin = createAction("FETCH_TEST_SUITES_BEGIN");
export const fetchTestSuitesSuccess = createAction("FETCH_TEST_SUITES_SUCCESS");
export const fetchTestSuitesError = createAction("FETCH_TEST_SUITES_ERROR");

export const findTestSuitesUsingIdentifierBegin = createAction(
  "FIND_TEST_SUITES_USING_IDENTIFIER_BEGIN"
);
export const findTestSuitesUsingIdentifierSuccess = createAction(
  "FIND_TEST_SUITES_USING_IDENTIFIER_SUCCESS"
);
export const findTestSuitesUsingIdentifierError = createAction(
  "FIND_TEST_SUITES_USING_IDENTIFIER_ERROR"
);

export const sortTestSuites = createAction("SORT_TEST_SUITES");

export const fetchTestSuites = () => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        test_suites {
            tsid,
            name,
            platform,
            lastEdited,
            lastEditor,
            testsCount
        }
    }`);

  dispatch(fetchTestSuitesBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const testSuites = (((response || {}).payload || {}).data || {}).test_suites || [];
    dispatch(fetchTestSuitesSuccess({ testSuites }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchTestSuitesError({ errors: e }));
  }
};

export const isUsedInTestSuite = (platform, identifier) => async (dispatch) => {
  try {
    dispatch(findTestSuitesUsingIdentifierBegin());

    const response = await CustomerManagement.GET(`/test-suites/${platform}/${identifier}`);
    const testSuites = response.payload;

    dispatch(findTestSuitesUsingIdentifierSuccess(testSuites));

    return testSuites;
  } catch (e) {
    if (e instanceof Error) throw e;

    dispatch(findTestSuitesUsingIdentifierError(e));
  }
};
