import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uniqBy, sortBy } from "lodash";

import DeviceInformation from "~/device-history/components/DeviceInformation/DeviceInformation.component";
import ConfigurationInformation from "~/device-history/components/ConfigurationInformation/ConfigurationInformation.component";
import AdvancedInformation from "~/device-history/components/AdvancedInformation/AdvancedInformation.component";
import DeviceTests from "~/device-history/components/DeviceTests/DeviceTests.component";
import DeviceServices from "~/device-history/components/DeviceServices/DeviceServices.component";
import BatteryInformation from "~/device-history/components/BatteryInformation/BatteryInformation.component";
import CustomFields from "~/device-history/components/CustomFields/CustomFields.component";
import FailDetails from "~/device-history/components/FailDetails/FailDetails.component";
import WDSInformations from "~/device-history/components/WDSInformations/WDSInformations.component";
import AuditTests from "~/device-history/components/AuditTests/AuditTests.component";
import ContentCleared from "~/device-history/components/ContentClearedServices/ContentClearedServices.component";
import DeviceParts from "~/device-history/components/DeviceParts/DeviceParts";

import moduleActions from "../../actions";
import "./SessionInformationContainer.component.scss";

const SessionInformationContainer = ({
  sessionSelected,
  customFields,
  customFieldNames,
  fetchWDSDeviceInfos,
}) => {
  const sessionSelectedTests = useMemo(() => sessionSelected.tests || [], [sessionSelected.tests]); // TODO: do this in a selector
  useEffect(() => {
    if (sessionSelected.deviceInfo.IMEI) fetchWDSDeviceInfos(sessionSelected.deviceInfo.IMEI);
  }, [sessionSelected.deviceInfo.IMEI]);

  const failStatuses = ["FAIL", "CRASH", "UNSUPPORTED", "INCOMPLETE"];
  const statusFailed =
    sessionSelected &&
    failStatuses.includes(sessionSelected.processResult) &&
    sessionSelected.failDetails != null;
  const allTestsIncomplete = sessionSelectedTests.every((test) => test.status === "INCOMPLETE");
  const failedManually = !!(
    !sessionSelectedTests ||
    (allTestsIncomplete && sessionSelected.failDetails?.failReason?.category)
  );

  const isCustomFieldsPresent = customFields !== undefined && customFields !== null;

  const testList = useMemo(
    () =>
      uniqBy(sortBy(sessionSelectedTests, "startTime").reverse(), "name").filter(
        (test) => !("isAudit" in test)
      ),
    [sessionSelectedTests]
  );

  const auditList = useMemo(
    () => sessionSelectedTests.filter((test) => "isAudit" in test),
    [sessionSelectedTests]
  );

  const contentClearedServices = useMemo(() => {
    const contentClearingServiceNames = [
      "AutoResetToFactoryAppleService",
      "AutoResetToFactoryAndroidService",
      "AutoResetToFactoryService",
      "ContentClearingService",
      "SoftwareFlashingService",
      "AppleSoftwareFlashingService",
    ];
    return sessionSelected.services?.filter((service) =>
      contentClearingServiceNames.includes(service.className)
    );
  }, [sessionSelected.services]);

  return (
    <div>
      <DeviceInformation
        process={sessionSelected.process}
        deviceInfo={sessionSelected.deviceInfo}
        processResult={sessionSelected.processResult}
      />

      <ConfigurationInformation
        process={sessionSelected.process}
        tests={testList}
        inputOrigin={sessionSelected.inputOrigin}
        tenantIdentifier={sessionSelected.tenantIdentifier}
      />

      {testList.length > 0 ? (
        <DeviceTests tests={testList} failedManually={failedManually} />
      ) : null}

      {statusFailed ? (
        <FailDetails failDetails={sessionSelected.failDetails} tests={sessionSelectedTests} />
      ) : null}

      {auditList.length > 0 ? (
        <AuditTests tests={auditList} failedManually={failedManually} />
      ) : null}

      <DeviceServices services={sessionSelected.services} />

      <ContentCleared services={contentClearedServices} />

      <AdvancedInformation session={sessionSelected} />

      <BatteryInformation batteryInfo={sessionSelected.batteryInformation} />

      {sessionSelected.deviceInfo.partsCheck && sessionSelected.deviceInfo.partsCheck.length && (
        <DeviceParts parts={sessionSelected.deviceInfo.partsCheck} />
      )}

      {isCustomFieldsPresent ? (
        <CustomFields customFields={customFields} customFieldNames={customFieldNames} />
      ) : null}

      <WDSInformations imei={sessionSelected.deviceInfo.IMEI} />
    </div>
  );
};

SessionInformationContainer.propTypes = {
  sessionSelected: PropTypes.object.isRequired,
  customFieldNames: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({});

const dispatchToProps = { fetchWDSDeviceInfos: moduleActions.deviceInfosWDS.fetchWDSDeviceInfos };

export default connect(stateToProps, dispatchToProps)(SessionInformationContainer);
