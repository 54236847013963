import { createAction } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";
import { CustomerManagement } from "~/services";

export const fetchDeploymentBegin = createAction("FETCH_DEPLOYMENT_BEGIN");
export const fetchDeploymentSuccess = createAction("FETCH_DEPLOYMENT_SUCCESS");
export const fetchDeploymentError = createAction("FETCH_DEPLOYMENT_ERROR");

export const initDeploymentSuccess = createAction("INIT_DEPLOYMENT_SUCCESS");

export const setCurrentDeployment = createAction("SET_CURRENT_DEPLOYMENT");
export const changeDeployment = createAction("CHANGE_DEPLOYMENT");
export const resetDeploymentModifications = createAction("RESET_DEPLOYMENT_MODIFICATIONS");

export const addDeploymentBegin = createAction("ADD_DEPLOYMENT_BEGIN");
export const addDeploymentSuccess = createAction("ADD_DEPLOYMENT_SUCCESS");
export const addDeploymentError = createAction("ADD_DEPLOYMENT_ERROR");

export const saveDeploymentBegin = createAction("SAVE_DEPLOYMENT_BEGIN");
export const saveDeploymentSuccess = createAction("SAVE_DEPLOYMENT_SUCCESS");
export const saveDeploymentError = createAction("SAVE_DEPLOYMENT_ERROR");

export const initDeployment = (deployment) => initDeploymentSuccess({ deployment });

export const fetchDeployment = (faid, dpid) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
    deployment(faid: "${faid}", dpid: "${dpid}") {
      dpid,
      name,
      programs,
      shifts {
        id,
        name,
        start { hours, minutes },
        end { hours, minutes }
      },
      customFields
    }
  }`);

  try {
    dispatch(fetchDeploymentBegin());
    const response = await CustomerManagement.QUERY(query);
    const { deployment } = response.payload.data;

    dispatch(fetchDeploymentSuccess({ deployment }));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchDeploymentError({ error: message }));
  }
};

export const setDeployment = (deployment) => setCurrentDeployment({ deployment });
export const clearDeployment = () => resetDeploymentModifications();

export const updateDeployment = (field, newValue) => async (dispatch, getState) => {
  const state = getState();
  const { original } = state.modules.deploymentManagement.deployment;
  const { modifications } = state.modules.deploymentManagement.deployment;

  const updated = { [field]: newValue };
  const unmodified = isEqual(original[field], updated[field]);

  const deployment = unmodified
    ? Object.keys(modifications)
        .filter((modification) => modification != field)
        .reduce((acc, key) => {
          acc[key] = modifications[key];
          return acc;
        }, {})
    : { ...modifications, ...updated };

  dispatch(changeDeployment({ deployment }));
};

export const addDeployment = (faid, deployment) => async (dispatch) => {
  try {
    dispatch(addDeploymentBegin());
    const path = `/facilities/${faid}/deployments`;
    const response = await CustomerManagement.POST(path, deployment);
    dispatch(addDeploymentSuccess(response.message));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(addDeploymentError({ errors: message }));
  }
};

export const saveDeployment = (faid, deployment) => async (dispatch) => {
  try {
    dispatch(saveDeploymentBegin());
    const path = `/facilities/${faid}/deployments/${deployment.dpid}`;
    const response = await CustomerManagement.PUT(path, deployment);
    dispatch(saveDeploymentSuccess(response.message));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(saveDeploymentError({ errors: message }));
  }
};
