import React from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";
import { Button } from "react-md";

import { AsciButton } from "~/global";
import { ServiceSuitesServices } from "~/programs-management";

import "./ServiceSuitesList.component.scss";

const { Text } = Typography;

const ServiceSuitesList = (props) => {
  const isEmpty = props.serviceSuites.length === 0;

  const showSuiteDetails = (serviceSuite) => {
    props.showDialog({
      title: serviceSuite.displayName.en,
      width: "850px",
      modal: false,
      content: (
        <div>
          <p>
            <b>ID:</b> {serviceSuite.identifier}
          </p>
          <p>
            <b>Description:</b> {serviceSuite.description}
          </p>
          <ServiceSuitesServices serviceSuite={serviceSuite} />
        </div>
      ),
      footer: (close) => (
        <div className="layout-row layout-row--end-center">
          <AsciButton
            color="green"
            onClick={() => {
              props.onClickDuplicate(serviceSuite);
              close();
            }}
          >
            <i className="icon-copy text-color--white" /> Duplicate
          </AsciButton>

          <AsciButton
            color="blue"
            onClick={() => {
              props.onClickEdit(serviceSuite);
              close();
            }}
          >
            <i className="icon-pencil text-color--white" /> Edit
          </AsciButton>

          <AsciButton
            color="red"
            onClick={() => {
              props.onClickDelete(serviceSuite);
              close();
            }}
          >
            <i className="icon-trash text-color--white" /> Delete
          </AsciButton>
        </div>
      ),
    });
  };

  return (
    <div className="service-suites-list">
      {isEmpty ? (
        <div className="paper-row layout-column layout-column--center-center padding--20">
          <div className="margin-bottom--15">
            There are currently no services in your Service Suite.
          </div>

          <AsciButton
            color="green"
            onClick={() => {
              props.onClickAdd();
            }}
          >
            Add Service Suite
          </AsciButton>
        </div>
      ) : (
        <div />
      )}

      {props.serviceSuites.map((serviceSuite, index) => (
        <div key={serviceSuite.identifier} className="paper-row">
          <Col>
            <div className="grow-noshrink">{serviceSuite.displayName.en || ""}</div>
            <Text type="secondary">{serviceSuite.servicesTemplateIdentifier}</Text>
          </Col>

          <div className="row spaced-centered padding-h--15 grow-noshrink">
            <div className="row nogrow-shrink margin-h--15">{serviceSuite.description}</div>
          </div>

          <div className="layout-row layout-row--end-center">
            <Button
              icon
              className="icon-btn"
              iconClassName="icon-file-text"
              title="Suite Details"
              onClick={() => {
                showSuiteDetails(serviceSuite);
              }}
            />

            <Button
              icon
              className="icon-btn"
              iconClassName="icon-copy"
              title="Duplicate Suite"
              onClick={() => {
                props.onClickDuplicate(serviceSuite);
              }}
            />

            <Button
              icon
              className="icon-btn"
              iconClassName="icon-pencil"
              title="Edit Suite"
              onClick={() => {
                props.onClickEdit(serviceSuite);
              }}
            />

            <Button
              icon
              className="icon-btn text-color--red"
              iconClassName="icon-trash"
              title="Delete Suite"
              onClick={() => {
                props.onClickDelete(serviceSuite);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

ServiceSuitesList.propTypes = {
  onClickDelete: PropTypes.func,
  onClickDuplicate: PropTypes.func,
  onClickEdit: PropTypes.func,
  showDialog: PropTypes.func,
  onClickAdd: PropTypes.func,
  serviceSuites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ServiceSuitesList.defaultProps = {
  onClickDelete: () => {},
  onClickDuplicate: () => {},
  onClickEdit: () => {},
};

export default ServiceSuitesList;
