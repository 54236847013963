import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,

  nameError: false,
  nameExistError: false,
  descriptionError: false,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [moduleActions.customerTeamEditor.setTeamToEdit]: (state, action) => {
    state.original = action.payload.team;
  },
  [moduleActions.customerTeamEditor.onClearTeamEdition]: (state) => {
    state.original = {};
    state.modifications = {};
    state.isLoading = false;
    state.nameError = false;
    state.descriptionError = false;
  },
  [moduleActions.customerTeamEditor.updateCustomerTeam]: (state, action) => {
    state.modifications = action.payload.team;
  },
  [moduleActions.customerTeamEditor.onTeamNameValidation]: (state, action) => {
    state.nameError = action.payload.errors;
  },
  [moduleActions.customerTeamEditor.onTeamNameExistValidation]: (state, action) => {
    state.nameExistError = action.payload.errors;
  },
  [moduleActions.customerTeamEditor.onTeamDescriptionValidation]: (state, action) => {
    state.descriptionError = action.payload.errors;
  },
});
