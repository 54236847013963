import { createSelector } from "@reduxjs/toolkit";
import { getLanguagesEmptyValue } from "./languages.selectors";

export const getPrograms = (state) => state.programs.items;

export const getProgramNames = createSelector([getPrograms], (programs) =>
  programs.map((program) => program.name)
);

export const getProgramDisplayNames = createSelector(
  [getPrograms, getLanguagesEmptyValue],
  (programs, languagesEmtyName) =>
    programs.map((program) => ({ ...languagesEmtyName, ...program.displayName }))
);

export const getProgramEnNames = createSelector([getProgramDisplayNames], (programDisplayNames) =>
  programDisplayNames.map((programDisplayName) => programDisplayName.en)
);

export const getProgramIdentifiers = createSelector([getPrograms], (programs) =>
  programs.map((program) => program.identifier)
);

export const getFlatPrograms = createSelector([getPrograms], (programs) =>
  programs.map((program) => {
    const displayNameEn = program.displayName.en || "";
    const serviceSuitesSize = program.serviceSuites.length;

    return {
      ...program,
      displayNameEn,
      serviceSuitesSize,
    };
  })
);
