import React from "react";
import { Cascader, Tag } from "antd";

import { useTranslation } from "~/i18n";
import { FacetOption } from "./reportingFilters.slice";

export type SelectedFacet = string[];

export type Filter = {
  field: string;
  value: string[];
};

type Props = {
  facetOptions: FacetOption[];
  facets: SelectedFacet[];
  reportType: string;
  onFacetsChange: (facets: SelectedFacet[]) => void;
};

export const facetsToApiFilters = (facets: SelectedFacet[]): Filter[] => {
  const filtersByField = facets.reduce<Record<string, string[]>>((acc, [field, value]) => {
    if (value != null) {
      if (!acc.hasOwnProperty(field)) {
        acc[field] = []; // eslint-disable-line no-param-reassign
      }

      acc[field].push(value);

      return acc;
    }

    return acc;
  }, {});

  const filters = Object.entries(filtersByField).map(([field, value]) => ({ field, value }));

  return filters;
};

const RecordingFacetsFilter = ({ facetOptions, facets, reportType, onFacetsChange }: Props) => {
  const { t } = useTranslation("reporting");
  // filter facets leave only supported by the selected report type
  const filteredFacetOptions = facetOptions.filter((option) => {
    if (option.supportedReports && option.supportedReports.length > 0) {
      return option.supportedReports.includes(reportType);
    }
  });

  return (
    <Cascader
      style={{ width: "100%" }}
      // TODO: translate option labels (backend - GLOB-3301)
      options={filteredFacetOptions}
      value={facets}
      onChange={(facets) => {
        onFacetsChange(facets.map((facet) => facet.map((value) => `${value}`)));
      }}
      multiple
      tagRender={({ label, value, closable, onClose }) => {
        const CASCADER_SPLIT = "__RC_CASCADER_SPLIT__";

        if (typeof value === "string" && value.includes(CASCADER_SPLIT)) {
          const parentValue = value.split(CASCADER_SPLIT)[0];
          const parentLabel = facetOptions.find((option) => option.value === parentValue)?.label;

          return (
            <Tag closable={closable} onClose={onClose} style={{ marginRight: 4 }}>
              {t("tag-label", { parentLabel, label })}
            </Tag>
          );
        }

        return (
          <Tag closable={closable} onClose={onClose} style={{ marginRight: 4 }}>
            {label}
          </Tag>
        );
      }}
      maxTagCount="responsive"
      placeholder={t("select-a-filter")}
      showSearch={{
        filter: (inputValue, path) =>
          path.some(
            (option) =>
              (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ),
        matchInputWidth: false,
      }}
    />
  );
};

export default RecordingFacetsFilter;
