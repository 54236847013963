import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { InventoryManagement } from "~/services";
import { RootState } from "~/store";

export type Bucket = {
  bucketName: string;
  type: string;
};

export type InventoryLocation = {
  location: string;
  subsidiary: string;
  buckets?: Bucket[];
};

export const fetchInventoryLocations = createAsyncThunk<
  InventoryLocation[],
  void,
  { rejectValue: string }
>("inventoryLocations/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = (await InventoryManagement.GET("/inventory/locations")) as {
      payload: InventoryLocation[];
    };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      return rejectWithValue(err.message);
    }
    const message: string =
      (err as { json: { message?: string } }).json?.message ?? "La requête n'a pas abouti";

    return rejectWithValue(message);
  }
});

const inventoryLocationsAdapter = createEntityAdapter<InventoryLocation>({
  selectId: (location) => location.location,
  sortComparer: (a, b) => a.location.localeCompare(b.location),
});

interface InventoryLocationsState extends EntityState<InventoryLocation> {
  status: "idle" | "loading";
}

const initialState: InventoryLocationsState = {
  ...inventoryLocationsAdapter.getInitialState(),
  status: "idle",
};

// Slice pour InventoryLocation
const inventoryLocationsSlice = createSlice({
  name: "inventoryLocations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryLocations.pending, (draftState) => {
        draftState.status = "loading";
      })
      .addCase(fetchInventoryLocations.fulfilled, (draftState, action) => {
        draftState.status = "idle";
        inventoryLocationsAdapter.setAll(draftState, action.payload);
      })
      .addCase(fetchInventoryLocations.rejected, (draftState) => {
        draftState.status = "idle";
      });
  },
});

export default inventoryLocationsSlice.reducer;

export const inventoryLocationsSelectors = inventoryLocationsAdapter.getSelectors<RootState>(
  (state) => state.inventoryLocations
);
