import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const ServiceSuiteTemplateIcon = ({ size, customId }) => (
  <SVGIcon title="Service Suite Templates" viewBox="0 0 35.66 26.44" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x2="30.74"
        y1="14.83"
        y2="14.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset=".13" stopColor="#8ec137" />
        <stop offset=".35" stopColor="#71a440" />
        <stop offset=".64" stopColor="#427550" />
        <stop offset=".99" stopColor="#023565" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill={`url(#${customId}-a)`}
        d="M30.74 17.93a2.45 2.45 0 00-2.63-2.61h-3.27V2.61A2.38 2.38 0 0022.24 0H8.6a2.64 2.64 0 00-2.71 2.73v7.21H2.43A2.56 2.56 0 000 12.66c.05 1.87 0 11.24 0 14.05a3 3 0 003 2.94h3.82l-.88-1H3a1.87 1.87 0 01-1.93-2V12.6a1.54 1.54 0 011.62-1.66h3.22v15.68a7.57 7.57 0 00.09.85 2.89 2.89 0 002.82 2.17h3.56l-.87-1H8.8a1.87 1.87 0 01-1.89-1.88V2.52A1.75 1.75 0 018.77 1h13.55a1.36 1.36 0 011.52 1.52v12.79h-9.47a2.43 2.43 0 00-2.57 2.57v9.19a2.45 2.45 0 002.58 2.56h13.83a2.45 2.45 0 002.53-2.52zm-1 9.11a1.61 1.61 0 01-1.62 1.61H14.47A1.57 1.57 0 0112.8 27v-7.21-1.86a1.56 1.56 0 01.7-1.36 1.41 1.41 0 01.74-.24h14A1.55 1.55 0 0129.73 18v7.68c0 .44.01.9 0 1.32z"
      />
      <circle cx="12.18" cy="6.68" r="2.34" />
      <circle cx="26" cy="19.93" r="1.88" />
      <path d="M5.31 21.46a2.32 2.32 0 010-4.64" />
      <path d="M10.18 13.94h7.52M10.18 11.46h9.85M16.34 26.75h7.52M16.34 24.27h9.85M3.94 26.75h1.09M3.94 24.27h1.09" />
    </g>
  </SVGIcon>
);

ServiceSuiteTemplateIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default ServiceSuiteTemplateIcon;
