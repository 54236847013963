import React from "react";
import PropTypes from "prop-types";
import { DialogContainer, Button } from "react-md";
import { connect } from "react-redux";
import classnames from "classnames";

import actions from "~/actions";

import "./DialogWithStore.component.scss";

const DialogWithStore = ({ dialog, close, setTitle, closeCallback }) => {
  // if available, call the close callback after hiding the dialog
  const onClose = () => {
    close();
    if (typeof closeCallback === "function") {
      closeCallback();
    }
  };

  if (!dialog) {
    return null;
  }

  return (
    <DialogContainer
      id="page-dialog"
      aria-label="Dialog"
      visible
      dialogClassName={classnames({
        "md-dialog--very-large": dialog.veryLarge,
      })}
      contentClassName={classnames(dialog.contentClassName, {
        "md-dialog-content--no-padding": dialog.noPadding,
        "md-dialog-content--no-margin": dialog.noMargin,
      })}
      modal={dialog.modal}
      title={dialog.title}
      width={dialog.width}
      height={dialog.height}
      onHide={onClose}
      focusOnMount={!!dialog.focusOnMount}
      actions={
        typeof dialog.footer === "function" ? dialog.footer(onClose, setTitle) : dialog.footer
      }
    >
      <>
        {typeof dialog.content === "function" ? dialog.content(onClose, setTitle) : dialog.content}
        <div className="close-button">
          <Button
            icon
            className="icon-btn text-color--grey"
            iconClassName="icon-close"
            onClick={() => onClose()}
          />
        </div>
      </>
    </DialogContainer>
  );
};

DialogWithStore.propTypes = {
  dialog: PropTypes.shape({
    modal: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    contentClassName: PropTypes.string,
    noPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    veryLarge: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    title: PropTypes.any,
    content: PropTypes.any,
    footer: PropTypes.any,
  }),
  close: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  closeCallback: PropTypes.func,
};

const stateToProps = (state) => ({
  dialog: state.dialog.settings,
  closeCallback: state.dialog.callback,
});

const dispatchToProps = {
  close: actions.dialog.hide,
  setTitle: actions.dialog.setTitle,
};

export default connect(stateToProps, dispatchToProps)(DialogWithStore);
