import { combineReducers } from "@reduxjs/toolkit";

import programEditor from "./programEditor.reducer";
import serviceSuiteEditor from "./serviceSuiteEditor.reducer";
import serviceSuiteTemplates from "./serviceSuiteTemplates.reducer";
import systemFields from "./systemFields.reducer";
import systemPermissions from "./systemPermissions.reducer";

export default combineReducers({
  programEditor,
  serviceSuiteEditor,
  serviceSuiteTemplates,
  systemFields,
  systemPermissions,
});
