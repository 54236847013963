import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { parseISO } from "date-fns";
import { orderBy } from "lodash";

import { GroupByValue } from "~/actions/reportTypes.actions";
import { overrideTimeZone } from "~/pages/reporting/lib/utils";
import { type ChartFilters } from "~/pages/reporting/ReportingFiltersAndChart";
import { ApiError, ApiErrorResponse, CustomerManagement } from "~/services";
import { type RootState } from "~/store";
import { facetsToApiFilters, Filter } from "../recording-facets/RecordingFacetsFilter.component";
import { Uniqueness } from "../recording-facets/UniquenessFilter.component";

export type PostReportingGraphBody = {
  /** ISO8601 date with time zone as UTC offset (<date>T<time>±hhmm). */
  dateFrom: string;
  /** ISO8601 date with time zone as UTC offset (<date>T<time>±hhmm). */
  dateTo: string;
  filters?: Filter[];
  uniqueness: Uniqueness;
  /** facility id */
  faid: string;
  fileId?: string;
  graph: {
    groupBy: string;
  };
  type: string;
};

export type ChartData = {
  count: number;
  pass: number;
  fail: number;
  failStatus: number;
  incomplete?: number;
  label: string;
}[];

type ApiChartDatum = {
  count: number;
  pass: number;
  fail: number;
  incomplete?: number;
  crash?: number;
  label: string;
};

interface ChartState {
  chartData?: ApiChartDatum[];
  status: "idle" | "loading";
  error?: ApiError;
}

const initialState: ChartState = {
  status: "idle",
};

// Thunks
export const fetchChartData = createAsyncThunk<
  ApiChartDatum[],
  { chartFilters: ChartFilters; groupByValue: GroupByValue },
  { rejectValue: ApiError }
>("reportingChart/fetchDataStatus", async ({ chartFilters, groupByValue }, { rejectWithValue }) => {
  try {
    const { facilityId, facets, timeZone, dateFrom, dateTo, uniqueness, csvFile, reportTypeName } =
      chartFilters;

    const params: PostReportingGraphBody = {
      faid: facilityId,
      filters: facetsToApiFilters(facets),
      dateFrom: overrideTimeZone(parseISO(dateFrom), timeZone),
      dateTo: overrideTimeZone(parseISO(dateTo), timeZone),
      uniqueness: uniqueness ?? "none",
      fileId: csvFile?._id,
      type: reportTypeName,
      graph: {
        groupBy: groupByValue,
      },
    };

    const response = (await CustomerManagement.POST("/reporting/graph", params)) as {
      payload: ApiChartDatum[];
    };
    const chartData = response.payload;

    return chartData;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const apiError = (err as ApiErrorResponse).json;

      return rejectWithValue(apiError);
    }
  }
});

// Slice
const chartSlice = createSlice({
  name: "reportingChart",
  initialState,
  reducers: {
    errorCleared(draftState) {
      draftState.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(fetchChartData.fulfilled, (draftState, action) => {
        draftState.status = "idle";
        draftState.chartData = action.payload;
      })
      .addCase(fetchChartData.rejected, (draftState, action) => {
        draftState.status = "idle";

        if (action.payload) {
          draftState.error = action.payload;
        } else {
          draftState.error = { message: "The request didn't complete successfully." };
        }
      });
  },
});

export const { errorCleared } = chartSlice.actions;

export default chartSlice.reducer;

// Selectors
export const selectChartData = createSelector(
  (state: RootState) => state.reportingModule.chart.chartData,
  (chartData): ChartData => {
    const data = chartData?.map((datum) => {
      const failStatus = datum.fail + (datum?.incomplete ?? 0) + (datum?.crash ?? 0);
      // GLOB-3282: merge the crashes with the incompletes
      const incomplete = (datum?.incomplete ?? 0) + (datum?.crash ?? 0);

      return { ...datum, failStatus, incomplete };
    });

    return orderBy(data, ["count", "pass"], ["desc", "desc"]);
  }
);

export const selectTotalCount = createSelector([selectChartData], (chartData) =>
  chartData.reduce((acc, datum) => acc + datum.count, 0)
);
