import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/canned-reports/actions/";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [moduleActions.testProcessingTimes.fetchTestProcessingTimesBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },

  [moduleActions.testProcessingTimes.fetchTestProcessingTimesSuccess]: (state, action) => {
    state.items = action.payload.testProcessingTimes;
    state.isLoading = false;
  },

  [moduleActions.testProcessingTimes.fetchTestProcessingTimesError]: (state, action) => {
    state.errors = action.payload.errors;
    state.items = [];
    state.isLoading = false;
  },
});
