import { createSelector } from "@reduxjs/toolkit";
import { keyBy } from "lodash/collection";
import { pick } from "lodash/object";

export const getCustomerUsers = (state) => state.modules.customerManagement.customerUsers.items;
const getFilter = (state) => state.modules.customerManagement.customerUsers.filter;
const getUserRoles = (state) => state.modules.customerManagement.customerUsers.roles;

export const getFlatUsers = createSelector(
  [getCustomerUsers, getUserRoles],
  (customerUsers, getUserRoles) => {
    const defaults = {
      userid: "",
      email: "",
      hasAllFacilitiesAccess: undefined,
      facilities: [],
      firstname: "",
      lastname: "",
      name: "",
      status: 0,
      operatorId: "",
    };
    const users =
      customerUsers?.map((user_metadata) => {
        const meta = pick(user_metadata, Object.keys(defaults));
        const role = getUserRoles.find(
          (roles) => roles.id === `ObjectID("${user_metadata?.roleids?.[0]}")`
        );
        return {
          ...defaults,
          ...meta,
          roleName: role?.name ?? "",
          roleId: user_metadata?.roleids?.[0],
        };
      }) ?? [];

    return users;
  }
);

export const getFilteredUsers = createSelector([getFlatUsers, getFilter], (users, filter) => {
  if (filter.length === 0) {
    return users;
  }
  const normalizedFilter = filter.toLowerCase();
  return users.filter((user) =>
    [user.name, user.email, user.roleName].some((field) =>
      field.toLowerCase().includes(normalizedFilter)
    )
  );
});

export const getFlattenUsersById = createSelector([getFlatUsers], (users = []) =>
  keyBy(users, (user) => user.userid)
);

export const getFirstRoleName = createSelector([getUserRoles], (userRoles) =>
  userRoles[0] != null ? userRoles[0].name : ""
);

export const getSupervisorsOptions = createSelector([getFlatUsers], (users) =>
  users
    .map((user) => {
      const username = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;

      return {
        value: `${username}:${user.email.toLowerCase()}`,
        label: username,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label))
);
