import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ApiError, ApiErrorResponse, ApiResponse, ModelManagement } from "~/services";

type QueryModelsResponse = {
  data: {
    models: {
      Images: {
        LargeImage: string;
      };
    }[];
  };
};

export const fetchModelImage = createAsyncThunk<string, string, { rejectValue: ApiError }>(
  "modelImage/fetch",
  async (imei, { rejectWithValue }) => {
    try {
      const query = ModelManagement.gqlBuilder(`{
        models(imei: "${imei}") {
          Images { LargeImage }
        }
      }`);

      const { payload } = (await ModelManagement.QUERY(query)) as ApiResponse<QueryModelsResponse>;

      return payload.data.models[0].Images.LargeImage;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        return rejectWithValue((err as ApiErrorResponse).json);
      }
    }
  }
);

interface ModelImageState {
  value?: string;
  error?: ApiError;
  status: "idle" | "loading";
}

const initialState: ModelImageState = {
  status: "idle",
};

const modelImageSlice = createSlice({
  name: "modelImage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch
      .addCase(fetchModelImage.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(fetchModelImage.fulfilled, (draftState, { payload }) => {
        draftState.status = "idle";

        if (payload.length > 0) {
          draftState.value = payload;
        }
      })
      .addCase(fetchModelImage.rejected, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.error = {
          ...payload,
          message: payload?.message ?? "The request did not complete successfully.",
        };
      });
  },
});

export default modelImageSlice.reducer;
