import React from "react";
import PropTypes from "prop-types";

const ShapeFreeformInspector = (props) => <div />;

ShapeFreeformInspector.defaultProps = {};

ShapeFreeformInspector.propTypes = {};

export default ShapeFreeformInspector;
