import { mapValues } from "lodash";

import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";
import { getRegexValidation } from "~/global/utils";

export const addFailCodePriorityGroupBegin = createAction("ADD_FAIL_CODE_PRIORITY_GROUP_BEGIN");
export const addFailCodePriorityGroupError = createAction("ADD_FAIL_CODE_PRIORITY_GROUP_ERROR");
export const addFailCodePriorityGroupSuccess = createAction("ADD_FAIL_CODE_PRIORITY_GROUP_SUCCESS");
export const selectFailCodePriorityGroup = createAction("SELECT_FAIL_CODE_PRIORITY_GROUP");
export const validateFailCodePriorityGroupNameSuccess = createAction(
  "VALIDATE_FAIL_CODE_PRIORITY_GROUP_NAME_SUCCESS"
);
export const validateFailCodePriorityGroupNameError = createAction(
  "VALIDATE_FAIL_CODE_PRIORITY_GROUP_NAME_ERROR"
);
export const deleteFailCodePriorityGroupBegin = createAction(
  "DELETE_FAIL_CODE_PRIORITY_GROUP_BEGIN"
);
export const deleteFailCodePriorityGroupError = createAction(
  "DELETE_FAIL_CODE_PRIORITY_GROUP_ERROR"
);
export const deleteFailCodePrioritGroupSuccess = createAction(
  "DELETE_FAIL_CODE_PRIORITY_GROUP_SUCCESS"
);

export const validateName = (name, language) => (dispatch, getState) => {
  const isValid = getRegexValidation("failCodePriorityGroupName", name);

  if (!isValid) {
    dispatch(
      validateFailCodePriorityGroupNameError({
        language,
        errorType: "badFormat",
      })
    );
    return;
  }

  const state = getState();
  const existingNames = selectors.failCodes.getExistingFailCodePriorityGroupNames(state);
  const alreadyExists = existingNames[language].includes(name.toLowerCase().trim());
  if (alreadyExists) {
    dispatch(
      validateFailCodePriorityGroupNameError({
        language,
        errorType: "alreadyExists",
      })
    );
    return;
  }

  dispatch(validateFailCodePriorityGroupNameSuccess({ language }));
};

export const addFailCodePriorityGroup = (group) => async (dispatch) => {
  const dataToServer = {
    ...group,
    // trim all names
    name: mapValues(group.name, (name) => name.trim()),
    // fccid must be "fccID" in PUT & POST requests
    failCodeCategories: group.failCodeCategories.map(({ fccid, ...categoryWithoutFccid }) => ({
      ...categoryWithoutFccid,
      fccID: fccid,
    })),
  };

  dispatch(addFailCodePriorityGroupBegin());

  // a payload is being returned here given that in the case of editing a Priority Group, it may be necessary to re-fetch the priority groups
  // because of this, the selectFailCodePriorityGroup action may need to be dispatched if we still want to be manipulating the priority group
  // hence the need to have its UpsertedID be returned
  try {
    const response = await CustomerManagement.POST("/priority-groups", dataToServer);
    dispatch(addFailCodePriorityGroupSuccess());
    return response.payload;
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(addFailCodePriorityGroupError(message));
  }
};

export const deleteFailCodePriorityGroup = (group) => async (dispatch) => {
  dispatch(deleteFailCodePriorityGroupBegin());
  try {
    await CustomerManagement.DELETE(`/priority-groups/${group}`);
    dispatch(deleteFailCodePrioritGroupSuccess());
  } catch (error) {
    const message = error.json?.message ?? "Request didn't complete successfully";
    dispatch(addFailCodePriorityGroupError(message));
  }
};
