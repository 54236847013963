import React, { Component } from "react";
import { Button } from "react-md";

import "./DeleteError.component.scss";

export default class FailCodesDeleteError extends Component {
  render() {
    return (
      <div className="delete-errors-container">
        <h3>List of Fail Codes with Associated Tests:</h3>

        <div className="delete-error-detail">
          {this.props.details.map((detail, indexFC) => (
            <div
              className="delete-error-fail-code-container"
              key={`delete-error-fail-code-${indexFC}`}
            >
              <div>Fail Code: {detail.failCodes[0].failCodeName}</div>

              <div className="associated-tests-container">
                <div className="associated-tests-title">Associated Tests</div>
                <div className="row associated-tests-header">
                  <div className="flex--25">Platform</div>
                  <div className="flex--25">Test Name</div>
                  <div className="flex--25">Config Name</div>
                  <div className="flex--5" />
                </div>
                {Object.keys(detail.failCodes || []).map((key) => {
                  const associatedTest = detail.failCodes[key].associatedTests;

                  return (
                    <div className="row associated-tests-body" key={`associated-test-${key}`}>
                      <div className="flex--25">{associatedTest.platform}</div>
                      <div className="flex--25">{associatedTest.testName}</div>
                      <div className="flex--25">{associatedTest.configName}</div>
                      <div className="flex--5">
                        <Button
                          icon
                          className="icon-btn"
                          iconClassName="icon-external-link"
                          onClick={() => this.props.onLinkClicked(associatedTest)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
