import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import values from "lodash/values";
import { sortBy } from "lodash/collection";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import moduleActions from "../../actions";

import { Row, Column, SectionHeader } from "~/global";
import { formatTime, secondsFormat, formatDate, getDurationTime } from "~/global/utils";
import StatusIcon from "~/device-history/components/StatusIcon/StatusIcon.component";

import "../DeviceTests/DeviceTests.component.scss";

const AuditTests = ({
  tests,
  certificate,
  failedManually,
  includeToPrint,
  toggleIncludeToPrint,
}) => {
  const [sortKey, setSortKey] = useState("start");
  const [sortReverse, setSortReverse] = useState(false);

  const onSort = (key) => {
    const newSortReverse = key == sortKey ? !sortReverse : sortReverse;
    setSortKey(key);
    setSortReverse(newSortReverse);
  };

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];

    if (sortKey == colKey) {
      classes.push("is-sorted");
    }

    if (sortReverse) {
      classes.push("sort-reverse");
    }

    return classes.join(" ");
  };

  const renderTable = () => {
    const sortedUniqueTests = sortBy(
      values(
        tests.reduce((acc, test) => {
          if (acc[test.name]) {
            if (test.status.toUpperCase() === "PASS") {
              acc[test.name] = test;
            }
          } else {
            acc[test.name] = test;
          }
          return acc;
        }, {})
      ),
      "order"
    ).reverse();

    let sortedTests =
      sortedUniqueTests != null
        ? sortBy(sortedUniqueTests, (test) => {
            const value = test[sortKey];

            switch (sortKey) {
              case "failCodes":
                if (value) {
                  return value[0].code;
                }
                break;

              case "duration":
                return parseInt(value);
              case "start":
                return new Date(value);
              default:
                if (typeof value === "string") {
                  return value.toLowerCase();
                }
                break;
            }
          })
        : [];

    if (sortReverse) {
      sortedTests = sortedTests.reverse();
    }

    let totalTime = 0;

    if (sortedUniqueTests != null) {
      sortedUniqueTests.forEach((test) => {
        const duration = isNaN(+test.duration)
          ? 0
          : secondsFormat(test.durationUnits, test.duration);
        totalTime += duration;
      });
    }

    totalTime = formatTime(totalTime);

    const testStatus = (test) => {
      let processResult = "";

      if (test.status.toUpperCase() === "PASS") {
        processResult = "FAIL";
      } else if (test.status.toUpperCase() === "FAIL") {
        processResult = "PASS";
      } else {
        processResult = test.status;
      }

      return processResult;
    };

    return (
      <Row className="device-tests full-width">
        <Column className="full-width">
          <DataTable className="device-tests-table" baseId="device-tests-table" plain>
            <TableHeader className="table-header">
              <TableRow>
                <TableColumn
                  className={`${getColHeaderClasses("name")} header-label`}
                  onClick={() => onSort("name")}
                >
                  Test name
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("status")} header-label`}
                  onClick={() => onSort("status")}
                >
                  Status
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("start")} header-label`}
                  onClick={() => onSort("start")}
                >
                  Start
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("duration")} header-label`}
                  onClick={() => onSort("duration")}
                >
                  Duration
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("failCodes")} header-label`}
                  onClick={() => onSort("failCodes")}
                >
                  Fail Codes (Priority)
                </TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody className="table-body">
              {sortedTests.map((test, index) => (
                <TableRow
                  key={index}
                  className={test.status == "FAIL" ? "failed clickable" : "clickable"}
                >
                  <TableColumn className="table-data">{test.name}</TableColumn>
                  <TableColumn className="table-data">
                    <div className="row">
                      <StatusIcon status={testStatus(test)} />
                    </div>
                  </TableColumn>
                  <TableColumn className="table-data">
                    {test.start != null ? formatDate(test.start) : ""}
                  </TableColumn>
                  <TableColumn className="right-aligned no-printable table-data">
                    {getDurationTime(test.durationUnits, test.duration)}
                  </TableColumn>
                  <TableColumn className="table-data">
                    {test.failCodes != null ? (
                      <div className="row">
                        <div className="min-margin">{test.failCodes[0].code}</div>
                        <div>({test.failCodes[0].priority})</div>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
            {sortedTests.length > 0 ? (
              <tfoot className="footer-tests no-printable">
                <TableRow>
                  <TableColumn className="bold-screen">Total:</TableColumn>
                  <TableColumn />
                  <TableColumn />
                  <TableColumn className="right-aligned bold-screen">{`~${totalTime}`}</TableColumn>
                  <TableColumn />
                </TableRow>
              </tfoot>
            ) : null}
          </DataTable>
        </Column>
      </Row>
    );
  };

  const includeForPrint = () => {
    toggleIncludeToPrint(includeToPrint);
  };

  return (
    <div className="list-content fade-in cc-device-tests">
      {certificate ? (
        <div>
          <SectionHeader title="Audit Tests" className="certificate-header" />
          {renderTable()}
        </div>
      ) : (
        <div
          className={includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"}
        >
          <ExpansionList>
            <ExpansionPanel
              className="cc-expansion-panel"
              label={
                <div className="panel-text title bold-screen printable-title">Audit Tests</div>
              }
              secondaryLabel={
                <div className="panel-text no-printable">
                  {includeToPrint ? "(added to report)" : "(not added to report)"}
                </div>
              }
              onExpandToggle={() => includeForPrint()}
              expanded={includeToPrint}
              footer={null}
              expanderIcon={<i className="no-printable icon-chevron-down" />}
              headerClassName="cc-section-header table-header"
              contentClassName="cc-fullwidth-panel"
            >
              {failedManually ? (
                <div className="no-tests md-text">No tests were executed.</div>
              ) : (
                renderTable()
              )}
            </ExpansionPanel>
          </ExpansionList>
        </div>
      )}
    </div>
  );
};

AuditTests.propTypes = {
  tests: PropTypes.array,
  certificate: PropTypes.bool,
  failedManually: PropTypes.bool,
};

AuditTests.defaultProps = {
  tests: [],
  certificate: false,
  failedManually: false,
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeAuditTestsToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeAuditTestsToPrint,
};

export default connect(stateToProps, dispatchToProps)(AuditTests);
