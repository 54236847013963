import React, { useMemo } from "react";
import { Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import difference from "lodash/difference";
import { FontIcon } from "react-md";
import EventEditor from "~/workflow/components/editors/EventEditor";

import * as wEvents from "~/workflow/workflowEvents";
import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";
import { SectionHeader } from "~/global";

import "./WorkflowEditor.scss";

const WorkflowEditor = () => {
  const dispatch = useDispatch();
  const selectedEvents = useSelector(workflowSelectors.workflowEditor.getSelectedEvents);
  const availableEvents = useMemo(
    () => difference(wEvents.selectableEvents, selectedEvents),
    [selectedEvents]
  );
  const onSelectEvent = (eventId) => () =>
    dispatch(workflowActions.workflowEditor.addEvent(eventId));

  return (
    <div className="workflow-editor">
      <SectionHeader
        title={
          <div className="workflow-header">
            <div>Workflow Events</div>
            <Dropdown
              overlay={
                <Menu>
                  {availableEvents.map((e) => (
                    <Menu.Item
                      key={`menu-item-${e}`}
                      title={wEvents.metadata[e].description}
                      onClick={onSelectEvent(e)}
                    >
                      {wEvents.metadata[e].label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <FontIcon
                iconClassName="icon-plus"
                className="add-icon-btn"
                style={{ marginLeft: "0.5rem", color: "white" }}
              />
            </Dropdown>
          </div>
        }
      />
      {selectedEvents.map((e) => (
        <EventEditor key={`event-editor-${e}`} eventId={e} />
      ))}
    </div>
  );
};

export default WorkflowEditor;
