import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Switch, FontIcon } from "react-md";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

import { Checkbox, MarqueeText, Row, Column } from "~/global";
import FailCodeCategoryEditor from "./FailCodeCategoryEditor.component";

import "./FailCodeCategoriesList.component.scss";

const DragHandle = SortableHandle(() => (
  <div>
    <FontIcon iconClassName="icon-sort" />
  </div>
));

const CategoryItem = SortableElement(
  ({
    order,
    failCodeCategory,
    isChecked,
    isSelected,
    isLoadingSelected,
    canEditFailFast,
    onSelect,
    onCheckToggle,
    toggleFailFast,
    editFailCodeCategory,
    visible,
    toggleVisibility,
  }) => (
    <Row
      align="start center"
      className={classnames("selector-row fail-code-category-row", {
        checked: isChecked,
        current: isSelected,
        hidden: !visible,
      })}
      onClick={() => onSelect(failCodeCategory.id)}
    >
      <Column className="order">{order}</Column>

      <Column className="handle">
        <DragHandle />
      </Column>

      <Column>
        <Checkbox
          id={`fail-code-category-check-${failCodeCategory.id}`}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onCheckToggle(failCodeCategory.id)}
          isChecked={isChecked}
          disabled={isLoadingSelected}
        />
      </Column>

      <Column className="fail-code-category-name">
        <MarqueeText
          text={failCodeCategory.defaultName != null ? failCodeCategory.defaultName : ""}
        />
      </Column>

      <Column>
        <Switch
          id={`fail-fast-switch-${failCodeCategory.id}`}
          type="switch"
          name={`fail-fast ${failCodeCategory}`}
          checked={failCodeCategory.failFast}
          onClick={(e) => e.stopPropagation()}
          onChange={() => !isLoadingSelected && toggleFailFast(failCodeCategory)}
          label=""
          disabled={!canEditFailFast}
        />
      </Column>

      <Column className="visible">
        <Button
          icon
          className="icon-btn"
          iconClassName={visible ? "icon-eye" : "icon-eye-slash"}
          onClick={(e) => {
            e.stopPropagation();
            toggleVisibility(failCodeCategory.id);
          }}
          disabled={isLoadingSelected}
        />
      </Column>

      <Column>
        <Button
          icon
          className="icon-btn"
          iconClassName="icon-pencil"
          onClick={() => editFailCodeCategory(failCodeCategory)}
          disabled={isLoadingSelected}
        />
      </Column>

      <Column>
        <i className="content-pointer icon-chevron-right" />
      </Column>
    </Row>
  )
);

const CategoryList = SortableContainer(
  ({
    failCodeCategories,
    selectedId,
    isLoading,
    isFailCodeCategoryChecked,
    onSelect,
    onCheckToggle,
    toggleFailFast,
    editFailCodeCategory,
    onUpdatePriorityGroup,
    failCodePriorityGroup,
  }) => {
    const toggleVisibility = (targetFccid) => {
      const newPriorityGroupCategories = failCodePriorityGroup.failCodeCategories.map(
        (category) => {
          if (category.fccid === targetFccid) {
            return { ...category, visible: !category.visible };
          }

          return category;
        }
      );

      onUpdatePriorityGroup(newPriorityGroupCategories);
    };

    return (
      <div className="scroll-container flex">
        {failCodeCategories.map((failCodeCategory, index) => {
          const isChecked = isFailCodeCategoryChecked(failCodeCategory.id);
          const isSelected = failCodeCategory.id === selectedId;
          const isLoadingSelected = isSelected && isLoading;
          const canEditFailFast = !isLoadingSelected;
          const priorityGroupFailCodeCategory = failCodePriorityGroup.failCodeCategories.find(
            (item) => item.fccid === failCodeCategory.id
          );
          const { visible } = priorityGroupFailCodeCategory;

          return (
            <CategoryItem
              key={failCodeCategory.id}
              index={index}
              order={index + 1}
              failCodeCategory={failCodeCategory}
              isChecked={isChecked}
              isSelected={isSelected}
              isLoadingSelected={isLoadingSelected}
              canEditFailFast={canEditFailFast}
              onSelect={onSelect}
              onCheckToggle={onCheckToggle}
              toggleFailFast={toggleFailFast}
              editFailCodeCategory={editFailCodeCategory}
              visible={visible}
              toggleVisibility={toggleVisibility}
            />
          );
        })}
      </div>
    );
  }
);

const FailCodeCategoriesList = ({
  failCodeCategories = [],
  checkedIds = [],
  selectedId,
  onCheck,
  onSelect,
  onChange,
  onCancel,
  isLoading = false,
  onUpdatePriorityGroup,
  failCodePriorityGroup,
  showDialog,
}) => {
  const isFailCodeCategoryChecked = (id) => checkedIds.indexOf(id) > -1;

  const areAllFailCodeCategoriesChecked = () => {
    const checked = failCodeCategories.reduce(
      (acc, failCodeCategory) => isFailCodeCategoryChecked(failCodeCategory.id) && acc,
      true
    );
    return checked;
  };

  const onCheckAllToggle = () => {
    const allSelected = checkedIds.length === failCodeCategories.length;
    const newCheckedIds = allSelected ? [] : failCodeCategories.map((f) => f.id);
    onCheck(newCheckedIds);
  };

  const onCheckToggle = (failCodeCategoryId) => {
    const index = checkedIds.indexOf(failCodeCategoryId);
    const newCheckedIds = [...checkedIds];
    if (index > -1) {
      newCheckedIds.splice(index, 1);
    } else {
      newCheckedIds.push(failCodeCategoryId);
    }
    onCheck(newCheckedIds);
  };

  const setSelected = (failCodeCategoryId) => {
    if (selectedId !== failCodeCategoryId) {
      onSelect(failCodeCategoryId);
    }
  };

  const toggleFailFast = (failCodeCategory) => {
    const failCodeCategoryUpdated = {
      ...failCodeCategory,
      failFast: !failCodeCategory.failFast,
    };
    onChange(failCodeCategoryUpdated);
    setSelected(failCodeCategory.id);
  };

  const editFailCodeCategory = () => {
    showDialog({
      title: "Edit Fail Category",
      width: "450px",
      modal: true,
      focusOnMount: true,
      initialFocus: "#fail-codes-category-editor-name",
      content: (close) => (
        <FailCodeCategoryEditor
          onCancel={() => {
            close();
            onCancel();
          }}
          onConfirm={(failCodeCategory) => {
            close();
            onChange(failCodeCategory);
          }}
        />
      ),
    });
  };

  const sortableListOrderChanged = ({ oldIndex, newIndex }) => {
    const priorityGroupCategories = failCodePriorityGroup.failCodeCategories;
    const orderedCategories = arrayMove(priorityGroupCategories, oldIndex, newIndex);
    onUpdatePriorityGroup(orderedCategories);
  };

  return (
    <div className="fail-code-categories-list">
      <Row align="start center" className="subheader-column">
        <Column className="priority">Pri.</Column>

        <Column
          className={classnames({
            "asci-hide": failCodeCategories.length === 0,
          })}
        >
          <Checkbox
            id="select-all-fail-code-categories"
            onChange={onCheckAllToggle}
            isChecked={areAllFailCodeCategoriesChecked()}
            disabled={failCodeCategories.length === 0}
          />
        </Column>

        <Column className="fail-code-category-name">Name</Column>
        <Column>Fail Fast</Column>
      </Row>

      {failCodePriorityGroup != null && (
        <CategoryList
          onSortEnd={sortableListOrderChanged}
          useDragHandle
          lockAxis="y"
          failCodeCategories={failCodeCategories}
          selectedId={selectedId}
          isLoading={isLoading}
          isFailCodeCategoryChecked={isFailCodeCategoryChecked}
          onSelect={setSelected}
          onCheckToggle={onCheckToggle}
          toggleFailFast={toggleFailFast}
          editFailCodeCategory={editFailCodeCategory}
          onUpdatePriorityGroup={onUpdatePriorityGroup}
          failCodePriorityGroup={failCodePriorityGroup}
        />
      )}
    </div>
  );
};

FailCodeCategoriesList.propTypes = {
  failCodeCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.object,
      failFast: PropTypes.bool.isRequired,
      defaultName: PropTypes.string,
    })
  ),
  checkedIds: PropTypes.arrayOf(PropTypes.string),
  selectedId: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onUpdatePriorityGroup: PropTypes.func.isRequired,
  failCodePriorityGroup: PropTypes.shape({
    failCodeCategories: PropTypes.arrayOf(
      PropTypes.shape({
        fccid: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        failCodes: PropTypes.arrayOf(
          PropTypes.shape({ fcid: PropTypes.string.isRequired, visible: PropTypes.bool.isRequired })
        ).isRequired,
      }).isRequired
    ).isRequired,
  }),
  showDialog: PropTypes.func.isRequired,
};

export default FailCodeCategoriesList;
