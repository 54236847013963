import { createContext, useContext } from "react";

export type AuthStatus =
  | "authenticated"
  | "pre_authenticated"
  | "pending"
  | "unauthenticated"
  | "authorization_error"
  | "uninit";
export interface Auth {
  status: AuthStatus;
  logout(): void;
}

export const AuthContext = createContext<Auth>({
  status: "uninit",
  logout: () => {
    throw new Error("You forgot to wrap your component in <AuthProvider>.");
  },
});

export const useAuth = () => useContext(AuthContext);
