import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";

export const getTestCategories = (state) => state.testCategories.items;
export const getSelectedTestCategoryName = (state) => state.testCategories.selectedName;

export const getSortedCategories = createSelector([getTestCategories], (categories) =>
  sortBy(categories, "displayName")
);

export const getSelectedTestCategory = createSelector(
  [getTestCategories, getSelectedTestCategoryName],
  (categories, categoryName) =>
    categories.filter((category) => category.name === categoryName)[0] || {}
);

export const getInitiallySelectedCategory = createSelector(
  [(state) => state.tests.initiallySelectedTestClassName, getSortedCategories],
  (className, sortedCategories) => {
    for (let i = 0; i < sortedCategories.length; i++) {
      if (sortedCategories[i].classNames.includes(className)) {
        return sortedCategories[i];
      }
    }
    return sortedCategories[0];
  }
);
