import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-md";

import { TestRow } from "~/global";
import "./FailCodeAssociatedTestsList.component.scss";

export default class FailCodeAssociatedTestsList extends Component {
  static propTypes = {
    testConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        displayName: PropTypes.string,
        className: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    emptyMessage: PropTypes.string,
    onLinkClicked: PropTypes.func,
  };

  static defaultProps = {
    testConfigs: [],
    emptyMessage: "",
  };

  render() {
    return (
      <div className="fail-code-associated-tests-list scroll-container">
        {this.props.testConfigs.length === 0 && this.props.emptyMessage.length > 0 ? (
          <div className="padding--10 text-centered">{this.props.emptyMessage}</div>
        ) : null}

        {this.props.testConfigs.map((config, index) => (
          <TestRow key={index}>
            <TestRow.Icon image={config.image} />
            <TestRow.FriendlyName name={config.displayName || config.className} />
            <TestRow.Description description={config.description} />
            {this.props.onLinkClicked ? (
              <TestRow.Extra className="layout-column layout-column--center-center">
                <Button
                  icon
                  className="icon-btn"
                  iconClassName="icon-external-link"
                  onClick={() => this.props.onLinkClicked(config)}
                />
              </TestRow.Extra>
            ) : null}
          </TestRow>
        ))}
      </div>
    );
  }
}
