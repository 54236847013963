import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import actions from "~/actions";
import selectors from "~/selectors";
import { Column, Row, SelectList, AsciSpinner } from "~/global";

import moduleActions from "~/data-import/actions";

import "./UploadInfoFilters.component.scss";

const UploadInfoFilters = ({
  configurationsByProgramId,
  currentFilters,
  deploymentsByFacilityId,
  facilities,
  fetchAllFilters,
  formattedFacilities,
  isLoading,
  onClearFilters,
  onSelectedFilters,
  programIdsByDeploymentId,
  programsById,
  resetFilters,
  selectedFilters,
  setCurrentFilters,
  setResetFilters,
  shiftsByDeploymentId,
  teamsByFacilityId,
}) => {
  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [selectedDeploymentId, setSelectedDeploymentId] = useState("all");
  const [selectedShiftId, setSelectedShiftId] = useState("all");
  const [selectedTeamId, setSelectedTeamId] = useState("all");
  const [selectedProgramId, setSelectedProgramId] = useState("all");
  const [selectedConfigurationId, setSelectedConfigurationId] = useState("all");

  useEffect(() => {
    fetchAllFilters();
  }, []);

  useEffect(() => {
    if (facilities.length > 0) {
      applyFilters(
        formattedFacilities[0].faid,
        selectedDeploymentId,
        selectedShiftId,
        selectedTeamId,
        selectedProgramId,
        selectedConfigurationId
      );
    }
  }, [facilities]);

  useEffect(() => {
    updateUploadInfo();
  }, [
    selectedConfigurationId,
    selectedFacilityId,
    selectedProgramId,
    setSelectedDeploymentId,
    selectedTeamId,
    selectedShiftId,
  ]);

  useEffect(() => {
    if (Object.keys(currentFilters).length > 0) {
      setSelectedFacilityId(currentFilters.facilityId);
      setSelectedDeploymentId(currentFilters.deploymentId);
      setSelectedShiftId(currentFilters.shiftId);
      setSelectedTeamId(currentFilters.teamId);
      setSelectedProgramId(currentFilters.programId);
      setSelectedConfigurationId(currentFilters.configurationId);
    }
  }, [currentFilters]);

  const updateFilters = (option, selectedFilter) => {
    switch (selectedFilter) {
      case "facility":
        setSelectedFacilityId(option.faid);
        setSelectedDeploymentId("all");
        setSelectedTeamId("all");
        setSelectedProgramId("all");
        break;

      case "deployment":
        setSelectedDeploymentId(option.dpid);
        setSelectedShiftId("all");
        setSelectedProgramId("all");
        setSelectedConfigurationId("all");
        setSelectedTeamId("all");
        break;

      case "shift":
        setSelectedShiftId(option.id);
        break;

      case "team":
        setSelectedTeamId(option.teamid);
        break;

      case "program":
        setSelectedProgramId(option.id);
        setSelectedConfigurationId("all");
        break;

      case "configuration":
        setSelectedConfigurationId(option.id);
        break;
    }

    updateUploadInfo();
  };

  const applyFilters = (facilityId, deploymentId, shiftId, teamId, programId, configurationId) => {
    setCurrentFilters({
      facilityId,
      deploymentId,
      shiftId,
      teamId,
      programId,
      configurationId,
    });
  };

  const updateUploadInfo = () => {
    onSelectedFilters({
      facility: selectedFacilityItem,
      deployment: selectedDeploymentItem,
      shift: selectedShiftItem,
      team: selectedTeamItem,
      program: selectedProgramItem,
      config: selectedConfigItem,
    });
  };

  useEffect(() => {
    if (resetFilters) {
      onClearFilters();
      setSelectedFacilityId("all");
      setSelectedDeploymentId("all");
      setSelectedTeamId("all");
      setSelectedProgramId("all");
      setSelectedConfigurationId("all");
      setResetFilters(!resetFilters);
    }
  }, [resetFilters]);

  useEffect(() => {
    if (selectedFilters === undefined) {
      // todo use selector to set base info to "select xxxx"
    }
  }, [resetFilters]);

  if (isLoading || selectedFacilityId.length === 0) {
    return <AsciSpinner visible />;
  }

  const selectedFacilityItem = formattedFacilities.filter((f) => f.faid === selectedFacilityId)[0];

  const deploymentItems = deploymentsByFacilityId[selectedFacilityId] || [];
  const selectedDeploymentItem = deploymentItems.filter((d) => d.dpid === selectedDeploymentId)[0];

  const shiftItems = (shiftsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const selectedShiftItem = shiftItems.filter((s) => s.id === selectedShiftId)[0];

  const teamItems = teamsByFacilityId[selectedFacilityId] || [];
  const selectedTeamItem = teamItems.filter((t) => t.teamid === selectedTeamId)[0];

  const programIds =
    (programIdsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const programs = programIds.map((programId) => programsById[programId.id] || []);

  const programItems = programs.filter((program, index, self) => self.indexOf(program) === index);
  const selectedProgramItem = programItems.filter((p) => p.id === selectedProgramId)[0];

  const configItems = configurationsByProgramId[selectedProgramId] || [];
  const selectedConfigItem = configItems.filter((c) => c.id === selectedConfigurationId)[0];

  return (
    <Column className="import-data-filters full-width">
      <Row className="section-title">Upload Info</Row>
      <Row className="filters">
        <Column className="filter-container">
          <Row className="filter-label">Facility</Row>
          <SelectList
            items={formattedFacilities}
            onChange={(option) => updateFilters(option, "facility")}
            selectedItem={selectedFacilityItem}
            nameKey="name"
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">Deployment</Row>
          <SelectList
            items={deploymentItems}
            onChange={(option) => updateFilters(option, "deployment")}
            selectedItem={selectedDeploymentItem}
            nameKey="name"
            disabled={deploymentItems.length === 0 || selectedFacilityId === "all"}
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">Shift</Row>
          <SelectList
            items={shiftItems}
            onChange={(option) => updateFilters(option, "shift")}
            selectedItem={selectedShiftItem}
            nameKey="name"
            disabled={shiftItems.length === 0 || selectedDeploymentId === "all"}
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">Team</Row>
          <SelectList
            items={teamItems}
            onChange={(option) => updateFilters(option, "team")}
            selectedItem={selectedTeamItem}
            nameKey="name"
            disabled={teamItems.length === 0}
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">Program</Row>
          <SelectList
            items={programItems}
            onChange={(option) => updateFilters(option, "program")}
            selectedItem={selectedProgramItem}
            nameKey="name"
            disabled={programItems.length === 0 || selectedFacilityId === "all"}
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">Service Suite</Row>
          <SelectList
            items={configItems}
            onChange={(option) => updateFilters(option, "configuration")}
            selectedItem={selectedConfigItem}
            nameKey="name"
            disabled={configItems.length === 0 || selectedProgramId === "all"}
          />
        </Column>
      </Row>
    </Column>
  );
};

UploadInfoFilters.propTypes = {
  currentFilters: PropTypes.exact({
    facilityId: PropTypes.string,
    deploymentId: PropTypes.string,
    shiftId: PropTypes.string,
    teamId: PropTypes.string,
    programId: PropTypes.string,
    configurationId: PropTypes.string,
  }).isRequired,
  setCurrentFilters: PropTypes.func.isRequired,

  // From the store
  fetchAllFilters: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  formattedFacilities: PropTypes.array.isRequired,
  deploymentsByFacilityId: PropTypes.object.isRequired,
  deploymentsByFacilityId: PropTypes.object.isRequired,
  shiftsByDeploymentId: PropTypes.object.isRequired,
  programIdsByDeploymentId: PropTypes.object.isRequired,
  programsById: PropTypes.object.isRequired,
  configurationsByProgramId: PropTypes.object.isRequired,
  teamsByFacilityId: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  configurationsByProgramId: selectors.filters.getConfigurationsByProgramId(state),
  deploymentsByFacilityId: selectors.filters.getDeploymentsByFacilityId(state),
  facilities: state.filters.facilities,
  formattedFacilities: selectors.filters.getCleanFacilities(state),
  programIdsByDeploymentId: selectors.filters.getProgramIdsByDeploymentId(state),
  programsById: selectors.filters.getProgramsById(state),
  selectedFilters: state.modules.dataImport.importDataAutomatic.selectedFilters,
  shiftsByDeploymentId: selectors.filters.getShiftsByDeploymentId(state),
  teamsByFacilityId: selectors.filters.getTeamsByFacilityId(state),
});

const dispatchToProps = {
  fetchAllFilters: actions.filters.fetchAllFilters,
  onClearFilters: moduleActions.importDataAutomatic.clearFilters,
  onSelectedFilters: moduleActions.importDataAutomatic.selectedUploadInfoData,
};

export default connect(stateToProps, dispatchToProps)(UploadInfoFilters);
