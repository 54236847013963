import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";

import { getSelectedTestCategory } from "./testCategories.selectors";
import { getSelectedPlatform } from "./platforms.selectors";

export const getTests = (state) => state.tests.items;
export const getSelectedTestClassName = (state) => state.tests.selected.className;
export const getInitiallySelectedTestClassName = (state) =>
  state.tests.initiallySelectedTestClassName;

export const getSortedTests = createSelector(
  [getSelectedTestCategory, getTests, getSelectedPlatform],
  (category, tests, platform) => {
    if (!category.classNames || tests.length === 0) {
      return [];
    }

    const filtered = tests.filter(
      (test) => test.platform === platform.value && category.classNames.includes(test.className)
    );

    return sortBy(filtered, "displayName");
  }
);

export const getInitiallySelectedTest = createSelector(
  [getInitiallySelectedTestClassName, getSortedTests],
  (className, sortedTests) =>
    sortedTests.filter((test) => test.className === className)[0] || sortedTests[0]
);
