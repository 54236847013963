import React, { PureComponent } from "react";

import "./CertificateFooter.component.scss";

export default class CertificateFooter extends PureComponent {
  render() {
    const date = new Date();
    const year = date.getFullYear();

    return (
      <footer className="row centered certificate-footer">
        Copyright© {year} Asset Science LLC. All Rights Reserved
      </footer>
    );
  }
}
