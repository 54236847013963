import { createAction } from "@reduxjs/toolkit";
import { ModelManagement, DataCollector } from "~/services";

export const createRecordingBegin = createAction("dataImport/CREATE_RECORDING_BEGIN");
export const createRecordingSuccess = createAction("dataImport/CREATE_RECORDING_SUCCESS");
export const createRecordingError = createAction("dataImport/CREATE_RECORDING_ERROR");

export const onClearFailState = createAction("dataImport/ON_CLEAR_FAIL_STATE");
export const onOperatorChange = createAction("dataImport/ON_OPERATOR_CHANGE");
export const onSaveCustomFieldList = createAction("dataImport/ON_SAVE_CUSTOM_FIELD");
export const onEmptyFields = createAction("dataImport/ON_EMPTY_FIELDS");
export const onSaveDeviceData = createAction("dataImport/ON_SAVE_DATA_DATA");
export const onSaveFailCode = createAction("dataImport/ON_SAVE_FAIL_CODE");
export const onSavePassTest = createAction("dataImport/ON_SAVE_PASS_TEST");
export const onSaveSessionId = createAction("dataImport/ON_SAVE_SESSION_ID");
export const onUpdateDateTime = createAction("dataImport/ON_UPDATE_DATETIME");
export const onUpdateIdentifyingInfo = createAction("dataImport/ON_UPDATE_IDENTIFYING_INFO");

export const fetchDeviceInfosBegin = createAction("dataImport/FETCH_DEVICE_INFOS_BEGIN");
export const fetchDeviceInfosSuccess = createAction("deviceHistory/FETCH_DEVICE_INFOS_SUCCESS");
export const fetchDeviceInfosError = createAction("deviceHistory/FETCH_DEVICE_INFOS_ERROR");

export const createRecording = (newRecording) => async (dispatch) => {
  try {
    dispatch(createRecordingBegin());
    const response = await DataCollector.POST(
      "/collect-factory",
      recordingToServerFormat(newRecording)
    );

    const recording = response.payload;

    await dispatch(createRecordingSuccess({ recording }));
    return true;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(createRecordingError({ errors: message }));
    return false;
  }
};

export const updateDateTime = (formattedDateTime) => (dispatch) => {
  dispatch(onUpdateDateTime(formattedDateTime));
};

export const updateOperator = (operator) => (dispatch) => {
  dispatch(onOperatorChange(operator));
};

export const updateIdentiyingInfo = (identifyingInfo) => (dispatch) => {
  dispatch(onUpdateIdentifyingInfo(identifyingInfo));
};

export const savePass = (duration) => (dispatch) => {
  dispatch(onSavePassTest(duration));
};

export const saveFailCode = (failCode) => (dispatch) => {
  dispatch(onSaveFailCode(failCode));
};

export const clearFailState = () => (dispatch) => {
  dispatch(onClearFailState());
};

export const saveCustomFieldList = (customFields) => (dispatch) => {
  dispatch(onSaveCustomFieldList(customFields));
};

export const saveSessionId = (sessionId) => (dispatch) => {
  dispatch(onSaveSessionId(sessionId));
};

export const saveDeviceData = (deviceData) => (dispatch) => {
  dispatch(onSaveDeviceData(deviceData));
};

export const emptyFields = () => (dispatch) => {
  dispatch(onEmptyFields());
};

export const fetchDeviceInfos = (imei) => async (dispatch) => {
  try {
    dispatch(fetchDeviceInfosBegin());
    const query = ModelManagement.gqlBuilder(`{
            models(
                imei: ${imei}
                ) {
                    AsciFields {
                        MarketingName
                        ANumber
                        Colors
                        Storages
                    }
                    HardwarePlatform {
                        Vendor
                        Model
                    }
                    Referencing {
                        MarketingName
                    }
                    Memory {
                        InternalMemory
                    }
                }
            }`);
    const response = await ModelManagement.POST("/gql", { query });

    const models = response.data.models[0];

    dispatch(fetchDeviceInfosSuccess({ models }));

    if (models.length === 0) {
      const message = "IMEI was not found on TAC database";
      dispatch(fetchDeviceInfosError({ errors: message }));
      return message;
    }

    return;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchDeviceInfosError({ errors: message }));
    return message;
  }
};

function recordingToServerFormat(recording = {}) {
  let formatted = {
    json: {
      deviceInfo: {
        deviceColor: recording.deviceData.color,
        imei: recording.imei,
        meid: recording.meid,
        manufacturer: recording.deviceData.manufacturer,
        modelName: recording.deviceData.modelName,
        modelNumber: recording.deviceData.modelNumber,
        storageCapacity: recording.deviceData.capacity,
        wifiMAC: recording.wifiMAC,
        bluetoothMAC: recording.bluetoothMAC,
        oemSerialNumber: recording.OEMSerialNumber,
      },
      process: {
        configurationInformation: {
          program: recording.selectedFilters.program.id,
          serviceSuite: recording.selectedFilters.config.id,
          testSuite: "ManualDeviceTesting",
        },
        customFields: {},
        startDate: recording.processDate.startTime,
        endDate: recording.processDate.endTime,
        operator: recording.operator,
        sessionID: recording.sessionId,
        shiftID: recording.selectedFilters.shift.id,
        shift: recording.selectedFilters.shift.name,
        teamID: recording.selectedFilters.team.teamid,
        team: recording.selectedFilters.team.name,
        time: `${recording.duration}`,
        timeUnits: "s", // hardcoded
      },
      processResult: recording.processResult,
      sender: recording.username,
      senderSource: "MANUAL", // hardcoded

      services: [
        {
          className: "ManualDeviceTesting", // hardcoded
          end: recording.processDate.endTime,
          start: recording.processDate.startTime,
          duration: `${recording.duration}`,
          durationUnits: "s", // hardcoded
          name: "ManualDeviceTesting", // hardcoded
          status: "PASS", // hardcoded
          systemStatus: "PASS", // hardcoded
          userStatus: "PASS", // hardcoded
        },
      ],
      tenantIdentifier: {
        portalCustomerID: recording.csid,
        portalDeploymentID: recording.selectedFilters.deployment.dpid,
        portalFacilityID: recording.selectedFilters.facility.faid,
      },
      tests: [
        {
          className: "ManualDeviceTesting", // hardcoded
          name: "ManualDeviceTesting", // hardcoded
          duration: `${recording.duration}`,
          durationUnits: "s", // hardcoded
          end: recording.processDate.endTime,
          order: 0, // hardcoded
          start: recording.processDate.startTime,
          status: recording.processResult,
          systemStatus: recording.processResult,
          userStatus: recording.processResult,
        },
      ],
    },
  };

  const keys = Object.keys(recording);

  for (const key of keys) {
    const value = recording[key];

    if (key == "processResult" && value == "FAIL") {
      formatted = addFailCodeToTest(formatted, recording);
    }
  }

  if (recording.processResult === "FAIL") {
    const failDetails = formatFailDetails(recording);

    formatted.json.failDetails = failDetails;
  }

  if (recording.customFields.length > 0) {
    const customFields = formatCustomFields(recording);

    formatted.json.process.customFields = customFields;
  }

  return formatted;
}

const addFailCodeToTest = (formatted, recording) => {
  const failCodeList = [];

  const failCode = {
    category: recording.failCode.category,
    code: recording.failCode.name,
    description: recording.failCode.description,
    priority: recording.failCode.priority,
  };

  failCodeList.push(failCode);

  formatted.json.tests.map((test) => (test.failCodes = failCodeList));

  return formatted;
};

const formatFailDetails = (recording) => {
  const { name, ...rest } = recording.failCode;
  const failCode = { ...rest, code: name };

  const failDetails = {
    failReason: {
      ...failCode,
    },
    failCodes: [
      {
        ...failCode,
      },
    ],
  };

  return failDetails;
};

const formatCustomFields = (recording) => {
  const { customFields } = recording;

  const formattedCustomFields = customFields.reduce((acc, customField) => {
    customField.type === "list"
      ? (acc[customField.name] = customField.value.value)
      : (acc[customField.name] = customField.value);

    return acc;
  }, {});

  return formattedCustomFields;
};
