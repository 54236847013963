import React, { useCallback } from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useTranslation } from "~/i18n";

// TODO: remove the size prop when all the icons have been updated to use antd
interface Props extends Partial<CustomIconComponentProps> {
  customId: string;
  size: number;
}

export const SkuManagerIcon = ({ customId, size, style, ...iconProps }: Props) => {
  const { t } = useTranslation("home");

  const SkuManagerSvg = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id={customId}
        viewBox="0 0 33.32 27.41"
        width="1em"
        height="1em"
      >
        <defs>
          <linearGradient
            id={`${customId}-linear-gradient`}
            x1={16.4}
            x2={33.32}
            y1={8.35}
            y2={8.35}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#9c3" />
            <stop offset={0.62} stopColor="#376a53" />
            <stop offset={1} stopColor="#036" />
          </linearGradient>
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-2`}
            x1={0}
            x2={23.12}
            y1={18.86}
            y2={18.86}
          />
        </defs>
        <g id="Version_Number">
          <path
            fill="#003764"
            d="M25.37 10.72c1.11-.23 1.95-1.2 1.95-2.37 0-1.34-1.11-2.43-2.46-2.43-1.23 0-2.26.9-2.44 2.07-.02.12-.17.39-.49.36-.31-.03-.4-.33-.37-.5.25-1.58 1.63-2.8 3.3-2.8 1.84 0 3.34 1.48 3.34 3.3 0 1.59-1.14 2.91-2.66 3.22-.17.03-.4.01-.5-.27-.14-.35.11-.53.33-.58Z"
          />
          <path
            fill={`url(#${customId}-linear-gradient)`}
            d="M32.19 6.67h-.7l-.03-.11c-.16-.56-.38-1.09-.67-1.57l-.06-.09.5-.49a1.09 1.09 0 0 0 0-1.56l-.81-.8c-.21-.21-.5-.32-.81-.32-.3 0-.58.12-.77.32l-.5.49-.1-.06c-.49-.28-1.02-.5-1.59-.65l-.11-.03v-.68c0-.61-.51-1.11-1.13-1.11h-1.14c-.62 0-1.13.5-1.13 1.11v.68l-.11.03c-.57.15-1.11.37-1.59.66l-.09.06-.5-.49c-.2-.2-.49-.32-.8-.32h-.01c-.3 0-.58.12-.77.32l-.81.8c-.21.21-.33.49-.33.78s.12.57.33.78l.49.5-.05.09a6.07 6.07 0 0 0-.66 1.57l-.03.1h-.7c-.62 0-1.13.5-1.13 1.11v.14s0 .45.45.45.45-.33.45-.45v-.14c0-.14.1-.24.24-.24h1.05c.21 0 .39-.14.44-.35.15-.77.45-1.48.89-2.12.12-.17.09-.4-.05-.55l-.74-.74a.246.246 0 0 1-.08-.17c0-.06.03-.12.08-.17l.81-.8s.1-.07.17-.07c.06 0 .12.02.17.07l.75.75c.15.14.38.17.55.05.65-.43 1.37-.73 2.14-.88.21-.04.36-.23.36-.43V1.12c0-.13.11-.24.24-.24h1.14c.13 0 .24.11.24.24v1.04c0 .21.15.39.35.42.78.16 1.5.45 2.14.88.18.11.41.09.56-.05l.74-.74s.11-.07.17-.07.13.02.17.07l.8.79c.05.05.07.12.07.18s-.03.12-.08.17l-.74.73c-.15.14-.17.38-.05.54.44.65.74 1.36.89 2.12.04.2.22.35.44.35h1.05c.13 0 .24.11.24.24v1.13c0 .13-.11.24-.24.24h-1.05c-.21 0-.4.15-.43.35-.16.77-.46 1.49-.9 2.12a.45.45 0 0 0 .05.55l.75.73s.07.1.07.17-.03.12-.07.17l-.81.8s-.11.07-.17.07-.13-.02-.17-.07l-.74-.73a.426.426 0 0 0-.55-.05c-.65.43-1.38.73-2.15.88a.44.44 0 0 0-.35.43v1.04c0 .13-.11.24-.24.24h-.11s-.42.02-.42.43.44.42.44.42h.08c.62 0 1.13-.5 1.13-1.11v-.69l.11-.03c.57-.15 1.11-.37 1.59-.65l.1-.05.49.49c.44.42 1.16.42 1.59 0l.81-.8c.21-.22.33-.49.33-.78 0-.3-.12-.58-.33-.78l-.5-.49.06-.09c.29-.48.51-1.01.67-1.57l.03-.1h.7c.62 0 1.13-.5 1.13-1.11V7.82c0-.61-.51-1.11-1.13-1.11Z"
          />
          <rect
            width={22.12}
            height={16.09}
            x={0.5}
            y={10.82}
            fill="none"
            stroke={`url(#${customId}-linear-gradient-2)`}
            strokeMiterlimit={10}
            rx={2}
            ry={2}
          />
          <path
            d="M4.68 14.89v7.92M6.54 14.89v6.05M8.25 14.89v6.05M10.1 14.89v6.05M11.96 14.89v7.92M13.84 14.89v6.05M16.45 14.89v6.05M18.44 14.89v7.92"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
      </svg>
    ),
    [customId]
  );

  return (
    <Icon
      title={t("modules.sku-manager-title")}
      component={SkuManagerSvg}
      style={{ ...style, fontSize: size }}
      {...iconProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};
