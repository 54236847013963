import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  items: [],
  sortKey: "",
  sortReverse: false,
  error: null,
  isLoading: false,
  initialized: false,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [moduleActions.customerFacilities.fetchCustomerFacilitiesBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.customerFacilities.fetchCustomerFacilitiesSuccess]: (state, action) => {
    state.items = action.payload.facilities;
    state.isLoading = false;
    state.errors = null;
    state.initialized = true;
  },
  [moduleActions.customerFacilities.fetchCustomerFacilitiesError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
    state.initialized = true;
  },

  [moduleActions.customerFacilities.updateCustomerFacilities]: (state, action) => {
    state.items = action.payload.facilities;
  },
  [moduleActions.customerFacilities.saveFacilitiesSuccess]: (state) => {
    state.isLoading = false;
  },
  [moduleActions.customerFacilities.saveFacilitiesBegin]: (state) => {
    state.error = null;
    state.isLoading = true;
  },
  [moduleActions.customerFacilities.saveFacilitiesError]: (state, action) => {
    state.error = action.payload.message;
    state.isLoading = false;
  },
  [moduleActions.customerFacilities.updateKey]: (state, action) => {
    state.sortKey = action.payload.key;
  },
  [moduleActions.customerFacilities.updateSortReverse]: (state, action) => {
    state.sortReverse = action.payload.sortReverse;
  },

  [moduleActions.customerEditor.resetCustomerEdition]: (state) => {
    state.items = [];
    state.errors = null;
    state.sortKey = "faid";
    state.sortReverse = false;
    state.isLoading = false;
    state.initialized = false;
  },
});
