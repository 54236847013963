import React from "react";
import PropTypes from "prop-types";

const FreeformInspector = (props) => <div />;

FreeformInspector.defaultProps = {};

FreeformInspector.propTypes = {};

export default FreeformInspector;
