import React from "react";
import PropTypes from "prop-types";
import { DialogContainer } from "react-md";
import classnames from "classnames";

const Page = ({ children, className, dialog, onDialogHide }) => (
  <div className={classnames("panel-container", className)}>
    {dialog ? (
      <DialogContainer
        id="page-dialog"
        visible
        modal={dialog.modal}
        title={dialog.title}
        width={dialog.width}
        onHide={onDialogHide}
        focusOnMount={!!dialog.focusOnMount}
      >
        {dialog.content}
      </DialogContainer>
    ) : null}

    {children}
  </div>
);

Page.propTypes = {
  className: PropTypes.string,
  dialog: PropTypes.object,
  onDialogHide: PropTypes.func,
};

Page.defaultProps = {
  className: "",
  dialog: null,
  onDialogHide: () => {},
};

export default Page;
