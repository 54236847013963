import { createReducer } from "@reduxjs/toolkit";

import labelActions from "~/label-printer/actions";

const initialState = {
  loading: false,
  loaded: false,
  labels: [],
  error: null,
};

export default createReducer(initialState, {
  [labelActions.labelLister.fetchLabelsBegin]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
    state.labels = [];
  },
  [labelActions.labelLister.fetchLabelsSuccess]: (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.labels = action.payload;
  },
  [labelActions.labelLister.fetchLabelsError]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
});
