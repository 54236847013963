import React, { useState, useRef } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";

import { AsciButton, DialogMessage } from "~/global";
import { formatDate } from "~/global/utils";
import CertificateDataWipe from "~/device-history/components/PrintableInformation/CertificateDataWipe.component";
import CertificateDiagnostic from "~/device-history/components/PrintableInformation/CertificateDiagnostic.component";
import SessionInformationContainer from "~/device-history/components/SessionInformationContainer/SessionInformationContainer.component";
import LabelPrinterButton from "~/label-printer/components/LabelPrinterButton";

const PrintableInformation = ({
  customFieldNames,
  customFields,
  sessionId,
  sessionSelected,
  showDialog,
  showSessionsList,
  showWipeCertificate,
}) => {
  const reportStyle = "@page {size: auto; margin :2cm;}";
  const pageLandscape = "@page {size: landscape; margin: 2cm;}";
  const [documentTitle, _] = useState(document.title);

  const sessionList = useRef();
  const deviceInformation = useRef();
  const wipeCertificate = useRef();
  const diagnosticCertificate = useRef();

  const today = formatDate(moment());
  const havesServices = sessionSelected.services != null && sessionSelected.services.length > 0;
  const havesTests = sessionSelected.tests != null && sessionSelected.tests.length > 0;
  const diagnosticReady = !!(havesServices || havesTests);

  const missingData = (message) => {
    showDialog({
      title: "Warning",
      width: "calc(50% - 100px)",
      modal: true,
      content: (close) => (
        <DialogMessage close={() => close()}>
          <div>{message}</div>
        </DialogMessage>
      ),
    });
  };
  const crossSystemId = sessionSelected.process.crossSystemId?.split("|")[0] || "";
  const renderCertificateButton = () => {
    const isProd = window.location.href.includes("https://client.assetscience.com/");
    const isStaging = window.location.href.includes("https://client-staging.asci.io/");
    const hasCrossSystemId = crossSystemId != null;
    const verifiedOrigin = isProd
      ? "https://verified.assetscience.com"
      : "https://verified-qa.assetscience.com";
    return (
      !isStaging &&
      hasCrossSystemId && (
        <AsciButton
          color="white"
          disabled={!diagnosticReady}
          onClick={() => {
            window.open(`${verifiedOrigin}/certificate/${crossSystemId}`);
          }}
        >
          <div className="row centered">
            <i className="icon-external-link" />
            <span className="margin-left--5">Public Certificate</span>
          </div>
        </AsciButton>
      )
    );
  };

  const renderWipeButton = () =>
    showWipeCertificate && (
      <ReactToPrint
        bodyClass="printable-page"
        content={() => wipeCertificate.current}
        onAfterPrint={() => (document.title = documentTitle)}
        onBeforePrint={() => (document.title = `data_wipe_certificate_${sessionId}_${today}`)}
        pageStyle={reportStyle}
        trigger={() => (
          <AsciButton color="white">
            <div className="row centered">
              <i className="icon-printer" />
              <span className="margin-left--5">Data Wipe Certificate</span>
            </div>
          </AsciButton>
        )}
      />
    );

  const renderDiagnosticButton = () => {
    if (diagnosticReady) {
      return (
        <ReactToPrint
          bodyClass="printable-page"
          content={diagnosticReady ? () => diagnosticCertificate.current : null}
          onAfterPrint={() => (document.title = documentTitle)}
          onBeforePrint={() => (document.title = `pre_wipe_certificate_${sessionId}_${today}`)}
          pageStyle={reportStyle}
          trigger={() => (
            <AsciButton ref={React.createRef()} color="white" disabled={!diagnosticReady}>
              <div className="row centered">
                <i className="icon-printer" />
                <span className="margin-left--5">Diagnostics Certificate</span>
              </div>
            </AsciButton>
          )}
        />
      );
    }

    return (
      <AsciButton color="white" onClick={() => missingData("Tests and/or services required")}>
        <div className="row centered">
          <i className="icon-printer" />
          <span className="margin-left--5">Diagnostics Certificate</span>
        </div>
      </AsciButton>
    );
  };

  const renderPrintButton = () => (
    <ReactToPrint
      bodyClass="printable-page"
      content={showSessionsList ? () => sessionList.current : () => deviceInformation.current}
      onAfterPrint={() => (document.title = documentTitle)}
      onBeforePrint={() => (document.title = `Sessions_List_${today}`)}
      pageStyle={showSessionsList ? pageLandscape : reportStyle}
      trigger={() => (
        <AsciButton color="white">
          <div className="row centered">
            <i className="icon-printer" />
            <span className="margin-left--5">
              {showSessionsList ? "Print Default Columns" : "Print Report"}
            </span>
          </div>
        </AsciButton>
      )}
    />
  );

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", rowGap: 10 }}>
        {!showSessionsList ? renderCertificateButton() : null}
        {!showSessionsList ? renderWipeButton() : null}
        {!showSessionsList ? renderDiagnosticButton() : null}

        {renderPrintButton()}
        {!showSessionsList && <LabelPrinterButton recording={sessionSelected} />}
      </div>
      <div className="no-printable fade-in">
        <div className="only-printable">
          {!showSessionsList ? (
            <div>
              <div ref={wipeCertificate}>
                <CertificateDataWipe
                  process={sessionSelected.process}
                  deviceInfo={sessionSelected.deviceInfo}
                  desktopInformation={sessionSelected.desktopInformation}
                  processResult={sessionSelected.processResult}
                  services={sessionSelected.services}
                />
              </div>

              <div ref={diagnosticCertificate}>
                <CertificateDiagnostic
                  process={sessionSelected.process}
                  deviceInfo={sessionSelected.deviceInfo}
                  desktopInformation={sessionSelected.desktopInformation}
                  processResult={sessionSelected.processResult}
                  services={sessionSelected.services}
                  tests={sessionSelected.tests}
                />
              </div>

              <div ref={deviceInformation}>
                <div>{today}</div>
                <SessionInformationContainer
                  sessionSelected={sessionSelected}
                  customFields={customFields}
                  customFieldNames={customFieldNames}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PrintableInformation;
