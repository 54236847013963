import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InspectorSection from "~/label-printer/components/designer/inspector/commons/InspectorSection";
import ShapeGridInspector from "~/label-printer/components/designer/inspector/layouts/ShapeGridInspector";
import ShapeFreeformInspector from "~/label-printer/components/designer/inspector/layouts/ShapeFreeformInspector";

import labelSelectors from "~/label-printer/selectors";
import * as layouts from "~/label-printer/layouts";

const ShapeLayoutInspector = (props) => {
  const { layoutName, layoutData, identity } = props;
  const content = useMemo(() => {
    switch (layoutName) {
      case layouts.GRID:
        return <ShapeGridInspector identity={identity} layoutData={layoutData} />;
      case layouts.FREEFORM:
        return <ShapeFreeformInspector identity={identity} layoutData={layoutData} />;
    }
  }, []);

  return (
    <InspectorSection title="Layout" level={1} initialFolded>
      {content}
    </InspectorSection>
  );
};

ShapeLayoutInspector.defaultProps = {};

ShapeLayoutInspector.propTypes = {
  identity: PropTypes.number,
  layoutName: PropTypes.string,
  layoutData: PropTypes.object,
};

const stateToProps = (state) => ({
  layoutName: labelSelectors.labelDesigner.getLayoutName(state),
  layoutData: labelSelectors.labelDesigner.getLayoutData(state),
});

export default connect(stateToProps, null)(ShapeLayoutInspector);
