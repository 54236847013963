import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchCustomersBegin = createAction("FETCH_CUSTOMERS_BEGIN");
export const fetchCustomersSuccess = createAction("FETCH_CUSTOMERS_SUCCESS");
export const fetchCustomersError = createAction("FETCH_CUSTOMERS_ERROR");

export const updateCustomersBegin = createAction("UPDATE_CUSTOMERS_BEGIN");
export const updateCustomersSuccess = createAction("UPDATE_CUSTOMERS_SUCCESS");
export const updateCustomersError = createAction("UPDATE_CUSTOMERS_ERROR");
export const resetCustomersEdition = createAction("RESET_CUSTOMERS_EDITION");

export const deleteCustomerBegin = createAction("DELETE_CUSTOMER_BEGIN");
export const deleteCustomerSuccess = createAction("DELETE_CUSTOMER_SUCCESS");
export const deleteCustomerError = createAction("DELETE_CUSTOMER_ERROR");

export const onResetCustomersEdition = () => resetCustomersEdition();

export const fetchCustomers = () => async (dispatch) => {
  try {
    dispatch(fetchCustomersBegin());

    const query = CustomerManagement.gqlBuilder(`query Customers {
            customers {
                csid,
                name,
                logo,
                reseller,
                autoAssignToAllTeams,
                processNames,
                processFlows,
                isArchived
            }
        }`);

    const response = await CustomerManagement.QUERY(query);
    const customers = (response.payload.data || {}).customers || [];

    dispatch(fetchCustomersSuccess({ customers }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchCustomersError({ errors: e }));
  }
};

export const addCustomer = (newCustomer) => async (dispatch, getState) => {
  const state = getState();
  const currentCustomers = state.customers.items;
  const customers = [].concat(currentCustomers, newCustomer);

  try {
    dispatch(updateCustomersBegin());
    const response = await CustomerManagement.POST(`/customers`, newCustomer);
    dispatch(updateCustomersSuccess({ customers }));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateCustomersError({ errors: message }));
  }
};

export const deleteCustomer = (csid) => async (dispatch) => {
  try {
    dispatch(deleteCustomerBegin());
    await CustomerManagement.DELETE(`/customer/${csid}`);
    dispatch(deleteCustomerSuccess());
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? `Server responded with: ${e.json.message}`
        : "Request didn't complete successfully";
    dispatch(deleteCustomerError({ errors: message }));
    throw new Error(message);
  }
};
