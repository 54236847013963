import produce from "immer";
import { without } from "lodash/array";

import {
  CHECK_FAIL_CODE_CATEGORIES,
  FETCH_FAIL_CODE_CATEGORIES_BEGIN,
  FETCH_FAIL_CODE_CATEGORIES_ERROR,
  FETCH_FAIL_CODE_CATEGORIES_SUCCESS,
  DELETE_FAIL_CODE_CATEGORIES_BEGIN,
  DELETE_FAIL_CODE_CATEGORIES_SUCCESS,
  DELETE_FAIL_CODE_CATEGORIES_ERROR,
  CLEAR_FAIL_CODE_CATEGORIES_ERRORS,
  SET_FAIL_CODES_CATEGORY_NAME_FILTER,
} from "~/actions/failCodes/failCodeCategories.actions";

import {
  ADD_FAIL_CODE_CATEGORIES_BEGIN,
  ADD_FAIL_CODE_CATEGORIES_ERROR,
  ADD_FAIL_CODE_CATEGORIES_SUCCESS,
  UPDATE_FAIL_CODE_CATEGORY_BEGIN,
  UPDATE_FAIL_CODE_CATEGORY_ERROR,
  UPDATE_FAIL_CODE_CATEGORY_SUCCESS,
} from "~/actions/failCodes/failCodeCategory.actions";

const initialState = {
  errors: null,
  isLoading: true,
  items: [],
  itemsCheckedIds: [],
  nameFilter: "",
};

export default function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_FAIL_CODE_CATEGORIES_BEGIN: {
        draft.items = state.items;
        draft.itemsCheckedIds = [];
        draft.isLoading = true;
        draft.errors = null;
        return;
      }

      case FETCH_FAIL_CODE_CATEGORIES_ERROR: {
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case FETCH_FAIL_CODE_CATEGORIES_SUCCESS: {
        draft.items = action.payload.failCodeCategories;
        draft.isLoading = false;
        return;
      }

      case ADD_FAIL_CODE_CATEGORIES_BEGIN: {
        draft.items.push(action.payload.placeholder);
        draft.errors = null;
        draft.isLoading = true;
        return;
      }

      case ADD_FAIL_CODE_CATEGORIES_SUCCESS: {
        const { failCodeCategory } = action.payload;
        draft.items = state.items.filter((item) => item.id != "placeholder");
        draft.items.push(failCodeCategory);
        draft.isLoading = false;
        return;
      }

      case ADD_FAIL_CODE_CATEGORIES_ERROR: {
        draft.items = state.items.filter((item) => item.id != "placeholder");
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case CHECK_FAIL_CODE_CATEGORIES: {
        draft.itemsCheckedIds = action.payload.failCodeCategoryIds;
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_BEGIN: {
        draft.isLoading = true;
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_SUCCESS: {
        const { failCodeCategory } = action.payload;
        for (let i = 0; i < state.items.length; i++) {
          const item = state.items[i];
          if (item.id === failCodeCategory.id) {
            draft.items[i] = failCodeCategory;
            draft.isLoading = false;
            break;
          }
        }
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_ERROR: {
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case DELETE_FAIL_CODE_CATEGORIES_BEGIN: {
        draft.isLoading = true;
        return;
      }

      case DELETE_FAIL_CODE_CATEGORIES_SUCCESS: {
        const failCodeCategoryIds = action.payload.ids;
        const itemsToDelete = state.items.filter(
          (item) => failCodeCategoryIds.indexOf(item.id) > -1
        );
        draft.items = without(state.items, ...itemsToDelete);
        draft.isLoading = false;
        draft.itemsCheckedIds = [];
        return;
      }

      case DELETE_FAIL_CODE_CATEGORIES_ERROR: {
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case CLEAR_FAIL_CODE_CATEGORIES_ERRORS: {
        draft.errors = null;
        return;
      }

      case SET_FAIL_CODES_CATEGORY_NAME_FILTER: {
        draft.nameFilter = action.payload.name;
      }
    }
  });
}
