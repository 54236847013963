import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const RemoteDiagnosticsIcon = ({ size, customId }) => (
  <SVGIcon title="Remote Diagnostics" viewBox="0 0 34.94 26.37" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x2="20.71"
        y1="10.36"
        y2="10.36"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x1="5.97"
        x2="20.68"
        y1="13.32"
        y2="13.32"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-c`}
        x1="11.93"
        x2="28.81"
        y1="20.07"
        y2="20.07"
        xlinkHref={`#${customId}-a`}
      />
    </defs>
    <g>
      <path
        fill={`url(#${customId}-a)`}
        d="M.5 20.71a.5.5 0 0 1-.5-.5A20.24 20.24 0 0 1 20.21 0a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5A19.23 19.23 0 0 0 1 20.21a.5.5 0 0 1-.5.5z"
      />
      <path
        fill={`url(#${customId}-b)`}
        d="M6.46 20.68a.49.49 0 0 1-.46-.49A14.24 14.24 0 0 1 20.19 6a.49.49 0 1 1 0 1A13.27 13.27 0 0 0 6.94 20.19a.49.49 0 0 1-.48.49z"
      />
      <path
        fill={`url(#${customId}-c)`}
        d="M20.37 28.66a8.8 8.6 0 0 1 0-17.19 8.31 8.31 0 0 1 2.93.53.5.5 0 0 1 .29.65.49.49 0 0 1-.64.29 7.28 7.28 0 0 0-2.58-.47 7.6 7.6 0 1 0 7.44 7.87.51.51 0 0 1 .52-.48.5.5 0 0 1 .48.51 8.48 8.48 0 0 1-8.44 8.29z"
      />
      <path
        fill="#93d500"
        d="M21.68 22.74a.48.48 0 0 1-.36-.16l-4.42-4.66a.5.5 0 1 1 .73-.69l4 4.26L31.79 9.17a.5.5 0 0 1 .78.64l-10.5 12.75a.49.49 0 0 1-.37.18z"
      />
    </g>
  </SVGIcon>
);

RemoteDiagnosticsIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default RemoteDiagnosticsIcon;
