import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Cell, DataTable, TableBody, TableColumn, TableRow } from "react-md";

import { SectionHeader } from "~/global";
import DeviceServices from "~/device-history/components/DeviceServices/DeviceServices.component";
import DeviceTests from "~/device-history/components/DeviceTests/DeviceTests.component";

export default class CertificateDiagnosticBody extends Component {
  static propTypes = {
    process: PropTypes.object.isRequired,
    deviceInfo: PropTypes.object.isRequired,
    desktopInformation: PropTypes.object.isRequired,
    processResult: PropTypes.string.isRequired,
    services: PropTypes.array.isRequired,
    tests: PropTypes.array,
  };

  static defaultProps = {
    tests: [],
  };

  render() {
    return (
      <div className="certificate-body">
        <div className="certificate-main-info">
          <SectionHeader className="certificate-header" title="Device Information" />

          <Grid noSpacing>
            <Cell size={6}>
              <DataTable
                className="cc-table full-width"
                baseId="certificate-device-info-table-1"
                plain
              >
                <TableBody>
                  <TableRow>
                    <TableColumn>Device Model</TableColumn>
                    <TableColumn>{this.props.deviceInfo.modelName || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Model Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.modelNumber || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Manufacturer</TableColumn>
                    <TableColumn>{this.props.deviceInfo.manufacturer || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>IMEI</TableColumn>
                    <TableColumn>{this.props.deviceInfo.IMEI || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>GUID</TableColumn>
                    <TableColumn>{this.props.deviceInfo.GUID || "N/A"}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>

            <Cell size={6}>
              <DataTable
                className="cc-table full-width"
                baseId="certificate-device-info-table-2"
                plain
              >
                <TableBody>
                  <TableRow>
                    <TableColumn>OEM Serial Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.OEMSerialNumber || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>WI-FI MAC Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.wifiMAC || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Storage Capacity</TableColumn>
                    <TableColumn>{this.props.deviceInfo.storageCapacity || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>MEID</TableColumn>
                    <TableColumn>{this.props.deviceInfo.MEID || "N/A"}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>
          </Grid>

          <SectionHeader className="certificate-header" title="Process Details" />

          <Grid noSpacing>
            <Cell size={6}>
              <DataTable className="cc-table full-width" baseId="certificate-process-table-1" plain>
                <TableBody>
                  <TableRow>
                    <TableColumn>Session ID</TableColumn>
                    <TableColumn>{this.props.process.sessionIDHumanReadable || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>DSE Software Version</TableColumn>
                    <TableColumn>
                      {this.props.desktopInformation.softwareInformation.dseVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Operator Name</TableColumn>
                    <TableColumn>{this.props.process.operator || "N/A"}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>

            <Cell size={6}>
              <DataTable className="cc-table full-width" baseId="certificate-process-table-2" plain>
                <TableBody>
                  <TableRow>
                    <TableColumn>Process Result</TableColumn>
                    <TableColumn>{this.props.processResult || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Software Bundle Version</TableColumn>
                    <TableColumn>
                      {this.props.desktopInformation.softwareInformation.bundleVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>
          </Grid>
        </div>

        <DeviceServices services={this.props.services} certificate />

        {this.props.tests != null && this.props.tests.length > 0 ? (
          <DeviceTests tests={this.props.tests} certificate />
        ) : null}
      </div>
    );
  }
}
