import React from "react";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

const SessionStatus = (props) => {
  const { status } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FontIcon
        iconClassName="icon-circle"
        style={{ color: status === "online" ? "green" : "red" }}
      />
      &nbsp;{status}
    </div>
  );
};

SessionStatus.defaultProps = {};

SessionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default SessionStatus;
