import { createReducer } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import {
  fetchReportTypesBegin,
  fetchReportTypesError,
  fetchReportTypesSuccess,
  ReportType,
} from "~/actions/reportTypes.actions";

interface ReportTypesState {
  items?: ReportType[];
  error?: string;
  status: "idle" | "loading" | "error";
}

const initialState: ReportTypesState = {
  status: "idle",
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchReportTypesBegin, (draftState) => {
      delete draftState.error;
      draftState.status = "loading";
    })
    .addCase(fetchReportTypesSuccess, (draftState, action) => {
      draftState.items = action.payload;

      draftState.status = "idle";
    })
    .addCase(fetchReportTypesError, (draftState) => {
      draftState.status = "error";
    });
});
