import React from "react";
import PropTypes from "prop-types";

import { AppButton } from "~/global";

import "./FeatureTitle.component.scss";

const FeatureTitle = ({ appName, title }) => (
  <div className="feature-title layout-row layout-row--center-center flex">
    <AppButton appName={appName} buttonId={`header${appName}`} size={22} key={`header${appName}`} />

    <div className="feature-text">{title}</div>
  </div>
);

FeatureTitle.propTypes = {
  title: PropTypes.string,
  appName: PropTypes.string,
};

FeatureTitle.defaultProps = {
  title: "",
  appName: "",
};

export default FeatureTitle;
