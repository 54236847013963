import { createSelector } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";

const getCustomerFacilityOriginal = (state) =>
  state.modules.customerManagement.customerFacilityEditor.original;
const getCustomerFacilityModified = (state) =>
  state.modules.customerManagement.customerFacilityEditor.modifications;
const getCustomerFacilityNameError = (state) =>
  state.modules.customerManagement.customerFacilityEditor.nameError;
const getCustomerFacilityAddressError = (state) =>
  state.modules.customerManagement.customerFacilityEditor.addressError;
const getCustomerFacilityCityError = (state) =>
  state.modules.customerManagement.customerFacilityEditor.cityError;
const getCustomerFacilityPostalCodeError = (state) =>
  state.modules.customerManagement.customerFacilityEditor.postalCodeError;
const getCountries = (state) => state.countries.items;

export const getUpdatedCustomerFacility = createSelector(
  [getCustomerFacilityOriginal, getCustomerFacilityModified],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getFacilityName = createSelector([getUpdatedCustomerFacility], (customerFacility) =>
  customerFacility.name != null ? customerFacility.name : ""
);

export const getFacilityCountry = createSelector([getUpdatedCustomerFacility], (customerFacility) =>
  customerFacility.country != null ? customerFacility.country : ""
);

export const getFacilityState = createSelector([getUpdatedCustomerFacility], (customerFacility) =>
  customerFacility.state != null ? customerFacility.state : ""
);

export const getFacilityPostalCode = createSelector(
  [getUpdatedCustomerFacility],
  (customerFacility) => (customerFacility.postalcode != null ? customerFacility.postalcode : "")
);

export const getFacilityAddress = createSelector([getUpdatedCustomerFacility], (customerFacility) =>
  customerFacility.address != null ? customerFacility.address : ""
);

export const getFacilityCity = createSelector([getUpdatedCustomerFacility], (customerFacility) =>
  customerFacility.city != null ? customerFacility.city : ""
);

export const getFacilityTimezone = createSelector(
  [getUpdatedCustomerFacility],
  (customerFacility) => (customerFacility.timezone != null ? customerFacility.timezone : "")
);

export const getCountriesByName = createSelector(getCountries, (countries = []) =>
  countries.reduce(
    (acc, country) => ({
      ...acc,
      [country.name]: country,
    }),
    {}
  )
);

export const getCountryMetadata = createSelector(
  [getCountriesByName, getFacilityCountry],
  (countriesByName, country) => {
    const emptyCountry = {
      code: "",
      name: country,
      states: [],
    };
    return countriesByName[country] || emptyCountry;
  }
);

export const getCountryStates = createSelector([getCountryMetadata], (countryMetadata) =>
  countryMetadata.states.map((state) => state.name || "")
);

// VALIDATION: to be included for each tab
export const getCustomerFacilityIsValid = createSelector(
  [
    getCustomerFacilityNameError,
    getCustomerFacilityAddressError,
    getCustomerFacilityCityError,
    getCustomerFacilityPostalCodeError,
  ],
  (nameError, addressError, cityError, postalCodeError) =>
    !nameError && !cityError && !addressError && !postalCodeError
);

export const getCustomerFacilityIsEqual = createSelector(
  [getUpdatedCustomerFacility, getCustomerFacilityOriginal],
  (modified, original) => {
    const modifiedTrimed = {
      ...modified,
      name: modified.name != null ? modified.name.trim() : "",
    };
    const originalTrimed = {
      ...original,
      name: original.name != null ? original.name.trim() : "",
    };
    return isEqual(modifiedTrimed, originalTrimed);
  }
);

export const getRequiredComplete = createSelector(
  [getFacilityName, getFacilityCity],
  (name, city) => name.length > 0 && city.length > 0
);

export const getCanSaveCustomerFacility = createSelector(
  [getCustomerFacilityIsValid, getCustomerFacilityIsEqual, getRequiredComplete],
  (customerFacilityIsValid, customerFacilityIsEqual, requiredComplete) =>
    customerFacilityIsValid && !customerFacilityIsEqual && requiredComplete
);
