import { createSelector } from "@reduxjs/toolkit";
import { chain, find, sortBy, without } from "lodash";

import { getFailCodesById } from "./failCodes.selectors";
import { getSelectedPlatform } from "./platforms.selectors";
import { getSelectedTestClassName, getSortedTests } from "./tests.selectors";

export const getTestDefinitions = (state) => state.testDefinitions.items;

const sortTestConfigs = (configs) => {
  const sorted = sortBy(configs, (definition) => definition.identifierDisplayName);
  const defaultConfig = find(sorted, (config) => config.className === config.identifier);

  // move default to first position
  if (defaultConfig) {
    return [defaultConfig, ...without(sorted, defaultConfig)];
  }
  return sorted;
};

export const getPlatformTestDefinitions = createSelector(
  [getTestDefinitions, getSelectedPlatform],
  (testDefinitions, platform) => testDefinitions.filter((def) => def.platform === platform.value)
);

export const getTestDefinitionOptions = createSelector(
  [getPlatformTestDefinitions],
  (testDefinitions) => {
    const testDefinitionOptions = chain(testDefinitions)
      .sortBy("identifierDisplayName")
      .groupBy("displayName")
      .map((definitions, displayName) => ({
        label: displayName,
        options: definitions.map(({ identifierDisplayName, identifier, className }) => ({
          label:
            identifier === className ? `${identifierDisplayName} (default)` : identifierDisplayName,
          value: identifier,
        })),
      }))
      .sortBy("label")
      .value();

    return testDefinitionOptions;
  }
);

export const getSortedDefinitions = createSelector(
  [getTestDefinitions, getSelectedTestClassName, getSelectedPlatform],
  (definitions, className, platform) => {
    // only definitions of given class name and platform
    const filteredDefinitions = definitions.filter(
      (definition) => definition.className === className && definition.platform === platform.value
    );

    return sortTestConfigs(filteredDefinitions);
  }
);

export const getArgumentsDetailsByName = createSelector(
  [(state) => state.tests.selected.arguments],
  (argDetails = []) =>
    argDetails.reduce((acc, item) => {
      acc[item.name] = item;
      return acc;
    }, {})
);

export const getTestsWithConfigs = createSelector(
  [getSortedTests, getTestDefinitions, getSelectedPlatform, getFailCodesById],
  (tests = [], configs = [], platform = {}, failCodesById = {}) =>
    tests.map((test) => ({
      ...test,
      configs: sortTestConfigs(
        configs.filter(
          (config) => config.platform === platform.value && config.className === test.className
        )
      ).map((config) => ({
        ...config,
        failCodeNames: config.failCodes.map((id) => (failCodesById[id] || {}).name),
      })),
    }))
);

export const getDefinitionsByPlatformByClassName = createSelector(
  [getTestDefinitions],
  (definitions) => {
    const platforms = {};

    definitions.forEach((definition) => {
      // create the platform if it doesn't exist yet
      if (!platforms[definition.platform]) {
        platforms[definition.platform] = {};
      }

      // create the array of definitions for this className if it doesn't exist yet for this platform
      if (!platforms[definition.platform][definition.className]) {
        platforms[definition.platform][definition.className] = [];
      }

      platforms[definition.platform][definition.className].push(definition);
    });

    return platforms;
  }
);
