import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";

import { toMM } from "~/label-printer/styling";

const FreeformLayout = (props) => {
  const { shapes, selectedField, width, height } = props;

  return (
    <div className="freeform-layout">
      {shapes.map((e) => {
        ``;
      })}
    </div>
  );
};

FreeformLayout.defaultProps = {};

FreeformLayout.propTypes = {
  shapes: PropTypes.array,

  selectedField: PropTypes.string,

  selectShape: labelActions.labelPicker,
};

const stateToProps = (state) => ({
  shapes: labelSelectors.labelDesigner.getShapes(state),
});

const dispatchToProps = {
  selectShape: labelActions.labelPicker.selectShape,
};

export default connect(stateToProps, dispatchToProps)(FreeformLayout);
