import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";
import classnames from "classnames";

import TestCategoriesList from "../TestCategoriesList/TestCategoriesList.component";
import { TestDefinitionIcon, Column, Row } from "~/global";
import { DeviceTestDefinition } from "~/models";

import "./TestDefinitionsSimpleView.component.scss";

const TestDefinitionsSimpleView = ({
  categories,
  selectedCategory,
  tests,
  onCategoryChanged,
  onConfigClicked,
}) => {
  const [selectedConfigByClassName, setSelectedConfigByClassName] = useState({});
  const [expandedByClassName, setExpandedByClassName] = useState({});

  const onConfigChanged = (config) => {
    setSelectedConfigByClassName({
      ...selectedConfigByClassName,
      [config.className]: config.identifier,
    });
  };

  const getSelectedConfig = (configs = [], identifier) =>
    configs.filter((config) => config.identifier === identifier)[0] || configs[0] || {};

  const getDisplayedConfigs = (configs = [], isExpanded) => {
    if (isExpanded || configs.length <= 2) {
      return configs;
    }

    return configs.slice(0, 2);
  };

  const getDisplayedFailCodes = (failCodeNames = [], isExpanded) => {
    if (isExpanded || failCodeNames.length <= 2) {
      return failCodeNames;
    }

    return failCodeNames.slice(0, 2);
  };

  const getDisplayedModels = (models = [], isExpanded) => {
    if (isExpanded || models.length <= 2) {
      return models;
    }

    return models.slice(0, 2);
  };

  const toggleShowMore = (config) => {
    setExpandedByClassName({
      ...expandedByClassName,
      [config.className]: !expandedByClassName[config.className],
    });
  };

  return (
    <Column className="test-definitions-simple-view" flex>
      <Row className="selection-columns" flex>
        <Column className="categories-container flex--noshrink">
          <h2 className="truncate">Test Categories</h2>
          <TestCategoriesList
            categories={categories}
            selectedCategory={selectedCategory}
            onSelect={(category) => onCategoryChanged && onCategoryChanged(category)}
          />
        </Column>

        <Column className="tests-container flex--noshrink">
          <h2 className="truncate">Tests</h2>

          <div className="column-headers layout-row">
            <div className="col-icon" />
            <div className="col-name">Test</div>
            <div className="col-configs">Test Configurations</div>
            <div className="col-fail-codes">Fail Code</div>
            <div className="col-inclusions">Inclusion / Exclusion</div>
            <div className="col-arrow" />
          </div>

          <div className="test-rows">
            {tests.map((test, index) => {
              const selectedConfig = getSelectedConfig(
                test.configs,
                selectedConfigByClassName[test.className]
              );
              const include =
                selectedConfig.inclusionType === DeviceTestDefinition.INCLUSION_TYPE_INCLUDE;
              const exclude =
                selectedConfig.inclusionType === DeviceTestDefinition.INCLUSION_TYPE_EXCLUDE;
              const canExpand =
                selectedConfig.failCodeNames?.length > 2 ||
                selectedConfig.targetModels?.length > 2 ||
                test.configs.length > 2;
              const isExpanded = !!expandedByClassName[selectedConfig.className];
              const testConfigs = getDisplayedConfigs(test.configs, isExpanded);
              const failCodeNames = getDisplayedFailCodes(selectedConfig.failCodeNames, isExpanded);
              const models = getDisplayedModels(selectedConfig.targetModels, isExpanded);

              return (
                <div
                  className={classnames("simple-test-row layout-row", {
                    "can-expand": canExpand,
                    expanded: isExpanded,
                  })}
                  key={index}
                >
                  {canExpand ? (
                    <div className="toggle-expand" onClick={() => toggleShowMore(selectedConfig)}>
                      {isExpanded ? "Collapse" : "Show More"}
                    </div>
                  ) : null}

                  <div className="col-icon">
                    <TestDefinitionIcon src={test.image} size="40" />
                  </div>

                  <div className="col-name">{test.displayName}</div>

                  <div className="col-configs">
                    {testConfigs.map((config) => (
                      <Row
                        className="test-configs"
                        key={config.identifierDisplayName}
                        onClick={() => onConfigChanged(config)}
                        align="space-between center"
                      >
                        <div className="ellipsis">
                          {config.identifier === config.className
                            ? "Default"
                            : config.identifierDisplayName}
                        </div>

                        <FontIcon iconClassName="icon-chevron-right" />
                      </Row>
                    ))}
                  </div>

                  <div className="col-fail-codes">
                    {failCodeNames.length > 0
                      ? failCodeNames.map((name) => <div key={name}>{name}</div>)
                      : "-"}
                  </div>

                  <div className="col-inclusions">
                    {include && models.length === 0 ? (
                      <span className="all">Include all models</span>
                    ) : (
                      <>
                        <div className="flex--40">
                          {exclude ? "Exclude only:" : "Include only:"}
                        </div>
                        <div className="flex--60">
                          {models.map((model, index) => (
                            <div key={index}>{model}</div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-arrow">
                    <FontIcon
                      iconClassName="icon-chevron-right"
                      onClick={() =>
                        onConfigClicked(selectedConfig.className, selectedConfig.identifier)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Column>
      </Row>
    </Column>
  );
};

TestDefinitionsSimpleView.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  selectedCategory: PropTypes.shape({ name: PropTypes.string }),
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      displayName: PropTypes.string,
      image: PropTypes.string,
      configs: PropTypes.arrayOf(
        PropTypes.shape({
          className: PropTypes.string,
          identifier: PropTypes.string,
          identifierDisplayName: PropTypes.string,
        })
      ),
    })
  ),
  onCategoryChanged: PropTypes.func,
  onConfigClicked: PropTypes.func,
};

TestDefinitionsSimpleView.defaultProps = {
  categories: [],
  tests: [],
};

export default TestDefinitionsSimpleView;
