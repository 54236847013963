import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const DocumentationTrainingIcon = ({ size, customId }) => (
  <SVGIcon title="Documentation Training" viewBox="0 0 32.9 34.37" size={size}>
    <defs>
      <linearGradient
        id={customId}
        x1="11.88"
        x2="32.9"
        y1="16.02"
        y2="16.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.13" stopColor="#8ec137" />
        <stop offset="0.35" stopColor="#71a440" />
        <stop offset="0.64" stopColor="#427550" />
        <stop offset="0.99" stopColor="#023565" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <circle fillOpacity="0" stroke="#99cc33" cx="7.23" cy="4.16" r="3.66" className="cls-1" />
      <path
        fill={`url(#${customId})`}
        d="M31.46 3.2H19.79v-.89a.5.5 0 1 0-1 0v.89h-6.41a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h19.08a.44.44 0 0 1 .44.44V24.9a.45.45 0 0 1-.44.45h-16a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5h2.79l-3 3a.5.5 0 0 0 0 .71.5.5 0 0 0 .7 0l3.36-3.36 3.36 3.36a.48.48 0 0 0 .7 0 .5.5 0 0 0 0-.71l-3-3h11.09a1.45 1.45 0 0 0 1.44-1.45V4.64a1.44 1.44 0 0 0-1.44-1.44z"
      />
      <path
        fillOpacity="0"
        stroke="#99cc33"
        d="M21.53 11v.09a1.82 1.82 0 0 1-1.81 1.81h-8.24v18.84a2.08 2.08 0 0 1-.63 1.5 2.12 2.12 0 0 1-1.51.63 2.15 2.15 0 0 1-2.11-1.8 2.17 2.17 0 0 1-2.11 1.8A2.14 2.14 0 0 1 3 31.74V21.18A2.48 2.48 0 0 1 .5 18.7v-7.22A2.49 2.49 0 0 1 3 9h.67a1.61 1.61 0 0 1 1.15.59c.28.32 1.24 1.25 1.85 1.82a.74.74 0 0 0 1 0c.66-.61 1.76-1.62 1.94-1.74a2.78 2.78 0 0 1 1.26-.53h8.86a1.82 1.82 0 0 1 1.8 1.86z"
        className="cls-1"
      />
    </g>
  </SVGIcon>
);

DocumentationTrainingIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default DocumentationTrainingIcon;
