import React, { useEffect, useState } from "react";
import { message } from "antd";
import { isEqual } from "lodash";
import { useTranslation } from "~/i18n";

import {
  InventoryProject,
  fetchInventoryProjects,
  InventoryProjectBody,
  updateInventoryProject,
} from "../../../entities/inventory/model/inventoryProjetsSlice";
import { CreateInventoryProjectForm } from "./CreateInventoryProjectForm";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { UpdateInventoryProjectForm } from "./UpdateInventoryProjectForm";
import { InventoryTasks } from "./InventoryTasks";
import { InventoryProjectsTable } from "./InventoryProjectsTable";

export const InventoryProjectsPage = () => {
  const { t } = useTranslation("inventory");
  const dispatch = useAppDispatch();

  const { formStatus } = useAppSelector((state) => state.inventoryProjects);

  const [projectToEdit, setProjectToEdit] = useState<InventoryProject>();

  useEffect(() => {
    if (formStatus === "idle") {
      void dispatch(fetchInventoryProjects());
    }
  }, [dispatch, formStatus]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="panel-container inventory-projects-page">
      {projectToEdit ? (
        <UpdateInventoryProjectForm
          key={projectToEdit.id}
          project={projectToEdit}
          onFinish={(values: InventoryProjectBody) => {
            const newValues = { ...projectToEdit, ...values };

            if (!isEqual(newValues, projectToEdit)) {
              dispatch(updateInventoryProject({ id: projectToEdit.id, ...values }))
                .unwrap()
                .then(() => {
                  void message.success(t("update-success"));
                })
                .catch((rejectedValue: string) => {
                  void message.error(rejectedValue);
                });
            }

            setProjectToEdit(undefined);
          }}
          onCancel={() => {
            setProjectToEdit(undefined);
          }}
        />
      ) : (
        <CreateInventoryProjectForm />
      )}

      <InventoryProjectsTable setProjectToEdit={setProjectToEdit} scrollToTop={scrollToTop} />

      <InventoryTasks />
    </div>
  );
};
