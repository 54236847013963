import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import labelSelectors from "~/label-printer/selectors";

const ImagePreview = (props) => {
  const { data, width, height, keepAspectRatio, fitShape } = props;
  const imgRef = useRef(null);

  const [imgSize, setImgSize] = useState({
    width,
    height: keepAspectRatio ? "auto" : height,
  });

  useEffect(() => {
    if (fitShape && data && imgRef.current != null) {
      const parentSize = imgRef.current.parentElement.getBoundingClientRect();

      setImgSize({
        width: parentSize.width,
        height: keepAspectRatio ? "auto" : parentSize.height,
      });
    } else {
      setImgSize({
        width,
        height: keepAspectRatio ? "auto" : height,
      });
    }
  }, [data, width, height, keepAspectRatio, fitShape, imgRef]);

  return data ? (
    <img ref={imgRef} src={data} width={imgSize.width} height={imgSize.height} alt="" />
  ) : null;
};

ImagePreview.defaultProps = {};

ImagePreview.propTypes = {
  identity: PropTypes.number,

  data: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  keepAspectRatio: PropTypes.bool,
  fitShape: PropTypes.bool,

  columnWidth: PropTypes.number,
  rowHeight: PropTypes.number,
};

const stateToProps = (state, props) => {
  const { row } = labelSelectors.labelDesigner.getShapeLayoutData(props.identity)(state);
  const columnWidth = labelSelectors.labelDesigner.getColumnWidth(props.identity)(state);
  const rowHeight = labelSelectors.labelDesigner.getRowHeight(row)(state);

  return {
    columnWidth,
    rowHeight,
  };
};

export default connect(stateToProps, null)(ImagePreview);
