import React from "react";
import { useHistory } from "react-router-dom";
import { Table } from "antd";
import Timestamp from "~/workstation/components/common/Timestamp";

const CrashReportList = (props) => {
  const history = useHistory();
  const { crashReports } = props;
  const onClick = (id) => history.push(`/workstations/crash-report/${id}`);
  const column = [
    {
      title: "Time",
      dataIndex: crashReports,
      render: (createdAt) => <Timestamp value={createdAt} />,
    },
    {
      title: "Version",
      dataIndex: crashReports,
      render: (report) => <> {report.appVersion}</>,
    },
    { title: "Guid", dataIndex: crashReports, render: (report) => <> {report.guid}</> },
    { title: "Platform", dataIndex: crashReports, render: (report) => <> {report.platform}</> },
  ];
  return (
    <Table
      columns={column}
      dataSource={crashReports}
      pagination={false}
      onRow={(crashReport) => ({
        onClick: () => {
          onClick(crashReport.id);
        },
      })}
    />
  );
};

CrashReportList.defaultProps = {};

export default CrashReportList;
