import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import { Button } from "react-md";
import actions from "~/actions";

import { Row, SelectList, Column, TextField, AsciButton } from "~/global";

import moduleSelectors from "~/data-import/selectors/";
import moduleActions from "~/data-import/actions/";

import "./CustomFieldsSection.component.scss";

const CustomFieldsSection = ({ fetchCustomFields, customFieldList, saveCustomFieldList }) => {
  const [customFields, setCustomFields] = useState([]);
  const [customField, setCustomField] = useState([]);
  const [cfList, setCfList] = useState([]);
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    fetchCustomFields();
  }, []);

  useEffect(() => {
    setCfList(customFieldList);
  }, [customFieldList]);

  const addCustomField = () => {
    if (customField.type === "list") {
      const listCustomField = {
        ...customField,
        value: {
          displayName: customField.items[0].displayName,
          value: customField.items[0].value,
        },
      };

      setCustomFields(customFields.concat(listCustomField));
    } else {
      setCustomFields(customFields.concat(customField));
    }

    setCfList(cfList.filter((item) => item.name !== customField.name));
  };

  useEffect(() => {
    if (customField.name === "Select..." || customField.name === undefined) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
  }, [customField]);

  const removeCustomField = (cf) => {
    setCfList(cfList.concat(cf));
    setCustomFields(customFields.filter((item) => item.name !== cf.name));
  };

  useEffect(() => {
    saveCustomFieldList(customFields);
    setCfList(sortBy(cfList, ["name", "value", "type", "items"]));
  }, [customFields]);

  useEffect(() => {
    setCustomField(customFieldList[0]);
  }, [cfList]);

  return (
    <div className="custom-field-section">
      <Row className="section-title">Custom Fields</Row>
      <Row>
        <Column className="cf-column">
          <Row>
            <SelectList
              items={cfList}
              onChange={(value) => setCustomField(value)}
              selectedItem={customField}
              nameKey="name"
            />
            <AsciButton
              color="blue"
              onClick={() => addCustomField()}
              className="add-cf-btn"
              disabled={isAvailable}
            >
              Add custom field
            </AsciButton>
          </Row>
          <Row>
            {customFields.map((cf, index) => {
              const newCustomFields = customFields;

              return (
                <Column key={index}>
                  <Row className="cf-label">{cf.name}</Row>
                  <Row className="cf-subtitle">({cf.type})</Row>

                  {cf.type === "numeric" ? (
                    <Row align="start center">
                      <TextField
                        id="custom-field"
                        onChange={(value) =>
                          setCustomFields(
                            newCustomFields.map((item, i) =>
                              index === i
                                ? {
                                    name: cf.name,
                                    type: cf.type,
                                    value,
                                    items: cf.items,
                                  }
                                : item
                            )
                          )
                        }
                        type="number"
                        className="custom-field-numeric"
                        value={cf.value ? cf.value : 0}
                      />
                      <Button
                        icon
                        className="remove-cf-btn"
                        iconClassName="icon-trash"
                        onClick={() => removeCustomField(cf)}
                      />
                    </Row>
                  ) : null}

                  {cf.type === "string" ? (
                    <Row align="start center">
                      <TextField
                        id="custom-field"
                        onChange={(value) =>
                          setCustomFields(
                            newCustomFields.map((item, i) =>
                              index === i
                                ? {
                                    name: cf.name,
                                    type: cf.type,
                                    value,
                                    items: cf.items,
                                  }
                                : item
                            )
                          )
                        }
                        className="custom-field-string"
                        type="string"
                      />
                      <Button
                        icon
                        className="remove-cf-btn"
                        iconClassName="icon-trash"
                        onClick={() => removeCustomField(cf)}
                      />
                    </Row>
                  ) : null}

                  {cf.type === "list" ? (
                    <Row align="start center">
                      <SelectList
                        items={cf.items}
                        onChange={(value) =>
                          setCustomFields(
                            newCustomFields.map((item, i) =>
                              index === i
                                ? {
                                    name: cf.name,
                                    type: cf.type,
                                    value,
                                    items: cf.items,
                                  }
                                : item
                            )
                          )
                        }
                        selectedItem={cf.items[0]}
                        nameKey="value"
                        className="custom-field-list"
                      />
                      <Button
                        icon
                        className="remove-cf-btn"
                        iconClassName="icon-trash"
                        onClick={() => removeCustomField(cf)}
                      />
                    </Row>
                  ) : null}
                </Column>
              );
            })}
          </Row>
        </Column>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  customFieldList: moduleSelectors.importDataManual.getCustomFieldsList(state),
});

const dispatchToProps = {
  fetchCustomFields: actions.customFields.fetchCustomFields,
  saveCustomFieldList: moduleActions.importDataManual.saveCustomFieldList,
};

export default connect(stateToProps, dispatchToProps)(CustomFieldsSection);
