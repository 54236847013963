import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";

import "./DetailsSection.scss";
import { FontIcon } from "react-md";

const DetailsSection = (props) => {
  const { sectionTitle, children, style, className, expandable, initialExpanded } = props;
  const [expanded, setExpanded] = useState(initialExpanded);

  const css = ["details-section"];
  if (className) {
    css.push(className);
  }

  const onExpand = useCallback(() => setExpanded(!expanded), [expanded]);
  const expandIcon = useMemo(() => (expanded ? "icon-angle-down" : "icon-angle-up"), [expanded]);

  return (
    <div className={css.join(" ")} style={style}>
      <div className="section-title">
        {sectionTitle}
        {expandable && (
          <FontIcon className="expand-icon" iconClassName={expandIcon} onClick={onExpand} />
        )}
      </div>
      {expanded && <div className="section-body">{children}</div>}
    </div>
  );
};

DetailsSection.defaultProps = {
  initialExpanded: true,
};

DetailsSection.propTypes = {
  sectionTitle: PropTypes.node,
  children: PropTypes.node,

  style: PropTypes.object,
  className: PropTypes.string,
  expandable: PropTypes.bool,
  initialExpanded: PropTypes.bool,
};

export default DetailsSection;
