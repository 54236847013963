import React, { useCallback } from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useTranslation } from "~/i18n";

// TODO: remove the size prop when all the icons have been updated to use antd
interface Props extends Partial<CustomIconComponentProps> {
  customId: string;
  size: number;
}

export const ModelDescriptorsIcon = ({ customId, size, style, ...iconProps }: Props) => {
  const { t } = useTranslation("home");

  const ModelDescriptorsSvg = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id={customId}
        viewBox="0 0 35.62 31.67"
        width="1em"
        height="1em"
      >
        <defs>
          <linearGradient
            id={`${customId}-linear-gradient`}
            x1={8.01}
            x2={11.38}
            y1={3.48}
            y2={3.48}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#9c3" />
            <stop offset={0.62} stopColor="#376a53" />
            <stop offset={1} stopColor="#036" />
          </linearGradient>
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-2`}
            x1={2.66}
            x2={16.71}
            y1={16.2}
            y2={16.2}
          />
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-3`}
            x1={16.63}
            x2={18.26}
            y1={1.92}
            y2={1.92}
          />
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-4`}
            x1={0}
            x2={19.37}
            y1={15.84}
            y2={15.84}
          />
        </defs>
        <g id={`${customId}-Version_Number`}>
          <path
            fill={`url(#${customId}-linear-gradient)`}
            d="M11.38 3.45v.04c0 .21-.16.38-.37.38H8.38a.37.37 0 0 1-.37-.37v-.04c0-.21.16-.38.37-.38h2.63c.21 0 .37.17.37.37Z"
          />
          <path
            fill={`url(#${customId}-linear-gradient-2)`}
            d="M15.71 18.17v7.36H3.66V6.88h12.05v6.52h1V5.87H2.66v20.66h14.05v-8.36h-1z"
          />
          <path
            fill={`url(#${customId}-linear-gradient-3)`}
            d="M17.64 1.72c.28.28.5.63.62 1.01-.12-.39-.34-.74-.62-1.01Zm-.36-.3c-.2-.14-.42-.25-.65-.31.24.07.45.18.65.31Z"
          />
          <path
            fill={`url(#${customId}-linear-gradient-4)`}
            d="M18.37 18.17v10.04c0 1.35-1.1 2.46-2.45 2.46H3.46c-1.35 0-2.46-1.1-2.46-2.46V3.46C1 2.11 2.11 1 3.46 1H15.9c.26 0 .5.04.73.11.23.06.45.17.65.31.13.09.25.19.36.3.28.27.5.62.62 1.01.07.23.11.48.11.73v9.94h1V3.46c0-1.9-1.55-3.46-3.47-3.46H3.46C1.56 0 0 1.56 0 3.46v24.75c0 1.9 1.56 3.46 3.46 3.46h12.46c1.9 0 3.45-1.56 3.45-3.46V18.17h-1Z"
          />
          <path
            d="M35.12 5.11h-9.06M9.53 15.59h12.83"
            fill="none"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx={9.61} cy={15.59} r={1.5} fill="#93d500" />
          <circle cx={22.28} cy={15.59} r={1.5} fill="#93d500" />
          <path
            d="M32.12 8.6h-6.06M34.12 12.1h-8.06M31.12 15.59h-5.06M33.12 19.09h-7.06M35.12 22.58h-9.06M31.12 26.07h-5.06"
            fill="none"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
    [customId]
  );

  return (
    <Icon
      title={t("modules.model-descriptors-title")}
      component={ModelDescriptorsSvg}
      style={{ ...style, fontSize: size }}
      {...iconProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};
