import React from "react";
import { connect } from "react-redux";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableRow,
  TableHeader,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import moduleActions from "../../actions";
import { Row, Column, AsciButton } from "~/global";

import "./AdvancedInformation.component.scss";

const AdvancedInformation = (props) => {
  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  const renderCountry = () => {
    let country = "N/A";

    if (
      props.session &&
      props.session.inputOrigin &&
      props.session.inputOrigin.requestHeader &&
      Array.isArray(props.session.inputOrigin.requestHeader["Cf-Ipcountry"])
    ) {
      country = props.session.inputOrigin.requestHeader["Cf-Ipcountry"][0];
    }

    return country;
  };

  const downloadJSONData = () => {
    const jsonData = JSON.stringify(props.session);
    const fileName = `${props.session.process.sessionIDHumanReadable}.json`;

    download(fileName, jsonData);
  };

  function download(filename, data) {
    const element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <div
      className={
        props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
      }
    >
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={
            <div className="panel-text title bold-screen printable-title">Advanced Information</div>
          }
          secondaryLabel={
            <div className="panel-text no-printable">
              {props.includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          footer={null}
          onExpandToggle={() => includeForPrint()}
          expanded={props.includeToPrint}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          <Row className="advanced-informations">
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="desktop-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Desktop Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Computer Name:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.computerName || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Computer IP:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.inputOrigin.ip || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">IP Country:</TableColumn>
                    <TableColumn className="table-data">{renderCountry()}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Manufacturer:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.manufacturer || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Model:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.model || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">osArchitecture:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.osArchitecture || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">osName:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.osName || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">osVersion:</TableColumn>
                    <TableColumn className="table-data">
                      {props.session.desktopInformation.osVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>

            <Column className="full-width">
              <Row>
                <Column className="full-width">
                  <DataTable
                    baseId="certificate-info-table-1"
                    plain
                    className="software-information-table"
                  >
                    <TableHeader className="table-header">
                      <TableRow>
                        <TableColumn colSpan="2" className="header-label">
                          <div className="section-banner row centered">Software Information</div>
                        </TableColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody className="table-body">
                      <TableRow>
                        <TableColumn className="table-label">DSE Version:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.desktopInformation.softwareInformation.dseVersion || "N/A"}
                        </TableColumn>
                      </TableRow>
                      <TableRow>
                        <TableColumn className="table-label">ProD Version IOS:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.desktopInformation.softwareInformation
                            .iosDiagnosticsVersion || "N/A"}
                        </TableColumn>
                      </TableRow>
                      <TableRow>
                        <TableColumn className="table-label">ProD Version Android:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.desktopInformation.softwareInformation
                            .androidDiagnosticsVersion || "N/A"}
                        </TableColumn>
                      </TableRow>
                    </TableBody>
                  </DataTable>
                </Column>
              </Row>

              <Row>
                <Column className="full-width">
                  <DataTable
                    baseId="certificate-info-table-1"
                    plain
                    className="other-information-table"
                  >
                    <TableHeader className="table-header">
                      <TableRow>
                        <TableColumn colSpan="2" className="header-label">
                          <div className="section-banner row centered">Other</div>
                        </TableColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody className="table-body">
                      <TableRow>
                        <TableColumn className="table-label">Sender:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.sender || "N/A"}
                        </TableColumn>
                      </TableRow>
                      <TableRow>
                        <TableColumn className="table-label">Host:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.inputOrigin.host || "N/A"}
                        </TableColumn>
                      </TableRow>
                      <TableRow>
                        <TableColumn className="table-label">Customer ID:</TableColumn>
                        <TableColumn className="table-data">
                          {props.session.tenantIdentifier.portalCustomerID || "N/A"}
                        </TableColumn>
                      </TableRow>
                    </TableBody>
                  </DataTable>
                </Column>
              </Row>
            </Column>
          </Row>

          <Row className="advanced-informations">
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="download-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Download Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="download-button row centered">
                      <AsciButton color="blue" onClick={downloadJSONData} className="download-json">
                        Download JSON File
                      </AsciButton>
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeAdvancedToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeAdvancedToPrint,
};

export default connect(stateToProps, dispatchToProps)(AdvancedInformation);
