import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextField, Grid, Cell, Switch, FontIcon } from "react-md";
import { Select, Input } from "antd";

import { SelectList } from "~/global";

import actions from "~/actions";
import selectors from "~/selectors";

import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import { useTranslation } from "~/i18n";

const CustomerUserEditor = (props) => {
  const [userFacilityIds, setUserFacilityIds] = useState(props.facilityIds);
  const { addUser } = props;
  const { t } = useTranslation("customerUserEditor");

  useEffect(() => {
    props.onResetPassword(!props.editMode);
  }, []);

  useEffect(() => {
    if (props.roleName != "operator") {
      props.onSelectStatus(0);
    }
  }, [props.roleName]);

  useEffect(() => {
    if (props.facilityIds.length > 0) {
      // Select the first facility that isn't "all"
      props.fetchAudits(props.facilityIds[1]);
    }
  }, [props.facilityIds[1]]);

  const { Option } = Select;
  const icons = {
    password: <FontIcon iconClassName="icon-eye" />,
  };
  const textDefaults = {
    lineDirection: "left",
    className: "table-text-field",
  };

  const updateFacilityIds = (facilityIds) => {
    if (!props.newCustomer) {
      props.validateFacilities(facilityIds);
    }

    props.onChangeFacilities(facilityIds);
    setUserFacilityIds(facilityIds);
  };

  const selectFacilityIds = (facilityIds) => {
    const selectedFacilityIdsWithoutAll = facilityIds.filter((faid) => faid !== "all");
    const faidsWithoutAll = props.facilitiesList.filter((faid) => faid !== "all");
    const allFacilitiesSelected = selectedFacilityIdsWithoutAll.length === faidsWithoutAll.length;

    // Select "all" when all or no facilities are selected
    if (
      (facilityIds.includes("all") && facilityIds[0] !== "all") ||
      allFacilitiesSelected ||
      selectedFacilityIdsWithoutAll.length === 0
    ) {
      updateFacilityIds(["all"]);
      return;
    }

    updateFacilityIds(selectedFacilityIdsWithoutAll);
  };

  const statusNames = { Probationary: t("probationary"), Trainee: t("trainee") };

  const roles = useMemo(
    () =>
      props.roleNamesList.map((roleName) => ({
        value: roleName,
        label: {
          "asci-administrator": t("asci-administrator"),
          "client-administrator": t("client-administrator"),
          operator: t("operator"),
          "remote-diagnostics-operator": t("remote-diagnostics-operator"),
          reporting: t("reporting"),
          "data-entry": t("data-entry"),
          "user-manager": t("user-manager"),
          "inventory-scanner": t("inventory-scanner"),
        }[roleName],
      })),
    []
  );

  return (
    <div>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("email-address")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={4}>
          <TextField
            {...textDefaults}
            id="user-email-text-field"
            placeholder={t("placeholder-enter-email-address")}
            errorText={t("error-text-a-valid-email-is-required")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveUser && e.key === "Enter" ? props.onSave() : null
            }
            value={props.userEmail}
            onChange={(email) => props.onChangeEmail(email)}
            error={props.emailError}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("first-name")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            {...textDefaults}
            id="first-name-text-field"
            placeholder={t("placeholder-enter-first-name")}
            errorText={t("error-text-a-valid-first-name")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveUser && e.key === "Enter" ? props.onSave() : null
            }
            value={props.firstName}
            onChange={(name) => props.onChangeFirstName(name)}
            error={props.firstNameError}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("last-name")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            {...textDefaults}
            id="last-name-text-field"
            placeholder={t("placeholder-enter-last-name")}
            errorText={t("error-text-a-valid-last-name-is-required")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveUser && e.key === "Enter" ? props.onSave() : null
            }
            value={props.lastName}
            onChange={(name) => props.onChangeLastName(name)}
            error={props.lastNameError}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("password")} {props.resetPassword ? <sup>*</sup> : null}
          </div>
        </Cell>
        <Cell size={4} tabletSize={2}>
          <TextField
            {...textDefaults}
            id="user-password-text-field"
            placeholder={
              !props.resetPassword
                ? t("placeholder-reset-to-enable")
                : t("placeholder-enter-password")
            }
            errorText={t("error-text-a-valid-password-is-required")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveUser && e.key === "Enter" ? props.onSave() : null
            }
            passwordIcon={icons.password}
            type="password"
            disabled={!props.resetPassword}
            value={props.userPassword}
            onChange={(password) => props.onChangePassword(password)}
            error={props.passwordError}
            autoComplete="new-password"
          />
        </Cell>
        {props.editMode ? (
          <Cell size={2}>
            <Switch
              id="enable-change-password-user"
              type="switch"
              name="list-control-primary"
              checked={props.resetPassword}
              label={t("password-reset")}
              onChange={() => props.onResetPassword(!props.resetPassword)}
            />
          </Cell>
        ) : null}
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("role")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          {props.newCustomer ? (
            <div>{props.roleAdmin.name}</div>
          ) : (
            <div>
              <SelectList
                items={roles}
                valueKey="value"
                nameKey="label"
                selectedItem={{ value: props.roleName }}
                onChange={(roleName) => {
                  props.onChangeRole(roleName);
                  if (roleName === "asci-administrator") {
                    selectFacilityIds(["all"]);
                  }
                }}
                underlined
                disabled={props.newCustomer}
              />
            </div>
          )}
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("facility")}</div>
        </Cell>

        <Cell size={6} tabletSize={3}>
          <Select
            mode="multiple"
            value={userFacilityIds}
            style={{ width: "100%" }}
            placeholder={t("placeholder-please-select")}
            disabled={props.roleName === "asci-administrator"}
            onChange={selectFacilityIds}
          >
            {props.facilitiesList.map((faid) => (
              <Option key={faid} value={faid}>
                {props.facilitiesById[faid].name === "All Facilities"
                  ? t("all-facilities")
                  : props.facilitiesById[faid].name}
              </Option>
            ))}
          </Select>
          {props.facilityError && !addUser && !props.newCustomer && (
            <p className="error-message row to-end">{t("user-will-be-removed-from-all")}</p>
          )}
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("operator-id")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <Input
            id="operator-id-input"
            placeholder={t("placeholder-enter-operator-id")}
            onChange={(e) => props.onChangeOperatorId(e.target.value)}
            value={props.operatorId}
            defaultValue={props.operatorId}
            style={{ width: "100%" }}
          />
        </Cell>
      </Grid>
      {props.roleName === "operator" ? (
        <Grid className="full-width">
          <Cell size={4} tabletSize={3} className="row centered to-end">
            {t("audit-status")}
          </Cell>
          <Cell size={6} tabletSize={3}>
            <SelectList
              items={props.audits}
              selectedItem={props.userStatus}
              nameBuilder={(status) =>
                statusNames[props.auditsByStatus[status].name] || props.auditsByStatus[status].name
              }
              onChange={(audit) => props.onSelectStatus(audit)}
              underlined
            />
          </Cell>
        </Grid>
      ) : null}
      <div className="info-message column centered">
        <div>{props.newCustomer ? t("other-users-can-be-added") : t("audit-status-available")}</div>
      </div>
    </div>
  );
};

CustomerUserEditor.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  editMode: PropTypes.bool,
  newCustomer: PropTypes.bool,
  facilitiesList: PropTypes.array,
  facilitiesById: PropTypes.object,
  facilityError: PropTypes.bool,
  addUser: PropTypes.bool,
};

CustomerUserEditor.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  editMode: false,
  newCustomer: false,
  facilitiesList: [],
  facilitiesById: {},
};

const stateToProps = (state) => ({
  audits: selectors.audits.getAuditsStatusList(state),
  auditsByStatus: selectors.audits.getAuditsByStatus(state),

  canSaveUser: moduleSelectors.customerUserEditor.getCanSaveCustomerUser(state),
  emailError: state.modules.customerManagement.customerUserEditor.emailError,
  facilityIds: moduleSelectors.customerUserEditor.getFacilityIds(state),
  firstName: moduleSelectors.customerUserEditor.getFirstName(state),
  firstNameError: state.modules.customerManagement.customerUserEditor.firstNameError,
  resetPassword: state.modules.customerManagement.customerUserEditor.resetPassword,
  lastName: moduleSelectors.customerUserEditor.getLastName(state),
  lastNameError: state.modules.customerManagement.customerUserEditor.lastNameError,
  passwordError: state.modules.customerManagement.customerUserEditor.passwordError,
  facilityError: state.modules.customerManagement.customerUserEditor.facilityError,
  roleAdmin: moduleSelectors.customerUserEditor.getAdminUser(state),
  roleName: moduleSelectors.customerUserEditor.getUserRoleName(state),
  roleNamesList: moduleSelectors.customerUserEditor.getRoleNamesList(state),
  user: moduleSelectors.customerUserEditor.getUpdatedCustomerUser(state),
  userEmail: moduleSelectors.customerUserEditor.getUserEmail(state),
  userPassword: moduleSelectors.customerUserEditor.getUserPassword(state),
  userStatus: moduleSelectors.customerUserEditor.getStatus(state),
  operatorId: moduleSelectors.customerUserEditor.getOperatorId(state),
});

const dispatchToProps = {
  validateUserPassword: moduleActions.customerUserEditor.validateUserPassword,
  validateUserEmail: moduleActions.customerUserEditor.validateUserEmail,
  validateUserFirstName: moduleActions.customerUserEditor.validateUserFirstName,
  validateUserLastName: moduleActions.customerUserEditor.validateUserLastName,
  onChangeFirstName: moduleActions.customerUserEditor.onChangeFirstName,
  onChangeLastName: moduleActions.customerUserEditor.onChangeLastName,
  onChangePassword: moduleActions.customerUserEditor.onChangePassword,
  onResetPassword: moduleActions.customerUserEditor.onResetPassword,
  onChangeEmail: moduleActions.customerUserEditor.onChangeEmail,
  onChangeRole: moduleActions.customerUserEditor.onChangeRole,
  onChangeFacilities: moduleActions.customerUserEditor.onChangeFacilities,
  validateFacilities: moduleActions.customerUserEditor.validateFacilities,
  onChangeOperatorId: moduleActions.customerUserEditor.onChangeOperatorId,
  onSelectStatus: moduleActions.customerUserEditor.onSelectStatus,
  fetchAudits: actions.audits.fetchAudits,
};

export default connect(stateToProps, dispatchToProps)(CustomerUserEditor);
