import React, { useEffect, useMemo } from "react";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "antd";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  actions as workstationActions,
  selectors as workstationSelectors,
} from "~/workstation/redux";
import { Spinner } from "~/global";
import TaskStatus from "~/workstation/components/common/TaskStatus";
import AutoFilter from "~/global/reduxer/components/AutoFilter";
import Timestamp from "~/workstation/components/common/Timestamp";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import TaskType from "~/workstation/components/common/TaskType";

const TaskList = (props) => {
  const { workstationId, sessionId } = props;
  const dispatch = useDispatch();
  const taskList = useSelector(workstationSelectors.tasks.getFilteredResults);
  const isFetched = useSelector(workstationSelectors.tasks.isFetched);
  const isFetching = useSelector(workstationSelectors.tasks.isFetching);
  const error = useSelector(workstationSelectors.tasks.getError);
  const history = useHistory();
  const onClick = (id) => history.push(`/workstations/tasks/${id}`);
  const tasks = useMemo(
    () => (taskList ? reverse(sortBy(taskList, [(task) => new Date(task.createdAt)])) : []),
    [taskList]
  );
  const onCancel = (e, id) => {
    e.stopPropagation();
    dispatch(workstationActions.taskCancel.fetch(id)).then(() => {
      dispatch(workstationActions.tasks.fetchReset());
      dispatch(workstationActions.taskDetails.fetchReset());
    });
  };
  const refresh = () => {
    dispatch(workstationActions.tasks.fetchReset());
  };

  useEffect(() => {
    if (!isFetched && !isFetching && !error) {
      dispatch(workstationActions.tasks.fetch(workstationId));
    }
  }, [isFetching, isFetched, error, dispatch, workstationId]);

  useEffect(() => () => dispatch(workstationActions.tasks.fetchReset()), [dispatch]);

  const column = [
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.tasks}
          selectors={workstationSelectors.tasks}
          identifier="status"
          style={{ width: "10rem" }}
        />
      ),
      dataIndex: "result",
      render: (result) => <TaskStatus status={result.status} />,
    },
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.tasks}
          selectors={workstationSelectors.tasks}
          identifier="type"
          style={{ width: "10rem" }}
        />
      ),
      dataIndex: "type",
      render: (type) => <TaskType type={type} />,
    },
    {
      title: "Time",
      render: (_, task) => <Timestamp value={task.createdAt || task.result.updateAt} />,
    },
    {
      title: () => <Button shape="circle" icon={<ReloadOutlined />} onClick={refresh} />,
      render: (req) => (
        <Button
          disabled={req.result.status !== "PENDING"}
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={(e) => onCancel(e, req.id)}
          danger
        />
      ),
    },
  ];
  return (
    <ReduxerControlled
      selectors={workstationSelectors.tasks}
      actions={workstationActions.tasks}
      reducerName="workstations"
      loadingComponent={<Spinner />}
      fetchArguments={[{ workstationId, sessionId }]}
    >
      <Table
        columns={column}
        dataSource={tasks}
        pagination={false}
        onRow={(task) => ({
          onClick: () => {
            onClick(task.id);
          },
        })}
      />
    </ReduxerControlled>
  );
};

TaskList.defaultProps = {};

TaskList.propTypes = {
  workstationId: PropTypes.string,
  sessionId: PropTypes.string,
};

export default TaskList;
