import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TestDefinitionIcon } from "~/global";

import "./TestRow.component.scss";

const TestIcon = ({ image }) => (
  <div className="icon">
    <TestDefinitionIcon src={image} />
  </div>
);

const TestFriendlyName = ({ name }) => <div className="name">{name}</div>;

const TestDescription = ({ description }) => <div className="description">{description}</div>;

const Extra = ({ children, className }) => (
  <div className={classnames("extra", className)}>{children}</div>
);

export default class TestRow extends Component {
  static Icon = TestIcon;
  static FriendlyName = TestFriendlyName;
  static Description = TestDescription;
  static Extra = Extra;

  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={classnames("cc-test-row", this.props.className)}>{this.props.children}</div>
    );
  }
}
