import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

const initialState = {
  items: [],
  error: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [actions.serviceSuites.fetchServiceSuitesBegin]: (state, action) => {
    state.isLoading = true;
  },
  [actions.serviceSuites.fetchServiceSuitesSuccess]: (state, action) => {
    state.items = action.payload.serviceSuites; // allServiceSuites suite
    state.isLoading = false;
  },
  [actions.serviceSuites.fetchServiceSuitesError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
