import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Column } from "~/global";
import { DatePicker } from "~/shared/ui/DatePicker";

import "./DayPicker.component.scss";

const DayPicker = ({ value, onChange }) => {
  const { t } = useTranslation("dayPicker");
  return (
    <Column className="cc-day-picker">
      <div className="day-picker-label">{t("date")}</div>

      <DatePicker
        className="date-picker"
        popupClassName="popup-calendar"
        value={value}
        onChange={onChange}
        allowClear={false}
        suffixIcon={<i className="icon-calendar" />}
      />
    </Column>
  );
};

DayPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default DayPicker;
