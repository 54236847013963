// Workflow Response to events.

export const ApiCall = "ApiCall";
export const RunApplication = "RunApplication";

export const PrintLabel = "PrintLabel";
export const SetCustomFields = "SetCustomFields";
export const SkipService = "SkipService";

export const metadata = {
  [ApiCall]: {
    defaultData: {
      url: "",
      method: "GET",
      bodyType: "application/json",
      setCustomFieldsFromResponse: false,
      customHeaders: [],
    },
    label: "HTTP call",
  },
  [RunApplication]: {
    defaultData: {
      path: "",
      parameters: [],
      cwd: null,
      encoding: "utf8",
      timeout: 0,
      shell: null,
    },
    label: "Run application",
  },
  [PrintLabel]: {
    defaultData: {
      labelId: null,
    },
    label: "Print label",
  },
  [SetCustomFields]: {
    defaultData: {
      fields: [],
    },
    label: "Set custom fields",
  },
  // [SkipService]: {
  //     defaultData: {},
  //     label: "Skip service"
  // }
};

export const allBehaviors = [ApiCall, SetCustomFields, PrintLabel, RunApplication];

export const getDefaultBehaviorData = (behaviorType) => ({ ...metadata[behaviorType].defaultData });
