import { combineReducers } from "@reduxjs/toolkit";

import cannedReports from "~/canned-reports/reducers";
import customFields from "~/custom-fields-editor/reducers";
import customerManagement from "~/customer-management/reducers";
import dashboard from "~/pages/dashboard/models";
import dataImport from "~/data-import/reducers";
import deploymentManagement from "~/deployment-management/reducers";
import deviceHistory from "~/device-history/reducers";
import programManagement from "~/programs-management/reducers";
import remoteDiagnostics from "~/remote-diagnostics/reducers/remoteDiagnostics.reducer";
import serviceSuiteTemplates from "~/service-suite-templates/reducers/serviceSuiteTemplates.reducer";
import testDefinitionsEditor from "~/test-definitions-editor/reducers/testDefinitionsEditor.reducer";
import testSuitesManagement from "~/test-suites-management/reducers/testSuitesManagement.reducer";
import userLogs from "~/user-logs/reducers";

export default combineReducers({
  cannedReports,
  customFields,
  customerManagement,
  dashboard,
  dataImport,
  deploymentManagement,
  deviceHistory,
  programManagement,
  remoteDiagnostics,
  serviceSuiteTemplates,
  testDefinitionsEditor,
  testSuitesManagement,
  userLogs,
});
