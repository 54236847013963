import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelProps,
  Text,
} from "recharts";
import React, { forwardRef, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "~/i18n";
import { ChartData } from "./reportingChart.slice";
import { DisplayByValue } from "~/actions/reportTypes.actions";

import "./BarChartGraph.component.scss";

type BarStack = {
  dataKey: keyof ChartData[number];
  name: string;
  color: string;
};

type Props = {
  displayBy?: DisplayByValue;
  chartData: ChartData;
  isPercentageShown: boolean;
  onDrillDown?: (value: string) => void;
};

const BarChartGraph = forwardRef<any, Props>(
  ({ chartData, displayBy = "status", isPercentageShown, onDrillDown }, ref) => {
    const [hiddenBars, setHiddenBars] = useState<string[]>([]);

    const percentageData = useMemo(() => {
      const totalCount = chartData.reduce((acc, datum) => acc + datum.count, 0);

      return chartData.map((datum) => {
        const totalStatus = datum.pass + datum.failStatus;

        return {
          ...datum,
          count: datum.count / totalCount,
          pass: datum.pass / totalStatus,
          fail: datum.fail / totalStatus,
          incomplete: datum.incomplete != null ? datum.incomplete / totalStatus : undefined,
          failStatus: datum.failStatus / totalStatus,
        };
      });
    }, [chartData]);

    const toggleBarVisibility = (dataKey: string) => {
      if (hiddenBars.includes(dataKey)) {
        setHiddenBars(hiddenBars.filter((bar) => bar !== dataKey));
      } else {
        setHiddenBars([...hiddenBars, dataKey]);
      }
    };
    const { t, language } = useTranslation("reporting");

    const passBar = {
      dataKey: "pass" as const,
      name: t("chart.pass"),
      color: "#8cbb73",
    };
    const failStatusBar = {
      dataKey: "failStatus" as const,
      name: t("chart.fail"),
      color: "#be6054",
    };
    const failBar = {
      ...failStatusBar,
      dataKey: "fail" as const,
    };
    const incompleteBar = {
      dataKey: "incomplete" as const,
      name: t("chart.incomplete"),
      color: "#edae42",
    };
    const countBar = {
      dataKey: "count" as const,
      name: t("chart.count"),
      color: "#617cba",
    };

    const barStacks: Record<DisplayByValue, BarStack[]> = {
      status: [passBar, failStatusBar],
      result: [passBar, failBar, incompleteBar],
      count: [countBar],
    };

    const formatValue = (value: string | number): string =>
      isPercentageShown && typeof value === "number"
        ? value.toLocaleString(language, { style: "percent", maximumFractionDigits: 2 })
        : value.toString();

    const renderBarLabel = ({ x, y, width, height, value }: LabelProps) => {
      if (
        typeof x !== "number" ||
        typeof y !== "number" ||
        typeof width !== "number" ||
        typeof height !== "number" ||
        typeof value === "undefined"
      ) {
        return null;
      }

      // Display the label horizontally
      if (height >= 16 && width >= 48) {
        return (
          <Text x={x + width / 2} y={y + height / 2} textAnchor="middle" verticalAnchor="middle">
            {formatValue(value)}
          </Text>
        );
      }

      // Display the label vertically
      if (height >= 10 && width >= 10) {
        return (
          <Text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            verticalAnchor="middle"
            angle={-90}
          >
            {formatValue(value)}
          </Text>
        );
      }

      return null;
    };

    return (
      <div className="bar-chart-graph" style={{ width: "100%", height: 640 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            className={classNames({ "drill-down": onDrillDown })}
            data={isPercentageShown ? percentageData : chartData}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            stackOffset={isPercentageShown && hiddenBars.length === 0 ? "expand" : "none"}
            ref={ref}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              // TODO: translate data labels (backend - GLOB-3301)
              dataKey="label"
              type="category"
              height={160}
              angle={-45}
              textAnchor="end"
              // Increase the interval by 1 every 70 items
              interval={Math.floor(chartData.length / 70)}
              onClick={(data) => {
                // Recharts is garbage and doesn't type its own events properly.
                const { value: valueFromChart } = data as unknown as { value: string };
                onDrillDown?.(valueFromChart);
              }}
            />
            <YAxis type="number" tickFormatter={(value: string | number) => formatValue(value)} />
            {/* The tooltip stutters while in dev, but should be fine once deployed */}
            <Tooltip formatter={(value: string | number) => formatValue(value)} />
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                  -- The type provided by Rechart for the data arg is broken and always end up being any */}
            <Legend onClick={(data) => toggleBarVisibility(data.dataKey)} />
            {barStacks[displayBy].map(({ dataKey, name, color }, _, array) => (
              <Bar
                key={dataKey}
                dataKey={dataKey}
                name={name}
                fill={color}
                stackId={array.length > 1 ? displayBy : undefined}
                hide={hiddenBars.includes(dataKey)}
                onClick={(data: { label: string }) => {
                  const { label: valueFromChart } = data;
                  onDrillDown?.(valueFromChart);
                }}
              >
                <LabelList dataKey={dataKey} content={renderBarLabel} />
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default BarChartGraph;
