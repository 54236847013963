import { createSelector } from "@reduxjs/toolkit";
import keys from "lodash/keys";

import { getDefinitionsByPlatformByClassName } from "~/selectors/testDefinitions.selectors";

export const getTestSuiteIsLoading = (state) => state.modules.testSuitesManagement.isLoading;
export const getTestSuiteIsUpdating = (state) => state.modules.testSuitesManagement.isUpdating;
export const getTestSuiteIsCreating = (state) => state.modules.testSuitesManagement.isCreating;
export const getOriginalTestSuite = (state) => state.modules.testSuitesManagement.original;
export const getTestSuiteModifications = (state) =>
  state.modules.testSuitesManagement.modifications;

export const getCurrentTestSuite = createSelector(
  [getOriginalTestSuite, getTestSuiteModifications],
  (original, modifications) => ({
    testIdentifiers: [],
    ...original,
    ...modifications,
  })
);

export const getCategoriesWithDefinitions = createSelector(
  [(state) => state.testCategories.items, getDefinitionsByPlatformByClassName, getCurrentTestSuite],
  (categories, definitionsByPlatformByClassName, testSuite) => {
    const platformDefinitions = definitionsByPlatformByClassName[testSuite.platform] || {};

    return categories.map((category) => {
      const definitions = (category.classNames || [])
        .map((className) => platformDefinitions[className] || null)
        .reduce((acc, item) => (item == null ? acc : [...acc, ...item]), []);

      return {
        ...category,
        definitions,
        selectedIdentifiers: testSuite.testIdentifiers.filter((identifier) => {
          for (let i = 0; i < definitions.length; i++) {
            if (definitions[i].identifier === identifier) {
              return true;
            }
          }
        }),
      };
    });
  }
);

export const hasCurrentTestSuiteChanged = createSelector(
  [getTestSuiteModifications],
  (modifications) => keys(modifications).length > 0
);
