import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchTestSuiteBegin = createAction("testSuiteEditor/FETCH_TEST_SUITE_BEGIN");
export const fetchTestSuiteSuccess = createAction("testSuiteEditor/FETCH_TEST_SUITE_SUCCESS");
export const fetchTestSuiteError = createAction("testSuiteEditor/FETCH_TEST_SUITE_ERROR");

export const deleteTestSuiteBegin = createAction("DELETE_TEST_SUITE_BEGIN");
export const deleteTestSuiteSuccess = createAction("DELETE_TEST_SUITE_SUCCESS");
export const deleteTestSuiteError = createAction("DELETE_TEST_SUITE_ERROR");

export const modifyTestSuite = createAction("testSuiteEditor/MODIFY_TEST_SUITE");
export const setTestSuite = createAction("testSuiteEditor/SELECT_TEST_SUITE");
export const resetTestSuite = createAction("testSuiteEditor/RESET_TEST_SUITE");

function testSuiteToServerFormat(testSuite = {}) {
  const formatted = {
    name: testSuite.name || "",
    numberOfOperatorAudits: testSuite.nbOperatorAudits,

    failCodeDisplayMode: testSuite.failCodeDisplayMode,
    deviceSupportLevel: testSuite.deviceSupportLevel,

    isFailFast: testSuite.isFailFast,
    autoStart: testSuite.autoStart,
    disableNavigationButtons: testSuite.disableNavigationButtons,
    orientationLock: testSuite.orientationLock,
    rapidTest: testSuite.rapidTest,
    singleAppMode: testSuite.singleAppMode,
    platform: testSuite.platform || "",
    testIdentifiers: testSuite.testIdentifiers || [],
  };

  return formatted;
}

export const fetchTestSuite = (tsid) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        test_suite(tsid: "${tsid || ""}") {
            tsid,
            name,
            nbOperatorAudits,
            failCodeDisplayMode,
            deviceSupportLevel,
            isFailFast,
            autoStart,
            singleAppMode,
            disableNavigationButtons,
            orientationLock,
            rapidTest,
            platform,
            testIdentifiers
        }
    }`);

  dispatch(fetchTestSuiteBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const testSuite = (((response || {}).payload || {}).data || {}).test_suite;

    await dispatch(fetchTestSuiteSuccess({ testSuite }));
  } catch (e) {
    const errors = {};
    if (e.response.status === 404) {
      errors.missing = "Test suite not found";
    }

    dispatch(fetchTestSuiteError({ errors }));
  }
};

// Set selected test suite to an empty test suite
export const initEmptyTestSuite = () =>
  setTestSuite({
    testSuite: {
      name: "",
      nbOperatorAudits: undefined,

      failCodeDisplayMode: undefined,
      deviceSupportLevel: undefined,

      isFailFast: false,
      autoStart: false,
      singleAppMode: false,
      disableNavigationButtons: false,
      orientationLock: false,
      rapidTest: false,

      platform: undefined,
      testIdentifiers: [],
    },
  });

export const createTestSuite = createAsyncThunk(
  "testSuite/create",
  async (testSuite, { rejectWithValue }) => {
    try {
      const response = await CustomerManagement.POST(
        "/test-suites",
        testSuiteToServerFormat(testSuite)
      );
      const tsid = response.payload;

      return tsid;
    } catch (e) {
      if (e instanceof Error) throw e;

      return rejectWithValue({ errors: e.json.errors || {} });
    }
  }
);

export const updateTestSuite = createAsyncThunk(
  "testSuite/update",
  async (testSuite, { rejectWithValue }) => {
    try {
      const response = await CustomerManagement.PUT(
        `/test-suite/${testSuite.tsid}`,
        testSuiteToServerFormat(testSuite)
      );

      return response.payload;
    } catch (e) {
      if (e instanceof Error) throw e;

      return rejectWithValue({ errors: e.json.errors || {} });
    }
  }
);

export const deleteTestSuite =
  (testSuite, cleanProgram = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentTestSuites = state.testSuites.items;
    const testSuites = currentTestSuites.filter(
      (currentTestSuite) => currentTestSuite.tsid !== testSuite.tsid
    );
    const url = `/test-suites/${testSuite.tsid}${cleanProgram ? "?cleanProgram=true" : ""}`;

    dispatch(deleteTestSuiteBegin());

    try {
      await CustomerManagement.DELETE(url);

      dispatch(deleteTestSuiteSuccess({ testSuites }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message = e.json?.message ?? "Request didn't complete successfully";
      const affectedPrograms = e.json?.payload ?? null;

      dispatch(deleteTestSuiteError({ error: message, affectedPrograms }));
    }
  };
