// Editor to go with each service on a service suite
import React from "react";
import PropTypes from "prop-types";
import * as workflowEvents from "~/workflow/workflowEvents";
import EventEditor from "~/workflow/components/editors/EventEditor";

const ServiceEventEditor = (props) => {
  const { eventId, serviceIdentifier } = props;

  return (
    <div>
      <EventEditor eventId={eventId} serviceIdentifier={serviceIdentifier} />
    </div>
  );
};

const ServiceEventsEditor = (props) => {
  const { serviceIdentifier, serviceName } = props;

  return (
    <div style={{ marginTop: "1rem" }}>
      <ServiceEventEditor
        eventId={workflowEvents.ServiceStarted}
        serviceIdentifier={serviceIdentifier}
        serviceName={serviceName}
      />
      <ServiceEventEditor
        eventId={workflowEvents.ServiceCompleted}
        serviceIdentifier={serviceIdentifier}
        serviceName={serviceName}
      />
    </div>
  );
};

ServiceEventsEditor.propTypes = {
  serviceIdentifier: PropTypes.string,
  serviceName: PropTypes.string,
};

export default ServiceEventsEditor;
