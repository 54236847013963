import React, { useState } from "react";

const useErrorState = (initialState) => {
  const [errors, setErrors] = useState(initialState);

  const setMultiErrors = (obj) => {
    const output = { ...errors };
    Object.keys(obj).map((key) => {
      if (obj[key] !== undefined) {
        output[key] = obj[key];
      } else {
        delete output[key];
      }
    });
    setErrors(output);
  };

  const removeError = (key) => setMultiErrors({ [key]: undefined });

  return [errors, setMultiErrors, removeError];
};

export default useErrorState;
