import React from "react";
import type { ReactNode } from "react";
import { Button } from "react-md";
import classnames from "classnames";

type Props = {
  id?: string;
  className?: string;
  color?: "white" | "red" | "green" | "blue";
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  children: ReactNode;
  tabIndex?: number;
};

const AsciButton = React.forwardRef<any, Props>(
  ({ id, className = "", color = "white", onClick, disabled = false, children, tabIndex }, ref) => (
    <Button
      ref={ref}
      raised
      id={id}
      className={classnames("info-dialog asci-btn row centered", `${color}-button`, className)}
      onClick={onClick}
      disabled={disabled != null ? disabled : false}
      tabIndex={tabIndex}
    >
      {children}
    </Button>
  )
);

export default AsciButton;
