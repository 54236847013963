import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";

import { AsciButton, BorderedGroup, DateTimeSelector, Row } from "~/global";
import moduleActions from "~/user-logs/actions";

import "./UserLogsFilters.component.scss";

const UserLogsFilters = ({ dateFrom, dateTo, datesCallBack, fetchUserLogs }) => {
  const timeRange = {
    dateFrom,
    dateTo,
  };

  useEffect(() => {
    generateLogs(timeRange);
  }, []);

  const generateLogs = async (timeRange) => {
    const requestStatus = await fetchUserLogs(timeRange);
    if (requestStatus) {
      message.success("Request completed successfully");
    } else {
      message.error("Request didn't complete successfully");
    }
  };

  return (
    <BorderedGroup className="user-logs-filters">
      <Row className="full-width">
        <DateTimeSelector
          dateFrom={dateFrom}
          dateTo={dateTo}
          datesCallBack={(dates) => datesCallBack(dates)}
        />
        <AsciButton className="generate-log-btn" onClick={() => generateLogs(timeRange)}>
          GENERATE LOGS
        </AsciButton>
      </Row>
    </BorderedGroup>
  );
};

UserLogsFilters.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  datesCallBack: PropTypes.func.isRequired,
  programs: PropTypes.array,
};

const stateToProps = () => ({});

const dispatchToProps = {
  fetchUserLogs: moduleActions.userActivityData.fetchUserLogs,
};

export default connect(stateToProps, dispatchToProps)(UserLogsFilters);
