import { combineReducers } from "redux";

import sessions from "./sessions.redux";
import sessionDetails from "./sessionDetails.redux";

import workstations from "./workstations.redux";
import workstationDetails from "./workstationDetails.redux";
import workstationNamer from "./workstationNamer.redux";

import tasks from "./tasks.redux";
import taskDetails from "./taskDetails.redux";
import taskSender from "./taskSender.redux";
import taskCancel from "./taskCancel.redux";

import crashDetails from "./crashDetails.redux";

import issues from "./issues.redux";

import crashReportDetails from "./crashReportDetails.redux";

const selectors = {
  sessions: sessions.selectors,
  sessionDetails: sessionDetails.selectors,
  workstations: workstations.selectors,
  workstationDetails: workstationDetails.selectors,
  workstationNamer: workstationNamer.selectors,
  tasks: tasks.selectors,
  taskDetails: taskDetails.selectors,
  taskSender: taskSender.selectors,
  taskCancel: taskCancel.selectors,
  crashDetails: crashDetails.selectors,
  issues: issues.selectors,
  crashReportDetails: crashReportDetails.selectors,
};

const actions = {
  sessions: sessions.actions,
  sessionDetails: sessionDetails.actions,
  workstations: workstations.actions,
  workstationDetails: workstationDetails.actions,
  workstationNamer: workstationNamer.actions,
  tasks: tasks.actions,
  taskDetails: taskDetails.actions,
  taskSender: taskSender.actions,
  taskCancel: taskCancel.actions,

  crashDetails: crashDetails.actions,
  issues: issues.actions,

  crashReportDetails: crashReportDetails.actions,
};

export default combineReducers({
  sessions: sessions.reducer,
  sessionDetails: sessionDetails.reducer,
  workstations: workstations.reducer,
  workstationDetails: workstationDetails.reducer,
  workstationNamer: workstationNamer.reducer,
  tasks: tasks.reducer,
  taskDetails: taskDetails.reducer,
  taskSender: taskSender.reducer,
  taskCancel: taskCancel.reducer,
  crashDetails: crashDetails.reducer,
  issues: issues.reducer,
  crashReportDetails: crashReportDetails.reducer,
});

export { selectors, actions };
