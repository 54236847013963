import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontIcon, Grid, Cell, TextField } from "react-md";

import { AsciButton } from "~/global";
import actions from "~/actions";

const DialogChangePassword = ({ onCancel, onConfirm, onUpdateSuccess, onUpdateError }) => {
  const ICON_PASSWORD = <FontIcon iconClassName="icon-eye" />;
  const PASSWORD_FORMAT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onCommitConfirmation = async () => {
    onConfirm();

    try {
      await actions.user.changePassword(newPassword);
      onUpdateSuccess();
    } catch (e) {
      if (e instanceof Error) throw e;
      onUpdateError(e);
    }
  };

  const onChangePassword = (value) => {
    const newValue = value.trim();

    setNewPassword(newValue);
    setIsPasswordMatching(confirmPassword === newValue);
    setIsValidPassword(PASSWORD_FORMAT.test(newValue));
  };

  const onChangeConfirmation = (value) => {
    const newValue = value.trim();

    setConfirmPassword(newValue);
    setIsPasswordMatching(newPassword === newValue);
    setIsValidPassword(PASSWORD_FORMAT.test(newValue));
  };

  return (
    <div>
      <Grid className="full-width">
        <Cell size={5} tabletSize={12} phoneSize={12} className="row centered to-end">
          Enter New Password:
        </Cell>
        <Cell size={7} tabletSize={12} phoneSize={12}>
          <TextField
            id="newPassword-text-field"
            lineDirection="left"
            placeholder="Enter password"
            value={newPassword}
            className="table-text-field min-margin"
            onChange={onChangePassword}
            type="password"
            passwordIcon={ICON_PASSWORD}
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={5} tabletSize={12} phoneSize={12} className="row centered to-end">
          Confirm Password:
        </Cell>
        <Cell size={7} tabletSize={12} phoneSize={12}>
          <TextField
            id="confirmPassword-text-field"
            lineDirection="left"
            placeholder="Enter password"
            value={confirmPassword}
            className="table-text-field min-margin"
            onChange={onChangeConfirmation}
            error={!isPasswordMatching}
            errorText="Password doesn't match"
            type="password"
            passwordIcon={ICON_PASSWORD}
          />
        </Cell>
        <p className="error-message">
          {isPasswordMatching && !isValidPassword
            ? "Password must contain at least eight characters and at least one number and both lower and uppercase letters"
            : null}
        </p>
      </Grid>

      <div className="layout-row layout-row--center-center full-width">
        <AsciButton color="white" onClick={onCancel}>
          Cancel
        </AsciButton>
        <AsciButton
          color="blue"
          disabled={!isValidPassword || !isPasswordMatching}
          onClick={onCommitConfirmation}
        >
          Confirm
        </AsciButton>
      </div>
    </div>
  );
};

DialogChangePassword.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateError: PropTypes.func.isRequired,
};

export default DialogChangePassword;
