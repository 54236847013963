import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

import "./SameDataComposedChart.component.scss";

const SameDataComposedChart = ({ title, data, medianMean, ref }) => (
  <div className="composed-chart">
    <div className="chart-header">
      <div className="chart-title">{title}</div>
    </div>

    <ResponsiveContainer height="80%">
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          unit="s"
          label={{ value: "Mean Test Duration (seconds)", position: "bottom" }}
        />
        <YAxis dataKey="Test" type="category" width={150} />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          height={38}
          wrapperStyle={{ bottom: 0 }}
          payload={[
            {
              value: "Median Test Time for All Tests",
              type: "plainline",
              color: "#FF7D92",
              payload: { strokeDasharray: 0 },
            },
          ]}
        />
        <Bar dataKey="Mean" fill="#2A6EBF" maxBarSize={15} name="Mean Test Duration" />
        <Line
          dataKey="Mean"
          type="linear"
          stroke="#424242"
          strokeWidth={3}
          dot={{ r: 7 }}
          isAnimationActive={false}
          name="Mean Test Duration"
        />
        <ReferenceLine
          x={medianMean}
          stroke="#FF7D92"
          strokeWidth={4}
          label={{
            value: `${medianMean}s`,
            position: "bottom",
            offset: 8,
            fill: "#FF7D92",
            fontWeight: "bold",
          }}
          isFront
        />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const formattedMean = new Date(payload[0].value * 1000).toISOString().substr(14, 7);
    const mean = payload[0].value >= 60 ? formattedMean : payload[0].value;

    return (
      <div className="recharts-default-tooltip">
        <p className="recharts-tooltip-label">
          {label}: <span className="accent">{mean}s</span>
        </p>
      </div>
    );
  }

  return null;
};

export default SameDataComposedChart;
