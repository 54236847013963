import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

import CustomerModel from "~/models/CustomerModel";

export const fetchCurrentCustomerCSIDBegin = createAction("FETCH_CURRENT_CUSTOMER_CSID_BEGIN");
export const fetchCurrentCustomerCSIDSuccess = createAction("FETCH_CURRENT_CUSTOMER_CSID_SUCCESS");
export const fetchCurrentCustomerProcessRouteSuccess = createAction(
  "FETCH_CURRENT_CUSTOMER_PROCESS_ROUTE_SUCCESS"
);
export const fetchCurrentCustomerCSIDError = createAction("FETCH_CURRENT_CUSTOMER_CSID_ERROR");

export const changeActiveCustomerBegin = createAction("CHANGE_ACTIVE_CUSTOMER_BEGIN");
export const changeActiveCustomerSuccess = createAction("CHANGE_ACTIVE_CUSTOMER_SUCCESS");
export const changeActiveCustomerError = createAction("CHANGE_ACTIVE_CUSTOMER_ERROR");

export function fetchCustomerId(csname) {
  csname = csname.replace(/ /g, "%20");
  const query = CustomerManagement.gqlBuilder(`query CustomerIdGenerator {
        customer_id_generator(csname: "${csname}")
    }`);

  return CustomerManagement.QUERY(query)
    .then((response) => {
      if (response.payload.data != null && response.payload.data.customer_id_generator != null) {
        const customerId = response.payload.data.customer_id_generator;
        return customerId;
      }
      return null;
    })
    .catch((error) => error);
}

export const fetchMyCustomer = () => async (dispatch) => {
  try {
    dispatch(fetchCurrentCustomerCSIDBegin());
    const query = CustomerManagement.gqlBuilder(
      `query MyCustomer {my_customer{csid, processRoutes}}`
    );
    const response = await CustomerManagement.QUERY(query);
    const customer = ((response.payload || {}).data || {}).my_customer || {};
    dispatch(fetchCurrentCustomerCSIDSuccess(customer.csid));
    dispatch(fetchCurrentCustomerProcessRouteSuccess(customer.processRoutes));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchCurrentCustomerCSIDError({ errors: e }));
  }
};

export function fetchCustomer() {
  const query = `{my_customer{csid, name, programs, services}}`;
  return CustomerManagement.QUERY(query)
    .then((response) => {
      const customer = new CustomerModel(response.payload.data.my_customer);
      return customer;
    })
    .catch((error) => {
      console.error(error);
    });
}

export const changeActiveCustomer = (csid) => async (dispatch) => {
  try {
    dispatch(changeActiveCustomerBegin());
    const query = CustomerManagement.gqlBuilder(`query ChangeActiveCustomer {
            customer(csid: "${csid}", updateUserClient: true) {
                name
            }
        }`);
    const response = await CustomerManagement.QUERY(query);

    dispatch(changeActiveCustomerSuccess(csid));
    return response;
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(changeActiveCustomerError({ errors: e }));
  }
};
