import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, TextField } from "react-md";
import { endOfDay, startOfDay } from "date-fns";

import { useTranslation } from "~/i18n";
import { AsciButton, Page } from "~/global";
import { formatDate } from "~/global/utils";
import DatePicker from "./DatePicker/DatePicker.component";
import TimePicker from "./TimePicker/TimePicker.component";

import "./DateTimeSelector.component.scss";

const DateTimeSelector = ({
  datesCallBack,
  dateFrom = "",
  dateTo = "",
  hideTimePicker = false,
  includeClearDate = false,
  label = true,
  maxRange = null,
  includeRelativeDate = false,
}) => {
  const { t } = useTranslation("dateTimeSelector");
  const [dialog, setDialog] = useState(null);
  const [activeDialog, setActiveDialog] = useState("");

  function setDates(startDate, endDate, selectedRange) {
    if (startDate != null && endDate != null) {
      const dateFrom = moment(startDate).format("YYYY-MM-DDTHH:mm:ssZZ");
      const dateTo = moment(endDate).format("YYYY-MM-DDTHH:mm:ssZZ");

      datesCallBack({ dateFrom, dateTo, selectedRange });

      setDialog(null);
      setActiveDialog("");
    } else {
      setDialog(null);
      setActiveDialog("");
    }
  }

  function selectDates() {
    const startDate = dateFrom ?? startOfDay(new Date()).toISOString();
    const endDate = dateTo ?? endOfDay(new Date()).toISOString();

    const dialog = {
      modal: true,
      width: "90%",
      title: `${t("select-dates")}`,
      content: (
        <>
          <div className="close-button">
            <Button
              icon
              className="icon-btn text-color--grey"
              iconClassName="icon-close"
              onClick={() => setDates()}
            />
          </div>
          <DatePicker
            setDates={(startDate, endDate, selectedRange) =>
              setDates(startDate, endDate, selectedRange)
            }
            startDate={startDate}
            endDate={endDate}
            maxRange={maxRange}
            includeRelativeDate={includeRelativeDate}
          />
        </>
      ),
    };
    setDialog(dialog);
    setActiveDialog("date-picker");
  }

  function selectTimes() {
    const timeFrom = dateFrom ?? startOfDay(new Date()).toISOString();
    const timeTo = dateTo ?? endOfDay(new Date()).toISOString();
    const dialog = {
      modal: true,
      width: "90%",
      title: `${t("select-times")}`,
      content: (
        <div>
          <div className="close-button">
            <Button
              icon
              className="icon-btn text-color--grey"
              iconClassName="icon-close"
              onClick={() => setDates()}
            />
          </div>
          <TimePicker
            dateFrom={timeFrom}
            dateTo={timeTo}
            setDates={(startTime, endTime) => setDates(startTime, endTime)}
          />
        </div>
      ),
    };
    setDialog(dialog);
    setActiveDialog("time-picker");
  }

  function clearDates() {
    datesCallBack();
  }
  const from = dateFrom != null ? formatDate(moment(dateFrom)) : "";
  const to = dateTo != null ? formatDate(moment(dateTo)) : "";

  return (
    <Page dialog={dialog} className={`date-time-selector ${activeDialog}`}>
      <div className="row-wrap">
        <div className="row">
          {label && <div className="label">{t("time-frame")}</div>}

          <div className="btn-with-text">
            <AsciButton color="white" onClick={() => selectDates()}>
              <i className="icon-calendar" />
              &nbsp; {t("set-dates")}
            </AsciButton>
          </div>

          {!hideTimePicker && (
            <div className="btn-with-text">
              <AsciButton color="white" onClick={() => selectTimes()}>
                <i className="icon-clock-o" />
                &nbsp; {t("set-times")}
              </AsciButton>
            </div>
          )}
        </div>

        <div className="space" />

        <div className="row">
          <div className="horizontal-label-margin">{t("from")}</div>
          <TextField id="date-from" className="cc-textfield" type="text" disabled value={from} />
        </div>

        <div className="space" />

        <div className="row">
          <div className="horizontal-label-margin">{t("to")}</div>
          <TextField id="date-to" className="cc-textfield" type="text" disabled value={to} />
        </div>

        {includeClearDate ? (
          <div className={to.length === 0 || from.length === 0 ? "asci-hide" : ""}>
            <Button
              icon
              className="btn-icon text-color--blue"
              iconClassName="icon-times-circle"
              onClick={() => clearDates()}
              disabled={to.length === 0 && from.length === 0}
            >
              <div>{t("clear")}</div>
            </Button>
          </div>
        ) : null}
      </div>
    </Page>
  );
};

DateTimeSelector.propTypes = {
  datesCallBack: PropTypes.func.isRequired,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  maxRange: PropTypes.object,
  includeClearDate: PropTypes.bool,
  hideTimePicker: PropTypes.bool,
  label: PropTypes.bool,
  includeRelativeDate: PropTypes.bool,
};

export default DateTimeSelector;
