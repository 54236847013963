import { createReducer } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import actions from "~/actions";
import type { ShortFacility } from "~/actions/facilities.actions";

interface FacilitiesState {
  items?: ShortFacility[];
  error?: string;
  status: "idle" | "loading" | "error";
}

const initialState: FacilitiesState = {
  status: "idle",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.facilities.fetchFacilitiesBegin, (state) => {
      delete state.error;
      state.status = "loading";
    })
    .addCase(actions.facilities.fetchFacilitiesSuccess, (state, action) => {
      const currentFacilities = state.items?.map((f) => f.faid) ?? [];
      const newFacilities = action.payload.facilities.map((f) => f.faid);

      if (!isEqual(newFacilities, currentFacilities)) {
        state.items = action.payload.facilities;
      }

      state.status = "idle";
    })
    .addCase(actions.facilities.fetchFacilitiesError, (state, action) => {
      state.error = action.payload.error;
      state.status = "error";
    })
);
