import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Switch, message } from "antd";
import qs from "query-string";

import { Button, Row } from "~/global";

import "./LabelDesigner.scss";

import LabelEditor from "./editor/LabelEditor";
import LabelInspector from "./inspector/LabelInspector";
import LabelStatics from "~/label-printer/components/LabelStatics";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";
import { use } from "i18next";

export const LabelDesigner = ({
  labelName,
  layoutName,
  layoutData,
  width,
  height,
  shapes,
  displayShapeBorder,
  setLabelName,
  saveLabel,
  setSaved,
  setDisplayShapeBorder,
  setPrintPreview,
  loadedLabelId,
  loadLabel,
  setLoadedLabelId,

  saving,
  saved,
}) => {
  const [editorHeight, setEditorHeight] = useState(null);
  const [editorWidth, setEditorWidth] = useState(null);
  const spacerRef = useRef(null);
  const editorRef = useRef(null);
  const { labelId } = qs.parse(useLocation().search);
  const history = useHistory();

  useEffect(() => {
    if (labelId && !loadedLabelId) {
      loadLabel(labelId);
    }
  }, [loadedLabelId, labelId, loadLabel]);

  // Set the editor height as inspector maxHeight so only the inspector scrolls, keeping the label in place.
  useEffect(() => {
    if (spacerRef.current) {
      const { height } = spacerRef.current.getBoundingClientRect();
      setEditorHeight(height);
    }
    if (editorRef.current) {
      const { width } = spacerRef.current.getBoundingClientRect();
      setEditorWidth(width);
    }
  }, [spacerRef.current, editorRef.current]);

  useEffect(() => {
    if (saved) {
      message.info("Saved!", 5, () => setSaved(false));
    }
  }, [saved]);

  const onExit = () => {
    setLoadedLabelId(null);
    history.push(`/label-printer`);
  };

  return (
    <LabelStatics>
      <div className="label-designer">
        <Row className="label-designer-header" align="space-between center">
          <Row align="space-around center">
            <Row className="header-field" align="center center">
              <div className="header-label">Name</div>
              <input
                className="header-input"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
              />
            </Row>
            <Row className="header-field" align="center center">
              <div className="header-label">Display Borders</div>
              <Switch checked={displayShapeBorder} onChange={setDisplayShapeBorder} />
            </Row>
          </Row>
          <Row align="space-between center" style={{ width: "12rem" }}>
            <Button className="print-btn" onClick={() => setPrintPreview(true)}>
              Print
            </Button>
            <Button onClick={saveLabel} className="save-btn" disabled={saving}>
              Save
            </Button>
            <Button onClick={onExit} className="save-btn" disabled={saving}>
              Exit
            </Button>
          </Row>
        </Row>
        <div className="label-designer-body">
          <div className="label-designer-spacer" ref={spacerRef} />
          <LabelEditor
            width={width}
            height={height}
            shapes={shapes}
            maxHeight={editorHeight}
            maxWidth={editorWidth}
            layoutName={layoutName}
            layoutData={layoutData}
          />
          <LabelInspector
            layoutName={layoutName}
            layoutData={layoutData}
            maxHeight={editorHeight}
          />
        </div>
      </div>
    </LabelStatics>
  );
};

const stateToProps = (state) => ({
  labelName: labelSelectors.labelDesigner.getName(state),
  layoutName: labelSelectors.labelDesigner.getLayoutName(state),
  layoutData: labelSelectors.labelDesigner.getLayoutData(state),
  width: labelSelectors.labelDesigner.getLabelWidth(state),
  height: labelSelectors.labelDesigner.getLabelHeight(state),
  shapes: labelSelectors.labelDesigner.getShapes(state),
  displayShapeBorder: labelSelectors.labelPicker.isDisplayShapeBorder(state),

  saving: labelSelectors.labelSaver.isSaving(state),
  saved: labelSelectors.labelSaver.isSaved(state),

  loading: labelSelectors.labelSaver.isLoading(state),
  loaded: labelSelectors.labelSaver.isLoaded(state),

  loadedLabelId: labelSelectors.labelSaver.getLoadedLabelId(state),
});

const dispatchToProps = {
  setFormatData: labelActions.labelDesigner.setFormatData,
  setLabelName: labelActions.labelDesigner.setLabelName,
  setLabelDescription: labelActions.labelDesigner.setLabelDescription,
  saveLabel: labelActions.labelSaver.saveLabel,
  setDisplayShapeBorder: labelActions.labelPicker.setDisplayShapeBorder,
  setPrintPreview: labelActions.labelPrint.setPrintPreview,
  loadLabel: labelActions.labelSaver.loadLabel,
  setSaved: labelActions.labelSaver.setSaved,
  setLoadedLabelId: labelActions.labelSaver.setLoadedLabelId,
};

export default connect(stateToProps, dispatchToProps)(LabelDesigner);
