import React from "react";
import type { CSSProperties } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";
import type { SelectValue } from "antd/lib/select";

const { Option, OptGroup } = Select;

interface SelectWithLabelProps<VT> extends SelectProps<VT> {
  label: string;
  labelPosition?: "top" | "left";
  selectWidth?: number;
}

/**
 * This component extends antd's [Select](https://ant.design/components/select/) with a label that can
 * either be postionned above (`labelPosition="top"`) or beside (`labelPosition="left"`) the Select.
 */
const SelectWithLabel = <VT extends SelectValue = SelectValue>({
  label,
  labelPosition = "top",
  selectWidth,
  style,
  children,
  ...rest
}: SelectWithLabelProps<VT>) => {
  const containerStyle: CSSProperties = {
    ...style,
    display: "inline-flex",
  };
  const labelStyle: CSSProperties = {};

  if (labelPosition === "top") {
    containerStyle.flexDirection = "column";
    labelStyle.marginBottom = "0.25em";
  } else {
    containerStyle.flexDirection = "row";
    containerStyle.alignItems = "center";
    labelStyle.marginRight = "0.5em";
  }

  return (
    <div className="cc-select-with-label" style={containerStyle}>
      <div style={labelStyle}>{label}</div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Select style={{ width: selectWidth }} {...rest}>
        {children}
      </Select>
    </div>
  );
};

export { SelectWithLabel, Option, OptGroup };
