import { createSelector } from "@reduxjs/toolkit";

import moduleSelectors from ".";

const getCanSaveCustomerIdentity = (state) =>
  moduleSelectors.customerIdentity.getCanSaveCustomerIdentity(state);
const getCanSaveFacility = (state) =>
  moduleSelectors.customerFacilityEditor.getCanSaveCustomerFacility(state);
const getCanSaveUser = (state) => moduleSelectors.customerUserEditor.getCanSaveCustomerUser(state);
const getUserPassword = (state) => moduleSelectors.customerUserEditor.getUserPassword(state);
const getPasswordError = (state) =>
  state.modules.customerManagement.customerUserEditor.passwordError;

export const saveDisabled = createSelector(
  [
    getCanSaveCustomerIdentity,
    getCanSaveFacility,
    getCanSaveUser,
    getUserPassword,
    getPasswordError,
  ],
  (identityReady, facilityReady, userReady, userPassword, passwordError) => {
    const passwordValid = userPassword.length > 0 && !passwordError;
    return !identityReady || !facilityReady || !userReady || !passwordValid;
  }
);
