import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AsciSpinner, AsciButton, Row, Column } from "~/global";

import actions from "~/actions";
import moduleActions from "~/service-suite-templates/actions/";

import TemplateList from "../TemplateList/TemplateList.component";
import AddNewTemplateDialog from "../AddNewTemplateDialog/AddNewTemplateDialog.component";

import "./ServiceSuiteTemplatesPage.component.scss";

const ServiceSuiteTemplatesPage = ({
  addPreparedTemplates,
  fetchAffectedPrograms,
  fetchTemplates,
  isLoading,
  originalTemplates,
  showDialog,
  templates,
}) => {
  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    addPreparedTemplates(originalTemplates);
  }, [originalTemplates]);

  useEffect(() => {
    templates.forEach((template) => {
      fetchAffectedPrograms(template);
    });
  }, [templates]);

  const addNewTemplate = () => {
    showDialog({
      title: "Add a new Template",
      width: "500px",
      modal: true,
      content: (close) => (
        <AddNewTemplateDialog
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  return (
    <Column className="service-suite-templates-page full-width" flex align="start center">
      <AsciSpinner visible={isLoading} />
      <div className="panel-container">
        <Row className="new-template-row">
          <AsciButton className="new-template-btn" color="blue" onClick={() => addNewTemplate()}>
            New Template
          </AsciButton>
        </Row>
        <TemplateList templates={templates} />
      </div>
    </Column>
  );
};

ServiceSuiteTemplatesPage.propTypes = {
  // From the store
  fetchAffectedPrograms: PropTypes.func.isRequired,
  addPreparedTemplates: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  originalTemplates: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
  isLoading: state.modules.serviceSuiteTemplates.isLoading,
  originalTemplates: state.modules.serviceSuiteTemplates.originalTemplates,
  templates: state.modules.serviceSuiteTemplates.templates,
});
const dispatchToProps = {
  fetchAffectedPrograms: moduleActions.serviceSuiteTemplates.fetchAffectedPrograms,
  addPreparedTemplates: moduleActions.serviceSuiteTemplates.addPreparedTemplates,
  fetchTemplates: moduleActions.serviceSuiteTemplates.fetchTemplates,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(ServiceSuiteTemplatesPage);
