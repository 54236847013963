import React, { useMemo } from "react";

import join from "lodash/join";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as fields from "~/label-printer/fields";
import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

import "./LabelShape.scss";

import BarcodePreview from "~/label-printer/components/designer/editor/previews/BarcodePreview";
import ImagePreview from "~/label-printer/components/designer/editor/previews/ImagePreview";
import RecordingPreview from "~/label-printer/components/designer/editor/previews/RecordingPreview";
import TextPreview from "~/label-printer/components/designer/editor/previews/TextPreview";
import QrCodePreview from "~/label-printer/components/designer/editor/previews/QrCodePreview";

const LabelShape = ({
  shape,
  isSelected,
  selectShape,
  preventPropagation,
  style,
  displayShapeBorder,
}) => {
  const { identity, fieldType, fieldData } = shape;

  const fieldProps = { identity, ...fieldData };

  const content = useMemo(() => {
    switch (fieldType) {
      case fields.BARCODE:
        return <BarcodePreview {...fieldProps} />;
      case fields.IMAGE:
        return <ImagePreview {...fieldProps} />;
      case fields.RECORDING:
        return <RecordingPreview {...fieldProps} />;
      case fields.TEXT:
        return <TextPreview {...fieldProps} />;
      case fields.QRCODE:
        return <QrCodePreview {...fieldProps} />;
    }
  }, [fieldType, fieldData]);

  const className = useMemo(() => {
    const css = ["label-shape"];
    if (isSelected) {
      css.push("shape-selected");
    }
    if (displayShapeBorder) {
      css.push("shape-border");
    }
    return join(css, " ");
  }, [isSelected, displayShapeBorder]);

  return (
    <div
      className={className}
      style={style}
      onClick={(e) => {
        if (preventPropagation) {
          e.stopPropagation();
        }
        selectShape({ identity });
      }}
    >
      {content}
    </div>
  );
};

LabelShape.propTypes = {
  shape: PropTypes.object.isRequired,
  preventPropagation: PropTypes.bool,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
  selectShape: PropTypes.func,
  displayShapeBorder: PropTypes.bool,
};

const stateToProps = (state, props) => ({
  isSelected: labelSelectors.labelPicker.isShapeSelected(props.shape)(state),
  style: {
    ...labelSelectors.labelDesigner.getShapeStyle(props.shape.identity)(state),
    ...props.style,
  },
  displayShapeBorder: labelSelectors.labelPicker.isDisplayShapeBorder(state),
});

const dispatchToProps = {
  selectShape: labelActions.labelPicker.selectShape,
};

export default connect(stateToProps, dispatchToProps)(LabelShape);
