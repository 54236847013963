import isEqual from "lodash/isEqual";

export default class VersionManagerModel {
  constructor(data = {}) {
    this.name = data.name;
    this.url = data.url;
    this.releaseNotes = data.releaseNotes;
    this.selected = data.selected;
  }

  clone() {
    const clone = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clone;
  }

  isEqual(unmodified) {
    const labelIsEqual = isEqual(this, unmodified);
    return labelIsEqual;
  }
}
