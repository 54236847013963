import { stringBoolToBool } from "~/global/utils";

export default class DeviceTestArgument {
  static TYPE_BOOLEAN = "boolean";
  static TYPE_STRING = "string";
  static TYPE_FLOAT = "float";
  static TYPE_INTEGER = "integer";
  static TYPE_LOCALIZABLE_STRING = "localizableString";
  static TYPE_BUNDLED_IMAGE = "BundledImage";
  static TYPE_PERCENT = "percent";
  static TYPE_SECONDS = "seconds";
  static NAME_TEST_LIST = "testList";
  static NAME_RUN_IF_DEPENDENT_TEST_FAILED = "runIfDependentTestFailed";
  static NAME_TEST_DEPENDENCY = "testDependency";

  static parse = (data = {}) => {
    const arg = {
      name: data.name,
      type: data.type || "",
    };

    if (DeviceTestArgument.isTypeBool(arg)) {
      arg.value = stringBoolToBool(data.value);
    } else if (DeviceTestArgument.isTypeString(arg)) {
      arg.value = data.value || "";
    } else if (DeviceTestArgument.isTypeInteger(arg)) {
      arg.value = +data.value;
    } else if (DeviceTestArgument.isTypeSeconds(arg)) {
      arg.value = +data.value;
    } else if (DeviceTestArgument.isTypeFloat(arg)) {
      arg.value = +data.value;
    } else if (DeviceTestArgument.isTypeLocalizableString(arg)) {
      const loc = data.localization || {};
      arg.localization = {
        en: loc.en || "",
        fr: loc.fr || "",
        es: loc.es || "",
      };
    } else if (DeviceTestArgument.isTypeBundledImage(arg)) {
      arg.value = data.value || "";
    } else if (DeviceTestArgument.isTypePercent(arg)) {
      arg.value = +data.value;
    } else {
      console.warn(`Unknown TestArgument type "${arg.type}"`, data);
    }

    return arg;
  };

  static getDefaultValue = (arg = {}) => {
    if (DeviceTestArgument.isTypeBool(arg)) {
      return stringBoolToBool(arg.defaultValue);
    }
    if (DeviceTestArgument.isTypeString(arg)) {
      return arg.defaultValue || "";
    }
    if (DeviceTestArgument.isTypeInteger(arg)) {
      return +arg.defaultValue;
    }
    if (DeviceTestArgument.isTypeSeconds(arg)) {
      return +arg.defaultValue;
    }
    if (DeviceTestArgument.isTypeFloat(arg)) {
      return +arg.defaultValue;
    }
    if (DeviceTestArgument.isTypeLocalizableString(arg)) {
      const loc = arg.localization || {};
      return {
        en: loc.en || "",
        fr: loc.fr || "",
        es: loc.es || "",
      };
    }
    if (DeviceTestArgument.isTypeBundledImage(arg)) {
      return arg.defaultValue || "";
    }
    if (DeviceTestArgument.isTypePercent(arg)) {
      return +arg.defaultValue;
    }
    console.warn(`Unknown TestArgument type "${arg.type}"`, arg);
  };

  static isTypeBool(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_BOOLEAN;
  }

  static isTypeString(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_STRING;
  }

  static isTestList(arg) {
    const name = arg?.name ?? "";
    return (
      name === DeviceTestArgument.NAME_TEST_LIST ||
      name === DeviceTestArgument.NAME_RUN_IF_DEPENDENT_TEST_FAILED ||
      name === DeviceTestArgument.NAME_TEST_DEPENDENCY
    );
  }

  static isTypeInteger(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_INTEGER;
  }

  static isTypeSeconds(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_SECONDS;
  }

  static isTypeFloat(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_FLOAT;
  }

  static isTypeLocalizableString(arg = {}) {
    const type = arg.type || ""; // localizableString is camelCase, don't lower case it
    return type == DeviceTestArgument.TYPE_LOCALIZABLE_STRING;
  }

  static isTypeBundledImage(arg = {}) {
    const type = arg.type || ""; // BundledImage has uppercase letters don't lower case it
    return type == DeviceTestArgument.TYPE_BUNDLED_IMAGE;
  }

  static isTypePercent(arg = {}) {
    const type = (arg.type || "").toLowerCase();
    return type == DeviceTestArgument.TYPE_PERCENT;
  }

  static toServerFormat(arg = {}) {
    const formatted = {
      name: arg.name,
      type: arg.type,
    };

    if (DeviceTestArgument.isTypeBool(arg)) {
      formatted.value = arg.value ? "true" : "false";
    } else if (DeviceTestArgument.isTypeString(arg)) {
      formatted.value = arg.value || "";
    } else if (DeviceTestArgument.isTypeInteger(arg)) {
      formatted.value = `${arg.value}`;
    } else if (DeviceTestArgument.isTypeSeconds(arg)) {
      formatted.value = `${arg.value}`;
    } else if (DeviceTestArgument.isTypeFloat(arg)) {
      formatted.value = `${arg.value}`;
    } else if (DeviceTestArgument.isTypeLocalizableString(arg)) {
      formatted.localization = arg.localization || {};
    } else if (DeviceTestArgument.isTypeBundledImage(arg)) {
      formatted.value = arg.value || "";
    } else if (DeviceTestArgument.isTypePercent(arg)) {
      formatted.value = `${arg.value}`;
    } else {
      console.warn(
        `Failed to parse TestArgument to Server format for type "${arg.type}", using data as is.`,
        arg
      );
      formatted.value = arg.value;
    }

    return formatted;
  }
}
