import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontIcon } from "react-md";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import {
  selectors as workstationSelectors,
  actions as workstationActions,
} from "~/workstation/redux";
import { Checkbox, Spinner } from "~/global";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import ListPage from "~/global/reduxer/components/ListPage";
import AutoFilter from "~/global/reduxer/components/AutoFilter";
import SessionStatus from "~/workstation/components/common/SessionStatus";

import "./WorkstationList.scss";
import DateFilter from "~/global/reduxer/components/DateFilter";
import Timestamp from "~/workstation/components/common/Timestamp";
import TaskSender from "~/workstation/components/common/TaskSender";

const PlatformIcon = ({ platform }) => {
  switch (platform) {
    case "Windows":
    case "win32":
      return (
        <>
          <FontIcon iconClassName="icon-windows8" forceSize={18} forceFontSize /> Windows
        </>
      );
    case "darwin":
      return (
        <>
          <FontIcon iconClassName="icon-apple" forceSize={18} forceFontSize /> Mac
        </>
      );
    case "linux":
      return (
        <>
          <FontIcon iconClassName="icon-tux" forceSize={18} forceFontSize /> Linux
        </>
      );
    default:
      return (
        <>
          <FontIcon forceSize={18} forceFontSize /> Unknown
        </>
      );
  }
};

const WorkstationList = () => {
  const workstationList = useSelector(workstationSelectors.workstations.getFilteredResults);
  const dispatch = useDispatch();
  const isActiveFilter = useSelector(workstationSelectors.workstations.getFilterValue("isActive"));
  const [selection, setSelection] = useState([]);
  const history = useHistory();
  const onClick = (id) => history.push(`/workstations/${id}`);
  const onActiveChecked = (value) =>
    dispatch(workstationActions.workstations.setFilterValue({ identifier: "isActive", value }));

  const column = [
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.workstations}
          selectors={workstationSelectors.workstations}
          identifier="platform"
          style={{ width: "12rem" }}
        />
      ),
      dataIndex: "info",
      render: (info) => <PlatformIcon platform={info.os.platform} />,
    },
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.workstations}
          selectors={workstationSelectors.workstations}
          identifier="name"
          style={{ width: "12rem" }}
        />
      ),
      dataIndex: "name",
    },
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.workstations}
          selectors={workstationSelectors.workstations}
          identifier="hostname"
          style={{ width: "12rem" }}
        />
      ),
      render: (_, workstation) => <> {workstation.info.os.hostname}</>,
    },
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.workstations}
          selectors={workstationSelectors.workstations}
          identifier="version"
          style={{ width: "12rem" }}
        />
      ),
      render: (_, workstation) => <> {workstation.info.version}</>,
    },
    {
      title: () => (
        <Checkbox id="active-checkbox" onChange={onActiveChecked} isChecked={isActiveFilter} />
      ),
      dataIndex: "isActive",
      render: (isActive) => <SessionStatus status={isActive ? "online" : "offline"} />,
    },
    {
      title: "Last Active",
      dataIndex: "lastActive",
      render: (lastActive) => <Timestamp value={lastActive} />,
    },
  ];
  return (
    <ListPage
      pageTitle="Workstations"
      filterComponent={
        <div className="workstations-filters">
          <TaskSender workstationIds={selection} btnLabel="Send task to all selected" />
          <DateFilter
            actions={workstationActions.workstations}
            selectors={workstationSelectors.workstations}
            identifier="lastActive"
          />
        </div>
      }
    >
      <ReduxerControlled
        selectors={workstationSelectors.workstations}
        actions={workstationActions.workstations}
        reducerName="workstations"
        loadingComponent={
          <div>
            <Spinner />
          </div>
        }
      >
        <Table
          columns={column}
          dataSource={workstationList}
          pagination={false}
          onRow={(workstation) => ({
            onClick: () => {
              onClick(workstation.id);
            },
          })}
          rowKey="id"
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelection(selectedRowKeys);
            },
            columnWidth: "64px",
          }}
        />
      </ReduxerControlled>
    </ListPage>
  );
};

export default WorkstationList;
