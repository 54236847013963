import { createAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";
import moment from "moment";
import { DataCollector } from "~/services";

export const createRecordingBegin = createAction("dataImport/CREATE_RECORDING_BEGIN");
export const createRecordingSuccess = createAction("dataImport/CREATE_RECORDING_SUCCESS");
export const createRecordingError = createAction("dataImport/CREATE_RECORDING_ERROR");
export const onClearFilters = createAction("dataImport/ON_CLEAR_FILTERS");
export const onClearFile = createAction("dataImport/ON_CLEAR_FILE");
export const onSelectedFilters = createAction("dataImport/ON_SELECTED_FILTERS");
export const onSelectedFile = createAction("dataImport/ON_SELECTED_FILE");
export const onProcessedData = createAction("dataImport/FILE_DATA_PROCESSED");

export const createRecording = (newRecording) => async (dispatch) => {
  try {
    dispatch(createRecordingBegin());
    const response = await DataCollector.POST(
      "/collect-factory",
      recordingToServerFormat(newRecording)
    );

    const recording = response.payload;

    await dispatch(createRecordingSuccess({ recording }));
    return true;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(createRecordingError({ errors: message }));
    return false;
  }
};

function recordingToServerFormat(recording = {}) {
  const startTime = moment(recording.startTime);
  const endTime = startTime.add(parseInt(recording.duration), "seconds");

  let formatted = {
    json: {
      deviceInfo: {
        deviceColor: recording.deviceColor,
        imei: recording.IMEI,
        meid: recording.MEID,
        manufacturer: recording.manufacturer,
        modelName: recording.modelName,
        modelNumber: recording.modelNumber,
        storageCapacity: recording.storageCapacity,
        wifiMAC: recording.wifiMAC,
        bluetoothMAC: recording.bluetoothMAC,
        oemSerialNumber: recording.OEMSerialNumber,
      },
      process: {
        configurationInformation: {
          program: recording.programId,
          serviceSuite: recording.configId,
          testSuite: "ManualDeviceTesting",
        },
        customFields: {},
        startDate: recording.startTime,
        endDate: endTime.format("YYYY-MM-DDTHH:mm:ssZZ"),
        operator: recording.operator,
        sessionID: recording.batchSessionId,
        shiftID: recording.shiftId,
        shift: recording.shift,
        teamID: recording.teamId,
        team: recording.team,
        time: `${parseInt(recording.duration)}`, // hardcoded
        timeUnits: "s", // hardcoded
      },
      processResult: recording.processResult,
      sender: recording.username,
      senderSource: "MANUAL", // hardcoded

      services: [
        {
          className: "ManualDeviceTesting", // hardcoded
          end: endTime.format("YYYY-MM-DDTHH:mm:ssZZ"),
          start: recording.startTime,
          duration: `${parseInt(recording.duration)}`, // hardcoded
          durationUnits: "s", // hardcoded
          name: "ManualDeviceTesting", // hardcoded
          status: "PASS", // hardcoded
          systemStatus: "PASS", // hardcoded
          userStatus: "PASS", // hardcoded
        },
      ],
      tenantIdentifier: {
        portalCustomerID: recording.csid,
        portalDeploymentID: recording.dpid,
        portalFacilityID: recording.faid,
      },
      tests: [
        {
          className: "ManualDeviceTesting", // hardcoded
          name: "ManualDeviceTesting", // hardcoded
          duration: `${parseInt(recording.duration)}`, // hardcoded
          durationUnits: "s", // hardcoded
          end: endTime.format("YYYY-MM-DDTHH:mm:ssZZ"),
          order: 0, // hardcoded
          start: recording.startTime,
          status: recording.processResult,
          systemStatus: recording.processResult,
          userStatus: recording.processResult,
        },
      ],
    },
  };

  const keys = Object.keys(recording);

  for (let key of keys) {
    const value = recording[key];

    if (key.charAt(0) == "_") {
      key = key.substr(1);
      formatted = addCustomField(key, value, formatted);
    }

    if (key == "processResult" && value == "FAIL") {
      formatted = addFailCodeToTest(formatted, recording);
    }
  }

  if (recording.processResult === "FAIL") {
    const failDetails = formatFailDetails(recording);

    formatted.json.failDetails = failDetails;
  }

  return formatted;
}

const addCustomField = (key, value, formatted) => {
  key = key.replace(/\s+/g, "");
  value = value.trim();
  const { customFields } = formatted.json.process;
  const customField = { [key]: value };
  formatted.json.process.customFields = {
    ...customFields,
    ...customField,
  };
  return formatted;
};

export const clearFile = () => (dispatch) => {
  dispatch(onClearFile());
};

export const clearFilters = () => (dispatch) => {
  dispatch(onClearFilters());
};

export const selectedUploadInfoData = (filters) => (dispatch) => {
  dispatch(onSelectedFilters({ filters }));
};

export const processedData = (fileData) => (dispatch) => {
  dispatch(onProcessedData({ fileData }));
};

export const selectedFile = (file) => (dispatch) => {
  dispatch(onSelectedFile({ file }));
};

const addFailCodeToTest = (formatted, recording) => {
  const keys = Object.keys(recording);
  const failCodeList = [];
  const failCodeKeys = [];

  keys.forEach((key) => {
    if (key.includes("failCode")) {
      failCodeKeys.push(key);
    }
  });

  failCodeKeys.forEach((key) => {
    const index = key.split("e")[1];

    const categoryKey = `failCategory${index}`.trim();
    const failCodeKey = `failCode${index}`.trim();
    const descriptionKey = `failDescription${index}`.trim();
    const priorityKey = `failPriority${index}`.trim();

    if (
      recording[categoryKey] !== "" &&
      recording[failCodeKey] !== "" &&
      recording[descriptionKey] !== "" &&
      recording[priorityKey] !== ""
    ) {
      const failCode = {
        category: recording[categoryKey],
        code: recording[failCodeKey],
        description: recording[descriptionKey],
        priority: recording[priorityKey],
      };

      failCodeList.push(failCode);
    }
  });

  formatted.json.tests.map((test) => (test.failCodes = failCodeList));

  return formatted;
};

const formatFailDetails = (recording) => {
  const csvKeys = Object.keys(recording);
  const failCodeKeys = csvKeys.filter((key) => key.startsWith("fail"));

  let failCode = {};
  const failCodes = failCodeKeys.reduce((acc, key, index) => {
    const keyCount = index + 1;
    // Remove the fail prefix and the number at the end of the key
    const cleanKey = key.slice(4).toLowerCase();
    const newKey = isNaN(cleanKey.slice(-1)) ? cleanKey : cleanKey.slice(0, -1);

    failCode[newKey] = recording[key];

    // Add a fail code every four keys
    if (keyCount % 4 === 0) {
      acc = [...acc, failCode];
      failCode = {};
    }

    return acc;
  }, []);

  // Sort by priority and remove fail codes with no priority
  const sortedFailCodes = sortBy(
    failCodes.filter((failCode) => failCode.priority.toString().trim().length > 0),
    "priority"
  ).reverse();

  const failDetails = {
    failReason: sortedFailCodes[0],
    failCodes: sortedFailCodes,
  };

  return failDetails;
};
