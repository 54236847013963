import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-md";
import classnames from "classnames";

import { Checkbox, MarqueeText } from "~/global";

import "./FailCodesPicker.component.scss";

const FailCodesPicker = ({ failCodes = [], checkedIds = [], selectedId, onCheck, onSelect }) => {
  const isFailCodeChecked = (id) => checkedIds.indexOf(id) > -1;

  const areAllFailCodesChecked = () =>
    failCodes.reduce((acc, failCode) => isFailCodeChecked(failCode.id) && acc, true);

  const onCheckAllToggle = () => {
    let newCheckedIds;
    let checked = [];
    let unchecked = [];

    if (checkedIds.length === failCodes.length) {
      unchecked = [...checkedIds];
      newCheckedIds = [];
    } else {
      newCheckedIds = failCodes.map((f) => f.id);
      checked = [...newCheckedIds];
    }

    onCheck(newCheckedIds, checked, unchecked);
  };

  const onCheckToggle = (failCode) => {
    const index = checkedIds.indexOf(failCode.id);
    const newCheckedIds = [...checkedIds];
    const checked = [];
    const unchecked = [];

    if (index > -1) {
      unchecked.push(failCode.id);
      newCheckedIds.splice(index, 1);
    } else {
      checked.push(failCode.id);
      newCheckedIds.push(failCode.id);
    }

    onCheck(newCheckedIds, checked, unchecked);
  };

  const selectFailcode = (failCode) => {
    if (selectedId !== failCode.id) {
      onSelect(failCode.id);
    }
  };

  return (
    <div className="fail-codes-picker">
      <div className="row centered subheader-column">
        <div
          className={classnames("flex--5", {
            "asci-hide": failCodes.length === 0,
          })}
        >
          <Checkbox
            id="check-all-failCodes"
            onChange={onCheckAllToggle}
            isChecked={areAllFailCodesChecked()}
            disabled={failCodes.length === 0}
          />
        </div>
        <div className="fail-code-name">Name</div>
        <div className="fail-code-description">Description</div>
        <div className="flex--30 row centered text-centered">Fail Fast</div>
        <div className="flex--30 row centered text-centered">Show on Desktop</div>
        <div className="flex--15" />
      </div>

      <div className="scroll-container flex">
        {failCodes.map((failCode, index) => {
          const isChecked = isFailCodeChecked(failCode.id);
          const isSelected = failCode.id === selectedId;

          return (
            <div
              key={`fail-code-${index}`}
              className={classnames("selector-row layout-row centered failCode-row", {
                checked: isChecked,
                current: isSelected,
              })}
              onClick={() => selectFailcode(failCode)}
            >
              <div className="flex--5">
                <Checkbox
                  id={`fail-code-check-${failCode.id}`}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onCheckToggle(failCode)}
                  isChecked={isChecked}
                />
              </div>

              <div className="fail-code-name">
                <MarqueeText text={failCode.name != null ? failCode.name : ""} />
              </div>

              <div className="fail-code-description">
                <MarqueeText
                  text={failCode.defaultDescription != null ? failCode.defaultDescription : ""}
                />
              </div>

              <Switch
                id={`fail-fast-switch-${failCode.id}`}
                type="switch"
                name={`fail-fast ${failCode}`}
                checked={failCode.failFast}
                className="flex--30 centered"
                label=""
                disabled
              />

              <Switch
                id={`show-on--desktop-switch-${failCode.id}`}
                type="switch"
                name={`fail-fast ${failCode}`}
                checked={failCode.showOnDesktop}
                className="flex--30 centered"
                label=""
                disabled
              />
              <div className="flex--15 align-right">
                <i className="content-pointer icon-chevron-right" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FailCodesPicker.propTypes = {
  failCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      failFast: PropTypes.bool.isRequired,
      description: PropTypes.object.isRequired,
      showOnDesktop: PropTypes.bool.isRequired,
    })
  ),
  checkedIds: PropTypes.arrayOf(PropTypes.string),
  selectedId: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FailCodesPicker;
