import React from "react";
import PropTypes from "prop-types";

import "./ListPage.scss";

const ListPage = (props) => {
  const { children, filterComponent, pageTitle } = props;

  return (
    <div className="asci-list-page panel-container">
      <div className="list-table">
        {pageTitle && <div className="list-page-title">{pageTitle}</div>}
        {filterComponent && <div className="list-filters">{filterComponent}</div>}
        <div className="list-body">{children}</div>
      </div>
    </div>
  );
};

ListPage.defaultProps = {};

ListPage.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  filterComponent: PropTypes.node,
};

export default ListPage;
