import { createSelector } from "@reduxjs/toolkit";
import { SystemPermission } from "~/entities/system-permissions/model/systemPermissionTypes";

interface State {
  modules: {
    programManagement: {
      systemPermissions: {
        items: SystemPermission[];
      };
    };
  };
}

const getSystemPermissions = (state: State) =>
  state.modules.programManagement.systemPermissions.items;

export const getCustomPermissions = createSelector(
  [getSystemPermissions],
  (systemPermissions: SystemPermission[]) => systemPermissions.filter((sp) => sp.type === "pdd")
);
