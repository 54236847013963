import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import PropTypes from "prop-types";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import moduleActions from "../../actions";

import { SectionHeader, Row, Column } from "~/global";
import { formatTime, secondsFormat, formatDate, getDurationTime } from "~/global/utils";
import StatusIcon from "~/device-history/components/StatusIcon/StatusIcon.component";

import "./DeviceServices.component.scss";

const DeviceServices = (props) => {
  const [services, setServices] = useState(props.services);

  useEffect(() => {
    setServices(sortBy(services, ["start", "name", "duration", "status"]));
  }, []);

  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  const renderTable = () => {
    let totalTime = 0;

    if (props.services != null) {
      props.services.forEach((service) => {
        const duration = secondsFormat(service.durationUnits, service.duration);
        totalTime += duration;
      });
    }

    totalTime = formatTime(totalTime);
    return (
      <Row className="device-services full-width">
        <Column className="full-width">
          <DataTable className="device-services-table" baseId="device-services-table" plain>
            <TableHeader className="table-header">
              <TableRow>
                <TableColumn className={"name" + ` header-label`}>Service name</TableColumn>
                <TableColumn className={"status" + ` header-label`}>Status</TableColumn>
                <TableColumn className={"start" + ` header-label`}>Start</TableColumn>
                <TableColumn className={"duration" + ` right-aligned no-printable header-label`}>
                  Duration
                </TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody className="table-body">
              {services.map((service, index) => (
                <TableRow key={index} className={service.status == "FAIL" ? "failed" : ""}>
                  <TableColumn className="table-data">
                    {service.name || service.className}
                  </TableColumn>
                  <TableColumn className="table-data">
                    <div className="row">
                      <StatusIcon status={service.status} />
                    </div>
                  </TableColumn>
                  <TableColumn className="table-data">
                    {service.start != null ? formatDate(service.start) : ""}
                  </TableColumn>
                  <TableColumn className="right-aligned no-printable table-data">
                    {getDurationTime(service.durationUnits, service.duration)}
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
            {services.length > 0 ? (
              <tfoot className="footer-services no-printable">
                <TableRow>
                  <TableColumn className="bold-screen">Total:</TableColumn>
                  <TableColumn />
                  <TableColumn />
                  <TableColumn className="right-aligned bold-screen">{`~${totalTime}`}</TableColumn>
                </TableRow>
              </tfoot>
            ) : null}
          </DataTable>
        </Column>
      </Row>
    );
  };

  const isEmpty = props.services != null ? props.services.length == 0 : true;

  if (isEmpty) {
    return null;
  }

  return (
    <div className={props.services.length > 1 ? "list-content fade-in" : "list-content fade-in"}>
      {props.certificate ? (
        <div>
          <SectionHeader title="Services" className="certificate-header" />
          {renderTable()}
        </div>
      ) : (
        <div
          className={
            props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
          }
        >
          <ExpansionList>
            <ExpansionPanel
              className="cc-expansion-panel"
              label={<div className="panel-text title bold-screen printable-title">Services</div>}
              secondaryLabel={
                <div className="panel-text no-printable">
                  {props.includeToPrint ? "(added to report)" : "(not added to report)"}
                </div>
              }
              onExpandToggle={() => includeForPrint()}
              expanded={props.includeToPrint}
              footer={null}
              expanderIcon={<i className="no-printable icon-chevron-down" />}
              headerClassName="cc-section-header table-header"
              contentClassName="cc-fullwidth-panel"
            >
              {renderTable()}
            </ExpansionPanel>
          </ExpansionList>
        </div>
      )}
    </div>
  );
};

DeviceServices.propTypes = {
  services: PropTypes.array,
  certificate: PropTypes.bool,
};

DeviceServices.defaultProps = {
  services: [],
  certificate: false,
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeServicesToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeServicesToPrint,
};

export default connect(stateToProps, dispatchToProps)(DeviceServices);
