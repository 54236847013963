import getBy from "lodash/get";

export const isPrinting = (state) => state.labelPrinter.labelPrint.printing;
export const printPreview = (state) => state.labelPrinter.labelPrint.printPreview;
export const isPrintingPreview = (state) => state.labelPrinter.labelPrint.printingPreview;
export const getRecordingValue = (path) => (state) => {
  if (state.labelPrinter.labelPrint.recording) {
    return getBy(state.labelPrinter.labelPrint.recording, path);
  }
};
