export default class RemoteDiagnosticsSession {
  static RESULT_PASS = "PASS";
  static RESULT_FAIL = "FAIL";
  static RESULT_SKIPPED = "SKIPPED";

  static SESSION_STATUS_EXPIRED = "expired";
  static SESSION_STATUS_COMPLETED = "completed";
  static SESSION_STATUS_PENDING = "pending";

  static parse(data = {}) {
    const formatted = {};

    formatted.sessionId = data.sessionId;
    formatted.phone = data.cellphone;
    formatted.email = data.email;
    formatted.firstName = data.firstName;
    formatted.lastName = data.lastName;
    formatted.identifiers = data.tests;
    formatted.transactionId = data.transactionId;
    formatted.udi = data.udi;
    formatted.status = data.status;
    formatted.model = data.model;
    formatted.platform = data.platform;
    formatted.results = data.results;
    formatted.createdOn = data.createdOn;
    formatted.imei = data.imei;

    return formatted;
  }
}
