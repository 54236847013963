import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const TestDefinitionsIcon = ({ size, customId }) => {
  const idGradientA = `${customId}-a`;
  const idGradientB = `${customId}-b`;

  return (
    <SVGIcon title="Test Configurations" viewBox="0 0 22.16 25.96" size={size}>
      <defs>
        <linearGradient
          id={idGradientA}
          x1="-0.71"
          y1="3.75"
          x2="22.67"
          y2="3.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9c3" />
          <stop offset="0.62" stopColor="#386b53" />
          <stop offset="1" stopColor="#036" />
        </linearGradient>

        <linearGradient
          id={idGradientB}
          x1="-0.71"
          y1="22.21"
          x2="22.67"
          y2="22.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#036" />
          <stop offset="0.62" stopColor="#386b53" />
          <stop offset="1" stopColor="#9c3" />
        </linearGradient>
      </defs>
      <g>
        <path
          stroke={`url(#${idGradientA})`}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.5 3.75h20.89"
        />
        <circle cx="18.41" cy="3.75" r="3.25" fill="white" stroke="#99cc33" />

        <path
          stroke={`url(#${idGradientA})`}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.5 12.98h20.89"
        />
        <circle cx="18.41" cy="12.98" r="3.25" fill="white" stroke="#99cc33" />

        <path
          stroke={`url(#${idGradientA})`}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.12 22.21h20.96"
        />
        <circle cx="3.75" cy="22.21" r="3.25" stroke="#003764" fill="#fff" strokeMiterlimit="10" />
      </g>
    </SVGIcon>
  );
};

TestDefinitionsIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default TestDefinitionsIcon;
