import { combineReducers } from "redux";
import * as wEvents from "~/workflow/workflowEvents";

import createEventEditor from "~/workflow/redux/createEventEditor";

const moduleName = "workflow";

const deviceConnected = createEventEditor(moduleName, wEvents.DeviceConnected);

const manualFailure = createEventEditor(moduleName, wEvents.ManualFailure);

const processCompleted = createEventEditor(moduleName, wEvents.ProcessCompleted);

const serviceStarted = createEventEditor(moduleName, wEvents.ServiceStarted);

const serviceCompleted = createEventEditor(moduleName, wEvents.ServiceCompleted);

export const selectors = {
  [wEvents.DeviceConnected]: deviceConnected.selectors,
  [wEvents.ManualFailure]: manualFailure.selectors,
  [wEvents.ProcessCompleted]: processCompleted.selectors,
  [wEvents.ServiceStarted]: serviceStarted.selectors,
  [wEvents.ServiceCompleted]: serviceCompleted.selectors,
};

export const actions = {
  [wEvents.DeviceConnected]: deviceConnected.actions,
  [wEvents.ManualFailure]: manualFailure.actions,
  [wEvents.ProcessCompleted]: processCompleted.actions,
  [wEvents.ServiceStarted]: serviceStarted.actions,
  [wEvents.ServiceCompleted]: serviceCompleted.actions,
};

export const reducer = combineReducers({
  [wEvents.DeviceConnected]: deviceConnected.reducer,
  [wEvents.ManualFailure]: manualFailure.reducer,
  [wEvents.ProcessCompleted]: processCompleted.reducer,
  [wEvents.ServiceStarted]: serviceStarted.reducer,
  [wEvents.ServiceCompleted]: serviceCompleted.reducer,
});
