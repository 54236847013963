import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "react-md";
import { message } from "antd";
import { Row, Column, AsciButton } from "~/global";

import moduleActions from "~/service-suite-templates/actions/";

import "./AddNewTemplateDialog.component.scss";

const AddNewTemplateDialog = ({ fetchTemplates, onAddTemplate, close }) => {
  const [identifier, setIdentifier] = useState("");

  const addNewTemplate = async () => {
    const services = [];
    const newTemplate = { identifier, services };

    await onAddTemplate(newTemplate);
    fetchTemplates();

    operationComplete();
  };

  const operationComplete = () => {
    close();
    message.success(`New template ${identifier} as been added`);
  };

  return (
    <div className="new-template-dialog">
      <Column className="full-width">
        <Row className="identifier-input full-width">
          <div className="identifier-label">Identifier:</div>
          <TextField
            id="identifier-text-field"
            lineDirection="left"
            placeholder="Enter identifier"
            value={identifier}
            className="identifier-field"
            onChange={(value) => setIdentifier(value)}
            type="text"
          />
        </Row>
        <Row className="btn-group full-width">
          <AsciButton className="cancel-btn" color="white" onClick={() => close()}>
            Cancel
          </AsciButton>
          <AsciButton
            className="confirm-btn"
            color="green"
            onClick={() => addNewTemplate()}
            disabled={identifier == ""}
          >
            Add
          </AsciButton>
        </Row>
      </Column>
    </div>
  );
};

AddNewTemplateDialog.propTypes = {
  close: PropTypes.func.isRequired,

  // From the store
  fetchTemplates: PropTypes.func.isRequired,
  onAddTemplate: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({});

const dispatchToProps = {
  fetchTemplates: moduleActions.serviceSuiteTemplates.fetchTemplates,
  onAddTemplate: moduleActions.serviceSuiteTemplates.addTemplate,
};

export default connect(stateToProps, dispatchToProps)(AddNewTemplateDialog);
