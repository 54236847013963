import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Tabs as ReactTabs, Tab as ReactTab, TabList, TabPanel } from "react-tabs";

import "./Tabs.component.scss";

// This is only used to get the title and its children, it doesn't need to render anything
export const Tab = () => null;

export class Tabs extends Component {
  static propTypes = {
    onTabChanged: PropTypes.func,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    beforeTabChange: PropTypes.array,
    selectedIndex: PropTypes.number,
  };

  static defaultProps = {
    onTabChanged: undefined,
    fullWidth: true,
    fullHeight: false,
    beforeTabChange: [], // validator for each tab, in order (must have same number of validators as there are tabs)
  };

  state = {
    animationClass: "",
  };

  onSelect = (index, prevIndex) => {
    // can't select the same tab consecutively
    if (index == prevIndex) {
      return false;
    }

    // execute current tab's corresponding validator function (if it exists)
    const canChangeFn = this.props.beforeTabChange[prevIndex];
    const canChange = canChangeFn ? canChangeFn() : true;

    if (!canChange) {
      return false;
    }

    const animationClass = index > prevIndex ? "animate-to-left" : "animate-to-right";

    this.setState({ animationClass }, () => {
      if (this.props.onTabChanged) {
        this.props.onTabChanged(index, prevIndex);
      }
    });
  };

  render() {
    const tabs = [];
    const panels = [];

    React.Children.map(this.props.children, (child, index) => {
      tabs.push(
        <ReactTab
          key={index}
          className={classnames("react-tabs__tab", { expand: this.props.fullWidth })}
        >
          {child.props.title}
        </ReactTab>
      );

      if (child.props.children) {
        panels.push(child.props.children);
      }
    });

    return (
      <ReactTabs
        className={classnames("cc-tabs", this.state.animationClass, {
          "full-height": this.props.fullHeight,
        })}
        onSelect={this.onSelect}
        selectedIndex={this.props.selectedIndex}
      >
        <TabList>{tabs}</TabList>

        {panels.map((panel, index) => (
          <TabPanel
            key={index}
            className={classnames({
              "full-height": this.props.fullHeight,
            })}
          >
            {panel}
          </TabPanel>
        ))}
      </ReactTabs>
    );
  }
}
