import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

const AutoFilter = (props) => {
  const { actions, selectors, identifier, ...rest } = props;
  const dispatch = useDispatch();
  const filterProps = useSelector(selectors.getFilter(identifier));
  const onChange = useCallback(
    (value) => dispatch(actions.setFilterValue({ identifier, value })),
    [dispatch, identifier]
  );
  const filterOptions = useSelector(selectors.getFilterOptions(identifier));
  const isFetched = useSelector(selectors.isFetched);

  return (
    <Select
      value={filterProps.value || undefined}
      onChange={onChange}
      placeholder={filterProps.label || identifier}
      allowClear
      showSearch
      disabled={!isFetched}
      {...rest}
    >
      {filterOptions.map((option) => (
        <Select.Option key={`filter-${identifier}-${option}`} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};

AutoFilter.defaultProps = {};

AutoFilter.propTypes = {
  actions: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
};

export default AutoFilter;
