import PropTypes from "prop-types";
import { Button, DataTable, TableHeader, TableBody, TableRow, TableColumn } from "react-md";
import { Popover } from "antd";
import React, { useState, useEffect } from "react";
import { sortBy } from "lodash/collection";
import { formatDate } from "~/global/utils";
import ProgramListPopover from "./ProgramListPopover.component";

import "./ProgramsList.component.scss";

const ProgramsList = (props) => {
  const [sortKey, setSortKey] = useState("");
  const [sortReverse, setSortReverse] = useState(false);
  const [programsSorted, setProgramsSorted] = useState([]);

  const setSort = (key) => {
    key == sortKey ? setSortReverse(!sortReverse) : setSortReverse(sortReverse);
    setSortKey(key);
  };

  function sortList() {
    let newSortedPrograms = [].concat(props.programs);
    newSortedPrograms = sortBy(newSortedPrograms, (program) => {
      const value = program[sortKey];
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value == null ? "" : value;
    });
    setProgramsSorted(newSortedPrograms);
  }

  useEffect(() => {
    if (props.programs.length > 0) {
      sortList();
    } else {
      setProgramsSorted([]);
    }
  }, [props.programs]);

  useEffect(() => {
    sortList();
  }, [sortKey]);

  useEffect(() => {
    const newSortedPrograms = [].concat(programsSorted).reverse();
    setProgramsSorted(newSortedPrograms);
  }, [sortReverse]);

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = sortKey == colKey;

    if (sorted) {
      classes.push("is-sorted");
    }
    if (sortReverse) {
      classes.push("sort-reverse");
    }
    return classes.join(" ");
  };

  const onDelete = (e, program) => {
    e.stopPropagation();
    props.onDelete(program);
  };

  const isEmpty = props.programs.length === 0;

  return (
    <DataTable className="cc-table programs-list" baseId="programs-list" plain>
      {!isEmpty ? (
        <TableHeader>
          <TableRow>
            <TableColumn
              style={{ minWidth: "200px" }}
              className={getColHeaderClasses("displayNameEn")}
              onClick={() => setSort("displayNameEn")}
            >
              Name
            </TableColumn>

            <TableColumn
              grow
              className={getColHeaderClasses("usedByDeployments")}
              onClick={() => setSort("usedByDeployments")}
            >
              Associated Deployment(s)
            </TableColumn>

            <TableColumn
              className={getColHeaderClasses("identifier")}
              onClick={() => setSort("identifier")}
            >
              Identifier
            </TableColumn>

            <TableColumn
              style={{ minWidth: "150px" }}
              className={getColHeaderClasses("createdOn")}
              onClick={() => setSort("createdOn")}
            >
              <div className="text-centered">Date Created</div>
            </TableColumn>

            <TableColumn
              style={{ minWidth: "150px" }}
              className={getColHeaderClasses("serviceSuites")}
              onClick={() => setSort("serviceSuites")}
            >
              <div className="text-centered">Service Suites</div>
            </TableColumn>

            <TableColumn />
          </TableRow>
        </TableHeader>
      ) : null}

      <TableBody>
        {isEmpty ? (
          <TableRow>
            <TableColumn colSpan="6" className="text-centered">
              No data to display
            </TableColumn>
          </TableRow>
        ) : (
          (programsSorted || []).map((program, index) => {
            const showMore = program.usedByDeployments.length >= 3;
            const usedByDeploymentsShortList = program.usedByDeployments.slice(0, 3).join(", ");

            return (
              <TableRow key={index}>
                <TableColumn style={{ minWidth: "200px" }}>
                  <div onClick={(e) => props.onRowClicked(program)} className="clickable-text">
                    {(program.displayName || {}).en}
                  </div>
                </TableColumn>

                <TableColumn grow>
                  <div className="row">
                    <div>{usedByDeploymentsShortList}</div>
                    {showMore ? (
                      <Popover
                        placement="right"
                        title="Associated Deployments"
                        content={
                          <ProgramListPopover usedByDeploymentsList={program.usedByDeployments} />
                        }
                        overlayClassName="see-more"
                        trigger="hover"
                      >
                        <div className="popover-clickable-text padding-h--5">See All</div>
                      </Popover>
                    ) : null}
                  </div>
                </TableColumn>

                <TableColumn title={program.identifier}>{program.identifier}</TableColumn>

                <TableColumn className="text-centered">{formatDate(program.createdOn)}</TableColumn>

                <TableColumn className="text-centered">{program.serviceSuites.length}</TableColumn>

                <TableColumn className="layout-row layout-row--center-center">
                  <Button
                    icon
                    className="icon-btn"
                    iconClassName="icon-copy"
                    disabled={props.disableDuplicate}
                    onClick={() => props.onDuplicate(program.identifier)}
                  />
                  <Button
                    icon
                    className="icon-btn"
                    iconClassName="icon-download"
                    onClick={() => props.onDownload(program.identifier)}
                  />
                  <Button
                    icon
                    className="icon-btn"
                    iconClassName="icon-pencil"
                    disabled={props.isDeleting}
                    onClick={(e) => props.onRowClicked(program)}
                  />
                  <Button
                    icon
                    className="icon-btn text-color--red"
                    iconClassName="icon-trash"
                    onClick={(e) => onDelete(e, program)}
                  />
                </TableColumn>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </DataTable>
  );
};

ProgramsList.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.object),
  onRowClicked: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDownload: PropTypes.func,
  disableDuplicate: PropTypes.bool,
};

ProgramsList.defaultProps = {
  programs: [],
  onRowClicked: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
  onDownload: () => {},
  disableDuplicate: true,
};

export default ProgramsList;
