import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, FontIcon, Paper, TextField } from "react-md";

import "./InputSearch.component.scss";

export default class InputSearch extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    inline: PropTypes.bool,
    trim: PropTypes.bool,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    initialValue: "",
    placeholder: "Search",
    inline: false,
    trim: false,
  };

  constructor(props) {
    super();

    this.state = {
      query: props.initialValue,
    };
  }

  onQueryChanged = (query = "") => {
    this.setState({ query }, this.fireCallback);
  };

  onClear = () => {
    this.setState({ query: "" }, this.fireCallback);
  };

  fireCallback = () => {
    if (this.props.onChange) {
      const query = this.props.trim ? this.state.query.trim() : this.state.query;

      this.props.onChange(query);
    }
  };

  render() {
    return (
      <div
        className={classnames("cc-input-search", this.props.className, {
          inline: this.props.inline,
        })}
      >
        <Paper className="search-container row spaced" raiseOnHover zDepth={1}>
          <TextField
            leftIcon={<FontIcon iconClassName="icon-search" />}
            id="search-text-field"
            block
            lineDirection="left"
            placeholder={this.props.placeholder}
            className="search-text-field"
            onChange={this.onQueryChanged}
            value={this.state.query}
            autoFocus
          />

          {this.state.query.length > 0 ? (
            <div>
              <Button
                icon
                className="icon-btn search-close-button"
                iconClassName="icon-x"
                onClick={this.onClear}
              />
            </div>
          ) : null}
        </Paper>
      </div>
    );
  }
}
