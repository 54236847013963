import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { message } from "antd";
import prettyBytes from "pretty-bytes";
import AsciButton from "~/global/AsciButtons/AsciButton/AsciButton.component";
import "./ImageUploader.component.scss";
import { useTranslation } from "~/i18n";

const ImageUploader = ({ imageUrl, onChangeImage }) => {
  const imageSize = 200000;
  const [errorText, setErrorText] = useState("");
  const { t, language } = useTranslation("imageUploader");

  const onImageDropRejected = (files) => {
    const errorMessages = [];
    const sizeValid = files[0].size <= imageSize;
    const formatValid = (files[0].type || "").includes("image/");

    if (!sizeValid) {
      message.warning(
        ` ${t("images-must-be-smaller-than")} ${prettyBytes(imageSize, { locale: language })}`
      );
      errorMessages.push(
        `${t("images-must-be-smaller-than")} ${prettyBytes(imageSize, { locale: language })}`
      );
    }

    if (!formatValid) {
      message.warning(t("invalid-format"), 2.5);
      errorMessages.push(t("invalid-format"));
    }

    if (errorMessages.length > 0) {
      const errorMessage = errorMessages.join(" ");
      setErrorText(errorMessage);
    }
  };

  const clearImage = () => {
    setErrorText("");
    onChangeImage("");
  };

  const onImageDrop = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      onChangeImage(reader.result);
    };
  };

  return (
    <div className="drop-image-wrapper">
      <Dropzone
        className="drop-image-zone"
        accept="image/*"
        activeClassName="active"
        maxSize={imageSize}
        multiple={false}
        onDropRejected={(error) => onImageDropRejected(error)}
        // onDragOver={() => clearImage()}
        onDropAccepted={(files) => onImageDrop(files[0])}
      >
        {imageUrl.length > 0 ? (
          <img className="image-dropped" src={imageUrl} />
        ) : (
          <div className="text-centered">
            <p className="error-message">{errorText}</p>
            <p>{t("drop-an-image-or-click")}</p>
          </div>
        )}
      </Dropzone>

      <div className="drop-image-instructions text-centered">
        <div>{t("upload-a-jpeg-png-or-svg-file")}</div>
        <div>{t("maximum-file-size")}</div>
        <div>{t("max-height-max-width")}</div>
      </div>
      <div className="row spaced">
        <AsciButton color="blue" onClick={() => clearImage()} disabled={imageUrl.length === 0}>
          {t("clear-image")}
        </AsciButton>
      </div>
    </div>
  );
};

export default ImageUploader;
