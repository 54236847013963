import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,
  errors: null,
  currentStep: 0,
};

export default createReducer(initialState, {
  [moduleActions.customerWizard.updateWizardStep]: (state, action) => {
    state.currentStep = action.payload.index;
  },
  [moduleActions.customerEditor.resetCustomerEdition]: (state) => {
    state.original = {};
    state.modifications = {};
    state.errors = null;
    state.currentStep = 0;
  },
});
