import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import find from "lodash/find";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import size from "lodash/size";

import { createSelector } from "@reduxjs/toolkit";
import { getAlignment, getBorder, getFont, getSize, toMM, toPt } from "~/label-printer/styling";

export const getName = (state) => state.labelPrinter.labelDesigner.name;
export const getLabelWidth = (state) => state.labelPrinter.labelDesigner.format.size.width;
export const getLabelHeight = (state) => state.labelPrinter.labelDesigner.format.size.height;
export const getLayoutName = (state) => state.labelPrinter.labelDesigner.layoutName;
export const getLayoutData = (state) => state.labelPrinter.labelDesigner.layoutData;
export const getShapes = (state) => state.labelPrinter.labelDesigner.shapes;
export const getLabelStyleData = (state) => state.labelPrinter.labelDesigner.style;
export const getPrinter = (state) => state.labelPrinter.labelDesigner.printer;
export const getFormatName = (state) => state.labelPrinter.labelDesigner.format.name;

export const getShape = (identity) => (state) =>
  find(state.labelPrinter.labelDesigner.shapes, { identity });
export const getPrintSettings = (state) => state.labelPrinter.labelDesigner.format.printSettings;
export const getShapeLayoutData = (identity) =>
  createSelector([getShape(identity)], (shape) => shape.layoutData);

export const getGridRows = (state) => {
  // Group & sort
  const grouped = groupBy(state.labelPrinter.labelDesigner.shapes, "layoutData.row");
  return sortBy(grouped).map((shapes) => sortBy(shapes, "layoutData.column"));
};

export const getColumnWidth = (identity) => (state) => {
  let { numColumns } = state.labelPrinter.labelDesigner.layoutData;
  const { padding } = state.labelPrinter.labelDesigner.style;
  const shape = getShape(identity)(state);
  const { row } = shape.layoutData;
  const labelWidth = getLabelWidth(state);

  if (shape.layoutData.columnWidth) {
    return shape.layoutData.columnWidth;
  }

  const rowShapes = state.labelPrinter.labelDesigner.shapes.filter(
    (s) => s.layoutData.row === row && !isNil(s.layoutData.columnWidth)
  );

  numColumns -= rowShapes.length;
  const padWidth = padding / (numColumns + 1); // +1 is magic

  const hardWidth = rowShapes.reduce((acc, s) => s.layoutData.columnWidth + acc, 0);

  return (labelWidth - hardWidth - padWidth) / numColumns;
};

export const getRowHeight = (row) => (state) => {
  const { numRows, rowHeights } = state.labelPrinter.labelDesigner.layoutData;
  const { padding } = state.labelPrinter.labelDesigner.style;
  const height = getLabelHeight(state);
  const hardHeight = rowHeights[row];

  if (hardHeight) {
    return hardHeight;
  }
  const rows = numRows - size(rowHeights);
  const restHeight = height - reduce(rowHeights, (acc, e) => acc + e, 0) - padding * 2;

  return restHeight / rows;
};

// Proper style object.
export const getLabelStyle = createSelector(
  [getLabelStyleData, getLabelWidth, getLabelHeight],
  (style, width, height) => ({
    ...omit(style, ["border", "font"]),
    ...getBorder(style.border),
    ...getFont(style.font),
    ...getSize({ width, height }),
    padding: style.padding ? toMM(style.padding) : undefined,
  })
);

export const getShapeStyle = (identity) =>
  createSelector([getShape(identity)], (shape) => ({
    ...omit(shape.shapeData.style, ["border", "font", "alignment"]),
    ...getBorder(shape.shapeData.style.border),
    ...getAlignment(shape.shapeData.style.alignment),
    ...getFont(shape.shapeData.style.font),
    padding: shape.shapeData.style.padding ? toMM(shape.shapeData.style.padding) : undefined,
  }));
