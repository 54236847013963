import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DataTable, Divider, TableHeader, TableBody, TableRow, TableColumn, Card } from "react-md";

import { Row, Column, AsciButton } from "~/global";
import { CANNED_REPORTS_COLORS } from "~/constants";

import "./ReportSummary.component.scss";

const ReportSummary = (props) => {
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    props.data.length > 0 ? setIsEmpty(false) : setIsEmpty(true);
  }, [props.data]);

  return (
    <Column className="report-summary-wrapper">
      <Card tableCard className="cc-paper">
        <Row className="report-summary-row" align="space-between">
          <div className="report-summary-title">Report Summary</div>
        </Row>
        <Divider />

        <Row align="space-around">
          <DataTable className="full-width" plain>
            {isEmpty ? (
              <TableBody>
                <TableRow className="borderless">
                  <TableColumn colSpan="6" className="text-centered">
                    There is no data to display.
                  </TableColumn>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHeader className="report-summary-table-header">
                  <TableRow className="borderless">
                    {(props.columns || []).map((column) => (
                      <TableColumn key={column} className="report-summary-row-header">
                        {props.titles[column]}
                      </TableColumn>
                    ))}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {(props.data || []).map((data, indexData) => (
                    <TableRow className="borderless" key={indexData}>
                      {(props.columns || []).map((column) =>
                        data[column] != null ? (
                          <TableColumn key={column}>
                            <Row className="flow-centered report-summary-name-column">
                              <i
                                className="icon-circle"
                                style={{
                                  color: CANNED_REPORTS_COLORS[indexData % 5],
                                  verticalAlign: "middle",
                                }}
                              />
                              <span className="margin-h--10">
                                {data[column].name || data[column].updateData.user_metadata.name}
                              </span>
                            </Row>
                          </TableColumn>
                        ) : (
                          <TableColumn numeric key={column}>
                            {data.ChartData[column]}
                          </TableColumn>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </DataTable>
        </Row>
      </Card>
    </Column>
  );
};

ReportSummary.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  titles: PropTypes.object.isRequired,
};

export default ReportSummary;
