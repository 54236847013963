import { createAction } from "@reduxjs/toolkit";
import { isObject } from "lodash/lang";
import { CustomerManagement } from "~/services";
import actions from "~/actions";

import moduleSelectors from "../selectors";
import { getRegexValidation } from "~/global/utils";
import selectors from "~/selectors";

export const updateCustomerIdentity = createAction(
  "customerManagement/UPDATE_CUSTOMER_NAME_IDENTITY"
);

export const updateCsidBegin = createAction("customerManagement/UPDATE_CSID_BEGIN");
export const updateCsidSuccess = createAction("customerManagement/UPDATE_CSID_SUCCESS");
export const updateCsidError = createAction("customerManagement/UPDATE_CSID_ERROR");

export const onCustomerNameValidation = createAction(
  "customerManagement/ON_CUSTOMER_NAMEVALIDATION"
);

export const fetchCustomerIdentityBegin = createAction(
  "customerManagement/FETCH_CUSTOMER_INFOS_BEGIN"
);
export const fetchCustomerIdentitySuccess = createAction(
  "customerManagement/FETCH_CUSTOMER_INFOS_SUCCESS"
);
export const fetchCustomerIdentityError = createAction(
  "customerManagement/FETCH_CUSTOMER_INFOS_ERROR"
);

export const saveIdentityBegin = createAction("customerManagement/SAVE_IDENTITY_BEGIN");
export const saveIdentitySuccess = createAction("customerManagement/SAVE_IDENTITY_SUCCESS");
export const saveIdentityError = createAction("customerManagement/SAVE_IDENTITY_ERROR");
export const updateCsid = createAction("customerManagement/UPDATE_CSID");

export const setCustomerIdentity = () => (dispatch) => {
  const identity = {
    csid: "",
    logo: "",
    name: "",
    reseller: false,
  };

  dispatch(updateCustomerIdentity({ identity }));
};

export const fetchCustomerIdentity = (csid) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`query CustomerIdentity {
        customer(csid: "${csid}", updateUserClient: false) {
            name,
            csid,
            reseller,
            logo,
            autoAssignToAllTeams
        }
    }`);

  try {
    dispatch(fetchCustomerIdentityBegin());
    const response = await CustomerManagement.QUERY(query);
    if (response.payload.data != null && response.payload.data.customer != null) {
      const identity = response.payload.data.customer;
      dispatch(fetchCustomerIdentitySuccess({ identity }));
    } else {
      dispatch(fetchCustomerIdentityError({ error: "Request didn't complete successfully" }));
    }
  } catch (e) {
    const message =
      e.message != null && !isObject(e.message)
        ? e.message
        : "Request didn't complete successfully";

    dispatch(fetchCustomerIdentityError({ error: message }));
  }
};

export const onChangeName = (name) => (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  identity.name = name;
  dispatch(updateCustomerIdentity({ identity }));
};

export const validateCustomerName = () => (dispatch, getState) => {
  const state = getState();
  const customerName = moduleSelectors.customerIdentity.getCustomerName(state);
  const nameForValidation = customerName.trim();
  const isValid = getRegexValidation("Customer_Name", nameForValidation);
  const errors = !isValid;
  dispatch(onCustomerNameValidation({ errors }));
};

export const onChangeReseller = () => (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  identity.reseller = !identity.reseller;
  dispatch(updateCustomerIdentity({ identity }));
};
export const onChangeAutoAssignToAllTeams = () => (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  identity.autoAssignToAllTeams = !identity.autoAssignToAllTeams;
  dispatch(updateCustomerIdentity({ identity }));
};

export const onChangeLogo = (image) => (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  identity.logo = image;
  dispatch(updateCustomerIdentity({ identity }));
};

export const onSaveIdentity = (csid) => async (dispatch, getState) => {
  const state = getState();
  const fullEditPermission = selectors.session.hasPermission(state, "PUT", "/customers/:csid");
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);

  const customer = {
    ...identity,
    name: identity.name.trim(),
  };

  dispatch(saveIdentityBegin({ customer }));
  try {
    if (fullEditPermission) {
      await CustomerManagement.PUT(`/customers/${csid}`, customer);
    } else {
      await CustomerManagement.PUT(`/customers/current`, customer);
    }
    dispatch(saveIdentitySuccess());
    dispatch(actions.customers.fetchCustomers());
    return;
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(saveIdentityError({ message }));
    return e;
  }
};

export const fetchCsid = (name) => async (dispatch, getState) => {
  try {
    dispatch(updateCsidBegin());
    const state = getState();
    const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
    const csname = name.replace(/[- ]/g, "");

    const response = await CustomerManagement.GET(`/generatecustomerid/${csname}`);
    const csid = response.payload;
    identity.csid = csid;
    dispatch(updateCsidSuccess());
    dispatch(updateCustomerIdentity({ identity }));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateCsidError({ message }));
  }
};
