import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DataTable, TableHeader, TableBody, TableColumn, TableRow, Button } from "react-md";
import { sortBy } from "lodash/collection";

import { formatDate } from "~/global/utils";

const CustomFieldsList = (props) => {
  const [sortKey, setSortKey] = useState("");
  const [sortReverse, setSortReverse] = useState(false);
  const [fieldsSorted, setFieldsSorted] = useState([]);

  const setSort = (key) => {
    key == sortKey ? setSortReverse(!sortReverse) : setSortReverse(sortReverse);
    setSortKey(key);
  };

  function sortList() {
    let newSortedFields = [].concat(props.customFields);
    newSortedFields = sortBy(newSortedFields, (field) => {
      const value = field[sortKey];
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value == null ? "" : value;
    });
    setFieldsSorted(newSortedFields);
  }

  useEffect(() => {
    sortList();
  }, [sortKey]);

  useEffect(() => {
    if (props.customFields.length > 0) {
      sortList();
    } else {
      setFieldsSorted([]);
    }
  }, [props.customFields]);

  useEffect(() => {
    const newSortedFields = [].concat(fieldsSorted).reverse();
    setFieldsSorted(newSortedFields);
  }, [sortReverse]);

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = sortKey == colKey;

    if (sorted) {
      classes.push("is-sorted");
    }
    if (sortReverse) {
      classes.push("sort-reverse");
    }
    return classes.join(" ");
  };

  const isEmpty = props.customFields.length === 0;

  return (
    <DataTable className="cc-table" plain>
      {!isEmpty ? (
        <TableHeader>
          <TableRow>
            <TableColumn
              className={getColHeaderClasses("displayNameEn")}
              onClick={() => setSort("displayNameEn")}
            >
              Name
            </TableColumn>

            <TableColumn>Identifier</TableColumn>

            <TableColumn className={getColHeaderClasses("type")} onClick={() => setSort("type")}>
              Type
            </TableColumn>

            <TableColumn
              className={getColHeaderClasses("displayAt")}
              onClick={() => setSort("displayAt")}
            >
              Display at
            </TableColumn>

            <TableColumn
              className={getColHeaderClasses("dateAdded")}
              onClick={() => setSort("dateAdded")}
            >
              Date Added
            </TableColumn>

            <TableColumn style={{ width: "100px" }} />
          </TableRow>
        </TableHeader>
      ) : null}

      <TableBody>
        <>
          {props.customFields.length === 0 ? (
            <TableRow>
              <TableColumn colSpan="6" className="text-centered">
                No data to display
              </TableColumn>
            </TableRow>
          ) : null}

          {(fieldsSorted || []).map((field, index) => (
            <TableRow key={index}>
              <TableColumn className="clickable-text" onClick={() => props.onEdit(field)}>
                {field.displayName.en}
              </TableColumn>

              <TableColumn>{field.name}</TableColumn>

              <TableColumn>{field.type}</TableColumn>

              <TableColumn>{field.displayAt}</TableColumn>

              <TableColumn>{formatDate(field.dateAdded)}</TableColumn>

              <TableColumn className="layout-row layout-row--center-center">
                <Button
                  icon
                  className="icon-btn"
                  iconClassName="icon-pencil"
                  disabled={props.isDeleting}
                  onClick={(e) => props.onEdit(field)}
                />

                <Button
                  icon
                  className="icon-btn text-color--red"
                  iconClassName="icon-trash"
                  disabled={props.isLoading}
                  onClick={() => props.onDelete(field)}
                />
              </TableColumn>
            </TableRow>
          ))}
        </>
      </TableBody>
    </DataTable>
  );
};

CustomFieldsList.propTypes = {
  customFields: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CustomFieldsList.defaultProps = {
  customFields: [],
  isDeleting: false,
  isLoading: false,
};
export default CustomFieldsList;
