import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement, ConsumerManagement } from "~/services";
import { DeviceTestDefinition } from "~/models";

export const setTestDefinitionOriginal = createAction(
  "testDefinitionsEditor/SET_TEST_DEFINITION_ORIGINAL"
);
export const setTestDefinitionModifications = createAction(
  "testDefinitionsEditor/SET_TEST_DEFINITION_MODIFICATIONS"
);
export const modifyTestDefinition = createAction("testDefinitionsEditor/MODIFY_TEST_DEFINITION");

export const saveTestDefinitionBegin = createAction(
  "testDefinitionsEditor/SAVE_TEST_DEFINITION_BEGIN"
);
export const saveTestDefinitionSuccess = createAction(
  "testDefinitionsEditor/SAVE_TEST_DEFINITION_SUCCESS"
);
export const saveTestDefinitionError = createAction(
  "testDefinitionsEditor/SAVE_TEST_DEFINITION_ERROR"
);

export const setSelectedCategory = createAction("testDefinitionsEditor/SET_SELECTED_TEST_CATEGORY");

export const revertModifications = () => setTestDefinitionModifications({});

export const saveTestDefinition = (definition) => async (dispatch) => {
  try {
    dispatch(saveTestDefinitionBegin());

    const path = `/test-definitions/${definition.platform}/${definition.identifier}`;
    const response = await CustomerManagement.PUT(
      path,
      DeviceTestDefinition.toServerFormat(definition)
    );

    dispatch(saveTestDefinitionSuccess(DeviceTestDefinition.parse(response.payload)));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(
      saveTestDefinitionError({
        errors: {
          error: e.json.message,
        },
      })
    );
  }
};

export const fetchGalleryImagesBegin = createAction(
  "testDefinitionsEditor/FETCH_GALLERY_IMAGES_BEGIN"
);
export const fetchGalleryImagesSuccess = createAction(
  "testDefinitionsEditor/FETCH_GALLERY_IMAGES_SUCCESS"
);
export const fetchGalleryImagesError = createAction(
  "testDefinitionsEditor/FETCH_GALLERY_IMAGES_ERROR"
);

export const fetchGalleryImages = () => async (dispatch) => {
  try {
    dispatch(fetchGalleryImagesBegin());

    const response = await ConsumerManagement.GET("/bundled-images");

    dispatch(fetchGalleryImagesSuccess(response.payload));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchGalleryImagesError({ errors: e.json.errors || {} }));
  }
};
