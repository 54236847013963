import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

import moment from "moment";
import { subMonths } from "date-fns";
import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "workstations",
  ({ filters }) =>
    WorkstationManagement.QUERY(
      WorkstationManagement.gqlBuilder(`{
            workstations(
                lastActiveStart: "${filters.lastActive.value.start}",
                lastActiveEnd:   "${filters.lastActive.value.end}"
            ) {
                id,
                uuid,
                name,
                info {
                    os {
                        platform,
                        distro,
                        hostname
                    }
                    version
                }
                lastActive,
                isActive
            }
        }`)
    ).then((response) =>
      reverse(sortBy(response.payload.data.workstations, [(w) => moment(w.lastActive)]))
    ),
  [
    {
      identifier: "isActive",
      label: "Active",
      value: false,
      filterFunc: (workstation, value) => (value ? workstation.isActive : true),
    },
    {
      identifier: "hostname",
      label: "Hostname",
      value: null,
      filterFunc: (workstation, value) => (value ? workstation.info.os.hostname === value : true),
      options: (workstations) =>
        uniq(workstations.map((workstation) => workstation.info.os.hostname)),
    },
    {
      identifier: "platform",
      label: "Platform",
      options: (workstations) =>
        uniq(workstations.map((workstation) => workstation.info.os.platform)),
      filterFunc: (workstation, value) => (value ? workstation.info.os.platform === value : true),
    },
    {
      identifier: "name",
      label: "Name",
      value: null,
      options: (workstations) => workstations.map((workstation) => workstation.name),
      filterFunc: (workstation, value) => (value ? workstation.name === value : true),
    },
    {
      identifier: "lastActive",
      label: "Last Activity",
      value: {
        start: subMonths(new Date(), 1).toISOString(),
        end: new Date().toISOString(),
      },
    },
    {
      identifier: "version",
      label: "Version",
      value: null,
      options: (workstations) => uniq(workstations.map((w) => w.info.version)),
      filterFunc: (w, v) => (v ? w.info.version === v : true),
    },
  ]
);
