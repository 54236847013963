import React from "react";
import PropTypes from "prop-types";

import { TextField, Switch } from "react-md";
import { Select } from "antd";
import { Panel } from "~/ui-common";
import { PlatformSelector, Column, Row } from "~/global";

const TestSuiteParametersEditor = ({
  onChange,
  errors,
  canEditPlatform,
  canEdit,
  name,
  nbOperatorAudits,
  failCodeDisplayModes,
  failCodeDisplayMode,
  deviceSupportLevels,
  deviceSupportLevel,
  isFailFast,
  autoStart,
  disableNavigationButtons,
  orientationLock,
  rapidTest,
  singleAppMode,
  supportedPlatforms,
  platform,
}) => {
  const onNbOperatorAuditsChanged = (value) => {
    const intVal = parseInt(value);
    if (!isNaN(intVal)) {
      onChange({ nbOperatorAudits: intVal });
    }
  };

  const onNameChanged = (value) => onChange({ name: value });
  const onFailCodeDisplayModeChanged = (value) => onChange({ failCodeDisplayMode: value });
  const onDeviceSupportLevelsChanged = (value) => onChange({ deviceSupportLevel: value });
  const onFailFastChanged = (value) => onChange({ isFailFast: value });
  const onAutoStartChanged = (value) => onChange({ autoStart: value });
  const onNavigationButtonsChanged = (value) => onChange({ disableNavigationButtons: !value });
  const onOrientationLockChanged = (value) => onChange({ orientationLock: value });
  const onRapidTestChanged = (value) => onChange({ rapidTest: value });
  const onPlatformChanged = (value) => onChange({ platform: value });
  const onSingleAppMode = (value) => onChange({ singleAppMode: value });

  return (
    <Panel className="test-suite-parameters-editor layout-row margin-bottom--20">
      <Column className="flex--50 padding-h--20">
        <Row className="padding-v--10" align="start center">
          <Column className="flex--50 padding-h--10">
            <label>
              Test Suite Name{" "}
              {errors.name && (
                <span className="color--error margin-top--15" style={{ fontSize: 12 }}>
                  ({errors.name})
                </span>
              )}
            </label>
            <TextField
              disabled={!canEdit}
              id="test-suite-name"
              type="text"
              value={name}
              onChange={onNameChanged}
            />
          </Column>

          <Column className="flex--50 padding-h--10">
            <label>Fail Code Display Mode</label>
            <Select
              disabled={!canEdit}
              value={failCodeDisplayMode}
              onSelect={onFailCodeDisplayModeChanged}
            >
              {failCodeDisplayModes.map((mode, index) => (
                <Select.Option key={index} value={mode}>
                  {mode}
                </Select.Option>
              ))}
            </Select>
          </Column>
        </Row>

        <Row className="padding-v--10" align="start center">
          <Column className="flex--50 padding-h--10">
            <label>Number of Operator Audits</label>
            <TextField
              disabled={!canEdit}
              id="number-of-operators-audits"
              type="number"
              defaultValue={nbOperatorAudits}
              onChange={onNbOperatorAuditsChanged}
              error={!!errors.nbOperatorAudits}
              errorText={errors.nbOperatorAudits}
            />
          </Column>

          <Column className="flex--50 padding-h--10">
            <label>
              Device Support Level{" "}
              {errors.deviceSupportLevel && (
                <span className="color--error margin-top--15" style={{ fontSize: 12 }}>
                  ({errors.deviceSupportLevel})
                </span>
              )}
            </label>
            <Select
              disabled={!canEdit}
              value={deviceSupportLevel}
              onSelect={onDeviceSupportLevelsChanged}
            >
              {deviceSupportLevels.map((level, index) => (
                <Select.Option key={index} value={level}>
                  {level}
                </Select.Option>
              ))}
            </Select>
          </Column>
        </Row>
      </Column>

      <Column
        className="flex--30 padding-h--20 border-grey--left border-grey--right"
        align="center"
      >
        <Row align="space-between center">
          <span>Fail Fast</span>
          <Switch
            disabled={!canEdit}
            id="switch-fail-fast"
            name="switch-fail-fast"
            type="switch"
            aria-label="Toggle Fail Fast"
            checked={isFailFast}
            onChange={onFailFastChanged}
          />
        </Row>

        <Row align="space-between center">
          <span>Auto Start</span>
          <Switch
            disabled={!canEdit}
            id="switch-auto-start"
            name="switch-auto-start"
            type="switch"
            aria-label="Toggle Auto Start"
            checked={autoStart}
            onChange={onAutoStartChanged}
          />
        </Row>

        <Row align="space-between center">
          <span>Navigation Buttons</span>
          <Switch
            disabled={!canEdit}
            id="switch-nav-buttons"
            name="switch-nav-buttons"
            type="switch"
            aria-label="Toggle Navigation Buttons"
            checked={!disableNavigationButtons}
            onChange={onNavigationButtonsChanged}
          />
        </Row>

        <Row align="space-between center">
          <span>Orientation Lock</span>
          <Switch
            disabled={!canEdit}
            id="switch-orientation-lock"
            name="switch-orientation-lock"
            type="switch"
            aria-label="Toggle Orientation Lock"
            checked={orientationLock}
            onChange={onOrientationLockChanged}
          />
        </Row>

        <Row align="space-between center">
          <span>Rapid Test</span>
          <Switch
            disabled={!canEdit}
            id="switch-rapid-test"
            name="switch-rapid-test"
            type="switch"
            aria-label="Toggle Rapid Test"
            checked={rapidTest}
            onChange={onRapidTestChanged}
          />
        </Row>

        <Row align="space-between center">
          <span>Single App Mode</span>
          <Switch
            disabled={!canEdit}
            id="switch-single-app-mode"
            name="switch-single-app-mode"
            type="switch"
            aria-label="Toggle Orientation Lock"
            checked={singleAppMode}
            onChange={onSingleAppMode}
          />
        </Row>
      </Column>

      <Column
        className="padding-h--20 flex--20"
        style={{ minWidth: "200px" }}
        align="center center"
      >
        <PlatformSelector
          disabled={!canEditPlatform}
          platforms={supportedPlatforms}
          selected={platform}
          onChange={onPlatformChanged}
        />

        {errors.platform && <p className="color--error margin-top--15">{errors.platform}</p>}
      </Column>
    </Panel>
  );
};

TestSuiteParametersEditor.propTypes = {
  canEdit: PropTypes.bool,
  canEditPlatform: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string,
  nbOperatorAudits: PropTypes.number,
  failCodeDisplayModes: PropTypes.arrayOf(PropTypes.string),
  failCodeDisplayMode: PropTypes.string,
  deviceSupportLevels: PropTypes.arrayOf(PropTypes.string),
  deviceSupportLevel: PropTypes.string,
  isFailFast: PropTypes.bool,
  autoStart: PropTypes.bool,
  disableNavigationButtons: PropTypes.bool,
  orientationLock: PropTypes.bool,
  rapidTest: PropTypes.bool,
  onChange: PropTypes.func,
  supportedPlatforms: PropTypes.array.isRequired,
  platform: PropTypes.string,
  singleAppMode: PropTypes.bool,
};

TestSuiteParametersEditor.defaultProps = {
  errors: {},
  name: "",
};

export default TestSuiteParametersEditor;
