import { useDebugValue, useEffect, useRef, useState } from "react";

export type Pagination = { page: number; pageSize: number };

/**
 * Handle pagination state.
 * @param onChangeCallback - This callback must be memoized with useCallback to avoid running it on
 * every render.
 */
export const usePagination = (
  onChangeCallback: (pagination: Pagination) => void,
  initialPagination: Pagination = { page: 1, pageSize: 10 }
) => {
  // The pageSize is stored in both a state and a ref:
  // - The state will ensure that a rerender will happen when it is updated
  // - The ref makes it possible to access it in an effect hook without re-running the effect when it changes
  const [pagination, setPagination] = useState(initialPagination);
  const pageSizeRef = useRef(pagination.pageSize);

  useEffect(() => {
    pageSizeRef.current = pagination.pageSize;
  }, [pagination.pageSize]);

  // Refresh the pagination
  // Reset the current page to 1, but keep the page size, when the callback changes
  useEffect(() => {
    const pagination = { page: 1, pageSize: pageSizeRef.current };

    setPagination(pagination);
    onChangeCallback(pagination);
  }, [onChangeCallback]);

  const changePagination = (page: number, pageSize: number) => {
    const pagination = { page, pageSize };

    setPagination(pagination);
    onChangeCallback(pagination);
  };

  useDebugValue(pagination);

  return { page: pagination.page, pageSize: pagination.pageSize, changePagination };
};
