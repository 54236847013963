import { createSelector } from "@reduxjs/toolkit";

export const getAuditResultsPerTest = (state) =>
  state.modules.cannedReports.auditResultsPerTest.items;

export const getChartDataKeys = createSelector([getAuditResultsPerTest], (auditResultsPerTest) =>
  (auditResultsPerTest.ChartData || []).length > 0
    ? Object.keys(auditResultsPerTest.ChartData[0])
    : []
);

export const getChartData = createSelector(
  [getAuditResultsPerTest, getChartDataKeys],
  (auditResultsPerTest, chartDataKeys) => {
    if (chartDataKeys.length > 0) {
      return auditResultsPerTest.ChartData.map((audit) => {
        const failedAuditsPercent = +audit.FailedAuditsPercent.toFixed(3);
        const auditWithPassedAuditsPercent = {
          ...audit,
          FailedAuditsPercent: failedAuditsPercent,
          PassedAuditsPercent: 100 - failedAuditsPercent,
        };

        return auditWithPassedAuditsPercent;
      });
    }
    return [];
  }
);
