import produce from "immer";

import {
  FETCH_HOME_DOCUMENTS_BEGIN,
  FETCH_HOME_DOCUMENTS_SUCCESS,
  FETCH_HOME_DOCUMENTS_ERROR,
} from "~/actions/Documentation.actions";

const initialState = {
  homeDocuments: [],
};

export default function (state = initialState, action) {
  const actions = {
    [FETCH_HOME_DOCUMENTS_BEGIN]: (draft) => {},
    [FETCH_HOME_DOCUMENTS_SUCCESS]: (draft) => {
      draft.homeDocuments = action.payload.documents || [];
    },
    [FETCH_HOME_DOCUMENTS_ERROR]: (draft) => {
      draft.homeDocuments = [];
    },
  };

  return produce(state, (draft) => {
    actions[action.type] ? actions[action.type](draft) : undefined;
  });
}
