import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./TablePagination.component.scss";

export default class TablePagination extends Component {
  static propTypes = {
    paginationCallback: PropTypes.func.isRequired,
    totalDoc: PropTypes.number.isRequired,
    docPerPage: PropTypes.number.isRequired,
    pageNo: PropTypes.number.isRequired,
    className: PropTypes.string,
    children: PropTypes.node, // to add other functionalities on the same navbar
  };

  static defaultProps = {
    className: "",
  };

  pagesFoward = 4;

  onCallback(page, docPerPage) {
    this.props.paginationCallback(page, docPerPage);
  }

  goToFirstPage = () => {
    if (this.props.pageNo !== 1) {
      this.onCallback(1);
    }
  };

  goToPrevPage = () => {
    const prevPage = this.props.pageNo - 1;
    if (prevPage >= 1) {
      this.onCallback(prevPage);
    }
  };

  goToNextPage = () => {
    const nextPage = this.props.pageNo + 1;
    const pageCount = Math.ceil(this.props.totalDoc / this.props.docPerPage);
    if (nextPage <= pageCount) {
      this.onCallback(nextPage);
    }
  };

  goToLastPage = () => {
    const lastPage = Math.ceil(this.props.totalDoc / this.props.docPerPage);
    if (this.props.pageNo !== lastPage) {
      this.onCallback(lastPage);
    }
  };

  render() {
    const pages = [];
    const pageCount = Math.ceil(this.props.totalDoc / this.props.docPerPage);
    const lastPageToShow =
      this.props.pageNo + this.pagesFoward < pageCount && pageCount > this.pagesFoward
        ? this.props.pageNo + this.pagesFoward
        : pageCount;
    const minWhenLastVisible =
      pageCount - (this.props.pageNo - 1) >= 5 ? this.props.pageNo - 1 : pageCount - 5;
    const firstPageToShow =
      this.props.pageNo - 1 > 1 ? (minWhenLastVisible > 1 ? minWhenLastVisible : 1) : 1;
    const firstDisplayedItemIndex =
      this.props.pageNo * this.props.docPerPage - this.props.docPerPage + 1;
    const lastDisplayedItemIndex =
      this.props.totalDoc > this.props.pageNo * this.props.docPerPage
        ? this.props.pageNo * this.props.docPerPage
        : this.props.totalDoc;
    const isFirstPage = this.props.pageNo === 1;
    const isLastPage = this.props.pageNo === pageCount;

    for (let index = firstPageToShow; index <= lastPageToShow; index++) {
      pages.push(
        <li
          key={`page-number-${index}`}
          className={classnames("pagination-nav-btn", { current: index == this.props.pageNo })}
          onClick={() => {
            this.onCallback(index);
          }}
        >
          {index}
        </li>
      );
    }

    return (
      <div className={classnames("cc-table-pagination row centered spaced", this.props.className)}>
        <div className="row docsPerPage">
          <div className="padding-h--5">{firstDisplayedItemIndex}</div>
          <div>-</div>
          <div className="padding-h--5">{lastDisplayedItemIndex}</div>
          <div className="padding-h--5"> of </div>
          <div className="padding-h--5">{this.props.totalDoc}</div>
        </div>

        {this.props.totalDoc > this.props.docPerPage ? (
          <ul className="pages-container row margin-bottom--0">
            <li
              onClick={this.goToFirstPage}
              className={classnames("page-navigation", { disabled: isFirstPage })}
            >
              <i className="icon-step-backward pagination-nav-btn" />
            </li>

            <li
              onClick={this.goToPrevPage}
              className={classnames("page-navigation", { disabled: isFirstPage })}
            >
              <i className="icon-chevron-left pagination-nav-btn" />
            </li>

            {pages}

            <li
              onClick={this.goToNextPage}
              className={classnames("page-navigation", { disabled: isLastPage })}
            >
              <i className="icon-chevron-right pagination-nav-btn" />
            </li>

            <li
              onClick={this.goToLastPage}
              className={classnames("page-navigation", { disabled: isLastPage })}
            >
              <i className="icon-step-forward pagination-nav-btn" />
            </li>
          </ul>
        ) : null}

        {this.props.children != null ? this.props.children : null}
      </div>
    );
  }
}
