import React, { useEffect, useRef } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputRef, Select, Space } from "antd";

import { SkuGroup, SkuGroupBody, deviceTypes } from "~/entities/sku-groups/model/skuGroupsSlice";
import { useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { selectAdditionalAttributes, selectSkuAttributes } from "../model/skuStaticsSlice";

type Props = {
  skuGroup: SkuGroup;
  onFinish: (values: SkuGroupBody) => void;
  onCancel: () => void;
};

export const UpdateSkuGroupForm = ({ skuGroup, onFinish, onCancel }: Props) => {
  const { t } = useTranslation("sku");
  const inputRef = useRef<InputRef>(null);

  const skuAttributes = useAppSelector(selectSkuAttributes);
  const additionalAttributes = useAppSelector(selectAdditionalAttributes);
  const { formStatus } = useAppSelector((state) => state.skuGroups);

  useEffect(() => {
    inputRef.current?.focus({ cursor: "end" });
  }, []);

  return (
    <Card
      title={
        <Space>
          <EditOutlined />
          <span>{t("edit-sku-group")}</span>
        </Space>
      }
      bordered={false}
      style={{ marginBottom: "2em" }}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          name: skuGroup.name,
          deviceType: skuGroup.deviceType,
          skuAttributes: skuGroup.skuAttributes,
          additionalAttributes: skuGroup.additionalAttributes,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name-required") }]}
        >
          <Input ref={inputRef} />
        </Form.Item>

        <Form.Item
          label={t("device-type")}
          name="deviceType"
          rules={[{ required: true, message: t("device-required") }]}
        >
          <Select
            style={{ width: 240 }}
            placeholder={t("select-device")}
            options={deviceTypes.map((type) => ({
              value: type,
              label: {
                phone: t("phone"),
                tablet: t("tablet"),
                watch: t("watch"),
              }[type],
            }))}
          />
        </Form.Item>

        <Form.Item
          label={t("required-attributes")}
          name="skuAttributes"
          rules={[{ required: true, message: t("attributes-required") }]}
        >
          <Select options={skuAttributes} mode="multiple" allowClear style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label={t("additional-attributes")} name="additionalAttributes">
          <Select
            options={additionalAttributes}
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={formStatus === "loading"}>
              {t("save")}
            </Button>

            <Button onClick={onCancel}>{t("cancel")}</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
