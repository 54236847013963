import { createSelector } from "@reduxjs/toolkit";

// fail code countries
const getCountries = (state) => state.countries.items;

export const getCountriesList = createSelector(getCountries, (countries = {}) =>
  Object.keys(countries).map((countryKey) => countries[countryKey])
);

export const getCountryNames = createSelector(getCountriesList, (countriesList = []) =>
  countriesList.map((countryData) => countryData.name)
);

export const getCountriesByName = createSelector(getCountriesList, (countriesList = []) =>
  countriesList.reduce(
    (acc, country) => ({
      ...acc,
      [country.name]: country,
    }),
    {}
  )
);
