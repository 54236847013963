import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import { DeviceTestDefinition } from "~/models";
import selectors from "~/selectors";

export const fetchTestDefinitionsBegin = createAction("FETCH_TEST_DEFINITIONS_BEGIN");
export const fetchTestDefinitionsSuccess = createAction("FETCH_TEST_DEFINITIONS_SUCCESS");
export const fetchTestDefinitionsError = createAction("FETCH_TEST_DEFINITIONS_ERROR");

export const duplicateTestDefinitionBegin = createAction("DUPLICATE_TEST_DEFINITION_BEGIN");
export const duplicateTestDefinitionSuccess = createAction("DUPLICATE_TEST_DEFINITION_SUCCESS");
export const duplicateTestDefinitionError = createAction("DUPLICATE_TEST_DEFINITION_ERROR");

export const deleteTestDefinitionBegin = createAction("DELETE_TEST_DEFINITION_BEGIN");
export const deleteTestDefinitionSuccess = createAction("DELETE_TEST_DEFINITION_SUCCESS");
export const deleteTestDefinitionError = createAction("DELETE_TEST_DEFINITION_ERROR");

export const setNameFilter = createAction("SET_TEST_DEFINITIONS_NAME_FILTER");

export const fetchTestDefinitions = (platform) => async (dispatch, getState) => {
  try {
    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    const query = CustomerManagement.gqlBuilder(`{
            test_definitions (platform: "${platform || ""}") {
                className,
                displayName,
                identifier,
                identifierDisplayName,
                platform,
                description,
                image,
                arguments {
                    name,
                    type,
                    value,
                    localization { ${displayNameLanguages} }
                },
                failCodes,
                inclusionType,
                targetModels
            }
        }`);

    dispatch(fetchTestDefinitionsBegin());

    const response = await CustomerManagement.QUERY(query);
    const definitions = (((response.payload || {}).data || {}).test_definitions || []).map((td) =>
      DeviceTestDefinition.parse(td)
    );

    dispatch(fetchTestDefinitionsSuccess({ testDefinitions: definitions }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchTestDefinitionsError({ error: e }));
  }
};

export const duplicateTestDefinition =
  (definition = {}, newName) =>
  async (dispatch) => {
    try {
      const duplicate = {
        ...definition,
        arguments: (definition.arguments || []).map((arg) => ({ ...arg })),
        identifierDisplayName: newName,
        identifier: "",
      };

      dispatch(duplicateTestDefinitionBegin());

      const response = await CustomerManagement.POST(
        "/test-definitions",
        DeviceTestDefinition.toServerFormat(duplicate)
      );
      const newDefinition = DeviceTestDefinition.parse(response.payload);

      dispatch(duplicateTestDefinitionSuccess({ definition: newDefinition }));

      return newDefinition;
    } catch (e) {
      if (e instanceof Error) throw e;
      dispatch(duplicateTestDefinitionError({ error: e.json.message }));
    }
  };

export const deleteTestDefinition = (platform, identifier) => async (dispatch) => {
  try {
    dispatch(deleteTestDefinitionBegin());

    await CustomerManagement.DELETE(`/test-definitions/${platform}/${identifier}`);

    dispatch(deleteTestDefinitionSuccess({ platform, identifier }));
  } catch (e) {
    dispatch(deleteTestDefinitionError({ errors: e }));
  }
};
