import { createAction } from "@reduxjs/toolkit";

import { ConsumerManagement } from "~/services";

export const fetchTestCategoriesBegin = createAction("FETCH_TEST_CATEGORIES_BEGIN");
export const fetchTestCategoriesSuccess = createAction("FETCH_TEST_CATEGORIES_SUCCESS");
export const fetchTestCategoriesError = createAction("FETCH_TEST_CATEGORIES_ERROR");

export const setSelectedCategory = createAction("SET_SELECTED_TEST_CATEGORY");

export const fetchTestCategories = () => async (dispatch) => {
  try {
    dispatch(fetchTestCategoriesBegin());

    const response = await ConsumerManagement.POST("/gql", {
      query: ConsumerManagement.gqlBuilder(`{
                test_categories {
                    name,
                    displayName,
                    image,
                    classNames
                }
            }`),
    });
    const testCategories = response.payload.data.test_categories || [];

    dispatch(fetchTestCategoriesSuccess(testCategories));
  } catch (e) {
    dispatch(fetchTestCategoriesError({ error: e }));
  }
};
