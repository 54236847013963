import React, { useEffect, useState, useMemo } from "react";
import includes from "lodash/includes";

import PropTypes from "prop-types";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { FontIcon } from "react-md";
import { SelectList, ExpandablePanel } from "~/global";
import { ServiceArgumentsEditor } from "~/programs-management";

import "./ServiceSuiteServicesList.component.scss";
import PrintLabelServiceEditor from "~/programs-management/components/PrintLabelServiceEditor/PrintLabelServiceEditor";
import ServiceEventsEditor from "~/workflow/components/editors/ServiceEventsEditor";

const DragHandle = SortableHandle(() => (
  <div className="drag-expandable">
    <FontIcon iconClassName="icon-sort" />
  </div>
));

const ListItem = ({
  service,
  onServiceToggled,
  onTestSuiteChanged,
  testSuitesAndroid,
  onServiceArgumentChanged,
  diagnosticDeviceArgumentsByName,
  testSuitesIos,
  switchView,
  labels,
}) => {
  const [androidTestsList, setAndroidTestList] = useState([]);
  const [iosTestsList, setIosTestList] = useState([]);

  useEffect(() => {
    testSuitesAndroid != null ? setAndroidTestList(Object.keys(testSuitesAndroid)) : null;
    testSuitesIos != null ? setIosTestList(Object.keys(testSuitesIos)) : null;
  }, []);

  const content = useMemo(() => {
    switch (service.identifier) {
      case "DiagnosticsSuite":
        // FIXME extract to DiagnosticsSuiteServiceEditor component.
        return (
          <div className="row space-around">
            <div className="row centered">
              <div className="label margin-right--10">Test Suite (Android)</div>
              <div>
                <SelectList
                  items={androidTestsList}
                  nameBuilder={(tsid) => testSuitesAndroid[tsid].name}
                  selectedItem={diagnosticDeviceArgumentsByName.testSuiteAndroid.value}
                  onChange={(ts) => onTestSuiteChanged("testSuiteAndroid", ts)}
                />
              </div>
            </div>
            <div className="row centered">
              <div className="label margin-right--10">Test Suite (IOS)</div>
              <div>
                <SelectList
                  items={iosTestsList}
                  nameBuilder={(tsid) => testSuitesIos[tsid].name}
                  selectedItem={diagnosticDeviceArgumentsByName.testSuiteIOS.value}
                  onChange={(ts) => onTestSuiteChanged("testSuiteIOS", ts)}
                />
              </div>
            </div>
          </div>
        );
      case "PrintLabel":
        return (
          <PrintLabelServiceEditor
            service={service}
            onServiceArgumentChanged={onServiceArgumentChanged}
          />
        );
      default:
        return (
          <ServiceArgumentsEditor
            isEditable={includes(
              [
                "PushWifiConfigService",
                "GsmaDeviceCheckService",
                "PrintLabel",
                "CheckESimRemoved",
                "WaitForBatteryChargedService",
                "GatherDeviceIdentifierService",
                "RunDiagnosticsApplicationService", // Workaround for WEB-969
              ],
              service.className
            )} // TODO: ideally track this in Mongo (ie, service.arguments.isEditable: "true")
            arguments={service.arguments}
            onChange={(newArgs) => onServiceArgumentChanged(service.identifier, newArgs)}
          />
        );
    }
  }, [
    service.identifier,
    service.arguments,
    service.className,
    androidTestsList,
    iosTestsList,
    diagnosticDeviceArgumentsByName,
    testSuitesAndroid,
    testSuitesIos,
    labels,
  ]);

  return (
    <div className="row service-container">
      {switchView ? <DragHandle /> : null}

      <ExpandablePanel
        id={service.identifier}
        title={service.displayName.en}
        secondaryLabel={`ID: ${service.identifier}`}
        onSelect={() => onServiceToggled(service.identifier)}
        isToggleable={service.isOptional}
        isOpenable
        isSelected={service.isEnabled}
      >
        {content}
        <ServiceEventsEditor serviceIdentifier={service.identifier} />
      </ExpandablePanel>
    </div>
  );
};

ListItem.propTypes = {
  diagnosticDeviceArgumentsByName: PropTypes.object.isRequired,
  onServiceArgumentChanged: PropTypes.func.isRequired,
  onServiceToggled: PropTypes.func.isRequired,
  onTestSuiteChanged: PropTypes.func.isRequired,
  service: PropTypes.object,
  testSuitesAndroid: PropTypes.object,
  testSuitesIos: PropTypes.object,
  switchView: PropTypes.bool,
};

ListItem.defaultProps = {
  service: [],
  switchView: false,
  testSuitesAndroid: { name: "", tsid: "" },
  testSuitesIos: { name: "", tsid: "" },
};

const SortableItem = SortableElement(
  ({
    service,
    switchView,
    onServiceToggled,
    onTestSuiteChanged,
    testSuitesAndroid,
    onServiceArgumentChanged,
    diagnosticDeviceArgumentsByName,
    testSuitesIos,
    order,
  }) => (
    <ListItem
      order={order}
      switchView={switchView}
      service={service}
      onServiceToggled={onServiceToggled}
      onTestSuiteChanged={onTestSuiteChanged}
      testSuitesAndroid={testSuitesAndroid}
      onServiceArgumentChanged={onServiceArgumentChanged}
      diagnosticDeviceArgumentsByName={diagnosticDeviceArgumentsByName}
      testSuitesIos={testSuitesIos}
    />
  )
);

const SortableList = SortableContainer(
  ({
    items,
    switchView,
    onServiceToggled,
    onTestSuiteChanged,
    testSuitesAndroid,
    onServiceArgumentChanged,
    diagnosticDeviceArgumentsByName,
    testSuitesIos,
  }) => (
    <ul>
      {items.map((service, index) => (
        <SortableItem
          key={`item-${service.identifier}`}
          switchView={switchView}
          index={index}
          order={index + 1}
          service={service}
          onServiceToggled={onServiceToggled}
          onTestSuiteChanged={onTestSuiteChanged}
          testSuitesAndroid={testSuitesAndroid}
          onServiceArgumentChanged={onServiceArgumentChanged}
          diagnosticDeviceArgumentsByName={diagnosticDeviceArgumentsByName}
          testSuitesIos={testSuitesIos}
        />
      ))}
    </ul>
  )
);

const ServiceSuiteServicesList = ({
  services,
  switchView,
  onServiceToggled,
  onTestSuiteChanged,
  testSuitesAndroid,
  onServiceArgumentChanged,
  diagnosticDeviceArgumentsByName,
  testSuitesIos,
  onOrderChanged,
}) => {
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (services.length > 0) {
      // const newSelectedServices = services.filter(service => switchView ? true : service.isEnabled);
      setSelectedServices(services);
    }
  }, [switchView, services]);

  const sortableListOrderChanged = ({ oldIndex, newIndex }) => {
    onOrderChanged(
      arrayMove(
        selectedServices.map((service) => service.identifier),
        oldIndex,
        newIndex
      )
    );
  };

  return (
    <div>
      <SortableList
        items={selectedServices || []}
        onSortEnd={sortableListOrderChanged}
        switchView={switchView}
        useDragHandle
        lockAxis="y"
        onServiceToggled={onServiceToggled}
        onTestSuiteChanged={onTestSuiteChanged}
        testSuitesAndroid={testSuitesAndroid}
        onServiceArgumentChanged={onServiceArgumentChanged}
        diagnosticDeviceArgumentsByName={diagnosticDeviceArgumentsByName}
        testSuitesIos={testSuitesIos}
      />
    </div>
  );
};

ServiceSuiteServicesList.propTypes = {
  diagnosticDeviceArgumentsByName: PropTypes.object.isRequired,
  onOrderChanged: PropTypes.func.isRequired,
  onServiceArgumentChanged: PropTypes.func.isRequired,
  onServiceToggled: PropTypes.func.isRequired,
  onTestSuiteChanged: PropTypes.func.isRequired,
  services: PropTypes.array,
  switchView: PropTypes.bool,
  testSuitesAndroid: PropTypes.object,
  testSuitesIos: PropTypes.object,
  labels: PropTypes.array,
};

ServiceSuiteServicesList.defaultProps = {
  onOrderChanged: () => {},
  services: [],
  switchView: false,
  testSuitesAndroid: [],
  testSuitesIos: [],
};

export default ServiceSuiteServicesList;
