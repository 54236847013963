import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

import "./TaskStatus.scss";

const TaskStatus = (props) => {
  const { status } = props;
  const css = ["task-status", status.toLowerCase()].join(" ");
  const icon = useMemo(() => {
    switch (status) {
      case "PENDING":
        return <FontIcon iconClassName="icon-hour-glass" />;
      case "RESOLVED":
        return <FontIcon iconClassName="icon-check" />;
      case "CANCELED":
        return <FontIcon iconClassName="icon-close" />;
      case "ERROR":
        return <FontIcon iconClassName="icon-exclamation-triangle" />;
      default:
        return null;
    }
  }, [status]);
  return (
    <div className={css}>
      {icon}&nbsp;{status}
    </div>
  );
};

TaskStatus.defaultProps = {};

TaskStatus.propTypes = {
  status: PropTypes.string,
};

export default TaskStatus;
