import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TextField, DialogContainer, Button } from "react-md";
import keys from "lodash/keys";
import { Popover } from "antd";
import { connect } from "react-redux";

import selectors from "~/selectors";
import { getRegexValidation } from "~/global/utils";
import {
  SectionHeader,
  CategoryTestsSelector,
  PlatformIcon,
  AsciButton,
  Checkbox,
  SelectList,
  StaticField,
  DialogMessage,
  DialogConfirmation,
} from "~/global";
import { RemoteDiagnosticsActions } from "~/actions";
import DialogCreateTestSet from "./DialogCreateTestSet.component";
import SessionResults from "../SessionResults/SessionResults.component";

import "./RemoteSessionEditor.component.scss";

class RemoteSessionEditor extends Component {
  SEND_BY_EMAIL_VALUE = "Email";
  SEND_BY_SMS_VALUE = "SMS";

  FIRST_NAME_MAX_LENGTH = 30;
  LAST_NAME_MAX_LENGTH = 30;

  static propTypes = {
    definitionsByPlatformByClassName: PropTypes.objectOf(
      // key: platform
      PropTypes.objectOf(
        // key: className
        PropTypes.arrayOf(
          // items: test definitions
          PropTypes.object // item: test definition
        )
      )
    ),
    categoriesWithDefinitionsByPlatform: PropTypes.arrayOf(
      // one item per category
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        classNames: PropTypes.arrayOf(PropTypes.string),
        image: PropTypes.string,
        definitionsByPlatform: PropTypes.objectOf(
          // key: platform
          PropTypes.arrayOf(
            // items: test definitions
            PropTypes.object // item: test definition
          )
        ),
      })
    ),
    classNameByPlatformByIdentifier: PropTypes.objectOf(
      // key: platform
      PropTypes.objectOf(
        // key: identifier
        PropTypes.string // value: class name for this identifier
      )
    ),
    license: PropTypes.string.isRequired,
    selectedSession: PropTypes.object,
    onSelectedSessionChanged: PropTypes.func.isRequired,
  };

  static defaultProps = {
    definitionsByPlatformByClassName: {},
    categoriesWithDefinitionsByPlatform: [],
    classNameByPlatformByIdentifier: {},
  };

  state = {
    dialog: null,
    errors: {},
    sendOptions: [this.SEND_BY_SMS_VALUE, this.SEND_BY_EMAIL_VALUE],
    platform: "",
    sendBy: this.SEND_BY_SMS_VALUE,
    // firstName: "",
    // lastName: "",
    phone: "",
    // email: "",
    imei: "",
    selectedIdentifiersByCategory: {},
  };

  componentDidMount() {
    this.updateTemplates();
  }

  onChange = (value, e) => {
    this.setState({
      [e.target.name]: value,
    });
  };

  onPlatformChanged = (platform) => {
    if (platform !== this.state.platform) {
      const newSelectedIdentifiersByCategory = keys(
        this.state.selectedIdentifiersByCategory
      ).reduce((acc, categoryName) => {
        acc[categoryName] = [];
        return acc;
      }, {});

      // TODO: add warning/message to tell the user that all tests have been unselected

      this.setState({
        platform,
        selectedIdentifiersByCategory: newSelectedIdentifiersByCategory,
      });
    }
  };

  hideDialog = () => {
    this.setState({ dialog: null });
  };

  createTemplate = () => {
    const allSelectedIdentifiers = this.getAllSelectedIdentifiers();

    if (allSelectedIdentifiers.length === 0) {
      this.setState({
        dialog: {
          title: "Create Test Set",
          content: (
            <DialogMessage close={this.hideDialog}>
              At least one test must be selected to create a test set.
            </DialogMessage>
          ),
        },
      });
      return;
    }

    this.setState({
      dialog: {
        title: "Create Test Set",
        content: (
          <DialogCreateTestSet
            identifiers={allSelectedIdentifiers}
            platform={this.state.platform}
            onHide={this.hideDialog}
            onSubmit={async (name) =>
              RemoteDiagnosticsActions.createTemplate(
                this.props.license,
                this.state.platform,
                name,
                allSelectedIdentifiers
              ).then(() => {
                this.updateTemplates();
                this.hideDialog();
              })
            }
          />
        ),
      },
    });
  };

  updateTemplates = async () => {
    const templates = await RemoteDiagnosticsActions.fetchTemplates(this.props.license);

    this.setState({ templates });
  };

  onTemplateToggled = (template, isChecked) => {
    const newIdentifiersByCategory = { ...this.state.selectedIdentifiersByCategory };

    // for all identifiers in the template
    template.identifiers.forEach((identifier) => {
      // find matching class name
      const matchingClassName =
        this.props.classNameByPlatformByIdentifier[this.state.platform][identifier];
      if (!matchingClassName) {
        console.warn(`couldn't associate identifier ${identifier} with a test className`);
        return;
      }

      // find the category that contains this className
      const category = this.props.categoriesWithDefinitionsByPlatform.filter((category) =>
        category.classNames.includes(matchingClassName)
      )[0];
      if (!category) {
        console.warn("didn't find any category that contains className", matchingClassName);
        return;
      }

      const categorySelectedIdentifiers = newIdentifiersByCategory[category.name] || [];

      // find selected definition for this class name
      const selectedDefinition = this.props.definitionsByPlatformByClassName[this.state.platform][
        matchingClassName
      ].filter((definition) => categorySelectedIdentifiers.includes(definition.identifier))[0];

      // found a selected identifier for this class name
      if (selectedDefinition && !isChecked) {
        // Template was toggled OFF and this identifier was already selected, unselect the template's identifier
        const index = categorySelectedIdentifiers.indexOf(selectedDefinition.identifier);
        categorySelectedIdentifiers.splice(index, 1);
      } else if (!selectedDefinition && isChecked) {
        // Template was toggled ON and there were no identifier selected for this class name, select the template's identifier
        newIdentifiersByCategory[category.name] = [...categorySelectedIdentifiers, identifier];
      } else if (selectedDefinition && isChecked) {
        // Template was toggled ON and an identifier for this class name was already selected, make sure the template's identifier is the selected one
        const index = categorySelectedIdentifiers.indexOf(selectedDefinition.identifier);
        categorySelectedIdentifiers[index] = identifier;
      }
    });

    this.setState({ selectedIdentifiersByCategory: newIdentifiersByCategory });
  };

  isTemplateSelected = (template, selectedIdentifiers) =>
    // all identifiers from the template must be in selected
    template.identifiers.filter((identifier) => selectedIdentifiers.includes(identifier)).length ==
    template.identifiers.length;

  deleteTemplate = (template) => {
    this.setState({
      dialog: {
        title: "Delete Test Set",
        content: (
          <DialogConfirmation
            onCancel={this.hideDialog}
            onConfirm={() => {
              RemoteDiagnosticsActions.deleteTemplate(this.props.license, template.name).then(
                () => {
                  const index = this.state.templates.indexOf(template);
                  const newTemplates = [...this.state.templates];
                  newTemplates.splice(index, 1);

                  this.hideDialog();
                  this.setState({ templates: newTemplates });
                }
              );
            }}
            textButtonConfirm="Delete"
          >
            <p>
              Are you sure you want to delete test set <b>{template.name}</b>?
            </p>
          </DialogConfirmation>
        ),
      },
    });
  };

  submit = () => {
    const errors = {};

    const platformMessage = this.validatePlatform(this.state.platform);
    if (platformMessage) {
      errors.platform = platformMessage;
    }

    const testsMessage = this.validateTests();
    if (testsMessage) {
      errors.tests = testsMessage;
    }

    if (this.state.sendBy == this.SEND_BY_SMS_VALUE) {
      const phoneMessage = this.validatePhone();
      if (phoneMessage) {
        errors.phone = phoneMessage;
      }
    }

    const imeiMessage = this.validateIMEI(this.state.imei);
    if (imeiMessage) {
      errors.imei = imeiMessage;
    }

    // if(this.state.sendBy == this.SEND_BY_EMAIL_VALUE) {
    //     const emailMessage = this.validateEmail();
    //     if(emailMessage) {
    //         errors["email"] = emailMessage;
    //     }
    // }

    this.setState({ errors }, () => {
      if (keys(this.state.errors).length > 0) {
        window.scrollTo(0, 0);
      } else {
        let phone = (this.state.phone || "").replace(/-/g, "");
        if (phone != "") {
          // ... make sure there's a 1 before the number and remove the "-" in the formatted string
          phone = this.formatPhone(this.state.phone).replace(/-/g, "");
        }

        const data = {
          cellphone: phone,
          // email: this.state.email,
          tests: this.getAllSelectedIdentifiers(),
          // firstName: this.state.firstName,
          // lastName: this.state.lastName,
          notifyBy: this.state.sendBy,
          platform: this.state.platform,
          imei: this.state.imei,
        };

        RemoteDiagnosticsActions.createSession(this.props.license, data)
          .then((sessionId) => {
            let message;
            if (data.notifyBy === this.SEND_BY_SMS_VALUE) {
              message = "A SMS has been sent to the provided phone number with instructions.";
              // } else if(data.notifyBy === this.SEND_BY_EMAIL_VALUE) {
              //     message = "An e-mail has been sent to the provided e-mail address with instructions."
            }

            this.setState({
              dialog: {
                title: "Session Created Successfully",
                content: (
                  <DialogMessage
                    close={() => {
                      this.props.onSelectedSessionChanged({ sessionId });
                      this.hideDialog();
                    }}
                  >
                    {message}
                  </DialogMessage>
                ),
              },
            });
          })
          .catch((err) => {
            if (err instanceof Error) {
              console.error(err);
              return;
            }

            this.setState({
              dialog: {
                title: "Failed to create session",
                content: <DialogMessage close={this.hideDialog}>{err.json.message}</DialogMessage>,
              },
            });
          });
      }
    });
  };

  validateTests = () => {
    const identifiers = this.getAllSelectedIdentifiers();

    if (identifiers.length === 0) {
      return "At least one test must be selected";
    }
  };

  validateFirstName = (firstName) => {
    if (firstName == null || firstName === "") {
      return;
    }

    if (firstName.length >= this.FIRST_NAME_MAX_LENGTH) {
      return `First name must be less than ${this.FIRST_NAME_MAX_LENGTH} characters`;
    }
  };

  validateLastName = (lastName) => {
    if (lastName == null || lastName === "") {
      return;
    }

    if (lastName.length >= this.LAST_NAME_MAX_LENGTH) {
      return `Last name must be less than ${this.LAST_NAME_MAX_LENGTH} characters`;
    }
  };

  optionalValidate = (key, value, validator) => {
    const errors = { ...this.state.errors };
    const errorMessage = validator(value);

    if (errorMessage) {
      errors[key] = errorMessage;
    } else {
      delete errors[key];
    }

    this.setState({ errors });
  };

  validatePhone = () => {
    const MIN_LENGTH = 10; // ex. 514-555-1234

    const trimmed = `${this.state.phone || ""}`.trim().replace(/[^0-9]/g, "");
    if (trimmed.length < MIN_LENGTH) {
      return "Invalid phone format (ex.: 1-222-333-4444)";
    }
  };

  validateEmail = () => {
    if (!getRegexValidation("User_Email", this.state.email)) {
      return "Invalid email";
    }
  };

  validatePlatform = (platform = "") => {
    if (platform === "") {
      return "Device type is required";
    }
  };

  validateIMEI = (imei = "") => {
    if (imei === "") {
      return "IMEI required";
    }

    if (!getRegexValidation("IMEI", imei)) {
      return "IMEI is invalid (it must be 15 numbers)";
    }
  };

  formatPhone = (value = "") => {
    const trimmed = `${value}`.trim().replace(/[^0-9]/g, "");

    const countryCode = trimmed.slice(0, trimmed.length - 10); // X-555-555-5555
    const regionCode = trimmed.slice(trimmed.length - 10, trimmed.length - 7); // 1-XXX-555-5555
    const numberStart = trimmed.slice(trimmed.length - 7, trimmed.length - 4); // 1-555-XXX-5555
    const numberEnd = trimmed.slice(trimmed.length - 4, trimmed.length); // 1-555-555-XXXX

    if (countryCode.length === 0) {
      return `1-${regionCode}-${numberStart}-${numberEnd}`;
    }
    return `${countryCode}-${regionCode}-${numberStart}-${numberEnd}`;
  };

  newSession = () => {
    // reset everything (except platform for ux)
    this.setState(
      {
        // firstName: "",
        // lastName: "",
        phone: "",
        // email: "",
        imei: "",
        errors: {},
        sendBy: this.SEND_BY_SMS_VALUE,
        platform: "",
        selectedIdentifiersByCategory: {},
      },
      () => this.props.onSelectedSessionChanged(null)
    );
  };

  getSelectedCategoryIdentifiers = (definitions) =>
    definitions
      .filter((definition) => this.state.selectedIdentifiers.includes(definition.identifier))
      .map((definition) => definition.identifier);

  onCategorySelectionChanged = (identifiers, name) => {
    const selectedIdentifiersByCategory = {
      ...this.state.selectedIdentifiersByCategory,
      [name]: identifiers,
    };

    this.setState({ selectedIdentifiersByCategory });
  };

  getAllSelectedIdentifiers = () =>
    keys(this.state.selectedIdentifiersByCategory).reduce(
      (acc, item) => [...acc, ...this.state.selectedIdentifiersByCategory[item]],
      []
    );

  getTestsByCategory = () =>
    this.props.categoriesWithDefinitionsByPlatform
      .map((cat) => ({
        name: cat.name,
        displayName: cat.displayName,
        image: cat.image,
        definitions: (cat.definitionsByPlatform[this.props.selectedSession.platform] || []).filter(
          (def) => {
            if (
              this.props.selectedSession.results &&
              this.props.selectedSession.results.testResults
            ) {
              for (let i = 0; i < this.props.selectedSession.results.testResults.length; i++) {
                const res = this.props.selectedSession.results.testResults[i];

                if (def.className === res.className && def.identifier === res.identifier) {
                  return true;
                }
              }
            }
            return false;
          }
        ),
      }))
      .filter((cat) => cat.definitions.length > 0);

  render() {
    const selectedIdentifiers = this.getAllSelectedIdentifiers();
    const isExistingSession = !!this.props.selectedSession;

    let firstName;
    let lastName;
    let phone;
    let email;
    let platform;
    let imei;
    if (isExistingSession) {
      // firstName = this.props.selectedSession.firstName;
      // lastName = this.props.selectedSession.lastName;
      phone = this.props.selectedSession.phone;
      // email = this.props.selectedSession.email;
      platform = this.props.selectedSession.platform;
      imei = this.props.selectedSession.imei;
    } else {
      // firstName = this.state.firstName;
      // lastName = this.state.lastName;
      phone = this.state.phone;
      // email = this.state.email;
      platform = this.state.platform;
      imei = this.state.imei;
    }

    return (
      <div className="remote-session-editor">
        {this.state.dialog ? (
          <DialogContainer
            id="remote-session-editor-dialog"
            visible
            modal={this.state.dialog.modal}
            title={this.state.dialog.title}
            width={this.state.dialog.width}
            onHide={this.hideDialog}
            focusOnMount={!!this.state.dialog.focusOnMount}
          >
            {this.state.dialog.content}
          </DialogContainer>
        ) : null}

        <SectionHeader title="Details">
          <AsciButton color="white" onClick={this.newSession}>
            Clear
          </AsciButton>
        </SectionHeader>

        <div className="grey--1 padding-v--10 padding-h--20 layout-row">
          <div className="padding--10 flex--50 layout-column">
            {/* <StaticField label="First Name:">
                            <TextField
                                id="field-firstName"
                                name="firstName"
                                value={firstName}
                                onChange={this.onChange}
                                disabled={isExistingSession}
                                onBlur={() => this.optionalValidate("firstName", firstName, this.validateFirstName)}
                                error={!!this.state.errors.firstName}
                                errorText={this.state.errors.firstName}
                                tabIndex={1}
                            />
                        </StaticField> */}

            <StaticField label="Device Type:" className="margin-top--10">
              <div className="layout-row">
                <PlatformIcon
                  platform="android"
                  size="30"
                  className={classnames({
                    selectable: !isExistingSession,
                    selected: platform === "android",
                  })}
                  onClick={() =>
                    isExistingSession ? undefined : this.onPlatformChanged("android")
                  }
                  tabIndex={1}
                />
                <PlatformIcon
                  platform="ios"
                  size="30"
                  className={classnames({
                    selectable: !isExistingSession,
                    selected: platform === "ios",
                  })}
                  onClick={() => (isExistingSession ? undefined : this.onPlatformChanged("ios"))}
                  tabIndex={2}
                />
              </div>

              {this.state.errors.platform ? (
                <div className="margin-right--20 color--error">{this.state.errors.platform}</div>
              ) : null}
            </StaticField>
          </div>

          <div className="padding--10 flex--50 layout-column">
            <StaticField label="IMEI:">
              <TextField
                id="field-imei"
                name="imei"
                value={imei}
                onChange={this.onChange}
                disabled={isExistingSession}
                error={!!this.state.errors.imei}
                errorText={this.state.errors.imei}
                tabIndex={3}
              />
            </StaticField>

            {!isExistingSession ? (
              <StaticField label="Phone #:">
                <TextField
                  id="field-phone"
                  name="phone"
                  placeholder="Format: 1-222-333-4444"
                  value={phone}
                  error={!!this.state.errors.phone}
                  errorText={this.state.errors.phone}
                  onChange={this.onChange}
                  disabled={isExistingSession}
                  tabIndex={4}
                />
              </StaticField>
            ) : null}

            {/* <StaticField label="Last Name:">
                            <TextField
                                id="field-lastName"
                                name="lastName"
                                value={lastName}
                                onChange={this.onChange}
                                disabled={isExistingSession}
                                onBlur={() => this.optionalValidate("lastName", lastName, this.validateLastName)}
                                error={!!this.state.errors.lastName}
                                errorText={this.state.errors.lastName}
                                tabIndex={2}
                            />
                        </StaticField> */}

            {/* <StaticField label="Email:">
                            <TextField
                                id="field-email"
                                name="email"
                                value={email}
                                error={!!this.state.errors.email}
                                errorText={this.state.errors.email}
                                onChange={this.onChange}
                                disabled={isExistingSession}
                                tabIndex={4}
                            />
                        </StaticField> */}
          </div>
        </div>

        <SectionHeader title="Tests">
          {!isExistingSession && platform !== "" ? (
            <div className="layout-column layout-column--start-center">
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div style={{ minWidth: "200px" }}>
                    <p className="align-center bold margin-bottom--0">
                      Templates for {this.state.platform} platform
                    </p>

                    {this.state.templates && this.state.templates.length > 0 ? (
                      this.state.templates.map((template, index) => (
                        <div key={index} className="layout-row layout-row--space-between-center">
                          <Checkbox
                            id={`checkbox-template-${index}`}
                            isChecked={this.isTemplateSelected(template, selectedIdentifiers)}
                            label={template.name}
                            onChange={(isChecked) => this.onTemplateToggled(template, isChecked)}
                          />

                          {this.props.canDeleteTestSets ? (
                            <Button
                              floating
                              className="asci-btn white-button mini flex--noshrink"
                              iconClassName="icon-close"
                              onClick={(e) => this.deleteTemplate(template)}
                            />
                          ) : null}
                        </div>
                      ))
                    ) : (
                      <div className="align-center margin-top--10">No tests set available</div>
                    )}
                  </div>
                }
              >
                <AsciButton color="green">From Test Set</AsciButton>
              </Popover>
            </div>
          ) : null}
        </SectionHeader>

        {/* List categories */}
        {isExistingSession ? (
          <SessionResults
            session={this.props.selectedSession}
            testsByCategory={this.getTestsByCategory()}
          />
        ) : platform !== "" ? (
          this.props.categoriesWithDefinitionsByPlatform.map((category) => {
            const selectedIdentifiers =
              this.state.selectedIdentifiersByCategory[category.name] || [];
            const definitions = category.definitionsByPlatform[this.state.platform];

            return (
              <CategoryTestsSelector
                id={`category-${category.name}`}
                key={`category-${category.name}`}
                name={category.name}
                image={category.image}
                displayName={category.displayName}
                testDefinitions={definitions}
                selectedIdentifiers={selectedIdentifiers}
                closedByDefault={selectedIdentifiers.length === 0}
                onChange={this.onCategorySelectionChanged}
              />
            );
          })
        ) : (
          <div className="layout-column layout-column--center-center padding--20">
            Select a device type to see the tests available for testing
          </div>
        )}

        {!isExistingSession ? (
          <div className="grey--1 padding-v--10 layout-row layout-row--space-between-center">
            {this.props.canCreateTestSets && platform !== "" ? (
              <AsciButton color="white" onClick={this.createTemplate}>
                Create Test Set
              </AsciButton>
            ) : null}

            <div className="layout-row layout-row--end-center flex">
              {this.state.errors.tests ? (
                <div className="margin-right--20 color--error">{this.state.errors.tests}</div>
              ) : null}

              {/* <div className="layout-row layout-row--start-center">
                                <div className="margin-right--10">Send By:</div>

                                <SelectList
                                    id="send-by-list"
                                    items={this.state.sendOptions}
                                    selectedItem={this.state.sendBy}
                                    onChange={value => this.setState({sendBy: value})}
                                    tabIndex={7}
                                />
                            </div> */}

              <AsciButton color="blue" onClick={this.submit} tabIndex={8}>
                Send
              </AsciButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const stateToProps = (state) => ({
  canCreateTestSets: selectors.session.hasPermission(
    state,
    "GET",
    "CREATE_REMOTE_DIAGNOSTICS_TEMPLATE"
  ),
  canDeleteTestSets: selectors.session.hasPermission(
    state,
    "GET",
    "DELETE_REMOTE_DIAGNOSTICS_TEMPLATE"
  ),
});

export default connect(stateToProps, null)(RemoteSessionEditor);
