export default {
  Shift_Name: {
    Semantics: "The symbolic name of a shift, eg Day-shift, Night-shift",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "ShiftID | JavaLauncher.xsd",
      "CustomerFields.ShiftID | RecellResult.xsd",
      "Shift.Name | datawarehouse_1_29",
    ],
  },
  "ShiftID | JavaLauncher.xsd": {
    AliasFor: "Shift_Name",
  },
  "Shift.Name | datawarehouse_1_29": {
    AliasFor: "Shift_Name",
  },
  Configuration_Name: {
    Semantics: "<definition string>  // This block defines an named DataElementObject",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "ConfigurationName | JavaLauncher.xsd",
      "ConfigurationName | RecellResult.xsd",
      "Configuration.name | datawarehouse_1_29",
    ],
  },
  Team_Name: {
    Semantics: "the name designating a group of Operators in a Facility",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "Team | JavaLauncher.xsd",
      " CustomerFields.TeamId | RecellResult.xsd",
      "Team.name | datawarehouse_1_29",
    ],
  },
  Team_Description: {
    Semantics: "the description of a designating a group of Operators in a Facility",
    Syntax: /^[/\w\W\s]{0,45}$/,
  },
  Bench_Name: {
    Semantics: "the name designating bench or work cell location in a Facility",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "Bench | JavaLauncher.xsd",
      " CustomerFields.WorkstationID | RecellResult.xsd",
      "Bench.number | datawarehouse_1_29",
    ],
  },
  Supplier_Name: {
    Semantics: "the name of the supplier of devices to our customer",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "<TBD> | JavaLauncher.xsd",
      " CustomerFields.<TBD> | RecellResult.xsd",
      "Supplier.name | datawarehouse_1_29",
    ],
  },
  Batch_Name: {
    Semantics: "the name of a group or lot of devices; normally received from a single supplier",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd"],
    Aliases: [
      "BatchId | JavaLauncher.xsd",
      " CustomerFields.BatchID | RecellResult.xsd",
      "CustomerBatch.batchId | datawarehouse_1_29",
    ],
  },
  BatchBin_Name: {
    Semantics: "the name designating a sub-lot partitioning of a Batch;  see Batch_Name",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: [
      "BatchBin | JavaLauncher.xsd",
      " CustomerFields.BatchBin | RecellResult.xsd",
      "BatchBin.value | datawarehouse_1_29",
    ],
  },
  Program_Name: {
    Semantics: "name of a client business unit or program (eg Warranty-Repair, Bulk_Resale, AT&T)",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-+( )]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: [
      "Program | JavaLauncher.xsd",
      "Program | RecellResult.xsd",
      "Program.name | datawarehouse_1_29",
    ],
  },
  ProgramStep_Name: {
    Semantics:
      "name of a task or step in a client business unit or program (eg Receiving, Triage, Repair, Final_QC )",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: [
      "ProgramStep | JavaLauncher.xsd",
      "<TBD> | RecellResult.xsd",
      "<none> | datawarehouse_1_29",
    ],
  },
  ProcessRunId: {
    Semantics:
      "Globally unique ID key for a process run (ie a set of services performed as a task unit)",
    Structure: "Datum",
    DataType: "ISO UUID",
    DataKind: "Key",
    Syntax: ["ISO random UUID"],
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: [
      "ProcessRunId | JavaLauncher.xsd",
      "TransactionRef | RecellResult.xsd",
      "ProcessRun.processRunId | datawarehouse_1_29",
    ],
  },
  EventGuid: {
    Semantics:
      "Globally unique ID key for a SystemEvent; which is reporting on a process run or a subsystem",
    Structure: "Datum",
    DataType: "ISO UUID",
    DataKind: "Key",
    Syntax: ["ISO random UUID"],
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: ["EventGuid | JavaLauncher.xsd", "Event.reportGuid | datawarehouse_1_29"],
  },
  Operator_Name: {
    Semantics: "the friendly name used for the Desktop Operator",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
    PartOfRecord: ["datawarehouse_1_29"],
    Aliases: ["Operator.friendlyName | datawarehouse_1_29"],
  },
  Operator_UserName: {
    Semantics:
      "the unique name, the Desktop Operator uses to log into Pro-Diagnostics Desktop workstation. Separate from cloud user, see User_UserName.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd", "datawarehouse_1_29"],
    Aliases: [
      "Computer.User | JavaLauncher.xsd",
      " ComputerInfo.User | RecellResult.xsd",
      "Operator.name | datawarehouse_1_29",
    ],
  },
  Computer_Name: {
    Semantics:
      "the unique name, the Desktop computer used to run the Pro-Diagnostics Desktop workstation.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9#_\-\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd", "datawarehouse_1_29"],
    Aliases: [
      "Computer.Name | JavaLauncher.xsd",
      " ComputerInfo.Name | RecellResult.xsd",
      "Computer.name | datawarehouse_1_29",
    ],
  },
  Facility_Name: {
    Semantics:
      "The friendly name of the facility, store or location. Associated with Facility_Id. ",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: ["Facility | JavaLauncher.xsd", "Facility.name | datawarehouse_1_29"],
  },
  Facility_Id: {
    Semantics:
      "The globally unique ID for the facility, store or location. Associated with friendly name of the facility, Facility_Name.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Key",
    Syntax: /FA-[a-zA-Z0-9_\-]{2,10}\.[0-9]{1,10}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: [" <TBD> | JavaLauncher.xsd"],
  },
  Facility_Address: {
    Semantics: "the street address of a Facility (or store); including Unit # if applicable.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\.\-\+\(\)\s]{2,60}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  Facility_City: {
    Semantics: "the city name for a Facility (or store).",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9'/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  Facility_PostalCode: {
    Semantics: "the postal code or zip or equivalent, for a Facility (or store).",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9/#_\.\-\+\(\)\s]{2,30}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  Facility_State: {
    Semantics: "the state or region name for a Facility (or store).",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    DataValues: ["countries.json <need Stash path>"],
    PartOfRecord: [],
    Aliases: [],
  },
  Facility_Country: {
    Semantics: "the country code for the country of a Facility (or store).",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /ISO 3166-1 alpha-2/,
    DataValues: ["countries.json <need Stash path>"],
    PartOfRecord: [],
    Aliases: [],
  },
  Facility_TimeZone: {
    Semantics: "the ISO standard time-zone of a Facility (or store).",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[+-]{1}[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}/,
    DataValues: ["TimeZones.json <need Stash path>"],
    PartOfRecord: [],
    Aliases: [],
  },
  Customer_Name: {
    Semantics: "The friendly name of the customer. Associated with Customer_Id. ",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9\-\s]{2,30}$/,
    DataValues: ["<enum values>"],
    MinValue: "value",
    MaxValue: "value",
    PartOfRecord: [],
    Aliases: ["<TBD> | datawarehouse_1_29"],
  },
  Customer_Id: {
    Semantics:
      "The globally unique ID for the customer. Associated with friendly name of the customer, Customer_Name. ",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Key",
    Syntax: /CS-[a-zA-Z0-9_\-]{2,10}$/,
    PartOfRecord: ["JavaLauncher.xsd"],
    Aliases: ["<TBD> | JavaLauncher.xsd"],
  },
  User_UserName: {
    Semantics:
      "the unique name, a cloud User uses to log into cloud services. <separate from Desktop Operator, see Operator_UserName",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9'#_\-]{2,30}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  User_Email: {
    Semantics: "the email address for a cloud User. (W3C syntax)",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
    PartOfRecord: [],
    Aliases: [],
  },
  User_FirstName: {
    Semantics: "the first name for a cloud User.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9'/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  User_LastName: {
    Semantics: "the last name for a cloud User.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^[a-zA-Z0-9'/#_\-\+\(\)\s]{2,30}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  User_Role: {
    Semantics: "the last name for a cloud User.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    DataValues: ["^(?!\\s*$).+"],

    PartOfRecord: [],
    Aliases: [],
  },
  User_Password: {
    Semantics: "the password for a cloud User.",
    Structure: "Datum",
    DataType: "String",
    DataKind: "Fact",
    Syntax: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
    PartOfRecord: [],
    Aliases: [],
  },
  "<DataElementName_Template>": {
    Semantics: "<definition string>  // This block defines a named DataElementObject",
    Structure: ["Datum", "List", "JSON", "XML"],
    DataType: [
      "String",
      "Boolean",
      "Integer",
      "Float",
      "ISO UUID",
      "Enum",
      "Date",
      "Time",
      "Timestamp",
      "JSON",
      "XML",
    ],
    DataKind: ["Fact", "Result", "Meta-data", "Key", "Index"],
    Syntax: ["<regex string>", "<number format>", "<time-date format>"],
    DataValues: ["<enum values>"],
    MinValue: "value",
    MaxValue: "value",
    PartOfRecord: ["JavaLauncher.xsd", "RecellResult.xsd", "datawarehouse_1_29"],
    Aliases: [
      "<alias> | JavaLauncher.xsd",
      "<alias> | RecellResult.xsd",
      "<alias> | datawarehouse_1_29",
    ],
    AliasFor: "<DataElementName>",
  },
  Label_Width_Inches: {
    Syntax: /^(1[0-7]|[1-9])$/,
  },
  Label_Height_Inches: {
    Syntax: /^(1[0-14]|[1-9])$/,
  },
  Label_Name: {
    Syntax: /^[0-9a-zA-Z]+$/,
  },
  IMEI: {
    Syntax: /^[0-9]{14,15}$/,
  },
  MEID: {
    Syntax: /^[A-Fa-f0-9]{14}$/,
  },
  operator: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  program: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  sessionID: {
    Syntax: /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/,
  },
  sessionIDHumanReadable: {
    Syntax: /^SE-[A-Z0-9]{2,5}-[0-9]{1,}$/i,
  },
  serviceSuite: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  shift: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  team: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  testSuite: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  computerName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  deviceColor: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  GUID: {
    Syntax: /^[a-zA-Z0-9]+$/,
  },
  ecid: {
    Syntax: /^[a-fA-F0-9]{16}$/,
  },
  OEMSerialNumber: {
    Syntax: /^[a-zA-Z0-9]+$/,
  },
  bluetoothMAC: {
    Syntax: /^[A-Fa-f0-9]{12}|[A-Fa-f0-9]{2}([:|-][A-Fa-f0-9]{2}){5}$/,
  },
  wifiMAC: {
    Syntax: /^[A-Fa-f0-9]{12}|[A-Fa-f0-9]{2}([:|-][A-Fa-f0-9]{2}){5}$/,
  },
  crossSystemId: {
    Syntax: /^(([a-zA-Z0-9]{4}(-)){3})[0-9]{1,}/,
  },
  failCodeCategoryPriority: {
    Syntax: /^[0-9]\d*$/,
  },
  failCodeCategoryName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,140}$/,
  },
  failCodePriorityGroupName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,140}$/,
  },
  failCodeName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,140}$/,
  },
  failCodeDescription: {
    Syntax: /^[\p{Letter}\p{Mark}\p{Number}/#_\-+%.'( )]{2,140}$/u,
  },
  testConfigName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ]{2,30}$/,
  },
  testConfigInclusionModelName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,30}$/,
  },
  testSuiteName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,999}$/,
  },
  customFieldId: {
    Syntax: /^[a-zA-Z0-9\-]{1,30}$/,
    ValidationMessage:
      "The custom field identifier should only include letters, numbers and dashes. Maximum of 30 characters.",
  },
  sender: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{1,30}$/,
  },
  modelName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,30}$/,
  },
  slotNumber: {
    Syntax: /^[0-9]{1,30}$/,
  },
  osName: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,30}$/,
  },
  osVersion: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,30}$/,
  },
  Status: {
    Syntax: /^(?:pass|fail)$/i,
  },
  className: {
    Syntax: /^[a-zA-Z0-9/#_\-+( ) ,]{2,30}$/,
  },
  modelNumber: {
    Syntax: /.*/,
  },
};
