import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const UserActionIcon = ({ size, customId }) => (
  <SVGIcon title="User Action Monitor" viewBox="0 0 34.66 31.17" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        y1="15.58"
        x2="34.66"
        y2="15.58"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill={`url(#${customId}-a)`}
        d="M32.72 0H1.94A1.8 1.8 0 000 1.94v21.67a1.79 1.79 0 002 2h11.43c-1.22 1.27-3.3 3.27-3.61 3.68a1.39 1.39 0 00-.28 1.38.93.93 0 00.92.5h13.69a1 1 0 001-.52A1.21 1.21 0 0025 29.4c-.19-.27-2.44-2.4-3.64-3.79H32.7a2.41 2.41 0 00.53 0 1.79 1.79 0 001.43-1.93V1.95A1.78 1.78 0 0032.72 0zm-8.48 30.16H10.47a1.4 1.4 0 01.14-.27c.39-.51 3.19-3.49 4-4.28h5.49c.91.95 3.81 4 4 4.36.1.1.12.18.14.19zM1.94 1h30.78c.64 0 .94.29.94.91v18.84H1V1.94c0-.65.29-.94.94-.94zM33 24.59a1.67 1.67 0 01-.33 0H2c-.75 0-1-.25-1-1v-1.84h32.66v1.89c0 .72-.34.89-.66.95z"
      />
      <rect
        className="cls-2"
        x="13.7"
        y="6.82"
        width="12.77"
        height=".98"
        rx=".49"
        transform="rotate(180 20.085 7.31)"
      />
      <rect
        className="cls-2"
        x="8.16"
        y="6.82"
        width="3.76"
        height=".98"
        rx=".49"
        transform="rotate(-180 10.04 7.31)"
      />
      <rect
        className="cls-2"
        x="13.7"
        y="10.34"
        width="12.77"
        height=".98"
        rx=".49"
        transform="rotate(180 20.085 10.83)"
      />
      <rect
        className="cls-2"
        x="8.16"
        y="10.34"
        width="3.76"
        height=".98"
        rx=".49"
        transform="rotate(-180 10.04 10.83)"
      />
      <rect
        className="cls-2"
        x="13.7"
        y="13.86"
        width="12.77"
        height=".98"
        rx=".49"
        transform="rotate(180 20.085 14.35)"
      />
      <rect
        className="cls-2"
        x="8.16"
        y="13.86"
        width="3.76"
        height=".98"
        rx=".49"
        transform="rotate(-180 10.04 14.35)"
      />
    </g>
  </SVGIcon>
);

UserActionIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default UserActionIcon;
