import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const fetchDeploymentsBegin = createAction("FETCH_DEPLOYMENT_BEGIN");
export const fetchDeploymentsSuccess = createAction("FETCH_DEPLOYMENT_SUCCESS");
export const fetchDeploymentsError = createAction("FETCH_DEPLOYMENT_ERROR");

export const archiveDeploymentBegin = createAction("ARCHIVE_DEPLOYMENT_BEGIN");
export const archiveDeploymentSuccess = createAction("ARCHIVE_DEPLOYMENT_SUCCESS");
export const archiveDeploymentError = createAction("ARCHIVE_DEPLOYMENT_ERROR");

export const fetchDeploymentsByFacility = () => async (dispatch) => {
  // TODO: Remove release line from query upon termination of classic DSE
  const query = CustomerManagement.gqlBuilder(`{
        facilities {
            faid,
            sbid,
            name,
            country,
            state,
            postalcode,
            timezone,
            deployments {
                dpid,
                name,
                releaseline
            }
        }
    }`);

  try {
    dispatch(fetchDeploymentsBegin());

    const response = await CustomerManagement.QUERY(query);

    const facilities = (response.payload.data || {}).facilities || [];

    dispatch(fetchDeploymentsSuccess({ facilities }));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchDeploymentsError({ error: message }));
  }
};

export const archiveDeployment = (faid, dpid) => async (dispatch) => {
  dispatch(archiveDeploymentBegin({ faid, dpid }));
  try {
    const path = `/deployment/${faid}/${dpid}/archive`;
    const { message } = await CustomerManagement.PUT(path);
    dispatch(archiveDeploymentSuccess({ faid, dpid, message }));
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(archiveDeploymentError({ faid, dpid, message }));
  }
};
