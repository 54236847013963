import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import { type ElementOf } from "~/shared/lib/utilityTypes";
import { RootState } from "~/store";

export type SkuGroup = {
  id: string;
  csid: string;
  name: string;
  deviceType: DeviceType;
  skuAttributes: string[];
  additionalAttributes: string[];
  isArchived: boolean;
  /** "2022-05-17T00:00:01Z" */
  createdAt: string;
  /** "2022-05-17T00:00:01Z" */
  modifiedAt: string;
  /** "2022-05-17T00:00:01Z" */
  archivedAt: string;
};

export type SkuGroupBody = {
  name: string;
  deviceType: DeviceType;
  skuAttributes: string[];
  additionalAttributes: string[];
};

export type DeviceType = ElementOf<typeof deviceTypes>;
export const deviceTypes = ["phone", "tablet", "watch"] as const;

type ServiceError = { json: { message?: string } };

export const fetchSkuGroups = createAsyncThunk<SkuGroup[], void, { rejectValue: string }>(
  "skuGroups/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = (await CustomerManagement.GET("/inventory/sku-groups?archive=false")) as {
        payload: SkuGroup[];
      };

      return response.payload;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

export const createSkuGroup = createAsyncThunk<SkuGroup, SkuGroupBody, { rejectValue: string }>(
  "skuGroups/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = (await CustomerManagement.POST("/inventory/sku-group", body)) as {
        payload: SkuGroup;
      };

      return response.payload;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

export const updateSkuGroup = createAsyncThunk<
  SkuGroup,
  SkuGroupBody & { id: string },
  { rejectValue: string }
>("skuGroups/update", async (skuGroupParams, { rejectWithValue }) => {
  try {
    const { id, ...body } = skuGroupParams;
    const response = (await CustomerManagement.PUT(`/inventory/sku-group/${id}`, {
      ...body,
    })) as { payload: SkuGroup };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const archiveSkuGroup = createAsyncThunk<string, string, { rejectValue: string }>(
  "skuGroups/archive",
  async (id, { rejectWithValue }) => {
    try {
      await CustomerManagement.PUT(`/inventory/sku-group/${id}/archive`, undefined);

      return id;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

const skuGroupsAdapter = createEntityAdapter<SkuGroup>({
  sortComparer: (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    if (dateA < dateB) {
      return 1;
    }

    if (dateA > dateB) {
      return -1;
    }

    return 0;
  },
});

interface SkuGroupsState extends EntityState<SkuGroup> {
  listStatus: "idle" | "loading";
  formStatus: "idle" | "loading";
  archiveStatus: "idle" | "loading";
}

const initialState: SkuGroupsState = {
  ...skuGroupsAdapter.getInitialState(),
  listStatus: "idle",
  formStatus: "idle",
  archiveStatus: "idle",
};

const skuGroupsSlice = createSlice({
  name: "skuGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchSkuGroups.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(fetchSkuGroups.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        skuGroupsAdapter.setAll(draftState, action.payload);
      })
      .addCase(fetchSkuGroups.rejected, (draftState) => {
        draftState.listStatus = "idle";
      })

      // Create
      .addCase(createSkuGroup.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(createSkuGroup.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        skuGroupsAdapter.addOne(draftState, action.payload);
      })
      .addCase(createSkuGroup.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })

      // Update
      .addCase(updateSkuGroup.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(updateSkuGroup.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        skuGroupsAdapter.setOne(draftState, action.payload);
      })
      .addCase(updateSkuGroup.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })

      // Archive (functions as a delete in the UI)
      .addCase(archiveSkuGroup.pending, (draftState) => {
        draftState.archiveStatus = "loading";
      })
      .addCase(archiveSkuGroup.fulfilled, (draftState, action) => {
        draftState.archiveStatus = "idle";
        skuGroupsAdapter.removeOne(draftState, action.payload);
      })
      .addCase(archiveSkuGroup.rejected, (draftState) => {
        draftState.archiveStatus = "idle";
      });
  },
});

export default skuGroupsSlice.reducer;

export const skuGroupsSelectors = skuGroupsAdapter.getSelectors<RootState>(
  (state) => state.skuGroups
);
