import React from "react";
import type { ReactNode } from "react";
import classnames from "classnames";

import "./SectionHeader.component.scss";

const SectionHeader = ({
  className,
  title,
  description,
  children,
}: {
  className?: string;
  title?: ReactNode;
  description?: string;
  children?: ReactNode;
}) => {
  const hasChildren = children != null;
  const hasTitle = title != null;
  const hasDescription = description != null;

  return (
    <header className={classnames("cc-section-header", className, { "no-title": !hasTitle })}>
      {hasTitle ? (
        <div className="title">
          <span>{title}</span>
          {hasDescription ? <span className="description">{description}</span> : null}
        </div>
      ) : null}

      {hasChildren ? children : null}
    </header>
  );
};

export default SectionHeader;
