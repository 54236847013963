import * as programEditor from "./programEditor.selectors";
import * as serviceSuiteEditor from "./serviceSuiteEditor.selectors";
import * as systemFields from "./systemFields.selectors";
import * as systemPermissions from "./systemPermissions.selectors";

export default {
  programEditor,
  serviceSuiteEditor,
  systemFields,
  systemPermissions,
};
