import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";

import { logoutSuccess, setCurrentApp } from "~/actions/session.actions";

const initialState = {
  // user profile
  user: {},
  isUserProfileLoading: false,

  // permissions
  roleNames: [],
  permissions: {},

  // current app
  currentAppUrlName: "",
};

export default createReducer(initialState, {
  [logoutSuccess]: (state, _) => {
    state.user = {};
    state.activeCustomerId = "";
    state.roleNames = [];
    state.permissions = {};
  },

  // Fetch user profile
  [actions.user.fetchUserProfileBegin]: (state, _) => {
    state.isUserProfileLoading = true;
  },
  [actions.user.fetchUserProfileSuccess]: (state, action) => {
    state.user = action.payload.user;
    state.isUserProfileLoading = false;

    // Permissions
    const roles = action.payload.roles || [];
    state.roleNames = [];
    state.permissions = roles.reduce((acc, role) => {
      state.roleNames.push(role.name);

      role.permissions.forEach((perm) => {
        if (!acc[perm.method]) {
          acc[perm.method] = {};
        }
        if (perm.service) acc[perm.method][perm.resource] = perm.service;
        else acc[perm.method][perm.resource] = true;
      });
      return acc;
    }, {});
  },
  [actions.user.fetchUserProfileError]: (state, _) => {
    state.isUserProfileLoading = false;
  },

  // Change current app
  [setCurrentApp]: (state, action) => {
    state.currentAppUrlName = action.payload || "";
  },
});
