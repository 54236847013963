import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const DeviceHistoryIcon = ({ size, customId }) => (
  <SVGIcon title="Device History" viewBox="0 0 26.62 38.91" size={size}>
    <defs>
      <linearGradient
        id={customId}
        x1="7.24"
        x2="26.62"
        y1="23.08"
        y2="23.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>

    <g>
      <path
        fill="#9c3"
        d="M10.07 10.18a.47.47 0 0 1-.35.15.5.5 0 0 1-.35-.15L7.26 8.07a.5.5 0 0 1-.14-.35V3.05a.5.5 0 0 1 .49-.51.51.51 0 0 1 .5.51v4.48l2 2a.48.48 0 0 1-.04.65z"
      />
      <path
        fill={`url(#${customId})`}
        d="M23.16 7.25h-6.42v1h6.43a2.46 2.46 0 0 1 2.46 2.46v24.74a2.47 2.47 0 0 1-2.46 2.46H10.71a2.47 2.47 0 0 1-2.47-2.46V16.73h-1v18.72a3.48 3.48 0 0 0 3.47 3.46h12.45a3.47 3.47 0 0 0 3.46-3.46V10.71a3.47 3.47 0 0 0-3.46-3.46z"
      />
      <path
        fill="#9c3"
        d="M7.61 15.23a7.62 7.62 0 1 1 7.62-7.62 7.63 7.63 0 0 1-7.62 7.62zm0-14.13a6.51 6.51 0 1 0 6.51 6.51A6.52 6.52 0 0 0 7.61 1.1z"
      />
      <path
        fill="#003764"
        d="M18.61 10.7a.37.37 0 0 1-.37.38h-2.19c.11-.26.2-.52.29-.79h1.9a.37.37 0 0 1 .37.41z"
      />
      <path
        fill={`url(#${customId})`}
        d="M15.48 13.12a8 8 0 0 1-.83 1h7.29v18.65h-10V15.88a8 8 0 0 1-1 .4v17.5H23V13.12z"
      />
    </g>
  </SVGIcon>
);

DeviceHistoryIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default DeviceHistoryIcon;
