import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import uuid from "uuid/v4";
import { omit } from "lodash";

import { Row, Column, AsciButton, Checkbox, AsciSpinner } from "~/global";

import moduleActions from "~/service-suite-templates/actions/";

import "./AddExistingServicesDialog.component.scss";

const AddExistingServicesDialog = ({
  close,
  fetchNonrelatedServices,
  isLoading,
  nonrelatedServices,
  saveEditedTemplate,
  template,
  onUpdateNRServiceList,
}) => {
  useEffect(() => {
    fetchNonrelatedServices(template.identifier);
  }, []);

  const toggleServiceChecked = (service) => {
    const updatedNRServices = nonrelatedServices.map((item) => {
      if (service.id === item.id) {
        service.isChecked = !service.isChecked;
        return service;
      }
      return item;
    });
    onUpdateNRServiceList(updatedNRServices);
  };

  const addExistingServices = () => {
    const newServices = nonrelatedServices
      .filter((service) => service.isChecked === true)
      .map((service) => ({
        ...omit(service, "isChecked"),
        arguments: service.arguments.map((arg) => ({ ...arg, id: uuid() })),
        id: uuid(),
      }));

    saveEditedTemplate({
      ...template,
      services: [...template.services, ...newServices],
    });
    message.success(`Selected services have been added to ${template.identifier}`);
    close();
  };

  return (
    <div className="existing-services-dialog">
      <AsciSpinner visible={isLoading} />
      <Column className="full-width">
        {nonrelatedServices.map((service) => (
          <Row className="full-width" key={service.id}>
            <Checkbox
              id={`checkbox-enabled-${service.id}`}
              isChecked={service.isChecked}
              onChange={() => toggleServiceChecked(service)}
            />
            <div className="service-displayName">{service.displayName.en}</div>
          </Row>
        ))}
        <Row className="btn-group">
          <AsciButton className="cancel-btn" color="white" onClick={() => close()}>
            Cancel
          </AsciButton>
          <AsciButton className="confirm-btn" color="green" onClick={() => addExistingServices()}>
            Add services
          </AsciButton>
        </Row>
      </Column>
    </div>
  );
};

AddExistingServicesDialog.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,

  // From the store
  saveEditedTemplate: PropTypes.func.isRequired,
  onUpdateNRServiceList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nonrelatedServices: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  isLoading: state.modules.serviceSuiteTemplates.isLoading,
  nonrelatedServices: state.modules.serviceSuiteTemplates.nonrelatedServices,
});

const dispatchToProps = {
  addPreparedNRServices: moduleActions.serviceSuiteTemplates.addPreparedNRServices,
  fetchNonrelatedServices: moduleActions.serviceSuiteTemplates.fetchNonrelatedServices,
  saveEditedTemplate: moduleActions.serviceSuiteTemplates.saveEditedTemplate,
  onUpdateNRServiceList: moduleActions.serviceSuiteTemplates.onUpdateNRServiceList,
};

export default connect(stateToProps, dispatchToProps)(AddExistingServicesDialog);
