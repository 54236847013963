import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "../actions";

const initialState = {
  selectedName: undefined,
  original: {},
  modifications: {},
  errors: {},
  areGalleryImagesLoading: false,
  isSaving: false,
  galleryImages: null,
};

export default createReducer(initialState, {
  [moduleActions.setSelectedCategory]: (state, action) => {
    state.selectedName = action.payload;
  },

  [moduleActions.setTestDefinitionOriginal]: (state, action) => {
    state.original = action.payload || {};
    state.modifications = {};
    state.errors = {};
  },

  [moduleActions.setTestDefinitionModifications]: (state, action) => {
    state.modifications = action.payload || {};
  },

  [moduleActions.modifyTestDefinition]: (state, action) => {
    const modifications = action.payload || {};
    for (const key in modifications) {
      state.modifications[key] = modifications[key];
    }
  },

  // Save test definition
  [moduleActions.saveTestDefinitionBegin]: (state) => {
    state.isSaving = true;
  },
  [moduleActions.saveTestDefinitionSuccess]: (state, action) => {
    state.original = action.payload || {};
    state.modifications = {};
    state.isSaving = false;
    state.errors = {};
  },
  [moduleActions.saveTestDefinitionError]: (state, action) => {
    state.isSaving = false;
    state.errors = state.errors || {};
    for (const key in action.payload.errors) {
      state.errors[key] = action.payload.errors[key];
    }
  },

  // Fetch gallery images
  [moduleActions.fetchGalleryImagesBegin]: (state, action) => {
    state.areGalleryImagesLoading = true;
  },
  [moduleActions.fetchGalleryImagesSuccess]: (state, action) => {
    state.areGalleryImagesLoading = false;
    state.galleryImages = action.payload;
  },
  [moduleActions.fetchGalleryImagesError]: (state, action) => {
    state.areGalleryImagesLoading = false;
    state.galleryImages = null;
  },
});
