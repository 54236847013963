import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import GearIcon from "./GearIcon/GearIcon.component";

import "./AsciWizardButton.component.scss";

const AsciWizardButton = ({ className, disabled, step, title }) => (
  <div className={classnames("gear-icon-btn", className, disabled ? "disabled" : "enabled")}>
    <div className="nav-icon">
      <GearIcon step={step} />
    </div>
  </div>
);

AsciWizardButton.propTypes = {
  step: PropTypes.any,
  className: PropTypes.any,
  disabled: PropTypes.bool,
};

export default AsciWizardButton;
