import React, { useEffect, useState } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import actions from "~/actions";
import { AsciButton, Column, Row } from "~/global";
import moduleActions from "../../actions";
import AddExistingServicesDialog from "../AddExistingServicesDialog/AddExistingServicesDialog.component";
import AddNewServiceDialog from "../AddNewServiceDialog/AddNewServiceDialog.component";
import { SortableService } from "../SortableService";

import "./ServiceList.component.scss";

const ServiceList = ({
  services,
  showDialog,
  template,
  onCancelChanges,
  editedTemplate,
  confirmationDialog,
  saveEditedTemplate,
}) => {
  const [currentlyEdited, setCurrentlyEdited] = useState(false);

  useEffect(() => {
    if (editedTemplate.identifier === template.identifier) {
      setCurrentlyEdited(true);
    } else {
      setCurrentlyEdited(false);
    }
  }, [editedTemplate]);

  const revertChanges = () => {
    onCancelChanges();
  };

  const addNewService = () => {
    showDialog({
      title: "Add a new service",
      width: "500px",
      modal: true,
      content: (close) => (
        <AddNewServiceDialog
          template={template}
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  const addExistingService = () => {
    showDialog({
      title: "Add Existing Services",
      width: "500px",
      modal: true,
      content: (close) => (
        <AddExistingServicesDialog
          template={template}
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  const removeService = (serviceId) => {
    const updatedServices = services.filter((service) => service.id !== serviceId);
    saveEditedTemplate({ ...template, services: updatedServices });
  };

  return (
    <div className="service-list full-width">
      <Row className="service-list-btn-group full-width" align="space-between">
        <Column>
          <Row>
            <AsciButton className="add-service-btn" color="blue" onClick={() => addNewService()}>
              Add new service
            </AsciButton>
            <AsciButton
              className="add-service-btn"
              color="blue"
              onClick={() => addExistingService()}
            >
              Add existing services
            </AsciButton>
          </Row>
        </Column>
        <Column>
          <Row>
            <AsciButton
              className="save-btn"
              color="green"
              onClick={() => confirmationDialog(editedTemplate)}
              disabled={!currentlyEdited}
            >
              Save
            </AsciButton>
            <AsciButton
              className="cancel-btn"
              color="white"
              onClick={() => revertChanges()}
              disabled={!currentlyEdited}
            >
              Cancel
            </AsciButton>
          </Row>
        </Column>
      </Row>

      <DndContext
        onDragEnd={(event) => {
          const { active, over } = event;

          if (active.id !== over.id) {
            const oldIndex = services.findIndex((service) => service.id === active.id);
            const newIndex = services.findIndex((service) => service.id === over.id);
            const sortedServices = arrayMove(services, oldIndex, newIndex);

            saveEditedTemplate({ ...template, services: sortedServices });
          }
        }}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={services} strategy={verticalListSortingStrategy}>
          {services.map((service) => (
            <SortableService
              key={service.id}
              service={service}
              template={template}
              removeService={removeService}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

ServiceList.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  services: PropTypes.array.isRequired,
  confirmationDialog: PropTypes.func.isRequired,

  // From the store
  editedTemplate: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  showDialog: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  saveEditedTemplate: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  editedTemplate: state.modules.serviceSuiteTemplates.editedTemplate,
});

const dispatchToProps = {
  showDialog: actions.dialog.show,
  onCancelChanges: moduleActions.serviceSuiteTemplates.onCancelChanges,
  saveEditedTemplate: moduleActions.serviceSuiteTemplates.saveEditedTemplate,
};

export default connect(stateToProps, dispatchToProps)(ServiceList);
