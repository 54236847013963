import React, { useState, useEffect, useCallback } from "react";
import { endOfToday, startOfToday } from "date-fns";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "~/i18n";
import { Row, Column, SelectList } from "~/global";

import {
  fetchDevicesProcessed,
  selectDevicesProcessedStatus,
  selectDevicesProcessedTotal,
} from "~/pages/dashboard/models/devicesProcessed.slice";
import {
  fetchTeamPerformance,
  getTeamPerformanceTotal,
} from "~/pages/dashboard/models/teamPerformance.slice";
import { fetchProcessesByServiceSuite } from "~/canned-reports/actions/processesByServiceSuite.actions";
import { fetchProcessesByOS } from "~/canned-reports/actions/processesByOS.actions";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { DashboardFiltersParams } from "~/pages/dashboard/models/typeAndToolDashboard";
import reportsSelectors from "~/canned-reports/selectors/";

import actions from "~/actions";
import selectors from "~/selectors";

import "./DashboardWidgets.component.scss";

type TotalProcesses = {
  combined: number;
  failed: number;
  passed: number;
};
type TotalProcessesByOS = {
  android: 0;
  ios: 0;
};

const DashboardWidgets = () => {
  const { t } = useTranslation("home");
  const dispatch = useAppDispatch();

  const [selectedFacilityId, setSelectedFacilityId] = useState("");

  const facilities = useAppSelector(selectors.facilities.getSortedFacilities);
  const devicesStatus = useAppSelector(selectDevicesProcessedStatus);

  const applyFilters = useCallback(
    (faid: string) => {
      const items: DashboardFiltersParams = {
        facilityID: faid,
        deploymentID: "all",
        shiftID: "all",
        teamID: "all",
        programID: "all",
        configID: "all",
      };
      setSelectedFacilityId(faid);

      void dispatch(fetchDevicesProcessed(items));
      void dispatch(fetchTeamPerformance(items));
      const dateFrom = startOfToday().toISOString();
      const dateTo = endOfToday().toISOString();
      void dispatch(
        fetchProcessesByServiceSuite(faid, "all", "all", "all", "all", dateFrom, dateTo)
      );
      void dispatch(fetchProcessesByOS(faid, "all", "all", "all", "all", dateFrom, dateTo));
      setSelectedFacilityId(faid);
    },
    [dispatch]
  );
  const devicesProcessedTotal = useAppSelector((state) => selectDevicesProcessedTotal(state));
  const teamPerformanceTotal = useAppSelector((state) => getTeamPerformanceTotal(state));

  const totalProcesses = useAppSelector(
    // @ts-ignore
    (state) => reportsSelectors.processesByServiceSuite.getTotalProcesses(state) as TotalProcesses
  );
  const totalProcessesByOS = useAppSelector(
    // @ts-ignore
    (state) => reportsSelectors.processesByOS.getTotalProcessesByOS(state) as TotalProcessesByOS
  );

  useEffect(() => {
    void dispatch(actions.facilities.fetchFacilities());
  }, [dispatch]);

  useEffect(() => {
    if (facilities.length > 0) {
      applyFilters(facilities[0].faid);
    }
  }, [facilities, applyFilters]);

  const selectedFacilityItem = facilities.filter((f) => f.faid === selectedFacilityId)[0];
  return (
    <Column className="cc-dashboard-widgets">
      {facilities.length > 0 && devicesStatus !== "succeeded" ? (
        <Row align="center center">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
            size="large"
          />
        </Row>
      ) : (
        <>
          <Row align="space-between center">
            <Row className="filter-container" align="start center">
              <Row className="filter-label">{t("current-facility")}</Row>
              <SelectList
                items={facilities}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                onChange={(option) => applyFilters(option.faid)}
                selectedItem={selectedFacilityItem}
                nameKey="name"
              />
            </Row>
          </Row>

          <Column className="widgets-container">
            <Row className="performance-row" align="space-around center">
              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {devicesProcessedTotal.devices -
                    teamPerformanceTotal.devicesIncomplete -
                    teamPerformanceTotal.devicesCrashed}
                </Row>
                <Row className="stats-label" align="center">
                  {t("total-process-runs-today")}
                </Row>
              </Column>

              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {devicesProcessedTotal.averageUph}
                </Row>
                <Row className="stats-label" align="center">
                  {t("average-prph")}
                </Row>
              </Column>
              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {totalProcesses.passed}
                </Row>
                <Row className="stats-label" align="center">
                  {t("pass")}
                </Row>
              </Column>

              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {totalProcesses.failed}
                </Row>
                <Row className="stats-label" align="center">
                  {t("fail")}
                </Row>
              </Column>
            </Row>

            <Row className="performance-row" align="space-around center">
              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {totalProcessesByOS?.ios || 0}
                </Row>
                <Row className="stats-label" align="center">
                  {t("ios")}
                </Row>
              </Column>

              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {totalProcessesByOS?.android || 0}
                </Row>
                <Row className="stats-label" align="center">
                  {t("android")}
                </Row>
              </Column>

              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {devicesProcessedTotal.audit - devicesProcessedTotal.auditFail}
                </Row>
                <Row className="stats-label" align="center">
                  {t("audit-pass")}
                </Row>
              </Column>

              <Column className="uph-stats" align="center">
                <Row className="stats-number" align="center">
                  {devicesProcessedTotal.auditFail}
                </Row>
                <Row className="stats-label" align="center">
                  {t("audit-fail")}
                </Row>
              </Column>
            </Row>
          </Column>
        </>
      )}
      <Row className="section-note" align="end">
        {t("note")}
      </Row>
    </Column>
  );
};

export default DashboardWidgets;
