import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { DeviceType } from "~/entities/sku-groups/model/skuGroupsSlice";
import { CustomerManagement } from "~/services";
import { RootState } from "~/store";

type Sku = {
  id: string;
  csid: string;
  groupId: string;
  groupName: string;
  deviceType: DeviceType;
  sku: string;
  manufacturer?: string;
  modelName?: string;
  storageCapacity?: string;
  color?: string;
  grade?: string;
  modelNumber?: string;
  network?: string;
  size?: string;
  barcode?: string;
  externalSku?: string;
  internalBucket?: string;
  isArchived: boolean;
  /** "2022-05-17T00:00:01Z" */
  createdAt: string;
  /** "2022-05-17T00:00:01Z" */
  modifiedAt: string;
  /** "2022-05-17T00:00:01Z" */
  archivedAt: string;
};

export type SkuSearchParams = {
  keyword?: string;
  page: number;
  perPage: number;
};

export type SkuSearchPayload = { Doc: Sku[]; TotalDoc: number };

type SkuImportResponse = {
  payload: { processedSheet: string; warnings: string[] };
  message: string;
};

type ServiceError = { json: { message?: string } };

export const searchSku = createAsyncThunk<
  SkuSearchPayload,
  SkuSearchParams,
  { rejectValue: string }
>("sku/search", async (skuSearchParams, { rejectWithValue }) => {
  try {
    const response = (await CustomerManagement.POST("/inventory/skus/search", skuSearchParams)) as {
      payload: SkuSearchPayload;
    };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const importSku = createAsyncThunk<SkuImportResponse, FormData, { rejectValue: string }>(
  "sku/import",
  async (formData, { rejectWithValue }) => {
    try {
      const response = (await CustomerManagement.POST(
        "/inventory/skus/import",
        formData
      )) as SkuImportResponse;

      return response;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

export const exportSku = createAsyncThunk<string, void, { rejectValue: string }>(
  "sku/export",
  async (_, { rejectWithValue }) => {
    try {
      await CustomerManagement.POST("/inventory/skus/export", {
        type: "Skus",
        format: "xlsx",
        method: "byDownload",
      });

      return "The export was successful";
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

const skuAdapter = createEntityAdapter<Sku>({
  sortComparer: (a, b) => a.sku.localeCompare(b.sku),
});

interface SkuState extends EntityState<Sku> {
  numOfResults?: number;
  listStatus: "idle" | "loading";
  importStatus: "idle" | "loading";
  exportStatus: "idle" | "loading";
}

const initialState: SkuState = {
  ...skuAdapter.getInitialState(),
  listStatus: "idle",
  importStatus: "idle",
  exportStatus: "idle",
};

const skuSlice = createSlice({
  name: "sku",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Search
      .addCase(searchSku.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(searchSku.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        skuAdapter.setAll(draftState, action.payload.Doc);
        draftState.numOfResults = action.payload.TotalDoc;
      })
      .addCase(searchSku.rejected, (draftState) => {
        draftState.listStatus = "idle";
      })
      // Import
      .addCase(importSku.pending, (draftState) => {
        draftState.importStatus = "loading";
      })
      .addCase(importSku.fulfilled, (draftState) => {
        draftState.importStatus = "idle";
      })
      .addCase(importSku.rejected, (draftState) => {
        draftState.importStatus = "idle";
      })
      // Export
      .addCase(exportSku.pending, (draftState) => {
        draftState.exportStatus = "loading";
      })
      .addCase(exportSku.fulfilled, (draftState) => {
        draftState.exportStatus = "idle";
      })
      .addCase(exportSku.rejected, (draftState) => {
        draftState.exportStatus = "idle";
      });
  },
});

export default skuSlice.reducer;

export const skuSelectors = skuAdapter.getSelectors<RootState>((state) => state.sku);
