import { combineReducers } from "@reduxjs/toolkit";

import auditResults from "./auditResults.reducer";
import auditResultsPerTest from "./auditResultsPerTest.reducer";
import processes from "./processes.reducer";
import processesByOS from "./processesByOS.reducer";
import processesByServiceSuite from "./processesByServiceSuite.reducer";
import testProcessingTimes from "./testProcessingTimes.reducer";

export default combineReducers({
  auditResults,
  auditResultsPerTest,
  processes,
  processesByOS,
  processesByServiceSuite,
  testProcessingTimes,
});
