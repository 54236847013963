import React, { Component } from "react";

import { SectionHeader, AsciButton } from "~/global";

import Button from "~/global/Button/Button.component";

import SectionHeaderExample from "./examples/SectionHeaderExample";

import "./KitchenSink.component.scss";

export default class KitchenSink extends Component {
  render() {
    return (
      <div className="panel-container padding--15">
        {/* <SectionHeaderExample /> */}

        <div>
          <AsciButton>Hello</AsciButton>

          <div className="margin--10" />

          <Button>Hello</Button>
        </div>
      </div>
    );
  }
}
