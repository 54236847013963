import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

import "./TextField.component.scss";

export default class TextField extends Component {
  static propTypes = {
    type(props, propName, componentName) {
      const valid = ["string", "number", "float"];
      const value = props[propName];
      if (value != null && !valid.includes(value)) {
        return new Error(
          `Invalid prop value "${propName}": "${value}". Accepted values are: ${valid.join(
            ", "
          )} or nothing at all (default to string)`
        );
      }
    },
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    showClearButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    step: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    type: "string",
    disabled: false,
    placeholder: "",
    focusOnMount: false,
    showClearButton: false,
    fullWidth: false,
    step: 1,
    onBlur: () => {},
  };

  constructor(props) {
    super();

    this.state = {
      displayedValue: props.value == null ? "" : props.value,
    };
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this._inputField.focus();
    }
  }

  onIncrement = () => {
    if (this.props.disabled) {
      return;
    }

    this.updateNumberValue(+this._inputField.value + this.props.step);
  };

  onDecrement = () => {
    if (this.props.disabled) {
      return;
    }

    this.updateNumberValue(+this._inputField.value - this.props.step);
  };

  updateNumberValue = (value) => {
    if (!this.isNumberType()) {
      return;
    }

    // is fractional step?
    if (this.props.step % 1 != 0) {
      // In JavaScript, 1 + 0.1 + 0.1 = 1.2000000000000002
      // this will ignore everything after 11th digit
      value = +parseFloat(value).toPrecision(12);
    }

    if (Number.isNaN(value)) {
      console.warn("number value is NaN, don't update");
      return;
    }

    this.setState({ displayedValue: value });
    this.props.onChange(value);
  };

  onKeyUp = (event) => {
    if (this.props.disabled) {
      return;
    }

    if (this.isNumberType()) {
      const keys = {
        ArrowUp: this.onIncrement,
        ArrowDown: this.onDecrement,
      };

      if (keys[event.key]) {
        keys[event.key]();
      }
      return false;
    }
  };

  isNumberType = () => ["number", "float"].includes(this.props.type);

  isIntegerType = () => this.props.type == "number";

  isFloatType = () => this.props.type == "float";

  onChange = (event) => {
    if (this.props.disabled) {
      return;
    }

    if (this.isIntegerType()) {
      const value = parseInt(event.target.value);

      if (!Number.isNaN(value)) {
        this.props.onChange(value);
      }
    } else if (this.isFloatType()) {
      const value = parseFloat(event.target.value);

      if (!Number.isNaN(value)) {
        this.props.onChange(value);
      }
    } else {
      this.props.onChange(event.target.value);
    }

    this.setState({
      displayedValue: event.target.value,
    });
  };

  onBlur = () => {
    if (this.props.disabled) {
      return;
    }

    new Promise((resolve) => {
      // update the displayedValue with the last valid value
      // strict equality because 1 == "1." is true (and we don't want that)
      if (this.isNumberType() && this.state.displayedValue !== this.props.value) {
        const displayedValue = this.props.value || "";

        this.setState({ displayedValue }, () => {
          resolve();
        });
      } else {
        resolve();
      }
    }).then(() => {
      this.props.onBlur(this.props.value);
    });
  };

  render() {
    const extraClasses = [`type-${this.props.type}`];
    if (this.props.disabled) {
      extraClasses.push("disabled");
    }
    if (this.props.fullWidth) {
      extraClasses.push("full-width");
    }

    return (
      <div className={`cc-text-field ${extraClasses.join(" ")}`}>
        <input
          type="text"
          value={this.state.displayedValue}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          onBlur={this.onBlur}
          ref={(field) => (this._inputField = field)}
        />

        {this.props.showClearButton ? <FontIcon iconClassName="icon-close" /> : null}

        {this.isNumberType() ? (
          <div className="number-controls">
            <FontIcon iconClassName="icon-caret-up" onClick={this.onIncrement} />
            <FontIcon iconClassName="icon-caret-down" onClick={this.onDecrement} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
