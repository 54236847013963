import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash/string";

const ProgramListPopover = (props) => (
  <ul>
    {props.usedByDeploymentsList.map((usedByDeployment) => {
      const id = camelCase(usedByDeployment);
      return <li key={id}>- {usedByDeployment}</li>;
    })}
  </ul>
);

ProgramListPopover.propTypes = {
  usedByDeploymentsList: PropTypes.arrayOf(PropTypes.string),
};

ProgramListPopover.defaultProps = {
  usedByDeploymentsList: [],
};

export default ProgramListPopover;
