import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Row, Column } from "~/global";
import defaultImg from "~/media/images/base/icon_AS-180x180.png";

import selectors from "~/selectors";

import "./AnalyticsButton.component.scss";

const AnalyticsButton = ({ urlName, appName, label, secondaryLabel, imgSrc, isAppAvailable }) => {
  if (!isAppAvailable) {
    return null;
  }

  return (
    <Link className="analytics-button" to={`/${urlName}`}>
      <Column>
        <Row className="app-preview" align="center center">
          <img src={imgSrc} alt="" />
        </Row>

        <Column className="label-container" align="center center">
          <Row className="label">{label}</Row>
          {secondaryLabel ? <Row className="secondary-label">{secondaryLabel}</Row> : null}
        </Column>
      </Column>
    </Link>
  );
};

AnalyticsButton.propTypes = {
  urlName: PropTypes.string.isRequired,
  appName: PropTypes.string,
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  imgSrc: PropTypes.string,
  isAppAvailable: PropTypes.bool,
};

AnalyticsButton.defaultProps = {
  imgSrc: defaultImg,
};

const stateToProps = (state, props) => ({
  isAppAvailable: selectors.session.isAppAvailable(
    state,
    props.appName ? props.appName : props.urlName
  ),
});

export default connect(stateToProps)(AnalyticsButton);
