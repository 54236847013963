import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { TextField, Switch } from "react-md";
import { message, Divider, Select } from "antd";
import actions from "~/actions";
import selectors from "~/selectors";
import moduleSelectors from "~/custom-fields-editor/selectors/";
import moduleActions from "~/custom-fields-editor/actions/";

import {
  AsciSpinner,
  Page,
  SectionHeader,
  SelectList,
  DialogMessage,
  DialogConfirmation,
} from "~/global";

import {
  CustomFieldListTypeEditor,
  CustomFieldNumericTypeEditor,
  CustomFieldStringTypeEditor,
  CustomFieldEditorButtons,
} from "~/custom-fields-editor";

import "./CustomFieldEditor.component.scss";

const CustomFieldEditor = (props) => {
  const fieldTypes = {
    numeric: { name: "Numeric", value: "numeric" },
    string: { name: "String", value: "string" },
    list: { name: "List", value: "list" },
  };

  const deviceTypes = [
    { label: "All Device Types", value: "all" },
    { label: "Airpods", value: "airpods" },
    { label: "Apple TV", value: "apple_tv" },
    { label: "Apple Watch", value: "apple_watch" },
    { label: "HomePod", value: "homepod" },
    { label: "iPad", value: "ipad" },
    { label: "iPhone", value: "iphone" },
    { label: "iPod Touch", value: "ipod_touch" },
  ];

  const displayAtOptions = {
    login: { name: "Login", value: "login" },
    // temporarily removing this until it is supported by Pro-Diagnostics Desktop
    // processStart:  { name: "Process Start", value: "processStart" },
    processEnd: { name: "Process End", value: "processEnd" },
  };
  const [csid, setCsid] = useState("");
  const [supportedDeviceTypesSelect, setSupportedDeviceTypesSelect] = useState({
    supportedDeviceTypes: ["all"],
    supportedByAllDeviceTypes: true,
  });

  useEffect(() => {
    setCsid(props.csid);

    if (props.isNew) {
      props.initCustomField();
    } else {
      props.fetchCustomFieldsName();
      props.fetchCustomField(props.match.params.name).then((value) => {
        setSupportedDeviceTypesSelect({
          supportedDeviceTypes: value.supportedByAllDeviceTypes
            ? ["all"]
            : value.supportedDeviceTypes,
          supportedByAllDeviceTypes: value.supportedByAllDeviceTypes,
        });
      });
    }
    return () => {
      props.clearCustomField();
    };
  }, []);

  useEffect(() => {
    if (csid.length > 0 && props.csid != csid) {
      props.history.push(`/custom-fields/`);
    }
  }, [props.csid]);

  useEffect(() => {
    if (props.message != null) {
      message.destroy();
    }
    if (props.errors != null) {
      showErrorMessage();
    } else if (props.message != null && props.errors == null) {
      message.success("Custom Field saved successfully", 2.5);
    }
  }, [props.message]);

  const showErrorMessage = () => {
    if (props.isNew) {
      message.error(props.errors, 2.5);
    } else {
      props.showDialog({
        title: "Error",
        width: "450px",
        content: (close) => (
          <DialogMessage
            close={() => {
              close();
            }}
          >
            <p>{props.errors}</p>
          </DialogMessage>
        ),
      });
    }
  };

  const handleSupportedDeviceTypes = (value) => {
    if (value.includes("all")) {
      if (supportedDeviceTypesSelect.supportedByAllDeviceTypes) {
        // Already contained "all" and clicked on another option. E.g.: ["all", "apple_watch"]
        value.shift(); // Remove "all" from the array
        setSupportedDeviceTypesSelect({
          supportedDeviceTypes: value,
          supportedByAllDeviceTypes: false,
        });
        onChange({ supportedByAllDeviceTypes: false, supportedDeviceTypes: value });
      } else {
        // Just clicked on "all". E.g.: ["apple_watch", "all"]
        setSupportedDeviceTypesSelect({
          supportedDeviceTypes: ["all"],
          supportedByAllDeviceTypes: true,
        });
        onChange({ supportedByAllDeviceTypes: true, supportedDeviceTypes: [] });
      }
    } else if (value.length === 0) {
      // Hasn't clicked on any option (empty array)
      setSupportedDeviceTypesSelect({
        supportedDeviceTypes: ["all"],
        supportedByAllDeviceTypes: true,
      });
      onChange({ supportedByAllDeviceTypes: true, supportedDeviceTypes: [] });
    } else if (value.length === deviceTypes.length - 1) {
      // Clicked on all the options except "all"
      // Equals (deviceTypes.length - 1) because we don't count the "all" option
      setSupportedDeviceTypesSelect({
        supportedDeviceTypes: ["all"],
        supportedByAllDeviceTypes: true,
      });
      onChange({ supportedByAllDeviceTypes: true, supportedDeviceTypes: [] });
    } else {
      // Base case
      setSupportedDeviceTypesSelect({
        supportedDeviceTypes: value,
        supportedByAllDeviceTypes: false,
      });
      onChange({ supportedByAllDeviceTypes: false, supportedDeviceTypes: value });
    }
  };

  const showConfirmation = () => {
    if (props.isEqual) {
      props.history.push("/custom-fields");
    } else {
      props.showDialog({
        title: "Error",
        width: "450px",
        content: (close) => (
          <DialogConfirmation
            onCancel={() => {
              close();
            }}
            onConfirm={() => {
              close();
              props.history.push("/custom-fields");
            }}
            // textButtonConfirm="Exit"
          >
            <p>
              You currently have unsaved modifications, are you sure you want to lose your
              modificatons?
            </p>
          </DialogConfirmation>
        ),
      });
    }
  };

  const onChange = (changes) => {
    props.onCustomFieldChanged(changes);
  };

  const onNameBlur = () => {
    onChange({ name: props.customField.name });
  };

  const onTranslationChanged = (key, lang, value) => {
    const changes = { [key]: { ...props.customField[key], [lang]: value } };
    onChange(changes);
  };

  return (
    <Page>
      <SectionHeader title={props.isNew ? "New Custom Field" : "Edit Custom Field"} />

      <div className="layout-row layout-row--start-center">
        <div className="flex--30 flex--nogrow bold">Required</div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-required"
              type="switch"
              name="custom-field-required"
              checked={props.customField.required}
              label=""
              onChange={(value) => {
                if (value) {
                  onChange({
                    required: true,
                    availableInReports: true,
                    displayInDeviceHistoryTable: true,
                  });
                } else {
                  onChange({ required: false });
                }
              }}
            />
            <div>YES</div>
          </div>
        </div>
      </div>

      <div className="layout-row layout-row--start-center">
        <div className="flex--30 flex--nogrow bold">Available in Reports</div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-available-in-reports"
              type="switch"
              name="custom-field-available-in-reports"
              checked={props.customField.availableInReports}
              label=""
              onChange={(value) => onChange({ availableInReports: value })}
              disabled={props.customField.required === true}
            />
            <div>YES</div>
          </div>
        </div>
      </div>
      <div className="layout-row layout-row--start-center">
        <div className="flex--30 flex--nogrow bold">Available in Device History</div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-display-in-device-history-table"
              type="switch"
              name="custom-field-display-in-device-history-table"
              checked={props.customField.displayInDeviceHistoryTable}
              label=""
              onChange={(value) => onChange({ displayInDeviceHistoryTable: value })}
              disabled={props.customField.required === true}
            />
            <div>YES</div>
          </div>
        </div>
      </div>
      <div className="layout-row layout-row--start-center">
        <div className="flex--30 flex--nogrow bold">Supported Device Types</div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={supportedDeviceTypesSelect.supportedDeviceTypes}
              onChange={handleSupportedDeviceTypes}
            >
              {deviceTypes.map((option) => (
                <Select.Option value={option.value} key={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <Divider />

      <div className="layout-row layout-row--start-center">
        <div className="flex--30 flex--nogrow bold">
          Identifier{props.isNew ? <sup>*</sup> : null}
        </div>
        <div className="flex--30 flex--nogrow">
          <TextField
            id="custom-field-name-textfield"
            placeholder="Name"
            value={props.customFieldName}
            onChange={(value) => onChange({ name: value })}
            onBlur={onNameBlur}
            disabled={!props.isNew}
            type="text"
            error={props.nameErrorText.length > 0}
            errorText={props.nameErrorText}
          />
        </div>
      </div>
      <div className="block margin--10 padding--10" />

      <div className="bold layout-row">Name</div>
      {props.customField.displayName
        ? Object.keys(props.customField.displayName).map((lang) => (
            <div key={lang} className="layout-row layout-row--start-center margin--5">
              <div className="flex--30 flex--nogrow">
                {props.languagesByCode[lang].name}
                {lang == "en" ? <sup>*</sup> : null}
              </div>
              <div className="flex--30 flex--nogrow">
                <TextField
                  id={`custom-field-display-name-textfield-${lang}`}
                  placeholder={`Display Name (${lang})`}
                  value={props.customField.displayName[lang] || ""}
                  onChange={(value) => onTranslationChanged("displayName", lang, value)}
                  type="text"
                  error={lang == "en" ? props.customField.displayName.en.length === 0 : false}
                  errorText="Required field"
                />
              </div>
            </div>
          ))
        : null}
      <div className="block margin--10 padding--10" />

      <div className="bold layout-row">Description</div>
      {props.customField.description != null
        ? Object.keys(props.customField.description).map((lang) => (
            <div key={lang} className="layout-row layout-row--start-center margin--5">
              <div className="flex--30 flex--nogrow">{props.languagesByCode[lang].name}</div>
              <div className="flex--30 flex--nogrow">
                <TextField
                  id={`custom-field-description-textfield-${lang}`}
                  placeholder={`Description (${lang})`}
                  value={props.customField.description[lang] || ""}
                  onChange={(value) => onTranslationChanged("description", lang, value)}
                  type="text"
                />
              </div>
            </div>
          ))
        : null}
      <div className="block margin--10 padding--10" />

      <SectionHeader title="Options" description="Types" />
      <div className="layout-row layout-row--start-center margin--5">
        <div className="flex--30 flex--nogrow bold">Type</div>
        <div className="flex--30 flex--nogrow">
          <SelectList
            items={Object.keys(fieldTypes)}
            selectedItem={props.customField.type}
            onChange={(type) => onChange({ type })}
            disabled={!props.isNew}
            nameBuilder={(type) => fieldTypes[type].name}
            underlined
          />
        </div>
      </div>
      <Divider />

      {props.customField.type === "list" ? (
        <CustomFieldListTypeEditor
          items={props.customFielditemsWithId}
          onChange={props.onItemChanged}
          onAdd={props.onAddItem}
          onDelete={props.onDeleteItem}
          languagesByCode={props.languagesByCode}
        />
      ) : null}
      {props.customField.type === "string" ? (
        <CustomFieldStringTypeEditor
          customField={props.customField}
          isMaxLengthValid={props.isMaxLengthValid}
          isStringValid={props.isStringValid}
          onChange={onChange}
        />
      ) : null}
      {props.customField.type === "numeric" ? (
        <CustomFieldNumericTypeEditor
          customField={props.customField}
          isMinNumberValid={props.isMinNumberValid}
          isMaxNumberValid={props.isMaxNumberValid}
          isDefaultNumberValid={props.isDefaultNumberValid}
          onChange={onChange}
        />
      ) : null}

      <div className="block margin--10 padding--10" />

      <SectionHeader title="Options" description="Pro-Diagnostics Desktop" />
      <div className="layout-row layout-row--start-center margin--5">
        <div className="flex--30 flex--nogrow bold">Available to Pro-Diagnostics Desktop</div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-available-on-desktop"
              type="switch"
              name="custom-field-available-on-desktop"
              checked={props.customField.availableOnDesktop}
              label=""
              onChange={(value) => {
                if (value) {
                  onChange({ availableOnDesktop: true });
                } else {
                  onChange({
                    availableOnDesktop: false,
                    displayOnSlot: false,
                    hideFromOperator: false,
                  });
                }
              }}
            />
            <div>YES</div>
          </div>
        </div>
      </div>
      <div className="layout-row layout-row--start-center margin--5">
        <div className="flex--30 flex--nogrow bold">
          Display on device slot in Pro-Diagnostics Desktop
        </div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-display-on-slot"
              type="switch"
              name="custom-field-display-on-slot"
              checked={props.customField.displayOnSlot}
              label=""
              onChange={(value) => onChange({ displayOnSlot: value })}
              disabled={!props.customField.availableOnDesktop}
            />
            <div>YES</div>
          </div>
        </div>
      </div>
      <div className="layout-row layout-row--start-center margin--5">
        <div className="flex--30 flex--nogrow bold">
          Hide from GMDI window in Pro-Diagnostics Desktop
        </div>
        <div className="flex--30 flex--nogrow">
          <div className="layout-row layout-row--start-center">
            <div>NO</div>
            <Switch
              id="custom-field-hide-from-operator"
              type="switch"
              name="custom-field-hide-from-operator"
              checked={props.customField.hideFromOperator}
              label=""
              onChange={(value) => onChange({ hideFromOperator: value })}
              disabled={!props.customField.availableOnDesktop}
            />
            <div>YES</div>
          </div>
        </div>
      </div>
      <div className="layout-row layout-row--start-center margin--5">
        <div className="flex--30 flex--nogrow margin--5">Display at</div>
        <div className="flex--30 flex--nogrow">
          <SelectList
            items={Object.keys(displayAtOptions)}
            selectedItem={props.customField.displayAt}
            onChange={(displayAt) => onChange({ displayAt })}
            nameBuilder={(type) => displayAtOptions[type].name}
            underlined
          />
        </div>
      </div>
      <div className="block margin--10 padding--10" />

      {props.isNew ? null : <CustomFieldEditorButtons showConfirmation={showConfirmation} />}
      <AsciSpinner visible={props.isLoading} />
    </Page>
  );
};

CustomFieldEditor.propTypes = {
  isNew: PropTypes.bool,
};

CustomFieldEditor.defaultProps = {
  isNew: false,
};

const stateToProps = (state) => ({
  canSave: moduleSelectors.customFieldEditor.getCanSave(state),
  customField: moduleSelectors.customFieldEditor.getUpdatedCustomField(state),
  customFielditemsWithId: moduleSelectors.customFieldEditor.getCustomFielditemsWithId(state),
  customFieldName: moduleSelectors.customFieldEditor.getCustomFieldName(state),
  errors: state.modules.customFields.customFieldEditor.errors,
  isDefaultNumberValid: moduleSelectors.customFieldEditor.getIsDefaultNumberValid(state),
  isEqual: moduleSelectors.customFieldEditor.getIsEqual(state),
  isLoading: state.modules.customFields.customFieldEditor.isLoading,
  isMaxLengthValid: moduleSelectors.customFieldEditor.getIsMaxLengthValid(state),
  isStringValid: moduleSelectors.customFieldEditor.getStringValid(state),
  isMaxNumberValid: moduleSelectors.customFieldEditor.getIsMaxNumberValid(state),
  isMinNumberValid: moduleSelectors.customFieldEditor.getIsMinNumberValid(state),
  languagesByCode: selectors.languages.getLanguagesByCode(state),
  message: state.modules.customFields.customFieldEditor.message,
  nameErrorText: moduleSelectors.customFieldEditor.getNameErrorText(state),
  csid: state.customer.activeCustomerId,
});

const dispatchToProps = {
  clearCustomField: moduleActions.customFieldEditor.clearCustomField,
  createCustomField: moduleActions.customFieldEditor.createCustomField,
  editCustomField: moduleActions.customFieldEditor.editCustomField,
  fetchCustomField: moduleActions.customFieldEditor.fetchCustomField,
  fetchCustomFieldsName: actions.customFields.fetchCustomFieldsName,
  hideDialog: actions.dialog.hide,
  initCustomField: moduleActions.customFieldEditor.initCustomField,
  onAddItem: moduleActions.customFieldEditor.onAddItem,
  onCustomFieldChanged: moduleActions.customFieldEditor.onCustomFieldChanged,
  onDeleteItem: moduleActions.customFieldEditor.onDeleteItem,
  onItemChanged: moduleActions.customFieldEditor.onItemChanged,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(CustomFieldEditor);
