import React from "react";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

import InspectorSection from "./InspectorSection";
import InspectorInput from "./InspectorInput";
import GroupedButtons from "./GroupedButtons";

const AlignmentInspector = (props) => {
  const { level, vertical, horizontal, orientation, direction, setterFactory } = props;
  return (
    <InspectorSection title="Alignment" initialFolded level={level}>
      <InspectorInput label="Horizontal">
        <GroupedButtons
          options={[
            {
              value: "flex-start",
              label: <FontIcon iconClassName="icon-align-left1" />,
              title: "Left",
            },
            {
              value: "center",
              label: <FontIcon iconClassName="icon-align-horizontal-middle" />,
              title: "Center",
            },
            {
              value: "flex-end",
              label: <FontIcon iconClassName="icon-align-right1" />,
              title: "Right",
            },
          ]}
          selected={horizontal ? [horizontal] : []}
          onSelectOption={setterFactory("horizontal")}
        />
      </InspectorInput>
      <InspectorInput label="Vertical">
        <GroupedButtons
          options={[
            {
              value: "flex-start",
              label: <FontIcon iconClassName="icon-align-top" />,
              title: "Top",
            },
            {
              value: "center",
              label: <FontIcon iconClassName="icon-align-vertical-middle" />,
              title: "Center",
            },
            {
              value: "flex-end",
              label: <FontIcon iconClassName="icon-align-bottom" />,
              title: "Bottom",
            },
          ]}
          selected={vertical ? [vertical] : vertical}
          onSelectOption={setterFactory("vertical")}
        />
      </InspectorInput>
      {/* <InspectorInput label="Orientation"> */}
      {/*    <Select value={orientation} onChange={setterFactory("orientation")} allowClear> */}
      {/*        <Select.Option value="vertical-lr">Vertical</Select.Option> */}
      {/*        <Select.Option value="vertical-rl">Vertical Right</Select.Option> */}
      {/*        <Select.Option value="horizontal-tb">Horizontal</Select.Option> */}
      {/*        <Select.Option value="sideways-lr">Sideways</Select.Option> */}
      {/*        <Select.Option value="sideways-rl">Sideways Right</Select.Option> */}
      {/*    </Select> */}
      {/* </InspectorInput> */}
      {/* <InspectorInput label="Direction"> */}
      {/*    <Select value={direction} onChange={setterFactory("direction")} allowClear> */}
      {/*        <Select.Option value="ltr">Left to Right</Select.Option> */}
      {/*        <Select.Option value="rtl">Right to left</Select.Option> */}
      {/*    </Select> */}
      {/* </InspectorInput> */}
    </InspectorSection>
  );
};

AlignmentInspector.defaultProps = {};

AlignmentInspector.propTypes = {
  level: PropTypes.number,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  orientation: PropTypes.string,
  direction: PropTypes.string,
  setterFactory: PropTypes.func,
};

export default AlignmentInspector;
