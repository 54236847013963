import { combineReducers } from "redux";
import labelDesigner from "./labelDesigner.reducer";
import labelSaver from "./labelSaver.reducer";
import labelDeleter from "./labelDeleter.reducer";
import labelLister from "./labelLister.reducer";
import labelPicker from "./labelPicker.reducer";
import labelFonts from "./labelFonts.reducer";
import labelPrint from "./labelPrint.reducer";
import labelFormats from "./labelFormats.reducer";
import labelFields from "./labelFields.reducer";
import labelStatics from "./labelStatics.reducer";

export default combineReducers({
  labelDesigner,
  labelSaver,
  labelDeleter,
  labelLister,
  labelPicker,
  labelFonts,
  labelPrint,
  labelFormats,
  labelFields,
  labelStatics,
});
