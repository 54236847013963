import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";

import { DialogConfirmation } from "~/global";
import { getRegexValidation } from "~/global/utils";

const AddInclusionModelDialog = ({ isModelValid, onCancel, onConfirm }) => {
  const [modelIdentifier, setModelIdentifier] = useState("");
  const [message, setMessage] = useState("");
  const [canConfirm, setCanConfirm] = useState(false);
  const inputField = useRef(null);

  useEffect(() => {
    inputField.current.focus();
  }, []);

  const onChange = (val) => {
    const trimmed = val.trim();

    let message = "";
    let canConfirm = true;

    if (trimmed.length < 2) {
      message = "Model identifier must have at least 2 characters";
      canConfirm = false;
    } else if (trimmed.length > 30) {
      message = "Model identifier can't have more than 30 characters";
      canConfirm = false;
    } else if (!getRegexValidation("testConfigInclusionModelName", trimmed)) {
      message = "Invalid model identifier";
      canConfirm = false;
    }

    if (!isModelValid(trimmed)) {
      message = "Model identifier must be unique";
      canConfirm = false;
    }

    setModelIdentifier(val);
    setMessage(message);
    setCanConfirm(canConfirm);
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter" && isModelValid(modelIdentifier) && canConfirm) {
      confirm();
    }
  };

  const confirm = () => {
    onConfirm(modelIdentifier.trim());
  };

  return (
    <DialogConfirmation onCancel={onCancel} onConfirm={confirm} confirmDisabled={!canConfirm}>
      <TextField
        id="model-name-input"
        label="Model identifier"
        aria-label="Model identifier input"
        placeholder="iPhone9,1"
        type="text"
        value={modelIdentifier}
        onKeyUp={onKeyUp}
        onChange={onChange}
        error={message.length > 0}
        errorText={message}
        ref={inputField}
      />
    </DialogConfirmation>
  );
};

AddInclusionModelDialog.propTypes = {
  isModelValid: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AddInclusionModelDialog;
