import WebserviceHandler from "./WebserviceHandler";

export const ConsumerManagement = new WebserviceHandler("consumerManagement");
export const CustomerManagement = new WebserviceHandler("customerManagement");
export const InventoryManagement = new WebserviceHandler("inventoryManagement");
export const DataCollector = new WebserviceHandler("dataCollector");
export const DocumentationManagement = new WebserviceHandler("documentationManagement");
export const LicenseManagement = new WebserviceHandler("licenseManagement");
export const Logmanagement = new WebserviceHandler("logManagement");
export const ModelManagement = new WebserviceHandler("modelManagement");
export const PDFManagement = new WebserviceHandler("pdfManagement");
export const UserManagement = new WebserviceHandler("userManagement");
export const WorkstationManagement = new WebserviceHandler("workstationManagement");

export type ApiErrorResponse = {
  json: ApiError;
};

export type ApiError = {
  message?: string;
  errors?: Record<string, string>;
};

export type ApiResponse<T> = {
  payload: T;
};

export type Paginated<T> = {
  Doc: T;
  TotalDoc: number;
};
