import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-md";
import { message } from "antd";

import actions from "~/actions";
import moduleSelectors from "~/data-import/selectors/";
import moduleActions from "~/data-import/actions/";
import { Row, AsciButton, SectionHeader, DialogMessage } from "~/global";

import "./UploadCSVPage.component.scss";
import dataImportTemplate from "./data-import-template.csv";
import UploadInfoFilters from "../UploadInfoFilters/UploadInfoFilters.component";
import UploadCSVFile from "../UploadCSVFile/UploadCSVFile.component";
import FileValidation from "../FileValidation/FileValidation.component";

const UploadCSVPage = ({
  createRecording,
  selectedFilters,
  dataToUpload,
  recordingErrors,
  isLoading,
  errors,
  showDialog,
}) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const [resetFilters, setResetFilters] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [onSucess, setOnSucess] = useState(false);
  const [showSessionId, setShowSessionId] = useState(false);
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    if (errors != null) {
      message.error(errors);
    }
  }, [errors]);

  const buttonAccessible = () => {
    let isFiltersSelected = true;

    if (selectedFilters !== undefined) {
      for (const field of Object.values(selectedFilters)) {
        if (field !== undefined) {
          isFiltersSelected = field.id != "all" && field.faid != "all" && field.teamid != "all";
          if (!isFiltersSelected) {
            break;
          }
        }
      }
    }
    return isFiltersSelected;
  };

  const isFacilitySelected = () => {
    let isSelected = false;

    if (selectedFilters !== undefined) {
      for (const field of Object.values(selectedFilters)) {
        if (field !== undefined) {
          isSelected = field.faid != "all";
          if (!isSelected) {
            break;
          }
        }
      }
    }

    return isSelected;
  };

  const buildUploadData = async () => {
    let isSuccess = true;

    for (const recording of dataToUpload) {
      if (!isSuccess) {
        break;
      }

      isSuccess = await createRecording(recording);
    }

    if (isSuccess) {
      message.success("Data successfully imported");
    }

    setSessionId(dataToUpload[0].batchSessionId);
    setOnSucess(true);
    setShowSessionId(true);
  };

  const isImportAllowed = () => {
    if (buttonAccessible() && isFileUploaded && recordingErrors.length == 0) {
      return true;
    }
    return false;
  };

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = sessionId;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    message.info(`${sessionId} copied to clipboard`);
  };

  const showInfoMessage = () => {
    showDialog({
      title: "Usage Information",
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={close}>
          <p>
            The upload device list allows you to import data from a csv file directly into the
            database.
          </p>
        </DialogMessage>
      ),
    });
  };

  return (
    <div className="upload-device-tab">
      <SectionHeader>
        <a href={dataImportTemplate} target="_blank" rel="noreferrer">
          <i className="md-icon icon-download text-color--white margin-right--10" />
          <span className="text-color--white bold">CSV File Template</span>
        </a>
        <Button
          icon
          className="icon-btn text-color--white"
          iconClassName="icon-info-circle"
          forceIconSize={32}
          forceIconFontSize
          onClick={showInfoMessage}
        />
      </SectionHeader>
      <Row className="section-spacing">
        <UploadInfoFilters
          currentFilters={currentFilters}
          setCurrentFilters={(filters) => setCurrentFilters(filters)}
          resetFilters={resetFilters}
          setResetFilters={(reset) => setResetFilters(reset)}
        />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <UploadCSVFile
          setIsFileUploaded={(isUploaded) => setIsFileUploaded(isUploaded)}
          onSucess={onSucess}
          setOnSucess={(sucess) => setOnSucess(sucess)}
        />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <FileValidation />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing" align="space-between">
        {showSessionId ? (
          <div>
            <AsciButton className="session-id-btn" color="white" onClick={() => copyToClipboard()}>
              Copy session ID
              <i className="icon-copy" />
            </AsciButton>
          </div>
        ) : (
          <div />
        )}
        <Row className="button-group">
          <AsciButton
            className="clear-filters-btn"
            color="white"
            onClick={() => setResetFilters(true)}
            disabled={!isFacilitySelected()}
          >
            Clear filters
          </AsciButton>
          <AsciButton
            className="import-data-btn"
            color="blue"
            onClick={() => buildUploadData()}
            disabled={!isImportAllowed() || isLoading}
          >
            Import into Database
          </AsciButton>
        </Row>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  customerId: state.customer.activeCustomerId,
  username: state.session.user.nickname,
  isLoading: state.modules.dataImport.importDataAutomatic.isLoading,
  errors: state.modules.dataImport.importDataAutomatic.errors,
  selectedFilters: state.modules.dataImport.importDataAutomatic.selectedFilters,
  dataToUpload: moduleSelectors.importDataAutomatic.getDataToUpload(state),
  recordingErrors: moduleSelectors.importDataAutomatic.getRecordingErrors(state),
});

const dispatchToProps = {
  createRecording: moduleActions.importDataAutomatic.createRecording,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(UploadCSVPage);
