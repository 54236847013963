import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Menu } from "antd";
import { FontIcon } from "react-md";

import * as wConditions from "~/workflow/workflowConditions";
import ConditionMenu from "~/workflow/components/editors/ConditionMenu";
import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";

import RecordingFieldCompare from "~/workflow/components/editors/conditions/RecordingFieldCompare";
import "./ConditionEditor.scss";
import TestResultCompare from "~/workflow/components/editors/conditions/TestResultCompare";

const OperatorMenu = (props) => (
  <Menu onClick={props.onClick}>
    <Menu.Item key="AND">AND</Menu.Item>
    <Menu.Item key="OR">OR</Menu.Item>
    <Menu.Item key="NOT">NOT</Menu.Item>
  </Menu>
);

const Empty = () => <></>;

const ConditionEditor = (props) => {
  const { conditionId, eventId, behaviorId, conditionType, operator, conditionData, behaviorType } =
    props;
  const dispatch = useDispatch();
  const isFirst = useSelector(
    workflowSelectors.eventEditor[eventId].isFirstCondition(behaviorId, conditionId)
  );

  const editor = useMemo(() => {
    const editorProps = { conditionId, eventId, behaviorId, conditionData };
    switch (conditionType) {
      case wConditions.Always:
      case wConditions.Never:
      case wConditions.IsPass:
      case wConditions.IsFail:
        return <Empty />;
      case wConditions.RecordingFieldCompare:
        return <RecordingFieldCompare {...editorProps} />;
      case wConditions.TestResultCompare:
        return <TestResultCompare {...editorProps} />;
      default:
        return (
          <div>
            <b style={{ color: "red" }}>ERROR</b>: invalid condition type: {conditionType}
          </div>
        );
    }
  }, [conditionType, conditionId, conditionData, eventId, behaviorId]);

  const onChangeConditionType = (e) =>
    dispatch(
      workflowActions.eventEditor[eventId].changeConditionType({
        behaviorId,
        conditionId,
        conditionType: e.key,
      })
    );
  const onChangeConditionOperator = (e) =>
    dispatch(
      workflowActions.eventEditor[eventId].setConditionOperator({
        behaviorId,
        conditionId,
        operator: e.key,
      })
    );
  const onRemoveCondition = () =>
    dispatch(workflowActions.eventEditor[eventId].removeCondition({ behaviorId, conditionId }));

  return (
    <div className="condition-editor">
      <div className="condition-editor-body">
        {!isFirst && (
          <Dropdown overlay={<OperatorMenu onClick={onChangeConditionOperator} />}>
            <Button>{operator}</Button>
          </Dropdown>
        )}
        <Dropdown
          overlay={
            <ConditionMenu
              eventId={eventId}
              behaviorType={behaviorType}
              onClick={onChangeConditionType}
            />
          }
        >
          <Button>{wConditions.metadata[conditionType].label}</Button>
        </Dropdown>
        {editor}
      </div>
      {!isFirst && (
        <FontIcon
          onClick={onRemoveCondition}
          iconClassName="icon-trash"
          className="remove-condition"
        />
      )}
    </div>
  );
};

export default ConditionEditor;
