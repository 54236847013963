import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import produce from "immer";
import type { AnyAction, StoreEnhancer } from "redux";
import { combineReducers } from "redux";
import type { ThunkAction } from "redux-thunk";

import applications from "./reducers/applications.reducer";
import audits from "./reducers/audits.reducer";
import countries from "./reducers/countries.reducer";
import csvUpload from "./features/csv-upload/csvUploadSlice";
import customer from "./reducers/customer.reducer";
import customers from "./reducers/customers.reducer";
import customFields from "./reducers/customFields.reducer";
import deviceProcesses from "./pages/reporting/model/deviceProcessesSlice";
import dialog from "./reducers/dialog.reducer";
import documentation from "./reducers/documentation.reducer";
import exportedReports from "./reducers/exportedReports.reducer";
import facilities from "./reducers/facilities.reducer";
import facility from "./reducers/facility.reducer";
import failCode from "./reducers/failCodes/failCode.reducer";
import failCodeCategories from "./reducers/failCodes/failCodeCategories.reducer";
import failCodeCategory from "./reducers/failCodes/failCodeCategory.reducer";
import failCodePriorityGroup from "./reducers/failCodes/failCodePriorityGroup.reducer";
import failCodePriorityGroups from "./reducers/failCodes/failCodePriorityGroups.reducer";
import failCodes from "./reducers/failCodes/failCodes.reducer";
import filters from "./reducers/filters.reducer";
import labelPrinter from "./label-printer/reducers";
import languages from "./reducers/languages.reducer";
import modelDescriptors from "./entities/model-descriptors/model/modelDescriptorsSlice";
import modelImage from "./pages/device-history/model/modelImageSlice";
import modules from "./reducers/modules.reducer";
import platforms from "./reducers/platforms.reducer";
import processRuns from "./entities/process-runs/model/processRunsSlice";
import programs from "./reducers/programs.reducer";
import programsReducer from "./pages/programs/model/programsSlice";
import report from "./reducers/report.reducer";
import reportingChart from "./features/reporting-chart/reportingChart.slice";
import reportingFilters from "./features/recording-facets/reportingFilters.slice";
import reportTypes from "./reducers/reportTypes.reducer";
import scheduledReports from "./pages/reporting/model/scheduledReportsSlice";
import serviceSuites from "./reducers/serviceSuites.reducer";
import servicesVersion from "./reducers/servicesVersion.reducer";
import session from "./reducers/session.reducer";
import sku from "./entities/sku/model/skuSlice";
import skuGroups from "./entities/sku-groups/model/skuGroupsSlice";
import skuStatics from "./pages/sku-groups/model/skuStaticsSlice";
import testCategories from "./reducers/testCategories.reducer";
import testDefinitions from "./test-definitions-editor/reducers/testDefinitions.reducer";
import tests from "./reducers/tests.reducer";
import testSuites from "./reducers/testSuites.reducer";
import workflow from "./workflow/redux";
import workstation from "./workstation/redux";
import inventoryProjects from "./entities/inventory/model/inventoryProjetsSlice";
import inventoryLocations from "./entities/inventory/model/inventoryLocationsSlice";
import inventoryTasks from "./entities/inventory/model/inventoryTasksSlice";

// This enhancer must be defined outside of the store creation because the store creation cannot reference the RootState type (inferred from itself)
const sentryEnhancer = Sentry.createReduxEnhancer({
  // Remove base64 encoded images before logging to avoid "413 Payload Too Large"
  stateTransformer: (state: RootState) =>
    produce(state, (draftState) => {
      draftState.customers.items.forEach((customer: { logo: string }) => {
        if (customer.logo.startsWith("data:")) {
          customer.logo = ""; // eslint-disable-line no-param-reassign
        }
      });
    }),
  actionTransformer: (action) => {
    switch (action.type) {
      case "FETCH_CUSTOMERS_SUCCESS":
        return produce(action, (action) => {
          action.payload.customers.forEach((customer: { logo: string }) => {
            if (customer.logo.startsWith("data:")) {
              customer.logo = ""; // eslint-disable-line no-param-reassign
            }
          });
        });
      default:
        return action;
    }
  },
}) as StoreEnhancer;

const reportingModule = combineReducers({
  chart: reportingChart,
  csvUpload,
  deviceProcesses,
  exportedReports,
  filters: reportingFilters,
  modelImage,
  processRuns,
  report,
  reportTypes,
  scheduledReports,
});

export const createAppStore = () =>
  configureStore({
    reducer: {
      modules,
      reportingModule,
      applications,
      audits,
      countries,
      customer,
      customers,
      customFields,
      dialog,
      documentation,
      facilities,
      facility,
      failCode,
      failCodeCategories,
      failCodeCategory,
      failCodePriorityGroup,
      failCodePriorityGroups,
      failCodes,
      filters,
      inventoryProjects,
      inventoryTasks,
      inventoryLocations,
      labelPrinter,
      languages,
      modelDescriptors,
      platforms,
      programs,
      programsReducer,
      serviceSuites,
      servicesVersion,
      session,
      sku,
      skuGroups,
      skuStatics,
      testCategories,
      testDefinitions,
      tests,
      testSuites,
      workflow,
      workstation,
    } as const,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    enhancers: [sentryEnhancer],
  });

const store = createAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default store;
