import { createReducer } from "@reduxjs/toolkit";

import {
  fetchTestDetailsBegin,
  fetchTestDetailsSuccess,
  fetchTestDetailsError,
  setSelectedTestDetails,
  setInitiallySelectedTestDetails,
} from "~/actions/tests.actions";

const initialState = {
  isLoading: false,
  items: [],
  selected: {},
  initiallySelectedTestClassName: undefined,
  initiallySelectedTestPlatform: undefined,
};

export default createReducer(initialState, {
  // Fetch test details
  [fetchTestDetailsBegin]: (state, action) => {
    state.isLoading = true;
  },
  [fetchTestDetailsSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.details || [];
  },
  [fetchTestDetailsError]: (state, action) => {
    console.error(action.payload.errors);
    state.isLoading = false;
    state.items = [];
  },

  [setSelectedTestDetails]: (state, action) => {
    state.selected = action.payload || {};
  },

  [setInitiallySelectedTestDetails]: (state, action) => {
    const payload = action.payload || {};
    state.initiallySelectedTestClassName = payload.className || undefined;
    state.initiallySelectedTestPlatform = payload.platform || undefined;
  },
});
