import { createAction } from "@reduxjs/toolkit";
import { isObject } from "lodash/lang";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";
import moduleSelectors from "~/custom-fields-editor/selectors/";

export const fetchCustomFieldBegin = createAction("FETCH_CUSTOM_FIELD_BEGIN");
export const fetchCustomFieldSuccess = createAction("FETCH_CUSTOM_FIELD_SUCCESS");
export const fetchCustomFieldError = createAction("FETCH_CUSTOM_FIELD_ERROR");
export const initCustomFieldSuccess = createAction("INIT_CUSTOM_FIELD_SUCCESS");
export const resetCustomField = createAction("RESET_CUSTOM_FIELD");

export const editCustomFieldBegin = createAction("EDIT_CUSTOM_FIELD_BEGIN");
export const editCustomFieldSuccess = createAction("EDIT_CUSTOM_FIELD_SUCCESS");
export const editCustomFieldError = createAction("EDIT_CUSTOM_FIELD_ERROR");

export const createCustomFieldBegin = createAction("CREATE_CUSTOM_FIELD_BEGIN");
export const createCustomFieldSuccess = createAction("CREATE_CUSTOM_FIELD_SUCCESS");
export const createCustomFieldError = createAction("CREATE_CUSTOM_FIELD_ERROR");

export const updateCustomField = createAction("UPDATE_CUSTOM_FIELD");

export const initCustomField = () => async (dispatch, getState) => {
  const state = getState();
  const languagesEmptyValue = selectors.languages.getLanguagesEmptyValue(state);

  const customField = {
    availableInReports: false,
    availableOnDesktop: false,
    displayInDeviceHistoryTable: false,
    displayOnSlot: false,
    defaultNumberValue: 0,
    defaultStringValue: "",
    description: languagesEmptyValue,
    displayAt: "login",
    displayName: languagesEmptyValue,
    hideFromOperator: false,
    items: [{ displayName: languagesEmptyValue, value: "" }],
    max: 0,
    maxLength: 0,
    min: 0,
    name: "",
    required: false,
    supportedByAllDeviceTypes: true,
    supportedDeviceTypes: [],
    type: "numeric",
  };

  dispatch(initCustomFieldSuccess({ customField }));
};

export const createCustomField = (data) => async (dispatch) => {
  const dataToServer = {
    ...data,
    maxLength: +data.maxLength,
    min: +data.min,
    max: +data.max,
  };

  try {
    dispatch(createCustomFieldBegin());
    const response = await CustomerManagement.POST("/custom-fields", dataToServer);
    if (response.success) {
      dispatch(createCustomFieldSuccess({ message: response.message }));
    } else {
      dispatch(createCustomFieldError({ errors: "Request didn't complete successfully" }));
    }
  } catch (e) {
    const message =
      e.message != null && !isObject(e.message)
        ? e.message
        : "Request didn't complete successfully";
    dispatch(createCustomFieldError({ errors: message }));
  }
};

export const editCustomField = (data) => async (dispatch) => {
  const dataToServer = {
    ...data,
    maxLength: +data.maxLength,
    min: +data.min,
    max: +data.max,
  };

  try {
    dispatch(editCustomFieldBegin());
    const response = await CustomerManagement.PUT(
      `/custom-fields/${dataToServer.name}`,
      dataToServer
    );
    if (response.success) {
      dispatch(editCustomFieldSuccess({ message: response.message }));
    } else {
      dispatch(editCustomFieldError({ errors: "Request didn't complete successfully" }));
    }
  } catch (e) {
    const message =
      e.message != null && !isObject(e.message)
        ? e.message
        : "Request didn't complete successfully";
    dispatch(editCustomFieldError({ errors: message }));
  }
};

export const fetchCustomField = (fieldName) => async (dispatch, getState) => {
  const state = getState();
  const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);
  try {
    dispatch(fetchCustomFieldBegin());
    const query = CustomerManagement.gqlBuilder(`{
      custom_field(name:"${fieldName}") {
        availableInReports,
        availableOnDesktop,
        dateAdded,
        dateUpdated,
        defaultNumberValue,
        defaultStringValue,
        description {${displayNameLanguages}},
        displayAt,
        displayInDeviceHistoryTable,
        displayOnSlot,
        displayName {${displayNameLanguages}},
        hideFromOperator,
        items{ displayName {${displayNameLanguages}}, value },
        max,
        maxLength,
        min,
        name,
        required,
        supportedByAllDeviceTypes,
        supportedDeviceTypes,
        type
      }
    }`);

    const response = await CustomerManagement.QUERY(query);
    const customField = response.payload.data.custom_field;

    dispatch(fetchCustomFieldSuccess({ customField }));
    return customField;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchCustomFieldError({ errors: message }));
  }
};

export const onCustomFieldChanged = (customField) => updateCustomField({ customField });

export const onItemChanged = (newItem, itemId) => async (dispatch, getState) => {
  const state = getState();
  const customFielditemsWithId = moduleSelectors.customFieldEditor.getCustomFielditemsWithId(state);
  customFielditemsWithId[itemId] = { ...customFielditemsWithId[itemId], ...newItem };
  const customFieldItems = Object.keys(customFielditemsWithId).map(
    (id) => customFielditemsWithId[id]
  );
  const customField = { items: customFieldItems };
  dispatch(updateCustomField({ customField }));
};

export const onAddItem = () => async (dispatch, getState) => {
  const state = getState();
  const languagesEmptyValue = selectors.languages.getLanguagesEmptyValue(state);
  const item = {
    displayName: languagesEmptyValue,
    value: "",
  };

  const customFielditemsWithId = moduleSelectors.customFieldEditor.getCustomFielditemsWithId(state);
  const customFieldItems = Object.keys(customFielditemsWithId).map(
    (id) => customFielditemsWithId[id]
  );
  const newCustomFieldItems = [].concat(customFieldItems, item);
  const customField = { items: newCustomFieldItems };

  dispatch(updateCustomField({ customField }));
};

export const onDeleteItem = (itemId) => async (dispatch, getState) => {
  const state = getState();
  const customFielditemsWithId = moduleSelectors.customFieldEditor.getCustomFielditemsWithId(state);
  const newCustomFieldItems = Object.keys(customFielditemsWithId)
    .filter((id) => id !== itemId)
    .map((id) => customFielditemsWithId[id]);
  const customField = { items: newCustomFieldItems };

  dispatch(updateCustomField({ customField }));
};

export const clearCustomField = () => resetCustomField();
