import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Drawer } from "react-md";
import { connect } from "react-redux";
import { useMatch } from "react-router-dom-v5-compat";

import { InputSearch } from "~/global";
import history from "~/global/history";
import selectors from "~/selectors";
import ActiveCustomerSelector from "./ActiveCustomerSelector/ActiveCustomerSelector.component";
import ClientLogo from "./ClientLogo/ClientLogo.component";
import { DeviceSearchBox } from "./DeviceSearchBox";
import DrawerMenu from "./DrawerMenu/DrawerMenu.component";
import FeatureTitle from "./FeatureTitle/FeatureTitle.component";
import SessionInfo from "./SessionInfo/SessionInfo.component";

import "./Navbar.component.scss";

const Navbar = ({
  currentApp,
  isAuthenticated,
  activeCustomer,
  isLoading,
  customers,
  onActiveCustomerChanged,
  onLogout,

  user,
  isChangingActiveCustomer,
  canChangeActiveCustomer,
  canChangePassword,
  canSeeLicenses,
  canSeeDownloadCenter,
}) => {
  const { t } = useTranslation(["navbar"]);
  const match = useMatch("/reporting");

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [canSearchItems, setCanSearchItems] = useState(false);
  const [drawerItems, setDrawerItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);

  // close drawer when the url changes
  useEffect(() => {
    const unregisterHistoryListener = history.listen(() => {
      closeDrawer();
    });

    return () => {
      if (unregisterHistoryListener) {
        unregisterHistoryListener();
      }
    };
  }, []);

  const closeDrawer = () => {
    setIsDrawerVisible(false);
    setCanSearchItems(false);
  };

  const onToggleDrawer = (isVisible) => {
    setIsDrawerVisible(isVisible);
  };

  const onShowActiveCustomerSelector = (items) => {
    setIsDrawerVisible(true);
    setDrawerItems([...items]);
    setDisplayedItems([...items]);
    setCanSearchItems(true);
  };

  const onActiveCustomerListChanged = (csid) => {
    setIsDrawerVisible(false);
    onActiveCustomerChanged(csid);
  };

  const onOpenMenu = (items) => {
    setIsDrawerVisible(true);
    setDrawerItems([...items]);
    setDisplayedItems([...items]);
    setCanSearchItems(false);
  };

  const onSearchChanged = (searchText) => {
    if (searchText.length > 0) {
      const value = searchText.toLowerCase();
      const items = ([...drawerItems] || []).filter((drawerItem) => {
        const customerKey = drawerItem.key.toLowerCase();
        const customerName = drawerItem.props.name.toLowerCase();
        let found = false;

        if (customerName.includes(value) || customerKey.includes(value)) {
          found = true;
        } else {
          found = false;
        }
        return found;
      });
      setDisplayedItems(items);
    } else {
      setDisplayedItems([...drawerItems]);
    }
  };

  let title = "";
  let appName = "";
  if (currentApp) {
    const i18nTitle = t(`home:modules.${currentApp.title}`, { returnObjects: true });
    title = Array.isArray(i18nTitle) ? i18nTitle.map((word) => word).join(" ") : i18nTitle;
    appName = currentApp.appName;
  }

  return (
    <div className="cc-navbar layout-row layout-row--start-center ">
      {isAuthenticated && !isLoading ? (
        <>
          <ClientLogo clientName={activeCustomer.name} clientLogo={activeCustomer.logo} />

          <FeatureTitle title={title} appName={appName} />

          {!match && <DeviceSearchBox />}

          <SessionInfo initials={user.initials || ""} nickname={user.nickname || ""} />

          {canChangeActiveCustomer && customers.length > 0 ? (
            <ActiveCustomerSelector
              customers={customers}
              activeCustomer={activeCustomer}
              isLoading={isChangingActiveCustomer}
              onChange={onActiveCustomerListChanged}
              onActivate={onShowActiveCustomerSelector}
            />
          ) : null}

          <DrawerMenu
            csid={activeCustomer.csid}
            onLogout={() => {
              closeDrawer();
              onLogout();
            }}
            onOpen={onOpenMenu}
            onClose={closeDrawer}
            canChangePassword={canChangePassword}
            canSeeLicenses={canSeeLicenses}
            canSeeDownloadCenter={canSeeDownloadCenter}
          />

          <Drawer
            id="navbar-drawer"
            type={Drawer.DrawerTypes.TEMPORARY}
            visible={isDrawerVisible}
            position="right"
            onVisibilityChange={onToggleDrawer}
            overlayClassName="main-menu-overlay"
            navItems={displayedItems}
            overlay
            autoclose
            header={
              <div className="drawer-header md-divider-border md-divider-border--bottom layout-row layout-row--space-between-center">
                <Button icon className="icon-arrow-right close-drawer-btn" onClick={closeDrawer} />
                {canSearchItems ? (
                  <InputSearch
                    onChange={(searchValue) => onSearchChanged(searchValue)}
                    placeholder={t("search-input-placeholder")}
                    className="margin-right--5"
                    trim
                  />
                ) : null}
              </div>
            }
          />
        </>
      ) : null}
    </div>
  );
};

Navbar.propTypes = {
  currentApp: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  activeCustomer: PropTypes.object,
  isLoading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.object),
  onActiveCustomerChanged: PropTypes.func,
  onLogout: PropTypes.func.isRequired,

  // store
  user: PropTypes.shape({
    initials: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
  isChangingActiveCustomer: PropTypes.bool.isRequired,
  canChangeActiveCustomer: PropTypes.bool.isRequired,
  canChangePassword: PropTypes.bool.isRequired,
  canSeeLicenses: PropTypes.bool.isRequired,
  canSeeDownloadCenter: PropTypes.bool.isRequired,
};

Navbar.defaultProps = {
  currentApp: {},
  isAuthenticated: false,
  activeCustomer: {},
  isLoading: false,
  customers: [],
};

const stateToProps = (state) => ({
  user: state.session.user,
  isChangingActiveCustomer: state.customer.isChangingActiveCustomer,

  canChangeActiveCustomer: selectors.session.hasPermission(
    state,
    "GET",
    "/gql/change-active-customer"
  ),
  canChangePassword: selectors.session.hasPermission(state, "PUT", "/user/pwd"),
  canSeeLicenses: selectors.session.hasPermission(state, "GET", "/licenses"),
  canSeeDownloadCenter: selectors.session.hasPermission(state, "GET", "/versions/:version"),
  customers: selectors.customers.getActiveCustomers(state),
});

export default connect(stateToProps, null)(Navbar);
