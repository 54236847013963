import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const DashboardIcon = ({ size, customId }) => (
  <SVGIcon viewBox="0 0 36.13 35.99" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x2="16.94"
        y1="8.47"
        y2="8.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.2" stopColor="#96c934" />
        <stop offset="0.36" stopColor="#8cbf37" />
        <stop offset="0.51" stopColor="#7aad3d" />
        <stop offset="0.65" stopColor="#629545" />
        <stop offset="0.78" stopColor="#437650" />
        <stop offset="0.91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x1="19.19"
        x2="36.13"
        y1="8.47"
        y2="8.47"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-c`}
        x2="16.94"
        y1="27.52"
        y2="27.52"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-d`}
        x1="19.19"
        x2="36.13"
        y1="27.52"
        y2="27.52"
        xlinkHref={`#${customId}-a`}
      />
    </defs>

    <g>
      <path
        d="M9.75 8.14h2.59a.34.34 0 0 0 .34-.34 2.92 2.92 0 0 0-2.93-2.92.34.34 0 0 0-.34.34V7.8a.34.34 0 0 0 .34.34zm.34-2.56A2.22 2.22 0 0 1 12 7.46h-1.91z"
        fill="#8bc53f"
        className="cls-1"
      />
      <path
        fill="#003764"
        d="M8.29 12.85a3.64 3.64 0 0 0 3.64-3.63.34.34 0 0 0-.34-.34h-3v-3a.34.34 0 0 0-.34-.34 3.64 3.64 0 0 0 0 7.27zM8 6.28v2.94a.34.34 0 0 0 .34.34h2.94A3 3 0 1 1 8 6.28z"
      />
      <path
        d="M23.12 31.06v-2.75h2.11v2.75M29.52 31.06v-8.02h2.12v8.02"
        fill="none"
        stroke={`url(#${customId}-a)`}
      />
      <path
        fill="none"
        stroke="#93d500"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.73"
        d="M26.32 31.06v-5.43h2.12v5.43"
      />
      <path
        fill="none"
        stroke="#005288"
        d="M24.21 9.24h4.11M24.21 6.7h1.91M24.21 10.51h1.94"
        className="cls-5"
      />
      <path
        fill="none"
        stroke="#93d500"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.73"
        d="M24.21 7.97h2.68"
      />
      <path
        d="M12.29 25H11a.25.25 0 0 0-.19.41l.31.37-2.61 2.27L7.6 28a.29.29 0 0 0-.16 0L5 29.24a.3.3 0 0 0-.14.41.31.31 0 0 0 .28.18.22.22 0 0 0 .13 0l2.42-1.18 1 .07a.34.34 0 0 0 .23-.08l2.66-2.32.39.46a.25.25 0 0 0 .44-.12l.23-1.33a.26.26 0 0 0-.35-.33z"
        fill="#8bc53f"
        className="cls-1"
      />
      <path id="_Group_" d="M11.76 30.7H5.1v-6.65" fill="none" stroke="#005288" />
      <path id="_Group_2" d="M30.5 11.92h-6.66V5.26" fill="none" stroke="#005288" />
      <rect
        width="15.81"
        height="15.81"
        x="0.56"
        y="0.56"
        fill="none"
        stroke={`url(#${customId}-a)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.13"
        rx="2.59"
        ry="2.59"
      />
      <rect
        width="15.81"
        height="15.81"
        x="19.76"
        y="0.56"
        fill="none"
        stroke={`url(#${customId}-b)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.13"
        rx="2.59"
        ry="2.59"
      />
      <rect
        width="15.81"
        height="15.81"
        x="0.56"
        y="19.62"
        fill="none"
        stroke={`url(#${customId}-c)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.13"
        rx="2.59"
        ry="2.59"
      />
      <rect
        width="15.81"
        height="15.81"
        x="19.76"
        y="19.62"
        fill="none"
        stroke={`url(#${customId}-d)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.13"
        rx="2.59"
        ry="2.59"
      />
    </g>
  </SVGIcon>
);

DashboardIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default DashboardIcon;
