import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const fetchServicesTemplatesBegin = createAction(
  "programManagement/FETCH_SERVICES_TEMPLATES_BEGIN"
);
export const fetchServicesTemplatesSuccess = createAction(
  "programManagement/FETCH_SERVICES_TEMPLATES_SUCCESS"
);
export const fetchServicesTemplatesError = createAction(
  "programManagement/FETCH_SERVICES_TEMPLATES_ERROR"
);

export const fetchServicesTemplates = () => async (dispatch, getState) => {
  try {
    dispatch(fetchServicesTemplatesBegin());
    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);
    const query = CustomerManagement.gqlBuilder(`{
            service_suite_templates {
                identifier,
                services {
                    arguments {
                        name,
                        type,
                        value,
                    },
                    className,
                    displayName {${displayNameLanguages}},
                    identifier,
                    isEnabled,
                    isOptional,
                }
            }
        }`);

    const response = await CustomerManagement.POST("/gql", { query });

    const serviceSuiteTemplates = response.payload.data.service_suite_templates;
    dispatch(fetchServicesTemplatesSuccess({ serviceSuiteTemplates }));
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchServicesTemplatesError({ errors: message }));
  }
};
