import { createSelector } from "@reduxjs/toolkit";

import { median } from "~/global/utils";

const getAuditResults = (state) => state.modules.cannedReports.auditResults.items;

export const getFormattedChartData = createSelector([getAuditResults], (auditResults) => {
  const formattedChartData = auditResults.map((item) => {
    const chartData = {
      audits: item.ChartData.Audits,
      failedAuditsPercent: Math.round(item.ChartData.FailedAuditsPercent * 1000) / 10,
      uphPercentMedian: Math.round(item.ChartData.UphPercentMedian * 1000) / 10,
      processesUnique: item.ChartData.ProcessesUnique,
    };

    return { ...item, ChartData: chartData };
  });

  return formattedChartData;
});

export const getAuditResultsByItemId = createSelector([getFormattedChartData], (auditResults) => {
  const auditResultsByItemId = auditResults.reduce((acc, item) => {
    const keys = Object.keys(item);
    const isFacility = keys.includes("Facility");
    const isTeam = keys.includes("Team");

    if (isFacility) {
      acc[item.Facility.faid] = {
        name: item.Facility.name,
        type: "facility",
        chartData: [item.ChartData],
      };
    } else if (isTeam) {
      acc[item.Team.teamid] = {
        name: item.Team.name,
        type: "team",
        chartData: [item.ChartData],
      };
    } else {
      acc[item.Operator.updateData.user_metadata.name] = {
        // TODO: do the acc on the user ID when it'll be available
        name: item.Operator.updateData.user_metadata.name,
        type: "operator",
        chartData: [item.ChartData],
      };
    }

    return acc;
  }, {});

  return auditResultsByItemId;
});

export const getTotalAudits = createSelector([getAuditResults], (auditResults) => {
  const totalAudits = auditResults.reduce((acc, item) => {
    acc += item.ChartData.Audits;
    return acc;
  }, 0);

  return totalAudits;
});

export const getFailedAuditsPercentMedian = createSelector([getAuditResults], (auditResults) => {
  const failedAuditsPercent = auditResults.map((item) => item.ChartData.FailedAuditsPercent * 100);
  const failedAuditsPercentMedian = median(failedAuditsPercent).toFixed(1);

  return failedAuditsPercentMedian;
});

export const getFormattedAuditResults = createSelector(
  [getAuditResultsByItemId, getTotalAudits, getFailedAuditsPercentMedian],
  (auditResultsByItemId, totalAudits, failedAuditsPercentMedian) => {
    const formattedAuditResults = { auditResultsByItemId, totalAudits, failedAuditsPercentMedian };

    return formattedAuditResults;
  }
);

export const getAuditResultsColumns = createSelector([getFormattedChartData], (auditResults) => {
  const keys = Object.keys(auditResults[0] || {});
  const firstColumn = keys.filter((key) => key !== "ChartData");
  const otherColumnsSorted = [
    "uphPercentMedian",
    "audits",
    "failedAuditsPercent",
    "processesUnique",
  ];
  const columns = firstColumn.concat(otherColumnsSorted);

  return columns;
});

export const getAuditResultsTitles = createSelector([getAuditResultsColumns], (columns) => {
  const [item] = columns;
  const titles = {
    [item]: item,
    uphPercentMedian: "Median UPH percent",
    audits: "Total audits performed",
    failedAuditsPercent: "Audits failed (%)",
    processesUnique: "Unique device count",
  };

  return titles;
});
