import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";

import "./UphLineChart.component.scss";

type Props = {
  width?: string;
  height?: string;
  data: {
    time: string;
    uph: number | null;
  }[];
  xAxisDataKey: string;
  lineDataKey: string;
  lineColor?: string;
  averageUph: undefined | number;
};

const UphLineChart = ({
  width = "99%", // this enables Recharts to calculate the width properly when using flex or grid
  height = "99%", // same thing, but for the height
  data,
  xAxisDataKey,
  lineDataKey,
  lineColor = "#5CD9C7",
  averageUph,
}: Props) => (
  <ResponsiveContainer width={width} height={height}>
    <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
      <XAxis dataKey={xAxisDataKey} padding={{ right: 30, left: 30 }} hide />
      <YAxis padding={{ top: 5, bottom: 5 }} hide />
      <CartesianGrid horizontal={false} fill="#f2f2f2" stroke="rgba(151, 151, 151, 0.2)" />
      <Tooltip
        contentStyle={{ border: "none", padding: "5px 10px" }}
        labelStyle={{ display: "none" }}
        itemStyle={{ color: "#6d6d6d" }}
      />
      <ReferenceLine y={averageUph} stroke="#979797" strokeDasharray="12 10" />
      <Line
        type="monotone"
        dataKey={lineDataKey}
        stroke={lineColor}
        strokeWidth="4"
        dot={false}
        activeDot={{ r: 5 }}
        isAnimationActive={false}
      />
    </LineChart>
  </ResponsiveContainer>
);

export default UphLineChart;
