import React, { useMemo } from "react";
import PropTypes from "prop-types";

import GridInspector from "./layouts/GridInspector";
import FreeformInspector from "./layouts/FreeformInspector";
import InspectorSection from "./commons/InspectorSection";

import * as layouts from "~/label-printer/layouts";

const LayoutInspector = (props) => {
  const { layoutName, layoutData } = props;

  const inspector = useMemo(() => {
    switch (layoutName) {
      case layouts.GRID:
        return <GridInspector {...layoutData} />;
      case layouts.FREEFORM:
        return <FreeformInspector {...layoutData} />;
    }
  }, [layoutName, layoutData]);

  return (
    <InspectorSection title="Layout" initialFolded>
      {inspector}
    </InspectorSection>
  );
};

LayoutInspector.defaultProps = {};

LayoutInspector.propTypes = {
  layoutName: PropTypes.string.isRequired,
  layoutData: PropTypes.object.isRequired,
};

export default LayoutInspector;
