import React, { useEffect, useState, useRef } from "react";

import { TextField } from "react-md";
import PropTypes from "prop-types";

import { SectionHeader, AsciButton } from "~/global";
import * as Utils from "~/global/utils";
import { ServiceSuitesList, ServiceSuiteWizard } from "~/programs-management";

const ProgramEditor = (props) => {
  const isEditing = !!props.program.createdOn;

  const [validProgramMessage, setValidProgramMessage] = useState("");

  const timerToClear = useRef(false);

  useEffect(() => {
    clearTimeout(timerToClear.current);
    const { identifier } = props.program;
    if (!isEditing && identifier != null && identifier.length > 0) {
      timerToClear.current = setTimeout(() => {
        const programIdentifier = Utils.toCamel(identifier);
        props.validateProgramIdentifier(programIdentifier);
      }, 500);
    }
    return () => {
      clearTimeout(timerToClear.current);
    };
  }, [props.program.identifier]);

  useEffect(() => {
    if (props.program.identifier != null && props.programDisplayName.en != null) {
      const requiredFilled =
        props.program.identifier.length > 0 && props.programDisplayName.en.value.length > 0;
      const msg = !requiredFilled ? "All fields marked with an asterisk (*) are required." : "";
      setValidProgramMessage(msg);
    }
  }, [props.program.identifier, props.programDisplayName]);

  const onIdentifierBlur = (identifier) => {
    const programIdentifier = Utils.toCamel(identifier);
    props.onChangeIdentifier(programIdentifier);
  };

  const onClickAddServiceSuite = () => {
    props.showDialog({
      width: "825px",
      modal: true,
      noPadding: true,
      noMargin: true,
      content: (close) => (
        <ServiceSuiteWizard
          serviceSuiteTemplates={props.serviceSuiteTemplates}
          serviceSuitesAllPrograms={props.serviceSuitesAllPrograms}
          programName={props.program.displayName.en}
          createFromTemplate={props.createServiceSuiteFromTemplate}
          createFromSuite={props.createFromSuite}
          close={close}
        />
      ),
    });
  };

  return (
    <div className="program-editor form-layout">
      <SectionHeader title={isEditing ? props.program.identifier : "New Programs"} />

      <div className="field-row">
        <div className="field-name">
          Program Identifier *<div className="description">Letters and numbers only</div>
        </div>

        <div className="field-control">
          <TextField
            id="program-identifier"
            lineDirection="left"
            placeholder="Enter program identifier"
            value={props.program.identifier}
            onChange={props.onChangeIdentifier}
            onBlur={() => onIdentifierBlur(props.program.identifier)}
            disabled={isEditing}
            type="text"
            onKeyPress={(e) =>
              !isEditing && !props.saveDisabled && e.key == "Enter"
                ? props.onClickSaveProgram()
                : null
            }
            error={props.identifierErrors != null}
            errorText={`${props.identifierErrors} (intervening spaces removed).`}
          />
        </div>
      </div>

      {Object.keys(props.programDisplayName || {}).map((lang) => {
        const language =
          props.programDisplayName[lang].name != null ? props.programDisplayName[lang].name : "";
        const optionalLanguage = lang != "en" ? "(Optional)" : "*";
        const displayLanguage = `(${language}) ${optionalLanguage}`;

        return (
          <div className="field-row" key={lang}>
            <div className="field-name">Name {displayLanguage}</div>
            <div className="field-control">
              <TextField
                id={`program-display-name-${lang}`}
                placeholder="Enter a display name"
                value={props.programDisplayName[lang].value}
                onChange={(value) => props.onChangeDisplayName(lang, value)}
                type="text"
                onKeyPress={(e) =>
                  !props.saveDisabled && e.key == "Enter" ? props.onClickSaveProgram() : null
                }
                error={props.displayNameErrors[lang]}
                errorText="A valid value is required: Between 2 and 30 characters, alphanumeric, /'#_-+() and spaces are allowed."
              />
            </div>
          </div>
        );
      })}

      <SectionHeader title="Service Suites" className="border-radiusless">
        <AsciButton color="green" disabled={false} onClick={() => onClickAddServiceSuite()}>
          Add Service Suite
        </AsciButton>
      </SectionHeader>

      <ServiceSuitesList
        serviceSuites={props.serviceSuites}
        onClickEdit={(serviceSuite) => props.onClickEdit(serviceSuite)}
        onClickDelete={(serviceSuiteIdentifier) => props.onClickDelete(serviceSuiteIdentifier)}
        onClickDuplicate={(serviceSuite) => props.createFromSuite(serviceSuite)}
        showDialog={props.showDialog}
        onClickAdd={onClickAddServiceSuite}
        addDisabled={false}
      />

      <div className="cc-details-panel row to-end">
        {validProgramMessage.length > 0 ? (
          <div className="info-message column centered">{validProgramMessage}</div>
        ) : null}
        <AsciButton color="white" onClick={props.onCancel}>
          {props.programDidChange ? "Cancel" : "Exit"}
        </AsciButton>
        <AsciButton color="green" onClick={props.onClickSaveProgram} disabled={props.saveDisabled}>
          {props.alreadySaved || props.saveDisabled ? "Saved" : "Save Program"}
        </AsciButton>
      </div>
    </div>
  );
};

ProgramEditor.propTypes = {
  hideDialog: PropTypes.func,
  onCancel: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func,
  onClickSaveProgram: PropTypes.func,
  showDialog: PropTypes.func,
  onChangeIdentifier: PropTypes.func.isRequired,
  onChangeDisplayName: PropTypes.func.isRequired,
  createFromSuite: PropTypes.func.isRequired,
  validateProgramIdentifier: PropTypes.func.isRequired,
  createServiceSuiteFromTemplate: PropTypes.func.isRequired,

  identifierErrors: PropTypes.string,
  displayNameErrors: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  programDisplayName: PropTypes.object.isRequired,
  saveDisabled: PropTypes.bool,
  alreadySaved: PropTypes.bool,
  programDidChange: PropTypes.bool,
  serviceSuites: PropTypes.array.isRequired,
  serviceSuiteTemplates: PropTypes.array,
  serviceSuitesAllPrograms: PropTypes.array,
};

ProgramEditor.defaultProps = {
  hideDialog: () => {},
  onCancel: () => {},
  onClickEdit: () => {},
  onClickSaveProgram: () => {},
  showDialog: () => {},
  onClickDelete: () => {},
  saveDisabled: true,
  alreadySaved: false,
  programDidChange: false,
  identifierErrors: "",
  serviceSuiteTemplates: [],
  serviceSuitesAllPrograms: [],
};

export default ProgramEditor;
