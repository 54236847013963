import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "../actions";

const initialState = {
  original: {},
  modifications: {},
  errors: null,
  isLoading: false,
  isInitialized: false,
};

export default createReducer(initialState, {
  [moduleActions.deployment.setCurrentDeployment]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.deployment;
  },
  [moduleActions.deployment.fetchDeploymentSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.deployment;
  },

  [moduleActions.deployment.initDeploymentSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.deployment;
  },

  [moduleActions.deployment.changeDeployment]: (state, action) => {
    state.isLoading = false;
    state.modifications = action.payload.deployment;
  },

  [moduleActions.deployment.resetDeploymentModifications]: (state, action) => {
    state.modifications = {};
  },

  [moduleActions.deployment.addDeploymentBegin]: (state, action) => {
    state.isLoading = true;
  },

  [moduleActions.deployment.addDeploymentSuccess]: (state, action) => {
    state.original = { ...state.original, ...state.modifications };
    state.modifications = {};
    state.isInitialized = true;
    state.isLoading = false;
  },

  [moduleActions.deployment.addDeploymentError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },

  [moduleActions.deployment.saveDeploymentBegin]: (state, action) => {
    state.isLoading = true;
  },

  [moduleActions.deployment.saveDeploymentSuccess]: (state, action) => {
    state.original = { ...state.original, ...state.modifications };
    state.modifications = {};
    state.isInitialized = true;
    state.isLoading = false;
  },

  [moduleActions.deployment.saveDeploymentError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
