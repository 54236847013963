import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, message, Upload } from "antd";
import { useNavigate } from "react-router-dom-v5-compat";
import { ImportOutlined } from "@ant-design/icons";

import {
  SectionHeader,
  AsciSpinner,
  AsciButton,
  DialogMessage,
  DialogConfirmation,
  Page,
  InputSearch,
} from "~/global";
import { ProgramsList } from "~/programs-management";
import { importProgram } from "~/pages/programs/model/programsSlice";

import actions from "~/actions";
import moduleActions from "~/programs-management/actions";
import selectors from "~/selectors";
import { useAppDispatch, useAppSelector } from "~/hooks";

import "./ProgramsPage.component.scss";

const ProgramsPage = ({
  clearErrors,
  duplicateProgram,
  exportProgram,
  deleteProgram,
  errors,
  fetchProgramsPreview,
  isLoadingPrograms,
  isLoadingProgram,
  programs,
  showDialog,
  programError,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [programsList, setProgramsList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  const { importStatus } = useAppSelector((state) => state.programsReducer);

  useEffect(() => {
    fetchProgramsPreview();
  }, []);

  useEffect(() => {
    setProgramsList(programs);
  }, [programs]);

  useEffect(() => {
    if (programs.length > 0) {
      filterProgramsList();
    }
  }, [programs, nameFilter]);

  useEffect(() => {
    if (errors != null && errors.length > 0) {
      messageError("Request didn't complete successfully");
      displayErrorMessage(errors);
    }
    if (programError != null && programError.length > 0) {
      messageError("Request didn't complete successfully");
      displayErrorMessage(programError);
    }
  }, [errors, programError]);

  const getMessageTimeout = (text) => {
    const textLength = typeof text === "string" ? text.length : 0;
    // 0.25s per word, 5char per word + 3s reaction, minimum 3
    return Math.max(3, textLength * 0.05 + 3);
  };

  const messageSuccess = (text) => {
    message.destroy();
    message.success(text, getMessageTimeout(text));
  };

  const messageError = (text) => {
    message.destroy();
    message.error(text, getMessageTimeout(text));
  };

  const messageLoading = async () => {
    await message.loading("Action in progress..", 10);

    const messageLonger = "Request is taking longer than expected";
    message.warning(messageLonger, getMessageTimeout(messageLonger));
  };

  const filterProgramsList = () => {
    if (nameFilter.length > 0) {
      const newFacilitiesList = programs.filter((program) => {
        const programName = (program.displayName.en || "").toLowerCase();
        const programIdentifier = (program.identifier || "").toLowerCase();
        const name = nameFilter.toLowerCase();

        return programName.includes(name) || programIdentifier.includes(name);
      });
      setProgramsList(newFacilitiesList);
    } else {
      setProgramsList(programs);
    }
  };

  const displayErrorMessage = (errorMessage) => {
    showDialog({
      title: "Error",
      width: "450px",
      content: (close) => (
        <DialogMessage
          close={() => {
            close();
            clearErrors();
          }}
        >
          <div>{errorMessage}</div>
        </DialogMessage>
      ),
    });
  };

  const onAddProgram = () => {
    navigate("/programs/new");
  };

  const onProgramRowClicked = (program) => {
    navigate(`/programs/${program.identifier}`);
  };

  const onProgramDuplicated = async (identifier) => {
    messageLoading();
    const response = await duplicateProgram(identifier);
    if (response) {
      messageSuccess(`Program created successfully`);
      fetchProgramsPreview();
    }
  };

  const onProgramExported = async (identifier) => {
    messageLoading();
    try {
      const message = await exportProgram(identifier);
      messageSuccess(message ?? "Program exported successfully");
    } catch (e) {
      const errMessage = typeof e === "string" ? e : "Error while exporting program";
      messageError(errMessage);
    }
  };

  const onProgramDeleted = (program) => {
    showDialog({
      title: (
        <div className="row centered spaced text-color--error">
          <i
            className={
              program.usedByDeployments.length > 0
                ? "icon-exclamation-triangle horizontal-label-margin"
                : ""
            }
          />
          Delete Program
        </div>
      ),
      width: "450px",
      modal: true,

      content: (close) => (
        <DialogConfirmation
          onCancel={() => {
            close();
          }}
          onConfirm={async () => {
            if (program.usedByDeployments.length > 0) {
              close();
            } else {
              close();
              message.loading("Action in progress...", isLoadingPrograms ? 0 : -1);
              const success = await deleteProgram(program);
              success ? messageSuccess("Program deleted successfully") : null;
            }
          }}
          textButtonConfirm={program.usedByDeployments.length > 0 ? "OK" : "Delete"}
        >
          <p className="align-center">
            {program.usedByDeployments.length > 0
              ? "Can't delete a program used by one or more deployments. Please first detach this from all deployments."
              : "Are you sure you want to delete this program?"}
          </p>
        </DialogConfirmation>
      ),
    });
  };

  return (
    <Page className="programs-page">
      <Upload
        name="json-file"
        accept=".json"
        beforeUpload={(file) => {
          const jsonFileExtension = /\.json$/.test(file.name);
          const isJson = jsonFileExtension && file.type === "application/json";
          const isLt10M = file.size / 1024 / 1024 < 10;

          if (!isJson) {
            void message.error(`${file.name} is not a JSON file`);
          }

          if (!isLt10M) {
            void message.error("File must be smaller than 10 MB");
          }

          return (isJson && isLt10M) || Upload.LIST_IGNORE;
        }}
        customRequest={({ file, onSuccess, onError }) => {
          const formData = new FormData();

          formData.append("file", file);

          dispatch(importProgram(formData))
            .unwrap()
            .then((response) => {
              onSuccess?.(response);
              fetchProgramsPreview();
              void message.success(response.message);
            })
            .catch((err) => {
              if (err instanceof Error) {
                onError?.(err);
              } else if (typeof err === "string") {
                onError?.(new Error(err));
                void message.error(err);
              }
            });
        }}
        showUploadList={false}
        maxCount={1}
      >
        <Button
          type="primary"
          icon={<ImportOutlined />}
          size="large"
          loading={importStatus === "loading"}
          style={{ marginBottom: "1em" }}
        >
          Import a program
        </Button>
      </Upload>

      <div className="programs-table">
        <SectionHeader title="Programs">
          <div className="push-right row centered">
            <InputSearch
              placeholder="Search Program"
              onChange={(name) => setNameFilter(name)}
              initialValue={nameFilter}
              trim
            />
            <AsciButton color="green" onClick={onAddProgram}>
              Add Program
            </AsciButton>
          </div>
        </SectionHeader>
        <ProgramsList
          programs={programsList}
          onRowClicked={onProgramRowClicked}
          onDelete={onProgramDeleted}
          onDuplicate={onProgramDuplicated}
          onDownload={onProgramExported}
          disableDuplicate={isLoadingProgram}
        />
        <AsciSpinner visible={isLoadingPrograms} />
      </div>
    </Page>
  );
};

const stateToProps = (state) => ({
  errors: state.programs.errors,
  isLoadingPrograms: state.programs.isLoading,
  isLoadingProgram: state.modules.programManagement.programEditor.isLoading,
  programError: state.modules.programManagement.programEditor.errors,
  programs: selectors.programs.getFlatPrograms(state),
});

const dispatchToProps = {
  clearProgramEdition: moduleActions.programEditor.clearProgramEdition,
  clearErrors: actions.programs.clearErrors,
  duplicateProgram: moduleActions.programEditor.duplicateProgram,
  exportProgram: moduleActions.programEditor.exportProgram,
  deleteProgram: actions.programs.deleteProgram,
  fetchProgramsPreview: actions.programs.fetchProgramsPreview,
  hideDialog: actions.dialog.hide,
  setServiceSuite: moduleActions.serviceSuiteEditor.setServiceSuite,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(ProgramsPage);
