import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep, isEmpty } from "lodash";
import moduleActions from "~/actions";
import action from "~/customer-management/actions";

const initialState = {
  errors: null,
  isLoading: false,
  original: [],
  modifications: [],
};

export default createReducer(initialState, {
  [moduleActions.audits.fetchAuditsBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.audits.fetchAuditsSuccess]: (state, action) => {
    state.original = action.payload;
    state.isLoading = false;
    state.errors = null;
  },
  [moduleActions.audits.fetchAuditsError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
  },
  [moduleActions.audits.saveAuditsBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.audits.saveAuditsSuccess]: (state) => {
    state.original = state.modifications;
    state.modifications = [];
    state.isLoading = false;
    state.errors = null;
  },
  [moduleActions.audits.saveAuditsError]: (state, action) => {
    state.modifications = [];
    state.isLoading = false;
    state.errors = action.payload.error;
  },
  [moduleActions.audits.updateAudits]: (state, action) => {
    const auditCopy = isEmpty(state.modifications)
      ? cloneDeep(state.original)
      : state.modifications;
    const foundAudit = auditCopy.find((audit) => audit.name === action.payload.auditName);
    foundAudit.auditrate = action.payload.number;
    state.modifications = auditCopy;
  },
  [action.customerEditor.resetCustomerEdition]: (state) => {
    state.errors = null;
    state.isLoading = false;
    state.original = [];
    state.modifications = [];
  },
  [moduleActions.audits.resetModifications]: (state) => {
    state.modifications = [];
  },
});
