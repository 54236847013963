import { createSelector } from "@reduxjs/toolkit";

export const getPlatforms = (state) => state.platforms.items;
export const getSelectedPlatform = (state) => state.platforms.selected;
export const getInitiallySelectedPlatformValue = (state) => state.platforms.initiallySelectedValue;

export const getPlatformsByValue = createSelector(getPlatforms, (platforms) =>
  platforms.reduce(
    (acc, platform) => ({
      ...acc,
      [platform.value]: platform,
    }),
    {}
  )
);

export const getInitiallySelectedPlatform = createSelector(
  [getPlatforms, getInitiallySelectedPlatformValue],
  (platforms, platformValue) =>
    platforms.filter((platform) => platform.value === platformValue)[0] || platforms[0]
);
