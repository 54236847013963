import { createSelector } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";

const getSystemFields = (state) => state.modules.programManagement.systemFields.items;

export const getMinimumIdentifiers = createSelector([getSystemFields], (systemFields) =>
  systemFields.filter((systemField) => systemField.canBeMinimumIdentifier)
);

export const getRequiredIdentifiers = createSelector([getSystemFields], (systemFields) =>
  systemFields.filter((systemField) => systemField.canBeRequiredIdentifier)
);
