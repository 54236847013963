import { createSelector } from "@reduxjs/toolkit";
import { find, keyBy, sortBy } from "lodash";

// fail code categories
const getFailCodeCategories = (state) => state.failCodeCategories.items;
const getFailCodeCategoriesFilterNames = (state) => state.failCodeCategories.nameFilter;
const getFailCodeCategoryOriginal = (state) => state.failCodeCategory.original;
const getFailCodeCategoryModifications = (state) => state.failCodeCategory.modifications;
const getFailCodeCategoriesSelectedIds = (state) => state.failCodeCategories.itemsCheckedIds;

// fail codes
const getFailCodeOriginal = (state) => state.failCode.original;
const getFailCodeModifications = (state) => state.failCode.modifications;
const getFailCodes = (state) => state.failCodes.items;
const getFailCodesSelectedIds = (state) => state.failCodes.itemsCheckedIds;

// Fail Code Priority Groups

const getFailCodePriorityGroups = (state) => state.failCodePriorityGroups.items;
export const getFailCodePriorityGroupPGID = (state) => state.failCodePriorityGroup.selectedPgid;

export const getDefaultPriorityGroup = createSelector(
  [getFailCodePriorityGroups],
  (priorityGroups) => find(priorityGroups, (priorityGroup) => priorityGroup.isDefault)
);

export const getFailCodePriorityGroup = createSelector(
  [getFailCodePriorityGroups, getFailCodePriorityGroupPGID, getDefaultPriorityGroup],
  (failCodePriorityGroups = {}, failCodePriorityGroupPGID = null, defaultPriorityGroup) =>
    failCodePriorityGroups[failCodePriorityGroupPGID] ?? defaultPriorityGroup
);

export const getExistingFailCodePriorityGroupNames = createSelector(
  [getFailCodePriorityGroups],
  (failCodePriorityGroups = []) => {
    const languages = ["en", "fr", "es"];
    const failCodePriorityGroupNames = {};

    languages.forEach((language) => {
      failCodePriorityGroupNames[language] = Object.values(failCodePriorityGroups)
        .map((failCodePriorityGroup) =>
          (failCodePriorityGroup.name[language] || "").toLowerCase().trim()
        )
        .filter((failCodePriorityGroupName) => failCodePriorityGroupName != "");
    });

    return failCodePriorityGroupNames;
  }
);

export const getNewFailCodePriorityGroup = createSelector(
  [getFailCodes, getFailCodeCategories],
  (failCodes, failCodeCategories) => ({
    name: { en: "", es: "", fr: "" },
    failCodeCategories: failCodeCategories.map((category) => ({
      fccid: category.id,
      visible: true,
      failCodes: failCodes
        .filter((failCode) => failCode.categoryId === category.id)
        .map((failCode) => ({
          fcid: failCode.id,
          visible: true,
        })),
    })),
  })
);

// Fail Codes

export const getFailCodesInEnglish = createSelector(getFailCodes, (failCodes = []) =>
  failCodes.map((failCode) => ({
    ...failCode,
    defaultDescription: failCode.description.en,
  }))
);

export const getFailCodesById = createSelector(getFailCodesInEnglish, (failCodes = []) =>
  failCodes.reduce(
    (acc, failCode) => ({
      ...acc,
      [failCode.id]: failCode,
    }),
    {}
  )
);

export const getSelectedCategoryFailCodes = createSelector(
  [getFailCodeCategoryOriginal, getFailCodesInEnglish],
  (selectedCategory, failCodes) =>
    failCodes.filter((failCode) => failCode.categoryId === selectedCategory.id)
);

export const getUpdatedFailCode = createSelector(
  [getFailCodeOriginal, getFailCodeModifications],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getExistingFailCodeNames = createSelector(
  [getFailCodesInEnglish, getFailCodeOriginal],
  (failCode, original) => {
    const failCodeNames = (failCode || [])
      .map((failCode) => (failCode.name || "").toLowerCase())
      .filter((failCodeName) => failCodeName != original.name);
    return failCodeNames;
  }
);

export const getCurrentFailCodes = createSelector(
  [getFailCodesInEnglish, getFailCodeCategoryOriginal],
  (failCodes, original) => {
    const currentFailCodes = failCodes.filter((failCode) => failCode.categoryId == original.id);
    return currentFailCodes;
  }
);

export const getSortedFailCodes = createSelector(getCurrentFailCodes, (currentFailCodes) =>
  sortBy(currentFailCodes, [(i) => i.name.toLowerCase()])
);

export const getSelectedCategoryCheckedFailCodesIds = createSelector(
  [getSelectedCategoryFailCodes, getFailCodesSelectedIds],
  (displayedFailCodes, allCheckedIds) =>
    displayedFailCodes.map((fc) => fc.id).filter((id) => allCheckedIds.includes(id))
);

// Categories

export const getFailCodeCategoriesInEnglish = createSelector(
  getFailCodeCategories,
  (failCodeCategories = []) =>
    failCodeCategories.map((failCodeCategory) => ({
      ...failCodeCategory,
      defaultName: failCodeCategory.name.en,
    }))
);

export const getFailCodesCategoriesById = createSelector(getFailCodeCategories, (categories = []) =>
  categories.reduce(
    (acc, category) => ({
      ...acc,
      [category.id]: category,
    }),
    {}
  )
);

export const getSortedFailCodeCategories = createSelector(
  [getFailCodeCategoriesInEnglish, getFailCodePriorityGroup],
  (categories, priorityGroup) => {
    const categoriesById = keyBy(categories, (category) => category.id);
    // sometimes a failCodeCategory ID exists in the priorityGroup but hasn't been yet loaded in the failCodeCategories.
    return (
      priorityGroup?.failCodeCategories.reduce(
        (acc, { fccid }) => (categoriesById[fccid] ? [...acc, categoriesById[fccid]] : acc),
        []
      ) ?? categories
    );
  }
);

export const getFilteredFailCodeCategories = createSelector(
  [getSortedFailCodeCategories, getFailCodeCategoriesFilterNames],
  (sortedFailCodeCategories, textFilter) => {
    const nameFilter = textFilter.toLowerCase();
    if (nameFilter.length > 0) {
      return sortedFailCodeCategories.filter(
        (sortedFailCodeCategory) =>
          sortedFailCodeCategory.defaultName.toLowerCase().indexOf(nameFilter) > -1
      );
    }
    return sortedFailCodeCategories;
  }
);

export const getUpdatedFailCodeCategory = createSelector(
  [getFailCodeCategoryOriginal, getFailCodeCategoryModifications],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getExistingFailCodeCategoryNames = createSelector(
  [getFailCodeCategoriesInEnglish, getFailCodeCategoryOriginal],
  (failCodeCategories = [], original) => {
    const languages = ["en", "fr", "es"];
    const failCodeCategoryNames = {};

    languages.forEach((language) => {
      failCodeCategoryNames[language] = failCodeCategories
        .map((failCodeCategory) => (failCodeCategory.name[language] || "").toLowerCase())
        .filter(
          (failCodeCategoryName) =>
            failCodeCategoryName != (original.name[language] || "").toLowerCase() &&
            failCodeCategoryName != ""
        );
    });

    return failCodeCategoryNames;
  }
);

export const getExistingFailCodeCategoryPriorities = createSelector(
  [getFailCodeCategoriesInEnglish, getFailCodeCategoryOriginal],
  (failCodeCategories, original) => {
    const failCodeCategoryPriorities = (failCodeCategories || [])
      .map((failCodeCategory) => failCodeCategory.priority)
      .filter((failCodeCategoryPriority) => failCodeCategoryPriority != original.priority);
    return failCodeCategoryPriorities;
  }
);

export const getFailCodeCategoryFailFast = createSelector(
  getFailCodeCategoryOriginal,
  (original) => original.failFast
);

export const getFailCodesAfterDeleteCategories = createSelector(
  [getFailCodeCategoriesSelectedIds, getFailCodesInEnglish],
  (failCodeCategoryIdsChecked, failCodes) => {
    const updatedFailCodes = failCodes.filter(
      (failCode) => failCodeCategoryIdsChecked.indexOf(failCode.categoryId) == -1
    );
    return updatedFailCodes;
  }
);
