import { createReducer } from "@reduxjs/toolkit";

// Sizes are in millimeters for precisions
import labelActions from "~/label-printer/actions";

const initialState = {
  printers: [],
  formats: [],
};

export default createReducer(initialState, {
  [labelActions.labelStatics.fetchLabelStaticsSuccess]: (state, action) => {
    const { printers, formats } = action.payload;
    state.printers = printers;
    state.formats = formats;
  },
});
