import produce from "immer";

import {
  SELECT_FAIL_CODE_CATEGORY,
  SET_FAIL_CODE_CATEGORY_FAIL_FAST,
  SET_FAIL_CODE_CATEGORY_NAME,
  UPDATE_FAIL_CODE_CATEGORY_BEGIN,
  UPDATE_FAIL_CODE_CATEGORY_ERROR,
  UPDATE_FAIL_CODE_CATEGORY_SUCCESS,
  VALIDATE_FAIL_CODE_CATEGORY_NAME,
  RESET_FAIL_CODE_CATEGORY,
} from "~/actions/failCodes/failCodeCategory.actions";

import { DELETE_FAIL_CODE_CATEGORIES_SUCCESS } from "~/actions/failCodes/failCodeCategories.actions";

const initialState = {
  original: {},
  modifications: {},
  errors: {
    name: {
      en: "",
    },
    message: [],
    title: "",
  },
};

export default function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SELECT_FAIL_CODE_CATEGORY: {
        draft.original = action.payload.failCodeCategory;
        draft.modifications = {};
        draft.errors = state.errors;
        return;
      }

      case SET_FAIL_CODE_CATEGORY_NAME: {
        draft.modifications.name = { ...draft.modifications.name, ...action.payload.name };
        return;
      }

      case SET_FAIL_CODE_CATEGORY_FAIL_FAST: {
        draft.modifications.failFast = action.payload.failFast;
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_ERROR: {
        draft.errors.message = action.payload.errors.message || [];
        draft.errors.title = action.payload.errors.title || "";
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_BEGIN: {
        draft.errors = initialState.errors;
        return;
      }

      case UPDATE_FAIL_CODE_CATEGORY_SUCCESS: {
        draft.original = action.payload.failCodeCategory;
        draft.modifications = {};
        return;
      }

      case RESET_FAIL_CODE_CATEGORY: {
        draft.modifications = {};
        draft.errors = initialState.errors;
        return;
      }

      case VALIDATE_FAIL_CODE_CATEGORY_NAME: {
        draft.errors.name = Object.assign(draft.errors.name, action.payload.error.name);
      }

      // case DELETE_FAIL_CODE_CATEGORIES_SUCCESS: {
      //     draft.original = {};
      //     return;
      // }
    }
  });
}
