import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import concat from "lodash/concat";
import PropTypes from "prop-types";

import { Button } from "antd";
import { FontIcon } from "react-md";
import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";
import DetailsField from "~/global/reduxer/components/details/DetailsField";
import TextField from "~/global/TextField/TextField.component";

const RunApplicationBehavior = (props) => {
  const { behaviorId, eventId } = props;
  const dispatch = useDispatch();

  const eventActions = workflowActions.eventEditor[eventId];
  const eventSelectors = workflowSelectors.eventEditor[eventId];

  const behavior = useSelector(eventSelectors.getBehaviorById(behaviorId));
  const dataSetter = (key) => (value) =>
    dispatch(eventActions.setBehaviorData({ behaviorId, key, value }));
  const [nextParamId, setNextParamId] = useState(0);

  const onAddParameter = () => {
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "parameters",
        value: concat(behavior.behaviorData.parameters, [
          { key: null, value: null, id: nextParamId },
        ]),
      })
    );
    setNextParamId(nextParamId + 1);
  };
  const parameterSetter = (field, paramId) => (value) =>
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "parameters",
        value: behavior.behaviorData.parameters.map((h) => {
          if (h.id === paramId) {
            return { ...h, [field]: value };
          }
          return h;
        }),
      })
    );

  const onRemoveParameter = (parameter) => () => {
    const filtered = behavior.behaviorData.parameters.filter((p) => p.id !== parameter.id);
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "parameters",
        value: filtered,
      })
    );
  };

  return (
    <>
      <DetailsField
        label="Path"
        value={<TextField value={behavior.behaviorData.path} onChange={dataSetter("path")} />}
      />
      <DetailsField
        label={
          <div>
            Parameters Mapping <Button onClick={onAddParameter}>+</Button>
          </div>
        }
        value={
          <div style={{ width: "100%" }}>
            {behavior.behaviorData.parameters.map((parameter) => (
              <div
                key={`${eventId}-${behaviorId}-${parameter.id}`}
                style={{ display: "flex", justifyContent: "stretch" }}
              >
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    onChange={parameterSetter("key", parameter.id)}
                    value={parameter.key}
                    placeholder="Key"
                    fullWidth
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    onChange={parameterSetter("value", parameter.id)}
                    value={parameter.value}
                    placeholder="Value"
                    fullWidth
                  />
                </div>
                <FontIcon
                  onClick={onRemoveParameter(parameter)}
                  iconClassName="icon-trash"
                  className="remove-condition"
                />
              </div>
            ))}
          </div>
        }
      />
      <DetailsField
        label="cwd"
        value={<TextField onChange={dataSetter("cwd")} value={behavior.behaviorData.cwd} />}
      />
      <DetailsField
        label="Encoding"
        value={
          <TextField onChange={dataSetter("encoding")} value={behavior.behaviorData.encoding} />
        }
      />
      <DetailsField
        label="Shell"
        value={<TextField onChange={dataSetter("shell")} value={behavior.behaviorData.shell} />}
      />
      <DetailsField
        label="Timeout"
        value={
          <TextField
            onChange={dataSetter("encoding")}
            value={behavior.behaviorData.timeout}
            type="number"
          />
        }
      />
    </>
  );
};

RunApplicationBehavior.propTypes = {
  behaviorId: PropTypes.number,
  eventId: PropTypes.string,
};

export default RunApplicationBehavior;
