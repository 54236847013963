import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontIcon } from "react-md";

import { TestDefinitionIcon } from "~/global";

import "./TestsList.component.scss";

const TestsList = ({ className, tests, selectedTest, onSelect }) => {
  const onSelectTest = (test) => {
    if (onSelect) {
      onSelect(test);
    }
  };

  return (
    <div className={classnames("tests-list", className)}>
      {tests.map((test, index) => (
        <div
          key={index}
          className={classnames("layout-row", {
            selected: selectedTest.className === test.className,
          })}
          onClick={() => onSelectTest(test)}
        >
          <div className="image layout-column layout-column--center-center flex--noshrink">
            <TestDefinitionIcon src={test.image} size="40" />
          </div>

          <div className="name flex layout-row layout-row--start-center">{test.displayName}</div>

          <div className="arrow layout-column layout-column--center-center flex--noshrink">
            <FontIcon iconClassName="icon-chevron-right" />
          </div>
        </div>
      ))}
    </div>
  );
};

TestsList.propTypes = {
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  selectedTest: PropTypes.shape({ className: PropTypes.string }),
  onSelect: PropTypes.func,
};

TestsList.defaultProps = {
  tests: [],
  selectedTest: {},
};

export default TestsList;
