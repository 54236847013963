import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import concat from "lodash/concat";
import { FontIcon } from "react-md";
import { Select } from "antd";
import selectors from "~/selectors";

import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";
import TextField from "~/global/TextField/TextField.component";
import DetailsField from "~/global/reduxer/components/details/DetailsField";

import LabelStatics from "~/label-printer/components/LabelStatics";

const SetCustomFieldsBehavior = (props) => {
  const { behaviorId, eventId } = props;
  const dispatch = useDispatch();

  const eventActions = workflowActions.eventEditor[eventId];
  const eventSelectors = workflowSelectors.eventEditor[eventId];

  const behavior = useSelector(eventSelectors.getBehaviorById(behaviorId));

  const customFields = useSelector(selectors.customFields.getFlatCustomFields);
  const [nextId, setNextId] = useState(0);

  const onAddCustomField = () => {
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "fields",
        value: concat(behavior.behaviorData.fields, [{ key: null, value: null, id: nextId }]),
      })
    );
    setNextId(nextId + 1);
  };
  const fieldSetter = (field, fieldId) => (value) =>
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "fields",
        value: behavior.behaviorData.fields.map((f) => {
          if (f.id === fieldId) {
            return { ...f, [field]: value };
          }
          return f;
        }),
      })
    );

  const removeCustomField = (field) => () => {
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "fields",
        value: behavior.behaviorData.fields.filter((f) => f.id !== field.id),
      })
    );
  };

  return (
    <LabelStatics>
      <DetailsField
        label={
          <div>
            Custom Fields{" "}
            <FontIcon
              onClick={onAddCustomField}
              iconClassName="icon-plus-circle"
              className="add-icon-btn"
            />
          </div>
        }
        value={
          <div style={{ width: "100%" }}>
            {behavior.behaviorData.fields.map((field) => (
              <div
                key={`${eventId}-${behaviorId}-${field.id}`}
                style={{ display: "flex", justifyContent: "stretch" }}
              >
                <div style={{ width: "45%" }}>
                  <Select
                    onChange={fieldSetter("key", field.id)}
                    value={field.key}
                    style={{ width: "100%" }}
                  >
                    {customFields.map((cf) => (
                      <Select.Option key={cf.name} value={cf.name}>
                        {cf.displayName.en}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div style={{ width: "45%" }}>
                  <TextField
                    onChange={fieldSetter("value", field.id)}
                    value={field.value}
                    placeholder="Value"
                    fullWidth
                  />
                </div>
                <FontIcon
                  onClick={removeCustomField(field)}
                  iconClassName="icon-trash"
                  className="remove-condition"
                />
              </div>
            ))}
          </div>
        }
        labelProps={{ style: { alignSelf: "flex-start" } }}
      />
    </LabelStatics>
  );
};

SetCustomFieldsBehavior.propTypes = {
  behaviorId: PropTypes.number,
  eventId: PropTypes.string,
};

export default SetCustomFieldsBehavior;
