import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "react-md";

import uuid from "uuid/v4";
import { message } from "antd";
import { Row, Column, AsciButton, Checkbox } from "~/global";

import moduleActions from "~/service-suite-templates/actions/";

import "./AddNewServiceDialog.component.scss";

const AddNewServiceDialog = ({ template, close, saveEditedTemplate }) => {
  const [enabledCheckbox, setEnabledCheckbox] = useState(false);
  const [optionalCheckbox, setOptionalCheckbox] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [className, setClassName] = useState("");

  const addNewService = () => {
    const services = [
      ...template.services,
      {
        id: uuid(),
        identifier: className,
        className,
        displayName: {
          en: displayName,
        },
        isOptional: optionalCheckbox,
        isEnabled: enabledCheckbox,
        arguments: [],
      },
    ];

    saveEditedTemplate({ ...template, services });
    message.success(`New service "${displayName}" added to the template`);
    close();
  };

  return (
    <div className="new-service-dialog">
      <Row className="full-width">
        <Column className="full-width">
          <Row className="className">
            <div className="className-label">className:</div>
            <TextField
              id="className-text-field"
              lineDirection="left"
              placeholder="Enter className"
              value={className}
              className="className-field"
              onChange={(value) => setClassName(value)}
              type="text"
            />
          </Row>
          <Row className="displayName">
            <div className="displayName-label">displayName:</div>
            <TextField
              id="displayName-text-field"
              lineDirection="left"
              placeholder="Enter displayName"
              value={displayName}
              className="displayName-field"
              onChange={(value) => setDisplayName(value)}
              type="text"
            />
          </Row>

          <Row className="is-enabled">
            <div className="is-enabled-label">isEnabled:</div>
            <Checkbox
              id="checkbox-isEnabled"
              isChecked={enabledCheckbox}
              onChange={(value) => setEnabledCheckbox(value)}
            />
          </Row>
          <Row className="is-optional">
            <div className="is-optional-label">isOptional:</div>
            <Checkbox
              id="checkbox-isOptional"
              isChecked={optionalCheckbox}
              onChange={(value) => setOptionalCheckbox(value)}
            />
          </Row>

          <Row className="btn-group">
            <AsciButton className="cancel-btn" color="white" onClick={() => close()}>
              Cancel
            </AsciButton>
            <AsciButton
              className="confirm-btn"
              color="green"
              onClick={() => addNewService()}
              disabled={displayName === "" || className === ""}
            >
              Add
            </AsciButton>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

AddNewServiceDialog.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,

  // From the store
  saveEditedTemplate: PropTypes.func.isRequired,
};

const dispatchToProps = {
  saveEditedTemplate: moduleActions.serviceSuiteTemplates.saveEditedTemplate,
};

export default connect(null, dispatchToProps)(AddNewServiceDialog);
