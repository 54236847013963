import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/user-logs/actions";

const initialState = {
  items: [],
  error: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [moduleActions.userActivityData.fetchLogsBegin]: (state, action) => {
    state.error = null;
    state.isLoading = true;
  },

  [moduleActions.userActivityData.fetchLogsSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.userLogs || [];
  },

  [moduleActions.userActivityData.fetchLogsError]: (state, action) => {
    state.error = action.payload.error;
    state.items = [];
    state.isLoading = false;
  },

  [moduleActions.userActivityData.onClearLogs]: (state, action) => {
    state.items = [];
    state.isLoading = false;
  },
});
