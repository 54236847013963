import React from "react";
import join from "lodash/join";
import includes from "lodash/includes";

import PropTypes from "prop-types";

import "./GroupedButtons.scss";

const OptionButton = (props) => {
  const { children, onClick, selected, ...rest } = props;
  const css = ["option-button"];
  if (selected) {
    css.push("selected");
  }
  return (
    <div className={join(css, " ")} onClick={onClick} {...rest}>
      {children}
    </div>
  );
};

const GroupedButtons = (props) => {
  const { options, selected, onSelectOption } = props;

  return (
    <div style={{ display: "flex" }}>
      <div className="grouped-buttons">
        {options.map(({ value, label, ...rest }) => (
          <OptionButton
            key={value}
            selected={includes(selected, value)}
            onClick={() => onSelectOption(value)}
            {...rest}
          >
            {label}
          </OptionButton>
        ))}
      </div>
    </div>
  );
};

GroupedButtons.defaultProps = {
  selectedColor: "#ffffff",
  selectedBg: "#005287",
};

GroupedButtons.propTypes = {
  options: PropTypes.array,
  selectedColor: PropTypes.string,
  selectedBg: PropTypes.string,

  selected: PropTypes.array,

  onSelectOption: PropTypes.func,
};

export default GroupedButtons;
