import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  items: [],
  sortKey: "",
  sortReverse: false,
  errors: null,
  isLoading: false,
  initialized: false,
  users: [],
  isLoadingUsers: false,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  // Fetch teams
  [moduleActions.customerTeams.fetchCustomerTeamsBegin]: (state, _) => {
    state.isLoading = true;
  },
  [moduleActions.customerTeams.fetchCustomerTeamsSucess]: (state, action) => {
    state.items = action.payload.teams;
    state.isLoading = false;
    state.initialized = true;
  },
  [moduleActions.customerTeams.fetchCustomerTeamsError]: (state, action) => {
    state.items = [];
    state.isLoading = false;
    state.errors = action.payload.message;
    state.initialized = true;
  },

  [moduleActions.customerFacilities.saveFacilitiesSuccess]: (state) => {
    state.initialized = false;
  },
  [moduleActions.customerUsers.saveUserSuccess]: (state) => {
    state.initialized = false;
  },
  [moduleActions.customerTeams.updateCustomerTeams]: (state, action) => {
    state.items = action.payload.teams;
  },
  [moduleActions.customerTeams.updateKey]: (state, action) => {
    state.sortKey = action.payload.key;
  },
  [moduleActions.customerTeams.updateSortReverse]: (state, action) => {
    state.sortReverse = action.payload.sortReverse;
  },

  // Fetch users
  [moduleActions.customerTeams.fetchUsersTeamsBegin]: (state) => {
    state.isLoadingUsers = true;
    state.errors = null;
  },
  [moduleActions.customerTeams.fetchUsersTeamsSuccess]: (state, action) => {
    state.isLoadingUsers = false;
    state.users = action.payload.users;
  },
  [moduleActions.customerTeams.fetchUsersTeamsError]: (state, action) => {
    state.isLoadingUsers = false;
    state.errors = action.payload.message;
  },

  [moduleActions.customerEditor.resetCustomerEdition]: (state, _) => {
    state.items = [];
    state.errors = null;
    state.sortKey = "teamsname";
    state.sortReverse = false;
    state.isLoading = false;
    state.initialized = false;
  },

  [moduleActions.customerTeams.updateTeamsBegin]: (state, action) => {
    state.items = action.payload.teams;
    state.errors = null;
    state.isLoading = true;
  },
  [moduleActions.customerTeams.updateTeamsSuccess]: (state, _) => {
    state.isLoading = false;
  },
  [moduleActions.customerTeams.updateTeamsError]: (state, action) => {
    state.errors = action.payload.message;
    state.isLoading = false;
  },
});
