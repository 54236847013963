import { useCallback, useEffect, useState } from "react";

/**
 * Persist a state in storage
 */
export const usePersistentState: {
  <T>(key: string): [T | undefined, (newState: T) => void, () => void];
  <T>(key: string, initialState: T, storage?: Storage): [T, (newState: T) => void, () => void];
} = <T>(key: string, initialState?: T, storage = localStorage) => {
  const storedState = storage.getItem(key);

  // local flag to indicate that the storage event originates from this hook
  let stateEditedFromHook = false;

  const [state, setState] = useState<T | undefined>(getHydratedState());

  function getHydratedState(): T | undefined {
    let hydratedState: T | undefined;
    if (storedState !== null) {
      hydratedState = JSON.parse(storedState);
    } else {
      hydratedState = initialState;

      if (hydratedState !== undefined) {
        stateEditedFromHook = true;
        storage.setItem(key, JSON.stringify(hydratedState));
      }
    }
    return hydratedState;
  }

  const setAndPersistState = useCallback(
    (newState: T): void => {
      stateEditedFromHook = true;
      if (newState === undefined) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(newState));
      }
      setState(newState);
    },
    [key, storage]
  );

  const resetState = useCallback(() => {
    stateEditedFromHook = true;
    storage.removeItem(key);
    setState(undefined);
  }, [key, storage]);

  const storageHandler = useCallback(
    (storageEvent) => {
      const storageMatches = storageEvent.storageArea === storage;
      // event key is null when all keys are affected
      const keyMatches = storageEvent.key === null || storageEvent.key === key;
      const dataDesync =
        (storageEvent.newValue === null && state !== undefined) ||
        storageEvent.newValue !== JSON.stringify(state);
      const externalDesync = !stateEditedFromHook && storageMatches && keyMatches && dataDesync;

      if (externalDesync) {
        setState(storageEvent.newValue);
      }
    },
    [key, storage, state]
  );

  useEffect(() => {
    window.addEventListener("storage", storageHandler);
    return () => {
      window.removeEventListener("storage", storageHandler);
    };
  }, [storageHandler]);

  return [state, setAndPersistState, resetState];
};
