import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ExpansionList, ExpansionPanel, Button } from "react-md";
import { message } from "antd";
import { Row, Column } from "~/global";

import actions from "~/actions";
import moduleActions from "../../actions";

import ServiceList from "../ServiceList/ServiceList.component";
import DuplicateTemplateDialog from "../DuplicateTemplateDialog/DuplicateTemplateDialog.component";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog.component";
import AffectedProgramsDialog from "../AffectedProgramsDialog/AffectedProgramsDialog.component";

import "./TemplateList.component.scss";

const TemplateList = ({
  fetchAffectedPrograms,
  editedTemplate,
  affectedPrograms,
  fetchTemplates,
  onAddTemplate,
  showDialog,
  templates,
}) => {
  const confirmationDialog = async (editedTemplate, index) => {
    showDialog({
      title: `Please confirm`,
      width: "700px",
      modal: true,
      content: (close) => (
        <ConfirmationDialog
          templateIndex={index}
          template={editedTemplate}
          close={() => {
            refreshTemplateData();
            close();
          }}
        />
      ),
    });
  };

  const affectedProgramsDialog = async (template) => {
    showDialog({
      title: `Changes will affect these programs`,
      width: "700px",
      modal: true,
      content: (close) => (
        <AffectedProgramsDialog
          templateIndex={template.id}
          template={template}
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  const duplicateTemplateDialog = (template) => {
    showDialog({
      title: `Duplicate ${template.identifier}`,
      width: "700px",
      modal: true,
      content: (close) => (
        <DuplicateTemplateDialog
          template={template}
          addNewTemplate={(newTemplate) => addNewTemplate(newTemplate)}
          close={() => {
            close();
          }}
        />
      ),
    });
  };

  // call to the DB action to add a new template
  const addNewTemplate = async (newTemplate) => {
    await onAddTemplate(newTemplate);
    refreshTemplateData();
    message.success(`${newTemplate.identifier} as been successfully added`);
  };

  const refreshTemplateData = async () => {
    await fetchTemplates();
    templates.forEach((template) => {
      fetchAffectedPrograms(template);
    });
  };

  return (
    <div className="template-list">
      <Row className="template-list-header">
        <Column className="template-name">Template name</Column>
        <Column className="template-programs-affected">Number of programs affected</Column>
      </Row>
      {templates.map((template) => {
        const affectedProgram = affectedPrograms[template.identifier] ?? [];
        return (
          <Row key={template.id} className="serviceList-expansion">
            <ExpansionList className=" full-width">
              <ExpansionPanel
                className="service-list-expansion-panel full-width"
                label={
                  <Row>
                    <div className="template-identifier">{template.identifier}</div>
                    <Row className="full-width" align="space-between">
                      <Button
                        className="programs-affected"
                        onClick={() => affectedProgramsDialog(template)}
                      >
                        {affectedProgram
                          .map((affectedProgram) => affectedProgram.nbPrograms)
                          .reduce((a, b) => a + b, 0)}
                      </Button>
                      <Button
                        className="duplicate-btn"
                        onClick={() => duplicateTemplateDialog(template)}
                      >
                        <i className="icon-copy" />
                      </Button>
                    </Row>
                  </Row>
                }
                footer={null}
                expanderIcon={<i className="icon-chevron-down" />}
                headerClassName="cc-section-header table-header"
                contentClassName="cc-fullwidth-panel"
              >
                <ServiceListWrapper
                  editedTemplate={editedTemplate}
                  template={template}
                  confirmationDialog={(editedTemplate) =>
                    confirmationDialog(editedTemplate, template.id)
                  }
                />
              </ExpansionPanel>
            </ExpansionList>
          </Row>
        );
      })}
    </div>
  );
};

const ServiceListWrapper = ({ template, editedTemplate, confirmationDialog }) => (
  <div>
    {editedTemplate.identifier === template.identifier ? (
      <Row className="full-width">
        <ServiceList
          services={editedTemplate.services}
          template={editedTemplate}
          confirmationDialog={confirmationDialog}
        />
      </Row>
    ) : (
      <Row className="full-width">
        <ServiceList
          services={template.services}
          template={template}
          confirmationDialog={confirmationDialog}
        />
      </Row>
    )}
  </div>
);

TemplateList.propTypes = {
  templates: PropTypes.array.isRequired,

  // From the store
  fetchAffectedPrograms: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  onAddTemplate: PropTypes.func.isRequired,
  affectedPrograms: PropTypes.array.isRequired,
  originalTemplates: PropTypes.array.isRequired,
  editedTemplate: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
};

const stateToProps = (state) => ({
  affectedPrograms: state.modules.serviceSuiteTemplates.affectedPrograms,
  editedTemplate: state.modules.serviceSuiteTemplates.editedTemplate,
  originalTemplates: state.modules.serviceSuiteTemplates.originalTemplates,
});

const dispatchToProps = {
  fetchAffectedPrograms: moduleActions.serviceSuiteTemplates.fetchAffectedPrograms,
  fetchTemplates: moduleActions.serviceSuiteTemplates.fetchTemplates,
  onAddTemplate: moduleActions.serviceSuiteTemplates.addTemplate,

  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(TemplateList);
