import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontIcon } from "react-md";
import { Checkbox } from "antd";
import findLastIndex from "lodash/findLastIndex";

import { TestDefinitionIcon, TestClassRow, Column, Row } from "~/global";

import "./TestSuiteBuilder.component.scss";

const TestSuiteBuilder = ({ categories, selectedIdentifiers, onChange }) => {
  const [categoryState, setCategoryState] = useState({});

  const toggleCategoryState = (name) => {
    setCategoryState({
      ...categoryState,
      [name]: !categoryState[name],
    });
  };

  const getClassNameDefinitions = (definitions = [], className) =>
    definitions
      .filter((definition) => definition.className === className)
      .sort((a, b) => {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
      });

  const getTestDetails = (definitions = []) => {
    const firstDefinition = definitions[0] || {};
    return {
      name: firstDefinition.displayName || firstDefinition.className,
      image: firstDefinition.image,
      description: firstDefinition.description || "no description",
    };
  };

  const getAvailableCategoryClassNames = (categoryClassNames, definitions) => {
    const availableClassNames = definitions.map((def) => def.className);

    return categoryClassNames.filter((className) => availableClassNames.includes(className));
  };

  const removeConfig = (identifier) => {
    const newSelectedIdentifiers = [...selectedIdentifiers];
    const lastIndex = findLastIndex(newSelectedIdentifiers, (i) => i === identifier);

    if (lastIndex > -1) {
      newSelectedIdentifiers.splice(lastIndex, 1);

      onChange(newSelectedIdentifiers);
    }
  };

  const addConfig = (identifier) => {
    onChange([...selectedIdentifiers, identifier]);
  };

  return (
    <Column className="test-suite-builder" flex basis="225px">
      {categories.map((category) => {
        const isOpen = categoryState[category.name];
        const availableClassNames = getAvailableCategoryClassNames(
          category.classNames,
          category.definitions
        );
        const selectedCount = category.definitions.filter((def) =>
          selectedIdentifiers.includes(def.identifier)
        ).length;

        return (
          <div key={category.name} className={classnames("category-container", { open: isOpen })}>
            <Row
              className="category-header"
              align="space-between center"
              onClick={() => toggleCategoryState(category.name)}
            >
              <Row align="start center">
                <TestDefinitionIcon src={category.image} isCategory />

                <b className="margin-h--10">{category.displayName}</b>

                {selectedCount > 0 ? (
                  <span style={{ fontSize: "0.9em" }}>({selectedCount} selected)</span>
                ) : null}
              </Row>

              {(category.definitions || []).length > 0 ? (
                <FontIcon iconClassName="icon-chevron-left" />
              ) : null}
            </Row>

            {isOpen && (
              <>
                <Row className="category-tests-header" align="start center">
                  <div className="test">Test</div>
                  <div className="description flex">Description</div>
                </Row>

                <div className="category-tests">
                  {availableClassNames.map((className) => {
                    const definitions = getClassNameDefinitions(category.definitions, className);
                    const { name, image, description } = getTestDetails(definitions);

                    return (
                      <TestClassRow key={className}>
                        <TestClassRow.Icon image={image} />

                        <Column flex>
                          <Row className="padding--10">
                            <div className="test-name">{name}</div>
                            <div className="test-desc">{description}</div>
                          </Row>

                          <Column>
                            {definitions.map((definition) => {
                              const configName =
                                definition.identifier === definition.className
                                  ? "Default"
                                  : definition.identifierDisplayName;

                              return (
                                <Row
                                  className="test-config"
                                  key={`${definition.className}-${definition.identifier}`}
                                >
                                  <Column align="center">
                                    <Checkbox
                                      onChange={(e) =>
                                        e.target.checked
                                          ? addConfig(definition.identifier)
                                          : removeConfig(definition.identifier)
                                      }
                                      defaultChecked={
                                        selectedIdentifiers.indexOf(definition.identifier) > -1
                                      }
                                    />
                                  </Column>

                                  <Column className="config-name" align="center">
                                    {configName}
                                  </Column>
                                </Row>
                              );
                            })}
                          </Column>
                        </Column>
                      </TestClassRow>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </Column>
  );
};

TestSuiteBuilder.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedIdentifiers: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      image: PropTypes.string,
      classNames: PropTypes.arrayOf(PropTypes.string),
      definitions: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

TestSuiteBuilder.defaultProps = {
  categories: [],
  selectedIdentifiers: [],
};

export default TestSuiteBuilder;
