import React from "react";
import classnames from "classnames";

import "./Panel.component.scss";

const Panel = ({ children, className, ...rest }) => (
  <div className={classnames("cc-simple-panel", className)} {...rest}>
    {children}
  </div>
);

export default Panel;
