import React, { Component } from "react";

import { TextField, Switch } from "react-md";
import { connect } from "react-redux";
import { AsciButton } from "~/global";
import actions from "~/actions";
import selectors from "~/selectors";

class FailCodeCategoryEditor extends Component {
  state = {
    languages: ["en", "fr", "es"],
  };

  onNameChanged = (name, language) => {
    this.props.validateName(name, language);
    const newName = {
      ...this.props.failCodeCategory.name,
      [language]: name,
    };
    this.props.setName(newName);
  };

  toggleFailFast = (value) => {
    const failFast = !value;
    this.props.setFailFast(failFast);
  };

  onConfirm = () => {
    const failCodeCategory = {
      ...this.props.failCodeCategory,
    };
    this.props.onConfirm(failCodeCategory);
  };

  render() {
    const errorsFound =
      this.props.errors.name.length > 0 || this.props.failCodeCategory.name.length === 0;
    const isEqual =
      this.props.failCodeCategory.name == this.props.originalFailCodeCategory.name &&
      this.props.failCodeCategory.failFast == this.props.originalFailCodeCategory.failFast;
    const isDisabled = errorsFound || isEqual;

    return (
      <div className="full-width layout-column">
        <div className="dialog-section">
          <h3>Name and Language Options</h3>

          <div className="layout-row">
            <div className="flex--30">Language</div>
            <div className="flex--70">Category Name</div>
          </div>

          {
            /* TODO: Create a TrilingualComponent */
            (this.state.languages || []).map((language) => (
              <div
                className="layout-row layout-row--center-center"
                key={`textfield-language-${language}`}
              >
                <div className="flex--30">
                  {language.toUpperCase()}: <sup>{language == "en" ? "*" : ""}</sup>
                </div>
                <div className="flex--70">
                  <TextField
                    required={language == "en"}
                    id={`fail-codes-category-editor-name-${language}`}
                    error={(this.props.errors.name[language] || "").length > 0}
                    errorText={this.props.errors.name[language]}
                    value={this.props.failCodeCategory.name[language]}
                    onChange={(name) => this.onNameChanged(name, language)}
                    ref={(field) => (this._textfield = field)}
                    type="text"
                    onKeyPress={(e) => {
                      if (!isDisabled && e.key == "Enter") {
                        this.onConfirm();
                      }
                    }}
                  />
                </div>
              </div>
            ))
          }
        </div>

        <div className="dialog-section">
          <h3>Other Options</h3>
          <div className="layout-row">
            <div className="flex--30">
              Fail Fast: <sup>*</sup>
            </div>
            <div className="flex--70">
              <Switch
                id={`fail-fast-switch-${this.props.failCodeCategory.id}`}
                type="switch"
                name={`fail-fast ${this.props.failCodeCategory}`}
                checked={this.props.failCodeCategory.failFast}
                onClick={(e) => e.stopPropagation()}
                onChange={() => this.toggleFailFast(this.props.failCodeCategory.failFast)}
                className="flex--30 editor-switch"
                label=""
              />
            </div>
          </div>
          <div className="info-message">* Required field</div>
        </div>

        <div className="dialog-actions layout-row layout-row--end-center">
          <AsciButton color="white" onClick={this.props.onCancel}>
            Cancel
          </AsciButton>
          <AsciButton color="green" onClick={this.onConfirm} disabled={isDisabled}>
            Save
          </AsciButton>
        </div>
      </div>
    );
  }
}

const stateToProps = (state) => ({
  existingNames: selectors.failCodes.getExistingFailCodeCategoryNames(state),
  failCodeCategory: selectors.failCodes.getUpdatedFailCodeCategory(state),
  originalFailCodeCategory: state.failCodeCategory.original,
  errors: state.failCodeCategory.errors,
});

const dispatchToProps = {
  validateName: actions.failCodeCategory.validateName,
  setName: actions.failCodeCategory.setName,
  setFailFast: actions.failCodeCategory.setFailFast,
};

export default connect(stateToProps, dispatchToProps)(FailCodeCategoryEditor);
