import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import { SketchPicker } from "react-color";

import "./ColorInput.scss";

const ColorInput = (props) => {
  const { color, onChange } = props;
  const [open, setOpen] = useState(false);

  // Picker should only close if the color has not changed in 1.5 seconds.
  const close = useCallback(
    debounce(() => setOpen(false), 1500),
    [setOpen]
  );

  return (
    <div className="color-input-container">
      {open && (
        <div className="color-input-picker">
          <SketchPicker
            color={color}
            onChange={(color) => {
              onChange(color.hex);
              close();
            }}
          />
        </div>
      )}
      <div className="color-input-button" onClick={() => setOpen(!open)}>
        <div className="color-input-preview" style={{ background: color }} />
      </div>
    </div>
  );
};

ColorInput.defaultProps = {
  label: "Color",
};

ColorInput.propTypes = {
  label: PropTypes.node,
  color: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorInput;
