import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { sortBy } from "lodash/collection";
import { Switch } from "antd";
import { useTranslation } from "~/i18n";

const CustomerList = ({
  canEdit,
  customers,
  onEdit,
  onDelete,
  isLoading,
  isArchived,
  setIsArchived,
}) => {
  const [sortKey, setSortKey] = useState("");
  const [sortReverse, setSortReverse] = useState(false);
  const [customersSorted, setCustomersSorted] = useState(customers);
  const { t } = useTranslation("customerList");

  useEffect(() => {
    if (customers.length > 0) {
      setCustomersSorted(customers);
      setSortKey("csid");
    } else {
      setCustomersSorted([]);
    }
  }, [customers]);

  useEffect(() => {
    let newSortedCustomers = [].concat(customersSorted);
    newSortedCustomers = sortBy(newSortedCustomers, (customer) => {
      const value = customer[sortKey];
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value == null ? "" : value;
    });
    setCustomersSorted(newSortedCustomers);
  }, [sortKey]);

  useEffect(() => {
    const newSortedCustomers = [].concat(customersSorted).reverse();
    setCustomersSorted(newSortedCustomers);
  }, [sortReverse]);

  const setSort = (key) => {
    key === sortKey ? setSortReverse(!sortReverse) : setSortReverse(sortReverse);
    setSortKey(key);
  };

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = sortKey === colKey;

    if (sorted) {
      classes.push("is-sorted");
    }
    if (sortReverse) {
      classes.push("sort-reverse");
    }
    return classes.join(" ");
  };

  return (
    <div>
      <DataTable className="cc-table full-width" baseId="session-list-table" plain>
        <TableHeader>
          <TableRow className="asci-table-row">
            <TableColumn className={getColHeaderClasses("csid")} onClick={() => setSort("csid")}>
              ID
            </TableColumn>
            <TableColumn className={getColHeaderClasses("name")} onClick={() => setSort("name")}>
              {t("customer-name")}
            </TableColumn>
            <TableColumn
              className={getColHeaderClasses("reseller")}
              onClick={() => setSort("reseller")}
            >
              {t("reseller")}
            </TableColumn>
            <TableColumn className="edit layout-row layout-row--end-center">
              <Switch
                checked={isArchived}
                align-right
                onChange={(checked) => {
                  setIsArchived(checked);
                }}
                checkedChildren={t("archived")}
                unCheckedChildren={t("not-archived")}
              />
            </TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {customers.length === 0 ? (
            <TableRow>
              <TableColumn colSpan="4" className="text-centered">
                {t("no-data-to-display")}
              </TableColumn>
            </TableRow>
          ) : (
            customersSorted.map((customer, index) => (
              <TableRow key={index} className={customer.reseller === "Yes" ? "reseller" : ""}>
                <TableColumn>
                  <div onClick={() => onEdit(customer.csid)} className="clickable-text">
                    {customer.csid || t("not-available")}
                  </div>
                </TableColumn>
                <TableColumn>{customer.name || t("not-available")}</TableColumn>
                <TableColumn>
                  {customer.reseller ? t("customer-reseller-yes") : t("customer-reseller-no")}
                </TableColumn>
                {canEdit && !isArchived ? (
                  <TableColumn className="layout-row layout-row--end-center">
                    <Button
                      icon
                      className="icon-btn"
                      iconClassName="icon-pencil"
                      onClick={() => onEdit(customer.csid)}
                    />
                    <Button
                      icon
                      className="icon-btn text-color--red"
                      iconClassName="icon-trash"
                      disabled={isLoading}
                      onClick={() => onDelete(customer.csid)}
                    />
                  </TableColumn>
                ) : (
                  <TableColumn className="layout-row layout-row--end-center"></TableColumn>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </DataTable>
    </div>
  );
};

CustomerList.propTypes = {
  canEdit: PropTypes.bool,
  customers: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isArchived: PropTypes.bool.isRequired,
  setIsArchived: PropTypes.func.isRequired,
};

export default CustomerList;
