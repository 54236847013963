import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Empty, Card } from "antd";
import { useAppDispatch, useAppSelector } from "~/hooks";
import actions from "~/actions";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors";
import { useTranslation } from "~/i18n";
import { AsciSpinner, AsciButton, DialogMessage, Tab, Tabs } from "~/global";

import {
  CustomerIdentity,
  CustomerFacilities,
  CustomerUsers,
  CustomerTeams,
  CustomerAudits,
} from "~/customer-management";

import "./CustomerEditor.component.scss";
import CustomerUserEditor from "../CustomerUsers/CustomerUserEditor.component";
import CustomerUserEditorButtons from "../CustomerUsers/CustomerUserEditorButtons.component";

const CustomerEditor = () => {
  const currentCsid = useAppSelector((state) => state.customer.activeCustomerId);
  const canSaveIdentity = useAppSelector(
    moduleSelectors.customerIdentity.getCanSaveCustomerIdentity
  );
  const currentTab = useAppSelector(
    (state) => state.modules.customerManagement.customerEditor.currentTab
  );
  const customerIdentityIsValid = useAppSelector(
    moduleSelectors.customerIdentity.getCustomerIdentityIsValid
  );
  const customerIdentityIsEqual = useAppSelector(
    moduleSelectors.customerIdentity.getCustomerIdentityIsEqual
  );
  const isLoading = useAppSelector(moduleSelectors.customerEditor.getIsLoading);
  // errors
  const userErrors = useAppSelector(
    (state) => state.modules.customerManagement.customerUsers.errors
  );
  const customerErrors = useAppSelector(
    (state) => state.modules.customerManagement.customerEditor.errors
  );
  const teamErrors = useAppSelector(
    (state) => state.modules.customerManagement.customerTeams.errors
  );
  // facilities
  const facilitiesList = useAppSelector(
    moduleSelectors.customerFacilities.getFacilitiesListAllOption
  );
  const facilitiesById = useAppSelector(
    moduleSelectors.customerFacilities.getFacilitiesByIdAllOption
  );
  const canEditOnlyCurrent = useAppSelector((state) =>
    selectors.session.hasPermission(state, "PUT", "/canEditOnlyCurrent")
  );
  const isAuditsIsEqual = useAppSelector(selectors.audits.getIsEqualAudits);
  const canSaveAudits = useAppSelector((state) => selectors.audits.getCanSaveAudits(state));
  const { t } = useTranslation("customerEditor");
  const dispatch = useAppDispatch();

  const history = useHistory();
  const params = useParams();

  const beforeTabChangeValidators = [
    () => {
      if (!customerIdentityIsValid) {
        showValidationMessage(t("table-title-identification"));
      }
      return customerIdentityIsValid;
    },
    () => true,
    () => true,
    () => true,
    () => true,
  ];

  const tabsTitles = [
    t("table-title-identification"),
    t("table-title-facilities"),
    t("table-title-audits"),
    t("table-title-users"),
    t("table-title-teams"),
  ];
  useEffect(() => {
    dispatch(moduleActions.customerFacilities.fetchCustomerFacilities(currentCsid, false));
    dispatch(actions.facilities.fetchFacilities(false));
  }, [currentCsid, currentTab, dispatch]);

  useEffect(() => {
    if (currentCsid !== "") {
      if (currentCsid !== params.csid) {
        history.replace(`/customer-management/${currentCsid}`);
      }

      dispatch(moduleActions.customerFacilities.fetchCustomerFacilities(currentCsid));
    }

    return () => {
      dispatch(actions.dialog.hide());
      dispatch(moduleActions.customerEditor.onResetCustomerEdition());
    };
  }, [currentCsid]);

  useEffect(() => {
    const message = [userErrors, customerErrors, teamErrors].filter((a) => a != null).join(". ");
    if (message !== "") {
      showErrorMessage(message);
    }
  }, [userErrors, customerErrors, teamErrors]);

  const showValidationMessage = (tabName) => {
    dispatch(
      actions.dialog.show({
        title: t("title-invalid-fields"),
        width: "450px",
        modal: true,
        content: (close) => (
          <DialogMessage close={close}>
            {tabName} {t("content-missing-information-warning")}
          </DialogMessage>
        ),
      })
    );
  };

  const exit = () => {
    if (canEditOnlyCurrent) {
      history.replace("/home");
    } else {
      history.replace("/customer-management");
    }
  };

  const showErrorMessage = (errors) => {
    dispatch(
      actions.dialog.show({
        title: t("title-error"),
        width: "650px",
        modal: true,
        content: (close) => (
          <div style={{ wordBreak: "break-all" }}>
            <p>{errors}</p>
            <div className="dialog-actions layout-row layout-row--end-center">
              <AsciButton
                color="white"
                onClick={async () => {
                  await close();
                  addUserCallback();
                }}
              >
                {t("continue-editing")}
              </AsciButton>
              <AsciButton
                color="red"
                onClick={() => {
                  exit();
                  dispatch(moduleActions.customerEditor.onResetCustomerEdition());
                  close();
                }}
              >
                {t("discard-exit")}
              </AsciButton>
            </div>
          </div>
        ),
      })
    );
  };

  const onCancel = () => {
    if (canSaveIdentity || canSaveAudits) {
      dispatch(
        actions.dialog.show({
          title: t("title-cancel-customer-edition"),
          width: "650px",
          modal: true,
          content: (close) => (
            <div>
              <p>{t("content-cancel-customer-edition-ask")}</p>
              <div className="dialog-actions layout-row layout-row--end-center">
                <AsciButton color="white" onClick={close}>
                  {t("content-continue-editing")}
                </AsciButton>
                <AsciButton
                  color="red"
                  onClick={() => {
                    close();
                    dispatch(moduleActions.customerEditor.onResetCustomerEdition());
                    exit();
                  }}
                >
                  {t("content-discard-exit")}
                </AsciButton>
              </div>
            </div>
          ),
        })
      );
    } else {
      dispatch(moduleActions.customerEditor.onResetCustomerEdition());
      exit();
    }
  };

  const onClickTab = (index) => {
    dispatch(moduleActions.customerEditor.onTabChanged(index));
    if (canSaveIdentity) {
      const tabIndex = tabsTitles.indexOf(t("table-title-identification"));
      dispatch(
        actions.dialog.show({
          title: t("title-save-to-continue"),
          width: "450px",
          modal: true,
          content: (close) => (
            <div>
              <p className="align-center">{t("content-save-change-ask")}</p>
              <div className="dialog-actions layout-row layout-row--end-center">
                <AsciButton
                  color="white"
                  onClick={() => {
                    dispatch(moduleActions.customerEditor.onTabChanged(tabIndex));
                    close();
                  }}
                >
                  {t("content-go-identification")}
                </AsciButton>

                <AsciButton
                  color="green"
                  onClick={() => {
                    dispatch(moduleActions.customerIdentity.onSaveIdentity(currentCsid));
                    close();
                  }}
                >
                  {t("content-save-change")}
                </AsciButton>
              </div>
            </div>
          ),
        })
      );
    } else if (canSaveAudits) {
      dispatch(
        actions.dialog.show({
          title: t("title-save-to-continue"),
          width: "450px",
          modal: true,
          content: (close) => (
            <div>
              <p className="align-center">{t("content-save-change-ask")}</p>
              <div className="dialog-actions layout-row layout-row--end-center">
                <AsciButton
                  color="red"
                  onClick={() => {
                    dispatch(actions.audits.onResetModifications());
                    close();
                  }}
                >
                  {t("content-reset")}
                </AsciButton>

                <AsciButton
                  color="green"
                  onClick={() => {
                    dispatch(actions.audits.onSaveAudits());
                    close();
                  }}
                >
                  {t("content-save-change")}
                </AsciButton>
              </div>
            </div>
          ),
        })
      );
    }
  };

  const addUserCallback = () => {
    dispatch(moduleActions.customerUserEditor.initUser());
    dispatch(
      actions.dialog.show({
        title: t("title-add-user"),
        width: "650px",
        modal: true,
        content: (close) => (
          <div>
            <CustomerUserEditor
              onSave={() => {
                dispatch(moduleActions.customerUsers.onAddUser());
                close();
              }}
              facilitiesById={facilitiesById}
              facilitiesList={facilitiesList}
              editMode={false}
              onCancel={() => {
                close();
              }}
              addUser
            />
          </div>
        ),
        footer: (close) => (
          <CustomerUserEditorButtons
            onSave={() => {
              dispatch(moduleActions.customerUsers.onAddUser());
              close();
            }}
            onCancel={() => close()}
          />
        ),
      })
    );
  };

  return (
    <div className="customer-page panel-container layout-column layout-column--start">
      <Tabs
        tabId="customer-management-tabs"
        onTabChanged={onClickTab}
        beforeTabChange={beforeTabChangeValidators}
        selectedIndex={currentTab}
      >
        <Tab key="tab-0" title={t("table-title-identification")}>
          <CustomerIdentity newCustomer={false} csid={currentCsid} />
        </Tab>
        <Tab key="tab-1" title={t("table-title-facilities")}>
          <CustomerFacilities newCustomer={false} csid={currentCsid} />
        </Tab>
        <Tab key="tab-2" title={t("table-title-audits")}>
          {facilitiesList.length > 1 ? (
            <CustomerAudits newCustomer={false} csid={currentCsid} />
          ) : (
            <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Empty description="No Data" />
            </Card>
          )}
        </Tab>
        <Tab key="tab-3" title={t("table-title-users")}>
          <CustomerUsers
            addUserCallback={addUserCallback}
            facilitiesById={facilitiesById}
            facilitiesList={facilitiesList}
            newCustomer={false}
            csid={currentCsid}
          />
        </Tab>
        <Tab key="tab-4" title={t("table-title-teams")}>
          <CustomerTeams facilitiesById={facilitiesById} csid={currentCsid} newCustomer={false} />
        </Tab>
      </Tabs>
      <div className="content-footer push-right">
        {currentTab === tabsTitles.indexOf(t("table-title-identification")) ? (
          <>
            <div className="info-message">{t("all-fields-marked-with-an-asterisk")}</div>
            <AsciButton color="white" onClick={onCancel} disabled={isLoading}>
              {customerIdentityIsEqual
                ? t("customer-identity-is-equal-exit")
                : t("customer-identity-is-equal-cancel")}
            </AsciButton>
            <AsciButton
              color="green"
              onClick={() => dispatch(moduleActions.customerIdentity.onSaveIdentity(currentCsid))}
              disabled={!canSaveIdentity}
            >
              {t("return-customer-editor-save")}
            </AsciButton>
          </>
        ) : currentTab === tabsTitles.indexOf(t("table-title-audits")) ? (
          <>
            <div className="info-message">{t("all-fields-marked-with-an-asterisk")}</div>
            <AsciButton color="white" onClick={onCancel} disabled={isLoading}>
              {isAuditsIsEqual
                ? t("customer-identity-is-equal-exit")
                : t("customer-identity-is-equal-cancel")}
            </AsciButton>
            <AsciButton
              color="green"
              onClick={() => {
                dispatch(actions.audits.onSaveAudits());
              }}
              disabled={!canSaveAudits}
            >
              {t("return-customer-editor-save")}
            </AsciButton>
          </>
        ) : (
          <AsciButton color="white" onClick={onCancel} disabled={isLoading}>
            {customerIdentityIsEqual || isAuditsIsEqual
              ? t("customer-identity-is-equal-exit")
              : t("customer-identity-is-equal-cancel")}
          </AsciButton>
        )}
      </div>
      <AsciSpinner visible={isLoading} />
    </div>
  );
};

export default CustomerEditor;
