import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { CANNED_REPORTS_FAIL_PASS_COLORS } from "~/constants/index";
import "./GroupBarChartByOS.component.scss";

const GroupBarChartByOS = ({ title, data, intervalsByFacility }) => (
  /*
        Render a custom tick (eg, tick={renderCustomTick})
        https://github.com/recharts/recharts/issues/1732
    */

  <div className="bar-chart">
    <div className="chart-header">
      <div className="chart-title">{title}</div>
    </div>
    <ResponsiveContainer height="80%">
      <BarChart layout="vertical" data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          allowDecimals={false}
          label={{ value: "Number of Devices Processed", position: "bottom" }}
        />
        <YAxis dataKey="suiteName" type="category" width={100} />
        <YAxis
          dataKey="itemName"
          type="category"
          width={180}
          yAxisId={1}
          tickLine={false}
          tick={<CustomTick intervalsByFacility={intervalsByFacility} />}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={48} />
        <Bar
          dataKey="passedDevices"
          stackId="stack"
          fill={CANNED_REPORTS_FAIL_PASS_COLORS.pass}
          maxBarSize={15}
          name="Pass"
        />
        <Bar
          dataKey="failedDevices"
          stackId="stack"
          fill={CANNED_REPORTS_FAIL_PASS_COLORS.fail}
          maxBarSize={15}
          name="Fail"
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
);
const CustomTick = (props) => {
  const { intervalsByFacility, x, y, payload, index, fill, textAnchor } = props;
  const { value, offset } = payload;

  const tickNb = index + 1;
  const style = { fontWeight: "bold" };
  const onlyOneTick = intervalsByFacility[value].nbOfTicks === 1;
  const lineAfter = y + offset;

  if (tickNb === intervalsByFacility[value].firstTick) {
    const offsetToCenter = offset * (intervalsByFacility[value].nbOfTicks - 1);
    const lineBefore = y - offset;

    if (onlyOneTick) {
      return (
        <g>
          <path d={`M ${x + 7},${lineBefore} h 20`} stroke={fill} />
          <text x={x} y={y + offsetToCenter} textAnchor={textAnchor} fill={fill} style={style}>
            <tspan dy="0.355em">{value}</tspan>
          </text>
          <path d={`M ${x + 7},${lineAfter} h 20`} stroke={fill} />
        </g>
      );
    }

    if (tickNb === 1) {
      return (
        <g>
          <path d={`M ${x + 7},${lineBefore} h 20`} stroke={fill} />
          <text x={x} y={y + offsetToCenter} textAnchor={textAnchor} fill={fill} style={style}>
            <tspan dy="0.355em">{value}</tspan>
          </text>
        </g>
      );
    }

    return (
      <text x={x} y={y + offsetToCenter} textAnchor={textAnchor} fill={fill} style={style}>
        <tspan dy="0.355em">{value}</tspan>
      </text>
    );
  }

  if (tickNb === intervalsByFacility[value].lastTick) {
    return <path d={`M ${x + 7},${lineAfter} h 20`} stroke={fill} />;
  }

  return null;
};

export default GroupBarChartByOS;
