import { createAction } from "@reduxjs/toolkit";
import join from "lodash/join";

export const setFontLoading = createAction("label-fonts/setFontLoading");
export const setFontLoaded = createAction("label-fonts/setFontLoaded");

export const loadFonts = () => async (dispatch, getState) => {
  const { fonts, loaded } = getState().labelPrinter.labelFonts;
  if (loaded) {
    return;
  }

  await dispatch(setFontLoading());

  const head = document.querySelector("head");

  const fontLink = document.createElement("link");
  fontLink.href = `https://fonts.googleapis.com/css2?family=${join(
    fonts.map(encodeURIComponent),
    "&family="
  )}`;
  fontLink.rel = "stylesheet";
  fontLink.onload = () => dispatch(setFontLoaded());

  head.appendChild(fontLink);
};
