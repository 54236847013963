import React, { useCallback } from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useTranslation } from "~/i18n";

// TODO: remove the size prop when all the icons have been updated to use antd
interface Props extends Partial<CustomIconComponentProps> {
  customId: string;
  size: number;
}

export const SkuGroupsIcon = ({ customId, size, style, ...iconProps }: Props) => {
  const { t } = useTranslation("home");

  const SkuGroupsSvg = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="Layer_2"
        viewBox="0 0 40.48 22.34"
        width="1em"
        height="1em"
      >
        <defs>
          <linearGradient
            id={`${customId}-linear-gradient`}
            x1={0}
            x2={18.87}
            y1={9.16}
            y2={9.16}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#9c3" />
            <stop offset={0.62} stopColor="#376a53" />
            <stop offset={1} stopColor="#036" />
          </linearGradient>
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-2`}
            x1={21.61}
            x2={40.48}
            y1={7}
            y2={7}
          />
          <linearGradient
            xlinkHref={`#${customId}-linear-gradient`}
            id={`${customId}-linear-gradient-3`}
            x1={11.1}
            x2={34.99}
            y1={13.51}
            y2={13.51}
          />
        </defs>
        <g id={`${customId}-Version_Number`}>
          <rect
            width={17.87}
            height={13}
            x={0.5}
            y={2.66}
            fill="none"
            stroke={`url(#${customId}-linear-gradient)`}
            strokeMiterlimit={10}
            rx={2}
            ry={2}
          />
          <path
            d="M3.88 5.95v6.4M5.38 5.95v4.89M6.76 5.95v4.89M8.26 5.95v4.89M9.76 5.95v6.4M11.28 5.95v4.89M13.38 5.95v4.89M15 5.95v6.4"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <rect
            width={17.87}
            height={13}
            x={22.11}
            y={0.5}
            fill="none"
            stroke={`url(#${customId}-linear-gradient-2)`}
            strokeMiterlimit={10}
            rx={2}
            ry={2}
          />
          <path
            d="M25.49 3.79v6.4M26.99 3.79v4.89M28.37 3.79v4.89M29.87 3.79v4.89M31.37 3.79v6.4M32.89 3.79v4.89M34.99 3.79v4.89M36.61 3.79v6.4"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <rect
            width={22.89}
            height={16.65}
            x={11.6}
            y={5.19}
            fill="#fff"
            stroke={`url(#${customId}-linear-gradient-3)`}
            strokeMiterlimit={10}
            rx={3.07}
            ry={3.07}
          />
          <path
            d="M15.93 9.4v8.2M17.85 9.4v6.26M19.62 9.4v6.26M21.54 9.4v6.26M23.46 9.4v8.2M25.4 9.4v6.26M28.1 9.4v6.26M30.17 9.4v8.2"
            stroke="#93d500"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
      </svg>
    ),
    [customId]
  );

  return (
    <Icon
      title={t("modules.sku-groups-title")}
      component={SkuGroupsSvg}
      style={{ ...style, fontSize: size }}
      {...iconProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};
