import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select } from "antd";

import ValueEditor from "~/global/ValueEditor/ValueEditor.component";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

const { Option } = Select;

const PrintLabelServiceEditor = (props) => {
  const { service, labels, onServiceArgumentChanged, loading, loaded, fetchLabels } = props;

  useEffect(() => {
    if (!loading && !loaded) {
      fetchLabels();
    }
  }, [loading, loaded, fetchLabels]);

  return (
    <div className="service-arguments-editor">
      <div className="value-editor">
        <div className="list-editor">
          <div className="label">Label</div>
          <div>
            <Select
              style={{ minWidth: "100%" }}
              allowClear
              // Find the first label from `labels` that has the same id as the first argument from `service`, and display its name as default.
              value={labels.find((label) => label.id === service.arguments[0]?.value)?.name}
              onChange={(value) => {
                onServiceArgumentChanged(
                  service.identifier,
                  service.arguments.map((e) => (e.name === "labelId" ? { ...e, value } : e))
                );
              }}
            >
              {labels.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <ValueEditor
        type="bool"
        name="Print on failure"
        value={service.arguments[1]?.value}
        onChange={(value) =>
          onServiceArgumentChanged(
            service.identifier,
            service.arguments.map((e) => (e.name === "printFails" ? { ...e, value } : e))
          )
        }
      />
    </div>
  );
};

PrintLabelServiceEditor.defaultProps = {};

PrintLabelServiceEditor.propTypes = {
  service: PropTypes.object,
  onServiceArgumentChanged: PropTypes.func,

  labels: PropTypes.array,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  fetchLabels: PropTypes.func,
};

export default connect(
  (state) => ({
    labels: labelSelectors.labelLister.getLabels(state),
    loaded: labelSelectors.labelLister.isLoaded(state),
    loading: labelSelectors.labelLister.isLoading(state),
  }),
  {
    fetchLabels: labelActions.labelLister.fetchLabels,
  }
)(PrintLabelServiceEditor);
