import produce from "immer";

import {
  FETCH_FAIL_CODE_ASSOCIATED_TESTS_BEGIN,
  FETCH_FAIL_CODE_ASSOCIATED_TESTS_ERROR,
  FETCH_FAIL_CODE_ASSOCIATED_TESTS_SUCCESS,
  SELECT_FAIL_CODE,
  SET_FAIL_CODE_DESCRIPTION,
  SET_FAIL_CODE_FAIL_FAST,
  SET_FAIL_CODE_NAME,
  SET_FAIL_CODE_SHOW_ON_DESKTOP,
  UPDATE_FAIL_CODE_BEGIN,
  UPDATE_FAIL_CODE_ERROR,
  UPDATE_FAIL_CODE_SUCCESS,
  VALIDATE_FAIL_CODE_DESCRIPTION,
  VALIDATE_FAIL_CODE_NAME,
  RESET_FAIL_CODE,
  SET_FAIL_ROUTE,
} from "~/actions/failCodes/failCode.actions";

const initialState = {
  original: {},
  modifications: {},
  errors: {
    name: "",
    description: {
      en: "",
    },
    message: [],
    title: "",
    route: "",
  },
  associatedTests: [],
  associatedTestsLoading: false,
};

export default function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_FAIL_CODE_ASSOCIATED_TESTS_BEGIN: {
        draft.associatedTestsLoading = true;
        return;
      }

      case FETCH_FAIL_CODE_ASSOCIATED_TESTS_SUCCESS: {
        draft.associatedTests = action.payload.tests || [];
        draft.associatedTestsLoading = false;
        return;
      }

      case FETCH_FAIL_CODE_ASSOCIATED_TESTS_ERROR: {
        draft.associatedTests = [];
        draft.associatedTestsLoading = false;
        return;
      }

      case SELECT_FAIL_CODE: {
        draft.original = action.payload.failCode;
        draft.modifications = {};
        draft.associatedTests = [];
        draft.errors = initialState.errors;
        return;
      }

      case SET_FAIL_CODE_NAME: {
        draft.modifications.name = action.payload.name;
        return;
      }
      case SET_FAIL_ROUTE: {
        draft.modifications.route = action.payload.route;
        return;
      }
      case SET_FAIL_CODE_DESCRIPTION: {
        draft.modifications.description = action.payload.description;
        return;
      }

      case SET_FAIL_CODE_FAIL_FAST: {
        draft.modifications.failFast = action.payload.failFast;
        return;
      }

      case SET_FAIL_CODE_SHOW_ON_DESKTOP: {
        draft.modifications.showOnDesktop = action.payload.showOnDesktop;
        return;
      }

      case UPDATE_FAIL_CODE_ERROR: {
        draft.errors.message = action.payload.errors.message || [];
        draft.errors.title = action.payload.errors.title || "";
        return;
      }

      case UPDATE_FAIL_CODE_BEGIN: {
        draft.errors = initialState.errors;
        return;
      }

      case UPDATE_FAIL_CODE_SUCCESS: {
        draft.errors = initialState.errors;
        draft.original = action.payload.failCode;

        return;
      }

      case RESET_FAIL_CODE: {
        draft.modifications = {};
        draft.errors = initialState.errors;
        return;
      }

      case VALIDATE_FAIL_CODE_NAME: {
        draft.errors.name = action.payload.error;
        return;
      }

      case VALIDATE_FAIL_CODE_DESCRIPTION: {
        draft.errors.description = Object.assign(
          draft.errors.description,
          action.payload.error.description
        );
      }
    }
  });
}
