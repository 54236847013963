import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "react-md";
import classnames from "classnames";
import keys from "lodash/keys";

import {
  AsciSelect,
  SectionHeader,
  AsciButton,
  AsciSpinner,
  Tabs,
  Tab,
  DialogMessage,
  DialogConfirmation,
  TestDefinitionIcon,
  Row,
  Column,
} from "~/global";
import actions from "~/actions";
import moduleActions from "../../actions";
import * as moduleSelectors from "../../selectors";
import selectors from "~/selectors";
import { DeviceTestArgument } from "~/models";

import TestDefinitionArgumentsEditor from "../TestDefinitionArgumentsEditor/TestDefinitionArgumentsEditor.component";
import TestDefinitionInclusionEditor from "../TestDefinitionInclusionEditor/TestDefinitionInclusionEditor.component";
import TestDefinitionFailCodesEditor from "../TestDefinitionFailCodesEditor/TestDefinitionFailCodesEditor.component";
import TestDefinitionLocaleEditor from "../TestDefinitionLocaleEditor/TestDefinitionLocaleEditor.component";
import TestDefinitionsSimpleView from "../TestDefinitionsSimpleView/TestDefinitionsSimpleView.component";
import TestsList from "../TestsList/TestsList.component";
import TestCategoriesList from "../TestCategoriesList/TestCategoriesList.component";

import DialogDuplicateDefinition from "./DialogDuplicateDefinition.component";
import DialogDeleteDefinition from "./DialogDeleteDefinition.component";

import "./TestDefinitionsPage.component.scss";

class TestDefinitionsPage extends Component {
  state = {
    advancedView: TestDefinitionsPage.getAdvancedViewState(),
    // csid,
  };

  async componentDidMount() {
    await Promise.all([
      this.props.fetchTestCategories(),
      this.props.fetchTests(),
      this.props.fetchTestConfigs(),
      this.props.fetchFailCodes(),
    ]);

    await this.props.setPlatform(this.props.initiallySelectedPlatform);
    await this.props.setSelectedCategory((this.props.initiallySelectedCategory || []).name);
    await this.props.setSelectedTest(this.props.initiallySelectedTest);
    this.props.setSelectedConfig(this.props.defaultConfig);

    // resets the initial test to select
    this.props.setInitiallySelectedTest();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.csid !== this.props.csid) {
      this.props.fetchTestCategories();
      this.props.fetchTests();
      this.props.fetchTestConfigs();
      this.props.fetchFailCodes();
    }
  }

  static getAdvancedViewState() {
    return localStorage.getItem("tde-advanced-view") === "true";
  }

  static setAdvancedViewState(enabled) {
    if (enabled) {
      localStorage.setItem("tde-advanced-view", "true");
    } else {
      localStorage.removeItem("tde-advanced-view");
    }
  }

  onPlatformChanged = async (platform) => {
    if (await this.confirmRevertModifications()) {
      await this.props.setPlatform(platform);
      await this.props.setSelectedTest(this.props.tests[0]);
      this.props.setSelectedConfig(this.props.defaultConfig);
    }
  };

  onToggleAdvancedView = () => {
    this.setState(
      (state) => ({
        advancedView: !state.advancedView,
      }),
      () => {
        TestDefinitionsPage.setAdvancedViewState(this.state.advancedView);
      }
    );
  };

  onCategoryChanged = async (category) => {
    if (category.name === this.props.selectedCategory.name) {
      return;
    }

    if (await this.confirmRevertModifications()) {
      await this.props.setSelectedCategory(category.name);
      await this.props.setSelectedTest(this.props.tests[0]);
      this.props.setSelectedConfig(this.props.defaultConfig);
    }
  };

  onTestChanged = async (test) => {
    if (test.className === this.props.selectedTest.className) {
      return;
    }

    if (await this.confirmRevertModifications()) {
      await this.props.setSelectedTest(test);
      this.props.setSelectedConfig(this.props.defaultConfig);
    }
  };

  onConfigChanged = async (config) => {
    if (config.identifier === this.props.selectedConfig.identifier) {
      return;
    }

    if (await this.confirmRevertModifications()) {
      this.props.setSelectedConfig(config);
    }
  };

  confirmRevertModifications = () =>
    new Promise((resolve) => {
      if (this.props.hasChanged) {
        this.props.showDialog({
          title: "Revert modifications",
          width: "450px",
          content: (close) => (
            <DialogConfirmation
              onCancel={() => {
                close();
                resolve(false);
              }}
              onConfirm={() => {
                this.props.revertModifications();
                close();
                resolve(true);
              }}
            >
              <p>
                You currently have unsaved modifications on this test configuration, are you sure
                you want to lose your modificatons?
              </p>
            </DialogConfirmation>
          ),
        });
      } else {
        resolve(true);
      }
    });

  onResetToDefault = () => {
    this.props.showDialog({
      title: "Reset to Default Settings",
      width: "450px",
      content: (close) => (
        <DialogConfirmation
          onCancel={close}
          onConfirm={() => {
            this.props.modifySelectedConfig({
              inclusionType: this.props.defaultConfig.inclusionType,
              targetModels: this.props.defaultConfig.targetModels,
              failCodes: this.props.defaultConfig.failCodes,
              arguments: this.props.defaultConfig.arguments,
            });

            close();
          }}
          textButtonConfirm="Reset to Default"
        >
          <p>Discard current settings for this configuration to the default settings?</p>
        </DialogConfirmation>
      ),
    });
  };

  onDuplicate = async () => {
    if (await this.confirmRevertModifications()) {
      this.props.showDialog({
        title: "Duplicate Test Configuration",
        width: "500px",
        content: (close) => (
          <DialogDuplicateDefinition
            onCancel={close}
            onSave={async (newName) => {
              if (!this.props.isDuplicating) {
                const definition = await this.props.duplicateTestDefinition(
                  this.props.selectedConfig,
                  newName
                );

                if (!this.props.errors.duplicate) {
                  this.props.setSelectedConfig(definition);
                  close();
                } else {
                  this.props.showDialog(
                    {
                      title: "Error",
                      content: (close) => (
                        <DialogMessage close={close}>{this.props.errors.duplicate}</DialogMessage>
                      ),
                    },
                    this.onDuplicate
                  );
                }
              }
            }}
          />
        ),
      });
    }
  };

  onCancel = () => {
    this.props.showDialog({
      title: "Revert current modifications",
      width: "450px",
      content: (close) => (
        <DialogConfirmation
          onCancel={close}
          onConfirm={() => {
            this.props.revertModifications();
            close();
          }}
        >
          <p>Are you sure you want to revert your current modifications?</p>
        </DialogConfirmation>
      ),
    });
  };

  onDelete = async () => {
    if (!this.props.isDeleting) {
      const platform = this.props.selectedPlatform.value;
      const { identifier } = this.props.selectedConfig;
      const displayName = this.props.selectedConfig.identifierDisplayName;

      const testSuites = await this.props.isConfigUsedInTestSuite(platform, identifier);

      if (testSuites.length > 0) {
        this.props.showDialog({
          title: "Test Configuration currently used",
          width: "700px",
          content: (close) => (
            <DialogMessage close={close}>
              <p>You can't remove a test configuration that is currently used by a test suite.</p>
              <p>
                In order to delete this test configuration, you have to remove the test "
                <b>{displayName}</b>" from the following test suites:
              </p>

              {testSuites.map((ts) => (
                <pre key={ts.tsid}>
                  - {ts.name} ({ts.tsid}) [{ts.platform}]
                </pre>
              ))}
            </DialogMessage>
          ),
        });
      } else {
        this.props.showDialog({
          title: "Delete Test Configuration",
          width: "450px",
          focusOnMount: true,
          content: (close) => (
            <DialogDeleteDefinition
              onCancel={close}
              onDelete={async () => {
                if (!this.props.isDeleting) {
                  await this.props.deleteTestDefinition(platform, identifier);
                  this.props.setSelectedConfig(this.props.defaultConfig);

                  close();
                }
              }}
            >
              <p>
                Delete <b>{displayName}</b> test configuration.
              </p>
            </DialogDeleteDefinition>
          ),
        });
      }
    }
  };

  canSave = () => {
    if (this.props.isDefaultConfig) {
      return this.props.modelsChanged || this.props.failCodesChanged || this.props.localesChanged;
    }
    return this.props.hasChanged;
  };

  onSave = async () => {
    await this.props.saveConfig(this.props.selectedConfig);

    const errorKeys = keys(this.props.editErrors);

    let title;
    let content;
    if (errorKeys.length > 0) {
      title = "Error";
      content = (
        <ul>
          {errorKeys.map((key) => (
            <li key={`err-${key}`}>{this.props.editErrors[key]}</li>
          ))}
        </ul>
      );
    } else {
      title = "Success";
      content = "The test configuration has been updated successfully.";
    }

    this.props.showDialog({
      title,
      width: "400px",
      content: (close) => <DialogMessage close={close}>{content}</DialogMessage>,
    });
  };

  onSimpleViewConfigClicked = async (className, identifier) => {
    const test = this.props.tests.filter((test) => test.className === className)[0];
    await this.props.setSelectedTest(test);

    const config = this.props.testConfigs.filter((config) => config.identifier === identifier)[0];
    await this.props.setSelectedConfig(config);

    this.onToggleAdvancedView();
  };

  render() {
    if (this.props.categoriesLoading || this.props.testsLoading || this.props.testConfigsLoading) {
      return <AsciSpinner visible />;
    }

    return (
      <div className="test-definitions-page layout-column layout-column--start flex--noshrink">
        <div className="test-definitions-table flex--noshrink layout-column layout-column--start flex--noshrink">
          <SectionHeader className="flex--noshrink">
            <div className="filters layout-row layout-row--start-center">
              {/* <InputSearch
              onChange={this.props.setNameFilter}
              initialValue={this.props.nameFilter}
              placeholder="Search tests"
              trim
            /> */}

              <AsciSelect
                items={this.props.platforms}
                selectedItem={this.props.selectedPlatform}
                valueKey="value"
                onChange={this.onPlatformChanged}
                id="platform-selector"
              />
            </div>

            <div>
              <Switch
                id="switch-advanced-view"
                name="switch-advanced-view"
                label="Advanced"
                onChange={this.onToggleAdvancedView}
                checked={this.state.advancedView}
              />
            </div>
          </SectionHeader>

          <Row className="content-wrapper flex--noshrink" flex>
            {this.state.advancedView ? (
              <Row className="advanced-view" flex>
                <Row className="selection-columns">
                  <Column className="categories-container flex--noshrink">
                    <h2 className="truncate">Test Categories</h2>
                    <TestCategoriesList
                      categories={this.props.categories}
                      selectedCategory={this.props.selectedCategory}
                      onSelect={this.onCategoryChanged}
                    />
                  </Column>

                  <Column className="tests-container flex--noshrink">
                    <h2 className="truncate flex--noshrink">Tests</h2>
                    <Column className="scroll-container flex">
                      <TestsList
                        className="flex"
                        tests={this.props.tests}
                        selectedTest={this.props.selectedTest}
                        onSelect={this.onTestChanged}
                      />
                    </Column>
                  </Column>

                  <Column className="test-configs-container flex--noshrink">
                    <h2 className="truncate flex--noshrink">Test Configurations</h2>
                    <Column className="scroll-container flex">
                      {this.props.testConfigs.map((testDefinition) => (
                        <Column
                          className={classnames("selector-row", {
                            selected:
                              this.props.selectedConfig.identifier === testDefinition.identifier,
                          })}
                          key={testDefinition.identifier}
                          align="center start"
                          onClick={() => this.onConfigChanged(testDefinition)}
                        >
                          <div className="name">
                            {testDefinition.className === testDefinition.identifier
                              ? "Default"
                              : testDefinition.identifierDisplayName}
                          </div>
                        </Column>
                      ))}
                    </Column>
                  </Column>
                </Row>

                <Column className="test-config-editor" flex>
                  <header className="layout-row padding--15">
                    <Column className="margin-right--20" align="center center">
                      <TestDefinitionIcon src={this.props.selectedTest.image} size="40" />
                    </Column>

                    <Column align="center" flex>
                      <Row align="space-between start">
                        <h2>{this.props.selectedTest.displayName}</h2>
                        <span className="config-id">
                          ID: {this.props.selectedConfig.identifier}
                        </span>
                      </Row>

                      <h3>{this.props.selectedTest.description}</h3>
                    </Column>
                  </header>

                  <main className="layout-column flex padding--10">
                    <Tabs
                      fullWidth={false}
                      fullHeight={false}
                      beforeTabChange={[
                        null, // validation for 1st tab
                        null, // validation for 2nd tab
                        () => {
                          // validation for 3rd tab
                          const errors = [];

                          const isValid =
                            this.props.selectedConfig.arguments.filter((arg) => {
                              if (DeviceTestArgument.isTypeLocalizableString(arg)) {
                                const details = this.props.argumentsDetailsByName[arg.name];
                                const values = arg.localization || {};

                                if (values.en === "") {
                                  errors.push(
                                    `Argument "${
                                      details.displayName || arg.name
                                    }" must have an english value or be unselected.`
                                  );
                                  return true;
                                }

                                return false;
                              }
                              return false;
                            }).length === 0;

                          if (!isValid) {
                            this.props.showDialog({
                              title: "Invalid Locales",
                              width: "500px",
                              content: (close) => (
                                <DialogMessage close={close}>
                                  {errors.map((err, index) => (
                                    <div key={`error-${index}`}>{err}</div>
                                  ))}
                                </DialogMessage>
                              ),
                            });
                          }
                          return isValid;
                        },
                        null, // validation for 4th tab
                      ]}
                    >
                      <Tab title="Test Parameters">
                        <TestDefinitionArgumentsEditor
                          configName={this.props.selectedConfig.className}
                          arguments={this.props.selectedConfig.arguments}
                          argumentsDetails={this.props.argumentsDetailsByName}
                          platform={this.props.selectedPlatform.value}
                          disabled={this.props.isDefaultConfig}
                          onChange={(args) =>
                            this.props.modifySelectedConfig({
                              arguments: args,
                            })
                          }
                        />
                      </Tab>

                      <Tab title="Fail Codes">
                        <TestDefinitionFailCodesEditor
                          failCodes={this.props.selectedFailCodes}
                          onChange={(failCodesIds) =>
                            this.props.modifySelectedConfig({
                              failCodes: failCodesIds,
                            })
                          }
                          config={this.props.selectedConfig}
                        />
                      </Tab>

                      <Tab title="Locale">
                        <TestDefinitionLocaleEditor
                          arguments={this.props.selectedConfig.arguments}
                          argumentsDetails={this.props.argumentsDetailsByName}
                          languages={this.props.languages}
                          onChange={(args) =>
                            this.props.modifySelectedConfig({
                              arguments: args,
                            })
                          }
                        />
                      </Tab>

                      <Tab title="Model Inclusion/Exclusion">
                        <TestDefinitionInclusionEditor
                          identifier={this.props.selectedConfig.identifier}
                          inclusionType={this.props.selectedConfig.inclusionType}
                          targetModels={this.props.selectedConfig.targetModels}
                          errors={this.props.editErrors}
                          onChange={(type, models) =>
                            this.props.modifySelectedConfig({
                              inclusionType: type,
                              targetModels: models,
                            })
                          }
                        />
                      </Tab>
                    </Tabs>
                  </main>
                </Column>
              </Row>
            ) : (
              <TestDefinitionsSimpleView
                categories={this.props.categories}
                selectedCategory={this.props.selectedCategory}
                onCategoryChanged={this.onCategoryChanged}
                tests={this.props.testsWithConfigs}
                onConfigClicked={this.onSimpleViewConfigClicked}
              />
            )}
          </Row>

          <Row className="actions flex--noshrink" align="space-between center">
            {this.state.advancedView && (
              <>
                <Row>
                  <AsciButton
                    color="white"
                    disabled={this.props.isDefaultConfig}
                    onClick={this.onResetToDefault}
                  >
                    Reset to default
                  </AsciButton>

                  <AsciButton color="white" onClick={this.onDuplicate}>
                    Duplicate
                  </AsciButton>
                </Row>

                <Row>
                  <AsciButton
                    color="white"
                    disabled={!this.props.hasChanged}
                    onClick={this.onCancel}
                  >
                    Cancel
                  </AsciButton>

                  <AsciButton
                    color="red"
                    disabled={this.props.isDefaultConfig || this.props.isDeleting}
                    onClick={this.onDelete}
                  >
                    Delete
                  </AsciButton>

                  <AsciButton
                    color="green"
                    disabled={
                      !this.canSave() ||
                      this.props.isDuplicating ||
                      this.props.isDeleting ||
                      this.props.isSaving
                    }
                    onClick={this.onSave}
                  >
                    Save
                  </AsciButton>
                </Row>
              </>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

const stateToProps = (state) => ({
  // Customer id
  csid: state.customer.activeCustomerId,

  // filters
  // nameFilter: state.testDefinitions.nameFilter,
  platforms: state.platforms.items,
  initiallySelectedPlatform: selectors.platforms.getInitiallySelectedPlatform(state),
  selectedPlatform: state.platforms.selected,

  // categories
  categories: selectors.testCategories.getSortedCategories(state),
  categoriesLoading: state.testCategories.isLoading,
  initiallySelectedCategory: selectors.testCategories.getInitiallySelectedCategory(state),
  selectedCategory: moduleSelectors.getSelectedTestCategory(state),

  // tests
  tests: selectors.tests.getSortedTests(state),
  testsLoading: state.tests.isLoading,
  initiallySelectedTest: selectors.tests.getInitiallySelectedTest(state),
  selectedTest: state.tests.selected,
  argumentsDetailsByName: selectors.testDefinitions.getArgumentsDetailsByName(state),

  // configs
  testConfigs: selectors.testDefinitions.getSortedDefinitions(state),
  testConfigsLoading: state.testDefinitions.isLoading,
  isDuplicating: state.testDefinitions.isDuplicating,
  isDeleting: state.testDefinitions.isDeleting,
  isSaving: state.modules.testDefinitionsEditor.isSaving,
  defaultConfig: moduleSelectors.getDefaultConfig(state),

  // selected config
  selectedConfig: moduleSelectors.getCurrentTestDefinition(state),
  isDefaultConfig:
    state.modules.testDefinitionsEditor.original.className ===
    state.modules.testDefinitionsEditor.original.identifier,
  hasChanged: moduleSelectors.hasCurrentTestConfigChanged(state),
  modelsChanged: moduleSelectors.hasTestConfigModelsChanged(state),
  failCodesChanged: moduleSelectors.hasTestConfigFailCodesChanged(state),
  localesChanged: moduleSelectors.hasTestConfigLocalesChanged(state),
  selectedFailCodes: moduleSelectors.getSelectedFailCodes(state),

  // simple view
  testsWithConfigs: selectors.testDefinitions.getTestsWithConfigs(state),

  // other
  errors: state.testDefinitions.errors,
  languages: state.languages.items,
  editErrors: moduleSelectors.getErrors(state),
});

const dispatchToProps = {
  // filters
  // setNameFilter: actions.testDefinitions.setNameFilter,
  setPlatform: actions.platforms.setSelectedPlatform,

  // categories
  fetchTestCategories: actions.testCategories.fetchTestCategories,
  setSelectedCategory: (catName) => (dispatch) => {
    // TEMP: until testCategories.setSelectedCategoy is removed
    // Right now it's deeply linked with the getTests and more stuff all over the place...
    dispatch(actions.testCategories.setSelectedCategory(catName));
    dispatch(moduleActions.setSelectedCategory(catName));
  },

  // tests
  fetchTests: actions.tests.fetchTests,
  setSelectedTest: actions.tests.setSelectedTestDetails,

  // configs
  fetchTestConfigs: actions.testDefinitions.fetchTestDefinitions,
  setSelectedConfig: moduleActions.setTestDefinitionOriginal,
  modifySelectedConfig: moduleActions.modifyTestDefinition,
  revertModifications: moduleActions.revertModifications,
  saveConfig: moduleActions.saveTestDefinition,

  // fail codes
  fetchFailCodes: actions.failCodeCategories.fetchFailCodeCategories,

  // other
  showDialog: actions.dialog.show,
  deleteTestDefinition: actions.testDefinitions.deleteTestDefinition,
  duplicateTestDefinition: actions.testDefinitions.duplicateTestDefinition,
  isConfigUsedInTestSuite: actions.testSuites.isUsedInTestSuite,
  setInitiallySelectedTest: actions.tests.setInitiallySelectedTestDetails,
};

export default connect(stateToProps, dispatchToProps)(TestDefinitionsPage);
