import { createReducer } from "@reduxjs/toolkit";

import labelActions from "~/label-printer/actions";
import actions from "~/actions";

const initialState = {
  recordingFields: [],
  customFields: [],
};

export default createReducer(initialState, {
  [labelActions.labelStatics.fetchLabelStaticsSuccess]: (state, action) => {
    const { recordingFields } = action.payload;
    state.recordingFields = recordingFields;
  },
  [actions.customFields.fetchCustomFieldsSuccess]: (state, action) => {
    const { customFields } = action.payload;
    state.customFields = customFields.map((customField) => {
      let preview;
      switch (customField.type) {
        case "numeric":
          preview = customField.defaultNumberValue || 39038;
          break;
        case "string":
          preview = customField.defaultStringValue;
        // No break abuse
        // eslint-disable-next-line no-fallthrough
        default:
          preview = preview || "Custom Field";
      }

      return {
        path: `process.customFields.${customField.name}`,
        name: customField.displayName.en || customField.name,
        preview,
      };
    });
  },
});
