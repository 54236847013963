// Data for the workflow attributes.
import { createAction, createReducer } from "@reduxjs/toolkit";
import concat from "lodash/concat";
import keys from "lodash/keys";
import includes from "lodash/includes";
import startsWith from "lodash/startsWith";

import { setServiceSuiteEdition } from "~/programs-management/actions/serviceSuiteEditor.actions";
import { selectableEvents } from "~/workflow/workflowEvents";

const initialState = {
  name: "",
  workflowId: null,
  description: "",
  selectedEvents: [],
  serviceSuiteId: "",
  expandedBehaviors: [],
  original: null,
};

export const actions = {
  setName: createAction("workflowEditor/setName"),
  setDescription: createAction("workflowEditor/setDescription"),
  setWorkflowId: createAction("workflowEditor/setWorkflowId"),
  addEvent: createAction("workflowEditor/addEvent"),
  reset: createAction("workflowEditor/reset"),
  setExpanded: createAction("workflowEditor/setExpanded"),
};

export const selectors = {
  getName: (state) => state.workflow.workflowEditor.name,
  getWorkflowId: (state) => state.workflow.workflowEditor.workflowId,
  getSelectedEvents: (state) => state.workflow.workflowEditor.selectedEvents,
  isExpanded: (eventId, behaviorId) => (state) =>
    includes(state.workflow.workflowEditor.expandedBehaviors, `${eventId}_${behaviorId}`),
};

export const reducer = createReducer(initialState, {
  [actions.setName]: (state, action) => {
    state.name = action.payload;
  },
  [actions.setWorkflowId]: (state, action) => {
    state.workflowId = action.payload;
  },
  [actions.setDescription]: (state, action) => {
    state.description = action.payload;
  },
  [actions.addEvent]: (state, action) => {
    state.selectedEvents = concat(state.selectedEvents, action.payload);
  },
  [actions.reset]: () => ({ ...initialState }),
  [actions.setExpanded]: (state, action) => {
    const { eventId, behaviorId, expanded } = action.payload;
    state.expandedBehaviors = state.expandedBehaviors.filter((e) => !startsWith(e, `${eventId}_`));
    if (expanded) {
      state.expandedBehaviors = concat(state.expandedBehaviors, [`${eventId}_${behaviorId}`]);
    }
  },
  [setServiceSuiteEdition]: (state, action) => {
    // Add all selected events from saved state.
    const { serviceSuite } = action.payload;
    if (serviceSuite.workflow?._id) {
      delete serviceSuite.workflow._id;
    }
    state.original = serviceSuite.workflow;
    const subbedEvents = keys((serviceSuite.workflow || {}).events || {});
    state.selectedEvents = selectableEvents.filter((event) => subbedEvents.includes(event));
  },
});
