import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";

const TrilingualTextField = ({ text, onTextChanged }) => (
  <div className="column">
    {Object.keys(text || []).map((lang) => (
      <div className="row" key={lang}>
        <div>{lang.toUpperCase()}</div>
        <div>
          <TextField
            required
            id="fail-codes-category-editor-name"
            // error={this.props.errors.name.length > 0}
            // errorText={this.props.errors.name}
            value={text[lang]}
            onChange={(value) => onTextChanged(value, lang)}
            type="text"
            // onKeyPress={e => {
            //     if (!isDisabled && e.key == "Enter") {
            //         this.onConfirm();
            //     }
            // }}
          />
        </div>
      </div>
    ))}
  </div>
);

TrilingualTextField.propTypes = {
  text: PropTypes.object.isRequired,
  onTextChanged: PropTypes.func.isRequired,
};

export default TrilingualTextField;
