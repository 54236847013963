import React from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";

const CustomFieldItemEditor = (props) => {
  const onItemTranslationChanged = (lang, value) => {
    const changes = {
      displayName: { ...props.item.displayName, [lang]: value },
    };
    props.onItemChanged(changes);
  };

  return (
    <div>
      {props.item.displayName != null
        ? Object.keys(props.item.displayName).map((lang, index) => (
            <div className="layout-row layout-row--start-center margin--5" key={lang}>
              <div className="flex--30 flex--nogrow">
                {props.languagesByCode[lang].name}
                {lang == "en" ? <sup>*</sup> : null}
              </div>
              <div className="flex--30 flex--nogrow">
                <TextField
                  id={`custom-field-item-textfield-${index}-${lang}`}
                  placeholder={`Display Name (${lang})`}
                  value={props.item.displayName[lang]}
                  onChange={(value) => onItemTranslationChanged(lang, value)}
                  type="text"
                  error={lang == "en" && props.item.displayName[lang].length === 0}
                  errorText="Required field"
                />
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

CustomFieldItemEditor.propTypes = {
  item: PropTypes.object,
  languagesByCode: PropTypes.object,
  onItemChanged: PropTypes.func.isRequired,
};

CustomFieldItemEditor.defaultProps = {
  item: {},
  languagesByCode: { en: { name: "English", value: "en" } },
};

export default CustomFieldItemEditor;
