import React from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import { connect } from "react-redux";
import { Select, Switch } from "antd";

import labelActions from "~/label-printer/actions";
import labelSelectors from "~/label-printer/selectors";

import InspectorSection from "~/label-printer/components/designer/inspector/commons/InspectorSection";
import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";

const PrintSettingsInspector = (props) => {
  const {
    setFormatData,
    landscape,
    printBackground,
    color,
    margins,
    dpi,
    printer,
    printers,
    setPrinter,
    printerFormats,
    format,
    width,
    height,
  } = props;
  const setterFactory = (key) => (data) => setFormatData({ key: `printSettings.${key}`, data });
  const marginSetter = (key) => setterFactory(`margins.${key}`);
  const sizeSetter = (key) => (data) => setFormatData({ key: `size.${key}`, data });
  const isCustomFormat = format === "custom";
  const isCustomMargins = margins.marginType === "custom";

  return (
    <InspectorSection title="Print Settings" initialFolded>
      <InspectorInput label="Printer">
        <Select value={printer} onChange={setPrinter}>
          {printers.map((p) => (
            <Select.Option value={p.name} key={p.name}>
              {p.name}
            </Select.Option>
          ))}
          <Select.Option value="custom">Other</Select.Option>
        </Select>
      </InspectorInput>
      <InspectorInput label="Format">
        <Select
          value={format}
          onChange={(data) => {
            setFormatData({ key: "name", data });
            if (data === "custom") return;
            const { size } = find(printerFormats, { name: data });
            sizeSetter("width")(size.width);
            sizeSetter("height")(size.height);
          }}
        >
          {printerFormats.map((f) => (
            <Select.Option title={f.inches} key={f.name} value={f.name}>
              {f.name}
            </Select.Option>
          ))}
          <Select.Option value="custom">Other</Select.Option>
        </Select>
      </InspectorInput>
      <InspectorInput label="Landscape">
        <Switch checked={landscape} onChange={setterFactory("landscape")} />
      </InspectorInput>
      <InspectorInput label="Print background">
        <Switch checked={printBackground} onChange={setterFactory("printBackground")} />
      </InspectorInput>
      <InspectorInput label="Print colors">
        <Switch checked={color} onChange={setterFactory("color")} />
      </InspectorInput>
      <InspectorSection title="Size" level={1}>
        <InspectorInput label="Width">
          <NumberInput
            onChange={sizeSetter("width")}
            unit="mm"
            value={width}
            disabled={!isCustomFormat}
          />
        </InspectorInput>
        <InspectorInput label="Height">
          <NumberInput
            onChange={sizeSetter("height")}
            unit="mm"
            value={height}
            disabled={!isCustomFormat}
          />
        </InspectorInput>
      </InspectorSection>
      <InspectorSection title="Margins" level={1} initialFolded>
        <InspectorInput label="Type">
          <Select onChange={marginSetter("marginType")} value={margins.marginType}>
            <Select.Option value="default">Default</Select.Option>
            <Select.Option value="none">None</Select.Option>
            <Select.Option value="printableArea">Printable Area</Select.Option>
            <Select.Option value="custom">Custom</Select.Option>
          </Select>
        </InspectorInput>
        <InspectorInput label="top">
          <NumberInput
            onChange={marginSetter("top")}
            value={margins.top}
            unit="px"
            min={0}
            disabled={!isCustomMargins}
          />
        </InspectorInput>
        <InspectorInput label="Bottom">
          <NumberInput
            onChange={marginSetter("bottom")}
            value={margins.bottom}
            unit="px"
            disabled={!isCustomMargins}
            min={0}
          />
        </InspectorInput>
        <InspectorInput label="left">
          <NumberInput
            onChange={marginSetter("left")}
            value={margins.left}
            unit="px"
            disabled={!isCustomMargins}
            min={0}
          />
        </InspectorInput>
        <InspectorInput label="right">
          <NumberInput
            onChange={marginSetter("right")}
            value={margins.right}
            unit="px"
            disabled={!isCustomMargins}
            min={0}
          />
        </InspectorInput>
      </InspectorSection>
      <InspectorSection title="DPI" level={1} initialFolded>
        <InspectorInput label="Horizontal">
          <NumberInput onChange={setterFactory("dpi.horizontal")} value={dpi.horizontal} min={0} />
        </InspectorInput>
        <InspectorInput label="Vertical">
          <NumberInput onChange={setterFactory("dpi.vertical")} value={dpi.vertical} min={0} />
        </InspectorInput>
      </InspectorSection>
    </InspectorSection>
  );
};

PrintSettingsInspector.propTypes = {
  landscape: PropTypes.bool,
  printBackground: PropTypes.bool,
  color: PropTypes.bool,
  margins: PropTypes.object,
  dpi: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,

  printer: PropTypes.string,
  printers: PropTypes.array,
  printerFormats: PropTypes.array,
  format: PropTypes.string,

  setFormatData: PropTypes.func,
  setPrinter: PropTypes.func,
};

const stateToProps = (state) => {
  const printer = labelSelectors.labelDesigner.getPrinter(state);
  return {
    ...labelSelectors.labelDesigner.getPrintSettings(state),
    width: labelSelectors.labelDesigner.getLabelWidth(state),
    height: labelSelectors.labelDesigner.getLabelHeight(state),
    printers: labelSelectors.labelFormats.getPrinters(state),
    printerFormats: labelSelectors.labelFormats.getPrinterFormats(printer)(state),
    format: labelSelectors.labelDesigner.getFormatName(state),
    printer,
  };
};

const dispatchToProps = {
  setFormatData: labelActions.labelDesigner.setFormatData,
  setPrinter: labelActions.labelDesigner.setPrinter,
};

export default connect(stateToProps, dispatchToProps)(PrintSettingsInspector);
