import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

type ProgramImportResponse = {
  message: string;
};

type ServiceError = { json: { message?: string } };

export const importProgram = createAsyncThunk<
  ProgramImportResponse,
  FormData,
  { rejectValue: string }
>("programs/importOne", async (formData, { rejectWithValue }) => {
  try {
    const response = (await CustomerManagement.POST(
      "/program/import",
      formData
    )) as ProgramImportResponse;

    return response;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

interface ProgramsState {
  importStatus: "idle" | "loading";
}

const initialState: ProgramsState = {
  importStatus: "idle",
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Import
      .addCase(importProgram.pending, (draftState) => {
        draftState.importStatus = "loading";
      })
      .addCase(importProgram.fulfilled, (draftState) => {
        draftState.importStatus = "idle";
      })
      .addCase(importProgram.rejected, (draftState) => {
        draftState.importStatus = "idle";
      });
  },
});

export default programsSlice.reducer;
