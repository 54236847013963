import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FontIcon, TextField } from "react-md";
import { useParams } from "react-router-dom";
import {
  selectors as workstationSelectors,
  actions as workstationActions,
} from "~/workstation/redux";
import DetailsField from "../../../global/reduxer/components/details/DetailsField";
import DetailsPage from "../../../global/reduxer/components/details/DetailsPage";

import SessionList from "~/workstation/components/listings/SessionList";
import TaskList from "~/workstation/components/listings/TaskList";
import DetailsSection from "~/global/reduxer/components/details/DetailsSection";
import "./WorkstationDetails.scss";
import TaskSender from "~/workstation/components/common/TaskSender";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import DateFilter from "~/global/reduxer/components/DateFilter";
import AsciButton from "~/global/AsciButtons/AsciButton/AsciButton.component";
import { RecordingList } from "~/workstation/components/common/WorkstationRecording";
import IssueList from "~/workstation/components/listings/IssueList";
import CrashReportList from "~/workstation/components/listings/CrashReportList";

const SystemInfo = (props) => {
  const { manufacturer, model, version, serial, uuid } = props;
  return (
    <DetailsSection sectionTitle="System">
      <DetailsField label="Manufacturer" value={manufacturer} />
      <DetailsField label="Model" value={model} />
      <DetailsField label="Version" value={version} />
      <DetailsField label="Serial Number" value={serial} />
      <DetailsField label="UUID" value={uuid} />
    </DetailsSection>
  );
};

const OsInfo = (props) => {
  const { platform, distro, release, kernel, arch, hostname, build, servicepack } = props;
  return (
    <DetailsSection sectionTitle="OS">
      <DetailsField label="Platform" value={platform} />
      <DetailsField label="Distro" value={distro} />
      <DetailsField label="Release" value={release} />
      <DetailsField label="Kernel" value={kernel} />
      <DetailsField label="Arch" value={arch} />
      <DetailsField label="Hostname" value={hostname} />
      <DetailsField label="Build" value={build} />
      <DetailsField label="Service Pack" value={servicepack} />
    </DetailsSection>
  );
};

const ComputerInfo = (props) => {
  const { os, system } = props;
  return (
    <div className="double-section">
      <SystemInfo {...system} />
      <OsInfo {...os} />
    </div>
  );
};

const WorkstationDetails = () => {
  const [workstationName, setWorkstationName] = useState(undefined);
  const { workstationId } = useParams();
  const dispatch = useDispatch();

  const workstation = useSelector(workstationSelectors.workstationDetails.getResults);

  useEffect(() => {
    if (!workstationName && workstation && workstation.name) {
      setWorkstationName(workstation.name);
    }
  }, [workstationName, workstation]);

  const onSaveName = () =>
    dispatch(workstationActions.workstationNamer.fetch(workstationId, workstationName));
  const onRefresh = () => {
    dispatch(workstationActions.workstationDetails.fetchReset());
    dispatch(workstationActions.sessions.fetchReset());
    dispatch(workstationActions.tasks.fetchReset());
    dispatch(workstationActions.issues.fetchReset());
  };

  return (
    <DetailsPage
      pageTitle={`Workstation - ${workstationId}${workstation ? ` - (${workstation.name})` : ""}`}
    >
      <div className="action-list">
        <TaskSender workstationIds={[workstationId]} />
        <div className="name-changer">
          <TextField onChange={setWorkstationName} value={workstationName} />
          <AsciButton onClick={onSaveName} color="blue">
            Save name
          </AsciButton>
        </div>
        <AsciButton onClick={onRefresh} color="blue">
          <FontIcon iconClassName="icon-refresh" />
          &nbsp;Refresh
        </AsciButton>
      </div>
      <ReduxerControlled
        selectors={workstationSelectors.workstationDetails}
        actions={workstationActions.workstationDetails}
        fetchArguments={[workstationId]}
      >
        {workstation ? <ComputerInfo {...workstation.info} /> : null}
      </ReduxerControlled>
      <div className="double-section">
        <DetailsSection sectionTitle="Sessions" className="details-table">
          <div className="details-filters">
            <DateFilter
              selectors={workstationSelectors.sessions}
              actions={workstationActions.sessions}
              identifier="begin"
              reducerName="sessions"
            />
          </div>
          <SessionList workstationId={workstationId} />
        </DetailsSection>
        <DetailsSection
          sectionTitle={
            <div className="details-title-bar">
              <div className="details-title-bar-title">Tasks</div>
              <TaskSender workstationIds={[workstationId]} btnLabel="" />
            </div>
          }
          className="details-table"
        >
          <TaskList workstationId={workstationId} />
        </DetailsSection>
      </div>
      <DetailsSection sectionTitle="Issues" expandable className="details-table">
        <IssueList workstationId={workstationId} />
      </DetailsSection>
      <DetailsSection sectionTitle="Workstation recordings" expandable>
        <div className="details-filters">
          <DateFilter
            selectors={workstationSelectors.workstationDetails}
            actions={workstationActions.workstationDetails}
            identifier="recordings"
          />
        </div>
        {workstation && (
          <RecordingList
            recordings={workstation.recordings}
            pageType="workstation"
            recordingType="recordings"
          />
        )}
      </DetailsSection>
      <DetailsSection sectionTitle="Crash Reports" expandable className="details-table">
        {workstation && <CrashReportList crashReports={workstation.crashReports} />}
      </DetailsSection>
    </DetailsPage>
  );
};

export default WorkstationDetails;
