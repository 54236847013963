import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash/lang";
import * as utils from "~/global/utils";

const getDeploymentOriginal = (state) => state.modules.deploymentManagement.deployment.original;
const getDeploymentModifications = (state) =>
  state.modules.deploymentManagement.deployment.modifications;

const getDeploymentIsLoading = (state) => state.modules.deploymentManagement.deployment.isLoading;

const getFacilityIsLoading = (state) => state.facility.status === "loading";

export const getDeploymentIsEqual = createSelector([getDeploymentModifications], (modifications) =>
  isEmpty(modifications)
);

export const getUpdatedDeployment = createSelector(
  [getDeploymentOriginal, getDeploymentModifications],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getDeploymentName = createSelector(
  [getUpdatedDeployment],
  (deployment) => deployment.name || ""
);

export const getShifts = createSelector(
  [getUpdatedDeployment],
  (deployment) => deployment.shifts || []
);

export const getPrograms = createSelector(
  [getUpdatedDeployment],
  (deployment) => deployment.programs || []
);

export const getCustomFields = createSelector(
  [getUpdatedDeployment],
  (deployment) => deployment.customFields || []
);

export const getIsLoading = createSelector(
  [getDeploymentIsLoading, getFacilityIsLoading],
  (deploymentIsLoading, facilityIsLoading) => {
    const isLoading = deploymentIsLoading || facilityIsLoading;
    return isLoading;
  }
);

export const getShiftsErrorMessage = createSelector([getShifts], (shifts) => {
  const messages = [];
  const hasValidNames =
    (shifts || []).filter((s) => utils.getRegexValidation("Shift_Name", s.name)).length ===
    (shifts || []).length;
  const hasDuplicates = utils.arrayHasDuplicate(shifts || [], "name");
  if (!hasValidNames) {
    messages.push(
      "A valid name is required for each shift: 2-30 characters, alphanumeric, /#_-+() and spaces are allowed only."
    );
  }
  if (hasDuplicates) {
    messages.push("Shift name already in use, please enter a new name.");
  }
  return messages;
});

export const getProgramsErrorMessage = createSelector([getPrograms], (programs) => {
  const messages = [];
  if (programs.length === 0) {
    messages.push("At least one program must be selected.");
  }
  return messages;
});

export const getErrorMessages = createSelector(
  [getShiftsErrorMessage, getProgramsErrorMessage, getUpdatedDeployment],
  (shiftsErrorMessage, programsErrorMessage, deployment) => {
    const nameFilled = deployment.name != null && deployment.name.length > 0;
    const messages = [].concat(programsErrorMessage, shiftsErrorMessage);
    if (!nameFilled) {
      messages.push("Deployment name is required.");
    }
    return messages;
  }
);

export const getShiftsValid = createSelector(
  [getShiftsErrorMessage],
  (shiftsValidMessage) => shiftsValidMessage.length === 0
);

export const getProgramsValid = createSelector(
  [getProgramsErrorMessage],
  (programsValidMessage) => programsValidMessage.length === 0
);

export const getDeploymentSaveEnabled = createSelector(
  [getDeploymentIsEqual, getUpdatedDeployment, getShiftsValid, getProgramsValid],
  (deploymentIsEqual, deployment, shiftsValid, programsValid) => {
    const nameFilled = deployment.name != null && deployment.name.length > 0;

    return !deploymentIsEqual && nameFilled && shiftsValid && programsValid;
  }
);
