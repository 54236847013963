import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableRow,
  TableHeader,
  ExpansionList,
  ExpansionPanel,
} from "react-md";

import { Row, Column } from "~/global";

import moduleActions from "~/device-history/actions/";
import "./FailDetails.component.scss";

const FailDetails = ({ failDetails, tests, includeToPrint, toggleIncludeToPrint }) => {
  const [failCodes, setFailCodes] = useState([]);

  useEffect(() => {
    buildSectionData();
  }, []);

  const includeForPrint = () => {
    toggleIncludeToPrint(includeToPrint);
  };

  const buildSectionData = () => {
    const failCodesFromFailDetails = failDetails.failCodes;
    const failCodesFromTests = [];

    tests.forEach((test) => {
      if (test.failCodes !== undefined) {
        failCodesFromTests.push(...(test.failCodes || []));
      }
    });

    const failCodesList = failCodesFromFailDetails || failCodesFromTests;

    setFailCodes(sortBy(failCodesList, "priority").reverse());
  };

  const renderTable = () => (
    <Row className="fail-details full-width">
      <Column className="full-width">
        <DataTable baseId="certificate-info-table-1" plain className="fail-details-table">
          <TableHeader className="table-header">
            <TableRow>
              <TableColumn className="header-label">
                <div className="section-banner ">Priority</div>
              </TableColumn>

              <TableColumn className="header-label">
                <div className="section-banner ">Category</div>
              </TableColumn>

              <TableColumn className="header-label">
                <div className="section-banner ">Code</div>
              </TableColumn>

              <TableColumn className="header-label">
                <div className="section-banner ">Description</div>
              </TableColumn>
            </TableRow>
          </TableHeader>

          <TableBody className="table-body">
            <>
              {failCodes.length > 0 &&
                failCodes.map((failCode, index) => (
                  <TableRow key={index}>
                    <TableColumn className="table-data" style={{ minWidth: "200px" }}>
                      {failCode.priority || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-data" style={{ minWidth: "200px" }}>
                      {failCode.category || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-data" style={{ minWidth: "200px" }}>
                      {failCode.code || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-data" style={{ minWidth: "200px" }}>
                      {failCode.description || "N/A"}
                    </TableColumn>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </DataTable>
      </Column>
    </Row>
  );

  return (
    <div className={includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"}>
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={<div className="panel-text title bold-screen printable-title">Fail Details</div>}
          secondaryLabel={
            <div className="panel-text no-printable">
              {includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          footer={null}
          onExpandToggle={() => includeForPrint()}
          expanded={includeToPrint}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          {renderTable()}
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeFailDetailsToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeFailDetailsToPrint,
};

export default connect(stateToProps, dispatchToProps)(FailDetails);
