import React, { useEffect, useMemo, useState } from "react";
import { Spin, Switch } from "antd";
import { BarChartOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "~/i18n";
import { useAppSelector, useAppDispatch } from "~/hooks";

import { Row, Column, ToggleButton } from "~/global";
import { getPercentage } from "~/global/utils";
import { DASHBOARD_COLORS } from "~/constants";
import UphLineChart from "../UphLineChart/UphLineChart.component";
import UphLineChartLabels from "../UphLineChartLabels/UphLineChartLabels.component";
import UphHeatMap from "../UphHeatMap/UphHeatMap.component";
import { DashboardFiltersParams } from "../../models/typeAndToolDashboard";
import {
  fetchDevicesProcessed,
  selectCleanUphData,
  selectDevicesProcessed,
  selectDevicesProcessedTotal,
  selectDevicesProcessedStatus,
} from "../../models/devicesProcessed.slice";
import { fetchTeamPerformance } from "../../models/teamPerformance.slice";
import type { FiltersState } from "../DashboardPage/DashboardPage.component";

import "./StatisticsTable.component.scss";
import BarChartGraph from "~/features/reporting-chart/BarChartGraph.component";
import { fetchChartData, selectChartData } from "~/features/reporting-chart/reportingChart.slice";
import { ChartReportTypeName, GroupByValue } from "~/actions/reportTypes.actions";
import { ChartFilters } from "~/pages/reporting/ReportingFiltersAndChart";
import { SelectedFacet } from "~/features/recording-facets/RecordingFacetsFilter.component";

type Props = {
  level: string;
  setLevel: (level: string) => void;
  currentFilters: FiltersState;
  setCurrentFilters: (filters: FiltersState) => void;
  chartTimezone: string | undefined;
};
const StatisticsTable = ({
  level,
  setLevel,
  currentFilters,
  setCurrentFilters,
  chartTimezone,
}: Props) => {
  // Configuration == Service Suite

  const { t } = useTranslation("dashboard");
  const [selectedView, setSelectedView] = useState("heatMap");

  const [isPercentageShown, setIsPercentageShown] = useState(false);

  const dispatch = useAppDispatch();

  const uphData = useAppSelector(selectCleanUphData);
  const devicesProcessed = useAppSelector(selectDevicesProcessed);
  const status = useAppSelector(selectDevicesProcessedStatus);
  const devicesProcessedTotal = useAppSelector(selectDevicesProcessedTotal);

  const showConfigurations = level === "configurations";
  const showTeams = level === "teams";
  const showOperators = level === "operators";
  const currentLevel = showConfigurations
    ? t("service-suites")
    : showTeams
    ? t("teams")
    : t("operators");

  useEffect(() => {
    const facets: SelectedFacet[] = [];

    if (currentFilters.configurationId !== "all")
      facets.push([
        "process.configurationInformation.serviceSuite",
        currentFilters.configurationId,
      ]);
    else if (currentFilters.programId !== "all")
      facets.push(["process.configurationInformation.program", currentFilters.programId]);
    else if (currentFilters.teamId !== "all") facets.push(["process.team", currentFilters.teamId]);
    else if (currentFilters.shiftId !== "all")
      facets.push(["process.shift", currentFilters.shiftId]);

    const chartFilters: ChartFilters = {
      dateFrom: currentFilters.dateFrom as string,
      dateTo: currentFilters.dateTo as string,
      dateRange: "custom",
      facilityId: currentFilters.facilityId || "all",
      facets,
      reportTypeName: "auditsByOperator" as ChartReportTypeName,
      timeZone: "America/Toronto",
      uniqueness: "none",
    };

    void dispatch(
      fetchChartData({
        chartFilters,
        groupByValue: "tests.title.en" as GroupByValue,
      })
    );
  }, [
    currentFilters.configurationId,
    currentFilters.dateFrom,
    currentFilters.dateTo,
    currentFilters.facilityId,
    currentFilters.programId,
    currentFilters.shiftId,
    currentFilters.teamId,
    dispatch,
  ]);
  const chartData = useAppSelector(selectChartData);

  const drillDown = (itemId: string, programId: string) => {
    let filters;

    if (programId) {
      if (currentFilters.teamId === "all") {
        setLevel("teams");
      } else {
        setLevel("operators");
      }
      filters = { ...currentFilters, programId, configurationId: itemId };
    } else {
      setLevel("operators");
      filters = { ...currentFilters, teamId: itemId };
    }

    setCurrentFilters(filters);
    const itemsFilters: DashboardFiltersParams = {
      facilityID: filters?.facilityId,
      deploymentID: filters?.deploymentId,
      shiftID: filters?.shiftId,
      teamID: filters?.teamId || "",
      programID: filters?.programId,
      configID: filters?.configurationId,
      timezone: chartTimezone,
      from: filters?.dateFrom,
      to: filters?.dateTo,
    };

    void dispatch(fetchDevicesProcessed(itemsFilters));

    void dispatch(fetchTeamPerformance(itemsFilters));
  };
  const isTitle = useMemo(
    () =>
      selectedView === "audit" ? currentLevel + t("audit-by-test") : t("process-runs-per-hours"),
    [currentLevel, selectedView, t]
  );

  return (
    <Column className="statistics-table">
      <Row className="statistics-table-header">
        <Row
          className="table-header statistics-table-row"
          flex
          basis="40%"
          align="space-around center"
        >
          <Row flex basis="25%" align="start">
            {currentLevel}
          </Row>
          <Row flex basis="25%" align="center">
            {t("average-prph")}
          </Row>
          <Row flex basis="25%" align="center">
            {t("audit-fail")}
          </Row>
          <Row flex basis="25%" align="center">
            {t("process-runs")}
          </Row>
        </Row>

        <Row
          className="charts-header statistics-table-row"
          flex
          basis="60%"
          align="space-around center"
        >
          <ul>
            <li>{isTitle}</li>
            <li>
              <Row>
                <ToggleButton
                  isActive={selectedView === "lineChart"}
                  onClick={() => {
                    setSelectedView("lineChart");
                  }}
                >
                  <i className="icon-stats-dots" />
                  {t("graph")}
                </ToggleButton>

                <ToggleButton
                  isActive={selectedView === "heatMap"}
                  onClick={() => {
                    setSelectedView("heatMap");
                  }}
                >
                  <i className="icon-th" />
                  {t("heatmap")}
                </ToggleButton>
                <ToggleButton
                  key={currentLevel}
                  isActive={selectedView === "audit"}
                  onClick={() => {
                    setSelectedView("audit");
                  }}
                >
                  <BarChartOutlined />
                  {t("audit")}
                </ToggleButton>
              </Row>
            </li>
          </ul>
        </Row>
      </Row>

      <Row className="statistics-table-body" align="start start">
        <Column
          className={`table-body ${showOperators ? "last-level" : ""} ${
            devicesProcessed?.length === 0 ? "no-data-uph" : ""
          }`}
          flex
          basis="40%"
        >
          {status === "pending" ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
              size="large"
            />
          ) : devicesProcessed?.length === 0 ? (
            <Row className="no-data" align="center center">
              <i className="icon-ban" />
              {t("no-data-to-be-displayed")}
            </Row>
          ) : (
            devicesProcessed?.map((row, index) => (
              <Row
                className="statistics-table-row"
                align="space-around center"
                key={`${row.programId}-${row.itemId}-${index + 1}}`}
                style={{ borderLeft: `4px solid ${DASHBOARD_COLORS[index % 5]}` }}
                onClick={!showOperators ? () => drillDown(row.itemId, row.programId) : null}
              >
                <Row flex basis="25%" align="start center">
                  <Row>
                    <i className="icon-angle-right" />
                  </Row>
                  <Column className="item-name">
                    <div className="text-overflow-ellipsis">{row.itemName}</div>
                    {showConfigurations ? (
                      <div className="text-overflow-ellipsis small-text">{row.programName}</div>
                    ) : null}
                  </Column>
                </Row>
                <Column flex basis="25%" align="start center">
                  {row.averageUph}
                </Column>
                <Column flex basis="25%" align="start center">
                  {row.audit === 0 ? (
                    t("null")
                  ) : (
                    <>
                      <Row>{getPercentage(row.auditFail, row.audit)}</Row>
                      <Row>
                        {t("audit-fail-ratio", { auditFail: row.auditFail, audit: row.audit })}
                      </Row>
                    </>
                  )}
                </Column>
                <Column flex basis="25%" align="start center">
                  {row.devices}
                </Column>
              </Row>
            ))
          )}
        </Column>

        <Column
          className={`charts-body  ${
            chartData.length === 0 && selectedView === "audit"
              ? "no-data-uph"
              : devicesProcessed?.length === 0 && selectedView !== "audit"
              ? "no-data-uph"
              : ""
          }`}
          flex
          basis="60%"
        >
          {status === "pending" ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
              size="large"
            />
          ) : devicesProcessed?.length === 0 ? (
            <Row className="no-data" align="center center">
              <i className="icon-ban" />
              {t("no-data-to-be-displayed")}
            </Row>
          ) : selectedView === "lineChart" && uphData ? (
            devicesProcessed?.map((row, index) => (
              <Row
                className="statistics-table-row line-chart"
                key={`${row.programId}-${row.itemId}-${index + 1}`}
              >
                <UphLineChart
                  data={uphData[index].row}
                  xAxisDataKey="time"
                  lineDataKey="uph"
                  lineColor={DASHBOARD_COLORS[index % 5]}
                  averageUph={row.averageUph}
                />
              </Row>
            ))
          ) : selectedView === "heatMap" && uphData ? (
            devicesProcessed?.map((row, index) => {
              const currentColor = DASHBOARD_COLORS[index % 5];

              return (
                <Row
                  className="statistics-table-row heat-map"
                  key={`${row.programId}-${row.itemId}-${index + 1}`}
                >
                  <UphHeatMap
                    data={uphData[index].row}
                    color={currentColor}
                    targetUph={row.averageUph}
                  />
                </Row>
              );
            })
          ) : selectedView === "audit" ? (
            chartData.length === 0 ? (
              <Row className="no-data" align="center center">
                <i className="icon-ban" />
                {t("no-data-to-be-displayed")}
              </Row>
            ) : (
              <>
                <div className="switch-dashboard">
                  <Row>
                    <Switch
                      checked={isPercentageShown}
                      onChange={(checked) => {
                        setIsPercentageShown(checked);
                      }}
                      checkedChildren={t("chart.percentage-switch")}
                      unCheckedChildren={t("chart.count-switch")}
                    />
                  </Row>
                </div>
                <BarChartGraph chartData={chartData} isPercentageShown={isPercentageShown} />
              </>
            )
          ) : null}
        </Column>
      </Row>

      <Row className="statistics-table-footer">
        <Row
          className="table-footer statistics-table-row"
          flex
          basis="40%"
          align="space-around center"
        >
          <Row flex basis="25%" align="center">
            {t("total")}
          </Row>

          {status === "pending" ? (
            <>
              <Row flex basis="25%" align="center">
                {t("null")}
              </Row>
              <Row flex basis="25%" align="center">
                {t("null")}
              </Row>
              <Row flex basis="25%" align="center">
                {t("null")}
              </Row>
            </>
          ) : (
            <>
              <Row flex basis="25%" align="center">
                {devicesProcessedTotal.averageUph}
              </Row>
              <Row flex basis="25%" align="center">
                {getPercentage(devicesProcessedTotal.auditFail, devicesProcessedTotal.audit)}
              </Row>
              <Row flex basis="25%" align="center">
                {devicesProcessedTotal.devices}
              </Row>
            </>
          )}
        </Row>
        {uphData && uphData.length > 0 && selectedView !== "audit" ? (
          <Row className="charts-footer statistics-table-row" flex basis="60%">
            <UphLineChartLabels
              data={uphData[0].row}
              xAxisDataKey="time"
              timezone={chartTimezone}
            />
          </Row>
        ) : (
          <Row className="charts-footer statistics-table-row" flex basis="60%" />
        )}
      </Row>
    </Column>
  );
};

export default StatisticsTable;
