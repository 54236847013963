import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const fetchLabelsBegin = createAction("labelLister/fetchLabelsBegin");
export const fetchLabelsSuccess = createAction("labelLister/fetchLabelsSuccess");
export const fetchLabelsError = createAction("labelLister/fetchLabelsError");
export const setLoaded = createAction("labelLister/setLoaded");

export const fetchLabels = () => (dispatch) => {
  dispatch(fetchLabelsBegin());

  const query = CustomerManagement.gqlBuilder(`{
        labels {
            id,
            name,
            printer,
            format,
            layoutName
        }
    }`);

  return CustomerManagement.QUERY(query)
    .then((response) => dispatch(fetchLabelsSuccess(response.payload.data.labels)))
    .catch((error) => dispatch(fetchLabelsError(error)));
};
