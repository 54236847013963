import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableRow,
  TableHeader,
  ExpansionList,
  ExpansionPanel,
} from "react-md";

import { Row, Column } from "~/global";
import { secondsFormat, formatDuration } from "~/global/utils";

import moduleActions from "~/device-history/actions/";
import deploymentActions from "~/deployment-management/actions/";
import moduleSelectors from "~/device-history/selectors/";

import "./ConfigurationInformation.component.scss";

const ConfigurationInformation = (props) => {
  const tests = props.tests || []; // TODO: do this in a selector

  const [testsCount, setTestsCount] = useState(tests.length);
  const [testsPassed, setTestsPassed] = useState(0);
  const [testsFailed, setTestsFailed] = useState(0);
  const [startDate, setStartDate] = useState(props.process.startDateTime);
  const [endDate, setEndDate] = useState(props.process.endDateTime);

  useEffect(() => {
    const dpid = props.tenantIdentifier.portalDeploymentID;
    const faid = props.tenantIdentifier.portalFacilityID;

    props.fetchDeployment(faid, dpid);
  }, []);

  useEffect(() => {
    getTestsCount();
  }, [props.tests]);

  useEffect(() => {
    formatTimeValues();
  }, [startDate, endDate]);

  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  const formatTimeValues = () => {
    let startTime = moment(startDate);
    let endTime = moment(endDate);

    startTime = startTime.format("YYYY-MM-DD HH:mm:ss");
    setStartDate(startTime);
    endTime = endTime.format("YYYY-MM-DD HH:mm:ss");
    setEndDate(endTime);
  };

  const getTestsCount = () => {
    let testsFailedCount = 0;
    let testPassedCount = 0;

    tests.forEach((test) => {
      if (test.status === "FAIL") {
        testsFailedCount++;
      } else if (test.status === "PASS") {
        testPassedCount++;
      }
    });

    setTestsFailed(testsFailedCount);
    setTestsPassed(testPassedCount);
    setTestsCount(testPassedCount + testsFailedCount);
  };

  const renderDuration = (units, value) => {
    const duration = secondsFormat(units, value);
    const time = duration.isNaN ? "N/A" : formatDuration(duration);

    return time;
  };

  const formattedCollectDate = () => {
    let time = moment(props.inputOrigin.dateTime);
    time = time.format("YYYY-MM-DD HH:mm:ss");

    return time;
  };

  return (
    <div
      className={
        props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
      }
    >
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={
            <div className="panel-text title bold-screen printable-title">
              Session &amp; Configuration
            </div>
          }
          secondaryLabel={
            <div className="panel-text no-printable">
              {props.includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          onExpandToggle={() => includeForPrint()}
          expanded={props.includeToPrint}
          footer={null}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          <Row className="configuration-details full-width">
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="session-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Session</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Start Date:</TableColumn>
                    <TableColumn className="table-data">{startDate}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">End Date:</TableColumn>
                    <TableColumn className="table-data">{endDate}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Duration :</TableColumn>
                    <TableColumn className="table-data">
                      {renderDuration(props.process.timeUnits, props.process.time)}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Collect Date (Date-In):</TableColumn>
                    <TableColumn className="table-data">{formattedCollectDate()}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Tests Evaluated:</TableColumn>
                    <TableColumn className="table-data">{testsCount}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Tests Passed:</TableColumn>
                    <TableColumn className="table-data">{testsPassed}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Tests Failed:</TableColumn>
                    <TableColumn className="table-data">{testsFailed}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>

            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="configuration-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Configuration</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Facility ID:</TableColumn>
                    <TableColumn className="table-data">
                      {props.tenantIdentifier.portalFacilityID}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Facility Name:</TableColumn>
                    <TableColumn className="table-data">{props.facilityName || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Deployment ID:</TableColumn>
                    <TableColumn className="table-data">
                      {props.tenantIdentifier.portalDeploymentID}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Deployment Name:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deploymentName || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Program:</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.configurationInformation.program}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Service Suite:</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.configurationInformation.serviceSuite}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Test Suite:</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.configurationInformation.testSuite}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};
const stateToProps = (state, props) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeConfigInfoToPrint,
  facilityName: moduleSelectors.configurationsInformation.getFacilityNameByID(state, props),
  deploymentName: state.modules.deploymentManagement.deployment.original.name,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeConfigurationInformation,
  fetchDeployment: deploymentActions.deployment.fetchDeployment,
};

export default connect(stateToProps, dispatchToProps)(ConfigurationInformation);
