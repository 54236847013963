import { createSelector } from "@reduxjs/toolkit";
import { getFlatten } from "~/global/utils";

const getWdsData = (state) => state.modules.deviceHistory.deviceInfosWDS.items;

export const getDeviceInfosFlat = createSelector([getWdsData], (wdsData) => {
  const flatData = (wdsData || []).map((data) => getFlatten(data));
  return flatData[0];
});

export const getDeviceInfosGrouped = createSelector([getDeviceInfosFlat], (flatData) => {
  const data = Object.keys(flatData || [])
    .map((key) => {
      const title = key.split(".").pop();
      const group = key.split(".")[0];
      return {
        value: flatData[key],
        id: key,
        title,
        group,
      };
    })
    .filter((wdsInfoExtra) => wdsInfoExtra.value.length > 0);
  return data;
});
