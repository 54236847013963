import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "react-md";

import "./UserAvatar.component.scss";

const UserAvatar = ({ background, initials }) => (
  <Avatar suffix={background} className="center-block user-avatar">
    {initials}
  </Avatar>
);

UserAvatar.propTypes = {
  initials: PropTypes.string,
  background: PropTypes.string,
};

UserAvatar.defaultProps = {
  initials: "",
  background: "grey",
};

export default UserAvatar;
