import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AsciButton } from "~/global";

import moduleSelectors from "../../selectors";
import { useTranslation } from "~/i18n";

const CustomerWizardButtons = (props) => {
  const { t } = useTranslation("customerWizardButtons");

  return (
    <div className="layout-row layout-row--space-between-center full-width">
      <div className="info-message layout-column">
        <div>{t("all-fields-marked-with-an-asterisk")}</div>
        <div>{t("save-available-once-all")}</div>
      </div>
      <div className="layout-row layout-row--end-center">
        <AsciButton color="white" onClick={() => props.onCancel()}>
          <span>{t("button-cancel")}</span>
        </AsciButton>
        <AsciButton
          color="blue"
          disabled={props.saveDisabled || props.isLoading}
          onClick={() => props.onSave()}
        >
          <span>{t("button-save")}</span>
        </AsciButton>
      </div>
    </div>
  );
};

CustomerWizardButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

CustomerWizardButtons.defaultProps = {
  onSave: () => null,
};

const stateToProps = (state) => ({
  saveDisabled: moduleSelectors.customerWizard.saveDisabled(state),
  isLoading: state.customers.isLoading,
});

export default connect(stateToProps, null)(CustomerWizardButtons);
