import React, { Component } from "react";

import "./StatusIcon.component.scss";

export default class StatusIcon extends Component {
  renderIcon() {
    const status = this.props.status != null ? this.props.status.replace(/\_/g, " ") : "";
    switch (this.props.status) {
      case "PASS":
        return (
          <div className="row status-container status-pass">
            <i className="icon-check status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "FAIL":
        return (
          <div className="row status-container status-fail">
            <i className="icon-close status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "SKIPPED":
        return (
          <div className="row status-container status-skipped">
            <i className="icon-mail-forward status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "CRASH":
        return (
          <div className="row status-container status-crash">
            <i className="icon-exclamation-triangle status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "UNSUPPORTED":
        return (
          <div className="row status-container status-unsupported">
            <i className="icon-question-circle status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "INCOMPLETE":
        return (
          <div className="row status-container status-incomplete">
            <i className="icon-info-circle status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      case "RETRY":
        return (
          <div className="row status-container status-retry">
            <i className="icon-exclamation-circle status-icon" />
            <div>{status}</div>
          </div>
        );
        break;
      default:
        return (
          <div className="row status-container">
            <div>{status}</div>
          </div>
        );
        break;
    }
  }

  render() {
    return this.renderIcon(this);
  }
}
