import React, { useState } from "react";
import PropTypes from "prop-types";

import { AsciButton, Row, Column } from "~/global";
import TestSuiteBuilder from "../TestSuiteBuilder/TestSuiteBuilder.component";

const TestSelectionDialog = (props) => {
  const [identifiers, setIdentifiers] = useState(props.selectedIdentifiers);

  const onAdd = () => {
    props.onChange(identifiers);
    props.close();
  };

  return (
    <Column flex>
      <TestSuiteBuilder
        categories={props.categories}
        selectedIdentifiers={identifiers}
        onChange={setIdentifiers}
      />

      <Row className="dialog-actions" align="end">
        <AsciButton color="white" tabIndex={1} onClick={props.close}>
          Cancel
        </AsciButton>

        <AsciButton color="green" tabIndex={2} onClick={onAdd}>
          {props.selectedIdentifiers.length > 0 ? "Select" : "Add"}
        </AsciButton>
      </Row>
    </Column>
  );
};

TestSelectionDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  selectedIdentifiers: PropTypes.arrayOf(PropTypes.string),
};

TestSelectionDialog.defaultProps = {
  categories: [],
  selectedIdentifiers: [],
};

export default TestSelectionDialog;
