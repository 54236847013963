import React from "react";
import classnames from "classnames";

import "./StaticField.component.scss";

const StaticField = ({ label, children, className }) => (
  <div className={classnames("cc-static-field layout-row layout-row--start-center", className)}>
    <div className="flex--40">{label}</div>
    <div className="flex--60">{children}</div>
  </div>
);

export default StaticField;
