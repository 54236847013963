import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { useTranslation } from "react-i18next";

import actions from "~/actions";
import { AsciButton, AppsCollection, DialogMessage } from "~/global";
import ContextMenu from "./ContextMenu/ContextMenu.component";
import LicenseList from "./Licenses/LicenseList.component";
import DialogChangePassword from "./DialogChangePassword/DialogChangePassword.component";
import DialogDownloadCenter from "./DialogDownloadCenter/DialogDownloadCenter.component";

const DrawerMenu = ({
  csid,
  onOpen,
  onClose,
  onLogout,
  canChangePassword = false,
  canSeeLicenses = false,
  canSeeDownloadCenter = false,
}) => {
  const isDialogVisible = useSelector((state) => !!state.dialog.settings);
  const { t } = useTranslation("navbar");
  const dispatch = useDispatch();

  const onPasswordUpdated = () => {
    onClose();
    dispatch(
      actions.dialog.show({
        modal: false,
        width: "calc(50% - 100px)",
        title: "Success",
        content: (close) => (
          <DialogMessage
            close={() => {
              close();
              onLogout();
            }}
          >
            <div className="column centered">
              <p className="align-center">Your changes have been saved!</p>
            </div>
          </DialogMessage>
        ),
      })
    );
  };

  const onPasswordUpdateError = () => {
    onClose();

    dispatch(
      actions.dialog.show({
        visible: true,
        width: "calc(50% - 100px)",
        title: "Error",
        content: (close) => (
          <DialogMessage close={close}>
            <div className="column centered">
              <p className="align-center">Failed to update password</p>
            </div>
          </DialogMessage>
        ),
      })
    );
  };

  const onChangePasswordClicked = () => {
    onClose();

    dispatch(
      actions.dialog.show({
        width: "calc(50% - 100px)",
        title: "Change Password",
        content: (close) => (
          <DialogChangePassword
            onCancel={close}
            onConfirm={close}
            onUpdateSuccess={onPasswordUpdated}
            onUpdateError={onPasswordUpdateError}
          />
        ),
      })
    );
  };

  const onShowVersionsClicked = async () => {
    const servicesVersion = await dispatch(actions.servicesVersion.fetchServicesVersion());

    servicesVersion
      .map((serviceVersion) => {
        const serviceKey = Object.keys(serviceVersion)[0];
        return `${serviceKey}: ${serviceVersion[serviceKey]}`;
      })
      .join("; \n");

    onClose();

    dispatch(
      actions.dialog.show({
        width: "100%",
        title: "Release Information",
        modal: true,
        content: (
          <DataTable className="cc-table full-width" baseId="release-list-table" plain>
            <TableHeader>
              <TableRow className="asci-table-row">
                <TableColumn>Service</TableColumn>
                <TableColumn>Release number</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {servicesVersion.map((service) => {
                const serviceKey = Object.keys(service)[0];
                return (
                  <TableRow key={serviceKey}>
                    <TableColumn>{serviceKey}:</TableColumn>
                    <TableColumn>{service[serviceKey]}</TableColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </DataTable>
        ),
        footer: (close) => (
          <div className="layout-row layout-row--end-center">
            <AsciButton color="white" onClick={close}>
              Close
            </AsciButton>
          </div>
        ),
      })
    );
  };

  const onShowLicenceClicked = async () => {
    const customerLicenses = await actions.licenses.fetchLicenses(csid);

    onClose();

    const isTextMessage = typeof customerLicenses === "string";

    dispatch(
      actions.dialog.show({
        width: "900px",
        title: "Client Licenses",
        modal: true,
        content: isTextMessage ? (
          customerLicenses
        ) : (
          <LicenseList customerLicenses={customerLicenses} />
        ),
        footer: (close) => (
          <div className="layout-row layout-row--end-center">
            <AsciButton color="white" onClick={close}>
              Close
            </AsciButton>
          </div>
        ),
      })
    );
  };

  const onDownloadCenterClicked = () => {
    onClose();

    dispatch(
      actions.dialog.show({
        width: "100%",
        title: "Download Center",
        content: (close) => <DialogDownloadCenter hideDialog={close} />,
      })
    );
  };

  const getNavItems = () => {
    const navItems = [
      <ContextMenu
        key="nav-menu-logout"
        text={t("log-out")}
        onClick={onLogout}
        disabled={isDialogVisible}
      />,
    ];

    // GLOB-775 every body should see the release information
    navItems.push(
      <ContextMenu
        key="nav-menu-about"
        text={t("release-information")}
        onClick={onShowVersionsClicked}
        disabled={isDialogVisible}
      />
    );

    if (canChangePassword) {
      navItems.push(
        <ContextMenu
          key="nav-menu-password"
          text={t("change-password")}
          onClick={onChangePasswordClicked}
          disabled={isDialogVisible}
        />
      );
    }

    if (canSeeLicenses) {
      navItems.push(
        <ContextMenu
          key="nav-menu-licence"
          text={t("show-licenses")}
          onClick={onShowLicenceClicked}
          disabled={isDialogVisible}
        />
      );
    }

    if (canSeeDownloadCenter) {
      navItems.push(
        <ContextMenu
          key="nav-menu-download"
          text={t("download-center")}
          onClick={onDownloadCenterClicked}
          disabled={isDialogVisible}
        />
      );
    }

    navItems.push(<AppsCollection key="header-apps" originId="header-apps" size={30} />);

    return navItems;
  };

  return (
    <div className="cc-drawer-menu">
      <Button
        onClick={() => onOpen(getNavItems())}
        icon
        iconClassName="icon-bars drawer-btn text-btn"
      />
    </div>
  );
};

DrawerMenu.propTypes = {
  csid: PropTypes.string,
  canChangePassword: PropTypes.bool,
  canSeeLicenses: PropTypes.bool,
  canSeeDownloadCenter: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DrawerMenu;
