import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { InputNumber, Select, Switch } from "antd";

import { TextField } from "~/global";

import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import InspectorSection from "~/label-printer/components/designer/inspector/commons/InspectorSection";
import FontSelect from "~/label-printer/components/designer/inspector/commons/FontSelect";

import labelSelectors from "~/label-printer/selectors";

const FORMAT_OPTIONS = [
  "CODE128",
  "CODE128A",
  "CODE128B",
  "CODE128C",
  "CODE39",
  "EAN13",
  "UPC",
  "EAN8",
  "EAN5",
  "EAN2",
  "ITF14",
  "MSI",
  "MSI10",
  "MSI11",
  "MSI1010",
  "MSI1110",
  "codabar",
];

const BarcodeFieldInspector = (props) => {
  const {
    setShapeFieldData,
    identity,
    path,
    displayValue,
    text,
    textMargin,
    textPosition,
    fontSize,
    format,
    height,
    width,
    font,
    margin,
    recordingFields,
  } = props;
  const setterFactory = (key) => (data) => setShapeFieldData({ identity, key, data });
  return (
    <>
      <InspectorInput label="Recording Value">
        <Select onChange={setterFactory("path")} value={path}>
          {recordingFields.map((field) => (
            <Select.Option key={field.path} value={field.path}>
              {field.name}
            </Select.Option>
          ))}
        </Select>
      </InspectorInput>
      <InspectorInput label="Format">
        <Select onChange={setterFactory("format")} value={format}>
          {FORMAT_OPTIONS.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </InspectorInput>

      <InspectorInput label="Display value">
        <Switch checked={displayValue} onChange={setterFactory("displayValue")} />
      </InspectorInput>

      <InspectorInput label="Height">
        <InputNumber onChange={setterFactory("height")} defaultValue={height} />
      </InspectorInput>

      <InspectorInput label="Width">
        <InputNumber onChange={setterFactory("width")} defaultValue={width} />
      </InspectorInput>

      <InspectorInput label="Margin">
        <InputNumber onChange={setterFactory("margin")} defaultValue={margin} />
      </InspectorInput>

      <InspectorSection title="Text" initialFolded level={1}>
        <InspectorInput label="Value" title="Use this value instead of the bar code value">
          <TextField onChange={setterFactory("text")} value={text} />
        </InspectorInput>

        <InspectorInput label="Font">
          <FontSelect value={font} setter={setterFactory("font")} />
        </InspectorInput>

        <InspectorInput label="Size">
          <InputNumber defaultValue={fontSize} onChange={setterFactory("fontSize")} />
        </InspectorInput>

        <InspectorInput label="Align">
          <Select onChange={setterFactory("textAlign")}>
            <Select.Option value="center">Center</Select.Option>
            <Select.Option value="left">Left</Select.Option>
            <Select.Option value="right">Right</Select.Option>
          </Select>
        </InspectorInput>
        <InspectorInput label="Position">
          <Select value={textPosition} onChange={setterFactory("textPosition")}>
            <Select.Option value="bottom">Bottom</Select.Option>
            <Select.Option value="top">Top</Select.Option>
          </Select>
        </InspectorInput>
        <InspectorInput label="Margin">
          <InputNumber defaultValue={textMargin} onChange={setterFactory("textMargin")} />
        </InspectorInput>
      </InspectorSection>
    </>
  );
};

BarcodeFieldInspector.propTypes = {
  identity: PropTypes.number.isRequired,

  path: PropTypes.string,
  format: PropTypes.string,
  displayValue: PropTypes.bool,
  flat: PropTypes.bool,
  text: PropTypes.string,
  textPosition: PropTypes.string,
  textMargin: PropTypes.number,
  fontSize: PropTypes.number,

  recordingFields: PropTypes.array,

  setShapeFieldData: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  recordingFields: labelSelectors.labelFields.getAvailableFields(state),
});

export default connect(stateToProps, null)(BarcodeFieldInspector);
