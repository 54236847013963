import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, Tooltip, Legend } from "recharts";
import { Tooltip as AntTooltip } from "antd";
import { PIE_CHART_COLORS } from "~/constants";
import { useTranslation } from "~/i18n";

import { Row, Column } from "~/global";

import "./TeamFailCodes.component.scss";

type Props = {
  failCodesData?: {
    name: string | undefined;
    value: string | number | undefined;
    truncatedName: string;
    count: string | number | undefined;
  }[];
  teamName: string;
  failCodesError?: string;
};

type PropsCustomLegendText = {
  payload: {
    count: number;
    value: number;
    truncatedName: string;
  };
};

const TeamFailCodes = ({ teamName, failCodesData = [], failCodesError }: Props) => {
  const [activeSector, setActiveSector] = useState<undefined | number>(undefined);
  const { t } = useTranslation("dashboard");

  const legendItemHeight = 50;
  const legendPadding = 44;
  const legendHeight = Math.ceil(failCodesData.length / 2) * legendItemHeight + legendPadding;
  const chartHeight = 296 + legendHeight;
  const failCodesErrorText = failCodesError || t("no-data-to-be-displayed");

  const customLegendText = (value: number, { payload }: PropsCustomLegendText) => (
    <AntTooltip
      placement="topLeft"
      title={value}
      mouseEnterDelay={0.25}
      overlayClassName="team-fail-codes"
    >
      {payload.truncatedName}: <b>{payload.count}</b> ({payload.value}%)
    </AntTooltip>
  );

  if (failCodesData.length === 0) {
    return (
      <Column className="team-fail-codes">
        <Row className="no-data" align="center center">
          <i className="icon-ban" />
          {failCodesErrorText}
        </Row>
      </Column>
    );
  }

  return (
    <Column className="team-fail-codes">
      <Column className="fail-codes-header" align="space-around">
        <div className="text-centered title">
          {t("fail-codes-breakdown")} {teamName}
        </div>
        <div className="text-centered instructions">{t("hover-for-details")}</div>
      </Column>

      <Row className="fail-codes-content" align="space-around">
        <PieChart width={400} height={chartHeight}>
          <Pie
            data={failCodesData}
            dataKey="value"
            cx={200}
            cy={140}
            innerRadius={70}
            outerRadius={130}
            // rechart will inject properties to the components
            label={<CustomLabel />}
            labelLine={false}
            activeIndex={activeSector}
            activeShape={<CustomActiveShape />}
            onMouseEnter={(data, index) => setActiveSector(index)}
            onMouseLeave={() => setActiveSector(undefined)}
            isAnimationActive={false}
          >
            {failCodesData.map((entry, index) => (
              <Cell key={index} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            width={440}
            layout="vertical"
            iconType="circle"
            iconSize={20}
            formatter={(value, entry) =>
              customLegendText(value, entry as unknown as PropsCustomLegendText)
            }
            wrapperStyle={{
              columns: 2,
            }}
          />
        </PieChart>
      </Row>
    </Column>
  );
};

type PropsCustomActiveShape = {
  cx?: number;
  cy?: number;
  innerRadius?: number;
  outerRadius?: number;
  startAngle?: number;
  endAngle?: number;
  fill?: string;
};

const CustomActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius = 0,
  startAngle,
  endAngle,
  fill,
}: PropsCustomActiveShape) => (
  <g>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 10}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  </g>
);

type PropsCustomLabel = {
  cx?: number;
  cy?: number;
  midAngle?: number;
  innerRadius?: number;
  outerRadius?: number;
  count?: number;
};

const CustomLabel = ({
  cx = 0,
  cy = 0,
  midAngle = 0,
  innerRadius = 0,
  outerRadius = 0,
  count = 0,
}: PropsCustomLabel) => {
  const radian = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * radian);
  const y = cy + radius * Math.sin(-midAngle * radian);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
      {count}
    </text>
  );
};

type PropsCustomTooltip = {
  payload?: {
    name: string;
    value: string;
    payload: { count?: number };
  }[];
  active?: boolean;
};

const CustomTooltip = ({ active, payload }: PropsCustomTooltip) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {payload?.[0].name}: {payload?.[0].payload.count} ({payload?.[0].value}%)
        </p>
      </div>
    );
  }

  return null;
};

export default TeamFailCodes;
