import { createReducer } from "@reduxjs/toolkit";

import actions from "~/actions";
import testSuitesManagementActions from "../test-suites-management/actions";

const initialState = {
  items: [],
  sortKey: "",
  sortReverse: false,
  error: null,
  affectedPrograms: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [actions.testSuites.fetchTestSuitesBegin]: (state, action) => {
    state.error = null;
    state.isLoading = true;
  },

  [actions.testSuites.fetchTestSuitesSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.testSuites || [];
  },

  [actions.testSuites.fetchTestSuitesError]: (state, action) => {
    state.error = action.payload.error;
    state.items = [];
    state.isLoading = false;
  },

  [actions.testSuites.sortTestSuites]: (state, action) => {
    const sortKey = action.payload;
    const sortReverse = sortKey === state.sortKey ? !state.sortReverse : false;

    state.sortKey = sortKey;
    state.sortReverse = sortReverse;
  },

  // Delete a test suite
  [testSuitesManagementActions.deleteTestSuiteBegin]: (state, action) => {
    state.error = null;
    state.affectedPrograms = null;
    state.isLoading = true;
  },
  [testSuitesManagementActions.deleteTestSuiteSuccess]: (state, action) => {
    state.items = action.payload.testSuites;
    state.isLoading = false;
  },
  [testSuitesManagementActions.deleteTestSuiteError]: (state, action) => {
    state.error = action.payload.error;
    state.affectedPrograms = action.payload.affectedPrograms
      ? action.payload.affectedPrograms
      : null;
    state.isLoading = false;
  },
});
