import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import * as VersionManagerActions from "~/actions/VersionManager.actions";
import { downloadVersion } from "./DownloadActions";
import { AsciButton, AsciSelect, Spinner } from "~/global";

import "./DialogDownloadCenter.component.scss";

const DialogDownloadCenter = ({ hideDialog }) => {
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadVersions();
  }, []);

  const loadVersions = async () => {
    const versions = await VersionManagerActions.fetchVersions();
    if (versions != null) {
      const selectedVersion = versions.filter((version) => version.selected)[0];

      setSelectedVersion(selectedVersion);
      setVersions(versions);
      setIsLoading(false);
    }
  };

  const installClicked = () => {
    downloadVersion(selectedVersion.name);
  };

  return (
    <div className="panel-container dialog-download-center column centered">
      <div className="row spaced-centered">
        {isLoading ? (
          <Spinner />
        ) : versions.length === 0 ? (
          <>No data to display</>
        ) : (
          <>
            <div>
              <b>Program Version</b> - Select the version you want install on this computer.
            </div>
            <AsciSelect
              id="download-version-selector"
              items={versions}
              selectedItem={selectedVersion}
              valueKey="name"
              onChange={setSelectedVersion}
              disabled={versions == null || versions.length <= 1}
            />
          </>
        )}
      </div>

      {!isLoading && versions.length !== 0 && (
        <div className="release-container column centered">
          <div className="release-version">
            Release Notes (version <b>{selectedVersion.name}</b>)
          </div>
          <div className="release-notes">{selectedVersion.releaseNotes}</div>

          <div className="content-footer row centered dialog-buttons">
            <div className="row centered">
              <AsciButton color="white" onClick={hideDialog}>
                Cancel
              </AsciButton>
            </div>

            <div className="row centered">
              <AsciButton color="blue" onClick={installClicked}>
                Install
              </AsciButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DialogDownloadCenter.propTypes = {
  hideDialog: PropTypes.func.isRequired,
};

export default DialogDownloadCenter;
