import React, { useState } from "react";
import { DataTable, TableBody, TableHeader, TableColumn, TableRow } from "react-md";
import PropTypes from "prop-types";
import { sortBy } from "lodash/collection";
import classnames from "classnames";

import DeploymentListRow from "./DeploymentListRow.component";

const DeploymentList = ({ deployments, canEdit, onEdit, canArchive, onArchive }) => {
  const [sortKey, setSortKey] = useState("name");
  const [sortReverse, setSortReverse] = useState(false);

  const onSort = (key) => {
    setSortKey(key);
    setSortReverse(key === sortKey ? !sortReverse : sortReverse);
  };

  const getColHeaderClasses = (colKey) => ({
    sortable: true,
    "is-sorted": sortKey === colKey,
    "sort-reverse": sortReverse,
  });

  const isEmpty = deployments.length === 0;

  let sortedDeployments = sortBy(deployments, (item) => {
    const value = item[sortKey];

    if (typeof value === "string") {
      return value.toLowerCase();
    }
    return value;
  });

  if (sortReverse) {
    sortedDeployments = sortedDeployments.reverse();
  }

  return (
    <DataTable className="cc-table" plain>
      {!isEmpty ? (
        <TableHeader>
          <TableRow>
            <TableColumn
              className={classnames(getColHeaderClasses("dpid"))}
              onClick={() => onSort("dpid")}
              style={{ minWidth: "150px" }}
            >
              DPID
            </TableColumn>

            <TableColumn
              style={{ minWidth: "275px" }}
              className={classnames(getColHeaderClasses("name"))}
              onClick={() => onSort("name")}
            >
              Name
            </TableColumn>
            {/* TODO: Remove release line from query upon termination of classic DSE */}
            <TableColumn
              className={classnames(getColHeaderClasses("releaseline"))}
              grow
              onClick={() => onSort("releaseline")}
            >
              Release Line
            </TableColumn>

            <TableColumn style={{ minWidth: "100px" }} />
          </TableRow>
        </TableHeader>
      ) : null}

      <TableBody>
        <>
          {isEmpty ? (
            <TableRow className="message-empty">
              <TableColumn colSpan="4">No deployments</TableColumn>
            </TableRow>
          ) : null}

          {sortedDeployments.map((deployment, index) => (
            <DeploymentListRow
              key={index}
              deployment={deployment}
              canEdit={canEdit}
              onEdit={onEdit}
              canArchive={canArchive}
              onArchive={onArchive}
            />
          ))}
        </>
      </TableBody>
    </DataTable>
  );
};

DeploymentList.propTypes = {
  deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  canArchive: PropTypes.bool,
  onArchive: PropTypes.func,
};

export default DeploymentList;
