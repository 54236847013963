import React, { useState, useMemo, useCallback } from "react";
import join from "lodash/join";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

import "./InspectorSection.scss";

const InspectorSection = ({ title, children, level, initialFolded }) => {
  const [folded, setFolded] = useState(initialFolded);

  const [titleClassName, bodyClassName] = useMemo(() => {
    const title = ["inspector-section-title"];
    const body = ["inspector-section-body"];
    if (folded) {
      body.push("folded");
      title.push("folded");
    }
    if (level > 0) {
      title.push("sub-section");
      body.push("sub-section");
    }
    return [title, body].map((e) => join(e, " "));
  }, [folded]);

  const onFold = useCallback(() => setFolded(!folded), [folded]);

  return (
    <div className="inspector-section">
      <div className={titleClassName} onClick={onFold}>
        <span>{title}</span>
        <FontIcon iconClassName={folded ? "icon-caret-down" : "icon-caret-up"} />
      </div>
      <div className={bodyClassName}>{children}</div>
    </div>
  );
};

InspectorSection.defaultProps = {
  level: 0,
  initialFolded: false,
};

InspectorSection.propTypes = {
  title: PropTypes.node.isRequired,
  initialFolded: PropTypes.bool,
  children: PropTypes.node,
  level: PropTypes.number,
};

export default InspectorSection;
