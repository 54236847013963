import Auth0Lock from "auth0-lock";
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import history from "~/global/history";
import Auth0DefaultIcon from "~/media/images/base/icon_AS-58x58.png";
import actions from "~/actions/index";
import { AuthContext, AuthStatus } from "./useAuth";
import { usePersistentState } from "~/hooks/usePersistentState";
import { useAppDispatch } from "~/hooks";

interface Session {
  accessToken: string;
  userId: string;
  bearerToken: string;
  expirationDate: number;
}

export const AuthProvider = ({
  children,
  auth0,
}: {
  children?: ReactNode;
  auth0: {
    clientId: string;
    domain: string;
  };
}): ReactElement => {
  const dispatch = useAppDispatch();

  // The authentication logic would be much better expressed with a reducer, be it in the store or through a useReducer hook
  // TODO: replace the local state management with a reducer
  const [status, setStatus] = useState<AuthStatus>("uninit");
  const [errorMessage, setErrorMessage] = useState("");
  const [session, setSession, removeSession] = usePersistentState<Session>("session");
  const [oauth2StateParam] = usePersistentState(
    "oauth2StateParam",
    generateStateParam(),
    sessionStorage
  );

  // the redirect url must be indexed with the "state" param from auth0
  // ref: https://auth0.com/docs/protocols/state-parameters#redirect-users
  const [redirectUrl, setRedirectUrl] = usePersistentState(
    oauth2StateParam,
    getCurrentUrl(),
    sessionStorage
  );

  const lock = useMemo(
    () => initAuth0Lock(auth0.clientId, auth0.domain),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0.clientId, auth0.domain]
  );

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      if (status === "authenticated") {
        setStatus(checkStatus(session));
        updateCurrentApp();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (status) {
      case "authenticated": {
        const freshStatus = checkStatus(session);
        if (freshStatus === "authenticated") {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          void onLogin(session!.userId);
          updateCurrentApp();
        } else {
          setStatus(freshStatus);
        }
        break;
      }
      case "pre_authenticated":
        // transition between "pending" and "authenticated"
        // user must be redirected
        history.replace(redirectUrl);
        setStatus("authenticated");
        break;
      case "pending":
        // do nothing; auth0 will handle this case automatically
        break;
      case "unauthenticated":
        setRedirectUrl(getCurrentUrl());
        lock.show();
        break;
      case "authorization_error":
        // restore initial url & show lock with error message
        history.replace(redirectUrl);
        lock.show({
          flashMessage: {
            type: "error",
            text: errorMessage,
          },
        });
        break;
      case "uninit":
        setStatus(checkStatus(session));
        break;
      default: {
        const exhaustiveCheck: never = status;
        throw new Error(`Unhandled case: ${exhaustiveCheck as string}`);
      }
    }
  }, [status, session, redirectUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  function getCurrentUrl(): string {
    const { pathname, search, hash } = history.location;
    return pathname + search + hash;
  }

  /** generate a secure 128 bit key */
  function generateStateParam(): string {
    return `state:${btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16))))}`;
  }

  function initAuth0Lock(clientId: string, domain: string): Auth0LockStatic {
    const lock = new Auth0Lock(clientId, domain, {
      theme: {
        logo: Auth0DefaultIcon,
        primaryColor: "#0e3c5f",
      },
      languageDictionary: {
        title: "Asset Science",
      },
      allowedConnections: ["Username-Password-Authentication", "google-oauth2"],
      closable: false,
      autoclose: true,
      autofocus: true,
      avatar: null,
      auth: {
        redirectUrl: window.location.origin,
        params: {
          state: oauth2StateParam,
        },
        responseType: "token id_token",
      },
      allowSignUp: false,
      rememberLastLogin: false,
    });
    lock.on("authenticated", (authResult) => {
      // accessToken to get the user profile from auth0
      const { accessToken } = authResult;
      const expirationDate = authResult.expiresIn * 1000 + new Date().getTime();
      // bearerToken to authenticate requests
      const bearerToken = authResult.idToken;
      const auth0UserId = authResult.idTokenPayload.sub;
      const session = {
        accessToken,
        userId: auth0UserId,
        bearerToken,
        expirationDate,
      };

      setSession(session);

      // login logic will be handled by effect hooks
      setStatus("pre_authenticated");
    });
    lock.on("authorization_error", (error) => {
      setErrorMessage(error.errorDescription ?? "");

      // the error status will be handled by effect hooks
      setStatus("authorization_error");
    });
    return lock;
  }

  function checkStatus(session: Session | undefined): AuthStatus {
    if (validateSession(session)) {
      return "authenticated";
    }
    removeSession();

    if (isPending()) {
      return "pending";
    }

    return "unauthenticated";
  }

  function validateSession(session: Session | undefined): boolean {
    const now = new Date().getTime();
    return !!(
      session &&
      session.accessToken &&
      session.userId &&
      session.bearerToken &&
      session.expirationDate &&
      now < session.expirationDate
    );
  }

  function isPending(): boolean {
    // check if we've just been redirected by auth0
    // the data, including the access token or any errors, is contained in the URL fragment (hash)
    // ref: https://datatracker.ietf.org/doc/html/draft-ietf-oauth-v2-26#section-4.2
    const hashWithoutLeadingPoundSign = history.location.hash.substring(1);
    const urlHashParams = new URLSearchParams(hashWithoutLeadingPoundSign);
    return urlHashParams.has("access_token") || urlHashParams.has("error");
  }

  const onLogin = async (auth0UserId: string): Promise<void> => {
    try {
      await Promise.all([
        dispatch(actions.user.fetchUserProfile(auth0UserId)),
        dispatch(actions.customer.fetchMyCustomer()),
        dispatch(actions.languages.fetchLanguages()),
        dispatch(actions.customers.fetchCustomers()),
      ]);
    } catch (e) {
      if (e instanceof Error) throw e;
      removeSession();
    }
  };

  const onLogout = useCallback((): void => {
    removeSession();
    history.push("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCurrentApp = (): void => {
    let fullPath = history.location.pathname;
    if (fullPath.startsWith("/")) {
      fullPath = fullPath.substring(1);
    }

    let currentModuleURLName = "";

    if (fullPath.split("/")[0] === "data-import") {
      currentModuleURLName = "data-import/upload";
    } else if (fullPath.split("/")[0] === "device-history") {
      currentModuleURLName = "device-history/session";
    } else {
      [currentModuleURLName] = fullPath.split("/");
    }
    dispatch(actions.session.setCurrentApp(currentModuleURLName));
  };

  const contextValue = useMemo(() => ({ status, logout: onLogout }), [status, onLogout]);
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
