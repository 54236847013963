import { mapValues } from "lodash";

import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const fetchFailCodePriorityGroupsBegin = createAction(
  "FETCH_FAIL_CODE_PRIORITY_GROUPS_BEGIN"
);
export const fetchFailCodePriorityGroupsSuccess = createAction(
  "FETCH_FAIL_CODE_PRIORITY_GROUPS_SUCCESS"
);
export const fetchFailCodePriorityGroupsError = createAction(
  "FETCH_FAIL_CODE_PRIORITY_GROUPS_ERROR"
);

export const updateFailCodePriorityGroupsBegin = createAction(
  "UPDATE_FAIL_CODE_PRIORITY_GROUPS_BEGIN"
);
export const updateFailCodePriorityGroupsError = createAction(
  "UPDATE_FAIL_CODE_PRIORITY_GROUPS_ERROR"
);
export const updateFailCodePriorityGroupsSuccess = createAction(
  "UPDATE_FAIL_CODE_PRIORITY_GROUPS_SUCCESS"
);

export const fetchFailCodePriorityGroups = () => async (dispatch) => {
  dispatch(fetchFailCodePriorityGroupsBegin());
  try {
    const query = CustomerManagement.gqlBuilder(`{
      priority_groups {
        id,
        csid,
        name{en,fr,es},
        isDefault,
        failCodeCategories{
          fccid,
          visible,
          failCodes{fcid, visible}
        }
      }
    }`);

    const response = await CustomerManagement.POST("/gql", { query });
    const data = response.payload.data.priority_groups || [];
    dispatch(fetchFailCodePriorityGroupsSuccess(data));
    //return data for get defaultvalue in balise radio to Service Suite editor
    return data;
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(fetchFailCodePriorityGroupsError(message));
  }
};

export const updateFailCodePriorityGroup = (group) => async (dispatch) => {
  const dataToStore = {
    ...group,
    // trim all names
    name: mapValues(group.name, (name) => name.trim()),
  };
  const dataToServer = {
    ...dataToStore,
    // fccid must be "fccID" in PUT & POST requests
    failCodeCategories: group.failCodeCategories.map(({ fccid, ...categoryWithoutFccid }) => ({
      ...categoryWithoutFccid,
      fccID: fccid,
    })),
  };

  dispatch(updateFailCodePriorityGroupsBegin(dataToStore));

  try {
    await CustomerManagement.PUT("/priority-groups", dataToServer);
    dispatch(updateFailCodePriorityGroupsSuccess());
  } catch (error) {
    dispatch(updateFailCodePriorityGroupsError(error.json));
  }
};
