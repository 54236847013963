import React, { useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { truncate } from "lodash";
import { useTranslation } from "~/i18n";

import { SectionHeader, AsciButton, Row, Column } from "~/global";
import TeamPerformance from "../TeamPerformance/TeamPerformance.component";
import TeamCustomFields from "../TeamCustomFields/TeamCustomFields.component";
import TeamFailCodes from "../TeamFailCodes/TeamFailCodes.component";
import { TeamMetrics } from "../../models/teams.slice";
import { TeamPerformanceType } from "../../models/teamPerformance.slice";

import "./PerformanceCard.component.scss";

type Props = {
  team?: TeamMetrics;
  performance: TeamPerformanceType;
  showMoreTeamData: () => void;
  showCustomFields: () => void;
  showFailCodes: () => void;
};

const PerformanceCard = ({
  team,
  performance,
  showMoreTeamData,
  showCustomFields,
  showFailCodes,
}: Props) => {
  const [selectedView, setSelectedView] = useState("performance");
  const { t } = useTranslation("dashboard");
  const failCodesWithTruncatedName = team?.failCodes.items?.map((failCode) => ({
    name: failCode.name,
    value: failCode.value,
    truncatedName: truncate(failCode.name, { length: 10 }),
    count: failCode.count,
  }));

  const customFieldsStatus = team?.customFields.status;
  const failCodesStatus = team?.failCodes.status;
  return (
    <Column className="cc-performance-card">
      <SectionHeader title={performance.teamName}>
        <Row>
          {selectedView === "performance" ? (
            <>
              <AsciButton
                color="blue"
                onClick={() => {
                  showCustomFields();
                  setSelectedView("customFields");
                }}
              >
                {t("custom-fields")}
              </AsciButton>

              <AsciButton
                color="blue"
                onClick={() => {
                  showFailCodes();
                  setSelectedView("failCodes");
                }}
              >
                {t("fail-codes")}
              </AsciButton>
            </>
          ) : (
            <AsciButton color="blue" onClick={() => setSelectedView("performance")}>
              <i className="icon-arrow-left" />
              {t("back")}
            </AsciButton>
          )}
        </Row>
      </SectionHeader>
      {customFieldsStatus === "pending" && failCodesStatus !== "pending" ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
          size="large"
        />
      ) : selectedView === "performance" ? (
        <TeamPerformance
          showMore={team?.showMore}
          performance={performance}
          showMoreTeamData={showMoreTeamData}
        />
      ) : selectedView === "customFields" ? (
        <TeamCustomFields customFieldsData={team?.customFields} />
      ) : selectedView === "failCodes" ? (
        <TeamFailCodes
          teamName={performance.teamName}
          failCodesData={failCodesWithTruncatedName}
          failCodesError={team?.failCodes.error}
        />
      ) : null}
    </Column>
  );
};

export default PerformanceCard;
