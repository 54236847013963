import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import { selectors as workflowSelectors, actions as workflowActions } from "~/workflow/redux";
import BehaviorEditor from "~/workflow/components/editors/BehaviorEditor";
import { metadata as behaviorsMetadata } from "~/workflow/workflowBehaviors";

import "./EventEditor.scss";
import { FontIcon } from "react-md";

const BehaviorMenu = (props) => (
  <Menu onClick={props.onClick}>
    {props.behaviors.map((b) => (
      <Menu.Item key={b}>{behaviorsMetadata[b].label || b}</Menu.Item>
    ))}
  </Menu>
);

const EventEditor = (props) => {
  const { eventId, serviceIdentifier } = props;
  const dispatch = useDispatch();
  const behaviors = useSelector(
    workflowSelectors.eventEditor[eventId].getBehaviors(serviceIdentifier)
  );
  const allowedBehaviors = useSelector(workflowSelectors.eventEditor[eventId].getAllowedBehaviors);
  const metadata = useSelector(workflowSelectors.eventEditor[eventId].getEventMetadata);

  const onMenuClick = useCallback(
    (e) => {
      dispatch(
        workflowActions.eventEditor[eventId].addBehavior({ behaviorType: e.key, serviceIdentifier })
      );
    },
    [dispatch, serviceIdentifier, eventId]
  );

  return (
    <div className="workflow-event-editor">
      <div className="workflow-event-editor-title">
        <div className="title-label">
          {metadata.label}&nbsp;
          <Dropdown overlay={<div className="event-description">{metadata.description}</div>}>
            <FontIcon className="add-icon-btn" iconClassName="icon-question-circle" />
          </Dropdown>
        </div>
        <Dropdown overlay={<BehaviorMenu behaviors={allowedBehaviors} onClick={onMenuClick} />}>
          <FontIcon iconClassName="icon-plus" className="add-icon-btn" />
        </Dropdown>
      </div>
      <div className="workflow-event-editor-body">
        {behaviors.map((b) => (
          <BehaviorEditor key={`${eventId}-behavior-${b.behaviorId}`} eventId={eventId} {...b} />
        ))}
      </div>
    </div>
  );
};

export default EventEditor;
