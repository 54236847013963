import React, { ReactElement } from "react";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useTranslation } from "react-i18next";

import { Row } from "~/global";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { fileCleared, uploadFile } from "./csvUploadSlice";

const { Dragger } = Upload;

const CsvUpload = (): ReactElement => {
  const { t } = useTranslation("csvUpload");
  const dispatch = useAppDispatch();

  const { file } = useAppSelector((state) => state.reportingModule.csvUpload);

  const validateFile = (file: RcFile) => {
    const splitFileName = file.name.split(".");
    const csvFileExtension = splitFileName[splitFileName.length - 1].toLowerCase() === "csv";
    const isCSV =
      csvFileExtension && (file.type === "text/csv" || file.type === "application/vnd.ms-excel");
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isCSV) {
      void message.error(t("wrong-file-type", { file: file.name }));
    }

    if (!isLt10M) {
      void message.error(t("file-too-big"));
    }

    return (isCSV && isLt10M) || Upload.LIST_IGNORE;
  };

  const handleFileUpload = async (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();

    formData.append("file", file);

    try {
      const response = await dispatch(uploadFile(formData)).unwrap();
      onSuccess?.(response._id);
    } catch (err: unknown) {
      if (err instanceof Error) {
        onError?.(err);
      } else if (typeof err === "string") {
        onError?.(new Error(err));
      }
    }
  };

  // The function will be called when uploading is in progress, completed or failed
  const onChange = ({ file }: UploadChangeParam) => {
    if (file.status === "done") {
      void message.success(t("successful-upload", { file: file.name }));
    } else if (file.status === "error") {
      if (file.error instanceof Error) {
        void message.error(file.error.message);
      } else {
        void message.error(t("failed-upload", { file: file.name }));
      }
    }
  };

  return (
    <Dragger
      name="csv-file"
      accept=".csv"
      beforeUpload={validateFile}
      customRequest={handleFileUpload}
      onChange={onChange}
      showUploadList={false}
      maxCount={1}
      style={{ padding: "0px 32px" }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>

      <p className="ant-upload-text">{t("clickdrag-file-toarea")}</p>
      <p className="ant-upload-hint">{t("csv-file-support-info")}</p>

      {file && (
        <Row align="center center" style={{ marginTop: "1em" }}>
          <p className="ant-upload-text">
            {t("file-summary", { count: file.itemCount, file: file.name })}
          </p>

          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(fileCleared());
            }}
            style={{ marginLeft: "0.5em" }}
          />
        </Row>
      )}
    </Dragger>
  );
};

export default CsvUpload;
