import { createAction } from "@reduxjs/toolkit";

export const showDialog = createAction("dialog/SHOW");
export const hideDialog = createAction("dialog/HIDE");
export const setDialogTitle = createAction("dialog/SET_TITLE");

export const show = (dialog, callback) => (dispatch) =>
  dispatch(
    showDialog({
      dialog,
      callback,
    })
  );

export const hide = () => (dispatch) => dispatch(hideDialog());

export const setTitle = (title) => (dispatch) => dispatch(setDialogTitle(title));
