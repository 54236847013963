import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import concat from "lodash/concat";

import { Select, Switch } from "antd";
import { FontIcon } from "react-md";
import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";
import DetailsField from "~/global/reduxer/components/details/DetailsField";
import { TextField } from "~/global";

const ApiCallBehavior = (props) => {
  const { behaviorId, eventId } = props;
  const dispatch = useDispatch();

  const eventActions = workflowActions.eventEditor[eventId];
  const eventSelectors = workflowSelectors.eventEditor[eventId];

  const behavior = useSelector(eventSelectors.getBehaviorById(behaviorId));
  const dataSetter = (key) => (value) =>
    dispatch(eventActions.setBehaviorData({ behaviorId, key, value }));
  const [nextId, setNextId] = useState(0);

  const onAddHeader = () => {
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "customHeaders",
        value: concat(behavior.behaviorData.customHeaders, [
          { key: null, value: null, id: nextId },
        ]),
      })
    );
    setNextId(nextId + 1);
  };

  const headerSetter = (field, headerId) => (value) =>
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "customHeaders",
        value: behavior.behaviorData.customHeaders.map((h, i) => {
          if (h.id === headerId) {
            return { ...h, [field]: value };
          }
          return h;
        }),
      })
    );

  const removeHeader = (header) => () => {
    dispatch(
      eventActions.setBehaviorData({
        behaviorId,
        key: "customHeaders",
        value: behavior.behaviorData.customHeaders.filter((h) => h.id !== header.id),
      })
    );
  };

  return (
    <div>
      <DetailsField
        value={
          <TextField
            value={behavior.behaviorData.url}
            placeholder={`https://api.assetscience.com/${eventId}`}
            onChange={dataSetter("url")}
          />
        }
        label="URL"
      />
      <DetailsField
        value={
          <Select value={behavior.behaviorData.method} onChange={dataSetter("method")}>
            <Select.Option key="GET">GET</Select.Option>
            <Select.Option key="POST">POST</Select.Option>
            <Select.Option key="PUT">PUT</Select.Option>
            <Select.Option key="DELETE">DELETE</Select.Option>
            <Select.Option key="PATH">PATCH</Select.Option>
          </Select>
        }
        label="Method"
      />
      <DetailsField
        value={
          <div title="Every json key in the response must correspond to custom field!">
            <Switch
              checked={behavior.behaviorData.setCustomFieldsFromResponse}
              onChange={dataSetter("setCustomFieldsFromResponse")}
            />
          </div>
        }
        label="Set custom fields from response"
      />

      <DetailsField
        label={
          <div>
            Custom Headers{" "}
            <FontIcon
              onClick={onAddHeader}
              iconClassName="icon-plus-circle"
              style={{ marginLeft: "0.5rem" }}
            />
          </div>
        }
        value={
          <div style={{ width: "100%" }}>
            {behavior.behaviorData.customHeaders.map((header) => (
              <div
                key={`${eventId}-${behaviorId}-${header.id}`}
                style={{ display: "flex", justifyContent: "stretch" }}
              >
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    onChange={headerSetter("key", header.id)}
                    value={header.key}
                    placeholder="Header key"
                    fullWidth
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    onChange={headerSetter("value", header.id)}
                    value={header.value}
                    placeholder="Value"
                    fullWidth
                  />
                </div>
                <FontIcon
                  onClick={removeHeader(header)}
                  iconClassName="icon-trash"
                  className="remove-condition"
                />
              </div>
            ))}
          </div>
        }
        labelProps={{ style: { alignSelf: "flex-start" } }}
      />
    </div>
  );
};

ApiCallBehavior.propTypes = {
  behaviorId: PropTypes.number,
  eventId: PropTypes.string,
};

export default ApiCallBehavior;
