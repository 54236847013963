import { createReducer } from "@reduxjs/toolkit";

import {
  fetchTestCategoriesBegin,
  fetchTestCategoriesSuccess,
  fetchTestCategoriesError,
  setSelectedCategory,
} from "~/actions/testCategories.actions";

const initialState = {
  isLoading: false,
  items: [],
  selectedName: undefined,
};

export default createReducer(initialState, {
  // Single Test Suite fetching
  [fetchTestCategoriesBegin]: (state, action) => {
    state.isLoading = true;
  },
  [fetchTestCategoriesSuccess]: (state, action) => {
    state.items = action.payload || [];
    state.isLoading = false;
  },
  [fetchTestCategoriesError]: (state, action) => {
    console.error(action.payload.error);
    state.items = [];
    state.selectedName = undefined;
    state.isLoading = false;
  },

  [setSelectedCategory]: (state, action) => {
    state.selectedName = action.payload;
  },
});
