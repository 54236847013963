import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import parseISO from "date-fns/parseISO";
import { DateTimeSelector } from "~/global";

const DateFilter = (props) => {
  const { actions, selectors, identifier } = props;
  const dispatch = useDispatch();
  const { start, end } = useSelector(selectors.getFilterValue(identifier));

  const onChange = useCallback(
    (event) => {
      const end = parseISO(event.dateTo).toISOString();
      const start = parseISO(event.dateFrom).toISOString();

      dispatch(actions.setFilterValue({ identifier, value: { end, start } }));
    },
    [dispatch, actions, identifier]
  );

  return <DateTimeSelector datesCallBack={onChange} dateTo={end} dateFrom={start} label />;
};

DateFilter.defaultProps = {};

DateFilter.propTypes = {
  actions: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
};

export default DateFilter;
