import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import { Button } from "react-md";
import {
  AsciButton,
  DialogMessage,
  InputSearch,
  SectionHeader,
  DialogConfirmation,
  LoadingSpinner,
  Row,
} from "~/global";
import actions from "~/actions";
import selectors from "~/selectors";
import FailCodeCategoriesList from "../FailCodeCategories/FailCodeCategoriesList.component";
import FailCodeCategoryEditor from "../FailCodeCategories/FailCodeCategoryEditor.component";

import FailCodesList from "../FailCodes/FailCodesList.component";
import FailCodeEditor from "../FailCodes/FailCodeEditor.component";
import FailCodePriorityGroupEditor from "../FailCodePriorityGroups/FailCodePriorityGroupEditor.component";
import FailCodeAssociatedTestsList from "../FailCodeAssociatedTestsList/FailCodeAssociatedTestsList.component";
import FailCodeCategoriesDeleteError from "../ErrorMessages/FailCodeCategoriesDeleteError.component";
import FailCodesDeleteError from "../ErrorMessages/FailCodesDeleteError.component";
import "./FailCodesPage.component.scss";

const FailCodesPage = () => {
  // failCodePriorityGroups
  const failCodePriorityGroup = useSelector(selectors.failCodes.getFailCodePriorityGroup);
  const failCodePriorityGroupPGID = useSelector(selectors.failCodes.getFailCodePriorityGroupPGID);
  const defaultPriorityGroup = useSelector(selectors.failCodes.getDefaultPriorityGroup);
  const newPriorityGroup = useSelector(selectors.failCodes.getNewFailCodePriorityGroup);
  const failCodePriorityGroups = useSelector((state) => state.failCodePriorityGroups.items);
  const failCodePriorityGroupError = useSelector(
    (state) => state.failCodePriorityGroups.error ?? state.failCodePriorityGroup.error
  );
  const failCodePriorityGroupsIsLoading = useSelector(
    (state) => state.failCodePriorityGroups.status === "loading"
  );

  // failCodeCategories
  const checkedFailCodeCategories = useSelector(
    (state) => state.failCodeCategories.itemsCheckedIds
  );
  const nameFilter = useSelector((state) => state.failCodeCategories.nameFilter);
  const failCodeCategories = useSelector(selectors.failCodes.getFilteredFailCodeCategories);
  const failCodeCategoryFailFast = useSelector(selectors.failCodes.getFailCodeCategoryFailFast);
  const failCodeCategoriesIsLoading = useSelector((state) => state.failCodeCategories.isLoading);
  const failCodeCategoriesErrors = useSelector((state) => state.failCodeCategories.errors);
  const failCodeCategoryErrors = useSelector((state) => state.failCodeCategory.errors);
  const selectedFailCodeCategoryId = useSelector((state) => state.failCodeCategory.original.id);

  // failCodes
  const checkedFailCodes = useSelector((state) => state.failCodes.itemsCheckedIds);
  const failCodes = useSelector(selectors.failCodes.getSortedFailCodes);
  const failCodesIsLoading = useSelector((state) => state.failCodes.isLoading);
  const selectedFailCodeId = useSelector((state) => state.failCode.original.id);
  const failCodesErrors = useSelector((state) => state.failCodes.errors);
  const failCodeIsLoading = useSelector((state) => state.failCode.isLoading);
  const failCodeErrors = useSelector((state) => state.failCode.errors);

  // selected fail code
  const testsAssociatedWithSelectedFailCode = useSelector(
    (state) => state.failCode.associatedTests
  );
  const associatedTestsLoading = useSelector((state) => state.failCode.associatedTestsLoading);

  const dispatch = useDispatch();

  // failCodePriorityGroups
  const fetchFailCodePriorityGroups = () =>
    dispatch(actions.failCodePriorityGroups.fetchFailCodePriorityGroups());

  // failCodePriorityGroup
  const addFailCodePriorityGroup = (group) =>
    dispatch(actions.failCodePriorityGroup.addFailCodePriorityGroup(group));
  const deleteFailCodePriorityGroup = (group) =>
    dispatch(actions.failCodePriorityGroup.deleteFailCodePriorityGroup(group));
  const updateFailCodePriorityGroup = (group) =>
    dispatch(actions.failCodePriorityGroups.updateFailCodePriorityGroup(group));
  const selectFailCodePriorityGroup = (group) =>
    dispatch(actions.failCodePriorityGroup.selectFailCodePriorityGroup(group));

  // failCodeCategories
  const setNameFilter = (name) => dispatch(actions.failCodeCategories.setNameFilter(name));
  const fetchFailCodeCategories = () =>
    dispatch(actions.failCodeCategories.fetchFailCodeCategories());
  const checkFailCodeCategories = (failCodeCategoryIds) =>
    dispatch(actions.failCodeCategories.checkFailCodeCategories(failCodeCategoryIds));
  const deleteFailCodeCategories = (failCodeCategoryIds) =>
    dispatch(actions.failCodeCategories.deleteFailCodeCategories(failCodeCategoryIds));
  const clearFailCodeCategoriesErrors = () =>
    dispatch(actions.failCodeCategories.clearFailCodeCategoriesErrors());

  // failCodeCategory
  const selectFailCodeCategory = (failCodeCategory) =>
    dispatch(actions.failCodeCategory.selectFailCodeCategory(failCodeCategory));
  const updateFailCodeCategory = (failCodeCategory) =>
    dispatch(actions.failCodeCategory.updateFailCodeCategory(failCodeCategory));
  const resetFailCodeCategory = () => dispatch(actions.failCodeCategory.resetFailCodeCategory());
  const addFailCodeCategory = (failCodeCategory, pgid) =>
    dispatch(actions.failCodeCategory.addFailCodeCategory(failCodeCategory, pgid));

  // failCodes
  const checkFailCode = (failCodeIds) => dispatch(actions.failCodes.checkFailCode(failCodeIds));
  const deleteFailCodes = (failCodesIds) =>
    dispatch(actions.failCodes.deleteFailCodes(failCodesIds));
  const resetFailCode = () => dispatch(actions.failCode.resetFailCode());
  const clearFailCodesErrors = () => dispatch(actions.failCodes.clearFailCodesErrors());

  // failCode
  const selectFailCode = (failCode) => dispatch(actions.failCode.selectFailCode(failCode));
  const updateFailCode = (failCode) => dispatch(actions.failCode.updateFailCode(failCode));
  const addFailCode = (failCode) => dispatch(actions.failCode.addFailCode(failCode));

  // associated tests
  const fetchFailCodeAssociatedTests = (failCodeId) =>
    dispatch(actions.failCode.fetchFailCodeAssociatedTests(failCodeId));
  const setInitiallySelectedPlatformValue = (platformValue) =>
    dispatch(actions.platforms.setInitiallySelectedPlatformValue(platformValue));
  const setInitiallySelectedTest = (test) =>
    dispatch(actions.tests.setInitiallySelectedTestDetails(test));

  // dialogs
  const hideDialog = () => dispatch(actions.dialog.hide());
  const showDialog = (dialog, callback) => dispatch(actions.dialog.show(dialog, callback));

  const history = useHistory();

  const { Option } = Select;

  useEffect(() => {
    fetchFailCodePriorityGroups();
    fetchFailCodeCategories();

    return () => {
      selectFailCode();
      checkFailCode([]);
      hideDialog();
    };
  }, []);

  useEffect(() => {
    if (!failCodePriorityGroupPGID && defaultPriorityGroup) {
      selectFailCodePriorityGroup(defaultPriorityGroup.id);
    }
  }, [defaultPriorityGroup, failCodePriorityGroupPGID]);

  useEffect(() => {
    const isError = failCodeCategoriesErrors != null;
    const isAssociatedTestError = isError ? failCodeCategoriesErrors.isAssociatedTestError : false;

    if (isAssociatedTestError) {
      displayDeleteError(failCodeCategoriesErrors.message, "failCodeCategory");
    } else if (isError) {
      displayErrorMessage(failCodeCategoriesErrors);
    } else {
      const resetSelection = checkedFailCodeCategories.indexOf(selectedFailCodeCategoryId) > -1;
      if (resetSelection) {
        selectFailCodeCategory();
      }
    }
  }, [failCodeCategoriesErrors]);

  useEffect(() => {
    const isError = failCodesErrors != null;
    const isAssociatedTestError = isError ? failCodesErrors.isAssociatedTestError : false;

    if (isAssociatedTestError) {
      displayDeleteError(failCodesErrors.message, "failCode");
    } else if (isError) {
      displayErrorMessage(failCodesErrors);
    } else {
      const resetSelection = checkedFailCodes.indexOf(selectedFailCodeId) > -1;
      if (resetSelection) {
        selectFailCode();
      }
    }
  }, [failCodesErrors]);

  const displayErrorMessage = (errors) => {
    if (errors.message.length > 0) {
      showDialog({
        title: "Error",
        width: "450px",
        modal: true,
        content: (close) => (
          <DialogMessage close={close}>
            {errors.message.map((errors, index) => (
              <div key={index}>{errors}</div>
            ))}
          </DialogMessage>
        ),
      });
    }
  };

  const displayDeleteError = (details, type) => {
    if (details.length > 0) {
      showDialog({
        title: "Cannot delete fail codes with associated tests",
        width: "90%",
        modal: true,
        content: (close) =>
          type === "failCodeCategory" ? (
            <DialogMessage
              close={() => {
                close();
                clearFailCodeCategoriesErrors();
              }}
            >
              <FailCodeCategoriesDeleteError
                details={details}
                onLinkClicked={async (test) => {
                  await onAssociatedTestLinClicked(test);
                  close();
                }}
              />
            </DialogMessage>
          ) : (
            <DialogMessage
              close={() => {
                close();
                clearFailCodesErrors();
              }}
            >
              <FailCodesDeleteError
                details={details}
                onLinkClicked={async (test) => {
                  await onAssociatedTestLinClicked(test);
                  close();
                }}
              />
            </DialogMessage>
          ),
      });
    }
  };

  const onDeleteFailCodeCategories = async () => {
    await deleteFailCodeCategories(checkedFailCodeCategories);
    fetchFailCodePriorityGroups();
  };

  const onDeleteFailCodes = () => {
    deleteFailCodes(checkedFailCodes);
  };

  const onAddFailCodePriorityGroup = () => {
    showDialog({
      title: "Add Fail Code Priority Group",
      width: "600px",
      modal: true,
      focusOnMount: true,
      initialFocus: "fail-codes-priority-group-name",
      content: (close) => (
        <FailCodePriorityGroupEditor
          failCodePriorityGroup={newPriorityGroup}
          onCancel={close}
          onConfirm={async (failCodePriorityGroup) => {
            close();
            const response = await addFailCodePriorityGroup(failCodePriorityGroup);
            await fetchFailCodePriorityGroups();
            if (failCodePriorityGroupError != null) {
              displayErrorMessage(failCodePriorityGroupError);
            }
            const id = response?.UpsertedID;
            if (id) {
              selectFailCodePriorityGroup(id);
            }
          }}
        />
      ),
    });
  };
  const onAddFailCodeCategory = async () => {
    const failCodeCategory = {
      name: {
        en: "",
        fr: "",
        es: "",
      },
      failFast: false,
    };
    await selectFailCodeCategory(failCodeCategory);

    showDialog({
      title: "Add Fail Category",
      width: "600px",
      modal: true,
      content: (close) => (
        <FailCodeCategoryEditor
          onCancel={() => {
            close();
            resetFailCodeCategory();
          }}
          onConfirm={async (failCodeCategory) => {
            close();
            const newFailCodeCategory = await addFailCodeCategory(
              failCodeCategory,
              failCodePriorityGroup.id
            );
            await fetchFailCodeCategories();
            await fetchFailCodePriorityGroups();
            if (failCodeCategoriesErrors != null) {
              displayErrorMessage(failCodeCategoriesErrors);
            }
            if (newFailCodeCategory != null && newFailCodeCategory.id != null) {
              selectFailCodeCategory(newFailCodeCategory);
            }
          }}
        />
      ),
    });
  };

  const onAddFailCode = async () => {
    const failCode = {
      categoryId: selectedFailCodeCategoryId,
      name: "",
      description: {
        en: "",
        fr: "",
        es: "",
      },
      failFast: false,
      type: "desktop",
      route: "",
    };

    await selectFailCode(failCode);

    showDialog({
      title: "Add Fail Code",
      width: "450px",
      modal: true,
      focusOnMount: true,
      initialFocus: "#fail-codes-editor-name",
      content: (close) => (
        <FailCodeEditor
          canEditFailFast={!failCodeCategoryFailFast}
          onCancel={() => {
            close();
            resetFailCode();
          }}
          onConfirm={async (failCode) => {
            close();
            const data = await addFailCode(failCode);
            await Promise.all([fetchFailCodeCategories(), fetchFailCodePriorityGroups()]);
            if (failCodeErrors != null) {
              displayErrorMessage(failCodeErrors);
            }

            if (data != null && data.id != null) {
              const newFailCode = {
                ...failCode,
                id: data.id,
              };
              selectFailCode(newFailCode);
            }
          }}
          onCreateAnother={async (failCode) => {
            await addFailCode(failCode);
            await Promise.all([fetchFailCodeCategories(), fetchFailCodePriorityGroups()]);
            resetFailCode();
            if (failCodeErrors != null) {
              displayErrorMessage(failCodeErrors);
            }
          }}
        />
      ),
    });
  };

  const onSelectFailCodeCategory = (failCodeCategoryId) => {
    const failCodeCategory = failCodeCategories.filter(
      (failCodeCategory) => failCodeCategory.id === failCodeCategoryId
    )[0];
    selectFailCodeCategory(failCodeCategory);
    selectFailCode();
  };

  const onSelectFailCode = (failCodeId) => {
    const failCode = failCodes.filter((failCode) => failCode.id === failCodeId)[0];
    selectFailCode(failCode);
    fetchFailCodeAssociatedTests(failCode.id);
  };

  const onUpdateFailCodeCategory = async (failCodeCategory) => {
    await updateFailCodeCategory(failCodeCategory);

    if (failCodeCategoryErrors != null) {
      displayErrorMessage(failCodeCategoryErrors);
    }
    if (failCodeCategory.id !== selectedFailCodeCategoryId) {
      selectFailCodeCategory(failCodeCategory);
    }
    return failCodeCategory.id;
  };

  const onUpdateFailCode = async (failCode) => {
    await updateFailCode(failCode);
    if (failCodeErrors != null) {
      displayErrorMessage(failCodeErrors);
    }
    if (failCode.id !== selectedFailCodeId) {
      selectFailCode(failCode);
    }
    return failCode.id;
  };

  const onSetNameFilter = (value) => {
    selectFailCodeCategory();
    selectFailCode();
    setNameFilter(value);
  };

  const onAssociatedTestLinClicked = async (test) => {
    await setInitiallySelectedPlatformValue(test.platform);
    await setInitiallySelectedTest({ className: test.className, platform: test.platform });
    history.push("/test-configurations");
  };

  const onUpdateSelectedPriorityGroup = async (groupId) => {
    await selectFailCodePriorityGroup(groupId);
  };

  const onUpdatePriorityGroup = (priorityGroupCategories) => {
    updateFailCodePriorityGroup({
      ...failCodePriorityGroup,
      failCodeCategories: priorityGroupCategories,
    });
  };

  const openEditPriorityGroupDialog = () => {
    showDialog({
      title: "Edit Priority Group Name",
      width: "600px",
      modal: true,
      focusOnMount: true,
      initialFocus: "fail-codes-priority-group-name",
      content: (close) => (
        <FailCodePriorityGroupEditor
          failCodePriorityGroup={failCodePriorityGroup}
          onCancel={close}
          onConfirm={async (failCodePriorityGroup) => {
            close();
            await updateFailCodePriorityGroup(failCodePriorityGroup);
            await fetchFailCodePriorityGroups();
            if (failCodePriorityGroupError != null) {
              displayErrorMessage(failCodePriorityGroupError);
            }
          }}
        />
      ),
    });
  };

  const openDeletePriorityGroupDialog = () => {
    showDialog({
      title: "Delete Priority Group",
      width: "450px",
      modal: true,
      content: (close) => (
        <DialogConfirmation
          onCancel={close}
          textButtonConfirm="Delete"
          onConfirm={async () => {
            close();
            await deleteFailCodePriorityGroup(failCodePriorityGroup.id);
            await fetchFailCodePriorityGroups();
            if (failCodePriorityGroupError != null) {
              displayErrorMessage(failCodePriorityGroupError);
            }
          }}
        >
          <p className="align-center">
            Are you sure you want to delete the priority group "{failCodePriorityGroup.name.en}"?
          </p>
        </DialogConfirmation>
      ),
    });
  };

  return (
    <div className="fail-codes-categories-page panel-container layout-column layout-column--start flex--noshrink">
      <div className="fail-codes-table">
        <SectionHeader className="flex--noshrink">
          <Row align="space-between center" style={{ width: "100%" }}>
            <div className="filters layout-row layout-row--start-center">
              <InputSearch
                placeholder="Search Fail Categories"
                onChange={onSetNameFilter}
                initialValue={nameFilter}
                trim
              />

              {failCodePriorityGroups ? (
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  value={failCodePriorityGroup?.id}
                  onChange={onUpdateSelectedPriorityGroup}
                  options={Object.entries(failCodePriorityGroups).map(([id, group]) => ({
                    value: id,
                    label: `${group.name.en}${group.isDefault ? " (default)" : ""}`,
                  }))}
                  dropdownMatchSelectWidth={false}
                  style={{ marginLeft: "1em", minWidth: 150 }}
                />
              ) : (
                "No fail code priority groups to select from"
              )}

              <AsciButton color="green" onClick={onAddFailCodePriorityGroup}>
                New Priority Group
              </AsciButton>

              {failCodePriorityGroup && !failCodePriorityGroup.isDefault && (
                <div style={{ display: "flex" }}>
                  <Button
                    icon
                    className="icon-btn white"
                    iconClassName="icon-pencil"
                    disabled={failCodePriorityGroup?.isDefault}
                    onClick={() => openEditPriorityGroupDialog()}
                  />

                  <Button
                    icon
                    className="icon-btn trash"
                    iconClassName="icon-trash"
                    disabled={failCodePriorityGroup?.isDefault}
                    onClick={() => openDeletePriorityGroupDialog()}
                  />
                </div>
              )}
            </div>

            {failCodesIsLoading ||
            failCodeCategoriesIsLoading ||
            failCodePriorityGroupsIsLoading ? (
              <LoadingSpinner size="large" iconColor="white" />
            ) : null}
          </Row>
        </SectionHeader>
        <div className="content-wrapper layout-row flex flex--noshrink">
          <div className="selection-columns layout-row">
            <div className="primary-container layout-column flex--noshrink">
              <h2 className="truncate header-column">Fail Category</h2>

              <FailCodeCategoriesList
                checkedIds={checkedFailCodeCategories}
                failCodeCategories={failCodeCategories}
                isLoading={failCodeCategoriesIsLoading || failCodePriorityGroupsIsLoading}
                onChange={(failCodeCategory) => onUpdateFailCodeCategory(failCodeCategory)}
                onCancel={() => resetFailCodeCategory()}
                onCheck={(failCodeCategoryId) => checkFailCodeCategories(failCodeCategoryId)}
                onSelect={(failCodeCategoryId) => onSelectFailCodeCategory(failCodeCategoryId)}
                selectedId={selectedFailCodeCategoryId}
                showDialog={showDialog}
                onUpdatePriorityGroup={onUpdatePriorityGroup}
                failCodePriorityGroup={failCodePriorityGroup}
              />
            </div>
            <div className="secondary-container layout-column flex--noshrink">
              <h2 className="truncate header-column">Fail Code</h2>
              <FailCodesList
                checkedIds={checkedFailCodes}
                failCodes={failCodes}
                isLoading={failCodeIsLoading || failCodePriorityGroupsIsLoading}
                onChange={(failCode) => onUpdateFailCode(failCode)}
                onCancel={() => resetFailCode()}
                onCheck={(failCodeId) => checkFailCode(failCodeId)}
                onSelect={(failCodeId) => onSelectFailCode(failCodeId)}
                selectedId={selectedFailCodeId}
                showDialog={showDialog}
                isEditableFailFast={!failCodeCategoryFailFast}
                onUpdatePriorityGroup={onUpdatePriorityGroup}
                failCodePriorityGroup={failCodePriorityGroup}
              />
            </div>

            <div className="tertiary-container layout-column flex--noshrink">
              <h2 className="truncate header-column">Associated Tests</h2>
              <div className="row centered subheader-column">
                <div className="flex--10 icon" />
                <div className="flex--30 name">Name</div>
                <div className="flex--60">Description</div>
              </div>
              <FailCodeAssociatedTestsList
                testConfigs={testsAssociatedWithSelectedFailCode}
                emptyMessage={
                  selectedFailCodeId && !associatedTestsLoading
                    ? "There is no tests associated with this fail code."
                    : undefined
                }
                onLinkClicked={onAssociatedTestLinClicked}
              />
            </div>
          </div>
        </div>
        <div className="actions actions-footer layout-row">
          <div className="primary-container layout-row">
            <AsciButton
              color="red"
              disabled={checkedFailCodeCategories.length === 0}
              onClick={() => onDeleteFailCodeCategories()}
            >
              {checkedFailCodeCategories.length > 0
                ? `Delete ${checkedFailCodeCategories.length} selected`
                : "Delete"}
            </AsciButton>
            <AsciButton color="blue" onClick={onAddFailCodeCategory}>
              New Category
            </AsciButton>
          </div>
          <div className="secondary-container layout-row">
            <AsciButton
              color="red"
              disabled={checkedFailCodes.length === 0}
              onClick={() => onDeleteFailCodes()}
            >
              {checkedFailCodes.length > 0
                ? `Delete ${checkedFailCodes.length} selected`
                : "Delete"}
            </AsciButton>
            <AsciButton
              color="blue"
              disabled={
                selectedFailCodeCategoryId == null || selectedFailCodeCategoryId.length === 0
              }
              onClick={onAddFailCode}
            >
              New Fail Code
            </AsciButton>
          </div>
          <div className="tertiary-container layout-row" />
        </div>
      </div>
    </div>
  );
};

export default FailCodesPage;
