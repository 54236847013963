import React, { useMemo } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Table, Tag, Tooltip, Typography } from "antd";
import { parseISO } from "date-fns";

import { ExportedReport } from "~/actions/exportedReports.actions";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import ExportedReportFilters from "~/reporting/ExportedReports/ExportedReportFilters.component";
import { ReportTypesByName } from "~/selectors/reports.selectors";
import {
  archiveScheduledReport,
  ScheduledReport,
  scheduledReportsSelectors,
} from "../model/scheduledReportsSlice";

const { Text } = Typography;

type Props = {
  reportTypesByName: ReportTypesByName;
  applyFilters: (report: ExportedReport, triggerSearch?: boolean) => void;
  onEdit: (scheduledReport: ScheduledReport) => void;
};

export const ScheduledReports = ({ reportTypesByName, applyFilters, onEdit }: Props) => {
  const { t, language } = useTranslation("reporting");
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(
    (state) => state.reportingModule.scheduledReports.listStatus === "loading"
  );
  const allScheduledReports = useAppSelector(scheduledReportsSelectors.selectAll);

  const timeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
    [language]
  );

  const renderName = ({ type, name }: ExportedReport) =>
    t("common:multi", { dict: reportTypesByName[type]?.displayName }) || name || type;

  const renderSchedule = ({ repeat }: ScheduledReport) => {
    const { every } = repeat;
    switch (every) {
      case "week": {
        const weekdays = repeat.on.map(
          (weekday) =>
            ({
              sunday: t("sunday"),
              monday: t("monday"),
              tuesday: t("tuesday"),
              wednesday: t("wednesday"),
              thursday: t("thursday"),
              friday: t("friday"),
              saturday: t("saturday"),
            }[weekday])
        );
        const full = weekdays.join(", ");

        if (repeat.on.length === 7) return <Text>{t("every-day")}</Text>;

        if (repeat.on.length > 3) {
          const preview = `${weekdays.slice(0, 3).join(", ")}…`;

          return (
            <Tooltip title={full}>
              <Text>{preview}</Text>
            </Tooltip>
          );
        }

        return <Text>{full}</Text>;
      }

      case "month": {
        return (
          <Text>
            {
              {
                "1st-day": t("1st-day"),
                "15th-day": t("15th-day"),
              }[repeat.on]
            }
          </Text>
        );
      }
      default: {
        const err: never = every; // if error, there is an unhandled case in the switch
        throw new TypeError(err);
      }
    }
  };

  return (
    <Table
      dataSource={allScheduledReports}
      columns={[
        {
          title: t("table.name"),
          key: "name",
          render: (_, { id, displayName, exportedReport }) => (
            <Space.Compact direction="vertical">
              <span data-testid={id}>{(displayName ?? "") || renderName(exportedReport)}</span>
              <Text type="secondary">{renderName(exportedReport)}</Text>
            </Space.Compact>
          ),
        },
        {
          title: t("table.format"),
          dataIndex: ["exportedReport", "format"],
          render: (_, { exportedReport }) => exportedReport.format.toUpperCase(),
        },
        {
          title: t("table.last-run"),
          dataIndex: "lastRun",
          render: (_, { lastRun }) => lastRun != null && timeFormat.format(parseISO(lastRun)),
        },
        {
          title: t("table.next-run"),
          dataIndex: "nextRun",
          render: (_, { nextRun }) => nextRun != null && timeFormat.format(parseISO(nextRun)),
        },
        {
          title: t("table.schedule"),
          dataIndex: "schedule",
          render: (_, scheduledReport) => renderSchedule(scheduledReport),
        },
        {
          title: t("table.status"),
          dataIndex: "active",
          render: (active: boolean) =>
            active ? (
              <Tag color="#5cd9a7">{t("active")}</Tag>
            ) : (
              <Tag color="#e55e67">{t("inactive")}</Tag>
            ),
        },
        {
          title: t("table.filters"),
          key: "filters",
          render: (_, { exportedReport }) => <ExportedReportFilters report={exportedReport} />,
        },
        {
          title: t("table.actions"),
          key: "actions",
          align: "right",
          width: 80,
          render: (_, scheduledReport) => {
            const { id, exportedReport } = scheduledReport;

            return (
              <Space>
                {exportedReport.type === "processRuns" && (
                  <Button
                    title={t("quick-search")}
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      applyFilters(exportedReport, true);
                    }}
                  />
                )}

                <Button
                  title={t("apply-filters")}
                  shape="circle"
                  icon={<FilterOutlined />}
                  onClick={() => {
                    applyFilters(exportedReport);
                  }}
                />

                <Button
                  title={t("edit-report")}
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    onEdit(scheduledReport);
                  }}
                />

                <Popconfirm
                  title={t("delete-confirmation")}
                  okText={t("yes")}
                  okType="danger"
                  cancelText={t("no")}
                  onConfirm={() => {
                    dispatch(archiveScheduledReport(id))
                      .unwrap()
                      .then(() => {
                        void message.success(t("delete-success"));
                      })
                      .catch((rejectedValue: string) => {
                        void message.error(rejectedValue);
                      });
                  }}
                >
                  <Button
                    title={t("delete-report")}
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Space>
            );
          },
        },
      ]}
      rowKey="id"
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50"],
      }}
      loading={
        isLoading
          ? {
              indicator: <LoadingOutlined style={{ color: "#005288" }} />,
              size: "large",
            }
          : false
      }
    />
  );
};
