/**
* @ngdoc component
* @name AsciSearch
*
* @description
* Insert an input text with search icon and clear button
* Text injected inside the search input is used to filter the array. A new array is returned as result.
*
*
* @example
    <AsciSearch
        array={this.state.data},
        callback={this.searchCallback.bind(this)},
        keys={["username", "restart", "user_profile.name"]}
    />

* @param {array} (Required) Array to filter
* @param {callback} (Required) Function that will be executed after filtering, the state updated must be different from the "array"
*
*    searchCallback(searchResult) {
*        this.setState({
*            example: searchResult
*        });
*    }
*
* @param {keys} (Required) Accepts a string or an array of strings. The string(s) can either have the format "prop" to get a top level value,
*    or "profile.name" to get a value that is not on the top level.
*    keys: "key1"
*       OR
*    keys: ["key1", "key2", "key5.something.somethingelse"]
*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FontIcon, Paper, TextField } from "react-md";

import "./AsciSearch.component.scss";

export default class AsciSearch extends Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    array: PropTypes.arrayOf(PropTypes.object).isRequired,
    callback: PropTypes.func.isRequired,
    caseSensitive: PropTypes.bool,
    keys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    query: "",
    placeholder: "Search",
    array: [],
    callback: () => {},
    caseSensitive: false,
    disabled: false,
    keys: [],
  };

  constructor(props) {
    super();

    this.state = {
      query: props.query || "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query != undefined && nextProps.query != this.state.query) {
      this.handleSeach(nextProps.query);
    }
  }

  handleSeach(query = "") {
    this.setState(
      {
        query,
      },
      () => {
        let searchResult = this.props.array;

        if (query.length > 0) {
          const isSingleKey = typeof this.props.keys === "string";

          if (isSingleKey) {
            searchResult = this.search(this.props.array, query, this.props.keys);
          } else {
            searchResult = (this.props.keys || [])
              .map((key) => this.search(this.props.array, query, key))
              .reduce((pre, cur) => pre.concat(cur))
              .filter((item, index, array) => array.indexOf(item) == index);
          }
        }

        if (searchResult && this.props.callback) {
          this.props.callback(searchResult, query);
        }
      }
    );
  }

  search(array = [], query, key = "") {
    query = query.trim();

    if (!this.props.caseSensitive) {
      query = query.toLowerCase();
    }

    // Path to the value (ex: "profile.personal.name")
    const path = key.split(".");

    return array.filter((item) => {
      let value;
      if (path.length > 1) {
        // Deep key
        value = path.reduce((res, prop) => res[prop], item);
      } else {
        // Top level key
        value = item[path[0]];
      }

      if (value != null) {
        if (!this.props.caseSensitive) {
          value = value.toLowerCase();
        }
        return value.indexOf(query) > -1;
      }
      return false;
    });
  }

  clearSearch() {
    this.handleSeach("");
  }

  render() {
    return (
      <div className="asci-search">
        <Paper raiseOnHover className="search-container" zDepth={1}>
          <TextField
            leftIcon={<FontIcon iconClassName="icon-search" />}
            id="search-text-field"
            block
            lineDirection="left"
            placeholder={this.props.placeholder}
            className="table-text-field"
            onChange={this.handleSeach.bind(this)}
            value={this.state.query}
            disabled={this.props.disabled}
          />
          {this.state.query.length > 0 ? (
            <div>
              <Button
                floating
                className="asci-btn white-button mini"
                iconClassName="icon-close"
                onClick={this.clearSearch.bind(this)}
                disabled={this.props.disabled}
              />
            </div>
          ) : (
            ""
          )}
        </Paper>
      </div>
    );
  }
}
