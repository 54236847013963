import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer("workstation", "crashDetails", (crashId) =>
  WorkstationManagement.QUERY(
    WorkstationManagement.gqlBuilder(`{
        crash(id: "${crashId}") {
            process,
            deviceInfo,
            userComment,
            services,
            tests,
            logsLocation
        }
    }`)
  ).then((response) => response.payload.data.crash)
);
