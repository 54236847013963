export const BARCODE = "barcode";
export const RECORDING = "recording";
export const TEXT = "text";
export const IMAGE = "image";
export const QRCODE = "qrcode";

export const FIELD_TYPES = [RECORDING, TEXT, IMAGE, BARCODE, QRCODE];

const DEFAULT_PATH = "deviceInfo.IMEI";

export const FIELDS_DEFAULTS = {
  [RECORDING]: () => ({
    path: DEFAULT_PATH,
    includeLabel: false,
    customLabel: "",
  }),
  [TEXT]: () => ({
    value: "",
  }),
  [IMAGE]: () => ({
    data: "",
    height: 80,
    width: 80,
    fitShape: false,
    keepAspectRatio: true,
  }),
  [BARCODE]: () => ({
    path: DEFAULT_PATH,
    format: "CODE128",
    displayValue: false,
    flat: false,
    text: "",
    textPosition: "bottom",
    textMargin: 1,
    font: "Cousine",
    fontSize: 10,
    margin: 0,
    width: 1,
    height: 20,
    lineColor: "#000",
    background: "#fff",
    fitShape: false,
  }),
  [QRCODE]: () => ({
    path: DEFAULT_PATH,
    size: 32,
    bgColor: "#FFF",
    fgColor: "#000",
    level: "L",
    includeMargin: false,
    fitSize: false,
  }),
};

export const getFieldDefault = (fieldType) => FIELDS_DEFAULTS[fieldType]();
