import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchProcessesByServiceSuiteBegin = createAction(
  "canned-reports/FETCH_PROCESSES_BY_SERVICE_SUITE_BEGIN"
);
export const fetchProcessesByServiceSuiteSuccess = createAction("canned-reports/FETCH_PROCESSES_BY_SERVICE_SUITE_SUCCESS"); // prettier-ignore
export const fetchProcessesByServiceSuiteError = createAction(
  "canned-reports/FETCH_PROCESSES_BY_SERVICE_SUITE_ERROR"
);

export const fetchProcessesByServiceSuite =
  (facilityId, teamId, programId, configId, platformId, from, to) => async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`{
        processesByServiceSuite(
            facility: "${facilityId === "all" ? "" : facilityId}",
            team: "${teamId === "all" ? "" : teamId}",
            program: "${programId === "all" ? "" : programId}",
            servicesuite: "${configId === "all" ? "" : configId}",
            os: "${platformId === "all" ? "" : platformId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Facility {
                name,
                faid
            },
            ChartData {
                EntityID,
                RawData {
                    Group,
                    Results {
                        Result,
                        Num
                    }
                }
            }
        }
    }`);

    dispatch(fetchProcessesByServiceSuiteBegin());

    try {
      const response = await CustomerManagement.QUERY(query);
      const processesByServiceSuite =
        (((response || {}).payload || {}).data || {}).processesByServiceSuite || [];

      dispatch(fetchProcessesByServiceSuiteSuccess({ processesByServiceSuite }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      dispatch(fetchProcessesByServiceSuiteError({ errors: message }));
    }
  };
