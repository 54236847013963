import React, { useCallback, useEffect, useState } from "react";
import { Table, Select } from "antd";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Button } from "react-md";

import actions from "~/actions";
import {
  AsciButton,
  DialogConfirmation,
  DialogMessage,
  InputSearch,
  SectionHeader,
} from "~/global";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { useTablePagination } from "~/shared/lib/useTablePagination";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import CustomerUserEditor from "./CustomerUserEditor.component";
import CustomerUserEditorButtons from "./CustomerUserEditorButtons.component";

const CustomerUsers = ({
  addUserCallback,
  csid = "",
  facilitiesById = {},
  facilitiesList = [],
}) => {
  const { t } = useTranslation("customerUsers");
  const dispatch = useAppDispatch();

  const customerName = useAppSelector(moduleSelectors.customerIdentity.getCustomerName);
  const users = useAppSelector(moduleSelectors.customerUsers.getFilteredUsers);
  const usersIsLoading = useAppSelector(
    (state) => state.modules.customerManagement.customerUsers.isLoading
  );
  const numOfResults = useAppSelector(
    (state) => state.modules.customerManagement.customerUsers.numOfResults
  );
  const roles = useAppSelector((state) => state.modules.customerManagement.customerUsers.roles);
  const filter = useAppSelector((state) => state.modules.customerManagement.customerUsers.filter);
  const [keyword, setKeyword] = useState(undefined);
  const { page, pageSize, changeTable } = useTablePagination(
    useCallback(
      ({ page, pageSize }, filters) => {
        void dispatch(
          moduleActions.customerUsers.fetchUsers(
            { page, perPage: pageSize, filters, keyword },
            csid
          )
        );
      },
      [csid, dispatch, keyword]
    )
  );

  useEffect(() => {
    if (isEmpty(roles)) {
      dispatch(moduleActions.customerUsers.fetchUserRoles());
    }
  }, [dispatch, roles]);

  const editUserCallback = (user) => {
    dispatch(moduleActions.customerUserEditor.setUser(user));
    dispatch(
      actions.dialog.show(
        {
          title: t("title-edit"),
          width: "650px",
          modal: true,
          content: (close) => (
            <div>
              <CustomerUserEditor
                onSave={() => {
                  dispatch(moduleActions.customerUsers.onSaveUser());
                  close();
                  dispatch(moduleActions.customerUserEditor.onClear());
                }}
                facilitiesById={facilitiesById}
                facilitiesList={facilitiesList}
                editMode
                onCancel={() => {
                  dispatch(moduleActions.customerUserEditor.onClear());
                  close();
                }}
              />
            </div>
          ),
          footer: (close) => (
            <CustomerUserEditorButtons
              onSave={() => {
                dispatch(moduleActions.customerUsers.onSaveUser());
                close();
                dispatch(moduleActions.customerUserEditor.onClear());
              }}
              onCancel={() => {
                dispatch(moduleActions.customerUserEditor.onClear());
                close();
              }}
              editMode
            />
          ),
        },
        () => dispatch(dispatch(moduleActions.customerUserEditor.onClear()))
      )
    );
  };

  const confirmDeleteUser = (user) => {
    dispatch(
      actions.dialog.show({
        title: t("title-delete-user"),
        width: "450px",
        modal: true,

        content: (close) => (
          <DialogConfirmation
            textButtonCancel={t("button-text-cancel")}
            onCancel={close}
            onConfirm={() => {
              close();
              dispatch(moduleActions.customerUsers.onDeleteUser(user.userid));
            }}
            textButtonConfirm={t("button-text-confirm")}
          >
            <p className="align-center">{t("are-you-sure", { user: user.name })}</p>
            <p className="align-center">
              <Trans t={t} i18nKey="will-be-impossible-to-connect" />
            </p>
          </DialogConfirmation>
        ),
      })
    );
  };

  const onInfoClicked = () => {
    dispatch(
      actions.dialog.show({
        title: t("title-usage-information"),
        width: "450px",
        modal: false,
        content: (close) => (
          <DialogMessage close={close} textButtonClose={t("close-button")}>
            <div>{t("email-first-name-and-last-name")}</div>
            <br />
            <div>{t("user-password-is-required")}</div>
            <br />
            <div>{t("at-last-one-client")}</div>
            <br />
            <div>{t("click-the-list-header")}</div>
          </DialogMessage>
        ),
      })
    );
  };

  const renderFacilities = (user) =>
    user.hasAllFacilitiesAccess
      ? t("render-all")
      : user.facilities
          .map((facility) => facilitiesById[facility.faid]?.name ?? t("not-available"))
          .join(", ");

  const searchfaid = (faids) => {
    const keyword = faids.join("+");
    setKeyword(keyword);
  };

  return (
    <div>
      <SectionHeader title={t("header-customer") + customerName} description={csid}>
        <div className="push-right row centered">
          <Select
            style={{ minWidth: "200px", marginRight: "10px" }}
            mode="multiple"
            placeholder="Select facility"
            options={facilitiesList.map((facility) => ({
              value: facility,
              label: facilitiesById[facility]?.name,
            }))}
            onChange={(values) => {
              if (values[0] === "all") {
                searchfaid(["true"]);
              } else {
                searchfaid(values);
              }
            }}
          />
          <InputSearch
            placeholder={t("placeholder-search-users")}
            initialValue={filter}
            onChange={(value) => setKeyword(value)}
            trim
          />
          <AsciButton color="green" onClick={() => addUserCallback()}>
            {t("add-user")}
          </AsciButton>
          <Button
            icon
            className="icon-btn text-color--white"
            iconClassName="icon-info-circle"
            forceIconSize={32}
            forceIconFontSize
            onClick={() => onInfoClicked()}
          />
        </div>
      </SectionHeader>
      <Table
        rowKey="userid"
        loading={usersIsLoading}
        dataSource={users}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50"],
          current: page,
          pageSize,
          total: numOfResults,
        }}
        onChange={changeTable}
        columns={[
          {
            dataIndex: "email",
            title: t("columns-title-email-address"),
            render: (email) => email ?? t("not-available"),
          },
          {
            dataIndex: "name",
            title: t("columns-title-name"),
            render: (name) => name ?? t("not-available"),
          },
          {
            dataIndex: "roleName",
            filters: [
              { text: t("customerUserEditor:asci-administrator"), value: "asci-administrator" },
              { text: t("customerUserEditor:client-administrator"), value: "client-administrator" },
              { text: t("customerUserEditor:operator"), value: "operator" },
              {
                text: t("customerUserEditor:remote-diagnostics-operator"),
                value: "remote-diagnostics-operator",
              },
              { text: t("customerUserEditor:reporting"), value: "reporting" },
              { text: t("customerUserEditor:data-entry"), value: "data-entry" },
              { text: t("customerUserEditor:user-manager"), value: "user-manager" },
              { text: t("customerUserEditor:inventory-scanner"), value: "inventory-scanner" },
            ],
            title: t("title-role"),
            onFilter: (value, report) => value === report.roleName,
            render: (roleName) => t(`customerUserEditor:${roleName}`) ?? t("not-available"),
          },
          {
            dataIndex: "facilities",
            title: t("title-facility"),
            render: (_, user) => renderFacilities(user),
          },
          {
            dataIndex: "operatorId",
            title: t("title-operator-id"),
            render: (operatorId) => operatorId ?? t("not-available"),
          },
          {
            key: "buttons",
            render: (_, user) => (
              <div className="row centered to-end">
                <Button
                  icon
                  className="icon-btn"
                  iconClassName="icon-pencil"
                  onClick={() => editUserCallback(user)}
                />

                <Button
                  icon
                  className="icon-btn text-color--red"
                  iconClassName="icon-trash"
                  onClick={() => {
                    confirmDeleteUser(user);
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

CustomerUsers.propTypes = {
  addUserCallback: PropTypes.func,
  facilitiesById: PropTypes.object,
  facilitiesList: PropTypes.array,
  csid: PropTypes.string,
};

export default CustomerUsers;
