import * as auditResults from "./auditResults.actions";
import * as auditResultsPerTest from "./auditResultsPerTest.actions";
import * as processes from "./processes.actions";
import * as processesByOS from "./processesByOS.actions";
import * as processesByServiceSuite from "./processesByServiceSuite.actions";
import * as testProcessingTimes from "./testProcessingTimes.actions";

export default {
  auditResults,
  auditResultsPerTest,
  processes,
  processesByOS,
  processesByServiceSuite,
  testProcessingTimes,
};
