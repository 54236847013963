import { createReducer } from "@reduxjs/toolkit";

// NOTE: the t() comments are there so that i18next-parser knows to add these keys to the the translation catalog.

const initialState = [
  {
    // t("home:modules.client-deployment-title")
    title: "client-deployment-title",
    // t("home:modules.client-deployment-desc")
    description: "client-deployment-desc",
    urlName: "deployment",
    appName: "client-deployment",
    permission: { method: "GET", path: "/gql/deployment" },
  },

  {
    // t("home:modules.control-center-title")
    title: "control-center-title",
    urlName: "",
    permission: { method: "GET", path: "/gql/home" },
  },

  {
    // t("home:modules.control-center-title")
    title: "control-center-title",
    urlName: "home",
    permission: { method: "GET", path: "/gql/home" },
  },

  {
    // t("home:modules.custom-fields-editor-title")
    title: "custom-fields-editor-title",
    // t("home:modules.custom-fields-editor-desc")
    description: "custom-fields-editor-desc",
    urlName: "custom-fields",
    appName: "custom-fields-editor",
    permission: { method: "GET", path: "/gql/custom-fields" },
  },

  {
    // t("home:modules.label-design-title")
    title: "label-design-title",
    // t("home:modules.label-design-desc")
    description: "label-design-desc",
    urlName: "label-printer",
    appName: "label-designer",
    permission: { method: "GET", path: "/gql/labels" },
  },

  {
    // t("home:modules.remote-diagnostics-title")
    title: "remote-diagnostics-title",
    // t("home:modules.remote-diagnostics-desc")
    description: "remote-diagnostics-desc",
    urlName: "remote-diagnostics",
    appName: "remote-diagnostics",
    permission: { method: "GET", path: "/gql/remote-diagnostics" },
  },

  {
    // t("home:modules.dashboard-title")
    title: "dashboard-title",
    // t("home:modules.dashboard-desc")
    description: "dashboard-desc",
    urlName: "dashboard",
    appName: "dashboard",
    permission: { method: "GET", path: "/dashboard" },
  },

  {
    // t("home:modules.reporting-title")
    title: "reporting-title",
    // t("home:modules.reporting-desc")
    description: "reporting-desc",
    urlName: "reporting",
    appName: "reporting",
    permission: { method: "GET", path: "/reporting" },
  },

  {
    // t("home:modules.sku-groups-title")
    title: "sku-groups-title",
    // t("home:modules.sku-groups-desc")
    description: "sku-groups-desc",
    urlName: "sku-groups",
    appName: "sku-groups",
    permission: { method: "GET", path: "/inventory/sku-groups" },
  },

  {
    // t("home:modules.sku-manager-title")
    title: "sku-manager-title",
    // t("home:modules.sku-manager-desc")
    description: "sku-manager-desc",
    urlName: "sku-manager",
    appName: "sku-manager",
    permission: { method: "GET", path: "/inventory/skus" },
  },

  {
    // t("home:modules.model-descriptors-title")
    title: "model-descriptors-title",
    // t("home:modules.model-descriptors-desc")
    description: "model-descriptors-desc",
    urlName: "model-descriptors",
    appName: "model-descriptors",
    permission: { method: "GET", path: "/model-descriptors" },
  },

  {
    // t("home:modules.test-configurations-title")
    title: "test-configurations-title",
    // t("home:modules.test-configurations-desc")
    description: "test-configurations-desc",
    urlName: "test-configurations",
    appName: "test-configurations",
    permission: { method: "GET", path: "/gql/test-definitions" },
  },

  {
    // t("home:modules.customer-management-title")
    title: "customer-management-title",
    // t("home:modules.customer-management-desc")
    description: "customer-management-desc",
    urlName: "customer-management",
    appName: "customer-manager",
    permission: { method: "GET", path: "/gql/customers" },
  },

  {
    // t("home:modules.workstation-management-title")
    title: "workstation-management-title",
    // t("home:modules.workstation-management-desc")
    description: "workstation-management-desc",
    urlName: "workstations",
    appName: "workstations",
    permission: { method: "GET", path: "/gql", service: "workstationmanagement" },
  },

  {
    // t("home:modules.developers-zone-title")
    title: "developers-zone-title",
    // t("home:modules.developers-zone-desc")
    description: "developers-zone-desc",
    urlName: "developers",
    appName: "developers-zone",
    permission: { method: "GET", path: "/canSeeSDK" },
  },

  {
    // t("home:modules.program-management-title")
    title: "program-management-title",
    urlName: "programs",
    appName: "program-management",
    // t("home:modules.program-management-desc")
    description: "program-management-desc",
    permission: { method: "GET", path: "/programs" },
  },

  {
    // t("home:modules.test-suite-management-title")
    title: "test-suite-management-title",
    // t("home:modules.test-suite-management-desc")
    description: "test-suite-management-desc",
    urlName: "test-suites",
    appName: "test-suites-management",
    permission: { method: "GET", path: "/gql/test-suites" },
  },

  {
    // t("home:modules.user-action-monitor-title")
    title: "user-action-monitor-title",
    // t("home:modules.user-action-monitor-desc")
    description: "user-action-monitor-desc",
    urlName: "user-logs",
    appName: "user-logs",
    permission: { method: "GET", path: "/user-logs" },
  },

  {
    // t("home:modules.fail-codes-title")
    title: "fail-codes-title",
    // t("home:modules.fail-codes-desc")
    description: "fail-codes-desc",
    urlName: "fail-codes",
    appName: "fail-codes",
    permission: { method: "GET", path: "/gql/fail-codes" },
  },

  {
    // t("home:modules.version-management-title")
    title: "version-management-title",
    // t("home:modules.version-management-desc")
    description: "version-management-desc",
    urlName: "version-manager",
    appName: "version-manager",
    permission: { method: "GET", path: "/gql/software-versions" },
  },

  {
    // t("home:modules.documentation-and-training-title")
    title: "documentation-and-training-title",
    // t("home:modules.documentation-and-training-desc")
    description: "documentation-and-training-desc",
    urlName: "documentation-training",
    appName: "documentation-training",
    permission: { method: "GET", path: "/documentation" },
  },

  {
    // t("home:modules.data-import-tool-title")
    title: "data-import-tool-title",
    // t("home:modules.data-import-tool-desc")
    description: "data-import-tool-desc",
    urlName: "data-import/upload",
    appName: "data-import",
    permission: { method: "GET", path: "/data-import" },
  },

  {
    // t("home:modules.service-suite-templates-title")
    title: "service-suite-templates-title",
    // t("home:modules.service-suite-templates-desc")
    description: "service-suite-templates-desc",
    urlName: "service-suite-templates",
    appName: "service-suite-templates",
    permission: { method: "GET", path: "/service-suite-templates" },
  },
  {
    //  t("home:modules.inventory-title")
    title: "inventory-title",
    description: "inventory",
    urlName: "inventory",
    appName: "inventory",
    permission: { method: "GET", path: "/inventory" },
  },

  {
    // t("home:modules.analytics-title")
    title: "analytics-title",
    // t("home:modules.analytics-desc")
    description: "analytics-desc",
    urlName: "analytics",
    appName: "analytics",
    permission: { method: "GET", path: "/analytics" },
    isHidden: true,
  },

  {
    // t("home:modules.audit-results-title")
    title: "audit-results-title",
    // t("home:modules.audit-results-desc")
    description: "audit-results-desc",
    urlName: "audit-results",
    appName: "audit-results",
    permission: { method: "GET", path: "/audit-results" },
    isHidden: true,
  },

  {
    // t("home:modules.devices-processed-by-group-title")
    title: "devices-processed-by-group-title",
    // t("home:modules.devices-processed-by-group-desc")
    description: "devices-processed-by-group-desc",
    urlName: "devices-processed-by-group",
    appName: "devices-processed-by-group",
    permission: { method: "GET", path: "/devices-processed-by-group" },
    isHidden: true,
  },

  {
    // t("home:modules.audit-results-per-test-title")
    title: "audit-results-per-test-title",
    // t("home:modules.audit-results-per-test-desc")
    description: "audit-results-per-test-desc",
    urlName: "audit-results-per-test",
    appName: "audit-results-per-test",
    permission: { method: "GET", path: "/audit-results-per-test" },
    isHidden: true,
  },

  {
    // t("home:modules.devices-processed-title")
    title: "devices-processed-title",
    // t("home:modules.devices-processed-desc")
    description: "devices-processed-desc",
    urlName: "devices-processed",
    appName: "devices-processed",
    permission: { method: "GET", path: "/devices-processed" },
    isHidden: true,
  },

  {
    // t("home:modules.test-processing-times-title")
    title: "test-processing-times-title",
    // t("home:modules.test-processing-times-desc")
    description: "test-processing-times-desc",
    urlName: "test-processing-times",
    appName: "test-processing-times",
    permission: { method: "GET", path: "/test-processing-times" },
    isHidden: true,
  },
];

export default createReducer(initialState, {});
