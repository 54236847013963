import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ResultDownloader = (props) => {
  const { selectors, filtered, download, label, ...rest } = props;
  const result = useSelector(filtered ? selectors.getResults : selectors.getFilteredResults);

  const href = useMemo(
    () =>
      result ? `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(result))}` : "",
    [result]
  );

  return (
    <a href={href} download={download} {...rest}>
      {label}
    </a>
  );
};

ResultDownloader.defaultProps = {
  download: "result.json",
  label: "DOWNLOAD",
};

ResultDownloader.propTypes = {
  selectors: PropTypes.object.isRequired,
  download: PropTypes.string,
  filtered: PropTypes.bool,
  label: PropTypes.string,
};

export default ResultDownloader;
