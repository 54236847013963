import React from "react";
import PropTypes from "prop-types";

import { Steps } from "antd";
import { AsciWizardButton, AsciButton } from "~/global";
import * as Utils from "~/global/utils";
import { useTranslation } from "~/i18n";

import "./WizardNavigation.component.scss";

const WizardNavigation = (props) => {
  const { Step } = Steps;
  const { t } = useTranslation("wizardNavigation");

  const onPrev = async (step) => {
    props.onClick(step);
  };

  const onNext = async (step) => {
    props.onClick(step);
  };

  const isLastStep = props.currentIndex == props.sections.length - 1;

  return (
    <div className="cc-asci-wizard-navigation layout-row padding-bottom--10">
      <div className="nav-btn-container layout-column layout-column--center padding--5">
        {props.currentIndex != 0 ? (
          <AsciButton
            color="white"
            onClick={() => onPrev(props.currentIndex - 1)}
            disabled={props.disabled}
          >
            <div className="layout-row layout-row--center-center">
              <i className="icon-arrow-circle-left" />
              <span className="margin-left--5">{t("previous")}</span>
            </div>
          </AsciButton>
        ) : (
          <AsciButton color="white" onClick={() => props.onCancel()}>
            <div className="layout-row layout-row--center-center">
              <span>{t("cancel")}</span>
            </div>
          </AsciButton>
        )}
      </div>
      <Steps current={props.currentIndex} labelPlacement="vertical" className="navigation-wizard">
        {props.sections.map((section, index) => {
          const identification = Utils.toCamel(section.title);
          const nextEnabled =
            (index > props.currentIndex && !props.disabled) ||
            (index == props.currentIndex + 1 && !props.disabled);
          const prevEnabled = index < props.currentIndex && !props.disabled;
          const currentEnabled = index == props.currentIndex;
          const enabled = nextEnabled || prevEnabled;
          const btnEnabled = (enabled && !props.disabled) || currentEnabled;

          return (
            <Step
              key={identification}
              onClick={() => btnEnabled && props.onClick(index)}
              title={section.title}
              icon={
                <AsciWizardButton
                  step={index + 1}
                  className={
                    index == props.currentIndex
                      ? `active wizard-step-button-${index}`
                      : `wizard-step-button-${index}`
                  }
                  disabled={!btnEnabled}
                />
              }
            />
          );
        })}
      </Steps>

      <div className="nav-btn-container layout-column layout-column--center padding--5">
        {isLastStep ? null : (
          <AsciButton
            color="white"
            onClick={() => onNext(props.currentIndex + 1)}
            disabled={props.disabled}
          >
            <div className="layout-row layout-row--center-center">
              <span className="margin-right--5">{t("next")}</span>
              <i className="icon-arrow-circle-right" />
            </div>
          </AsciButton>
        )}
      </div>
    </div>
  );
};

WizardNavigation.propTypes = {
  currentIndex: PropTypes.number,
  sections: PropTypes.array,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

WizardNavigation.defaultProps = {
  sections: [],
  currentIndex: 0,
  disabled: false,
};

export default WizardNavigation;
