import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchProcessesByTeamBegin = createAction(
  "canned-reports/FETCH_PROCESSES_BY_TEAM_BEGIN"
);
export const fetchProcessesByTeamSuccess = createAction(
  "canned-reports/FETCH_PROCESSES_BY_TEAM_SUCCESS"
);
export const fetchProcessesByTeamError = createAction(
  "canned-reports/FETCH_PROCESSES_BY_TEAM_ERROR"
);

export const fetchProcessesByOperatorBegin = createAction(
  "canned-reports/FETCH_PROCESSES_BY_OPERATOR_BEGIN"
);
export const fetchProcessesByOperatorSuccess = createAction(
  "canned-reports/FETCH_PROCESSES_BY_OPERATOR_SUCCESS"
);
export const fetchProcessesByOperatorError = createAction(
  "canned-reports/FETCH_PROCESSES_BY_OPERATOR_ERROR"
);

export const fetchProcessesByTeam = (facilityId, programId, from, to) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        processesByTeam(
            facility: "${facilityId === "all" ? "" : facilityId}",
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Team {
                name,
                teamid,
                faid
            },
            ChartData {
                PerOpProductivity,
                Processes,
                FailedAuditsPercent,
                ProcessesUnique
            }
        }
    }`);

  dispatch(fetchProcessesByTeamBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const processesByTeam = (((response || {}).payload || {}).data || {}).processesByTeam || [];
    dispatch(fetchProcessesByTeamSuccess({ processesByTeam }));
  } catch (e) {
    if (e instanceof Error) throw e;

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(fetchProcessesByTeamError({ errors: message }));
  }
};

export const fetchProcessesByOperator =
  (facilityId, teamId, programId, from, to) => async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`{
        processesByOperator(
            facility: "${facilityId === "all" ? "" : facilityId}",
            team: "${teamId === "all" ? "" : teamId}",
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Operator {
                updateData {
                    user_metadata {
                        name
                    }
                }
            },
            ChartData {
                PerOpProductivity,
                Processes,
                FailedAuditsPercent,
                ProcessesUnique
            }
        }
    }`);

    dispatch(fetchProcessesByOperatorBegin());

    try {
      const response = await CustomerManagement.QUERY(query);
      const processesByOperator =
        (((response || {}).payload || {}).data || {}).processesByOperator || [];
      dispatch(fetchProcessesByOperatorSuccess({ processesByOperator }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      dispatch(fetchProcessesByOperatorError({ errors: message }));
    }
  };
