import React, { useState } from "react";
import PropTypes from "prop-types";
import Timekeeper from "react-timekeeper";
import { format, startOfDay, compareDesc, addHours, addMinutes, parseISO } from "date-fns";

import { AsciButton } from "~/global";
import { useTranslation } from "~/i18n";

import "./TimePicker.component.scss";

const TimePicker = ({ dateFrom = null, dateTo = null, setDates }) => {
  const { t } = useTranslation("TimePicker");

  const [fromTime, setFromTime] = useState(format(parseISO(dateFrom), "HH:mm"));
  const [toTime, setToTime] = useState(format(parseISO(dateTo), "HH:mm"));
  const fromDate = format(parseISO(dateFrom), "yyyy-MM-dd");
  const toDate = format(parseISO(dateTo), "yyyy-MM-dd");
  const [closeDisabled, setCloseDisabled] = useState(false);

  const [fromDateTime, setFromDateTime] = useState(new Date(dateFrom));
  const [toDateTime, setToDateTime] = useState(new Date(dateTo));

  function updateFromTime(time) {
    let fromDateTime = startOfDay(parseISO(dateFrom));
    fromDateTime = addHours(fromDateTime, time.hour);
    fromDateTime = addMinutes(fromDateTime, time.minute);
    const closeDisabled = compareDesc(fromDateTime, toDateTime) <= 0;

    setFromTime(time.formatted24);
    setFromDateTime(fromDateTime);
    setCloseDisabled(closeDisabled);
  }

  function updateToTime(time) {
    let toDateTime = startOfDay(parseISO(dateTo));
    toDateTime = addHours(toDateTime, time.hour);
    toDateTime = addMinutes(toDateTime, time.minute);
    const closeDisabled = compareDesc(fromDateTime, toDateTime) <= 0;

    setToTime(time.formatted24);
    setToDateTime(toDateTime);
    setCloseDisabled(closeDisabled);
  }

  function saveAndClose() {
    setDates(fromDateTime, toDateTime);
  }

  function cancelAndClose() {
    setDates();
  }

  const width = window.innerWidth;
  return (
    <div className="column spaced">
      <div className={width > 768 ? "row space-around" : "column spaced-centered"}>
        <div className="column centered">
          <div className="origin-date-indicator">{t("from", { fromDate })}</div>
          <Timekeeper
            time={fromTime}
            switchToMinuteOnHourSelect
            onChange={(time) => updateFromTime(time)}
          />
        </div>
        <div className="column centered">
          <div className="origin-date-indicator">{t("to", { toDate })}</div>
          <Timekeeper
            time={toTime}
            switchToMinuteOnHourSelect
            onChange={(time) => updateToTime(time)}
          />
        </div>
      </div>
      <div className={!closeDisabled ? "asci-hide" : "margin-error error-message row centered"}>
        {t("error-message")}
      </div>
      <div className="row centered">
        <AsciButton
          color="white"
          onClick={() => {
            cancelAndClose();
          }}
        >
          {t("cancel")}
        </AsciButton>
        <AsciButton
          color="blue"
          disabled={closeDisabled}
          onClick={() => {
            saveAndClose();
          }}
        >
          {t("ok")}
        </AsciButton>
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  setDates: PropTypes.func,
};

export default TimePicker;
