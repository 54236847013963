import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
  roles: [],
  rolesLoading: false,
  filter: "",
  initialized: false,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [moduleActions.customerUsers.fetchUsersBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.customerUsers.fetchUsersSuccess]: (state, action) => {
    const { users, numOfResults, pagination } = action.payload;

    state.isLoading = false;
    state.items = users;
    state.numOfResults = numOfResults;
    state.pagination = pagination;
    state.initialized = true;
  },
  [moduleActions.customerUsers.fetchUsersError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
    state.initialized = true;
  },
  // user roles
  [moduleActions.customerUsers.fetchUserRolesBegin]: (state) => {
    state.errors = null;
  },
  [moduleActions.customerUsers.fetchUserRolesSuccess]: (state, action) => {
    state.roles = action.payload.roles;
  },
  [moduleActions.customerUsers.fetchUserRolesError]: (state, action) => {
    state.errors = action.payload.error;
  },

  [moduleActions.customerEditor.resetCustomerEdition]: () => initialState,
  [moduleActions.customerUsers.saveUserBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.customerUsers.saveUserSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.users;
    state.numOfResults = action.payload.numOfResults;
  },
  [moduleActions.customerUsers.saveUserError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.message;
  },
  [moduleActions.customerUsers.setFilter]: (state, action) => {
    state.filter = action.payload;
  },
});
