import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabsContainer } from "react-md";
import { Page } from "~/global";

import * as DocumentationActions from "~/actions/Documentation.actions";
import DocumentTab from "../DocumentTab/DocumentTab.component";

import "./DocumentationTrainingPage.component.scss";

const DocumentationTrainingPage = () => {
  const [documentsByCategory, setDocumentsByCategory] = useState({});

  useEffect(() => {
    DocumentationActions.fetchDocumentation().then((documentsByCategory) => {
      setDocumentsByCategory(documentsByCategory);
    });
  }, []);

  const userGuides = (documentsByCategory || {})["User Guide"] || [];
  const training = (documentsByCategory || {})["Training"] || [];
  const other = (documentsByCategory || {})["Other"] || [];

  return (
    <Page className="documentation-training">
      <TabsContainer panelClassName="tabs-container" className="tabs-container-override">
        <Tabs tabId="docs-training-tabs">
          <Tab label="User Guide">
            <DocumentTab documents={userGuides} />
          </Tab>
          <Tab label="training">
            <DocumentTab documents={training} />
          </Tab>
          <Tab label="other">
            <DocumentTab documents={other} />
          </Tab>
        </Tabs>
      </TabsContainer>
    </Page>
  );
};

export default DocumentationTrainingPage;
