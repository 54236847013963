import React, { ReactElement, useMemo, useEffect } from "react";
import { BarChartOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { getAllTimezones } from "countries-and-timezones";
import { endOfToday, format, startOfToday } from "date-fns";
import { difference, pick } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";

import { Button } from "react-md";

import actions from "~/actions";
import { ShortFacility } from "~/actions/facilities.actions";
import {
  BorderedGroup,
  Column,
  DateTimeSelector,
  DialogMessage,
  Row,
  SectionHeader,
} from "~/global";
import CsvUpload from "~/features/csv-upload/CsvUpload";
import RecordingFacetsFilter, {
  SelectedFacet,
} from "~/features/recording-facets/RecordingFacetsFilter.component";
import { FacetOption } from "~/features/recording-facets/reportingFilters.slice";
import UniquenessFilter, {
  Uniqueness,
} from "~/features/recording-facets/UniquenessFilter.component";
import { relativeDateRanges } from "~/global/DateTimeSelector/DatePicker/DatePicker.component";
import { useAppDispatch } from "~/hooks";
import { useTranslation } from "~/i18n";

import "./ReportingFilters.scss";

export type DateRangeKeys = keyof typeof relativeDateRanges;

export type DateRange = DateRangeKeys | "custom";

export interface ReportingFiltersType {
  facilityId: string;
  facets: SelectedFacet[];
  /** IANA time zone name. */
  timeZone: string;
  /** ISO8601 date without time zone or trailing Z. */
  dateFrom: string;
  /** ISO8601 date without time zone or trailing Z. */
  dateTo: string;
  dateRange: DateRange;
  uniqueness: Uniqueness;
  keyword?: string;
}

type Props = {
  facilities: ShortFacility[];
  facetOptions: FacetOption[];
  reportingFilters: ReportingFiltersType;
  setReportingFilters: (reportingFilters: ReportingFiltersType) => void;
  csvSupport?: boolean;
  searchSupport?: boolean;
  onSearch?: () => void;
  excludeUniqueness?: boolean;
  currentReportType: string;
  clearFacets: boolean;
  setClearFacets: (clearFacets: boolean) => void;
};

const { Option, OptGroup } = Select;

export const getInitialFilters = (facilities: ShortFacility[]): ReportingFiltersType => ({
  facilityId: facilities[0].faid,
  facets: [],
  timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
  dateFrom: format(startOfToday(), "yyyy-MM-dd'T'HH:mm:ss"),
  dateTo: format(endOfToday(), "yyyy-MM-dd'T'HH:mm:ss"),
  dateRange: "today",
  uniqueness: "none",
});

export const ReportingFilters = ({
  facilities,
  facetOptions,
  reportingFilters,
  setReportingFilters,
  csvSupport = false,
  searchSupport = false,
  onSearch,
  excludeUniqueness = false,
  currentReportType,
  clearFacets,
  setClearFacets,
}: Props): ReactElement => {
  const { t } = useTranslation("reporting");
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const notClear = searchParams.get("notClear");
  const navigate = useNavigate();

  const onInfoClicked = () => {
    dispatch(
      actions.dialog.show({
        title: t("information"),
        width: "640px",
        modal: false,
        content: (close: any) => (
          <DialogMessage close={close}>
            <p>{t("exported-reports-expiry")}</p>
            <p>
              <BarChartOutlined style={{ paddingRight: "0.5em" }} />
              {t("chart-support-info")}
            </p>
          </DialogMessage>
        ),
      })
    );
  };

  const allTimezones = useMemo(() => getAllTimezones(), []);

  const facilityTimezones = useMemo(
    () =>
      Object.values(
        pick(
          allTimezones,
          facilities.map((facility) => facility.timezone)
        )
      ),
    [facilities, allTimezones]
  );

  const otherTimezones = useMemo(
    () => difference(Object.values(allTimezones), facilityTimezones),
    [allTimezones, facilityTimezones]
  );

  const stripTimeZone = (date: string) => date.replace(/[+-]\d\d\d\d|Z$/, "");

  const onSelectDateRange = ({
    dateFrom,
    dateTo,
    selectedRange,
  }: {
    dateFrom: string;
    dateTo: string;
    selectedRange?: { value: DateRangeKeys };
  }) => {
    /* Strip the time zone because the DateTimeSelector always reverts to the local time zone
       no matter what time zone it receives. */
    setReportingFilters({
      ...reportingFilters,
      dateFrom: stripTimeZone(dateFrom),
      dateTo: stripTimeZone(dateTo),
      dateRange: selectedRange?.value ?? "custom",
    });
  };
  useEffect(() => {
    if (clearFacets && notClear !== "true") {
      // Reset the value here
      // Then signal back that the value has been reset
      setClearFacets(false);
      const facets: SelectedFacet[] = [];
      setReportingFilters({ ...reportingFilters, facets });
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("notClear");
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  }, [clearFacets, navigate, reportingFilters]);

  return (
    <div className="reporting-form">
      <SectionHeader>
        <Row align="start center">
          {facilities.length > 0 && (
            <div className="facility-selector row centered">
              {/* TODO: use the SelectWithLabel component */}
              <label htmlFor="variable-options-selector" className="horizontal-label-margin">
                {t("facility")}
              </label>

              <Select
                showSearch
                value={reportingFilters.facilityId}
                onChange={(facilityId) => {
                  const facility = facilities.find((facility) => facility.faid === facilityId);

                  if (facility != null) {
                    const timeZone = facility.timezone;
                    setReportingFilters({ ...reportingFilters, facilityId, timeZone });
                  }
                }}
                dropdownMatchSelectWidth={false}
                style={{ minWidth: "120px" }}
              >
                {facilities.map((facility) => (
                  <Option value={facility.faid} key={facility.faid}>
                    {facility.name}
                  </Option>
                ))}
              </Select>

              <label htmlFor="variable-options-selector" className="horizontal-label-margin">
                {t("time-zone")}
              </label>

              <Select
                showSearch
                value={reportingFilters.timeZone}
                onChange={(timeZone) => {
                  setReportingFilters({ ...reportingFilters, timeZone });
                }}
                dropdownMatchSelectWidth={false}
                style={{ minWidth: "150px" }}
              >
                <OptGroup label={t("facility-timezones")}>
                  {facilityTimezones.map((tz) => (
                    <Option value={tz.name} key={tz.name}>
                      {/* TODO: i18n */}
                      {tz.name} ({tz.utcOffsetStr})
                    </Option>
                  ))}
                </OptGroup>

                <OptGroup label={t("other-timezone")}>
                  {otherTimezones.map((tz) => (
                    <Option value={tz.name} key={tz.name}>
                      {/* TODO: i18n */}
                      {tz.name} ({tz.utcOffsetStr})
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
          )}

          <a
            href="https://s3.amazonaws.com/static.assetscience.com/controlcenter/DHBulkSearchTemplate.csv"
            target="_blank"
            rel="noreferrer"
          >
            <i className="md-icon icon-download text-color--white margin-right--10" />
            <span className="text-color--white bold">{t("csv-file-template")}</span>
          </a>
        </Row>

        <Button
          icon
          className="icon-btn text-color--white"
          iconClassName="icon-info-circle"
          onClick={onInfoClicked}
          forceIconSize={32}
          forceIconFontSize
        />
      </SectionHeader>

      <div className="white-background filters-container">
        <BorderedGroup title={t("select-report")}>
          <DateTimeSelector
            dateFrom={reportingFilters.dateFrom}
            dateTo={reportingFilters.dateTo}
            datesCallBack={onSelectDateRange}
            includeRelativeDate
          />
        </BorderedGroup>

        {searchSupport && (
          <BorderedGroup title={t("search")}>
            <Input
              placeholder="Full text search"
              value={reportingFilters.keyword}
              onChange={(e) => {
                setReportingFilters({ ...reportingFilters, keyword: e.target.value });
              }}
              onPressEnter={onSearch}
              suffix={<SearchOutlined />}
              size="large"
            />
          </BorderedGroup>
        )}

        <BorderedGroup title={t("filters-title")}>
          <RecordingFacetsFilter
            facetOptions={facetOptions}
            facets={reportingFilters.facets}
            reportType={currentReportType}
            onFacetsChange={(facets) => {
              setReportingFilters({ ...reportingFilters, facets });
            }}
          />

          {!excludeUniqueness && (
            <Row className="uniqueness" align="start center">
              <div className="select-label">{t("uniqueness")}</div>

              <UniquenessFilter
                uniqueness={reportingFilters.uniqueness}
                onUniquenessChange={(uniqueness) => {
                  setReportingFilters({ ...reportingFilters, uniqueness });
                }}
              />
            </Row>
          )}
        </BorderedGroup>

        {csvSupport && (
          <BorderedGroup title={t("csv-upload")}>
            <Column align="start center" style={{ width: "100%" }}>
              <CsvUpload />
            </Column>
          </BorderedGroup>
        )}
      </div>
    </div>
  );
};
