import React, { Component } from "react";
import { Grid, Cell } from "react-md";
import { connect } from "react-redux";

import { AsciButton, Page, SectionHeader, Thumbnail, Column } from "~/global";
import { downloadVersion } from "~/actions/Documentation.actions";
import selectors from "~/selectors";

import "./DevelopersZone.component.scss";

class DevelopersZone extends Component {
  state = {
    dialog: null,
  };

  files = [
    {
      title: "PRO-D SDK",
      url: "http://documentation.assetscience.com/IOS/IOS.zip",
      name: "iOS SDK",
      category: "apple",
    },
    {
      title: "SAMPLE PROJECT",
      url: "http://documentation.assetscience.com/IOS/ios_sample.zip",
      name: "Sample iOS Project (Objective-C)",
      category: "apple",
    },
    {
      title: "PRO-D SDK",
      url: "http://documentation.assetscience.com/Android/Android.zip",
      name: "Android SDK",
      category: "android",
    },
    {
      title: "SAMPLE PROJECT",
      url: "http://documentation.assetscience.com/Android/Android_Sample.zip",
      name: "Sample Android Project (Java)",
      category: "android",
    },
    {
      title: "DOCUMENTATION",
      url: "http://documentation.assetscience.com/proddoc/ProD%2BWhitelabel%2BDeveloper%2BGuide.pdf",
      name: "PRO•D White-Label Integration Guide (PDF)",
      category: "pdf",
    },
  ];

  icons = {
    pdf: "icon-file-pdf-o",
    android: "icon-android",
    apple: "icon-apple",
  };

  componentDidMount() {
    if (!this.props.canSeeSDK) {
      this.setState({
        dialog: {
          visible: true,
          title: "Forbidden",
          width: "calc(50% - 100px)",
          content: (
            <div className="column centered">
              <p className="align-center">
                You do not have the permissions to access to this section
              </p>

              <div className="row centered">
                <AsciButton color="green" onClick={() => this.redirectTo("/home")}>
                  Go to Home
                </AsciButton>
              </div>
            </div>
          ),
        },
      });
    }
  }

  hideDialog = () => {
    this.setState({ dialog: null });
  };

  installClicked(doc) {
    downloadVersion(doc.url);
  }

  render() {
    return (
      <Column className="developers-zone-page full-width" flex align="start center">
        <Page className="developers-zone" dialog={this.state.dialog} onDialogHide={this.hideDialog}>
          <SectionHeader
            title="Developers Zone"
            description="Select the files you want to download."
          />
          {this.props.canSeeSDK ? (
            <div className="body-wrapper grey-border">
              <Grid className="full-width cc-download">
                {(this.files || []).map((file, index) => (
                  <Cell size={3} key={`developper-zone-file-${index}`}>
                    <div className="column centered selection-container">
                      <div>{file.title}</div>

                      <Thumbnail iconClass={this.icons[file.category]} text={file.name} />

                      {file.category == "pdf" ? (
                        <div className="row centered buttons-container">
                          <a href={file.url} target="_blank" rel="noreferrer">
                            <AsciButton color="green">Open</AsciButton>
                          </a>
                        </div>
                      ) : (
                        <div className="row centered buttons-container">
                          <a href={file.url}>
                            <AsciButton color="green">Download</AsciButton>
                          </a>
                        </div>
                      )}
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          ) : null}
        </Page>
      </Column>
    );
  }
}

const stateToProps = (state) => ({
  canSeeSDK: selectors.session.hasPermission(state, "GET", "/canSeeSDK"),
});

export default connect(stateToProps, null)(DevelopersZone);
