import { combineReducers } from "redux";
import concat from "lodash/concat";
import isEqual from "lodash/isEqual";

import { ServiceStarted, ServiceCompleted } from "~/workflow/workflowEvents";

import * as eventEditor from "./eventEditor.redux";
import * as workflowEditor from "./workflowEditor.redux";

const getWorkflow = (state) => ({
  events: concat(workflowEditor.selectors.getSelectedEvents(state), [
    ServiceStarted,
    ServiceCompleted,
  ]).reduce((acc, e) => {
    acc[e] = state.workflow.eventEditor[e];
    return acc;
  }, {}),
});

const didChange = (state) => {
  if (state.workflow.workflowEditor.original) {
    const workflow = getWorkflow(state);
    return !isEqual(state.workflow.workflowEditor.original, workflow);
  }
  return false;
};

export const selectors = {
  eventEditor: eventEditor.selectors,
  workflowEditor: workflowEditor.selectors,
  getWorkflow,
  didChange,
};

export const actions = {
  eventEditor: eventEditor.actions,
  workflowEditor: workflowEditor.actions,
};

export default combineReducers({
  eventEditor: eventEditor.reducer,
  workflowEditor: workflowEditor.reducer,
});
