import React from "react";
import type { FallbackRender } from "@sentry/react/dist/errorboundary";

import history from "~/global/history";

type Props = {
  errorData: Parameters<FallbackRender>[0];
};

const ErrorPage = ({ errorData }: Props) => (
  <div style={{ maxWidth: 1280, minHeight: "100vh", padding: "1em", margin: "0 auto" }}>
    <h2>Something went wrong.</h2>

    <div style={{ margin: "1em 0" }}>
      <button
        type="button"
        onClick={() => {
          errorData.resetError();
        }}
      >
        Reload the page
      </button>

      <span>&nbsp;or&nbsp;</span>

      <button
        type="button"
        onClick={() => {
          errorData.resetError();
          history.push("/");
        }}
      >
        Go to the home page
      </button>
    </div>

    <details>
      <pre>
        {errorData.error.toString()}
        {errorData.componentStack}
      </pre>
    </details>
  </div>
);

export default ErrorPage;
