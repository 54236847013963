import React, { useEffect, useMemo } from "react";
import { Grid, Cell } from "react-md";

import actions from "~/actions";
import selectors from "~/selectors";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";

const CustomerTeamDetails = () => {
  const { t } = useTranslation("customerTeamDetails");
  const auditsAllFacilities = useAppSelector(selectors.audits.getAuditsByFacilityStatus);
  const facilitiesById = useAppSelector(moduleSelectors.customerFacilities.getFacilitiesById);
  const teamDescription = useAppSelector(moduleSelectors.customerTeamEditor.getTeamDescription);
  const teamFaid = useAppSelector(moduleSelectors.customerTeamEditor.getTeamFaid);
  const teamName = useAppSelector(moduleSelectors.customerTeamEditor.getTeamName);
  const userIdMembers = useAppSelector(moduleSelectors.customerTeamEditor.getUserIdMembers);
  const users = useAppSelector(moduleSelectors.customerTeams.getFlatUsersTeams);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (users.length > 0) {
      dispatch(moduleActions.customerTeamEditor.initTeamMembers(users));
    }
  }, [users]);

  const usersInFacility = useMemo(() => {
    if (teamFaid === "" || users.length === 0) {
      return [];
    }

    return users.filter(
      (user) => user.facilities.some(({ faid }) => faid === teamFaid) || user.hasAllFacilitiesAccess
    );
  }, [users, teamFaid]);

  useEffect(() => {
    if (teamFaid !== "") {
      dispatch(actions.audits.fetchAudits(teamFaid));
    }
  }, [teamFaid]);

  const getUserLabel = (user) => {
    const status = auditsAllFacilities[`${teamFaid}.${user.status}`];
    const roleStatus = status != null ? `(${user.roleName} ${status.name})` : `(${user.roleName})`;
    const userName =
      user.firstname == "" || user.lastname == ""
        ? `${user.name}`
        : `${user.firstname} ${user.lastname}`;
    return `${userName} ${roleStatus}`;
  };

  return (
    <div>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("team-name")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <div>{teamName}</div>
        </Cell>
      </Grid>

      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("description")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <div>{teamDescription}</div>
        </Cell>
      </Grid>

      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("facility")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <div>{facilitiesById?.[teamFaid]?.name ?? t("not-available")}</div>
        </Cell>
      </Grid>

      <Grid className="full-width">
        <Cell size={12} className="row centered space-around">
          <div>{t("members")}</div>
        </Cell>
        <Cell size={12} className="row centered">
          {userIdMembers.length === 0 ? (
            "N/A"
          ) : (
            <ul>
              {usersInFacility
                .filter((user) => userIdMembers.includes(user.userid))
                .map((user) => (
                  <li key={user.userid} style={{ listStyleType: "disc" }}>
                    {getUserLabel(user)}
                  </li>
                ))}
            </ul>
          )}
        </Cell>
      </Grid>
    </div>
  );
};

export default CustomerTeamDetails;
