import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import selectors from "~/selectors";

import { getTestCategories } from "~/selectors/testCategories.selectors";
import {
  getSortedDefinitions,
  getArgumentsDetailsByName,
} from "~/selectors/testDefinitions.selectors";
import { getFailCodesById, getFailCodesCategoriesById } from "~/selectors/failCodes.selectors";
import { DeviceTestArgument } from "~/models";

export const getSelectedTestCategoryName = (state) =>
  state.modules.testDefinitionsEditor.selectedName;
export const getTestOriginal = (state) => state.modules.testDefinitionsEditor.original;
export const getTestModifications = (state) => state.modules.testDefinitionsEditor.modifications;
export const getErrors = (state) => state.modules.testDefinitionsEditor.errors;
export const getAreGalleryImagesLoading = (state) =>
  state.modules.testDefinitionsEditor.areGalleryImagesLoading;
export const getGalleryImages = (state) => state.modules.testDefinitionsEditor.galleryImages;
export const getLanguageCodes = (state) => selectors.languages.getLanguageCodes(state);

// utils
const getArgumentsByName = (args = []) =>
  args.reduce((acc, arg) => {
    acc[arg.name] = arg;
    return acc;
  }, {});

export const getSelectedTestCategory = createSelector(
  [getTestCategories, getSelectedTestCategoryName],
  (categories, categoryName) =>
    categories.filter((category) => category.name === categoryName)[0] || {}
);

export const getCurrentTestDefinition = createSelector(
  [getTestOriginal, getTestModifications],
  (original, modifications) => ({
    arguments: [],
    ...original,
    ...modifications,
  })
);

export const getSelectedFailCodes = createSelector(
  [getCurrentTestDefinition, getFailCodesById, getFailCodesCategoriesById],
  (definition = {}, failCodesById = {}, failCodesCategoriesById = {}) =>
    (definition.failCodes || []).map((id) => {
      const failCode = failCodesById[id] || {};
      const category = failCodesCategoriesById[failCode.categoryId] || {};
      return {
        ...failCode,
        categoryName: category.name,
        categoryPriority: category.priority,
        failFast: category.failFast ? true : failCode.failFast || false,
      };
    })
);

export const hasTestConfigModelsChanged = createSelector(
  [getTestOriginal, getTestModifications],
  (original, modifications) => {
    if (modifications.targetModels && !isEqual(modifications.targetModels, original.targetModels)) {
      return true;
    }

    return false;
  }
);

export const hasTestConfigInclusionChanged = createSelector(
  [getTestOriginal, getTestModifications, hasTestConfigModelsChanged],
  (original, modifications, modelsChanged) => {
    if (modelsChanged) {
      return true;
    }

    if (
      modifications.inclusionType &&
      modifications.inclusionType != original.inclusionType &&
      modifications.targetModels.length > 0
    ) {
      return true;
    }

    return false;
  }
);

export const hasTestConfigArgumentsChanged = createSelector(
  [getTestOriginal, getTestModifications],
  (original, modifications) => {
    if (modifications.arguments) {
      if (modifications.arguments.length !== original.arguments.length) {
        return true;
      }

      const argsValuesByName = (modifications.arguments || []).reduce((acc, arg) => {
        if (DeviceTestArgument.isTypeLocalizableString(arg)) {
          acc[arg.name] = arg.localization;
        } else {
          acc[arg.name] = arg.value;
        }

        return acc;
      }, {});

      // determine if any of the arguments value have changed from the original
      for (let i = 0; i < original.arguments.length; i++) {
        const arg = original.arguments[i];

        if (DeviceTestArgument.isTypeLocalizableString(arg)) {
          if (!isEqual(arg.localization, argsValuesByName[arg.name])) {
            return true;
          }
        } else if (arg.value != argsValuesByName[arg.name]) {
          return true;
        }
      }
    }
    return false;
  }
);

export const hasTestConfigFailCodesChanged = createSelector(
  [getTestOriginal, getTestModifications],
  (original, modifications) => {
    if (modifications.failCodes) {
      // different number of fail codes
      if (original.failCodes.length !== modifications.failCodes.length) {
        return true;
      }

      // different fail codes
      for (let i = 0; i < modifications.failCodes.length; i++) {
        const failCodeId = modifications.failCodes[i];
        if (!original.failCodes.includes(failCodeId)) {
          return true;
        }
      }
    }
    return false;
  }
);

export const hasTestConfigLocalesChanged = createSelector(
  [getTestOriginal, getTestModifications, getLanguageCodes],
  (original, modifications, languages) => {
    if (modifications.arguments) {
      // map original arguments by name
      const argsByName = getArgumentsByName(original.arguments);

      // find localizable string types in modified arguments
      for (let i = 0; i < modifications.arguments.length; i++) {
        const arg = modifications.arguments[i];

        if (DeviceTestArgument.isTypeLocalizableString(arg)) {
          // new arg was added
          if (!argsByName[arg.name]) {
            return true;
          }

          for (let l = 0; l < languages.length; l++) {
            const lang = languages[l];

            if (argsByName[arg.name].localization[lang] != arg.localization[lang]) {
              return true;
            }
          }
        }
      }

      if (modifications.arguments.length !== original.arguments.length) {
        return true;
      }
    }
    return false;
  }
);

export const hasCurrentTestConfigChanged = createSelector(
  [
    hasTestConfigArgumentsChanged,
    hasTestConfigFailCodesChanged,
    hasTestConfigLocalesChanged,
    hasTestConfigInclusionChanged,
  ],
  (argumentsChanged, failCodesChanged, localesChanged, inclusionChanged) => {
    if (argumentsChanged || failCodesChanged || localesChanged || inclusionChanged) {
      return true;
    }

    return false;
  }
);

export const getDefaultConfig = createSelector(
  [getSortedDefinitions],
  (definitions) =>
    definitions.filter((definition) => definition.className === definition.identifier)[0] || {}
);

export const getSelectedConfigArgumentsByName = createSelector(
  [getCurrentTestDefinition],
  (selectedConfig = {}) =>
    (selectedConfig.arguments || []).reduce((acc, arg) => {
      acc[arg.name] = arg;
      return acc;
    }, {})
);

export const getLocalizableArguments = createSelector(
  [getSelectedConfigArgumentsByName, getArgumentsDetailsByName],
  (selectedConfigArgsByName, argDetailsByName) => {
    const displayedArgs = [];

    for (const name in argDetailsByName) {
      const details = argDetailsByName[name];

      // ignore everything but localizableString
      if (DeviceTestArgument.isTypeLocalizableString(details)) {
        const item = {
          name: details.name,
          type: details.type,
          displayName: details.displayName || details.name,
          description: details.description,
          isRequired: details.required,
          isSelected: selectedConfigArgsByName[details.name] != null,
        };

        if (item.isSelected) {
          item.localization = selectedConfigArgsByName[item.name].localization || {};
        } else {
          item.localization = {
            en: "",
            fr: "",
            es: "",
          };
        }

        displayedArgs.push(item);
      }
    }

    return sortBy(displayedArgs, (i) => (i.displayName || "").toLowerCase());
  }
);
