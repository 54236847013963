import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import actions from "~/actions";

/*
interface PriorityGroup {
  name: string;
  pgid: string;
  isDefault: boolean;
  failCodeCategories: FailCodeCategory[];
}

interface FailCodeCategory {
  fccid: string;
  visible: boolean;
  failCodes: {
    fcid: string;
    visible: boolean;
  }[]
}
*/

const initialState = {
  // { [pgid]: PriorityGroup }
  items: {},
  // "idle" | "loading" | "succeeded" | "failed"
  status: "idle",
  error: null,

  originalPriorityGroup: null,
};

export default createReducer(initialState, {
  [actions.failCodePriorityGroups.fetchFailCodePriorityGroupsBegin]: (state) => {
    state.status = "loading";
    state.error = null;
  },
  [actions.failCodePriorityGroups.fetchFailCodePriorityGroupsSuccess]: (state, action) => {
    state.status = "succeeded";
    state.error = null;
    // store a map of PriorityGroups
    state.items = keyBy(action.payload, (priorityGroup) => priorityGroup.id);
  },
  [actions.failCodePriorityGroups.fetchFailCodePriorityGroupsError]: (state, action) => {
    state.status = "failed";
    state.error = action.payload;
  },
  [actions.failCodePriorityGroups.updateFailCodePriorityGroupsBegin]: (state, action) => {
    state.status = "loading";
    state.error = null;

    const newGroup = action.payload;
    // save the original to revert in case of errors
    state.originalPriorityGroup = state.items[newGroup.id];
    state.items[newGroup.id] = newGroup;
  },
  [actions.failCodePriorityGroups.updateFailCodePriorityGroupsSuccess]: (state) => {
    state.status = "succeeded";
    state.originalPriorityGroup = null;
  },
  [actions.failCodePriorityGroups.updateFailCodePriorityGroupsError]: (state, action) => {
    state.status = "failed";
    state.error = action.payload;

    // revert the change
    const { id } = state.originalPriorityGroup;
    state.items[id] = state.originalPriorityGroup;
    state.originalPriorityGroup = null;
  },
});
