import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Paper } from "react-md";
import { InputNumber } from "antd";
import actions from "~/actions";
import { Row, Column, SelectList } from "~/global";

import moduleSelectors from "~/data-import/selectors/";
import moduleActions from "~/data-import/actions/";

import "./TestInfo.component.scss";

const TestInfo = ({
  fetchFailCodeCategories,
  failCodes,
  failCategoryItems,
  savePass,
  saveFailCode,
  processResultFromState,
  clearFailState,
}) => {
  const [processResult, setProcessResult] = useState("");
  const [failCode, setFailCode] = useState("");
  const [failCategory, setFailCategory] = useState();
  const [failDescription, setFailDescription] = useState("");
  const [failCodeItems, setFailCodeItems] = useState(["Select..."]);
  const [resultStatus, setResultStatus] = useState(true);
  const [categoryChosen, setCategoryChosen] = useState(false);
  const [enableFailDescription, setEnableFailDescription] = useState(false);
  const [duration, setDuration] = useState(1);
  const processResultItems = ["Select...", "PASS", "FAIL"];

  const failCodeList = ["Select..."];

  useEffect(() => {
    fetchFailCodeCategories();
  }, []);

  useEffect(() => {
    if (processResultFromState === "") {
      setProcessResult(processResultItems[0]);
    }
  }, [processResultFromState]);

  useEffect(() => {
    if (processResult !== "FAIL") {
      setResultStatus(true);
      setFailCategory(failCategoryItems[0]);
      setFailCode(failCodeItems[0]);
    } else {
      setResultStatus(false);
    }
    if (processResult === "PASS") {
      savePass({ duration });
    }

    if (processResult === "Select...") {
      clearFailState();
    }

    if (processResult === "FAIL") {
      const failcode = {
        name: "",
        category: "",
        description: "",
        priority: 1,
      };

      saveFailCode({ failcode, duration });
    }
  }, [processResult, duration]);

  useEffect(() => {
    if (processResult === "FAIL") {
      failCodes.forEach((tempFailCode) => {
        if (tempFailCode.category === failCategory) {
          failCodeList.push(tempFailCode.name);
        }
        setFailCodeItems(failCodeList);
        setFailCode(failCodeList[0]);
        setFailDescription("");
      });
    }

    if (failCategory !== "Select...") {
      setCategoryChosen(false);
    } else {
      setCategoryChosen(true);
    }
  }, [failCategory]);

  useEffect(() => {
    if (failCode === "Select...") {
      setEnableFailDescription(true);
      setFailDescription("n/a");
    } else {
      setEnableFailDescription(false);
      getFailDescription(failCode);
    }
  }, [failCode]);

  useEffect(() => {
    const failcode = {
      name: failCode,
      category: failCategory,
      description: failDescription,
      priority: 1,
    };

    saveFailCode({ failcode, duration });
  }, [failDescription, duration]);

  const getFailDescription = (name) => {
    failCodes.forEach((tempFailCode) => {
      if (tempFailCode.name == name) {
        setFailDescription(tempFailCode.description);
      }
    });
  };

  return (
    <div className="test-info">
      <Row className="section-title">Test Info</Row>
      <Row>
        <Column className="test-column">
          <Row className="test-label">Result</Row>
          <Row>
            <SelectList
              items={processResultItems}
              onChange={(value) => setProcessResult(value)}
              selectedItem={processResult}
            />
          </Row>
        </Column>
        <Column className="test-column">
          <Row className="test-label">Fail Category</Row>
          <SelectList
            items={failCategoryItems}
            onChange={(value) => setFailCategory(value)}
            selectedItem={failCategory}
            disabled={resultStatus}
          />
        </Column>
        <Column className="test-column">
          <Row className="test-label">Fail Code</Row>
          <SelectList
            items={failCodeItems}
            onChange={(value) => setFailCode(value)}
            selectedItem={failCode}
            disabled={categoryChosen}
          />
        </Column>
        <Column className="test-column">
          <Row className="test-label">Fail Description</Row>
          <Paper zDepth={0}>
            <Row
              className={
                !enableFailDescription ? "fail-code-description" : "fail-code-description-disabled"
              }
            >
              {failDescription || "n/a"}
            </Row>
          </Paper>
        </Column>
        <Column className="test-column">
          <Row className="test-label">Duration</Row>
          <InputNumber
            formatter={(value) => `${value}s`}
            parser={(value) => value.replace("s", "")}
            min={0}
            size="large"
            value={duration}
            onChange={(value) => {
              setDuration(value);
            }}
            style={{ marginTop: 5, width: 170 }}
          />
        </Column>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  failCodes: moduleSelectors.importDataManual.getFlattenFailCodes(state),
  failCategoryItems: moduleSelectors.importDataManual.getCleanFailCategories(state),
  processResultFromState: state.modules.dataImport.importDataManual.processResult,
});

const dispatchToProps = {
  fetchFailCodeCategories: actions.failCodeCategories.fetchFailCodeCategories,
  savePass: moduleActions.importDataManual.savePass,
  saveFailCode: moduleActions.importDataManual.saveFailCode,
  clearFailState: moduleActions.importDataManual.clearFailState,
};

export default connect(stateToProps, dispatchToProps)(TestInfo);
