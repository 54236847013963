import React from "react";
import { ResponsiveContainer, LineChart, XAxis, Label } from "recharts";
import "./UphLineChartLabels.component.scss";

type Props = {
  width?: string;
  height?: string;
  data: {
    time: string;
    uph: number | null;
  }[];
  xAxisDataKey: string;
  timezone: string | undefined;
};

/** Known issue about a console error: https://assetscience.atlassian.net/browse/GLOB-3380 */
const UphLineChartLabels = ({
  width = "99%", // this enables Recharts to calculate the width properly when using flex or grid
  height = "99%", // same thing, but for the height
  data,
  xAxisDataKey,
  timezone,
}: Props) => (
  <ResponsiveContainer className="uph-line-chart-labels" width={width} height={height}>
    <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
      <XAxis
        dataKey={xAxisDataKey}
        padding={{ right: 30, left: 30 }}
        stroke="#999999"
        axisLine={false}
        height={64}
        tickSize={12}
        tickMargin={8}
      >
        <Label value={timezone} position="insideBottom" offset={10} fill="#6D6D6D" />
      </XAxis>
    </LineChart>
  </ResponsiveContainer>
);

export default UphLineChartLabels;
