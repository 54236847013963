import React, { useCallback, useEffect, useState } from "react";
import {
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Input, List, message, Modal, Row, Space, Table, Upload } from "antd";

import { exportSku, importSku, searchSku, skuSelectors } from "~/entities/sku/model/skuSlice";
import { AsciSpinner } from "~/global";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import {
  fetchSkuStatics,
  selectAdditionalAttributes,
  selectSkuAttributes,
} from "~/pages/sku-groups/model/skuStaticsSlice";
import { usePagination } from "~/shared/lib/usePagination";

import "./SkuManagerPage.scss";

export const SkuManagerPage = () => {
  const { t } = useTranslation("sku");
  const dispatch = useAppDispatch();

  const isStaticDataLoading = useAppSelector((state) => state.skuStatics.status === "loading");
  const skuAttributes = useAppSelector(selectSkuAttributes);
  const additionalAttributes = useAppSelector(selectAdditionalAttributes);

  const { numOfResults, listStatus, importStatus, exportStatus } = useAppSelector(
    (state) => state.sku
  );
  const allSku = useAppSelector(skuSelectors.selectAll);

  const [searchInput, setSearchInput] = useState<string>();
  const [keyword, setKeyword] = useState<string>();

  const { page, pageSize, changePagination } = usePagination(
    useCallback(
      ({ page, pageSize }) => {
        if (importStatus === "idle") {
          void dispatch(searchSku({ page, perPage: pageSize, keyword }));
        }
      },
      [dispatch, importStatus, keyword]
    )
  );

  useEffect(() => {
    void dispatch(fetchSkuStatics());
  }, [dispatch]);

  if (isStaticDataLoading) {
    return <AsciSpinner visible />;
  }

  return (
    <div className="panel-container sku-manager-page">
      <Row wrap={false} style={{ marginBottom: "1em" }}>
        <Col flex="auto">
          <Space>
            <Upload
              name="xlsx-file"
              accept=".xlsx"
              beforeUpload={(file) => {
                const xlsxFileExtension = /\.xlsx$/.test(file.name);
                const isXlsx =
                  xlsxFileExtension &&
                  file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                const isLt10M = file.size / 1024 / 1024 < 10;

                if (!isXlsx) {
                  void message.error(t("wrong-file-type", { file: file.name }));
                }

                if (!isLt10M) {
                  void message.error(t("file-too-big"));
                }

                return (isXlsx && isLt10M) || Upload.LIST_IGNORE;
              }}
              customRequest={({ file, onSuccess, onError }) => {
                const formData = new FormData();

                formData.append("file", file);

                dispatch(importSku(formData))
                  .unwrap()
                  .then((response) => {
                    onSuccess?.(response);

                    if (response.payload.warnings.length > 0) {
                      Modal.success({
                        title: response.message,
                        content: (
                          <List
                            bordered
                            header={<b>{t("warnings")}</b>}
                            dataSource={response.payload.warnings}
                            renderItem={(warning) => <List.Item>{warning}</List.Item>}
                          />
                        ),
                      });
                    } else {
                      void message.success(response.message);
                    }
                  })
                  .catch((err: unknown) => {
                    if (err instanceof Error) {
                      onError?.(err);
                    } else if (typeof err === "string") {
                      onError?.(new Error(err));
                      void message.error(err);
                    }
                  });
              }}
              showUploadList={false}
              maxCount={1}
            >
              <Button
                type="primary"
                icon={<ImportOutlined />}
                size="large"
                loading={importStatus === "loading"}
              >
                {t("import-sku")}
              </Button>
            </Upload>

            <Button
              icon={<ExportOutlined />}
              size="large"
              loading={exportStatus === "loading"}
              onClick={() => {
                dispatch(exportSku())
                  .unwrap()
                  .then((response) => message.success(response))
                  .catch((rejectedValue: string) => message.error(rejectedValue));
              }}
            >
              {t("export-sku")}
            </Button>
          </Space>
        </Col>

        <Col flex="none">
          <Button
            icon={<DownloadOutlined />}
            size="large"
            href="https://s3.amazonaws.com/static.assetscience.com/controlcenter/SKU_import_template_v3.xlsx"
          >
            {t("download-template")}
          </Button>
        </Col>
      </Row>

      <Input
        className="sku-search"
        placeholder={t("sku-search")}
        value={searchInput}
        onChange={(e) => {
          setSearchInput(e.target.value);
        }}
        onPressEnter={() => {
          setKeyword(searchInput);
        }}
        suffix={<SearchOutlined />}
        size="large"
      />

      <Card className="sku-list" title={t("sku-list")} bordered={false}>
        <Table
          dataSource={allSku}
          columns={[
            {
              title: t("group-name"),
              dataIndex: "groupName",
            },
            {
              title: t("device-type"),
              dataIndex: "deviceType",
              render: (_, { deviceType }) =>
                ({
                  phone: t("phone"),
                  tablet: t("tablet"),
                  watch: t("watch"),
                }[deviceType]),
            },
            {
              title: t("sku"),
              dataIndex: "sku",
            },
            {
              title: t("required-attributes"),
              dataIndex: "skuAttributes",
              children: skuAttributes?.map(({ value, label }) => ({
                title: label,
                dataIndex: value,
              })),
            },
            {
              title: t("additional-attributes"),
              dataIndex: "additionalAttributes",
              children: additionalAttributes?.map(({ value, label }) => ({
                title: label,
                dataIndex: value,
              })),
            },
          ]}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "25", "50"],
            current: page,
            pageSize,
            onChange: changePagination,
            total: numOfResults,
          }}
          loading={
            listStatus === "loading"
              ? {
                  indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                  size: "large",
                }
              : false
          }
        />
      </Card>
    </div>
  );
};
