import { createAction } from "@reduxjs/toolkit";

export const createNewLabel = createAction("labelDesigner/createNewLabel");
export const setLabelName = createAction("labelDesigner/setLabelName");
export const setLabelDescription = createAction("labelDesigner/setLabelDescription");
export const setLabelStyle = createAction("labelDesigner/setLabelStyle");
export const setPrinter = createAction("labelDesigner/setPrinter");

export const setFormat = createAction("labelDesigner/setFormat");
export const setFormatData = createAction("labelDesigner/setFormatData");

export const setLayoutName = createAction("labelDesigner/setLayoutName");
export const setLayoutData = createAction("labelDesigner/setLayoutData");

export const setShapeData = createAction("labelDesigner/setShapeData");

export const setShapeFieldType = createAction("labelDesigner/setShapeFieldType");
export const setShapeFieldData = createAction("labelDesigner/setShapeFieldData");

export const setShapeLayoutData = createAction("labelDesigner/setShapeLayoutData");

export const addShape = createAction("labelDesigner/addShape");
export const removeShape = createAction("labelDesigner/removeShape");

export const setRowHeight = createAction("labelDesigner/setRowHeight");

export const createGrid = (rows, columns) => async (dispatch) => {
  for (let row = 0; row < rows; row++) {
    for (let column = 0; column < columns; column++) {
      await dispatch(addShape({ layoutData: { row, column } }));
    }
  }
};

export const addRow = () => async (dispatch, getState) => {
  const state = getState();
  const { numColumns, numRows } = state.labelPrinter.labelDesigner.layoutData;
  const row = numRows;
  for (let column = 0; column < numColumns; column++) {
    await dispatch(addShape({ layoutData: { row, column } }));
  }
  await dispatch(setLayoutData({ key: "numRows", data: row + 1 }));
};

export const removeRow = () => async (dispatch, getState) => {
  const state = getState();
  const { numRows } = state.labelPrinter.labelDesigner.layoutData;
  const rows = numRows - 1;
  const removalIds = state.labelPrinter.labelDesigner.shapes.filter(
    (shape) => shape.layoutData.row >= rows
  );
  for (let i = 0; i < removalIds.length; i++) {
    await dispatch(removeShape({ identity: removalIds[i].identity }));
  }
  await dispatch(setLayoutData({ key: "numRows", data: rows }));
};

export const changeNumRows = (numRows) => async (dispatch, getState) => {
  const state = getState();
  const { numRows: oldRows } = state.labelPrinter.labelDesigner.layoutData;
  if (numRows > oldRows) {
    for (let row = 0; row < numRows - oldRows; row++) {
      await dispatch(addRow());
    }
  } else {
    for (let i = 0; i < oldRows - numRows; i++) {
      await dispatch(removeRow());
    }
  }
};

export const addColumn = (rowIndex) => async (dispatch, getState) => {
  const state = getState();
  const { numColumns, numRows } = state.labelPrinter.labelDesigner.layoutData;
  const column = numColumns;

  if (!rowIndex) {
    // Add column to all rows.
    for (let row = 0; row < numRows; row++) {
      await dispatch(addShape({ layoutData: { row, column } }));
    }
  } else {
    await dispatch(addShape({ layoutData: { row: rowIndex, column } }));
  }

  await dispatch(setLayoutData({ key: "numColumns", data: column + 1 }));
};

export const removeColumn = (rowIndex) => async (dispatch, getState) => {
  let removalIds;
  const state = getState();
  const { numColumns } = state.labelPrinter.labelDesigner.layoutData;
  const column = numColumns - 1;

  if (!rowIndex) {
    // Remove column from all rows
    removalIds = state.labelPrinter.labelDesigner.shapes.filter(
      (shape) => shape.layoutData.column >= column
    );
  } else {
    removalIds = state.labelPrinter.labelDesigner.shapes.filter(
      (shape) => shape.layoutData.row !== rowIndex || shape.layoutData.column < column
    );
  }

  for (let i = 0; i < removalIds.length; i++) {
    await dispatch(removeShape({ identity: removalIds[i].identity }));
  }

  await dispatch(setLayoutData({ key: "numColumns", data: column }));
};

export const changeNumColumns = (numColumns) => async (dispatch, getState) => {
  const state = getState();
  const { numColumns: oldColumns } = state.labelPrinter.labelDesigner.layoutData;

  if (numColumns > oldColumns) {
    await dispatch(addColumn());
  } else {
    await dispatch(removeColumn());
  }
};
