import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/programs-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,
  exportPending: false,
  exportError: null,
  errors: null,
  identifierErrors: null,
  displayNameErrors: {
    en: false,
    fr: false,
    es: false,
  },
  currentView: "",
};

export default createReducer(initialState, {
  [moduleActions.programEditor.initProgramBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.programEditor.initProgramSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.program;
    state.currentView = "program-editor";
  },
  [moduleActions.programEditor.initProgramError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.programEditor.updateProgramSuccess]: (state, action) => {
    state.modifications = { ...state.modifications, ...action.payload.program };
    state.currentView = "program-editor";
    state.displayNameErrors = { ...state.displayNameErrors, ...action.payload.error };
  },
  [moduleActions.programEditor.resetProgramEdition]: (state, action) => {
    state.modifications = {};
    state.original = {};
    state.currentView = "";
    state.isLoading = false;
    state.errors = null;
    state.identifierErrors = null;
    state.displayNameErrors = initialState.displayNameErrors;
  },
  [moduleActions.programEditor.fetchProgramBegin]: (state) => {
    state.isLoading = true;
  },
  [moduleActions.programEditor.fetchProgramSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.program;
    state.currentView = "program-editor";
  },
  [moduleActions.programEditor.fetchProgramError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.serviceSuiteEditor.setServiceSuiteEdition]: (state, action) => {
    state.currentView = "service-suite-editor";
  },
  [moduleActions.serviceSuiteEditor.resetServiceSuiteEdition]: (state, action) => {
    state.currentView = "program-editor";
  },
  [moduleActions.programEditor.createProgramBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.programEditor.createProgramSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.program;
    state.currentView = "program-editor";
    state.modifications = {};
  },
  [moduleActions.programEditor.createProgramError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },

  [moduleActions.programEditor.saveProgramBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.programEditor.saveProgramSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.program;
    state.modifications = {};
    state.currentView = "program-editor";
    state.displayNameErrors = initialState.displayNameErrors;
  },
  [moduleActions.programEditor.saveProgramError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.programEditor.validateProgramIdentifierBegin]: (state, action) => {
    state.identifierErrors = null;
  },
  [moduleActions.programEditor.validateProgramIdentifierSuccess]: (state, action) => {
    state.identifierErrors = null;
  },
  [moduleActions.programEditor.validateProgramIdentifierError]: (state, action) => {
    console.log(action.payload.errors);
    state.identifierErrors = action.payload.errors;
  },
  [moduleActions.programEditor.exportProgramBegin]: (state) => {
    state.exportPending = true;
    state.exportError = null;
  },
  [moduleActions.programEditor.exportProgramSuccess]: (state) => {
    state.exportPending = false;
    state.exportError = null;
  },
  [moduleActions.programEditor.exportProgramError]: (state, { payload: error }) => {
    state.isLoading = false;
    state.exportError = error;
  },
});
