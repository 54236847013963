import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/programs-management/actions";

const initialState = {
  items: [],
  isLoading: false,
  errors: null,
};

export default createReducer(initialState, {
  [moduleActions.systemFields.fetchSystemFieldsBegin]: (state, action) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.systemFields.fetchSystemFieldsSuccess]: (state, action) => {
    state.items = action.payload.systemFields;
    state.isLoading = false;
  },
  [moduleActions.systemFields.fetchSystemFieldsError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
