import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const fetchLabelStaticsBegin = createAction("labelStatics/fetchLabelStaticsBegin");
export const fetchLabelStaticsSuccess = createAction("labelStatics/fetchLabelStaticsSuccess");
export const fetchLabelStaticsError = createAction("labelStatics/fetchLabelStaticsError");

export const fetchLabelStatics = () => (dispatch) => {
  dispatch(fetchLabelStaticsBegin());

  const query = CustomerManagement.gqlBuilder(`{
        label_statics {
            printers {
                name,
                formats
            },
            formats {
                name,
                size {
                    width,
                    height
                }
                inches,
                generic
            }
            recordingFields {
                name,
                path,
                preview
            }
        }
    }`);

  return CustomerManagement.QUERY(query)
    .then((response) => {
      dispatch(fetchLabelStaticsSuccess(response.payload.data.label_statics));
    })
    .catch((error) => {
      console.error(error);
      dispatch(fetchLabelStaticsError(error));
    });
};
