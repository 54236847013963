import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "../UserAvatar/UserAvatar.component";

import "./SessionInfo.component.scss";

const SessionInfo = ({ initials, nickname }) => (
  <div className="session-info layout-row layout-row--start-center">
    <UserAvatar initials={initials} />

    <div className="nickname">{nickname}</div>
  </div>
);

SessionInfo.propTypes = {
  initials: PropTypes.string,
  nickname: PropTypes.string,
};

export default SessionInfo;
