import { combineReducers } from "@reduxjs/toolkit";
import teamPerformance from "./teamPerformance.slice";
import teams from "./teams.slice";
import devicesProcessed from "./devicesProcessed.slice";

export default combineReducers({
  devicesProcessed,
  teamPerformance,
  teams,
});
