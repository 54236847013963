import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TestDefinitionIcon, Checkbox, SelectList } from "~/global";
import { RemoteDiagnosticsSession } from "~/models";

import IconTestResultPass from "~/media/test-results/icon-pass.png";
import IconTestResultFail from "~/media/test-results/icon-fail.png";
import IconTestResultSkipped from "~/media/test-results/icon-skipped.png";
import IconTestResultOther from "~/media/test-results/icon-other.png";

import "./TestClassRow.component.scss";

const ResultStatus = ({ status }) => (
  <div className="status">
    {status === RemoteDiagnosticsSession.RESULT_PASS ? (
      <img src={IconTestResultPass} title={status} />
    ) : status === RemoteDiagnosticsSession.RESULT_FAIL ? (
      <img src={IconTestResultFail} title={status} />
    ) : status === RemoteDiagnosticsSession.RESULT_SKIPPED ? (
      <img src={IconTestResultSkipped} title={status} />
    ) : (
      <img src={IconTestResultOther} title={status} />
    )}
  </div>
);

const DefinitionIcon = ({ image }) => (
  <div className="icon">
    <TestDefinitionIcon src={image} />
  </div>
);

const DefinitionIdentifier = ({
  displayName,
  className,
  testDefinitions,
  selectedDefinition,
  onSelectedIdentifierChanged,
  canChangeIdentifier,
  fixedWidth,
}) => (
  <div className={classnames("test", { "fixed-width": fixedWidth })}>
    <div className="name padding--5">{displayName || className}</div>

    {testDefinitions && testDefinitions.length > 1 ? (
      <div className="definitions" onClick={(e) => e.stopPropagation()}>
        <SelectList
          id="identifier-list"
          items={testDefinitions}
          selectedItem={selectedDefinition}
          nameBuilder={(item) =>
            item.identifier === item.className ? "Default" : item.identifierDisplayName
          }
          onChange={onSelectedIdentifierChanged}
          disabled={!canChangeIdentifier}
        />
      </div>
    ) : null}
  </div>
);

DefinitionIdentifier.propTypes = {
  displayName: PropTypes.string,
  className: PropTypes.string,
  testDefinitions: PropTypes.arrayOf(PropTypes.object),
  selectedDefinition: PropTypes.object,
  onSelectedIdentifierChanged: PropTypes.func,
  canChangeIdentifier: PropTypes.bool,
  fixedWidth: PropTypes.bool,
};

const DefinitionDescription = ({ description }) => <div className="description">{description}</div>;

DefinitionDescription.propTypes = {
  description: PropTypes.string,
};

const DefinitionToggle = ({ className, isChecked, onChecked }) => (
  <div className="toggle" onClick={(e) => e.stopPropagation()}>
    <Checkbox id={`checkbox-test-${className}`} isChecked={isChecked} onChange={onChecked} />
  </div>
);

DefinitionToggle.propTypes = {
  className: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChecked: PropTypes.func.isRequired,
};

DefinitionToggle.defaultProps = {
  isChecked: false,
};

const TestClassRow = ({ children, isSelected, onToggled }) => (
  <div
    className={classnames("test-class-row", {
      selected: isSelected,
      clickable: !!onToggled,
    })}
    onClick={() => onToggled && onToggled(!isSelected)}
  >
    {children}
  </div>
);

TestClassRow.Icon = DefinitionIcon;
TestClassRow.Identifier = DefinitionIdentifier;
TestClassRow.Description = DefinitionDescription;
TestClassRow.Toggle = DefinitionToggle;
TestClassRow.ResultStatus = ResultStatus;

TestClassRow.propTypes = {
  isSelected: PropTypes.bool,
  onToggled: PropTypes.func,
};

export default TestClassRow;
