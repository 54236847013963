import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import { Row, Column, AsciButton } from "~/global";
import CannedReportFilters from "~/canned-reports/components/CannedReportFilters/CannedReportFilters.component";
import BubbleChart from "~/canned-reports/components/BubbleChart/BubbleChart.component";
import ReportSummary from "~/canned-reports/components/ReportSummary/ReportSummary.component";
import moduleActions from "~/canned-reports/actions/";
import moduleSelectors from "~/canned-reports/selectors/";

import "./AuditResultsPage.component.scss";

const AuditResultsPage = ({
  fetchAuditResultsForFacility,
  fetchAuditResultsForTeam,
  formattedAuditResults,
  auditResultsColumns,
  auditResultsTitles,
  formattedChartData,
}) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentLevel, setCurrentLevel] = useState("Facilities");
  const ref = useRef();
  const itemIds = Object.keys(formattedAuditResults.auditResultsByItemId);

  const drillDown = (itemId, facilityId, type) => {
    let filters;

    if (type === "facility") {
      filters = { ...currentFilters, facilityId: itemId };
      fetchAuditResultsForFacility(
        filters.facilityId,
        filters.programId,
        filters.dateFrom,
        filters.dateTo
      );

      setCurrentLevel("Teams");
    } else if (type === "team") {
      filters = { ...currentFilters, teamId: itemId };
      fetchAuditResultsForTeam(
        filters.facilityId,
        filters.teamId,
        filters.programId,
        filters.dateFrom,
        filters.dateTo
      );

      setCurrentLevel("Operators");
    }

    setCurrentFilters(filters);
  };

  return (
    <Column className="audit-results-page full-width">
      <div className="audit-results-filters">
        <CannedReportFilters
          currentFilters={currentFilters}
          setCurrentFilters={(filters) => setCurrentFilters(filters)}
          currentCannedReport="auditResults"
          filtersToDisplay="timeframe facility team program"
        />
      </div>

      <div className="report-results-wrapper">
        <Row className="report-results">
          <div className="report-statistics">
            <Row className="result-item">
              Results:&nbsp;<b>{itemIds.length}</b>&nbsp;{currentLevel}
            </Row>

            <Row className="result-item">
              <b>{formattedAuditResults.totalAudits}</b>&nbsp;Total Audits Performed
            </Row>

            <Row className="result-item">
              <b>{formattedAuditResults.failedAuditsPercentMedian}%</b>&nbsp;Median Audit Failure
              Rate
            </Row>
          </div>

          <ReactToPrint
            content={() => ref.current}
            pageStyle={`
              @page { size: auto; height: 100%; margin: 1cm; }
              @media print {
                .page-break {
                  page-break-after: avoid;
                }
                body { -webkit-print-color-adjust: exact; page-break-after: avoid; }}
              .recharts-wrapper > .recharts-surface { width: 1120px; height: 600px; }
              .recharts-legend-wrapper > .recharts-default-legend { width: 1120px; height: 600px; }
            `}
            trigger={() => (
              <AsciButton color="white">
                <div className="row centered">
                  <i className="icon-printer" />
                  <span className="margin-left--5">Export</span>
                </div>
              </AsciButton>
            )}
          />
        </Row>
      </div>
      <div ref={ref}>
        <BubbleChart
          title="Audit Results"
          data={formattedAuditResults.auditResultsByItemId}
          xAxis={{ dataKey: "audits", name: "Total Audits Performed" }}
          yAxis={{ dataKey: "uphPercentMedian", name: "Median UPH Percentile" }}
          zAxis={{ dataKey: "failedAuditsPercent", name: "Failed Audits", unit: "%" }}
          drillDown={(itemId, facilityId, type) => drillDown(itemId, facilityId, type)}
        />
        <div className="page-break">
          <ReportSummary
            data={formattedChartData}
            columns={auditResultsColumns}
            titles={auditResultsTitles}
          />
        </div>
      </div>
    </Column>
  );
};

const stateToProps = (state) => ({
  formattedAuditResults: moduleSelectors.auditResults.getFormattedAuditResults(state),
  auditResultsColumns: moduleSelectors.auditResults.getAuditResultsColumns(state),
  auditResultsTitles: moduleSelectors.auditResults.getAuditResultsTitles(state),
  formattedChartData: moduleSelectors.auditResults.getFormattedChartData(state),
});

const dispatchToProps = {
  fetchAuditResultsForFacility: moduleActions.auditResults.fetchAuditResultsForFacility,
  fetchAuditResultsForTeam: moduleActions.auditResults.fetchAuditResultsForTeam,
};

export default connect(stateToProps, dispatchToProps)(AuditResultsPage);
