import { createAction, createReducer } from "@reduxjs/toolkit";
import concat from "lodash/concat";
import find from "lodash/find";
import isFunction from "lodash/isFunction";

/**
 * Create actions, selectors & reducer for a fetch.
 */
export default function (moduleName, reducerName, fetcher, filters = []) {
  const initialState = {
    results: null,
    fetching: false,
    fetched: false,
    error: null,
    filters,
  };

  // Actions
  const fetchStarted = createAction(`${reducerName}/fetchStarted`);
  const fetchSuccess = createAction(`${reducerName}/fetchSuccess`);
  const fetchError = createAction(`${reducerName}/fetchError`);
  const fetchReset = createAction(`${reducerName}/fetchReset`);
  const addFilter = createAction(`${reducerName}/addFilter`);
  const removeFilter = createAction(`${reducerName}/removeFilter`);
  const setFilterValue = createAction(`${reducerName}/setFilterValue`);

  const getOwnState = (state) => state[moduleName][reducerName];

  // Selectors
  const isFetching = (state) => getOwnState(state).fetching;
  const isFetched = (state) => getOwnState(state).fetched;
  const getResults = (state) => getOwnState(state).results;
  const getError = (state) => getOwnState(state).error;
  const getFilters = (state) => getOwnState(state).filters;
  const getFilter = (identifier) => (state) => find(getOwnState(state).filters, { identifier });
  const getFilterValue = (identifier) => (state) => (getFilter(identifier)(state) || {}).value;
  const getFiltersByName = (state) =>
    getOwnState(state).filters.reduce((acc, f) => {
      acc[f.identifier] = f;
      return acc;
    }, {});

  const getCsid = (state) => {
    const csid = state.customer.activeCustomerId;
    return csid;
  };

  const getFilteredResults = (state) => {
    const ownState = getOwnState(state);
    if (!ownState.results) {
      return null;
    }

    // Apply only the filters which have a function associated.
    // Other filters assume filtering on the backend via fetch.
    const feFilters = ownState.filters.filter((f) => f.filterFunc);
    return feFilters.reduce(
      (acc, fil) => acc.filter((item) => fil.filterFunc(item, fil.value)),
      ownState.results
    );
  };

  /**
   * Get a filter options either calculated or from static data.
   */
  const getFilterOptions = (identifier) => (state) => {
    const ownState = getOwnState(state);

    if (!ownState.results) {
      return [];
    }
    const filter = find(ownState.filters, { identifier });
    return isFunction(filter.options) ? filter.options(ownState.results) : filter.options;
  };

  /**
   * Fetch actions on the store.
   */
  const fetch =
    (...args) =>
    async (dispatch, getState) => {
      const state = getState();
      if (getOwnState(state).fetching) {
        return;
      }
      await dispatch(fetchStarted());

      const filters = getFiltersByName(state);

      return fetcher(...args, { filters })
        .then((results) => dispatch(fetchSuccess(results)))
        .catch((err) => {
          console.error(err);
          dispatch(fetchError(err.json ? err.json.message : err));
        });
    };

  // noinspection JSValidateTypes
  return {
    reducer: createReducer(initialState, {
      [fetchStarted]: (state) => {
        state.fetching = true;
        state.fetched = false;
        state.results = null;
        state.error = null;
      },
      [fetchSuccess]: (state, action) => {
        state.results = action.payload;
        state.fetching = false;
        state.fetched = true;
      },
      [fetchError]: (state, action) => {
        state.error = action.payload;
        state.fetched = true;
        state.fetching = false;
      },
      // Reset on add/remove Filter
      [addFilter]: (state, action) => {
        state.filters = concat(state.filters, [action.payload]);
        state.fetched = false;
      },
      [removeFilter]: (state, action) => {
        state.filter = state.filters.filter((f) => f.identifier !== action.payload);
        state.fetched = false;
      },
      [setFilterValue]: (state, action) => {
        const { identifier, value } = action.payload;
        let isFrontEnd = false;
        state.filters = state.filters.map((f) => {
          if (f.identifier === identifier) {
            isFrontEnd = !!f.filterFunc; // Knock knock
            return { ...f, value };
          }
          return f;
        });
        if (!isFrontEnd) {
          state.fetched = false;
        }
      },
      [fetchReset]: (state) => ({ ...initialState, filters: state.filters }),
      CHANGE_ACTIVE_CUSTOMER_BEGIN: (state) => {
        state.fetched = false;
        state.fetching = true;
      },
      CHANGE_ACTIVE_CUSTOMER_SUCCESS: (state) => ({ ...initialState, filters: state.filters }),
    }),
    actions: {
      fetchStarted,
      fetchError,
      fetchSuccess,
      fetchReset,
      addFilter,
      removeFilter,
      setFilterValue,
      fetch,
    },
    selectors: {
      isFetching,
      isFetched,
      getResults,
      getCsid,
      getError,
      getFilters,
      getFilterValue,
      getFilteredResults,
      getFilterOptions,
      getFilter,
    },
  };
}
