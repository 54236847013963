import React, { useMemo } from "react";
import { FontIcon } from "react-md";
import {
  EXECUTE,
  GATHER_LOGS,
  LOGOUT,
  SCREENSHOT,
  SEND_MESSAGE,
  UPDATE_WORKSTATION,
} from "~/workstation/taskTypes";

const TaskType = (props) => {
  const { type, ...rest } = props;
  const [iconClassName, label] = useMemo(() => {
    switch (type) {
      case SCREENSHOT:
        return ["icon-image", "Take screenshot"];
      case GATHER_LOGS:
        return ["icon-file-text-o", "Gather logs"];
      case LOGOUT:
        return ["icon-sign-out", "Logout user"];
      case SEND_MESSAGE:
        return ["icon-mail4", "Send message"];
      case UPDATE_WORKSTATION:
        return ["icon-download", "Update workstation"];
      case EXECUTE:
        return ["icon-terminal", "Execute command"];
      default:
        return "";
    }
  }, [type]);

  return (
    <div>
      <FontIcon iconClassName={iconClassName} {...rest} />
      &nbsp;{label}
    </div>
  );
};

TaskType.defaultProps = {};

TaskType.propTypes = {};

export default TaskType;
