import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DataTable, TableHeader, TableRow, TableColumn, TableBody } from "react-md";

import DeploymentCustomFieldRow from "./DeploymentCustomFieldRow.component";

const DeploymentCustomFields = ({ customFields, activeCustomFields, onChange }) => {
  const onFieldChange = (field, isActive) => {
    const isActiveCustomField = activeCustomFields.includes(field.name);
    const newActiveCustomFields = isActiveCustomField
      ? activeCustomFields.filter((activeCustomField) => activeCustomField != field.name)
      : (activeCustomFields || []).concat(field.name);
    onChange(newActiveCustomFields);
  };
  const isChecked = (fieldName) => activeCustomFields.includes(fieldName);
  return (
    <DataTable className="cc-table" plain>
      <TableHeader>
        <TableRow>
          <TableColumn>Name</TableColumn>
          <TableColumn>Description</TableColumn>
          <TableColumn>Display at</TableColumn>
          <TableColumn>Include</TableColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        <>
          {customFields.length === 0 ? (
            <TableRow>
              <TableColumn colSpan="4" className="text-centered">
                No custom fields
              </TableColumn>
            </TableRow>
          ) : null}

          {customFields.map((field, index) => (
            <DeploymentCustomFieldRow
              key={index}
              customField={field}
              checked={isChecked(field.name)}
              onChange={(value) => onFieldChange(field, value)}
            />
          ))}
        </>
      </TableBody>
    </DataTable>
  );
};

DeploymentCustomFields.propTypes = {
  customFields: PropTypes.array,
  activeCustomFields: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

DeploymentCustomFields.defaultProps = {
  customFields: [],
  activeCustomFields: [],
};

export default DeploymentCustomFields;
