import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchProcessesByOSBegin = createAction("canned-reports/FETCH_PROCESSES_BY_OS_BEGIN");
export const fetchProcessesByOSSuccess = createAction("canned-reports/FETCH_PROCESSES_BY_OS_SUCCESS"); // prettier-ignore
export const fetchProcessesByOSError = createAction("canned-reports/FETCH_PROCESSES_BY_OS_ERROR");

export const fetchProcessesByOS =
  (facilityId, teamId, programId, configId, platformId, from, to) => async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`{
        processesByOS(
            facility: "${facilityId === "all" ? "" : facilityId}",
            team: "${teamId === "all" ? "" : teamId}",
            program: "${programId === "all" ? "" : programId}",
            servicesuite: "${configId === "all" ? "" : configId}",
            os: "${platformId === "all" ? "" : platformId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            Facility {
                name,
                faid
            },
            ChartData {
                EntityID,
                RawData {
                    Group,
                    Results {
                        Result,
                        Num
                    }
                }
            }
        }
    }`);

    dispatch(fetchProcessesByOSBegin());

    try {
      const response = await CustomerManagement.QUERY(query);
      const processesByOS = (((response || {}).payload || {}).data || {}).processesByOS || [];

      dispatch(fetchProcessesByOSSuccess({ processesByOS }));
    } catch (e) {
      if (e instanceof Error) throw e;

      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      dispatch(fetchProcessesByOSError({ errors: message }));
    }
  };
