import { createSelector } from "@reduxjs/toolkit";

import { isEmpty } from "lodash/lang";
import isEqual from "lodash/isEqual";

import moduleSelectors from ".";

const getCustomerIdentityOriginal = (state) =>
  state.modules.customerManagement.customerIdentity.original;
const getCustomerIdentityModified = (state) =>
  state.modules.customerManagement.customerIdentity.modifications;
const getCanSaveCustomerIdentity = (state) =>
  moduleSelectors.customerIdentity.getCanSaveCustomerIdentity(state);
const getIsLoadingIdentity = (state) => state.modules.customerManagement.customerIdentity.isLoading;
const getIsLoadingFacilities = (state) =>
  state.modules.customerManagement.customerFacilities.isLoading;
const getIsLoadingUsers = (state) => state.modules.customerManagement.customerUsers.isLoading;
const getIsLoadingTeams = (state) => state.modules.customerManagement.customerTeams.isLoading;
const getIsLoadingAudits = (state) => state.audits.isLoading;

export const saveDisabled = createSelector(
  [getCanSaveCustomerIdentity],
  (customerIdentityModifiedValid) => !customerIdentityModifiedValid
);

// add all sections
export const getCustomerDidChange = createSelector(
  [getCustomerIdentityOriginal, getCustomerIdentityModified],
  (original, modified) => {
    const unmodified = isEqual(modified, original) || isEmpty(modified);
    return !unmodified;
  }
);

/// show tab with error on dialogs
export const getCustomerTabWithError = createSelector(
  [getCustomerDidChange, getCanSaveCustomerIdentity],
  (customerDidChange, customerIdentityModifiedValid) => {
    const tabWithErrors = [];
    customerIdentityModifiedValid && customerDidChange
      ? null
      : tabWithErrors.push("Identification");
    const errorMessage = tabWithErrors.join(", ");
    return errorMessage;
  }
);

export const getIsLoading = createSelector(
  [
    getIsLoadingIdentity,
    getIsLoadingFacilities,
    getIsLoadingUsers,
    getIsLoadingTeams,
    getIsLoadingAudits,
  ],
  (
    isLoadingEditor,
    isLoadingIdentity,
    isLoadingFacilities,
    isLoadingUsers,
    isLoadingTeams,
    isLoadingAudits
  ) =>
    isLoadingEditor ||
    isLoadingIdentity ||
    isLoadingFacilities ||
    isLoadingUsers ||
    isLoadingTeams ||
    isLoadingAudits
);
