import React, { useCallback, useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LabelStatics from "~/label-printer/components/LabelStatics";

import labelSelectors from "../selectors";
import labelActions from "../actions";
import LabelPrinter from "~/label-printer/components/LabelPrinter";
import AsciButton from "~/global/AsciButtons/AsciButton/AsciButton.component";

const LabelPrinterButton = (props) => {
  const { recording } = props;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [chosenLabel, setChosenLabel] = useState(null);
  const [print, setPrint] = useState(false);
  const labels = useSelector(labelSelectors.labelLister.getLabels);
  const labelsFetched = useSelector(labelSelectors.labelLister.isLoaded);

  const toggleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen, setModalOpen]);
  const onPrint = useCallback(() => {
    toggleModal();
    setPrint(true);
  }, [setPrint, chosenLabel, recording]);

  const setPrinted = useCallback(() => {
    setPrint(false);
  }, []);

  useEffect(() => {
    if (!labelsFetched) {
      dispatch(labelActions.labelLister.fetchLabels());
    }
  }, [dispatch]);

  return (
    <LabelStatics>
      <AsciButton color="white" onClick={toggleModal}>
        <div className="row centered" style={{ padding: "0 1rem" }}>
          <i className="icon-printer" />
          <span className="margin-left--5">Print Label</span>
        </div>
      </AsciButton>
      <Modal
        open={modalOpen}
        onOk={onPrint}
        onCancel={toggleModal}
        title="Print label"
        okText="Print"
        okButtonProps={{ disabled: !chosenLabel }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <b>Label</b>
          {labelsFetched && (
            <Select value={chosenLabel} onChange={setChosenLabel} style={{ width: "60%" }}>
              {labels.map((label) => (
                <Select.Option key={label.id} value={label.id}>
                  {label.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Modal>
      {print && (
        <LabelPrinter labelId={chosenLabel} recording={recording} setPrinted={setPrinted} />
      )}
    </LabelStatics>
  );
};

export default LabelPrinterButton;
