import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

import moment from "moment";
import { subWeeks } from "date-fns";
import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "sessions",
  (workstationId, { filters }) =>
    WorkstationManagement.QUERY(
      WorkstationManagement.gqlBuilder(`{
            sessions(
                workstationId: "${workstationId}",
                dateRangeStart: "${filters.begin.value.start}",
                dateRangeStop: "${filters.begin.value.end}"
            ) {
                id,
                status,
                beginAt,
                endAt,
                userId,
                operator,
                workstationId
            }
        }`)
    ).then((response) =>
      reverse(sortBy(response.payload.data.sessions, [(s) => moment(s.beginAt)]))
    ),
  [
    {
      identifier: "status",
      label: "Status",
      value: null,
      options: ["online", "offline"],
      filterFunc: (session, value) => (value ? session.status === value : true),
    },
    {
      identifier: "user",
      label: "User",
      value: null,
      options: (results) => uniq(results.map((session) => session.operator.name)),
      filterFunc: (session, value) => (value ? session.operator.name === value : true),
    },
    {
      identifier: "begin",
      label: "Begin",
      value: {
        start: subWeeks(new Date(), 1).toISOString(),
        end: new Date().toISOString(),
      },
    },
  ]
);
