import React from "react";
import PropTypes from "prop-types";

import { Row } from "~/global";

import "./InfoBanner.component.scss";

const InfoBanner = ({ text }) => (
  <Row className="cc-info-banner" align="center center">
    <i className="icon-info-circle" />
    <div>{text}</div>
  </Row>
);

InfoBanner.propTypes = {
  text: PropTypes.string.isRequired,
};

InfoBanner.defaultProps = {};

export default InfoBanner;
