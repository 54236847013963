import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AsciSpinner, AsciButton, SectionHeader, DialogConfirmation, Column } from "~/global";
import DeploymentList from "../DeploymentList/DeploymentList.component";
import selectors from "~/selectors";
import moduleActions from "~/deployment-management/actions/";
import actions from "~/actions";

import "./DeploymentsPage.component.scss";

const DeploymentsPage = () => {
  const canEditDeployment = useSelector((state) =>
    selectors.session.hasPermission(state, "PUT", "/facilities/:faid/deployments/:dpid")
  );
  const canArchiveDeployment = useSelector((state) =>
    selectors.session.hasPermission(state, "PUT", "/deployment/:faid/:dpid/archive")
  );
  const canAddDeployment = useSelector((state) =>
    selectors.session.hasPermission(state, "POST", "/facilities/:faid/deployments")
  );
  const facilities = useSelector((state) => state.modules.deploymentManagement.deployments.items);
  const isLoading = useSelector(
    (state) => state.modules.deploymentManagement.deployments.isLoading
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(moduleActions.deployments.fetchDeploymentsByFacility());
  }, []);

  const onAddDeployment = (facility) => {
    const currentDeployment = {
      customFields: [],
      dpid: "",
      name: "",
      programs: [],
      shifts: [
        {
          end: {
            hours: 23,
            minutes: 45,
          },
          id: "",
          name: "",
          start: {
            hours: 0,
            minutes: 0,
          },
        },
      ],
    };

    dispatch(moduleActions.deployment.setDeployment(currentDeployment));
    history.push(`/deployment/wizard/${facility.faid}`);
  };

  const onEditDeployment = (facility, deployment) => {
    const sbid = facility.sbid || "";
    const { faid } = facility;
    const { dpid } = deployment;

    let dest;
    if (sbid.length > 0) {
      // edit deployment in subscriber
      dest = `/deployment/editor/${sbid}/${faid}/${dpid}`;
    } else {
      // edit deployment
      dest = `/deployment/editor/${faid}/${dpid}`;
    }

    history.push(dest);
  };

  const onArchiveDeployment = (facility, deployment) => {
    dispatch(
      actions.dialog.show({
        title: `Archive Deployment`,
        width: "450px",
        modal: true,

        content: (close) => (
          <DialogConfirmation
            onCancel={() => {
              close();
            }}
            onConfirm={() => {
              close();
              dispatch(moduleActions.deployments.archiveDeployment(facility.faid, deployment.dpid));
            }}
            textButtonConfirm="Archive"
          >
            <p className="align-center">
              Are you sure you want to archive the deployment "{deployment.name}"?
            </p>
            <p className="align-center">
              After archival, the deployment will not be visible or usable. Unarchiving a deployment
              will require support from Asset Science.
            </p>
            <p className="align-center">
              Confirm the removal or <b>Cancel</b>.
            </p>
          </DialogConfirmation>
        ),
      })
    );
  };

  if (isLoading) {
    return <AsciSpinner visible />;
  }

  return (
    <Column className="deployments-page full-width" flex align="start center">
      {(facilities || []).map((facility, index) => (
        <div className="panel-container" key={facility.faid}>
          <div className="deployment">
            <SectionHeader title={`${facility.name} FACILITY`}>
              <AsciButton color="green" onClick={() => onAddDeployment(facility)}>
                New Deployment
              </AsciButton>
            </SectionHeader>

            <div className="layout-row layout-row--space-between">
              <div className="data-list data-list--border-horizontal layout-column flex--50">
                <div className="data-list__item layout-row">
                  <div className="data-list__item-name flex--50">Country</div>
                  <div className="data-list__item-value flex--50">{facility.country}</div>
                </div>

                <div className="data-list__item layout-row">
                  <div className="data-list__item-name flex--50">State</div>
                  <div className="data-list__item-value flex--50">{facility.state}</div>
                </div>
              </div>

              <div className="data-list data-list--border-right layout-column flex--50">
                <div className="data-list__item layout-row">
                  <div className="data-list__item-name flex--50">Postal Code</div>
                  <div className="data-list__item-value flex--50">{facility.postalcode}</div>
                </div>

                <div className="data-list__item layout-row">
                  <div className="data-list__item-name flex--50">Time Zone</div>
                  <div className="data-list__item-value flex--50">{facility.timezone}</div>
                </div>
              </div>
            </div>

            <DeploymentList
              deployments={facility.deployments}
              canEdit={canEditDeployment}
              canArchive={canArchiveDeployment}
              canDeploy={canAddDeployment}
              onEdit={(deployment) => onEditDeployment(facility, deployment)}
              onArchive={(deployment) => onArchiveDeployment(facility, deployment)}
            />
          </div>
        </div>
      ))}
    </Column>
  );
};

export default DeploymentsPage;
