import { createAction } from "@reduxjs/toolkit";

import uuid from "uuid/v4";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const fetchCustomFieldsBegin = createAction("FETCH_CUSTOM_FIELDS_BEGIN");
export const fetchCustomFieldsSuccess = createAction("FETCH_CUSTOM_FIELDS_SUCCESS");
export const fetchCustomFieldsError = createAction("FETCH_CUSTOM_FIELDS_ERROR");

export const fetchCustomFieldsNameBegin = createAction("FETCH_CUSTOM_FIELDS_NAME_BEGIN");
export const fetchCustomFieldsNameSuccess = createAction("FETCH_CUSTOM_FIELDS_NAME_SUCCESS");
export const fetchCustomFieldsNameError = createAction("FETCH_CUSTOM_FIELDS_NAME_ERROR");

export const deleteCustomFieldBegin = createAction("DELETE_CUSTOM_FIELD_BEGIN");
export const deleteCustomFieldSuccess = createAction("DELETE_CUSTOM_FIELD_SUCCESS");
export const deleteCustomFieldError = createAction("DELETE_CUSTOM_FIELD_ERROR");

export const onCustomFieldAdded = createAction("ON_CUSTOM_FIELD_ADDED");

export const fetchCustomFields = () => async (dispatch, getState) => {
  try {
    dispatch(fetchCustomFieldsBegin());

    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    const query = CustomerManagement.gqlBuilder(`{
      custom_fields {
        name,
        type,
        required,
        availableOnDesktop,
        availableInReports,
        supportedByAllDeviceTypes,
        supportedDeviceTypes,
        displayAt,
        displayInDeviceHistoryTable,
        displayOnSlot,
        hideFromOperator,
        description {${displayNameLanguages}},
        displayName {${displayNameLanguages}},
        dateAdded,
        dateUpdated,
        defaultStringValue,
        defaultNumberValue,
        min,
        max,
        maxLength,
        items { displayName {${displayNameLanguages}}, value }
      }
    }`);

    const response = await CustomerManagement.QUERY(query);
    const customFields = ((response.payload || {}).data || {}).custom_fields || [];

    dispatch(fetchCustomFieldsSuccess({ customFields }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchCustomFieldsError({ errors: e }));
  }
};

export const fetchCustomFieldsName = () => async (dispatch, getState) => {
  try {
    dispatch(fetchCustomFieldsNameBegin());

    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    const query = CustomerManagement.gqlBuilder(`{
            custom_fields {
                name,
                displayName {${displayNameLanguages}},
                displayInDeviceHistoryTable,
            }
        }`);

    const response = await CustomerManagement.QUERY(query);
    const customFields = ((response.payload || {}).data || {}).custom_fields || [];
    dispatch(fetchCustomFieldsNameSuccess({ customFields }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchCustomFieldsNameError({ errors: e }));
  }
};

export const deleteCustomField = (name) => async (dispatch) => {
  try {
    dispatch(deleteCustomFieldBegin());
    await CustomerManagement.DELETE(`/custom-fields/${name}`);
    dispatch(deleteCustomFieldSuccess({}));
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(deleteCustomFieldError({ errors: `${message} ${e.json.payload}` }));
    throw { errors: `${message} ${e.json.payload}` };
  }
};

export const addCustomField = (customField) => onCustomFieldAdded({ customField });

export const createListItem =
  (data = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const languages = selectors.languages.getLanguagesEmptyValue(state);
    return {
      // including an uuid because the list items don't have an unique identifier and using the list's index cause problem in some situation
      _id: data._id || uuid(),
      displayName: {
        languages,
        ...data.displayName,
      },
      value: data.value || "",
    };
  };
