import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TableRow, TableColumn, Switch, FontIcon } from "react-md";

const DeploymentCustomFieldRow = ({ customField, checked, onChange }) => {
  const [displayNameEN, setDisplayNameEN] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [displayAt, setDisplayAt] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    (customField.displayName || {}).en != null
      ? setDisplayNameEN(customField.displayName.en)
      : null;
  }, [customField.displayName]);

  useEffect(() => {
    (customField.description || {}).en != null
      ? setDescriptionEn(customField.description.en)
      : null;
  }, [customField.description]);

  useEffect(() => {
    (customField.displayAt || {}).en != null ? setDisplayAt(customField.displayAt.en) : null;
  }, [customField.displayAt]);

  useEffect(() => {
    customField.name != null ? setName(customField.name) : null;
  }, [customField.name]);

  return (
    <TableRow>
      <TableColumn>{displayNameEN}</TableColumn>

      <TableColumn>{descriptionEn}</TableColumn>

      <TableColumn>{displayAt}</TableColumn>

      <TableColumn>
        <Switch
          id={`switch-enable-custom-filed-${name}`}
          name={`switch-enable-custom-filed-${name}`}
          aria-label="Toggle Custom Field"
          onChange={onChange}
          checkedCheckboxIcon={<FontIcon iconClassName="icon-check-square" />}
          uncheckedCheckboxIcon={<FontIcon iconClassName="icon-square-o" />}
          checked={checked}
        />
      </TableColumn>
    </TableRow>
  );
};

DeploymentCustomFieldRow.propTypes = {
  customField: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DeploymentCustomFieldRow.defaultProps = {
  checked: false,
};

export default DeploymentCustomFieldRow;
