import React from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";

import { selectors as workflowSelectors } from "~/workflow/redux";

const ConditionMenu = (props) => {
  const { onClick, eventId } = props;
  const allowedConditions = useSelector(
    workflowSelectors.eventEditor[eventId].getAllowedConditions
  );

  return (
    <Menu onClick={onClick}>
      {allowedConditions.map((c) => (
        <Menu.Item key={c}>{c}</Menu.Item>
      ))}
    </Menu>
  );
};

export default ConditionMenu;
