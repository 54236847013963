import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initState = {
  original: {},
  modifications: {},
  errors: null,
  nameError: false,
  isLoading: false,
};

export default createReducer(initState, {
  [moduleActions.customerIdentity.fetchCustomerIdentityBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.customerIdentity.fetchCustomerIdentitySuccess]: (state, action) => {
    state.original = action.payload.identity;
    state.isLoading = false;
    state.errors = null;
  },
  [moduleActions.customerIdentity.fetchCustomerIdentityError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
  },

  [moduleActions.customerIdentity.updateCustomerIdentity]: (state, action) => {
    state.modifications = action.payload.identity;
  },
  [moduleActions.customerIdentity.onCustomerNameValidation]: (state, action) => {
    state.nameError = action.payload.errors;
  },

  [moduleActions.customerEditor.resetCustomerEdition]: (state, action) => {
    state.original = {};
    state.modifications = {};
    state.nameError = false;
    state.errors = null;
  },
  [moduleActions.customerIdentity.saveIdentityBegin]: (state, action) => {
    state.nameError = false;
    state.errors = null;
    state.isLoading = true;
  },
  [moduleActions.customerIdentity.saveIdentitySuccess]: (state, action) => {
    state.original = state.modifications;
    state.modifications = {};
    state.isLoading = false;
  },
  [moduleActions.customerIdentity.saveIdentityError]: (state, action) => {
    state.modifications = {};
    state.nameError = false;
    state.errors = action.payload.message;
    state.isLoading = false;
  },

  [moduleActions.customerIdentity.updateCsidBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },
  [moduleActions.customerIdentity.updateCsidSuccess]: (state, action) => {
    state.isLoading = false;
  },
  [moduleActions.customerIdentity.updateCsidError]: (state, action) => {
    state.errors = action.payload.message;
    state.isLoading = false;
  },
});
