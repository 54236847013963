import React from "react";
import { Radio } from "antd";
import { useTranslation } from "~/i18n";

export type Uniqueness = "none" | "last-in" | "first-in";

type Props = {
  uniqueness: Uniqueness;
  onUniquenessChange: (uniqueness: Uniqueness) => void;
};

const UniquenessFilter = ({ uniqueness, onUniquenessChange }: Props) => {
  const { t } = useTranslation("uniquenessFilter");

  return (
    <Radio.Group
      value={uniqueness}
      onChange={(e) => onUniquenessChange(e.target.value)}
      buttonStyle="solid"
    >
      <Radio value="none">
        {t("none")} &ndash; {t("all-non-unique-identifier")}
      </Radio>
      <Radio value="last-in">
        {t("last-in")} &ndash; {t("last-unique-identifier")}
      </Radio>
      <Radio value="first-in">
        {t("first-in")} &ndash; {t("first-unique-identifier")}
      </Radio>
    </Radio.Group>
  );
};

export default UniquenessFilter;
