import React from "react";
import { connect } from "react-redux";

import {
  DataTable,
  TableBody,
  TableHeader,
  TableColumn,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import { Row, Column } from "~/global";

import moduleActions from "../../actions";

import "./CustomFields.component.scss";

const CustomFields = (props) => {
  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  const customFieldKeys = Object.keys(props.customFields);

  return (
    <div
      className={
        props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
      }
    >
      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={<div className="panel-text title bold-screen printable-title">Custom Fields</div>}
          secondaryLabel={
            <div className="panel-text no-printable">
              {props.includeToPrint ? "(added to report)" : "(not added to report)"}
            </div>
          }
          footer={null}
          onExpandToggle={() => includeForPrint()}
          expanded={props.includeToPrint}
          expanderIcon={<i className="no-printable icon-chevron-down" />}
          headerClassName="cc-section-header table-header"
          contentClassName="cc-fullwidth-panel"
        >
          <Row className="custom-fields full-width">
            <Column className="full-width">
              <DataTable className="custom-fields-table" baseId="custom-fields-table" plain>
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn className="header-label">Fields</TableColumn>
                    <TableColumn className="header-label">Value</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  {customFieldKeys.map((key, index) => {
                    const customFieldNameValid =
                      props.customFieldNames[key] != null &&
                      props.customFieldNames[key].displayName != null &&
                      props.customFieldNames[key].displayName.en != null;
                    const customFieldName = customFieldNameValid
                      ? props.customFieldNames[key].displayName.en
                      : key;
                    return (
                      <TableRow key={key}>
                        <TableColumn className="table-data">{customFieldName}:</TableColumn>
                        <TableColumn className="table-data">{props.customFields[key]}</TableColumn>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </div>
  );
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeCustomFieldsToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeCustomFieldsToPrint,
};

export default connect(stateToProps, dispatchToProps)(CustomFields);
