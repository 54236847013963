import { createSelector } from "@reduxjs/toolkit";

const getRawUserActivityData = (state) => state.modules.userLogs.userActivityData.items;

export const getFormattedModules = createSelector([getRawUserActivityData], (userActivityData) => {
  const formattedModules = userActivityData.map((item) => {
    let module = item.basicLogInfo.path;

    if (module.charAt(0) == "/") {
      module = module.substr(1);

      for (const char of module) {
        if (char == "-") {
          module = module.replace("-", " ");
        }
      }

      module = module.split(" ");

      for (let i = 0; i < module.length; i++) {
        const char = module[i].charAt(0).toUpperCase();

        module[i] = char + module[i].substr(1);
      }

      module = module.join(" ");
    } else if (module.charAt(module.length) == "/") {
      module = module.slice(module.length);
    }

    module = module.charAt(0).toUpperCase() + module.slice(1);

    return module;
  });

  return formattedModules;
});

export const getFormattedActions = createSelector([getRawUserActivityData], (userActivityData) => {
  const formattedActions = userActivityData.map((item) => {
    let action = item.basicLogInfo.method;

    switch (action) {
      case "GET":
        action = "Accessed Data";
        break;
      case "POST":
        action = "Created Data Entry";
        break;
      case "PUT":
        action = "Modified Data Entry";
        break;
      case "DELETE":
        action = "Removed Data Entry";
        break;
    }

    return action;
  });

  return formattedActions;
});

export const getUserActivityData = createSelector(
  [getRawUserActivityData, getFormattedModules, getFormattedActions],
  (userActivityData, formattedModules, formattedActions) => {
    const logData = userActivityData.map((item, index) => {
      const user = item.basicLogInfo.name;

      const eventTime = item.basicLogInfo.start;

      const module = formattedModules[index];

      const action = formattedActions[index];

      return {
        user,
        eventTime,
        action,
        module,
      };
    });

    return logData;
  }
);
