import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "~/global";

const ReduxerControlled = (props) => {
  const { actions, selectors, fetchArguments, loadingComponent, children } = props;
  const dispatch = useDispatch();

  const isFetched = useSelector(selectors.isFetched);
  const isFetching = useSelector(selectors.isFetching);
  const error = useSelector(selectors.getError);

  // Auto fetch the results as needed (mount, reset)
  useEffect(() => {
    if (!isFetched && !isFetching) {
      dispatch(actions.fetch(...fetchArguments));
    }
  }, [dispatch, isFetched, isFetching, fetchArguments]);

  // Reset the reduxer state when this component unmount.
  useEffect(() => () => dispatch(actions.fetchReset()), [dispatch]);

  if (error) {
    return <div>ERROR: {error}</div>;
  }

  if (!isFetched || isFetching) {
    return loadingComponent;
  }

  return children;
};

ReduxerControlled.defaultProps = {
  fetchArguments: [],
  loadingComponent: <Spinner />,
};

ReduxerControlled.propTypes = {
  actions: PropTypes.object.isRequired,
  selectors: PropTypes.object.isRequired,
  fetchArguments: PropTypes.array,
  loadingComponent: PropTypes.node,
};

export default ReduxerControlled;
