import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import capitalize from "lodash/capitalize";

export const toPt = (value) => `${value}pt`;
export const toMM = (value) => `${value}mm`;

export const getBorder = (border) => {
  let { side } = border;
  if (side === "all") {
    side = "";
  }
  if (side === null) {
    return {};
  }

  return mapKeys(
    omit({ ...border, width: toPt(border.width) }, "borderSide"),
    (v, k) => `border${capitalize(side)}${capitalize(k)}`
  );
};

export const getAlignment = (alignment) => {
  const { horizontal, vertical, orientation, direction } = alignment;
  const style = {};
  if (horizontal || vertical) {
    style.display = "flex";
  }
  if (horizontal) {
    style.justifyContent = horizontal;
  }
  if (vertical) {
    style.alignItems = vertical;
  }
  if (orientation) {
    style.writingMode = orientation;
  }
  if (direction) {
    style.direction = direction;
  }

  return style;
};

export const getFont = (font) => ({
  ...font,
  fontSize: font.fontSize ? toPt(font.fontSize) : undefined,
});

export const getSize = ({ width, height }) => ({
  width: toMM(width),
  height: toMM(height),
});
