import * as programEditor from "./programEditor.actions";
import * as serviceSuiteEditor from "./serviceSuiteEditor.actions";
import * as systemFields from "./systemFields.actions";
import * as systemPermissions from "./systemPermissions.actions";
import * as serviceSuiteTemplates from "./serviceSuiteTemplates.actions";

export default {
  programEditor,
  serviceSuiteEditor,
  systemFields,
  systemPermissions,
  serviceSuiteTemplates,
};
