import { createSelector } from "@reduxjs/toolkit";
import { orderBy } from "lodash/collection";
import moment from "moment";

const getTestProcessingTimes = (state) => state.modules.cannedReports.testProcessingTimes.items;

export const getFormattedProcesses = createSelector(
  [getTestProcessingTimes],
  (testProcessingTimes) => {
    const chartData = testProcessingTimes.ChartData || [];
    const sortedChartData = orderBy(chartData, ["Mean"], ["desc"]);
    const medianMean = testProcessingTimes.MedianMean;
    const executions = chartData.reduce((acc, item) => {
      acc += item.Count;

      return acc;
    }, 0);
    const formattedProcesses = { chartData: sortedChartData, medianMean, executions };

    return formattedProcesses;
  }
);

export const getFormattedChartData = createSelector(
  [getFormattedProcesses],
  (formattedProcesses) => {
    const formattedChartData = formattedProcesses.chartData.map((item) => {
      const max = (item.Maximum / 1000).toFixed(1);
      const mean = (item.Mean / 1000).toFixed(1);
      const min = (item.Minimum / 1000).toFixed(1);

      return { ...item, Maximum: max, Mean: mean, Minimum: min };
    });

    console.log(formattedChartData);
    return formattedChartData;
  }
);
