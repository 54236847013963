import React, { Component } from "react";
import PropTypes from "prop-types";

import CertificateContainer from "./CertificateContainer.component";
import CertificateDescription from "./CertificateDescription.component";
import CertificateDataWipeBody from "./CertificateDataWipeBody.component";
import CertificateFooter from "./CertificateFooter.component";
import { SectionHeader } from "~/global";
import { formatDate } from "~/global/utils";
import DataWipeLogo from "~/media/printable/certificates/datawipe-seal@2x.png";

export default class CertificateDataWipe extends Component {
  static propTypes = {
    process: PropTypes.object.isRequired,
    deviceInfo: PropTypes.object.isRequired,
    desktopInformation: PropTypes.object.isRequired,
    processResult: PropTypes.string.isRequired,
  };

  render() {
    return (
      <CertificateContainer>
        <SectionHeader className="certificate-header" title="Data Wipe Certificate" />

        <CertificateDescription logo={DataWipeLogo}>
          <h2>Asset Science Data Wipe Certificate.</h2>
          <p>
            Our content clearing solution first identifies the type of encryption to ensure data is
            removed via the most efficient and effective method available. On devices that feature
            full-disk encryption, our software destroys the encryption key, rendering data
            inaccessible. On unencrypted devices, our software overwrite technology is certified
            effective by Kroll Ontrack and ADISA.
          </p>
          <p>
            Our content clearing process is compliant with the U.S. National Institute of Standards
            And Technology. Special Publication 800-88, Revision 1: Guidelines for media Sanitation
            (NIST-800).
          </p>
          <p className="bolder-text">Session ID: {this.props.process.sessionIDHumanReadable}</p>
          <p className="bolder-text">Date: {formatDate(this.props.process.endDateTime)}</p>
        </CertificateDescription>

        <CertificateDataWipeBody
          process={this.props.process}
          deviceInfo={this.props.deviceInfo}
          desktopInformation={this.props.desktopInformation}
          processResult={this.props.processResult}
        />

        <CertificateFooter />
      </CertificateContainer>
    );
  }
}
