import React from "react";
import PropTypes from "prop-types";

const TextPreview = (props) => {
  const { value, textAlign, fontSize, fontWeight, fontFamily } = props;
  return <div style={{ textAlign, fontSize, fontWeight, fontFamily }}>{value}</div>;
};

TextPreview.defaultProps = {};

TextPreview.propTypes = {
  value: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
};

export default TextPreview;
