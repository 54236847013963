import React, { useRef, useMemo } from "react";
import QRCode from "qrcode.react";
import find from "lodash/find";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import labelSelectors from "~/label-printer/selectors";

const QrCodePreview = (props) => {
  const { path, recordingFields, fitSize, size, ...rest } = props;
  const qrRef = useRef(null);
  const fieldInfo = find(recordingFields, { path });
  const { preview } = fieldInfo;
  const recordingValue = useSelector(labelSelectors.labelPrint.getRecordingValue(path));

  const qrSize = useMemo(() => {
    if (fitSize && qrRef.current) {
      const { width, height } = qrRef.current.parentElement.getBoundingClientRect();
      return width < height ? width : height;
    }
    return size;
  }, [fitSize, size]);

  return (
    <div ref={qrRef}>
      <QRCode value={recordingValue || preview} size={qrSize} {...rest} renderAs="svg" />
    </div>
  );
};

QrCodePreview.defaultProps = {};

QrCodePreview.propTypes = {
  path: PropTypes.string,

  fitSize: PropTypes.bool,
  size: PropTypes.number,

  recordingFields: PropTypes.array,
};

export default connect(
  (state) => ({ recordingFields: labelSelectors.labelFields.getAvailableFields(state) }),
  null
)(QrCodePreview);
