import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextField, Grid, Cell } from "react-md";
import { SelectList } from "~/global";
import { useTranslation } from "~/i18n";

import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors/index";

const CustomerFacilityEditor = (props) => {
  useEffect(() => {
    if (props.countryStates.indexOf(props.facilityState) < 0) {
      const selectedState = props.countryStates[0] || "";
      props.onChangeState(selectedState);
    }
    props.setTimeZones();
  }, [props.countryStates]);

  const { t } = useTranslation("customerFacilityEditor");
  const onChangeName = async (name) => {
    props.onChangeName(name);
    props.validateFacilityName(name);
  };

  const onChangeAddress = async (address) => {
    props.onChangeAddress(address);
    props.validateFacilityAddress(address);
  };

  const onChangeCity = async (city) => {
    props.onChangeCity(city);
    props.validateFacilityCity(city);
  };

  return (
    <div>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("facility-name")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="facility-name-text-field"
            lineDirection="left"
            placeholder={t("enter-facility-name")}
            value={props.facilityName}
            className="table-text-field"
            onChange={(name) => onChangeName(name)}
            error={props.facilityNameError}
            errorText={t("error-text-a-valid-facility-name")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveFacility && e.key == "Enter"
                ? props.onSave()
                : null
            }
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("country")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          {props.countries.length > 1 ? (
            <SelectList
              items={props.countries}
              selectedItem={props.facilityCountry}
              onChange={(country) => props.onChangeCountry(country)}
              underlined
            />
          ) : (
            <div>{t("failed-to-fetch")}</div>
          )}
        </Cell>
      </Grid>
      {props.countryStates.length > 1 ? (
        <Grid className="full-width">
          <Cell size={4} tabletSize={3} className="row centered to-end">
            <div className="align-right layout-row layout-row--end-center">
              {t("administrative-division")}
            </div>
          </Cell>
          <Cell size={6} tabletSize={3}>
            <SelectList
              items={props.countryStates}
              selectedItem={props.facilityState}
              onChange={(country) => props.onChangeState(country)}
              underlined
            />
          </Cell>
        </Grid>
      ) : null}
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">
            {t("city")}
            <sup>*</sup>
          </div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="facility-city-text-field"
            lineDirection="left"
            placeholder={t("enter-city")}
            value={props.facilityCity}
            className="table-text-field min-margin"
            onChange={(city) => onChangeCity(city)}
            error={props.cityError}
            errorText={t("error-valid-city-name")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveFacility && e.key == "Enter"
                ? props.onSave()
                : null
            }
          />
        </Cell>
      </Grid>

      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("address")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="facility-address-text-field"
            lineDirection="left"
            placeholder={t("enter-address")}
            value={props.facilityAddress}
            className="table-text-field"
            onChange={(address) => onChangeAddress(address)}
            error={props.addressError}
            errorText={t("error-valid-facility-name")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveFacility && e.key == "Enter"
                ? props.onSave()
                : null
            }
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("postal-code")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          <TextField
            id="facility-postal-code-text-field"
            lineDirection="left"
            placeholder={t("enter-postal-code")}
            value={props.facilityPostalCode}
            className="table-text-field min-margin"
            onChange={(postalCode) => props.onChangePostalCode(postalCode)}
            error={props.postalCodeError}
            errorText={t("error-valid-city-name")}
            onKeyPress={(e) =>
              !props.newCustomer && props.canSaveFacility && e.key == "Enter"
                ? props.onSave()
                : null
            }
          />
        </Cell>
      </Grid>
      <Grid className="full-width">
        <Cell size={4} tabletSize={3} className="row centered to-end">
          <div className="align-right layout-row layout-row--end-center">{t("time-zone")}</div>
        </Cell>
        <Cell size={6} tabletSize={3}>
          {props.timezones.length > 1 ? (
            <SelectList
              items={props.timezones}
              selectedItem={props.facilityTimezone}
              onChange={(timezone) => props.onChangeTimezone(timezone)}
              underlined
            />
          ) : (
            <div>{props.facilityTimezone}</div>
          )}
        </Cell>
      </Grid>
    </div>
  );
};

CustomerFacilityEditor.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  newCustomer: PropTypes.bool,
};

CustomerFacilityEditor.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  newCustomer: false,
};

const stateToProps = (state) => ({
  countries: selectors.countries.getCountryNames(state),
  countriesByName: selectors.countries.getCountriesByName(state),
  facilityName: moduleSelectors.customerFacilityEditor.getFacilityName(state),
  facilityCountry: moduleSelectors.customerFacilityEditor.getFacilityCountry(state),
  facilityAddress: moduleSelectors.customerFacilityEditor.getFacilityAddress(state),
  facilityCity: moduleSelectors.customerFacilityEditor.getFacilityCity(state),
  facilityState: moduleSelectors.customerFacilityEditor.getFacilityState(state),
  facilityPostalCode: moduleSelectors.customerFacilityEditor.getFacilityPostalCode(state),
  facilityTimezone: moduleSelectors.customerFacilityEditor.getFacilityTimezone(state),
  timezones: state.modules.customerManagement.customerFacilityEditor.timezones,

  canSaveFacility: moduleSelectors.customerFacilityEditor.getCanSaveCustomerFacility(state),
  countryStates: moduleSelectors.customerFacilityEditor.getCountryStates(state),
  facilityNameError: state.modules.customerManagement.customerFacilityEditor.nameError,
  addressError: state.modules.customerManagement.customerFacilityEditor.addressError,
  cityError: state.modules.customerManagement.customerFacilityEditor.cityError,
});

const dispatchToProps = {
  onChangeName: moduleActions.customerFacilityEditor.onChangeName,
  validateFacilityName: moduleActions.customerFacilityEditor.validateFacilityName,
  validateFacilityAddress: moduleActions.customerFacilityEditor.validateFacilityAddress,
  validateFacilityCity: moduleActions.customerFacilityEditor.validateFacilityCity,
  validateFacilityPostalCode: moduleActions.customerFacilityEditor.validateFacilityPostalCode,
  onChangeCountry: moduleActions.customerFacilityEditor.onChangeCountry,
  onChangeAddress: moduleActions.customerFacilityEditor.onChangeAddress,
  onChangeCity: moduleActions.customerFacilityEditor.onChangeCity,
  onChangeState: moduleActions.customerFacilityEditor.onChangeState,
  onChangePostalCode: moduleActions.customerFacilityEditor.onChangePostalCode,
  onChangeTimezone: moduleActions.customerFacilityEditor.onChangeTimezone,
  setTimeZones: moduleActions.customerFacilityEditor.setTimeZones,
};

export default connect(stateToProps, dispatchToProps)(CustomerFacilityEditor);
