import { createAction } from "@reduxjs/toolkit";
// import CountriesAndTimezones from "countries-and-timezones";
import { CustomerManagement } from "~/services";

// export const fetchCountriesTimezones = createAction("FETCH_COUNTRIES_SUCCESS");
export const fetchCountriesBegin = createAction("FETCH_COUNTRIES_BEGIN");
export const fetchCountriesSuccess = createAction("FETCH_COUNTRIES_SUCCESS");
export const fetchCountriesError = createAction("FETCH_COUNTRIES_ERROR");

// export const fetchCountries = () => async dispatch => {
//     const countries = CountriesAndTimezones.getAllCountries();
//     dispatch(fetchCountriesTimezones({ countries}));
// };

export const fetchCountries = () => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        countries {
            name,
            code,
            states {
                name
            }
        }
    }`);

  const response = await CustomerManagement.QUERY(query);
  dispatch(fetchCountriesBegin());
  try {
    // const countries = ((response.payload || {}).data || {}).my_customer || {};
    const { countries } = response.payload.data;
    dispatch(fetchCountriesSuccess({ countries }));
  } catch (e) {
    dispatch(fetchCountriesError());
  }
};
