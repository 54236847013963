import { createSelector } from "@reduxjs/toolkit";

const getProcesses = (state) => state.modules.cannedReports.processes.items;

export const getFormattedChartData = createSelector([getProcesses], (processes) => {
  const formattedChartData = processes.map((item) => {
    const chartData = {
      productivity: item.ChartData.PerOpProductivity,
      processes: item.ChartData.Processes,
      failedAuditsPercent: Math.round(item.ChartData.FailedAuditsPercent * 1000) / 10,
      numUnique: item.ChartData.NumUnique,
    };

    return { ...item, ChartData: chartData };
  });

  return formattedChartData;
});

export const getProcessesByItemId = createSelector([getFormattedChartData], (processes) => {
  const processesByItemId = processes.reduce((acc, item) => {
    const keys = Object.keys(item);
    const isTeam = keys.includes("Team");

    if (isTeam) {
      acc[item.Team.teamid] = {
        faid: item.Team.faid,
        name: item.Team.name,
        type: "team",
        chartData: [item.ChartData],
      };
    } else {
      acc[item.Operator.updateData.user_metadata.name] = {
        // TODO: do the acc on the user ID when it'll be available
        name: item.Operator.updateData.user_metadata.name,
        type: "operator",
        chartData: [item.ChartData],
      };
    }

    return acc;
  }, {});

  return processesByItemId;
});

export const getTotalProcesses = createSelector([getProcesses], (processes) => {
  const totalProcesses = processes.reduce((acc, item) => {
    acc += item.ChartData.Processes;

    return acc;
  }, 0);

  return totalProcesses;
});

export const getFormattedProcesses = createSelector(
  [getProcessesByItemId, getTotalProcesses],
  (processesByItemId, totalProcesses) => {
    const formattedProcesses = { processesByItemId, totalProcesses };
    return formattedProcesses;
  }
);

export const getProcessesColumns = createSelector([getFormattedChartData], (processes) => {
  const keys = Object.keys(processes[0] || {});
  const firstColumn = keys.filter((key) => key !== "ChartData");
  const otherColumnsSorted = ["productivity", "processes", "failedAuditsPercent", "numUnique"];
  const columns = firstColumn.concat(otherColumnsSorted);

  return columns;
});

export const getProcessesTitles = createSelector([getProcessesColumns], (keys) => {
  const [item] = keys;
  const titles = {
    [item]: item,
    productivity: "UPH per operator",
    processes: "Total device count",
    failedAuditsPercent: "Audits failed (%)",
    numUnique: "Unique device count",
  };

  return titles;
});
