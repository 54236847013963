import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, FontIcon } from "react-md";
import classnames from "classnames";

import { AsciSpinner } from "~/global";

import "./ActiveCustomerSelector.component.scss";

const ActiveCustomerSelector = ({ customers, activeCustomer, isLoading, onActivate, onChange }) => {
  const activate = () => {
    onActivate(
      customers.map((customer, index) => {
        // Right now the CSIDs are not unique so we need to concatenate them with the index
        const isSelected = activeCustomer.csid === customer.csid;

        return (
          <li className="context-menu" key={`${customer.csid}-${index}`} name={customer.name}>
            <Button
              flat
              className={classnames("customer-button full-width", { selected: isSelected })}
              onClick={() => onChange(customer.csid)}
            >
              {customer.name}
              <div>{customer.csid}</div>
            </Button>

            <Divider key={`divider-${customer.csid}-${index}`} />
          </li>
        );
      })
    );
  };

  if (!activeCustomer.name || customers.length === 0) {
    return null;
  }

  return (
    <div className="active-customer-selector">
      <Button
        flat
        className="drawer-btn"
        onClick={activate}
        iconBefore={false}
        iconEl={<FontIcon iconClassName="icon-caret-down" label="Active Customer Name" />}
      >
        {activeCustomer.name}
      </Button>

      <AsciSpinner visible={isLoading} />
    </div>
  );
};

ActiveCustomerSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  customers: PropTypes.array,
  activeCustomer: PropTypes.object,
  isLoading: PropTypes.bool,
};

ActiveCustomerSelector.defaultProps = {
  customers: [],
  activeCustomer: {},
  isLoading: false,
};

export default ActiveCustomerSelector;
