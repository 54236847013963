import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash/lang";
import { Button, Input, List, Typography } from "antd";
import { InfoOutlined } from "@ant-design/icons";

import {
  AsciButton,
  AsciSpinner,
  DialogMessage,
  SectionHeader,
  Tab,
  Tabs,
  WizardNavigation,
} from "~/global";
import DeploymentSchedules from "../DeploymentSchedules/DeploymentSchedules.component";
import DeploymentCustomFields from "../DeploymentCustomFields/DeploymentCustomFields.component";
import DeploymentSummary from "../DeploymentSummary/DeploymentSummary.component";
import DeploymentPrograms from "../DeploymentPrograms/DeploymentPrograms.component";

import actions from "~/actions";
import selectors from "~/selectors";
import moduleActions from "../../actions";
import moduleSelectors from "~/deployment-management/selectors";

const { Text } = Typography;

const DeploymentEditor = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [csid, setCsid] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [wizardCompleted, setWizardCompleted] = useState(false);

  const [dpid, setDpi] = useState(props.match.params.dpid);
  const [faid, setFaid] = useState(props.match.params.faid);

  const deploymentName = useRef();

  const steps = [
    {
      title: "Shifts",
      id: "shifts",
      content: (
        <>
          <SectionHeader
            title="Shift"
            description="A recurring period of time to which groups of operators can be associated. Also used as a filter in Dashboards"
          >
            <Button
              shape="circle"
              size="small"
              icon={<InfoOutlined style={{ color: "#005288", fontSize: 16 }} />}
              onClick={() =>
                onInfoClicked(
                  "A shift might be:",
                  "Morning, Day, Afternoon, Evening, Night, Half - Day, Weekend, Special, Numbered, etc"
                )
              }
            />
          </SectionHeader>
          <DeploymentSchedules
            shifts={props.shifts}
            onChange={(newShifts) => props.updateDeployment("shifts", newShifts)}
          />
        </>
      ),
      valid: props.shiftsValid,
      message: props.shiftsMessage,
    },
    {
      title: "Programs",
      id: "programs",
      content: (
        <>
          <SectionHeader
            title="Programs"
            description="A line of business; a set of products and services. Lines of business may be divided by product type, customer size, customer needs, channel and brand"
          />
          <DeploymentPrograms
            programs={props.programs}
            selectedPrograms={props.selectedPrograms}
            onChange={(programs) => props.updateDeployment("programs", programs)}
          />
        </>
      ),
      valid: props.programsValid,
      message: props.programsMessage,
    },
    {
      title: "Custom Fields",
      id: "customFields",

      content: (
        <>
          <SectionHeader title="Custom Field" description="Mandatory information required" />
          <DeploymentCustomFields
            customFields={props.customFields}
            activeCustomFields={props.deployment.customFields}
            onChange={(newCustomFields) => props.updateDeployment("customFields", newCustomFields)}
          />
        </>
      ),
      valid: true,
      message: "",
    },
    {
      title: "Review",
      id: "review",
      onlyWhenCreating: true,
      content: (
        <DeploymentSummary
          facility={props.facility ?? {}}
          shifts={props.shifts}
          programs={props.programs}
          selectedPrograms={props.selectedPrograms}
          customFields={props.customFields}
          selectedCustomFields={props.selectedCustomFields}
        />
      ),
      valid: true,
      message: props.summaryMessage,
    },
  ];

  useEffect(() => {
    if (!props.canEdit) {
      props.history.replace("/deployment");
    }
    props.fetchPrograms();
    props.fetchCustomFields();
    setCsid(props.csid);
    return () => {
      props.clearDeployment();
    };
  }, []);

  useEffect(() => {
    if (csid.length > 0 && props.csid != csid) {
      props.history.push(`/deployment`);
    }
  }, [props.csid]);

  useEffect(() => {
    const { dpid } = props.match.params;
    const { faid } = props.match.params;
    const { sbid } = props.match.params;

    if (dpid != null && dpid.length > 0) {
      setDpi(props.match.params.dpid);
    } else {
      setIsNew(true);
    }

    if (faid != null && faid.length > 0) {
      setFaid(props.match.params.faid);
      props.fetchFacility(faid, sbid);
    }
  }, [props.match.params]);

  useEffect(() => {
    deploymentName.current != null ? deploymentName.current.focus() : null;

    if (dpid != null) {
      props.fetchDeployment(faid, dpid);
    } else {
      const deployment = {
        customFields: [],
        dpid: "",
        name: "",
        programs: [],
        shifts: [
          {
            end: {
              hours: 23,
              minutes: 45,
            },
            id: "",
            name: "",
            start: {
              hours: 0,
              minutes: 0,
            },
          },
        ],
      };
      props.initDeployment(deployment);
    }
  }, [props.facility]);

  const showValidationMessage = (step) => {
    props.showDialog({
      title: "Invalid Fields",
      width: "450px",
      modal: true,
      content: (close) => (
        <DialogMessage
          close={() => {
            close();
          }}
        >
          <List
            dataSource={step.message}
            renderItem={(item) => (
              <List.Item>
                <Text>{item}</Text>
              </List.Item>
            )}
          />
        </DialogMessage>
      ),
    });
  };

  const onStepChanged = (index) => {
    if (index == steps.length - 1) {
      setWizardCompleted(true);
    }

    if (steps[currentStep].valid) {
      setCurrentStep(index);
    } else {
      showValidationMessage(steps[currentStep]);
    }
  };

  const navigateToDeploymentList = () => {
    props.history.push("/deployment");
  };

  const onCancelButtonClicked = () => {
    if (props.unmodifiedDeployment) {
      props.hideDialog();
      navigateToDeploymentList();
    } else {
      props.showDialog({
        title: "Exit Deployment",
        width: "450px",
        modal: true,
        content: (
          <div>
            <p className="align-center">
              Changes have been made that were not saved. Do you want to save the changes?
            </p>
          </div>
        ),
        footer: (close) => (
          <div className="row centered">
            <AsciButton color="white" onClick={() => close()}>
              {isNew ? "Continue Creation" : "Continue Editing"}
            </AsciButton>

            <AsciButton
              color="red"
              onClick={() => {
                navigateToDeploymentList();
                close();
              }}
            >
              Discard and Exit
            </AsciButton>

            {!isNew ? (
              <AsciButton
                color="blue"
                onClick={() => {
                  onSaveButtonClicked();
                  close();
                }}
                disabled={!canSaveDeployment}
              >
                Save Changes
              </AsciButton>
            ) : null}
          </div>
        ),
      });
    }
  };

  const onInfoClicked = (title, text) => {
    props.showDialog({
      title,
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={close}>
          <div>{text}</div>
        </DialogMessage>
      ),
    });
  };

  const onSaveButtonClicked = () => {
    const updatedDeployment = props.deployment;
    let shifts;

    if (updatedDeployment.shifts) {
      shifts = updatedDeployment.shifts.map((shift) => {
        if (!shift.id) {
          delete shift.id;
        }

        return shift;
      });
    }

    updatedDeployment.shifts = shifts;

    isNew
      ? props.addDeployment(faid, updatedDeployment)
      : props.saveDeployment(faid, updatedDeployment);
  };

  if (isEmpty(props.deployment)) {
    return <AsciSpinner visible />;
  }

  const canSaveDeployment = isNew ? wizardCompleted && props.canSave : props.canSave;
  return (
    <div className="deployment-editor panel-container layout-column layout-column--start">
      <div className="layout-row layout-row--start-center margin-bottom--10 padding-left--10 full-width">
        <label className="margin-right--5">Facility:</label>
        <div className="margin-right--15 flex--grow">{props.facility?.name}</div>

        <label className="margin-right--5">Deployment:</label>
        <Input
          id="deployment-name"
          value={props.deploymentName}
          placeholder="Name"
          onChange={(e) => props.updateDeployment("name", e.target.value)}
          ref={deploymentName}
          onKeyPress={(e) =>
            canSaveDeployment && e.key === "Enter" ? onSaveButtonClicked() : null
          }
          style={{ width: 240 }}
        />
      </div>

      {isNew ? (
        <div className="layout-column">
          <WizardNavigation
            currentIndex={currentStep}
            onClick={(index) => onStepChanged(index)}
            onCancel={onCancelButtonClicked}
            sections={steps}
            disabled={!steps[currentStep].valid}
          />

          <div className="current-step">{steps[currentStep].content}</div>
        </div>
      ) : (
        <Tabs
          tabId="deployment-management-editor-tabs"
          onTabChanged={(index) => onStepChanged(index)}
          selectedIndex={currentStep}
        >
          {steps
            .filter((step) => step.id != "review")
            .map((step) => (
              <Tab title={step.title} key={step.id}>
                {step.content}
              </Tab>
            ))}
        </Tabs>
      )}

      <div className="content-footer push-right">
        <p>{isNew ? steps[currentStep].message : props.summaryMessage}</p>

        <AsciButton clor="white" onClick={onCancelButtonClicked}>
          <span>{props.unmodifiedDeployment ? "Exit" : "Cancel"}</span>
        </AsciButton>

        <AsciButton color="blue" onClick={onSaveButtonClicked} disabled={!canSaveDeployment}>
          <span>Save Changes</span>
        </AsciButton>
      </div>
      <AsciSpinner visible={props.isLoading} />
    </div>
  );
};

const stateToProps = (state) => ({
  canEdit: selectors.session.hasPermission(state, "PUT", "/facilities/:faid/deployments/:dpid"),
  canCreateDeployment: selectors.session.hasPermission(
    state,
    "POST",
    "/facilities/:faid/deployments"
  ),
  isLoading: moduleSelectors.deploymentEditor.getIsLoading(state),
  deployment: moduleSelectors.deploymentEditor.getUpdatedDeployment(state),
  deploymentName: moduleSelectors.deploymentEditor.getDeploymentName(state),
  shifts: moduleSelectors.deploymentEditor.getShifts(state),
  selectedPrograms: moduleSelectors.deploymentEditor.getPrograms(state),
  selectedCustomFields: moduleSelectors.deploymentEditor.getCustomFields(state),
  canSave: moduleSelectors.deploymentEditor.getDeploymentSaveEnabled(state),
  unmodifiedDeployment: moduleSelectors.deploymentEditor.getDeploymentIsEqual(state),
  facility: selectors.facility.getUpdatedFacility(state),
  programs: state.programs.items,
  customFields: state.customFields.items,
  isInitialized: state.modules.deploymentManagement.deployment.isInitialized,

  shiftsValid: moduleSelectors.deploymentEditor.getShiftsValid(state),
  programsValid: moduleSelectors.deploymentEditor.getProgramsValid(state),
  shiftsMessage: moduleSelectors.deploymentEditor.getShiftsErrorMessage(state),
  programsMessage: moduleSelectors.deploymentEditor.getProgramsErrorMessage(state),
  summaryMessage: moduleSelectors.deploymentEditor.getErrorMessages(state),
  csid: state.customer.activeCustomerId,
});

const dispatchToProps = {
  clearDeployment: moduleActions.deployment.clearDeployment,
  fetchDeployment: moduleActions.deployment.fetchDeployment,
  initDeployment: moduleActions.deployment.initDeployment,
  updateDeployment: moduleActions.deployment.updateDeployment,
  fetchFacility: actions.facility.fetchFacilityWithDeployments,
  fetchPrograms: actions.programs.fetchPrograms,
  fetchCustomFields: actions.customFields.fetchCustomFields,

  addDeployment: moduleActions.deployment.addDeployment,
  saveDeployment: moduleActions.deployment.saveDeployment,

  hideDialog: actions.dialog.hide,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(DeploymentEditor);
