import produce from "immer";

import {
  SET_SELECTED_PLATFORM,
  SET_INITIALLY_SELECTED_PLATFORM_VALUE,
} from "~/actions/platforms.actions";

const PLATFORM_ANDROID = { name: "Android", value: "android" };
const PLATFORM_IOS = { name: "iOS", value: "ios" };

const initialState = {
  items: [PLATFORM_IOS, PLATFORM_ANDROID],
  initiallySelectedValue: PLATFORM_IOS.value,
  selected: PLATFORM_IOS,
};

export default function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_SELECTED_PLATFORM: {
        draft.selected = action.payload.platform;
        return;
      }

      case SET_INITIALLY_SELECTED_PLATFORM_VALUE: {
        draft.initiallySelectedValue = action.payload.platformValue;
      }
    }
  });
}
