import React, { useState } from "react";
import { ErrorObject } from "ajv";
import { Alert, List, message, Modal } from "antd";
import { omit } from "lodash";
import { Trans } from "react-i18next";
import ReactJson from "react-json-view";

import { validate } from "~/entities/model-descriptors/lib/compileJsonSchema";
import { Descriptor } from "~/entities/model-descriptors/model/descriptorTypes";
import { updateModelDescriptor } from "~/entities/model-descriptors/model/modelDescriptorsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";

type Props = {
  open: boolean;
  modelDescriptor: Descriptor;
  onClose: () => void;
};

export const UpdateDescriptorModal = ({ open, modelDescriptor, onClose }: Props) => {
  const { t } = useTranslation("modelDescriptors");
  const dispatch = useAppDispatch();

  const isDescriptorUpdating = useAppSelector(
    (state) => state.modelDescriptors.formStatus === "loading"
  );

  const [src, setSrc] = useState(omit(modelDescriptor, "id"));
  const [validationErrors, setValidationErrors] = useState<ErrorObject[]>([]);

  const validateDescriptor = (data: object) => {
    const descriptor = { id: modelDescriptor.id, ...data };
    const valid = validate(descriptor);

    if (valid) {
      setSrc(omit(descriptor, "id"));
      setValidationErrors([]);
    } else if (validate.errors) {
      setValidationErrors(validate.errors);
    }
  };

  return (
    <Modal
      className="model-descriptors-modal"
      title={t("edit-descriptor")}
      open={open}
      okText={t("save")}
      okButtonProps={{
        loading: isDescriptorUpdating,
        disabled: validationErrors.length > 0,
      }}
      onOk={() => {
        const { id } = modelDescriptor;

        void dispatch(updateModelDescriptor({ id, descriptor: { id, ...src } }))
          .unwrap()
          .then(() => {
            void message.success(t("update-success"));
            onClose();
          })
          .catch((rejectedValue: string) => {
            void message.error(rejectedValue);
          });
      }}
      onCancel={() => {
        onClose();
      }}
      width={960}
      style={{ top: 24 }}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 156px)" }}
    >
      {validationErrors.length > 0 && (
        <Alert
          message={t("validation-errors")}
          description={
            <List
              dataSource={validationErrors}
              renderItem={({ instancePath, message }) => (
                <List.Item>
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  <code>root{instancePath}</code> {message}
                </List.Item>
              )}
              split={false}
            />
          }
          type="error"
        />
      )}

      <div className="descriptor-id">
        <Trans
          i18nKey="descriptor-id"
          ns="modelDescriptors"
          t={t}
          values={{ id: modelDescriptor?.id }}
        />
      </div>

      <ReactJson
        src={src}
        collapsed={1}
        iconStyle="square"
        onEdit={({ updated_src }) => {
          validateDescriptor(updated_src);
        }}
        onAdd={({ updated_src }) => {
          validateDescriptor(updated_src);
        }}
        onDelete={({ updated_src }) => {
          validateDescriptor(updated_src);
        }}
      />
    </Modal>
  );
};
