import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppButton } from "~/global";
import selectors from "~/selectors";

import "./AppsCollection.component.scss";

const AppsCollection = ({ originId, size, showDescription, availableApps }) => {
  const { t } = useTranslation(["home"]);

  const getContent = (content = "") => {
    const i18nContent = t(`modules.${content}`, { returnObjects: true });

    if (Array.isArray(i18nContent)) {
      return i18nContent.map((str, index) => (
        <Fragment key={index}>
          <span>{str}</span>
          {index < content.length - 1 ? <br /> : null}
        </Fragment>
      ));
    }

    return i18nContent;
  };

  return (
    <div className="apps-collection">
      {availableApps
        .filter((app) => !app.isHidden)
        .map((app) => (
          <NavLink
            key={`app-button-${app.appName}`}
            to={`/${app.urlName}`}
            className="app-button"
            activeClassName="selected"
          >
            <AppButton
              appName={app.appName}
              buttonId={originId != null ? `-${originId}` : ""}
              size={size}
            />

            <div className="app-name">{getContent(app.title)}</div>

            <div className="app-description">{showDescription && getContent(app.description)}</div>
          </NavLink>
        ))}
    </div>
  );
};

AppsCollection.propTypes = {
  originId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDescription: PropTypes.bool,
  availableApps: PropTypes.arrayOf(
    PropTypes.shape({
      appName: PropTypes.string,
      urlName: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ).isRequired,
};

AppsCollection.defaultProps = {
  showDescription: false,
};

const stateToProps = (state) => ({
  availableApps: selectors.session.getAvailableApps(state),
});

export default connect(stateToProps, null)(AppsCollection);
