import { createSelector } from "@reduxjs/toolkit";

const getFacilityList = (state) => state.facilities.items;
const getFacilityID = (state, props) => props.tenantIdentifier.portalFacilityID;

export const getFacilityNameByID = createSelector(
  [getFacilityList, getFacilityID],
  (facilities = [], facilityID = "") => {
    const facilityByID = facilities.filter((facility) => facility.faid === facilityID);

    if (facilityByID[0] === undefined) {
      return facilityID;
    }
    return facilityByID[0].name || facilityID;
  }
);
