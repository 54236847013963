import { find } from "lodash/collection";
import { without } from "lodash/array";
import { createReducer } from "@reduxjs/toolkit";

import actions from "~/actions";
import moduleActions from "../actions";

const initialState = {
  items: [],
  nameFilter: "",
  initiallySelectedConfig: undefined,
  isDuplicating: false,
  isDeleting: false,
  isLoading: false,
  errors: {},
};

export default createReducer(initialState, {
  // Fetch all test defintions
  [actions.testDefinitions.fetchTestDefinitionsBegin]: (state) => {
    state.isLoading = true;
  },
  [actions.testDefinitions.fetchTestDefinitionsSuccess]: (state, action) => {
    state.items = action.payload.testDefinitions || [];
    state.isLoading = false;
  },
  [actions.testDefinitions.fetchTestDefinitionsError]: (state, action) => {
    console.error(action.payload.error);
    state.items = [];
    state.isLoading = false;
  },

  // Duplicate a test definition
  [actions.testDefinitions.duplicateTestDefinitionBegin]: (state, action) => {
    state.isDuplicating = true;
  },
  [actions.testDefinitions.duplicateTestDefinitionSuccess]: (state, action) => {
    state.isDuplicating = false;
    delete state.errors.duplicate;
    state.items.push(action.payload.definition || {});
  },
  [actions.testDefinitions.duplicateTestDefinitionError]: (state, action) => {
    state.isDuplicating = false;
    state.errors = {
      duplicate: action.payload.error,
    };
  },

  // Delete a test definition
  [actions.testDefinitions.deleteTestDefinitionBegin]: (state, action) => {
    state.isDeleting = true;
  },
  [actions.testDefinitions.deleteTestDefinitionSuccess]: (state, action) => {
    const { platform } = action.payload;
    const { identifier } = action.payload;
    const definition = find(
      state.items,
      (item) => item.platform == platform && item.identifier == identifier
    );

    state.isDeleting = false;
    state.items = without(state.items, definition);
    state.selected = {};
  },
  [actions.testDefinitions.deleteTestDefinitionError]: (state, action) => {
    console.error(action.payload.error);
    state.isDeleting = false;
  },

  // Set test definitions display name filter
  [actions.testDefinitions.setTestDefinitionsNameFilter]: (state, action) => {
    state.nameFilter = action.payload;
  },

  // update items list when a test definition is updated
  [moduleActions.saveTestDefinitionSuccess]: (state, action) => {
    const updatedDefinition = action.payload || {};

    for (let i = 0; i < state.items.length; i++) {
      const item = state.items[i];
      if (
        item.className === updatedDefinition.className &&
        item.identifier === updatedDefinition.identifier &&
        item.platform === updatedDefinition.platform
      ) {
        state.items[i] = updatedDefinition;
        break;
      }
    }
  },
});
