import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/data-import/actions";

const initialState = {
  isLoading: false,
  errors: null,
  operator: "",
  processDate: {
    startTime: "",
    endTime: "",
  },
  imei: "",
  meid: "",
  wifiMAC: "",
  bluetoothMAC: "",
  OEMSerialNumber: "",
  processResult: "",
  failCode: {},
  duration: 0,
  customFields: [],
  sessionId: "",
  deviceInfo: [],
  deviceData: {
    modelName: "",
    color: "",
    capacit: "",
    manufacturer: "",
  },
};

export default createReducer(initialState, {
  [moduleActions.importDataManual.createRecordingBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.importDataManual.createRecordingSuccess]: (state) => {
    state.isLoading = false;
  },
  [moduleActions.importDataManual.createRecordingError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.importDataManual.onOperatorChange]: (state, action) => {
    state.isLoading = false;
    state.operator = action.payload;
  },
  [moduleActions.importDataManual.onUpdateDateTime]: (state, action) => {
    state.isLoading = false;
    state.processDate = action.payload;
  },
  [moduleActions.importDataManual.onUpdateIdentifyingInfo]: (state, action) => {
    state.isLoading = false;
    state.imei = action.payload.imei;
    state.meid = action.payload.meid;
    state.wifiMAC = action.payload.wifiMAC;
    state.bluetoothMAC = action.payload.bluetoothMAC;
    state.OEMSerialNumber = action.payload.OEMSerialNumber;
  },
  [moduleActions.importDataManual.onSavePassTest]: (state, action) => {
    state.isLoading = false;
    state.processResult = "PASS";
    state.failCode = {};
    state.duration = action.payload.duration;
  },
  [moduleActions.importDataManual.onSaveFailCode]: (state, action) => {
    state.isLoading = false;
    state.processResult = "FAIL";
    state.failCode = action.payload;
    state.duration = action.payload.duration;
  },
  [moduleActions.importDataManual.onClearFailState]: (state, action) => {
    state.isLoading = false;
    state.processResult = "";
    state.failCode = {};
    state.duration = 0;
  },
  [moduleActions.importDataManual.onSaveCustomFieldList]: (state, action) => {
    state.customFields = action.payload;
    state.isLoading = false;
  },
  [moduleActions.importDataManual.onSaveSessionId]: (state, action) => {
    state.sessionId = action.payload;
    state.isLoading = false;
  },
  [moduleActions.importDataManual.fetchDeviceInfosSuccess]: (state, action) => {
    state.deviceInfo = action.payload;
    state.isLoading = false;
  },
  [moduleActions.importDataManual.onSaveDeviceData]: (state, action) => {
    state.deviceData = action.payload;
    state.isLoading = false;
  },
  [moduleActions.importDataManual.onEmptyFields]: (state, action) => {
    state.isLoading = false;
    state.operator = "";
    state.processDate = {
      startTime: "",
      endTime: "",
    };
    state.imei = "";
    state.meid = "";
    state.wifiMAC = "";
    state.bluetoothMAC = "";
    state.OEMSerialNumber = "";
    state.processResult = "";
    state.failCode = {};
    state.duration = 0;
    state.customFields = [];
    state.sessionId = "";
    state.deviceInfo = [];
    state.deviceData = {
      modelName: "",
      color: "",
      capacit: "",
      manufacturer: "",
    };
  },
});
