import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { message } from "antd";
import actions from "~/actions";

import { AsciButton } from "~/global";
import history from "~/global/history";

import moduleSelectors from "~/custom-fields-editor/selectors/";
import moduleActions from "~/custom-fields-editor/actions/";

const CustomFieldEditorButtons = (props) => {
  useEffect(() => {
    if (props.message != null && props.errors == null) {
      props.addCustomField(props.customField);
      props.close();
      props.fetchCustomFields();
    }
  }, [props.message]);

  const onCancel = () => {
    props.isNew ? props.close() : props.showConfirmation();
  };

  const onSave = async () => {
    message.loading("Action in progress..", 0);

    if (props.isNew) {
      props.createCustomField(props.customField);
    } else {
      props.editCustomField(props.customField);
    }
  };

  return (
    <div
      className={props.isNew ? "layout-row layout-row--end-center" : "content-footer push-right"}
    >
      <div>{props.errorMessage}</div>
      <AsciButton color="white" onClick={() => onCancel()}>
        {props.isEqual ? "Exit" : "Cancel"}
      </AsciButton>

      <AsciButton color="blue" onClick={onSave} disabled={!props.canSave || props.isLoading}>
        Save
      </AsciButton>
    </div>
  );
};

CustomFieldEditorButtons.propTypes = {
  isNew: PropTypes.bool,
  close: PropTypes.func,
  showConfirmation: PropTypes.func,
};

CustomFieldEditorButtons.defaultProps = {
  isNew: false,
  close: () => {},
  showConfirmation: () => {},
};

const stateToProps = (state) => ({
  isEqual: moduleSelectors.customFieldEditor.getIsEqual(state),
  canSave: moduleSelectors.customFieldEditor.getCanSave(state),
  errorMessage: moduleSelectors.customFieldEditor.getErrorMessages(state),
  customField: moduleSelectors.customFieldEditor.getUpdatedCustomField(state),
  message: state.modules.customFields.customFieldEditor.message,
  errors: state.modules.customFields.customFieldEditor.errors,
  isLoading: state.modules.customFields.customFieldEditor.isLoading,
});

const dispatchToProps = {
  createCustomField: moduleActions.customFieldEditor.createCustomField,
  editCustomField: moduleActions.customFieldEditor.editCustomField,
  addCustomField: actions.customFields.addCustomField,
  fetchCustomFields: actions.customFields.fetchCustomFields,
};

export default connect(stateToProps, dispatchToProps)(CustomFieldEditorButtons);
