import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import uniq from "lodash/uniq";
import {
  SuiteTemplateList,
  AllServiceSuitesList,
  ServiceSuitesServices,
} from "~/programs-management";
import { AsciButton, InputSearch, SelectList } from "~/global";

import "./ServiceSuiteWizard.component.scss";

const ServiceSuiteWizard = (props) => {
  const [view, setView] = useState("creationOptions");
  const [templateIdentifier, setTemplate] = useState("");
  const [currentServiceSuite, setCurrentServicesSuite] = useState([]);
  const [serviceSuitesAllPrograms, setServiceSuitesAllPrograms] = useState(
    props.serviceSuitesAllPrograms
  );
  const [selectedProgram, setSelectedProgram] = useState("");
  const [programs, setPrograms] = useState([]);

  const paperClasses = "paper-button layout-column layout-row--center-center";
  const templateDisable = props.serviceSuiteTemplates.length === 0;
  const serviceSuitesAllProgramsEmpty = props.serviceSuitesAllPrograms.length === 0;
  const allServiceSuiteServicesEmpty = props.allServiceSuiteServices.length === 0;

  useEffect(() => {
    const programs = props.serviceSuitesAllPrograms.map(
      (program) => program["program.displayName"].en
    );
    const uniqPrograms = uniq(programs);
    const programsList = [].concat("", uniqPrograms);
    setPrograms(programsList);
  }, []);

  useEffect(() => {
    if (view == "creationOptions") {
      setCurrentServicesSuite([]);
      setTemplate("");
    }
    if (view == "suiteList") {
      setServiceSuitesAllPrograms(props.serviceSuitesAllPrograms);
      setSelectedProgram("");
    }
  }, [view]);

  const createFromTemplate = () => {
    props.createFromTemplate(templateIdentifier);
    props.close();
  };

  const onClickCreateFromScratch = () => {
    props.createFromSuite(props.allServiceSuiteServices);
    props.close();
  };

  const onClickCreate = () => {
    props.createFromSuite(currentServiceSuite);
    props.close();
  };

  const showSuiteDetails = (serviceSuite) => {
    setCurrentServicesSuite(serviceSuite);
    setView("serviceSuiteDetails");
  };

  const onSearch = (name) => {
    const filteredServicesSuites = props.serviceSuitesAllPrograms
      .filter((serviceSuiteAllPrograms) => {
        const value = name.toLowerCase();
        const identifier = (serviceSuiteAllPrograms.identifier || "").toLowerCase().trim();
        const programDisplayName = (serviceSuiteAllPrograms["program.displayName"].en || "")
          .toLowerCase()
          .trim();
        const displayName = (serviceSuiteAllPrograms.displayName.en || "").toLowerCase().trim();
        const identifierFound = identifier.indexOf(value) > -1;
        const nameFound = displayName.indexOf(value) > -1;
        const programFound = programDisplayName.indexOf(value) > -1;

        return identifierFound || nameFound || programFound;
      })
      .filter((serviceSuiteFilteredPrograms) =>
        selectedProgram.length > 0
          ? serviceSuiteFilteredPrograms["program.displayName"].en == selectedProgram
          : true
      );

    setServiceSuitesAllPrograms(filteredServicesSuites);
  };

  const selectProgram = (program) => {
    const filteredServicesSuites = props.serviceSuitesAllPrograms.filter(
      (serviceSuiteAllPrograms) =>
        program.length > 0 ? serviceSuiteAllPrograms["program.displayName"].en == program : true
    );

    setServiceSuitesAllPrograms(filteredServicesSuites);
    setSelectedProgram(program);
  };

  return (
    <div className="service-suite-wizard">
      {view === "creationOptions" ? (
        <div className="column spaced-centered">
          <h2 className="dialog">Add Service Suite to {props.programName}</h2>
          <h3>How would you like to add a new Service Suite?</h3>
          <div className="layout-row space-around full-width">
            <div onClick={() => !templateDisable && setView("templateList")}>
              <div className={classnames(paperClasses, templateDisable ? "disabled" : "")}>
                <i className="icon-template" />
                <div>
                  Copy from
                  <br />
                  Template
                </div>
              </div>
              <p className="error-button-message">
                {templateDisable ? "No available Templates found" : ""}
              </p>
            </div>
            <div onClick={() => !serviceSuitesAllProgramsEmpty && setView("suiteList")}>
              <div
                className={classnames(
                  paperClasses,
                  serviceSuitesAllProgramsEmpty ? "disabled" : ""
                )}
              >
                <i className="icon-copy" />
                <div>
                  Copy
                  <br />
                  existing Suite
                </div>
              </div>
              <p className="error-button-message">
                {serviceSuitesAllProgramsEmpty ? "No existing Suites found" : ""}
              </p>
            </div>
            <div onClick={() => !allServiceSuiteServicesEmpty && onClickCreateFromScratch()}>
              <div
                className={classnames(paperClasses, allServiceSuiteServicesEmpty ? "disabled" : "")}
              >
                <i className="icon-empty-data" />
                <div>
                  Create Suite
                  <br />
                  from scratch
                </div>
              </div>
              <p className="error-button-message">
                {allServiceSuiteServicesEmpty
                  ? "No available Services found for creation from scratch"
                  : ""}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {view === "templateList" ? (
        <div className="column spaced-centered">
          <h2 className="dialog">Copy from Template</h2>
          <h3>Select from our list of templates</h3>
          <div className="service-wizard-content">
            <SuiteTemplateList
              suiteTemplates={props.serviceSuiteTemplates}
              selectTemplate={(templateIdentifier) => setTemplate(templateIdentifier)}
            />
          </div>
          <div className="layout-row layout-row--end-center full-width footer-btns">
            <AsciButton
              color="white"
              onClick={() => {
                setView("creationOptions");
                setTemplate("");
              }}
            >
              Back
            </AsciButton>
            <AsciButton
              color="green"
              disabled={templateIdentifier.length === 0}
              onClick={() => createFromTemplate()}
            >
              Create
            </AsciButton>
          </div>
        </div>
      ) : (
        <div />
      )}
      {view === "suiteList" ? (
        <div className="column spaced-centered">
          <h2 className="dialog">Copy existing Suite</h2>
          <div className="row space-around filter-service-suite">
            <InputSearch
              className="suite-search cc-input-search-large"
              placeholder="Search Services Suites"
              onChange={onSearch}
              trim
            />
            <SelectList items={programs} selectedItem={selectedProgram} onChange={selectProgram} />
          </div>

          <div className="service-wizard-content">
            <AllServiceSuitesList
              serviceSuites={serviceSuitesAllPrograms}
              showSuiteDetails={showSuiteDetails}
              onClickSelect={(serviceSuite) => setCurrentServicesSuite(serviceSuite)}
            />
          </div>
          <div className="layout-row layout-row--end-center full-width footer-btns">
            <AsciButton color="white" onClick={() => setView("creationOptions")}>
              Back
            </AsciButton>
            <AsciButton
              color="green"
              disabled={currentServiceSuite.length === 0}
              onClick={() => onClickCreate()}
            >
              Create
            </AsciButton>
          </div>
        </div>
      ) : (
        <div />
      )}
      {view === "serviceSuiteDetails" ? (
        <div className="column spaced-centered">
          <h2 className="dialog">Details of {currentServiceSuite.displayName.en}</h2>
          <h3>Create current Suite or go back to the list of Suites</h3>
          <div className="service-wizard-content">
            <ServiceSuitesServices serviceSuite={currentServiceSuite} />
          </div>
          <div className="layout-row layout-row--end-center full-width footer-btns">
            <AsciButton color="white" onClick={() => setView("suiteList")}>
              Back
            </AsciButton>
            <AsciButton color="green" onClick={() => onClickCreate()}>
              Copy
            </AsciButton>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

ServiceSuiteWizard.propTypes = {
  serviceSuiteTemplates: PropTypes.array,
  serviceSuitesAllPrograms: PropTypes.array,
  allServiceSuiteServices: PropTypes.array, // TODO: need the list from backend
  programName: PropTypes.string,
  close: PropTypes.func,
  createFromTemplate: PropTypes.func,
  createFromSuite: PropTypes.func,
};

ServiceSuiteWizard.defaultProps = {
  programName: "",
  serviceSuiteTemplates: [],
  serviceSuitesAllPrograms: [],
  allServiceSuiteServices: [], // TODO: need the list from backend
};

export default ServiceSuiteWizard;
