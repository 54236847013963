import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { AsciButton } from "~/global";

const DialogDeleteDefinition = ({ children, isDeleting, onDelete, onCancel }) => {
  const onConfirm = () => {
    if (!isDeleting) {
      onDelete();
    }
  };

  return (
    <div>
      <div>{children}</div>

      <div className="dialog-actions layout-row layout-row--end-center">
        <AsciButton color="white" tabIndex={1} onClick={onCancel}>
          Cancel
        </AsciButton>

        <AsciButton color="green" tabIndex={2} onClick={onConfirm}>
          Confirm
        </AsciButton>
      </div>
    </div>
  );
};

DialogDeleteDefinition.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
};

const stateToProps = (state) => ({
  isDeleting: state.testDefinitions.isDeleting,
});

export default connect(stateToProps, undefined)(DialogDeleteDefinition);
