import { setAutoFreeze, produce as immerProduce } from "immer";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createNextState as toolkitProduce } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./app/App.component";
import ErrorPage from "./app/ErrorPage/ErrorPage.component";
import { AuthProvider } from "./auth";
import WebserviceHandler from "./services/WebserviceHandler";
import store from "./store";
import history from "./global/history";
import { AntdConfigProvider } from "./i18n";

import "./styles/index.scss";

/**
 * To disable autoFreeze, the version of immer imported here MUST be the same as the version of immer imported by
 * @redux/toolkit. If two versions of immer exist (one for this project, one for @redux/toolkit) setAutoFreeze()
 * will only affect the local version.
 */
// check that the produce exported by @redux/toolkit is the same as the one exported by immer
// TODO: move this sanity check to some sort of automated test
if (toolkitProduce !== immerProduce) {
  // eslint-disable-next-line no-alert
  alert(
    "Warning: there are more than one version of immer installed. This could lead to breakage in production. See ./src/index.tsx"
  );
}

// disable autoFreeze to prevent the app from breaking when the store is accidentally mutated
setAutoFreeze(false);

if (!WEBPACK_DEV_MODE) {
  // Redirect http to https (except in development environment)
  const loc = `${window.location.href}`;
  if (loc.indexOf("http://") === 0) {
    window.location.href = loc.replace("http://", "https://");
  }

  // Configure sentry only for actual deployments (not in development)
  Sentry.init({
    dsn: "https://6de4842839a94411bc1a9f24bf454b30@o137916.ingest.sentry.io/6137519",
    release: CLIENT_PORTAL_VERSION,
    environment: TARGET_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  });
}

// Load config.json
// When running the dev server, this file only exists in memory (will not appear in the /src folder)
// When building for prod, this file will be generated from /webpack/env_definitions.json based on the target parameter
void fetch("/config.json", { method: "GET" }).then(async (response) => {
  const { hosts, auth0 } = (await response.json()) as {
    hosts: Record<string, string>;
    auth0: Record<"clientId" | "domain", string>;
  };

  Object.entries(hosts).forEach(([hostName, host]) => {
    WebserviceHandler.setHost(hostName, host);
  });

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={(errorData) => <ErrorPage errorData={errorData} />}>
      <Provider store={store}>
        <Suspense fallback="Loading...">
          <AntdConfigProvider>
            <AuthProvider auth0={auth0}>
              <App />
            </AuthProvider>
          </AntdConfigProvider>
        </Suspense>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById("app-wrapper")
  );
});
