import React, { useEffect, useState } from "react";
import { List, InputNumber, Select, Card } from "antd";
import { Button } from "react-md";
import { DialogMessage, SectionHeader, BorderedGroup, Column, AsciButton } from "~/global";
import actions from "~/actions";
import selectors from "~/selectors";
import { useTranslation } from "~/i18n";
import { useAppDispatch, useAppSelector } from "~/hooks";
import moduleSelectors from "../../selectors";
import "./CustomerAudits.component.scss";

const CustomerAudits = ({ csid = "", newCustomer = true }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("customerAudits");
  const facilities = useAppSelector((state) =>
    moduleSelectors.customerFacilities.getSortedFacilities(state)
  );
  const customerName = useAppSelector(moduleSelectors.customerIdentity.getCustomerName);
  const audits = useAppSelector(selectors.audits.getUpdatedAudits);
  const auditsLoadings = useAppSelector((state) => state.audits.isLoading);
  const [facility, setFacility] = useState(facilities[0] ? facilities[0].faid : "");
  const canSaveAudits = useAppSelector((state) => selectors.audits.getCanSaveAudits(state));

  useEffect(() => {
    if (facility !== "") {
      dispatch(actions.audits.fetchAudits(facility)).then(() => {
        dispatch(actions.audits.onResetModifications());
      });
    }
  }, [dispatch, facility]);

  const handleChangeFacility = (faid) => {
    if (canSaveAudits)
      dispatch(
        actions.dialog.show({
          title: t("title-save-to-continue"),
          width: "450px",
          modal: true,
          content: (close) => (
            <div>
              <p className="align-center">{t("content-save-change-ask")}</p>
              <div className="dialog-actions layout-row layout-row--end-center">
                <AsciButton
                  color="green"
                  onClick={() => {
                    dispatch(actions.audits.onSaveAudits()).then(() => setFacility(faid));
                    close();
                  }}
                >
                  {t("content-save-change")}
                </AsciButton>
                <AsciButton
                  color="red"
                  onClick={() => {
                    dispatch(actions.audits.onResetModifications());
                    close();
                  }}
                >
                  {t("content-reset")}
                </AsciButton>
              </div>
            </div>
          ),
        })
      );
    else setFacility(faid);
  };

  const getFacilities = () => {
    const listFacilities = facilities.map((facility) => ({
      label: facility.name,
      value: facility.faid,
    }));
    return listFacilities;
  };

  const onInfoClicked = () => {
    dispatch(
      actions.dialog.show({
        title: t("title-usage-information"),
        width: "450px",
        modal: false,
        content: (close) => (
          <DialogMessage close={close} textButtonClose={t("close-button")}>
            <div>{t("info-dialog")}</div>
          </DialogMessage>
        ),
      })
    );
  };

  return (
    <div className="customer-audits">
      {!newCustomer && (
        <SectionHeader title={t("title-customer-name") + customerName} description={csid}>
          <Button
            icon
            className="icon-btn text-color--white header-audits"
            iconClassName="icon-info-circle"
            forceIconSize={32}
            forceIconFontSize
            onClick={onInfoClicked}
          />
        </SectionHeader>
      )}
      <div className="select-label">
        {t("select-facilities")}
        <Select
          aria-label="Selected-facility-audit"
          showSearch
          dropdownMatchSelectWidth={false}
          value={facility}
          style={{ minWidth: "120px", width: "auto" }}
          onChange={handleChangeFacility}
          options={getFacilities()}
        />
      </div>
      <div className="customer-audits-section">
        <BorderedGroup title={t("audits")}>
          <Column align="start center" style={{ width: "100%" }}>
            <List
              grid={{
                gutter: 16,
                column: 3,
              }}
              dataSource={audits}
              loading={auditsLoadings}
              renderItem={(audit) => (
                <List.Item key={audit.name}>
                  <Card
                    defaultActiveTabKey={audit.name}
                    className="card-audits"
                    title={audit.name}
                    size="small"
                    style={{ maxWidth: 300, backgroundColor: "#f5f5f3" }}
                  >
                    <InputNumber
                      aria-label={audit.name}
                      min={0}
                      max={100}
                      formatter={() => `${audit.auditrate}%`}
                      value={audit.auditrate}
                      onChange={(number) =>
                        dispatch(actions.audits.onUpdateAudits(number, audit.name))
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
          </Column>
        </BorderedGroup>
      </div>
    </div>
  );
};

export default CustomerAudits;
