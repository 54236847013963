import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { sortBy } from "lodash/collection";
import {
  DataTable,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import moduleActions from "../../actions";

import { SectionHeader, Row, Column } from "~/global";
import { formatTime, secondsFormat, formatDate, getDurationTime } from "~/global/utils";
import StatusIcon from "~/device-history/components/StatusIcon/StatusIcon.component";

import "./ContentClearedServices.component.scss";

const ContentClearedServices = (props) => {
  const [sortKey, setSortKey] = useState("");
  const [sortReverse, setSortReverse] = useState(true);

  const onSort = (key) => {
    const newSortReverse = key == sortKey ? !sortReverse : sortReverse;
    setSortKey(key);
    setSortReverse(newSortReverse);
  };

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];

    if (sortKey == colKey) {
      classes.push("is-sorted");
    }
    if (sortReverse) {
      classes.push("sort-reverse");
    }

    return classes.join(" ");
  };

  const includeForPrint = () => {
    props.toggleIncludeToPrint(props.includeToPrint);
  };

  const renderTable = () => {
    let services =
      props.services != null
        ? sortBy(props.services, (item) => {
            const value = item[sortKey];
            const stringValue =
              typeof value === "string" && sortKey != "duration" && sortKey != "start";

            if (stringValue) {
              return value.toLowerCase();
            }
            if (sortKey == "duration") {
              return parseInt(value);
            }
            if (sortKey == "start") {
              return new Date(value);
            }

            return value;
          })
        : [];

    let totalTime = 0;

    if (props.services != null) {
      props.services.forEach((service) => {
        const duration = secondsFormat(service.durationUnits, service.duration);
        totalTime += duration;
      });
    }

    totalTime = formatTime(totalTime);

    if (sortReverse) {
      services = services.reverse();
    }

    return (
      <Row className="device-services full-width">
        <Column className="full-width">
          <DataTable className="device-services-table" baseId="device-services-table" plain>
            <TableHeader className="table-header">
              <TableRow>
                <TableColumn
                  className={`${getColHeaderClasses("name")} header-label`}
                  onClick={() => onSort("name")}
                >
                  Service name
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("status")} header-label`}
                  onClick={() => onSort("status")}
                >
                  Status
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses("start")} header-label`}
                  onClick={() => onSort("start")}
                >
                  Start
                </TableColumn>
                <TableColumn
                  className={`${getColHeaderClasses(
                    "duration"
                  )} right-aligned no-printable header-label`}
                  onClick={() => onSort("duration")}
                >
                  Duration
                </TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody className="table-body">
              {services.map((service, index) => (
                <TableRow key={index} className={service.status == "FAIL" ? "failed" : ""}>
                  <TableColumn className="table-data">{service.name}</TableColumn>
                  <TableColumn className="table-data">
                    <div className="row">
                      <StatusIcon status={service.status} />
                    </div>
                  </TableColumn>
                  <TableColumn className="table-data">
                    {service.start != null ? formatDate(service.start) : ""}
                  </TableColumn>
                  <TableColumn className="right-aligned no-printable table-data">
                    {getDurationTime(service.durationUnits, service.duration)}
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
            {services.length > 0 ? (
              <tfoot className="footer-services no-printable">
                <TableRow>
                  <TableColumn className="bold-screen">Total:</TableColumn>
                  <TableColumn />
                  <TableColumn />
                  <TableColumn className="right-aligned bold-screen">{`~${totalTime}`}</TableColumn>
                </TableRow>
              </tfoot>
            ) : null}
          </DataTable>
        </Column>
      </Row>
    );
  };

  const isEmpty = props.services != null ? props.services.length == 0 : true;

  if (isEmpty) {
    return null;
  }

  return (
    <div className={props.services.length > 1 ? "list-content fade-in" : "list-content fade-in"}>
      {props.certificate ? (
        <div>
          <SectionHeader title="Services" className="certificate-header" />
          {renderTable()}
        </div>
      ) : (
        <div
          className={
            props.includeToPrint ? "list-content fade-in" : "list-content fade-in no-printable"
          }
        >
          <ExpansionList>
            <ExpansionPanel
              className="cc-expansion-panel"
              label={
                <div className="panel-text title bold-screen printable-title">Content Clearing</div>
              }
              secondaryLabel={
                <div className="panel-text no-printable">
                  {props.includeToPrint ? "(added to report)" : "(not added to report)"}
                </div>
              }
              onExpandToggle={() => includeForPrint()}
              expanded={props.includeToPrint}
              footer={null}
              expanderIcon={<i className="no-printable icon-chevron-down" />}
              headerClassName="cc-section-header table-header"
              contentClassName="cc-fullwidth-panel"
            >
              {renderTable()}
            </ExpansionPanel>
          </ExpansionList>
        </div>
      )}
    </div>
  );
};

ContentClearedServices.propTypes = {
  services: PropTypes.array,
  certificate: PropTypes.bool,
};

ContentClearedServices.defaultProps = {
  services: [],
  certificate: false,
};

const stateToProps = (state) => ({
  includeToPrint: state.modules.deviceHistory.printableInformation.includeContentClearedToPrint,
});

const dispatchToProps = {
  toggleIncludeToPrint: moduleActions.printableInformation.toggleIncludeContentClearedToPrint,
};

export default connect(stateToProps, dispatchToProps)(ContentClearedServices);
