import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/programs-management/actions";
import actions from "~/actions";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [actions.programs.fetchProgramsBegin]: (state, action) => {
    state.isLoading = true;
  },
  [actions.programs.fetchProgramsSuccess]: (state, action) => {
    state.isLoading = false;
    state.items = action.payload.programs;
  },
  [actions.programs.fetchProgramsError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [actions.programs.deleteProgramBegin]: (state, action) => {
    state.isLoading = true;
    state.items = action.payload.programs;
  },
  [actions.programs.deleteProgramSuccess]: (state, action) => {
    state.isLoading = false;
  },
  [actions.programs.clearProgramsError]: (state, action) => {
    state.errors = null;
  },
  [actions.programs.deleteProgramError]: (state, action) => {
    state.items = action.payload.programs;
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
