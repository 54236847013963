import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import * as VersionManagerActions from "~/actions/VersionManager.actions";
import { Page, AsciButton, AsciSelect, AsciSpinner, SectionHeader } from "~/global";
import * as Utils from "~/global/utils";
import selectors from "~/selectors";

import "./VersionManager.component.scss";

class VersionManager extends Component {
  state = {
    versions: [],
    unmodifiedVersions: [],
    selectedVersion: { name: "", url: "", releaseNotes: "" },

    visibleCopyBtn: true,
    isLoading: true,
    linkCopied: false,
    dialog: null,
  };

  willUnmountComponent = false;

  componentWillUnmount() {
    this.willUnmountComponent = true;
  }

  async componentDidMount() {
    if (this.props.canSeeVersions) {
      const versions = await VersionManagerActions.fetchVersions();
      this.versionsReceived(versions);
    } else if (!this.willUnmountComponent) {
      this.setState({
        dialog: {
          visible: true,
          title: "Forbidden",
          width: "calc(50% - 100px)",
          content: (
            <div className="column centered">
              <p className="align-center">
                You do not have the permissions to access to this section
              </p>

              <div className="row centered">
                <AsciButton color="green" onClick={() => this.redirectTo("/home")}>
                  Go to Home
                </AsciButton>
              </div>
            </div>
          ),
        },
      });
    }
  }

  versionsReceived = (versions) => {
    if (versions != null && versions.length > 0 && !this.willUnmountComponent) {
      const unmodifiedVersions = versions.map((vrsn) => vrsn.clone());

      this.setState({
        versions,
        selectedVersion: versions.filter((version) => version.selected)[0],
        unmodifiedVersions,
        isLoading: false,
      });
    } else if (!this.willUnmountComponent) {
      this.setState({
        dialog: {
          visible: true,
          width: "calc(50% - 100px)",
          title: "Error",
          modal: true,
          content: (
            <div className="column centered">
              <div className="align-center">Request didn't complete successfully</div>

              <div className="dialog-actions layout-row layout-row--center-center">
                <AsciButton color="white" onClick={() => this.redirectTo("/home")}>
                  Exit
                </AsciButton>
              </div>
            </div>
          ),
        },
      });
    }
  };

  redirectTo = (path) => {
    this.props.history.push(path);
  };

  saveClicked = () => {
    this.setState({
      dialog: {
        visible: true,
        width: "calc(50% - 100px)",
        title: "Confirm",
        modal: true,
        content: (
          <div className="column centered">
            <div className="align-center">Are you sure you want to save all your changes?</div>

            <div className="dialog-actions layout-row layout-row--end-center">
              <AsciButton color="white" onClick={this.hideDialog}>
                Cancel
              </AsciButton>

              <AsciButton color="blue" onClick={this.saveConfirmed}>
                Save
              </AsciButton>
            </div>
          </div>
        ),
      },
    });
  };

  saveConfirmed = async () => {
    const response = await VersionManagerActions.updateVersions(this.state.selectedVersion);
    if (response != null) {
      this.setState({
        visibleCopyBtn: true,
      });
      this.hideDialog();
    }
  };

  onVersionChange = (selectedVersion) => {
    const versions = this.state.versions.map((vrsn) => vrsn.clone());

    versions.map((version) => {
      if (selectedVersion.name == version.name) {
        selectedVersion.selected = true;
        version.selected = true;
      } else {
        version.selected = false;
      }
      return version;
    });

    this.setState({
      selectedVersion,
      visibleCopyBtn: false,
      versions,
      linkCopied: false,
    });
  };

  copyToClipboard = () => {
    Utils.copyToClipboard(this.state.selectedVersion.url);

    this.setState({
      linkCopied: true,
    });
  };

  hideDialog = () => {
    this.setState({ dialog: null });
  };

  render() {
    return (
      <Page className="version-manager" dialog={this.state.dialog} onDialogHide={this.hideDialog}>
        {this.props.canSeeVersions ? (
          <>
            <SectionHeader
              title="Program Version"
              description="Select the version you want to set and share."
            >
              <AsciSelect
                id="label-format-selector"
                items={this.state.versions}
                selectedItem={this.state.selectedVersion}
                valueKey="name"
                onChange={(version) => this.onVersionChange(version)}
                disabled={
                  !this.props.canEditVersions ||
                  this.state.versions == null ||
                  this.state.versions.length <= 1
                }
              />
            </SectionHeader>

            <div className="body-wrapper grey-border">
              <div className="white-background">
                Release Notes (version <b>{this.state.selectedVersion.name}</b>)
              </div>

              <div className="release-notes-container">
                <div>
                  {(this.state.selectedVersion.releaseNotes || []).map((releaseNote, index) => (
                    <div key={`releaseNote${index}`} className="release-note">
                      {releaseNote}
                    </div>
                  ))}
                </div>
              </div>

              <div className="content-footer row-reverse">
                <div className="layout-row layout-row--start-center">
                  <p>Saving changes will set the default version</p>

                  <AsciButton
                    color="blue"
                    onClick={this.saveClicked}
                    disabled={!this.props.canEditVersions}
                  >
                    Save
                  </AsciButton>
                </div>
                {this.state.visibleCopyBtn ? (
                  <div className="share-options layout-row layout-row--start-center">
                    <AsciButton color="green" onClick={this.copyToClipboard}>
                      {this.state.linkCopied ? (
                        <div className="layout-row layout-row--center-center">
                          {" "}
                          <i className="icon-check" />
                          Link Copied
                        </div>
                      ) : (
                        <div className="copy-share">Copy to Share</div>
                      )}
                    </AsciButton>

                    <p>{this.state.selectedVersion.url}</p>
                  </div>
                ) : null}
              </div>
            </div>

            <AsciSpinner visible={this.state.isLoading} />
          </>
        ) : null}
      </Page>
    );
  }
}

const stateToProps = (state) => ({
  canSeeVersions: selectors.session.hasPermission(state, "GET", "/gql/software-versions"),
  canEditVersions: selectors.session.hasPermission(state, "PUT", "/versions/selected"),
});

export default connect(stateToProps, null)(VersionManager);
