import { createReducer } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import moduleActions from "~/programs-management/actions";

const initialState = {
  items: [],
  isLoading: false,
  errors: null,
};

export default createReducer(initialState, {
  [moduleActions.serviceSuiteTemplates.fetchServicesTemplatesBegin]: (state, action) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.serviceSuiteTemplates.fetchServicesTemplatesSuccess]: (state, action) => {
    state.items = action.payload.serviceSuiteTemplates;
    state.isLoading = false;
  },

  [moduleActions.serviceSuiteTemplates.fetchServicesTemplatesError]: (state, action) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
