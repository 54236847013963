import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import moduleActions from "~/programs-management/actions";
import { SystemPermission } from "~/entities/system-permissions/model/systemPermissionTypes";

interface State {
  items: SystemPermission[];
  isLoading: boolean;
  errors: string | null;
}

const initialState: State = {
  items: [],
  isLoading: false,
  errors: null,
};

interface FetchSystemPermissionsErrorPayload {
  errors: string;
}

export default createReducer(initialState, {
  [moduleActions.systemPermissions.fetchSystemPermissionsBegin.type]: (state: State) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.systemPermissions.fetchSystemPermissionsSuccess.type]: (
    state: State,
    action: PayloadAction<SystemPermission[]>
  ) => {
    state.items = action.payload;
    state.isLoading = false;
  },
  [moduleActions.systemPermissions.fetchSystemPermissionsError.type]: (
    state: State,
    action: PayloadAction<FetchSystemPermissionsErrorPayload>
  ) => {
    state.errors = action.payload.errors;
    state.isLoading = false;
  },
});
