import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer("workstation", "taskDetails", (taskId) =>
  WorkstationManagement.QUERY(
    WorkstationManagement.gqlBuilder(`{
        task(id: "${taskId}") {
            id,
            type,
            payload,
            result {
                status,
                data,
                updatedAt,
                updator
            }
            createdAt,
            creator,
            workstationId,
            logUrl
        }
    }`)
  ).then((response) => response.payload.data.task)
);
