import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const FailCodesIcon = ({ size, customId }) => (
  <SVGIcon title="Client Deployment" viewBox="0 0 25.45 28.33" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x1="10.45"
        x2="25.92"
        y1="4.44"
        y2="4.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.2" stopColor="#96c934" />
        <stop offset="0.36" stopColor="#8cbf37" />
        <stop offset="0.51" stopColor="#7aad3d" />
        <stop offset="0.65" stopColor="#629545" />
        <stop offset="0.78" stopColor="#437650" />
        <stop offset="0.91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x1="10.45"
        x2="25.92"
        y1="4.44"
        y2="4.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#003764" />
        <stop offset="1" stopColor="#146fab" />
      </linearGradient>
    </defs>

    <g id="Version_Number" data-name="Version Number">
      <ellipse
        fill="none"
        stroke={`url(#${customId}-b)`}
        cx="4.14"
        cy="4.44"
        className="cls-1"
        rx="3.64"
        ry="3.94"
      />
      <path
        fill="none"
        stroke={`url(#${customId}-a)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.97"
        d="M11.41 4.44h13.55"
      />
      <path
        fill="none"
        stroke={`url(#${customId}-a)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.97"
        d="M11.41 14.18h13.55"
      />
      <path
        fill="none"
        stroke={`url(#${customId}-a)`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.97"
        d="M11.41 23.93h13.55"
      />
      <path
        fill="#9c3"
        d="M5.67 6.61a.51.51 0 0 1-.37-.17L2.24 3.13a.5.5 0 0 1 0-.71.52.52 0 0 1 .72 0l3.09 3.34a.5.5 0 0 1 0 .71.52.52 0 0 1-.38.14z"
      />
      <path
        fill="#9c3"
        d="M2.61 6.61a.51.51 0 0 1-.34-.14.5.5 0 0 1 0-.71L5.3 2.44a.52.52 0 0 1 .72 0 .5.5 0 0 1 0 .71L3 6.44a.52.52 0 0 1-.39.17z"
      />
      <ellipse
        fill="none"
        stroke={`url(#${customId}-b)`}
        cx="4.14"
        cy="14.17"
        className="cls-1"
        rx="3.64"
        ry="3.94"
      />
      <path
        fill="none"
        stroke={`url(#${customId}-b)`}
        d="M2.61 12.51l3.06 3.31M5.67 12.51l-3.06 3.31"
        className="cls-6"
      />
      <ellipse
        fill="none"
        stroke={`url(#${customId}-b)`}
        cx="4.14"
        cy="23.89"
        className="cls-1"
        rx="3.64"
        ry="3.94"
      />
      <path
        fill="none"
        stroke={`url(#${customId}-b)`}
        d="M2.61 22.23l3.06 3.31M5.67 22.23l-3.06 3.31"
        className="cls-6"
      />
    </g>
  </SVGIcon>
);

FailCodesIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default FailCodesIcon;
