import { createSelector } from "@reduxjs/toolkit";

const getProcessesByServiceSuite = (state) =>
  state.modules.cannedReports.processesByServiceSuite.items;

export const getChartData = createSelector(
  [getProcessesByServiceSuite],
  (processesByServiceSuite) => {
    const chartData = processesByServiceSuite
      .map((item) => {
        const itemName = item.Facility.name;

        const itemData = item.ChartData.RawData.map((suite) => {
          const passedDevices = suite.Results.reduce((acc, result) => {
            if (result.Result === "PASS") {
              acc += result.Num;
            }

            return acc;
          }, 0);

          const failedDevices = suite.Results.reduce((acc, result) => {
            if (result.Result === "FAIL") {
              acc += result.Num;
            }

            return acc;
          }, 0);

          return {
            itemName,
            suiteName: suite.Group,
            passedDevices,
            failedDevices,
          };
        });

        return itemData;
      })
      .flat(); // TODO: try not to use flat

    return chartData;
  }
);

export const getTotalProcesses = createSelector([getChartData], (chartData) => {
  const totalProcesses = chartData.reduce(
    (acc, item) => {
      acc.combined += item.passedDevices + item.failedDevices;
      acc.passed += item.passedDevices;
      acc.failed += item.failedDevices;

      return acc;
    },
    { combined: 0, passed: 0, failed: 0 }
  );

  return totalProcesses;
});

export const getFormattedProcesses = createSelector(
  [getChartData, getTotalProcesses],
  (chartData, totalProcesses) => {
    const intervalsByFacility = chartData.reduce((acc, item, index) => {
      const facility = item.itemName;

      if (acc[facility] == null) {
        acc[facility] = {};
        acc[facility].firstTick = index + 1;
        acc[facility].lastTick = index;
        acc[facility].nbOfTicks = 0;
      }

      acc[facility].lastTick += 1;
      acc[facility].nbOfTicks += 1;

      return acc;
    }, {});

    const formattedProcesses = {
      chartData,
      totalProcesses: totalProcesses.combined,
      intervalsByFacility,
    };

    return formattedProcesses;
  }
);

/* Report summary */

export const getProcessesForReport = createSelector([getChartData], (chartData) => {
  const processesForReport = chartData.map((item) => ({
    orgName: `${item.itemName}: ${item.suiteName}`,
    passedDevices: item.passedDevices,
    failedDevices: item.failedDevices,
  }));

  return processesForReport;
});

export const getProcessesKeys = createSelector([getProcessesForReport], (processesForReport) => {
  const columns = Object.keys(processesForReport[0] || {});

  return columns;
});

export const getProcessesTitle = createSelector([getProcessesKeys], (keys) => {
  const keyNames = {
    orgName: "Organization Name",
    passedDevices: "Pass Count",
    failedDevices: "Fail Count",
  };
  return keyNames;
});
