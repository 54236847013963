import React, { Component } from "react";
import { connect } from "react-redux";
import keys from "lodash/keys";

import { Page, Tabs, Tab, AsciSpinner, DialogMessage } from "~/global";
import RemoteSessionEditor from "../RemoteSessionEditor/RemoteSessionEditor.component";
import RemoteSessionsHistory from "../RemoteSessionsHistory/RemoteSessionsHistory.component";
import actions, { RemoteDiagnosticsActions } from "~/actions";
import { RemoteDiagnosticsSession } from "~/models";
import selectors from "~/selectors";

class RemoteDiagnosticsPage extends Component {
  TAB_INDEX_SESSION = 0;
  TAB_INDEX_HISTORY = 1;

  RESULTS_UPDATER_INTERVAL_IN_MS = 5000;

  state = {
    error: null,
    isLoading: true,
    selectedSession: null,
    selectedTabIndex: this.TAB_INDEX_SESSION,
    sessionUpdater: null,
  };

  componentDidMount() {
    this.initComponent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.csid.length > 0 && prevProps.csid !== this.props.csid) {
      window.location.reload(true); // TODO: change this to avoind reloading the page. to call initComponent? Will need refactoring to work
    }
  }

  initComponent = async () => {
    let license;

    try {
      license = await actions.licenses.fetchApplicationLicense("testeditor"); // can't use with redux until we add dispatch to the action
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
        return;
      }

      this.setState({
        error: `You don't have a license to use remote diagnostics.`,
        isLoading: false,
      });

      return;
    }

    await Promise.all([this.props.fetchTestCategories(), this.props.fetchTestDefinitions()]);

    const definitionsByPlatformByClassName = this.getDefinitionsByPlatformByClassName(
      this.props.testDefinitions
    );
    const categoriesWithDefinitionsByPlatform = this.getCategoriesWithDefinitionsByPlatform(
      this.props.categories,
      definitionsByPlatformByClassName
    );
    const classNameByPlatformByIdentifier = this.getClassNameByPlatformByIdentifier(
      this.props.testDefinitions
    );

    let selectedTabIndex = this.TAB_INDEX_SESSION;
    let selectedSession;
    if (this.props.match.path === "/remote-diagnostics/:sessionId") {
      selectedTabIndex = this.TAB_INDEX_SESSION;

      try {
        selectedSession = await this.getSession(license, this.props.match.params.sessionId);
      } catch (e) {
        const response = (e || {}).json || {};
        const message = ((response.payload || [])[0] || {}).message || response.message;
        if (message) {
          this.props.showDialog(
            {
              title: "Error",
              width: "600px",
              content: (close) => (
                <DialogMessage close={close}>
                  <p>{message}</p>
                </DialogMessage>
              ),
            },
            () => this.updateUrl(selectedTabIndex)
          );
        }
        return;
      }
    }

    if (this.props.match.path === "/remote-diagnostics/history") {
      selectedTabIndex = this.TAB_INDEX_HISTORY;
    }

    this.setState(
      {
        isLoading: false,
        license,
        categories: this.props.categories,
        testDefinitions: this.props.testDefinitions,
        definitionsByPlatformByClassName,
        categoriesWithDefinitionsByPlatform,
        classNameByPlatformByIdentifier,
        selectedTabIndex,
        selectedSession,
      },
      () => {
        if (this.state.selectedSession && !this.state.selectedSession.results) {
          this.startSessionUpdater(this.state.selectedSession.sessionId);
        }
      }
    );
  };

  getCategoriesWithDefinitionsByPlatform = (categories, definitionsByPlatformByClassName) =>
    /* [{
            "classNames": ["BatteryHealthTest", "UsbPlugTest"],
            "name": "audio",
            "displayName": "Audio",
            "image": "http://images.com/audio.png",
            "definitionsByPlatform": {
                "android": [
                    {"className": "BatteryHealthTest", ...},
                    {"className": "UsbPlugTest", ...}
                ]
            }
        }, ... ] */
    categories.map((category) => ({
      ...category,

      // add the definitions for this category from this categorie's class names
      definitionsByPlatform: keys(definitionsByPlatformByClassName).reduce(
        (acc, platform) => ({
          ...acc,
          [platform]: (category.classNames || [])
            .map((className) => definitionsByPlatformByClassName[platform][className] || null)
            .reduce((acc, item) => (item == null ? acc : [...acc, ...item]), []),
        }),
        {}
      ),
    }));

  getClassNameByPlatformByIdentifier = (definitions) =>
    definitions.reduce((acc, definition) => {
      if (!acc[definition.platform]) {
        acc[definition.platform] = {};
      }

      acc[definition.platform][definition.identifier] = definition.className;

      return acc;
    }, {});

  getDefinitionsByPlatformByClassName = (definitions) => {
    const platforms = {};

    definitions.forEach((definition) => {
      // create the platform if it doesn't exist yet
      if (!platforms[definition.platform]) {
        platforms[definition.platform] = {};
      }

      // create the array of definitions for this className if it doesn't exist yet for this platform
      if (!platforms[definition.platform][definition.className]) {
        platforms[definition.platform][definition.className] = [];
      }

      platforms[definition.platform][definition.className].push(definition);
    });

    return platforms;
  };

  getSession = async (license, sessionId) =>
    RemoteDiagnosticsActions.fetchSession(license, sessionId);

  onSelectedSessionChanged = async (session) => {
    this.stopSessionUpdater();

    let selectedSession;
    if (session) {
      selectedSession = await this.getSession(this.state.license, session.sessionId);
    } else {
      selectedSession = null;
    }

    this.setState(
      {
        selectedSession,
        selectedTabIndex: this.TAB_INDEX_SESSION,
      },
      () => {
        this.updateUrl(this.TAB_INDEX_SESSION, selectedSession ? selectedSession.sessionId : null);

        if (this.state.selectedSession && !this.state.selectedSession.results) {
          this.startSessionUpdater(this.state.selectedSession.sessionId);
        }
      }
    );
  };

  startSessionUpdater = (sessionId) => {
    if (this.state.selectedSession.status !== RemoteDiagnosticsSession.SESSION_STATUS_PENDING) {
      return;
    }

    this.setState({
      sessionUpdater: setTimeout(async () => {
        const session = await this.getSession(this.state.license, sessionId);

        this.setState({ selectedSession: session }, () => {
          if (!this.state.selectedSession.results) {
            this.startSessionUpdater(this.state.selectedSession.sessionId);
          }
        });
      }, this.RESULTS_UPDATER_INTERVAL_IN_MS),
    });
  };

  stopSessionUpdater = () => {
    clearTimeout(this.state.sessionUpdater);
    this.setState({ sessionUpdater: null });
  };

  onTabChanged = (index) => {
    this.setState(
      {
        selectedTabIndex: index,
      },
      () => {
        this.updateUrl(
          index,
          this.state.selectedSession ? this.state.selectedSession.sessionId : null
        );
      }
    );
  };

  updateUrl = (tabIndex, sessionId) => {
    if (tabIndex == this.TAB_INDEX_SESSION) {
      if (sessionId) {
        this.props.history.push(`/remote-diagnostics/${sessionId}`);
      } else {
        this.props.history.push("/remote-diagnostics");
      }
    }

    if (tabIndex == this.TAB_INDEX_HISTORY) {
      this.props.history.push("/remote-diagnostics/history");
    }
  };

  render() {
    if (this.state.isLoading) {
      return <AsciSpinner visible />;
    }

    if (this.state.error) {
      return (
        <Page>
          <p>{this.state.error}</p>
        </Page>
      );
    }

    return (
      <Page>
        <Tabs
          fullWidth={false}
          onTabChanged={this.onTabChanged}
          selectedIndex={this.state.selectedTabIndex}
        >
          <Tab title={this.state.selectedSession ? "Session details" : "New session"}>
            <RemoteSessionEditor
              license={this.state.license}
              definitionsByPlatformByClassName={this.state.definitionsByPlatformByClassName}
              classNameByPlatformByIdentifier={this.state.classNameByPlatformByIdentifier}
              categoriesWithDefinitionsByPlatform={this.state.categoriesWithDefinitionsByPlatform}
              selectedSession={this.state.selectedSession}
              onSelectedSessionChanged={this.onSelectedSessionChanged}
            />
          </Tab>

          <Tab title="History">
            <RemoteSessionsHistory
              license={this.state.license}
              onSessionSelected={this.onSelectedSessionChanged}
            />
          </Tab>
        </Tabs>
      </Page>
    );
  }
}

const stateToProps = (state) => ({
  categories: selectors.testCategories.getSortedCategories(state),
  testDefinitions: state.testDefinitions.items,
  csid: state.customer.activeCustomerId,
});

const dispatchToProps = {
  showDialog: actions.dialog.show,
  fetchTestCategories: actions.testCategories.fetchTestCategories,
  fetchTestDefinitions: actions.testDefinitions.fetchTestDefinitions,
};

export default connect(stateToProps, dispatchToProps)(RemoteDiagnosticsPage);
