// TODO implement freeform layout
export const GRID = "grid";
export const FREEFORM = "freeform";

export const LAYOUTS = [FREEFORM, GRID];

export const getDefaultLayoutData = (layoutName) =>
  ({
    [GRID]: {
      numRows: 3,
      numColumns: 2,
      rowHeights: {},
    },
    [FREEFORM]: {},
  }[layoutName]);
