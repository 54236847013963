import { createAction } from "@reduxjs/toolkit";
import type { AppThunk } from "~/store";
import { CustomerManagement } from "~/services";
import type { Facility } from "./facility.actions";

export type ShortFacility = Pick<Facility, "faid" | "name" | "timezone">;
type FacilitiesResponse = { facilities: ShortFacility[] };

export const fetchFacilitiesBegin = createAction("FETCH_FACILITIES_BEGIN");
export const fetchFacilitiesSuccess = createAction<FacilitiesResponse>("FETCH_FACILITIES_SUCCESS");
export const fetchFacilitiesError = createAction<{ error: string }>("FETCH_FACILITIES_ERROR");

export const fetchFacilities =
  (isArchived = false): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const isLoadingFacilities = state.facilities.status === "loading";

    if (!isLoadingFacilities) {
      const query = CustomerManagement.gqlBuilder(`query Facilities {
      facilities(isArchived: ${isArchived.toString()}) {
        faid,
        name,
        timezone
      }
    }`);

      dispatch(fetchFacilitiesBegin());

      try {
        const response = await CustomerManagement.QUERY<FacilitiesResponse>(query);
        const facilities = response?.payload?.data?.facilities ?? [];

        dispatch(fetchFacilitiesSuccess({ facilities }));
      } catch (e: any) {
        dispatch(fetchFacilitiesError({ error: e.json as string }));
      }
    }
  };
