import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import { endOfDay, format, startOfDay, sub } from "date-fns";
import { createSearchParams, useNavigate } from "react-router-dom-v5-compat";

import { Row } from "~/global";
import { useTranslation } from "~/i18n";
import { REPORTING_SEARCH_PARAMS_VERSION } from "~/pages/reporting/model/constants";
import { useAppDispatch, useAppSelector } from "~/hooks";
import actions from "~/actions";
import selectors from "~/selectors";

const { Search } = Input;

export const DeviceSearchBox = () => {
  const { t } = useTranslation("navbar");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const facilities = useAppSelector(selectors.facilities.getFacilitiesAllOption);

  const [chosenFacilityId, setChosenFacilityId] = useState(facilities[0].faid);

  useEffect(() => {
    void dispatch(actions.facilities.fetchFacilities());
  }, [dispatch]);

  const search = (value: string) => {
    const facility = facilities.find((facility) => facility.faid === chosenFacilityId);

    if (facility != null) {
      const searchParams = createSearchParams({
        facilityId: chosenFacilityId,
        timeZone: facility.timezone,
        dateFrom: format(
          startOfDay(sub(new Date(), { months: 18, days: -1 })),
          "yyyy-MM-dd'T'HH:mm:ss"
        ),
        dateTo: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
        uniqueness: "last-in",
        keyword: value.trim(),
        reportTypeName: "processRuns",
        v: REPORTING_SEARCH_PARAMS_VERSION,
      });

      navigate(
        {
          pathname: "/reporting",
          search: searchParams.toString(),
        },
        { state: { triggerSearch: true } }
      );
    }
  };

  return (
    <Row>
      <Select
        options={facilities.map((facility) => ({ label: facility.name, value: facility.faid }))}
        value={chosenFacilityId}
        onChange={(value) => {
          setChosenFacilityId(value);
        }}
        style={{ minWidth: 120, marginRight: "0.5em" }}
      />

      <Search placeholder={t("search")} onSearch={search} style={{ marginRight: "1em" }} />
    </Row>
  );
};
