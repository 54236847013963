import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export type CsvFile = {
  _id: string;
  name: string;
  itemCount: number;
};

type UploadedCsvMetadata = {
  _id: string;
  /** ISO8601 utc timestamp */
  createdOn: string;
  /** username */
  createdBy: string;
  /** ISO8601 utc timestamp */
  modifiedOn: string;
  /** ISO8601 utc timestamp */
  expiresOn: string;
  /** filename */
  name: string;
  storagePath: string;
  size: number;
  /** auth0 userId */
  userId: string;
  /** number of identifiers for each columns */
  stats: {
    bluetoothmac: number;
    ecid: number;
    guid: number;
    imei: number;
    meid: number;
    oemserialnumber: number;
    wifimac: number;
  };
};

type InvalidCsvMetadata = {
  name: string;
  /** null when csv file is invalid */
  stats: null;
};

type APIResponse = {
  payload: UploadedCsvMetadata | InvalidCsvMetadata;
  message: string;
  success: boolean;
};

interface CsvUploadState {
  file?: CsvFile;
  status: "idle" | "loading";
  error?: string;
}

const initialState: CsvUploadState = {
  status: "idle",
};

// Thunks
export const uploadFile = createAsyncThunk<UploadedCsvMetadata, FormData, { rejectValue: string }>(
  "csvUpload/fileUploadStatus",
  async (formData, { rejectWithValue }) => {
    try {
      const response = (await CustomerManagement.POST(
        "/bulk-search-file",
        formData
      )) as APIResponse;

      if (response.payload.stats === null) {
        return rejectWithValue(`${response.payload.name} is malformed or is missing identifiers`);
      }

      return response.payload;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        type ServiceError = { json: { message?: string } };
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

// Slice
const csvUploadSlice = createSlice({
  name: "csvUpload",
  initialState,
  reducers: {
    fileCleared(draftState) {
      delete draftState.file;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(uploadFile.fulfilled, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.file = {
          _id: payload._id,
          name: payload.name,
          // sum of all stats fields
          itemCount: Object.values(payload.stats).reduce((a, b) => a + b),
        };
      })
      .addCase(uploadFile.rejected, (draftState, action) => {
        draftState.status = "idle";
        draftState.error = action.payload;
      });
  },
});

export const { fileCleared } = csvUploadSlice.actions;

export default csvUploadSlice.reducer;
