import * as auditResults from "./auditResults.selectors";
import * as auditResultsPerTest from "./auditResultsPerTest.selectors";
import * as processes from "./processes.selectors";
import * as processesByOS from "./processesByOS.selectors";
import * as processesByServiceSuite from "./processesByServiceSuite.selectors";
import * as testProcessingTimes from "./testProcessingTimes.selectors";

export default {
  auditResults,
  auditResultsPerTest,
  processes,
  processesByOS,
  processesByServiceSuite,
  testProcessingTimes,
};
