import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import head from "lodash/head";
import Dropzone from "react-dropzone";
import { InputNumber, Switch } from "antd";

import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";

const ImageFieldInspector = (props) => {
  const { setShapeFieldData, identity, width, height, data, keepAspectRatio, fitShape } = props;
  const [dropError, setDropError] = useState(null);
  const setterFactory = (key) => (data) => setShapeFieldData({ identity, key, data });

  const onDropRejected = useCallback(() => {
    setDropError("Invalid file");
  }, []);

  const onDropAccepted = useCallback((acceptedFiles) => {
    // Only one file allowed.
    const file = head(acceptedFiles);
    const reader = new FileReader();

    // Convert to a string image to be loaded in img src & saved raw in the label definition.
    // Eliminating the need for uploads.
    reader.readAsDataURL(file);
    reader.onerror = () => setDropError("File reading failed");
    reader.onload = () => setShapeFieldData({ identity, key: "data", data: reader.result });
  }, []);

  return (
    <>
      <InspectorInput label="Image">
        <Dropzone
          accept=".jpeg, .jpg, .webp, .jfif, .png"
          maxSize={200000} // 200kb max
          multiple={false}
          onDropRejected={onDropRejected}
          onDropAccepted={onDropAccepted}
        >
          {data ? (
            <img src={data} width={width} height={height} alt="" />
          ) : (
            <div className="text-centered">
              <p className="error-message">{dropError}</p>
              <p>Drop an image or click to select a file to upload.</p>
            </div>
          )}
        </Dropzone>
      </InspectorInput>
      <InspectorInput label="Auto fit">
        <Switch checked={fitShape} onChange={setterFactory("fitShape")} />
      </InspectorInput>
      <InspectorInput label="Keep Aspect Ratio">
        <Switch checked={keepAspectRatio} onChange={setterFactory("keepAspectRatio")} />
      </InspectorInput>
      <InspectorInput label="Width">
        <InputNumber value={width} min={1} onChange={setterFactory("width")} disabled={fitShape} />
      </InspectorInput>
      <InspectorInput label="Height">
        <InputNumber
          value={height}
          min={1}
          onChange={setterFactory("height")}
          disabled={keepAspectRatio || fitShape}
        />
      </InspectorInput>
    </>
  );
};

ImageFieldInspector.propTypes = {
  identity: PropTypes.number.isRequired,

  data: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  keepAspectRatio: PropTypes.bool,
  fitShape: PropTypes.bool,

  setShapeFieldData: PropTypes.func.isRequired,
};

export default ImageFieldInspector;
