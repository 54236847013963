import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer("workstation", "crashReportDetails", (crashId) =>
  WorkstationManagement.QUERY(
    WorkstationManagement.gqlBuilder(`{
        crashReport(id: "${crashId}") {
            id,
            appVersion,
            workstationId,
            sessionId,
            dumpUrl,
            createdAt,
            guid,
            platform,
            processType,
        }
    }`)
  ).then((response) => response.payload.data.crashReport)
);
