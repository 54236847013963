import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField, Button } from "react-md";
import uuid from "uuid/v4";
import { Row, Column, Checkbox } from "~/global";

import moduleActions from "~/service-suite-templates/actions/";
import ArgumentEditor from "../ArgumentEditor/ArgumentEditor.component";

import "./ServiceDetails.component.scss";

const ServiceDetails = ({
  service,
  template,
  removeService,
  saveEditedTemplate,
  editedTemplate,
}) => {
  const [serviceDetails, setServiceDetails] = useState(editedTemplate);
  const [enabledCheckbox, setEnabledCheckbox] = useState(service.isEnabled);
  const [optionalCheckbox, setOptionalCheckbox] = useState(service.isOptional);
  const [displayName, setDisplayName] = useState(service.displayName.en);
  const [argList, setArgList] = useState(service.arguments ?? []);

  useEffect(() => {
    // Create a temporary service with details as state and always keeps it up-to-date with the UI
    if (
      argList !== service.arguments ||
      displayName !== service.displayName.en ||
      enabledCheckbox !== service.isEnabled ||
      optionalCheckbox !== service.isOptional
    )
      setServiceDetails({
        ...service,
        displayName: {
          en: displayName,
        },
        isEnabled: enabledCheckbox,
        isOptional: optionalCheckbox,
        arguments: argList,
      });
  }, [argList, displayName, optionalCheckbox, enabledCheckbox]);

  useEffect(() => {
    if (serviceDetails.identifier !== undefined) {
      const newServices = template.services.map((service) => {
        if (service.identifier === serviceDetails.identifier) {
          return serviceDetails;
        }
        return service;
      });

      const newTemplate = { ...template, services: newServices };

      saveEditedTemplate(newTemplate);
    }
  }, [serviceDetails]);

  const addArgument = () => {
    const id = uuid();
    setArgList([
      ...argList,
      {
        id,
        name: "",
        type: "string",
        value: "",
      },
    ]);
  };

  // function that removes an argument for the service
  const removeArgument = (argument) => {
    const newArgList = argList.filter((arg) => argument.id !== arg.id);

    setArgList(newArgList);
  };

  // function that that updates the list of arguments of a service when changing
  const onArgumentChanged = (arg) => {
    const newArgList = argList.map((argument) => {
      if (argument.id === arg.id) {
        return arg;
      }
      return argument;
    });

    setArgList(newArgList);
  };

  const handleRemoveService = () => {
    removeService(service.id);
  };

  return (
    <div className="service-details full-width">
      <Row>
        <Column className="service-info">
          <Row className="service-className">
            <div className="className-label">className:</div>
            <div className="className-info">{service.className}</div>
          </Row>
          <Row className="displayName">
            <div className="displayName-label">displayName:</div>
            <TextField
              id="displayName-text-field"
              lineDirection="left"
              placeholder="Enter displayName"
              value={displayName}
              className="displayName-textfield"
              onChange={(value) => setDisplayName(value)}
              type="text"
              rightIcon={<i className="icon-pencil" />}
            />
          </Row>
          <Row className="isEnabled">
            <div className="isEnabled-label">isEnabled:</div>
            <Checkbox
              id={`checkbox-enabled-${service.id}`}
              isChecked={enabledCheckbox}
              onChange={(value) => setEnabledCheckbox(value)}
            />
          </Row>
          <Row className="isOptional">
            <div className="isOptional-label">isOptional:</div>
            <Checkbox
              id={`checkbox-optional-${service.id}`}
              isChecked={optionalCheckbox}
              onChange={(value) => setOptionalCheckbox(value)}
            />
          </Row>
        </Column>
        <Column className="argument-editor full-width">
          <Row>
            <div className="arguments-title">Arguments</div>
          </Row>
          <Row>
            <div className="name-label">Name</div>
            <div className="type-label">Type</div>
            <div className="value-label">Value</div>
          </Row>
          {argList.map((argument) => (
            <Row key={argument.id}>
              <Button className="remove-btn" onClick={() => removeArgument(argument)}>
                <i className="icon-x" />
              </Button>
              <ArgumentEditor argument={argument} onChange={(value) => onArgumentChanged(value)} />
            </Row>
          ))}
          <Row>
            <Button className="add-btn" onClick={() => addArgument()}>
              +
            </Button>
          </Row>
        </Column>
        <Column className="argument-editor fit-content">
          <Row>
            <Button
              className="md-btn md-btn--raised md-btn--text md-btn--raised-pressed md-pointer--hover md-text md-inline-block asci-btn red-button add-service-btn"
              onClick={() => handleRemoveService()}
            >
              <i className="icon-x" />
              &nbsp;Remove Service
            </Button>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

ServiceDetails.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  service: PropTypes.exact({
    id: PropTypes.string,
    identifier: PropTypes.string,
    className: PropTypes.string,
    displayName: PropTypes.exact({
      en: PropTypes.string,
    }),
    isOptional: PropTypes.bool,
    isEnabled: PropTypes.bool,
    arguments: PropTypes.array,
  }).isRequired,
  removeService: PropTypes.func.isRequired,

  // From the store
  saveEditedTemplate: PropTypes.func.isRequired,
  editedTemplate: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
};

const stateToProps = (state) => ({
  editedTemplate: state.modules.serviceSuiteTemplates.editedTemplate,
});

const dispatchToProps = {
  saveEditedTemplate: moduleActions.serviceSuiteTemplates.saveEditedTemplate,
};

export default connect(stateToProps, dispatchToProps)(ServiceDetails);
