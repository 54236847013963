import produce from "immer";
import React from "react";
import PropTypes from "prop-types";
import { Button, Switch } from "react-md";
import classnames from "classnames";

import { Checkbox, MarqueeText } from "~/global";
import FailCodeEditor from "./FailCodeEditor.component";

import "./FailCodesList.component.scss";

const FailCodesList = ({
  failCodes = [],
  checkedIds = [],
  selectedId,
  isEditableFailFast = true,
  onCheck,
  onSelect,
  onChange,
  onCancel,
  isLoading = false,
  showDialog,
  onUpdatePriorityGroup,
  failCodePriorityGroup,
}) => {
  const isFailCodeChecked = (id) => checkedIds.indexOf(id) > -1;

  const areAllFailCodesChecked = () =>
    failCodes.reduce((acc, failCode) => isFailCodeChecked(failCode.id) && acc, true);

  const onCheckAllToggle = () => {
    let newCheckedIds;
    let checked = [];
    let unchecked = [];

    if (checkedIds.length === failCodes.length) {
      unchecked = [...checkedIds];
      newCheckedIds = [];
    } else {
      newCheckedIds = failCodes.map((f) => f.id);
      checked = [...newCheckedIds];
    }

    onCheck(newCheckedIds, checked, unchecked);
  };

  const onCheckToggle = (failCode) => {
    const index = checkedIds.indexOf(failCode.id);
    const newCheckedIds = [...checkedIds];
    const checked = [];
    const unchecked = [];

    if (index > -1) {
      unchecked.push(failCode.id);
      newCheckedIds.splice(index, 1);
    } else {
      checked.push(failCode.id);
      newCheckedIds.push(failCode.id);
    }

    onCheck(newCheckedIds, checked, unchecked);
  };

  const selectFailcode = (failCode) => {
    if (selectedId !== failCode.id) {
      onSelect(failCode.id);
    }
  };

  const toggleFailFast = (failCode) => {
    const failCodeUpdated = {
      ...failCode,
      failFast: !failCode.failFast,
    };
    onChange(failCodeUpdated);
  };

  const toggleShowOnDesktop = (failCode) => {
    const failCodeUpdated = {
      ...failCode,
      showOnDesktop: !failCode.showOnDesktop,
    };
    onChange(failCodeUpdated);
  };

  const toggleVisibility = ({ categoryId: targetFccid, id: targetFcid }) => {
    const newPriorityGroupCategories = produce(
      failCodePriorityGroup.failCodeCategories,
      (draftCategories) => {
        const draftFailCode = draftCategories
          .find(({ fccid }) => fccid === targetFccid)
          .failCodes.find(({ fcid }) => fcid === targetFcid);
        draftFailCode.visible = !draftFailCode.visible;
      }
    );

    onUpdatePriorityGroup(newPriorityGroupCategories);
  };

  const editFailCode = () => {
    showDialog({
      title: "Edit Fail Code",
      width: "450px",
      modal: true,
      content: (close) => (
        <FailCodeEditor
          canEditFailFast={isEditableFailFast}
          onCancel={() => {
            close();
            onCancel();
          }}
          onConfirm={(failCode) => {
            close();
            onChange(failCode);
          }}
        />
      ),
    });
  };

  return (
    <div className="fail-codes-list">
      <div className="row centered subheader-column">
        <div
          className={classnames("flex--5", {
            "asci-hide": failCodes.length === 0,
          })}
        >
          <Checkbox
            id="check-all-failCodes"
            onChange={onCheckAllToggle}
            isChecked={areAllFailCodesChecked()}
            disabled={failCodes.length === 0}
          />
        </div>
        <div className="fail-code-name">Name</div>
        <div className="fail-code-description">Description</div>
        <div className="flex--30 row centered text-centered">Fail Fast</div>
        <div className="flex--30 row centered text-centered">Show on Desktop</div>
        <div className="flex--15" />
        <div className="flex--15" />
        <div className="flex--15" />
      </div>

      <div className="scroll-container flex">
        {failCodes.map((failCode, index) => {
          const isChecked = isFailCodeChecked(failCode.id);
          const isSelected = failCode.id === selectedId;
          const canEditFailFast = !(isSelected && isLoading) && isEditableFailFast;
          const canEditShowOnDesktop = !(isSelected && isLoading);
          const failCodeCategory = failCodePriorityGroup.failCodeCategories.find(
            ({ fccid }) => fccid === failCode.categoryId
          );
          let isVisible = false;
          if (failCodeCategory)
            isVisible =
              failCodeCategory.failCodes.find(({ fcid }) => fcid === failCode.id)?.visible ?? true;
          return (
            <div
              key={`fail-code-${index}`}
              className={classnames("selector-row layout-row centered failCode-row", {
                checked: isChecked,
                current: isSelected,
                hidden: !isVisible,
              })}
              onClick={() => selectFailcode(failCode)}
            >
              <div className="flex--5">
                <Checkbox
                  id={`fail-code-check-${failCode.id}`}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onCheckToggle(failCode)}
                  isChecked={isChecked}
                  disabled={isSelected && isLoading}
                />
              </div>

              <div className="fail-code-name">
                <MarqueeText text={failCode.name ?? ""} />
                <div className="subtitle">{failCode.route ? `(route:${failCode.route})` : ""}</div>
              </div>

              <div className="fail-code-description">
                <MarqueeText text={failCode.defaultDescription ?? ""} />
              </div>

              <Switch
                id={`fail-fast-switch-${failCode.id}`}
                type="switch"
                name={`fail-fast ${failCode}`}
                checked={failCode.failFast}
                onClick={(e) => e.stopPropagation()}
                onChange={() => canEditFailFast && toggleFailFast(failCode)}
                className="flex--30 centered"
                label=""
                disabled={!canEditFailFast}
              />

              <Switch
                id={`show-on--desktop-switch-${failCode.id}`}
                type="switch"
                name={`fail-fast ${failCode}`}
                checked={failCode.showOnDesktop}
                onClick={(e) => e.stopPropagation()}
                onChange={() => canEditShowOnDesktop && toggleShowOnDesktop(failCode)}
                className="flex--30 centered"
                label=""
                disabled={!canEditShowOnDesktop}
              />
              <div className="flex--15 row centered visible">
                <Button
                  icon
                  className="icon-btn"
                  iconClassName={isVisible ? "icon-eye" : "icon-eye-slash"}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleVisibility(failCode);
                  }}
                  disabled={isLoading}
                />
              </div>

              <div className="flex--15 row centered">
                <Button
                  icon
                  className="icon-btn"
                  iconClassName="icon-pencil"
                  onClick={() => editFailCode(failCode)}
                  disabled={isSelected && isLoading}
                />
              </div>

              <div className="flex--15 align-right">
                <i className="content-pointer icon-chevron-right" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FailCodesList.propTypes = {
  failCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      failFast: PropTypes.bool.isRequired,
      description: PropTypes.object.isRequired,
      showOnDesktop: PropTypes.bool.isRequired,
      route: PropTypes.string,
    })
  ),
  checkedIds: PropTypes.arrayOf(PropTypes.string),
  selectedId: PropTypes.string,
  isEditableFailFast: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  showDialog: PropTypes.func.isRequired,
  onUpdatePriorityGroup: PropTypes.func.isRequired,
  failCodePriorityGroup: PropTypes.shape({
    failCodeCategories: PropTypes.arrayOf(
      PropTypes.shape({
        fccid: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        failCodes: PropTypes.arrayOf(
          PropTypes.shape({ fcid: PropTypes.string.isRequired, visible: PropTypes.bool.isRequired })
        ).isRequired,
      }).isRequired
    ).isRequired,
  }),
};

export default FailCodesList;
