import { TableBody, TableHeader, TableColumn, TableRow, DataTable } from "react-md";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { sortBy } from "lodash/collection";
import { Column } from "~/global";
import { formatDate } from "~/global/utils";

import moduleSelectors from "~/user-logs/selectors/";
import moduleActions from "~/user-logs/actions/";

import "./UserLogsList.component.scss";

const UserLogsList = ({ userActivityData, clearLogs }) => {
  const isEmpty = userActivityData.length === 0;

  const [sortKey, setSortKey] = useState("");
  const [sortReverse, setSortReverse] = useState(false);
  const [logsSorted, setLogsSorted] = useState([]);

  useEffect(
    () => () => {
      clearLogs();
    },
    []
  );

  useEffect(() => {
    if (userActivityData.length > 0) {
      sortList();
    } else {
      setLogsSorted([]);
    }
  }, [userActivityData]);

  useEffect(() => {
    sortList();
  }, [sortKey]);

  useEffect(() => {
    const newSortedLogs = [].concat(logsSorted).reverse();
    setLogsSorted(newSortedLogs);
  }, [sortReverse]);

  const getColHeaderClasses = (colKey) => {
    const classes = ["sortable"];
    const sorted = sortKey == colKey;

    if (sorted) {
      classes.push("is-sorted");
    }
    if (sortReverse) {
      classes.push("sort-reverse");
    }

    return classes.join(" ");
  };

  const setSort = (key) => {
    key == sortKey ? setSortReverse(!sortReverse) : setSortReverse(sortReverse);
    setSortKey(key);
  };

  function sortList() {
    let newSortedLogs = [].concat(userActivityData);
    newSortedLogs = sortBy(newSortedLogs, (userActivityData) => {
      const value = userActivityData[sortKey];
      if (typeof value === "string") {
        return value;
      }
      return value == null ? "" : value;
    });
    setLogsSorted(newSortedLogs);
  }

  return (
    <Column className="user-logs-list">
      <DataTable className="logs-table" plain>
        {!isEmpty ? (
          <TableHeader className="logs-table-header">
            <TableRow>
              <TableColumn className={getColHeaderClasses("user")} onClick={() => setSort("user")}>
                User
              </TableColumn>

              <TableColumn
                className={getColHeaderClasses("eventTime")}
                onClick={() => setSort("eventTime")}
              >
                Event Time
              </TableColumn>

              <TableColumn
                className={getColHeaderClasses("module")}
                onClick={() => setSort("module")}
              >
                Module
              </TableColumn>

              <TableColumn
                className={getColHeaderClasses("action")}
                onClick={() => setSort("action")}
              >
                Action
              </TableColumn>
            </TableRow>
          </TableHeader>
        ) : null}

        <TableBody className="logs-table-body">
          {isEmpty ? (
            <TableRow>
              <TableColumn colSpan="5" className="text-centered">
                No data to display
              </TableColumn>
            </TableRow>
          ) : (
            logsSorted.map((log, index) => (
              <TableRow key={index}>
                <TableColumn style={{ minWidth: "200px" }}>{log.user}</TableColumn>

                <TableColumn className="text-left" style={{ minWidth: "200px" }}>
                  {formatDate(log.eventTime)}
                </TableColumn>

                <TableColumn style={{ minWidth: "200px" }}>{log.module}</TableColumn>

                <TableColumn style={{ minWidth: "200px" }}>{log.action}</TableColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </DataTable>
    </Column>
  );
};

UserLogsList.propTypes = {};

UserLogsList.defaultProps = {
  isLoading: false,
};

const stateToProps = (state) => ({
  userActivityData: moduleSelectors.userLogs.getUserActivityData(state),
  formattedModules: moduleSelectors.userLogs.getFormattedModules(state),
  formattedActions: moduleSelectors.userLogs.getFormattedActions(state),
});

const dispatchToProps = {
  clearLogs: moduleActions.userActivityData.clearLogs,
};

export default connect(stateToProps, dispatchToProps)(UserLogsList);
