import { combineReducers } from "@reduxjs/toolkit";
import customerList from "./customerList.reducer";
import customerEditor from "./customerEditor.reducer";
import customerIdentity from "./customerIdentity.reducer";
import customerFacilities from "./customerFacilities.reducer";
import customerFacilityEditor from "./customerFacilityEditor.reducer";
import customerUsers from "./customerUsers.reducer";
import customerUserEditor from "./customerUserEditor.reducer";
import customerTeams from "./customerTeams.reducer";
import customerTeamEditor from "./customerTeamEditor.reducer";
import customerWizard from "./customerWizard.reducer";

export default combineReducers({
  customerList,
  customerEditor,
  customerIdentity,
  customerFacilities,
  customerFacilityEditor,
  customerUsers,
  customerUserEditor,
  customerTeams,
  customerTeamEditor,
  customerWizard,
});
