import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const CustomFieldsEditorIcon = ({ size, customId }) => {
  const gradientId = `${customId}-a`;
  return (
    <SVGIcon title="Custom Fields" viewBox="0 0 29.09 19.3" size={size}>
      <defs>
        <linearGradient
          id={gradientId}
          y1="9.65"
          x2="29.09"
          y2="9.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9c3" />
          <stop offset="0.62" stopColor="#386b53" />
          <stop offset="1" stopColor="#036" />
        </linearGradient>
      </defs>
      <g>
        <path d="M3.4 7.48h1.42v4.29H3.4z" fill="#93d500" />
        <path
          d="M29.09 4H20v1h8v9.29H17.51V4a2.78 2.78 0 0 1 2.59-2.58V0a4.18 4.18 0 0 0-3.3 1.87A4.18 4.18 0 0 0 13.52 0v1.39A2.78 2.78 0 0 1 16.11 4H0v11.28h14v-1H1V5h15.13v10.26a2.79 2.79 0 0 1-2.6 2.69v1.35a4.21 4.21 0 0 0 3.3-1.87 4.21 4.21 0 0 0 3.3 1.87v-1.39a2.8 2.8 0 0 1-2.6-2.63h11.56z"
          fill={`url(#${gradientId})`}
        />
      </g>
    </SVGIcon>
  );
};

CustomFieldsEditorIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default CustomFieldsEditorIcon;
