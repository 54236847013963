import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontIcon } from "react-md";
import uniq from "lodash/uniq";

import { TestDefinitionIcon, Checkbox, TestClassRow } from "~/global";

import "./CategoryTestsSelector.component.scss";

const CategoryTestsSelector = ({
  closedByDefault,
  selectedIdentifiers,
  image,
  displayName,
  name,
  testDefinitions,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(!closedByDefault);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getSelectedIdentifier = (definitions) =>
    definitions
      .map((d) => d.identifier)
      .filter((identifier) => selectedIdentifiers.includes(identifier))[0];

  const onSelectedIdentifierChanged = (row, definition) => {
    // find existing definition for selected test class
    const existingDefinition = row.definitions.filter((def) =>
      selectedIdentifiers.includes(def.identifier)
    )[0];

    const index = selectedIdentifiers.indexOf(existingDefinition.identifier);
    const newSelectedIdentifiers = [...selectedIdentifiers];

    if (index >= 0) {
      // if this test class was already selected, just replace the existing identifier with the new one (so that the order doesn't change)
      newSelectedIdentifiers[index] = definition.identifier;
    } else {
      newSelectedIdentifiers.push(definition.identifier);
    }

    onChange(newSelectedIdentifiers, name);
  };

  const onTestClassToggled = (row, isChecked) => {
    const newSelectedIdentifiers = [...selectedIdentifiers];

    if (isChecked) {
      // enable the first identifier in the list
      newSelectedIdentifiers.push(row.definitions[0].identifier);
    } else {
      // find existing identifier in the list
      const selectedDefinition = row.definitions.filter((def) =>
        selectedIdentifiers.includes(def.identifier)
      )[0];

      const index = selectedIdentifiers.indexOf(selectedDefinition.identifier);

      // remove it from the list
      newSelectedIdentifiers.splice(index, 1);
    }

    onChange(newSelectedIdentifiers, name);
  };

  const areAllTestsSelected = () => {
    const classNames = uniq(testDefinitions.map((d) => d.className));

    return classNames.length == selectedIdentifiers.length;
  };

  const onToggleAll = (rows) => {
    let newSelectedIdentifiers;

    if (selectedIdentifiers.length === rows.length) {
      // all are selected, unselect all
      newSelectedIdentifiers = [];
    } else if (selectedIdentifiers.length === 0) {
      // none are selected, select all
      newSelectedIdentifiers = rows.map((row) => row.definitions[0].identifier);
    } else {
      // some are selected, select all others
      const unselectedRowsIdentifiers = rows
        .filter((row) => !isRowSelected(row))
        .map((row) => row.definitions[0].identifier);

      newSelectedIdentifiers = [...selectedIdentifiers, ...unselectedRowsIdentifiers];
    }

    onChange(newSelectedIdentifiers, name);
  };

  const isRowSelected = (row) =>
    row.definitions.filter((def) => selectedIdentifiers.includes(def.identifier)).length > 0;

  const getSelectedDefinition = (row, selectedIdentifier) =>
    row.definitions.filter((d) => d.identifier == selectedIdentifier)[0] || row.definitions[0];

  const rowByClassName = testDefinitions.reduce((acc, item) => {
    if (!acc[item.className]) {
      acc[item.className] = {
        definitions: [],
      };
    }

    // all those fields should be the same for all different identifiers (not editable)
    acc[item.className].className = item.className;
    acc[item.className].displayName = item.displayName;
    acc[item.className].image = item.image;
    acc[item.className].description = item.description;
    acc[item.className].definitions.push(item);

    return acc;
  }, {});

  const rows = Object.keys(rowByClassName)
    .map((className) => ({
      ...rowByClassName[className],
      className,
    }))
    .sort((a, b) => {
      if (a.displayName < b.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      return 0;
    });

  return (
    <div className={classnames("category-tests-selector", { open: isOpen })}>
      <header className="layout-row layout-row--space-between-center" onClick={toggle}>
        <div className="layout-row layout-row--start-center">
          <TestDefinitionIcon src={image} isCategory />

          <b className="margin-h--10">{displayName}</b>

          {selectedIdentifiers.length > 0 ? (
            <span style={{ fontSize: "0.9em" }}>({selectedIdentifiers.length} selected)</span>
          ) : null}
        </div>

        {testDefinitions.length > 0 ? <FontIcon iconClassName="icon-chevron-left" /> : null}
      </header>

      {isOpen ? (
        <>
          <div className="columns-header layout-row layout-row--start-center">
            <div className="test">Test</div>
            <div className="description flex">Description</div>
            <div className="select layout-column layout-column--center-center">
              <Checkbox
                id={`checkbox-select-all-${name}`}
                isChecked={areAllTestsSelected()}
                onChange={() => onToggleAll(rows)}
              />
            </div>
          </div>

          <div className="tests">
            {rows.map((row) => {
              const selectedIdentifier = getSelectedIdentifier(row.definitions);

              return (
                <TestClassRow
                  key={row.className}
                  isSelected={selectedIdentifier != null}
                  onToggled={(isSelected) => onTestClassToggled(row, isSelected)}
                >
                  <TestClassRow.Icon image={row.image} />
                  <TestClassRow.Identifier
                    displayName={row.displayName}
                    className={row.className}
                    fixedWidth
                    testDefinitions={row.definitions}
                    onSelectedIdentifierChanged={(definition) =>
                      onSelectedIdentifierChanged(row, definition)
                    }
                    selectedDefinition={getSelectedDefinition(row, selectedIdentifier)}
                    canChangeIdentifier={selectedIdentifier != null}
                  />
                  <TestClassRow.Description description={row.description} />
                  <TestClassRow.Toggle
                    className={row.className}
                    isChecked={selectedIdentifier != null}
                    onChecked={(isSelected) => onTestClassToggled(row, isSelected)}
                  />
                </TestClassRow>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

CategoryTestsSelector.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.string,
  image: PropTypes.string,
  closedByDefault: PropTypes.bool,
  testDefinitions: PropTypes.arrayOf(PropTypes.object),
  selectedIdentifiers: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

CategoryTestsSelector.defaultProps = {
  testDefinitions: [],
  selectedIdentifiers: [],
  name: "",
  displayName: "",
  image: "",
  closedByDefault: false,
};

export default CategoryTestsSelector;
