import React from "react";

import ClientDeploymentIcon from "./AppIcons/ClientDeploymentIcon.component";
import CustomerManagerIcon from "./AppIcons/CustomerManagerIcon.component";
import CustomFieldsEditorIcon from "./AppIcons/CustomFieldsEditorIcon.component";
import DashboardIcon from "./AppIcons/DashboardIcon.component";
import DataImportIcon from "./AppIcons/DataImportIcon.component";
import DevelopersZoneIcon from "./AppIcons/DevelopersZoneIcon.component";
import DeviceHistoryIcon from "./AppIcons/DeviceHistoryIcon.component";
import DocumentationTrainingIcon from "./AppIcons/DocumentationTrainingIcon.component";
import { ModelDescriptorsIcon } from "./AppIcons/ModelDescriptorsIcon";
import LabelDesignerIcon from "./AppIcons/LabelDesignerIcon.component";
import ProgramManagementIcon from "./AppIcons/ProgramManagementIcon.component";
import RemoteDiagnosticsIcon from "./AppIcons/RemoteDiagnosticsIcon.component";
import ReportingIcon from "./AppIcons/ReportingIcon.component";
import TestDefinitionsIcon from "./AppIcons/TestDefinitionsIcon.component";
import TestSuiteManagementIcon from "./AppIcons/TestSuiteManagementIcon.component";
import VersionManagerIcon from "./AppIcons/VersionManagerIcon.component";
import FailCodesIcon from "./AppIcons/FailCodesIcon.component";
import WorkstationManagementIcon from "./AppIcons/WorkstationManagementIcon.component";
import UserActionIcon from "./AppIcons/UserActionIcon.component";
import ServiceSuiteTemplateIcon from "./AppIcons/ServiceSuiteTemplateIcon.component";
import { SkuGroupsIcon } from "./AppIcons/SkuGroupsIcon";
import { SkuManagerIcon } from "./AppIcons/SkuManagerIcon";
import { InventoryIcon } from "./AppIcons/InventoryIcon.component";

import "./AppButton.component.scss";

type AppName =
  | "label-designer"
  | "client-deployment"
  | "remote-diagnostics"
  | "customer-manager"
  | "test-suites-management"
  | "version-manager"
  | "documentation-training"
  | "test-configurations"
  | "program-management"
  | "device-history"
  | "custom-fields-editor"
  | "developers-zone"
  | "reporting"
  | "dashboard"
  | "fail-codes"
  | "analytics"
  | "user-logs"
  | "data-import"
  | "service-suite-templates"
  | "workstations"
  | "inventory";

const AppButton = ({
  appName,
  buttonId,
  size,
}: {
  appName: AppName;
  buttonId: string;
  size: number;
}) => {
  const getButtonComponent = (appName: AppName) =>
    ({
      "label-designer": LabelDesignerIcon,
      "client-deployment": ClientDeploymentIcon,
      "remote-diagnostics": RemoteDiagnosticsIcon,
      "customer-manager": CustomerManagerIcon,
      "test-suites-management": TestSuiteManagementIcon,
      "version-manager": VersionManagerIcon,
      "documentation-training": DocumentationTrainingIcon,
      "test-configurations": TestDefinitionsIcon,
      "program-management": ProgramManagementIcon,
      "device-history": DeviceHistoryIcon,
      "custom-fields-editor": CustomFieldsEditorIcon,
      "developers-zone": DevelopersZoneIcon,
      reporting: ReportingIcon,
      dashboard: DashboardIcon,
      "sku-groups": SkuGroupsIcon,
      "sku-manager": SkuManagerIcon,
      "fail-codes": FailCodesIcon,
      "model-descriptors": ModelDescriptorsIcon,
      analytics: DashboardIcon,
      "user-logs": UserActionIcon,
      "data-import": DataImportIcon,
      "service-suite-templates": ServiceSuiteTemplateIcon,
      workstations: WorkstationManagementIcon,
      inventory: InventoryIcon,
    }[appName] || null);

  const customId = appName + buttonId;
  const ButtonComponent = getButtonComponent(appName);

  return ButtonComponent && <ButtonComponent size={size} customId={customId} />;
};

export default AppButton;
