import { useDebugValue, useEffect, useRef, useState } from "react";

import { Pagination } from "./usePagination";

type Filters = Record<string, string[] | null>;

// TODO: support the table sorter

/**
 * Handle table pagination and filters.
 * @param onChangeCallback - This callback must be memoized with useCallback to avoid running it on
 * every render.
 */
export const useTablePagination = (
  onChangeCallback: (pagination: Pagination, filters: Filters) => void,
  initialPagination: Pagination = { page: 1, pageSize: 10 },
  initialFilters: Filters = {}
) => {
  // The pageSize and filters are stored in both a state and a ref:
  // - The state will ensure that a rerender will happen when it is updated
  // - The ref makes it possible to access it in an effect hook without re-running the effect when it changes
  const [pagination, setPagination] = useState(initialPagination);
  const [filters, setFilters] = useState(initialFilters);

  const pageSizeRef = useRef(pagination.pageSize);
  const filtersRef = useRef(filters);

  useEffect(() => {
    pageSizeRef.current = pagination.pageSize;
  }, [pagination.pageSize]);

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  // Refresh the pagination:
  // - Reset the current page to 1, but keep the page size, when the callback changes
  // - Changes to the filters automatically reset the page which is why we don't want them in the dependency array
  useEffect(() => {
    const pagination = { page: 1, pageSize: pageSizeRef.current };

    setPagination(pagination);
    onChangeCallback(pagination, filtersRef.current);
  }, [onChangeCallback]);

  const changeTable = (
    { current, pageSize }: { current: number; pageSize: number },
    filters: Filters
  ) => {
    const pagination = { page: current, pageSize };

    setPagination(pagination);
    setFilters(filters);
    onChangeCallback(pagination, filters);
  };

  useDebugValue(pagination);
  useDebugValue(filters);

  return { page: pagination.page, pageSize: pagination.pageSize, filters, changeTable };
};
