// Always or never (disabled)
export const Always = "Always";
export const Never = "Never";

// Recording fields comparisons
export const RecordingFieldCompare = "RecordingFieldCompare";
export const TestResultCompare = "TestResultCompare";

// Process results
export const IsPass = "IsPass";
export const IsFail = "IsFail";

export const metadata = {
  [Always]: {
    defaultData: {},
    label: "Always",
  },
  [Never]: {
    defaultData: {},
    label: "Never",
  },
  [RecordingFieldCompare]: {
    defaultData: {
      field: null,
      compareType: "EQUALS",
      value: "",
    },
    label: "Compare recording field",
  },
  [TestResultCompare]: {
    defaultData: {
      testIdentifier: "",
      status: "",
    },
    label: "Test result compare",
  },
  [IsPass]: {
    defaultData: {},
    label: "Process passed",
  },
  [IsFail]: {
    defaultData: {},
    label: "Process failed",
  },
};

export const allConditions = [
  Always,
  Never,
  RecordingFieldCompare,
  TestResultCompare,
  IsPass,
  IsFail,
];

export const serviceConditions = [RecordingFieldCompare, Always, Never];

export const completedConditions = [
  IsFail,
  IsPass,
  RecordingFieldCompare,
  TestResultCompare,
  Always,
  Never,
];

export const getDefaultConditionData = (conditionType) => ({
  ...metadata[conditionType].defaultData,
});
