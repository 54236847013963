import { LicenseManagement } from "~/services";

let _cachedLicense;

export async function fetchApplicationLicense(applicationName) {
  if (_cachedLicense) {
    return _cachedLicense;
  }

  const response = await LicenseManagement.GET(`/license/${applicationName}`);
  return response.payload;
}

export async function fetchLicenses(csid) {
  const response = await LicenseManagement.GET(`/licenses?csid=${csid}`);
  return response.payload || response.message;
}
