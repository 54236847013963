import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";

const getCustomerFacilities = (state) => state.modules.customerManagement.customerFacilities.items;
const getCustomerFacilityOriginal = (state) =>
  state.modules.customerManagement.customerFacilityEditor.original;
const getCustomerFacilityModified = (state) =>
  state.modules.customerManagement.customerFacilityEditor.modifications;
const getSortReverse = (state) => state.modules.customerManagement.customerFacilities.sortReverse;
const getSortKey = (state) => state.modules.customerManagement.customerFacilities.sortKey;

export const getUpdatedCustomerFacility = createSelector(
  [getCustomerFacilityOriginal, getCustomerFacilityModified],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getSortedFacilities = createSelector(
  [getCustomerFacilities, getSortReverse, getSortKey],
  (facilities, sortReverse, sortKey) => {
    const sortedFacilities = sortBy(facilities, (facility) => {
      const value = facility[sortKey];
      return value == null ? "" : typeof value === "string" ? value.toLowerCase() : value;
    });
    return sortReverse ? sortedFacilities.reverse() : sortedFacilities;
  }
);

export const getSortedFacilitiesAllOption = createSelector([getSortedFacilities], (facilities) => {
  const allOption = {
    faid: "all",
    name: "All Facilities",
  };
  const allFacilities = [].concat(allOption, facilities);
  return allFacilities;
});

export const getFacilitiesById = createSelector(
  [getCustomerFacilities],
  (customerFacilities = []) => {
    const facilitiesById = customerFacilities.reduce(
      (acc, facility) => ({
        ...acc,
        [facility.faid]: facility,
      }),
      {}
    );
    return facilitiesById;
  }
);

export const getFacilitiesByIdAllOption = createSelector(
  [getSortedFacilitiesAllOption],
  (customerFacilities = []) => {
    const facilitiesById = customerFacilities.reduce(
      (acc, facility) => ({
        ...acc,
        [facility.faid]: facility,
      }),
      {}
    );
    return facilitiesById;
  }
);

export const getUpdatedFacilitiesById = createSelector(
  [getFacilitiesById, getUpdatedCustomerFacility],
  (facilitiesById, updtedFacility) =>
    updtedFacility.faid != null
      ? {
          ...facilitiesById,
          [updtedFacility.faid]: updtedFacility,
        }
      : { ...facilitiesById }
);

export const getFacilitiesListAllOption = createSelector(
  [getFacilitiesByIdAllOption],
  (updatedFacilitiesById) => {
    const updatedFacilities = Object.keys(updatedFacilitiesById).filter(
      (facilityId) => facilityId != null
    );
    return updatedFacilities;
  }
);

export const getUpdatedFacilities = createSelector(
  [getUpdatedFacilitiesById],
  (updatedFacilitiesById) => {
    const updatedFacilities = Object.keys(updatedFacilitiesById).map(
      (faid) => updatedFacilitiesById[faid]
    );
    return updatedFacilities;
  }
);
