import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message, notification } from "antd";
import { z } from "zod";

import { Row, Column, AsciButton } from "~/global";
import moduleActions from "../../actions";

import "./ConfirmationDialog.component.scss";

const ConfirmationDialog = ({ close, template, affectedPrograms, updateTemplate }) => {
  const currentAffectedPrograms = affectedPrograms[template.identifier] ?? [];
  const confirmationDescription = `The changes to ${template.identifier} will affect the following programs :`;

  const onUpdateTemplate = async () => {
    const templateSchema = z.object({
      identifier: z.string(),
      services: z.array(
        z.object({
          identifier: z.string(),
          className: z.string(),
          isOptional: z.boolean(),
          isEnabled: z.boolean(),
          displayName: z.object({
            en: z.string(),
          }),
          arguments: z.array(
            z.object({
              name: z.string().min(1, { message: "Argument name is required" }),
              type: z.string(),
              value: z.string(),
            })
          ),
        })
      ),
    });

    const result = templateSchema.safeParse(template);

    if (!result.success) {
      notification.error({
        message: "Validation error",
        description: (
          <ul>
            {result.error.issues.map(({ message, path }) => {
              const pathStr = path.join(".");

              // eslint-disable-next-line react/jsx-no-literals
              return <li key={pathStr}>{`${pathStr}: ${message}`}</li>;
            })}
          </ul>
        ),
        duration: 0,
      });

      close();
      return;
    }

    await updateTemplate(template);
    message.success(`${template.identifier} as been successfully updated`);
    close();
  };

  return (
    <div className="confirmation-dialog">
      <Column className="full-width">
        <Column>
          <Row className="description-text">{confirmationDescription}</Row>
          {currentAffectedPrograms.length === 0 ? (
            <Row className="affected-csid">
              <div className="csid-label">No Programs affected</div>
            </Row>
          ) : (
            currentAffectedPrograms.map((affectedProgram) => (
              <>
                <Row className="affected-csid">
                  <div className="csid-label">CSID:</div>
                  <div className="csid-programs">{affectedProgram._id}</div>
                </Row>

                <ProgramsIdentifiers affectedProgram={affectedProgram} />
              </>
            ))
          )}
        </Column>

        <Row className="btn-group full-width">
          <AsciButton className="confirm-btn" color="green" onClick={() => onUpdateTemplate()}>
            Save
          </AsciButton>
          <AsciButton className="cancel-btn" color="white" onClick={() => close()}>
            Cancel
          </AsciButton>
        </Row>
      </Column>
    </div>
  );
};

const ProgramsIdentifiers = ({ affectedProgram }) =>
  affectedProgram.identifiers.map((identifier) => (
    <Row key={identifier} className="identifier-row">
      <div className="icon-circle" />
      <div className="identifier-value">{identifier}</div>
    </Row>
  ));

ConfirmationDialog.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,

  // From the store
  affectedPrograms: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  affectedPrograms: state.modules.serviceSuiteTemplates.affectedPrograms,
});

const dispatchToProps = {
  updateTemplate: moduleActions.serviceSuiteTemplates.updateTemplate,
};

export default connect(stateToProps, dispatchToProps)(ConfirmationDialog);
