import { createReducer } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import { omit } from "lodash/object";
import moduleActions from "../actions";

const initialState = {
  original: {},
  modifications: {},
  errors: null,
  isLoading: false,
  message: null,
};

export default createReducer(initialState, {
  [moduleActions.customFieldEditor.fetchCustomFieldBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.customFieldEditor.fetchCustomFieldSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.customField;
    state.responseMessage = "";
  },
  [moduleActions.customFieldEditor.fetchCustomFieldError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.customFieldEditor.initCustomFieldSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.customField;
  },
  [moduleActions.customFieldEditor.updateCustomField]: (state, { payload }) => {
    const update = payload.customField;

    Object.entries(update).forEach(([key, value]) => {
      if (state.original[key] === value) {
        delete state.modifications[key];
      } else {
        state.modifications[key] = value;
      }
    });
  },
  [moduleActions.customFieldEditor.resetCustomField]: (state, action) => {
    state.modifications = {};
    state.errors = null;
    state.message = null;
  },

  [moduleActions.customFieldEditor.editCustomFieldBegin]: (state, action) => {
    state.errors = null;
    state.message = null;
  },
  [moduleActions.customFieldEditor.editCustomFieldSuccess]: (state, action) => {
    state.message = action.payload.message;
    state.original = { ...state.original, ...state.modifications };
    state.modifications = {};
  },
  [moduleActions.customFieldEditor.editCustomFieldError]: (state, action) => {
    state.errors = action.payload.errors;
    state.message = action.payload.errors;
  },

  [moduleActions.customFieldEditor.createCustomFieldBegin]: (state, action) => {
    state.errors = null;
    state.message = null;
  },
  [moduleActions.customFieldEditor.createCustomFieldSuccess]: (state, action) => {
    state.message = action.payload.message;
    state.original = { ...state.original, ...state.modifications };
    state.modifications = {};
  },
  [moduleActions.customFieldEditor.createCustomFieldError]: (state, action) => {
    state.errors = action.payload.errors;
    state.message = action.payload.errors;
  },
});
