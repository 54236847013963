import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";

import { FontIcon } from "react-md";
import BarcodeFieldInspector from "./fields/BarcodeFieldInspector";
import ImageFieldInspector from "./fields/ImageFieldInspector";
import RecordingFieldInspector from "./fields/RecordingFieldInspector";
import TextFieldInspector from "./fields/TextFieldInspector";
import QrCodeFieldInspector from "./fields/QrCodeFieldInspector";

import InspectorInput from "./commons/InspectorInput";
import InspectorSection from "./commons/InspectorSection";

import { BARCODE, FIELD_TYPES, IMAGE, RECORDING, TEXT, QRCODE } from "~/label-printer/fields";
import labelActions from "~/label-printer/actions";
import GroupedButtons from "~/label-printer/components/designer/inspector/commons/GroupedButtons";

const QRIcon = () => <QRCode value="AssetScience" size={20} />;

const FieldInspector = (props) => {
  const { identity, fieldType, fieldData, setShapeFieldType, setShapeFieldData } = props;

  const content = useMemo(() => {
    const fieldProps = { identity, setShapeFieldData, ...fieldData };

    switch (fieldType) {
      case RECORDING:
        return <RecordingFieldInspector {...fieldProps} />;
      case TEXT:
        return <TextFieldInspector {...fieldProps} />;
      case IMAGE:
        return <ImageFieldInspector {...fieldProps} />;
      case BARCODE:
        return <BarcodeFieldInspector {...fieldProps} />;
      case QRCODE:
        return <QrCodeFieldInspector {...fieldProps} />;
      default:
        return null;
    }
  }, [fieldType, fieldData, identity]);

  return (
    <InspectorSection title="Field" level={1}>
      <InspectorInput label="Field Type">
        <GroupedButtons
          options={[
            {
              value: TEXT,
              label: <FontIcon iconClassName="icon-text-color" size={20} />,
              title: "Text",
            },
            {
              value: RECORDING,
              label: <FontIcon iconClassName="icon-pilcrow" size={20} />,
              title: "Display value from the recording data.",
            },
            {
              value: BARCODE,
              label: <FontIcon iconClassName="icon-barcode" size={20} />,
              title: "Barcode",
            },
            { value: QRCODE, label: <QRIcon />, title: "QR Code" },
            {
              value: IMAGE,
              label: <FontIcon iconClassName="icon-image" size={20} />,
              title: "Image",
            },
          ]}
          selected={[fieldType]}
          onSelectOption={(value) => setShapeFieldType({ identity, fieldType: value })}
        />
      </InspectorInput>
      {content}
    </InspectorSection>
  );
};

FieldInspector.propTypes = {
  identity: PropTypes.number,
  fieldType: PropTypes.oneOf(FIELD_TYPES),
  fieldData: PropTypes.object,

  setShapeFieldType: PropTypes.func,
  setShapeFieldData: PropTypes.func,
};

const dispatchToProps = {
  setShapeFieldType: labelActions.labelDesigner.setShapeFieldType,
  setShapeFieldData: labelActions.labelDesigner.setShapeFieldData,
};

export default connect(null, dispatchToProps)(FieldInspector);
