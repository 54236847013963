import { createReducer } from "@reduxjs/toolkit";

import { showDialog, hideDialog, setDialogTitle } from "~/actions/dialog.actions";

const initialState = {
  settings: null,
  callback: null,
};

export default createReducer(initialState, {
  [showDialog]: (state, action) => {
    state.settings = action.payload.dialog;
    state.callback = action.payload.callback || null;
  },
  [hideDialog]: (state, action) => {
    state.settings = null;
    state.callback = null;
  },
  [setDialogTitle]: (state, action) => {
    state.settings.title = action.payload;
  },
});
