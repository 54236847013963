/**
* @ngdoc component
* @name AsciTableSortColumn
*
* @description
* Add text with sort icon
* When clicking it, change sort of injected array
*
*
* @example
   <AsciTableSortColumn
        options={{
            array: this.state.initialDataCopy,
            callback: this.callbackSort.bind(this)
            sortColumn: "name",
            text: "Operator Level",
        }}
    />

* @param {array} (Required) Array to be sorted. THIS IS A COPY OF ORIGINAL DATA that will be updated
*    Need to be a copy of the original array because it will be modified.
*   TODO: make the copy inside this component instead.
*
* @param {callback} (Required) function that will apply the changes to the original array
*
*      callbackSort(sortedArray) {
*           this.setState({
*               example: sortedArray
*           });
*       }
*
* @param {sortColumn} (Required) Type Strings. Key for the column to be sorted
*
*
* @param {text} (Required) Type Strings. Text to be showed
*
*
* @returns {Array} New array filtered from the input text
*/

import React, { Component } from "react";
import { FontIcon } from "react-md";
import PropTypes from "prop-types";
import { sortBy } from "lodash/collection";

import "./AsciTableSortColumn.component.scss";

export default class AsciTableSortColumn extends Component {
  static propTypes = {
    options: PropTypes.object,
  };

  state = {
    ascending: true,
  };

  toggleSort = () => {
    const asc = this.state.ascending;
    const key = this.props.options.sortColumn;

    let sorted = sortBy(this.props.options.array, (i) => {
      if (typeof i[key] === "string") {
        // need to lowercase strings otherwise uppercase letters will always be before lowercase letters
        return i[key].toLowerCase();
      }
      return i[key];
    });

    if (!asc) {
      sorted = sorted.reverse();
    }

    this.setState({
      ascending: !asc,
    });

    this.props.options.callback(sorted, key);
  };

  render() {
    let sortableIcon;

    if (this.props.options.text.length > 0) {
      sortableIcon = (
        <FontIcon iconClassName={`asci-sort-icon icon-sort ascending-${this.state.ascending}`} />
      );
    }

    return (
      <div onClick={this.toggleSort} className="asci-sortable-column">
        {sortableIcon}
        <span> {this.props.options.text} </span>
      </div>
    );
  }
}
