import * as applications from "./applications.selectors";
import * as audits from "./audits.selectors";
import * as countries from "./countries.selectors";
import * as customFields from "./customFields.selectors";
import * as facilities from "./facilities.selectors";
import * as facility from "./facility.selectors";
import * as failCodes from "./failCodes.selectors";
import * as filters from "./filters.selectors";
import * as languages from "./languages.selectors";
import * as platforms from "./platforms.selectors";
import * as programs from "./programs.selectors";
import * as reports from "./reports.selectors";
import * as session from "./session.selectors";
import * as testCategories from "./testCategories.selectors";
import * as testDefinitions from "./testDefinitions.selectors";
import * as tests from "./tests.selectors";
import * as testSuites from "~/test-suites-management/selectors/testSuites.selectors";
import * as customers from "./customers.selectors";

export default {
  applications,
  audits,
  countries,
  customFields,
  customers,
  facilities,
  facility,
  failCodes,
  filters,
  languages,
  platforms,
  programs,
  reports,
  session,
  testCategories,
  testDefinitions,
  tests,
  testSuites,
};
