import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelStatics from "~/label-printer/components/LabelStatics";

import labelActions from "../actions";
import labelSelectors from "../selectors";
import LabelEditor from "~/label-printer/components/designer/editor/LabelEditor";

const PrintWrap = (props) => {
  const { onReady, children } = props;
  useEffect(() => {
    setTimeout(onReady, 1000);
  }, []);
  return <div style={{ display: "none" }}>{children}</div>;
};

const LabelPrinter = (props) => {
  const { labelId, recording, setPrinted } = props;

  const dispatch = useDispatch();
  const loadedLabelId = useSelector(labelSelectors.labelSaver.getLoadedLabelId);
  const label = useSelector((state) => ({ ...state.labelPrinter.labelDesigner }));

  useEffect(() => {
    // TODO load label then add print job.
    dispatch(labelActions.labelSaver.loadLabel(labelId));
  }, [labelId]);

  useEffect(() => {
    if (loadedLabelId) {
      dispatch(labelActions.labelPrint.setPrintRecording(recording));
    }
  }, [loadedLabelId]);

  const onReady = () => {
    dispatch(labelActions.labelPrint.setPrintPreview(true));
  };

  const onPrinted = () => {
    setPrinted();
  };

  return (
    <LabelStatics>
      {loadedLabelId && (
        <PrintWrap onReady={onReady}>
          <LabelEditor recording={recording} {...label} onPrinted={onPrinted} />
        </PrintWrap>
      )}
    </LabelStatics>
  );
};

export default LabelPrinter;
