import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./SelectList.component.scss";

const SelectList = ({
  items,
  onChange,
  selectedItem,
  valueKey,
  nameKey,
  nameBuilder,
  disabled,
  underlined,
  tabIndex,
  labelName,
}) => {
  const onSelectedItemChanged = (event) => {
    if (!valueKey) {
      onChange(items[event.target.value]);
      return;
    }
    onChange(event.target.value);
  };

  // value of the select:
  //      with a value key: the value of the field at value key
  //      without a value key: index of the selected item
  const selectedItemValue = valueKey ? selectedItem[valueKey] : items.indexOf(selectedItem);
  return (
    <div
      className={classnames("cc-select-list", {
        disabled,
        underlined,
      })}
    >
      <select
        aria-label={labelName}
        disabled={disabled}
        value={selectedItemValue}
        onChange={onSelectedItemChanged}
        tabIndex={tabIndex}
      >
        {(items || []).map((item, index) => {
          const itemValue = valueKey ? item[valueKey] : index;

          let label;
          if (nameBuilder) {
            label = nameBuilder(item);
          } else if (nameKey) {
            label = item[nameKey];
          } else {
            label = item;
          }
          return (
            <option key={index} value={itemValue}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

SelectList.propTypes = {
  items: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  valueKey: PropTypes.string,
  nameKey: PropTypes.string,
  nameBuilder: PropTypes.func,
  disabled: PropTypes.bool,
  underlined: PropTypes.bool,
  tabIndex: PropTypes.number,
  labelName: PropTypes.string,
};

SelectList.defaultProps = {
  selectedItem: undefined,
  disabled: false,
  underlined: false,
};

export default SelectList;
