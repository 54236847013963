import React, { useEffect, useMemo } from "react";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontIcon } from "react-md";
import { TextField } from "~/global";

import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";
import * as workflowBehaviors from "../../workflowBehaviors";

import "./BehaviorEditor.scss";

import ConditionMenu from "~/workflow/components/editors/ConditionMenu";
import PrintLabelBehavior from "~/workflow/components/editors/behaviors/PrintLabelBehavior";
import ApiCallBehavior from "~/workflow/components/editors/behaviors/ApiCallBehavior";
import ConditionEditor from "~/workflow/components/editors/ConditionEditor";
import RunApplicationBehavior from "~/workflow/components/editors/behaviors/RunApplicationBehavior";
import SetCustomFieldsBehavior from "~/workflow/components/editors/behaviors/SetCustomFieldsBehavior";

const BehaviorEditor = (props) => {
  const { behaviorType, conditions, behaviorId, eventId, behaviorName } = props;

  const dispatch = useDispatch();

  const expanded = useSelector(workflowSelectors.workflowEditor.isExpanded(eventId, behaviorId));
  const isFirst = useSelector(workflowSelectors.eventEditor[eventId].isFirstBehavior(behaviorId));
  const isLast = useSelector(workflowSelectors.eventEditor[eventId].isLastBehavior(behaviorId));

  const onAddCondition = (e) => {
    dispatch(
      workflowActions.eventEditor[eventId].addCondition({ behaviorId, conditionType: e.key })
    );
  };
  const onChangeName = (e) => {
    dispatch(workflowActions.eventEditor[eventId].setBehaviorName({ behaviorId, name: e }));
  };

  const onMoveUp = () =>
    dispatch(workflowActions.eventEditor[eventId].moveBehaviorUp({ behaviorId }));
  const onMoveDown = () =>
    dispatch(workflowActions.eventEditor[eventId].moveBehaviorDown({ behaviorId }));

  const setExpanded = (expanded) =>
    dispatch(workflowActions.workflowEditor.setExpanded({ eventId, behaviorId, expanded }));

  const [editorBody, isEmptyBody] = useMemo(() => {
    const editorProps = { behaviorId, eventId };
    switch (behaviorType) {
      case workflowBehaviors.ApiCall:
        // eslint-disable-next-line
        return [<ApiCallBehavior {...editorProps} />, false];
      case workflowBehaviors.PrintLabel:
        // eslint-disable-next-line react/jsx-key
        return [<PrintLabelBehavior {...editorProps} />, false];
      case workflowBehaviors.RunApplication:
        // eslint-disable-next-line react/jsx-key
        return [<RunApplicationBehavior {...editorProps} />, false];
      case workflowBehaviors.SetCustomFields:
        // eslint-disable-next-line react/jsx-key
        return [<SetCustomFieldsBehavior {...editorProps} />, false];
      case workflowBehaviors.SkipService:
        return [<></>, true];
      default:
        // eslint-disable-next-line react/jsx-key
        return [
          <div>
            <b style={{ color: "red" }}>ERROR</b>: Unhandled behavior type: <b>{behaviorType}</b>
          </div>,
          false,
        ];
    }
  }, [behaviorType, behaviorId, eventId]);

  // On mount set expanded.
  useEffect(() => {
    setExpanded(true);
  }, []);

  return (
    <div className="workflow-behavior-editor">
      <div className="workflow-behavior-summary">
        <div className="reorder-buttons">
          <FontIcon iconClassName="icon-angle-up" onClick={!isFirst ? onMoveUp : null} />
          <FontIcon iconClassName="icon-angle-down" onClick={!isLast ? onMoveDown : null} />
        </div>
        <div onClick={() => setExpanded(!expanded)} className="summary-behavior-name">
          {behaviorName}
        </div>
        <div onClick={() => setExpanded(!expanded)}>
          <i>[{behaviorType}]</i> <b>({behaviorId})</b>
        </div>
        <div>
          <FontIcon onClick={() => setExpanded(!expanded)} iconClassName="icon-sliders" />
          <FontIcon
            onClick={() =>
              dispatch(workflowActions.eventEditor[eventId].removeBehavior({ behaviorId }))
            }
            iconClassName="icon-trash"
            style={{ color: "red", marginLeft: "1rem" }}
          />
        </div>
      </div>
      {expanded && (
        <div className="workflow-behavior-editor-body">
          <div className="behavior-editor-header">
            <div>
              <b>Name</b>&nbsp;
              <TextField onChange={onChangeName} value={behaviorName} />
            </div>
          </div>
          <div className="workflow-behavior-editor-conditions">
            <div className="condition-header">
              <b>Conditions</b>
              <Dropdown
                overlay={
                  <ConditionMenu
                    eventId={eventId}
                    behaviorType={behaviorType}
                    onClick={onAddCondition}
                  />
                }
              >
                <FontIcon
                  iconClassName="icon-plus"
                  className="add-icon-btn"
                  style={{ color: "white" }}
                />
              </Dropdown>
            </div>
            <div className="condition-body">
              {conditions.map((condition) => (
                <ConditionEditor
                  key={condition.conditionId}
                  eventId={eventId}
                  behaviorId={behaviorId}
                  behaviorType={behaviorType}
                  {...condition}
                />
              ))}
            </div>
          </div>
          {!isEmptyBody && <div className="workflow-behavior-editor-content">{editorBody}</div>}
        </div>
      )}
    </div>
  );
};

export default BehaviorEditor;
