import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "~/i18n";

import { Row, Column, SelectList, AsciButton, AsciSpinner, DayPicker } from "~/global";

import selectors from "~/selectors";
import { fetchAllFilters } from "~/actions/filters.actions";
import { useAppSelector, useAppDispatch } from "~/hooks";
import { DashboardFiltersParams } from "../../models/typeAndToolDashboard";
import { fetchDevicesProcessed } from "../../models/devicesProcessed.slice";
import { fetchTeamPerformance } from "../../models/teamPerformance.slice";
import type { FiltersState } from "../DashboardPage/DashboardPage.component";

import "./DashboardFilters.component.scss";

type Props = {
  setLevel: (level: string) => void;
  currentFilters: FiltersState;
  setCurrentFilters: (filters: FiltersState) => void;
  setChartTimezone: (timezone: string | undefined) => void;
};

const DashboardFilters = ({
  setLevel,
  currentFilters,
  setCurrentFilters,
  setChartTimezone,
}: Props) => {
  // Configuration == Service Suite
  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [selectedDeploymentId, setSelectedDeploymentId] = useState("all");
  const [selectedShiftId, setSelectedShiftId] = useState("all");
  const [selectedTeamId, setSelectedTeamId] = useState("all");
  const [selectedProgramId, setSelectedProgramId] = useState("all");
  const [selectedConfigurationId, setSelectedConfigurationId] = useState("all");

  const [day, setDay] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [dateTo, setDateTo] = useState(moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [lastUpdateTime, setLastUpdateTime] = useState(moment().format("LTS"));
  const dispatch = useAppDispatch();

  const { isLoading, facilities } = useAppSelector((state) => state.filters);
  const deploymentsByFacilityId = useAppSelector((state) =>
    // @ts-ignore
    selectors.filters.getDeploymentsByFacilityId(state)
  );
  const shiftsByDeploymentId = useAppSelector((state) =>
    // @ts-ignore
    selectors.filters.getShiftsByDeploymentId(state)
  );
  const programIdsByDeploymentId = useAppSelector((state) =>
    // @ts-ignore
    selectors.filters.getProgramIdsByDeploymentId(state)
  );
  // @ts-ignore
  const programsById = useAppSelector((state) => selectors.filters.getProgramsById(state));
  const configurationsByProgramId = useAppSelector((state) =>
    // @ts-ignore
    selectors.filters.getConfigurationsByProgramId(state)
  );
  const teamsByFacilityId = useAppSelector((state) =>
    selectors.filters.getTeamsByFacilityId(state)
  );
  const timezonesByFacilityId = useAppSelector((state) =>
    selectors.filters.getTimezonesByFacilityId(state)
  );
  const { t } = useTranslation("dashboard");

  useEffect(() => {
    void dispatch(fetchAllFilters());
  }, [dispatch]);

  useEffect(() => {
    if (facilities.length > 0) {
      applyFilters(
        // Select the first facility and its timezone by default
        // @ts-expect-error
        facilities[0].faid,
        selectedDeploymentId,
        selectedShiftId,
        selectedTeamId,
        selectedProgramId,
        selectedConfigurationId,
        // @ts-expect-error
        facilities[0].timezone
      );
    }
  }, [facilities]);

  useEffect(() => {
    if (Object.keys(currentFilters).length > 0) {
      setSelectedFacilityId(currentFilters.facilityId);
      setSelectedDeploymentId(currentFilters.deploymentId);
      setSelectedShiftId(currentFilters.shiftId);
      setSelectedTeamId(currentFilters.teamId);
      setSelectedProgramId(currentFilters.programId);
      setSelectedConfigurationId(currentFilters.configurationId);

      setLastUpdateTime(moment().format("LTS"));
    }
  }, [currentFilters]);

  type OptionUpdateFilter = {
    faid: string;
    dpid: string;
    id: string;
    teamid: string;
  };

  const updateFilters = (option: OptionUpdateFilter, selectedFilter: string) => {
    switch (selectedFilter) {
      case "facility":
        setSelectedFacilityId(option.faid);
        setSelectedDeploymentId("all");
        setSelectedShiftId("all");
        setSelectedProgramId("all");
        setSelectedConfigurationId("all");
        setSelectedTeamId("all");
        break;

      case "deployment":
        setSelectedDeploymentId(option.dpid);
        setSelectedShiftId("all");
        setSelectedProgramId("all");
        setSelectedConfigurationId("all");
        break;

      case "shift":
        setSelectedShiftId(option.id);
        break;

      case "team":
        setSelectedTeamId(option.teamid);
        break;

      case "program":
        setSelectedProgramId(option.id);
        setSelectedConfigurationId("all");
        break;

      case "configuration":
        setSelectedConfigurationId(option.id);
    }
  };

  const applyFilters = (
    facilityId: string,
    deploymentId: string,
    shiftId: string,
    teamId: string,
    programId: string,
    configurationId: string,
    timezone?: string
  ) => {
    let level = "configurations";

    if (programId !== "all" && configurationId !== "all") {
      if (teamId !== "all") {
        level = "operators";
      } else {
        level = "teams";
      }
    }

    setLevel(level);

    if (!timezone) {
      timezone = timezonesByFacilityId[facilityId];
    }

    setChartTimezone(timezone);

    setCurrentFilters({
      facilityId,
      deploymentId,
      shiftId,
      teamId,
      programId,
      configurationId,
      dateFrom,
      dateTo,
    });
    const itemsFilters: DashboardFiltersParams = {
      facilityID: facilityId,
      deploymentID: deploymentId,
      shiftID: shiftId,
      teamID: teamId || "",
      programID: programId,
      configID: configurationId,
      timezone,
      from: dateFrom,
      to: dateTo,
    };
    void dispatch(fetchDevicesProcessed(itemsFilters));

    void dispatch(fetchTeamPerformance(itemsFilters));
  };

  if (isLoading || selectedFacilityId.length === 0) {
    return <AsciSpinner visible />;
  }
  // @ts-expect-error
  const selectedFacilityItem = facilities.filter((f) => f.faid === selectedFacilityId)[0];

  const deploymentItems = deploymentsByFacilityId[selectedFacilityId] || [];
  const selectedDeploymentItem = deploymentItems.filter(
    (d: { dpid: string }) => d.dpid === selectedDeploymentId
  )[0];

  const shiftItems = (shiftsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const selectedShiftItem = shiftItems.filter((s: { id: string }) => s.id === selectedShiftId)[0];

  const teamItems = teamsByFacilityId[selectedFacilityId] || [];
  const selectedTeamItem = teamItems.filter(
    (t: { teamid: string }) => t.teamid === selectedTeamId
  )[0];

  const programIds =
    (programIdsByDeploymentId[selectedFacilityId] || [])[selectedDeploymentId] || [];
  const programs = programIds.map(
    (programId: { id: string | number }) => programsById[programId.id] || []
  );

  const programItems = programs.filter(
    (program: any, index: any, self: string | any[]) => self.indexOf(program) === index
  );
  const selectedProgramItem = programItems.filter(
    (p: { id: string }) => p.id === selectedProgramId
  )[0];

  const configItems = configurationsByProgramId[selectedProgramId] || [];
  const selectedConfigItem = configItems.filter(
    (c: { id: string }) => c.id === selectedConfigurationId
  )[0];

  return (
    <Row className="dashboard-filters full-width" align="space-between center">
      <Row className="filters">
        <Column className="filter-container">
          <DayPicker
            value={day}
            onChange={(date: Date) => {
              const now = moment().format("YYYY-MM-DDTHH:mm:ssZZ");
              const momentDate = moment(date);

              setDay(date);
              setDateFrom(momentDate.startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));

              if (momentDate.isSame(now, "day")) {
                setDateTo(now);
              } else {
                setDateTo(momentDate.endOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));
              }
            }}
          />
        </Column>

        <div className="filter-separator" />

        <Column className="filter-container">
          <Row className="filter-label">{t("facility")}</Row>
          <SelectList
            items={facilities}
            onChange={(option) => updateFilters(option, "facility")}
            selectedItem={selectedFacilityItem}
            nameKey="name"
            labelName="facility"
          />
        </Column>

        <div className="filter-separator" />

        <Column className="filter-container">
          <Row className="filter-label">{t("deployment")}</Row>
          <SelectList
            items={deploymentItems}
            onChange={(option) => updateFilters(option, "deployment")}
            selectedItem={selectedDeploymentItem}
            nameKey="name"
            disabled={deploymentItems.length === 0}
            labelName="deployment"
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">{t("shift")}</Row>
          <SelectList
            items={shiftItems}
            onChange={(option) => updateFilters(option, "shift")}
            selectedItem={selectedShiftItem}
            nameKey="name"
            disabled={shiftItems.length === 0 || selectedDeploymentId === "all"}
            labelName="shift"
          />
        </Column>

        <div className="filter-separator" />

        <Column className="filter-container">
          <Row className="filter-label">{t("team")}</Row>
          <SelectList
            items={teamItems}
            onChange={(option) => updateFilters(option, "team")}
            selectedItem={selectedTeamItem}
            nameKey="name"
            disabled={teamItems.length === 0}
            labelName="team"
          />
        </Column>

        <div className="filter-separator" />

        <Column className="filter-container">
          <Row className="filter-label">{t("program")}</Row>
          <SelectList
            items={programItems}
            onChange={(option) => updateFilters(option, "program")}
            selectedItem={selectedProgramItem}
            nameKey="name"
            disabled={programItems.length === 0}
            labelName="program"
          />
        </Column>

        <Column className="filter-container">
          <Row className="filter-label">{t("service-suite")}</Row>
          <SelectList
            items={configItems}
            onChange={(option) => updateFilters(option, "configuration")}
            selectedItem={selectedConfigItem}
            nameKey="name"
            disabled={programItems.length === 0 || selectedProgramId === "all"}
            labelName="service-suite"
          />
        </Column>
      </Row>

      <Column className="actions" align="center end">
        <Column className="btn-group" align="center center">
          <AsciButton
            color="green"
            onClick={() =>
              applyFilters(
                selectedFacilityId,
                selectedDeploymentId,
                selectedShiftId,
                selectedTeamId,
                selectedProgramId,
                selectedConfigurationId
              )
            }
          >
            {t("apply-filters")}
          </AsciButton>

          <Row
            className="secondary-btn"
            // @ts-expect-error
            onClick={() => applyFilters(facilities[0].faid, "all", "all", "all", "all", "all", "")}
          >
            <span>{t("clear-all-filters")}</span>
          </Row>
        </Column>

        <Row className="small-text">
          {t("last-updated")}: {lastUpdateTime}
        </Row>

        <Row
          className="secondary-btn small-text"
          align="center center"
          onClick={() =>
            applyFilters(
              currentFilters.facilityId,
              currentFilters.deploymentId,
              currentFilters.shiftId,
              currentFilters.teamId,
              currentFilters.programId,
              currentFilters.configurationId
            )
          }
        >
          <i className="icon-repeat" />
          <span>{t("refresh")}</span>
        </Row>
      </Column>
    </Row>
  );
};
export default DashboardFilters;
