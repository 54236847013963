import * as GSMAActions from "./GSMA.actions";
import * as VersionManagerActions from "./VersionManager.actions";

import * as audits from "./audits.actions";
import * as countries from "./countries.actions";
import * as customer from "./customer.actions";
import * as customers from "./customers.actions";
import * as customFields from "./customFields.actions";
import * as dialog from "./dialog.actions";
import * as documentation from "./Documentation.actions";
import * as facilities from "./facilities.actions";
import * as facility from "./facility.actions";
import * as failCode from "./failCodes/failCode.actions";
import * as failCodeCategories from "./failCodes/failCodeCategories.actions";
import * as failCodeCategory from "./failCodes/failCodeCategory.actions";
import * as failCodePriorityGroups from "./failCodes/failCodePriorityGroups.actions";
import * as failCodePriorityGroup from "./failCodes/failCodePriorityGroup.actions";
import * as failCodes from "./failCodes/failCodes.actions";
import * as filters from "./filters.actions";
import * as languages from "./languages.actions";
import * as licenses from "./licenses.actions";
import * as platforms from "./platforms.actions";
import * as programs from "./programs.actions";
import * as remoteDiagnostics from "./RemoteDiagnostics.actions";
import * as report from "./report.actions";
import * as reportTypes from "./reportTypes.actions";
import * as exportedReports from "./exportedReports.actions";
import * as serviceSuites from "./serviceSuites.actions";
import * as session from "./session.actions";
import * as testCategories from "./testCategories.actions";
import * as testDefinitions from "~/test-definitions-editor/actions/testDefinitions.actions";
import * as tests from "./tests.actions";
import * as testSuites from "./testSuites.actions";
import * as user from "./user.actions";
import * as servicesVersion from "./servicesVersion.actions";

export {
  GSMAActions,
  remoteDiagnostics as RemoteDiagnosticsActions,
  VersionManagerActions,
  facilities as facilityActions,
  dialog as DialogActions,
  documentation as DocumentationActions,
};

export default {
  audits,
  countries,
  customer,
  customers,
  customFields,
  dialog,
  documentation,
  facilities,
  facility,
  failCode,
  failCodeCategories,
  failCodeCategory,
  failCodes,
  failCodePriorityGroup,
  failCodePriorityGroups,
  filters,
  languages,
  licenses,
  platforms,
  programs,
  remoteDiagnostics,
  report,
  reportTypes,
  exportedReports,
  serviceSuites,
  session,
  testCategories,
  testDefinitions,
  tests,
  testSuites,
  user,
  servicesVersion,
};
