import type { ReactNode } from "react";
import React from "react";
import {
  DefaultNamespace,
  initReactI18next,
  KeyPrefix,
  Namespace,
  TFunction,
  useTranslation as useI18next,
  UseTranslationOptions,
} from "react-i18next";
import i18n, { i18n as I18nType } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ConfigProvider } from "antd";
import antdEn from "antd/lib/locale/en_US";
import antdEs from "antd/lib/locale/es_ES";
import antdFr from "antd/lib/locale/fr_CA";

import en from "./locales/en";
import es from "./locales/es";
import fr from "./locales/fr";

const antdLocales = {
  en: antdEn,
  es: antdEs,
  fr: antdFr,
};

const resources = {
  en,
  es,
  fr,
};

const supportedLngs = ["en", "es", "fr"] as const;
export type SupportedLanguage = typeof supportedLngs[number];
export type MultiLang = {
  en: string;
} & {
  [Key in Exclude<SupportedLanguage, "en">]?: string;
};

/**
 * The `useTranslation()` hook from react-i18next, with custom extensions for our use-case
 */
export function useTranslation<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
): {
  /*
   * NOTE: The return type was vendored-in (instead of imported & extended) from
   * react-i18next because the provided typedef is broken and impossible to import.
   * Fix this whenever the type UseTranslationResponse can be imported.
   * Still broken as of react-18next v11.15.5
   */
  /** The i18next [Translation Function](https://www.i18next.com/translation-function/essentials) */
  t: TFunction<N, TKPrefix> &
    // Allow usage of these special keys from any other namespace
    ((key: "common:multi", options: { dict?: MultiLang }) => string) &
    ((key: "common:datetime", options: { val: Date } & Intl.DateTimeFormatOptions) => string) &
    ((
      key: "common:relativetime",
      options: { val: number; unit: Intl.RelativeTimeFormatUnit } & Intl.RelativeTimeFormatOptions
    ) => string) &
    ((key: "common:number", options: { val: number } & Intl.NumberFormatOptions) => string);
  // Examples provided so i18next-parser doesn't flag them as unused
  // t("common:multi")
  // t("common:datetime")
  // t("common:relativetime")
  // t("common:number")
  /** The i18next main object. Avoid using directly if possible. */
  i18n: I18nType;

  // Custom extensions

  /** `true` if selected language is "cimode", `false` otherwise */
  cimode: boolean;
  /** Current language, formatted as a BCP 47 language tag */
  language: SupportedLanguage;
} {
  const translation = useI18next(ns, options);
  if (translation.i18n.language === "cimode") {
    return {
      ...translation,
      cimode: true,
      // show spanish to help distinguish translated content from non-translated content
      // This applies to translations that are not provided by client-portal
      // e.g. antd components, date formats & localized data from the backend
      language: "es",
    };
  }
  return {
    ...translation,
    cimode: false,
    language: i18n.language as SupportedLanguage,
  };
}

export const AntdConfigProvider = ({ children }: { children: ReactNode }) => {
  const { language } = useTranslation();
  return <ConfigProvider locale={antdLocales[language]}>{children}</ConfigProvider>;
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs,
    defaultNS: "common",
    appendNamespaceToCIMode: true,
    interpolation: {
      // not needed for react
      escapeValue: false,
      skipOnVariables: false,
    },
    // allow an empty value to count as invalid (by default is true)
    returnEmptyString: false,
    react: {
      transKeepBasicHtmlNodesFor: ["br", "strong", "em", "b", "i", "p", "code"],
    },
  });
i18n.services.formatter?.add("multi", (value: unknown, lng) => {
  const language = (lng ?? "en") as SupportedLanguage | "cimode";

  if (language === "cimode") {
    // This is never actually displayed; cimode replaces the value with `namespace:key`
    return "MULTILANG";
  }
  if (value == null) {
    // Passing an undefined dict is supported
    return "";
  }
  if (typeof value !== "object") {
    console.error(new TypeError("i18n: dict must be an object")); // eslint-disable-line no-console
    return "";
  }
  const dirtyDict = value as Record<string, unknown>;
  if (dirtyDict.en == null) {
    console.error(new TypeError("i18n: dict.en is not defined")); // eslint-disable-line no-console
    return "";
  }
  if (
    supportedLngs
      .map((lng) => dirtyDict[lng])
      .some((value) => value != null && typeof value !== "string")
  ) {
    console.error(new TypeError("i18n: dict contains non-string properties")); // eslint-disable-line no-console
    return "";
  }
  const dict = dirtyDict as MultiLang;

  return (dict[language] ?? "") || dict.en;
});
i18n.services.formatter?.add("lowercase", (value: unknown, lng) => {
  if (value == null || typeof value !== "string") {
    console.error(new TypeError("i18n: value must be a string")); // eslint-disable-line no-console
    return "";
  }

  return value.toLocaleLowerCase(lng);
});
i18n.services.formatter?.add("uppercase", (value: unknown, lng) => {
  if (value == null || typeof value !== "string") {
    console.error(new TypeError("i18n: value must be a string")); // eslint-disable-line no-console
    return "";
  }

  return value.toLocaleUpperCase(lng);
});

export default i18n;
