import { createAction } from "@reduxjs/toolkit";
import actions from "~/actions";
import moduleSelectors from "../selectors";

export const updateWizardStep = createAction("customerManagement/ UPDATE_WIZARD_STEP");

export const onStepChanged = (index) => updateWizardStep({ index });

export const addToCustomersList = () => async (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  const user = moduleSelectors.customerUserEditor.getUserToServerFormat(state);

  const addCustomerServerFormat = {
    name: identity.name,
    csid: identity.csid,
    reseller: identity.reseller,
    logo: identity.logo,
    users: [].concat(user),
    facilities: [].concat(facility),
  };

  dispatch(actions.customers.addCustomer(addCustomerServerFormat));
};
