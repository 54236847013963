import { createAction } from "@reduxjs/toolkit";
import { parseISO } from "date-fns";

import { CsvFile } from "~/features/csv-upload/csvUploadSlice";
import {
  Filter,
  facetsToApiFilters,
} from "~/features/recording-facets/RecordingFacetsFilter.component";
import { Uniqueness } from "~/features/recording-facets/UniquenessFilter.component";
import { DateRange, ReportingFiltersType } from "~/features/reporting-filters";
import { overrideTimeZone } from "~/pages/reporting/lib/utils";
import { AppThunk } from "~/store";
import { CustomerManagement } from "~/services";
import { ReportTypeName } from "./reportTypes.actions";

export const generateReportBegin = createAction("GENERATE_REPORT_BEGIN");
export const generateReportSuccess = createAction("GENERATE_REPORT_SUCCESS");
export const generateReportError = createAction("GENERATE_REPORT_ERROR");

export type ExportedReportFilters = Omit<ReportingFiltersType, "uniqueness"> &
  Pick<Partial<ReportingFiltersType>, "uniqueness"> & {
    csvFile?: CsvFile;
    reportTypeName: ReportTypeName;
  };

type GenerateReportOptions = {
  exportedReportFilters: ExportedReportFilters;
  fileFormat: string;
  columns?: string[];
  email?: string;
};

type PostReportingBody = {
  method: "byEmail" | "byDownload";
  dateFrom: string;
  dateTo: string;
  dateRange: DateRange;
  type: ReportTypeName;
  format: string;
  filters?: Filter[];
  uniqueness?: Uniqueness;
  email?: string; // undefined -> user email
  faid?: string; // undefined -> user faid
  fileId?: string;
  columns?: string[];
};

export const generateReport =
  ({
    exportedReportFilters,
    fileFormat,
    columns,
    email,
  }: GenerateReportOptions): AppThunk<Promise<{ message?: string; error?: string }>> =>
  async (dispatch) => {
    dispatch(generateReportBegin());

    const {
      facilityId,
      facets,
      timeZone,
      dateFrom,
      dateTo,
      dateRange,
      uniqueness,
      csvFile,
      reportTypeName,
    } = exportedReportFilters;

    const body: PostReportingBody = {
      faid: facilityId,
      filters: facetsToApiFilters(facets),
      dateFrom: overrideTimeZone(parseISO(dateFrom), timeZone),
      dateTo: overrideTimeZone(parseISO(dateTo), timeZone),
      dateRange,
      uniqueness,
      fileId: csvFile?._id,
      type: reportTypeName,
      format: fileFormat,
      columns,
      email,
      method: email != null ? "byEmail" : "byDownload",
    };

    try {
      const response = await CustomerManagement.POST(`/reporting`, body);
      const message = response.message as string;
      dispatch(generateReportSuccess());
      return { message };
    } catch (e: any) {
      if (e instanceof Error) throw e;

      const message: string = e.json?.message ?? "Request didn't complete successfully";
      dispatch(generateReportError());
      return { error: message };
    }
  };
