import React from "react";

import "./Spinner.component.scss";

const Spinner = () => (
  <div className="cc-spinner">
    <i className="icon-asci-gear" />
  </div>
);

export default Spinner;
