import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const CustomerManagerIcon = ({ size, customId }) => (
  <SVGIcon title="Customer Manager" viewBox="0 0 29.38 34.67" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x1="23.82"
        x2="28"
        y1="3.47"
        y2="3.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
      <linearGradient
        id={`${customId}-b`}
        x2="29.38"
        y1="17.33"
        y2="17.33"
        xlinkHref={`#${customId}-a`}
      />
      <linearGradient
        id={`${customId}-c`}
        x1="22.96"
        x2="29.38"
        y1="3.21"
        y2="3.21"
        xlinkHref={`#${customId}-a`}
      />
    </defs>
    <g>
      <path fill={`url(#${customId}-a)`} d="M28 5.56h-4.18V1.38l1 1v2.18H27l1 1z" />
      <path
        fill="none"
        stroke="#93d500"
        strokeMiterlimit="10"
        d="M23.46 25.42H5.92c.06-.52.08-1 .17-1.55a3.68 3.68 0 0 1 2.25-3c1-.4 2-.75 3-1.13a9.26 9.26 0 0 0 1-.42 1 1 0 0 0 .35-1.3c-.23-.44-.52-.86-.72-1.33a9 9 0 0 1-.74-4.88 2.72 2.72 0 0 1 2.22-2.39 5.57 5.57 0 0 1 2.66.07A2.61 2.61 0 0 1 18 11.37a6.65 6.65 0 0 1 .15 1.89 9.39 9.39 0 0 1-1.41 4.57 1 1 0 0 0 .39 1.48 7.89 7.89 0 0 0 1 .39c1 .4 2.06.77 3.08 1.2a3.52 3.52 0 0 1 2.14 2.79c0 .57.04 1.15.11 1.73z"
      />
      <path
        fill={`url(#${customId}-b)`}
        d="M28.38 4v26.77a2.9 2.9 0 0 1-2.9 2.9H3.91A2.91 2.91 0 0 1 1 30.76V3.91A2.91 2.91 0 0 1 3.91 1h21.46l-1-1H3.91A3.91 3.91 0 0 0 0 3.91v26.85a3.91 3.91 0 0 0 3.91 3.91h21.57a3.91 3.91 0 0 0 3.9-3.91V5z"
      />
      <path fill={`url(#${customId}-c)`} d="M28.38 4l-3-3-1-1H23l6.42 6.42V5z" />
    </g>
  </SVGIcon>
);

CustomerManagerIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default CustomerManagerIcon;
