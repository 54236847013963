import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

import "./PercentBarChart.component.scss";

const PercentBarChart = ({ title, data, yAxis, xAxisTitle, median, ref }) => (
  <div className="percent-bar-chart">
    <div className="chart-header">
      <div className="chart-title">{title}</div>
    </div>

    <ResponsiveContainer height="80%">
      <BarChart layout="vertical" data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          unit="%"
          label={{
            value: xAxisTitle,
            position: "bottom",
          }}
        />
        <YAxis dataKey={yAxis.key} type="category" width={150} />
        <Tooltip />
        <Legend verticalAlign="top" height={48} />
        {yAxis.details.map((detail) => (
          <Bar
            key={detail.dataKey}
            dataKey={detail.dataKey}
            name={detail.name}
            unit={detail.unit ? detail.unit : null}
            fill={detail.colour}
            maxBarSize={15}
            stackId="stack"
          />
        ))}
        <Bar dataKey="Median" fill="#29AB8E" name={median.title} />
        <ReferenceLine
          x={median.value}
          stroke="#29AB8E"
          strokeWidth="4"
          label={{
            value: median.label,
            position: "top",
            fill: "#29AB8E",
            fontWeight: "bold",
          }}
          isFront
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default PercentBarChart;
