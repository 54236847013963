import React, { useState } from "react";
import classnames from "classnames";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "~/i18n";
import { Row, Column } from "~/global";

import { TeamPerformanceType } from "../../models/teamPerformance.slice";
import { ShowMore } from "../../models/teams.slice";

import "./TeamPerformance.component.scss";

type Props = {
  showMore?: {
    item?: ShowMore;
    status: "idle" | "pending" | "succeeded" | "failed";
    error?: string;
  };
  performance: TeamPerformanceType;
  showMoreTeamData: () => void;
};
const TeamPerformance = ({ showMore, performance, showMoreTeamData }: Props) => {
  // Configuration == Service Suite
  const [isExpanded, setExpansion] = useState(false);
  const { t } = useTranslation("dashboard");

  const status = showMore?.status;
  return (
    <Column className="cc-team-performance">
      <Row className="performance-row" align="space-between center">
        <Column className="uph-stats" align="center">
          <Row className="stats-number" align="center">
            {performance.currentUph}
          </Row>
          <Row className="stats-label" align="center">
            {t("current-prph")}
          </Row>
        </Column>

        <i
          className={classnames("uph-arrow", {
            "icon-minus": performance.currentUph === performance.averageUph,
            "icon-long-arrow-up above-avg": performance.currentUph > performance.averageUph,
            "icon-long-arrow-down below-avg": performance.currentUph < performance.averageUph,
          })}
        />

        <Column className="uph-stats" align="center">
          <Row className="stats-number" align="center">
            {performance.averageUph}
          </Row>
          <Row className="stats-label" align="center">
            {t("avg-team-prph")}
          </Row>
        </Column>
      </Row>

      <Row className="performance-row" align="space-around">
        <Column className="devices-stats">
          <Row className="devices-number" align="center">
            {performance.devices}
          </Row>
          <Row className="devices-label" align="center">
            {t("processed")}
          </Row>
        </Column>

        <Column className="devices-stats">
          <Row className="devices-number" align="center">
            {`${performance.processPass}/${performance.processFail}`}
          </Row>
          <Row className="devices-label" align="center">
            {t("pass-fail")}
          </Row>
        </Column>

        <Column className="devices-stats">
          <Row className="devices-number" align="center">
            {`${performance.auditFail}/${performance.audit}`}
          </Row>
          <Row className="devices-label" align="center">
            {t("audit-fail")}
          </Row>
        </Column>

        <Column className="devices-stats">
          <Row className="devices-number" align="center">
            {performance.devicesIncomplete}
          </Row>
          <Row className="devices-label" align="center">
            {t("incomplete")}
          </Row>
        </Column>

        <Column className="devices-stats">
          <Row className="devices-number" align="center">
            {performance.devicesRetried}
          </Row>
          <Row className="devices-label" align="center">
            {t("retry")}
          </Row>
        </Column>
      </Row>

      {status === "pending" ? (
        <Row className="show-more-btn" align="center center">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
            size="large"
          />
        </Row>
      ) : isExpanded ? (
        <>
          <Column className="more-info">
            <Column className="more-info-row" align="space-around">
              <Row className="title" align="start center">
                <i className="icon-cell-phone" />
                {t("line-phones")}
              </Row>

              <ul className="columns-3">
                {showMore?.item?.linePhones?.map((linePhone, index) => (
                  <li key={index}>{linePhone}</li>
                ))}
              </ul>
            </Column>

            <Column className="more-info-row" align="space-around">
              <Row className="title" align="start center">
                <i className="icon-code" />
                {t("service-suites")}
              </Row>

              <ul className="columns-2">
                {showMore?.item?.configurations?.map((configuration, index) => (
                  <li key={index}>{configuration}</li>
                ))}
              </ul>
            </Column>
          </Column>

          <Row className="show-more-btn" align="center center" onClick={() => setExpansion(false)}>
            <i className="icon-minus" />
            {t("show-less")}
          </Row>
        </>
      ) : (
        <Row
          className="show-more-btn"
          align="center center"
          onClick={() => {
            showMoreTeamData();
            setExpansion(true);
          }}
        >
          <i className="icon-plus" />
          {t("show-more")}
        </Row>
      )}
    </Column>
  );
};

export default TeamPerformance;
