import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { parseISO } from "date-fns";

import {
  facetsToApiFilters,
  Filter,
} from "~/features/recording-facets/RecordingFacetsFilter.component";
import { Uniqueness } from "~/features/recording-facets/UniquenessFilter.component";
import { overrideTimeZone } from "~/pages/reporting/lib/utils";
import { type SearchFilters } from "~/pages/reporting/ReportingFiltersAndChart";
import { ApiError, ApiErrorResponse, ApiResponse, CustomerManagement, Paginated } from "~/services";
import { Pagination } from "~/shared/lib/usePagination";
import { type RootState } from "~/store";
import {
  GenericArraySummary,
  PartsCheckSummary,
  ProcessRun,
  SummaryHistory,
} from "./processRunTypes";
import { DeviceProcess } from "~/pages/reporting/model/deviceProcessesSlice";

export type PostReportingSearchBody = {
  /** ISO 8601 with UTC offset */
  dateFrom: string;
  /** ISO 8601 with UTC offset */
  dateTo: string;
  /** Facility ID */
  faid: string;
  filters?: Filter[];
  uniqueness: Uniqueness;
  fileId?: string;
  keyword?: string;
  type: string;
  page: number;
  perPage: number;
  columns?: string[];
};

export const searchProcessRuns = createAsyncThunk<
  Paginated<ProcessRun[]>,
  { filters: SearchFilters; pagination: Pagination },
  { rejectValue: ApiError }
>("processRuns/search", async ({ filters, pagination }, { rejectWithValue }) => {
  try {
    const {
      facilityId,
      facets,
      timeZone,
      dateFrom,
      dateTo,
      uniqueness,
      csvFile,
      keyword,
      reportTypeName,
      columns,
    } = filters;

    const { page, pageSize } = pagination;
    const body: PostReportingSearchBody = {
      faid: facilityId,
      filters: facetsToApiFilters(facets),
      dateFrom: overrideTimeZone(parseISO(dateFrom), timeZone),
      dateTo: overrideTimeZone(parseISO(dateTo), timeZone),
      uniqueness: uniqueness ?? "none",
      keyword,
      type: reportTypeName,
      page,
      perPage: pageSize,
      columns: columns?.split(",") ?? [],
    };

    const { payload } = (await CustomerManagement.POST("/reporting/search", body)) as ApiResponse<
      Paginated<ProcessRun[]>
    >;

    return payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      return rejectWithValue((err as ApiErrorResponse).json);
    }
  }
});

export const fetchSummary = createAsyncThunk<
  SummaryHistory,
  { sessionId: string; processRuns: DeviceProcess[] },
  { rejectValue: ApiError }
>("processRuns/fetchSummary", async ({ sessionId, processRuns }, { rejectWithValue }) => {
  try {
    const { payload } = (await CustomerManagement.POST(`/dh-search-session/${sessionId}/summary`, {
      csid: "",
      bestAvailableId: "",
      processes: processRuns,
    })) as ApiResponse<SummaryHistory>;
    return payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      return rejectWithValue((err as ApiErrorResponse).json);
    }
  }
});

export const fetchProcessRun = createAsyncThunk<ProcessRun, string, { rejectValue: ApiError }>(
  "processRuns/fetchOne",
  async (sessionId, { rejectWithValue }) => {
    try {
      const { payload } = (await CustomerManagement.GET(
        `/dh-search-session/${sessionId}`
      )) as ApiResponse<ProcessRun>;

      return payload;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        return rejectWithValue((err as ApiErrorResponse).json);
      }
    }
  }
);

const processRunsAdapter = createEntityAdapter<ProcessRun>({
  selectId: (processRun) => processRun.ID,
});
const summaryAdapter = createEntityAdapter<SummaryHistory>({
  selectId: (summary) => summary.recording.ID,
});

interface ProcessRunsState extends EntityState<ProcessRun> {
  selected?: ProcessRun;
  summary: SummaryHistory;
  total?: number;
  error?: ApiError;
  status: "idle" | "loading";
}

const initialState: ProcessRunsState = {
  ...processRunsAdapter.getInitialState(),
  status: "idle",
  summary: {
    ...summaryAdapter.getInitialState(),
    recording: {
      ID: "",
      license: undefined,
      tenantIdentifier: undefined,
      batteryInformation: undefined,
      desktopInformation: undefined,
      process: undefined,
      deviceInfo: undefined,
      inputOrigin: undefined,
      failDetails: undefined,
      processResult: undefined,
      services: undefined,
      tests: undefined,
      sender: undefined,
      senderSource: undefined,
      supplierID: undefined,
      clientID: undefined,
      inventory: undefined,
      userComment: "",
      facilityName: "",
      deploymentName: "",
      startDateFormatted: "",
      endDateFormatted: "",
      processRunOutcome: "",
      failCodesDescriptions: "",
    },
  },
};

const processRunsSlice = createSlice({
  name: "processRuns",
  initialState,
  reducers: {
    errorCleared(draftState) {
      draftState.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // search
      .addCase(searchProcessRuns.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(searchProcessRuns.fulfilled, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.total = payload.TotalDoc;
        processRunsAdapter.setAll(draftState, payload.Doc);
      })
      .addCase(searchProcessRuns.rejected, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.error = payload;
      })
      // fetchOne
      .addCase(fetchProcessRun.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(fetchProcessRun.fulfilled, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.selected = payload;
      })
      .addCase(fetchProcessRun.rejected, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.error = {
          ...payload,
          message: payload?.message ?? "The request did not complete successfully.",
        };
      })
      // fetchSummary
      .addCase(fetchSummary.pending, (draftState) => {
        draftState.status = "loading";
        draftState.error = undefined;
      })
      .addCase(fetchSummary.fulfilled, (draftState, { payload }) => {
        draftState.status = "idle";
        const newSummary = { ...payload }; // create a copy of summary
        newSummary.recording.services = summaryReduce(payload.summary?.servicesSummary || []);
        newSummary.recording.tests = summaryReduce(payload.summary?.testsSummary || []);
        if (newSummary.recording.deviceInfo) {
          newSummary.recording.deviceInfo.partsCheck = summaryReducePartCheck(
            payload.summary?.partsCheckSummary || []
          );
        }

        draftState.summary = newSummary;
      })
      .addCase(fetchSummary.rejected, (draftState, { payload }) => {
        draftState.status = "idle";
        draftState.error = {
          ...payload,
          message: payload?.message ?? "The request did not complete successfully.",
        };
      });
  },
});

const summaryReducePartCheck = (genericSummary: PartsCheckSummary[]) =>
  genericSummary.map((value) => {
    const status =
      value.GenericSummary.passCount != null
        ? value.GenericSummary.passCount > 0
          ? "PASS"
          : "FAIL"
        : "FAIL";
    return {
      name: value.PartCheck.name,
      currentSerial: value.PartCheck.currentSerial,
      factorySerial: value.PartCheck.factorySerial,
      result: status,
      message: value.PartCheck.message,
      count: value.GenericSummary.count,
      passCount: value.GenericSummary.passCount,
      successPercentage: value.GenericSummary.successPercentage,
    };
  });

const classNameAndNameFilter = (className: string, name: string) => {
  if (className === name) return className;
  if (className && name) return `${className} | ${name}`;
  return name;
};

const summaryReduce = (genericSummary: GenericArraySummary[]) =>
  genericSummary.map((value) => ({
    name: classNameAndNameFilter(value?.className ?? "", value?.name ?? ""),
    count: value.GenericSummary?.count,
    passCount: value.GenericSummary?.passCount,
    duration: value.duration?.toString() != null ? value.duration?.toString() : "0",
    durationUnits: value?.durationUnits,
    startTime: value?.dateStartPass != null ? value?.dateStartPass : value?.startTime,
    successPercentage: value.GenericSummary?.successPercentage,
    status: value.status,
    isAudit: value.isAudit ?? false,
  }));

export const { errorCleared } = processRunsSlice.actions;

export default processRunsSlice.reducer;

export const processRunsSelectors = processRunsAdapter.getSelectors<RootState>(
  (state) => state.reportingModule.processRuns
);

export const summaryHistory = (state: RootState) => state.reportingModule.processRuns.summary;

const getSelectedProcessRuns = (state: RootState) => state.reportingModule.processRuns.selected;

export const getDeviceIsWipedByService = createSelector([getSelectedProcessRuns], (processRun) => {
  const services = processRun?.services ?? [];
  if (processRun?.deviceInfo?.manufacturer?.toLocaleLowerCase() === "apple") {
    const iOSWipedByService = services.some(
      (service) =>
        (service.className === "AutoResetToFactoryAppleService" ||
          service.className === "AutoResetToFactoryService" ||
          service.className === "ContentClearingService" ||
          service.className === "ContentClearingAppleService" ||
          service.className === "SoftwareFlashingService" ||
          service.className === "AppleSoftwareFlashingService") &&
        typeof service.status === "string" &&
        service.status.toLowerCase() === "pass"
    );
    return iOSWipedByService;
  }
  const AndroidWipedByService = services.some(
    (service) =>
      (service.className === "AutoResetToFactoryService" ||
        service.className === "AutoResetToFactoryAndroidService" ||
        service.className === "ContentClearingService" ||
        service.className === "ContentClearingAndroidService" ||
        service.className === "SoftwareFlashingService") &&
      typeof service.status === "string" &&
      service.status.toLowerCase() === "pass"
  );
  return AndroidWipedByService;
});

export const getShowSessionsList = createSelector([getSelectedProcessRuns], (processRuns) => {
  if (processRuns) return false;
  return true;
});
