import * as customerEditor from "./customerEditor.actions";
import * as customerIdentity from "./customerIdentity.actions";
import * as customerFacilities from "./customerFacilities.actions";
import * as customerFacilityEditor from "./customerFacilityEditor.actions";
import * as customerUsers from "./customerUsers.actions";
import * as customerUserEditor from "./customerUserEditor.actions";
import * as customerTeams from "./customerTeams.actions";
import * as customerTeamEditor from "./customerTeamEditor.actions";
import * as customerWizard from "./customerWizard.actions";

export default {
  customerEditor,
  customerIdentity,
  customerFacilities,
  customerFacilityEditor,
  customerUsers,
  customerUserEditor,
  customerTeams,
  customerTeamEditor,
  customerWizard,
};
