import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { MarqueeText, Row, Column } from "~/global";

import "./FailCodeCategoryPicker.component.scss";

const FailCodeCategoryPicker = ({ failCodeCategories = [], selectedId, onSelect }) => {
  const setSelected = (failCodeCategoryId) => {
    if (selectedId !== failCodeCategoryId) {
      onSelect(failCodeCategoryId);
    }
  };

  return (
    <div className="fail-code-category-picker">
      <Row align="start center" className="subheader-column">
        <Column className="order">#</Column>
        <Column className="fail-code-category-name">Name</Column>
      </Row>

      <div className="scroll-container flex">
        {failCodeCategories.map((failCodeCategory, index) => {
          const isSelected = failCodeCategory.id === selectedId;

          return (
            <Row
              key={failCodeCategory.id}
              align="start center"
              className={classnames("selector-row fail-code-category-row", {
                current: isSelected,
              })}
              onClick={() => setSelected(failCodeCategory.id)}
            >
              <Column className="order">{index + 1}</Column>

              <Column className="fail-code-category-name">
                <MarqueeText
                  text={failCodeCategory.defaultName != null ? failCodeCategory.defaultName : ""}
                />
              </Column>

              <Column>
                <i className="content-pointer icon-chevron-right" />
              </Column>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

FailCodeCategoryPicker.propTypes = {
  failCodeCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.object.isRequired,
      failFast: PropTypes.bool.isRequired,
      defaultName: PropTypes.string.isRequired,
    })
  ),
  selectedId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default FailCodeCategoryPicker;
