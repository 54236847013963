import React from "react";
import { Redirect } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";

import { useAppSelector } from "~/hooks";
import selectors from "~/selectors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- React Router does not provide a props type for the CompatRoute
interface Props extends Record<string, any> {
  appName: string;
}

const RouteWithPermission = ({ appName, ...rest }: Props) => {
  const isAppAvailable = useAppSelector((state) =>
    selectors.session.isAppAvailable(state, appName)
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (isAppAvailable) return <CompatRoute {...rest} />;

  return <Redirect to="/" />;
};

export default RouteWithPermission;
