import React from "react";
import PropTypes from "prop-types";

import "./DetailsPage.scss";

const DetailsPage = (props) => {
  const { children, pageTitle, ...rest } = props;
  return (
    <div className="details-page" {...rest}>
      {pageTitle && <div className="details-page-title">{pageTitle}</div>}
      <div className="details-page-body">{children}</div>
    </div>
  );
};

DetailsPage.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.node,
};

export default DetailsPage;
