import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { message } from "antd";
import { AsciSpinner, DialogConfirmation, DialogMessage, Page } from "~/global";
import { ServiceSuiteEditor, ProgramEditor } from "~/programs-management";

import { useAppSelector } from "~/hooks";
import moduleActions from "~/programs-management/actions";
import moduleSelectors from "../../selectors";
import actions from "~/actions";
import selectors from "~/selectors";
import { selectors as workflowSelectors } from "~/workflow/redux";
import { getActiveCustomer } from "~/selectors/session.selectors";

import "./ProgramEditorPage.component.scss";

const ProgramEditorPage = (props) => {
  const [saveMessage, setSaveMessage] = useState("");
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [csid, setCsid] = useState("");
  const workflow = useSelector(workflowSelectors.getWorkflow);
  const { processNames } = useAppSelector(getActiveCustomer) ?? { processNames: [] };
  const { processFlows } = useAppSelector(getActiveCustomer) ?? { processFlows: [] };

  useEffect(() => {
    props.fetchSystemFields();
    props.fetchSystemPermissions();
    props.fetchServicesTemplates();
    props.fetchPrograms();
    props.fetchTestSuites();
    setCsid(props.csid);
    return () => props.clearProgramEdition();
  }, []);

  useEffect(() => {
    if (csid.length > 0 && props.csid != csid) {
      props.history.push("/programs");
    }
  }, [props.csid]);

  useEffect(() => {
    if (!props.isLoading && saveMessage.length > 0 && props.programErrors == null) {
      message.success(saveMessage, 2.5);
      setSaveMessage("");
      setAlreadySaved(true);
    }
    if (!props.isLoading && props.programErrors != null) {
      message.error(props.programErrors || "Request didn't complete successfully", 2.5);
      setSaveMessage("");
    }
  }, [props.isLoading]);

  useEffect(() => {
    const { identifier } = props.match.params;
    if (identifier == null) {
      props.initProgram(identifier);
    } else {
      props.fetchProgram(identifier);
    }
  }, [props.match.params.identifier]);

  const onProgramCancelled = () => {
    props.history.push("/programs");
  };

  const onClickSaveProgram = async (program) => {
    if (!program.createdOn) {
      props.isLoading && message.loading("Action in progress..", 1);
      const response = await props.createProgram(program);
      if (response) {
        setSaveMessage("Program created successfully");
        props.history.push(`/programs/${program.identifier}`);
      }
    } else {
      props.isLoading && message.loading("Action in progress..", 1);
      props.saveProgram(program);
      setSaveMessage("Program updated successfully");
    }
  };

  const unSavedProgram = (program) => {
    props.updateProgram(program);

    props.showDialog({
      modal: false,
      width: "450px",
      title: (
        <div className="row centered spaced text-color--error">
          <i className="icon-exclamation-triangle horizontal-label-margin" />
          Program not saved
        </div>
      ),
      content: (close) => (
        <DialogMessage close={() => close()}>
          <div className="column centered">
            <p className="align-center">
              All fields marked with an asterisk (*) are required. Fullfill required fields before
              saving changes.
            </p>
          </div>
        </DialogMessage>
      ),
    });
  };

  const onClickDeleteServiceSuite = (serviceSuite) => {
    const requiredCompleted =
      props.program.identifier.length > 0 && props.program.displayName.en.length > 0;

    props.showDialog({
      title: (
        <div className="row centered spaced text-color--error">
          <i className="icon-exclamation-triangle horizontal-label-margin" />
          {!requiredCompleted ? "Action Needed" : "Delete Service Suite"}
        </div>
      ),
      width: "450px",
      modal: true,
      content: (close) => (
        <DialogConfirmation
          onCancel={() => close()}
          onConfirm={() => {
            close();
            props.onClickDeleteServiceSuite(serviceSuite.identifier);
            message.loading("Action in progress..", props.isLoading ? 0 : -1);
            setSaveMessage("Service Suite deleted successfully");
          }}
          textButtonConfirm="Delete"
        >
          <p className="align-center">
            {!requiredCompleted
              ? "All fields marked with an asterisk (*) and at least one Service Suite are required. Fullfill required fields to make Delete effective."
              : "Are you sure you want to delete this Service Suite?"}
          </p>
        </DialogConfirmation>
      ),
    });
  };

  const updateProgramServiceSuites = (serviceSuites) => {
    const program = {
      ...props.program,
      serviceSuites,
    };
    if (program.identifier.length === 0 || program.displayName.en.length === 0) {
      if (serviceSuites.length == 0) {
        unSavedProgram(program);
      } else {
        props.updateProgram(program);
      }
    } else {
      onClickSaveProgram(program);
    }
  };

  const onClickSaveServiceSuite = () => {
    const serviceSuites = ([...props.program.serviceSuites] || []).map((serviceSuite) => {
      if (serviceSuite.identifier === props.serviceSuite.identifier) {
        serviceSuite = { ...props.serviceSuite, workflow };
      }
      return serviceSuite;
    });
    updateProgramServiceSuites(serviceSuites);
  };

  const onAddServiceSuite = (serviceSuite) => {
    const serviceSuites = ([...props.program.serviceSuites] || []).concat(serviceSuite);
    const updateService = serviceSuites.map((serviceSuite) => {
      if (serviceSuite.identifier === props.serviceSuite.identifier) {
        serviceSuite = { ...props.serviceSuite, workflow };
      }
      return serviceSuite;
    });
    updateProgramServiceSuites(updateService);
  };

  return (
    <Page>
      {props.currentView == "program-editor" ? (
        <ProgramEditor
          createFromSuite={props.createFromSuite}
          createServiceSuiteFromTemplate={props.createServiceSuiteFromTemplate}
          hideDialog={props.hideDialog}
          identifierErrors={props.identifierErrors}
          displayNameErrors={props.displayNameErrors}
          onCancel={onProgramCancelled}
          onChangeDisplayName={props.onChangeProgramDisplayName}
          onChangeIdentifier={props.onChangeProgramIdentifier}
          onClickDelete={onClickDeleteServiceSuite}
          onClickEdit={(serviceSuite) => props.setServiceSuite(serviceSuite)}
          onClickSaveProgram={() => onClickSaveProgram(props.program)}
          program={props.program}
          programDisplayName={props.programDisplayName}
          saveDisabled={props.programSaveDisabled}
          serviceSuites={props.serviceSuites}
          serviceSuitesAllPrograms={props.serviceSuitesAllPrograms}
          serviceSuiteTemplates={props.serviceSuiteTemplates}
          showDialog={props.showDialog}
          validateProgramIdentifier={props.validateProgramIdentifier}
          alreadySaved={alreadySaved}
        />
      ) : (
        <div />
      )}

      {props.currentView == "service-suite-editor" ? (
        <ServiceSuiteEditor
          diagnosticDeviceArgumentsByName={props.diagnosticDeviceArgumentsByName}
          identifiers={props.serviceSuiteIdentifiers}
          minimumIdentifiers={props.minimumIdentifiers}
          names={props.serviceSuiteDisplayName}
          onAdd={onAddServiceSuite}
          onClearEdition={props.onCancelServiceSuiteEdition}
          onOrderChanged={(serviceIdentifiers) => props.onOrderChanged(serviceIdentifiers)}
          onSave={onClickSaveServiceSuite}
          onServiceArgumentChanged={props.onServiceArgumentChanged}
          onServiceToggled={props.onServiceToggled}
          onTestSuiteChanged={props.onTestSuiteChanged}
          requiredIdentifiers={props.requiredIdentifiers}
          customPermissions={props.customPermissions}
          saveDisabled={props.serviceSuiteSaveDisabled}
          serviceSuite={props.serviceSuite}
          serviceSuiteDidChange={props.serviceSuiteDidChange}
          testSuitesAndroid={props.testSuitesAndroid}
          testSuitesIos={props.testSuitesIos}
          updateServiceSuite={props.updateServiceSuite}
          serviceSuiteRequireFilled={props.serviceSuiteRequireFilled}
          // Fails code Priority Groups
          priorityGroups={props.priorityGroups}
          fetchGroupPriorities={props.fetchGroupPriorities}
          processNames={processNames}
          processFlows={processFlows}
        />
      ) : (
        <div />
      )}

      <AsciSpinner visible={props.isLoading} />
    </Page>
  );
};

const stateToProps = (state) => ({
  // Program
  currentView: state.modules.programManagement.programEditor.currentView,
  identifierErrors: state.modules.programManagement.programEditor.identifierErrors,
  displayNameErrors: state.modules.programManagement.programEditor.displayNameErrors,
  isLoading: state.modules.programManagement.programEditor.isLoading,
  program: moduleSelectors.programEditor.getUpdatedProgram(state),
  programDidChange: moduleSelectors.programEditor.getProgramDidChange(state),
  programDisplayName: moduleSelectors.programEditor.getDisplayName(state),
  programErrors: state.modules.programManagement.programEditor.errors,
  programSaveDisabled: moduleSelectors.programEditor.getSaveDisabled(state),
  serviceSuites: moduleSelectors.programEditor.getServiceSuites(state),
  // Service Suites
  diagnosticDeviceArgumentsByName:
    moduleSelectors.serviceSuiteEditor.getDiagnosticDeviceArgumentsByName(state),
  minimumIdentifiers: moduleSelectors.systemFields.getMinimumIdentifiers(state),
  programs: state.programs.items,
  requiredIdentifiers: moduleSelectors.systemFields.getRequiredIdentifiers(state),
  customPermissions: moduleSelectors.systemPermissions.getCustomPermissions(state),
  serviceSuite: moduleSelectors.serviceSuiteEditor.getUpdatedServiceSuite(state),
  serviceSuiteDidChange: moduleSelectors.serviceSuiteEditor.getServiceSuiteDidChange(state),
  serviceSuiteDisplayName: moduleSelectors.serviceSuiteEditor.getDisplayName(state),
  serviceSuiteIdentifiers: moduleSelectors.programEditor.getServiceSuiteIdentifiers(state),
  serviceSuitesAllPrograms: moduleSelectors.serviceSuiteEditor.getServiceSuitesAllPrograms(state),
  serviceSuiteSaveDisabled: moduleSelectors.serviceSuiteEditor.saveDisabled(state),
  serviceSuiteRequireFilled: moduleSelectors.serviceSuiteEditor.getRequiredIsFilled(state),
  serviceSuiteTemplates: state.modules.programManagement.serviceSuiteTemplates.items,
  testSuitesAndroid: selectors.testSuites.getAndroidTestSuitesByTsid(state),
  testSuitesIos: selectors.testSuites.getIosTestSuitesByTsid(state),
  csid: state.customer.activeCustomerId,
  // Fails code Priority Groups
  priorityGroups: state.failCodePriorityGroups.items,
});

const dispatchToProps = {
  // Program
  clearProgramEdition: moduleActions.programEditor.clearProgramEdition,
  createFromSuite: moduleActions.serviceSuiteEditor.createFromSuite,
  createProgram: moduleActions.programEditor.createProgram,
  createServiceSuiteFromTemplate: moduleActions.serviceSuiteEditor.createFromTemplate,
  fetchProgram: moduleActions.programEditor.fetchProgram,
  initProgram: moduleActions.programEditor.initProgram,
  onChangeProgramDisplayName: moduleActions.programEditor.onChangeDisplayName,
  onChangeProgramIdentifier: moduleActions.programEditor.onChangeProgramIdentifier,
  onClickDeleteServiceSuite: moduleActions.programEditor.onClickDeleteServiceSuite,
  saveProgram: moduleActions.programEditor.saveProgram,
  updateProgram: moduleActions.programEditor.updateProgram,
  validateProgramIdentifier: moduleActions.programEditor.validateProgramIdentifier,
  // Service Suites
  fetchPrograms: actions.programs.fetchPrograms,
  fetchServicesTemplates: moduleActions.serviceSuiteTemplates.fetchServicesTemplates,
  fetchSystemFields: moduleActions.systemFields.fetchSystemFields,
  fetchSystemPermissions: moduleActions.systemPermissions.fetchSystemPermissions,
  fetchTestSuites: actions.testSuites.fetchTestSuites,
  onCancelServiceSuiteEdition: moduleActions.serviceSuiteEditor.clearServiceSuiteEdition,
  onOrderChanged: moduleActions.serviceSuiteEditor.onOrderChanged,
  onServiceArgumentChanged: moduleActions.serviceSuiteEditor.onServiceArgumentChanged,
  onServiceToggled: moduleActions.serviceSuiteEditor.onServiceToggled,
  onTestSuiteChanged: moduleActions.serviceSuiteEditor.onTestSuiteChanged,
  setServiceSuite: moduleActions.serviceSuiteEditor.setServiceSuite,
  updateServiceSuite: moduleActions.serviceSuiteEditor.updateServiceSuite,

  hideDialog: actions.dialog.hide,
  showDialog: actions.dialog.show,

  // Fails code Priority Groups
  fetchGroupPriorities: actions.failCodePriorityGroups.fetchFailCodePriorityGroups,
};

export default connect(stateToProps, dispatchToProps)(ProgramEditorPage);
