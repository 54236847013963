import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";

import { AsciButton } from "~/global";
import { getRegexValidation } from "~/global/utils";

const DialogDuplicateDefinition = ({ onSave, onCancel }) => {
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const nameInput = useRef(null);

  useEffect(() => {
    nameInput.current.focus();
  }, [nameInput]);

  const isValid = (name = "") => getRegexValidation("testConfigName", name);

  const save = (name = "") => {
    name = name.trim();
    if (isValid(name)) {
      setIsSaving(true);
      onSave(name);
    } else {
      setError(
        "The name must be 2-30 characters and can use numbers, letters, spaces and the special characters /#_-+()."
      );
    }
  };

  return (
    <div className="layout-column">
      <div className="layout-row layout-row--start-center">
        <div className="flex flex--noshrink margin-right--15">Enter a name:</div>

        <TextField
          id="input-duplicate-name"
          type="text"
          className="margin-bottom--10"
          aria-label="input-duplicate-name"
          placeholder="New name"
          tabIndex={1}
          error={!!error}
          errorText={error}
          disabled={isSaving}
          onKeyPress={(e) => e.key === "Enter" && save(nameInput.current.value)}
          ref={nameInput}
        />
      </div>

      <div className="dialog-actions layout-row layout-row--end-center">
        <AsciButton color="white" tabIndex={2} onClick={onCancel}>
          Cancel
        </AsciButton>

        <AsciButton
          color="blue"
          tabIndex={3}
          onClick={() => save(nameInput.current.value)}
          disabled={isSaving}
        >
          Create Duplicate
        </AsciButton>
      </div>
    </div>
  );
};

DialogDuplicateDefinition.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DialogDuplicateDefinition;
