import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "taskSender",
  (workstationIds, taskType, payload) =>
    WorkstationManagement.POST("/tasks", { workstationIds, taskType, payload }).then(
      (response) => response.payload
    )
);
