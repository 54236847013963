import { createSelector } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";

const getProgramOriginal = (state) => state.modules.programManagement.programEditor.original;
const getProgramModifications = (state) =>
  state.modules.programManagement.programEditor.modifications;
const getLanguages = (state) => state.languages.items;
const getProgramIsLoading = (state) => state.modules.programManagement.programEditor.isLoading;
const getValidationErrors = (state) =>
  state.modules.programManagement.programEditor.validationErrors;
const getDisplayNameErrors = (state) =>
  state.modules.programManagement.programEditor.displayNameErrors;

export const getUpdatedProgram = createSelector(
  [getProgramOriginal, getProgramModifications],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getDisplayName = createSelector(
  [getUpdatedProgram, getLanguages],
  (program, languages) => {
    if (program.displayName != null) {
      return languages.reduce((acc, language) => {
        const value = program.displayName[language.value];
        acc[language.value] = {
          value,
          name: language.name,
        };

        return acc;
      }, {});
    }
    return languages.reduce((acc, language) => {
      acc[language.value] = { value: "", name: language.name };
      return acc;
    }, {});
  }
);

export const getIdentifier = createSelector(
  [getUpdatedProgram],
  (program) => program.identifier || ""
);

export const getEnglishNameValue = createSelector([getUpdatedProgram], (program) =>
  program.displayName != null ? program.displayName.en || "" : ""
);

export const getProgramDidChange = createSelector(
  [getProgramOriginal, getUpdatedProgram],
  (original, updatedProgram) => {
    const unmodified = isEqual(updatedProgram, original);
    return !unmodified;
  }
);

export const getServiceSuites = createSelector(
  [getUpdatedProgram],
  (program) => program.serviceSuites || []
);

export const getServiceSuiteIdentifiers = createSelector([getServiceSuites], (serviceSuites) => {
  const currentServiceSuites = serviceSuites.map((serviceSuite) => serviceSuite.identifier);
  return currentServiceSuites;
});

export const getRequiredCompleted = createSelector(
  [getEnglishNameValue, getIdentifier],
  (englishName, identifier) => englishName.length > 0 && identifier.length > 0
);

export const getSaveDisabled = createSelector(
  [
    getRequiredCompleted,
    getProgramDidChange,
    getProgramIsLoading,
    getValidationErrors,
    getDisplayNameErrors,
  ],
  (requiredCompleted, didChange, programIsLoading, validationErrors, displayNameErrors) => {
    const langKeys = Object.keys(displayNameErrors);
    const displayNameError = langKeys.reduce((acc, langKey) => {
      const error = displayNameErrors[langKey];

      if (error) {
        acc = error;
      }

      return acc;
    }, false);

    return (
      !requiredCompleted ||
      !didChange ||
      programIsLoading ||
      validationErrors != null ||
      displayNameError
    );
  }
);
