import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./LayoutContainer.component.scss";

const validAlignments = ["start", "center", "end", "space-between", "space-around"];

const LayoutContainer = ({
  className,
  style,
  direction,
  basis,
  grow,
  shrink,
  align,
  flex,
  ...rest
}) => {
  const customStyle = { ...style };
  if (basis) {
    customStyle.flexBasis = basis;
  }
  if (grow) {
    customStyle.flexGrow = grow;
  }
  if (shrink) {
    customStyle.flexShrink = shrink;
  }

  const getAlignClasses = () => {
    if (align) {
      const classes = [];
      const parts = align.split(" ");
      const main = parts[0];
      const cross = parts[1];

      if (validAlignments.includes(main)) {
        classes.push(`main-${main}`);
      }
      if (validAlignments.includes(cross)) {
        classes.push(`cross-${cross}`);
      }
      return classes;
    }
  };

  return (
    <div
      className={classnames(
        "cc-layout-container",
        `direction-${direction}`,
        className,
        getAlignClasses(),
        {
          flex,
        }
      )}
      style={customStyle}
      {...rest}
    />
  );
};

LayoutContainer.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  align: PropTypes.string,
  basis: PropTypes.string,
  grow: PropTypes.string,
  shrink: PropTypes.string,
  flex: PropTypes.bool,
};

LayoutContainer.defaultProps = {
  flex: false,
};

const Row = (props) => <LayoutContainer direction="row" {...props} />;
const Column = (props) => <LayoutContainer direction="column" {...props} />;

export default LayoutContainer;
export { Row, Column };
