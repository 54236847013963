import React, { Component } from "react";
import PropTypes from "prop-types";

import CertificateContainer from "./CertificateContainer.component";
import CertificateDescription from "./CertificateDescription.component";
import CertificateDiagnosticBody from "./CertificateDiagnosticBody.component";
import CertificateFooter from "./CertificateFooter.component";
import { SectionHeader } from "~/global";
import { formatDate } from "~/global/utils";
import DiagnosticLogo from "~/media/printable/certificates/diagnostic-seal@2x.png";

export default class CertificateDiagnostic extends Component {
  static propTypes = {
    process: PropTypes.object.isRequired,
    deviceInfo: PropTypes.object.isRequired,
    desktopInformation: PropTypes.object.isRequired,
    processResult: PropTypes.string.isRequired,
    services: PropTypes.array,
    tests: PropTypes.array,
  };

  static defaultProps = {
    services: [],
    tests: [],
  };

  render() {
    return (
      <CertificateContainer>
        <SectionHeader className="certificate-header" title="Diagnostic Certificate" />

        <CertificateDescription logo={DiagnosticLogo}>
          <h2>Asset Science Diagnostic Certificate</h2>
          <p>
            Asset Science certification means the device has been tested using the highest quality
            diagnostics in the market place. When you receive a device certified for diagnostics by
            Asset Science, you can be confident that you have an accurate and consistent diagnosis,
            giving you control over process and quality.
          </p>
          <p className="bolder-text">Session ID: {this.props.process.sessionIDHumanReadable}</p>
          <p className="bolder-text">Date: {formatDate(this.props.process.endDateTime)}</p>
        </CertificateDescription>

        <CertificateDiagnosticBody
          process={this.props.process}
          deviceInfo={this.props.deviceInfo}
          desktopInformation={this.props.desktopInformation}
          processResult={this.props.processResult}
          services={this.props.services}
          tests={this.props.tests}
        />

        <CertificateFooter />
      </CertificateContainer>
    );
  }
}
