import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchTestProcessingTimesBegin = createAction(
  "canned-reports/FETCH_TEST_PROCESSING_TIMES_BEGIN"
);
export const fetchTestProcessingTimesSuccess = createAction(
  "canned-reports/FETCH_TEST_PROCESSING_TIMES_SUCCESS"
);
export const fetchTestProcessingTimesError = createAction(
  "canned-reports/FETCH_TEST_PROCESSING_TIMES_ERROR"
);

export const fetchTestProcessingTimes = (facilityId, programId, from, to) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        testProcessingTimes(
            facility: "${facilityId === "all" ? "" : facilityId}",
            program: "${programId === "all" ? "" : programId}",
            from: "${from || ""}",
            to: "${to || ""}"
        ) {
            MedianMean,
            ChartData {
                Test,
                Configuration,
                Total,
                Count,
                Minimum,
                Mean,
                Maximum
            }
        }
    }`);

  dispatch(fetchTestProcessingTimesBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const testProcessingTimes =
      (((response || {}).payload || {}).data || {}).testProcessingTimes || [];

    dispatch(fetchTestProcessingTimesSuccess({ testProcessingTimes }));
  } catch (e) {
    if (e instanceof Error) throw e;

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";

    dispatch(fetchTestProcessingTimesError({ errors: message }));
  }
};
