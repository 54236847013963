import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDate } from "~/global/utils";
import { AppsCollection, AsciSpinner } from "~/global";
import DashboardWidgets from "~/home/DashboardWidgets/DashboardWidgets.component";

import actions from "~/actions";
import selectors from "~/selectors";

import "./HomePage.component.scss";

const HomePage = ({
  canSeeNewContent,
  fetchDocuments,
  documents,
  isUserProfileLoading,
  isUpdatingCurrentCustomer,
  availableApps,
}) => {
  const { t } = useTranslation(["home"]);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  useEffect(() => {
    if (canSeeNewContent) {
      fetchDocuments();
    }
  }, []);

  useEffect(() => {
    setIsLoadingSession(isUserProfileLoading || isUpdatingCurrentCustomer);
  }, [isUserProfileLoading, isUpdatingCurrentCustomer]);

  if (isLoadingSession) {
    return <AsciSpinner visible />;
  }
  if (availableApps.length === 0) {
    return <div>{t("no-available-applications")}</div>;
  }

  return (
    <div className="panel-container home-page">
      <div className="section-wrapper layout-row layout-row--space-between-center full-width">
        <div className="content-box full-width">
          <h3>{t("your-performance-indicators")}</h3>
          <DashboardWidgets />
        </div>

        {canSeeNewContent && (
          <div className="section-wrapper cc-layout-container direction-column">
            <div className="content-box new-content">
              <h3>{t("new-content")}</h3>
              <div className="apps-collection home-documentation-body">
                {documents.map((doc, index) => (
                  <a
                    key={`new-content${index}`}
                    rel="noopener noreferrer"
                    href={doc.url}
                    target="_blank"
                    className="content-box new-content-box"
                  >
                    <div className="row box-content">
                      <i className="icon-file icon-file-pdf-o" />
                      <div className="column">
                        <div className="doc-name">{doc.name}</div>
                        <div className="doc-date">{formatDate(doc.addedOn)}</div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="full-width row-to-column">
        <div className="section-wrapper">
          <div className="content-box applications">
            <h3>{t("your-applications-and-tools")}</h3>
            <AppsCollection originId="home-apps" size={60} showDescription />
          </div>
        </div>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  canSeeNewContent: PropTypes.bool,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
      addedOn: PropTypes.string,
    })
  ).isRequired,
  fetchDocuments: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  canSeeNewContent: selectors.session.hasPermission(state, "GET", "/documentation"),
  documents: state.documentation.homeDocuments,
  isUserProfileLoading: state.session.isUserProfileLoading,
  isUpdatingCurrentCustomer: state.customer.isUpdatingCurrentCustomer,
  availableApps: selectors.session.getAvailableApps(state),
});

const dispatchToProps = {
  fetchDocuments: actions.documentation.fetchHomeDocuments,
};

export default connect(stateToProps, dispatchToProps)(HomePage);
