import React from "react";
import { HolderOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "antd";
import PropTypes from "prop-types";
import { ExpansionList, ExpansionPanel } from "react-md";

import { Row } from "~/global";
import ServiceDetails from "./ServiceDetails/ServiceDetails.component";

export const SortableService = ({ service, template, removeService }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({
      id: service.id,
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Row className="serviceDetails-expansion" align="start center">
        <Button
          type="text"
          icon={<HolderOutlined />}
          size="large"
          ref={setActivatorNodeRef}
          {...listeners} // eslint-disable-line react/jsx-props-no-spreading
          {...attributes} // eslint-disable-line react/jsx-props-no-spreading
        />

        <ExpansionList className="full-width">
          <ExpansionPanel
            className="service-details-expansion-panel full-width"
            label={<div className="serviceDetails-title">{service.displayName.en}</div>}
            footer={null}
            expanderIcon={<i className="icon-chevron-down" />}
            headerClassName="cc-section-header table-header"
            contentClassName="cc-fullwidth-panel"
          >
            <Row className="serviceDetails-row">
              <ServiceDetails service={service} template={template} removeService={removeService} />
            </Row>
          </ExpansionPanel>
        </ExpansionList>
      </Row>
    </div>
  );
};

SortableService.propTypes = {
  service: PropTypes.shape({
    identifier: PropTypes.string,
    className: PropTypes.string,
    isOptional: PropTypes.bool,
    isEnabled: PropTypes.bool,
    displayName: PropTypes.shape({
      en: PropTypes.string,
    }),
    arguments: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  template: PropTypes.shape({
    identifier: PropTypes.string,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string,
        className: PropTypes.string,
        isOptional: PropTypes.bool,
        isEnabled: PropTypes.bool,
        displayName: PropTypes.shape({
          en: PropTypes.string,
        }),
        arguments: PropTypes.array,
        id: PropTypes.string,
      })
    ),
    id: PropTypes.string,
  }).isRequired,
  removeService: PropTypes.func.isRequired,
};
