import React from "react";

import { Row, Column } from "~/global";
import AnalyticsButton from "~/analytics/components/AnalyticsButton/AnalyticsButton.component";

import dashboardImg from "~/media/images/thumbnails/Dashboard.png";
import reportingImg from "~/media/images/thumbnails/Data-Export.png";
import processesByGroupImg from "~/media/images/thumbnails/ICE-99.png";
import devicesProcessedImg from "~/media/images/thumbnails/ICE-100.png";
import testProcessingTimesImg from "~/media/images/thumbnails/ICE-103.png";
import auditResultsPerTestImg from "~/media/images/thumbnails/ICE-104.png";
import auditResultsImg from "~/media/images/thumbnails/ICE-106.png";

import "./AnalyticsPage.component.scss";

const AnalyticsPage = () => (
  <Column className="analytics-page full-width" flex align="start center">
    <div className="panel-container">
      <Column className="analytics-section">
        <Column className="section-header">
          <Row className="section-title">Daily Operations Dashboard &amp; Data Export</Row>
          <Row className="section-description">View KPIs or download the data.</Row>
        </Column>

        <Row className="app-list layout-wrap">
          <AnalyticsButton
            urlName="dashboard"
            label="Daily Operations Dashboard"
            imgSrc={dashboardImg}
          />
          <AnalyticsButton urlName="reporting" label="Data Export Tool" imgSrc={reportingImg} />
        </Row>
      </Column>

      <Column className="analytics-section">
        <Column className="section-header">
          <Row className="section-title">Canned Reports</Row>
          <Row className="section-description">
            Select a type of canned report from the list below.
          </Row>
        </Column>

        <Row className="app-list layout-wrap">
          <AnalyticsButton
            urlName="audit-results"
            label="Audit Results"
            secondaryLabel="Bubble Chart"
            imgSrc={auditResultsImg}
          />

          <AnalyticsButton
            urlName="devices-processed-by-group"
            label="Devices Processed by Group"
            secondaryLabel="Bubble Chart"
            imgSrc={processesByGroupImg}
          />

          <AnalyticsButton
            urlName="audit-results-per-test"
            label="Audit Results Per Test"
            secondaryLabel="Bar Chart"
            imgSrc={auditResultsPerTestImg}
          />

          <AnalyticsButton
            urlName="devices-processed"
            label="Devices Processed"
            secondaryLabel="Bar Chart"
            imgSrc={devicesProcessedImg}
          />

          <AnalyticsButton
            urlName="test-processing-times"
            label="Test Processing Times"
            secondaryLabel="Bar Chart"
            imgSrc={testProcessingTimesImg}
          />
        </Row>
      </Column>
    </div>
  </Column>
);

export default AnalyticsPage;
