import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const ProgramManagementIcon = ({ size, customId }) => (
  <SVGIcon title="Program Management" viewBox="0 0 31.88 38.24" size={size}>
    <defs>
      <linearGradient
        id={customId}
        x1="-1526.07"
        y1="22.4"
        x2="-1500.69"
        y2="22.4"
        gradientTransform="matrix(-1 0 0 1 -1500.69 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#036" />
        <stop offset="0.7" stopColor="#9c3" />
        <stop offset="1" stopColor="#9c3" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M0 10v24.78a3.47 3.47 0 0 0 3.46 3.46h18.45a3.48 3.48 0 0 0 3.47-3.46v-18.7a8.26 8.26 0 0 1-1-.06v18.76a2.47 2.47 0 0 1-2.47 2.46H3.46A2.47 2.47 0 0 1 1 34.78V10a2.46 2.46 0 0 1 2.46-2.43h12.43a8.26 8.26 0 0 1-.06-1H3.46A3.47 3.47 0 0 0 0 10z"
        fill={`url(#${customId})`}
      />

      <rect
        fill="#036"
        x="5.86"
        y="15.57"
        width="12.85"
        height="1.61"
        rx="0.8"
        ry="0.8"
        transform="rotate(180 12.285 16.375)"
      />
      <rect
        fill="#036"
        x="5.86"
        y="21.06"
        width="12.85"
        height="1.61"
        rx="0.8"
        ry="0.8"
        transform="rotate(180 12.285 21.86)"
      />
      <rect
        fill="#036"
        x="5.86"
        y="26.55"
        width="12.85"
        height="1.61"
        rx="0.8"
        ry="0.8"
        transform="rotate(-180 12.285 27.35)"
      />

      <path
        d="M19.82 12.12c-.06 0-.08 0-.07-.07s.13-.39.19-.59l.63-2c.15-.5.31-1 .46-1.48a.73.73 0 0 1 .17-.28q3.59-3.53 7.16-7.11l.08-.09h.31a3.52 3.52 0 0 1 2.59 2.59c0 .12.09.24 0 .36l-7.25 7.24a.28.28 0 0 1-.14.09l-1.18.37-1.66.53z"
        fill="none"
        stroke="#003764"
        strokeMiterlimit="10"
      />
    </g>
  </SVGIcon>
);

ProgramManagementIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default ProgramManagementIcon;
