import React, { useEffect, useState, useRef } from "react";
import { message, notification } from "antd";
import keys from "lodash/keys";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { PrimaryTitle } from "~/ui-common";
import {
  Page,
  SectionHeader,
  AsciSpinner,
  DialogConfirmation,
  AsciButton,
  DialogMessage,
  Row,
} from "~/global";
import useErrorState from "~/hooks/useErrorState";
import TestSuiteOrderList from "../TestSuiteOrderList/TestSuiteOrderList.component";
import TestSuiteParametersEditor from "../TestSuiteParametersEditor/TestSuiteParametersEditor.component";
import TestSelectionDialog from "../TestSelectionDialog/TestSelectionDialog.component";
import { getRegexValidation } from "~/global/utils";

import actions from "~/actions";
import selectors from "~/selectors";
import moduleActions from "../../actions";
import * as moduleSelectors from "../../selectors";

import "./TestSuiteEditor.component.scss";

const TestSuitesEditor = ({
  isLoadingTestSuite,
  isLoadingDefinitions,
  isLoadingCategories,
  isUpdating,
  isCreating,
  testSuite,
  modifyTestSuite,
  testDefinitions,
  categories,
  supportedPlatforms,
  hasChanged,
  fetchTestCategories,
  fetchTestSuite,
  fetchTestDefinitions,
  initEmptyTestSuite,
  showDialog,
  hideDialog,
  createTestSuite,
  updateTestSuite,
  csid,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const FAIL_CODE_DISPLAY_MODES = ["CODE_ONLY", "DESCRIPTION_ONLY", "CODE_AND_DESCRIPTION"];
  const DEVICE_SUPPORT_LEVELS = ["ASSET_SCIENCE_APPROVED", "CUSTOMER_APPROVED", "UNSUPPORTED"];

  const [isNewTestSuite, setIsNewTestSuite] = useState(false);

  const selectedTestsCount = (testSuite.testIdentifiers || []).length;

  const [initialCsid, setInitialCsid] = useState("");

  useEffect(() => {
    if (initialCsid.length > 0 && csid !== initialCsid) {
      goBackToTestSuitesPage();
    }
  }, [csid]);

  const prevCsidRef = useRef();
  useEffect(() => {
    if (prevCsidRef.current !== csid) {
      initTestSuite();
    }
    prevCsidRef.current = csid;
    setInitialCsid(csid);
    fetchTestCategories();
    fetchTestDefinitions(testSuite.platform);
  }, [csid, fetchTestCategories, fetchTestDefinitions, initTestSuite, testSuite.platform]);

  const initTestSuite = async () => {
    const tsid = params.tsid || "";

    if (tsid !== "") {
      // edit existing test suite
      await fetchTestSuite(tsid);

      if (errors.missing) {
        showDialog(
          {
            title: "Invalid test suite",
            width: "calc(50% - 100px)",
            content: (close) => <DialogMessage close={close}>{errors.missing}</DialogMessage>,
          },
          goBackToTestSuitesPage
        );
      }

      setIsNewTestSuite(false);
    } else {
      // create new test suite
      initEmptyTestSuite();
      setIsNewTestSuite(true);
    }
  };

  const goBackToTestSuitesPage = () => {
    navigate("/test-suites");
  };

  const onExit = () => {
    if (!hasChanged) {
      goBackToTestSuitesPage();
    } else {
      showDialog({
        title: "Confirm Test Suite Edition",
        width: "calc(50% - 100px)",
        modal: true,
        content: (
          <div>
            <p>Do you want to cancel the test suite edition and discard the changes?</p>

            <div className="dialog-actions layout-row layout-row--end-center">
              <AsciButton color="white" onClick={hideDialog}>
                Continue Edition
              </AsciButton>

              <AsciButton
                color="red"
                onClick={() => {
                  hideDialog();
                  goBackToTestSuitesPage();
                }}
              >
                Discard and Exit
              </AsciButton>

              <AsciButton color="blue" disabled={hasErrors()} onClick={onSave}>
                Save and Exit
              </AsciButton>
            </div>
          </div>
        ),
      });
    }
  };

  const onSave = async () => {
    if (isNewTestSuite) {
      createTestSuite(testSuite)
        .unwrap()
        .then(() => {
          void message.success("Test suite created successfully.");
          goBackToTestSuitesPage();
        })
        .catch((rejectedValue) => {
          notification.error({
            message: "An error occurred",
            description: rejectedValue.errors && <ErrorList errors={rejectedValue.errors} />,
          });
        });
    } else {
      updateTestSuite(testSuite)
        .unwrap()
        .then(() => {
          void message.success("Test suite updated successfully.");
          goBackToTestSuitesPage();
        })
        .catch((rejectedValue) => {
          notification.error({
            message: "An error occurred",
            description: rejectedValue.errors && <ErrorList errors={rejectedValue.errors} />,
          });
        });
    }
  };

  ///
  // Validation
  ///
  const MAX_TEST_SUITE_NAME_LENGTH = 999;
  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    setErrors({
      name: validateName(testSuite.name),
      platform: validatePlatform(testSuite.platform),
      identifiers: validateIdentifiers(testSuite.testIdentifiers),
      deviceSupportLevel: validateDeviceSupportLevel(testSuite.deviceSupportLevel),
      nbOperatorAudits: validateNbOperatorAudits(testSuite.nbOperatorAudits),
    });
  }, [testSuite]);

  const validateName = (value = "") => {
    if (value === "") {
      return "required";
    }

    if (value.length < 2) {
      return "at least 2 characters required";
    }

    if (value.length >= MAX_TEST_SUITE_NAME_LENGTH) {
      return `name must have a maximum of ${MAX_TEST_SUITE_NAME_LENGTH} characters`;
    }

    if (!getRegexValidation("testSuiteName", value)) {
      return "name is invalid";
    }
  };

  const validatePlatform = (value) => {
    if (value == null) {
      return "platform required";
    }
  };

  const validateIdentifiers = (identifiers) => {
    if (identifiers.length === 0) {
      return "at least one test required";
    }
  };

  const validateDeviceSupportLevel = (value) => {
    if (value === "" || value == null) {
      return "required";
    }
  };

  const validateNbOperatorAudits = (value) => {
    if (value < 0 || value > 5) {
      return "must be between 0 and 5";
    }
    return undefined;
  };

  const hasErrors = () => keys(errors).length > 0;

  const showTestSelectionDialog = () => {
    showDialog({
      title: "Tests - Build an OS-specific Test Suite",
      width: "90%",
      height: "80%",
      veryLarge: true,
      contentClassName: "layout-column",
      content: (close) => (
        <TestSelectionDialog
          close={close}
          categories={categories}
          selectedIdentifiers={testSuite.testIdentifiers}
          onChange={(identifiers) => modifyTestSuite({ testIdentifiers: identifiers })}
        />
      ),
    });
  };

  const isLoading = isLoadingTestSuite || isLoadingDefinitions || isLoadingCategories;

  let selectedDefinitions;
  if (!isLoading) {
    // index the test definitions of the test suite's platform by identifier
    const platformDefinitionByIdentifier = testDefinitions.reduce((acc, definition) => {
      if (definition.platform === testSuite.platform) {
        acc[definition.identifier] = definition;
      }
      return acc;
    }, {});

    selectedDefinitions = testSuite.testIdentifiers.map((identifier) => {
      const definition = platformDefinitionByIdentifier[identifier];
      if (definition == null) {
        console.warn(
          `missing definition for identifier ${identifier} on platform ${testSuite.platform}`
        );
        return null;
      }
      return definition;
    });
  }

  return (
    <Page className="test-suite-editor">
      {isLoading ? (
        <AsciSpinner visible />
      ) : (
        <>
          <PrimaryTitle>Test Suite Parameters</PrimaryTitle>

          <TestSuiteParametersEditor
            canEdit
            canEditPlatform={isNewTestSuite}
            errors={errors}
            name={testSuite.name}
            nbOperatorAudits={testSuite.nbOperatorAudits}
            failCodeDisplayModes={FAIL_CODE_DISPLAY_MODES}
            failCodeDisplayMode={testSuite.failCodeDisplayMode}
            deviceSupportLevels={DEVICE_SUPPORT_LEVELS}
            deviceSupportLevel={testSuite.deviceSupportLevel}
            isFailFast={testSuite.isFailFast}
            autoStart={testSuite.autoStart}
            singleAppMode={testSuite.singleAppMode}
            disableNavigationButtons={testSuite.disableNavigationButtons}
            orientationLock={testSuite.orientationLock}
            rapidTest={testSuite.rapidTest}
            supportedPlatforms={supportedPlatforms}
            platform={testSuite.platform}
            onChange={(change) => {
              // confirm that all selected test will be removed
              if (change.platform && testSuite.testIdentifiers.length > 0) {
                showDialog({
                  title: "Confirm OS change",
                  content: (close) => (
                    <DialogConfirmation
                      onCancel={close}
                      onConfirm={() => {
                        modifyTestSuite({
                          ...change,
                          testIdentifiers: [],
                        });
                        close();
                      }}
                    >
                      All previously selected tests will be removed.
                    </DialogConfirmation>
                  ),
                });
              } else {
                modifyTestSuite(change);
              }
            }}
          />

          <SectionHeader title="Test Suite" description="Drag and drop in the correct order">
            <Row align="center center">
              <p style={{ color: "white", margin: 0, marginRight: "15px" }}>
                {`${selectedTestsCount} test${selectedTestsCount === 1 ? "" : "s"} selected`}
              </p>

              <AsciButton
                color="green"
                className="no-margin"
                onClick={showTestSelectionDialog}
                disabled={testSuite.platform === "" || testSuite.platform == null}
              >
                <span>
                  {testSuite.testIdentifiers.length > 0 ? "Select" : "Add"} {testSuite.platform}{" "}
                  tests
                </span>
              </AsciButton>
            </Row>
          </SectionHeader>

          <TestSuiteOrderList
            testDefinitions={selectedDefinitions}
            onOrderChanged={(identifiers) => modifyTestSuite({ testIdentifiers: identifiers })}
            onRemoveItem={(index) => {
              const identifiers = [...testSuite.testIdentifiers];
              identifiers.splice(index, 1);
              modifyTestSuite({ testIdentifiers: identifiers });
            }}
          />

          <div className="cc-details-panel row to-end">
            <AsciButton color="white" onClick={onExit}>
              {hasChanged ? "Cancel" : "Exit"}
            </AsciButton>

            <AsciButton
              color="blue"
              disabled={!hasChanged || hasErrors() || isCreating || isUpdating}
              onClick={onSave}
            >
              Save
            </AsciButton>
          </div>
        </>
      )}
    </Page>
  );
};

const stateToProps = (state) => ({
  isLoadingTestSuite: moduleSelectors.getTestSuiteIsLoading(state),
  isLoadingDefinitions: state.testDefinitions.isLoading,
  isLoadingCategories: state.testCategories.isLoading,
  isUpdating: moduleSelectors.getTestSuiteIsUpdating(state),
  isCreating: moduleSelectors.getTestSuiteIsCreating(state),
  testSuite: moduleSelectors.getCurrentTestSuite(state),
  testDefinitions: selectors.testDefinitions.getTestDefinitions(state),
  categories: moduleSelectors.getCategoriesWithDefinitions(state),
  hasChanged: moduleSelectors.hasCurrentTestSuiteChanged(state),
  supportedPlatforms: selectors.platforms.getPlatforms(state),
  csid: state.customer.activeCustomerId,
});

const dispatchToProps = {
  // data fetching
  fetchTestCategories: actions.testCategories.fetchTestCategories,
  fetchTestDefinitions: actions.testDefinitions.fetchTestDefinitions,
  fetchTestSuite: moduleActions.fetchTestSuite,

  // test suite modifications
  modifyTestSuite: moduleActions.modifyTestSuite,
  initEmptyTestSuite: moduleActions.initEmptyTestSuite,
  createTestSuite: moduleActions.createTestSuite,
  updateTestSuite: moduleActions.updateTestSuite,

  // other
  showDialog: actions.dialog.show,
  hideDialog: actions.dialog.hide,
};

export default connect(stateToProps, dispatchToProps)(TestSuitesEditor);

// eslint-disable-next-line react/prop-types
const ErrorList = ({ errors }) => (
  <ul style={{ listStyleType: "initial", paddingLeft: 16 }}>
    {Object.entries(errors).map(([key, value]) => (
      <li key={key}>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        {key}: {value}
      </li>
    ))}
  </ul>
);
