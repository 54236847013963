import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash/collection";
import { pick } from "lodash/object";
import moduleSelectors from ".";
import { DEFAULT_TEAM } from "~/constants";

const getCustomerTeams = (state) => state.modules.customerManagement.customerTeams.items;
const getSortReverse = (state) => state.modules.customerManagement.customerTeams.sortReverse;
const getSortKey = (state) => state.modules.customerManagement.customerTeams.sortKey;
const getFacilitiesById = (state) => moduleSelectors.customerFacilities.getFacilitiesById(state);
const getCustomerUsersTeams = (state) => state.modules.customerManagement.customerTeams.users;
const getUserRoles = (state) => state.modules.customerManagement.customerUsers.roles;

export const getFlatUsersTeams = createSelector(
  [getCustomerUsersTeams, getUserRoles],
  (customerUsersTeams, getUserRoles) => {
    const defaults = {
      userid: "",
      email: "",
      hasAllFacilitiesAccess: undefined,
      facilities: [],
      firstname: "",
      lastname: "",
      name: "",
      status: 0,
    };
    const users =
      customerUsersTeams?.map((user_metadata) => {
        const meta = pick(user_metadata, Object.keys(defaults));
        const role = getUserRoles.find(
          (roles) => roles.id === `ObjectID("${user_metadata?.roleids?.[0]}")`
        );
        return {
          ...defaults,
          ...meta,
          roleName: role?.name ?? "",
          roleId: user_metadata?.roleids?.[0],
        };
      }) ?? [];

    return users;
  }
);

export const getTeamsList = createSelector(
  [getFacilitiesById, getCustomerTeams],
  (facilitiesById, customerTeams) => {
    const facilityList = customerTeams
      .filter(
        (customerTeam) =>
          facilitiesById[customerTeam.faid] && facilitiesById[customerTeam.faid].name
      )
      .map((customerTeam) => ({
        ...customerTeam,
        facilityName: facilitiesById[customerTeam.faid].name,
        usersNumber: customerTeam.usersid.length || 0,
      }));
    return facilityList;
  }
);

export const getTeamsNames = createSelector([getTeamsList], (teamsList) =>
  teamsList.map((team) => team.name)
);

export const getSortedTeams = createSelector(
  [getTeamsList, getSortReverse, getSortKey],
  (teams, sortReverse, sortKey) => {
    const sortedTeams = sortBy(teams, (team) => {
      const value = team[sortKey];
      return value == null ? "" : typeof value === "string" ? value.toLowerCase() : value;
    });
    return sortReverse ? sortedTeams.reverse() : sortedTeams;
  }
);

export const getGroupedTeams = createSelector([getSortedTeams], (teams) => {
  const defaultTeams = teams.filter((team) => team.name === DEFAULT_TEAM);
  const otherTeams = teams.filter((team) => team.name !== DEFAULT_TEAM);
  const groupedTeams = [...defaultTeams, ...otherTeams];

  return groupedTeams;
});

export const getTeamsById = createSelector([getCustomerTeams], (teams = []) => {
  const teamsById = teams.reduce((acc, team) => {
    const teamId = team.teamid != null ? team.teamid : "new";
    return {
      ...acc,
      [teamId]: team,
    };
  }, {});
  return teamsById;
});
