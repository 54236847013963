import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Select, Switch } from "antd";

import labelSelectors from "~/label-printer/selectors";
import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";
import GroupedButtons from "~/label-printer/components/designer/inspector/commons/GroupedButtons";
import ColorInput from "~/label-printer/components/designer/inspector/commons/ColorInput";

const QrCodeFieldInspector = (props) => {
  const {
    path,
    size,
    level,
    includeMargin,
    bgColor,
    fgColor,
    fitSize,
    recordingFields,
    identity,
    setShapeFieldData,
  } = props;

  const setterFactory = (key) => (data) => setShapeFieldData({ identity, key, data });
  return (
    <>
      <InspectorInput label="Recording Value">
        <Select onChange={setterFactory("path")} value={path}>
          {recordingFields.map((field) => (
            <Select.Option key={field.path} value={field.path}>
              {field.name}
            </Select.Option>
          ))}
        </Select>
      </InspectorInput>
      <InspectorInput label="Size">
        <NumberInput value={size} onChange={setterFactory("size")} unit="px" min={1} />
      </InspectorInput>
      <InspectorInput label="Background">
        <ColorInput color={bgColor} onChange={setterFactory("bgColor")} />
      </InspectorInput>
      <InspectorInput label="Foreground">
        <ColorInput color={fgColor} onChange={setterFactory("fgColor")} />
      </InspectorInput>
      <InspectorInput label="Level">
        <GroupedButtons
          options={["L", "M", "Q", "H"].map((e) => ({ label: e, value: e }))}
          selected={[level]}
          onSelectOption={setterFactory("level")}
        />
      </InspectorInput>
      <InspectorInput label="Include margin">
        <Switch checked={includeMargin} onChange={setterFactory("includeMargin")} />
      </InspectorInput>
      <InspectorInput
        label="Fit size to shape"
        title="Smallest of height or width will be considered."
      >
        <Switch checked={fitSize} onChange={setterFactory("fitSize")} />
      </InspectorInput>
    </>
  );
};

QrCodeFieldInspector.defaultProps = {};
QrCodeFieldInspector.propTypes = {
  path: PropTypes.string,
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  level: PropTypes.string,
  includeMargin: PropTypes.bool,
  fitSize: PropTypes.bool,

  identity: PropTypes.number,
  setShapeFieldData: PropTypes.func,
  recordingFields: PropTypes.array,
};

const stateToProps = (state) => ({
  recordingFields: labelSelectors.labelFields.getAvailableFields(state),
});

export default connect(stateToProps, null)(QrCodeFieldInspector);
