import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Paper, TextField } from "react-md";
import { Row, Column, SelectList } from "~/global";
import moduleAction from "~/data-import/actions/";

import "./DeviceInfo.component.scss";

const DeviceInfo = ({
  imei,
  meid,
  OEMSerialNumber,
  bluetoothMAC,
  wifiMAC,
  fetchDeviceInfos,
  deviceInfo,
  saveDeviceData,
  manufacturerFromState,
  modelNameFromState,
  modelNumberFromState,
  colorFromState,
  capacityFromState,
}) => {
  const [manufacturer, setManufacturer] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [selectedCapacity, setSelectedCapacity] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isIdentifyingInfo, setIsIdentifyingInfo] = useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  const capacityItems = ["Select...", "32 GB", "64 GB", "128 GB", "256 GB", "512 GB"];

  const colorItems = ["Select...", "Black", "Red", "White", "Gray", "Green", "Gold", "Silver"];

  useEffect(() => {
    if (manufacturer === "Apple" || manufacturer === "apple") {
      setIsAppleDevice(true);
      if (deviceInfo.models !== undefined) {
        setModelNumber(deviceInfo.models.AsciFields.ANumber);
      }
    } else {
      setIsAppleDevice(false);
    }
  }, [manufacturer]);

  useEffect(() => {
    if (
      imei !== "" ||
      meid !== "" ||
      OEMSerialNumber !== "" ||
      bluetoothMAC !== "" ||
      wifiMAC !== ""
    ) {
      if (imei !== "") {
        fetchDeviceInfos(imei);
      }

      setIsIdentifyingInfo(true);
    } else {
      setIsIdentifyingInfo(false);
    }
  }, [imei, meid, OEMSerialNumber, bluetoothMAC, wifiMAC]);

  useEffect(() => {
    let deviceData = {};

    let color = selectedColor;
    let capacity = selectedCapacity;

    if (selectedCapacity === "" || selectedCapacity === "Select...") {
      capacity = "";
    }

    if (selectedColor === "" || selectedColor === "Select...") {
      color = "";
    }

    saveDeviceData(
      (deviceData = {
        capacity,
        color,
        manufacturer,
        modelName,
        modelNumber,
      })
    );
  }, [selectedColor, selectedCapacity, manufacturer, modelNumber, modelName]);

  useEffect(() => {
    if (deviceInfo.models !== undefined) {
      setManufacturer(deviceInfo.models.HardwarePlatform.Vendor);
      setModelName(deviceInfo.models.HardwarePlatform.Model);
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (manufacturerFromState === "") {
      setManufacturer("");
    }

    if (modelNameFromState === "") {
      setModelName("");
    }

    if (modelNumber === "") {
      setModelNumber("");
    }

    if (capacityFromState === "") {
      setSelectedColor(colorItems[0]);
    }

    if (capacityFromState === "") {
      setSelectedCapacity(capacityItems[0]);
    }
  }, [
    manufacturerFromState,
    modelNameFromState,
    modelNumberFromState,
    colorFromState,
    capacityFromState,
  ]);

  return (
    <div className="device-info">
      <Row className="section-title">Device Info</Row>
      <Row>
        <Column className="device-column">
          <Row className="device-label">Manufacturer</Row>
          <TextField
            id="device-info-manufacturer"
            placeholder="Type the manufacturer"
            onChange={(value) => setManufacturer(value)}
            value={manufacturer}
            type="text"
            disabled={!isIdentifyingInfo}
            className="device-input-field"
          />
        </Column>
        <Column className="device-column">
          <Row className="device-label">Model Name</Row>
          <TextField
            id="device-info-model-name"
            placeholder="Type the Model Name"
            onChange={(value) => setModelName(value)}
            value={modelName}
            type="text"
            disabled={!isIdentifyingInfo}
            className="device-input-field"
          />
        </Column>
        <Column className="device-column">
          <Row className="device-label">Model Number</Row>
          <TextField
            id="device-info-model-number"
            placeholder="Type the Model Number"
            onChange={(value) => setModelNumber(value)}
            value={modelNumber}
            type="text"
            disabled={!isAppleDevice}
            className="device-input-field"
          />
        </Column>
        <Column className="device-column">
          <Row className="device-label">Storage Capacity</Row>
          <SelectList
            items={capacityItems}
            onChange={(value) => setSelectedCapacity(value)}
            selectedItem={selectedCapacity}
            disabled={!isIdentifyingInfo}
          />
        </Column>
        <Column className="device-column">
          <Row className="device-label">Color</Row>
          <SelectList
            items={colorItems}
            onChange={(value) => setSelectedColor(value)}
            selectedItem={selectedColor}
            disabled={!isIdentifyingInfo}
          />
        </Column>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  meid: state.modules.dataImport.importDataManual.meid,
  imei: state.modules.dataImport.importDataManual.imei,
  OEMSerialNumber: state.modules.dataImport.importDataManual.OEMSerialNumber,
  bluetoothMAC: state.modules.dataImport.importDataManual.bluetoothMAC,
  wifiMAC: state.modules.dataImport.importDataManual.wifiMAC,
  deviceInfo: state.modules.dataImport.importDataManual.deviceInfo,
  manufacturerFromState: state.modules.dataImport.importDataManual.deviceData.manufacturer,
  modelNameFromState: state.modules.dataImport.importDataManual.deviceData.modelName,
  modelNumberFromState: state.modules.dataImport.importDataManual.deviceData.modelNumber,
  capacityFromState: state.modules.dataImport.importDataManual.deviceData.capacity,
  colorFromState: state.modules.dataImport.importDataManual.deviceData.color,
});

const dispatchToProps = {
  fetchDeviceInfos: moduleAction.importDataManual.fetchDeviceInfos,
  saveDeviceData: moduleAction.importDataManual.saveDeviceData,
};

export default connect(stateToProps, dispatchToProps)(DeviceInfo);
