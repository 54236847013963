import React from "react";
import PropTypes from "prop-types";

import { AsciButton } from "~/global";

const DialogMessage = ({ children, close, textButtonClose }) => (
  <div>
    <div>{children}</div>

    <div className="dialog-actions layout-row layout-row--end-center">
      <AsciButton color="green" onClick={close}>
        {textButtonClose}
      </AsciButton>
    </div>
  </div>
);

DialogMessage.propTypes = {
  close: PropTypes.func.isRequired,
  textButtonClose: PropTypes.string,
};

DialogMessage.defaultProps = {
  textButtonClose: "Close",
};

export default DialogMessage;
