import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

import { getRegexValidation } from "~/global/utils";

export const ADD_FAIL_CODE_CATEGORIES_BEGIN = "ADD_FAIL_CODE_CATEGORIES_BEGIN";
export const ADD_FAIL_CODE_CATEGORIES_ERROR = "ADD_FAIL_CODE_CATEGORIES_ERROR";
export const ADD_FAIL_CODE_CATEGORIES_SUCCESS = "ADD_FAIL_CODE_CATEGORIES_SUCCESS";
export const SELECT_FAIL_CODE_CATEGORY = "SELECT_FAIL_CODE_CATEGORY";
export const SET_FAIL_CODE_CATEGORY_FAIL_FAST = "SET_FAIL_CODE_CATEGORY_FAIL_FAST";
export const SET_FAIL_CODE_CATEGORY_NAME = "SET_FAIL_CODE_CATEGORY_NAME";
export const UPDATE_FAIL_CODE_CATEGORY_BEGIN = "UPDATE_FAIL_CODE_CATEGORY_BEGIN";
export const UPDATE_FAIL_CODE_CATEGORY_ERROR = "UPDATE_FAIL_CODE_CATEGORY_ERROR";
export const UPDATE_FAIL_CODE_CATEGORY_SUCCESS = "UPDATE_FAIL_CODE_CATEGORY_SUCCESS";
export const VALIDATE_FAIL_CODE_CATEGORY_NAME = "VALIDATE_FAIL_CODE_CATEGORY_NAME";
export const RESET_FAIL_CODE_CATEGORY = "RESET_FAIL_CODE_CATEGORY";

export const validateName = (name, language) => (dispatch, getState) => {
  const state = getState();
  const existingNames = selectors.failCodes.getExistingFailCodeCategoryNames(state);
  const alreadyExists = existingNames[language].indexOf(name.toLowerCase().trim()) > -1;
  const isValid = getRegexValidation("failCodeCategoryName", name);

  const error = !isValid
    ? "Names must be 2-140 characters and can use numbers, letters, spaces and the special characters /#_-+()"
    : alreadyExists
    ? "That name is already assigned."
    : "";

  dispatch({
    type: VALIDATE_FAIL_CODE_CATEGORY_NAME,
    payload: {
      error: {
        name: {
          [language]: error,
        },
      },
    },
  });
};

export const setName = (name) => ({
  type: SET_FAIL_CODE_CATEGORY_NAME,
  payload: { name },
});

export const setFailFast = (failFast) => ({
  type: SET_FAIL_CODE_CATEGORY_FAIL_FAST,
  payload: { failFast },
});

export const selectFailCodeCategory = (failCodeCategory = {}) => ({
  type: SELECT_FAIL_CODE_CATEGORY,
  payload: { failCodeCategory },
});

export const addFailCodeCategory = (failCodeCategory, pgid) => async (dispatch) => {
  const languages = Object.keys(failCodeCategory.name || {});

  const failCodeCategoryNames = languages.reduce((acc, language) => {
    acc[language] = failCodeCategory.name[language].trim();

    return acc;
  }, {});

  const dataToServer = {
    ...failCodeCategory,
    name: failCodeCategoryNames,
  };

  const failCodeCategoryPlaceholder = {
    ...dataToServer,
    id: "placeholder",
  };

  dispatch(addFailCodeCategoryBegin(failCodeCategoryPlaceholder));

  try {
    const response = await CustomerManagement.POST(`/fail-codes-categories/${pgid}`, dataToServer);
    dispatch(addFailCodeCategorySuccess(response.payload));
    return response.payload;
  } catch (error) {
    dispatch(addFailCodeCategoryError(error.json));
  }
};

export const addFailCodeCategoryBegin = (placeholder) => ({
  type: ADD_FAIL_CODE_CATEGORIES_BEGIN,
  payload: {
    placeholder,
  },
});

export const addFailCodeCategorySuccess = (data) => {
  const failCodeCategory = {
    id: data.id,
    failFast: !!data.failFast,
    name: data.name || "",
  };

  return {
    type: ADD_FAIL_CODE_CATEGORIES_SUCCESS,
    payload: {
      failCodeCategory,
    },
  };
};

export const addFailCodeCategoryError = (response) => {
  const errorMessage = response.message != null ? response.message : "Failed to update Fail Code";
  return {
    type: ADD_FAIL_CODE_CATEGORIES_ERROR,
    payload: {
      message: [].concat(errorMessage),
    },
  };
};

export const updateFailCodeCategory = (failCodeCategory) => async (dispatch) => {
  const languages = Object.keys(failCodeCategory.name || {});

  const failCodeCategoryNames = languages.reduce((acc, language) => {
    acc[language] = failCodeCategory.name[language].trim();

    return acc;
  }, {});

  const dataToServer = {
    ...failCodeCategory,
    name: failCodeCategoryNames,
  };

  try {
    dispatch(updateFailCodeCategoryBegin(dataToServer));
    await CustomerManagement.PUT("/fail-codes-categories", dataToServer);
    dispatch(updateFailCodeCategorySuccess(dataToServer));
  } catch (error) {
    dispatch(updateFailCodeCategoryError(error.json));
  }
};

export const updateFailCodeCategoryBegin = () => ({
  type: UPDATE_FAIL_CODE_CATEGORY_BEGIN,
});

export const updateFailCodeCategorySuccess = (failCodeCategory) => ({
  type: UPDATE_FAIL_CODE_CATEGORY_SUCCESS,
  payload: { failCodeCategory },
});

export const updateFailCodeCategoryError = (response) => {
  const errorMessage =
    response.message != null ? response.message : "Failed to update Fail Code Category";
  return {
    type: UPDATE_FAIL_CODE_CATEGORY_ERROR,
    payload: {
      errors: {
        message: [].concat(errorMessage),
      },
    },
  };
};

export const resetFailCodeCategory = () => ({
  type: RESET_FAIL_CODE_CATEGORY,
});
