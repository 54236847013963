import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const ReportingIcon = ({ size, customId }) => (
  <SVGIcon title="Reporting" viewBox="0 0 35.66 26.44" size={size}>
    <defs>
      <linearGradient
        id={`${customId}-a`}
        x1="0.19"
        x2="34.28"
        y1="9.1"
        y2="9.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.2" stopColor="#96c934" />
        <stop offset="0.36" stopColor="#8cbf37" />
        <stop offset="0.51" stopColor="#7aad3d" />
        <stop offset="0.65" stopColor="#629545" />
        <stop offset="0.78" stopColor="#437650" />
        <stop offset="0.91" stopColor="#1d505c" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>

    <g>
      <path
        fill={`url(#${customId}-a)`}
        d="M34.08.28a.77.77 0 0 0-.62-.28L29.1.1a.77.77 0 0 0-.71.47.78.78 0 0 0 .12.83L30 3.19l-5 4.2a2.91 2.91 0 0 0-4.81 1.44L16 8.64a2.92 2.92 0 0 0-5.84.36 2.89 2.89 0 0 0 .2 1.06l-5.07 3.27a2.92 2.92 0 1 0 .76 1.95 3 3 0 0 0-.19-1L11 11a2.91 2.91 0 0 0 5-1.28l4.2.19a2.92 2.92 0 0 0 5.82-.39 2.82 2.82 0 0 0-.3-1.26l5-4.26 1.37 1.63a.79.79 0 0 0 .61.37.76.76 0 0 0 .21 0 .77.77 0 0 0 .57-.63l.78-4.43a.79.79 0 0 0-.18-.66zm-31 16.83A1.83 1.83 0 1 1 5 15.28a1.83 1.83 0 0 1-1.88 1.83zm10-6.25A1.83 1.83 0 1 1 14.92 9a1.84 1.84 0 0 1-1.83 1.86zm9.92.51a1.83 1.83 0 1 1 1.83-1.83A1.83 1.83 0 0 1 23 11.37zm9.51-6.91l-2.75-3.28 3.35-.07z"
      />
      <path
        fill={`url(#${customId}-a)`}
        d="M35.11 26.44H.55a.55.55 0 0 1-.55-.55.55.55 0 0 1 .55-.55h34.56a.55.55 0 0 1 .55.55.54.54 0 0 1-.55.55z"
      />
      <path
        fill="#99cc33"
        d="M3.37 23.06a.54.54 0 0 1-.55-.55V21a.54.54 0 0 1 .55-.55.55.55 0 0 1 .55.55v1.53a.55.55 0 0 1-.55.53zM13.08 23.06a.54.54 0 0 1-.55-.55V14.8a.54.54 0 0 1 .55-.55.55.55 0 0 1 .55.55v7.71a.55.55 0 0 1-.55.55zM22.79 23.06a.54.54 0 0 1-.55-.55v-7.23a.55.55 0 0 1 .55-.55.55.55 0 0 1 .55.55v7.23a.55.55 0 0 1-.55.55zM32.5 23.06a.54.54 0 0 1-.55-.55V8.78a.55.55 0 0 1 .55-.55.55.55 0 0 1 .55.55v13.73a.55.55 0 0 1-.55.55z"
      />
    </g>
  </SVGIcon>
);

ReportingIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default ReportingIcon;
