import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InspectorInput from "~/label-printer/components/designer/inspector/commons/InspectorInput";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

const ShapeGridInspector = (props) => {
  const {
    identity,
    column,
    row,
    columnWidth,
    rowHeight,
    labelWidth,

    setShapeLayoutData,
    setRowHeight,
  } = props;

  const setterFactory = (key) => (data) => setShapeLayoutData({ identity, key, data });

  return (
    <>
      <InspectorInput label="Position">
        <div style={{ fontWeight: "bold" }}>
          ({row},{column})
        </div>
      </InspectorInput>

      <InspectorInput label="Cell Width">
        <NumberInput
          value={columnWidth}
          onChange={(value) => value <= labelWidth && setterFactory("columnWidth")(value)}
          unit="mm"
          min={1}
        />
      </InspectorInput>
      <InspectorInput label="Row Height">
        <NumberInput
          value={rowHeight}
          onChange={(height) => setRowHeight({ rowIndex: row, height })}
          unit="mm"
          min={1}
        />
      </InspectorInput>
    </>
  );
};

ShapeGridInspector.defaultProps = {};

ShapeGridInspector.propTypes = {
  identity: PropTypes.number,

  column: PropTypes.number,
  row: PropTypes.number,
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  columnSpan: PropTypes.number,

  labelWidth: PropTypes.number,

  setShapeLayoutData: PropTypes.func,
  setRowHeight: PropTypes.func,
};

const stateToProps = (state, props) => {
  const layoutData = labelSelectors.labelDesigner.getShapeLayoutData(props.identity)(state);

  return {
    ...layoutData,

    labelWidth: labelSelectors.labelDesigner.getLabelWidth(state),
    columnWidth: labelSelectors.labelDesigner.getColumnWidth(props.identity)(state),
    rowHeight: labelSelectors.labelDesigner.getRowHeight(layoutData.row)(state),
  };
};

const dispatchToProps = {
  setShapeLayoutData: labelActions.labelDesigner.setShapeLayoutData,
  setRowHeight: labelActions.labelDesigner.setRowHeight,
};

export default connect(stateToProps, dispatchToProps)(ShapeGridInspector);
