import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { TextField, Paper } from "react-md";
import { Row, Column, AsciButton } from "~/global";
import moduleAction from "~/data-import/actions/";

import "./ManualEntry.component.scss";

const ManualEntry = ({ saveDateTime, saveOperator, operatorFromState, duration }) => {
  const [operator, setOperator] = useState("");
  const [dateTime, setDateTime] = useState("");

  const updateDateTime = () => {
    const tempDateTime = new Date();
    let minutes = tempDateTime.getMinutes();
    if (minutes < 10) {
      minutes = `0${tempDateTime.getMinutes()}`;
    }
    setDateTime(
      `${tempDateTime.getFullYear()}-${
        tempDateTime.getMonth() + 1
      }-${tempDateTime.getDate()} ${tempDateTime.getHours()}:${minutes}`
    );
  };

  const formatDateTime = () => {
    let startTime = moment(dateTime);
    let endTime = moment(dateTime);
    endTime.add(duration, "seconds");
    startTime = startTime.format("YYYY-MM-DDTHH:mm:ssZZ");
    endTime = endTime.format("YYYY-MM-DDTHH:mm:ssZZ");

    const formattedDateTime = {
      startTime,
      endTime,
    };

    return formattedDateTime;
  };

  useEffect(() => {
    updateDateTime();
    saveDateTime(formatDateTime());
  }, [duration]);

  useEffect(() => {
    if (operator !== "") {
      saveOperator(operator);
    }
  }, [operator]);

  useEffect(() => {
    if (operatorFromState === "") {
      setOperator("");
    }
  }, [operatorFromState]);

  return (
    <div className="manual-entry">
      <Row className="section-title">Manual Entry</Row>
      <Row>
        <Column className="manual-entry-column">
          <Row className="field-label">Operator</Row>
          <Row>
            <TextField
              id="manual-entry-operator"
              placeholder="Type the operator"
              onChange={(value) => setOperator(value)}
              type="text"
              value={operator}
              className="input-field-operator"
            />
          </Row>
        </Column>
        <Column className="manual-entry-column">
          <Row className="field-label">Process Date</Row>
          <Row>
            <Paper zDepth={0}>
              <Row className="date-time-field">{dateTime}</Row>
            </Paper>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  operatorFromState: state.modules.dataImport.importDataManual.operator,
  duration: state.modules.dataImport.importDataManual.duration,
});

const dispatchToProps = {
  saveDateTime: moduleAction.importDataManual.updateDateTime,
  saveOperator: moduleAction.importDataManual.updateOperator,
};

export default connect(stateToProps, dispatchToProps)(ManualEntry);
