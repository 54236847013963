import { createAction } from "@reduxjs/toolkit";
import ISO6391 from "iso-639-1";
import { UserManagement } from "~/services";

export const fetchLanguagesBegin = createAction("FETCH_LANGUAGES_BEGIN");
export const fetchLanguagesSuccess = createAction("FETCH_LANGUAGES_SUCCESS");
export const fetchLanguagesError = createAction("FETCH_LANGUAGES_ERROR");

export const fetchLanguages = (faid) => async (dispatch) => {
  dispatch(fetchLanguagesBegin());
  try {
    const query = UserManagement.gqlBuilder(`query Languages {
            languages
        }`);

    const response = await UserManagement.QUERY(query);
    const data = response.payload.data.languages || [];

    const languages = await data.map((lang) => ({
      value: lang,
      name: ISO6391.getNativeName(lang),
    }));

    dispatch(fetchLanguagesSuccess({ languages }));
  } catch (error) {
    console.log(error);

    dispatch(fetchLanguagesError({ error }));
  }
};
