import { createAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

import { CustomerManagement, UserManagement } from "~/services";
import moduleSelectors from "../selectors";

export const fetchUserRolesBegin = createAction("FETCH_USER_ROLES_BEGIN");
export const fetchUserRolesSuccess = createAction("FETCH_USER_ROLES_SUCCESS");
export const fetchUserRolesError = createAction("FETCH_USER_ROLES_ERROR");

export const fetchUsersBegin = createAction("FETCH_USERS_BEGIN");
export const fetchUsersSuccess = createAction("FETCH_USERS_SUCCESS");
export const fetchUsersError = createAction("FETCH_USERS_ERROR");

export const saveUserBegin = createAction("customerManagement/SAVE_USER_BEGIN");
export const saveUserSuccess = createAction("customerManagement/SAVE_USER_SUCCESS");
export const saveUserError = createAction("customerManagement/SAVE_USER_ERROR");
export const setFilter = createAction("customerManagement/SET_FILTER");

export const fetchUserRoles = () => async (dispatch) => {
  const query = UserManagement.gqlBuilder(`query Roles {
    roles {
      id,
      name,
      permissions {
        method,
        resource,
      }
    }
  }`);

  dispatch(fetchUserRolesBegin());
  const response = await UserManagement.QUERY(query);
  try {
    const roles = [].concat(response.payload.data.roles);
    dispatch(fetchUserRolesSuccess({ roles }));
  } catch (e) {
    if (e instanceof Error) throw e;
    dispatch(fetchUserRolesError({ e }));
  }
};

export const fetchUsers =
  ({ page, perPage, filters, keyword }, csid) =>
  async (dispatch, getState) => {
    dispatch(fetchUsersBegin());
    try {
      const state = getState();
      const { roles } = state.modules.customerManagement.customerUsers;
      let apiFilters;

      if (filters != null && filters.roleName != null) {
        const roleIds = filters.roleName.reduce((acc, name) => {
          acc.push(
            roles
              .filter((role) => role.name === name)
              .map((x) => x.id)
              .join(",")
              .split('"')[1]
          );

          return acc;
        }, []);

        apiFilters = [
          {
            field: "roleids",
            value: roleIds,
          },
        ];
      }

      const response = await CustomerManagement.POST(`/customers/${csid}/users/search`, {
        page,
        perPage,
        filters: apiFilters,
        keyword,
      });
      const { Doc, TotalDoc } = response.payload;

      dispatch(
        fetchUsersSuccess({
          users: Doc,
          numOfResults: TotalDoc,
          pagination: { page, pageSize: perPage },
        })
      );
    } catch (e) {
      if (e instanceof Error) throw e;

      dispatch(fetchUsersError({ e }));
    }
  };

export const onSaveUser = () => async (dispatch, getState) => {
  const state = getState();
  const currentUsers = state.modules.customerManagement.customerUsers.items;
  const updatedUserId = moduleSelectors.customerUserEditor.getUserId(state);
  const updatedUser = moduleSelectors.customerUserEditor.getUserToServerFormat(state);
  // Strip ObjectID("") garbage
  const roleIds = [updatedUser.user_metadata.roles[0].id.split('"')[1]];
  const currentNumOfResults = state.modules.customerManagement.customerUsers.numOfResults;

  dispatch(saveUserBegin());

  try {
    const response = await UserManagement.PUT(`/user/${updatedUserId}`, updatedUser);
    const userMetadata = response.payload.user_metadata;
    const newUser = {
      ...userMetadata,
      roleids: roleIds,
    };
    const newUsers = [...currentUsers.filter((user) => user.userid !== newUser.userid), newUser];
    // Sort by name like in the back-end
    const newSortedUsers =
      newUsers.length > currentUsers.length
        ? sortBy(newUsers, "name").slice(0, -1)
        : sortBy(newUsers, "name");

    dispatch(saveUserSuccess({ users: newSortedUsers, numOfResults: currentNumOfResults }));
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(saveUserError({ message }));
  }
};

export const onAddUser = () => async (dispatch, getState) => {
  const state = getState();
  const currentUsers = state.modules.customerManagement.customerUsers.items;
  const currentNumOfResults = state.modules.customerManagement.customerUsers.numOfResults;
  const { pagination } = state.modules.customerManagement.customerUsers;
  const updatedUser = moduleSelectors.customerUserEditor.getUserToServerFormat(state);

  dispatch(saveUserBegin());

  try {
    const response = await UserManagement.POST(`/user`, updatedUser);
    const newUser = response.payload.user_metadata;
    const newUsers = [...currentUsers, newUser];
    // Sort by name like in the back-end
    const newSortedUsers =
      newUsers.length > pagination.pageSize
        ? sortBy(newUsers, "name").slice(0, -1)
        : sortBy(newUsers, "name");

    dispatch(saveUserSuccess({ users: newSortedUsers, numOfResults: currentNumOfResults + 1 }));
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(saveUserError({ message }));
  }
};

export const onDeleteUser = (userid) => async (dispatch, getState) => {
  const state = getState();
  const currentUsers = state.modules.customerManagement.customerUsers.items;
  const currentNumOfResults = state.modules.customerManagement.customerUsers.numOfResults;
  const newUsers = currentUsers.filter((user) => user.userid !== userid);
  const newSortedUsers = sortBy(newUsers, "name");

  dispatch(saveUserBegin());

  try {
    await UserManagement.DELETE(`/user/${userid}`);

    dispatch(saveUserSuccess({ users: newSortedUsers, numOfResults: currentNumOfResults - 1 }));
  } catch (e) {
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(saveUserError({ message }));
  }
};
