import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "react-md";
import { Row, SelectList } from "~/global";

import "./ArgumentEditor.component.scss";

const ArgumentEditor = ({ argument, onChange }) => {
  const [argumentName, setArgumentName] = useState(argument.name);
  const [argumentType, setArgumentType] = useState(argument.type);
  const [argumentValue, setArgumentValue] = useState(argument.value);
  const [newArgument, setNewArgument] = useState(argument);

  const types = ["integer", "bool", "string"];
  const boolTypes = ["true", "false"];

  const changeType = (option) => {
    setArgumentType(option);
    switch (option) {
      case "bool":
        setArgumentValue("false");
        break;
      case "integer":
        setArgumentValue(0);
        break;
      case "string":
        setArgumentValue("");
        break;
    }
  };

  useEffect(() => {
    if (
      argument.name !== argumentName ||
      argument.type !== argumentType ||
      argument.value !== argumentValue
    ) {
      const arg = {
        id: argument.id,
        name: argumentName,
        type: argumentType,
        value: argumentValue,
      };
      setNewArgument(arg);
      onChange(arg);
    }
  }, [argumentName, argumentType, argumentValue]);

  return (
    <Row className="full-width argument-editor" key={argument.id}>
      <TextField
        id="argumentName-text-field "
        lineDirection="left"
        placeholder="Enter argument name"
        value={argumentName}
        className="argument-textfield"
        onChange={(value) => setArgumentName(value)}
        type="text"
        rightIcon={<i className="icon-pencil" />}
      />
      <div className="argument-type">
        <SelectList
          items={types}
          onChange={(option) => changeType(option)}
          selectedItem={argumentType}
          nameKey=""
        />
      </div>

      {argumentType == "bool" ? (
        <div className="argument-selectlist">
          <SelectList
            items={boolTypes}
            onChange={(option) => setArgumentValue(option)}
            selectedItem={argumentValue}
            nameKey=""
          />
        </div>
      ) : (
        <TextField
          id="argumentValue-text-field "
          lineDirection="left"
          placeholder="Enter argument value"
          value={argumentValue}
          className="argument-textfield"
          onChange={(value) => setArgumentValue(value)}
          type="text"
          rightIcon={<i className="icon-pencil" />}
        />
      )}
    </Row>
  );
};

ArgumentEditor.propTypes = {
  argument: PropTypes.exact({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(ArgumentEditor);
