import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const VersionManagerIcon = ({ size, customId }) => (
  <SVGIcon title="Version Manager" viewBox="0 0 32.34 35" size={size}>
    <defs>
      <linearGradient id={customId} x2="32.34" y1="17.5" y2="17.5" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill={`url(#${customId})`}
        d="M26 22.24A6.38 6.38 0 0 0 20.69 25l-8.52-4.81a6.48 6.48 0 0 0 .59-2.68 6.24 6.24 0 0 0-.59-2.66l8.62-4.78a6.35 6.35 0 1 0-.56-.93l-8.59 4.76a6.38 6.38 0 1 0 0 7.23L20.16 26A6.38 6.38 0 1 0 26 22.24zm0-21.16a5.3 5.3 0 1 1-5.3 5.3 5.31 5.31 0 0 1 5.3-5.3zM6.38 22.84a5.3 5.3 0 1 1 5.3-5.3 5.31 5.31 0 0 1-5.3 5.3zM26 33.92a5.3 5.3 0 1 1 5.3-5.3 5.31 5.31 0 0 1-5.3 5.3z"
      />
    </g>
  </SVGIcon>
);

VersionManagerIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default VersionManagerIcon;
