import moment from "moment";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "issues",
  ({ workstationId, sessionId }) => {
    const queryFilter = workstationId
      ? `workstationId: "${workstationId}"`
      : `sessionId: "${sessionId}"`;

    return WorkstationManagement.QUERY(
      WorkstationManagement.gqlBuilder(`{
            issues(${queryFilter}) {
                id,
                workstationId,
                sessionId,
                reason,
                description,
                logFileUrl,
                createdAt
            }
        }`)
    ).then((response) => reverse(sortBy(response.payload.data.issues, [(i) => moment(i)])));
  },
  [
    {
      identifier: "reason",
      label: "Reason",
      options: ["bug", "feedback", "other"],
      value: null,
      filterFunc: (issue, value) => (value ? issue.reason === value : true),
    },
  ]
);
