import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import cloneDeep from "lodash/cloneDeep";
import ValueEditor from "~/global/ValueEditor/ValueEditor.component";

import "./ServiceArgumentsEditor.component.scss";

const ServiceArgumentsEditor = (props) => {
  const onValChanged = (argIndex, val) => {
    const serviceArguments = cloneDeep(props.arguments);
    serviceArguments[argIndex].value = val;
    props.onChange(serviceArguments);
  };

  return (
    <div className="service-arguments-editor">
      {props.arguments.map((arg, index) => {
        // edit the value of an item in the list of items in the argument
        if (arg.type == "list") {
          return (
            <div className="list-editor" key={index}>
              <div className="label">{arg.name}</div>

              <div>
                {((arg.extra || {}).items || [])
                  .filter((item) => ["bool", "string", "integer", "float"].includes(item.type))
                  .map((item, itemIndex) => (
                    <ValueEditor
                      key={`item-${itemIndex}`}
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      disabled={!props.isEditable}
                      onChange={(val) => onValChanged(index, itemIndex, val)}
                    />
                  ))}
              </div>
            </div>
          );
        }
        return ["bool", "string", "integer", "float"].includes(arg.type) ? (
          <ValueEditor
            key={index}
            name={arg.name}
            type={arg.type}
            value={arg.value}
            disabled={
              !props.isEditable ||
              // HACK: WEB-483 - the "BlacklistIsTest" argument is used for qa only;
              // It must be visible but disabled in production.
              (TARGET_ENVIRONMENT === "production" && arg.name === "BlacklistIsTest")
            }
            onChange={(val) => onValChanged(index, val)}
          />
        ) : null;
      })}
    </div>
  );
};

ServiceArgumentsEditor.propTypes = {
  isEditable: PropTypes.bool,
  arguments: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

ServiceArgumentsEditor.defaultProps = {
  isEditable: true,
};

export default ServiceArgumentsEditor;
