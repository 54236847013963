import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./BorderedGroup.component.scss";

const BorderedGroup = ({ title, className, children }) => (
  <div className={classnames("bordered-group-container", className)}>
    <span className="bordered-group-title">{title}</span>

    <div className="bordered-group-body row-wrap">{children}</div>
  </div>
);

BorderedGroup.propTypes = {
  // additional classes
  className: PropTypes.string,
  // title of the group
  title: PropTypes.string,
  // content of the group
  children: PropTypes.any.isRequired,
};

export default BorderedGroup;
