import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import { AsciButton } from "~/global";
import { getGalleryImages, getAreGalleryImagesLoading } from "../../selectors";

import "./ImageGalleryDialog.component.scss";

const ImageGalleryDialog = ({
  imagesLoading,
  images,
  platform,
  selectedIcon: initiallySelectedIcon,
  selectedPanelImage: initiallySelectedPanelImage,
  onCancel,
  onConfirm,
  setTitle,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(initiallySelectedIcon);
  const [selectedPanel, setSelectedPanel] = useState(initiallySelectedPanelImage);
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    {
      name: "icons",
      title: "Step 1: Select your Test Icon",
      getImages: () => ((images || {})[platform] || {}).icons || [],
      getImageURL: (filename) => `${images.url}/${platform}/icons/${filename}`,
      isSelected: (filename) => filename === selectedIcon,
      setSelected: setSelectedIcon,
    },
    {
      name: "panels",
      title: "Step 2: Select your Test Panel image",
      getImages: () => (images[platform] || {}).panels || [],
      getImageURL: (filename) => `${images.url}/${platform}/panels/${filename}`,
      isSelected: (filename) => filename === selectedPanel,
      setSelected: setSelectedPanel,
    },
  ];

  useEffect(() => {
    setTitle(steps[stepIndex].title);
  }, [stepIndex]);

  if (imagesLoading && !images) {
    return "Loading...";
  }

  const step = steps[stepIndex] || {};

  return (
    <div className="image-gallery">
      <div className="images">
        {step.getImages().map((filename) => (
          <div
            key={`${step.name}-${filename}`}
            className={classnames({ selected: step.isSelected(filename) })}
          >
            <img src={step.getImageURL(filename)} onClick={() => step.setSelected(filename)} />
          </div>
        ))}
      </div>

      <div className="dialog-actions layout-row layout-row--end-center">
        <AsciButton color="white" onClick={onCancel}>
          Cancel
        </AsciButton>

        {stepIndex === 0 && (
          <AsciButton color="green" onClick={() => setStepIndex(1)}>
            Next
          </AsciButton>
        )}

        {stepIndex === 1 && (
          <>
            <AsciButton color="green" onClick={() => setStepIndex(0)}>
              Previous
            </AsciButton>
            <AsciButton color="green" onClick={() => onConfirm(selectedIcon, selectedPanel)}>
              Finish
            </AsciButton>
          </>
        )}
      </div>
    </div>
  );
};

ImageGalleryDialog.propTypes = {
  imagesLoading: PropTypes.bool,
  images: PropTypes.shape({
    url: PropTypes.string,
  }),
  platform: PropTypes.string.isRequired,
  selectedIcon: PropTypes.string,
  selectedPanelImage: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  imagesLoading: getAreGalleryImagesLoading(state),
  images: getGalleryImages(state),
});

export default connect(stateToProps, null)(ImageGalleryDialog);
