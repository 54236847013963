import React, { useMemo } from "react";
import { Button, Collapse, Popover, Space, Typography } from "antd";
import { parseISO } from "date-fns";

import { ExportedReport } from "~/actions/exportedReports.actions";
import { Filter } from "~/features/recording-facets/RecordingFacetsFilter.component";
import { selectOptionsByValue } from "~/features/recording-facets/reportingFilters.slice";
import { Uniqueness } from "~/features/recording-facets/UniquenessFilter.component";
import { Column, LoadingSpinner } from "~/global";
import { useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";

const { Text } = Typography;
const { Panel } = Collapse;

const FiltersSummary = ({
  filters,
}: {
  filters: {
    facetFilters?: Filter[];
    bulkFileId?: string;
    uniqueness: Uniqueness;
  };
}) => {
  const facetsByValue = useAppSelector(selectOptionsByValue);
  const { t } = useTranslation("reporting");

  const { facetFilters, bulkFileId, uniqueness } = filters;

  const list: string[] = [];

  if (bulkFileId) {
    list.push(t("table.bulk"));
  }

  if (uniqueness !== "none") {
    list.push(t("table.uniqueness.label"));
  }

  if (facetFilters) {
    if (!facetsByValue) {
      return <LoadingSpinner />;
    }

    list.push(...facetFilters.map((filter) => facetsByValue[filter.field]?.label ?? filter.field));
  }

  return <Text ellipsis>{list.join(", ")}</Text>;
};

const DateRange = ({ range }: { range: { from: string; to: string } }) => {
  const { language } = useTranslation();

  const rangeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
    [language]
  );

  const from = parseISO(range.from);
  const to = parseISO(range.to);

  // @ts-expect-error this works right now, but hasn't yet been defined by TS. ref:https://github.com/microsoft/TypeScript/pull/47740
  return <>{rangeFormat.formatRange(from, to)}</>; // eslint-disable-line @typescript-eslint/no-unsafe-call
};

const FiltersCollapseList = ({
  filters,
}: {
  filters: {
    dateRange: {
      from: string;
      to: string;
    };
    facetFilters?: Filter[];
    bulkFileId?: string;
    uniqueness: Uniqueness;
  };
}) => {
  const facetsByValue = useAppSelector(selectOptionsByValue);
  const { t } = useTranslation("reporting");

  const { dateRange, facetFilters, bulkFileId, uniqueness } = filters;

  return (
    <Collapse ghost style={{ overflow: "auto", maxHeight: "480px", minWidth: "400px" }}>
      <Panel key="dateRange" header={t("table.date-range")}>
        <DateRange range={dateRange} />
      </Panel>
      {bulkFileId && (
        <Panel key="bulkFile" header={t("table.bulk")}>
          {t("table.bulk-file-found")}
        </Panel>
      )}
      {uniqueness !== "none" && (
        <Panel key="uniqueness" header={t("table.uniqueness.label")}>
          {
            {
              "last-in": t("table.uniqueness.last-in"),
              "first-in": t("table.uniqueness.first-in"),
            }[uniqueness]
          }
        </Panel>
      )}
      {facetFilters &&
        (!facetsByValue ? (
          <LoadingSpinner />
        ) : (
          facetFilters.map((filter) => {
            const label = facetsByValue[filter.field]?.label ?? filter.field;
            return (
              <Panel key={filter.field} header={label}>
                <ul style={{ display: "flex", flexWrap: "wrap", maxWidth: 320 }}>
                  {filter.value.map((value) => (
                    <li key={value}>
                      <Text code>{value}</Text>
                    </li>
                  ))}
                </ul>
              </Panel>
            );
          })
        ))}
    </Collapse>
  );
};

type Props = {
  report: Pick<ExportedReport, "filters" | "dateFrom" | "dateTo" | "fileId" | "uniqueness">;
};

const ExportedReportFilters = ({ report }: Props) => {
  const { t } = useTranslation("reporting");

  const { dateFrom, dateTo, filters: facetFilters, fileId: bulkFileId, uniqueness } = report;
  const dateRange = { from: dateFrom, to: dateTo };
  const filters = { dateRange, uniqueness, bulkFileId, facetFilters };

  if (!facetFilters && uniqueness === "none" && !bulkFileId) {
    return <DateRange range={dateRange} />;
  }

  return (
    <Space align="center">
      <Column style={{ width: "240px" }}>
        <DateRange range={dateRange} />

        <FiltersSummary filters={filters} />
      </Column>
      <Column>
        <Popover
          placement="topRight"
          trigger="click"
          content={<FiltersCollapseList filters={filters} />}
        >
          <Button size="small" style={{ marginLeft: "1em" }}>
            {t("table.more")}
          </Button>
        </Popover>
      </Column>
    </Space>
  );
};

export default ExportedReportFilters;
