import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { InventoryManagement } from "~/services";
import { RootState } from "~/store";

export type InventoryProject = {
  id: string;
  csid: string;
  projectName: string;
  facility: string;
  location: string;
  supervisorName: string;
  status: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export type InventoryProjectBody = {
  projectName: string;
  facility: string;
  location: string;
  supervisorName: string;
  status: string;
  active: boolean;
};

type ServiceError = { json: { message?: string } };

export const fetchInventoryProjects = createAsyncThunk<
  InventoryProject[],
  void,
  { rejectValue: string }
>("inventoryProjects/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = (await InventoryManagement.GET("/inventory/projects")) as {
      payload: InventoryProject[];
    };
    return response.payload;
  } catch (err) {
    const message = ((err as ServiceError).json?.message ?? "") || "Request failed";
    return rejectWithValue(message);
  }
});

export const createInventoryProject = createAsyncThunk<
  InventoryProject,
  InventoryProjectBody,
  { rejectValue: string }
>("inventoryProjects/create", async (body, { rejectWithValue }) => {
  try {
    const response = (await InventoryManagement.POST("/inventory/projects", body)) as {
      payload: InventoryProject;
    };
    return response.payload;
  } catch (err) {
    const message = ((err as ServiceError).json?.message ?? "") || "Request failed";
    return rejectWithValue(message);
  }
});

export const updateInventoryProject = createAsyncThunk<
  InventoryProject,
  InventoryProjectBody & { id: string },
  { rejectValue: string }
>("inventoryProjects/update", async (projectParams, { rejectWithValue }) => {
  try {
    const { id, ...body } = projectParams;
    const response = (await InventoryManagement.PUT(`/inventory/projects/${id}`, body)) as {
      payload: InventoryProject;
    };
    return response.payload;
  } catch (err) {
    const message = ((err as ServiceError).json?.message ?? "") || "Request failed";
    return rejectWithValue(message);
  }
});

export const deleteInventoryProject = createAsyncThunk<string, string, { rejectValue: string }>(
  "inventoryProjects/delete",
  async (id, { rejectWithValue }) => {
    try {
      await InventoryManagement.DELETE(`/inventory/projects/${id}`, null);
      return id;
    } catch (err) {
      const message = (err as ServiceError)?.json?.message ?? "Request failed";
      return rejectWithValue(message);
    }
  }
);

const inventoryProjectsAdapter = createEntityAdapter<InventoryProject>({
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

interface InventoryProjectsState extends EntityState<InventoryProject> {
  listStatus: "idle" | "loading";
  formStatus: "idle" | "loading";
}

const initialState: InventoryProjectsState = {
  ...inventoryProjectsAdapter.getInitialState(),
  listStatus: "idle",
  formStatus: "idle",
};

const inventoryProjectsSlice = createSlice({
  name: "inventoryProjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryProjects.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(fetchInventoryProjects.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        inventoryProjectsAdapter.setAll(draftState, action.payload);
      })
      .addCase(createInventoryProject.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(createInventoryProject.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        inventoryProjectsAdapter.addOne(draftState, action.payload);
      })
      .addCase(createInventoryProject.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })
      .addCase(updateInventoryProject.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(updateInventoryProject.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        inventoryProjectsAdapter.upsertOne(draftState, action.payload);
      })
      .addCase(updateInventoryProject.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })
      .addCase(deleteInventoryProject.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(deleteInventoryProject.fulfilled, (draftState, action) => {
        draftState.formStatus = "idle";
        inventoryProjectsAdapter.removeOne(draftState, action.payload);
      });
  },
});

export const inventoryProjectsSelectors = inventoryProjectsAdapter.getSelectors<RootState>(
  (draftState) => draftState.inventoryProjects
);

export default inventoryProjectsSlice.reducer;
