import React from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import classnames from "classnames";
import { FontIcon, Button } from "react-md";

import { TestDefinitionIcon, Column, Row } from "~/global";

import "./TestSuiteOrderList.component.scss";

const DragHandle = SortableHandle(() => (
  <div>
    <FontIcon iconClassName="icon-sort" />
  </div>
));

const ListItem = ({ order, image, name, identifierName, description, header, onRemove }) => (
  <li className={classnames("orderable-list-item", { header })}>
    <Column className="order" align="center center">
      {order}
    </Column>

    <Column className="handle">{!header && <DragHandle />}</Column>

    <Column className="icon" align="center center">
      {image && <TestDefinitionIcon src={image} />}
    </Column>

    <Column className="test-name" align="center">
      {name}
    </Column>

    <Column className="identifier-name" align="center">
      {identifierName}
    </Column>

    <Column className="description" align="center">
      {description}
    </Column>

    <Row className="actions" align="center center">
      {!header && (
        <Button icon className="icon-btn" iconClassName="icon-times-circle" onClick={onRemove} />
      )}
    </Row>
  </li>
);

ListItem.propTypes = {
  order: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  identifierName: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.bool,
  onRemove: PropTypes.func,
};

const SortableItem = SortableElement(({ definition = {}, order, onRemove }) =>
  definition != null ? (
    <ListItem
      order={order}
      image={definition.image}
      name={definition.displayName}
      identifierName={
        definition.className === definition.identifier
          ? "Default"
          : definition.identifierDisplayName
      }
      description={definition.description}
      onRemove={onRemove}
    />
  ) : (
    <div />
  )
);

const SortableList = SortableContainer(({ items, onRemoveItem }) => (
  <ul>
    {items.length > 0 && (
      <ListItem header name="Test" identifierName="Configuration" description="Description" />
    )}

    {items.map((definition, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        definition={definition}
        order={index + 1}
        onRemove={() => onRemoveItem(index)}
      />
    ))}
  </ul>
));

const TestSuiteOrderList = ({ testDefinitions, onOrderChanged, onRemoveItem }) => {
  const sortableListOrderChanged = ({ oldIndex, newIndex }) => {
    onOrderChanged(
      arrayMove(
        testDefinitions.map((definition) => definition.identifier),
        oldIndex,
        newIndex
      )
    );
  };

  return (
    <div className="test-suite-order-list">
      <SortableList
        items={testDefinitions || []}
        onSortEnd={sortableListOrderChanged}
        useDragHandle
        lockAxis="y"
        onRemoveItem={onRemoveItem}
      />
    </div>
  );
};

TestSuiteOrderList.propTypes = {
  testDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      displayName: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  onOrderChanged: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
};

export default TestSuiteOrderList;
