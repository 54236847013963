import { createSelector } from "@reduxjs/toolkit";
import { sortBy, keyBy } from "lodash";
import type { RootState } from "~/store";

import i18n from "../i18n";

const getFacilities = (state: RootState) => state.facilities.items;
const getFacility = (state: RootState) => state.facility.data;
export const getSortedFacilities = createSelector(getFacilities, (facilities) =>
  sortBy(facilities, (item) => item.name.toLowerCase())
);

export const getFacilitiesById = createSelector(getSortedFacilities, (facilities = []) =>
  keyBy(facilities, (facility) => facility.faid)
);

export const getSelectedFacility = createSelector(
  [getFacility, getFacilities, getFacilitiesById],
  (facility, facilities = [], facilitiesById = {}) => {
    if (facility && facilitiesById[facility.faid]) {
      return facilitiesById[facility.faid];
    }
    return facilities.length > 0 ? facilities[0] : facility;
  }
);

export const getFacilitiesAllOption = createSelector(getSortedFacilities, (facilities) => {
  const allOption = {
    faid: "all",
    timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    name: i18n.t("common:facilities.all"),
  };
  return [allOption, ...facilities];
});

export const getFacilitiesByIdAllOption = createSelector(
  getFacilitiesAllOption,
  (facilities = []) => keyBy(facilities, (facility) => facility.faid)
);

export const getFacilitiesListAllOption = createSelector(
  [getFacilitiesByIdAllOption],
  (updatedFacilitiesById) => {
    const updatedFacilities = Object.keys(updatedFacilitiesById).filter(
      (facilityId) => facilityId != null
    );
    return updatedFacilities;
  }
);

export const getSelectedFacilityAllOption = createSelector(
  [getFacility, getFacilitiesAllOption, getFacilitiesByIdAllOption],
  (facility, facilities = [], facilitiesByIdAllOption = {}) => {
    if (facility && facilitiesByIdAllOption[facility.faid]) {
      return facilitiesByIdAllOption[facility.faid];
    }
    return facilities[0];
  }
);
