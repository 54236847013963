import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";

export const fetchLogsBegin = createAction("FETCH_USERLOGS_BEGIN");
export const fetchLogsSuccess = createAction("FETCH_USERLOGS_SUCCESS");
export const fetchLogsError = createAction("userlogs/FETCH_USERLOGS_ERROR");
export const onClearLogs = createAction("userlogs/ON_CLEAR_LOGS");

export const fetchUserLogs = (dates) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        userActions(
            from: "${dates.dateFrom}",
            to: "${dates.dateTo}"
        )  { 
      
        basicLogInfo {
            csid,
            method,
            path,
            query,
            status,
            start,
            uri,
            userid,
            name,
        }
    }
    }`);
  try {
    dispatch(fetchLogsBegin());

    const response = await CustomerManagement.QUERY(query);
    const userLogs = (response.payload.data || {}).userActions || [];

    dispatch(fetchLogsSuccess({ userLogs }));
    return true;
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchLogsError({ error: message }));
    return false;
  }
};

export const clearLogs = () => (dispatch, getState) => {
  const state = getState();
  const selectedLogs = [...state.modules.userLogs.userActivityData.items];
  selectedLogs.map((selectedLog) => (selectedLog.value = ""));

  dispatch(onClearLogs({ selectedLogs }));
};
