import React from "react";
import type { CSSProperties } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  size?: "small" | "default" | "large";
  center?: boolean;
  iconColor?: "primary" | "white";
  style?: CSSProperties;
};

const LoadingSpinner = ({
  size = "default",
  center = false,
  iconColor = "primary",
  style,
}: Props) => {
  const centerStyle = center ? { width: "100%", margin: "0 auto" } : undefined;
  const colors = { primary: "#005288", white: "#fff" };

  return (
    <Spin
      indicator={<LoadingOutlined style={{ color: colors[iconColor] }} />}
      size={size}
      style={{ ...style, ...centerStyle }}
    />
  );
};

export default LoadingSpinner;
