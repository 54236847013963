import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Cell, DataTable, TableBody, TableColumn, TableRow } from "react-md";

import { SectionHeader } from "~/global";
import StatusIcon from "~/device-history/components/StatusIcon/StatusIcon.component";

export default class CertificateDataWipeBody extends Component {
  static propTypes = {
    process: PropTypes.object.isRequired,
    deviceInfo: PropTypes.object.isRequired,
    desktopInformation: PropTypes.object.isRequired,
    processResult: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="certificate-body">
        <div className="certificate-main-info">
          <SectionHeader className="certificate-header" title="Device Information" />

          <Grid noSpacing>
            <Cell size={6}>
              <DataTable
                className="cc-table full-width"
                baseId="certificate-device-info-table-1"
                plain
              >
                <TableBody>
                  <TableRow>
                    <TableColumn>Device Model</TableColumn>
                    <TableColumn>{this.props.deviceInfo.modelName || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Model Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.modelNumber || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Manufacturer</TableColumn>
                    <TableColumn>{this.props.deviceInfo.manufacturer || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>IMEI</TableColumn>
                    <TableColumn>{this.props.deviceInfo.IMEI || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>GUID</TableColumn>
                    <TableColumn>{this.props.deviceInfo.GUID || "N/A"}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>

            <Cell size={6}>
              <DataTable
                className="cc-table full-width"
                baseId="certificate-device-info-table-2"
                plain
              >
                <TableBody>
                  <TableRow>
                    <TableColumn>OEM Serial Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.OEMSerialNumber || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>WI-FI MAC Number</TableColumn>
                    <TableColumn>{this.props.deviceInfo.wifiMAC || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Storage Capacity</TableColumn>
                    <TableColumn>{this.props.deviceInfo.storageCapacity || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>MEID</TableColumn>
                    <TableColumn>{this.props.deviceInfo.MEID || "N/A"}</TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>
          </Grid>

          <SectionHeader className="certificate-header" title="Process Details" />

          <Grid noSpacing>
            <Cell size={6}>
              <DataTable className="cc-table full-width" baseId="certificate-process-table-1" plain>
                <TableBody>
                  <TableRow>
                    <TableColumn>Session ID</TableColumn>
                    <TableColumn>{this.props.process.sessionIDHumanReadable || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>DSE Software Version</TableColumn>
                    <TableColumn>
                      {this.props.desktopInformation.softwareInformation.dseVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>

            <Cell size={6}>
              <DataTable className="cc-table full-width" baseId="certificate-process-table-2" plain>
                <TableBody>
                  <TableRow>
                    <TableColumn>Process Result</TableColumn>
                    <TableColumn>{this.props.processResult || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Software Bundle Version</TableColumn>
                    <TableColumn>
                      {this.props.desktopInformation.softwareInformation.bundleVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Cell>
          </Grid>

          <SectionHeader className="certificate-header" title="Services" />

          <DataTable className="cc-table full-width" baseId="certificate-services-table-1" plain>
            <TableBody>
              <TableRow>
                <TableColumn>Content Clearing</TableColumn>
                <TableColumn>
                  <StatusIcon status="PASS" />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </div>
      </div>
    );
  }
}
