import produce from "immer";
import { without } from "lodash/array";

import {
  CHECK_FAIL_CODE,
  DELETE_FAIL_CODES_BEGIN,
  DELETE_FAIL_CODES_SUCCESS,
  DELETE_FAIL_CODES_ERROR,
  UPDATE_FAIL_CODES,
  CLEAR_FAIL_CODES_ERRORS,
} from "~/actions/failCodes/failCodes.actions";

import {
  ADD_FAIL_CODE_BEGIN,
  ADD_FAIL_CODE_ERROR,
  ADD_FAIL_CODE_SUCCESS,
  UPDATE_FAIL_CODE_BEGIN,
  UPDATE_FAIL_CODE_ERROR,
  UPDATE_FAIL_CODE_SUCCESS,
} from "~/actions/failCodes/failCode.actions";

import {
  FETCH_FAIL_CODE_CATEGORIES_SUCCESS,
  DELETE_FAIL_CODE_CATEGORIES_SUCCESS,
} from "~/actions/failCodes/failCodeCategories.actions";

const initialState = {
  errors: null,
  isLoading: false,
  items: [],
  itemsCheckedIds: [],
  itemSelectedId: "",
  nameFilter: "",
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_FAIL_CODE_CATEGORIES_SUCCESS: {
        draft.items = action.payload.failCodes;
        draft.isLoading = false;
        return;
      }

      case ADD_FAIL_CODE_BEGIN: {
        draft.items.push(action.payload.placeholder);
        draft.errors = null;
        draft.isLoading = true;
        return;
      }

      case ADD_FAIL_CODE_SUCCESS: {
        const { failCode } = action.payload;
        draft.items = state.items.filter((item) => item.id !== "placeholder");
        draft.items.push(failCode);
        draft.isLoading = false;
        return;
      }

      case ADD_FAIL_CODE_ERROR: {
        draft.items = state.items.filter((item) => item.id !== "placeholder");
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case CHECK_FAIL_CODE: {
        draft.itemsCheckedIds = action.payload.failCodeIds;
        return;
      }

      case CLEAR_FAIL_CODES_ERRORS: {
        draft.errors = null;
        return;
      }

      case UPDATE_FAIL_CODES: {
        draft.items = action.payload.failCodes;
        return;
      }

      case UPDATE_FAIL_CODE_BEGIN: {
        draft.isLoading = true;
        return;
      }

      case UPDATE_FAIL_CODE_SUCCESS: {
        const { failCode } = action.payload;
        for (let i = 0; i < state.items.length; i++) {
          const item = state.items[i];
          if (item.id === failCode.id) {
            draft.items[i] = failCode;
            draft.isLoading = false;
            break;
          }
        }
        return;
      }

      case UPDATE_FAIL_CODE_ERROR: {
        draft.isLoading = false;
        return;
      }

      case DELETE_FAIL_CODES_BEGIN: {
        draft.errors = null;
        draft.isLoading = true;
        return;
      }

      case DELETE_FAIL_CODES_SUCCESS: {
        const failCodeIds = action.payload.ids;
        const itemsToDelete = state.items.filter((item) => failCodeIds.indexOf(item.id) > -1);
        draft.items = without(state.items, ...itemsToDelete);
        draft.isLoading = false;
        draft.itemsCheckedIds = [];
        return;
      }

      case DELETE_FAIL_CODES_ERROR: {
        draft.errors = action.payload;
        draft.isLoading = false;
        return;
      }

      case DELETE_FAIL_CODE_CATEGORIES_SUCCESS: {
        const deletedFailCodeIds = action.payload.ids;
        draft.items = state.items.filter((item) => {
          const index = deletedFailCodeIds.indexOf(item.categoryId) < 0;
          return index;
        });
      }
    }
  });
