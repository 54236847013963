import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InspectorSection from "./commons/InspectorSection";
import InspectorInput from "./commons/InspectorInput";
import ColorInput from "./commons/ColorInput";
import BorderInspector from "./commons/BorderInspector";
import FontInspector from "./commons/FontInspector";
import AlignmentInspector from "./commons/AlignmentInspector";

import labelActions from "~/label-printer/actions";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";

const ShapeStyleInspector = (props) => {
  const { setShapeData, identity, style } = props;
  const {
    padding,
    margin,
    background,
    border,
    font,
    alignment,
    marginTop,
    height,
    marginLeft,
    width,
  } = style;
  const setterFactory = (key) => (data) => setShapeData({ key: `style.${key}`, identity, data });
  const borderSetterFactory = (key) => setterFactory(`border.${key}`);
  const fontSetterFactory = (key) => setterFactory(`font.${key}`);
  const alignmentSetterFactory = (key) => setterFactory(`alignment.${key}`);
  return (
    <InspectorSection title="Style" initialFolded level={1}>
      <InspectorInput label="Background">
        <ColorInput
          onChange={setterFactory("background")}
          color={background || "#fff"}
          label="Background"
        />
      </InspectorInput>
      {/* FIXME padding & margin messing up layout */}
      <InspectorInput label="Padding">
        <NumberInput defaultValue={padding} onChange={setterFactory("padding")} />
      </InspectorInput>
      <InspectorInput label="Margin">
        <NumberInput defaultValue={margin} onChange={setterFactory("margin")} />
      </InspectorInput>
      <InspectorInput label="Margin top">
        <NumberInput defaultValue={marginTop} onChange={setterFactory("marginTop")} />
      </InspectorInput>
      <InspectorInput label="Margin left">
        <NumberInput defaultValue={marginLeft} onChange={setterFactory("marginLeft")} />
      </InspectorInput>
      <InspectorInput label="height">
        <NumberInput defaultValue={height} onChange={setterFactory("height")} />
      </InspectorInput>
      <InspectorInput label="width">
        <NumberInput defaultValue={width} onChange={setterFactory("width")} />
      </InspectorInput>
      <AlignmentInspector setterFactory={alignmentSetterFactory} {...alignment} level={2} />
      <FontInspector setterFactory={fontSetterFactory} {...font} level={2} />
      <BorderInspector setterFactory={borderSetterFactory} {...border} level={2} />
    </InspectorSection>
  );
};

ShapeStyleInspector.defaultProps = {};

ShapeStyleInspector.propTypes = {
  identity: PropTypes.number,
  style: PropTypes.shape({
    background: PropTypes.string,
    padding: PropTypes.number,
    margin: PropTypes.number,
    border: PropTypes.object,
    font: PropTypes.object,
    marginLeft: PropTypes.object,
    width: PropTypes.object,
    marginTop: PropTypes.string,
    height: PropTypes.number,
    alignment: PropTypes.object,
  }),

  setShapeData: PropTypes.func,
};

const dispatchToProps = {
  setShapeData: labelActions.labelDesigner.setShapeData,
};

export default connect(null, dispatchToProps)(ShapeStyleInspector);
