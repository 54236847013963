import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "~/canned-reports/actions/";

const initialState = {
  items: [],
  errors: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [moduleActions.processesByServiceSuite.fetchProcessesByServiceSuiteBegin]: (state, action) => {
    state.errors = null;
    state.isLoading = true;
  },

  [moduleActions.processesByServiceSuite.fetchProcessesByServiceSuiteSuccess]: (state, action) => {
    state.items = action.payload.processesByServiceSuite;
    state.isLoading = false;
  },

  [moduleActions.processesByServiceSuite.fetchProcessesByServiceSuiteError]: (state, action) => {
    state.errors = action.payload.errors;
    state.items = [];
    state.isLoading = false;
  },
});
