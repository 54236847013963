import { subWeeks } from "date-fns";
import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer(
  "workstation",
  "workstationDetails",
  (workstationId, { filters }) =>
    WorkstationManagement.QUERY(
      WorkstationManagement.gqlBuilder(`{
        workstation(id: "${workstationId}") {
            id,
            uuid,
            createdOn,
            name,
            info {
                system {
                    manufacturer,
                    model,
                    version,
                    serial,
                    uuid,
                    sku
                }
                os {
                    platform,
                    distro,
                    release,
                    kernel,
                    arch,
                    hostname,
                    codepage,
                    build,
                    servicePack
                }
            }
            recordingCount,
            recordings(
                start: "${filters.recordings.value.start}",
                end: "${filters.recordings.value.end}"
            ),
            crashes(
                start: "${filters.crashes.value.start}",
                end: "${filters.crashes.value.end}"
            ),
            crashReports
        }
    }`)
    ).then((response) => response.payload.data.workstation),
  [
    {
      identifier: "recordings",
      label: "Recordings Timeframe",
      value: {
        start: subWeeks(new Date(), 1).toISOString(),
        end: new Date().toISOString(),
      },
    },
    {
      identifier: "crashes",
      label: "Crashes Timeframe",
      value: {
        start: subWeeks(new Date(), 1).toISOString(),
        end: new Date().toISOString(),
      },
    },
  ]
);
