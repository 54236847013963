import React from "react";
import PropTypes from "prop-types";
import { Button, Divider } from "react-md";

const ContextMenu = ({ text, disabled, onClick }) => (
  <li className="context-menu">
    <Button className="full-width" flat onClick={onClick} disabled={disabled}>
      {text}
    </Button>
    <Divider />
  </li>
);

ContextMenu.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ContextMenu.defaultProps = {
  text: "",
  disabled: false,
};

export default ContextMenu;
