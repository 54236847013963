import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./GridLayout.scss";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

import LabelShape from "../LabelShape";
import { toMM } from "~/label-printer/styling";

const UnconnectedGridedShape = (props) => {
  const { columnWidth, shape } = props;
  return (
    <div className="label-grid-column" style={{ width: columnWidth }}>
      <LabelShape shape={shape} preventPropagation />
    </div>
  );
};

const GridedShape = connect(
  (state, props) => ({
    columnWidth: toMM(labelSelectors.labelDesigner.getColumnWidth(props.identity)(state)),
  }),
  null
)(UnconnectedGridedShape);

const UnconnectedGridRow = (props) => {
  const { row, rowHeight } = props;

  return (
    <div className="label-grid-row" style={{ height: rowHeight }}>
      {row?.map((col) => (
        <GridedShape shape={col} key={`shape-${col.identity}`} identity={col.identity} />
      ))}
    </div>
  );
};

const GridRow = connect(
  (state, props) => ({
    rowHeight: toMM(labelSelectors.labelDesigner.getRowHeight(props.rowIndex)(state)),
  }),
  null
)(UnconnectedGridRow);

// Row & columns shapes.
const GridLayout = ({ numRows, numColumns, rows, createGrid, isLoaded }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      if (!isLoaded) {
        createGrid(numRows, numColumns);
      }
      setInitialized(true);
    }
  }, [numRows, numColumns, createGrid, isLoaded, initialized]);

  return (
    <div className="label-grid-layout">
      {rows.map((row, i) => (
        <GridRow row={row} rowIndex={i} key={`row-${i}`} />
      ))}
    </div>
  );
};

GridLayout.propTypes = {
  numRows: PropTypes.number,
  numColumns: PropTypes.number,
  rows: PropTypes.array,

  createGrid: PropTypes.func,

  loadedLabelId: PropTypes.string,
  isLoaded: PropTypes.bool,
};

const stateToProps = (state) => ({
  rows: labelSelectors.labelDesigner.getGridRows(state),

  isLoaded: labelSelectors.labelSaver.isLoaded(state),
  loadedLabelId: labelSelectors.labelSaver.getLoadedLabelId(state),
});

const dispatchToProps = {
  createGrid: labelActions.labelDesigner.createGrid,
};

export default connect(stateToProps, dispatchToProps)(GridLayout);
