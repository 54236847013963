import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TableRow, TableColumn, TextField, Button, FontIcon } from "react-md";
import uuid from "uuid/v4";

import { TimeSelector, Checkbox } from "~/global";

import "./DeploymentShiftRow.component.scss";

const DeploymentShiftRow = ({ shift, numberOfShifts, onChange, onDelete }) => {
  const onShiftNameChanged = (newName) => {
    const newShift = {
      ...shift,
      name: newName,
    };
    onChange(newShift);
  };

  const onShiftNameBlur = (e) => {
    const name = e.target.value.trim();

    if (shift.name != name) {
      const newShift = {
        ...shift,
        name,
      };
      onChange(newShift);
    }
  };

  const onShiftTimeChanged = (key, changes) => {
    const newShift = { ...shift };
    newShift[key] = { ...newShift[key], ...changes };
    onChange(newShift);
  };

  const id = uuid();

  return (
    <TableRow className="asci-table-row cc-deployment-shift-row">
      <TableColumn className="shift-name asci-table-cell">
        <TextField
          id={`shift-name-text-field-${id}`}
          leftIcon={<FontIcon iconClassName="icon-pencil asci-btn" />}
          block
          lineDirection="left"
          className="table-text-field"
          value={shift.name}
          placeholder="Enter name"
          onChange={onShiftNameChanged}
          onBlur={onShiftNameBlur}
        />
      </TableColumn>

      <TableColumn>
        <TimeSelector
          hours={shift.start.hours}
          minutes={shift.start.minutes}
          onChange={(newTimes) => onShiftTimeChanged("start", newTimes)}
          label="Start"
        />
      </TableColumn>

      <TableColumn>
        <TimeSelector
          hours={shift.end.hours}
          minutes={shift.end.minutes}
          onChange={(newTimes) => onShiftTimeChanged("end", newTimes)}
          label="End"
        />
      </TableColumn>

      <TableColumn className="text-right asci-table-cell">
        <Button
          icon
          className="icon-btn text-color--red"
          iconClassName="icon-trash"
          onClick={numberOfShifts > 1 ? onDelete : null}
          disabled={numberOfShifts <= 1}
        />
      </TableColumn>
    </TableRow>
  );
};

DeploymentShiftRow.propTypes = {
  shift: PropTypes.shape({
    name: PropTypes.string,
    isEnabled: PropTypes.bool,
    start: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    }),
    end: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    }),
  }).isRequired,
  numberOfShifts: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeploymentShiftRow;
