import { createReducer } from "@reduxjs/toolkit";
import concat from "lodash/concat";

import labelActions from "~/label-printer/actions";

let nPrint = 0;

const createPrintJob = (identity) => ({
  identity,
});

const initialState = {
  printing: false,
  printPreview: false,
  printingPreview: false,
  jobs: [],
  recording: null,
};

export default createReducer(initialState, {
  [labelActions.labelPrint.addPrintJob]: (state, action) => {
    const job = createPrintJob(nPrint);
    nPrint++;
    state.jobs = concat(state.jobs, [job]);
  },
  [labelActions.labelPrint.setPrintPreview]: (state, action) => {
    state.printPreview = action.payload;
  },
  [labelActions.labelPrint.setPrintRecording]: (state, action) => {
    state.recording = action.payload;
  },
});
