import React, { useRef, useState } from "react";
import { EditOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputRef, Select, Space, Switch } from "antd";
import moduleSelectors from "../../../selectors";
import moduleSelectorsUsers from "../../../customer-management/selectors";
import { inventoryLocationsSelectors } from "~/entities/inventory/model/inventoryLocationsSlice";
import { useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import {
  InventoryProject,
  InventoryProjectBody,
} from "~/entities/inventory/model/inventoryProjetsSlice";

type Props = {
  project: InventoryProject;
  onFinish: (values: InventoryProjectBody) => void;
  onCancel: () => void;
};

export const UpdateInventoryProjectForm = ({ project, onFinish, onCancel }: Props) => {
  const { t } = useTranslation("inventory");
  const inputRef = useRef<InputRef>(null);

  const { formStatus } = useAppSelector((state) => state.inventoryProjects);
  const supervisors = useAppSelector((state) =>
    moduleSelectorsUsers.customerUsers.getSupervisorsOptions(state)
  );
  const facilities = useAppSelector((state) =>
    moduleSelectors.facilities.getSortedFacilities(state).map((facility) => ({
      value: facility.faid,
      label: facility.name,
    }))
  );

  const locations = useAppSelector((state) =>
    inventoryLocationsSelectors.selectAll(state).map((location) => ({
      value: location.location,
      label: location.location,
    }))
  );
  const statusOptions = [
    { value: "Pending", label: t("pending") },
    { value: "In Progress", label: t("in-progress") },
    { value: "Completed", label: t("completed") },
  ];

  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Space>
            <EditOutlined />
            <span>{t("edit-inventory-project")}</span>
          </Space>
          <Button
            type="text"
            icon={isVisible ? <UpOutlined /> : <DownOutlined />}
            onClick={() => setIsVisible(!isVisible)}
          />
        </div>
      }
      bordered={false}
      style={{ marginBottom: "2em", transition: "max-height 0.3s ease" }}
      bodyStyle={{
        maxHeight: isVisible ? "500px" : "0",
        overflow: "hidden",
        padding: isVisible ? "24px" : "0",
      }}
    >
      {isVisible && (
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            projectName: project.projectName,
            facility: project.facility,
            location: project.location,
            supervisorName: project.supervisorName,
            status: project.status,
            active: project.active,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t("project-name")}
            name="projectName"
            rules={[{ required: true, message: t("project-name-required") }]}
          >
            <Input ref={inputRef} />
          </Form.Item>

          <Form.Item
            label={t("facility")}
            name="facility"
            rules={[{ required: true, message: t("facility-required") }]}
          >
            <Select options={facilities} placeholder={t("select-facility")} />
          </Form.Item>

          <Form.Item
            label={t("location")}
            name="location"
            rules={[{ required: true, message: t("location-required") }]}
          >
            <Select options={locations} placeholder={t("select-location")} />
          </Form.Item>

          <Form.Item
            label={t("supervisor-name")}
            name="supervisorName"
            rules={[{ required: true, message: t("supervisor-name-required") }]}
          >
            <Select options={supervisors} placeholder={t("select-supervisor")} />
          </Form.Item>

          <Form.Item
            label={t("status")}
            name="status"
            rules={[{ required: true, message: t("status-required") }]}
          >
            <Select options={statusOptions} placeholder={t("select-status")} />
          </Form.Item>

          <Form.Item
            label={t("active")}
            name="active"
            valuePropName="checked"
            initialValue={project.active}
            rules={[{ required: true, message: t("active-required") }]}
          >
            <Switch checkedChildren={t("yes")} unCheckedChildren={t("no")} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={formStatus === "loading"}>
                {t("save")}
              </Button>
              <Button onClick={onCancel}>{t("cancel")}</Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};
