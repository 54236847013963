import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/customer-management/actions";

const initialState = {
  original: {},
  modifications: {},
  isLoading: false,

  lastNameError: false,
  passwordError: false,
  emailError: false,
  firstNameError: false,
  facilityError: false,
  editMode: false,
  resetPassword: false,
};
// updateCustomerEditorCustomerId
export default createReducer(initialState, {
  [moduleActions.customerUserEditor.setUserToEdit]: (state, action) => {
    state.original = action.payload.user;
    state.lastNameError = false;
    state.passwordError = false;
    state.emailError = false;
    state.firstNameError = false;
    state.editMode = action.payload.editMode;
  },
  /* [moduleActions.customerUsers.saveUserBegin]: state => {
        state.original = {};
        state.modifications = {};
    }, */
  [moduleActions.customerUsers.saveUserSuccess]: (state, action) => {
    state.original = {};
    state.modifications = {};
  },
  [moduleActions.customerUserEditor.onClearUserEdition]: (state) => {
    state.original = {};
    state.modifications = {};
    state.lastNameError = false;
    state.passwordError = false;
    state.emailError = false;
    state.firstNameError = false;
    state.editMode = false;
    state.facilityError = false;
  },
  [moduleActions.customerEditor.resetCustomerEdition]: (state) => {
    state.original = {};
    state.modifications = {};
    state.lastNameError = false;
    state.passwordError = false;
    state.emailError = false;
    state.firstNameError = false;
    state.editMode = false;
    state.facilityError = false;
  },
  [moduleActions.customerUserEditor.onUserPasswordValidation]: (state, action) => {
    state.passwordError = action.payload.errors;
  },
  [moduleActions.customerUserEditor.onUserEmailValidation]: (state, action) => {
    state.emailError = action.payload.errors;
  },
  [moduleActions.customerUserEditor.onUserFirstNameValidation]: (state, action) => {
    state.firstNameError = action.payload.errors;
  },
  [moduleActions.customerUserEditor.onUserLastNameValidation]: (state, action) => {
    state.lastNameError = action.payload.errors;
  },
  [moduleActions.customerUserEditor.updateCustomerUser]: (state, action) => {
    state.modifications = action.payload.user;
  },
  [moduleActions.customerUserEditor.onUserFacilitiesValidation]: (state, action) => {
    state.facilityError = action.payload.error;
  },
  [moduleActions.customerUserEditor.setResetPassword]: (state, action) => {
    const isResetOn = action.payload.value;
    if (!isResetOn) {
      state.passwordError = false;
      state.modifications.password = "";
    }
    state.resetPassword = isResetOn;
  },
});
