import createFetchReduxer from "~/global/reduxer/createFetchReduxer";
import { WorkstationManagement } from "~/services";

export default createFetchReduxer("workstation", "sessionDetails", (sessionId) =>
  WorkstationManagement.QUERY(
    WorkstationManagement.gqlBuilder(`{
        session(id: "${sessionId}") {
            id,
            status,
            beginAt,
            endAt,
            operator,
            workstationId,
            version,
            recordingCount,
            sessionData,
            recordings,
            crashes
        }
    }`)
  ).then((response) => response.payload.data.session)
);
