import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import { camelCase } from "lodash/string";
import "./SuiteTemplateList.component.scss";

const SuiteTemplateList = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const selectTemplate = (e, identifier) => {
    if (identifier != selectedTemplate) {
      setSelectedTemplate(identifier);
      props.selectTemplate(identifier);
    }
  };

  const checked = "icon-check-circle text-color text-color--green";
  const unchecked = "icon-circle-o text-color text-color--grey";

  return (
    <div className="suite-template-list">
      {props.suiteTemplates.map((suiteTemplate) => {
        const showMore = suiteTemplate.services.length >= 2;
        const servicesShortList = suiteTemplate.services.slice(0, 2);
        const servicesShortListIdentifiers = servicesShortList
          .map((service) => service.identifier)
          .join(", ");

        return (
          <div
            key={suiteTemplate.identifier}
            className="paper-row"
            onClick={(e) => selectTemplate(e, suiteTemplate.identifier)}
          >
            <div className="row centered">
              <i className={selectedTemplate == suiteTemplate.identifier ? checked : unchecked} />
              <div>{suiteTemplate.identifier}</div>
            </div>
            <div className="row identifiers">
              <div>{servicesShortListIdentifiers}</div>
              {showMore ? (
                <Popover
                  placement="left"
                  title="Services"
                  content={
                    <ul>
                      {suiteTemplate.services.map((service) => {
                        const id = camelCase(service.identifier);
                        return <li key={id}>- {service.identifier}</li>;
                      })}
                    </ul>
                  }
                  trigger="hover"
                  overlayClassName="see-more"
                >
                  <div className="popover-clickable-text padding-h--5">See All</div>
                </Popover>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SuiteTemplateList.propTypes = {
  suiteTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTemplate: PropTypes.func,
};

SuiteTemplateList.defaultProps = {
  selectTemplate: () => {},
};

export default SuiteTemplateList;
