import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputRef, message, Select, Space, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "~/hooks";

import { useTranslation } from "~/i18n";
import moduleSelectors from "../../../selectors";
import {
  fetchInventoryLocations,
  inventoryLocationsSelectors,
} from "~/entities/inventory/model/inventoryLocationsSlice";
import moduleSelectorsUsers from "../../../customer-management/selectors";
import moduleActions from "~/customer-management/actions";
import {
  InventoryProjectBody,
  createInventoryProject,
} from "~/entities/inventory/model/inventoryProjetsSlice";

export const CreateInventoryProjectForm = () => {
  const { t } = useTranslation("inventory");
  const inputRef = useRef<InputRef>(null);
  const currentCsid = useAppSelector((state) => state.customer.activeCustomerId);

  const { formStatus } = useAppSelector((state) => state.inventoryProjects);

  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.modules.customerManagement.customerUsers.roles);
  const rolesLoading = useAppSelector(
    (state) => state.modules.customerManagement.customerUsers.rolesLoading
  );

  const supervisors = useAppSelector((state) =>
    moduleSelectorsUsers.customerUsers.getSupervisorsOptions(state)
  );

  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility

  useEffect(() => {
    // Fetch roles when the component mounts
    void dispatch(moduleActions.customerUsers.fetchUserRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!rolesLoading && roles.length > 0) {
      void dispatch(
        moduleActions.customerUsers.fetchUsers(
          {
            page: 1,
            perPage: 1000,
            filters: {
              roleName: ["inventory-scanner"],
            },
            keyword: "",
          },
          currentCsid
        )
      );
    }
    if (formStatus === "idle") {
      void dispatch(fetchInventoryLocations());
    }
  }, [rolesLoading, roles, dispatch, formStatus, currentCsid]);

  const statusOptions = [
    { value: "Pending", label: t("pending") },
    { value: "In Progress", label: t("in-progress") },
    { value: "Completed", label: t("completed") },
  ];

  const facilities = useAppSelector((state) =>
    moduleSelectors.facilities.getSortedFacilities(state).map((facility) => ({
      value: facility.faid,
      label: facility.name,
    }))
  );

  const locations = useAppSelector((state) =>
    inventoryLocationsSelectors.selectAll(state).map((location) => ({
      value: location.location,
      label: location.location,
    }))
  );
  const onFinish = (values: InventoryProjectBody) => {
    dispatch(createInventoryProject(values))
      .unwrap()
      .then(() => {
        void message.success(t("create-success"));
      })
      .catch((rejectedValue: string) => {
        void message.error(rejectedValue);
      });
  };

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Space>
            <PlusOutlined />
            <span>{t("create-inventory-project")}</span>
          </Space>
          <Button
            type="text"
            icon={isVisible ? <UpOutlined /> : <DownOutlined />}
            onClick={() => setIsVisible(!isVisible)}
          />
        </div>
      }
      bordered={false}
      style={{ marginBottom: "2em", transition: "max-height 0.3s ease" }}
      bodyStyle={{
        maxHeight: isVisible ? "500px" : "0",
        overflow: "hidden",
        padding: isVisible ? "24px" : "0",
      }}
    >
      {isVisible && (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onFinish}>
          <Form.Item
            label={t("project-name")}
            name="projectName"
            rules={[{ required: true, message: t("project-name-required") }]}
          >
            <Input ref={inputRef} />
          </Form.Item>

          <Form.Item
            label={t("facility")}
            name="facility"
            rules={[{ required: true, message: t("facility-required") }]}
          >
            <Select options={facilities} placeholder={t("select-facility")} />
          </Form.Item>

          <Form.Item
            label={t("location")}
            name="location"
            rules={[{ required: true, message: t("location-required") }]}
          >
            <Select options={locations} placeholder={t("select-location")} />
          </Form.Item>

          <Form.Item
            label={t("supervisor-name")}
            name="supervisorName"
            rules={[{ required: true, message: t("supervisor-name-required") }]}
          >
            <Select options={supervisors} placeholder={t("select-supervisor")} />
          </Form.Item>

          <Form.Item
            label={t("status")}
            name="status"
            rules={[{ required: true, message: t("status-required") }]}
          >
            <Select options={statusOptions} placeholder={t("select-status")} />
          </Form.Item>

          <Form.Item
            label={t("active")}
            name="active"
            valuePropName="checked"
            initialValue={true}
            rules={[{ required: true, message: t("active-required") }]}
          >
            <Switch checkedChildren={t("yes")} unCheckedChildren={t("no")} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={formStatus === "loading"}>
                {t("create")}
              </Button>
              <Button htmlType="reset">{t("reset")}</Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};
