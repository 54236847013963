import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { Table } from "antd";
import { useHistory } from "react-router-dom";
import {
  actions as workstationActions,
  selectors as workstationSelectors,
} from "~/workstation/redux";

import { Spinner } from "~/global";

import AutoFilter from "~/global/reduxer/components/AutoFilter";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import Timestamp from "~/workstation/components/common/Timestamp";
import UserDisplay from "~/workstation/components/common/UserDisplay";
import SessionStatus from "~/workstation/components/common/SessionStatus";

const SessionList = (props) => {
  const { workstationId } = props;

  const sessions = useSelector(workstationSelectors.sessions.getFilteredResults);
  const history = useHistory();
  const onClick = (id) => history.push(`/workstations/sessions/${id}`);
  const column = [
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.sessions}
          selectors={workstationSelectors.sessions}
          reducerName="sessions"
          identifier="status"
          style={{ width: "10rem" }}
        />
      ),
      dataIndex: "status",
      render: (status) => <SessionStatus status={status} />,
    },
    {
      title: () => (
        <AutoFilter
          selectors={workstationSelectors.sessions}
          actions={workstationActions.sessions}
          identifier="user"
          style={{ width: "10rem" }}
        />
      ),
      dataIndex: "operator",
      render: (operator) => <UserDisplay user={operator} />,
    },
    {
      title: "begin",
      dataIndex: "beginAt",
      render: (beginAt) => <Timestamp value={beginAt} />,
    },
    {
      title: "end",
      dataIndex: "endAt",
      render: (endAt, record) => {
        // Vérifier si le status est 'online'
        if (record.status === "online") {
          return null; // Retourne null pour laisser la case vide
        }
        return <Timestamp value={endAt} />;
      },
    },
  ];

  return (
    <ReduxerControlled
      selectors={workstationSelectors.sessions}
      actions={workstationActions.sessions}
      loadingComponent={<Spinner />}
      fetchArguments={[workstationId]}
    >
      <Table
        columns={column}
        dataSource={sessions}
        pagination={false}
        onRow={(session) => ({
          onClick: () => {
            onClick(session.id);
          },
        })}
      />
    </ReduxerControlled>
  );
};

SessionList.defaultProps = {};

SessionList.propTypes = {
  workstationId: PropTypes.string,
};

export default SessionList;
