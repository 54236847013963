import concat from "lodash/concat";
import { createReducer } from "@reduxjs/toolkit";

import moduleActions from "../actions";

const initialState = {
  original: {},
  modifications: {},
  errors: {},
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  loaded: [],
};

export default createReducer(initialState, {
  // Fetch a single test suite
  [moduleActions.fetchTestSuiteBegin]: (state) => {
    state.isLoading = true;
  },
  [moduleActions.fetchTestSuiteSuccess]: (state, action) => {
    state.isLoading = false;
    state.original = action.payload.testSuite || {};
    state.modifications = {};
    state.errors = {};
    state.loaded = concat(state.loaded, [action.payload.testSuite]);
  },
  [moduleActions.fetchTestSuiteError]: (state, action) => {
    state.isLoading = false;
    state.original = {};
    state.modifications = {};
    state.errors = action.payload.errors || {};
  },

  // Create a new test suite
  [moduleActions.createTestSuite.pending]: (state) => {
    state.isCreating = true;
  },
  [moduleActions.createTestSuite.fulfilled]: (state) => {
    state.isCreating = false;
    state.errors = {};
  },
  [moduleActions.createTestSuite.rejected]: (state, action) => {
    state.isCreating = false;
    state.errors = action.payload.errors || {};
  },

  // Update a test suite
  [moduleActions.updateTestSuite.pending]: (state) => {
    state.isUpdating = true;
  },
  [moduleActions.updateTestSuite.fulfilled]: (state) => {
    state.isUpdating = false;
  },
  [moduleActions.updateTestSuite.rejected]: (state) => {
    state.isUpdating = false;
  },

  // reset a test suite
  [moduleActions.resetTestSuite]: (state) => {
    state.original = {};
    state.modifications = {};
    state.errors = {};
    state.isLoading = false;
    state.isCreating = false;
    state.isUpdating = false;
  },
  // Set a test suite
  [moduleActions.setTestSuite]: (state, action) => {
    state.original = action.payload.testSuite || {};
    state.modifications = {};
    state.errors = {};
  },

  // Modify the current test suite
  [moduleActions.modifyTestSuite]: (state, action) => {
    Object.keys(action.payload).forEach((key) => {
      const val = action.payload[key];
      if (state.original[key] === val) {
        delete state.modifications[key];
      } else {
        state.modifications[key] = val;
      }
    });
  },
});
