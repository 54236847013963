import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/service-suite-templates/actions";

const initialState = {
  originalTemplates: [],
  templates: [],
  editedTemplate: {},
  originalNRServices: [],
  nonrelatedServices: [],
  affectedPrograms: {},
  isLoading: false,
  errors: null,
};

export default createReducer(initialState, {
  // List all Templates
  [moduleActions.serviceSuiteTemplates.fetchServiceSuiteTemplatesBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.serviceSuiteTemplates.fetchServiceSuiteTemplatesSuccess]: (state, action) => {
    state.isLoading = false;
    state.originalTemplates = action.payload.templateList;
  },
  [moduleActions.serviceSuiteTemplates.fetchServiceSuiteTemplatesError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
  },

  // update Template
  [moduleActions.serviceSuiteTemplates.updateServiceTemplateBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.serviceSuiteTemplates.updateServiceTemplateSuccess]: (state, action) => {
    state.isLoading = false;
  },
  [moduleActions.serviceSuiteTemplates.updateServiceTemplateError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.error;
  },

  // nonrelatedServices
  [moduleActions.serviceSuiteTemplates.fetchNonrelatedServicesBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.serviceSuiteTemplates.fetchNonrelatedServicesSuccess]: (state, action) => {
    state.isLoading = false;
    state.nonrelatedServices = action.payload;
  },
  [moduleActions.serviceSuiteTemplates.fetchNonrelatedServicesError]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // affectedPrograms for a Template
  [moduleActions.serviceSuiteTemplates.fetchAffectedProgramsBegin]: (state, action) => {
    state.isLoading = true;
  },
  [moduleActions.serviceSuiteTemplates.fetchAffectedProgramsSuccess]: (state, action) => {
    state.isLoading = false;
    state.affectedPrograms[action.payload.templateId] = action.payload.affectedPrograms;
  },
  [moduleActions.serviceSuiteTemplates.fetchAffectedProgramsError]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Redux state management functions
  [moduleActions.serviceSuiteTemplates.onUpdateNRServiceList]: (state, action) => {
    state.isLoading = false;
    state.nonrelatedServices = action.payload;
  },
  [moduleActions.serviceSuiteTemplates.onAddPreparedTemplates]: (state, action) => {
    state.isLoading = false;
    state.templates = action.payload;
  },
  [moduleActions.serviceSuiteTemplates.onUpdateTemplateList]: (state, action) => {
    state.isLoading = false;
    state.templates = action.payload;
  },
  [moduleActions.serviceSuiteTemplates.saveEditedTemplate]: (state, action) => {
    state.isLoading = false;
    state.editedTemplate = action.payload;
  },
  [moduleActions.serviceSuiteTemplates.onCancelChanges]: (state, action) => {
    state.isLoading = false;
    state.editedTemplate = {};
  },
});
