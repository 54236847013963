import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import { Row, Column, AsciButton } from "~/global";
import CannedReportFilters from "~/canned-reports/components/CannedReportFilters/CannedReportFilters.component";
import PercentBarChart from "~/canned-reports/components/PercentBarChart/PercentBarChart.component";
import ReportSummary from "~/canned-reports/components/ReportSummary/ReportSummary.component";
import moduleSelectors from "~/canned-reports/selectors/";
import { CANNED_REPORTS_FAIL_PASS_COLORS } from "~/constants";

import "./AuditResultsPerTestPage.component.scss";

const AuditResultsPerTestPage = ({ auditResultsPerTest, chartData }) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const ref = useRef();

  return (
    <Column className="devices-processed-by-group-page full-width">
      <CannedReportFilters
        currentFilters={currentFilters}
        setCurrentFilters={(filters) => setCurrentFilters(filters)}
        currentCannedReport="auditResultsPerTest"
        filtersToDisplay="timeframe facility program" // TODO: add deployment
      />

      <div className="report-results-wrapper">
        <Row className="report-results">
          <div className="report-statistics">
            <Row className="result-item">
              <div className="margin-left--5">Results:</div>
              <div className="margin-left--5">
                <b>{auditResultsPerTest.TotalResults}</b>
              </div>
              <div className="margin-left--5">Audits</div>
            </Row>

            <Row className="result-item">
              <div className="margin-left--5">{auditResultsPerTest.TotalAudits}</div>
              <div className="margin-left--5">
                <b>Executions</b>
              </div>

              <div className="margin-left--5">(Min</div>
              <div className="margin-left--5">
                <b>{auditResultsPerTest.Minimum}%</b>
              </div>

              <div className="margin-left--5">| Med</div>
              <div className="margin-left--5">
                <b>{auditResultsPerTest.Median}%</b>
              </div>

              <div className="margin-left--5">| Max</div>
              <div className="margin-left--5">
                <b>{auditResultsPerTest.Maximum}%</b>)
              </div>
            </Row>
          </div>

          <ReactToPrint
            content={() => ref.current}
            pageStyle={`
              @page { size: auto; height: 100%; margin: 1cm; }
              @media print {
                .page-break {
                  page-break-after: avoid;
                }
                body { -webkit-print-color-adjust: exact; page-break-after: avoid; }}
              .recharts-wrapper > .recharts-surface { width: 1120px; height: 600px; }
              .recharts-legend-wrapper > .recharts-default-legend { width: 1120px; height: 600px; }
            `}
            trigger={() => (
              <AsciButton color="white">
                <div className="row centered">
                  <i className="icon-printer" />
                  <span className="margin-left--5">Export</span>
                </div>
              </AsciButton>
            )}
          />
        </Row>
      </div>
      <div className="page-break" ref={ref}>
        <PercentBarChart
          title="All Audit Results"
          xAxisTitle="Percentage of Passed Audits"
          yAxis={{
            title: "Audit Tests",
            key: "Test",
            details: [
              {
                dataKey: "FailedAuditsPercent",
                name: "Failed",
                colour: CANNED_REPORTS_FAIL_PASS_COLORS.fail,
                unit: "%",
              },
              {
                dataKey: "PassedAuditsPercent",
                name: "Passed",
                colour: CANNED_REPORTS_FAIL_PASS_COLORS.pass,
                unit: "%",
              },
            ],
          }}
          data={chartData}
          median={{
            value: auditResultsPerTest.Median,
            label: `${auditResultsPerTest.Median}%`,
            title: `Median % Passed Audits`,
          }}
        />
      </div>
    </Column>
  );
};

const stateToProps = (state) => ({
  auditResultsPerTest: moduleSelectors.auditResultsPerTest.getAuditResultsPerTest(state),
  chartData: moduleSelectors.auditResultsPerTest.getChartData(state),
});

export default connect(stateToProps)(AuditResultsPerTestPage);
