import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DataTable, Divider, TableHeader, TableBody, TableRow, TableColumn, Card } from "react-md";
import { Row, Column } from "~/global";
import "./ReportSummaryProcesses.component.scss";

const ReportSummaryProcesses = (props) => {
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    props.data.length > 0 ? setIsEmpty(false) : setIsEmpty(true);
  }, [props.data]);

  return (
    <Column className="report-summary-processes-wrapper">
      <Card tableCard className="cc-paper">
        <Row className="report-summary-row" align="space-between">
          <div className="report-summary-title">Report Summary</div>
        </Row>
        <Divider />
        <Row align="space-around">
          <DataTable className="full-width" plain>
            {isEmpty ? (
              <TableBody>
                <TableRow className="borderless">
                  <TableColumn colSpan="6" className="text-centered">
                    No data to display
                  </TableColumn>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHeader className="report-summary-table-header">
                  <TableRow className="borderless">
                    {(props.columns || []).map((column) => (
                      <TableColumn key={column} className="report-summary-row-header">
                        {props.titles[column]}
                      </TableColumn>
                    ))}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {(props.data || []).map((data, indexData) => (
                    <TableRow className="borderless" key={indexData}>
                      {(props.columns || []).map((column, indexColumn) => (
                        <TableColumn key={column} className="">
                          {indexColumn === 0 ? (
                            <Row className="flow-centered report-summary-name-rolumn">
                              {data[column]}
                            </Row>
                          ) : (
                            data[column]
                          )}
                        </TableColumn>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </DataTable>
        </Row>
      </Card>
    </Column>
  );
};

ReportSummaryProcesses.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  titles: PropTypes.object.isRequired,
};

export default ReportSummaryProcesses;
