import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-md";
import uuid from "uuid/v4";

import { TextField } from "~/global";

import "./ValueEditor.component.scss";

const ValueEditor = ({ type, disabled, name, value, onChange }) => {
  const id = uuid();

  return (
    <div className="value-editor">
      {type == "bool" ? (
        <div className="bool-editor">
          <div className="label">{name}</div>

          <Switch
            id={`switch-${id}`}
            name={`switch-${id}-${name}`}
            aria-label={`switch-${name}`}
            disabled={disabled}
            onChange={onChange}
            checked={(value || "").toString().toLowerCase() == "true"}
          />
        </div>
      ) : type == "string" ? (
        <div className="string-editor">
          <div className="label">{name}</div>

          <TextField
            placeholder="Value"
            disabled={disabled}
            value={value}
            fullWidth
            onChange={onChange}
          />
        </div>
      ) : type == "integer" ? (
        <div className="integer-editor">
          <div className="label">{name}</div>

          <TextField
            type="number"
            disabled={disabled}
            value={value}
            fullWidth
            onChange={onChange}
          />
        </div>
      ) : type == "float" ? (
        <div className="float-editor">
          <div className="label">{name}</div>

          <TextField
            type="float"
            disabled={disabled}
            value={value}
            fullWidth
            step={0.1}
            onChange={onChange}
          />
        </div>
      ) : (
        <span>ValueEditor: Unknown value type "{type}"</span>
      )}
    </div>
  );
};

ValueEditor.propTypes = {
  type: PropTypes.oneOf(["bool", "string", "integer", "float"]).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

ValueEditor.defaultProps = {
  disabled: false,
};

export default ValueEditor;
