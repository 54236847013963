import React from "react";
import PropTypes from "prop-types";

import "./DetailsField.scss";

const DetailsField = (props) => {
  const { label, value, labelProps, ...rest } = props;
  return (
    <div className="details-field" {...rest}>
      <div className="details-field-label" {...labelProps}>
        {label}
      </div>
      <div className="details-field-value">{value}</div>
    </div>
  );
};

DetailsField.defaultProps = {};

DetailsField.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
  labelProps: PropTypes.object,
};

export default DetailsField;
