import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { add, assignWith, groupBy, mapValues, pick, sortBy, sum } from "lodash";

import { CustomerManagement } from "~/services";
import { RootState } from "~/store";
import { DashboardFiltersParams, helperString } from "./typeAndToolDashboard";

export type DevicesProcessed = {
  devices: number;
  averageUph: number;
  audit: number;
  auditFail: number;
  itemId: string;
  itemName: string;
  programId: string;
  programName: string;
};

export type ProcessStats = Pick<DevicesProcessed, "devices" | "averageUph" | "audit" | "auditFail">;

export type UphData = {
  row: {
    time: string;
    uph: number;
  }[];
};

type APIResponse = {
  devicesProcessedBy: {
    devicesProcessed?: DevicesProcessed[];
    uphData?: UphData[];
  };
};

/**
{
  "type": "dashboard/fetchDevicesProcessed/fulfilled",
  "payload": {
    "devicesProcessed": [
      {
        "audit": 0,
        "auditFail": 0,
        "averageUph": 7,
        "devices": 7,
        "itemId": "autoRtfBattery",
        "itemName": "Battery",
        "programId": "AutoRTF",
        "programName": "Activation + autoRTF (Jimmy)"
      },
      {
        "audit": 0,
        "auditFail": 0,
        "averageUph": 3.25,
        "devices": 13,
        "itemId": "flashingContentclearing",
        "itemName": "Flashing and Content Clearing",
        "programId": "FlashingContentClearing",
        "programName": "Flashing/ContentClearing"
      },
      {
        "audit": 0,
        "auditFail": 0,
        "averageUph": 6,
        "devices": 6,
        "itemId": "buttonsControlsAutoRtf",
        "itemName": "Buttons/Controls",
        "programId": "CopyOfAutoRTF",
        "programName": "No extra services [No GSMA, WifiPush, Activation, RTF] [Gower]"
      },
      {
        "audit": 7,
        "auditFail": 3,
        "averageUph": 7.6,
        "devices": 38,
        "itemId": "pcstest",
        "itemName": "PCSTEST",
        "programId": "PCSTest",
        "programName": "PCS Test"
      },
      {
        "audit": 0,
        "auditFail": 0,
        "averageUph": 5.5,
        "devices": 22,
        "itemId": "autoRtfBattery",
        "itemName": "Battery",
        "programId": "CopyOfAutoRTF",
        "programName": "No extra services [No GSMA, WifiPush, Activation, RTF] [Gower]"
      }
    ],
    "uphData": [
      { "row": [ { "time": "01:00", "uph": 0 }, { "time": "02:00", "uph": 0 }, { "time": "03:00", "uph": 0 }, { "time": "04:00", "uph": 0 }, { "time": "05:00", "uph": 0 }, { "time": "06:00", "uph": 0 }, { "time": "07:00", "uph": 0 }, { "time": "08:00", "uph": 0 }, { "time": "09:00", "uph": 0 }, { "time": "10:00", "uph": 0 }, { "time": "11:00", "uph": 0 }, { "time": "12:00", "uph": 0 }, { "time": "13:00", "uph": 0 }, { "time": "14:00", "uph": 0 }, { "time": "15:00", "uph": 0 }, { "time": "16:00", "uph": 0 }, { "time": "17:00", "uph": 0 }, { "time": "18:00", "uph": 0 }, { "time": "19:00", "uph": 0 }, { "time": "20:00", "uph": 7 }, { "time": "21:00", "uph": 0 }, { "time": "22:00", "uph": 0 }, { "time": "23:00", "uph": 0 }, { "time": "24:00", "uph": 0 } ] },
      { "row": [ { "time": "01:00", "uph": 0 }, { "time": "02:00", "uph": 0 }, { "time": "03:00", "uph": 0 }, { "time": "04:00", "uph": 0 }, { "time": "05:00", "uph": 0 }, { "time": "06:00", "uph": 0 }, { "time": "07:00", "uph": 0 }, { "time": "08:00", "uph": 0 }, { "time": "09:00", "uph": 0 }, { "time": "10:00", "uph": 0 }, { "time": "11:00", "uph": 0 }, { "time": "12:00", "uph": 0 }, { "time": "13:00", "uph": 0 }, { "time": "14:00", "uph": 0 }, { "time": "15:00", "uph": 1 }, { "time": "16:00", "uph": 0 }, { "time": "17:00", "uph": 0 }, { "time": "18:00", "uph": 1 }, { "time": "19:00", "uph": 0 }, { "time": "20:00", "uph": 0 }, { "time": "21:00", "uph": 5 }, { "time": "22:00", "uph": 6 }, { "time": "23:00", "uph": 0 }, { "time": "24:00", "uph": 0 } ] },
      { "row": [ { "time": "01:00", "uph": 0 }, { "time": "02:00", "uph": 0 }, { "time": "03:00", "uph": 0 }, { "time": "04:00", "uph": 0 }, { "time": "05:00", "uph": 0 }, { "time": "06:00", "uph": 0 }, { "time": "07:00", "uph": 0 }, { "time": "08:00", "uph": 0 }, { "time": "09:00", "uph": 0 }, { "time": "10:00", "uph": 0 }, { "time": "11:00", "uph": 0 }, { "time": "12:00", "uph": 0 }, { "time": "13:00", "uph": 0 }, { "time": "14:00", "uph": 0 }, { "time": "15:00", "uph": 0 }, { "time": "16:00", "uph": 6 }, { "time": "17:00", "uph": 0 }, { "time": "18:00", "uph": 0 }, { "time": "19:00", "uph": 0 }, { "time": "20:00", "uph": 0 }, { "time": "21:00", "uph": 0 }, { "time": "22:00", "uph": 0 }, { "time": "23:00", "uph": 0 }, { "time": "24:00", "uph": 0 } ] },
      { "row": [ { "time": "01:00", "uph": 0 }, { "time": "02:00", "uph": 0 }, { "time": "03:00", "uph": 0 }, { "time": "04:00", "uph": 0 }, { "time": "05:00", "uph": 0 }, { "time": "06:00", "uph": 0 }, { "time": "07:00", "uph": 0 }, { "time": "08:00", "uph": 0 }, { "time": "09:00", "uph": 0 }, { "time": "10:00", "uph": 0 }, { "time": "11:00", "uph": 0 }, { "time": "12:00", "uph": 0 }, { "time": "13:00", "uph": 0 }, { "time": "14:00", "uph": 0 }, { "time": "15:00", "uph": 1 }, { "time": "16:00", "uph": 1 }, { "time": "17:00", "uph": 0 }, { "time": "18:00", "uph": 0 }, { "time": "19:00", "uph": 24 }, { "time": "20:00", "uph": 2 }, { "time": "21:00", "uph": 10 }, { "time": "22:00", "uph": 0 }, { "time": "23:00", "uph": 0 }, { "time": "24:00", "uph": 0 } ] },
      { "row": [ { "time": "01:00", "uph": 0 }, { "time": "02:00", "uph": 0 }, { "time": "03:00", "uph": 0 }, { "time": "04:00", "uph": 0 }, { "time": "05:00", "uph": 0 }, { "time": "06:00", "uph": 0 }, { "time": "07:00", "uph": 0 }, { "time": "08:00", "uph": 0 }, { "time": "09:00", "uph": 0 }, { "time": "10:00", "uph": 0 }, { "time": "11:00", "uph": 1 }, { "time": "12:00", "uph": 0 }, { "time": "13:00", "uph": 2 }, { "time": "14:00", "uph": 0 }, { "time": "15:00", "uph": 0 }, { "time": "16:00", "uph": 8 }, { "time": "17:00", "uph": 0 }, { "time": "18:00", "uph": 0 }, { "time": "19:00", "uph": 11 }, { "time": "20:00", "uph": 0 }, { "time": "21:00", "uph": 0 }, { "time": "22:00", "uph": 0 }, { "time": "23:00", "uph": 0 }, { "time": "24:00", "uph": 0 } ] }
    ]
  },
  "meta": {
    "arg": {
      "facilityID": "FA-TES-00002",
      "deploymentID": "all",
      "shiftID": "all",
      "teamID": "all",
      "programID": "all",
      "configID": "all"
    },
    "requestId": "f-QC4KEuWeAF6TFfsxO8A",
    "requestStatus": "fulfilled"
  }
}
*/

export const fetchDevicesProcessed = createAsyncThunk<
  {
    devicesProcessed?: DevicesProcessed[];
    uphData?: UphData[];
  },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/fetchDevicesProcessed", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  try {
    const query = CustomerManagement.gqlBuilder(`query DevicesProcessed {
      devicesProcessedBy(${stringItems}) {
        devicesProcessed {
          devices,
          averageUph,
          audit,
          auditFail,
          itemId,
          itemName,
          programId,
          programName
        },
        uphData {
          row { time, uph }
        }
      }
    }`);
    const response = await CustomerManagement.QUERY<APIResponse>(query);

    const { uphData, devicesProcessed } = response.payload.data.devicesProcessedBy;

    return { uphData, devicesProcessed };
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

interface DevicesProcessedState {
  error?: string;
  devicesProcessed?: DevicesProcessed[];
  uphData?: UphData[];
  status: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: DevicesProcessedState = {
  status: "idle",
};

const DevicesProcessedSlice = createSlice({
  name: "devicesProcessed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevicesProcessed.pending, (draftState) => {
        draftState.status = "pending";
        draftState.error = undefined;
      })
      .addCase(fetchDevicesProcessed.fulfilled, (draftState, { payload }) => {
        draftState.status = "succeeded";
        draftState.uphData = payload.uphData;
        draftState.devicesProcessed = payload.devicesProcessed;
      })
      .addCase(fetchDevicesProcessed.rejected, (draftState, action) => {
        draftState.status = "idle";
        draftState.uphData = undefined;
        draftState.devicesProcessed = undefined;
        draftState.error = action.payload ?? "The request didn't complete successfully.";
      });
  },
});

// selectors
const selectSlice = (state: RootState): DevicesProcessedState =>
  state.modules.dashboard.devicesProcessed;
export const selectDevicesProcessedStatus = createSelector([selectSlice], (state) => state.status);
export const selectDevicesProcessedError = createSelector([selectSlice], (state) => state.error);
export const selectDevicesProcessed = createSelector(
  [selectSlice],
  (state): DevicesProcessed[] | undefined => state.devicesProcessed
);
export const selectUphData = createSelector(
  [selectSlice],
  (state): UphData[] | undefined => state.uphData
);

export const selectCleanUphData = createSelector(
  [selectUphData],
  (uphData): UphData[] | undefined =>
    uphData?.map(({ row }) => ({
      row: sortBy(
        row.map(({ time, uph }) => ({ time, uph: Math.max(0, uph ?? 0) })),
        ({ time }) => time
      ),
    }))
);

/**
 * Calculates the total Process runs per hour by analyzing the uphData.
 *
 * The result is (total process runs) divided by (number of hours with 1+ process runs).
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- keeping this in case it comes back
const selectTotalPrph = createSelector([selectCleanUphData], (uphData): number => {
  if (!uphData || uphData.length === 0) {
    return 0;
  }

  const addUphData = (a: UphData, b: UphData) => ({
    row: Object.entries(groupBy([...a.row, ...b.row], (item) => item.time)).map(
      ([time, values]) => ({
        time,
        uph: sum(values.map(({ uph }) => uph)),
      })
    ),
  });

  // no default value; uphData has length > 0
  const uphTotal = uphData.reduce(addUphData);
  const processesTotal = sum(uphTotal.row.map(({ uph }) => uph));
  if (processesTotal === 0) {
    return processesTotal;
  }

  const hoursTotal = uphTotal.row.filter(({ uph }) => uph > 0).length;
  return processesTotal / hoursTotal;
});

/**
 * audit: total audits
 * auditFail: total audit fails
 * devices: total devices
 * averageUph: total devices / total active hours
 *
 * An active hour is counted for each hour with 1+ devices processed, for *each* uphData row (= each service suite when querying all data).
 * This means that if two service suites are processing devices in a given hour, 2 hours are counted.
 * Example:
 *           | 08:00 | 09:00 | 10:00 | 11:00 | 12:00 | active hours | devices | average uph
 * Service 1 |     5 |     0 |     3 |     0 |     4 |            3 |      12 |           4
 * Service 2 |     1 |     6 |     0 |     0 |     0 |            2 |       7 |         3.5
 *
 * Total devices: 19
 * Total active hours: 5
 * averageUph: 3.8
 *
 * This is equivalent to a doing a weighted average of the averageUph for each row,
 * weighted by the number of active hours per row
 */
export const selectDevicesProcessedTotal = createSelector(
  [selectDevicesProcessed, selectCleanUphData],
  (devicesProcessed, uphData): ProcessStats => {
    const zeroStats = { audit: 0, auditFail: 0, averageUph: 0, devices: 0 };
    if (!devicesProcessed || !uphData) {
      return zeroStats;
    }

    const extractStats = (devicesProcessed: DevicesProcessed): ProcessStats =>
      pick(devicesProcessed, Object.keys(zeroStats) as (keyof ProcessStats)[]);
    const addStats = (a: ProcessStats, b: ProcessStats): ProcessStats => assignWith(a, b, add);
    const total = devicesProcessed.map(extractStats).reduce(addStats, zeroStats);

    // count the number of hours with 1+ process runs
    const activeHours = uphData.flatMap(({ row }) => row).filter(({ uph }) => uph > 0).length;
    const prph = total.devices === 0 ? 0 : total.devices / activeHours;

    return mapValues({ ...total, averageUph: prph }, (val) => +val.toFixed(2));
  }
);

export default DevicesProcessedSlice.reducer;
