import React, { useCallback } from "react";
import PropTypes from "prop-types";
import isNumber from "lodash/isNumber";

import { InputNumber } from "antd";

const NumberInput = (props) => {
  const { unit, onChange, ...rest } = props;

  const onChangeNumber = useCallback(
    (value) => {
      if (isNumber(Number(value))) {
        onChange(Number(value));
      }
    },
    [onChange]
  );

  return (
    <>
      <InputNumber onChange={onChangeNumber} {...rest} />
      &nbsp;{unit}
    </>
  );
};

NumberInput.defaultProps = {
  unit: "",
};

NumberInput.propTypes = {
  unit: PropTypes.string,
  onChange: PropTypes.func,
  ...InputNumber.propTypes,
};

export default NumberInput;
