import React from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "react-md";

const LabelDesignerIcon = ({ size, customId }) => (
  <SVGIcon title="Label Editor" viewBox="0 0 35 25.73" size={size}>
    <defs>
      <linearGradient id={customId} x2="35" y1="12.87" y2="12.87" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#9c3" />
        <stop offset="0.62" stopColor="#386b53" />
        <stop offset="1" stopColor="#036" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill={`url(#${customId})`}
        d="M31.44 25.73H3.57A3.57 3.57 0 0 1 0 22.17V3.57A3.57 3.57 0 0 1 3.57 0h27.87A3.57 3.57 0 0 1 35 3.57v18.6a3.57 3.57 0 0 1-3.56 3.56zM3.57 1A2.57 2.57 0 0 0 1 3.57v18.6a2.57 2.57 0 0 0 2.57 2.56h27.87A2.56 2.56 0 0 0 34 22.17V3.57A2.57 2.57 0 0 0 31.44 1z"
      />
      <path
        fill="#99cc33"
        d="M8.69 18.42a.5.5 0 0 1-.5-.5V7.78a.5.5 0 1 1 1 0v10.14a.5.5 0 0 1-.5.5zM11.07 16a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v7.75a.51.51 0 0 1-.5.47zM13.26 16a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v7.75a.51.51 0 0 1-.5.47zM15.64 16a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v7.75a.51.51 0 0 1-.5.47zM18 18.42a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v10.14a.5.5 0 0 1-.5.5zM20.42 16a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 1 0v7.75a.5.5 0 0 1-.5.47zM23.75 16a.51.51 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v7.75a.5.5 0 0 1-.5.47zM26.31 18.42a.5.5 0 0 1-.5-.5V7.78a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v10.14a.5.5 0 0 1-.5.5z"
        className="cls-2"
      />
    </g>
  </SVGIcon>
);

LabelDesignerIcon.propTypes = {
  size: PropTypes.number,
  customId: PropTypes.string,
};

export default LabelDesignerIcon;
