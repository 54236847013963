import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField } from "react-md";

import { AsciButton } from "~/global";

export default class CreateTestSetDialog extends Component {
  static propTypes = {
    identifiers: PropTypes.arrayOf(PropTypes.string).isRequired,
    platform: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    name: "",
    errors: {},
  };

  componentDidMount() {
    this._nameField.focus();
  }

  onNameChanged = (name) => {
    this.setState({ name });
  };

  onCreate = () => {
    this.props.onSubmit(this.state.name).catch((e) => {
      if (e instanceof Error) {
        throw e;
      }

      this.setState({ errors: e });
    });
  };

  render() {
    return (
      <div>
        <div>
          Create a new test set with the {this.props.identifiers.length} selected test(s) for{" "}
          {this.props.platform} platform.
        </div>

        <TextField
          id="field-set-name"
          placeholder="Test Set Name"
          onChange={this.onNameChanged}
          ref={(el) => (this._nameField = el)}
          error={!!this.state.errors.name}
          errorText={this.state.errors.name}
        />

        <div className="dialog-actions layout-row layout-row--end-center">
          <AsciButton color="white" onClick={this.props.onHide}>
            Cancel
          </AsciButton>
          <AsciButton color="green" onClick={this.onCreate}>
            Create
          </AsciButton>
        </div>
      </div>
    );
  }
}
