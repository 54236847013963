import React, { useMemo } from "react";
import { Modal } from "antd";
import { omit } from "lodash";
import { Trans } from "react-i18next";
import ReactJson from "react-json-view";

import { Descriptor } from "~/entities/model-descriptors/model/descriptorTypes";
import { useTranslation } from "~/i18n";

type Props = {
  open: boolean;
  modelDescriptor: Descriptor;
  onClose: () => void;
};

export const ViewDescriptorModal = ({ open, modelDescriptor, onClose }: Props) => {
  const { t } = useTranslation("modelDescriptors");

  const src = useMemo(() => omit(modelDescriptor, "id"), [modelDescriptor]);

  return (
    <Modal
      className="model-descriptors-modal"
      title={t("view-descriptor")}
      open={open}
      footer={false}
      onCancel={() => {
        onClose();
      }}
      width={960}
      style={{ top: 24 }}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 103px)" }}
    >
      <div className="descriptor-id">
        <Trans
          i18nKey="descriptor-id"
          ns="modelDescriptors"
          t={t}
          values={{ id: modelDescriptor.id }}
        />
      </div>

      <ReactJson
        src={src}
        name={false}
        collapsed={1}
        iconStyle="square"
        displayDataTypes={false}
        displayObjectSize={false}
      />
    </Modal>
  );
};
