import { createAction } from "@reduxjs/toolkit";

import cloneDeep from "lodash/cloneDeep";
import moduleSelectors from "~/programs-management/selectors";

export const setServiceSuiteEdition = createAction("programManagement/SET_SERVICE_SUITE_EDITION");
export const resetServiceSuiteEdition = createAction(
  "programManagement/RESET_SERVICE_SUITE_EDITION"
);

export const onChangeServiceSuite = createAction("programManagement/ON_CHANGE_SERVICE_SUITE");
export const setServiceToggled = createAction("programManagement/SET_SERVICE_TOGGLED");
export const setServiceArguments = createAction("programManagement/SET_SERVICE_ARGUMENT");
export const setServiceSuitesServices = createAction("programManagement/SET_TEST_SUITE");

export const setServicesOrder = createAction("programManagement/SET_SERVICES_ORDER");

export const onOrderChanged = (serviceIdentifiers) => async (dispatch, getState) => {
  const state = getState();
  const serviceSuiteServicesByIdentifier = cloneDeep(
    moduleSelectors.serviceSuiteEditor.getServiceSuiteServicesByIdentifier(state)
  );
  const services = serviceIdentifiers.map(
    (serviceIdentifier) => serviceSuiteServicesByIdentifier[serviceIdentifier]
  );
  dispatch(setServicesOrder({ services }));
};

export const setServiceSuite = (serviceSuite) => setServiceSuiteEdition({ serviceSuite });

export const updateServiceSuite = (serviceSuite) => onChangeServiceSuite({ serviceSuite });

export const onServiceToggled = (serviceIdentifier) => async (dispatch) => {
  if (serviceIdentifier === "AutoResetToFactoryService") {
    dispatch(setServiceToggled({ serviceIdentifier: "ApplicationRemovalService" }));
  }
  dispatch(setServiceToggled({ serviceIdentifier }));
};
export const onServiceArgumentChanged = (serviceIdentifier, serviceArguments) =>
  setServiceArguments({ serviceIdentifier, serviceArguments });

export const onTestSuiteChanged = (platform, testSuiteValue) => async (dispatch, getState) => {
  const state = getState();
  const diagnosticDeviceArgumentsByName = cloneDeep(
    moduleSelectors.serviceSuiteEditor.getDiagnosticDeviceArgumentsByName(state)
  );
  diagnosticDeviceArgumentsByName[platform].value = testSuiteValue;

  const diagnosticDeviceArguments = Object.keys(diagnosticDeviceArgumentsByName).map(
    (argumentName) => diagnosticDeviceArgumentsByName[argumentName]
  );

  const serviceSuiteServicesByIdentifier = cloneDeep(
    moduleSelectors.serviceSuiteEditor.getServiceSuiteServicesByIdentifier(state)
  );

  serviceSuiteServicesByIdentifier["DiagnosticsSuite"].arguments = diagnosticDeviceArguments;

  const services = Object.keys(serviceSuiteServicesByIdentifier).map(
    (identifier) => serviceSuiteServicesByIdentifier[identifier]
  );

  dispatch(setServiceSuitesServices({ services }));
};

export const clearServiceSuiteEdition = () => resetServiceSuiteEdition();

export const createFromTemplate = (templateIdentifier) => async (dispatch, getState) => {
  const state = getState();

  const languages = state.languages.items;
  const displayNameLanguages = languages.reduce((acc, language) => {
    acc[language.value] = "";
    return acc;
  }, {});

  const serviceSuiteTemplates = state.modules.programManagement.serviceSuiteTemplates.items;
  const services =
    serviceSuiteTemplates.filter(
      (serviceSuiteTemplate) => templateIdentifier == serviceSuiteTemplate.identifier
    )[0].services || [];

  const serviceSuite = {
    createdOn: null,
    description: "",
    displayName: displayNameLanguages,
    identifier: "",
    labelCopies: 1,
    labelType: "",
    minimumIdentifiers: [],
    pgid: "",
    printLabel: false,
    printOnResults: [],
    requiredFields: [],
    services: [...services],
    servicesTemplateIdentifier: templateIdentifier,
  };

  dispatch(setServiceSuite(serviceSuite));
};

export const createFromSuite = (copiedServiceSuite) => async (dispatch) => {
  const serviceSuite = {
    ...copiedServiceSuite,
    identifier: "",
    createdOn: null,
  };
  dispatch(setServiceSuite(serviceSuite));
};
