import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataTable, FontIcon, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import DetailsPage from "~/global/reduxer/components/details/DetailsPage";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import {
  selectors as workstationSelectors,
  actions as workstationActions,
} from "~/workstation/redux";
import DetailsSection from "~/global/reduxer/components/details/DetailsSection";
import DetailsField from "~/global/reduxer/components/details/DetailsField";
import ResultDownloader from "~/global/reduxer/components/ResultDownloader";

const ServiceItem = (props) => {
  const { className, duration, durationUnits, status } = props;
  return (
    <TableRow>
      <TableColumn>{className}</TableColumn>
      <TableColumn>
        {duration}&nbsp;{durationUnits}
      </TableColumn>
      <TableColumn>{status}</TableColumn>
    </TableRow>
  );
};

const ServicesSection = (props) => {
  const { services } = props;

  return (
    <DetailsSection sectionTitle="Services" expandable className="details-table">
      <DataTable className="cc-table full-width asci-table" plain baseId="services">
        <TableHeader>
          <TableRow>
            <TableColumn>Class Name</TableColumn>
            <TableColumn>Duration</TableColumn>
            <TableColumn>Status</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {services.map((service) => (
            <ServiceItem {...service} key={service.className} />
          ))}
        </TableBody>
      </DataTable>
    </DetailsSection>
  );
};

const TestItem = (props) => {
  const { className, name, duration, durationUnits, status } = props;
  return (
    <TableRow>
      <TableColumn>{className}</TableColumn>
      <TableColumn>{name}</TableColumn>
      <TableColumn>
        {duration}&nbsp;{durationUnits}
      </TableColumn>
      <TableColumn>{status}</TableColumn>
    </TableRow>
  );
};

const TestSection = (props) => {
  const { tests } = props;
  return (
    <DetailsSection sectionTitle="Test" expandable className="details-table">
      <DataTable className="cc-table full-width asci-table" plain baseId="tests">
        <TableHeader>
          <TableRow>
            <TableColumn>Class Name</TableColumn>
            <TableColumn>Name</TableColumn>
            <TableColumn>Duration</TableColumn>
            <TableColumn>Status</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tests.map((test) => (
            <TestItem {...test} key={test.className} />
          ))}
        </TableBody>
      </DataTable>
    </DetailsSection>
  );
};

const DeviceInfoSection = (props) => {
  const {
    IMEI,
    modelName,
    productName,
    productNumber,
    modelNumber,
    MarketingName,
    storageCapacity,
    deviceColor,
    manufacturer,
  } = props;

  return (
    <DetailsSection sectionTitle="Device Info">
      <DetailsField label="Model" value={MarketingName || productName || modelName} />
      <DetailsField label="Manufacturer" value={manufacturer} />
      <DetailsField label="IMEI" value={IMEI} />
      <DetailsField label="Model Number" value={modelNumber} />
      {productNumber && <DetailsField label="productNumber" value={productNumber} />}
      <DetailsField label="Device Color" value={deviceColor} />
      <DetailsField label="Storage Capacity" value={storageCapacity} />
    </DetailsSection>
  );
};

const CrashDetailsContent = (props) => {
  const { userComment, process, deviceInfo, services, tests } = props;
  return (
    <>
      <div className="double-section">
        <DetailsSection sectionTitle="Details">
          <DetailsField label="User comment" value={userComment || "N/A"} />
          <DetailsField
            label="Session"
            value={
              <Link to={`/workstations/sessions/${process.workstationSessionId}`}>
                {process.workstationSessionId}
              </Link>
            }
          />
          <DetailsField
            label="Workstation"
            value={
              <Link to={`/workstations/${process.workstationId}`}>{process.workstationId}</Link>
            }
          />
          <DetailsField
            label="Manual report"
            value={
              <FontIcon
                iconClassName={userComment ? "icon-check" : "icon-close"}
                style={{ color: userComment ? "green" : "red" }}
              />
            }
          />
        </DetailsSection>
        <DeviceInfoSection {...deviceInfo} />
      </div>
      <ServicesSection services={services} />
      {tests && <TestSection tests={tests} />}
    </>
  );
};

const CrashDetails = () => {
  const { crashId } = useParams();
  const crash = useSelector(workstationSelectors.crashDetails.getResults);

  return (
    <DetailsPage
      pageTitle={
        <div className="details-page-title-head">
          <span>Crash {crashId}</span>
          <ResultDownloader
            selectors={workstationSelectors.crashDetails}
            download={`crash-${crashId}.json`}
          />
        </div>
      }
    >
      <ReduxerControlled
        selectors={workstationSelectors.crashDetails}
        actions={workstationActions.crashDetails}
        fetchArguments={[crashId]}
      >
        {crash && <CrashDetailsContent {...crash} />}
      </ReduxerControlled>
    </DetailsPage>
  );
};

export default CrashDetails;
