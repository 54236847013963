import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "react-md";

import { AsciButton } from "~/global";
import actions from "~/actions";

const FailCodePriorityGroupEditor = ({ onCancel, onConfirm, failCodePriorityGroup }) => {
  const languages = ["en", "fr", "es"];
  const [names, setNames] = useState(failCodePriorityGroup?.name ?? { en: "", fr: "", es: "" });
  const nameValidationError = useSelector(
    (state) => state.failCodePriorityGroup.nameValidationError
  );

  const dispatch = useDispatch();

  const onNameChanged = (name, language) => {
    dispatch(actions.failCodePriorityGroup.validateName(name.trim(), language));
    setNames((prevNames) => ({
      ...prevNames,
      [language]: name,
    }));
  };

  const confirm = () => {
    const newFailCodePriorityGroup = {
      ...failCodePriorityGroup,
      name: names,
    };
    onConfirm(newFailCodePriorityGroup);
  };

  const errorsFound =
    names.en.length === 0 ||
    languages.some((lang) => {
      const name = names[lang];
      const error = nameValidationError[lang];
      const prevName = failCodePriorityGroup?.name[lang];
      // ignore validation error if field is empty or unchanged
      return name.trim() !== "" && name.trim() !== prevName?.trim() && error != null;
    });

  const isModified = languages.some(
    (lang) =>
      failCodePriorityGroup?.name[lang].toLowerCase().trim() !== names[lang].toLowerCase().trim()
  );

  const canSave = isModified && !errorsFound;

  return (
    <div className="full-width layout-column">
      <div className="dialog-section">
        {languages.map((language) => {
          const name = names[language];
          const error = nameValidationError[language];
          const prevName = failCodePriorityGroup?.name[language];

          const isModified = prevName?.toLowerCase().trim() !== name.toLowerCase().trim();
          const errorText = {
            badFormat:
              "Names must be 2-140 characters and can use numbers, letters, spaces and the special characters /#_-+()",
            alreadyExists: "That name is already assigned.",
          }[error];
          return (
            <div
              className="layout-row layout-row--center-center"
              key={`textfield-language-${language}`}
            >
              <div className="flex--30">
                {language.toUpperCase()}: <sup>{language == "en" ? "*" : ""}</sup>
              </div>
              <div className="flex--70">
                <TextField
                  required={language == "en"}
                  id={`fail-codes-priority-group-name-${language}`}
                  error={isModified && name !== "" && error != null}
                  errorText={errorText}
                  value={name}
                  onChange={(name) => onNameChanged(name, language)}
                  type="text"
                  onKeyPress={(e) => {
                    if (canSave && e.key == "Enter") {
                      confirm();
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="dialog-actions layout-row layout-row--end-center">
        <AsciButton color="white" onClick={onCancel}>
          Cancel
        </AsciButton>
        <AsciButton color="green" onClick={confirm} disabled={!canSave}>
          Save
        </AsciButton>
      </div>
    </div>
  );
};

FailCodePriorityGroupEditor.propTypes = {
  failCodePriorityGroup: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FailCodePriorityGroupEditor;
