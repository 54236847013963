import { createAction } from "@reduxjs/toolkit";
import { uniq } from "lodash/array";
import CountriesAndTimezones from "countries-and-timezones";
import moduleSelectors from "../selectors";
import selectors from "~/selectors/index";

import { getRegexValidation } from "~/global/utils";

export const setFacilityToEdit = createAction("customerManagement/SET_FACILITY_TO_EDIT");
export const updateCustomerFacility = createAction("customerManagement/UPDATE_CUSTOMER_FACILITY");
export const onFacilityNameValidation = createAction(
  "customerManagement/ON_FACILITY_NAME_VALIDATION"
);
export const onFacilityAddressValidation = createAction(
  "customerManagement/ON_FACILITY_ADDRESS_VALIDATION"
);
export const onFacilityCityValidation = createAction(
  "customerManagement/ON_FACILITY_CITY_VALIDATION"
);
export const onClearFacilityEdition = createAction("customerManagement/ON_CLEAR_FACILITY_EDITION");
export const onPostalCodeValidation = createAction("customerManagement/ON_POSTAL_CODE_VALIDATION");
export const updateTimezones = createAction("customerManagement/UPDATE_TIMEZONES");

export const onClear = () => onClearFacilityEdition();

export const initFacility =
  (facilityNumber = "1") =>
  (dispatch, getState) => {
    const state = getState();
    const csid = moduleSelectors.customerIdentity.getCsid(state);

    const facilityIdPrefix = csid.replace("CS", "FA");

    facilityNumber = facilityNumber.padStart(5, "0");

    const faid = [facilityIdPrefix, facilityNumber].join("-");

    const facility = {
      address: "",
      city: "",
      country: "United States of America",
      faid,
      name: "",
      postalcode: "",
      state: "New York",
      timezone: "America/New_York",
    };
    dispatch(setFacilityToEdit({ facility }));
  };

export const setFacility = (faid) => (dispatch, getState) => {
  const state = getState();
  const facilitiesById = moduleSelectors.customerFacilities.getFacilitiesById(state);
  const facility = facilitiesById[faid];
  dispatch(setFacilityToEdit({ facility }));
};

export const onChangeName = (name) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.name = name;
  dispatch(updateCustomerFacility({ facility }));
};

export const onChangeCountry = (country) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);

  if (facility.country != country) {
    facility.state = "";
  }
  facility.country = country;

  dispatch(updateCustomerFacility({ facility }));
};

export const onChangeAddress = (address) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.address = address;
  dispatch(updateCustomerFacility({ facility }));
};

export const onChangeState = (countryState) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.state = countryState;
  dispatch(updateCustomerFacility({ facility }));
};

export const onChangePostalCode = (postalcode) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.postalcode = postalcode;
  dispatch(updateCustomerFacility({ facility }));
};

export const onChangeCity = (city) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.city = city;
  dispatch(updateCustomerFacility({ facility }));
};

export const setTimeZones = () => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  const countriesByName = selectors.countries.getCountriesByName(state);
  const countryData = countriesByName[facility.country];
  const countryCode = countryData != null ? countryData.code.toUpperCase() : "US";

  const timezonesForCountry = CountriesAndTimezones.getTimezonesForCountry(countryCode);
  const allTimeZonesCountry = timezonesForCountry.map((timezone) => `${timezone.name}`);
  const timezones = uniq(allTimeZonesCountry);

  if (allTimeZonesCountry.indexOf(facility.timezone) < 0) {
    dispatch(onChangeTimezone(timezones[0]));
  }
  dispatch(updateTimezones({ timezones }));
};

export const onChangeTimezone = (timezone) => (dispatch, getState) => {
  const state = getState();
  const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
  facility.timezone = timezone;
  dispatch(updateCustomerFacility({ facility }));
};

export const validateFacilityName = (facilityName) => (dispatch) => {
  const nameForValidation = facilityName.trim();
  const isValid = getRegexValidation("Facility_Name", nameForValidation);
  const errors = !isValid;
  dispatch(onFacilityNameValidation({ errors }));
};

export const validateFacilityAddress = (address) => (dispatch) => {
  const nameForValidation = address.trim();
  const isValid = getRegexValidation("Facility_Address", nameForValidation);
  const errors = !isValid;
  dispatch(onFacilityAddressValidation({ errors }));
};

export const validateFacilityCity = (facilityCity) => (dispatch) => {
  const nameForValidation = facilityCity.trim();
  const isValid = getRegexValidation("Facility_City", nameForValidation);
  const errors = !isValid;
  dispatch(onFacilityCityValidation({ errors }));
};

export const validateFacilityPostalCode = (postalCode) => (dispatch) => {
  const nameForValidation = postalCode.trim();
  const isValid = getRegexValidation("Facility_PostalCode", nameForValidation);
  const errors = !isValid;
  dispatch(onPostalCodeValidation({ errors }));
};
