import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./LabelInspector.scss";
import LayoutInspector from "./LayoutInspector";
import ShapeInspector from "./ShapeInspector";

import labelSelectors from "~/label-printer/selectors";
import LabelStyleInspector from "./LabelStyleInspector";
import PrintSettingsInspector from "./PrintSettingsInspector";

const LabelInspector = (props) => {
  const { layoutName, layoutData, selectedShape, maxHeight } = props;
  return (
    <div className="label-inspector" style={{ maxHeight }}>
      {selectedShape && (
        // Important key change with identity otherwise the inputs don't changes
        <ShapeInspector shape={selectedShape} key={`shape-inspector-${selectedShape.identity}`} />
      )}
      <LayoutInspector layoutName={layoutName} layoutData={layoutData} />
      <LabelStyleInspector />
      <PrintSettingsInspector />
    </div>
  );
};

LabelInspector.defaultProps = {};

LabelInspector.propTypes = {
  layoutName: PropTypes.string,
  layoutData: PropTypes.object,

  maxHeight: PropTypes.number,

  selectedShape: PropTypes.object,
};

const stateToProps = (state) => ({
  selectedShape: labelSelectors.labelPicker.getSelectedShape(state),
});

export default connect(stateToProps, null)(LabelInspector);
