import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./CertificateContainer.component.scss";

export default class CertificateContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return <div className="certificate-container">{this.props.children}</div>;
  }
}
