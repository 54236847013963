import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AsciButton } from "~/global";

import moduleSelectors from "../../selectors";
import { useTranslation } from "~/i18n";

const CustomerTeamEditorButtons = (props) => {
  const { t } = useTranslation("customerTeamEditorButtons");
  return (
    <div className="layout-row layout-row--space-between-center full-width">
      <div className="info-message">{t("all-fields-marked-with-an-asterisk")}</div>
      <div className="layout-row layout-row--end-center">
        <AsciButton color="white" onClick={() => props.onCancel()}>
          {t("button-cancel")}
        </AsciButton>
        <AsciButton color="blue" disabled={!props.canSaveTeam} onClick={() => props.onSave()}>
          {t("button-save")}
        </AsciButton>
      </div>
    </div>
  );
};

CustomerTeamEditorButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

CustomerTeamEditorButtons.defaultProps = {
  onSave: () => null,
};

const stateToProps = (state) => ({
  canSaveTeam: moduleSelectors.customerTeamEditor.getCanSaveCustomerTeam(state),
});

export default connect(stateToProps, null)(CustomerTeamEditorButtons);
