import { createAction } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";

export const fetchAuditResultsPerTestBegin = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_PER_TEST_BEGIN"
);
export const fetchAuditResultsPerTestSuccess = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_PER_TEST_SUCCESS"
);
export const fetchAuditResultsPerTestError = createAction(
  "canned-reports/FETCH_AUDIT_RESULTS_PER_TEST_ERROR"
);

export const fetchAuditResultsPerTest = (facilityId, programId, from, to) => async (dispatch) => {
  const query = CustomerManagement.gqlBuilder(`{
        auditResultsByTest(
            from: "${from || ""}",
            to: "${to || ""}",
            program: "${programId === "all" ? "" : programId}",
            facility: "${facilityId === "all" ? "" : facilityId}"
        ) {
            Minimum,
            Mean,
            Median,
            Maximum,
            TotalAudits,
            TotalResults,
            ChartData {
                Test,
                Configuration,
                Audits,
                FailedAudits,
                FailedAuditsPercent
            }
        }
    }`);

  dispatch(fetchAuditResultsPerTestBegin());

  try {
    const response = await CustomerManagement.QUERY(query);
    const auditResultsPerTest =
      (((response || {}).payload || {}).data || {}).auditResultsByTest || [];
    dispatch(fetchAuditResultsPerTestSuccess({ auditResultsPerTest }));
  } catch (e) {
    if (e instanceof Error) throw e;
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(fetchAuditResultsPerTestError({ errors: message }));
  }
};
