import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Select, Switch } from "antd";

import { TextField } from "~/global";

import InspectorInput from "../commons/InspectorInput";

import labelSelectors from "~/label-printer/selectors";

const RecordingFieldInspector = (props) => {
  const { identity, path, includeLabel, customLabel, setShapeFieldData, recordingFields } = props;
  const setterFactory = (key) => (data) => setShapeFieldData({ identity, key, data });

  return (
    <>
      <InspectorInput label="Recording Value">
        <Select onChange={setterFactory("path")} defaultValue={path}>
          {recordingFields.map((field) => (
            <Select.Option key={field.path} value={field.path}>
              {field.name}
            </Select.Option>
          ))}
        </Select>
      </InspectorInput>
      <InspectorInput label="Include label">
        <Switch onChange={setterFactory("includeLabel")} checked={includeLabel} />
      </InspectorInput>
      <InspectorInput label="Custom label">
        <TextField onChange={setterFactory("customLabel")} value={customLabel} />
      </InspectorInput>
    </>
  );
};

RecordingFieldInspector.propTypes = {
  identity: PropTypes.number.isRequired,
  path: PropTypes.string,
  includeLabel: PropTypes.bool,
  customLabel: PropTypes.string,

  recordingFields: PropTypes.array,

  setShapeFieldData: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  recordingFields: labelSelectors.labelFields.getAvailableFields(state),
});

export default connect(stateToProps, null)(RecordingFieldInspector);
