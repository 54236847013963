import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontIcon } from "react-md";

import { DialogMessage, Checkbox, Column, Row } from "~/global";
import actions from "~/actions";

const ArgumentRow = ({
  description,
  disabled,
  name,
  displayName,
  isSelected,
  isRequired,
  showDialog,
  children,
  onToggle,
}) => {
  const showDescription = (description) => {
    showDialog({
      title: "Parameter Description",
      width: "450px",
      content: (close) => <DialogMessage close={close}>{description}</DialogMessage>,
    });
  };

  return (
    <Row className="argument" align="start center">
      <Column className="col-check" align="center center">
        <Checkbox
          id={`arg-row-checkbox-${name}`}
          disabled={disabled || isRequired}
          isChecked={isSelected}
          onChange={onToggle}
        />
      </Column>

      <Column className="col-info" align="center center">
        {description && (
          <FontIcon iconClassName="icon-info-circle" onClick={() => showDescription(description)} />
        )}
      </Column>

      <div className="col-name">{displayName}</div>

      <Column className="col-value">{children}</Column>
    </Row>
  );
};

ArgumentRow.propTypes = {
  description: PropTypes.string,
  displayName: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  showDialog: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

ArgumentRow.defaultProps = {
  description: "",
  displayName: "",
  disabled: false,
};

const dispatchToProps = {
  showDialog: actions.dialog.show,
};

export default connect(null, dispatchToProps)(ArgumentRow);
