import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";
import actions from "~/actions";

import { Spinner } from "~/global";

const LabelStatics = (props) => {
  const { loadedStatics, loadingStatics, fetchLabelStatics, fetchCustomFields, children } = props;

  useEffect(() => {
    if (!loadedStatics && !loadingStatics) {
      fetchLabelStatics();
      fetchCustomFields();
    }
  }, [loadingStatics, loadedStatics, fetchLabelStatics, fetchCustomFields]);

  if (!loadedStatics) {
    return <Spinner />;
  }

  return children;
};

LabelStatics.defaultProps = {};

LabelStatics.propTypes = {
  children: PropTypes.node,
  loadedStatics: PropTypes.bool,
  loadingStatics: PropTypes.bool,
  fetchLabelStatics: PropTypes.func,
  fetchCustomFields: PropTypes.func,
};

const stateToProps = (state) => ({
  loadedStatics: labelSelectors.labelStatics.isLoaded(state),
  loadingStatics: labelSelectors.labelStatics.isLoading(state),
});

const dispatchToProps = {
  fetchLabelStatics: labelActions.labelStatics.fetchLabelStatics,
  fetchCustomFields: actions.customFields.fetchCustomFields,
};

export default connect(stateToProps, dispatchToProps)(LabelStatics);
