import React from "react";
import chunk from "lodash/chunk";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { TextField } from "~/global";
import { actions as workflowActions } from "~/workflow/redux";
import LabelStatics from "~/label-printer/components/LabelStatics";
import labelSelectors from "~/label-printer/selectors";

const CompareMenu = (props) => (
  <Menu onClick={props.onClick}>
    <Menu.Item key="EQUALS">EQUALS</Menu.Item>
    <Menu.Item key="MATCH">MATCH</Menu.Item>
  </Menu>
);

const RecordingFieldCompare = (props) => {
  const { conditionData, conditionId, eventId, behaviorId } = props;
  const dispatch = useDispatch();
  const dataSetter = (key) => (value) =>
    dispatch(
      workflowActions.eventEditor[eventId].setConditionData({ conditionId, behaviorId, key, value })
    );
  const availableFields = useSelector(labelSelectors.labelFields.getAvailableFields);

  const onSelectField = (fieldObject) => () =>
    dispatch(
      workflowActions.eventEditor[eventId].setConditionData({
        conditionId,
        behaviorId,
        key: "field",
        value: fieldObject,
      })
    );

  return (
    <LabelStatics>
      <Dropdown
        overlay={
          <div className="row">
            {chunk(availableFields, 8).map((c, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Menu selectable key={`${behaviorId}-menu-chunk-${i}`}>
                {c.map((f) => (
                  <Menu.Item
                    onClick={onSelectField(f)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${behaviorId}-menu-chunk-${i}-${f.path}`}
                  >
                    {f.name}
                  </Menu.Item>
                ))}
              </Menu>
            ))}
          </div>
        }
      >
        <Button>{(conditionData.field && conditionData.field.name) || "Select field"}</Button>
      </Dropdown>
      <Dropdown overlay={<CompareMenu onClick={(e) => dataSetter("compareType")(e.key)} />}>
        <Button>{conditionData.compareType}</Button>
      </Dropdown>
      <TextField value={conditionData.value} onChange={dataSetter("value")} />
    </LabelStatics>
  );
};

export default RecordingFieldCompare;
