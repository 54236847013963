import { saveAs } from "file-saver";

const API: Record<string, string> = {};

const getBearerToken = () => {
  const sessionString = localStorage.getItem("session");
  if (sessionString === null) {
    return undefined;
  }

  const { bearerToken } = JSON.parse(sessionString) as { bearerToken: string };
  return bearerToken;
};

export default class WebserviceHandler {
  static setHost(name: string, value: string): void {
    API[name] = value;
  }
  apiName: string;
  constructor(name: string) {
    this.apiName = name;
  }

  fetchApi(path: string, method: string, data?: unknown): Promise<Record<string, unknown>> {
    let contentType: string | null = "application/json";
    let body: null | string | FormData;

    if (data == null) {
      body = null;
    } else if (typeof data === "string") {
      body = data;
    } else if (data instanceof FormData) {
      contentType = null;
      body = data;
    } else {
      body = JSON.stringify(data);
    }
    return new Promise((resolve, reject) => {
      const host = API[this.apiName];
      if (!host) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`Host is not set for API "${this.apiName}".`);
        return;
      }

      const token = getBearerToken();
      if (!token) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Bearer token required (current value: ${token}, url: ${this.apiName + path}`
        );
        return;
      }

      const headers: Record<string, string> = contentType
        ? { Authorization: `Bearer ${token}`, "Content-Type": contentType }
        : { Authorization: `Bearer ${token}` };

      fetch(API[this.apiName] + path, {
        method,
        body,
        headers,
      })
        .then(async (response) => {
          type GqlResult = {
            data: unknown;
          };
          type ApiResponse = {
            commit?: string;
            success: boolean;
            message: string;
            payload: GqlResult;
          };
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const jsonRaw: ApiResponse | GqlResult = await response.json();
          const json: ApiResponse =
            "data" in jsonRaw
              ? {
                  success: true,
                  message: "GraphQL response",
                  payload: jsonRaw,
                }
              : jsonRaw;

          // TODO: remove that and modify .GQL method instead
          const isValidPOSTGql =
            method === "POST" &&
            path.slice(path.length - 4, path.length) === "/gql" &&
            response.status === 200;

          const isGetVersion =
            path === "/version" &&
            method === "GET" &&
            response.status === 200 &&
            json.commit != null;
          if (isGetVersion) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            resolve(json.commit as any);
          } else if (json.success || isValidPOSTGql) {
            resolve(json);
          } else {
            // eslint-disable-next-line no-console
            console.error(
              [
                `Request Error`,
                `\tMethod: ${method}`,
                `\tURL: ${response.url}`,
                `\tStatus: ${response.status} - ${response.statusText}`,
                `\tSuccess: ${json.success}`, // eslint-disable-line @typescript-eslint/restrict-template-expressions
                `\tMessage: ${json.message}`,
              ].join("\n")
            );

            if (response.status === 401 || json.message.indexOf("token is expired") >= 0) {
              // HACK: should find a way to call the logout action instead of doing this
              localStorage.removeItem("session");
              window.location.reload();
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ response, json });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(
            `[${this.apiName}] Request didn't complete successfully (${method} ${path})`
          );
          // eslint-disable-next-line prefer-promise-reject-errors, @typescript-eslint/no-unsafe-assignment
          reject({ message: error });
        });
    });
  }

  getImage = async (path: string, externalUrl = false): Promise<ArrayBuffer> => {
    const downloadUrl = externalUrl ? path : API[this.apiName] + path;
    const token = getBearerToken();
    if (!token) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Bearer token required (current value: ${token}, url : ${this.apiName + path}`
      );
    }

    const response = await fetch(downloadUrl, {
      method: "GET",
      headers: {
        Authorization: externalUrl ? "" : `Bearer ${token}`,
        "Content-Type": "text/plain",
      },
    });

    if (response.status === 200) {
      const blob = await response.blob();

      const reader = new FileReader();
      reader.readAsDataURL(blob);

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          if (reader.result != null) {
            resolve(reader.result as ArrayBuffer);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject("Error");
          }
        };
      });
    }
    return Promise.reject(response);
  };

  downloadFile = async (
    path: string,
    contentType: string,
    fileName: string | undefined,
    externalUrl = false
  ): Promise<string> => {
    const downloadUrl = externalUrl ? path : API[this.apiName] + path;

    const token = getBearerToken();
    if (!token) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Bearer token required (current value: ${token}, url : ${this.apiName + path}`
      );
    }

    const response = await fetch(downloadUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
      },
    });

    if (response.status === 200) {
      const blob = await response.blob();
      saveAs(blob, fileName);
      return "file downloaded";
    }
    return Promise.reject(response);
  };

  getServiceVersion(): Promise<string> {
    return this.fetchApi(`/version`, "GET") as unknown as Promise<string>;
  }

  GET(path: string): Promise<Record<string, unknown>> {
    return this.fetchApi(path, "GET");
  }

  PUT(path: string, data: unknown): Promise<Record<string, unknown>> {
    return this.fetchApi(path, "PUT", data);
  }

  POST(path: string, data: unknown): Promise<Record<string, unknown>> {
    return this.fetchApi(path, "POST", data);
  }

  PATCH(path: string, data: unknown): Promise<Record<string, unknown>> {
    return this.fetchApi(path, "PATCH", data);
  }

  DELETE(path: string, data: unknown): Promise<Record<string, unknown>> {
    if (data != null) {
      return this.fetchApi(path, "DELETE", data);
    }
    return this.fetchApi(path, "DELETE");
  }

  QUERY<T extends Record<string, unknown>>(
    query: string
  ): Promise<{
    payload: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: T;
      errors: { message: string }[];
    };
  }> {
    return new Promise((resolve, reject) => {
      (
        this.fetchApi(`/gql?${new URLSearchParams({ query }).toString()}`, "GET") as Promise<{
          payload: {
            data: T;
            errors: { message: string }[];
          };
        }>
      )
        .then((json) => {
          // Special case for GQL that returns 200 even when there's an error
          if ((json?.payload?.errors?.length ?? 0) > 0) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(`GQL errors: ${json.payload.errors.map((err) => err.message).join(", ")}`);
          }

          resolve(json);
        })
        .catch((message) => reject(message));
    });
  }

  // Strip spaces and linebreaks
  // eslint-disable-next-line class-methods-use-this
  gqlBuilder(query = "", fragments: string | string[] = []): string {
    // In order to be mocked for integration testing, graphql queries must be _named_,
    // e.g.: `query MyQuery { something { foo, bar } }`.
    // Thus, the first space (after `query`) must be preserved when removing whitespace.
    let first = true;
    // eslint-disable-next-line no-param-reassign
    query = query.replace(/[ \n]/g, (match) => {
      if (first) {
        first = false;
        return match;
      }
      return "";
    }); // remove white spaces and line breaks

    if (typeof fragments === "string") {
      // eslint-disable-next-line no-param-reassign
      fragments = [fragments];
    }

    fragments.forEach((f) => {
      // remove 2 consecutive white spaces, needed because of the fragment
      // remove line breaks
      query += f.replace(/[ ]{2,}/g, "").replace(/\n/g, ""); // eslint-disable-line no-param-reassign
    });

    return query;
  }
}
