import customerAudits from "./customerAudits.json";
import common from "./common.json";
import csvUpload from "./csvUpload.json";
import customerEditor from "./customerEditor.json";
import customerFacilities from "./customerFacilities.json";
import customerFacilityEditor from "./customerFacilityEditor.json";
import customerFacilityEditorButtons from "./customerFacilityEditorButtons.json";
import customerIdentity from "./customerIdentity.json";
import customerList from "./customerList.json";
import customerManagementPage from "./customerManagementPage.json";
import customerTeamDetails from "./customerTeamDetails.json";
import customerTeamEditor from "./customerTeamEditor.json";
import customerTeamEditorButtons from "./customerTeamEditorButtons.json";
import customerTeams from "./customerTeams.json";
import customerUserEditor from "./customerUserEditor.json";
import customerUserEditorButtons from "./customerUserEditorButtons.json";
import customerUsers from "./customerUsers.json";
import customerWizard from "./customerWizard.json";
import customerWizardButtons from "./customerWizardButtons.json";
import dashboard from "./dashboard.json";
import dataExport from "./dataExport.json";
import DatePicker from "./DatePicker.json";
import dateTimeSelector from "./dateTimeSelector.json";
import dayPicker from "./dayPicker.json";
import deviceHistory from "./deviceHistory.json";
import dh from "./dh.json";
import footer from "./footer.json";
import home from "./home.json";
import imageUploader from "./imageUploader.json";
import modelDescriptors from "./modelDescriptors.json";
import navbar from "./navbar.json";
import reporting from "./reporting.json";
import sku from "./sku.json";
import TimePicker from "./TimePicker.json";
import uniquenessFilter from "./uniquenessFilter.json";
import wizardNavigation from "./wizardNavigation.json";
import inventory from "./inventory.json";

export default {
  common,
  csvUpload,
  customerEditor,
  customerFacilities,
  customerFacilityEditor,
  customerFacilityEditorButtons,
  customerIdentity,
  customerList,
  customerManagementPage,
  customerTeamDetails,
  customerTeamEditor,
  customerTeamEditorButtons,
  customerTeams,
  customerUserEditor,
  customerUserEditorButtons,
  customerUsers,
  customerWizard,
  customerWizardButtons,
  customerAudits,
  dashboard,
  dataExport,
  DatePicker,
  dateTimeSelector,
  dayPicker,
  deviceHistory,
  dh,
  footer,
  home,
  inventory,
  imageUploader,
  modelDescriptors,
  navbar,
  reporting,
  sku,
  TimePicker,
  uniquenessFilter,
  wizardNavigation,
};
