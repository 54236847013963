import { createAction } from "@reduxjs/toolkit";
import moduleSelectors from "../selectors";

import { getRegexValidation } from "~/global/utils";

export const setTeamToEdit = createAction("customerManagement/SET_TEAM_TO_EDIT");
export const updateCustomerTeam = createAction("customerManagement/UPDATE_CUSTOMER_TEAM");
export const onClearTeamEdition = createAction("customerManagement/ON_CLEAR_TEAM_EDITION");

export const onTeamNameValidation = createAction("customerManagement/ON_TEAM_NAME_VALIDATION");
export const onTeamNameExistValidation = createAction(
  "customerManagement/ON_TEAM_NAME_EXIST_VALIDATION"
);
export const onTeamDescriptionValidation = createAction(
  "customerManagement/ON_TEAM_DESCRIPTION_VALIDATION"
);

export const onClear = () => onClearTeamEdition();

export const initTeam = (csid) => async (dispatch, getState) => {
  const state = getState();
  const facilitiesList = moduleSelectors.customerFacilities.getUpdatedFacilities(state);

  const team = {
    description: "",
    faid: facilitiesList[0].faid,
    name: "",
    teamid: "",
    usersid: [],
    csid,
  };
  dispatch(setTeamToEdit({ team }));
};

export const setTeam = (teamData) => (dispatch, getState) => {
  const state = getState();
  const teams = moduleSelectors.customerTeams.getTeamsById(state);
  const team = teams[teamData.teamid];
  dispatch(setTeamToEdit({ team }));
};

export const validateTeamName = (teamName) => (dispatch) => {
  const nameForValidation = teamName.trim();
  const isValid = getRegexValidation("Team_Name", nameForValidation);
  const errors = !isValid;
  dispatch(onTeamNameValidation({ errors }));
};

export const validateNameExist = (teamName, originalTeamName) => (dispatch, getState) => {
  const state = getState();
  const teamNames = moduleSelectors.customerTeams.getTeamsNames(state);
  const teamsToEvaluate = teamNames.filter((teamName) => teamName != originalTeamName);
  const nameForValidation = teamName.trim();
  const alreadyExist = teamsToEvaluate.indexOf(nameForValidation) > -1;
  dispatch(onTeamNameExistValidation({ errors: alreadyExist }));
};

export const onChangeName = (name) => (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  team.name = name;
  dispatch(updateCustomerTeam({ team }));
};

export const validateTeamDescription = (teamDescription) => (dispatch) => {
  const nameForValidation = teamDescription.trim();
  const isValid = getRegexValidation("Team_Description", nameForValidation);
  const errors = !isValid;
  dispatch(onTeamDescriptionValidation({ errors }));
};

export const onChangeDescription = (description) => (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  team.description = description;
  dispatch(updateCustomerTeam({ team }));
};

export const onChangeFacility = (faid) => (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  team.faid = faid;
  team.usersid = [];

  dispatch(updateCustomerTeam({ team }));
};

export const initTeamMembers = (users) => (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);

  const usersid = users
    .map((user) => user.userid)
    .filter((userid) => team.usersid.indexOf(userid) > -1);
  team.usersid = usersid;
  dispatch(updateCustomerTeam({ team }));
};

export const onChangeUserIdMembers = (usersid) => (dispatch, getState) => {
  const state = getState();
  const team = moduleSelectors.customerTeamEditor.getUpdatedCustomerTeam(state);
  team.usersid = usersid;
  dispatch(updateCustomerTeam({ team }));
};
