import { CustomerManagement } from "~/services";
import { getRegexValidation } from "~/global/utils";

export const ADD_FAIL_CODE_BEGIN = "ADD_FAIL_CODE_BEGIN";
export const ADD_FAIL_CODE_ERROR = "ADD_FAIL_CODE_ERROR";
export const ADD_FAIL_CODE_SUCCESS = "ADD_FAIL_CODE_SUCCESS";
export const SELECT_FAIL_CODE = "SELECT_FAIL_CODE";
export const SET_FAIL_CODE_DESCRIPTION = "SET_FAIL_CODE_DESCRIPTION";
export const SET_FAIL_CODE_NAME = "SET_FAIL_CODE_NAME";
export const UPDATE_FAIL_CODE_BEGIN = "UPDATE_FAIL_CODE_BEGIN";
export const UPDATE_FAIL_CODE_ERROR = "UPDATE_FAIL_CODE_ERROR";
export const UPDATE_FAIL_CODE_SUCCESS = "UPDATE_FAIL_CODE_SUCCESS";
export const VALIDATE_FAIL_CODE_DESCRIPTION = "VALIDATE_FAIL_CODE_DESCRIPTION";
export const VALIDATE_FAIL_CODE_NAME = "VALIDATE_FAIL_CODE_NAME";
export const FETCH_FAIL_CODE_ASSOCIATED_TESTS_BEGIN = "FETCH_FAIL_CODE_ASSOCIATED_TESTS_BEGIN";
export const FETCH_FAIL_CODE_ASSOCIATED_TESTS_SUCCESS = "FETCH_FAIL_CODE_ASSOCIATED_TESTS_SUCCESS";
export const FETCH_FAIL_CODE_ASSOCIATED_TESTS_ERROR = "FETCH_FAIL_CODE_ASSOCIATED_TESTS_ERROR";
export const SET_FAIL_CODE_FAIL_FAST = "SET_FAIL_CODE_FAIL_FAST";
export const SET_FAIL_CODE_SHOW_ON_DESKTOP = "SET_FAIL_CODE_SHOW_ON_DESKTOP";
export const RESET_FAIL_CODE = "RESET_FAIL_CODE";
export const SET_FAIL_ROUTE = "SET_FAIL_ROUTE";

export const validateName = (name, existingNames) => {
  const isValid = getRegexValidation("failCodeName", name);
  const newName = existingNames.indexOf(name.toLowerCase()) < 0;
  const error = !isValid
    ? "The name must be 2-140 characters and can use numbers, letters, spaces and the special characters /#_-+()."
    : !newName
    ? "This name is already assigned."
    : "";

  return {
    type: VALIDATE_FAIL_CODE_NAME,
    payload: { error },
  };
};

export const validateDescription = (description, language) => (dispatch) => {
  const isValid = getRegexValidation("failCodeDescription", description);

  const error = !isValid
    ? "Description must be 2-140 characters and can use numbers, letters, spaces and the special characters /#_-+%.'()."
    : "";

  dispatch({
    type: VALIDATE_FAIL_CODE_DESCRIPTION,
    payload: {
      error: {
        description: {
          [language]: error,
        },
      },
    },
  });
};

export const setName = (name) => ({
  type: SET_FAIL_CODE_NAME,
  payload: { name },
});
export const setRoute = (route) => ({
  type: SET_FAIL_ROUTE,
  payload: { route },
});
export const setDescription = (description) => ({
  type: SET_FAIL_CODE_DESCRIPTION,
  payload: { description },
});

export const setFailFast = (failFast) => ({
  type: SET_FAIL_CODE_FAIL_FAST,
  payload: { failFast },
});

export const setShowOnDesktop = (showOnDesktop) => ({
  type: SET_FAIL_CODE_SHOW_ON_DESKTOP,
  payload: { showOnDesktop },
});

export const selectFailCode = (failCode = {}) => ({
  type: SELECT_FAIL_CODE,
  payload: { failCode },
});

export const addFailCode = (failCode) => async (dispatch) => {
  const serverFormat = {
    name: failCode.name || "",
    description: { ...failCode.description },
    failFast: failCode.failFast,
    type: failCode.showOnDesktop ? "desktop" : "",
    categoryID: failCode.categoryId,
    route: failCode.route,
  };

  const failCodePlaceholder = {
    ...failCode,
    id: "placeholder",
    showOnDesktop: failCode.showOnDesktop == null ? false : failCode.showOnDesktop,
  };

  dispatch(addFailCodeBegin(failCodePlaceholder));

  try {
    const response = await CustomerManagement.POST("/fail-codes", serverFormat);
    dispatch(addFailCodeSuccess(response.payload));
    return response.payload;
  } catch (error) {
    dispatch(addFailCodeError(error));
  }
};

export const addFailCodeBegin = (placeholder) => ({
  type: ADD_FAIL_CODE_BEGIN,
  payload: {
    placeholder,
  },
});

export const addFailCodeSuccess = (data) => {
  const failCode = {
    categoryId: data.categoryID,
    description: data.description || {},
    failFast: data.failFast,
    id: data.id,
    name: data.name || "",
    showOnDesktop: data.type === "desktop",
    route: data.route,
  };

  return {
    type: ADD_FAIL_CODE_SUCCESS,
    payload: { failCode },
  };
};

export const addFailCodeError = (response) => {
  const errors =
    response.json != null
      ? Object.keys(response.json.errors || {}).map((error) => response.json.errors[error])
      : [];
  const title = response.json != null ? response.json.message : "Error";
  return {
    type: ADD_FAIL_CODE_ERROR,
    payload: {
      message: errors.length > 0 ? errors : ["Failed to update Fail Code"],
      title,
    },
  };
};

export const updateFailCode = (failCode) => async (dispatch) => {
  const serverFormat = {
    id: failCode.id,
    name: failCode.name || "",
    description: { ...failCode.description },
    failFast: failCode.failFast,
    type: failCode.showOnDesktop ? "desktop" : "",
    categoryID: failCode.categoryId,
    route: failCode.route,
  };

  try {
    dispatch(updateFailCodeBegin(failCode));
    await CustomerManagement.PUT("/fail-codes", serverFormat);
    dispatch(updateFailCodeSuccess(failCode));
  } catch (error) {
    dispatch(updateFailCodeError(error));
  }
};

export const updateFailCodeBegin = () => ({
  type: UPDATE_FAIL_CODE_BEGIN,
});

export const updateFailCodeSuccess = (failCode) => ({
  type: UPDATE_FAIL_CODE_SUCCESS,
  payload: { failCode },
});

export const updateFailCodeError = (response) => {
  const errors =
    response.json != null
      ? Object.keys(response.json.errors || {}).map((error) => response.json.errors[error])
      : [];
  return {
    type: UPDATE_FAIL_CODE_ERROR,
    payload: {
      errors: {
        message: errors.length > 0 ? errors : ["Failed to update Fail Code Category"],
        title: "Error",
      },
    },
  };
};

export const resetFailCode = () => ({
  type: RESET_FAIL_CODE,
});

export const fetchFailCodeAssociatedTests = (failCodeId) => async (dispatch) => {
  try {
    dispatch(fetchFailCodeAssociatedTestsBegin());

    const query = CustomerManagement.gqlBuilder(`{
            test_definitions_using_fail_code(failCodeId: "${failCodeId}") {
                className,
                displayName,
                identifier,
                identifierDisplayName,
                description,
                image,
                platform
            }
        }`);

    const response = await CustomerManagement.POST("/gql", { query });
    const tests = ((response.payload || {}).data || {}).test_definitions_using_fail_code || [];

    dispatch(fetchFailCodeAssociatedTestsSuccess(tests));
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
    dispatch(fetchFailCodeAssociatedTestsError(e.json));
  }
};

const fetchFailCodeAssociatedTestsBegin = () => ({
  type: FETCH_FAIL_CODE_ASSOCIATED_TESTS_BEGIN,
});

const fetchFailCodeAssociatedTestsSuccess = (tests) => ({
  type: FETCH_FAIL_CODE_ASSOCIATED_TESTS_SUCCESS,
  payload: { tests },
});

const fetchFailCodeAssociatedTestsError = (e) => ({
  type: FETCH_FAIL_CODE_ASSOCIATED_TESTS_ERROR,
  payload: { errors: e },
});
