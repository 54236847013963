import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message, Select, Space } from "antd";

import {
  createSkuGroup,
  deviceTypes,
  SkuGroupBody,
} from "~/entities/sku-groups/model/skuGroupsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { selectAdditionalAttributes, selectSkuAttributes } from "../model/skuStaticsSlice";

export const CreateSkuGroupForm = () => {
  const { t } = useTranslation("sku");
  const dispatch = useAppDispatch();

  const skuAttributes = useAppSelector(selectSkuAttributes);
  const additionalAttributes = useAppSelector(selectAdditionalAttributes);
  const { formStatus } = useAppSelector((state) => state.skuGroups);

  return (
    <Card
      title={
        <Space>
          <PlusOutlined />
          <span>{t("create-sku-group")}</span>
        </Space>
      }
      bordered={false}
      style={{ marginBottom: "2em" }}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          skuAttributes: skuAttributes?.filter((a) => a.isDefault).map((a) => a.value),
          additionalAttributes: additionalAttributes
            ?.filter((a) => a.isDefault)
            .map((a) => a.value),
        }}
        onFinish={(values: SkuGroupBody) => {
          dispatch(createSkuGroup(values))
            .unwrap()
            .then(() => {
              void message.success(t("create-success"));
            })
            .catch((rejectedValue: string) => {
              void message.error(rejectedValue);
            });
        }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name-required") }]}
        >
          <Input placeholder={t("enter-name")} />
        </Form.Item>

        <Form.Item
          label={t("device-type")}
          name="deviceType"
          rules={[{ required: true, message: t("device-required") }]}
        >
          <Select
            style={{ width: 240 }}
            placeholder={t("select-device")}
            options={deviceTypes.map((type) => ({
              value: type,
              label: {
                phone: t("phone"),
                tablet: t("tablet"),
                watch: t("watch"),
              }[type],
            }))}
          />
        </Form.Item>

        <Form.Item
          label={t("required-attributes")}
          name="skuAttributes"
          rules={[{ required: true, message: t("attributes-required") }]}
        >
          <Select options={skuAttributes} mode="multiple" allowClear style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label={t("additional-attributes")} name="additionalAttributes">
          <Select
            options={additionalAttributes}
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={formStatus === "loading"}>
              {t("create")}
            </Button>

            <Button htmlType="reset">{t("reset")}</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
