import React, { useEffect } from "react";
import { Select } from "antd";
import produce from "immer";
import findIndex from "lodash/findIndex";
import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import { Switch, TextField } from "react-md";
import { connect } from "react-redux";

import actions from "~/actions";
import { AsciButton, Row } from "~/global";
import { DeviceTestArgument } from "~/models";
import { getTestDefinitionOptions } from "~/selectors/testDefinitions.selectors";
import moduleActions from "../../actions";
import { getAreGalleryImagesLoading, getGalleryImages } from "../../selectors";
import ImageGalleryDialog from "../ImageGalleryDialog/ImageGalleryDialog.component";
import ArgumentRow from "./ArgumentRow.component";

import "./TestDefinitionArgumentsEditor.component.scss";

const TestDefinitionArgumentsEditor = ({
  configName,
  arguments: testArguments,
  argumentsDetails,
  platform,
  disabled,
  onChange,
  showDialog,
  fetchImages,
  images,
  imagesLoading,
  testDefinitionOptions,
}) => {
  const COMBINED_IMAGE_ICON_ITEM_NAME = "icon_and_image";
  const IMAGE_ARGUMENT_NAME = "image";
  const ICON_ARGUMENT_NAME = "icon";
  const AUDIT_ARGUMENT_NAME = "isOperatorAuditEnabled";
  const AUTONEXT_ARGUMENT_NAME = "autonext";
  const FAILFAST_ARGUMENT_NAME = "isFailFast";
  const VERSION_TEST = "VersionTest";
  const VERSION_TEST_EXACT_VALUE = "exactValue";
  const VERSION_TEST_MINIMUM_VALUE = "minimumValue";
  const VERSION_TEST_MAXIMUM_VALUE = "maximumValue";

  useEffect(() => {
    fetchImages();
  }, []);

  const onBoolArgumentToggled = (arg) => {
    const newValue = !arg.value;
    const newArguments = updateArgValue(testArguments, arg.name, newValue);
    onChange(newArguments);
  };

  const onIntegerArgumentChanged = (arg, val) => {
    const newValue = Math.floor(val); // Math.floor also convert string to int
    const newArguments = updateArgValue(testArguments, arg.name, newValue);
    onChange(newArguments);
  };

  const onPercentArgumentChanged = (arg, val) => {
    let newValue;

    if (val < 1) {
      newValue = 1;
    } else if (val > 100) {
      newValue = 100;
    } else {
      newValue = Math.floor(val);
    }

    const newArguments = updateArgValue(testArguments, arg.name, newValue);
    onChange(newArguments);
  };

  const onFloatArgumentChanged = (arg, val) => {
    const newValue = +val;
    const newArguments = updateArgValue(testArguments, arg.name, newValue);
    onChange(newArguments);
  };

  const onStringArgumentChanged = (arg, val) => {
    const newArguments = updateArgValue(testArguments, arg.name, val);
    onChange(newArguments);
  };

  const onStringBlur = (arg) => {
    onStringArgumentChanged(arg, arg.value.trim());
  };

  const openGallery = (testIconArg, testPanelImageArg) => {
    showDialog({
      title: "Image Gallery",
      width: "500px",
      content: (close, setTitle) => (
        <ImageGalleryDialog
          selectedIcon={testIconArg.value}
          selectedPanelImage={testPanelImageArg.value}
          platform={platform}
          setTitle={setTitle}
          onCancel={close}
          onConfirm={(iconFilename, panelFilename) => {
            // change both arguments at the same time
            let newArgs = updateArgValue(testArguments, testIconArg.name, iconFilename);
            newArgs = updateArgValue(newArgs, testPanelImageArg.name, panelFilename);

            onChange(newArgs);
            close();
          }}
        />
      ),
    });
  };

  const getImageURL = (type, filename) => {
    if (images) {
      return `${images.url}/${platform}/${type}/${filename}`;
    }
    return null;
  };

  const toggleArgument = (argName, isSelected) => {
    const index = findIndex(testArguments, (a) => a.name === argName);
    let newArguments;

    if (!isSelected || index > -1) {
      newArguments = removeArgument(testArguments, argName);
    }

    if (isSelected) {
      newArguments = addArgument(testArguments, argName);
    }

    if (newArguments) {
      onChange(newArguments);
    }
  };

  const removeWhenSelected = (argName, isSelected, argsToRemove) => {
    const i = findIndex(testArguments, (a) => a.name === argName);
    let newArguments;

    if (!isSelected || i > -1) {
      newArguments = removeArgument(testArguments, argName);
    } else {
      newArguments = addArgument(testArguments, argName);

      argsToRemove.map((argToRemove) => {
        const j = findIndex(testArguments, (a) => a.name === argToRemove);

        if (j > -1) {
          newArguments = removeArgument(newArguments, argToRemove);
        }
      });
    }

    if (newArguments) {
      onChange(newArguments);
    }
  };

  const addArgument = (args, argName) => {
    const argDetails = argumentsDetails[argName];

    return produce(args, (draft) => {
      draft.push({
        name: argName,
        type: argDetails.type,
        value: DeviceTestArgument.getDefaultValue(argDetails),
      });
    });
  };

  const removeArgument = (args, argName) => {
    const index = findIndex(args, (a) => a.name === argName);

    if (index > -1) {
      return produce(args, (draft) => {
        draft.splice(index, 1);
      });
    }
  };

  const updateArgValue = (args, argName, newValue) =>
    produce(args, (draft) => {
      const index = findIndex(args, (a) => a.name === argName);
      draft[index].value = newValue;
    });

  const toggleImagesArguments = async (isSelected) => {
    let newArgs;

    if (isSelected) {
      newArgs = addArgument(testArguments, ICON_ARGUMENT_NAME);
      newArgs = addArgument(newArgs, IMAGE_ARGUMENT_NAME);
    } else {
      newArgs = removeArgument(testArguments, ICON_ARGUMENT_NAME);
      newArgs = removeArgument(newArgs, IMAGE_ARGUMENT_NAME);
    }

    onChange(newArgs);
  };

  const indexArgumentsByName = (args) => {
    let audit;
    let autonext;
    let failfast;

    const byName = args.reduce((acc, arg) => {
      acc[arg.name] = arg;

      // at the same time, find the audit, autonext and failfast arguments
      if (arg.name === AUDIT_ARGUMENT_NAME) {
        audit = arg;
      } else if (arg.name === AUTONEXT_ARGUMENT_NAME) {
        autonext = arg;
      } else if (arg.name === FAILFAST_ARGUMENT_NAME) {
        failfast = arg;
      }

      return acc;
    }, {});

    return [byName, audit, autonext, failfast];
  };

  const getDisplayedArguments = (args, argsDetails) => {
    const [currentArgumentsByName, audit, autonext, failfast] = indexArgumentsByName(args);
    const isAuditEnabled = audit && audit.value === true;
    let displayedArgs = [];
    let testIcon;
    let testPanelImage;

    for (const name in argsDetails) {
      const argDetails = argsDetails[name];

      // arguments with "onlyWhenAuditable" to true are only displayed when the auditable argument is enabled
      if (!isAuditEnabled && argDetails.onlyWhenAuditable) {
        continue;
      }

      // ignore localizableString
      if (DeviceTestArgument.isTypeLocalizableString(argDetails)) {
        continue;
      }

      const item = {
        name: argDetails.name,
        displayName: argDetails.displayName || argDetails.name,
        description: argDetails.description,
        type: argDetails.type,
        isSelected: currentArgumentsByName[argDetails.name] != null,
        isRequired: argDetails.required,
      };

      if (item.isSelected) {
        item.value = currentArgumentsByName[argDetails.name].value;
      } else {
        item.value = DeviceTestArgument.getDefaultValue(argDetails);
      }

      // different layout for these 2 arguments
      if (
        argDetails.name == ICON_ARGUMENT_NAME &&
        DeviceTestArgument.isTypeBundledImage(argDetails)
      ) {
        testIcon = item;
      } else if (
        argDetails.name == IMAGE_ARGUMENT_NAME &&
        DeviceTestArgument.isTypeBundledImage(argDetails)
      ) {
        testPanelImage = item;
      } else {
        displayedArgs.push(item);
      }
    }

    // combine icon and image argument into a single row
    if (testIcon && testPanelImage) {
      displayedArgs.push({
        name: COMBINED_IMAGE_ICON_ITEM_NAME,
        displayName: "Test Images",
        description: "",
        isSelected:
          currentArgumentsByName[testIcon.name] != null &&
          currentArgumentsByName[testPanelImage.name] != null,
        isRequired:
          argsDetails[testIcon.name].required && argsDetails[testPanelImage.name].required,
      });
    }

    displayedArgs = sortBy(displayedArgs, (i) => (i.displayName || "").toLowerCase());

    return [displayedArgs, audit, autonext, failfast, testIcon, testPanelImage];
  };

  const toggleAuditable = (audit) => {
    let newArgs;

    // turning audit off, remove all audit-only arguments
    if (audit.value) {
      newArgs = testArguments.filter((arg) => {
        const details = argumentsDetails[arg.name];
        return !details || (details && !details.onlyWhenAuditable);
      });
    }

    // toggle the audit argument value
    // not using the onBoolArgumentToggled handler because we want to make both of those changes at the same time
    const newValue = !audit.value;
    newArgs = updateArgValue(newArgs || testArguments, audit.name, newValue);
    onChange(newArgs);
  };

  const [listArguments, audit, autonext, failfast, testIcon, testPanelImage] =
    getDisplayedArguments(testArguments, argumentsDetails);

  return (
    <div className="test-definition-arguments-editor">
      <header className="layout-row layout-row--start-center">
        <div className="col-check">Include</div>
        <div className="col-info" />
        <div className="col-name">Description</div>
        <div className="col-value">Value</div>
      </header>

      <div className="arguments">
        {listArguments.length === 0 ? (
          <div className="padding-h--20 padding-v--10">
            This test doesn't have any test-specific arguments to configure.
          </div>
        ) : null}

        {configName === VERSION_TEST ? (
          <div className="padding-h--20 padding-v--10">
            <em>
              At least one of the following arguments has to be included and given a value:
              exactValue, minimumValue or maximumValue.
            </em>
          </div>
        ) : null}

        {listArguments.map((arg) => {
          // TODO: make it so these are radio buttons instead of using the funky removeWhenSelected function
          if (configName === VERSION_TEST && arg.name === VERSION_TEST_EXACT_VALUE) {
            return (
              <ArgumentRow
                key={`arg-row-${arg.name}`}
                name={arg.name}
                isSelected={arg.isSelected}
                isRequired={false}
                displayName={arg.displayName}
                description={arg.description}
                showDialog={showDialog}
                disabled={disabled}
                onToggle={(isSelected) => {
                  removeWhenSelected(arg.name, isSelected, [
                    VERSION_TEST_MINIMUM_VALUE,
                    VERSION_TEST_MAXIMUM_VALUE,
                  ]);
                }}
              >
                <TextField
                  id={`input-string-${arg.name}`}
                  type="text"
                  aria-label={`input-${arg.name}`}
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onBlur={() => onStringBlur(arg)}
                  onChange={(val) => onStringArgumentChanged(arg, val)}
                />
              </ArgumentRow>
            );
          }

          if (
            configName === VERSION_TEST &&
            (arg.name === VERSION_TEST_MINIMUM_VALUE || arg.name === VERSION_TEST_MAXIMUM_VALUE)
          ) {
            return (
              <ArgumentRow
                key={`arg-row-${arg.name}`}
                name={arg.name}
                isSelected={arg.isSelected}
                isRequired={arg.isRequired}
                displayName={arg.displayName}
                description={arg.description}
                showDialog={showDialog}
                disabled={disabled}
                onToggle={(isSelected) => {
                  removeWhenSelected(arg.name, isSelected, [VERSION_TEST_EXACT_VALUE]);
                }}
              >
                <TextField
                  id={`input-string-${arg.name}`}
                  type="text"
                  aria-label={`input-${arg.name}`}
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onBlur={() => onStringBlur(arg)}
                  onChange={(val) => onStringArgumentChanged(arg, val)}
                />
              </ArgumentRow>
            );
          }

          if (testIcon && testPanelImage && arg.name === COMBINED_IMAGE_ICON_ITEM_NAME) {
            return (
              <ArgumentRow
                key={`arg-row-${arg.name}`}
                name={arg.name}
                isSelected={arg.isSelected}
                isRequired={arg.isRequired}
                displayName={arg.displayName}
                description={arg.description}
                showDialog={showDialog}
                disabled={disabled}
                onToggle={toggleImagesArguments}
              >
                {testIcon.value.length === 0 && testPanelImage.value.length === 0 ? (
                  <AsciButton
                    color="green"
                    disabled={disabled || !arg.isSelected}
                    onClick={() => openGallery(testIcon, testPanelImage)}
                  >
                    Select Images
                  </AsciButton>
                ) : !imagesLoading ? (
                  <div className="preview">
                    <img src={getImageURL("icons", testIcon.value)} />
                    <img src={getImageURL("panels", testPanelImage.value)} />
                    <div
                      className="replace-link"
                      onClick={() => openGallery(testIcon, testPanelImage)}
                    >
                      Replace images
                    </div>
                  </div>
                ) : (
                  "loading..."
                )}
              </ArgumentRow>
            );
          }

          return (
            <ArgumentRow
              key={`arg-row-${arg.name}`}
              name={arg.name}
              isSelected={arg.isSelected}
              isRequired={arg.isRequired}
              displayName={arg.displayName}
              description={arg.description}
              showDialog={showDialog}
              disabled={disabled}
              onToggle={(isSelected) => toggleArgument(arg.name, isSelected)}
            >
              {DeviceTestArgument.isTypeBool(arg) ? (
                <Switch
                  id={`switch-${arg.name}`}
                  name={`switch-${arg.name}`}
                  aria-label={`switch-${arg.name}`}
                  disabled={disabled || !arg.isSelected}
                  onChange={() => onBoolArgumentToggled(arg)}
                  checked={arg.value}
                />
              ) : DeviceTestArgument.isTypeString(arg) && DeviceTestArgument.isTestList(arg) ? (
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select a test"
                  maxTagCount={8}
                  disabled={disabled || !arg.isSelected}
                  value={arg.value.length > 0 ? arg.value.split(",") : []}
                  onChange={(value) => {
                    const val = value.join(",");
                    onStringArgumentChanged(arg, val);
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
                    (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={testDefinitionOptions}
                />
              ) : DeviceTestArgument.isTypeString(arg) ? (
                <TextField
                  id={`input-string-${arg.name}`}
                  type="text"
                  aria-label={`input-${arg.name}`}
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onBlur={() => onStringBlur(arg)}
                  onChange={(val) => onStringArgumentChanged(arg, val)}
                />
              ) : DeviceTestArgument.isTypeInteger(arg) ? (
                <TextField
                  id={`input-number-arg-${arg.name}`}
                  type="number"
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onChange={(val) => onIntegerArgumentChanged(arg, val)}
                />
              ) : DeviceTestArgument.isTypeSeconds(arg) ? (
                <TextField
                  id={`input-number-arg-${arg.name}`}
                  type="number"
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onChange={(val) => onIntegerArgumentChanged(arg, val)}
                />
              ) : DeviceTestArgument.isTypeFloat(arg) ? (
                <TextField
                  id={`input-float-arg-${arg.name}`}
                  type="number"
                  disabled={disabled || !arg.isSelected}
                  value={arg.value}
                  onChange={(val) => onFloatArgumentChanged(arg, val)}
                />
              ) : DeviceTestArgument.isTypeLocalizableString(arg) ? (
                <TextField
                  id={`input-localizable-arg-${arg.name}`}
                  type="text"
                  disabled
                  value={(arg.localization || {}).en}
                />
              ) : DeviceTestArgument.isTypeBundledImage(arg) ? (
                <TextField
                  id={`input-bundled-image-${arg.name}`}
                  type="text"
                  value={arg.value}
                  disabled={disabled || !arg.isSelected}
                  onChange={(val) => onStringArgumentChanged(arg, val)}
                />
              ) : DeviceTestArgument.isTypePercent(arg) ? (
                <Row align="start center">
                  <TextField
                    id={`input-number-arg-${arg.name}`}
                    type="number"
                    disabled={disabled || !arg.isSelected}
                    value={arg.value}
                    onChange={(val) => onPercentArgumentChanged(arg, val)}
                    min={1}
                    max={100}
                  />
                  &nbsp;%
                </Row>
              ) : (
                `unknown type (type: ${arg.type}, name: ${arg.name}, value: ${arg.value})`
              )}
            </ArgumentRow>
          );
        })}
      </div>

      <footer className="layout-row layout-row--space-between-center">
        {audit && (
          <div className="layout-row layout-row--center-center">
            <p>Auditable</p>

            <Switch
              id="switch-auditable"
              name="switch-auditable"
              aria-label="switch-auditable"
              disabled={disabled}
              onChange={() => toggleAuditable(audit)}
              checked={audit.value}
            />
          </div>
        )}

        {autonext && (
          <div className="layout-row layout-row--center-center">
            <p>Auto Next</p>

            <Switch
              id="switch-autonext"
              name="switch-autonext"
              aria-label="switch-autonext"
              disabled={disabled}
              onChange={() => onBoolArgumentToggled(autonext)}
              checked={autonext.value}
            />
          </div>
        )}

        {failfast && (
          <div className="layout-row layout-row--center-center">
            <p>Fail Fast</p>

            <Switch
              id="switch-failfast"
              name="switch-failfast"
              aria-label="switch-failfast"
              disabled={disabled}
              onChange={() => onBoolArgumentToggled(failfast)}
              checked={failfast.value}
            />
          </div>
        )}
      </footer>
    </div>
  );
};

TestDefinitionArgumentsEditor.propTypes = {
  configName: PropTypes.string,
  arguments: PropTypes.arrayOf(PropTypes.object),
  argumentsDetails: PropTypes.object,
  platform: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,

  // Redux
  showDialog: PropTypes.func.isRequired,
  fetchImages: PropTypes.func.isRequired,
  images: PropTypes.shape({
    url: PropTypes.string,
  }),
  imagesLoading: PropTypes.bool,
  testDefinitionOptions: PropTypes.arrayOf(PropTypes.object),
};

TestDefinitionArgumentsEditor.defaultProps = {
  disabled: false,
  arguments: [],
  argumentsDetails: {},
};

const stateToProps = (state) => ({
  images: getGalleryImages(state),
  imagesLoading: getAreGalleryImagesLoading(state),
  testDefinitionOptions: getTestDefinitionOptions(state),
});

const dispatchToProps = {
  showDialog: actions.dialog.show,
  fetchImages: moduleActions.fetchGalleryImages,
};

export default connect(stateToProps, dispatchToProps)(TestDefinitionArgumentsEditor);
