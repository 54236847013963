import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-md";

import "./AllServiceSuitesList.component.scss";

const AllServiceSuiteList = (props) => {
  const [selectedServiceSuite, setSelectedServiceSuite] = useState("");

  const selectServiceSuite = (identifier, serviceSuite) => {
    if (identifier != selectedServiceSuite) {
      setSelectedServiceSuite(identifier);
      props.onClickSelect(serviceSuite);
    }
  };

  const checked = "icon-check-circle text-color text-color--green";
  const unchecked = "icon-circle-o text-color text-color--grey";

  return (
    <div className="all-service-suites-list">
      {props.serviceSuites.map((serviceSuite) => {
        const identifier = `${serviceSuite["program.identifier"]}.${serviceSuite.identifier}`;
        return (
          <div
            key={identifier}
            className="paper-row"
            onClick={() => selectServiceSuite(identifier, serviceSuite)}
          >
            <div className="row centered">
              <i className={selectedServiceSuite == identifier ? checked : unchecked} />
              <div>{`${serviceSuite.identifier} (${serviceSuite["program.displayName"].en})`}</div>
            </div>
            <div>{serviceSuite.displayName.en || ""}</div>
            <div className="row">
              <div>{serviceSuite.description}</div>
              <Button
                icon
                className="icon-btn"
                iconClassName="icon-file-text"
                title="Suite Details"
                onClick={() => {
                  props.showSuiteDetails(serviceSuite);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

AllServiceSuiteList.propTypes = {
  serviceSuites: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSuiteDetails: PropTypes.func,
  onClickSelect: PropTypes.func,
};

AllServiceSuiteList.defaultProps = {
  showSuiteDetails: () => {},
};

export default AllServiceSuiteList;
