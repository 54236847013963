import { createAction } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";
import { SystemPermission } from "~/entities/system-permissions/model/systemPermissionTypes";

type APIResponse = {
  success: boolean;
  message: string;
  payload: {
    data: {
      system_permissions: SystemPermission[];
    };
  };
};

export const fetchSystemPermissionsBegin = createAction(
  "programManagement/FETCH_SYSTEM_PERMISSIONS_BEGIN"
);
export const fetchSystemPermissionsSuccess = createAction<SystemPermission[]>(
  "programManagement/FETCH_SYSTEM_PERMISSIONS_SUCCESS"
);
export const fetchSystemPermissionsError = createAction<string>(
  "programManagement/FETCH_SYSTEM_PERMISSIONS_ERROR"
);

export const fetchSystemPermissions =
  (): ThunkAction<void, {}, {}, AnyAction> => async (dispatch, getState) => {
    const state = getState();
    const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

    try {
      dispatch(fetchSystemPermissionsBegin());
      const query = CustomerManagement.gqlBuilder(`{
            system_permissions {
                name,
                type,
                displayName {${displayNameLanguages}},
            }
        }`);

      const response = (await CustomerManagement.POST("/gql", { query })) as APIResponse;
      const systemPermissions: SystemPermission[] = response.payload.data.system_permissions;
      dispatch(fetchSystemPermissionsSuccess(systemPermissions));
    } catch (e: any) {
      const message: string =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";
      dispatch(fetchSystemPermissionsError(message));
    }
  };
