import React, { Component } from "react";

import { SectionHeader, AsciButton, InputSearch } from "~/global";

export default class SectionHeaderExample extends Component {
  render() {
    return (
      <div>
        <SectionHeader style={{ marginBottom: "10px" }} title="Title only" />

        <SectionHeader
          style={{ marginBottom: "10px" }}
          title="Title"
          description="and description"
        />

        <SectionHeader style={{ marginBottom: "10px" }} title="Title and content">
          <AsciButton color="green">This is a button</AsciButton>
        </SectionHeader>

        <SectionHeader
          style={{ marginBottom: "10px" }}
          title="Title, description and content"
          description="Shiny stuff!"
        >
          <AsciButton color="green">This is a button</AsciButton>
        </SectionHeader>

        <SectionHeader style={{ marginBottom: "10px" }}>
          <InputSearch onChange={() => {}} />

          <AsciButton color="green">This is a button</AsciButton>
        </SectionHeader>
      </div>
    );
  }
}
