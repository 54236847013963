import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select } from "antd";

import { TextField } from "~/global";

import "./NewLabelModalContent.scss";

import labelSelectors from "~/label-printer/selectors";

import * as layouts from "~/label-printer/layouts";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";

const NewLabelInput = (props) => {
  const { label, children } = props;
  return (
    <div className="new-label-input">
      <div className="new-label-input-label">{label}</div>
      <div className="new-label-input-input">{children}</div>
    </div>
  );
};

const NewLabelModalContent = (props) => {
  const { setter, name, width, height, printer, format, printers, printerFormats, layoutName } =
    props;
  const isCustomFormat = format === "custom";
  return (
    <div className="new-label-modal-content">
      <NewLabelInput label="Name">
        <TextField onChange={setter("name")} value={name} />
      </NewLabelInput>

      {/* <NewLabelInput label="Layout"> */}
      {/*    <Select onChange={setter("layoutName")} value={layoutName}> */}
      {/*        { */}
      {/*            layouts.LAYOUTS.map( */}
      {/*                layout => */}
      {/*                    <Select.Option key={layout} value={layout}>{capitalize(layout)}</Select.Option> */}
      {/*            ) */}
      {/*        } */}
      {/*    </Select> */}
      {/* </NewLabelInput> */}

      <NewLabelInput label="Printer">
        <Select onChange={setter("printer")} value={printer}>
          {printers.map((e) => (
            <Select.Option key={e.name} value={e.name}>
              {e.name}
            </Select.Option>
          ))}
          <Select.Option value="custom">Other</Select.Option>
        </Select>
      </NewLabelInput>

      <NewLabelInput label="Format">
        <Select onChange={setter("format")} value={format}>
          {printerFormats.map((e) => (
            <Select.Option key={e.name} value={e.name} title={e.inches}>
              {e.name}
            </Select.Option>
          ))}
          <Select.Option value="custom">Custom</Select.Option>
        </Select>
      </NewLabelInput>
      {isCustomFormat && (
        <>
          <NewLabelInput label="Width">
            <NumberInput min={1} onChange={setter("width")} value={width} unit="mm" />
          </NewLabelInput>
          <NewLabelInput label="Height">
            <NumberInput min={1} onChange={setter("height")} value={height} unit="mm" />
          </NewLabelInput>
        </>
      )}
    </div>
  );
};

NewLabelModalContent.defaultProps = {};

NewLabelModalContent.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  printer: PropTypes.string,
  format: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  layoutName: PropTypes.string,

  setter: PropTypes.func,

  printers: PropTypes.array,
  printerFormats: PropTypes.array,
};

const stateToProps = (state, props) => ({
  printers: labelSelectors.labelFormats.getPrinters(state),
  printerFormats: labelSelectors.labelFormats.getPrinterFormats(props.printer)(state),
});

export default connect(stateToProps, null)(NewLabelModalContent);
