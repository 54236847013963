import { createSelector } from "@reduxjs/toolkit";
import { keyBy, partition } from "lodash";
import {
  BasicReportType,
  BasicReportTypeName,
  ChartReportType,
  ChartReportTypeName,
  ReportType,
} from "~/actions/reportTypes.actions";

import type { RootState } from "~/store";

const selectReportTypes = (state: RootState) => state.reportingModule.reportTypes.items;
export const selectExportedReports = (state: RootState) =>
  state.reportingModule.exportedReports.items;
export const selectLoadingReports = (state: RootState) =>
  state.reportingModule.exportedReports.status === "loading";

export type ReportTypesByName = {
  [name: BasicReportTypeName]: BasicReportType;
  [name: ChartReportTypeName]: ChartReportType;
  [name: string]: ReportType | undefined;
};

export const selectReportTypesByName = createSelector(
  [selectReportTypes],
  (reportTypes: ReportType[] | undefined): ReportTypesByName => {
    const [chartReportTypes, basicReportTypes] = partition(
      reportTypes,
      (reportType): reportType is ChartReportType => reportType.chartSupport
    );
    return {
      ...(keyBy(chartReportTypes, (report) => report.name) as Record<
        ChartReportTypeName,
        ChartReportType
      >),
      ...(keyBy(basicReportTypes, (report) => report.name) as Record<
        BasicReportTypeName,
        BasicReportType
      >),
    };
  }
);
