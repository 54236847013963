import * as workflowBehaviors from "./workflowBehaviors";
import * as workflowConditions from "./workflowConditions";

export const DeviceConnected = "DeviceConnected";
export const ManualFailure = "ManualFailure";
export const ProcessCompleted = "ProcessCompleted";
export const ServiceStarted = "ServiceStarted";
export const ServiceCompleted = "ServiceCompleted";

const completedBehaviors = [
  workflowBehaviors.ApiCall,
  workflowBehaviors.RunApplication,
  workflowBehaviors.PrintLabel,
];

export const allEvents = [
  DeviceConnected,
  ProcessCompleted,
  ManualFailure,
  ServiceStarted,
  ServiceCompleted,
];

export const selectableEvents = [DeviceConnected, ProcessCompleted, ManualFailure];

export const metadata = {
  [DeviceConnected]: {
    label: "Device connected",
    description: "Called when a device is first connected",
    allowedBehaviors: [
      workflowBehaviors.ApiCall,
      workflowBehaviors.RunApplication,
      workflowBehaviors.PrintLabel,
      workflowBehaviors.SetCustomFields,
    ],
    allowedConditions: workflowConditions.serviceConditions,
  },
  [ManualFailure]: {
    label: "Manual failure",
    description: "Called when a slot is manually failed by the operator.",
    allowedBehaviors: completedBehaviors,
    allowedConditions: workflowConditions.completedConditions,
  },
  [ProcessCompleted]: {
    label: "Process completed",
    description:
      "Called when all services have successfully completed, can compare the process results.",
    allowedBehaviors: completedBehaviors,
    allowedConditions: workflowConditions.completedConditions,
  },
  [ServiceStarted]: {
    label: "On Service Started",
    description: "The service is starting and can be skipped if necessary.",
    allowedBehaviors: [
      workflowBehaviors.ApiCall,
      workflowBehaviors.RunApplication,
      workflowBehaviors.PrintLabel,
      workflowBehaviors.SetCustomFields,
    ],
    allowedConditions: workflowConditions.serviceConditions,
  },
  [ServiceCompleted]: {
    label: "On Service Completed",
    description: "Service has completed, relevant info can be retrieved here.",
    allowedBehaviors: [
      workflowBehaviors.ApiCall,
      workflowBehaviors.RunApplication,
      workflowBehaviors.PrintLabel,
      workflowBehaviors.SetCustomFields,
    ],
    allowedConditions: workflowConditions.serviceConditions,
  },
};
