import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import { isEmpty } from "lodash/lang";

const getAuditsOriginal = (state) => state.audits.original;
const getAuditsModified = (state) => state.audits.modifications;

export const getUpdatedAudits = createSelector(
  [getAuditsModified, getAuditsOriginal],
  (modifications, original) => (isEmpty(modifications) ? original : modifications)
);

export const getAuditsByStatus = createSelector(getAuditsOriginal, (audits = []) => {
  const auditsById = audits.reduce(
    (acc, audit) => ({
      ...acc,
      [audit.level]: audit,
    }),
    {}
  );
  return auditsById;
});

export const getAuditsStatusList = createSelector(getAuditsOriginal, (audits = []) =>
  audits.map((audit) => audit.level)
);

export const getAuditsByFacilityStatus = createSelector(getAuditsOriginal, (audits = []) => {
  const auditsByFacility = audits.reduce(
    (acc, audit) => ({
      ...acc,
      [`${audit.facility}.${audit.level}`]: audit,
    }),
    {}
  );
  return auditsByFacility;
});

export const getIsEqualAudits = createSelector(
  [getAuditsModified, getAuditsOriginal],
  (modifications, original) => isEqual(modifications, original)
);

export const getCanSaveAudits = createSelector(
  [getAuditsModified, getIsEqualAudits],
  (modifications, isEqual) => !isEmpty(modifications) && !isEqual
);
