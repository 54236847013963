import React, { useRef } from "react";
import PropTypes from "prop-types";
import Barcode from "react-barcode";
import find from "lodash/find";
import { connect, useSelector } from "react-redux";

import labelSelectors from "~/label-printer/selectors";

const BarcodePreview = (props) => {
  const { path, recordingFields, ...rest } = props;
  const barCodeRef = useRef(null);
  const fieldInfo = find(recordingFields, { path });
  const { preview } = fieldInfo;
  const recordingValue = useSelector(labelSelectors.labelPrint.getRecordingValue(path));

  // useEffect(() => {
  //     // No props for the actual size, width & height of that lib just ain't named right.
  //     const svg = barCodeRef.current.querySelector("svg");
  //     const { width, height } = barCodeRef.current.getBoundingClientRect();
  //     svg.setAttribute("width", `100%`);
  //     svg.setAttribute("height", `100%`);
  // }, [barCodeRef, path, rest]);

  return <Barcode value={recordingValue || preview} {...rest} />;
};

BarcodePreview.defaultProps = {};

BarcodePreview.propTypes = {
  path: PropTypes.string,
  format: PropTypes.string,
  displayValue: PropTypes.bool,
  flat: PropTypes.bool,
  text: PropTypes.string,
  textPosition: PropTypes.string,
  textMargin: PropTypes.number,
  fontSize: PropTypes.number,

  recordingFields: PropTypes.array,
};

export default connect(
  (state) => ({ recordingFields: labelSelectors.labelFields.getAvailableFields(state) }),
  null
)(BarcodePreview);
