import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Button } from "react-md";
import { Column, Row } from "~/global";

import moduleAction from "~/data-import/actions/";

import "./UploadCSVFile.component.scss";
import { message } from "antd";

const UploadCSVFile = ({
  fileSelectorHandler,
  setIsFileUploaded,
  onSucess,
  setOnSucess,
  processedData,
  onClearFile,
  file,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [dataFromCSV, setDataFromCSV] = useState("");
  const [dataToUpload, setDataToUpload] = useState([]);
  const [fileName, setFileName] = useState("No file selected");

  useEffect(
    () => () => {
      clearFile();
    },
    []
  );

  useEffect(() => {
    if (selectedFile != undefined) {
      setFileName(selectedFile.name);
      transformFileToObject(selectedFile);
    } else {
      setFileName("No file selected");
      setDataFromCSV("");
    }
  }, [selectedFile]);

  const transformFileToObject = () => {
    const reader = new FileReader();

    reader.onloadend = () => {
      reader.result;
      setDataFromCSV(reader.result);
    };
    reader.readAsText(selectedFile);
  };

  const processData = () => {
    // TODO: use a CSV parsing library to parse the CSV instead of doing it "manually"
    const csvData = dataFromCSV;
    const allDocLines = csvData.split(/\n/);

    for (let i = 0; i, i < allDocLines.length; i++) {
      /*
       * Here's a break down of the RegExp used below. Source: https://stackoverflow.com/a/23582323
       * ,        ','
       * (?=      look ahead to see if there is:
       * (?:      group, but do not capture (0 or more times):
       * (?:      group, but do not capture (2 times):
       *  [^"]*   any character except: '"' (0 or more times)
       *  "       '"'
       * ){2}     end of grouping
       * )*       end of grouping
       *  [^"]*   any character except: '"' (0 or more times)
       * $        before an optional \n, and the end of the string
       * )        end of look-ahead
       */
      const row = allDocLines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      const field = [];

      for (let j = 0; j < row.length; j++) {
        field.push(row[j]);
      }

      if (field != "") {
        dataToUpload.push(field);
      }
    }

    return dataToUpload;
  };

  useEffect(() => {
    if (dataFromCSV.length > 0) {
      processedData(processData());
    }
  }, [dataFromCSV]);

  useEffect(() => {
    if (onSucess) {
      clearFile();
      isFileChosen = false;
      setOnSucess(false);
    }
  }, [onSucess]);

  fileSelectorHandler = (e) => {
    clearFile();
    // for single file upload purposes
    if (e.target.files[0].size <= 2000000) {
      setSelectedFile(e.target.files[0]);
      file(e.target.files[0]);
    } else {
      message.error("File size is above 2MB");
    }
    e.target.value = null;
  };

  const clearFile = () => {
    onClearFile();
    setDataToUpload([]);
    setSelectedFile(undefined);
    isFileChosen = false;
  };

  let isFileChosen = selectedFile !== undefined;

  useEffect(() => {
    setIsFileUploaded(isFileChosen);
  }, [isFileChosen]);

  return (
    <div>
      <Column className="import-data-file-uploader full-width">
        <Row className="section-title">Upload CSV File</Row>
        <Column>
          <Row className="uploadCSV">
            <input
              className="file-uploader"
              type="file"
              id="file"
              onChange={fileSelectorHandler}
              accept=".csv"
            />
            <label htmlFor="file">
              <i className="icon-upload" />
              Choose File
            </label>
            <label className="file-name">{fileName}</label>
            {isFileChosen ? (
              <Button
                icon
                className="icon-btn text-color--blue"
                iconClassName="icon-close"
                onClick={() => clearFile()}
              />
            ) : null}
          </Row>
        </Column>
      </Column>
    </div>
  );
};

const stateToProps = (state) => ({});

const dispatchToProps = {
  processedData: moduleAction.importDataAutomatic.processedData,
  onClearFile: moduleAction.importDataAutomatic.clearFile,
  file: moduleAction.importDataAutomatic.selectedFile,
};

export default connect(stateToProps, dispatchToProps)(UploadCSVFile);
