import React from "react";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DetailsPage from "../../../global/reduxer/components/details/DetailsPage";
import {
  actions as workstationActions,
  selectors as workstationSelectors,
} from "~/workstation/redux";
import DetailsField from "~/global/reduxer/components/details/DetailsField";
import TaskStatus from "~/workstation/components/common/TaskStatus";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import UserDisplay from "~/workstation/components/common/UserDisplay";
import Timestamp from "~/workstation/components/common/Timestamp";
import { EXECUTE, GATHER_LOGS, SCREENSHOT, SEND_MESSAGE } from "~/workstation/taskTypes";
import ResultDownloader from "~/global/reduxer/components/ResultDownloader";
import DetailsSection from "~/global/reduxer/components/details/DetailsSection";

const TaskError = (props) => {
  const { reason } = props.result.data;

  return <DetailsField label="Reason" value={reason} />;
};

const ScreenshotTask = (props) => {
  const { image } = props.result.data;
  if (props.result.status !== "RESOLVED") {
    return null;
  }
  return (
    <DetailsField
      label="Screenshot"
      value={<img src={image} alt="screenshot" width={400} height={200} />}
    />
  );
};

const GatherLogTask = (props) => {
  const { logUrl, result } = props;

  if (result.status !== "RESOLVED") {
    return null;
  }
  return (
    <DetailsField
      label="Logs"
      value={
        <a href={logUrl} target="_blank" rel="noopener noreferrer">
          Download
        </a>
      }
    />
  );
};

const ExecuteTask = (props) => {
  const { command } = props.payload;
  const { stdout, stderr } = props.result.data;
  // if (!props.result.status !== "RESOLVED") {
  //     return null;
  // }
  return (
    <>
      <DetailsField label="Command" value={command} />
      <DetailsField label="STDOUT" value={stdout} />
      <DetailsField label="STDERR" value={stderr} />
    </>
  );
};

const SendMessageTask = (props) => {
  const { message, options, title } = props.payload;
  const { choice } = props.result.data;
  return (
    <>
      <DetailsField label="Message" value={message} />
      {options && <DetailsField label="Options" value={options} />}
      {title && <DetailsField label="Title" value={title} />}
      {choice && <DetailsField label="Choice" value={choice} />}
    </>
  );
};

const TaskResultAdapter = (props) => {
  const { type, result } = props;
  if (result.status === "ERROR") {
    return <TaskError {...props} />;
  }

  switch (type) {
    case SCREENSHOT:
      return <ScreenshotTask {...props} />;
    case GATHER_LOGS:
      return <GatherLogTask {...props} />;
    case EXECUTE:
      return <ExecuteTask {...props} />;
    case SEND_MESSAGE:
      return <SendMessageTask {...props} />;
    default:
      return null;
  }
};

const TaskDetails = () => {
  const { taskId } = useParams();

  const task = useSelector(workstationSelectors.taskDetails.getResults);

  return (
    <DetailsPage
      pageTitle={
        <div className="details-page-title-head">
          <span>Task {taskId}</span>
          <ResultDownloader
            selectors={workstationSelectors.taskDetails}
            download={`task-${taskId}.json`}
          />
        </div>
      }
    >
      <ReduxerControlled
        selectors={workstationSelectors.taskDetails}
        actions={workstationActions.taskDetails}
        reducerName="taskDetails"
        fetchArguments={[taskId]}
      >
        {task && (
          <div className="double-section">
            <DetailsSection sectionTitle="Data" style={{ minWidth: "28rem" }}>
              <DetailsField label="Type" value={task.type} />
              <DetailsField
                label="Status"
                value={<TaskStatus status={task.result.status} inline />}
              />
              <DetailsField
                label="Created At"
                value={<Timestamp value={task.createdAt} inline />}
              />
              <DetailsField label="Created By" value={<UserDisplay user={task.creator} inline />} />
            </DetailsSection>
            {task.result.data && (
              <DetailsSection sectionTitle="Result">
                {task.result.updator && (
                  <DetailsField
                    label="Resolved By"
                    value={<UserDisplay user={task.result.updator} inline />}
                  />
                )}
                <TaskResultAdapter {...task} />
              </DetailsSection>
            )}
          </div>
        )}
      </ReduxerControlled>
    </DetailsPage>
  );
};

TaskDetails.defaultProps = {};

TaskDetails.propTypes = {};

export default TaskDetails;
