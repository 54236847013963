import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { truncate, mapValues } from "lodash";
import { RootState } from "~/store";
import { CustomerManagement } from "~/services";
import { DashboardFiltersParams, helperString } from "./typeAndToolDashboard";

export type CustomFields = {
  name?: string;
  value?: string;
  count?: number;
  percent?: string;
};

type FailCodes = {
  name?: string;
  value?: number;
  count?: number;
};

export type TopFailedAudits = {
  name?: string;
  audits?: number;
  auditsFailed?: number;
  percentage?: number;
};

export type TopFailedTests = {
  name?: string;
  tests?: number;
  testsFailed?: number;
  percentage?: number;
};

export type ShowMore = {
  linePhones?: string[];
  configurations?: string[];
};

export const fetchTeamShowMore = createAsyncThunk<
  { showMore: ShowMore; teamId: string },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/FetchTeamShowMoreStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  const teamId = items.teamID;
  try {
    const query = CustomerManagement.gqlBuilder(`query TeamShowMore {
      teamShowMore(${stringItems}) {
        linePhones,
        configurations
      }
    }`);
    const response = await CustomerManagement.QUERY<{ teamShowMore: ShowMore }>(query);
    const showMore = response.payload.data.teamShowMore;
    return { showMore, teamId };
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (e as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const fetchTeamCustomFields = createAsyncThunk<
  { customFields: CustomFields[]; teamId: string },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/FetchTeamCustomFieldsStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  const teamId = items.teamID;
  try {
    const query = CustomerManagement.gqlBuilder(`query TeamCustomFields {
      teamCustomFields(${stringItems}) {
        name,
        value,
        count,
        percent
      }
    }`);
    const response = await CustomerManagement.QUERY<{ teamCustomFields: CustomFields[] }>(query);
    const customFields = response.payload.data.teamCustomFields;
    return { customFields, teamId };
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (e as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const fetchTeamFailCodes = createAsyncThunk<
  { failCodes: FailCodes[]; teamId: string },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/fetchTeamFailCodesStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  const teamId = items.teamID;
  try {
    const query = CustomerManagement.gqlBuilder(`query TeamFailCodes {
      teamFailCodes(${stringItems}) {
        name,
        value,
        count
      }
    }`);
    const response = await CustomerManagement.QUERY<{ teamFailCodes: FailCodes[] }>(query);

    const failCodes = response.payload.data.teamFailCodes;
    return { failCodes, teamId };
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (e as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const fetchTeamTopFailedAudits = createAsyncThunk<
  { topFailedAudits: TopFailedAudits[]; teamId: string },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/fetchTeamTopFailedAuditsStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  const teamId = items.teamID;

  try {
    const query = CustomerManagement.gqlBuilder(`query TeamTopFailedAudits {
      teamTopFailedAudits(${stringItems}) {
        name,
        audits,
        auditsFailed,
        percentage
      }
    }`);
    const response = await CustomerManagement.QUERY<{ teamTopFailedAudits: TopFailedAudits[] }>(
      query
    );

    const topFailedAudits = response.payload.data.teamTopFailedAudits;
    return { topFailedAudits, teamId };
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (e as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const fetchTeamTopFailedTests = createAsyncThunk<
  { topFailedTests: TopFailedTests[]; teamId: string },
  DashboardFiltersParams,
  { rejectValue: string }
>("dashboard/fetchTeamTopFailedTestsStatus", async (items, { rejectWithValue }) => {
  const stringItems = helperString(items);
  const teamId = items.teamID;

  try {
    const query = CustomerManagement.gqlBuilder(`query TeamTopFailedTests {
      teamTopFailedTests(${stringItems}) {
        name,
        tests,
        testsFailed,
        percentage
      }
    }`);
    const response = await CustomerManagement.QUERY<{ teamTopFailedTests: TopFailedTests[] }>(
      query
    );

    const topFailedTests = response.payload.data.teamTopFailedTests;
    return { topFailedTests, teamId };
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e;
    } else {
      type ServiceError = { json: { message?: string } };
      const message: string =
        (e as ServiceError).json?.message ?? "Request didn't complete successfully";
      return rejectWithValue(message);
    }
  }
});

interface TeamsState {
  error?: string;
  teamsById: Record<string, TeamMetrics | undefined>;
  status: "idle" | "pending" | "succeeded" | "failed";
}

type Items<T> = {
  items?: T[];
  status: "idle" | "pending" | "succeeded" | "failed";
  error?: string;
};

export type TeamMetrics = {
  topFailedAudits: Items<TopFailedAudits>;
  customFields: Items<CustomFields>;
  failCodes: Items<FailCodes>;
  topFailedTests: Items<TopFailedTests>;
  showMore: {
    item?: ShowMore;
    status: "idle" | "pending" | "succeeded" | "failed";
    error?: string;
  };
};

const newTeam = (): TeamMetrics => ({
  topFailedAudits: { status: "idle" as const },
  customFields: { status: "idle" as const },
  failCodes: { status: "idle" as const },
  topFailedTests: { status: "idle" as const },
  showMore: { status: "idle" as const },
});

const initialState: TeamsState = {
  status: "idle",
  teamsById: {},
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchTeamShowMore
      .addCase(fetchTeamShowMore.pending, (draftState, { meta }) => {
        draftState.status = "pending";
        const team = draftState.teamsById[meta.arg.teamID] ?? newTeam();
        draftState.teamsById[meta.arg.teamID] = team;

        team.showMore.status = "pending";
        draftState.error = undefined;
      })
      .addCase(fetchTeamShowMore.fulfilled, (draftState, { payload }) => {
        draftState.status = "succeeded";
        draftState.error = undefined;
        const team = draftState.teamsById[payload.teamId];
        if (team) {
          team.showMore.status = "succeeded";
          team.showMore.error = undefined;
          team.showMore.item = payload.showMore;
        }
      })
      .addCase(fetchTeamShowMore.rejected, (draftState, { payload, meta }) => {
        draftState.status = "idle";
        if (!payload) {
          draftState.error = "The request didn't complete successfully.";
          return;
        }

        draftState.error = payload;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.showMore.status = "idle";
        }
      })
      // fetchTeamTopFailedAudits
      .addCase(fetchTeamTopFailedAudits.pending, (draftState, { meta }) => {
        draftState.status = "pending";
        const team = draftState.teamsById[meta.arg.teamID] ?? newTeam();
        draftState.teamsById[meta.arg.teamID] = team;
        team.topFailedAudits.status = "pending";
        draftState.error = undefined;
      })
      .addCase(fetchTeamTopFailedAudits.fulfilled, (draftState, { payload, meta }) => {
        draftState.status = "succeeded";
        draftState.error = undefined;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.topFailedAudits.status = "succeeded";
          team.topFailedAudits.error = undefined;
          team.topFailedAudits.items = payload.topFailedAudits;
        }
      })
      .addCase(fetchTeamTopFailedAudits.rejected, (draftState, { payload, meta }) => {
        draftState.status = "idle";
        if (!payload) {
          draftState.error = "The request didn't complete successfully.";
          return;
        }

        draftState.error = payload;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.topFailedAudits.status = "idle";
          team.topFailedAudits.error = payload;
        }
      })
      // fetchTeamTopFailedTests
      .addCase(fetchTeamTopFailedTests.pending, (draftState, { meta }) => {
        draftState.status = "pending";
        const team = draftState.teamsById[meta.arg.teamID] ?? newTeam();
        draftState.teamsById[meta.arg.teamID] = team;

        team.topFailedTests.status = "pending";

        draftState.error = undefined;
      })
      .addCase(fetchTeamTopFailedTests.fulfilled, (draftState, { payload, meta }) => {
        draftState.status = "succeeded";
        draftState.error = undefined;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.topFailedTests.status = "succeeded";
          team.topFailedTests.error = undefined;
          team.topFailedTests.items = payload.topFailedTests;
        }
      })
      .addCase(fetchTeamTopFailedTests.rejected, (draftState, { payload, meta }) => {
        draftState.status = "idle";
        if (!payload) {
          draftState.error = "The request didn't complete successfully.";
          return;
        }

        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.topFailedTests.status = "idle";
          team.topFailedTests.error = payload;
        }
      })
      // fetchTeamFailCodes
      .addCase(fetchTeamFailCodes.pending, (draftState, { meta }) => {
        draftState.status = "pending";
        draftState.error = undefined;

        const team = draftState.teamsById[meta.arg.teamID] ?? newTeam();
        draftState.teamsById[meta.arg.teamID] = team;

        team.failCodes.status = "pending";
      })
      .addCase(fetchTeamFailCodes.fulfilled, (draftState, { payload, meta }) => {
        draftState.status = "succeeded";
        draftState.error = undefined;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.failCodes.status = "succeeded";
          team.failCodes.error = undefined;
          team.failCodes.items = payload.failCodes;
        }
      })
      .addCase(fetchTeamFailCodes.rejected, (draftState, { payload, meta }) => {
        draftState.status = "idle";

        if (!payload) {
          draftState.error = "The request didn't complete successfully.";
          return;
        }
        draftState.error = payload;

        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.failCodes.status = "idle";
          team.failCodes.error = payload;
        }
      })
      // fetchTeamCustomFields
      .addCase(fetchTeamCustomFields.pending, (draftState, { meta }) => {
        draftState.status = "pending";
        const team = draftState.teamsById[meta.arg.teamID] ?? newTeam();
        draftState.teamsById[meta.arg.teamID] = team;
        team.customFields.status = "pending";
        draftState.error = undefined;
      })
      .addCase(fetchTeamCustomFields.fulfilled, (draftState, { payload, meta }) => {
        draftState.status = "succeeded";
        draftState.error = undefined;
        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.customFields.status = "succeeded";
          team.customFields.error = undefined;
          team.customFields.items = payload.customFields;
        }
      })
      .addCase(fetchTeamCustomFields.rejected, (draftState, { payload, meta }) => {
        draftState.status = "idle";

        if (!payload) {
          draftState.error = "The request didn't complete successfully.";
          return;
        }
        draftState.error = payload;

        const team = draftState.teamsById[meta.arg.teamID];
        if (team) {
          team.customFields.status = "idle";
          team.customFields.error = payload;
        }
      });
  },
});

export const getTeamsById = (state: RootState) => state.modules.dashboard.teams.teamsById;

export default teamSlice.reducer;
