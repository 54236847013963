import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { AsciButton, Column, Row } from "~/global";
import { copyToClipboard, formatDate } from "~/global/utils";

import "./LicenseList.component.scss";

const License = ({ license }) => (
  <Row align="space-between" className="license">
    <Column>
      <div>
        <span className="label">{license.name}: </span>
        {license.license}
      </div>
      <div>
        <span className="label">Creation date: </span>
        {formatDate(license.creationDate)}
      </div>
    </Column>

    <AsciButton color="green" onClick={() => copyToClipboard(license.license)}>
      Copy
    </AsciButton>
  </Row>
);
License.propTypes = {
  license: PropTypes.shape({
    creationDate: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    license: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const LicenseList = ({ customerLicenses = [] }) => {
  const hasLicenses = useMemo(
    () =>
      customerLicenses.some(
        (csLicense) =>
          csLicense.license ||
          csLicense.facilities?.some(
            (faLicense) =>
              faLicense.license || faLicense.deployments.some((dpLicense) => dpLicense.license)
          )
      ),
    [customerLicenses]
  );

  if (!hasLicenses) {
    return "No licenses available";
  }

  return (
    <div className="license-list">
      {customerLicenses.map((csLicense) => (
        <div key={csLicense.id} className="client-license">
          <div className="customer-header">Customer license:</div>

          <License license={csLicense} />

          <div className="facility-list">
            {csLicense.facilities?.map((faLicense) => (
              <div key={faLicense.id}>
                <div className="facility-header">Facility license:</div>

                <License license={faLicense} />

                {faLicense.deployments?.length > 0 && (
                  <div className="deployment-list">
                    <div className="deployment-list-header">Deployment licenses:</div>

                    {faLicense.deployments.map((dpLicense) => (
                      <License key={dpLicense.id} license={dpLicense} />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

LicenseList.propTypes = {
  customerLicenses: PropTypes.array,
};

export default LicenseList;
