import { createAction } from "@reduxjs/toolkit";
import { isObject } from "lodash/lang";
import moduleSelectors from "../selectors";
import { CustomerManagement } from "~/services";
import { Modal } from "antd";

export const fetchCustomerFacilitiesBegin = createAction(
  "customerManagement/FETCH_CUSTOMER_FACILITIES_BEGIN"
);
export const fetchCustomerFacilitiesSuccess = createAction(
  "customerManagement/FETCH_CUSTOMER_FACILITIES_SUCCESS"
);
export const fetchCustomerFacilitiesError = createAction(
  "customerManagement/FETCH_CUSTOMER_FACILITIES_ERROR"
);

export const updateCustomerFacilities = createAction(
  "customerManagement/UPDATE_CUSTOMER_FACILITIES"
);
export const updateKey = createAction("customerManagement/UPDATE_KEY_FACILITIES");
export const updateSortReverse = createAction("customerManagement/UPDATE_SORT_REVERSE_FACILITIES");
export const saveFacilitiesBegin = createAction("customerManagement/SAVE_FACILITIES_BEGIN");
export const saveFacilitiesError = createAction("customerManagement/SAVE_FACILITIES_ERROR");
export const saveFacilitiesSuccess = createAction("customerManagement/SAVE_FACILITIES_SUCCESS");

export const fetchCustomerFacilities =
  (csid, isArchived = false) =>
  async (dispatch) => {
    const query = CustomerManagement.gqlBuilder(`query CustomerFacilities {
        customer(csid: "${csid}", updateUserClient: false, isArchived: ${isArchived}) {
            facilities {
                faid,
                name,
                address,
                city,
                state,
                postalcode,
                timezone,
                country
            }
        }
    }`);
    try {
      dispatch(fetchCustomerFacilitiesBegin());
      const response = await CustomerManagement.QUERY(query);
      if (response.payload.data != null && response.payload.data.customer != null) {
        const { facilities } = response.payload.data.customer;
        dispatch(fetchCustomerFacilitiesSuccess({ facilities }));
      } else {
        dispatch(fetchCustomerFacilitiesError({ error: "Request didn't complete successfully" }));
      }
    } catch (e) {
      const message =
        e.message != null && !isObject(e.message)
          ? e.message
          : "Request didn't complete successfully";

      dispatch(fetchCustomerFacilitiesError({ error: message }));
    }
  };

export const setSortKey = (key) => updateKey({ key });

export const setSortReverse = () => (dispatch, getState) => {
  const state = getState();
  const sortReverse = !state.modules.customerManagement.customerFacilities.sortReverse;

  dispatch(updateSortReverse({ sortReverse }));
};

export const onSaveFacilities = () => (dispatch, getState) => {
  const state = getState();
  const identity = moduleSelectors.customerIdentity.getUpdatedCustomerIdentity(state);
  const facilities = moduleSelectors.customerFacilities.getUpdatedFacilities(state);

  try {
    dispatch(saveFacilitiesBegin());
    const customerServerFormat = {
      ToUpdate: {
        ...identity,
        name: identity.name.trim(),
        facilities: [...facilities],
      },
    };
    dispatch(updateCustomerFacilities({ facilities }));
    CustomerManagement.PUT(`/customers`, customerServerFormat);
    dispatch(saveFacilitiesSuccess());
    return;
  } catch (e) {
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    const originalFacilities = moduleSelectors.customerFacilities.getSortedFacilities(state);
    dispatch(updateCustomerFacilities({ facilities: originalFacilities }));
    dispatch(saveFacilitiesError({ message }));
    return e;
  }
};

export const onDeleteFacilities = (faid) => (dispatch, getState) => {
  const state = getState();
  const facilities = moduleSelectors.customerFacilities.getUpdatedFacilities(state);
  const originalFacilities = moduleSelectors.customerFacilities.getSortedFacilities(state);
  const facilitiesToDelete = facilities.filter((facility) => facility.faid !== faid);

  dispatch(updateCustomerFacilities({ facilities: facilitiesToDelete }));
  dispatch(saveFacilitiesBegin());

  CustomerManagement.DELETE(`/facilities/${faid}`)
    .then(() => {
      // WebserviceHandler will 'reject' if the was an error or
      // response was no successful ( {..."success": false... )
      dispatch(saveFacilitiesSuccess());
    })
    .catch((e) => {
      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";

      // Display an error message to the user
      Modal.error({
        title: "Request didn't complete successfully",
        content: message.charAt(0).toUpperCase() + message.slice(1),
      });

      dispatch(updateCustomerFacilities({ facilities: originalFacilities }));
      dispatch(saveFacilitiesError({ message }));
    });
};

export const onAddFacilities = (csid, forceRender) => async (dispatch, getState) => {
  const state = getState();
  const facilities = moduleSelectors.customerFacilities.getUpdatedFacilities(state);

  dispatch(updateCustomerFacilities({ facilities }));
  dispatch(saveFacilitiesBegin());

  try {
    await CustomerManagement.POST(
      `/customer/${csid}/facilities`,
      facilities[facilities.length - 1]
    ).then((response) => {
      // WebserviceHandler will 'reject' if the was an error or
      facilities[facilities.length - 1].faid = response.payload.faid;
      dispatch(updateCustomerFacilities({ facilities }));
      forceRender();
      dispatch(saveFacilitiesSuccess());
    });
    return;
  } catch (e) {
    const facility = moduleSelectors.customerFacilityEditor.getUpdatedCustomerFacility(state);
    const originalFacilities = facilities.filter(
      (originalFacility) => originalFacility.faid == facility.faid
    );

    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(updateCustomerFacilities({ facilities: originalFacilities }));
    dispatch(saveFacilitiesError({ message }));

    return e;
  }
};
