import { createAction } from "@reduxjs/toolkit";

export const setIncludeAdvancedToPrint = createAction(
  "deviceHistory/SET_INCLUDE_ADVANCED_TO_PRINT"
);
export const setIncludeServicesToPrint = createAction(
  "deviceHistory/SET_INCLUDE_SERVICES_TO_PRINT"
);
export const setIncludeTestsToPrint = createAction("deviceHistory/SET_INCLUDE_TESTS_TO_PRINT");
export const setIncludeAuditTestsToPrint = createAction(
  "deviceHistory/SET_INCLUDE_AUDITS_TESTS_TO_PRINT"
);
export const setIncludeConfigurationToPrint = createAction(
  "deviceHistory/SET_INCLUDE_CONFIGURATION_TO_PRINT"
);
export const setIncludeFailDetailsToPrint = createAction(
  "deviceHistory/SET_INCLUDE_FAIL_DETAILS_TO_PRINT"
);
export const setIncludeBatteryToPrint = createAction("deviceHistory/SET_INCLUDE_BATTERY_TO_PRINT");
export const setIncludeWdsToPrint = createAction("deviceHistory/SET_INCLUDE_WDS_TO_PRINT");
export const setIncludeCustomFieldsToPrint = createAction(
  "deviceHistory/SET_INCLUDE_CUSTOM_FIELDS_TO_PRINT"
);
export const setIncludeContentClearedToPrint = createAction(
  "deviceHistory/SET_INCLUDE_CONTENT_CLEARED_TO_PRINT"
);

export const toggleIncludeAdvancedToPrint = (value) => (dispatch) =>
  dispatch(setIncludeAdvancedToPrint({ value: !value }));

export const toggleIncludeServicesToPrint = (value) => (dispatch) =>
  dispatch(setIncludeServicesToPrint({ value: !value }));

export const toggleIncludeTestsToPrint = (value) => (dispatch) =>
  dispatch(setIncludeTestsToPrint({ value: !value }));

export const toggleIncludeAuditTestsToPrint = (value) => (dispatch) =>
  dispatch(setIncludeAuditTestsToPrint({ value: !value }));

export const toggleIncludeConfigurationInformation = (value) => (dispatch) =>
  dispatch(setIncludeConfigurationToPrint({ value: !value }));

export const toggleIncludeFailDetailsToPrint = (value) => (dispatch) =>
  dispatch(setIncludeFailDetailsToPrint({ value: !value }));

export const toggleIncludeBatteryToPrint = (value) => (dispatch) =>
  dispatch(setIncludeBatteryToPrint({ value: !value }));

export const toggleIncludeWdsToPrint = (value) => (dispatch) =>
  dispatch(setIncludeWdsToPrint({ value: !value }));

export const toggleIncludeCustomFieldsToPrint = (value) => (dispatch) =>
  dispatch(setIncludeCustomFieldsToPrint({ value: !value }));

export const toggleIncludeContentClearedToPrint = (value) => (dispatch) =>
  dispatch(setIncludeContentClearedToPrint({ value: !value }));
