import { createSelector } from "@reduxjs/toolkit";

import { isEmpty } from "lodash/lang";
import isEqual from "lodash/isEqual";
import moduleSelectors from ".";

const getCustomerUserOriginal = (state) =>
  state.modules.customerManagement.customerUserEditor.original;
const getCustomerUserModified = (state) =>
  state.modules.customerManagement.customerUserEditor.modifications;
const getCustomerFirstNameError = (state) =>
  state.modules.customerManagement.customerUserEditor.firstNameError;
const getCustomerLastNameError = (state) =>
  state.modules.customerManagement.customerUserEditor.lastNameError;
const getCustomerUserPasswordError = (state) =>
  state.modules.customerManagement.customerUserEditor.passwordError;
const getCustomerUserEmailError = (state) =>
  state.modules.customerManagement.customerUserEditor.emailError;
const getVerifyPassword = (state) =>
  state.modules.customerManagement.customerUserEditor.resetPassword;
const getUsersRoles = (state) => state.modules.customerManagement.customerUsers.roles;
const getCsid = (state) => moduleSelectors.customerIdentity.getCsid(state);

export const getUpdatedCustomerUser = createSelector(
  [getCustomerUserOriginal, getCustomerUserModified],
  (original, modifications) => ({ ...original, ...modifications })
);

export const getUserPassword = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.password != null && customer.password.length > 0 ? customer.password : ""
);

export const getOperatorId = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.operatorId != null ? customer.operatorId : "N/A"
);

export const getUserEmail = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.email != null ? customer.email : ""
);

export const getFirstName = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.firstname != null ? customer.firstname : ""
);

export const getLastName = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.lastname != null ? customer.lastname : ""
);

export const getStatus = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.status != null ? customer.status : 6
);

export const getUserId = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.userid != null ? customer.userid : ""
);

export const getUserRoleName = createSelector([getUpdatedCustomerUser], (customer) =>
  customer.roleName != null ? customer.roleName : ""
);

export const getFacilityIds = createSelector([getUpdatedCustomerUser], (user) => {
  // TODO: make it so you don't have to check for null (there seems to be an issue with the onClear action)
  if (user.facilities != null) {
    return user.hasAllFacilitiesAccess ? ["all"] : user.facilities.map((facility) => facility.faid);
  }

  return ["all"];
});

export const getUserRolesByName = createSelector(getUsersRoles, (usersRoles = []) => {
  const roles = usersRoles.reduce(
    (acc, role) => ({
      ...acc,
      [role.name]: role,
    }),
    {}
  );
  return roles;
});

export const getUserRoleId = createSelector(
  [getUserRolesByName, getUserRoleName],
  (userRolesByName, userRoleName) => userRolesByName[userRoleName].id
);

export const getAdminUser = createSelector([getUserRolesByName], (userRolesByName = {}) =>
  userRolesByName["client-administrator"] != null ? userRolesByName["client-administrator"] : {}
);

export const getRoleNamesList = createSelector(getUsersRoles, (usersRoles = []) =>
  usersRoles
    .map((usersRole) => (usersRole.name ? usersRole.name : ""))
    .filter((userRoles) => userRoles != "")
);
export const getIsRequiredFilled = createSelector(
  [getFirstName, getLastName, getUserPassword, getVerifyPassword, getUserEmail],
  (firstName, lastName, userPassword, verifyPassword, userEmail) => {
    const isFilledFirstName = firstName.length > 0;
    const isFilledLastName = lastName.length > 0;
    const isFilledUserPassword = verifyPassword ? userPassword.length > 0 : true;
    const isFilledUserEmail = userEmail.length > 0;
    return isFilledFirstName && isFilledLastName && isFilledUserPassword && isFilledUserEmail;
  }
);

// VALIDATION: to be included for each tab
export const getCustomerUserIsValid = createSelector(
  [
    getCustomerFirstNameError,
    getCustomerLastNameError,
    getCustomerUserEmailError,
    getCustomerUserPasswordError,
    getVerifyPassword,
    getIsRequiredFilled,
  ],
  (
    firstNameError,
    lastNameError,
    userEmailError,
    userPasswordError,
    verifyPassword,
    isRequiredFilled
  ) => {
    const passwordError = verifyPassword ? userPasswordError : false;
    const errors = firstNameError || lastNameError || userEmailError || passwordError;
    return !errors && isRequiredFilled;
  }
);

export const getCustomerUserIsEqual = createSelector(
  [getUpdatedCustomerUser, getCustomerUserOriginal, getVerifyPassword],
  (modified, original, verifyPassword) => {
    const modifiedTrimed = {
      ...modified,
      password: verifyPassword ? modified.password : "",
    };
    const originalTrimed = {
      ...original,
      password: verifyPassword ? original.password : "",
    };
    const unmodified = isEqual(modifiedTrimed, originalTrimed);
    return unmodified || isEmpty(modified);
  }
);

export const getRequiredComplete = createSelector(
  [getUserEmail, getFirstName, getLastName],
  (userEmail, firstName, lastName) =>
    userEmail.length > 0 && firstName.length > 0 && lastName.length > 0
);

export const getCanSaveCustomerUser = createSelector(
  [getCustomerUserIsValid, getCustomerUserIsEqual, getRequiredComplete],
  (customerUserIsValid, customerFacilityIsEqual, requiredComplete) =>
    customerUserIsValid && !customerFacilityIsEqual && requiredComplete
);

// server format
export const getUserToServerFormat = createSelector(
  [getUpdatedCustomerUser, getUserRolesByName, getCustomerUserIsEqual, getCsid],
  (customerUser, userRolesByName, customerUserIsEqual, csid) => {
    const isEmptyUser = isEmpty(customerUser);
    if (!isEmptyUser && !customerUserIsEqual) {
      return {
        password: customerUser.password != null ? customerUser.password.trim() : "",
        email: customerUser.email.trim().toLowerCase(),
        user_metadata: {
          email: customerUser.email.trim().toLowerCase(),
          firstname: customerUser.firstname.trim(),
          lastname: customerUser.lastname.trim(),
          roles: [userRolesByName[customerUser.roleName]],
          client: csid,
          status: customerUser.status,
          userid: customerUser.userid,
          facilities: customerUser.facilities,
          hasAllFacilitiesAccess: customerUser.hasAllFacilitiesAccess,
          operatorId: customerUser.operatorId,
        },
      };
    }
  }
);
