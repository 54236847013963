import { createReducer } from "@reduxjs/toolkit";
import moduleActions from "~/data-import/actions";

const initialState = {
  isLoading: false,
  fileProcessing: false,
  errors: null,
  fileData: [],
  file: {
    name: "No file selected",
    size: "0",
  },
  selectedFilters: {},
};

export default createReducer(initialState, {
  [moduleActions.importDataAutomatic.createRecordingBegin]: (state) => {
    state.isLoading = true;
    state.errors = null;
  },
  [moduleActions.importDataAutomatic.createRecordingSuccess]: (state) => {
    state.isLoading = false;
  },
  [moduleActions.importDataAutomatic.createRecordingError]: (state, action) => {
    state.isLoading = false;
    state.errors = action.payload.errors;
  },
  [moduleActions.importDataAutomatic.onClearFile]: (state) => {
    state.fileData = [];
    state.file = {
      name: "No file selected",
      size: "0",
    };
    state.validationErrors = [];
    state.isLoading = false;
  },
  [moduleActions.importDataAutomatic.onClearFilters]: (state, action) => {
    state.selectedFilters = {};
    state.isLoading = false;
  },
  [moduleActions.importDataAutomatic.onSelectedFilters]: (state, action) => {
    state.selectedFilters = action.payload.filters;
    state.isLoading = false;
  },
  [moduleActions.importDataAutomatic.onProcessedData]: (state, action) => {
    state.fileData = action.payload.fileData;
    state.isLoading = false;
    state.fileProcessing = false;
  },
  [moduleActions.importDataAutomatic.onSelectedFile]: (state, action) => {
    let size = action.payload.file.size * 0.001; // convert byte size to kb size
    size = size.toFixed(2);

    const file = {
      name: action.payload.file.name,
      size,
    };
    state.file = file;
    state.isLoading = false;
    state.fileProcessing = true;
  },
});
