import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Select } from "antd";
import { actions as workflowActions, selectors as workflowSelectors } from "~/workflow/redux";

import labelSelectors from "~/label-printer/selectors";
import labelActions from "~/label-printer/actions";

import DetailsField from "~/global/reduxer/components/details/DetailsField";

const PrintLabelBehavior = (props) => {
  const { behaviorId, eventId } = props;
  const dispatch = useDispatch();

  const eventActions = workflowActions.eventEditor[eventId];
  const eventSelectors = workflowSelectors.eventEditor[eventId];

  const behavior = useSelector(eventSelectors.getBehaviorById(behaviorId));
  const dataSetter = (key) => (value) =>
    dispatch(eventActions.setBehaviorData({ behaviorId, key, value }));
  const labelLoaded = useSelector(labelSelectors.labelLister.isLoaded);
  const labelLoading = useSelector(labelSelectors.labelLister.isLoading);
  const labels = useSelector(labelSelectors.labelLister.getLabels);

  useEffect(() => {
    if (!labelLoaded && !labelLoading) {
      dispatch(labelActions.labelLister.fetchLabels());
    }
  }, [labelLoaded, labelLoading]);

  return (
    <>
      {labelLoaded && (
        <DetailsField
          label="Label"
          value={
            <Select onChange={dataSetter("labelId")} value={behavior.behaviorData.labelId}>
              {labels.map((label) => (
                <Select.Option key={label.id}>{label.name}</Select.Option>
              ))}
            </Select>
          }
        />
      )}
    </>
  );
};

PrintLabelBehavior.propTypes = {
  behaviorId: PropTypes.number,
  eventId: PropTypes.string,
};

export default PrintLabelBehavior;
