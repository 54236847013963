import { createReducer } from "@reduxjs/toolkit";
import actions from "~/actions";
import type { Facility } from "~/actions/facility.actions";

interface FacilityState {
  data?: Facility;
  error?: string;
  status: "idle" | "loading" | "error";
}

const initialState: FacilityState = {
  status: "idle",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.facility.fetchFacilityBegin, (state) => {
      delete state.error;
      state.status = "loading";
    })
    .addCase(actions.facility.fetchFacilitySuccess, (state, action) => {
      state.data = action.payload.facility;
      state.status = "idle";
    })
    .addCase(actions.facility.fetchFacilityError, (state, action) => {
      state.error = action.payload.error;
      state.status = "error";
    })
);
