import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const CHECK_FAIL_CODE = "CHECK_FAIL_CODE";
export const DELETE_FAIL_CODES_BEGIN = "DELETE_FAIL_CODES_BEGIN";
export const DELETE_FAIL_CODES_SUCCESS = "DELETE_FAIL_CODES_SUCCESS";
export const DELETE_FAIL_CODES_ERROR = "DELETE_FAIL_CODES_ERROR";
export const SET_FAIL_CODES = "SET_FAIL_CODES";
export const UPDATE_FAIL_CODES = "UPDATE_FAIL_CODES";
export const CLEAR_FAIL_CODES_ERRORS = "CLEAR_FAIL_CODES_ERRORS";

export const updateFailCodes = (failCodes) => ({
  type: UPDATE_FAIL_CODES,
  payload: { failCodes },
});

export const setCurrentFailCodes = (failCodes) => ({
  type: SET_FAIL_CODES,
  payload: { failCodes },
});

export const checkFailCode = (failCodeIds) => ({
  type: CHECK_FAIL_CODE,
  payload: { failCodeIds },
});

export const clearFailCodesErrors = () => ({
  type: CLEAR_FAIL_CODES_ERRORS,
});

export const deleteFailCodes = (failCodesIds) => async (dispatch, getState) => {
  dispatch(deleteFailCodesBegin(failCodesIds));
  try {
    await CustomerManagement.DELETE("/fail-codes", failCodesIds);
    dispatch(deleteFailCodesSuccess(failCodesIds));
  } catch (e) {
    const state = getState();
    const errors = e.json != null ? e.json.errors : null;
    const isAssociatedTestsError = errors != null && errors.failCodes != null;

    if (isAssociatedTestsError) {
      const failCodesById = selectors.failCodes.getFailCodesById(state);

      const message = Object.keys(errors.failCodes || {}).map((failCodeCategoryId) => {
        const failCodeCategoryErrors = errors.failCodes[failCodeCategoryId];

        return {
          failCodes: Object.keys(failCodeCategoryErrors).map((associatedTestIndex) => ({
            failCodeName: failCodesById[failCodeCategoryId].name,
            associatedTests: failCodeCategoryErrors[associatedTestIndex],
          })),
        };
      });

      dispatch(deleteFailCodesError(message, true));
    } else {
      const textOfErased = failCodesIds.length > 1 ? "Codes" : "Code";

      const message = [].concat(
        errors != null && typeof errors === "string"
          ? errors
          : `Failed to delete Fail ${textOfErased}`
      );
      dispatch(deleteFailCodesError(message, false));
    }
  }
};

export const deleteFailCodesBegin = (ids) => ({
  type: DELETE_FAIL_CODES_BEGIN,
  payload: { ids },
});

export const deleteFailCodesSuccess = (ids) => ({
  type: DELETE_FAIL_CODES_SUCCESS,
  payload: { ids },
});

export const deleteFailCodesError = (message, isAssociatedTestError) => ({
  type: DELETE_FAIL_CODES_ERROR,
  payload: {
    message,
    isAssociatedTestError,
  },
});
