import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sortBy } from "lodash/collection";

import { AsciButton, SectionHeader } from "~/global";
import FailCodeCategoryPicker from "~/fail-codes/FailCodeCategories/FailCodeCategoryPicker.component";
import FailCodesPicker from "~/fail-codes/FailCodes/FailCodesPicker.component";
import FailCodeAssociatedTestsList from "~/fail-codes/FailCodeAssociatedTestsList/FailCodeAssociatedTestsList.component";

import actions from "~/actions";
import selectors from "~/selectors";

import "./TestDefinitionFailCodesSelectionDialog.component.scss";

const TestDefinitionFailCodesSelectionDialog = ({
  onClose,
  onConfirm,
  initialSelectedFailCodeIds,

  // From the store
  categories,
  categoriesById,
  selectedCategory,
  selectCategory,

  failCodesById,
  selectedFailCode,
  selectFailCode,
  categoryFailCodes,
  allFailCodesCheckedIds,
  selectedCategoryCheckedFailCodeIds,
  checkFailCodes,
  fetchFailCodeAssociatedTests,

  testsAssociatedWithSelectedFailCode,
  associatedTestsLoading,
}) => {
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await selectCategory(categories[0]);
    checkFailCodes(initialSelectedFailCodeIds);
  };

  const onCategorySelected = async (id) => {
    await selectCategory(categoriesById[id]);
    selectFailCode({});
  };

  const onFailCodeSelected = async (id) => {
    selectFailCode(failCodesById[id]);
    await fetchFailCodeAssociatedTests(id);
  };

  const onFailCodesChecked = async (ids, checked, unchecked) => {
    let newIds = [...allFailCodesCheckedIds];

    // add ids that were checked
    checked.forEach((id) => {
      // only add ids that were not already checked
      if (!allFailCodesCheckedIds.includes(id)) {
        newIds.push(id);
      }
    });

    // remove ids that were unchecked
    if (unchecked.length > 0) {
      newIds = newIds.filter((id) => !unchecked.includes(id));
    }

    await checkFailCodes(newIds);
  };

  return (
    <div className="test-definition-fail-codes-selection-dialog">
      <SectionHeader className="flex--noshrink" />

      <div className="sections layout-row">
        <div className="col-categories">
          <h2>Fail Codes Categories</h2>
          <FailCodeCategoryPicker
            failCodeCategories={categories}
            isEditable={false}
            isCheckable={false}
            selectedId={selectedCategory.id}
            onSelect={onCategorySelected}
          />
        </div>

        <div className="col-fail-codes">
          <h2>Fail Codes</h2>
          <FailCodesPicker
            failCodes={sortBy(categoryFailCodes, "name")}
            checkedIds={selectedCategoryCheckedFailCodeIds}
            selectedId={selectedFailCode.id}
            onSelect={onFailCodeSelected}
            onCheck={onFailCodesChecked}
          />
        </div>

        <div className="col-tests">
          <h2>Associated Tests</h2>
          <FailCodeAssociatedTestsList
            testConfigs={testsAssociatedWithSelectedFailCode}
            emptyMessage={
              selectedFailCode.id && !associatedTestsLoading
                ? "There are no tests associated with this fail code."
                : undefined
            }
          />
        </div>
      </div>

      <div
        className="actions layout-row layout-row--end-center padding--10"
        style={{ background: "grey" }}
      >
        <AsciButton color="white" disabled={false} onClick={onClose}>
          Close
        </AsciButton>

        <AsciButton color="blue" disabled={false} onClick={() => onConfirm(allFailCodesCheckedIds)}>
          Select
        </AsciButton>
      </div>
    </div>
  );
};

TestDefinitionFailCodesSelectionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  initialSelectedFailCodeIds: PropTypes.arrayOf(PropTypes.string),

  // from the store
  categories: PropTypes.array.isRequired,
  categoriesById: PropTypes.object.isRequired,
  selectedCategory: PropTypes.object.isRequired,

  failCodesById: PropTypes.object.isRequired,
  selectedFailCode: PropTypes.object.isRequired,
};

TestDefinitionFailCodesSelectionDialog.defaultProps = {
  initialSelectedFailCodeIds: [],
};

const mapStateToProps = (state) => ({
  // categories
  categories: selectors.failCodes.getFilteredFailCodeCategories(state),
  categoriesById: selectors.failCodes.getFailCodesCategoriesById(state),
  selectedCategory: state.failCodeCategory.original,

  // fail codes
  failCodesById: selectors.failCodes.getFailCodesById(state),
  categoryFailCodes: selectors.failCodes.getSelectedCategoryFailCodes(state),
  selectedFailCode: state.failCode.original,
  allFailCodesCheckedIds: state.failCodes.itemsCheckedIds,
  selectedCategoryCheckedFailCodeIds:
    selectors.failCodes.getSelectedCategoryCheckedFailCodesIds(state),

  // selected fail code
  testsAssociatedWithSelectedFailCode: state.failCode.associatedTests,
  associatedTestsLoading: state.failCode.associatedTestsLoading,
});

const mapDispatchToProps = {
  selectCategory: actions.failCodeCategory.selectFailCodeCategory,
  selectFailCode: actions.failCode.selectFailCode,
  checkFailCodes: actions.failCodes.checkFailCode,
  fetchFailCodeAssociatedTests: actions.failCode.fetchFailCodeAssociatedTests,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestDefinitionFailCodesSelectionDialog);
