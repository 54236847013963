import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";

import { CustomerManagement } from "~/services";
import { RootState } from "~/store";
import { Descriptor } from "./descriptorTypes";

export type SearchModelDescriptorsParams = {
  page: number;
  perPage: number;
  keyword?: string;
};

export type SearchModelDescriptorsPayload = { Doc: Descriptor[] | null; TotalDoc: number };

type ServiceError = { json: { message?: string } };

export const searchModelDescriptors = createAsyncThunk<
  SearchModelDescriptorsPayload,
  SearchModelDescriptorsParams,
  { rejectValue: string }
>("modelDescriptors/search", async (searchParams, { rejectWithValue }) => {
  try {
    const response = (await CustomerManagement.POST("/model-descriptors/search", searchParams)) as {
      payload: SearchModelDescriptorsPayload;
    };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const archiveModelDescriptor = createAsyncThunk<string, string, { rejectValue: string }>(
  "modelDescriptors/archive",
  async (id, { rejectWithValue }) => {
    try {
      await CustomerManagement.PUT(`/model-descriptor/${id}/archive`, undefined);

      return id;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      } else {
        const message: string =
          (err as ServiceError).json?.message ?? "Request didn't complete successfully";

        return rejectWithValue(message);
      }
    }
  }
);

export const createModelDescriptor = createAsyncThunk<
  Descriptor,
  Omit<Descriptor, "id">,
  { rejectValue: string }
>("modelDescriptors/create", async (descriptor, { rejectWithValue }) => {
  try {
    const response = (await CustomerManagement.POST(`/model-descriptor`, descriptor)) as {
      payload: Descriptor;
    };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

export const updateModelDescriptor = createAsyncThunk<
  Descriptor,
  { id: string; descriptor: Descriptor },
  { rejectValue: string }
>("modelDescriptors/update", async ({ id, descriptor }, { rejectWithValue }) => {
  try {
    const response = (await CustomerManagement.PUT(`/model-descriptor/${id}`, descriptor)) as {
      payload: Descriptor;
    };

    return response.payload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw err;
    } else {
      const message: string =
        (err as ServiceError).json?.message ?? "Request didn't complete successfully";

      return rejectWithValue(message);
    }
  }
});

const modelDescriptorsAdapter = createEntityAdapter<Descriptor>({
  sortComparer: (a, b) => {
    const nameA = a.modelInfo.commercialName;
    const nameB = b.modelInfo.commercialName;

    if (nameA == null && nameB == null) {
      return 0;
    }
    if (nameA == null) {
      return 1;
    }
    if (nameB == null) {
      return -1;
    }

    return nameA.localeCompare(nameB);
  },
});

interface ModelDescriptorsState extends EntityState<Descriptor> {
  numOfResults?: number;
  listStatus: "idle" | "loading";
  formStatus: "idle" | "loading";
}

const initialState: ModelDescriptorsState = {
  ...modelDescriptorsAdapter.getInitialState(),
  listStatus: "idle",
  formStatus: "idle",
};

const modelDescriptorsSlice = createSlice({
  name: "modelDescriptors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Search
      .addCase(searchModelDescriptors.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(searchModelDescriptors.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        // TODO: remove this condition when the back-end is updated to return an empty array instead of null
        if (action.payload.Doc) {
          modelDescriptorsAdapter.setAll(draftState, action.payload.Doc);
        } else {
          modelDescriptorsAdapter.setAll(draftState, []);
        }
        draftState.numOfResults = action.payload.TotalDoc;
      })
      .addCase(searchModelDescriptors.rejected, (draftState) => {
        draftState.listStatus = "idle";
      })
      // Create
      .addCase(createModelDescriptor.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(createModelDescriptor.fulfilled, (draftState) => {
        draftState.formStatus = "idle";
      })
      .addCase(createModelDescriptor.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })
      // Update
      .addCase(updateModelDescriptor.pending, (draftState) => {
        draftState.formStatus = "loading";
      })
      .addCase(updateModelDescriptor.fulfilled, (draftState) => {
        draftState.formStatus = "idle";
      })
      .addCase(updateModelDescriptor.rejected, (draftState) => {
        draftState.formStatus = "idle";
      })

      // Archive (functions as a delete in the UI)
      .addCase(archiveModelDescriptor.pending, (draftState) => {
        draftState.listStatus = "loading";
      })
      .addCase(archiveModelDescriptor.fulfilled, (draftState, action) => {
        draftState.listStatus = "idle";
        modelDescriptorsAdapter.removeOne(draftState, action.payload);
      })
      .addCase(archiveModelDescriptor.rejected, (draftState) => {
        draftState.listStatus = "idle";
      });
  },
});

export default modelDescriptorsSlice.reducer;

export const modelDescriptorsSelectors = modelDescriptorsAdapter.getSelectors<RootState>(
  (state) => state.modelDescriptors
);
