import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Column, AsciButton } from "~/global";

import "./AffectedProgramsDialog.component.scss";

const AffectedProgramsDialog = ({ close, template, affectedPrograms }) => {
  const currentAffectedPrograms = affectedPrograms[template.identifier] ?? [];

  const confirmationDescription = `The changes to ${template.identifier} will affect the following programs :`;

  return (
    <div className="confirmation-dialog">
      <Column className="full-width">
        <Column>
          <Row className="description-text">{confirmationDescription}</Row>
          {currentAffectedPrograms.length === 0 ? (
            <Row className="affected-csid">
              <div className="csid-label">No Programs affected</div>
            </Row>
          ) : (
            currentAffectedPrograms.map((affectedProgram) => (
              <>
                <Row className="affected-csid">
                  <div className="csid-label">CSID:</div>
                  <div className="csid-programs">{affectedProgram._id}</div>
                </Row>

                <ProgramsIdentifiers affectedProgram={affectedProgram} />
              </>
            ))
          )}
        </Column>

        <Row className="btn-group full-width">
          <AsciButton className="close-btn" color="white" onClick={() => close()}>
            Close
          </AsciButton>
        </Row>
      </Column>
    </div>
  );
};

const ProgramsIdentifiers = ({ affectedProgram }) =>
  affectedProgram.identifiers.map((identifier) => (
    <Row key={identifier} className="identifier-row">
      <div className="icon-circle" />
      <div className="identifier-value">{identifier}</div>
    </Row>
  ));

AffectedProgramsDialog.propTypes = {
  template: PropTypes.exact({
    identifier: PropTypes.string,
    services: PropTypes.array,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,

  // From the store
  affectedPrograms: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  affectedPrograms: state.modules.serviceSuiteTemplates.affectedPrograms,
});

const dispatchToProps = {};

export default connect(stateToProps, dispatchToProps)(AffectedProgramsDialog);
