import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Cell } from "react-md";

import { message } from "antd";
import * as DocumentationActions from "~/actions/Documentation.actions";
import { AsciButton, AsciSearch, Thumbnail } from "~/global";

const DocumentTab = ({ documents }) => {
  const [displayedDocuments, setDisplayedDocuments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setDisplayedDocuments([].concat(documents));
  }, [documents]);

  const onSearch = (searchResult, query) => {
    setDisplayedDocuments(searchResult);
    setSearchQuery(query);
  };

  const installClicked = async (doc) => {
    message
      .loading("Download in progress..", 10)
      .then(() => message.warning("Request is taking longer than expected", 2.5));
    const response = await DocumentationActions.downloadVersion(doc.url, doc.contentType, doc.name);
    message.destroy();
    response === "file downloaded"
      ? message.success(response, 2.5)
      : response != null
      ? message.error(response, 2.5)
      : message.error("Failed to Download", 2.5);
  };

  return (
    <div className="asci-table">
      <div className="toolbar-header">
        <AsciSearch
          id="documents-search"
          array={documents || []}
          callback={onSearch}
          keys={["name"]}
          query={searchQuery}
        />
      </div>

      <Grid className="full-width docs-container">
        {displayedDocuments.length > 0 ? (
          displayedDocuments.map((doc, index) => (
            <Cell size={3} key={`doc${index}`}>
              <div className="column centered doc-selection-container selection-container">
                <div className="doc-name">{doc.name}</div>
                <Thumbnail
                  iconClass={
                    doc.contentType == "application/pdf" ? "icon-file-pdf-o" : "icon-file-pdf-o"
                  }
                  text=""
                  image={`${doc.url}/thumbnail`}
                />

                <div className="row centered buttons-container">
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    <AsciButton color="white">Open</AsciButton>
                  </a>

                  <AsciButton color="green" onClick={() => installClicked(doc)}>
                    Download
                  </AsciButton>
                </div>
              </div>
            </Cell>
          ))
        ) : (
          <div className="row centered full-width">
            <h2>No available documents</h2>
          </div>
        )}
      </Grid>
    </div>
  );
};

DocumentTab.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      contentType: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

DocumentTab.defaultProps = {
  documents: [],
};

export default DocumentTab;
