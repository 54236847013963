import React from "react";
import PropTypes from "prop-types";

import "./InspectorInput.scss";

const InspectorInput = ({ label, children, title }) => (
  <div className="inspector-input" title={title}>
    <div className="inspector-input-label">{label}</div>
    <div className="inspector-input-input">{children}</div>
  </div>
);

InspectorInput.defaultProps = {};

InspectorInput.propTypes = {
  label: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default InspectorInput;
