import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Button.component.scss";

export default class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.oneOf([undefined, "white", "red", "blue", "green"]),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    disabled: false,
  };

  render() {
    const classes = ["cc-button"];

    if (this.props.className.length > 0) {
      classes.push(this.props.className);
    }

    if (this.props.color) {
      classes.push(this.props.color);
    }

    return (
      <button
        type="button"
        className={classes.join(" ")}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}
