import React from "react";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";
import { Select } from "antd";

import SideSelect from "./SideSelect";
import InspectorSection from "./InspectorSection";
import InspectorInput from "./InspectorInput";
import ColorInput from "./ColorInput";
import NumberInput from "~/label-printer/components/designer/inspector/commons/NumberInput";

const BORDER_STYLES = ["solid", "double", "dotted", "dashed", "inset", "outset", "groove", "ridge"];

const BorderInspector = (props) => {
  const { title, level, side, style, width, color, radius, setterFactory } = props;

  return (
    <InspectorSection title={title} initialFolded level={level}>
      <InspectorInput label="Side">
        <SideSelect onChange={setterFactory("side")} value={side} />
      </InspectorInput>
      <InspectorInput label="Style">
        <Select onChange={setterFactory("style")} value={style} allowClear>
          {BORDER_STYLES.map((border) => (
            <Select.Option key={`option-${border}`} value={border}>
              {capitalize(border)}
            </Select.Option>
          ))}
        </Select>
      </InspectorInput>
      <InspectorInput label="Width">
        <NumberInput onChange={setterFactory("width")} defaultValue={width} min={0} unit="pt" />
      </InspectorInput>
      <InspectorInput label="Color">
        <ColorInput color={color || "#000"} onChange={setterFactory("color")} />
      </InspectorInput>

      <InspectorInput label="Radius">
        <NumberInput onChange={setterFactory("radius")} defaultValue={radius} unit="pt" />
      </InspectorInput>
    </InspectorSection>
  );
};

BorderInspector.defaultProps = {
  level: 1,
  title: "Border",
};

BorderInspector.propTypes = {
  level: PropTypes.number,
  title: PropTypes.string,

  side: PropTypes.oneOf(SideSelect.SIDES),
  style: PropTypes.oneOf(BORDER_STYLES),
  width: PropTypes.number,
  color: PropTypes.string,
  radius: PropTypes.number,

  setterFactory: PropTypes.func.isRequired,
};

export default BorderInspector;
