import React, { useState, useEffect } from "react";
import { ExpansionList, ExpansionPanel } from "react-md";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import StatisticsTable from "../StatisticsTable/StatisticsTable.component";
import PerformanceCard from "../PerformanceCard/PerformanceCard.component";
import TopFailedCard from "../TopFailedCard/TopFailedCard.component";
import DashboardFilters from "../DashboardFilters/DashboardFilters.component";

import { Row, Column, AsciButton, InfoBanner } from "~/global";
import { useTranslation } from "~/i18n";
import { useAppSelector, useAppDispatch } from "~/hooks";
import selectors from "~/selectors";
import {
  fetchTeamShowMore,
  fetchTeamCustomFields,
  fetchTeamFailCodes,
  fetchTeamTopFailedAudits,
  fetchTeamTopFailedTests,
} from "../../models/teams.slice";
import { fetchTeamPerformance } from "../../models/teamPerformance.slice";
import "./DashboardPage.component.scss";
import { fetchDevicesProcessed } from "../../models/devicesProcessed.slice";
import { DashboardFiltersParams } from "../../models/typeAndToolDashboard";

export type FiltersState = {
  teamId: string;
  facilityId: string;
  deploymentId: string;
  shiftId: string;
  programId: string;
  configurationId: string;
  dateFrom?: string;
  dateTo?: string;
};

const DashboardPage = () => {
  // Configuration == Service Suite

  const [level, setLevel] = useState("configurations");
  const [currentFilters, setCurrentFilters] = useState<FiltersState>({
    teamId: "all",
    facilityId: "all",
    deploymentId: "all",
    shiftId: "all",
    programId: "all",
    configurationId: "all",
  });
  const [chartTimezone, setChartTimezone] = useState<string | undefined>(undefined);

  const showConfigurations = level === "configurations";
  const showTeams = level === "teams";
  const showOperators = level === "operators";
  const dispatch = useAppDispatch();
  const { status, teamsPerformance } = useAppSelector(
    (state) => state.modules.dashboard.teamPerformance
  );

  const teamsByIdByFacilityId = useAppSelector((state) =>
    // @ts-ignore
    selectors.filters.getTeamsByIdByFacilityId(state)
  );

  const teamsById = useAppSelector((state) => state.modules.dashboard.teams.teamsById);
  const auditsStatus = teamsById[currentFilters.teamId]?.topFailedAudits.status;
  const auditsHasData = !!(teamsById[currentFilters.teamId] || {}).topFailedAudits;

  const testsStatus = teamsById[currentFilters.teamId]?.topFailedTests.status;
  const testsHasData = !!(teamsById[currentFilters.teamId] || {}).topFailedTests;

  const { t } = useTranslation("dashboard");

  const goBackALevel = (e: React.MouseEvent) => {
    e.stopPropagation();

    let filters;

    if (level === "operators") {
      setLevel("teams");
      filters = { ...currentFilters, teamId: "all" };
    } else if (level === "teams") {
      setLevel("configurations");
      filters = { ...currentFilters, programId: "all", configurationId: "all" };
    }
    if (filters) setCurrentFilters(filters);
    const itemsFilters: DashboardFiltersParams = {
      facilityID: filters?.facilityId,
      deploymentID: filters?.deploymentId,
      shiftID: filters?.shiftId,
      teamID: filters?.teamId || "",
      programID: filters?.programId,
      configID: filters?.configurationId,
      timezone: chartTimezone,
      from: filters?.dateFrom,
      to: filters?.dateTo,
    };
    void dispatch(fetchDevicesProcessed(itemsFilters));

    void dispatch(fetchTeamPerformance(itemsFilters));
  };
  const itemsCurrentFilters: DashboardFiltersParams = {
    facilityID: currentFilters.facilityId,
    deploymentID: currentFilters.deploymentId,
    shiftID: currentFilters.shiftId,
    teamID: currentFilters.teamId,
    programID: currentFilters.programId,
    configID: currentFilters.configurationId,
    timezone: chartTimezone,
    from: currentFilters.dateFrom,
    to: currentFilters.dateTo,
  };
  const showMoreTeamData = (teamId: string) => {
    itemsCurrentFilters.teamID = teamId;
    void dispatch(fetchTeamShowMore(itemsCurrentFilters));
  };

  const showCustomFields = (teamId: string) => {
    itemsCurrentFilters.teamID = teamId;
    void dispatch(fetchTeamCustomFields(itemsCurrentFilters));
  };

  const showFailCodes = (teamId: string) => {
    itemsCurrentFilters.teamID = teamId;
    void dispatch(fetchTeamFailCodes(itemsCurrentFilters));
  };

  useEffect(() => {
    if (showOperators) {
      if (!auditsHasData) {
        void dispatch(fetchTeamTopFailedAudits(itemsCurrentFilters));
      }

      if (!testsHasData) {
        void dispatch(fetchTeamTopFailedTests(itemsCurrentFilters));
      }
    }
  }, [showOperators, auditsHasData, testsHasData, dispatch, itemsCurrentFilters]);

  return (
    <Column className="dashboard-page full-width" flex>
      <InfoBanner text={t("only-pro-d-devices-are-displayed")} />
      <DashboardFilters
        setLevel={setLevel}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
        setChartTimezone={setChartTimezone}
      />

      <ExpansionList>
        <ExpansionPanel
          className="cc-expansion-panel"
          label={
            <CustomLabel
              showConfigurations={showConfigurations}
              showTeams={showTeams}
              goBackALevel={goBackALevel}
            />
          }
          secondaryLabel={
            !showConfigurations ? (
              <CustomSecondaryLabel
                currentFilters={currentFilters}
                teamsByIdByFacilityId={teamsByIdByFacilityId}
              />
            ) : null
          }
          defaultExpanded
          expanderIcon={<i className="icon-angle-down" />}
          footer={null}
        >
          <StatisticsTable
            setLevel={setLevel}
            level={level}
            setCurrentFilters={setCurrentFilters}
            currentFilters={currentFilters}
            chartTimezone={chartTimezone}
          />
        </ExpansionPanel>

        <ExpansionPanel
          className="cc-expansion-panel"
          label={t("performance-by-team")}
          // secondaryLabel={<Row className="filter-tag">Team</Row>}
          defaultExpanded
          expanderIcon={<i className="icon-angle-down" />}
          footer={null}
        >
          <Row className="layout-wrap">
            {status === "pending" ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 40, color: "#005288" }} spin />}
                size="large"
              />
            ) : (
              <>
                {teamsPerformance?.map((teamPerformance) => {
                  const team = teamsById?.[teamPerformance.teamId];
                  return (
                    <PerformanceCard
                      key={teamPerformance.teamId}
                      performance={teamPerformance}
                      team={team}
                      showMoreTeamData={() => showMoreTeamData(teamPerformance.teamId)}
                      showCustomFields={() => showCustomFields(teamPerformance.teamId)}
                      showFailCodes={() => showFailCodes(teamPerformance.teamId)}
                    />
                  );
                })}
                {(showOperators && auditsStatus === "pending") ||
                  (showOperators && testsStatus === "pending" && (
                    <>
                      <TopFailedCard
                        data={teamsById[currentFilters.teamId]?.topFailedAudits.items}
                        dataType="Audits"
                      />
                      <TopFailedCard
                        data={teamsById[currentFilters.teamId]?.topFailedTests.items}
                        dataType="Tests"
                      />
                    </>
                  ))}
              </>
            )}
          </Row>
        </ExpansionPanel>
      </ExpansionList>
    </Column>
  );
};

type PropsCustomLabel = {
  showConfigurations: boolean;
  showTeams: boolean;
  goBackALevel: (e: React.MouseEvent) => void;
};

const CustomLabel = ({ showConfigurations, showTeams, goBackALevel }: PropsCustomLabel) => {
  const { t } = useTranslation("dashboard");
  const currentLevel = showConfigurations ? "Service Suite" : showTeams ? "Team" : "Operator";

  return (
    <Row>
      {!showConfigurations ? (
        <>
          <AsciButton color="green" onClick={(e) => goBackALevel(e)}>
            <i className="icon-arrow-left" />
            &nbsp;{t("back")}
          </AsciButton>

          <Row>
            {t("devices-processed-by")} {currentLevel} {t("in")}
          </Row>
        </>
      ) : (
        <Row>
          {t("devices-processed-by")} {currentLevel}
        </Row>
      )}
    </Row>
  );
};

type PropsCustomSecondaryLabel = {
  currentFilters: FiltersState;
  teamsByIdByFacilityId: any;
};

const CustomSecondaryLabel = ({
  currentFilters,
  teamsByIdByFacilityId,
}: PropsCustomSecondaryLabel) => {
  const filteredFilters: string[] = [];
  filteredFilters.push(
    currentFilters.configurationId !== "all" ? currentFilters.configurationId : "all"
  );
  filteredFilters.push(currentFilters.teamId !== "all" ? currentFilters.teamId : "all");
  const { facilityId } = currentFilters;

  const filters = teamsByIdByFacilityId[facilityId][currentFilters.teamId];
  return (
    <Row>
      <div className="filter-tag">{currentFilters.configurationId}</div>
      <div className="filter-tag">
        {filters}
        {/* <span className="filter-arrow">&gt;</span> */}
      </div>
    </Row>
  );
};

export default DashboardPage;
