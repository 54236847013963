import React, { useEffect } from "react";
import { isEmpty } from "lodash/lang";

import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import { FontIcon, TextField, SelectionControl, Button } from "react-md";
import actions from "~/actions";
import moduleActions from "../../actions";
import moduleSelectors from "../../selectors";
import selectors from "~/selectors";
import { useTranslation } from "~/i18n";
import { useAppDispatch, useAppSelector } from "~/hooks";

import { ImageUploader, DialogMessage, SectionHeader } from "~/global";

const CustomerIdentity = ({ csid = "", newCustomer = true }) => {
  const { t } = useTranslation("customerIdentity");
  const dispatch = useAppDispatch();

  const customerInfos = useAppSelector(moduleSelectors.customerIdentity.getUpdatedCustomerIdentity);
  const customerName = useAppSelector(moduleSelectors.customerIdentity.getCustomerName);
  const customerNameError = useAppSelector(
    (state) => state.modules.customerManagement.customerIdentity.nameError
  );
  const customerLogo = useAppSelector(moduleSelectors.customerIdentity.getCustomerLogo);
  const reseller = useAppSelector(moduleSelectors.customerIdentity.getReseller);
  const autoAssignToAllTeams = useAppSelector(
    moduleSelectors.customerIdentity.getAutoAssignToAllTeams
  );
  const canEditCustomerName = useAppSelector((state) =>
    selectors.session.hasPermission(state, "PUT", "/customers/:csid")
  );
  const canEditReseller = useAppSelector((state) =>
    selectors.session.hasPermission(state, "PUT", "/customers/:csid")
  );
  const canSave = useAppSelector(moduleSelectors.customerIdentity.getCanSaveCustomerIdentity);

  useEffect(() => {
    if (csid.length > 0 && isEmpty(customerInfos)) {
      dispatch(moduleActions.customerIdentity.fetchCustomerIdentity(csid));
    }
  }, [csid, customerInfos, dispatch]);

  const icons = {
    checked: <FontIcon iconClassName="icon-check-square" />,
    unchecked: <FontIcon iconClassName="icon-square-o" />,
  };

  const onInfoClicked = () => {
    dispatch(
      actions.dialog.show({
        title: t("title-usage-information"),
        width: "450px",
        modal: false,
        content: (close) => (
          <DialogMessage close={close} textButtonClose={t("close-button")}>
            <div>{t("a-customer-name-must-be-entered")}</div>
            <br />
            <div>{t("click-the-list-header")}</div>
          </DialogMessage>
        ),
      })
    );
  };

  return (
    <div>
      {!newCustomer && (
        <SectionHeader title={t("title-customer-name") + customerName} description={csid}>
          <Button
            icon
            className="icon-btn text-color--white"
            iconClassName="icon-info-circle"
            forceIconSize={32}
            forceIconFontSize
            onClick={onInfoClicked}
          />
        </SectionHeader>
      )}
      <div className="grey-border column centered ">
        <div className="layout-row full-width margin-v--15">
          <div className="flex--30 flex--nogrow layout-row layout-row--end-center">
            {t("customer-name")} {canEditCustomerName && <sup>{t("required")}</sup>}
          </div>
          <div className="flex--50 flex--nogrow layout-row layout-row--start-center margin-left--15">
            {canEditCustomerName ? (
              <TextField
                id="customer-name-text-field"
                lineDirection="left"
                placeholder={t("placeholder-enter-customer-name")}
                value={customerName}
                className="table-text-field"
                onChange={(name) => {
                  dispatch(moduleActions.customerIdentity.onChangeName(name));
                  dispatch(moduleActions.customerIdentity.validateCustomerName());
                }}
                onBlur={() => {
                  if (newCustomer) {
                    dispatch(moduleActions.customerIdentity.fetchCsid(customerName));
                  }
                }}
                error={customerNameError}
                errorText={t("error-text-a-valid-customer-name")}
                onKeyPress={(e) => {
                  if (!newCustomer && canSave && e.key === "Enter") {
                    dispatch(moduleActions.customerIdentity.onSaveIdentity(csid));
                  }
                }}
              />
            ) : (
              <div>{customerName}</div>
            )}
          </div>
        </div>
        <div className="layout-row full-width margin-v--15">
          <div className="flex--30 flex--nogrow layout-row layout-row--end-center">
            {t("customer-id")}
          </div>
          <div className="flex--20 flex--nogrow layout-row layout-row--space-between-center margin-left--15">
            <div>{csid}</div>
          </div>

          <div className="flex--10 flex--nogrow layout-row layout-row--space-between-center margin-left--15">
            {canEditReseller && (
              <SelectionControl
                id="checkbox-read-documentation-page"
                checkedCheckboxIcon={icons.checked}
                uncheckedCheckboxIcon={icons.unchecked}
                name="simple-checkboxes[]"
                label={t("reseller")}
                type="checkbox"
                value="documentation"
                checked={!!reseller}
                onChange={() => dispatch(moduleActions.customerIdentity.onChangeReseller())}
                disabled={!canEditReseller}
                onKeyPress={(e) => {
                  if (!newCustomer && canSave && e.key === "Enter") {
                    dispatch(moduleActions.customerIdentity.onSaveIdentity(csid));
                  }
                }}
              />
            )}
          </div>
          <div className="flex--20 flex--nogrow layout-row layout-row--space-between-center margin-left--15">
            <SelectionControl
              id="checkbox-auto-assign-to-all-teams"
              checkedCheckboxIcon={icons.checked}
              uncheckedCheckboxIcon={icons.unchecked}
              name="simple-checkboxes[]"
              label={t("auto-assign-to-all-teams")}
              type="checkbox"
              value="auto-assign-to-all-teams"
              checked={!!autoAssignToAllTeams}
              onChange={() =>
                dispatch(moduleActions.customerIdentity.onChangeAutoAssignToAllTeams())
              }
              onKeyPress={(e) => {
                if (!newCustomer && canSave && e.key === "Enter") {
                  dispatch(moduleActions.customerIdentity.onSaveIdentity(csid));
                }
              }}
            />
          </div>
        </div>

        <div className="layout-row margin-v--15 full-width">
          <div className="flex--30 flex--nogrow layout-row layout-row--end-start">
            {t("company-logo")}
          </div>
          <div className="flex--50 flex--nogrow layout-row layout-row--start-center margin-left--15">
            <ImageUploader
              imageUrl={customerLogo}
              onChangeImage={(image) =>
                dispatch(moduleActions.customerIdentity.onChangeLogo(image))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerIdentity.propTypes = {
  newCustomer: PropTypes.bool,
  csid: PropTypes.string,
};

export default CustomerIdentity;
