import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataTable, TableBody, TableColumn, TableRow, TableHeader } from "react-md";
import { isEmpty } from "lodash/lang";

import PropTypes from "prop-types";
import { DialogMessage, Row, Column } from "~/global";

import moduleActions from "~/device-history/actions/";
import moduleSelectors from "~/device-history/selectors/";

import WdsInformation from "~/device-history/components/WDSInformations/WDSInformations.component";
import StatusIcon from "~/device-history/components/StatusIcon/StatusIcon.component";

import "./DeviceInformation.component.scss";

const DeviceInfoColumn = ({ label, children }) => (
  <>
    <TableColumn className="table-label">{label}</TableColumn>
    <TableColumn className="table-data">{children || "N/A"}</TableColumn>
  </>
);

const iphonePlaceHolder =
  "https://storage.googleapis.com/mm-device-images/placeholder/large/iphone.png";
const androidPlaceHolder =
  "https://storage.googleapis.com/mm-device-images/placeholder/large/android.png";

const DeviceInformation = (props) => {
  const [phonePlaceHolder, setPhonePlaceHolder] = useState(androidPlaceHolder);

  const { bestAvailableField } = props.deviceInfo;

  const isIMEI2Present = props.deviceInfo.IMEI2 !== undefined && props.deviceInfo.IMEI2 !== "";

  useEffect(() => {
    if (!isEmpty(props.deviceInfo.manufacturer) && props.deviceInfo.manufacturer === "Apple") {
      setPhonePlaceHolder(iphonePlaceHolder);
    }
  }, [props.deviceInfo]);

  useEffect(() => {
    if (props.wdsInfos != null) {
      props.showDialog({
        title: `TAC Database for IMEI ${props.deviceInfo.IMEI}`,
        width: "90%",
        modal: false,
        content: (close) => (
          <DialogMessage close={props.hideDialog}>
            <WdsInformation wdsInfos={props.wdsInfos} wdsInfosExtra={props.wdsInfosExtra} />
          </DialogMessage>
        ),
      });
    }
  }, [props.wdsInfos]);

  const renderIMEI2Row = () => <Row>{props.deviceInfo.IMEI2}</Row>;

  const renderBestAvailableID = () => {
    if (props.deviceInfo.bestAvailableField !== undefined) {
      return (
        <Row className="best-ID row centered">
          <Column>
            <Row className="section-title row centered full width">Best Available ID</Row>
            <Row className="best-ID-value row centered full width">
              {`${bestAvailableField.split(".")[1]}: ${props.deviceInfo.bestAvailableID}`}
            </Row>
          </Column>
        </Row>
      );
    }
  };

  const addDefaultSrc = (e) => {
    e.target.onerror = null;
    e.target.src = phonePlaceHolder;
    e.target.alt = `Device Image with IMEI ${props.deviceInfo.IMEI} not found`;
  };

  const deviceImage =
    props.deviceImage != null && props.deviceImage.length > 0
      ? props.deviceImage
      : phonePlaceHolder;
  const deviceImageAlt =
    props.deviceImage != null && props.deviceImage.length > 0
      ? `Device Image with imei ${props.deviceInfo.IMEI}`
      : `Device Image with IMEI ${props.deviceInfo.IMEI} not found`;

  return (
    <div className="device-information-component">
      <Row className="device-details">
        <Column className="device-image">
          <img
            src={deviceImage}
            alt={deviceImageAlt}
            onError={(e) => addDefaultSrc(e)}
            style={{ maxWidth: "100%", minWidth: "100px" }}
          />
          <Row className="process-result row centered">
            <div
              className={
                props.processResult === "PASS" ? "process-result-pass" : "process-result-fail"
              }
            >
              <StatusIcon status={props.processResult} />
            </div>
          </Row>
          {renderBestAvailableID()}
        </Column>
        <Column className="full-width">
          <Row>
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="device-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="4" className="header-label">
                      <div className="section-banner row centered">Device Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">
                      <Row>IMEI:</Row>
                      {isIMEI2Present ? <Row>IMEI 2:</Row> : null}
                    </TableColumn>
                    <TableColumn className="table-data">
                      <Row>{props.deviceInfo.IMEI || "N/A"}</Row>
                      {isIMEI2Present ? renderIMEI2Row() : null}
                    </TableColumn>

                    <TableColumn className="table-label">Manufacturer:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.manufacturer || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">MEID:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.MEID || "N/A"}
                    </TableColumn>
                    <DeviceInfoColumn label="Model Name:">
                      {props.deviceInfo.modelName}
                    </DeviceInfoColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Wi-Fi MAC:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.wifiMAC || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Model Number:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.modelNumber || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Bluetooth MAC:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.bluetoothMAC || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">OS Version:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.osVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">ECID:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.ecid || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Storage Capacity:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.storageCapacity || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">ICCID:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.iccid || "N/A"}
                    </TableColumn>

                    <TableColumn className="table-label">Device Color:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.deviceColor || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Serial Number:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.OEMSerialNumber || "N/A"}
                    </TableColumn>
                    {props.deviceInfo.modelRegion && (
                      <DeviceInfoColumn label="Region">
                        {props.deviceInfo.modelRegion} ({props.deviceInfo.RegionCode})
                      </DeviceInfoColumn>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">GUID:</TableColumn>
                    <TableColumn className="table-data" colSpan="3">
                      {props.deviceInfo.GUID || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>

          <Row>
            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-1"
                plain
                className="process-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Process Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Session ID :</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.sessionIDHumanReadable || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Cross System ID :</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.crossSystemId || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Process Name :</TableColumn>
                    <TableColumn className="table-data">{props.process.name || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Shift :</TableColumn>
                    <TableColumn className="table-data">{props.process.shift || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Team :</TableColumn>
                    <TableColumn className="table-data">{props.process.team || "N/A"}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Operator:</TableColumn>
                    <TableColumn className="table-data">
                      {props.process.operator || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Usb Port:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.slotNumber || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>

            <Column className="full-width">
              <DataTable
                baseId="certificate-info-table-2"
                plain
                className="model-information-table"
              >
                <TableHeader className="table-header">
                  <TableRow>
                    <TableColumn colSpan="2" className="header-label">
                      <div className="section-banner row centered">Model Information</div>
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody className="table-body">
                  <TableRow>
                    <TableColumn className="table-label">Commercial Name:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.MarketingName || props.deviceInfo.modelName || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Manufacturer:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.manufacturer || "N/A"}
                    </TableColumn>
                  </TableRow>
                  {props.deviceInfo.productName &&
                    props.deviceInfo.productName !== props.deviceInfo.modelName && (
                      <TableRow>
                        <DeviceInfoColumn label="Product Name:">
                          {props.deviceInfo.productName}
                        </DeviceInfoColumn>
                      </TableRow>
                    )}
                  {props.deviceInfo.productNumber && (
                    <TableRow>
                      <DeviceInfoColumn label="Product Number:">
                        {props.deviceInfo.productNumber}
                      </DeviceInfoColumn>
                    </TableRow>
                  )}
                  {props.deviceInfo.productType && (
                    <TableRow>
                      <DeviceInfoColumn label="Product Type:">
                        {props.deviceInfo.productType}
                      </DeviceInfoColumn>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableColumn className="table-label">OS Version:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.osVersion || "N/A"}
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn className="table-label">Storage Capacity:</TableColumn>
                    <TableColumn className="table-data">
                      {props.deviceInfo.storageCapacity || "N/A"}
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

DeviceInformation.prototypes = {
  deviceInfo: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  deviceImage: state.modules.deviceHistory.deviceInfosWDS.deviceImage,
  wdsInfos: moduleSelectors.deviceInfosWDS.getDeviceInfosFlat(state),
  wdsInfosExtra: moduleSelectors.deviceInfosWDS.getDeviceInfosGrouped(state),
});

const dispatchToProps = {
  fetchWDSDeviceInfos: moduleActions.deviceInfosWDS.fetchWDSDeviceInfos,
  clearWdsInfos: moduleActions.deviceInfosWDS.clearWdsInfos,
};

export default connect(stateToProps, dispatchToProps)(DeviceInformation);
