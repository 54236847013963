import React, { ReactNode } from "react";
import {
  Button,
  Form as AntForm,
  FormItemProps,
  FormProps,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { FormInstance as AntFormInstance } from "antd/es/form/Form";

import { useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { TimePicker } from "~/shared/ui/TimePicker";
import { Labelled, Override } from "~/shared/lib/utilityTypes";
import {
  MonthlyOption,
  monthlyOptions,
  PlainTime,
  RepeatPeriod,
  ScheduledReportParams,
  ScheduleRepeat,
  Weekday,
  weekdays,
} from "../model/scheduledReportsSlice";

// Form fields
type FormValues = {
  // These fields must be optional to properly model how antd manages the form.
  // Notably, they are not immediately initialized to their initialValues, and thus sometimes are empty.
  repeatEvery?: RepeatPeriod;
  repeatOnWeek?: Weekday[];
  repeatOnMonth?: MonthlyOption;
  runAt?: Date;
  displayName?: string;
};

// Type of the form after successful validation; antd Form will only call onFinish() when the form has this shape.
// Note: this type must stay in sync with the `rules` from each FormItem
type ValidFormValues = ValidRepeatValues & {
  displayName?: string;
  runAt: Date;
};

type ValidRepeatValues =
  | {
      repeatEvery: "week";
      repeatOnWeek: Weekday[];
    }
  | {
      repeatEvery: "month";
      repeatOnMonth: MonthlyOption;
    };

// Stricter type overrides for antd form
type FormOverrides = {
  initialValues: FormValues;
  onFinish: (values: ValidFormValues) => void;
};
type FormItemOverrides = {
  name?: keyof FormValues;
  children: ReactNode | ((form: FormInstance) => ReactNode);
};
type FormInstanceOverrides = {
  getFieldValue<T extends keyof FormValues>(field: T): FormValues[T];
};

const Form = (props: Override<FormProps<FormValues>, FormOverrides>) => (
  <AntForm<FormValues> {...(props as FormProps<FormValues>)} /> // eslint-disable-line react/jsx-props-no-spreading
);
const FormItem = (props: Override<FormItemProps<FormValues>, FormItemOverrides>) => (
  <AntForm.Item<FormValues> {...props} /> // eslint-disable-line react/jsx-props-no-spreading
);
type FormInstance = Override<AntFormInstance<FormValues>, FormInstanceOverrides>;

const plainTimeToDate = ({ hour, minute }: PlainTime): Date => {
  const today = new Date();
  const [year, month, day] = [today.getFullYear(), today.getMonth(), today.getDate()];
  return new Date(year, month, day, hour, minute);
};

const dateToPlainTime = (date: Date): PlainTime => {
  const [hour, minute] = [date.getHours(), date.getMinutes()];
  return { hour, minute };
};

const modelToForm = ({ repeat, runAt, ...rest }: ScheduledReportParams): ValidFormValues => ({
  runAt: plainTimeToDate(runAt),
  ...repeatModelToForm(repeat),
  ...rest,
});

const repeatModelToForm = ({ every, on }: ScheduleRepeat): ValidRepeatValues => {
  switch (every) {
    case "week":
      return { repeatEvery: every, repeatOnWeek: on };
    case "month":
      return { repeatEvery: every, repeatOnMonth: on };
    default: {
      const err: never = every; // if error, there is an unhandled case in the switch
      throw new TypeError("Unhandled case: ", err);
    }
  }
};

const formToModel = (form: ValidFormValues): ScheduledReportParams => {
  const { displayName, runAt } = form;
  const report = {
    repeat: repeatValuesToModel(form),
    runAt: dateToPlainTime(runAt),
    displayName,
  };
  return report;
};

const repeatValuesToModel = (form: ValidRepeatValues): ScheduleRepeat => {
  const { repeatEvery } = form;

  switch (repeatEvery) {
    case "week":
      return { every: repeatEvery, on: form.repeatOnWeek };
    case "month":
      return { every: repeatEvery, on: form.repeatOnMonth };
    default: {
      const err: never = repeatEvery; // if error, there is an unhandled case in the switch
      throw new TypeError("Unhandled case: ", err);
    }
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
  onFinish: (values: ScheduledReportParams) => void;
  initialValues: ScheduledReportParams;
};

export const ReportSchedulerModal = ({ open, onClose, onFinish, initialValues }: Props) => {
  const { t } = useTranslation("reporting");

  const isLoading = useAppSelector(
    (state) => state.reportingModule.scheduledReports.formStatus === "loading"
  );

  return (
    <Modal
      forceRender // Pre-render the modal to fix useForm warning
      title={t("report-scheduler")}
      open={open}
      footer={false}
      onCancel={() => {
        onClose();
      }}
      width={640}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={modelToForm(initialValues)}
        onFinish={(form) => {
          onFinish(formToModel(form));
        }}
      >
        <FormItem
          label={t("scheduled-report-name")}
          name="displayName"
          // Note: these rules must stay in sync with the ValidFormValues type
          rules={[]}
        >
          <Input style={{ width: 200 }} />
        </FormItem>
        <FormItem
          label={t("repeat-every")}
          name="repeatEvery"
          // Note: these rules must stay in sync with the ValidFormValues type
          rules={[{ required: true, message: t("required-field") }]}
        >
          <Select<unknown, Labelled<FormValues["repeatEvery"]>>
            style={{ width: 120 }}
            options={[
              {
                value: "week",
                label: t("week"),
              },
              {
                value: "month",
                label: t("month"),
              },
            ]}
          />
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev.repeatEvery !== current.repeatEvery}
        >
          {({ getFieldValue }) => {
            const repeatEvery = getFieldValue("repeatEvery");
            switch (repeatEvery) {
              case undefined:
                return null;
              case "week":
                return (
                  <FormItem
                    label={t("repeat-on")}
                    name="repeatOnWeek"
                    // Note: these rules must stay in sync with the ValidFormValues type
                    rules={[{ required: true, message: t("required-field") }]}
                  >
                    <Select<unknown, Labelled<Weekday>>
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={t("select-days")}
                      options={weekdays.map((day) => ({
                        value: day,
                        label: {
                          sunday: t("sunday"),
                          monday: t("monday"),
                          tuesday: t("tuesday"),
                          wednesday: t("wednesday"),
                          thursday: t("thursday"),
                          friday: t("friday"),
                          saturday: t("saturday"),
                        }[day],
                      }))}
                    />
                  </FormItem>
                );
              case "month":
                return (
                  <FormItem
                    label={t("repeat-on")}
                    name="repeatOnMonth"
                    // Note: these rules must stay in sync with the ValidFormValues type
                    rules={[{ required: true, message: t("required-field") }]}
                  >
                    <Radio.Group
                      options={monthlyOptions.map(
                        (option: NonNullable<FormValues["repeatOnMonth"]>) => ({
                          value: option,
                          label: {
                            "1st-day": t("1st-day"),
                            "15th-day": t("15th-day"),
                          }[option],
                        })
                      )}
                    />
                  </FormItem>
                );

              default: {
                const err: never = repeatEvery; // if error, there is an unhandled case in the switch
                throw new TypeError("Unhandled case: ", err);
              }
            }
          }}
        </FormItem>

        <FormItem
          label={t("run-at")}
          name="runAt"
          // Note: these rules must stay in sync with the ValidFormValues type
          rules={[{ required: true, message: t("required-field") }]}
        >
          <TimePicker format="HH:mm" />
        </FormItem>

        <FormItem wrapperCol={{ offset: 6, span: 18 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("save")}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};
